<template>
  <master-layout pageTitle="Web">
		<ion-content>
			<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
				<ion-refresher-content
					:pulling-icon="chevronDownCircleOutline"
					pulling-text="Pull to refresh"
					refreshing-spinner="circles"
					refreshing-text="Refreshing...">
				</ion-refresher-content>
			</ion-refresher>
			<ion-loading
			v-if="loading"
			:is-open="loading"
			cssClass="my-custom-class"
			message="Loading..."
			></ion-loading>
			<div v-else>
				<ion-toolbar color="light">
					<ion-segment scrollable color="dark" v-model="activeTab" class="segment ion-justify-content-center">
						<ion-segment-button value="appearance">
								<ion-label>Appearance</ion-label>
						</ion-segment-button>
						<ion-segment-button value="website">
								<ion-label>Your Website</ion-label>
						</ion-segment-button>
					</ion-segment>
				</ion-toolbar>
				<div>
					<h2 class="title ion-no-margin" style="margin-top: 20px">Widget Settings</h2>
				</div>
				<div v-if="activeTab === 'appearance'">
					<ion-grid>
						<ion-row>
							<ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
								<div class="model-sections">
									<ion-row class="rowMargin">
										<ion-col class="colMargin">
											<ion-label>Background Color</ion-label>
										</ion-col>
										<ion-col>
											<div class="colorPickerWrapper" :style="{'background-color':customWidget.primary_bgcolor}">
												<ion-input v-model="customWidget.primary_bgcolor" type="color" class="themePicker"></ion-input>
											</div>
										</ion-col>
									</ion-row>
									<ion-row class="rowMargin">
										<ion-col class="colMargin">
											<ion-label>Greeting message</ion-label>
										</ion-col>
										<ion-col>
											<ion-input v-model="customWidget.subheading" rows="4" placeholder="Enter greeting text" class="fullBordered input_custom_style"></ion-input>
										</ion-col>
									</ion-row>
									<ion-row class="rowMargin">
										<ion-col class="colMargin">
											<ion-label>Brand logo</ion-label>
										</ion-col>
										<ion-col>
											<div class="widgetBasicCustomization roundRadius">
												<div class="widgetBasicCustomizationUploadLogoArea">
													<input type="file" style="display: none;" id="brandLogoInput" ref="brandLogoInput" @change="handleImageUpload($event, 'logo')" accept="image/*" />
														<img @click="() => $refs.brandLogoInput.click()" :src="customWidget.brand_logo" class="img-container">
												</div>
												<div class="absoluteButtonIconArea topRightIconArea">
													<ion-button @click="() => $refs.brandLogoInput.click()" size="small" expand="block" fill="none" color="light" class="upload-bttn">
														<ion-icon :icon="arrowUpCircleOutline"></ion-icon>
													</ion-button>
												</div>
												<div class="absoluteButtonIconArea bottomRightIconArea">
													<ion-button @click="() => customWidget.brand_logo = 'https://lh3.googleusercontent.com/VW6R9_VWIOn6EqNp-lElvPiiam3cfMyQIxYgwWDlPb6UK8XasSO8gixWNRO6FmKBHHEZg-LNPLaHD8vf5V2RDr0AYEQB3Qm_rPcUVg'"  size="small" color="light" expand="block" fill="none">
														<ion-icon :icon="trashBinOutline" color="danger"></ion-icon>
													</ion-button>
												</div>
											</div>
										</ion-col>
									</ion-row>
									<ion-row class="rowMargin">
										<ion-col class="colMargin">
											<ion-label>Chat bubble image</ion-label>
										</ion-col>
										<ion-col>
											<div class="widgetBasicCustomization roundRadius">
												<div class="widgetBasicCustomizationUploadLogoArea">
													<input type="file" style="display: none;" id="bubbleImgInput" ref="bubbleImgInput" @change="handleImageUpload($event, 'bubble')" accept="image/*" />
													<img @click="() => $refs.bubbleImgInput.click()" :style="{'background-color': 'black'}" :src="customWidget.bubble_image" class="img-container">
												</div>
												<div class="absoluteButtonIconArea topRightIconArea">
													<ion-button @click="() => $refs.bubbleImgInput.click()" size="small" expand="block" fill="none" color="light" class="upload-bttn">
														<ion-icon :icon="arrowUpCircleOutline"></ion-icon>
													</ion-button>
												</div>
												<div class="absoluteButtonIconArea bottomRightIconArea">
													<ion-button @click="() => customWidget.bubble_image = 'https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg'"  size="small" color="light" expand="block" fill="none">
														<ion-icon :icon="trashBinOutline" color="danger"></ion-icon>
													</ion-button>
												</div>
											</div>
										</ion-col>
									</ion-row>
									<ion-toolbar>
										<ion-buttons slot="end">
											<ion-button fill="solid"  expand="block" size="default" @click="updateWidget(true, true)">
												Save
											</ion-button>
										</ion-buttons>
									</ion-toolbar>
								</div>
							</ion-col>
							<!-- Preview -->
							<ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
								<div class="widgetPreviewContainer">
									<div class="widgetBody">
										<div class="widgetBodyInnerScrollableArea">
											<div class="widgetBodyTop">
												<div class="widgetBodyCompanyHeader" :style="{'background-color':customWidget.primary_bgcolor}">
													<div class="widgetBodyCompanyLogo">
														<img :src="customWidget.brand_logo" class="img-container">
													</div>
													<div class="widgetBodyCompanyDetails">
														<h5 title="name" class="ion-no-margin">{{ user.shopName }}</h5>
													</div>
													<div class="widgetBodyShopDetails">
														<p class="ion-no-margin">{{ customWidget.subheading }}</p>
													</div>
												</div>
											</div>
											<div class="widgetBodyMainArea">
												<div class="widgetBodyMainChatArea">
													<div class="widgetChatContainer">
														<div class="chatBubble chatBubbleLeft">
															<img :src="customWidget.brand_logo" class="left-img-container">
															<div class="chatBubbleContent">Hello, how may I help you?</div>
														</div>
														<div class="chatBubble chatBubbleRight">
															<div class="chatBubbleContent" :style="{'background-color':customWidget.primary_bgcolor}">Hi, I want to order this now!</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="widgetFooterArea">
											<div class="widgetChatInputArea">
												<div class="chatInputBarArea">
													<div class="widgetChatForm">
														<ion-button class="widgetHamburgarOpener" size="small" expand="block" fill="clear" :style="{color:customWidget.primary_bgcolor}" >
															<ion-icon :icon="menuOutline"></ion-icon>
													  </ion-button>
														<ion-input type="text" class="widgetChatInputText" autocomplete="off" placeholder="Type a message..."></ion-input>
														<ion-button class="widgetChatSendButton" size="small" expand="block" fill="clear" :style="{color:customWidget.primary_bgcolor}">
															<ion-icon :icon="sendOutline"></ion-icon>
													  </ion-button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="widgetOpenerFloatingButton" :style="{'background-color':customWidget.primary_bgcolor}">
										<img src="https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg" class="imgFloating" />
									</div>
								</div>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
				<div v-if="activeTab === 'website'">
					<div class="model-sections">
						<div class="prdct-section">
							<ion-card-title class="sctn-labels">Your website:</ion-card-title>
							<ion-input type="url" placeholder="eg https://mysite.com" v-model="customWidget.domain_url" class="fullBordered input_custom_style sctn-input"></ion-input>
						</div>
					</div>
					<div class="model-sections">
						<h3 class="sub-title ion-no-margin">Make sure you have added the attentive chat to the <b>head section</b> of your website, the widget will not show till you have created a message and added the pages where you want to show it.</h3>
						<ol>
							<li>
								<p>Copy the following code</p>
								<figure class="highlight">
									<pre>{{ widgetScript }}</pre>
									<a @click="copy(widgetScript)">Copy to Clipboard</a>
								</figure>
							</li>
							<li>
								<p>Add the code in your page</p>
								<h3 class="info-title ion-no-margin">Paste the Attentive widget code above in your page. You can add it in the HTML head section.</h3>
							</li>
						</ol>
					</div>
				<div>						
			</div>
				</div>
			</div>
		</ion-content>
	</master-layout>
</template>
  
<script>
import { chevronDownCircleOutline, trashBinOutline, arrowUpCircleOutline, menuOutline, sendOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';

var newMessage = {
  widgetid: undefined,
  site_url: "",
  trigger_type: undefined,
  trigger_message_data: [],
  open_after_delay: 0,
  include_urls: [],
  exclude_urls: [],
  extra_options: {
    name: undefined,
    active: false
  }
}

export default {
	data() {
		return {
			loading: false,
			chevronDownCircleOutline,
			trashBinOutline,
			arrowUpCircleOutline,
			menuOutline,
			sendOutline,
			activeTab: 'appearance',
			customWidget: {
        brand_logo: 'https://lh3.googleusercontent.com/VW6R9_VWIOn6EqNp-lElvPiiam3cfMyQIxYgwWDlPb6UK8XasSO8gixWNRO6FmKBHHEZg-LNPLaHD8vf5V2RDr0AYEQB3Qm_rPcUVg',
        bubble_image: 'https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg',
        domain_url: undefined,
        id: undefined,
        primary_bgcolor: '#3d39b9ff',
        store_info: null,
        subheading: undefined,
        widgets_data: []
      },
      newMessage: newMessage,
      dummyMessage: JSON.parse(JSON.stringify(newMessage)),
      isPopperOpen: false,
		}
	},
	computed: {
    widgetScript() {
      return '<script src="https://jumper.ai/widget/js/attentive-widget.js" id="jmpr_attentive_js" widget-id="' + this.customWidget.id + '"></scrip' + 't>';
    },
  },
	mounted() {
		this.init();
	},
	methods: {
		async doRefresh(event) {
			event.target.complete();
		},
		async init() {
			try {
				this.loading = true;
				console.log("Initializing");
			} finally {
				this.loading = false;
			}
		},
		async updateWidget(widgets_data_change, deleting) {
		try {
			this.loading = true;
			console.log(this.customWidget);
			let reqBody = JSON.parse(JSON.stringify(this.customWidget));
			reqBody.store_info = JSON.stringify(reqBody.store_info);

			if(widgets_data_change) {
				if(deleting) reqBody.widgets_data = this.customWidget.widgets_data;
				else {
					if(this.dummyMessage.include_urls && this.dummyMessage.include_urls.length) this.dummyMessage.include_urls = this.dummyMessage.include_urls.map(e => e.url);
					if(this.dummyMessage.exclude_urls && this.dummyMessage.exclude_urls.length) this.dummyMessage.exclude_urls = this.dummyMessage.exclude_urls.map(e => e.url);

					if(this.widgetIndexToEdit > -1) for(var prop in reqBody.widgets_data[this.widgetIndexToEdit]) reqBody.widgets_data[this.widgetIndexToEdit][prop] = this.dummyMessage[prop];
					else reqBody.widgets_data.push(this.dummyMessage);
				}
			}

			reqBody.widgets_data = JSON.stringify(reqBody.widgets_data);

			console.log(reqBody);
			let updateWidgetResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-customized-widget", reqBody);
			if ((updateWidgetResponse.status == 200 || updateWidgetResponse.status == 201) && updateWidgetResponse.data.success) {
				if(updateWidgetResponse.data.result) {
					this.updateToast({
						open: true,
						message: "Widget successfully updated",
						type: "success"
					})
				}
				await this.getCustomWidget();
			} else {
				if(updateWidgetResponse.data && !updateWidgetResponse.data.success && updateWidgetResponse.data.errorMessage) {
					this.updateToast({
						open: true,
						message: updateWidgetResponse.data.errorMessage,
						type: "danger"
					})
				} else {
					this.updateToast({
						open: true,
						message: "Something went wrong",
						type: "danger"
					})
				}
			}
		} catch (error) {
			console.log("Exception: ", error);
			this.updateToast({
				open: true,
				message: "Something went wrong",
				type: "danger"
			})
		} finally {
			this.loading = false;
			this.dummyMessage = JSON.parse(JSON.stringify(newMessage));
		}
		},
		async handleImageUpload (event, elemIndex) {
      try {
        this.loading = true;
        if(elemIndex === 'logo' || elemIndex === 'bubble') {
          if(event.target.files && event.target.files.length) {
            let newImage = event.target.files[0];
            if(await this.validateImage(newImage, 1)) {
              let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                elemIndex === 'logo' ? this.customWidget.brand_logo = uploadSendResponseData.url : this.customWidget.bubble_image = uploadSendResponseData.url;
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong uploading the image",
                  type: "danger"
                })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({
                open: true,
                message: "Invalid picture",
                type: "danger"
              })
            }
          }
        } else {
          if(elemIndex > -1) {
            if(event.target.files && event.target.files.length) {
              let newImage = event.target.files[0];
              if(await this.validateImage(newImage, 4)) {
                let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
                if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                  this.dummyMessage.trigger_message_data[elemIndex].message[0] = uploadSendResponseData.url;
                } else {
                  this.updateToast({
                    open: true,
                    message: "Something went wrong uploading the image",
                    type: "danger"
                  })
                }
              } else {
                console.log("Invalid picture");
                this.updateToast({
                  open: true,
                  message: "Invalid picture",
                  type: "danger"
                })
              }
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
		async getCustomWidget() {
      try {
        this.loading = true;
        let getCustomWidgetResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-customized-widget");
        if ((getCustomWidgetResponse.status == 200 || getCustomWidgetResponse.status == 201) && getCustomWidgetResponse.data.success) {
          for(var prop in this.customWidget) {
            if((prop === 'store_info' || prop === 'widgets_data') && getCustomWidgetResponse.data[prop]) this.customWidget[prop] = JSON.parse(getCustomWidgetResponse.data[prop]);
            else this.customWidget[prop] = getCustomWidgetResponse.data[prop];
          }
        } else {
          if(getCustomWidgetResponse.data && !getCustomWidgetResponse.data.success && getCustomWidgetResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: getCustomWidgetResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
		copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
	}
}
</script>

<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}
.widgetBasicCustomization {
	position: relative;
	width: 120px;
	overflow: hidden;
	background-color: #f1f1f1;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px #f1f1f1 solid;
}

.widgetBasicCustomizationUploadLogoArea {
	cursor: pointer;
	height: 82px;
	width: 81px;
	border: none;
	position: relative;
	margin-top: 6px;
	outline: none;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
}

.absoluteButtonIconArea{
	position: absolute;
  right: -5px;
}
.topRightIconArea{
	top: 5px;
}
.bottomRightIconArea{
	bottom: 5px;
}
.roundRadius{
	border-radius: 40px 5px 5px 40px;
	-webkit-border-radius: 40px 5px 5px 40px;
}
.title {
	font-size: 28px;
	font-weight: 400;
	color: var(--ion-color-black);
	padding-left: 20px;
}

.sctn-labels {
	color: var(--ion-color-black);
	font-weight: 700;
}

.sub-title {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.3;
	color: var(--ion-color-black);
}
.info-title {
	font-size: 14px;
	font-weight: 700;
	color: var(--ion-color-black);
	line-height: 1.3;
}

.img-container{
	width: 80px;
	height: 80px;
	vertical-align: middle;
}
.upload-bttn{
	cursor: pointer;
	color: var(--ion-color-black);
}

figure {
  margin: 0;
}

figure.highlight {
  margin-top: 5px;
}

figure.highlight pre {
  white-space: normal;
  text-align: left;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.colorPickerWrapper {
  min-height: 45px;
  min-width: 45px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  border: 1px #E3E3E3 solid;
  cursor: pointer;
} 
.widgetPreviewContainer{
	margin: 30px 20px;
	pointer-events: none;
}
.widgetBody{
	width: 330px;
	height: auto;
	border-radius: 5px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-box-shadow: 0px 0px 12px rgb(0 0 0 / 12%), 0px 0px 12px rgb(0 0 0 / 12%);
	-moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.12);
	-o-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 12px rgb(0 0 0 / 12%), 0px 0px 12px rgb(0 0 0 / 12%);
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	border-radius: 6px;
}
.widgetBodyTop {
	background: url(https://jumperdevnew.appspot.com/widget/images/chatbox-header-bg.png) no-repeat;
	-webkit-background-size: cover ;
	-moz-background-size: cover ;
	-o-background-size: cover ;
	background-size: cover ;
	background-position: bottom center ;
	opacity: 1 ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
	z-index: 1 ;
	width: 100% ;
}
.widgetBodyCompanyHeader {
	padding: 30px 20px 20px ;
	display: block ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
	position: relative ;
}
.widgetBodyCompanyLogo {
	display: inline-block;
  width: 60px;
}

.widgetBodyCompanyLogo img {
	display: inline-block ;
	height: 45px ;
	width: 45px ;
	object-fit: contain ;
	-webkit-border-radius: 5px ;
	-moz-border-radius: 5px ;
	-o-border-radius: 5px ;
	border-radius: 5px ;
	vertical-align: middle ;
	/* background-color: #fff ; */
	background-color: transparent ;
}
.widgetBodyCompanyDetails {
	display: inline-block ;
	width: calc(100% - 70px) ;
	vertical-align: middle ;
	text-transform: none ;
}

.widgetBodyCompanyDetails h5 {
	font-weight: 400 ;
	font-size: 17px ;
	line-height: 1.2 ;
	letter-spacing: 0.5px ;
	text-transform: none ;
	color: var(--ion-color-white);
}
.widgetBodyShopDetails {
	padding-top: 20px ;
}

.widgetBodyShopDetails p {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.5;
	text-transform: none;
	color: var(--ion-color-white);
}

.widgetBodyMainArea{
	background-color: var(--ion-color-white);
}

.widgetBodyMainChatArea {
	overflow-x: hidden ;
	padding-bottom: 80px ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
}
.widgetChatContainer {
	padding: 15px 15px 5px;
}

.chatBubble {
	font-size: 13px ;
	line-height: 1.5 ;
	display: inline-block ;
	position: relative;
	clear: both ;
	margin-bottom: 25px ;
	vertical-align: top ;
	max-width: 80% ;
	-webkit-animation-duration: 0.3s ;
	-moz-animation-duration: 0.3s ;
	-o-animation-duration: 0.3s ;
	animation-duration: 0.3s ;
	text-transform: none ;
}
.chatBubbleLeft {
	float: left ;
	color: #000 ;
	-webkit-animation-name: slideFromLeft ;
	animation-name: slideFromLeft ;
}
.chatBubbleRight {
	float: right ;
	color: #fff ;
	-webkit-animation-name: slideFromRight ;
	animation-name: slideFromRight ;
}

.chatBubbleRight .chatBubbleContent {
	background-color: var(--main-bg-color, #3d39b9) ;
	padding: 8px 10px ;
	float: left ;
	-webkit-border-radius: 8px ;
	-moz-border-radius: 8px ;
	-o-border-radius: 8px ;
	border-radius: 8px ;
	word-break: break-word ;
}

.left-img-container {
	height: 20px ;
	width: 20px ;
	float: left ;
	margin: 4px 4px 2px 0px ;
	bottom: 0px ;
	left: 0px ;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	vertical-align: middle;
}

.chatBubbleContent {
	background-color: #e9e8e9 ;
	padding: 8px 10px ;
	-webkit-border-radius: 8px ;
	-moz-border-radius: 8px ;
	-o-border-radius: 8px ;
	border-radius: 8px ;
	margin-left: 25px ;
	display: inline-block ;
}

.widgetFooterArea {
	width: 100% ;
	text-align: center ;
	background-color: #fff ;
}

.widgetChatInputArea {
	border-top: none;
}
.chatInputBarArea {
	padding: 10px 10px 10px 10px;
}
.widgetChatForm {
	margin: 0px ;
	text-align: left ;
	position: relative ;
}
.widgetHamburgarOpener {
	display: inline-block;
	margin: 0px;
	padding-bottom: 10px;
}
.widgetChatInputText {
	width: 265px;
	height: 40px ;
	padding-left: 15px ;
	display: inline-block;
	font-size: 15px ;
	border: 1px #ccc solid ;
	background-color: #fff ;
	color: #221e38 ;
	-webkit-border-radius: 25px ;
	border-radius: 25px ;
}
.widgetOpenerFloatingButton {
	margin-left: auto;
	margin-right: 0px;
	z-index: 1;
	position: absolute;
	bottom: 13px;
	right: 148px;
	border-radius: 5px;
}

.imgFloating {
	padding-top: 0px;
	padding-left: 0px;
	height: 60px;
	width: 60px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.widgetChatSendButton {
	z-index: 2;
	margin: 0px ;
	background-color: transparent ;
	cursor: pointer ;
	position: absolute ;
	right: 5px ;
	top: 13px ;
	height: 18px ;
	display: block ;
}

@media only screen and (max-width: 760px) {
	.widgetOpenerFloatingButton{
		bottom: -40px;
		right: 52px;
	}
}
/* input[type="color"] {
    -webkit-appearance: none;
    border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
}  */
</style>