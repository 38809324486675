/* PayPal: we support countries who supports these currencies but the country should be present in the list (check links below)
https://developer.paypal.com/docs/api/reference/country-codes/#paypal-account-payments
https://developer.paypal.com/docs/integration/direct/rest/currency-codes/ */

var paypalSupportedCurrencies = ["AUD", "BRL", "CAD", "CZK", "DKK", "EUR", "HKD", "HUF", "INR", "ILS", "JPY", "MYR", "MXN", "TWD", "NZD", "NOK", "PHP", "PLN", "GBP", "RUB", "SGD", "SEK", "CHF", "THB", "USD"];

var stripeSupportedCurrencies = ["USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BIF", "BMD", "BND", "BOB", "BRL", "BSD", "BWP", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP", "ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF", "IDR", "ILS", "INR", "ISK", "JMD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "MAD", "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MRO", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "PAB", "PEN", "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD", "SCR", "SEK", "SGD", "SHP", "SLL", "SOS", "SRD", "STD", "SVC", "SZL", "THB", "TJS", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU", "UZS", "VND", "VUV", "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR", "ZMW"];

var allCountryCurrencyData = [
	{
		"country_name": "Afghanistan",
		"currency_name": "Afghan Afghani",
		"currency_code": "AFN",
		"currency_code_with_symbol": "AFN",
		"flag_img": "https://flagpedia.net/data/flags/h80/af.png",
		"country_short_code": "AF",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Albania",
		"currency_name": "Albanian lek",
		"currency_code": "ALL",
		"currency_code_with_symbol": "ALL",
		"flag_img": "https://flagpedia.net/data/flags/h80/al.png",
		"country_short_code": "AL",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Algeria",
		"currency_name": "Algerian dinar",
		"currency_code": "DZD",
		"currency_code_with_symbol": "DZD",
		"flag_img": "https://flagpedia.net/data/flags/h80/dz.png",
		"country_short_code": "DZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "American Samoa",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/as.png",
		"country_short_code": "AS",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Andorra",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/ad.png",
		"country_short_code": "AD",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Angola",
		"currency_name": "Angolan kwanza",
		"currency_code": "AOA",
		"currency_code_with_symbol": "AOA",
		"flag_img": "https://flagpedia.net/data/flags/h80/ao.png",
		"country_short_code": "AO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Anguilla",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ai.png",
		"country_short_code": "AI",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Antigua and Barbuda",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ag.png",
		"country_short_code": "AG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Argentina",
		"currency_name": "Argentine peso",
		"currency_code": "ARS",
		"currency_code_with_symbol": "ARS",
		"flag_img": "https://flagpedia.net/data/flags/h80/ar.png",
		"country_short_code": "AR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Armenia",
		"currency_name": "Armenian dram",
		"currency_code": "AMD",
		"currency_code_with_symbol": "AMD",
		"flag_img": "https://flagpedia.net/data/flags/h80/am.png",
		"country_short_code": "AM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Aruba",
		"currency_name": "Aruban guilder",
		"currency_code": "AWG",
		"currency_code_with_symbol": "AWG",
		"flag_img": "https://flagpedia.net/data/flags/h80/aw.png",
		"country_short_code": "AW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Australia",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/au.png",
		"country_short_code": "AU",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Austria",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/at.png",
		"country_short_code": "AT",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Azerbaijan",
		"currency_name": "New azerbaijani Manat",
		"currency_code": "AZN",
		"currency_code_with_symbol": "AZN",
		"flag_img": "https://flagpedia.net/data/flags/h80/az.png",
		"country_short_code": "AZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bahamas",
		"currency_name": "Bahamian dollar",
		"currency_code": "BSD",
		"currency_code_with_symbol": "BS $",
		"flag_img": "https://flagpedia.net/data/flags/h80/bs.png",
		"country_short_code": "BS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bahrain",
		"currency_name": "Bahraini dinar",
		"currency_code": "BHD",
		"currency_code_with_symbol": "BHD",
		"flag_img": "https://flagpedia.net/data/flags/h80/bh.png",
		"country_short_code": "BH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bangladesh",
		"currency_name": "Bangladeshi taka",
		"currency_code": "BDT",
		"currency_code_with_symbol": "BDT",
		"flag_img": "https://flagpedia.net/data/flags/h80/bd.png",
		"country_short_code": "BD",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Barbados",
		"currency_name": "Barbados dollar",
		"currency_code": "BBD",
		"currency_code_with_symbol": "BB $",
		"flag_img": "https://flagpedia.net/data/flags/h80/bb.png",
		"country_short_code": "BB",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Belarus",
		"currency_name": "Belarusian ruble",
		"currency_code": "BYN",
		"currency_code_with_symbol": "BYN",
		"flag_img": "https://flagpedia.net/data/flags/h80/by.png",
		"country_short_code": "BY",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Belgium",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/be.png",
		"country_short_code": "BE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Belize",
		"currency_name": "Belize dollar",
		"currency_code": "BZD",
		"currency_code_with_symbol": "BZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/bz.png",
		"country_short_code": "BZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Benin",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/bj.png",
		"country_short_code": "BJ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bermuda",
		"currency_name": "Bermudian dollar",
		"currency_code": "BMD",
		"currency_code_with_symbol": "BM $",
		"flag_img": "https://flagpedia.net/data/flags/h80/bm.png",
		"country_short_code": "BM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bhutan",
		"currency_name": "Bhutanese ngultrum",
		"currency_code": "BTN",
		"currency_code_with_symbol": "BTN",
		"flag_img": "https://flagpedia.net/data/flags/h80/bt.png",
		"country_short_code": "BT",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bolivia",
		"currency_name": "Boliviano",
		"currency_code": "BOB",
		"currency_code_with_symbol": "BOB",
		"flag_img": "https://flagpedia.net/data/flags/h80/bo.png",
		"country_short_code": "BO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bosnia-Herzegovina",
		"currency_name": "Convertible mark",
		"currency_code": "BAM",
		"currency_code_with_symbol": "BAM",
		"flag_img": "https://flagpedia.net/data/flags/h80/ba.png",
		"country_short_code": "BA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Botswana",
		"currency_name": "Botswana pula",
		"currency_code": "BWP",
		"currency_code_with_symbol": "BWP",
		"flag_img": "https://flagpedia.net/data/flags/h80/bw.png",
		"country_short_code": "BW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bouvet Island",
		"currency_name": "Norwegian krone",
		"currency_code": "NOK",
		"currency_code_with_symbol": "NOK",
		"flag_img": "https://flagpedia.net/data/flags/h80/bv.png",
		"country_short_code": "BV",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Brazil",
		"currency_name": "Brazilian real",
		"currency_code": "BRL",
		"currency_code_with_symbol": "BRL",
		"flag_img": "https://flagpedia.net/data/flags/h80/br.png",
		"country_short_code": "BR",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "British Indian O. Terr.",
		"currency_name": "Pound sterling",
		"currency_code": "GBP",
		"currency_code_with_symbol": "GBP",
		"flag_img": "https://flagpedia.net/data/flags/h80/io.png",
		"country_short_code": "IO",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "British Virgin Islands",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/vg.png",
		"country_short_code": "VG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Brunei Darussalam",
		"currency_name": "Brunei dollar",
		"currency_code": "BND",
		"currency_code_with_symbol": "BN $",
		"flag_img": "https://flagpedia.net/data/flags/h80/bn.png",
		"country_short_code": "BN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Bulgaria",
		"currency_name": "Bulgarian lev",
		"currency_code": "BGN",
		"currency_code_with_symbol": "BGN",
		"flag_img": "https://flagpedia.net/data/flags/h80/bg.png",
		"country_short_code": "BG",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Burkina Faso",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/bf.png",
		"country_short_code": "BF",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Burundi",
		"currency_name": "Burundian franc",
		"currency_code": "BIF",
		"currency_code_with_symbol": "BIF",
		"flag_img": "https://flagpedia.net/data/flags/h80/bi.png",
		"country_short_code": "BI",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cambodia",
		"currency_name": "Cambodian riel",
		"currency_code": "KHR",
		"currency_code_with_symbol": "KHR",
		"flag_img": "https://flagpedia.net/data/flags/h80/kh.png",
		"country_short_code": "KH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cameroon",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/cm.png",
		"country_short_code": "CM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Canada",
		"currency_name": "Canadian dollar",
		"currency_code": "CAD",
		"currency_code_with_symbol": "CA $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ca.png",
		"country_short_code": "CA",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cape Verde",
		"currency_name": "Cape Verde escudo",
		"currency_code": "CVE",
		"currency_code_with_symbol": "CVE",
		"flag_img": "https://flagpedia.net/data/flags/h80/cv.png",
		"country_short_code": "CV",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cayman Islands",
		"currency_name": "Cayman Islands dollar",
		"currency_code": "KYD",
		"currency_code_with_symbol": "KY $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ky.png",
		"country_short_code": "KY",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Central African Rep.",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/cf.png",
		"country_short_code": "CF",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Chad",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/td.png",
		"country_short_code": "TD",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Chile",
		"currency_name": "Chilean peso",
		"currency_code": "CLP",
		"currency_code_with_symbol": "CLP",
		"flag_img": "https://flagpedia.net/data/flags/h80/cl.png",
		"country_short_code": "CL",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "China",
		"currency_name": "Chinese yuan renminbi (RMB)",
		"currency_code": "CNY",
		"currency_code_with_symbol": "CNY",
		"flag_img": "https://flagpedia.net/data/flags/h80/cn.png",
		"country_short_code": "CN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Christmas Island",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/cx.png",
		"country_short_code": "CX",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cocos (Keeling) Islands",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/cc.png",
		"country_short_code": "CC",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Colombia",
		"currency_name": "Colombian peso",
		"currency_code": "COP",
		"currency_code_with_symbol": "COP",
		"flag_img": "https://flagpedia.net/data/flags/h80/co.png",
		"country_short_code": "CO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Comoros",
		"currency_name": "Comoro franc",
		"currency_code": "KMF",
		"currency_code_with_symbol": "KMF",
		"flag_img": "https://flagpedia.net/data/flags/h80/km.png",
		"country_short_code": "KM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Congo (Brazzaville)",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/cg.png",
		"country_short_code": "CG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cook Islands",
		"currency_name": "New Zealand dollar",
		"currency_code": "NZD",
		"currency_code_with_symbol": "NZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ck.png",
		"country_short_code": "CK",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Costa Rica",
		"currency_name": "Costa Rican colon",
		"currency_code": "CRC",
		"currency_code_with_symbol": "CRC",
		"flag_img": "https://flagpedia.net/data/flags/h80/cr.png",
		"country_short_code": "CR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Croatia",
		"currency_name": "Croatian kuna",
		"currency_code": "HRK",
		"currency_code_with_symbol": "HRK",
		"flag_img": "https://flagpedia.net/data/flags/h80/hr.png",
		"country_short_code": "HR",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cuba",
		"currency_name": "Cuban convertible Peso",
		"currency_code": "CUC",
		"currency_code_with_symbol": "CUC",
		"flag_img": "https://flagpedia.net/data/flags/h80/cu.png",
		"country_short_code": "CU",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Cyprus",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/cy.png",
		"country_short_code": "CY",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Czech Republic",
		"currency_name": "Czech koruna",
		"currency_code": "CZK",
		"currency_code_with_symbol": "CZK",
		"flag_img": "https://flagpedia.net/data/flags/h80/cz.png",
		"country_short_code": "CZ",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Democratic Republic of Congo (Kinshasa-Zaire)",
		"currency_name": "Congolese franc",
		"currency_code": "CDF",
		"currency_code_with_symbol": "CDF",
		"flag_img": "https://flagpedia.net/data/flags/h80/cd.png",
		"country_short_code": "CD",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Denmark",
		"currency_name": "Danish krone",
		"currency_code": "DKK",
		"currency_code_with_symbol": "DKK",
		"flag_img": "https://flagpedia.net/data/flags/h80/dk.png",
		"country_short_code": "DK",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Djibouti",
		"currency_name": "Djiboutian franc",
		"currency_code": "DJF",
		"currency_code_with_symbol": "DJF",
		"flag_img": "https://flagpedia.net/data/flags/h80/dj.png",
		"country_short_code": "DJ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Dominica",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/dm.png",
		"country_short_code": "DM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Dominican Republic",
		"currency_name": "Dominican peso",
		"currency_code": "DOP",
		"currency_code_with_symbol": "DOP",
		"flag_img": "https://flagpedia.net/data/flags/h80/do.png",
		"country_short_code": "DO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ecuador",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/ec.png",
		"country_short_code": "EC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Egypt",
		"currency_name": "Egyptian pound",
		"currency_code": "EGP",
		"currency_code_with_symbol": "EGP",
		"flag_img": "https://flagpedia.net/data/flags/h80/eg.png",
		"country_short_code": "EG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "El Salvador",
		"currency_name": "Salvadoran colon",
		"currency_code": "SVC",
		"currency_code_with_symbol": "SVC",
		"flag_img": "https://flagpedia.net/data/flags/h80/sv.png",
		"country_short_code": "SV",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Eritrea",
		"currency_name": "Eritrean nakfa",
		"currency_code": "ERN",
		"currency_code_with_symbol": "ERN",
		"flag_img": "https://flagpedia.net/data/flags/h80/er.png",
		"country_short_code": "ER",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Estonia",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/ee.png",
		"country_short_code": "EE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ethiopia",
		"currency_name": "Ethipian birr",
		"currency_code": "ETB",
		"currency_code_with_symbol": "ETB",
		"flag_img": "https://flagpedia.net/data/flags/h80/et.png",
		"country_short_code": "ET",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "European Union",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/eu.png",
		"country_short_code": "EU",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Falkland Isl., Malvinas",
		"currency_name": "Falkland Islands pound",
		"currency_code": "FKP",
		"currency_code_with_symbol": "FKP",
		"flag_img": "https://flagpedia.net/data/flags/h80/fk.png",
		"country_short_code": "FK",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Faroe Islands",
		"currency_name": "Danish krone",
		"currency_code": "DKK",
		"currency_code_with_symbol": "DKK",
		"flag_img": "https://flagpedia.net/data/flags/h80/fo.png",
		"country_short_code": "FO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Fiji",
		"currency_name": "Fiji dollar",
		"currency_code": "FJD",
		"currency_code_with_symbol": "FJ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/fj.png",
		"country_short_code": "FJ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Finland",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/fi.png",
		"country_short_code": "FI",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "France",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/fr.png",
		"country_short_code": "FR",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "French Guiana",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/gf.png",
		"country_short_code": "GF",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "French Polynesia",
		"currency_name": "French pacific franc",
		"currency_code": "XPF",
		"currency_code_with_symbol": "XPF",
		"flag_img": "https://flagpedia.net/data/flags/h80/pf.png",
		"country_short_code": "PF",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "French Southern Terr.",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/tf.png",
		"country_short_code": "TF",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Gabon",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ga.png",
		"country_short_code": "GA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Gambia",
		"currency_name": "Gambian dalasi",
		"currency_code": "GMD",
		"currency_code_with_symbol": "GMD",
		"flag_img": "https://flagpedia.net/data/flags/h80/gm.png",
		"country_short_code": "GM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Georgia",
		"currency_name": "Georgian lari",
		"currency_code": "GEL",
		"currency_code_with_symbol": "GEL",
		"flag_img": "https://flagpedia.net/data/flags/h80/ge.png",
		"country_short_code": "GE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Germany",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/de.png",
		"country_short_code": "DE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ghana",
		"currency_name": "Ghanaian Cedi",
		"currency_code": "GHS",
		"currency_code_with_symbol": "GHS",
		"flag_img": "https://flagpedia.net/data/flags/h80/gh.png",
		"country_short_code": "GH",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Gibraltar",
		"currency_name": "Gibraltar pound",
		"currency_code": "GIP",
		"currency_code_with_symbol": "GIP",
		"flag_img": "https://flagpedia.net/data/flags/h80/gi.png",
		"country_short_code": "GI",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Greece",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/gr.png",
		"country_short_code": "GR",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Greenland (Denmark)",
		"currency_name": "Danish krone",
		"currency_code": "DKK",
		"currency_code_with_symbol": "DKK",
		"flag_img": "https://flagpedia.net/data/flags/h80/gl.png",
		"country_short_code": "GL",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Grenada",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/gd.png",
		"country_short_code": "GD",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guadaloupe",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/gp.png",
		"country_short_code": "GP",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guam (US)",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/gu.png",
		"country_short_code": "GU",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guatemala",
		"currency_name": "Guatemalan quetzal",
		"currency_code": "GTQ",
		"currency_code_with_symbol": "GTQ",
		"flag_img": "https://flagpedia.net/data/flags/h80/gt.png",
		"country_short_code": "GT",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guernsey",
		"currency_name": "Guernsey Pound",
		"currency_code": "GGP",
		"currency_code_with_symbol": "GGP",
		"flag_img": "https://flagpedia.net/data/flags/h80/gg.png",
		"country_short_code": "GG",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guinea (Conakry)",
		"currency_name": "Guinean franc",
		"currency_code": "GNF",
		"currency_code_with_symbol": "GNF",
		"flag_img": "https://flagpedia.net/data/flags/h80/gn.png",
		"country_short_code": "GN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guinea Bissau",
		"currency_name": "CFA Franc BEAC",
		"currency_code": "XAF",
		"currency_code_with_symbol": "XAF",
		"flag_img": "https://flagpedia.net/data/flags/h80/gw.png",
		"country_short_code": "GW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Guyana",
		"currency_name": "Guyanese dollar",
		"currency_code": "GYD",
		"currency_code_with_symbol": "GY $",
		"flag_img": "https://flagpedia.net/data/flags/h80/gy.png",
		"country_short_code": "GY",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Haiti",
		"currency_name": "Haitian gourde",
		"currency_code": "HTG",
		"currency_code_with_symbol": "HTG",
		"flag_img": "https://flagpedia.net/data/flags/h80/ht.png",
		"country_short_code": "HT",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Heard and McDonald Isl.",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/hm.png",
		"country_short_code": "HM",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Honduras",
		"currency_name": "Honduran lempira",
		"currency_code": "HNL",
		"currency_code_with_symbol": "HNL",
		"flag_img": "https://flagpedia.net/data/flags/h80/hn.png",
		"country_short_code": "HN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Hong Kong",
		"currency_name": "Hong Kong dollar",
		"currency_code": "HKD",
		"currency_code_with_symbol": "HK $",
		"flag_img": "https://flagpedia.net/data/flags/h80/hk.png",
		"country_short_code": "HK",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": true,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Hungary",
		"currency_name": "Hungarian forint",
		"currency_code": "HUF",
		"currency_code_with_symbol": "HUF",
		"flag_img": "https://flagpedia.net/data/flags/h80/hu.png",
		"country_short_code": "HU",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Iceland",
		"currency_name": "Icelandic króna",
		"currency_code": "ISK",
		"currency_code_with_symbol": "ISK",
		"flag_img": "https://flagpedia.net/data/flags/h80/is.png",
		"country_short_code": "IS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "India",
		"currency_name": "Indian rupee",
		"currency_code": "INR",
		"currency_code_with_symbol": "₹",
		"flag_img": "https://flagpedia.net/data/flags/h80/in.png",
		"country_short_code": "IN",
		"stripe_enabled": true,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": true,
		"razorpay_enabled": true,
		"paytm_enabled": true,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Indonesia",
		"currency_name": "Indonesian rupiah",
		"currency_code": "IDR",
		"currency_code_with_symbol": "Rp ",
		"flag_img": "https://flagpedia.net/data/flags/h80/id.png",
		"country_short_code": "ID",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": true,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": true,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Iran",
		"currency_name": "Iranian rial",
		"currency_code": "IRR",
		"currency_code_with_symbol": "IRR",
		"flag_img": "https://flagpedia.net/data/flags/h80/ir.png",
		"country_short_code": "IR",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Iraq",
		"currency_name": "Iraqi dinar",
		"currency_code": "IQD",
		"currency_code_with_symbol": "IQD",
		"flag_img": "https://flagpedia.net/data/flags/h80/iq.png",
		"country_short_code": "IQ",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ireland",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/ie.png",
		"country_short_code": "IE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Isle of Man",
		"currency_name": "Manx pound",
		"currency_code": "IMP",
		"currency_code_with_symbol": "IMP",
		"flag_img": "https://flagpedia.net/data/flags/h80/im.png",
		"country_short_code": "IM",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Israel",
		"currency_name": "Israeli new shekel",
		"currency_code": "ILS",
		"currency_code_with_symbol": "ILS",
		"flag_img": "https://flagpedia.net/data/flags/h80/il.png",
		"country_short_code": "IL",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": true,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Italy",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/it.png",
		"country_short_code": "IT",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ivory Coast",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ci.png",
		"country_short_code": "CI",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Jamaica",
		"currency_name": "Jamaican dollar",
		"currency_code": "JMD",
		"currency_code_with_symbol": "JM $",
		"flag_img": "https://flagpedia.net/data/flags/h80/jm.png",
		"country_short_code": "JM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Japan",
		"currency_name": "Japanese yen",
		"currency_code": "JPY",
		"currency_code_with_symbol": "JP ¥",
		"flag_img": "https://flagpedia.net/data/flags/h80/jp.png",
		"country_short_code": "JP",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": true,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Jersey",
		"currency_name": "Jersey pound",
		"currency_code": "JEP",
		"currency_code_with_symbol": "JEP",
		"flag_img": "https://flagpedia.net/data/flags/h80/je.png",
		"country_short_code": "JE",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Jordan",
		"currency_name": "Jordanian dinar",
		"currency_code": "JOD",
		"currency_code_with_symbol": "JOD",
		"flag_img": "https://flagpedia.net/data/flags/h80/jo.png",
		"country_short_code": "JO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Kazakhstan",
		"currency_name": "Kazakhstani tenge",
		"currency_code": "KZT",
		"currency_code_with_symbol": "KZT",
		"flag_img": "https://flagpedia.net/data/flags/h80/kz.png",
		"country_short_code": "KZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Kenya",
		"currency_name": "Kenyan shilling",
		"currency_code": "KES",
		"currency_code_with_symbol": "KES",
		"flag_img": "https://flagpedia.net/data/flags/h80/ke.png",
		"country_short_code": "KE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Kiribati",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ki.png",
		"country_short_code": "KI",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Kuwait",
		"currency_name": "Kuwaiti dinar",
		"currency_code": "KWD",
		"currency_code_with_symbol": "KWD",
		"flag_img": "https://flagpedia.net/data/flags/h80/kw.png",
		"country_short_code": "KW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Kyrgyz Republic",
		"currency_name": "Kyrgyzstani som",
		"currency_code": "KGS",
		"currency_code_with_symbol": "KGS",
		"flag_img": "https://flagpedia.net/data/flags/h80/kg.png",
		"country_short_code": "KG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Laos",
		"currency_name": "Lao kip",
		"currency_code": "LAK",
		"currency_code_with_symbol": "LAK",
		"flag_img": "https://flagpedia.net/data/flags/h80/la.png",
		"country_short_code": "LA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Latvia",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/lv.png",
		"country_short_code": "LV",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Lebanon",
		"currency_name": "Lebanese pound",
		"currency_code": "LBP",
		"currency_code_with_symbol": "LBP",
		"flag_img": "https://flagpedia.net/data/flags/h80/lb.png",
		"country_short_code": "LB",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Lesotho",
		"currency_name": "Lesotho loti",
		"currency_code": "LSL",
		"currency_code_with_symbol": "LSL",
		"flag_img": "https://flagpedia.net/data/flags/h80/ls.png",
		"country_short_code": "LS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Liberia",
		"currency_name": "Liberian dollar",
		"currency_code": "LRD",
		"currency_code_with_symbol": "LR $",
		"flag_img": "https://flagpedia.net/data/flags/h80/lr.png",
		"country_short_code": "LR",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Libya",
		"currency_name": "Libyan dinar",
		"currency_code": "LYD",
		"currency_code_with_symbol": "LYD",
		"flag_img": "https://flagpedia.net/data/flags/h80/ly.png",
		"country_short_code": "LY",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Liechtenstein",
		"currency_name": "Swiss franc",
		"currency_code": "CHF",
		"currency_code_with_symbol": "CHF",
		"flag_img": "https://flagpedia.net/data/flags/h80/li.png",
		"country_short_code": "LI",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Lithuania",
		"currency_name": "Lithuanian litas",
		"currency_code": "LTL",
		"currency_code_with_symbol": "LTL",
		"flag_img": "https://flagpedia.net/data/flags/h80/lt.png",
		"country_short_code": "LT",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Luxembourg",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/lu.png",
		"country_short_code": "LU",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Macau",
		"currency_name": "Macanese pataca",
		"currency_code": "MOP",
		"currency_code_with_symbol": "MOP",
		"flag_img": "https://flagpedia.net/data/flags/h80/mo.png",
		"country_short_code": "MO",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Macedonia",
		"currency_name": "Macedonian denar",
		"currency_code": "MKD",
		"currency_code_with_symbol": "MKD",
		"flag_img": "https://flagpedia.net/data/flags/h80/mk.png",
		"country_short_code": "MK",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Madagascar",
		"currency_name": "Malagasy ariayry",
		"currency_code": "MGA",
		"currency_code_with_symbol": "MGA",
		"flag_img": "https://flagpedia.net/data/flags/h80/mg.png",
		"country_short_code": "MG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Malawi",
		"currency_name": "Malawian kwacha",
		"currency_code": "MWK",
		"currency_code_with_symbol": "MWK",
		"flag_img": "https://flagpedia.net/data/flags/h80/mw.png",
		"country_short_code": "MW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Malaysia",
		"currency_name": "Malaysian ringgit",
		"currency_code": "MYR",
		"currency_code_with_symbol": "RM ",
		"flag_img": "https://flagpedia.net/data/flags/h80/my.png",
		"country_short_code": "MY",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": true,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false, //true
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": true,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Maldives",
		"currency_name": "Maldivian rufiyaa",
		"currency_code": "MVR",
		"currency_code_with_symbol": "MVR",
		"flag_img": "https://flagpedia.net/data/flags/h80/mv.png",
		"country_short_code": "MV",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mali",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ml.png",
		"country_short_code": "ML",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Malta",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/mt.png",
		"country_short_code": "MT",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Marshall Islands",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/mh.png",
		"country_short_code": "MH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Martinique",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/mq.png",
		"country_short_code": "MQ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mauritania",
		"currency_name": "Mauritanian ouguiya",
		"currency_code": "MRO",
		"currency_code_with_symbol": "MRO",
		"flag_img": "https://flagpedia.net/data/flags/h80/mr.png",
		"country_short_code": "MR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mauritius",
		"currency_name": "Mauritian rupee",
		"currency_code": "MUR",
		"currency_code_with_symbol": "MUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/mu.png",
		"country_short_code": "MU",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mayotte",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/yt.png",
		"country_short_code": "YT",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mexico",
		"currency_name": "Mexican peso",
		"currency_code": "MXN",
		"currency_code_with_symbol": "MXN",
		"flag_img": "https://flagpedia.net/data/flags/h80/mx.png",
		"country_short_code": "MX",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Micronesia",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/fm.png",
		"country_short_code": "FM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Moldova",
		"currency_name": "Moldovan leu",
		"currency_code": "MDL",
		"currency_code_with_symbol": "MDL",
		"flag_img": "https://flagpedia.net/data/flags/h80/md.png",
		"country_short_code": "MD",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Monaco",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/mc.png",
		"country_short_code": "MC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mongolia",
		"currency_name": "Mongolian tugrik",
		"currency_code": "MNT",
		"currency_code_with_symbol": "MNT",
		"flag_img": "https://flagpedia.net/data/flags/h80/mn.png",
		"country_short_code": "MN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Montenegro",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/me.png",
		"country_short_code": "ME",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Montserrat",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/ms.png",
		"country_short_code": "MS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Morocco",
		"currency_name": "Moroccan dirham",
		"currency_code": "MAD",
		"currency_code_with_symbol": "MAD",
		"flag_img": "https://flagpedia.net/data/flags/h80/ma.png",
		"country_short_code": "MA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Mozambique",
		"currency_name": "Mozambican metical",
		"currency_code": "MZN",
		"currency_code_with_symbol": "MZN",
		"flag_img": "https://flagpedia.net/data/flags/h80/mz.png",
		"country_short_code": "MZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Myanmar",
		"currency_name": "Myanma kyat",
		"currency_code": "MMK",
		"currency_code_with_symbol": "MMK",
		"flag_img": "https://flagpedia.net/data/flags/h80/mm.png",
		"country_short_code": "MM",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Namibia",
		"currency_name": "Namibian dollar",
		"currency_code": "NAD",
		"currency_code_with_symbol": "NA $",
		"flag_img": "https://flagpedia.net/data/flags/h80/na.png",
		"country_short_code": "NA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Nauru",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/nr.png",
		"country_short_code": "NR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Nepal",
		"currency_name": "Nepalese rupee",
		"currency_code": "NPR",
		"currency_code_with_symbol": "NPR",
		"flag_img": "https://flagpedia.net/data/flags/h80/np.png",
		"country_short_code": "NP",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Netherland Antilles",
		"currency_name": "Netherlands Antillean guilder",
		"currency_code": "ANG",
		"currency_code_with_symbol": "ANG",
		"flag_img": "https://flagpedia.net/data/flags/h80/an.png",
		"country_short_code": "AN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Netherlands",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/nl.png",
		"country_short_code": "NL",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "New Caledonia",
		"currency_name": "French pacific franc",
		"currency_code": "XPF",
		"currency_code_with_symbol": "XPF",
		"flag_img": "https://flagpedia.net/data/flags/h80/nc.png",
		"country_short_code": "NC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "New Zealand",
		"currency_name": "New Zealand dollar",
		"currency_code": "NZD",
		"currency_code_with_symbol": "NZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/nz.png",
		"country_short_code": "NZ",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Nicaragua",
		"currency_name": "Nicaraguan córdoba",
		"currency_code": "NIO",
		"currency_code_with_symbol": "NIO",
		"flag_img": "https://flagpedia.net/data/flags/h80/ni.png",
		"country_short_code": "NI",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Niger",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ne.png",
		"country_short_code": "NE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Nigeria",
		"currency_name": "Nigerian naira",
		"currency_code": "NGN",
		"currency_code_with_symbol": "NGN",
		"flag_img": "https://flagpedia.net/data/flags/h80/ng.png",
		"country_short_code": "NG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": true,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Niue",
		"currency_name": "New Zealand dollar",
		"currency_code": "NZD",
		"currency_code_with_symbol": "NZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/nu.png",
		"country_short_code": "NU",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Norfolk Island",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/nf.png",
		"country_short_code": "NF",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "North Korea",
		"currency_name": "North Korean won",
		"currency_code": "KPW",
		"currency_code_with_symbol": "KPW",
		"flag_img": "https://flagpedia.net/data/flags/h80/kp.png",
		"country_short_code": "KP",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Northern Mariana Isl.",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/mp.png",
		"country_short_code": "MP",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Norway",
		"currency_name": "Norwegian krone",
		"currency_code": "NOK",
		"currency_code_with_symbol": "kr ",
		"flag_img": "https://flagpedia.net/data/flags/h80/no.png",
		"country_short_code": "NO",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Oman",
		"currency_name": "Omani rial",
		"currency_code": "OMR",
		"currency_code_with_symbol": "OMR",
		"flag_img": "https://flagpedia.net/data/flags/h80/om.png",
		"country_short_code": "OM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Pakistan",
		"currency_name": "Pakistani rupee",
		"currency_code": "PKR",
		"currency_code_with_symbol": "PKR",
		"flag_img": "https://flagpedia.net/data/flags/h80/pk.png",
		"country_short_code": "PK",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Palau",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/pw.png",
		"country_short_code": "PW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Palestine",
		"currency_name": "Israeli new shekel",
		"currency_code": "ILS",
		"currency_code_with_symbol": "ILS",
		"flag_img": "https://flagpedia.net/data/flags/h80/ps.png",
		"country_short_code": "PS",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Panama",
		"currency_name": "Panamanian balboa",
		"currency_code": "PAB",
		"currency_code_with_symbol": "PAB",
		"flag_img": "https://flagpedia.net/data/flags/h80/pa.png",
		"country_short_code": "PA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Papua New Guinea",
		"currency_name": "Papua New Guinean kina",
		"currency_code": "PGK",
		"currency_code_with_symbol": "PGK",
		"flag_img": "https://flagpedia.net/data/flags/h80/pg.png",
		"country_short_code": "PG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Paraguay",
		"currency_name": "Paraguayan guaraní",
		"currency_code": "PYG",
		"currency_code_with_symbol": "PYG",
		"flag_img": "https://flagpedia.net/data/flags/h80/py.png",
		"country_short_code": "PY",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Peru",
		"currency_name": "Peruvian nuevo sol",
		"currency_code": "PEN",
		"currency_code_with_symbol": "PEN",
		"flag_img": "https://flagpedia.net/data/flags/h80/pe.png",
		"country_short_code": "PE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": true,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Philippines",
		"currency_name": "Philippine peso",
		"currency_code": "PHP",
		"currency_code_with_symbol": "₱",
		"flag_img": "https://flagpedia.net/data/flags/h80/ph.png",
		"country_short_code": "PH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": true,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": true,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": true,
		"gcash_enabled": true,
		"paymongo_enabled": true,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Pitcairn",
		"currency_name": "New Zealand dollar",
		"currency_code": "NZD",
		"currency_code_with_symbol": "NZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/pn.png",
		"country_short_code": "PN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Poland",
		"currency_name": "Polish zloty",
		"currency_code": "PLN",
		"currency_code_with_symbol": "PLN",
		"flag_img": "https://flagpedia.net/data/flags/h80/pl.png",
		"country_short_code": "PL",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Portugal",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/pt.png",
		"country_short_code": "PT",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Puerto Rico",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/pr.png",
		"country_short_code": "PR",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Qatar",
		"currency_name": "Qatari riyal",
		"currency_code": "QAR",
		"currency_code_with_symbol": "QAR",
		"flag_img": "https://flagpedia.net/data/flags/h80/qa.png",
		"country_short_code": "QA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Reunion",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/re.png",
		"country_short_code": "RE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Romania",
		"currency_name": "Romanian new Leu",
		"currency_code": "RON",
		"currency_code_with_symbol": "RON",
		"flag_img": "https://flagpedia.net/data/flags/h80/ro.png",
		"country_short_code": "RO",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Russian Federation",
		"currency_name": "Russian ruble",
		"currency_code": "RUB",
		"currency_code_with_symbol": "RUB",
		"flag_img": "https://flagpedia.net/data/flags/h80/ru.png",
		"country_short_code": "RU",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Rwanda",
		"currency_name": "Rwandan franc",
		"currency_code": "RWF",
		"currency_code_with_symbol": "RWF",
		"flag_img": "https://flagpedia.net/data/flags/h80/rw.png",
		"country_short_code": "RW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Saint Lucia",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/lc.png",
		"country_short_code": "LC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Saint Pierre and Miquelon",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/pm.png",
		"country_short_code": "PM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "San Marino",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/sm.png",
		"country_short_code": "SM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Saudi Arabia",
		"currency_name": "Saudi riyal",
		"currency_code": "SAR",
		"currency_code_with_symbol": "SAR",
		"flag_img": "https://flagpedia.net/data/flags/h80/sa.png",
		"country_short_code": "SA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Senegal",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/sn.png",
		"country_short_code": "SN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Serbia",
		"currency_name": "Serbian dinar",
		"currency_code": "RSD",
		"currency_code_with_symbol": "RSD",
		"flag_img": "https://flagpedia.net/data/flags/h80/rs.png",
		"country_short_code": "RS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Seychelles",
		"currency_name": "Seychelles rupee",
		"currency_code": "SCR",
		"currency_code_with_symbol": "SCR",
		"flag_img": "https://flagpedia.net/data/flags/h80/sc.png",
		"country_short_code": "SC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Sierra Leone",
		"currency_name": "Sierra Leonean leone",
		"currency_code": "SLL",
		"currency_code_with_symbol": "SLL",
		"flag_img": "https://flagpedia.net/data/flags/h80/sl.png",
		"country_short_code": "SL",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Singapore",
		"currency_name": "Singapore dollar",
		"currency_code": "SGD",
		"currency_code_with_symbol": "SG $",
		"flag_img": "https://flagpedia.net/data/flags/h80/sg.png",
		"country_short_code": "SG",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": true,
		"omise_enabled": true,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false, //true
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": true,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": true,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Sint Maarten (Dutch part)",
		"currency_name": "Netherlands Antillean guilder",
		"currency_code": "ANG",
		"currency_code_with_symbol": "ANG",
		"flag_img": "https://flagpedia.net/data/flags/h80/sx.png",
		"country_short_code": "SX",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Slovakia",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/sk.png",
		"country_short_code": "SK",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Slovenia",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/si.png",
		"country_short_code": "SI",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": true,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Solomon Islands",
		"currency_name": "Solomon Islands dollar",
		"currency_code": "SBD",
		"currency_code_with_symbol": "SB $",
		"flag_img": "https://flagpedia.net/data/flags/h80/sb.png",
		"country_short_code": "SB",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Somalia",
		"currency_name": "Somali shilling",
		"currency_code": "SOS",
		"currency_code_with_symbol": "SOS",
		"flag_img": "https://flagpedia.net/data/flags/h80/so.png",
		"country_short_code": "SO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "South Africa",
		"currency_name": "South African rand",
		"currency_code": "ZAR",
		"currency_code_with_symbol": "ZAR",
		"flag_img": "https://flagpedia.net/data/flags/h80/za.png",
		"country_short_code": "ZA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": true,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "South Georgia and South Sandwich Islands",
		"currency_name": "Pound sterling",
		"currency_code": "GBP",
		"currency_code_with_symbol": "GBP",
		"flag_img": "https://flagpedia.net/data/flags/h80/gs.png",
		"country_short_code": "GS",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "South Korea",
		"currency_name": "South Korean won",
		"currency_code": "KRW",
		"currency_code_with_symbol": "KRW",
		"flag_img": "https://flagpedia.net/data/flags/h80/kr.png",
		"country_short_code": "KR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "South Sudan",
		"currency_name": "South Sudanese Pound",
		"currency_code": "SSP",
		"currency_code_with_symbol": "SSP",
		"flag_img": "https://flagpedia.net/data/flags/h80/ss.png",
		"country_short_code": "SS",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Spain",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/es.png",
		"country_short_code": "ES",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Sri Lanka",
		"currency_name": "Sri Lankan rupee",
		"currency_code": "LKR",
		"currency_code_with_symbol": "LKR",
		"flag_img": "https://flagpedia.net/data/flags/h80/lk.png",
		"country_short_code": "LK",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "St. Helena",
		"currency_name": "Saint Helena pound",
		"currency_code": "SHP",
		"currency_code_with_symbol": "SHP",
		"flag_img": "https://flagpedia.net/data/flags/h80/sh.png",
		"country_short_code": "SH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "St. Tome and Principe",
		"currency_name": "São Tomé dobra",
		"currency_code": "STD",
		"currency_code_with_symbol": "STD",
		"flag_img": "https://flagpedia.net/data/flags/h80/st.png",
		"country_short_code": "ST",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "St.Kitts Nevis Anguilla",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/kn.png",
		"country_short_code": "KN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "St.Vincent and Grenadines",
		"currency_name": "East Caribbean dollar",
		"currency_code": "XCD",
		"currency_code_with_symbol": "XC $",
		"flag_img": "https://flagpedia.net/data/flags/h80/vc.png",
		"country_short_code": "VC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Sudan",
		"currency_name": "Sudanese pound",
		"currency_code": "SDG",
		"currency_code_with_symbol": "SDG",
		"flag_img": "https://flagpedia.net/data/flags/h80/sd.png",
		"country_short_code": "SD",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Suriname",
		"currency_name": "Surinamese dollar",
		"currency_code": "SRD",
		"currency_code_with_symbol": "SR $",
		"flag_img": "https://flagpedia.net/data/flags/h80/sr.png",
		"country_short_code": "SR",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Svalbard and Jan Mayen Is",
		"currency_name": "Norwegian krone",
		"currency_code": "NOK",
		"currency_code_with_symbol": "NOK",
		"flag_img": "https://flagpedia.net/data/flags/h80/sj.png",
		"country_short_code": "SJ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Swaziland",
		"currency_name": "Swazi lilangeni",
		"currency_code": "SZL",
		"currency_code_with_symbol": "SZL",
		"flag_img": "https://flagpedia.net/data/flags/h80/sz.png",
		"country_short_code": "SZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Sweden",
		"currency_name": "Swedish krona",
		"currency_code": "SEK",
		"currency_code_with_symbol": "SEK",
		"flag_img": "https://flagpedia.net/data/flags/h80/se.png",
		"country_short_code": "SE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Switzerland",
		"currency_name": "Swiss franc",
		"currency_code": "CHF",
		"currency_code_with_symbol": "CHF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ch.png",
		"country_short_code": "CH",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Syria",
		"currency_name": "Syrian pound",
		"currency_code": "SYP",
		"currency_code_with_symbol": "SYP",
		"flag_img": "https://flagpedia.net/data/flags/h80/sy.png",
		"country_short_code": "SY",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Taiwan",
		"currency_name": "New Taiwan dollar",
		"currency_code": "TWD",
		"currency_code_with_symbol": "TW $",
		"flag_img": "https://flagpedia.net/data/flags/h80/tw.png",
		"country_short_code": "TW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tajikistan",
		"currency_name": "Tajikistani somoni",
		"currency_code": "TJS",
		"currency_code_with_symbol": "TJS",
		"flag_img": "https://flagpedia.net/data/flags/h80/tj.png",
		"country_short_code": "TJ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tanzania",
		"currency_name": "Tanzanian shilling",
		"currency_code": "TZS",
		"currency_code_with_symbol": "TZS",
		"flag_img": "https://flagpedia.net/data/flags/h80/tz.png",
		"country_short_code": "TZ",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Thailand",
		"currency_name": "Thai baht",
		"currency_code": "THB",
		"currency_code_with_symbol": "฿",
		"flag_img": "https://flagpedia.net/data/flags/h80/th.png",
		"country_short_code": "TH",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": true,
		"omise_enabled": true,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Timor-Leste",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/tl.png",
		"country_short_code": "TL",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Togo",
		"currency_name": "CFA Franc BCEAO",
		"currency_code": "XOF",
		"currency_code_with_symbol": "XOF",
		"flag_img": "https://flagpedia.net/data/flags/h80/tg.png",
		"country_short_code": "TG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tokelau",
		"currency_name": "New Zealand dollar",
		"currency_code": "NZD",
		"currency_code_with_symbol": "NZ $",
		"flag_img": "https://flagpedia.net/data/flags/h80/tk.png",
		"country_short_code": "TK",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tonga",
		"currency_name": "Tongan pa'anga",
		"currency_code": "TOP",
		"currency_code_with_symbol": "TOP",
		"flag_img": "https://flagpedia.net/data/flags/h80/to.png",
		"country_short_code": "TO",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Trinidad and Tobago",
		"currency_name": "Trinidad dollar",
		"currency_code": "TTD",
		"currency_code_with_symbol": "TT $",
		"flag_img": "https://flagpedia.net/data/flags/h80/tt.png",
		"country_short_code": "TT",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tunisia",
		"currency_name": "Tunisian dinar",
		"currency_code": "TND",
		"currency_code_with_symbol": "TND",
		"flag_img": "https://flagpedia.net/data/flags/h80/tn.png",
		"country_short_code": "TN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Turkey",
		"currency_name": "Turkish lira",
		"currency_code": "TRY",
		"currency_code_with_symbol": "TRY",
		"flag_img": "https://flagpedia.net/data/flags/h80/tr.png",
		"country_short_code": "TR",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": true,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Turkmenistan",
		"currency_name": "Turkmenistani new manat",
		"currency_code": "TMT",
		"currency_code_with_symbol": "TMT",
		"flag_img": "https://flagpedia.net/data/flags/h80/tm.png",
		"country_short_code": "TM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Turks and Caicos Islands",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/tc.png",
		"country_short_code": "TC",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Tuvalu",
		"currency_name": "Australian dollar",
		"currency_code": "AUD",
		"currency_code_with_symbol": "AU $",
		"flag_img": "https://flagpedia.net/data/flags/h80/tv.png",
		"country_short_code": "TV",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Uganda",
		"currency_name": "Ugandan shilling",
		"currency_code": "UGX",
		"currency_code_with_symbol": "UGX",
		"flag_img": "https://flagpedia.net/data/flags/h80/ug.png",
		"country_short_code": "UG",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Ukraine",
		"currency_name": "Ukrainian hryvnia",
		"currency_code": "UAH",
		"currency_code_with_symbol": "UAH",
		"flag_img": "https://flagpedia.net/data/flags/h80/ua.png",
		"country_short_code": "UA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "United Arab Emirates",
		"currency_name": "UAE dirham",
		"currency_code": "AED",
		"currency_code_with_symbol": "AED",
		"flag_img": "https://flagpedia.net/data/flags/h80/ae.png",
		"country_short_code": "AE",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": true,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "United Kingdom",
		"currency_name": "Pound sterling",
		"currency_code": "GBP",
		"currency_code_with_symbol": "GBP",
		"flag_img": "https://flagpedia.net/data/flags/h80/gb.png",
		"country_short_code": "GB",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": true,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "United States",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/us.png",
		"country_short_code": "US",
		"stripe_enabled": true,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": true,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": true,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": true,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": true,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Uruguay",
		"currency_name": "Urugayan peso",
		"currency_code": "UYU",
		"currency_code_with_symbol": "UYU",
		"flag_img": "https://flagpedia.net/data/flags/h80/uy.png",
		"country_short_code": "UY",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "US Minor outlying Isl.",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/um.png",
		"country_short_code": "UM",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "US Virgin Islands",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/vi.png",
		"country_short_code": "VI",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Uzbekistan",
		"currency_name": "Uzbekitan som",
		"currency_code": "UZS",
		"currency_code_with_symbol": "so'm ",
		"flag_img": "https://flagpedia.net/data/flags/h80/uz.png",
		"country_short_code": "UZ",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Vanuatu",
		"currency_name": "Vanuatu vatu",
		"currency_code": "VUV",
		"currency_code_with_symbol": "VUV",
		"flag_img": "https://flagpedia.net/data/flags/h80/vu.png",
		"country_short_code": "VU",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Vatican City State",
		"currency_name": "Euro",
		"currency_code": "EUR",
		"currency_code_with_symbol": "EUR",
		"flag_img": "https://flagpedia.net/data/flags/h80/va.png",
		"country_short_code": "VA",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": true,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Venezuela",
		"currency_name": "Venezualan bolivar fuerte",
		"currency_code": "VEF",
		"currency_code_with_symbol": "VEF",
		"flag_img": "https://flagpedia.net/data/flags/h80/ve.png",
		"country_short_code": "VE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Vietnam",
		"currency_name": "Vietnamese đồng",
		"currency_code": "VND",
		"currency_code_with_symbol": "VND",
		"flag_img": "https://flagpedia.net/data/flags/h80/vn.png",
		"country_short_code": "VN",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": true,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": true,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": true,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": true,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Wallis and Futuna Islands",
		"currency_name": "French pacific franc",
		"currency_code": "XPF",
		"currency_code_with_symbol": "XPF",
		"flag_img": "https://flagpedia.net/data/flags/h80/wf.png",
		"country_short_code": "WF",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Western Sahara",
		"currency_name": "Moroccan dirham",
		"currency_code": "MAD",
		"currency_code_with_symbol": "MAD",
		"flag_img": "https://flagpedia.net/data/flags/h80/eh.png",
		"country_short_code": "EH",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Western Samoa",
		"currency_name": "Samoan tala",
		"currency_code": "WST",
		"currency_code_with_symbol": "WST",
		"flag_img": "https://flagpedia.net/data/flags/h80/ws.png",
		"country_short_code": "WS",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Yemen",
		"currency_name": "Yemeni rial",
		"currency_code": "YER",
		"currency_code_with_symbol": "YER",
		"flag_img": "https://flagpedia.net/data/flags/h80/ye.png",
		"country_short_code": "YE",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Yugoslavia",
		"currency_name": "Yugoslav dinar",
		"currency_code": "YUN",
		"currency_code_with_symbol": "YUN",
		"flag_img": "https://flagpedia.net/data/flags/h80/yu.png",
		"country_short_code": "YU",
		"stripe_enabled": false,
		"paypal_enabled": false,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Zambia",
		"currency_name": "Zambian kwacha",
		"currency_code": "ZMW",
		"currency_code_with_symbol": "ZMW",
		"flag_img": "https://flagpedia.net/data/flags/h80/zm.png",
		"country_short_code": "ZM",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": false,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	},
	{
		"country_name": "Zimbabwe",
		"currency_name": "US dollar",
		"currency_code": "USD",
		"currency_code_with_symbol": "$",
		"flag_img": "https://flagpedia.net/data/flags/h80/zw.png",
		"country_short_code": "ZW",
		"stripe_enabled": false,
		"paypal_enabled": true,
		"pesopay_enabled": false,
		"wirecard_enabled": false,
		"pg2c2p_enabled": false,
		"payulatam_enabled": false,
		"ipay88_enabled": false,
		"omise_enabled": false,
		"authorize_enabled": false,
		"twocheckout_enabled": true,
		"adyen_enabled": false,
		"iyzico_enabled": false,
		"checkoutcom_enabled": false,
		"hoolah_enabled": false,
		"ngenius_enabled": false,
		"mollie_enabled": false,
		"worldpay_enabled": false,
		"atome_enabled": false,
		"paymaya_enabled": false,
		"gcash_enabled": false,
		"paymongo_enabled": false,
		"payU_enabled": false,
		"razorpay_enabled": false,
		"paytm_enabled": false,
		"midtrans_enabled": false,
		"enets_enabled": false,
		"vnpay_enabled": false,
		"billplz_enabled": false,
		"paystack_enabled": false,
		"square_enabled": false,
		"bangkokbankIpay_enabled": false,
		"bangkokbankQr_enabled": false
	}
];

export default {
	paypalSupportedCurrencies: paypalSupportedCurrencies,
	stripeSupportedCurrencies: stripeSupportedCurrencies,
	allCountryCurrencyData: allCountryCurrencyData
}