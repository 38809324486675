<template>
  <div class="model-sections ion-padding">
    <div class="header-text">Preview</div>
    <div lines="none" v-for="(thisElement, eIndex) in adMessageJsonElements" :key="eIndex"
      class="table-reorder ion-no-padding ion-justify-content-between rowMargin">
      <!-- text -->
      <div class="element-container" v-if="thisElement.type === 'text'">
        <ion-textarea v-model="thisElement.payload" rows="4" auto-grow="true" autofocus="true" class="textarea-box">
        </ion-textarea>
        <div v-if="thisElement.buttons && thisElement.buttons.length" class="addNew" :class="{ complete: thisElement.buttons && thisElement.buttons.length == 3 }">
          <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"
            expand="block" fill="outline"
            @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }"
            class="btn-template-dynamic-action">
            {{ thisButton.text }}
          </div>
        </div>
        <!-- <div v-if="thisElement.buttons && thisElement.buttons.length < 3" class="addNew">
          <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New
          </div>
        </div> -->
      </div>
      <!-- image -->
      <div v-if="thisElement.type === 'image'">
        <div class="img-block">
          <input type="file" style="display: none;" id="image" ref="image" @change="handleImageUpload($event, eIndex)"
            accept="image/png, image/gif, image/jpeg" />
          <img v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200"
            @click="() => $refs.image.click()" class="img-container" />
          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container" />
        </div>
        <div class="upload-img">
          <ion-button @click="() => $refs.image.click()" expand="block" fill="outline">Upload Image</ion-button>
        </div>
      </div>
      <!-- custom card -->
      <div v-if="thisElement.type === 'customCard'">
        <div class="img-block">
          <input type="file" style="display: none;" id="image2" ref="image2" @change="handleImageUpload($event, eIndex)" accept="image/png, image/gif, image/jpeg" />
          <img v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.image2.click()" class="img-container" />
          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"/>
          <div class="upload-img">
            <ion-button @click="() => $refs.image2.click()" expand="block" fill="outline">Upload Image</ion-button>
          </div>
          <ion-input v-model="thisElement.title" rows="4" autofocus="true" placeholder="Enter Title" class="fullBordered input_custom_style"></ion-input>
          <ion-input v-model="thisElement.subtitle" rows="4" autofocus="true" placeholder="Enter Subtitle" class="fullBordered input_custom_style"></ion-input>
          <div v-if="thisElement.buttons && thisElement.buttons.length" class="addNew" :class="{ complete: thisElement.buttons && thisElement.buttons.length == 3 }">
            <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex" expand="block" fill="outline" @click="onclickButton('customCard', bIndex, eIndex)" class="btn-template-dynamic-action">
              {{ thisButton.text }}
            </div>
          </div>
        </div>
      </div>
      <!-- video -->
      <div v-if="thisElement.type === 'video'">
        <div class="img-block">
          <input type="file" style="display: none;" id="video" ref="video" @change="handleVideoUpload($event, eIndex)"
            accept="video/mp4, video/mpeg4" />
          <video v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200"
            @click="() => $refs.video.click()" class="img-container"></video>
          <video v-else
            poster="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/video-only.svg"
            class="img-container"></video>
        </div>
        <div class="upload-img">
          <ion-button @click="() => $refs.video.click()" expand="block" fill="outline">Upload Video</ion-button>
        </div>
      </div>
      <!-- product -->
      <div v-if="thisElement.type === 'product'">
        <div class="img-block">
          <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length"
            :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"
            style="margin: 20px auto 20px auto" />
          <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name"
            class="fullBordered input_custom_style inpt-container"></ion-input>
          <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name"
            class="fullBordered input_custom_style inpt-container"></ion-input>
          <div v-if="thisElement.buttons && thisElement.buttons.length">
            <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"
              class="img-container btn-template-dynamic-action" fill="outline"
              @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }">
              {{ thisButton.text }}
            </div>
          </div>
        </div>
      </div>
      <!-- collection -->
      <div v-if="thisElement.type === 'collection'">
        <div class="img-block">
          <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length"
            :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"
            style="margin: 20px auto 20px auto" />
          <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name"
            class="fullBordered input_custom_style inpt-container"></ion-input>
          <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name"
            class="fullBordered input_custom_style inpt-container"></ion-input>
          <div v-if="thisElement.buttons && thisElement.buttons.length">
            <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"
              class="img-container btn-template-dynamic-action" fill="outline"
              @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }">
              {{ thisButton.text }}
            </div>
          </div>
        </div>
      </div>
      <!-- reply -->
      <div class="element-container" v-if="thisElement.type === 'reply'">
        <ion-textarea v-model="thisElement.payload" rows="4" auto-grow="true" autofocus="true" class="textarea-box"> </ion-textarea>
        <!-- <my-input class="quick-reply" id="template_body" emoji name="template_body" :value="thisElement.payload" @onInput="(prop) => thisElement.payload = prop" placeholder="Enter a Reply" :maxlength="1024" /> -->
        <div class="quick-reply-button-container" v-if="thisElement.buttons && thisElement.buttons.length">
          <div class="quick-reply-buttons-wrapper" :class="{ complete: thisElement.buttons.length == 3 }">
            <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container btn-template-dynamic-action" fill="outline" @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }">
              {{ thisButton.text }}
            </div>
          </div>
          <!-- <div v-if="thisElement.buttons.length" class="quick-reply-add-new-button">
            <div class="btn-reply-add-new" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
          </div> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { IonTextarea } from "@ionic/vue";

export default {
  components: { IonTextarea },
  data() {},
  props: {
    adMessageJsonElements: {
      type: Array,
      default: () => { return []; }
    }
  },
  async ionViewWillEnter(){},
  computed: {},
  methods: {}
}
</script>

<style scoped>
.quick-reply-button-container {
  display: flex;
  column-gap: 10px;
  max-width: 450px;
  overflow-x: scroll;
  padding: 10px 10px;
}

.quick-reply-button-container::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #F5F5F5;
}
.quick-reply-buttons-wrapper {
  display: flex;
  column-gap: 10px;
}
.quick-reply-buttons-wrapper .btn-template-dynamic-action {
  color: #0084ff;
  border: 1px #0084ff solid !important;
  border-radius: 15px;
  cursor: pointer;
  max-width: 180px;
  width: 180px !important;
  height: 30px;
}

.btn-reply-add-new {
  text-align: center;
  display: inline-block;
  width: 130px;
  font-weight: 500;
  background-color: transparent;
  color: #aaa;
  border: 1px #aaa dashed;
  margin-right: 8px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
}

.quick-reply-button-container::-webkit-scrollbar-thumb{
  background-color: var(--primary-theme-color, #3d39b9); 
  background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.header-text {
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}
.element-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 450px;
}

.btn-template-dynamic-action {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px #eee dashed;
  border-bottom: none;
  height: 40px;
  width: 100% !important;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.btn-template-dynamic-action:last-of-type {
  border-bottom: 2px #eee dashed;
}

.img-block .btn-template-dynamic-action {
  border-left: none;
  border-right: none;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  padding-left: 15px;
  padding-bottom: 5px;
}

.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-left: 15px;
}

.sub-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 38px;
}

.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
}


.filterImage {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.model-sections {
  background: var(--ion-color-white);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  padding-left: 20px;
  pointer-events: none;
}

.inpt-container {
  width: 360px;
  margin: 0px 30px 10px 46px;
}

.addRow {
  margin-top: 0;
}

.addRow:hover {
  border: 2px #881fff dashed;
}

.addNew {
  max-width: 450px;
  width: 100%;
}

.addNew.complete {
  /* border-bottom: 2px #eee dashed; */
}
.textarea-box {
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
  color: #262626;
}

.img-block {
  border-radius: 5px;
  border: 2px #eee dashed;
  display: inline-block;
  width: 450px;
}

.jinput {
  background-color: #ffffff;
}

@media only screen and (max-width: 760px) {

  .model-sections,
  .element-container {
    width: calc(100%);
  }

  .upload-img {
    max-width: 453px;
    margin-left: 0px;
  }
}
</style>