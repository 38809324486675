<template>
  <master-layout pageTitle="Dashboard">
    <ion-content>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <div class="gradient_lyr containerPadding_lr">
          <ion-row style="margin-bottom: 52px">
            <ion-col size-xl="12" class="ion-no-padding">
              <ion-text>
                <h1 class="clr_wht highlight_title">
                  Welcome back<span v-if="user.name">, {{ (user.name.split(" "))[0] }} </span> 👋
                </h1>
              </ion-text>

              <div v-if="currentUser_passwordExpiryDays">
                <ion-label class="valignMiddle clr_wht" style="margin-right: 10px;">
                  Your password will expire in {{ currentUser_passwordExpiryDays }}
                  <span v-if="currentUser_passwordExpiryDays == 1">day!</span>
                  <span v-if="currentUser_passwordExpiryDays != 1">days!</span>
                </ion-label>

                <ion-button size="default" color="light" fill="outline" @click="changePwd" class="valignMiddle">
                  Change password
                </ion-button>
              </div>
              <div v-else>
                <ion-label class="valignMiddle clr_wht" style="margin-right: 10px;">
                  <!-- Your account password is not expiring anytime soon! -->
                  Your account password is up-to-date!
                </ion-label>
              </div>
            </ion-col>
          </ion-row>

          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="sales_channels" v-if="socialPlatformConnected">
                <ion-text>
                  <h4 class="clr_wht sub_title">Connected sales channel(s)</h4>
                </ion-text>
                <div ref="boxView_container" id="boxView_container" class="boxView_container">
                  <div class="blockView" lines="full" v-for="thisPage in facebookSocialData.pages" :key="thisPage.id">
                    <!-- @click="() => $router.push('/sales-channel/facebook')" -->
                    <div class="channel_img">
                      <img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/Facebook.png">
                    </div>
                    <!-- @click="() => $router.push('/sales-channel/facebook')" -->
                    <div>
                      <h2 class="channel_title">Facebook</h2>
                      <a target="_blank" :href="'https://www.facebook.com/' + thisPage.messenger" class="common-label"><p>{{ thisPage.name }}</p></a>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/whatsapp')" -->
                  <div class="blockView" lines="full" v-if="whatsappConnected == true">
                    <div class="channel_img">
                      <img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/whatsapp.png">
                    </div>
                    <div>
                      <h2 class="channel_title">WhatsApp</h2>
                      <p style="margin-bottom: 5px" class="common-label">{{ whatsAppSocialData.name }}</p>
                        <a class="hoverUnderline" :href="'https://wa.me/'+ countryCodeWithout_plusSign(whatsAppSocialData.country_code) +''+ whatsAppSocialData.mobile_number" target="_blank" rel="noreferer noopener">{{ whatsAppSocialData.country_code }} {{ whatsAppSocialData.mobile_number }}</a>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/instagram')" -->
                  <div class="blockView" lines="full" v-for="thisPage in instagramSocialData" :key="thisPage.id">
                    <div class="channel_img">
                      <img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/Instagram.png">
                    </div>
                    <div>
                      <h2 class="channel_title">Instagram</h2>
                      <a target="_blank" :href="'https://www.instagram.com/' + thisPage.name" class="common-label"><p>{{ thisPage.name }}</p></a>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/viber')" -->
                  <div class="blockView" lines="full" v-if="viberConnected == true">
                    <div class="channel_img">
                      <img src="https://jumper.ai/landing/resources/images/viber.png">
                    </div>
                    <div>
                      <h2 class="channel_title">Viber</h2>
                      <p class="common-label">{{ viberSocialData.channelname }}</p>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/telegram')" -->
                  <div class="blockView" lines="full" v-if="telegramConnected == true">
                    <div class="channel_img">
                      <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA">
                    </div>
                    <div>
                      <h2 class="channel_title">Telegram</h2>
                      <p class="common-label">{{ telegramSocialData.botusername }}</p>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/line')" -->
                  <div class="blockView" lines="full" v-if="lineConnected == true">
                    <div class="channel_img">
                      <img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/LINE.png">
                    </div>
                    <div>
                      <h2 class="channel_title">LINE</h2>
                      <p class="common-label">{{ lineSocialData.channelname }}</p>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/imessage')" -->
                  <div class="blockView" lines="full" v-if="imessageConnected == true">
                    <div class="channel_img">
                      <img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/iMessage.png">
                    </div>
                    <div>
                      <h2 class="channel_title">iMessage</h2>
                      <p class="common-label">{{ imessageSocialData.name }}</p>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/sms')" -->
                  <div class="blockView" lines="full" v-if="smsConnected == true">
                    <div class="channel_img">
                      <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg">
                    </div>
                    <div>
                      <h2 class="channel_title">SMS</h2>
                      <p class="common-label">{{ smsSocialData.account_name }}</p>
                      <p class="common-label">{{ smsSocialData.countrycode }} {{ smsSocialData.mobile_number }}</p>
                    </div>
                  </div>
                  <!-- @click="() => $router.push('/sales-channel/google-business-messages')" -->
                  <div class="blockView" lines="full" v-if="gBusinessMessagesConnected == true">
                    <div class="channel_img">
                      <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA">
                    </div>
                    <div>
                      <h2 class="channel_title">Google Business Messages</h2>
                      <p class="common-label">{{ gBusinessMessagesSocialData.bot_name }}</p>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 10px" >
                  <ion-row>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
                    <ion-icon @click="scrollContainer(false)" style="color: white; cursor: pointer; font-size: 25px;" :icon="arrowBackOutline"></ion-icon>
                  </ion-col>
                  <ion-col class="text-right" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
                    <ion-icon @click="scrollContainer(true)" style="color: white; cursor: pointer; font-size: 25px;" :icon="arrowForwardOutline"></ion-icon>
                  </ion-col>
                </ion-row>
                </div>
              </ion-col>
              <ion-col v-if="!IS_ASIA" size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                 <h2 class="clr_wht links ion-no-margin"> Quick links</h2>
                  <!-- <ion-col class="text-right" size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
                    <ion-button size="default" color="light" fill="outline">
                      Edit
                    </ion-button>
                  </ion-col> -->
                <ion-grid class="ion-no-padding">
                  <ion-row class="lnkMargin">
                    <ion-col v-if="accountServiceType == 'restaurant'" router-link="/restaurant-orders" size-xl="3" size-lg="3" size-md="3" size-sm="4" size-xs="4" class="cursorPointer rowMargin">
                      <div class="icon_container">
                        <ion-icon :icon="listOutline"></ion-icon>
                      </div>
                      <h2 class="boxTitle clr_wht" style="margin-bottom: 15px">View orders</h2>
                    </ion-col>
                    <ion-col v-if="accountServiceType != 'restaurant'" router-link="/orders" size-xl="3" size-lg="3" size-md="3" size-sm="4" size-xs="4" class="cursorPointer">
                      <div class="icon_container">
                        <ion-icon :icon="listOutline"></ion-icon>
                      </div>
                      <h2 class="boxTitle clr_wht">View orders</h2>
                    </ion-col>
                    <ion-col v-if="(isAdmin || oldPermissions.page_inbox) && redRibbon_ph_manila != true" router-link="/page-inbox" size-xl="3" size-lg="3" size-md="3" size-sm="4" size-xs="4" class="cursorPointer">
                      <div class="icon_container">
                        <ion-icon :icon="chatbubblesOutline"></ion-icon>
                      </div>
                      <h2 class="boxTitle clr_wht">Go to live chat</h2>
                    </ion-col>
                    <ion-col v-if="accountServiceType != 'restaurant'" router-link="/store" size-xl="3" size-lg="3" size-md="3" size-sm="4" size-xs="4" class="cursorPointer">
                      <div class="icon_container">
                        <ion-icon :icon="storefrontOutline"></ion-icon>
                      </div>
                      <h2 class="boxTitle clr_wht">Manage store</h2>
                    </ion-col>
                    <ion-col size-xl="3" size-lg="3" size-md="3" size-sm="0" size-xs="0">
                    </ion-col>
                    <ion-col router-link="/settings/team" size-xl="3" size-lg="3" size-md="3" size-sm="4" size-xs="4" class="cursorPointer">
                      <div class="icon_container">
                        <ion-icon :icon="peopleCircleOutline"></ion-icon>
                      </div>
                      <h2 class="boxTitle clr_wht">Team members</h2>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="blog_pad containerPadding_lr">
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
              <ion-row>
                <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
                  <ion-text>
                    <h4 class="sub_title" style="margin-bottom: 10px;">CSV/Excel exports</h4>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size-xl="11" size-lg="11" size-md="11" size-sm="12" size-xs="12">
                  <div class="csvExportsListContainer">
                    <ion-item v-if="!IS_ASIA && ((accountServiceType == 'product' || (redRibbon_ph_manila == true || user.enterprise_Name === 'walls_my')) && user.sellerproducttype.indexOf('brand_com_marketplace') == -1)" lines="full" button @click="csvExportWithDateRangeModal_open('orders','Export orders')">
                      <ion-label>
                        Orders
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="loreal_my_family_user == true" @click="csvExportWithDateRangeModal_open('BAReport','Export BA sales report')">
                      <ion-label>
                        BA sales report
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button @click="csvExportWithDateRangeModal_open('customers','Export customers')">
                      <ion-label>
                        Customers
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button @click="csvExportWithDateRangeModal_open('UserRoles','Export team member\'s list')">
                      <ion-label>
                        Team member's list
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name === 'lbc'" @click="csvExportWithDateRangeModal_open('LBCCRMDataExport','CRM export')">
                      <ion-label>
                        CRM export
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name === 'lbc'" @click="csvExportWithDateRangeModal_open('LBCCustomerFeedback','Customers feedback')">
                      <ion-label>
                        Customers feedback
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'jnj_acom_ph'" @click="csvExportWithDateRangeModal_open('policyexport','Customer audit for privacy policy consent')">
                      <ion-label>
                        Customer audit for privacy policy consent
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'smretail_ph'" @click="csvExportWithDateRangeModal_open('agentexport','Download agent performance data')">
                      <ion-label>
                        Download agent performance data
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'fairprice'" @click="csvExportWithDateRangeModal_open('FPUnityBotExport','Export bot data')">
                      <ion-label>
                        Export bot data
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'elc_family_th_lamer' || samsung_family_MerchantBool == true || user.enterprise_Name == 'rb_acom_ph'" @click="csvExportWithDateRangeModal_open('UserChatLog','User chat log')">
                      <ion-label>
                        User chat log
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'elc_family_th_lamer' || samsung_family_MerchantBool == true || user.enterprise_Name == 'rb_acom_ph'" @click="csvExportWithDateRangeModal_open('lamerth_order','Order - paid and unpaid')">
                      <ion-label>
                        Order - paid and unpaid
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'elc_family_th_lamer' || samsung_family_MerchantBool == true" @click="csvExportWithDateRangeModal_open('lamerth_adevent','Ad events')">
                      <ion-label>
                        Ad events
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="user.enterprise_Name == 'rb_acom_ph'" @click="csvExportWithDateRangeModal_open('metaadevents','Ad events')">
                      <ion-label>
                        Ad events
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="alcon_family_user == true" @click="csvExportWithDateRangeModal_open('AlconMYSG_Raw','Alcon raw report (bot)')">
                      <ion-label>
                        Alcon raw report (bot)
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="alcon_family_user == true" @click="csvExportWithDateRangeModal_open('AlconMYSG_Summary','Alcon summary report (bot)')">
                      <ion-label>
                        Alcon summary report (bot)
                      </ion-label>
                    </ion-item>
                    <ion-item lines="full" button v-if="samsung_family_MerchantBool == true" @click="csvExportWithDateRangeModal_open('SamsungThTickets','Conversation Report')">
                      <ion-label>
                        Conversation Report
                      </ion-label>
                    </ion-item>
                  </div>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
    <exports-modal :type="csvExportWithDateRangeModal_type" :isOpen="csvExportWithDateRangeModal" :title="csvExportWithDateRangeModal_title" @close="() => csvExportWithDateRangeModal = false"></exports-modal>
  </master-layout>
</template>

<script>
import '/src/resources/css/dashboard.css';
import { mapGetters } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, listOutline, chatbubblesOutline, storefrontOutline, peopleCircleOutline, arrowBackOutline, arrowForwardOutline, close } from 'ionicons/icons';
import { Preferences as Storage } from "@capacitor/preferences";
import exportsModal from '/src/components/exportsModal.vue';
import { environment } from '/src/shared/env';

export default {
  components: {
    exportsModal
  },
  data(){
    return {
      loading: false,
      currentUser_passwordExpiryDays: undefined,
      allEmails: [],
      chevronDownCircleOutline,
      listOutline,
      chatbubblesOutline,
      storefrontOutline,
      peopleCircleOutline,
      arrowBackOutline,
      arrowForwardOutline,
      close,
      csvExportWithDateRangeModal: false
    }
  },
  async ionViewWillEnter(){
    try {
      this.loading = true;
      let jmpr_redirect_url = (await Storage.get({ key: "jmpr_redirect_url" })).value || undefined;
      if(jmpr_redirect_url) {await Storage.remove({ key: "jmpr_redirect_url" }); this.$router.push({ path: jmpr_redirect_url });}
      let pwdResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "show-expire-password");
      if ((pwdResponse.status == 200 || pwdResponse.status == 201) && pwdResponse.data) {
        if(pwdResponse.data.success) {
          if(pwdResponse.data.expiry && typeof pwdResponse.data.days_left != 'undefined') {
            this.currentUser_passwordExpiryDays = parseInt(pwdResponse.data.days_left);
            if(this.currentUser_passwordExpiryDays < 0) this.currentUser_passwordExpiryDays = 0;
          } else {
            console.log("Password expiry not set");
          }
        } else if(pwdResponse.data.errorMessage && pwdResponse.data.errorMessage.length) {
          this.updateToast({
            open: true,
            message: pwdResponse.data.errorMessage,
            type: "danger"
          })
        } else {
          console.log("Response:", pwdResponse);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } else {
        console.log("Response:", pwdResponse);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      }

      //save timezone in add-user API - start
      if(this.accountType_master == true && (this.user.timezone == null || this.user.timezone == 'None' || this.user.timezone == '')){
        let reqBody = {
          timezone: this.myTimeZone
        }
        let timezoneSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-user", reqBody);
        if ((timezoneSaveResponse.status == 200 || timezoneSaveResponse.status == 201) && timezoneSaveResponse.data.success) {
          this.user.timezone = this.myTimeZone;
          await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
          window.location.href = window.location.pathname;
        }
      }
      //save timezone in add-user API - end
    } catch (error) {
      console.log("Exception", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData",
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
  },
  methods: {
    environment: environment,
    scrollContainer(right) {
      if(right) document.getElementById('boxView_container').scrollLeft += 200
      else document.getElementById('boxView_container').scrollLeft -= 200
    },
    changePwd() {
      this.$router.push('/change-password');
    }
  }
};
</script>

<style scoped>
.sub_title {
  font-size: 20px;
  font-weight: 700;
  margin: 9px 0px 30px 0px;
}

.links {
   font-size: 20px;
   font-weight: 700;
   margin: 10px 0px 30px 120px;
}
.blogTitle{
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
  margin-top: 9px;
}

.blogDate {
  color: #3DC2FF;
  font-weight: 700;
  font-size: 14px;
}
ion-card{
  --background: var(--ion-color-white);
  box-shadow: none;
  border-radius: 0px;
}

.lnkMargin {
  margin-left: 110px;
}
@media only screen and (max-width: 760px){
  .links{
   margin: 10px 0px 30px 0px;
  }
  .lnkMargin {
    margin-left: 0px;
  }
}

</style>