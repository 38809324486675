<template>
  <master-layout pageTitle="Bot Builder">
    <ion-content>
      <iframe style="width: 100%; height: calc(100% - 56px); border: none;" :src="iframeUrl" title="Bot builder"></iframe>
    </ion-content>
  </master-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    }),
    iframeUrl() {
      return this.environment('VUE_APP_ROOT') + 's/bot-builder-v4-iframe?convuser='+ this.authData.token +'&uid='+ this.authData.authkey;
    }
  }
}
</script>