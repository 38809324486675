<template>
  <master-layout pageTitle="Facebook Opt-in Notification">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="ion-hide-md-up toolbar">
          <ion-segment scrollable v-model="activeTab" class="segment">
            <ion-segment-button value="notifications" class="segmentBar">
              <ion-label class="common-label">Notifications</ion-label>
            </ion-segment-button>
            <ion-segment-button value="preview" class="segmentBar">
              <ion-label class="common-label">Preview</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <ion-grid class="ion-no-padding notification-container">
          <ion-row class="ion-no-padding">
            <ion-col size-xl="7.5" size-lg="7.5" size-md="7.5" size-sm="12" size-xs="12" class="model-sections custMarginRight ion-no-padding"> 
              <div v-if="activeTab === 'notifications'">
                <div class="previewHeader">
                  <ion-label>Notification opt-in</ion-label>
                </div>
                <div class="addedNotifications sectionPadding">
                  <ion-accordion-group>
                    <ion-accordion class="listAccordion" v-for="thisNotification in allNotifications" :key="thisNotification.notificationid">
                      <div slot="header" class="notificationItems" @click="() => { addEditMode = true; displayNotifications(thisNotification); countSubscribers(); }">
                        <div>
                          <p class="notification-title ion-no-margin">{{ thisNotification.title }}</p>
                          <ion-label class="notification-subtitle"> {{ thisNotification.button_title }}</ion-label>
                        </div>
                        <div slot="end">
                          <ion-button fill="clear" size="default" color="dark">
                            <ion-icon :icon="createOutline"></ion-icon>
                          </ion-button>
      
                          <ion-button @click="deleteNotification(thisNotification.notificationid)" fill="clear" size="default" color="danger">
                            <ion-icon :icon="trashBinOutline"></ion-icon>
                          </ion-button>
                        </div>
                      </div>
                      <div slot="content" class="addContainer" v-if="addEditMode">   <!-- edit/add notification opt-in -->
                          <!-- Edit/Add Notifications -->
                        <AddNotificationOptIn
                          :key="thisNotification.notificationid" 
                          :dummyNotification="dummyNotification"
                          :uploadingImage="uploadingImage"
                          :loading="loading"
                          :commonLoader="commonLoader"
                          :allNotifications="allNotifications"
                          :addEditMode="addEditMode"
                          v-on:update:add-edit-mode="onAddEditModeUpdate"
                          @handleImageUpload="handleImageUpload"
                          @addEditNotification="addEditNotification"
                          v-on:update:dummyNotification="onFormDataUpdate"
                        /> 
                      </div>
                    </ion-accordion>
                  </ion-accordion-group>
                  <div class="text-center img-container" v-if="allNotifications.length == 0 && !addEditMode">
                    <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" width="300" />
                    <h5>No notification opt-ins yet! 😟</h5>
                    <ion-text color="medium">Seems like there are no notifications opt-ins added yet, click the plus button to add one</ion-text>
                  </div>
                </div>
                <!-- Add a New notification Opt-in -->
                <div class="sectionPadding">
                  <ion-button fill="outline" size="default" class="bttnPrimary" expand="block" @click="() => { addEditMode = true; dummyNotification = JSON.parse(JSON.stringify(newNotification)); addNewNotification = true }"> 
                    + Add a new notification opt-in
                  </ion-button>
                </div>
                <div class="addContainer" v-if="addNewNotification">
                  <!-- Edit/Add Notifications -->
                  <AddNotificationOptIn 
                    :key="dummyNotification.id"
                    :dummyNotification="dummyNotification"
                    :uploadingImage="uploadingImage"
                    :loading="loading"
                    :commonLoader="commonLoader"
                    :allNotifications="allNotifications"
                    :addEditMode="addEditMode"
                    :addNewNotification="addNewNotification"
                    v-on:update:add-edit-mode="onAddEditModeUpdate"
                    @handleImageUpload="handleImageUpload"
                    @addEditNotification="addEditNotification"
                  /> 
                </div>
              </div>
            </ion-col>
            <!-- preview -->
            <ion-col v-if="allNotifications.length > 0 && addEditMode" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="model-sections ion-no-padding">
              <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop'">
                <div class="previewHeader">
                  <ion-label>Preview</ion-label>
                </div>
                <div class="sectionPadding flexContainer">     
                  <div class="carouselItems">
                    <img v-if="dummyNotification.imageurl !== undefined" class="imageContainer" :src="dummyNotification.imageurl" />
                    <img v-else class="imageContainer" src="https://jumperdevnew.appspot.com/web-s/resources/images/cart-recovery-dummy-product.png" />
                    <div class="carouselItemsDataArea">
                      <h5 class="preview-title ion-no-margin">{{ dummyNotification.title !== undefined ? dummyNotification.title :'Enter card title'}}</h5>
                      <p class="notification-subtitle ion-no-margin">Want to get additional messages from us? You can opt out at any time.</p>
                    </div>
                    <div>
                      <button type="button" class="singleCardBigButton">{{ dummyNotification.button_title !== undefined ? dummyNotification.button_title : 'Allow messages' }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, trashBinOutline,addCircleOutline, close, createOutline, imageOutline, peopleOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import {  IonAccordion, IonAccordionGroup } from "@ionic/vue";
import AddNotificationOptIn from "../../../components/add-notification-opt-in.vue"


export default {
  components: { IonAccordion, IonAccordionGroup, AddNotificationOptIn },
  data() {
    return {
      loading: false,
      close,
      chevronDownCircleOutline,
      trashBinOutline,
      addCircleOutline,
      createOutline,
      imageOutline,
      peopleOutline,
      allNotifications: [],
      deleteAllMode: false,
      addEditMode: false,
      dummyNotification: {
        button_title: 'Get messages',
        imageurl: undefined,
        notificationid: undefined,
        notificationtype: undefined,
        title: undefined,
        subscribers: undefined,
        image_aspect_ratio: 'HORIZONTAL'
      },
      newNotification: {
        button_title: 'Get messages',
        imageurl: undefined,
        notificationid: undefined,
        notificationtype: undefined,
        title: undefined,
        image_aspect_ratio: 'HORIZONTAL'
      },
      addNewNotification: false,
      uploadingImage: false,
      activeTab: 'notifications',
      commonLoader: true,
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  methods: {
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchNotifications();
    },
    async fetchNotifications() {
      try {
        this.loading = true;
        const allNotificationsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "notification-template-fetch");
        if ((allNotificationsResponse.status == 200 || allNotificationsResponse.status == 201) && allNotificationsResponse.data.success) {
          this.allNotifications = allNotificationsResponse.data.notification_templates;
        } else {
          if(allNotificationsResponse.data.errorMessage && allNotificationsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: allNotificationsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async handleImageUpload (event) {
      try {
        this.uploadingImage = true;
        if(event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          if(await this.validateImage(newImage, 1)) {
            let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
            if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
              this.dummyNotification.imageurl = uploadSendResponseData.url;
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong uploading the image",
                type: "danger"
              })
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.uploadingImage = false;
      }
    },
    displayNotifications(thisNotification){
      this.dummyNotification = JSON.parse(JSON.stringify(thisNotification))
    },
    async addEditNotification() {
      try {
        this.commonLoader = false;
        let reqBody = {
          pageid: this.instagramSocialData[0].pageid,
          notificationid: this.dummyNotification.notificationid ?  this.dummyNotification.notificationid : undefined,
          notificationtype: this.dummyNotification.notificationtype ?  this.dummyNotification.notificationtype : undefined,
          title:  this.dummyNotification.title,
          imageurl:  this.dummyNotification.imageurl,
          button_title:  this.dummyNotification.button_title,
          image_aspect_ratio: this.dummyNotification.image_aspect_ratio
        }
        if(this.dummyNotification.title == '' || this.dummyNotification.title.length == 0) {
          this.updateToast({
            open: true,
            message: "Please add a valid title",
            type: "warning"
          })
          this.addEditMode = true;
          return;
        } else if(!this.dummyNotification.image_aspect_ratio || !this.dummyNotification.image_aspect_ratio){
           this.updateToast({
            open: true,
            message: "Please add Image aspect ratio for Notification",
            type: "warning"
          })
          this.addEditMode = true;
          return;
        } else if(!this.dummyNotification.button_title || !this.dummyNotification.button_title){
           this.updateToast({
            open: true,
            message: "Please add button Type for Notification",
            type: "warning"
          })
          this.addEditMode = true;
          return;
        } else if(!this.dummyNotification.imageurl || !this.dummyNotification.imageurl){
           this.updateToast({
            open: true,
            message: "Please add Image",
            type: "warning"
          })
          this.addEditMode = true;
          return;
        }
        else {
          const saveNotificationResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "notification-template-create", reqBody);
          if ((saveNotificationResponse.status == 200 || saveNotificationResponse.status == 201) && saveNotificationResponse.data.success) {
            let thisNotification = this.allNotifications.find(e => e.notificationid === saveNotificationResponse.data.notificationid);
            if(thisNotification) for(var prop in thisNotification) thisNotification[prop] = saveNotificationResponse.data[prop];
            else this.allNotifications.push({ 
              button_title: saveNotificationResponse.data.button_title,
              imageurl: saveNotificationResponse.data.imageurl,
              notificationid: saveNotificationResponse.data.notificationid,
              notificationtype: saveNotificationResponse.data.notificationtype,
              title: saveNotificationResponse.data.title,
              image_aspect_ratio: saveNotificationResponse.data.image_aspect_ratio
            })
            this.updateToast({
              open: true,
              message: reqBody.notificationid ? 'Updated notification successfully' : 'Added notification successfully',
              type: "success"
            })
            this.dummyNotification = JSON.parse(JSON.stringify(this.newNotification));
            this.addEditMode = false;
            this.addNewNotification = false;
          } else {
            if(saveNotificationResponse.data.errorMessage && saveNotificationResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: saveNotificationResponse.data.errorMessage,
                type: "danger"
              })
              this.dummyNotification = JSON.parse(JSON.stringify(this.newNotification));
              this.addEditMode = false;
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          } 
        }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        } 
        finally {
          this.commonLoader = true;
        }
      },
    async deleteNotification(id) {
      try {
        this.loading = true;
        const deleteNotificationResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "notification-template-delete", {notificationid: id});
        if ((deleteNotificationResponse.status == 200 || deleteNotificationResponse.status == 201) && deleteNotificationResponse.data.success) {
          let thisNotificationIndex = this.allNotifications.find(e => e.notificationid == id);
          this.allNotifications.splice(thisNotificationIndex, 1);
          this.updateToast({
            open: true,
            message: "Notification deleted successfully",
            type: "success"
          })
        } else {
          if(deleteNotificationResponse.data.errorMessage && deleteNotificationResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: deleteNotificationResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.dummyNotification = JSON.parse(JSON.stringify(this.newNotification));
        this.addEditMode = false;
        this.loading = false;
      }
    },
    async countSubscribers() {
      try {
        if(!this.dummyNotification.notificationtype || this.dummyNotification.notificationtype === undefined) return;
        const countSubscriberResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "count-subscribed", {notificationtype: this.dummyNotification.notificationtype});
        if ((countSubscriberResponse.status == 200 || countSubscriberResponse.status == 201) && countSubscriberResponse.data.success) {
          this.dummyNotification.subscribers = countSubscriberResponse.data.subscribed_count;
        } else {
          if(countSubscriberResponse.data.errorMessage && countSubscriberResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: countSubscriberResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    formatUrl(url){
      const maxLength = 50; // adjust as needed
      if (url.length <= maxLength) {
        return url;
      }
      return url.slice(0, maxLength) + '...';
    },
    onFormDataUpdate(newVal) {
      this.dummyNotification = newVal;
    },
    onAddEditModeUpdate(newVal) {
      this.addNewNotification = newVal;
      this.addEditMode = newVal;
    }
  }
}
</script>

<style scoped>
.addContainer{
  border: 1px solid var(--background-primary);
  padding: 20px;
}
.notification-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  padding-bottom: 5px;
}
.notification-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-04);
}
.model-sections {
  background: var(--white);
  border-radius: 5px;
}
.sectionPadding{
  padding: 15px 20px;
}
.custMarginRight{
  margin-right: 20px;
}
.notification-container{
  margin: 20px;
}
.previewHeader{
  border-bottom: 1px solid var(--background-primary);
  color: var(--black);
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 20px;
}
.sectionPadding{
  padding: 15px 20px;
}
ion-button.bttnPrimary{
  --background: var(--purple);
  color: var(--white);
  --border-radius: 5px;
  --border-width: 0px;
}
.notificationItems{
  background: var(--background-primary);
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.listAccordion{
  margin-bottom: 10px;
  border-radius: 5px;
}
.addedNotifications{
  margin-bottom: 0px;
  overflow: hidden;
  overflow-y: auto; 
}
.img-container {
  width: 50%;
  display: block;
  margin: 20px auto 20px auto;
}
.img-container img{
  border-radius: 5px;
}
.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}
ion-segment.segment {
  --background: var(--white);
  --ion-color-base: var(--purple) !important;
}
.segmentBar{
  text-transform: none;
  --color-checked: var(--purple) !important;
  --color: var(--black);
}
.carouselItems{
  max-width: calc(100% - 70px);
  width: 250px;
  background-color: var(--white);
  display: inline-block;
  border: 1px solid var(--background-primary);
  border-radius: 5px;
}
.imageContainer{
  width: 250px;
  border-bottom: 1px solid var(--background-primary);
  border-radius: 5px 5px 0px 0px;
  padding: 20px;
}
.carouselItemsDataArea{
  padding: 10px;
}
.singleCardBigButton{
  width: 100%;
  font-size: 14px;
  padding: 10px 2px 10px;
  background-color: var(--white);
  color: var(--purple);
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid var(--background-primary);
}
ion-button.bttnOutline{
  --background: var(--white);
  color: var(--purple);
  --border-radius: 5px;
  --border-width: 1px;
  --border-color: var(--purple);
}
.preview-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  padding-bottom: 5px;
}
.sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-04);
}
.flexContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 35px 0px;
}
@media only screen and (max-width: 760px) {
  .prevMobile {
    display: block;
  }
  .prevDesktop {
      display: none;
  }
}

</style>