<template>
<master-layout pageTitle="Store">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    ></ion-loading>
    <div v-else>
    <ion-toolbar class="chat-toolbar">
      <ion-buttons slot="start">
        <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline"></ion-icon>
        <h2 class="prdt-title ion-no-margin">{{ (dummyDiscount.id ? 'Edit' : 'Add') + ' Discount' }}</h2>
      </ion-buttons>
      <ion-buttons slot="end" class="paddingBox">
          <ion-button @click="updateDiscount" color="white" class="bttn-bgnd">Save discount</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size-xl="7" size-lg="7" size-md="12" size-sm="12" size-xs="12">
          <ion-accordion-group value="addCollections" class="set-collections">
            <ion-accordion class="model-sections" value="addCollections">
              <ion-item slot="header" lines="none">
                <ion-text class="disc-header">
                  Discount Details
                </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding">
                <ion-grid class="card_container_grid ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding">
                      <div class="prdct-section">
                        <h2 class="ion-no-padding sctn-labels ion-no-margin">Discount code</h2>
                          <ion-input type="text" placeholder="Eg.: GET50" class="fullBordered input_custom_style sctn-input" v-model="dummyDiscount.name"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-no-padding">
                      <h2 class="ion-no-padding sctn-labels ion-no-margin" style="padding-top: 12px">Discount Type</h2>
                    </ion-col>
                    <ion-col>
                        <ion-select :interface-options="slctDiscountOptions" class="slctContainer" v-model="discountType" ok-text="Okay" cancel-text="Dismiss">
                          <ion-select-option value="undefined" disabled>Select Discount type</ion-select-option><!-- couponapplied: true and automatic: false -->
                          <ion-select-option :value="'automatic'">Automatic (Auto apply discount)</ion-select-option><!-- couponapplied: true and automatic: false -->
                          <ion-select-option :value="'manual'">Manual (Shopper needs to enter coupon code)</ion-select-option><!-- couponapplied: false and automatic: false -->
                          <ion-select-option :value="'agent'">Agent only (Agent applied discount)</ion-select-option><!-- couponapplied: 'automatic' and automatic: true -->
                        </ion-select>
                    </ion-col>
                  </ion-row>
                </ion-grid>  
                <div class="prdct-section descContainer">
                  <h2 class="ion-no-padding sctn-labels ion-no-margin">Short Description</h2>
                    <ion-textarea v-model="dummyDiscount.description" rows="4" placeholder="Discount description" class="fullBordered input_custom_style text_area"></ion-textarea>
                </div>
              </ion-list>
            </ion-accordion>
            <ion-accordion class="model-sections" value="addCollections">
              <ion-item slot="header" lines="none">
                <ion-text class="disc-header">
                  Discount Type
                </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding">
              <div class="card_container_grid">
              <div class="prdct-section">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="2" size-sm="2" size-xs="2">
                      <h2 class="common-label ion-no-padding ion-no-margin">Type</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding text-right" size-xl="8" size-lg="8" size-md="10" size-sm="10" size-xs="10">
                      <ion-radio-group v-model="dummyDiscount.type">
                        <ion-grid class="ion-no-padding">
                          <ion-row>
                            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4" class="ion-no-padding">
                              <ion-item lines="none">
                                <h2 class="ion-no-padding orderDesc ion-no-margin"> &#x25; OFF</h2>
                                <ion-radio slot="start" value="percentage" class="radio-bttns"></ion-radio>
                              </ion-item>
                            </ion-col>
                            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4" class="ion-no-padding">
                              <ion-item lines="none">
                                <h2 class="ion-no-padding orderDesc ion-no-margin">Flat off</h2>
                                <ion-radio slot="start" value="amount" class="radio-bttns"></ion-radio>
                              </ion-item>
                            </ion-col>
                            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4" class="ion-no-padding">
                              <ion-item lines="none">
                                <h2 class="ion-no-padding orderDesc ion-no-margin">Free item</h2>
                                <ion-radio slot="start" value="item" class="radio-bttns"></ion-radio>
                              </ion-item>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-radio-group>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>

              <div v-if="dummyDiscount.type === 'percentage'" class="section_style_v1">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="12" size-sm="12" size-xs="12">
                      <h2 class="common-label ion-no-padding ion-no-margin ion-hide-md-down">Off percentage</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="12" size-sm="12" size-xs="12">
                      <span class="common-label pad-left">
                        Percentage off
                      </span>
                      <div class="post_text_cont cust_pad_v1">
                        <ion-input v-model="dummyDiscount.upperpercentagelimit" type="text" placeholder="Eg. 10" class="fullBordered input_custom_style"></ion-input>
                        <span class="post_text"> &#x25;</span>
                      </div>
                    </ion-col>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="12" size-sm="12" size-xs="12">
                      <span class="common-label pad-left">
                        Capped at
                      </span>
                      <div class="pre_text_cont cust_pad_v1">
                        <span class="pre_text">
                          {{ user.currency }}
                        </span>
                        <ion-input v-model="dummyDiscount.upperoffmoney" type="text" placeholder="Eg. 10" class="fullBordered input_custom_style"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>

              <div v-if="dummyDiscount.type === 'amount'" class="section_style_v1">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                      <h2 class="common-label ion-no-padding ion-no-margin pad-bottm">Off amount</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="6" size-sm="12" size-xs="12">
                      <div class="pre_text_cont">
                        <span class="pre_text">
                          {{ user.currency }}
                        </span>
                        <ion-input v-model="dummyDiscount.offmoney" type="text" placeholder="Eg.: 500" class="fullBordered input_custom_style"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>

              <div v-if="dummyDiscount.type === 'item'" class="section_style_v1">
                <ion-grid class="ion-no-padding">
                  <ion-row class="rowMargin">
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                      <h2 class="common-label ion-no-padding ion-no-margin pad-bottm">Select free items</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <div @click="() => selectFreeItemsModal = true" class="post_text_cont">
                        <ion-input type="text" disabled :placeholder="multipleProducts.length + ' item(s) selected'" class="fullBordered input_custom_style"></ion-input>
                        <span class="post_text brdr_clr_primary txt_clr_primary button">
                          Select
                        </span>
                      </div>
                      <div v-if="multipleProducts.length" class="sctItems">
                        <span @click="() => showFreeItems = true" class="txt_clr_primary" style="cursor: pointer" >View selected Items</span>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                      <h2 class="common-label ion-no-padding ion-no-margin pad-bottm">Maximum quantity</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <div class="">
                        <div class="post_text_cont">
                          <ion-input v-model="dummyDiscount.max_quantity" type="text" placeholder="Eg.: 2" class="fullBordered input_custom_style"></ion-input>
                          <span class="post_text">
                            out of 0
                          </span>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>

              <div v-if="!discountOnAll && dummyDiscount.shipping === 'false'" class="section_style_v1">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                      <h2 class="label_custom_style ion-no-margin">Apply discount at</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <ion-radio-group v-model="dummyDiscount.order">
                        <ion-grid class="ion-no-padding">
                          <ion-row>
                            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="6" class="ion-no-padding">
                              <ion-item>
                                <ion-label>Order level</ion-label>
                                <ion-radio slot="start" :value="'true'"></ion-radio>
                              </ion-item>
                            </ion-col>
                            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="6" class="ion-no-padding">
                              <ion-item>
                                <ion-label>Item level</ion-label>
                                <ion-radio slot="start" :value="'false'"></ion-radio>
                              </ion-item>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-radio-group>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>

              <div class="section_style_v1">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col class="ion-no-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
                      <h2 class="ion-no-padding common-label ion-no-margin">Apply on shipping</h2>
                    </ion-col>
                    <ion-col class="ion-no-padding text-right" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
                      <ion-checkbox v-model="dummyDiscount.shipping" style="margin-right: 12px"></ion-checkbox>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </ion-list>
            </ion-accordion>
            <ion-accordion class="model-sections" value="addCollections">
              <ion-item slot="header" lines="none">
                <ion-text class="disc-header" >
                  Discounts
                </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding">
                <ion-card-header>
                  <div class="section_style_v1">
                    <ion-grid class="ion-no-padding">
                      <ion-row class="rowMargin">
                        <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <h2 class="ion-no-padding common-label ion-no-margin pad-bottm">Order amount</h2>
                        </ion-col>
                        <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <span class="common-label pad-left">
                            Minimum:
                          </span>
                          <div class="pre_text_cont cust_pad_v1">
                            <span class="pre_text">
                              {{ user.currency }}
                            </span>
                            <ion-input type="text" v-model="dummyDiscount.ticketsize" placeholder="Eg. 10" class="fullBordered input_custom_style"></ion-input>
                          </div>
                        </ion-col>
                        <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <span class="common-label  pad-left" >
                            Maximum:
                          </span>
                          <div class="pre_text_cont cust_pad_v1">
                            <span class="pre_text">
                              {{ user.currency }}
                            </span>
                            <ion-input type="text" v-model="dummyDiscount.maxticketsize" placeholder="No limit" class="fullBordered input_custom_style"></ion-input>
                          </div>
                        </ion-col>
                      </ion-row>
                        <ion-row class="rowMargin">
                        <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <h2 class="common-label ion-no-padding ion-no-margin">Applies to</h2>
                        </ion-col>
                        <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                          <ion-radio-group v-model="discountOnAll">
                            <ion-grid class="ion-no-padding">
                              <ion-row>
                                <ion-col class="ion-no-padding">
                                  <ion-item lines="none">
                                    <h2 class="ion-no-padding orderDesc ion-no-margin">All items</h2>
                                    <ion-radio slot="start" :value="true" class="radio-bttns"></ion-radio>
                                  </ion-item>
                                </ion-col>
                                <ion-col class="ion-no-padding">
                                  <ion-item lines="none">
                                    <h2 class="ion-no-padding orderDesc ion-no-margin">Selected item</h2>
                                    <ion-radio slot="start" :value="false" class="radio-bttns"></ion-radio>
                                  </ion-item>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </ion-radio-group>
                        </ion-col>
                      </ion-row>
                      <ion-row v-if="discountOnAll" class="rowMargin">
                        <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <h2 class="common-label ion-no-padding ion-no-margin pad-bottm">Exclude items</h2>
                        </ion-col>

                        <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                          <div @click="() => selectExcludeItemsModal = true" class="post_text_cont">
                            <ion-input type="text" disabled :placeholder="dummyDiscount.excludedProducts.length + ' item(s) selected'" class="fullBordered input_custom_style"></ion-input>
                            <span class="post_text brdr_clr_primary txt_clr_primary button">
                              Select
                            </span>
                          </div>
                          <div v-if="dummyDiscount.excludedProducts.length" class="slctItems">
                            <span @click="() => showExcludedItems = true" class="txt_clr_primary" style="cursor: pointer">View selected Items</span>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row v-else class="rowMargin">
                        <ion-col class="ion-no-padding ion-align-self-center" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                          <h2 class="common-label ion-no-padding ion-no-margin pad-bottm">Select items</h2>
                        </ion-col>
                        <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                          <div @click="() => selectDiscountedItemsModal = true" class="post_text_cont">
                            <ion-input type="text" disabled :placeholder="dummyDiscount.products.length + ' item(s) selected'" class="fullBordered input_custom_style"></ion-input>
                            <span class="post_text brdr_clr_primary txt_clr_primary button">
                              Select
                            </span>
                          </div>
                            <div v-if="dummyDiscount.products.length > 0" style="margin-top: 10px; margin-bottom: 10px">
                            <span @click="() => showDiscountedItems = true" class="txt_clr_primary" style="cursor: pointer">View selected Items</span>
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                </ion-card-header>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>             
        </ion-col>

        <ion-col size-xl="5" size-lg="5" size-md="12" size-sm="12" size-xs="12">
          <ion-accordion-group value="addCollections" class="set-collections">
            <ion-accordion  class="model-sections" value="addCollections">
              <ion-item slot="header" lines="none">
                <ion-text class="disc-header">
                  Validity
                </ion-text>
              </ion-item>
              <ion-list slot="content" lines="none" class="ion-no-padding">
                <ion-grid class="card_container_grid ion-no-padding">
                  <ion-row class="rowMargin">
                    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="5" class="ion-no-padding">
                      <h2 class="inputLabel ion-no-margin" for="startDate" @click="$refs.openStartDateInput.click()">Start Date</h2>
                      <ion-item lines="none" class="item-padding">
                        <ion-input @focus="$refs.openStartDateInput.click()" :value="dummyDiscount.validfrom ? moment(dummyDiscount.validfrom).format('DD/MM/YYYY'): undefined" id="startDate" class="fullBordered input_custom_style inputStyle"></ion-input>
                        <ion-button id="openStartDateInput" ref="openStartDateInput" slot="end" fill="clear" style="margin-left: 0px">
                          <ion-icon slot="end" :icon="calendarClearOutline"></ion-icon>
                        </ion-button>
                        <ion-popover class="start-date" size="auto" slot="end" side="bottom" trigger="openStartDateInput">
                          <ion-datetime size="cover" :min="moment().endOf('day').format()" :max="moment().add(20, 'years').format()" :show-default-buttons="false" :value="dummyDiscount.validfrom" @ionChange="onStartDateChange" presentation="date" display-format="DD/MM/YY"></ion-datetime>
                        </ion-popover>
                      </ion-item>
                    </ion-col>
                    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="5" class="ion-no-padding">
                      <h2 class="inputLabel ion-no-margin" for="endDate" @click="$refs.openEndDateInput.click()">End Date</h2>
                      <ion-item lines="none">
                        <ion-input :disabled="validTillNotRequired" @focus="$refs.openEndDateInput.click()" :value="dummyDiscount.validtill ? moment(dummyDiscount.validtill).format('DD/MM/YYYY'): undefined" id="endDate" class="fullBordered input_custom_style inputStyle"></ion-input>
                        <ion-button id="openEndDateInput" ref="openEndDateInput" slot="end" fill="clear" style="margin-left: 0px">
                          <ion-icon slot="end" :icon="calendarClearOutline"></ion-icon>
                        </ion-button>
                        <ion-popover class="end-date" v-if="!validTillNotRequired" slot="end" side="bottom" trigger="openEndDateInput">
                          <ion-datetime :min="moment(dummyDiscount.validfrom).endOf('day').format()" :max="moment().add(20, 'years').format()" display-format="DD/MM/YY" :value="dummyDiscount.validtill" ref="endDate" name="endDate" @ionChange="onEndDateChange" presentation="date" id="endDate"></ion-datetime>
                        </ion-popover>
                      </ion-item> 
                    </ion-col>
                    <ion-item lines="none" class="endDate">
                      <div class="timeRow">
                        <div class="inputLabels">No End Date</div>
                        <ion-checkbox v-model="validTillNotRequired" class="dateCheckBox"></ion-checkbox>
                      </div>
                    </ion-item>
                  </ion-row>

                  <ion-row class="rowMargin">
                    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="5" class="ion-no-padding">
                      <h2 class="inputLabel ion-no-margin">Start Time</h2>
                      <ion-item lines="none">
                        <ion-input :disabled="is24Hrs" @focus="$refs.openstartTimeInput.click()" :value="validityStartEndTime.startTime.display" class="fullBordered input_custom_style inputStyle" id="startTime"></ion-input>
                        <ion-button id="openstartTimeInput" ref="openstartTimeInput" slot="end" fill="clear"  style="margin-left: 0px">
                          <ion-icon slot="end" :icon="alarm"></ion-icon>
                        </ion-button>
                        <ion-popover v-if="!is24Hrs" slot="end" side="bottom" trigger="openstartTimeInput">
                          <ion-datetime :show-default-buttons="true" display-format="hh:mm A" :value="validityStartEndTime.startTime.value" ref="startTime" name="startTime" @ionChange="onStartTimeChange" presentation="time" id="start-time"></ion-datetime>
                        </ion-popover>
                      </ion-item>
                    </ion-col>
                    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="5" size-xs="5" class="ion-no-padding">
                      <h2  class="inputLabel ion-no-margin">End Time</h2>
                      <ion-item lines="none">
                        <ion-input :disabled="is24Hrs" @focus="$refs.openendTimeInput.click()" :value="validityStartEndTime.endTime.display" id="endTime"  class="fullBordered input_custom_style inputStyle"> </ion-input>
                        <ion-button id="openendTimeInput" ref="openendTimeInput" fill="clear" style="margin-left: 0px">
                          <ion-icon slot="end" :icon="alarm"></ion-icon>
                        </ion-button>
                        <ion-popover v-if="!is24Hrs" slot="end" side="bottom" trigger="openendTimeInput">
                          <ion-datetime :show-default-buttons="true" display-format="hh:mm A" :value="validityStartEndTime.endTime.value" ref="endTime" name="endTime" @ionChange="onEndTimeChange" presentation="time" id="start-time"></ion-datetime>
                        </ion-popover>
                      </ion-item>
                    </ion-col>
                    <ion-item lines="none" class="endDate">
                      <div class="timeRow">
                        <div class="inputLabels">All day</div>
                        <ion-checkbox v-model="is24Hrs" class="dateCheckBox"></ion-checkbox>
                      </div>
                    </ion-item>
                  </ion-row>
                </ion-grid> 
                <div class="card_container_grid ion-no-padding"> 
                  <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
                     <h2 class="ion-no-padding sctn-labels ion-no-margin">Select timezone</h2>
                  </ion-col>
                  <ion-col class="ion-no-padding" size-xl="8" size-lg="8" size-md="8" size-sm="8" size-xs="8">
                    <div class="section_style_v1">
                      <timezone-options v-model="dummyDiscount.timezone" class="slctContainer" />
                    </div>
                  </ion-col>
                </div>
                <div class="slctDays">
                  <span class="orderDesc ion-no-margin"> Apply discount on selected days</span>
                  <span @click="selectAllDays" class="slct-dates">Select all</span>
                </div>
            
                <div class="daysRow">
                  <span class="day" 
                    @click="dummyDiscount.days.indexOf(dIndex) > -1 ? dummyDiscount.days.splice(dummyDiscount.days.indexOf(dIndex), 1) : dummyDiscount.days.push(dIndex)" 
                    v-for="(thisDay, dIndex) in daysArr" :key="dIndex"
                    :class="dummyDiscount.days.indexOf(dIndex) > -1 ? 'selected' : ''">
                    {{ thisDay }}
                  </span>
                </div>
              </ion-list>
            </ion-accordion>
            <ion-accordion class="model-sections" value="addCollections">
              <ion-item slot="header" lines="none">
                  <ion-text class="disc-header">
                      Other settings
                  </ion-text>
              </ion-item>
              <ion-list slot="content" lines="none" class="ion-no-padding">
                <ion-card-header>
                  <ion-grid class="ion-no-padding">
                    <ion-row class="ion-no-padding" style="margin-bottom: 5px">
                      <ion-col class="pad-top"> 
                        <ion-text class="common-label">
                              This discount is stackable                      
                        </ion-text>
                      </ion-col>
                      <ion-col class="ion-no-padding text-right"> 
                        <ion-checkbox class="cust_toggle" v-model="dummyDiscount.stackable"></ion-checkbox>
                      </ion-col>
                    </ion-row>
                    <ion-row  class="ion-no-padding" style="margin-bottom: 5px">
                      <ion-col class="pad-top">
                        <ion-text class="common-label">
                            Unlimited discount codes 
                        </ion-text>
                        </ion-col>
                      <ion-col class="ion-no-padding text-right">
                          <ion-checkbox class="cust_toggle"  v-model="dummyDiscount.usageLimit" ></ion-checkbox>
                      </ion-col>
                    </ion-row>
                      <ion-row v-if="!dummyDiscount.usageLimit" style="margin-bottom: 5px; margin-top: 5px">
                      <ion-col class="pad-top">
                        <ion-text class="common-label">
                              Limited Uses                  
                        </ion-text>
                      </ion-col>
                      <ion-col class="text-right pad-top">
                        <ion-input type="text" placeholder="Eg.: 10" class="fullBordered input_custom_style sctn-input" v-model="dummyDiscount.limitedUses"></ion-input>
                      </ion-col>
                    </ion-row>

                    <ion-row  class="ion-no-padding" style="margin-bottom: 5px">
                      <ion-col class="pad-top">
                        <ion-text class="common-label">
                          Limit usage per customer?
                        </ion-text>
                        </ion-col>
                      <ion-col class="ion-no-padding text-right">
                        <ion-checkbox class="cust_toggle" v-model="dummyDiscount.limitpershopper"></ion-checkbox>
                      </ion-col>
                    </ion-row>

                    <ion-row  v-if="dummyDiscount.limitpershopper" class="ion-no-padding" style="margin-bottom: 5px">
                      <ion-col class="pad-top">
                        <ion-text class="common-label">
                            Custom Limit
                        </ion-text>
                        </ion-col>
                      <ion-col class="ion-no-padding text-right pad-top">
                          <ion-input type="text" placeholder="Eg.: 2" class="fullBordered input_custom_style sctn-input" v-model="dummyDiscount.customlimit"></ion-input>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-header>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-col>
      </ion-row>
    </ion-grid>
    </div>
  </ion-content>
  <ion-modal
    v-if="selectFreeItemsModal"
    :is-open="selectFreeItemsModal"
    @didDismiss="() => {selectFreeItemsModal = false; freeItemFilter = undefined;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Select free items</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {selectFreeItemsModal = false; freeItemFilter = undefined;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
          <div class="searchInput">
            <ion-input v-model="freeItemFilter" type="text" placeholder="Enter product name" class="fullBordered cust_input_hght input-width"></ion-input>
          </div>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row v-for="product in freeItemsToShow" :key="product.id" class="rowMargin">
                <ion-col size="2">
                    <img width="50" v-if="product.image" :src="product.image" />
                </ion-col>
                <ion-col size="5" class="ion-align-self-center">
                  <h2 class="common-label ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
                  <h2 class="common-label ion-no-margin custom_mrg_left">{{ product.stock }}</h2>
                </ion-col>
                <div class="increament_cont"> 
                  <div @click="() => product.quantity ? product.quantity-- : product.quantity = 1" class="decrease">
                    -
                  </div>
                  <div class="value_cont">
                    <ion-input v-model="product.quantity" class="fullBordered cust_input_hght"  type="text" placeholder="0"></ion-input>
                  </div>
                  <div @click="() => product.quantity ? product.quantity++ : product.quantity = 1" class="increase">
                    +
                  </div>
              </div>
              <ion-checkbox @ionChange="addFreeItem($event, product)" :checked="product.selected" class="cust_mrg"></ion-checkbox>
              </ion-row>
            </ion-grid>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button @click="() => {selectFreeItemsModal = false; freeItemFilter = undefined;}" color="dark">Done</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>
  <ion-modal
    v-if="selectExcludeItemsModal"
    :is-open="selectExcludeItemsModal"
    @didDismiss="() => {selectExcludeItemsModal = false; excludeItemFilter = undefined;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Select Items to Exclude</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {selectExcludeItemsModal = false; excludeItemFilter = undefined;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="grid_col_02">
          <div>
            <ion-checkbox @ionChange="excludeAllItems($event)" color="dark"></ion-checkbox>
          </div>
          <div>
            <ion-input v-model="excludeItemFilter" class="fullBordered cust_input_hght" type="text" placeholder="Search"></ion-input>
          </div>
        </div>
        <!-- <ion-grid class="ion-no-padding">
          <ion-row  v-for="product in excludeItemsToShow" :key="product.id" class="rowMargin">
            <ion-col class="ion-no-padding ion-align-self-center">
              <h2 class="common-label ion-no-padding"> {{ product.name }} </h2>
              <h2 class="common-label ion-no-padding"> Stock: {{ product.stock }} </h2>
            </ion-col>
            <ion-col>
               <ion-checkbox @ionChange="excludeItem($event, product)" :checked="product.selected" color="dark"></ion-checkbox>
            </ion-col>
          </ion-row>
        </ion-grid> -->
        
        <ion-list>
          <ion-item class="bdr_block"  v-for="product in excludeItemsToShow" :key="product.id">
            <div class="grid_col_02_v1">
              <div>
                <ion-checkbox @ionChange="excludeItem($event, product)" :checked="product.selected" color="dark"></ion-checkbox>
              </div>
              <div>
                <ion-label>
                  {{ product.name }}
                </ion-label>
                <p>
                  Stock:
                  <span>
                    {{ product.stock }}
                  </span>
                </p>
              </div>
            </div>
          </ion-item>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button @click="() => {selectExcludeItemsModal = false; excludeItemFilter = undefined;}" color="dark">Done</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>
  <ion-modal
    v-if="selectDiscountedItemsModal"
    :is-open="selectDiscountedItemsModal"
    @didDismiss="() => {selectDiscountedItemsModal = false; discountItemFilter = undefined;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Select Items</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {selectDiscountedItemsModal = false; discountItemFilter = undefined;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
          <div class="searchInput">
              <ion-input v-model="discountItemFilter" type="text" placeholder="Search" class="fullBordered cust_input_hght input-width" ></ion-input>
          </div>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row v-for="product in discountItemsToShow" :key="product.id" class="rowMargin">
                <ion-col size="2">
                  <img width="50" v-if="product.image" :src="product.image" />
                </ion-col>
                <ion-col size="6" class="ion-align-self-center">
                  <h2 class="common-label ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
                  <h2 class="common-label ion-no-margin custom_mrg_left">{{ product.stock }}</h2>
                </ion-col>
                <ion-col>
                    <ion-checkbox @ionChange="chooseItem($event, product)" :checked="product.selected"></ion-checkbox>
                </ion-col>
              </ion-row>
            </ion-grid>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button @click="() => {selectDiscountedItemsModal = false; discountItemFilter = undefined;}" color="dark">Done</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  
  </ion-modal>
  
  <!-- showDiscountedItems Modal -->
  <ion-modal
    v-if="showDiscountedItems && dummyDiscount.products.length > 0"
    :is-open="showDiscountedItems"
    @didDismiss="() => {showDiscountedItems = false;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Selected Items</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {showDiscountedItems = false;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid class="card_container_grid ion-no-padding">
          <ion-row v-for="product in dummyDiscount.products" :key="product.id" class="rowMargin selected-container">
              <ion-col size="2">
                <img width="50" v-if="product.image" :src="product.image" class="slctd-img" />
              </ion-col>
              <ion-col size="5" class="ion-align-self-center">
                <h2 class="common-label ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
              </ion-col>
              <ion-col size="4" class="ion-align-self-center">
                <ion-button @click="() => dummyDiscount.products.splice(dummyDiscount.products.findIndex(e => e.id === product.id), 1)" color="danger" fill="outline">Remove</ion-button>
              </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- showExcludedItems Modal -->
  <ion-modal
    v-if="showExcludedItems && dummyDiscount.excludedProducts.length > 0"
    :is-open="showExcludedItems"
    @didDismiss="() => {showExcludedItems = false;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Selected Items</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {showExcludedItems = false;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid class="card_container_grid ion-no-padding">
          <ion-row v-for="product in dummyDiscount.excludedProducts" :key="product.id" class="rowMargin selected-container">
              <ion-col size="2">
                <img width="50" v-if="product.image" :src="product.image" class="slctd-img" />
              </ion-col>
              <ion-col size="5" class="ion-align-self-center">
                <h2 class="common-label ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
              </ion-col>
              <ion-col size="4" class="ion-align-self-center">
                <ion-button @click="() => dummyDiscount.excludedProducts.splice(dummyDiscount.excludedProducts.findIndex(e => e.id === product.id), 1)" color="danger" fill="outline">Remove</ion-button>
              </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- showFreeItems Modal -->
  <ion-modal
    v-if="showFreeItems && multipleProducts.length > 0"
    :is-open="showFreeItems"
    @didDismiss="() => {showFreeItems = false;}"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="prdt-title ion-no-margin">Selected Items</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {showFreeItems = false;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid class="card_container_grid ion-no-padding">
          <ion-row v-for="product in multipleProducts" :key="product.id" class="rowMargin selected-container">
              <ion-col size="2">
                <img width="50" v-if="product.image" :src="product.image" class="slctd-img" />
              </ion-col>
              <ion-col size="5" class="ion-align-self-center">
                <h2 class="common-label ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
              </ion-col>
              <ion-col size="4" class="ion-align-self-center">
                <ion-button @click="() => multipleProducts.splice(multipleProducts.findIndex(e => e.id === product.id), 1)" color="danger" fill="outline">Remove</ion-button>
              </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ion-page>
  </ion-modal>
</master-layout>
</template>

<script>
import { chevronDownCircleOutline, close, calendarClearOutline, alarm, arrowBackOutline, addOutline } from 'ionicons/icons';
import { IonTextarea, IonRadio, IonRadioGroup, IonCheckbox, IonSelect, IonSelectOption, IonAccordion, IonAccordionGroup, IonPopover, IonDatetime } from "@ionic/vue";
import timezoneOptions from '/src/components/timezoneOptions';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapGetters } from "vuex";

export default {
  components: {
    timezoneOptions,
    IonTextarea,
    IonRadio,
    IonRadioGroup,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonAccordion,
    IonAccordionGroup,
    IonPopover,
    IonDatetime
  },
  data() {
    return {
      chevronDownCircleOutline,
      close,
      calendarClearOutline,
      arrowBackOutline,
      addOutline,
      alarm,
      loading: false,
      is24Hrs: false,
      daysArr: ['Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat', 'Sun'],
      allItems: [],
      discountOnAll: true,
      selectFreeItemsModal: false,
      selectExcludeItemsModal: false,
      selectDiscountedItemsModal: false,
      showExcludedItems: false,
      showDiscountedItems: false,
      showFreeItems: false,
      discountType: "undefined",
      validTillNotRequired: false,
      multipleProducts: [],
      dummyDiscount: {
        upperpercentagelimit: 0,
        coupon: null,
        maxticketsize: undefined,
        image: undefined,
        usageLimit: false,
        addproduct: null,
        opening_time: undefined,
        percentage: undefined,
        type: undefined,
        limitedUses: "",
        addmultiproduct: undefined,
        customlimit: "",
        couponapplied: true,
        excludedProducts: [],
        validfrom: undefined,
        zones: [],
        name: undefined,
        offerquantity: "0",
        variations: [],
        offmoney: "0",
        enableForAllVariations: true,
        automatic: false,
        limitpershopper: false,
        limitMoney: null,
        stackable: undefined,
        timezone: undefined,
        id: undefined,
        nocondition: false,
        enableForAllProducts: false,
        max_quantity: 0,
        upperoffmoney: "0",
        description: undefined,
        validDate: false,
        cart: false,
        active: false,
        validtill: undefined,
        ticketsize: "0",
        created: undefined,
        addproductconfig: {},
        days: [],
        shipping: false,
        products: [],
        closing_time: undefined,
        promotion: false,
        nodatecondition: false,
        order: true,
        quantity: "1000"
      },
      freeItemFilter: undefined,
      excludeItemFilter: undefined,
      discountItemFilter: undefined
    }
  },
  async ionViewWillEnter() {
    await this.fetchItems();
    if(this.$route.query && this.$route.query.discountid) await this.init();
    this.dummyDiscount.timezone = this.user.timezone;
  },
  computed: {
    ...mapGetters({
      user: "user/getUser"
    }),
    freeItemsToShow() {
      return this.freeItemFilter && this.freeItemFilter.length ? (this.allItems.filter(e => e.name?.match(new RegExp(this.freeItemFilter, 'gi')))).map(e => {
        let index = this.multipleProducts.findIndex(p => p.id === e.id);
        e.selected = index > -1;
        if(e.selected) e.quantity = this.multipleProducts[index].quantity;
        else e.quantity = 1;
        return e;
      }) : this.allItems.map(e => {
        let index = this.multipleProducts.findIndex(p => p.id === e.id);
        e.selected = index > -1;
        if(e.selected) e.quantity = this.multipleProducts[index].quantity;
        else e.quantity = 1;
        return e;
      });
    },
    excludeItemsToShow() {
      return this.excludeItemFilter && this.excludeItemFilter.length ? (this.allItems.filter(e => e.name?.match(new RegExp(this.excludeItemFilter, 'gi')))).map(e => {
        e.selected = this.dummyDiscount.excludedProducts.findIndex(p => p.id === e.id) > -1;
        return e;
      }) : this.allItems.map(e => {
        e.selected = this.dummyDiscount.excludedProducts.findIndex(p => p.id === e.id) > -1;
        return e;
      });
    },
    discountItemsToShow() {
      return this.discountItemFilter && this.discountItemFilter.length ? (this.allItems.filter(e => e.name?.match(new RegExp(this.discountItemFilter, 'gi')))).map(e => {
        e.selected = this.dummyDiscount.products.findIndex(p => p.id === e.id) > -1;
        return e;
      }) : this.allItems.map(e => {
        e.selected = this.dummyDiscount.products.findIndex(p => p.id === e.id) > -1;
        return e;
      });
    },
    validityStartEndTime() {
      let objTime = {
        startTime: {
          display: '',
          value: ''
        },
        endTime: {
          display: '',
          value: ''
        }
      };
      const { opening_time, closing_time } = this.dummyDiscount;
      if (!opening_time || opening_time === "00:00:00") {
        objTime.startTime.display = "00:00:00";
        objTime.startTime.value = null;
      } else {
        objTime.startTime.display = this.moment(opening_time).format('hh:mm A');
        objTime.startTime.value = this.moment(opening_time).format();
      }

      if (!closing_time || closing_time === "00:00:00") {
        objTime.endTime.display = "00:00:00";
        objTime.endTime.value = null;
      } else {
        objTime.endTime.display = this.moment(closing_time).format('hh:mm A');
        objTime.endTime.value = this.moment(closing_time).format();
      }
      console.log(objTime, closing_time);
      return objTime;
    }
  },
  watch: {
    is24Hrs: {
      immediate: true,
      handler() {
        let { validfrom, validtill } = this.dummyDiscount;
        if (this.is24Hrs && validfrom && validtill) {
          this.dummyDiscount.opening_time = `${this.moment(this.dummyDiscount.validfrom).format('DD/MM/YY')} 00:00:00`;
          this.dummyDiscount.closing_time = `${this.moment(this.dummyDiscount.validtill).format('DD/MM/YY')} 23:59:00`;
        } else if (this.is24Hrs) {
          this.dummyDiscount.opening_time = `${this.moment().format('DD/MM/YY')} 00:00:00`;
          this.dummyDiscount.closing_time = `${this.moment().format('DD/MM/YY')} 23:59:00`;
        }
      }
    },
    discountType() {
      switch(this.discountType) {
        case 'automatic': this.dummyDiscount.automatic = false;
        //this.dummyDiscount.couponapplied = true;
        this.dummyDiscount.couponapplied = false;
        break;
        case 'manual': this.dummyDiscount.automatic = false;
        //this.dummyDiscount.couponapplied = false;
        this.dummyDiscount.couponapplied = true;
        break;
        case 'agent': this.dummyDiscount.automatic = true;
        //this.dummyDiscount.couponapplied = 'automatic';
        this.dummyDiscount.couponapplied = false;
        break;
      }
    },
    validTillNotRequired() {
      if(this.validTillNotRequired) this.dummyDiscount.validtill = undefined;
    },
    'dummyDiscount.excludedProducts': {
     handler(){
       this.dummyDiscount.excludedProductid = (this.dummyDiscount.excludedProducts.map(e => e.id)).join(',');
     },
     deep: true
    },
    'dummyDiscount.products': {
     handler(){
       this.dummyDiscount.productids = (this.dummyDiscount.products.map(e => e.id)).join(',');
     },
     deep: true
    },
    multipleProducts: {
     handler(){
       if(this.multipleProducts && this.multipleProducts.length) {
         this.dummyDiscount.addmultiproduct = "";
         this.dummyDiscount.addproductconfig = {};
         this.multipleProducts.forEach((e, eIndex) => {
           if(eIndex !== this.multipleProducts.length - 1) this.dummyDiscount.addmultiproduct = this.dummyDiscount.addmultiproduct + e.id + ',';
           else this.dummyDiscount.addmultiproduct = this.dummyDiscount.addmultiproduct + e.id;
           this.dummyDiscount.addproductconfig[e.id] = { "quantity": e.quantity ? e.quantity : 1 };
         })
       }
       console.log(this.dummyDiscount.addmultiproduct);
       console.log(this.dummyDiscount.addproductconfig);
     },
     deep: true
    },
  },
  setup() {
    const slctDiscountOptions = {
      cssClass: 'slct-discount-type',
    };
    return { slctDiscountOptions }
  },
  methods: {
    onStartDateChange(e) {
      e.target.closeParentOverlay();
      this.dummyDiscount.validfrom = this.moment(new Date(e.detail.value)).format();
      if (this.dummyDiscount.validfrom > this.dummyDiscount.validtill) {
        this.dummyDiscount.validtill = undefined;
      }
    },
    onEndDateChange(e) {
      e.target.closeParentOverlay();
      this.dummyDiscount.validtill = this.moment(new Date(e.detail.value)).format();
    },
    onStartTimeChange(e) {
      e.target.closeParentOverlay();
      this.dummyDiscount.opening_time = this.moment(new Date(e.detail.value)).format();
    },
    onEndTimeChange(e) {
      e.target.closeParentOverlay();
      this.dummyDiscount.closing_time = this.moment(new Date(e.detail.value)).format();
    },
    setStarEndTime() {
      // code for backward compatibility
      let { validfrom, validtill, opening_time, closing_time } = this.dummyDiscount;
      let validOpeningDateTimeString, validClosingDateTimeString;
      if (this.moment(validfrom).isValid()) {
        validOpeningDateTimeString = `${this.moment(validfrom).format('DD/MM/YY')} ${opening_time}`;
      } else {
        validOpeningDateTimeString = `${this.moment().format('DD/MM/YY')} ${opening_time}`;
      }
      if (this.moment(validOpeningDateTimeString).isValid()) {
        this.dummyDiscount.opening_time = validOpeningDateTimeString;
      } else {
        this.dummyDiscount.opening_time = `${this.moment().format('DD/MM/YY')} 00:00:00`;        
      }

      if (this.moment(validtill).isValid()) {
        validClosingDateTimeString = `${this.moment(validtill).format('DD/MM/YY')} ${closing_time}`;
      } else {
        validClosingDateTimeString = `${this.moment().format('DD/MM/YY')} ${closing_time}`;
      }
      if (this.moment(validClosingDateTimeString).isValid()) {
        this.dummyDiscount.closing_time = validClosingDateTimeString;
      } else {
        this.dummyDiscount.closing_time = `${this.moment().format('DD/MM/YY')} 00:00:00`;
      }
    },
    async init() {
      try {
        this.loading = true;
        let editDiscountResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "discount/" + this.$route.query.discountid);

        if ((editDiscountResponse.status == 200 || editDiscountResponse.status == 201) && editDiscountResponse.data.success) {
          this.dummyDiscount = editDiscountResponse.data.data;
          (this.dummyDiscount.opening_time === "00:00:00" && this.dummyDiscount.closing_time === "23:59:00") ? (this.is24Hrs = true) : (this.is24Hrs = false);

          if(!this.dummyDiscount.automatic && this.dummyDiscount.couponapplied === true) this.discountType = 'manual';
          if(this.dummyDiscount.automatic && this.dummyDiscount.couponapplied === false) this.discountType = 'agent';
          if(!this.dummyDiscount.automatic && this.dummyDiscount.couponapplied === false) this.discountType = 'automatic';

          if(this.dummyDiscount.validtill === undefined || this.dummyDiscount.validtill === "9999-09-09") this.validTillNotRequired = true;

          if(this.dummyDiscount.excludedProducts && this.dummyDiscount.excludedProducts.length) this.discountOnAll = false;

          this.dummyDiscount.limitpershopper = !this.dummyDiscount.limitpershopper;

          if(this.dummyDiscount.maxticketsize == 9999999) this.dummyDiscount.maxticketsize = undefined;

          if(this.dummyDiscount.upperoffmoney == 9999999) this.dummyDiscount.upperoffmoney = undefined;

          if(this.dummyDiscount.addmultiproduct && this.dummyDiscount.addmultiproduct.length) {
            this.dummyDiscount.addmultiproduct.forEach(e => {
              let thisItem = this.allItems.find(item => item.id === e.id);
              thisItem.quantity = this.dummyDiscount.addproductconfig[thisItem.id].quantity;
              this.multipleProducts.push(thisItem);
            })
          }
        } else {
          if(editDiscountResponse.data && !editDiscountResponse.data.success && editDiscountResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: editDiscountResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", editDiscountResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
        this.setStarEndTime();
      }
    },
    async fetchItems() {
      try {
        this.loading = true;
        let fetchItemsResponse = await jwtInterceptor.get(this.environment('VUE_APP_ROOT_API') + "fetch-item");

        if ((fetchItemsResponse.status == 200 || fetchItemsResponse.status == 201) && fetchItemsResponse.data.success) {
          this.allItems = fetchItemsResponse.data.data.items;
        } else {
          if(fetchItemsResponse.data && !fetchItemsResponse.data.success && fetchItemsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchItemsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", fetchItemsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    selectAllDays() {
      this.dummyDiscount.days = [0,1,2,3,4,5,6];
    },
    excludeItem(event, item) {
      let index = this.dummyDiscount.excludedProducts.findIndex(e => e.id === item.id);
      if(event.detail.checked && index === -1) this.dummyDiscount.excludedProducts.push(item);
      if(!event.detail.checked && index > -1)this.dummyDiscount.excludedProducts.splice(index, 1);
    },
    excludeAllItems(event) {
      if(event.detail.checked) this.dummyDiscount.excludedProducts = JSON.parse(JSON.stringify(this.allItems));
      else this.dummyDiscount.excludedProducts = [];
    },
    chooseItem(event, item) {
      let index = this.dummyDiscount.products.findIndex(e => e.id === item.id);
      if(event.detail.checked && index === -1) this.dummyDiscount.products.push(item);
      if(!event.detail.checked && index > -1)this.dummyDiscount.products.splice(index, 1);
    },
    chooseAllItems(event) {
      if(event.detail.checked) this.dummyDiscount.products = JSON.parse(JSON.stringify(this.allItems));
      else this.dummyDiscount.products = [];
    },
    addFreeItem(event, item) {
      let index = this.multipleProducts.findIndex(e => e.id === item.id);
      if(event.detail.checked && index === -1) this.multipleProducts.push(item);
      if(!event.detail.checked && index > -1)this.multipleProducts.splice(index, 1);
    },
    async updateDiscount() {
      try {
        this.loading = true;

        if(!this.dummyDiscount.name || this.dummyDiscount.name.length === 0) return this.updateToast({
          open: true,
          message: "Please enter discount code",
          type: "warning"
        });

        if(!this.dummyDiscount.validfrom) return this.updateToast({
          open: true,
          message: "Please choose a start date",
          type: "warning"
        });

        if (!this.dummyDiscount.opening_time || !this.dummyDiscount.closing_time) {
          return this.updateToast({ open: true, message: "Please choose opening and closing time", type: "warning" });
        }

        switch(this.discountType) {
          case 'automatic': this.dummyDiscount.automatic = false;
          //this.dummyDiscount.couponapplied = true;
          this.dummyDiscount.couponapplied = false;
          break;
          case 'manual': this.dummyDiscount.automatic = false;
          //this.dummyDiscount.couponapplied = false;
          this.dummyDiscount.couponapplied = true;
          break;
          case 'agent': this.dummyDiscount.automatic = true;
          //this.dummyDiscount.couponapplied === 'automatic';
          this.dummyDiscount.couponapplied = false;
          break;
          default: return this.updateToast({
            open: true,
            message: "Please select proper discount type",
            type: "warning"
          });
        }

        if(this.validTillNotRequired){
          this.dummyDiscount.validtill = undefined;
          this.dummyDiscount.nodatecondition = true;
        } else {
          if(!this.dummyDiscount.validtill) return this.updateToast({
            open: true,
            message: "Please choose an end date",
            type: "warning"
          });
        }

        if(!this.dummyDiscount.timezone) return this.updateToast({
          open: true,
          message: "Please add a timezone",
          type: "warning"
        });

        switch(this.dummyDiscount.type) {
          case 'percentage': if(!this.dummyDiscount.upperpercentagelimit || this.dummyDiscount.upperpercentagelimit == 0) return this.updateToast({
            open: true,
            message: "Please add the percentage off",
            type: "warning"
          });
          break;
          case 'amount': if(!this.dummyDiscount.offmoney || this.dummyDiscount.offmoney == 0) return this.updateToast({
            open: true,
            message: "Please add the amount off value",
            type: "warning"
          });
          break;
          case 'item': if(!this.multipleProducts || this.multipleProducts.length === 0) return this.updateToast({
            open: true,
            message: "Please select atleast one free item to give",
            type: "warning"
          });
          break;
          default: return this.updateToast({
            open: true,
            message: "Please select valid discount type",
            type: "warning"
          });
        }

        if(!this.discountOnAll && (!this.dummyDiscount.products || this.dummyDiscount.products.length === 0)) return this.updateToast({
          open: true,
          message: "Please select at least one item to apply discount on",
          type: "warning"
        });

        if(!this.dummyDiscount.days || this.dummyDiscount.days.length === 0) return this.updateToast({
          open: true,
          message: "Please select at least one day",
          type: "warning"
        });

        if(this.discountOnAll) this.dummyDiscount.excludedProducts = [];
        else this.dummyDiscount.products = [];

        if(this.dummyDiscount.products.length == 0) this.dummyDiscount.productids = "";
        if(this.dummyDiscount.variations.length == 0) this.dummyDiscount.variationids = "";
        if(this.dummyDiscount.excludedProducts.length == 0) this.dummyDiscount.excludedProductid = "";

        if(this.dummyDiscount.variationids.length > 0) this.dummyDiscount.enableForAllVariations = false;

        if(this.dummyDiscount.type == 'item') this.dummyDiscount.quantity = "0";

        let maxticketsize = "9999999";
        if(this.dummyDiscount.maxticketsize){
          if(this.dummyDiscount.maxticketsize != ''){
            maxticketsize = this.dummyDiscount.maxticketsize?.toString();
          }
        }

        if(!this.dummyDiscount.upperpercentagelimit) this.dummyDiscount.upperpercentagelimit = 0;

        let reqBody = {
          name: this.dummyDiscount.name,
          description: this.dummyDiscount.description,
          validfrom: this.moment(this.dummyDiscount.validfrom).format("DD/MM/YYYY"),
          couponapplied: this.dummyDiscount.couponapplied?.toString(),
          quantity: this.dummyDiscount.quantity?.toString(),
          percentage: this.dummyDiscount.upperpercentagelimit?.toString(),
          upperpercentagelimit: this.dummyDiscount.upperpercentagelimit?.toString(),
          offmoney: this.dummyDiscount.offmoney?.toString(),
          upperoffmoney: this.dummyDiscount.upperoffmoney?.toString(),
          offerquantity: this.dummyDiscount.offerquantity?.toString(),
          validtill: this.dummyDiscount.validtill ? this.moment(this.dummyDiscount.validtill).format("DD/MM/YYYY") : "09/09/9999",
          productids: this.dummyDiscount.productids,
          variationids: this.dummyDiscount.variationids,
          excludedProductid: this.dummyDiscount.excludedProductid,
          enableForAllVariations: this.dummyDiscount.enableForAllVariations?.toString(),
          days: this.dummyDiscount.days.join(','),
          timezone: this.dummyDiscount.timezone,
          opening_time: this.moment(this.dummyDiscount.opening_time).format("HH:mm"),
          closing_time: this.moment(this.dummyDiscount.closing_time).format("HH:mm"),
          discountid: this.dummyDiscount.id?.toString(),
          nodatecondition: this.dummyDiscount.nodatecondition?.toString(),
          type: this.dummyDiscount.type,
          nocondition: this.dummyDiscount.nocondition?.toString(),
          order: this.dummyDiscount.order?.toString(),
          stackable: this.dummyDiscount.stackable?.toString(),
          automatic: this.dummyDiscount.automatic?.toString(),
          shipping: this.dummyDiscount.shipping?.toString(),
          ticketsize: this.dummyDiscount.ticketsize?.toString(),
          maxticketsize: maxticketsize,
          zones: this.dummyDiscount.zones.join(','),
          usageLimit: this.dummyDiscount.usageLimit?.toString(),
          limitpershopper: !this.dummyDiscount.limitpershopper
        }
        reqBody.limitpershopper = reqBody.limitpershopper.toString();

        if(this.dummyDiscount.customlimit == "" || this.dummyDiscount.customlimit == null){
          this.dummyDiscount.customlimit = "1";
        }

        if(this.dummyDiscount.limitedUses == "" || this.dummyDiscount.limitedUses == null){
          this.dummyDiscount.limitedUses = "1";
        }

        if(reqBody.limitpershopper == 'false'){
          reqBody['customlimit'] = this.dummyDiscount.customlimit?.toString();
        }

        if(reqBody.usageLimit == 'false'){
          reqBody['limitedUses'] = this.dummyDiscount.limitedUses?.toString();
        }

        if(this.dummyDiscount.type === 'item'){
          if(!this.dummyDiscount.max_quantity || this.dummyDiscount.max_quantity == '' || this.dummyDiscount.max_quantity == 0){
            reqBody['max_quantity'] = this.multipleProducts.length?.toString();
          }
          else{
            reqBody['max_quantity'] = this.dummyDiscount.max_quantity?.toString();
          }

          if(this.multipleProducts && this.multipleProducts.length > 0){
            reqBody['addmultiproduct'] = this.dummyDiscount.addmultiproduct.join();
            reqBody['addproductconfig'] = Object.keys(this.dummyDiscount.addproductconfig).length ? JSON.stringify(this.dummyDiscount.addproductconfig) : undefined;
          }
        }

        let discountSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-discount", reqBody);
        if ((discountSaveResponse.status == 200 || discountSaveResponse.status == 201) && discountSaveResponse.data.success && discountSaveResponse.data.saved) {
          this.updateToast({
            open: true,
            message: "Discount updated successfully!",
            type: "success"
          });
          this.$router.replace({ path: '/store', query: { tab: 'discounts' }});
        } else {
          if(discountSaveResponse.data.errorMessage && discountSaveResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: discountSaveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>


<style>
.slct-discount-type {
   --max-width: 500px;
   --width: 500px; 
}
ion-popover .picker-item {
  cursor: pointer !important;
}
</style>

<style scoped>
ion-datetime ::v-deep(.picker-item) {
  cursor: pointer !important;
}
 ion-popover.start-date, ion-popover.end-date {
  --width: 340px;
}
ion-datetime ::v-deep(.picker-item) {
  border: 1px solid blue;
  display: none;
}
.card_container_grid {
  margin: 20px;
}
.sctn-labels {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
}
.sctn-input {
  border-radius: 5px;
}

.text_area {
  border-radius: 5px;
}

.disc-header {
  font-size: 18px;
  color: var(--ion-color-black);
  font-weight: 700;
}

.pad-top {
  padding-top: 20px;
}
.slct-dates {
  color: #881fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
}
.radio-bttns {
  margin-right: 10px;
}

.inputLabel {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-left: 16px;
}
.inputStyle {
  max-width: 150px;
}

.inputLabels {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
}
.pad-left {
  padding-left: 14px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 10px;
}
.descContainer {
  border-top: 1px solid #EDEDED;
  padding: 20px;
}
.slctItems{
    margin: 10px 0px 10px 0px;
}
.slctDays {
  margin: 0px 20px
}
.daysRow {
  margin: 10px 0px 20px 20px;
  padding: 10px 0;
  cursor: pointer;
}

.daysRow > .day {
  padding: 5px;
  border: 1px solid black;
  border-radius: 3px;
  margin: 2px;
}
.daysRow > .day.selected {
  background-color: #881fff;
  color: white;
}

ion-modal {
  --max-width: 520px;
}
.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}
ion-checkbox.dateCheckBox{
  margin: 5px;
}
 .searchInput {
  padding: 15px;
}

.slctd-img{
  width: 50px;
  border-radius: 5px;
}

.selected-container {
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
  padding: 10px;
}


@media only screen and (max-width: 780px) { 
  .inputStyle {
    max-width: 100%;
  }
  .pad-left {
    padding-left: 0px;
  }
  .cust_pad_v1{
    padding: 14px 0px;
  }
  .pad-bottm {
    padding-bottom: 14px;
  }
  .searchInput {
    padding: 15px;
  }
  .rowMargin{
    margin-bottom: 10px;
  }
  .timeRow{
    margin-right: 10px;
  }
  .endDate {
    margin-top: 15px;
  }
}
</style>