<template>
  <ion-col>
    <input
      type="file"
      style="display: none;"
      id="sendImageHiddenInput"
      ref="sendImageHiddenInput"
      @input="handleImageUpload"
      accept="image/png, image/jpeg, image/jpg, image/gif"
      multiple
    />

    <input
      type="file"
      style="display: none;"
      id="sendVideoHiddenInput"
      ref="sendVideoHiddenInput"
      @input="handleVideoUpload"
      accept="video/mp4, video/mpeg4"
    />

    <input
      type="file"
      style="display: none;"
      id="sendDocumentHiddenInput"
      ref="sendDocumentHiddenInput"
      @input="handleDocumentUpload"
      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, .csv"
    />
    <ion-loading
      :is-open="loading"
      v-if="loading"
      cssClass="my-custom-class"
      message="Loading..."
    >
    </ion-loading>
    <ion-content
      ref="mainContent_container"
      v-else
    >
      <ion-refresher
        slot="fixed"
        :disabled="!conversationid"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-toolbar
        v-if="activeCustomer.page_conversation_id && conversationid"
        class="chat-toolbar"
        color="light"
      >
        <ion-buttons slot="start">
          <ion-button
            class="show-xs"
            @click="() => $emit('home')"
          >
            <ion-icon
              slot="icon-only"
              :icon="arrowBack"
            ></ion-icon>
          </ion-button>
          <ion-button
            v-if="!receiverDetailMode"
            @click="() => receiverDetailMode = true"
          >
            <ion-icon
              slot="icon-only"
              :icon="personCircleOutline"
            ></ion-icon>
          </ion-button>
          <ion-button
            v-if="receiverDetailMode"
            @click="() => receiverDetailMode = false"
          >
            <ion-icon
              slot="icon-only"
              :icon="chatbubblesOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{ activeCustomer.name }}</ion-title>

        <ion-buttons slot="end">
          <ion-button @click="setMenuOpen(true)">
            <ion-icon
              slot="icon-only"
              :icon="ellipsisHorizontal"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-action-sheet
        :is-open="isMenuOpen"
        header="Options"
        :buttons="menuOptions"
        @didDismiss="setMenuOpen(false)"
      >
      </ion-action-sheet>
      <ion-action-sheet
        v-if="allAgentsResult.length"
        :is-open="mentionAgentMode"
        header="Mention team member"
        :buttons="mentionAgentOptions"
        @didDismiss="() => mentionAgentMode = false"
      >
      </ion-action-sheet>
      <ion-action-sheet
        v-if="allShortcuts.length && sendShortcutMode"
        :is-open="sendShortcutMode"
        header="Send"
        :buttons="sendShortcutOptions"
        @didDismiss="() => sendShortcutMode = false"
      >
      </ion-action-sheet>
      <ion-action-sheet
        v-if="actionModal"
        :is-open="actionModal"
        header="Actions"
        :buttons="productActionOptions"
        @didDismiss="() => actionModal = false"
      >
      </ion-action-sheet>
      <!-- chats start -->
      <div v-if="!receiverDetailMode">
        <div
          class="text-center"
          style="margin-top: 10px;"
        >
          <ion-button
            size="small"
            fill="outline"
            color="dark"
            v-if="activeUserConversationMore && loadMoreChatLoadingShow == false && activeUserConversationDataResult.length > 0"
            @click="loadMoreMessages"
          >Load more messages</ion-button>
          <ion-text
            color="medium"
            v-if="loadMoreChatLoadingShow == true"
          >Loading...</ion-text>
        </div>

        <div
          class="text-center"
          v-if="activeUserConversationDataResult.length == 0"
          style="width: 100%; padding: 15px;"
        >
          <img
            src="https://jumper.ai/web-s/resources/images/no-conversations-image.svg"
            style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
          />

          <h5>Yet to strike a conversation 🤐</h5>
          <ion-text color="medium">This customer hasn't sent a message or replied to an automation yet!</ion-text>
        </div>

        <div v-if="activeUserConversationDataResult.length > 0">
          <div class="chatComponentsContainer">
            <div class="inner_chatComponentsContainer">
              <div
                class="singleChatComponent"
                v-for="resultOuter in activeUserConversationDataResult"
                :key="resultOuter.messageid"
              >
                <div
                  class="repliedFalse_container"
                  v-if="resultOuter.replied == false"
                >
                  <!-- customer replied start -->
                  <div
                    class="botProfileImageInitialChar bgImgContainer custom_tooltip_container"
                    data-placement="right"
                    v-if="resultOuter.template.type != 'note' && resultOuter.template.type != 'status' && resultOuter.template.type != 'schedule'"
                  >
                    <div
                      class="bgImgContainerInner"
                      v-if="activeCustomer.image != null"
                      :style="checkIfThereIsNoLogoThenShowTransparentImage(activeCustomer.image)"
                      :title="renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false)"
                    ></div>
                    <div
                      class="bgImgContainerInner"
                      v-if="activeCustomer.image == null"
                      style="background: url(https://jumper.ai/web-s/resources/images/transparent-bg.png) no-repeat;"
                      :title="renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false)"
                    ></div>

                    <span class="custom_tooltip_text left">{{ renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false) }}</span>
                  </div>
                  <!-- customer replied end -->

                  <!-- message template start -->
                  <!-- fbcomment -->
                  <div
                    class="messageTemplateContainer userMsgBubble fbcomment"
                    v-if="resultOuter.template.attachmenttype == 'text' && resultOuter.template.type == 'fbcomment'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">Commented on your page</ion-badge>
                      </div>
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>
                  </div>

                  <!-- externallinkopened -->
                  <div
                    class="messageTemplateContainer userMsgBubble externallinkopened"
                    v-if="resultOuter.template.attachmenttype == 'text' && resultOuter.template.type == 'externallinkopened'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">Opened a link</ion-badge>
                      </div>
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>
                  </div>

                  <!-- non-reply to message -->
                  <div
                    class="messageTemplateContainer userMsgBubble"
                    v-if="(!resultOuter.replytomessage || resultOuter.replytomessage == null || resultOuter.replytomessage == 'None' || resultOuter.replytomessage == '') && ((resultOuter.template.attachmenttype == 'text' && resultOuter.template.type != 'fbcomment' && resultOuter.template.type != 'externallinkopened') || resultOuter.template.attachmenttype == 'image' || resultOuter.template.attachmenttype == 'video')"
                  >
                    <!-- text -->
                    <div
                      class="normalchat_component msg_component"
                      v-if="resultOuter.template.attachmenttype == 'text' && resultOuter.template.type != 'fbcomment' && resultOuter.template.type != 'externallinkopened'"
                    >
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>

                    <!-- document -->
                    <div
                      class="normalchat_component msg_component"
                      v-if="resultOuter.template.attachmenttype == 'document'"
                    >
                      <a
                        :href="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                        target="_blank"
                        rel="noreferer noopener"
                      >Click here to open file in new window</a>
                    </div>

                    <!-- image -->
                    <div
                      class="image_component msg_component"
                      v-if="resultOuter.template.attachmenttype == 'image'"
                    >
                      <img
                        :src="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                        class="imageInChat"
                      />
                    </div>

                    <!-- video -->
                    <div
                      class="video_component msg_component"
                      v-if="resultOuter.template.attachmenttype == 'video'"
                    >
                      <video
                        class="videoInChat"
                        controls
                        controlslist="nodownload"
                      >
                        <source
                          :src="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                          type="video/mp4"
                        >
                      </video>
                    </div>
                  </div>

                  <!-- reply to message -->
                  <div
                    class="messageTemplateContainer userMsgBubble replytomessage_container"
                    v-if="resultOuter.replytomessage && resultOuter.replytomessage != null && resultOuter.replytomessage != 'None' && resultOuter.replytomessage != ''"
                  >
                    <div class="innerDiv">
                      <!-- text -->
                      <div v-if="resultOuter.template.attachmenttype == 'text' || resultOuter.template.attachmenttype == 'document'">
                        <div>
                          <div
                            class="sourceMessage textType"
                            v-if="resultOuter.replytomessage.template.attachmenttype == 'text'"
                            v-html="renderNormalChat(resultOuter.replytomessage.message)"
                          ></div>

                          <span v-if="resultOuter.replytomessage.template.length > 0">
                            <div
                              class="sourceMessage textType"
                              v-if="resultOuter.replytomessage.template[0].type == 'text' || resultOuter.replytomessage.template[0].type == 'quick_replies' || resultOuter.replytomessage.template[0].type == 'payment_replies' || resultOuter.replytomessage.template[0].type == 'location' || resultOuter.replytomessage.template[0].type == 'button_message' || (resultOuter.replytomessage.template[0].type == 'payment' && resultOuter.replytomessage.template[0].buttons)"
                              v-html="renderNormalChat(resultOuter.replytomessage.message)"
                            ></div>
                          </span>

                          <span v-if="resultOuter.replytomessage.template.length > 0">
                            <div
                              class="sourceMessage textType"
                              v-if="resultOuter.replytomessage.template[0].type == 'generic_message' || (resultOuter.replytomessage.template[0].type == 'payment' && !resultOuter.replytomessage.template[0].buttons)"
                            >
                              <b>{{ resultOuter.replytomessage.template[0].message[0].title }}</b>
                              <div v-html="renderNormalChat(resultOuter.replytomessage.template[0].message[0].subtitle)"></div>
                            </div>
                          </span>

                          <div
                            class="sourceMessage imageType"
                            v-if="resultOuter.replytomessage.template.attachmenttype == 'image'"
                          >
                            <a
                              :href="sanitizeHardLinkUrl(resultOuter.replytomessage.template.attachment)"
                              target="_blank"
                              rel="noreferer noopener"
                            >
                              <img
                                :src="sanitizeHardLinkUrl(resultOuter.replytomessage.template.attachment)"
                                class="imageInChat"
                              />
                            </a>
                          </div>

                          <span v-if="resultOuter.replytomessage.template.length > 0">
                            <div
                              class="sourceMessage imageType"
                              v-if="resultOuter.replytomessage.template[0].type == 'image' || (resultOuter.replytomessage.template[0].type == 'file' && resultOuter.replytomessage.template[0].filetype == 'image')"
                            >
                              <a
                                :href="sanitizeHardLinkUrl(resultOuter.replytomessage.template[0].message)"
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <img
                                  :src="sanitizeHardLinkUrl(resultOuter.replytomessage.template[0].message)"
                                  class="imageInChat"
                                />
                              </a>
                            </div>
                          </span>

                          <div
                            class="sourceMessage videoType"
                            v-if="resultOuter.replytomessage.template.attachmenttype == 'video'"
                          >
                            <video
                              class="videoInChat"
                              controls
                              controlslist="nodownload"
                            >
                              <source
                                :src="sanitizeHardLinkUrl(resultOuter.replytomessage.template.attachment)"
                                type="video/mp4"
                              >
                            </video>
                          </div>

                          <span v-if="resultOuter.replytomessage.template.length > 0">
                            <div
                              class="sourceMessage videoType"
                              v-if="resultOuter.replytomessage.template[0].type == 'video' || (resultOuter.replytomessage.template[0].type == 'file' && resultOuter.replytomessage.template[0].filetype == 'video')"
                            >
                              <video
                                class="videoInChat"
                                controls
                                controlslist="nodownload"
                              >
                                <source
                                  :src="sanitizeHardLinkUrl(resultOuter.replytomessage.template[0].message)"
                                  type="video/mp4"
                                >
                              </video>
                            </div>
                          </span>

                          <span v-if="resultOuter.replytomessage.template.length > 0">
                            <div
                              class="sourceMessage textType"
                              v-if="resultOuter.replytomessage.template.attachmenttype != 'text' && resultOuter.replytomessage.template[0].type != 'text' && resultOuter.replytomessage.template.attachmenttype != 'image' && resultOuter.replytomessage.template[0].type != 'image' && (resultOuter.replytomessage.template[0].type == 'file' && resultOuter.replytomessage.template[0].filetype != 'image') && resultOuter.replytomessage.template.attachmenttype != 'video' && resultOuter.replytomessage.template[0].type != 'video' && (resultOuter.replytomessage.template[0].type == 'file' && resultOuter.replytomessage.template[0].filetype != 'video') && resultOuter.replytomessage.template[0].type != 'quick_replies' && resultOuter.replytomessage.template[0].type != 'payment_replies' && resultOuter.replytomessage.template[0].type != 'location' && resultOuter.replytomessage.template[0].type != 'button_message' && resultOuter.replytomessage.template[0].type != 'payment' && resultOuter.replytomessage.template[0].type != 'generic_message'"
                            >
                              <i>Attachment</i>
                              <ion-icon :icon="attachOutline"></ion-icon>
                            </div>
                          </span>

                          <div class="replyMessage textType">
                            <div>
                              <ion-text
                                color="medium"
                                style="font-size: 13px; line-height: 1.3; text-decoration: underline; margin-bottom: 5px; display: block;"
                              >
                                <i>
                                  <span v-if="activeCustomer.name != 'unrecorded' && activeCustomer.name != '' && activeCustomer.name != 'None' && activeCustomer.name != null">{{ activeCustomer.name }}</span>
                                  <span v-if="activeCustomer.name == 'unrecorded' || activeCustomer.name == '' || activeCustomer.name == 'None' || activeCustomer.name == null">Customer</span>
                                  replied to
                                  <span v-if="resultOuter.replytomessage.replied == true">your</span>
                                  <span v-if="resultOuter.replytomessage.replied == false">their own</span>
                                  message
                                </i>
                              </ion-text>
                            </div>

                            <div v-html="renderNormalChat(resultOuter.message)"></div>
                          </div>
                        </div>
                      </div>

                      <!-- image -->
                      <div v-if="resultOuter.template.attachmenttype == 'image'">
                        <div
                          class="sourceMessage textType"
                          v-if="resultOuter.replytomessage.template.attachmenttype == 'text' || resultOuter.replytomessage.template[0].type == 'text'"
                          v-html="renderNormalChat(resultOuter.replytomessage.message)"
                        ></div>

                        <div
                          class="sourceMessage textType"
                          v-if="resultOuter.replytomessage.template.attachmenttype != 'text' && resultOuter.replytomessage.template[0].type != 'text'"
                        >
                          <i>Attachment</i>
                          <ion-icon :icon="attachOutline"></ion-icon>
                        </div>

                        <div class="replyMessage imageType">
                          <div style="padding: 8px 8px 3px 8px;">
                            <ion-text
                              color="medium"
                              style="font-size: 13px; line-height: 1.3; text-decoration: underline; margin-bottom: 5px; display: block;"
                            >
                              <i>
                                <span v-if="activeCustomer.name != 'unrecorded' && activeCustomer.name != '' && activeCustomer.name != 'None' && activeCustomer.name != null">{{ activeCustomer.name }}</span>
                                <span v-if="activeCustomer.name == 'unrecorded' || activeCustomer.name == '' || activeCustomer.name == 'None' || activeCustomer.name == null">Customer</span>
                                replied to
                                <span v-if="resultOuter.replytomessage.replied == true">your</span>
                                <span v-if="resultOuter.replytomessage.replied == false">their own</span>
                                message
                              </i>
                            </ion-text>
                          </div>

                          <a
                            :href="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                            target="_blank"
                            rel="noreferer noopener"
                          >
                            <img
                              :src="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                              class="imageInChat"
                            />
                          </a>
                        </div>
                      </div>

                      <!-- video -->
                      <div v-if="resultOuter.template.attachmenttype == 'video'">
                        <div
                          class="sourceMessage textType"
                          v-if="resultOuter.replytomessage.template.attachmenttype == 'text' || resultOuter.replytomessage.template[0].type == 'text'"
                          v-html="renderNormalChat(resultOuter.replytomessage.message)"
                        ></div>

                        <div
                          class="sourceMessage textType"
                          v-if="resultOuter.replytomessage.template.attachmenttype != 'text' && resultOuter.replytomessage.template[0].type != 'text'"
                        >
                          <i>Attachment</i>
                          <ion-icon :icon="attachOutline"></ion-icon>
                        </div>

                        <div class="replyMessage videoType">
                          <div style="padding: 8px 8px 3px 8px;">
                            <ion-text
                              color="medium"
                              style="font-size: 13px; line-height: 1.3; text-decoration: underline; margin-bottom: 5px; display: block;"
                            >
                              <i>
                                <span v-if="activeCustomer.name != 'unrecorded' && activeCustomer.name != '' && activeCustomer.name != 'None' && activeCustomer.name != null">{{ activeCustomer.name }}</span>
                                <span v-if="activeCustomer.name == 'unrecorded' || activeCustomer.name == '' || activeCustomer.name == 'None' || activeCustomer.name == null">Customer</span>
                                replied to
                                <span v-if="resultOuter.replytomessage.replied == true">your</span>
                                <span v-if="resultOuter.replytomessage.replied == false">their own</span>
                                message
                              </i>
                            </ion-text>
                          </div>

                          <video
                            class="videoInChat"
                            controls
                            controlslist="nodownload"
                          >
                            <source
                              :src="sanitizeHardLinkUrl(resultOuter.template.attachment)"
                              type="video/mp4"
                            >
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- map location -->
                  <div
                    class="messageTemplateContainer userMsgBubble"
                    v-if="resultOuter.template.attachmenttype == 'location'"
                  >
                    <div
                      class="normalchat_component msg_component"
                      style="padding: 0px; font-size: 0px;"
                    >
                      <iframe
                        :src="getLatLongFromTheGoogleMapsUrl(resultOuter.template.attachment)"
                        style="border:0; height: 200px; width: 300px; max-width: 100%; -webkit-border-radius: 5px;  border-radius: 5px; overflow: hidden;"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>

                  <!-- story -->
                  <div
                    class="messageTemplateContainer userMsgBubble storyMentionPreview"
                    v-if="resultOuter.template.attachmenttype == 'story' || resultOuter.template.attachmenttype == 'story_mention'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">Mentioned you in a story</ion-badge>
                      </div>

                      <div v-if="!resultOuter.template.attachment_preview_url">
                        <a
                          class="singleMessageLoadStoryPreviewBtn"
                          :data-id="resultOuter.messageid"
                          @click="loadStoryPreviewForSingleMessage(resultOuter.messageid,resultOuter.template.attachment,resultOuter.pageid)"
                        >
                          View story preview
                        </a>
                      </div>

                      <div v-if="resultOuter.template.attachment_preview_url && resultOuter.template.attachment_preview_url != 'expired'">
                        <a
                          :href="resultOuter.template.attachment_preview_url"
                          target="_blank"
                          rel="noreferer noopener"
                          v-if="checkIfStoryIsImageOrVideo(resultOuter.template.attachment_preview_url) == 'image'"
                        >
                          <img
                            v-if="resultOuter.template.attachment_preview_url != '' && resultOuter.template.attachment_preview_url != 'None' && resultOuter.template.attachment_preview_url != null"
                            :src="resultOuter.template.attachment_preview_url"
                            class="imageInChat"
                          />
                          <img
                            v-if="resultOuter.template.attachment_preview_url == '' || resultOuter.template.attachment_preview_url == 'None' || resultOuter.template.attachment_preview_url == null"
                            src="https://jumper.ai/web-s/resources/images/upload-image-placeholder.png"
                            class="imageInChat"
                          />
                        </a>

                        <video
                          class="videoInChat"
                          controls
                          controlslist="nodownload"
                          v-if="checkIfStoryIsImageOrVideo(resultOuter.template.attachment_preview_url) == 'video'"
                        >
                          <source
                            :src="sanitizeHardLinkUrl(resultOuter.template.attachment_preview_url)"
                            type="video/mp4"
                          >
                        </video>
                      </div>

                      <div v-if="resultOuter.template.attachment_preview_url == 'expired'">
                        Oops! Looks like the user has deleted their story
                      </div>
                    </div>
                  </div>

                  <!-- story_reply -->
                  <div
                    class="messageTemplateContainer userMsgBubble storyMentionPreview"
                    v-if="resultOuter.template.attachmenttype == 'story_reply'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">
                          Replied to this story:
                          <b v-html="resultOuter.message"></b>
                        </ion-badge>
                      </div>

                      <div v-if="resultOuter.template.attachment != '' && resultOuter.template.attachment != 'None' && resultOuter.template.attachment != null">
                        <a
                          class="hoverUnderline singleMessageLoadStoryPreviewBtn"
                          id="singleMessageLoadStoryPreviewBtn-[[resultOuter.messageid]]"
                          :href="resultOuter.template.attachment"
                          target="_blank"
                          rel="noreferer noopener"
                        >
                          View story
                        </a>
                      </div>

                      <div v-if="resultOuter.template.attachment == '' || resultOuter.template.attachment == 'None' || resultOuter.template.attachment == null">
                        Oops! Looks like the user has deleted their story
                      </div>
                    </div>
                  </div>

                  <!-- note -->
                  <div
                    class="messageTemplateContainer botMsgBubble noteMessageType"
                    v-if="resultOuter.template.type == 'note'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">{{ resultOuter.agent.name }} left private note</ion-badge>
                      </div>
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>
                  </div>

                  <!-- status -->
                  <div
                    class="messageTemplateContainer botMsgBubble statusMessageType"
                    v-if="resultOuter.template.type == 'status' && resultOuter.message != null && resultOuter.message != '' && resultOuter.message != 'None'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">Status info. message</ion-badge>
                      </div>
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>
                  </div>

                  <!-- schedule -->
                  <div
                    class="messageTemplateContainer botMsgBubble scheduleMessageType"
                    v-if="resultOuter.template.type == 'schedule'"
                  >
                    <div class="normalchat_component msg_component">
                      <div>
                        <ion-badge color="light">
                          Reminder for {{ resultOuter.agent.name }}
                          <br v-if="resultOuter.schedule != null" />
                          <span
                            v-if="resultOuter.schedule != null"
                            style="display: inline-block;"
                          >
                            on
                            {{ resultOuter.schedule }}
                            <!-- pending => renderFormattedReminderDateTime() -->
                          </span>
                        </ion-badge>
                      </div>
                      <div v-html="renderNormalChat(resultOuter.message)"></div>
                    </div>
                  </div>
                  <!-- message template end -->

                  <!-- bot/agent replied start -->
                  <div
                    class="botProfileImageInitialChar custom_tooltip_container"
                    data-placement="left"
                    v-if="resultOuter.agent == null && (resultOuter.template.type == 'note' || (resultOuter.template.type == 'status' && resultOuter.message != null && resultOuter.message != '' && resultOuter.message != 'None') || resultOuter.template.type == 'schedule')"
                    :data-vertex="resultOuter.vertex"
                    :data-rule="resultOuter.rule"
                  >
                    <img :src="getShopLogoImage" />

                    <span class="custom_tooltip_text right">{{ renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false) }}</span>
                  </div>

                  <div
                    class="botProfileImageInitialChar custom_tooltip_container"
                    v-if="resultOuter.agent != null && (resultOuter.template.type == 'note' || (resultOuter.template.type == 'status' && resultOuter.message != null && resultOuter.message != '' && resultOuter.message != 'None') || resultOuter.template.type == 'schedule')"
                  >
                    <img
                      :src="resultOuter.agent.picture"
                      v-if="resultOuter.agent.picture != ''"
                    />

                    <div
                      class="botProfileImageInitialCharTextOnly"
                      v-if="resultOuter.agent.picture == ''"
                    >
                      {{ getAgentNameInitials(resultOuter.agent.name) }}
                    </div>

                    <span class="custom_tooltip_text right">{{ renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false) }}</span>
                  </div>
                  <!-- bot/agent replied end -->
                </div>
                <div
                  class="repliedTrue_container"
                  v-else
                >
                  <div
                    v-for="result in resultOuter.template"
                    :key="result.type"
                    :data-type="result.type"
                  >
                    <!-- message template start -->
                    <div
                      class="messageTemplateContainer botMsgBubble statusMessageType"
                      v-if="resultOuter.template.type == 'status' && resultOuter.message != null && resultOuter.message != '' && resultOuter.message != 'None'"
                    >
                      <div class="normalchat_component msg_component">
                        <div>
                          <ion-badge color="light">Status info. message</ion-badge>
                        </div>
                        <div v-html="renderNormalChat(resultOuter.message)"></div>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'text'"
                    >
                      <div
                        class="normalchat_component msg_component"
                        v-html="renderNormalChat(result.message)"
                      ></div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'contact'"
                    >
                      <div
                        class="normalchat_component msg_component"
                        style="padding: 0px; border: 1px #e4e6eb solid; overflow: hidden;"
                      >
                        <ion-item lines="none">
                          <ion-avatar slot="start">
                            <img src="https://jumper.ai/web-s/resources/images/default-customer.svg">
                          </ion-avatar>
                          <ion-label>
                            <h2>{{ result.name }}</h2>
                            <p>{{ result.phone }}</p>
                          </ion-label>
                        </ion-item>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'location'"
                    >
                      <div class="normalchat_component msg_component">
                        <ion-icon :icon="locationOutline"></ion-icon>
                        <span v-html="renderNormalChat(result.message)"></span>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'image' || (result.type == 'file' && result.filetype == 'image')"
                    >
                      <div class="image_component msg_component">
                        <img
                          :src="checkIfThereIsNoLogoThenShowTransparentImage(result.message)"
                          class="imageInChat"
                        />
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'video' || (result.type == 'file' && result.filetype == 'video')"
                    >
                      <div class="video_component msg_component">
                        <video
                          class="videoInChat"
                          controls
                          controlslist="nodownload"
                        >
                          <source
                            :src="sanitizeHardLinkUrl(result.message)"
                            type="video/mp4"
                          >
                        </video>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="(result.type == 'file' && !result.filetype) || (result.type == 'file' && result.filetype == 'document')"
                    >
                      <div class="normalchat_component msg_component">
                        <a
                          :href="sanitizeHardLinkUrl(result.message)"
                          target="_blank"
                          rel="noreferer noopener"
                        >Click here to open file in new window</a>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'quick_replies' || result.type == 'payment_replies'"
                    >
                      <div
                        class="normalchat_component msg_component"
                        v-if="result.message"
                      >
                        <div
                          class="messageArea"
                          v-html="renderNormalChat(result.message)"
                        ></div>
                      </div>

                      <div class="quickRepliesArea">
                        <div
                          class="singleQuickReply"
                          v-for="button in result.buttons"
                          :key="button.title"
                        >{{ button.title }}</div>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'button_message' || (result.type == 'payment' && result.buttons)"
                    >
                      <div class="buttonMessage_component msg_component">
                        <div
                          class="messageArea"
                          v-html="renderNormalChat(result.message)"
                        ></div>
                        <div class="buttonsArea">
                          <button
                            type="button"
                            class="singleCardBigButton"
                            v-for="button in result.buttons"
                            :key="button.title"
                          >{{ button.title }}</button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'generic_message' || (result.type == 'payment' && !result.buttons)"
                    >
                      <div class="carousel_component msg_component">
                        <div
                          class="singleCardArea"
                          v-for="carousel in result.message"
                          :key="carousel.title"
                        >
                          <img
                            width="268"
                            height="150"
                            :src="checkIfThereIsNoLogoThenShowTransparentImage(carousel.image_url)"
                            class="carouselCardImg"
                            v-if="carousel.image_url && carousel.image_url != ''"
                          />

                          <div class="titleArea">
                            <p class="title">{{ carousel.title }}</p>
                            <p
                              class="subTitle"
                              v-html="renderNormalChat(carousel.subtitle)"
                            ></p>
                          </div>

                          <div class="buttonsArea">
                            <button
                              type="button"
                              class="singleCardBigButton"
                              v-for="button in carousel.buttons"
                              :key="button.title"
                            >{{ button.title }}</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="messageTemplateContainer botMsgBubble"
                      v-if="result.type == 'orderConfirmation'"
                    >
                      <div class="confirmationBubbleWithCart">
                        <ion-row class="top_recpt pad_zero">
                          <ion-col>
                            <p>{{ result.keywords.OrderConfirmation }}</p>
                          </ion-col>
                        </ion-row>

                        <ion-row
                          class="row recpt_sec1"
                          v-if="result.message.cart.length == 0"
                        >
                          <ion-col
                            size="3"
                            class="recp_cont_img pad_zero"
                          >
                            <img :src="result.message.displayimage">
                          </ion-col>
                          <ion-col size="9">
                            <div class="pad_zero recpt_sec2">
                              <h3 class="mrg_zero">{{ result.message.product.name }}</h3>
                            </div>
                            <div class="pad_zero recpt_sec3">
                              <p class="inline">{{ result.message.variation }}</p>
                              <p class="inline">
                                <span>Qty.:</span>

                                {{ result.message.quantity }}
                              </p>
                            </div>
                          </ion-col>
                        </ion-row>

                        <div v-if="result.message.cart.length > 0">
                          <ion-row
                            class="row recpt_sec1"
                            v-for="cart in result.message.cart"
                            :key="cart.id"
                          >
                            <ion-col
                              size="3"
                              class="recp_cont_img pad_zero"
                            >
                              <img :src="cart.image">
                            </ion-col>
                            <ion-col size="9">
                              <div class="pad_zero recpt_sec2">
                                <h3 class="mrg_zero">{{ cart.name }}</h3>
                              </div>
                              <div class="pad_zero recpt_sec3">
                                <p
                                  class="inline"
                                  v-html="convertCommaToLineBreak(cart.subtitle)"
                                ></p>
                                <p class="inline">
                                  <span>Qty.:</span>

                                  {{ cart.quantity }}
                                </p>
                              </div>
                            </ion-col>
                          </ion-row>
                        </div>

                        <ion-row
                          class="recpt_sec5"
                          v-if="result.message.address.addressLine1 && result.message.address.addressLine1 != ''"
                        >
                          <ion-col>
                            <p>{{ result.keywords.Shipto }}:</p>
                            <h4>{{ result.message.address.addressLine1 }}, {{ result.message.address.city }}, {{ result.message.address.pincode }}, {{ result.message.address.country }}</h4>
                          </ion-col>
                        </ion-row>

                        <ion-row class="recpt_sec5">
                          <ion-col>
                            <p>{{ result.keywords.Summary }}:</p>

                            <div
                              class="displayBlock"
                              v-if="result.message.subtotal && result.message.restaurant != true"
                            >
                              <h4 class="inline left_algn">Subtotal</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.subtotal,currency) }}</h4>
                            </div>

                            <div
                              class="displayBlock"
                              v-if="result.message.discount && result.message.discount != '' && result.message.discount != 'None' && result.message.discount != null && result.message.discount != '0.00' && result.message.discount != '0' && result.message.discount != 0.00 && result.message.discount != 0"
                            >
                              <h4 class="inline left_algn">Discount</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.discount,currency) }}</h4>
                            </div>

                            <div
                              class="displayBlock"
                              v-if="result.message['reward discount'] && result.message['reward discount'] != '' && result.message['reward discount'] != 'None' && result.message['reward discount'] != null && result.message['reward discount'] != '0.00' && result.message['reward discount'] != '0' && result.message['reward discount'] != 0.00 && result.message['reward discount'] != 0"
                            >
                              <h4 class="inline left_algn">Reward</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}<span v-html="result.message['reward discount']"></span></h4>
                            </div>

                            <span v-if="result.message['discount'] != result.message['total discount']">
                              <div
                                class="displayBlock"
                                v-if="result.message['additional discount'] && result.message['additional discount'] != '' && result.message['additional discount'] != 'None' && result.message['additional discount'] != null && result.message['additional discount'] != '0.00' && result.message['additional discount'] != '0' && result.message['additional discount'] != 0.00 && result.message['additional discount'] != 0"
                              >
                                <h4 class="inline left_algn">Additional discount</h4>
                                <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}<span v-html="result.message['additional discount']"></span></h4>
                              </div>

                              <div
                                class="displayBlock"
                                v-if="result.message['total discount'] && result.message['total discount'] != '' && result.message['total discount'] != 'None' && result.message['total discount'] != null && result.message['total discount'] != '0.00' && result.message['total discount'] != '0' && result.message['total discount'] != 0.00 && result.message['total discount'] != 0"
                              >
                                <h4 class="inline left_algn">Total discount</h4>
                                <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}<span v-html="result.message['total discount']"></span></h4>
                              </div>
                            </span>

                            <div
                              class="displayBlock"
                              v-if="result.message.shippingcost"
                            >
                              <h4 class="inline left_algn">Shipping</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.shippingcost,currency) }}</h4>
                            </div>

                            <div class="displayBlock">
                              <h4 class="inline left_algn">Tax</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.tax,currency) }}</h4>
                            </div>

                            <div
                              class="displayBlock"
                              v-if="result.message.tipamount && result.message.tipamount != '' && result.message.tipamount != 'None' && result.message.tipamount != null && result.message.tipamount != '0.00' && result.message.tipamount != '0' && result.message.tipamount != 0.00 && result.message.tipamount != 0"
                            >
                              <h4 class="inline left_algn">Tip</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.tipamount,currency) }}</h4>
                            </div>

                            <div class="displayBlock">
                              <h4 class="inline left_algn">Total</h4>
                              <h4 class="inline right_algn">{{ renderCurrencySymbol(currency) }}{{ formatAmountInLocale(result.message.total,currency) }}</h4>
                            </div>
                          </ion-col>
                        </ion-row>

                        <ion-row class="recpt_sec6">
                          <ion-col class="recpt_sec7">
                            <p class="inlineBlock">{{ result.keywords.OrderNo }}.: &nbsp;</p>
                            <p class="inlineBlock">{{ result.message.ordernumber }}</p>
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                    <!-- message template end -->

                    <!-- bot/agent replied start -->
                    <div
                      class="botProfileImageInitialChar custom_tooltip_container"
                      data-placement="left"
                      v-if="resultOuter.agent == null"
                      :data-vertex="resultOuter.vertex"
                      :data-rule="resultOuter.rule"
                    >
                      <img :src="getShopLogoImage" />

                      <span class="custom_tooltip_text right">{{ renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false) }}</span>
                    </div>

                    <div
                      class="botProfileImageInitialChar custom_tooltip_container"
                      v-if="resultOuter.agent != null"
                    >
                      <img
                        :src="resultOuter.agent.picture"
                        v-if="resultOuter.agent.picture != ''"
                      />

                      <div
                        class="botProfileImageInitialCharTextOnly"
                        v-if="resultOuter.agent.picture == ''"
                      >
                        {{ getAgentNameInitials(resultOuter.agent.name) }}
                      </div>

                      <span class="custom_tooltip_text right">{{ renderDateTime_lastReplyTimestamp(resultOuter.created, 'customReadable', false) }}</span>
                    </div>
                    <!-- bot/agent replied end -->

                    <!-- reactions viewer start -->
                    <div>

                    </div>
                    <!-- reactions viewer end -->

                    <!-- reactions opener start -->
                    <div>

                    </div>
                    <!-- reactions opener end -->

                    <!-- message status start -->
                    <div
                      class="messageReadStatusArea"
                      v-if="resultOuter.status && resultOuter.status != null"
                    >
                      <ion-icon
                        color="medium"
                        :icon="checkmarkOutline"
                        v-if="resultOuter.status == 'sent'"
                      ></ion-icon>

                      <ion-icon
                        color="medium"
                        :icon="checkmarkDoneOutline"
                        v-if="resultOuter.status == 'delivered'"
                      ></ion-icon>

                      <ion-icon
                        color="dark"
                        :icon="checkmarkDoneOutline"
                        v-if="resultOuter.status == 'read'"
                      ></ion-icon>
                    </div>
                    <!-- message status end -->

                    <!-- undelivered message tag start -->
                    <div
                      class="undeliveredMessageInfoArea custom_tooltip_container"
                      v-if="resultOuter.delivered == false"
                    >
                      <ion-icon :icon="warningOutline"></ion-icon>

                      <span
                        class="custom_tooltip_text right"
                        v-if="resultOuter.fail_reason && resultOuter.fail_reason != '' && resultOuter.fail_reason != 'None' && resultOuter.fail_reason != null"
                      >{{ resultOuter.fail_reason }}</span>

                      <span
                        class="custom_tooltip_text right"
                        v-if="!resultOuter.fail_reason || resultOuter.fail_reason == '' || resultOuter.fail_reason == 'None' || resultOuter.fail_reason == null"
                      >Message not delivered - we are waiting for a reply from the user to engage.</span>
                    </div>
                    <!-- undelivered message tag end -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- composer input area start -->
          <div
            class="humanTakeoverMessageInputContainer"
            v-if="chatInputBarLoadingIsOn == false && composerInputAllowed == true && composerInputValid_asPerTime == true && thisIsAValidConversation_bool == true"
          >
            <ion-badge
              class="botActiveTextNoticeBarOverInput"
              color="success"
              v-if="activeCustomer.active == true && noteMode == false && !isWhatsappChat_old24H && !isWhatsappChat_sendTemplate"
            >Bot is active, type a message to pause the bot and take over the conversation.</ion-badge>

            <div
              class="inputContainerInnerInline mobileLikeInputContainer"
              v-if="checkIfToShow_inputContainerInnerInline"
              style="display: flex;"
            >
              <button
                type="button"
                class="submitBtn bottomProductChatSendBtn"
                id="bottomProductChatSendBtn"
                @click="() => actionModal = true"
                v-if="actionsPanelEnabled == true && thisIsAValidConversation_bool == true && (calculate24Plus1RuleForCustomer(activeCustomer.userReplyTimestamp) < 24 || (activeCustomer.channel != 'facebook' && activeCustomer.channel != 'whatsapp' && activeCustomer.channel != 'instagram_off'))"
                style="align-self: flex-end;"
              >
                <ion-icon
                  style="font-size: 24px; color: white;"
                  slot="icon-only"
                  :icon="addCircleOutline"
                ></ion-icon>
              </button>

              <my-input
                textarea
                :style="noteMode ? { backgroundColor: '#FFF5CC' } : { backgroundColor: '#f1f1f1' }"
                id="newChatMessage"
                emoji
                name="bottomProductChatInputText"
                :value="newChatMessage"
                @onInput="changeChatInput"
                @onKeyPress="newMessageKeyPressHandler"
                placeholder="Type a message"
                :maxlength="500"
              />

              <button
                :disabled="newChatMessage == undefined || newChatMessage == ''"
                type="button"
                class="submitBtn bottomProductChatSendBtn"
                id="bottomProductChatSendBtn"
                @click="sendMessage"
                style="align-self: flex-end;"
              >
                <img src="../resources/images/send-chat-icon.png" />
              </button>

              <div
                v-show="newChatMessage == undefined || newChatMessage == ''"
                class="actionButtonsOverChatSendInputBar_container"
              >
                <img
                  @click="() => sendWhatsappTemplate = true"
                  src="https://jumper.ai/web-s/resources/images/page-inbox-send-template.svg"
                  class="singleActionButtonIcon"
                  title="Send template"
                  v-if="activeCustomer.channel == 'whatsapp'"
                >

                <img
                  @click="() => fb_notification_optin_container_visible = true"
                  src="https://jumper.ai/web-s/resources/images/page-inbox-send-template.svg"
                  class="singleActionButtonIcon"
                  title="Send opt-in notification"
                  v-if="activeCustomer.channel == 'facebook' && fbNotificationOptinsResult.length > 0"
                >

                <img
                  @click="switchNoteMode"
                  src="https://jumper.ai/web-s/resources/images/page-inbox-add-note.svg"
                  class="singleActionButtonIcon"
                >

                <img
                  src="https://jumper.ai/web-s/resources/images/page-inbox-attach.svg"
                  class="singleActionButtonIcon"
                  id="inchat_uploadImageToChatImageElem"
                  @click="sendImage(false)"
                >
              </div>

              <!-- <input type="hidden" id="noteMentionUserData" style="display: none;" value="" /> -->
            </div>

            <div
              class="chatInputBarLoadingArea text-center"
              v-if="activeCustomer.channel == 'whatsapp' && (isWhatsappChat_old24H || isWhatsappChat_sendTemplate)"
            >
              <div style="padding: 10px 15px 5px 15px;">
                <ion-text style="font-size: 13px;">You can not initiate an action for a customer who's last message was received more than 24 hours ago</ion-text>
              </div>
              <div>
                <ion-button
                  fill="outline"
                  color="dark"
                  size="default"
                  @click="() => sendWhatsappTemplate = true"
                  style="margin-bottom: 10px;"
                >Send Template Message</ion-button>
              </div>
            </div>
          </div>

          <div
            class="chatInputBarLoadingArea text-center"
            v-if="chatInputBarLoadingIsOn == true"
          >
            <div>
              <p class="loadingTextWithImage">
                <ion-icon
                  color="medium"
                  :icon="cloudUploadOutline"
                  style="font-size: 20px; vertical-align: middle;"
                ></ion-icon>
                <ion-text
                  color="medium"
                  style="vertical-align: middle; padding-left: 8px;"
                >Uploading...</ion-text>
              </p>
            </div>
          </div>

          <div
            class="chatInputBarLoadingArea text-center"
            v-if="composerInputAllowed == false || composerInputValid_asPerTime == false"
          >
            <div>
              <p class="loadingTextWithImage">
                <ion-text
                  color="medium"
                  v-if="composerInputValid_asPerTime == true"
                >Composer input is disabled</ion-text>

                <ion-text
                  color="medium"
                  v-if="composerInputValid_asPerTime == false && activeCustomer.channel == 'facebook'"
                >Messages can be sent within 7 days after a user message. If you still need to message the user, you may have to do so from the Facebook Inbox</ion-text>

                <ion-text
                  color="medium"
                  v-if="composerInputValid_asPerTime == false && activeCustomer.channel == 'instagram'"
                >Messages can be sent within 7 days after a user message.</ion-text>
              </p>
            </div>
          </div>

          <div
            class="chatInputBarLoadingArea text-center"
            v-if="thisIsAValidConversation_bool == false"
          >
            <div>
              <p class="loadingTextWithImage">
                <ion-text color="medium">Composer input is disabled because this conversation is invalid!</ion-text>
              </p>
            </div>
          </div>

          <div
            class="chatInputBarLoadingArea text-center"
            v-if="sending"
          >
            <div>
              <p class="loadingTextWithImage">
                <ion-icon
                  color="medium"
                  :icon="chatbubblesOutline"
                  style="font-size: 20px; vertical-align: middle;"
                ></ion-icon>
                <ion-text
                  color="medium"
                  style="vertical-align: middle; padding-left: 8px;"
                >Sending message...</ion-text>
              </p>
            </div>
          </div>
          <!-- composer input area end -->
        </div>
      </div>
      <!-- chats end -->

      <!-- profile start -->
      <div v-if="receiverDetailMode">
        <single-user-detail
          v-if="activeCustomer"
          :conversationid="conversationid"
          :activeCustomer="activeCustomer"
          @custom_fields_updated="init('customData')"
          @tags_updated="init('tags')"
          @user_info_updated="init('userData')"
          :agentReAssignmentAllowed="agentReAssignmentAllowed"
          @reassign="() => reAssignModal = true"
        />
      </div>
      <!-- profile end -->
    </ion-content>

    <ion-modal
      v-if="createOrderModal"
      :is-open="createOrderModal"
      @didDismiss="() => createOrderModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Create order</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => createOrderModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <iframe
            style="width: 100%; height: calc(100% - 51px); border: none;"
            :src="createOrderModal_iframeUrl"
            title="Create order"
            allowpaymentrequest="true"
          ></iframe>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="shareOrderModal"
      :is-open="shareOrderModal"
      @didDismiss="() => shareOrderModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Share product details</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => shareOrderModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <iframe
            style="width: 100%; height: calc(100vh - 51px); border: none;"
            :src="shareOrderModal_iframeUrl"
            title="Share product details"
            allowpaymentrequest="true"
          ></iframe>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="reAssignModal && allGroupsResult.length"
      :is-open="reAssignModal"
      @didDismiss="() => reAssignModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Re-assign</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => reAssignModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div v-if="manageUserRoles_v2 == false">
            <div v-if="allAgentsResult.length">
              <ion-item
                v-for="thisRole in allAgentsResult"
                :key="thisRole.id"
                lines="full"
              >
                <ion-avatar
                  class="img-container"
                  slot="start"
                >
                  <img :src="thisRole.picture && thisRole.picture.length ? thisRole.picture : 'https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg'" />
                </ion-avatar>
                <ion-label>{{ thisRole.name }}</ion-label>
                <ion-button
                  @click="assignChat('agent', thisRole.id)"
                  slot="end"
                >
                  Assign
                </ion-button>
              </ion-item>
            </div>
          </div>
          <div v-else>
            <ion-item>
              <ion-label>Select a group</ion-label>
              <ion-select
                interface="popover"
                v-model="selectedReassignGroup"
                placeholder="Select group"
              >
                <ion-select-option
                  v-for="thisbRole in allGroupsResult"
                  :key="thisbRole.id"
                  :value="thisbRole.id"
                >{{ thisbRole.name }}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item>
              <ion-label position="stacked">Search agent</ion-label>
              <ion-input
                type="text"
                class="fullBordered"
                v-model="searchAgentToReassignText"
                @change="searchAgentToReassignTextChanger"
                @keyup="searchAgentToReassignTextChanger"
                placeholder="Enter a name..."
                style="margin-bottom: 15px;"
              ></ion-input>
            </ion-item>

            <div v-if="!loadingRoles">
              <span v-if="allAgentsResult_apiBased_data.length == 0">
                <div v-if="selectedReassignGroup">
                  <ion-item lines="full">
                    <ion-avatar
                      class="img-container"
                      slot="start"
                    >
                      <img src="https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg" />
                    </ion-avatar>
                    <ion-label>
                      <h2>Assign to group</h2>
                      <p>The conversation will get assigned to one of the agents in the group</p>
                    </ion-label>
                    <ion-button
                      @click="assignChat('group', selectedReassignGroup)"
                      slot="end"
                    >
                      Assign
                    </ion-button>
                  </ion-item>
                </div>
                <div v-if="allAgentsOfSelectedGroupResult.length">
                  <ion-item
                    v-for="thisRole in allAgentsOfSelectedGroupResult"
                    :key="thisRole.id"
                    lines="full"
                  >
                    <ion-avatar
                      class="img-container"
                      slot="start"
                    >
                      <img :src="thisRole.picture && thisRole.picture.length ? thisRole.picture : 'https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg'" />
                    </ion-avatar>
                    <ion-label>{{ thisRole.name }}</ion-label>
                    <ion-button
                      @click="assignChat('agent', thisRole.id)"
                      slot="end"
                    >
                      Assign
                    </ion-button>
                  </ion-item>
                </div>
              </span>
              <span v-if="allAgentsResult_apiBased_data.length > 0">
                <div v-if="allAgentsResult_apiBased_data.length">
                  <ion-item
                    v-for="thisRole in allAgentsResult_apiBased_data"
                    :key="thisRole.id"
                    lines="full"
                  >
                    <ion-avatar
                      class="img-container"
                      slot="start"
                    >
                      <img :src="thisRole.picture && thisRole.picture.length ? thisRole.picture : 'https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg'" />
                    </ion-avatar>
                    <ion-label>{{ thisRole.name }}</ion-label>
                    <ion-button
                      @click="assignChat('agent', thisRole.id)"
                      slot="end"
                    >
                      Assign
                    </ion-button>
                  </ion-item>
                </div>
              </span>
            </div>
            <ion-loading
              v-else
              :is-open="loadingRoles"
              cssClass="my-custom-class"
              message="Please Wait..."
            >
            </ion-loading>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="sendWhatsappTemplate"
      :is-open="sendWhatsappTemplate"
      @didDismiss="() => {sendWhatsappTemplate = false; resetTemplateDataForWA(); newChatMessage = undefined;}"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Send template message</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => {sendWhatsappTemplate = false; resetTemplateDataForWA(); newChatMessage = undefined;}">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div>
            <ion-item>
              <ion-label>Select template</ion-label>
              <ion-select
                interface="popover"
                v-model="waTemplateToSend.template"
                placeholder="Select template"
              >
                <ion-select-option
                  v-for="thisTemplate in whatsappTemplates"
                  :key="thisTemplate.template_name"
                  :value="thisTemplate.template_name"
                >{{ thisTemplate.template_name }}</ion-select-option>
              </ion-select>
            </ion-item>
            <!-- <ion-item>
              <ion-label>Select language</ion-label>
              <ion-select interface="popover" v-model="waTemplateToSend.language" placeholder="Select language">
                <ion-select-option v-for="thisLanguage in waLanguages" :key="thisLanguage.language" :value="thisLanguage.language">{{ thisLanguage.lang_full_name }}</ion-select-option>
              </ion-select>
            </ion-item> -->

            <ion-text
              color="medium"
              class="text-center waTemplateLoadingText"
              v-if="processingWaTemplates"
            >
              Please wait...
            </ion-text>
            <div v-if="processingWaTemplates == false && messageJson.template && messageJson.template.text">
              <!-- pending -->
              <ion-item lines="none">
                <figure
                  class="waTemplateMessageFigure"
                  v-if="messageJson.template && messageJson.template.text"
                >
                  <pre class="waTemplateMessagePre">
                    <p v-if="messageJson.header && messageJson.header.format == 'TEXT'">
                      <b v-html="messageJson.header.text"></b>
                    </p>
                    
                    <div v-html="messageJson.template.text"></div>

                    <div class="quickRepliesArea" v-if="messageJson.template.quick_replies.length > 0">
                      <div class="singleQuickReply" v-for="resultQrBtns in messageJson.template.quick_replies" :key="resultQrBtns.payload">
                        {{ resultQrBtns.title }}

                        <input type="hidden" class="contentEditableInput ceForQuickReply defaultPlaceholderForQuickReplyButton" :value="resultQrBtns.title" readonly />
                        <input type="hidden" id="ceForQuickReplyDescription" :value="resultQrBtns.description" />
                        <input type="hidden" id="ceForQuickReplyPayload" :value="resultQrBtns.payload" />
                      </div>
                    </div>
                  </pre>
                </figure>
              </ion-item>

              <ion-item lines="none">
                <ion-button
                  expand="block"
                  fill="solid"
                  size="default"
                  color="dark"
                  @click="sendTemplate"
                >
                  Send
                </ion-button>
              </ion-item>

              <ion-item lines="none">
                <ion-text color="medium">
                  <ion-icon :icon="informationCircleOutline"></ion-icon>
                  You will be charged for this message
                </ion-text>
              </ion-item>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="startBotFlowModal"
      :is-open="startBotFlowModal"
      @didDismiss="() => startBotFlowModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Share bot flow to chat</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => startBotFlowModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-loading
            v-if="loadingStartBotFlowModal"
            :is-open="loadingStartBotFlowModal"
            cssClass="my-custom-class"
            message="Please wait..."
          >
          </ion-loading>
          <div v-else>
            <div v-if="allActiveBotBuilderFlowsResult.length > 0">
              <div v-for="thisBotFlow in allActiveBotBuilderFlowsResult" :key="thisBotFlow.id">
                <ion-item lines="full" v-if="checkForValidSalesChannelOfFlowAndUser(thisBotFlow.saleschannel, activeCustomer.channel)">
                  <ion-label>{{ thisBotFlow.displayname }}</ion-label>
                  <ion-button
                    @click="() => {botFlowToShare = thisBotFlow; confirmSharingBotFlow = true;}"
                    slot="end"
                  >
                    Share
                  </ion-button>
                </ion-item>
              </div>
            </div>

            <div
              class="text-center"
              v-else
              style="width: 100%; padding: 15px; margin-top: 20vh;"
            >
              <h5>No bot flows found!</h5>
              <ion-text color="medium">Seems like there is no bot flow created or none are active</ion-text>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="confirmSharingBotFlow"
      :is-open="confirmSharingBotFlow"
      header="Are you sure?"
      :sub-header="activeCustomer.name +' will be able to interact with this bot.'"
      message="This will end any automated conversation that they are interacting with"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          botFlowToShare = null;
          confirmSharingBotFlow = false;
          //startBotFlowModal = false;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          startABotBuilderFlowForThisTrigger()
        },
      }]"
      @didDismiss="() => confirmSharingBotFlow = false"
    >
    </ion-alert>
    <ion-modal
      v-if="sendLocationModal"
      :is-open="sendLocationModal"
      @didDismiss="() => sendLocationModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Send location</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="() => sendLocationModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-item lines="none">
            <ion-label position="stacked">Button title</ion-label>
            <ion-input
              type="text"
              class="fullBordered"
              v-model="sendLocation.buttonTitle"
            ></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label position="stacked">Address</ion-label>
            <ion-input
              type="text"
              class="fullBordered"
              id="autocomplete"
              v-model="sendLocation.address"
              autocomplete="off"
            ></ion-input>
          </ion-item>
          <ion-item
            lines="none"
            style="margin-top: 15px;"
          >
            <ion-button
              expand="block"
              fill="solid"
              size="default"
              color="dark"
              @click="sendLocationTemplate"
            >
              Send
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="sendContactModal"
      :is-open="sendContactModal"
      @didDismiss="() => sendContactModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Send contact</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="() => sendContactModal = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-item lines="none">
            <ion-label position="stacked">Name</ion-label>
            <ion-input
              type="text"
              class="fullBordered"
              v-model="sendContact.buttonTitle"
            ></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label position="stacked">Number</ion-label>
            <ion-input
              type="text"
              class="fullBordered"
              id="autocomplete"
              v-model="sendContact.number"
              autocomplete="off"
            ></ion-input>
          </ion-item>
          <ion-item
            lines="none"
            style="margin-top: 15px;"
          >
            <ion-button
              expand="block"
              fill="solid"
              size="default"
              color="dark"
              @click="sendContactTemplate"
            >
              Send
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="attachAndSendModal"
      :is-open="attachAndSendModal"
      @didDismiss="() => attachAndSendModal = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Attach & send to chat</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="() => {attachAndSendModal = false; filesToSend = []; pendingUpload = false; chatInputBarLoadingIsOn = false; document.getElementById('sendImageHiddenInput').value = ''; document.getElementById('sendVideoHiddenInput').value = ''; document.getElementById('sendDocumentHiddenInput').value = '';}">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar
            v-if="activeCustomer.channel === 'whatsapp'"
            style="position: sticky; top: 56px;"
            color="light"
          >
            <ion-segment
              scrollable
              color="dark"
              v-model="activeSendAndAttachTab"
            >
              <ion-segment-button
                value="recentTab"
                @click="() => { filesToSend = []; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
              >
                <ion-label>
                  Recently uploaded
                </ion-label>
              </ion-segment-button>
              <ion-segment-button
                value="imageTab"
                @click="() => { filesToSend = []; document.getElementById('sendImageHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
              >
                <ion-label>
                  Image
                </ion-label>
              </ion-segment-button>
              <ion-segment-button
                value="videoTab"
                @click="() => { filesToSend = []; document.getElementById('sendVideoHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
              >
                <ion-label>
                  Video
                </ion-label>
              </ion-segment-button>
              <ion-segment-button
                value="fileTab"
                @click="() => { filesToSend = []; document.getElementById('sendDocumentHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
              >
                <ion-label>
                  File
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-toolbar>
          <div v-if="activeSendAndAttachTab === 'recentTab'">
            <div v-if="recentUploadedFiles.length">
              <ion-item
                v-for="thisFile in recentUploadedFiles"
                :key="thisFile.id"
                lines="full"
              >
                <ion-thumbnail
                  class="img-container documentImage"
                  slot="start"
                >
                  <div
                    class="img-containerInner"
                    :style="renderCustomerImage_style_background(thisFile.url)"
                  ></div>
                </ion-thumbnail>
                <a
                  :href="thisFile.url"
                  target="_blank"
                  rel="noreferer noopener"
                >
                  <span v-if="thisFile.name == '' || thisFile.name == 'None' || thisFile.name == null">{{ thisFile.url }}</span>
                  <span v-else>{{ thisFile.name }}</span>
                </a>
                <ion-button
                  @click="selectRecentUploadFile(thisFile)"
                  slot="end"
                >
                  Send
                </ion-button>
              </ion-item>
            </div>
          </div>
          <div
            style="height: 60%;"
            v-if="activeSendAndAttachTab === 'imageTab'"
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; align-items: center; height: 100%;">
              <div
                v-if="filesToSend && filesToSend.length"
                style="display: block; text-align: center; width: 100%;"
              >
                <img
                  v-for="thisFile in filesToSend"
                  :key="thisFile.randomId"
                  @click="() => filesToSend = []"
                  :src="thisFile.src_path"
                  style="display: inline-block; width: 80px; height: 80px; object-fit: cover; margin: 5px; border: 1px var(--ion-color-medium) solid; border-radius: 5px;"
                />
              </div>
              <img
                v-else
                width="200"
                @click="sendImage(true)"
                src="https://jumper.ai/web-s/resources/images/upload-image-placeholder-purple.svg"
              />
              <div
                class="text-center width100P"
                style="padding: 15px;"
              >
                <ion-text color="medium">
                  <b>Note:</b>
                  Maximum file size: 4 MB
                </ion-text>
              </div>
              <ion-buttons
                style="margin-top: 50px;"
                v-if="filesToSend && filesToSend.length"
                slot="end"
              >
                <ion-button
                  size="default"
                  fill="outline"
                  color="danger"
                  v-if="waitForconfirmation"
                  @click="() => {filesToSend = []; document.getElementById('sendImageHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
                >
                  Clear
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  v-if="waitForconfirmation"
                  @click="confirmSendImages"
                >
                  Confirm
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  disabled
                  v-else
                >
                  Sending...
                </ion-button>
              </ion-buttons>
            </div>
          </div>
          <div
            style="height: 60%;"
            v-if="activeSendAndAttachTab === 'videoTab'"
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; align-items: center; height: 100%;">
              <video
                v-if="filesToSend && filesToSend.length"
                :src="filesToSend[0].src_path"
                width="200"
                height="200"
                autoplay
                controls
              />
              <img
                v-else
                width="200"
                @click="sendVideo(true)"
                src="https://jumper.ai/web-s/resources/images/upload-video-placeholder.svg"
              />

              <div
                class="text-center width100P"
                style="padding: 15px;"
              >
                <ion-text color="medium">
                  <b>Note:</b>
                  Maximum file size: 60 MB
                  <!-- 25 MB if imessage -->
                </ion-text>
              </div>

              <ion-buttons
                style="margin-top: 50px;"
                v-if="filesToSend && filesToSend.length"
                slot="end"
              >
                <ion-button
                  size="default"
                  fill="outline"
                  color="danger"
                  v-if="waitForconfirmation"
                  @click="() => {filesToSend = []; document.getElementById('sendVideoHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
                >
                  Clear
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  v-if="waitForconfirmation"
                  @click="confirmSendVideos"
                >
                  Confirm
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  disabled
                  v-else
                >
                  Sending...
                </ion-button>
              </ion-buttons>
            </div>
          </div>
          <div
            style="height: 60%;"
            v-if="activeSendAndAttachTab === 'fileTab'"
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; align-items: center; height: 100%;">
              <a
                v-if="filesToSend && filesToSend.length"
                :href="filesToSend[0].src_path"
                target="_blank"
              >Open file in new window</a>
              <img
                v-else
                width="200"
                @click="sendDocument(true)"
                src="https://jumper.ai/web-s/resources/images/upload-image-placeholder-dotted.svg"
              />

              <div
                class="text-center width100P"
                style="padding: 15px;"
              >
                <ion-text color="medium">
                  <b>Note:</b>
                  Maximum file size: 60 MB
                  <!-- 25 MB if imessage -->
                </ion-text>
              </div>

              <ion-buttons
                style="margin-top: 50px;"
                v-if="filesToSend && filesToSend.length"
                slot="end"
              >
                <ion-button
                  size="default"
                  fill="outline"
                  color="danger"
                  v-if="waitForconfirmation"
                  @click="() => {filesToSend = []; document.getElementById('sendDocumentHiddenInput').value = ''; pendingUpload = false; chatInputBarLoadingIsOn = false;}"
                >
                  Clear
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  v-if="waitForconfirmation"
                  @click="confirmSendDocuments"
                >
                  Confirm
                </ion-button>
                <ion-button
                  size="default"
                  fill="outline"
                  color="dark"
                  disabled
                  v-else
                >
                  Sending...
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="fb_notification_optin_container_visible"
      :is-open="fb_notification_optin_container_visible"
      @didDismiss="() => fb_notification_optin_container_visible = false"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar
            class="chat-toolbar"
            color="light"
          >
            <ion-title>Send opt-in notification</ion-title>

            <ion-buttons slot="end">
              <ion-button @click="() => fb_notification_optin_container_visible = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div>
            <ion-item>
              <ion-label>Select a template</ion-label>
              <ion-select
                interface="popover"
                v-model="FbOptinNotification_selectedId"
                placeholder="--- Select ---"
              >
                <ion-select-option
                  value="undefined"
                  disabled
                >--- Select ---</ion-select-option>
                <ion-select-option
                  v-for="thisNotification in fbNotificationOptinsResult"
                  :key="thisNotification.notificationid"
                  :value="thisNotification.notificationid"
                >{{ thisNotification.title }}</ion-select-option>
              </ion-select>
            </ion-item>

            <div
              v-if="FbOptinNotification_selectedId"
              class="preview-section"
            >
              <div style="margin-top: 15px; margin-bottom: 7px; padding: 0px 15px">
                <ion-text color="medium">Preview:</ion-text>
              </div>
              <div class="messageTemplateContainer botMsgBubble">
                <div
                  class="carousel_component msg_component"
                  style="text-align: left !important;"
                >
                  <div class="singleCardArea">
                    <img
                      width="268"
                      height="150"
                      :src="checkIfThereIsNoLogoThenShowTransparentImage(notificationToSend.imageurl)"
                      class="carouselCardImg"
                    />

                    <div class="titleArea">
                      <p class="title">{{ notificationToSend.title }}</p>

                      <p
                        class="subTitle"
                        v-if="notificationToSend.frequency == 'DAILY'"
                      >You may receive additional messages on this topic over the next 6 months.</p>
                      <p
                        class="subTitle"
                        v-if="notificationToSend.frequency == 'WEEKLY'"
                      >You may receive additional messages on this topic over the next 9 months.</p>
                      <p
                        class="subTitle"
                        v-if="notificationToSend.frequency == 'MONTHLY'"
                      >You may receive additional messages on this topic over the next 12 months.</p>
                    </div>

                    <div class="buttonsArea">
                      <button
                        type="button"
                        class="singleCardBigButton"
                        v-if="notificationToSend.frequency == 'DAILY'"
                      >Get daily messages</button>
                      <button
                        type="button"
                        class="singleCardBigButton"
                        v-if="notificationToSend.frequency == 'WEEKLY'"
                      >Get weekly messages</button>
                      <button
                        type="button"
                        class="singleCardBigButton"
                        v-if="notificationToSend.frequency == 'MONTHLY'"
                      >Get monthly messages</button>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding: 0px 15px;">
                <ion-button
                  :disabled="!notificationToSend"
                  fill="solid"
                  color="dark"
                  size="default"
                  @click="sendFbNotificationOptin"
                  style="margin-bottom: 10px;"
                >Send</ion-button>
              </div>
            </div>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="confirmTakeoverChatFromOtherAgent"
      :is-open="confirmTakeoverChatFromOtherAgent"
      header="Alert"
      :message="'This chat is already assigned to '+ activeCustomer.assigned_to.name +'. Do you wish to take over the conversation from them?'"
      :buttons="[{
        text: 'Don\'t Reassign',
        role: 'cancel',
        handler: () => {
          confirmTakeoverChatFromOtherAgent = false;
          taskToBeDone_after_confirmTakeoverChatFromOtherAgent = '';
        },
      },
      {
        text: 'Confirm',
        handler: () => {
          if(taskToBeDone_after_confirmTakeoverChatFromOtherAgent == 'sendMessageFinal'){
            sendMessageFinal();
          }
        },
      }]"
      @didDismiss="() => {confirmTakeoverChatFromOtherAgent = false; taskToBeDone_after_confirmTakeoverChatFromOtherAgent = '';}"
    >
    </ion-alert>
  </ion-col>
</template>
<style scoped>
</style>
<script>
import "/src/resources/css/page-inbox.css";
import {
  personCircleOutline,
  chatbubblesOutline,
  ellipsisHorizontal,
  chevronDownCircleOutline,
  checkmarkCircleOutline,
  star,
  starOutline,
  addCircleOutline,
  warningOutline,
  checkmarkOutline,
  checkmarkDoneOutline,
  informationCircleOutline,
  attachOutline,
  cloudUploadOutline,
  locationOutline,
  close,
  arrowBack,
} from "ionicons/icons";
import { mapGetters } from "vuex";
import jwtInterceptor from "/src/shared/jwtInterceptor";
/* import mixins */
import chatMixin from "../mixins/chat.js";
import {
  IonActionSheet,
  IonBadge,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import singleUserDetail from "../components/singleUserDetail";
import { io } from "socket.io-client";

export default {
  mixins: [chatMixin],
  props: {
    conversationid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      socket: null,
      personCircleOutline,
      chatbubblesOutline,
      ellipsisHorizontal,
      chevronDownCircleOutline,
      addCircleOutline,
      warningOutline,
      checkmarkOutline,
      checkmarkDoneOutline,
      informationCircleOutline,
      attachOutline,
      cloudUploadOutline,
      locationOutline,
      close,
      star,
      starOutline,
      arrowBack,
      loading: false,
      preferredCountries: ["sg"],
      initialCountry: "sg",
      isWhatsappChat_old24H: false,
      isWhatsappChat_sendTemplate: false,
      isWhatsappSendTemplateEnabled: false,
      whatsappTemplates: [],
      selectedWhatsappTemplate: "-1",
      whatsappTemplateLanguages: [],
      selectedTemplateLanguage: "-1",
      sendWhatsappTemplate: false,
      waTemplateToSend: {
        template: undefined,
        language: undefined,
        templateData: null,
      },
      processingWaTemplates: false,
      fb_notification_optin_container_visible: false,
      fbNotificationOptinsResult: [],
      sendFbNotificationOptin_sending: false,
      FbOptinNotification_selectedId: undefined,
      simpleMessageTags: [],
      UPLOAD_FILE_TYPE: {
        image: "image",
        video: "video",
        document: "document",
      },
      messageJson: {},
      activeCustomer: {},
      activeUserConversationDataResult: [],
      customersResults: [],
      customersResultsLoading: false,
      chatInputBarLoadingIsOn: false,
      allAgentsResult: [],
      allGroupsResult: [],
      allAgentsOfSelectedGroupResult: [],
      allAgentsResult_apiBased_data: [],
      activeUserConversationCursor: undefined,
      activeUserConversationMore: false,
      loadMoreChatLoadingShow: false,
      receiverDetailMode: false,
      newChatMessage: undefined,
      page_conversation_id: undefined,
      pageid: undefined,
      platform: undefined,
      sending: undefined,
      isMenuOpen: false,
      fileToSend: null,
      filesToSend: [],
      pendingUpload: false,
      noteMode: false,
      mentionAgentMode: false,
      sendShortcutMode: false,
      allShortcuts: [],
      actionModal: false,
      createOrderModal: false,
      shareOrderModal: false,
      createOrderModal_iframeUrl: "",
      shareOrderModal_iframeUrl: "",
      startBotFlowModal: false,
      loadingStartBotFlowModal: false,
      allActiveBotBuilderFlowsResult: [],
      botFlowToShare: null,
      confirmSharingBotFlow: false,
      confirmTakeoverChatFromOtherAgent: false,
      taskToBeDone_after_confirmTakeoverChatFromOtherAgent: "",
      selectedReassignGroup: undefined,
      searchByNameOrEmailText: "",
      loadingRoles: false,
      reAssignModal: false,
      agentReAssignmentAllowed: true,
      composerInputAllowed: true,
      composerInputValid_asPerTime: true,
      thisIsAValidConversation_bool: true,
      actionsPanelEnabled: true,
      pusher_channel_liveChat_IG_reaction: undefined,
      pusher_channel_liveChat_IG_ig_unsend_msg: undefined,
      latestVertexIsForPayment: false,
      normalPusherNotifications: [],
      sendLocationModal: false,
      sendContactModal: false,
      sendLocation: {
        buttonTitle: undefined,
        address: undefined,
      },
      sendContact: {
        buttonTitle: undefined,
        number: undefined,
      },
      autoComplete: undefined,
      sendLocationLatitudeText: undefined,
      sendLocationLongitudeText: undefined,
      locationForm: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "long_name",
        country: "long_name",
        postal_code: "short_name",
      },
      google: undefined,
      attachAndSendModal: false,
      activeSendAndAttachTab: "imageTab",
      recentUploadedFiles: [],
      waitForconfirmation: false,
      document: document,
    };
  },
  components: {
    IonActionSheet,
    IonBadge,
    IonSelect,
    IonSelectOption,
    singleUserDetail,
  },
  async mounted() {
    if (this.conversationid) await this.init();
    if (
      this.IS_LIVE == false ||
      (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
    ) {
      this.socket = io(
        this.environment("VUE_APP_SOCKET_URL") + "conversation",
        {
          reconnectionDelayMax: 10000,
          /* auth: {
          token: this.authData.token
        } */
          query: {
            token: "JWT " + this.authData.token,
          },
        }
      );

      this.socket.on("connect", () => {
        console.log("socket connected", this.socket.connected); // true
        this.socket.emit("message", this.conversationid);
      });

      this.socket.io.on("error", (error) => {
        console.log("socket connection error", error);
      });

      this.socket.io.on("reconnect", (attempt) => {
        console.log("socket connection attempt", attempt);
      });

      this.socket.io.on("reconnect_attempt", (attempt) => {
        console.log("socket connection attempt number", attempt);
      });

      this.socket.io.on("reconnect_error", (error) => {
        console.log("socket connection reconnect error", error);
      });

      this.socket.io.on("ping", () => {
        console.log("socket connection ping");
      });

      /* this.socket.emit("hello", { a: "b", c: [] });
      this.socket.emit("message", { a: "b", c: [] });

      this.socket.on("hey", (args) => {
        console.log("on hey", args);
      }); */

      this.socket.on("unread", (args) => {
        console.log("on unread", args);
      });
    }

    var handleConnectionChange = (event) => {
      if (event.type == "offline") {
        console.log("You lost connection.");

        this.updateToast({
          open: true,
          message: "Lost internet connection.",
          type: "danger",
        });
      }
      if (event.type == "online") {
        console.log("You are now back online.");

        this.updateToast({
          open: true,
          message: "Your internet connection was restored.",
          type: "success",
        });

        if (
          this.IS_LIVE == false ||
          (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
        ) {
          this.socket.emit("online");
        }

        this.loading = true;
        this.init();
      }

      console.log(new Date(event.timeStamp));
    };
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    /* If whatsapp connected importing google script */
    if (!window.google.maps) {
      let googleScript = document.createElement("script");
      googleScript.addEventListener("load", () => {
        console.log("google maps script loaded");
        if (window.google.maps) this.google = window.google;
      });
      googleScript.setAttribute(
        "src",
        "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_API_KEY +
          "&libraries=places"
      );
      googleScript.setAttribute("async", true);
      googleScript.setAttribute("defer", true);
      document.head.appendChild(googleScript);
    } else {
      if (!this.google) this.google = window.google;
      else console.log("Google maps already set up");
    }
    /* If whatsapp connected importing google script */

    document.addEventListener("new-message", (e) => {
      if (
        this.IS_LIVE == false ||
        (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
      ) {
        if (this.socket)
          this.socket.emit("read", {
            conversation: e.detail.conversationid,
            message: e.detail.messageid,
          });
        else console.log("Something went wrong with the socket connection");
      }

      this.parseNewConversationDataReceivedFromPusher(e.detail, false);
    });

    document.addEventListener("new-message-via-agent-routing", (e) => {
      if (
        this.IS_LIVE == false ||
        (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
      ) {
        if (this.socket)
          this.socket.emit("read", {
            conversation: e.detail.conversationid,
            message: e.detail.messageid,
          });
        else console.log("Something went wrong with the socket connection");
      }

      this.parseNewConversationDataReceivedFromPusher(e.detail, true);
    });

    document.addEventListener("remove-conversation", (e) => {
      this.hideResolvedConversationFromLiveChat(e.detail);
    });

    document.addEventListener("reassign-conversation", (e) => {
      this.hideReAssignedConversationFromLiveChat(e.detail);
    });
  },
  beforeUnmount() {
    var handleConnectionChange = (event) => {
      if (event.type == "offline") {
        console.log("You lost connection.");

        this.updateToast({
          open: true,
          message: "Lost internet connection.",
          type: "danger",
        });
      }
      if (event.type == "online") {
        console.log("You are now back online.");

        this.updateToast({
          open: true,
          message: "Your internet connection was restored.",
          type: "success",
        });

        if (
          this.IS_LIVE == false ||
          (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
        ) {
          this.socket.emit("online");
        }

        this.loading = true;
        this.init();
      }

      console.log(new Date(event.timeStamp));
    };
    window.removeEventListener("online", handleConnectionChange, true);
    window.removeEventListener("offline", handleConnectionChange, true);

    document.removeEventListener(
      "new-message",
      (e) => {
        if (
          this.IS_LIVE == false ||
          (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
        ) {
          if (this.socket)
            this.socket.emit("read", {
              conversation: e.detail.conversationid,
              message: e.detail.messageid,
            });
          else console.log("Something went wrong with the socket connection");
        }

        this.parseNewConversationDataReceivedFromPusher(e.detail, false);
      },
      true
    );

    document.removeEventListener(
      "new-message-via-agent-routing",
      (e) => {
        if (
          this.IS_LIVE == false ||
          (this.user.enterprise_Name == "smretail_ph" && this.IS_LIVE == true)
        ) {
          if (this.socket)
            this.socket.emit("read", {
              conversation: e.detail.conversationid,
              message: e.detail.messageid,
            });
          else console.log("Something went wrong with the socket connection");
        }

        this.parseNewConversationDataReceivedFromPusher(e.detail, true);
      },
      true
    );

    document.removeEventListener(
      "remove-conversation",
      (e) => {
        this.hideResolvedConversationFromLiveChat(e.detail);
      },
      true
    );

    document.removeEventListener(
      "reassign-conversation",
      (e) => {
        this.hideReAssignedConversationFromLiveChat(e.detail);
      },
      true
    );
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData",
    }),
    agentAssigned() {
      return this.activeUserConversationDataResult &&
        this.activeUserConversationDataResult.length &&
        this.activeUserConversationDataResult[0].agent &&
        this.activeUserConversationDataResult[0].agent.id
        ? this.activeUserConversationDataResult[0].agent.id
        : "not_assigned";
    },
    mentionAgentOptions() {
      return this.allAgentsResult.map((e) => { return { text: e.name + "  [" + e.email + "]", handler: () => this.mentionAgent(e), }; });
    },
    sendShortcutOptions() {
      return this.allShortcuts.map((e) => {
        return {
          text: e.name + "  =>  " + e.response,
          handler: () => this.sendShortcut(e),
        };
      });
    },
    /* waLanguages(){
      console.log(this.localeFullFormData_whatsapp.localeFullFormData_whatsapp);
      let thisTemplate = this.whatsappTemplates.find(e => e.template_name === this.waTemplateToSend.template)
      return thisTemplate ? thisTemplate.templates.map(elem => {
        return {
          language: elem.language,
          lang_full_name: (this.localeFullFormData_whatsapp.localeFullFormData_whatsapp.find(l => l.id === elem.language)).name
        }
      }) : []
    }, */
    menuOptions() {
      let returnArray = [
        {
          text: this.activeCustomer.followup ? "Unfollow" : "Follow-up",
          icon: this.activeCustomer.followup ? star : starOutline,
          handler: async () => {
            let reqBody = {
              conversation: this.conversationid,
            };
            try {
              let doneResponse = await jwtInterceptor.post(
                this.environment("VUE_APP_ROOT_API") + "mark-followup",
                reqBody
              );
              if (
                (doneResponse.status == 200 || doneResponse.status == 201) &&
                doneResponse.data.success
              ) {
                this.updateToast({
                  open: true,
                  message: this.activeCustomer.followup
                    ? "Customer removed from follow-up list!"
                    : "Customer added to follow-up list!",
                  type: "success",
                });
                this.activeCustomer.followup = !this.activeCustomer.followup;
              } else {
                this.updateToast({
                  open: true,
                  message: doneResponse.data.errorMessage,
                  type: "danger",
                });
              }
            } catch (error) {
              console.log("Exception", error);
            } finally {
              this.menuOpen = false;
            }
          },
        },
      ];

      if (
        this.activeCustomer.assigned_to != null ||
        (this.activeCustomer.active == false &&
          this.activeCustomer.channel != "web" &&
          this.activeCustomer.channel != "webbot")
      ) {
        returnArray.push({
          text: "Mark as resolved",
          icon: checkmarkCircleOutline,
          handler: async () => {
            let reqBody = {
              conversation: this.conversationid,
              platform: this.platform,
            };
            try {
              let doneResponse = await jwtInterceptor.post(
                this.environment("VUE_APP_ROOT_API") + "mark-as-done",
                reqBody
              );
              if (
                (doneResponse.status == 200 || doneResponse.status == 201) &&
                doneResponse.data.success &&
                doneResponse.data["mark as done"]
              ) {
                this.updateToast({
                  open: true,
                  message: "Conversation marked as done!",
                  type: "success",
                });

                this.$router.push({ path: "/page-inbox" });
              } else {
                this.updateToast({
                  open: true,
                  message: doneResponse.data.errorMessage,
                  type: "danger",
                });
              }
            } catch (error) {
              console.log("Exception", error);
            } finally {
              this.menuOpen = false;
            }
          },
        });
      }

      return returnArray;
    },
    productActionOptions() {
      let returnArray = [];

      if (
        this.accountServiceType == "product" &&
        this.user.enterprise_Name != "bmw" &&
        this.user.sellerproducttype.indexOf("brand_com_marketplace") == -1
      ) {
        returnArray.push({
          text: "Create order",
          handler: async () => {
            this.createOrderModal = true;
            this.actionModal = false;
            this.createOrderModal_iframeUrl =
              this.environment("VUE_APP_ROOT") +
              "mobile-order-create/" +
              this.conversationid +
              "?userid=" +
              this.authData.token +
              "&channel=" +
              this.activeCustomer.channel +
              "&page_conversation_id=" +
              this.page_conversation_id +
              "&pageid=" +
              this.pageid +
              "&platform=" +
              this.platform;
          },
        });

        returnArray.push({
          text: "Share product details",
          handler: async () => {
            this.shareOrderModal = true;
            this.actionModal = false;
            this.shareOrderModal_iframeUrl =
              this.environment("VUE_APP_ROOT") +
              "mobile-share-products/" +
              this.conversationid +
              "?userid=" +
              this.authData.token;
          },
        });
      }

      returnArray.push({
        text: "Start bot flow",
        handler: async () => {
          this.startBotFlowModal = true;
          this.actionModal = false;
          this.fetchAllBotBuilderFlows();
        },
      });

      if (
        this.whatsappConnected == true &&
        this.activeCustomer.channel == "whatsapp"
      ) {
        returnArray.push({
          text: "Send location",
          handler: async () => {
            this.sendLocationModal = true;
            if (this.google) this.initAutocomplete();
          },
        });
      }
      if (
        this.whatsappConnected == true &&
        this.activeCustomer.channel == "whatsapp"
      ) {
        returnArray.push({
          text: "Send contact",
          handler: async () => {
            this.sendContactModal = true;
          },
        });
      }

      return returnArray;
    },
    checkIfToShow_inputContainerInnerInline() {
      if (!this.activeCustomer) return false;
      if (
        this.activeCustomer.channel === "whatsapp" &&
        (this.isWhatsappChat_old24H || this.isWhatsappChat_sendTemplate)
      )
        return false; //WA send template mode is active
      if (
        this.activeCustomer.channel === "facebook" &&
        this.fb_notification_optin_container_visible &&
        this.fbNotificationOptinsResult.length
      )
        return false; //FB send notification opt-in mode is active
      return true;
    },
    notificationToSend() {
      if (this.FbOptinNotification_selectedId)
        return this.fbNotificationOptinsResult.find(
          (e) => e.notificationid === this.FbOptinNotification_selectedId
        );
      else return null;
    },
    currency() {
      return this.user.currency;
    },
  },
  watch: {
    conversationid(newVal) {
      this.init();
      if (newVal == undefined) {
        this.receiverDetailMode = false;
      }
    },
    async selectedReassignGroup(nVal) {
      this.searchAgentToReassignText = "";
      this.allAgentsResult_apiBased_data = [];

      let reqBody = {
        businessroleid: nVal,
      };
      this.loadingRoles = true;
      try {
        let rolesResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-agent-business",
          reqBody
        );
        if (
          (rolesResponse.status == 200 || rolesResponse.status == 201) &&
          rolesResponse.data.success
        ) {
          this.allAgentsOfSelectedGroupResult = rolesResponse.data.roles;
        } else {
          console.log("Response: ", rolesResponse);
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loadingRoles = false;
      }
    },
    async "waTemplateToSend.template"() {
      console.log(this.waTemplateToSend);
      if (this.waTemplateToSend.template) {
        this.processingWaTemplates = true;
        let thisTemplate = this.whatsappTemplates.find(
          (e) => e.template_name === this.waTemplateToSend.template
        );
        let languagesAvailable = thisTemplate
          ? thisTemplate.templates.map((elem) => {
              return {
                language: elem.language,
                lang_full_name:
                  this.localeFullFormData_whatsapp.localeFullFormData_whatsapp.find(
                    (l) => l.id === elem.language
                  ).name,
              };
            })
          : [];
        this.waTemplateToSend.language =
          languagesAvailable && languagesAvailable.length
            ? languagesAvailable[0].language
            : undefined;
        console.log(this.waTemplateToSend);
        let templateChosen = thisTemplate.templates.find(
          (e) => e.language === this.waTemplateToSend.language
        );
        let reqBody = {
          id: templateChosen.id,
        };
        try {
          let templateResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT") + "whatsapp/message-template/get",
            reqBody
          );
          if (
            (templateResponse.status == 200 ||
              templateResponse.status == 201) &&
            templateResponse.data.success
          ) {
            //this.newChatMessage = templateResponse.data.data.message.BODY.text;
            this.waTemplateToSend.templateData = templateResponse.data.data;

            this.fillMessageData(
              templateResponse.data.data.message,
              templateResponse.data.data.message_params
            );
          } else {
            console.log("Response: ", templateResponse);
          }
        } catch (error) {
          console.log("Exception", error);
        } finally {
          this.processingWaTemplates = false;
        }
      }
    },
    async "sendLocation.address"() {
      console.log(this.sendLocation);
      this.fillInAddress();
    },
  },
  methods: {
    newMessageKeyPressHandler(event) {
      if(event && !this.receiverDetailMode) {
        switch(event.key) {
          case "Enter": {
            if(!event.shiftKey && window.innerWidth > 600) {
              event.preventDefault();
              if (this.noteMode) this.sendNote();
              else {
                if (this.activeCustomer.assigned_to != null && this.activeCustomer.assigned_to.id != this.loggedInUser_id) {
                  this.confirmTakeoverChatFromOtherAgent = true;
                  this.taskToBeDone_after_confirmTakeoverChatFromOtherAgent = "sendMessageFinal";
                } else this.sendMessageFinal();
              }
            }
          } break;
          case "@": {
            if(this.noteMode) this.mentionAgentMode = true;
          } break;
          case "/": {
            if(event.ctrlKey && this.allShortcuts.length) this.sendShortcutMode = true;
          } break;
          default: //do nothing
        }
      }
    },
    async init() {
      try {
        this.loading = true;
        this.activeUserConversationDataResult = [];

        //---enterprise/account based settings start
        if (this.agentRoutingEnabled_accountLevel == true) {
          this.agentReAssignmentAllowed = false;
        } else {
          this.agentReAssignmentAllowed = true;
        }

        if (
          this.loreal_my_family_user == true ||
          (this.user.enterprise_Name == "smretail_ph" &&
            this.accountType_master == true)
        ) {
          this.agentReAssignmentAllowed = true;
        }

        if (
          this.user.enterprise_Name == "samsungid" ||
          this.user.enterprise_Name == "grablivechat"
        ) {
          this.composerInputAllowed = false;
        } else {
          this.composerInputAllowed = true;
        }

        if (
          this.user.enterprise_Name == "lakmeindia" ||
          this.user.enterprise_Name == "samsungid" ||
          this.user.enterprise_Name == "grablivechat"
        ) {
          this.actionsPanelEnabled = false;
        } else {
          this.actionsPanelEnabled = true;
        }

        if (this.user.enterprise_Name == "shiseido_id") {
          this.preferredCountries = ["id"];
          this.initialCountry = "id";
        } else if (
          this.user.enterprise_Name == "jnj_acom_ph" ||
          this.user.enterprise_Name == "rb_acom_ph" ||
          this.user.enterprise_Name == "iams_ph"
        ) {
          this.preferredCountries = ["ph"];
          this.initialCountry = "ph";
        } else if (
          this.user.enterprise_Name == "uriage_th" ||
          this.user.enterprise_Name == "philips_th_intrepid" ||
          this.user.enterprise_Name == "ipalfish_th" ||
          this.elc_family_th_user == true
        ) {
          this.preferredCountries = ["th"];
          this.initialCountry = "th";
        } else if (this.user.enterprise_Name == "countryroad_au" || this.elc_family_au_user == true) {
          this.preferredCountries = ["au"];
          this.initialCountry = "au";
        } else if (
          this.elc_family_sg_user == true ||
          this.user.enterprise_Name == "heartlandhealth_sg" ||
          this.user.enterprise_Name == "alcon_sg"
        ) {
          this.preferredCountries = ["sg"];
          this.initialCountry = "sg";
        } else if (this.elc_family_nz_user == true) {
          this.preferredCountries = ["nz"];
          this.initialCountry = "nz";
        } else if (
          this.loreal_my_family_user == true ||
          this.elc_family_my_user == true ||
          this.user.enterprise_Name == "alcon_my" ||
          this.user.enterprise_Name == "ipalfish_my"
        ) {
          this.preferredCountries = ["my"];
          this.initialCountry = "my";
        }

        if (this.whatsappConnected == true) {
          //pending - @kru
          //this.getPreviouslyUploadedFiles();
        }
        //---enterprise/account based settings end

        let reqBody = {
          conversationid: this.conversationid,
        };
        let inboxResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-inbox",
          reqBody
        );
        let receiverResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fbrt/user-detail",
          reqBody
        );

        let agentsResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_role-list'}))).value);
        if(!agentsResponseData) var agentsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-list");

        reqBody = {
          limit: 50,
          offset: this.allShortcuts ? this.allShortcuts.length : 0,
        };
        let shortcutResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_fetch-shortcut'}))).value);
        if(!shortcutResponseData) var shortcutResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-shortcut", reqBody);

        let businessRolesResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_fetch-business-role'}))).value);
        if(!businessRolesResponseData) var businessRolesResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");

        reqBody = {
          limit: "all",
          status: "APPROVED",
        };
        let whatsappTemplateResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_whatsapp-message-template'}))).value);
        if(!whatsappTemplateResponseData) var whatsappTemplateResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/message-template/fetch", reqBody);
        
        let recentUploadedFilesResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_whatsapp-list-uploaded-document'}))).value);
        if(!recentUploadedFilesResponseData) var recentUploadedFilesResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/list-uploaded-document");

        let fbNotificationOptinsResultResponseData = JSON.parse((await (this.Storage.get({key: 'jmpr_notification-template-fetch'}))).value);
        if(!fbNotificationOptinsResultResponseData) var fbNotificationOptinsResultResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "notification-template-fetch");

        let allResponses = await Promise.all([
          inboxResponse,
          receiverResponse,
          agentsResponse,
          shortcutResponse,
          businessRolesResponse,
          whatsappTemplateResponse,
          recentUploadedFilesResponse,
          fbNotificationOptinsResultResponse,
        ]);
        inboxResponse = allResponses[0];
        receiverResponse = allResponses[1];
        agentsResponse = allResponses[2];
        shortcutResponse = allResponses[3];
        businessRolesResponse = allResponses[4];
        whatsappTemplateResponse = allResponses[5];
        recentUploadedFilesResponse = allResponses[6];
        fbNotificationOptinsResultResponse = allResponses[7];
        if ( (inboxResponse.status == 200 || inboxResponse.status == 201) && inboxResponse.data.success ) {
          this.fetchInboxResponseHandler(inboxResponse.data);
        } else {
          console.log("Response:", inboxResponse);
        }
        if ( (receiverResponse.status == 200 || receiverResponse.status == 201) && receiverResponse.data.success ) {
          if (typeof receiverResponse.data.data.active != "undefined") {
            if (receiverResponse.data.data.active == null) {
              receiverResponse.data.data.active = true;
            }
          }

          var thisCustomer = receiverResponse.data.data;
          thisCustomer.id = this.conversationid;
          thisCustomer.conversationid = this.conversationid;
          thisCustomer.currentlyOpendChat = false;
          thisCustomer.custom_fields_for_editing = thisCustomer.custom_fields;

          if (typeof thisCustomer.custom_fields == "undefined") {
            thisCustomer.custom_fields = [];
            thisCustomer.custom_fields_for_editing = [];
          }

          if (typeof thisCustomer.outletid != "undefined") {
            this.activeCustomer_outletid = thisCustomer.outletid;
          } else {
            this.activeCustomer_outletid = undefined;
          }

          thisCustomer.channel_id = thisCustomer.channel;
          thisCustomer.channel = this.getChannelFromPageActive(
            thisCustomer.channel,
            true
          );

          this.activeCustomer = thisCustomer; //doing it here also just to add data in activeCustomer

          if (
            this.calculate24Plus1RuleForCustomer(
              thisCustomer.userReplyTimestamp
            ) >= 24
          ) {
            if (
              thisCustomer.channel == "facebook" ||
              thisCustomer.channel == "instagram_off"
            ) {
              //thisCustomer.active = false;
              thisCustomer.page_msg_subscription_restriction = true;
            } else {
              thisCustomer.page_msg_subscription_restriction = false;
            }
          } else {
            thisCustomer.page_msg_subscription_restriction = false;
          }

          if (this.calculate24Plus1RuleForCustomer(thisCustomer.userReplyTimestamp) >= 24 && thisCustomer.channel == "whatsapp") {
            this.isWhatsappChat_old24H = true;
          } else {
            if (thisCustomer.channel == "whatsapp") {
              this.isWhatsappChat_old24H = false;
              this.isWhatsappChat_sendTemplate = false;
            }
          }

          //---

          if (thisCustomer.channel == "INVALID_MERCHANT") {
            //this.fetchCustomers(0,true); //pending
          } else {
            var conversationidFoundBool = false;

            if (conversationidFoundBool == false) {
              this.checkAndAddTheSingleCustomerDataToRelevantTabArray(
                thisCustomer,
                "unshift"
              );
            }

            this.loading = false;

            this.activeCustomer = thisCustomer;

            //---

            this.singleCustomerOrdersResult = [];

            this.composerInputValid_asPerTime = true;

            this.thisIsAValidConversation_bool = true;
            if (typeof thisCustomer.status != "undefined") {
              if (thisCustomer.status == "invalid") {
                this.thisIsAValidConversation_bool = false;
              }
            }

            this.resetTemplateDataForWA();

            if (typeof thisCustomer.outletid != "undefined") {
              this.activeCustomer_outletid = thisCustomer.outletid;
            } else {
              this.activeCustomer_outletid = undefined;
            }

            this.activeCustomer.currentlyOpendChat = true;

            if (this.activeCustomer.channel == "whatsapp") {
              if (
                this.calculate24Plus1RuleForCustomer(
                  this.activeCustomer.userReplyTimestamp
                ) >= 24
              ) {
                this.isWhatsappChat_old24H = true;
              }
            }

            //this.fetchActiveConversationData(this.conversationid);

            this.singleCustomerPastAgentsResultApiCalled = false;
            this.singleCustomerPastAgentsResult = [];
            this.singleCustomerOrdersResultApiCalled = false;
            this.singleCustomerOrdersResult = [];

            this.noteMode = false;
          }
        } else {
          console.log("Response:", receiverResponse);
        }
        if (agentsResponse && (agentsResponse.status == 200 || agentsResponse.status == 201) && agentsResponse.data && agentsResponse.data.success) {
          agentsResponseData = agentsResponse.data;
          await this.Storage.set({
            key: "jmpr_role-list",
            value: JSON.stringify(agentsResponseData),
          });
        }
        if (agentsResponseData) {
          this.allAgentsResult = [];

          for (
            var i_roles = 0;
            i_roles < agentsResponseData.roles.length;
            i_roles++
          ) {
            for (var key_role in agentsResponseData.roles[i_roles]
              .permissions) {
              if (
                key_role == "page_inbox" &&
                agentsResponseData.roles[i_roles].permissions[key_role] == true
              ) {
                this.allAgentsResult.push(agentsResponseData.roles[i_roles]);
              }
            }
          }
        } else {
          console.log("Response:", agentsResponseData);
        }
        if (shortcutResponse && (shortcutResponse.status == 200 || shortcutResponse.status == 201) && shortcutResponse.data && shortcutResponse.data.success) {
          shortcutResponseData = shortcutResponse.data;
          await this.Storage.set({
            key: "jmpr_fetch-shortcut",
            value: JSON.stringify(shortcutResponseData),
          });
        }
        if (shortcutResponseData) this.allShortcuts = shortcutResponseData.shortcuts;
        else console.log("Response:", shortcutResponseData);

        if (businessRolesResponse && (businessRolesResponse.status == 200 || businessRolesResponse.status == 201) && businessRolesResponse.data && businessRolesResponse.data.success) {
          businessRolesResponseData = businessRolesResponse.data;
          await this.Storage.set({
            key: "jmpr_fetch-business-role",
            value: JSON.stringify(businessRolesResponseData),
          });
        }
        if (businessRolesResponseData) this.allGroupsResult = businessRolesResponseData.business_roles;
        else console.log("Response:", businessRolesResponseData);

        if (whatsappTemplateResponse && (whatsappTemplateResponse.status == 200 || whatsappTemplateResponse.status == 201) && whatsappTemplateResponse.data && whatsappTemplateResponse.data.success) {
          whatsappTemplateResponseData = whatsappTemplateResponse.data;
          await this.Storage.set({
            key: "jmpr_whatsapp-message-template",
            value: JSON.stringify(whatsappTemplateResponseData),
          });
        }
        if (whatsappTemplateResponseData) {
          this.whatsappTemplates = [];

          for (
            var i_wa_t = 0;
            i_wa_t < whatsappTemplateResponseData.data.length;
            i_wa_t++
          ) {
            if (
              whatsappTemplateResponseData.data[i_wa_t].templates.length > 0 &&
              whatsappTemplateResponseData.data[
                i_wa_t
              ].templates[0].status.toLowerCase() == "approved"
            ) {
              this.whatsappTemplates.push(
                whatsappTemplateResponseData.data[i_wa_t]
              );
            }
          }

          //---

          this.simpleMessageTags = [
            {
              tag: "username",
              description: "User's First Name",
            },
            {
              tag: "shopname",
              description: "Store Name",
            },
            {
              tag: "agentname",
              description: "Agent Name",
            },
            {
              tag: "sellername",
              description: "Account Owner Name",
            },
            {
              tag: "selleremail",
              description: "Support E-mail",
            },
            {
              tag: "sellerphone",
              description: "Support Phone",
            },
            {
              tag: "menuaddress",
              description: "Outlet Address",
            },
          ];

          if (
            this.accountServiceType == "restaurant" ||
            this.accountServiceType == "event"
          ) {
            this.simpleMessageTags.forEach((tagData) => {
              if (tagData.tag == "hashtag") {
                tagData.description =
                  this.accountServiceType == "restaurant"
                    ? "Restaurant: Hashtag"
                    : "Event: Hashtag";
              }
            });
          }
        }
        else console.log("Response:", whatsappTemplateResponseData);

        if (recentUploadedFilesResponse && (recentUploadedFilesResponse.status == 200 || recentUploadedFilesResponse.status == 201) && recentUploadedFilesResponse.data && recentUploadedFilesResponse.data.success) {
          recentUploadedFilesResponseData = recentUploadedFilesResponse.data;
          await this.Storage.set({
            key: "jmpr_whatsapp-list-uploaded-document",
            value: JSON.stringify(recentUploadedFilesResponseData),
          });
        }
        if (recentUploadedFilesResponseData) this.recentUploadedFiles = recentUploadedFilesResponseData.images;
        else console.log("Response:", recentUploadedFilesResponseData);

        if (fbNotificationOptinsResultResponse && (fbNotificationOptinsResultResponse.status == 200 || fbNotificationOptinsResultResponse.status == 201) && fbNotificationOptinsResultResponse.data && fbNotificationOptinsResultResponse.data.success) {
          fbNotificationOptinsResultResponseData = fbNotificationOptinsResultResponse.data;
          await this.Storage.set({
            key: "jmpr_notification-template-fetch",
            value: JSON.stringify(fbNotificationOptinsResultResponseData),
          });
        }
        if (fbNotificationOptinsResultResponseData) this.fbNotificationOptinsResult = fbNotificationOptinsResultResponseData.notification_templates;
        else console.log("Response:", fbNotificationOptinsResultResponseData);

      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
        this.scrollToBottom();
      }
    },
    initAutocomplete() {
      setTimeout(() => {
        let autocompleteInput = this.$refs.autocomplete || document.getElementById("autocomplete");
        autocompleteInput.getInputElement().then((e) => {
          autocompleteInput = e;
          this.autocomplete = new this.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */
            (autocompleteInput),
            {
              types: ["geocode", "establishment"],
            }
          );
          this.autocomplete.addListener("place_changed", this.fillInAddress);
          this.geolocate();
        });
      }, 1000);
    },
    fillInAddress() {
      console.log(this.autocomplete);
      var place = this.autocomplete.getPlace();
      if (place && typeof place.geometry != "undefined") {
        this.sendLocationLatitudeText = place.geometry.location.lat();
        this.sendLocationLongitudeText = place.geometry.location.lng();

        /* Not sure what this part of code does */
        /* for (var component in this.locationForm){
          document.getElementById(component).value = '';
          document.getElementById(component).disabled = false;
        } */
        /* Not sure what this part of code does */
        for (var i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (this.locationForm[addressType]) {
            let val =
              place.address_components[i][this.locationForm[addressType]];
            /* document.getElementById(addressType).value = val; */
            this.locationForm[addressType] = val;
          }
        }

        if (
          this.locationForm.street_number &&
          this.locationForm.street_number.length &&
          this.locationForm.street_number !== "short_name"
        ) {
          this.locationForm.street_number =
            this.locationForm.street_number + ", " + this.locationForm.route;
        } else {
          this.locationForm.street_number = this.locationForm.route;
        }

        if (this.locationForm.country === "Singapore") {
          this.locationForm.administrative_area_level_1 = "Singapore";
        }
        console.log(this.locationForm);
      }
    },
    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          var circle = new this.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    getPlatformPageIdAndPageConversationIdOfCustomer() {
      var dataFoundFromFetchInbox = false;

      for (var i = 0; i < this.activeUserConversationDataResult.length; i++) {
        if (
          typeof this.activeUserConversationDataResult[i].pageid != "undefined"
        ) {
          if (
            this.activeUserConversationDataResult[i].pageid != "" &&
            this.activeUserConversationDataResult[i].page_conversation_id !=
              "" &&
            this.activeUserConversationDataResult[i].platform != ""
          ) {
            dataFoundFromFetchInbox = true;

            this.pageid = this.activeUserConversationDataResult[i].pageid;
            this.page_conversation_id =
              this.activeUserConversationDataResult[i].page_conversation_id;
            this.platform = this.activeUserConversationDataResult[i].platform;

            break;
          }
        }
      }

      if (dataFoundFromFetchInbox == false) {
        this.pageid = this.activeCustomer.pageid;
        this.page_conversation_id = this.activeCustomer.page_conversation_id;
        this.platform = this.activeCustomer.platform;

        if (this.platform == "" || this.platform == null) {
          this.platform = this.activeCustomer.channel;
        }
      }
    },
    async loadMoreMessages() {
      this.loadMoreChatLoadingShow = true;
      try {
        let reqBody = {
          conversationid: this.conversationid,
          cursor: this.activeUserConversationCursor,
        };
        let inboxResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-inbox",
          reqBody
        );
        if ( (inboxResponse.status == 200 || inboxResponse.status == 201) && inboxResponse.data.success ) {
          this.fetchInboxResponseHandler(inboxResponse.data);
        } else {
          console.log("Response:", inboxResponse);
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    fetchInboxResponseHandler(data) {
      this.loading = false;

      if (typeof this.activeUserConversationCursor != "undefined") {
        var newData = data.messages;
        this.activeUserConversationDataResult.push.apply(
          this.activeUserConversationDataResult,
          newData
        );
        this.loadMoreChatLoadingShow = false;
      } else {
        this.activeUserConversationDataResult = [];

        this.activeUserConversationDataResult = data.messages;
      }

      if (
        this.activeUserConversationDataResult &&
        this.activeUserConversationDataResult.length
      ) {
        this.getPlatformPageIdAndPageConversationIdOfCustomer();
      }

      this.activeUserConversationCursor = data.cursor;
      this.activeUserConversationMore = data.more;

      this.loading = false;

      this.disabling_rightClick_for_privacy_of_content();
    },
    searchAgentToReassignTextChanger() {
      this.loadingRoles = false;
      setTimeout(() => {
        console.log(this.searchAgentToReassignText);
        var searchKeyword = this.searchAgentToReassignText;

        if (typeof searchKeyword != "undefined" && searchKeyword != null) {
          if (searchKeyword == "" || searchKeyword.length <= 2) {
            this.allAgentsResult_apiBased_data = [];
          } else if (searchKeyword.length > 2) {
            this.allAgentsResult_apiBased_data = [];

            for (var i = 0; i < this.allAgentsResult.length; i++) {
              var thisAgent = this.allAgentsResult[i];

              if (
                thisAgent.name.toLowerCase().indexOf(searchKeyword) > -1 ||
                thisAgent.email.toLowerCase().indexOf(searchKeyword) > -1
              ) {
                this.allAgentsResult_apiBased_data.push(
                  this.allAgentsResult[i]
                );
              }
            }
          }
        }
      }, 10);
    },
    sendMessage() {
      if (this.activeCustomer.assigned_to != null && this.activeCustomer.assigned_to.id != this.loggedInUser_id) {
        this.confirmTakeoverChatFromOtherAgent = true;
        this.taskToBeDone_after_confirmTakeoverChatFromOtherAgent = "sendMessageFinal";
      } else this.sendMessageFinal();
    },
    async sendMessageFinal() {
      if(this.noteMode) this.sendNote();
      else {
        let sendResponse = null;
        if (this.newChatMessage && this.newChatMessage.length) {
          try {
            let reqBody = {
              conversationid: this.conversationid,
              message: this.newChatMessage,
              page_conversation_id: this.page_conversation_id,
              pageid: this.pageid,
              platform: this.platform,
            };
            this.sending = this.newChatMessage;
            this.newChatMessage = undefined;
            sendResponse = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "manual-message", reqBody);

            if (sendResponse.status == 200 || sendResponse.status == 201) {
              this.scrollToBottom();
            } else {
              console.log("Response:", sendResponse);
            }
          } catch (error) {
            console.log("Response:", sendResponse);
          } finally {
            this.sending = undefined;
          }
        }
      }
    },
    async sendNote() {
      let sendResponse = null;
      try {
        let reqBody = {
          conversation: this.conversationid,
          message: this.newChatMessage,
          agent: this.agentAssigned,
        };
        this.sending = this.newChatMessage;
        this.newChatMessage = undefined;
        sendResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "assign-note",
          reqBody
        );

        if (sendResponse.status == 200 || sendResponse.status == 201) {
          this.sending = undefined;
          this.scrollToBottom();
        } else {
          console.log("Response:", sendResponse);
        }
      } catch (error) {
        console.log("Response:", sendResponse);
      } finally {
        this.sending = undefined;
        this.noteMode = false;
      }
    },
    sendShortcut(s) {
      this.newChatMessage = s.response;
      this.sendShortcutMode = false;
      if (this.activeCustomer.assigned_to != null && this.activeCustomer.assigned_to.id != this.loggedInUser_id) {
        this.confirmTakeoverChatFromOtherAgent = true;
        this.taskToBeDone_after_confirmTakeoverChatFromOtherAgent = "sendMessageFinal";
      } else {
        this.sendMessageFinal();
      }
    },
    mentionAgent(a) {
      this.newChatMessage = (this.newChatMessage && this.newChatMessage.length ? this.newChatMessage : "") + a.name;
      this.mentionAgentMode = false;
    },
    changeChatInput(prop) {
      this.newChatMessage = prop;
    },
    setMenuOpen(state) {
      if (this.isMenuOpen == true) {
        state = false;
      } else {
        state = true;
      }
      this.isMenuOpen = state;
    },
    async doRefresh(event) {
      try {
        this.loading = true;
        this.init();
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
      } finally {
        this.scrollToBottom();
        event.target.complete();
      }
    },
    sendImage(skipAttach) {
      if (!skipAttach && this.whatsappConnected) {
        this.waitForconfirmation = true;
        this.attachAndSendModal = true;
      } else this.$refs.sendImageHiddenInput.click();
    },
    sendVideo(skipAttach) {
      if (!skipAttach && this.whatsappConnected) {
        this.waitForconfirmation = true;
        this.attachAndSendModal = true;
      } else this.$refs.sendVideoHiddenInput.click();
    },
    sendDocument(skipAttach) {
      if (!skipAttach && this.whatsappConnected) {
        this.waitForconfirmation = true;
        this.attachAndSendModal = true;
      } else this.$refs.sendDocumentHiddenInput.click();
    },
    handleImageUpload(event) {
      try {
        this.pendingUpload = true;
        this.chatInputBarLoadingIsOn = true;

        var filesToSend = event.target.files;
        console.log("New Upoload Event", filesToSend);

        var totalFilesToBeSent = filesToSend.length;
        if (totalFilesToBeSent > 5) {
          alert("You can't upload more than 5 images!");
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
          return;
        }
        // eslint-disable-next-line
        let looper = async () => {
          this.fileToSend = filesToSend[totalFilesToBeSent - 1];
          if (await this.validateImage(this.fileToSend, 4)) {
            var randomId = Math.floor(Math.random() * 10000 + 1);

            this.filesToSend.push({
              id: "",
              file: this.fileToSend,
              randomId: randomId,
              src_path: URL.createObjectURL(this.fileToSend),
            });

            totalFilesToBeSent--;
            if (totalFilesToBeSent === 0) {
              console.log("now sending to server", this.filesToSend);
              if (!this.waitForconfirmation) this.confirmSendImages();
            } else {
              looper();
            }
          } else {
            this.updateToast({
              open: true,
              message:
                "Sorry! We couldn't read the uploaded file. Please upload a valid image file",
              type: "danger",
            });
            totalFilesToBeSent--;
            if (totalFilesToBeSent > 0) looper();
          }
        };
        looper();
      } catch (error) {
        console.log("Exception", error);
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
        this.attachAndSendModal = false;
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    async confirmSendImages() {
      this.waitForconfirmation = false;
      for (var i = 0; i < this.filesToSend.length; i++) {
        if (this.filesToSend[i].id == "" && this.filesToSend[i].file) {
          let uploadSendResponseData = await this.uploadImage(
            this.filesToSend[i].file,
            "/add-image"
          );
          await this.addProductImageUrl(
            uploadSendResponseData.url,
            this.filesToSend[i].randomId
          );
        }
      }
      this.pendingUpload = false;
      this.chatInputBarLoadingIsOn = false;
      this.attachAndSendModal = false;
      this.filesToSend = [];
      this.fileToSend = null;
    },
    handleVideoUpload(event) {
      try {
        this.pendingUpload = true;
        this.chatInputBarLoadingIsOn = true;

        var filesToSend = event.target.files;
        console.log("New Upoload Event", filesToSend);

        var totalFilesToBeSent = filesToSend.length;
        if (totalFilesToBeSent > 5) {
          alert("You can't upload more than 5 videos!");
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
          return;
        }
        // eslint-disable-next-line
        let looper = async () => {
          this.fileToSend = filesToSend[totalFilesToBeSent - 1];
          let maxSizeAllowed =
            this.activeCustomer.channel === "whatsapp" ? 60 : 25;
          if (await this.validateVideo(this.fileToSend, maxSizeAllowed)) {
            var randomId = Math.floor(Math.random() * 10000 + 1);

            this.filesToSend.push({
              id: "",
              file: this.fileToSend,
              randomId: randomId,
              src_path: URL.createObjectURL(this.fileToSend),
            });

            totalFilesToBeSent--;
            if (totalFilesToBeSent < 1) {
              console.log("now sending to server", this.filesToSend);
              if (!this.waitForconfirmation) this.confirmSendVideos();
            } else {
              looper();
            }
          } else {
            this.updateToast({
              open: true,
              message:
                "Sorry! We couldn't read the uploaded file. Please upload a valid video file",
              type: "danger",
            });
            totalFilesToBeSent--;
            if (totalFilesToBeSent > 0) looper();
          }
        };
        looper();
      } catch (error) {
        console.log("Exception", error);
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
        this.attachAndSendModal = false;
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    async confirmSendVideos() {
      this.waitForconfirmation = false;
      for (var i = 0; i < this.filesToSend.length; i++) {
        if (this.filesToSend[i].id == "" && this.filesToSend[i].file) {
          let uploadSendResponseData = await this.uploadVideo(
            this.filesToSend[i].file,
            "/add-doc"
          );
          console.log(uploadSendResponseData);
          await this.addProductVideoUrl(
            uploadSendResponseData.url,
            this.filesToSend[i].randomId
          );
        }
      }
      this.pendingUpload = false;
      this.chatInputBarLoadingIsOn = false;
      this.attachAndSendModal = false;
      this.filesToSend = [];
      this.fileToSend = null;
    },
    handleDocumentUpload(event) {
      try {
        this.pendingUpload = true;
        this.chatInputBarLoadingIsOn = true;

        var filesToSend = event.target.files;
        console.log("New Upoload Event", filesToSend);

        var totalFilesToBeSent = filesToSend.length;
        if (totalFilesToBeSent > 5) {
          alert("You can't upload more than 5 documents!");
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
          return;
        }
        // eslint-disable-next-line
        let looper = async () => {
          this.fileToSend = filesToSend[totalFilesToBeSent - 1];
          let maxFileSizeAllowed =
            this.activeCustomer.channel === "whatsapp" ? 60 : 25;
          if (
            await this.validateDocument(this.fileToSend, maxFileSizeAllowed)
          ) {
            var randomId = Math.floor(Math.random() * 10000 + 1);

            this.filesToSend.push({
              id: "",
              file: this.fileToSend,
              randomId: randomId,
              src_path: URL.createObjectURL(this.fileToSend),
            });

            totalFilesToBeSent--;
            if (totalFilesToBeSent < 1) {
              console.log("now sending to server", this.filesToSend);
              if (!this.waitForconfirmation) this.confirmSendDocuments();
            } else {
              looper();
            }
          } else {
            this.updateToast({
              open: true,
              message:
                "Sorry! We couldn't read the uploaded file. Please upload a valid document",
              type: "danger",
            });
            totalFilesToBeSent--;
            if (totalFilesToBeSent > 0) looper();
          }
        };
        looper();
      } catch (error) {
        console.log("Exception", error);
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
        this.attachAndSendModal = false;
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    async confirmSendDocuments() {
      this.waitForconfirmation = false;
      for (var i = 0; i < this.filesToSend.length; i++) {
        if (this.filesToSend[i].id == "" && this.filesToSend[i].file) {
          let uploadSendResponseData = await this.uploadDocument(
            this.filesToSend[i].file,
            "/add-doc"
          );
          console.log(uploadSendResponseData);
          await this.addProductDocumentUrl(
            uploadSendResponseData.url,
            this.filesToSend[i].randomId
          );
        }
      }
      this.pendingUpload = false;
      this.chatInputBarLoadingIsOn = false;
      this.attachAndSendModal = false;
      this.filesToSend = [];
      this.fileToSend = null;
    },
    async addProductImageUrl(image_url, randomId) {
      try {
        let sendingFile = undefined;
        if (typeof image_url != "undefined") {
          for (var i = 0; i < this.filesToSend.length; i++) {
            if (this.filesToSend[i].randomId == randomId) {
              this.filesToSend[i].id = image_url;
              sendingFile = this.filesToSend[i].file;
            }
          }

          let reqBody = {
            message: image_url,
            messagetype: "image",
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
          };

          let manualMessageResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "manual-message", reqBody );

          if ( (manualMessageResponse.status == 200 || manualMessageResponse.status == 201) && manualMessageResponse.data ) {
            if (manualMessageResponse.data.success) {
              //sent successfully
              reqBody["name"] = sendingFile.name;
              reqBody["contenttype"] = sendingFile.type;
              reqBody["extension"] = sendingFile.name.split(".")[1];
              this.recentUploadedFiles.unshift({
                id: Math.floor(Math.random() * 100000000000 + 1),
                contenttype: reqBody.contenttype,
                extension: reqBody.extension,
                name: reqBody.name,
                url: image_url,
              });
            } else {
              this.updateToast({
                open: true,
                message: manualMessageResponse.data.errorMessage,
                type: "danger",
              });
            }
          } else {
            console.log("Response:", manualMessageResponse);
          }
        } else {
          this.updateToast({
            open: true,
            message: "The uploaded image is invalid",
            type: "danger",
          });
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
      }
    },
    async addProductVideoUrl(video_url, randomId) {
      try {
        let sendingFile = undefined;
        if (typeof video_url != "undefined") {
          for (var i = 0; i < this.filesToSend.length; i++) {
            if (this.filesToSend[i].randomId == randomId) {
              this.filesToSend[i].id = video_url;
              sendingFile = this.filesToSend[i].file;
            }
          }

          let reqBody = {
            message: undefined,
            url: undefined,
            messagetype: undefined,
            filetype: undefined,
            extension: undefined,
            contenttype: undefined,
            name: undefined,
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
          };

          if (this.activeCustomer.channel === "imessage") {
            reqBody["message"] = "Video";
            reqBody["url"] = video_url;
            reqBody["messagetype"] = "videolink";
          } else if (this.activeCustomer.channel === "whatsapp") {
            reqBody["message"] = video_url;
            reqBody["messagetype"] = "videolink";
            reqBody["filetype"] = "video";
            reqBody["name"] = sendingFile.name;
            reqBody["contenttype"] = sendingFile.type;
            reqBody["extension"] = sendingFile.name.split(".")[1];
          }

          let manualMessageResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "manual-message", reqBody );

          if (
            (manualMessageResponse.status == 200 ||
              manualMessageResponse.status == 201) &&
            manualMessageResponse.data.success
          ) {
            console.log(manualMessageResponse.data);
            if (manualMessageResponse.data.success) {
              //sent successfully
              this.recentUploadedFiles.unshift({
                id: Math.floor(Math.random() * 100000000000 + 1),
                contenttype: reqBody.contenttype,
                extension: reqBody.extension,
                name: reqBody.name,
                url: video_url,
              });
            } else {
              this.updateToast({
                open: true,
                message: manualMessageResponse.data.errorMessage,
                type: "danger",
              });
            }
          } else {
            console.log("Response:", manualMessageResponse);
          }
        } else {
          this.updateToast({
            open: true,
            message: "The uploaded video is invalid",
            type: "danger",
          });
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
      }
    },
    async addProductDocumentUrl(document_url, randomId) {
      try {
        let sendingFile = undefined;
        if (typeof document_url != "undefined") {
          for (var i = 0; i < this.filesToSend.length; i++) {
            if (this.filesToSend[i].randomId == randomId) {
              this.filesToSend[i].id = document_url;
              sendingFile = this.filesToSend[i].file;
            }
          }

          let reqBody = {
            message: document_url,
            url: undefined,
            messagetype: undefined,
            filetype: "document",
            extension: undefined,
            contenttype: undefined,
            name: undefined,
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
          };

          if (this.activeCustomer.channel === "imessage") {
            reqBody["messagetype"] = "image";
          } else if (this.activeCustomer.channel === "whatsapp") {
            reqBody["messagetype"] = "document";
            reqBody["name"] = sendingFile.name;
            reqBody["contenttype"] = sendingFile.type;
            reqBody["extension"] = sendingFile.name.split(".")[1];
          }

          let manualMessageResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "manual-message",
            reqBody
          );

          if (
            (manualMessageResponse.status == 200 ||
              manualMessageResponse.status == 201) &&
            manualMessageResponse.data.success
          ) {
            console.log(manualMessageResponse.data);
            if (manualMessageResponse.data.success) {
              //sent successfully
              this.recentUploadedFiles.unshift({
                id: Math.floor(Math.random() * 100000000000 + 1),
                contenttype: reqBody.contenttype,
                extension: reqBody.extension,
                name: reqBody.name,
                url: document_url,
              });
            } else {
              this.updateToast({
                open: true,
                message: manualMessageResponse.data.errorMessage,
                type: "danger",
              });
            }
          } else {
            console.log("Response:", manualMessageResponse);
          }
        } else {
          this.updateToast({
            open: true,
            message: "The uploaded document is invalid",
            type: "danger",
          });
          this.pendingUpload = false;
          this.chatInputBarLoadingIsOn = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
        this.pendingUpload = false;
        this.chatInputBarLoadingIsOn = false;
      }
    },
    switchNoteMode() {
      this.noteMode = !this.noteMode;
    },
    async assignChat(type, id) {
      let reqBody = {
        conversation: this.conversationid,
      };
      if (type === "group") {
        reqBody["businessroleid"] = id;
      } else {
        reqBody["agent"] = id;
      }
      try {
        let assignAgentResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "assign-agent",
          reqBody
        );
        console.log(assignAgentResponse);
        if (
          (assignAgentResponse.status == 200 ||
            assignAgentResponse.status == 201) &&
          assignAgentResponse.data.success &&
          assignAgentResponse.data.assigned
        ) {
          this.updateToast({
            open: true,
            message: "Assigned Successfully",
            type: "success",
          });
          this.$router.push({ path: "/page-inbox" });
        } else {
          console.log("Response:", assignAgentResponse);
          this.updateToast({
            open: true,
            message: assignAgentResponse.data.errorMessage,
            type: "warning",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.reAssignModal = false;
      }
    },
    getWhatsappJSONFormat() {
      return {
        header: {
          format: "",
          text: "",
          url: "",
        },
        template: {
          text: "",
          buttons: [],
          quick_replies: [],
        },
        footer: {
          text: "",
        },
      };
    },
    parseBodyText(bodyText, body_params) {
      var bodyParams = body_params;

      bodyText = bodyText.replace(/\n/g, "<br>");

      if (typeof bodyParams == "string") {
        bodyParams = JSON.parse(bodyParams);
        bodyParams = bodyParams.map((mapObj) => {
          return Object.keys(mapObj)[0];
        });
      }
      var msgText = bodyText;
      var regex = /\{{(.+?)\}}/g;
      var placeholders = msgText.match(regex);
      if (placeholders && placeholders.length) {
        for (
          var i = 0;
          i < placeholders.length && bodyParams && i < bodyParams.length;
          i++
        ) {
          var placeholder = placeholders[i];
          var tag = bodyParams[i];
          if (typeof tag != "string") {
            tag = Object.keys(tag)[0].replace("<", "").replace(">", "");
          }
          var tagData = this.simpleMessageTags.find((item) => {
            return item.tag == tag;
          });
          var text = tagData ? tagData.description : tag;
          var elemParam =
            '<input type="text" class="inline-text-input" placeholder="' +
            text +
            '" />';
          msgText = msgText.replace(placeholder, elemParam);
        }
      }
      console.log(msgText);
      return msgText;
    },
    fillMessageData(message, messageParams) {
      this.messageJson = this.getWhatsappJSONFormat();
      if (message.FOOTER) {
        //var footText = message.FOOTER.text || "";
      }
      if (message.HEADER) {
        var header = message.HEADER;
        this.messageJson.header.format = header.format;
        if (header.format.toLowerCase() == "text") {
          this.messageJson.header.text = header.text;
        } else {
          //this.messageJson.header.url = header.url;
        }
      }
      if (message.BODY) {
        var bodyText = this.parseBodyText(
          message.BODY.text,
          messageParams.BODY
        );
        this.messageJson.template.text = bodyText;
      }
      if (message.BUTTONS && message.BUTTONS.length) {
        var buttons = [];
        var quick_replies = [];
        for (var btn of message.BUTTONS) {
          var button = {};
          if (btn.type == "PHONE_NUMBER") {
            button.type = "phone_number";
            button.payload = btn.phone_number;
            button.title = btn.text;
            buttons.push(button);
          } else if (btn.type == "URL") {
            button.type = "web_url";
            button.url = btn.url;
            button.title = btn.text;
            buttons.push(button);
          } else if (btn.type == "QUICK_REPLY") {
            button.payload = btn.payload;
            button.title = btn.text;
            quick_replies.push(button);
          }
        }
        this.messageJson.template.buttons = buttons;
        this.messageJson.template.quick_replies = quick_replies;
      }
      console.log(this.messageJson);
    },
    getEditedBodyParams() {
      var arrTags = [];
      var customTag = document.querySelectorAll(
        ".waTemplateMessagePre .inline-text-input"
      );
      if (customTag.length > 0) {
        for (
          let i_wa_custTag = 0;
          i_wa_custTag < customTag.length;
          i_wa_custTag++
        ) {
          var elementTag = customTag[i_wa_custTag].value;
          var elementObj = {};
          elementObj[elementTag] = "text";
          arrTags.push(elementObj);
        }
      }
      return arrTags;
    },
    getEditedHeaderParams() {
      var arrTags = [];
      if (this.messageJson.header.url) {
        var elementTag = this.messageJson.header.url;
        var elementObj = {};
        elementObj[elementTag] = this.messageJson.header.format.toLowerCase();
        arrTags.push(elementObj);
      }
      return arrTags;
    },
    getEditedButtonParams(type) {
      var arrBtn = [];
      if (
        type == "quick_replies" &&
        this.messageJson.template.quick_replies &&
        this.messageJson.template.quick_replies.length
      ) {
        for (
          var i = 0;
          i < this.messageJson.template.quick_replies.length;
          i++
        ) {
          var temp_payload = this.messageJson.template.quick_replies[i].payload;
          var tempQR = {
            type: "button",
            sub_type: "quick_reply",
            index: i,
            parameters: [
              {
                type: "payload",
                payload: temp_payload,
              },
            ],
          };
          arrBtn.push(tempQR);
        }
      }
      return arrBtn;
    },
    generate_wa_message_params() {
      var message_params = undefined;

      var bodyParams = this.getEditedBodyParams();
      var headerParams = this.getEditedHeaderParams();
      var quickReplyParams = this.getEditedButtonParams("quick_replies");

      if (bodyParams.length || headerParams.length || quickReplyParams.length) {
        message_params = {};

        if (bodyParams.length) {
          message_params.BODY = bodyParams;
        }
        if (headerParams.length) {
          message_params.HEADER = headerParams;
        }
        if (quickReplyParams.length) {
          message_params.BUTTONS = quickReplyParams;
        }
      }
      console.log(message_params);
      return message_params;
    },
    async sendTemplate() {
      let sendResponse = null;
      if (this.waTemplateToSend.templateData != null) {
        this.loading = true;

        try {
          let reqBody = {
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
            message: "s3ndt3mpl4te_" + this.waTemplateToSend.templateData.id,
            messagetype: "template",
            message_params: JSON.stringify(this.generate_wa_message_params()),
          };
          this.sending = this.newChatMessage;
          this.newChatMessage = undefined;
          sendResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "manual-message",
            reqBody
          );

          if (sendResponse.status == 200 || sendResponse.status == 201) {
            this.scrollToBottom();
          } else {
            console.log("Response:", sendResponse);
            this.loading = false;
          }
        } catch (error) {
          console.log("Response:", sendResponse);
          this.loading = false;
        } finally {
          this.loading = false;
          this.sending = undefined;
          this.resetTemplateDataForWA();
          this.newChatMessage = undefined;
          this.sendWhatsappTemplate = false;

          if (this.activeCustomer.channel == "whatsapp") {
            if (
              this.calculate24Plus1RuleForCustomer(
                this.activeCustomer.userReplyTimestamp
              ) >= 24
            ) {
              this.isWhatsappChat_old24H = true;
            }
          }
        }
      }
    },
    sendLocationTemplate() {
      let formError = false;
      let name = this.sendLocation.buttonTitle;
      let addressname = this.sendLocation.address;
      let latitude = this.sendLocationLatitudeText;
      let longitude = this.sendLocationLongitudeText;
      let sendResponse = null;

      let autocompleteInput =
        this.$refs.autocomplete || document.getElementById("autocomplete");
      autocompleteInput.getInputElement().then(async (e) => {
        addressname = e.value;
        if (name === undefined || addressname === undefined) {
          formError = true;
          this.updateToast({
            open: true,
            message: "Please fill in the required fields!",
            type: "danger",
          });
        } else if (latitude === undefined || longitude === undefined) {
          formError = true;
          this.updateToast({
            open: true,
            message: "Please provide latitude & longitude!",
            type: "danger",
          });
        }

        if (formError == false) {
          this.loading = true;

          try {
            let reqBody = {
              conversationid: this.conversationid,
              page_conversation_id: this.page_conversation_id,
              pageid: this.pageid,
              platform: this.platform,
              message: "",
              messagetype: "location",
              name: name,
              addressname: addressname,
              latitude: latitude,
              longitude: longitude,
            };
            this.sending = "Sending location...";
            this.newChatMessage = undefined;
            sendResponse = await jwtInterceptor.post(
              this.environment("VUE_APP_ROOT_API") + "manual-message",
              reqBody
            );

            if (sendResponse.status == 200 || sendResponse.status == 201) {
              this.scrollToBottom();
              this.updateToast({
                open: true,
                message: "Location has been sent successfully!",
                type: "success",
              });
            } else {
              this.loading = false;
              console.log("Response:", sendResponse);
            }
          } catch (error) {
            this.loading = false;
            console.log("Response:", sendResponse);
          } finally {
            this.loading = false;
            this.sending = undefined;
            this.newChatMessage = undefined;
            (this.sendLocationModal = false),
              (this.sendLocation = {
                buttonTitle: undefined,
                address: undefined,
              });
            (this.sendLocationLatitudeText = undefined),
              (this.sendLocationLongitudeText = undefined),
              (this.locationForm = {
                street_number: "short_name",
                route: "long_name",
                locality: "long_name",
                administrative_area_level_1: "long_name",
                country: "long_name",
                postal_code: "short_name",
              });
          }
        }
      });
    },
    async sendContactTemplate() {
      let formError = false;
      let name = this.sendContact.buttonTitle;
      let number = this.sendContact.number;
      let sendResponse = null;
      if (name === undefined || number === undefined) {
        formError = true;
        this.updateToast({
          open: true,
          message: "Please fill in the required fields!",
          type: "danger",
        });
      }
      if (formError == false) {
        try {
          let reqBody = {
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
            message: "",
            messagetype: "contact",
            name: name,
            phone: number,
          };
          this.sending = "Sending contact...";
          this.newChatMessage = undefined;
          sendResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "manual-message",
            reqBody
          );

          if (sendResponse.status == 200 || sendResponse.status == 201) {
            this.scrollToBottom();
            this.updateToast({
              open: true,
              message: "Contact has been sent successfully!",
              type: "success",
            });
          } else {
            console.log("Response:", sendResponse);
          }
        } catch (error) {
          console.log("Response:", sendResponse);
        } finally {
          this.sending = undefined;
          this.newChatMessage = undefined;
          (this.sendContactModal = false),
            (this.sendContact = {
              buttonTitle: undefined,
              number: undefined,
            });
        }
      }
    },
    resetTemplateDataForWA() {
      this.isWhatsappChat_old24H = false;
      this.isWhatsappChat_sendTemplate = false;
      this.isWhatsappSendTemplateEnabled = false;
      this.whatsappTemplateLanguages = [];
      this.selectedWhatsappTemplate = "-1";
      this.selectedTemplateLanguage = "-1";
      this.messageJson = {};
      this.selectedTemplate = {};

      this.waTemplateToSend = {
        template: undefined,
        language: undefined,
        templateData: null,
      };
    },
    async fetchAllBotBuilderFlows() {
      let reqBody = {};
      this.loadingStartBotFlowModal = true;
      this.allActiveBotBuilderFlowsResult = [];
      try {
        let fetchBotActionsResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-action",
          reqBody
        );
        if (
          (fetchBotActionsResponse.status == 200 ||
            fetchBotActionsResponse.status == 201) &&
          fetchBotActionsResponse.data.success
        ) {
          for (
            var i = 0;
            i < fetchBotActionsResponse.data.actions.length;
            i++
          ) {
            if (fetchBotActionsResponse.data.actions[i].active == true) {
              this.allActiveBotBuilderFlowsResult.push(
                fetchBotActionsResponse.data.actions[i]
              );
            }
          }
        } else {
          console.log("Response: ", fetchBotActionsResponse);
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loadingStartBotFlowModal = false;
      }
    },
    async startABotBuilderFlowForThisTrigger() {
      if (this.botFlowToShare.triggers?.length) {
        let reqBody = {
          conversationid: this.conversationid,
          page_conversation_id: this.page_conversation_id,
          pageid: this.pageid,
          platform: this.platform,
          hashtag: this.botFlowToShare.triggers[0],
        };
        try {
          let startBotFlowResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "bot-takeover",
            reqBody
          );
          if (
            (startBotFlowResponse.status == 200 ||
              startBotFlowResponse.status == 201) &&
            startBotFlowResponse.data.success
          ) {
            this.updateToast({
              open: true,
              message: "Flow has been sent successfully to the customer.",
              type: "success",
            });

            if(this.customersResults?.length) for (var i = 0; i < this.customersResults.length; i++) {
              if(this.conversationid == this.customersResults[i].conversationid) {
                this.customersResults[i].active = true;
                break;
              }
            }

            if(this.activeChatsTab_customersResults?.length) for (var i1 = 0; i1 < this.activeChatsTab_customersResults.length; i1++) {
              if(this.conversationid == this.activeChatsTab_customersResults[i1].conversationid) {
                this.activeChatsTab_customersResults[i1].active = true;
                break;
              }
            }

            if(this.requestsChatsTab_customersResults?.length) for (var i2 = 0; i2 < this.requestsChatsTab_customersResults.length; i2++) {
              if(this.conversationid == this.requestsChatsTab_customersResults[i2].conversationid) {
                this.requestsChatsTab_customersResults[i2].active = true;
                break;
              }
            }

            if(this.botChatsTab_customersResults?.length) for (var i3 = 0; i3 < this.botChatsTab_customersResults.length; i3++) {
              if(this.conversationid == this.botChatsTab_customersResults[i3].conversationid) {
                this.botChatsTab_customersResults[i3].active = true;
                break;
              }
            }
            //this.distribute_customersResults_intoTabs(this.customersResults);
          } else {
            console.log("Response:", startBotFlowResponse);
            this.updateToast({
              open: true,
              message: startBotFlowResponse.data.errorMessage,
              type: "warning",
            });
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger",
          });
        } finally {
          this.startBotFlowModal = false;
          this.confirmSharingBotFlow = false;
          this.botFlowToShare = null;
        }
      } else {
        this.updateToast({
          open: true,
          message: "The selected bot flow can not be sent",
          type: "danger",
        });
      }
    },
    async loadStoryPreviewForSingleMessage(messageid, attachment, pageid) {
      let reqBody = {
        pageid: pageid,
        mid: messageid,
      };
      try {
        let getIgStoryResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-ig-story",
          reqBody
        );
        console.log(getIgStoryResponse);
        if (
          (getIgStoryResponse.status == 200 ||
            getIgStoryResponse.status == 201) &&
          getIgStoryResponse.data.success
        ) {
          for (
            var i = 0;
            i < this.activeUserConversationDataResult.length;
            i++
          ) {
            if (
              messageid == this.activeUserConversationDataResult[i].messageid
            ) {
              if (
                typeof getIgStoryResponse.data.link == "undefined" ||
                getIgStoryResponse.data.link == "" ||
                getIgStoryResponse.data.link == "None" ||
                getIgStoryResponse.data.link == null
              ) {
                this.activeUserConversationDataResult[
                  i
                ].template.attachment_preview_url = "expired";
              } else {
                this.activeUserConversationDataResult[
                  i
                ].template.attachment_preview_url =
                  getIgStoryResponse.data.link;
              }
            }
          }

          this.disabling_rightClick_for_privacy_of_content();
        } else {
          console.log("Response:", getIgStoryResponse);
          this.updateToast({
            open: true,
            message: getIgStoryResponse.data.errorMessage,
            type: "warning",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    toggleSendTemplateForWA() {
      this.isWhatsappChat_sendTemplate = true;
      this.enableWhatsappTemplating();
    },
    enableWhatsappTemplating() {
      this.isWhatsappSendTemplateEnabled = true;
    },
    disableWhatsappTemplating() {
      this.isWhatsappSendTemplateEnabled = false;
      this.isWhatsappChat_sendTemplate = false;
    },
    async selectRecentUploadFile(file) {
      try {
        this.loading = true;
        let sendResponse = null;
        let reqBody = {
          conversationid: this.conversationid,
          page_conversation_id: this.page_conversation_id,
          pageid: this.pageid,
          platform: this.platform,
          message: file.url,
          messagetype: undefined,
          filetype: undefined,
          extension: file.extension,
          contenttype: file.contenttype,
          name: file.name,
        };
        let ext = file.extension.toLowerCase();
        if (
          ext.indexOf("png") > -1 ||
          ext.indexOf("jpg") > -1 ||
          ext.indexOf("jpeg") > -1 ||
          ext.indexOf("gif") > -1 ||
          ext.indexOf("svg") > -1
        ) {
          reqBody["messagetype"] = "image";
          reqBody["filetype"] = "image";
        } else if (
          ext.indexOf("mp4") > -1 ||
          ext.indexOf("mpeg") > -1 ||
          ext.indexOf("mpeg4") > -1
        ) {
          reqBody["messagetype"] = "videolink";
          reqBody["filetype"] = "video";
        } else {
          reqBody["messagetype"] = "document";
          reqBody["filetype"] = "document";
        }
        this.sending = "Sending file...";
        this.newChatMessage = undefined;
        sendResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "manual-message",
          reqBody
        );

        if (sendResponse.status == 200 || sendResponse.status == 201) {
          this.scrollToBottom();
          this.updateToast({
            open: true,
            message: "File has been sent to customer successfully!",
            type: "success",
          });
        } else {
          console.log("Response:", sendResponse);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.sending = undefined;
        this.newChatMessage = undefined;
        this.attachAndSendModal = false;
        this.loading = false;
      }
    },
    async fetchFbNotificationOptins() {
      let fbNotificationOptinsResultResponse = await jwtInterceptor.post(
        this.environment("VUE_APP_ROOT_API") + "notification-template-fetch"
      );

      if (
        fbNotificationOptinsResultResponse.status == 200 ||
        fbNotificationOptinsResultResponse.status == 201
      ) {
        if (fbNotificationOptinsResultResponse.data.success) {
          this.fbNotificationOptinsResult =
            fbNotificationOptinsResultResponse.data.notification_templates;
        } else {
          this.updateToast({
            open: true,
            message: fbNotificationOptinsResultResponse.data.errorMessage,
            type: "danger",
          });
        }
      } else {
        console.log("Response:", fbNotificationOptinsResultResponse);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    async sendFbNotificationOptin() {
      this.sendFbNotificationOptin_sending = true;
      let sendResponse = null;
      if (this.notificationToSend != null) {
        this.loading = true;

        try {
          let reqBody = {
            conversationid: this.conversationid,
            page_conversation_id: this.page_conversation_id,
            pageid: this.pageid,
            platform: this.platform,
            message: "Facebook Opt-in Notification",
            messagetype: "notification",
            title: this.notificationToSend.title,
            notification_type: this.notificationToSend.notificationtype,
            frequency: this.notificationToSend.frequency,
            imageurl: this.notificationToSend.imageurl,
          };
          this.sending = "Facebook Opt-in Notification";
          this.newChatMessage = undefined;
          sendResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "manual-message",
            reqBody
          );

          if (sendResponse.status == 200 || sendResponse.status == 201) {
            this.scrollToBottom();
          } else {
            console.log("Response:", sendResponse);
            this.loading = false;
          }
        } catch (error) {
          console.log("Response:", sendResponse);
          this.loading = false;
        } finally {
          this.loading = false;
          this.sending = undefined;
          this.newChatMessage = undefined;
          this.fb_notification_optin_container_visible = false;
          this.FbOptinNotification_selectedId = undefined;
          this.sendFbNotificationOptin_sending = false;
        }
      }
    }
  },
};
</script>