<template>
  <div>
    <p class="orderIdText">
      <b>ID:</b> {{ order.ordernumber }}
    </p>
    <p class="orderTypeEmojiArea" v-if="order.variables && order.variables.regalo" style="right: 55px;">
      <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4N-dtaMIDA" style="height: 35px; width: auto;" />
    </p>

    <p class="orderTypeEmojiArea" v-if="renderFnbOrderType(order.takeaway, order.variables) === 'Delivery'">
      <img src="https://jumper.ai/web-s/resources/images/fnb-delivery-icon.png" style="height: 35px; width: auto;" />
    </p>

    <p class="orderTypeEmojiArea" v-if="renderFnbOrderType(order.takeaway, order.variables) === 'Pickup' && (!order.variables.carplate || order.variables.carplate == '' || order.variables.carplate == null || order.variables.carplate == 'None')">
      <img src="https://jumper.ai/web-s/resources/images/fnb-pickup-icon.png" style="height: 35px; width: auto;" />
    </p>

    <p class="orderTypeEmojiArea" v-if="renderFnbOrderType(order.takeaway, order.variables) === 'Dine-in'">
      <img src="https://jumper.ai/web-s/resources/images/fnb-dinein-icon.png" style="height: 35px; width: auto;" />
    </p>

    <p class="orderTypeEmojiArea" v-if="renderFnbOrderType(order.takeaway, order.variables) === 'Take-out'">
      <img src="https://jumper.ai/web-s/resources/images/fnb-takeout-icon.png" style="height: 35px; width: auto;" />
    </p>

    <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAoMa8mdIIDA" class="pickupViaGrabImage" v-if="order.variables && order.variables.fromgrab" />

    <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4I6KhrAIDA" class="pickupViaGrabImage" v-if="order.variables && order.variables.fromlalamove" />

    <img src="https://jumper.ai/web-s/resources/images/fnb-curbside-car-icon.png" class="curbsidePickupImage" v-if="order.variables && order.variables.carplate && order.variables.carplate.length" />

    <p class="customerName">
      <span v-if="order.buyer.name && order.buyer.name">{{ order.buyer.name }}</span>
      <span v-else>Anonymous</span>
      
      <span class="orderPickupStatusTag" style="padding: 0px; margin: 0px; margin-left: 3px !important;" v-if="order.variables.documentdiscount && order.variables.discount_pwd_sc && order.variables.documenttype != ''">
        <span style="text-transform: uppercase;">{{ order.variables.documenttype }}</span>
      </span>
    </p>

    <p class="orderIdText" v-if="moment(order.created).isSameOrAfter(moment()) || order.variables.regalo" style="padding-top: 5px; padding-bottom: 10px;">
      <span v-if="moment(order.created).isSameOrAfter(moment())" class="orderPickupStatusTag" style="padding: 0px; margin: 0px; margin-right: 5px !important;">
        <span>Advance order</span>
      </span>
      <span class="orderPickupStatusTag" style="padding: 0px; margin: 0px;" v-if="order.variables.regalo">
        <span>ReGALO {{ renderFnbOrderType(order.takeaway, order.variables) }}</span>
      </span>
    </p>

    <p class="orderIdText" v-if="moment(order.created).isSameOrAfter(moment())">
      <b>Ordered on:</b>
      <span>{{ moment(order.order_updated_on).format('DD-MM hh:mm a') }}</span>
    </p>

    <p class="orderIdText" v-if="order.pickuptime && order.pickuptime.length">
      <b v-if="user.enterprise_Name != 'walls_my'">Pickup time:</b>
      <b v-else>Delivery date:</b>
      
      <span v-if="user.enterprise_Name === 'walls_my'">
        {{ moment(order.pickuptime).format('DD-MMM') }}
      </span>
      <span v-else>
        {{ moment(order.pickuptime).format('DD-MMM hh:mm a') }}
      </span>
    </p>

    <p class="orderIdText" v-if="order.variables.deliverydate && order.variables.deliverydate.length">
      <b>Delivery date & time:</b>
      {{ moment(order.variables.deliverydate).format('DD-MMM') }}&nbsp;{{ moment(order.variables.deliverytime).format('hh:mm a') }}
    </p>

    <!-- outlet name start -->
    <!-- <p class="orderIdText" v-if="squareConnected == true && order.address.name && order.address.name != ''"><b>Outlet name:</b> {{ order.address.name }}</p> -->

    <p class="orderIdText" v-if="!squareConnected && order.location.name">
      <b>Outlet name:</b>
      <span v-if="order.location.name.length">{{ order.location.name }}</span>
      <!-- <span v-if="order.location.name == ''">{{ order.location.addressLine1 }}</span> -->
      <span v-else>NA</span>
    </p>
    <!-- outlet name end -->

    <p class="orderIdText">
      <b>Payment via:</b>
      <span v-if="redRibbon_ph_manila">
        <span class="orderPickupStatusTag" style="padding: 0px; margin: 0px; margin-left: 3px !important;">
          <span class="red" v-if="renderPaymentModeName(order.gateway) === 'COD'">{{ renderPaymentModeName(order.gateway) }}</span>
          <span class="green" v-if="renderPaymentModeName(order.gateway) !== 'COD' && user.enterprise_Name !== 'greenwich_ph'">{{ renderPaymentModeName(order.gateway) }}</span>
          <span class="green" v-if="renderPaymentModeName(order.gateway) !== 'COD' && user.enterprise_Name === 'greenwich_ph'">Online Payment</span>
        </span>
      </span>
      <span v-else>
        {{ renderPaymentModeName(order.gateway) }}
      </span>
    </p>

    <p class="cartItemsLink">
      <span class="hoverUnderline">
        {{ order.cart_items }}
        <span v-if="order.cart_items <= 1">item</span>
        <span v-if="order.cart_items > 1">items</span>
        ordered

        <i class="fa fa-angle-right"></i>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: ['order'],
  methods: {
    renderFnbOrderType(takeaway, variables) {
      let delivery = false;
      for(var key in variables) {
        if(key == 'state') {
          if(variables[key] == 'delivery') delivery = true;
          else if(variables[key] == 'pickup') return "Pickup";
          else if(variables[key] == 'dinein') return "Dine-in";
          else if(variables[key] == 'takeout') return "Take-out";
        }
      }
      if(delivery) return "Delivery";
      else {
        if(takeaway == true) return "Pickup";
        else return "Dine-in";
      }
    }
  }
}
</script>