<template>
  <master-layout pageTitle="Integration Settings">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" v-model="activeTab">
            <ion-segment-button value="connected">
              <ion-label>Connected</ion-label>
            </ion-segment-button>
            <ion-segment-button value="available">
              <ion-label>Available</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="activeTab === 'connected'">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Connected Integrations</ion-card-title>
              <ion-card-subtitle>The integrations active for your Jumper Account</ion-card-subtitle>
              <resetIntegrationsData @isLoading="(loadingState) => loading = loadingState"></resetIntegrationsData>
            </ion-card-header>
            <ion-card-content>
              <ion-list v-for="(value, key) in connectedCategories" :key="key">
                <ion-list-header>
                  <b>{{ key }}</b>
                </ion-list-header>
                <ion-item v-for="(thisMethod, mIndex) in value" :key="mIndex">
                  <div class="card-wrapper">
                    <div>
                      <ion-thumbnail v-if="thisMethod.logo" slot="start">
                        <img :src="thisMethod.logo" />
                      </ion-thumbnail>
                    </div>
                    <div>
                      <ion-label>
                        <h2>{{ thisMethod.name }}</h2>
                        <h6 v-if="thisMethod.description">{{ thisMethod.description }}</h6>
                      </ion-label>
                    </div>

                    <ion-button v-if="thisMethod.settingButton" @click="settings(thisMethod)" color="primary" slot="end" fill="outline">Settings</ion-button>
                    <ion-button @click="() => {confirmDisconnect = true; toDisconnect = thisMethod;}" color="danger" slot="end" fill="outline">Disconnect</ion-button>
                    <div class="warning-note-wrapper" v-if="thisMethod.active == false"><ion-icon class="ion-no-margin" slot="start" :icon="warningOutline" color="warning"></ion-icon><ion-text class="warning-note" color="warning">Something is not working, please re-connect your BigCommerce store.</ion-text></div>
                  </div>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </div>
        <div v-if="activeTab === 'available'">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Available Integrations</ion-card-title>
              <ion-card-subtitle>The integrations available for your country.</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content class="available-list">
              <ion-list v-for="(value, key) in availableCategories" :key="key" :class="{disabled: isDisabledCategory(key)}" >
                <ion-list-header>
                  <b>{{ key }}</b>
                  <div class="warning-note-wrapper" v-if="isDisabledCategory(key)">
                    <ion-icon class="ion-no-margin" slot="start" :icon="warningOutline" color="danger"></ion-icon>
                    <ion-text class="warning-note danger" v-if="isDisabledCategory(key)" color="danger">{{shipmentAlreadyConnectedText(key)}}</ion-text>
                  </div>
                </ion-list-header>
                <ion-item v-for="(thisMethod, mIndex) in value" :key="mIndex">
                  <ion-thumbnail v-if="thisMethod.logo" slot="start">
                    <img :src="thisMethod.logo" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2>{{ thisMethod.name }}</h2>
                    <h6 v-if="thisMethod.description">{{ thisMethod.description }}</h6>
                  </ion-label>
                  <ion-button @click="connect(thisMethod)" slot="end">Connect</ion-button>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
    <ion-modal
      v-if="connectModal"
      :is-open="connectModal"
      @didDismiss="() => {connectModal = false; toConnect = null;}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Connect {{ toConnect.name }}</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => {connectModal = false; toConnect = null;}">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item v-for="(thisField, fIndex) in toConnect.fields" :key="fIndex">
            <ion-label>{{ thisField.placeholder }}</ion-label>
            <ion-select :disabled="thisField.disabled" slot="end" v-if="thisField.type === 'select'" v-model="thisField.value" :placeholder="thisField.placeholder">
              <ion-select-option v-for="(thisOption, oIndex) in thisField.options" :key="oIndex" :value="thisOption.value">{{ thisOption.name }}</ion-select-option>
            </ion-select>
            <div slot="end" v-else>
              <ion-checkbox :disabled="thisField.disabled" slot="end" v-if="thisField.type === 'checkbox'" v-model="thisField.value" color="dark"></ion-checkbox>
              <ion-input :disabled="thisField.disabled" slot="end" v-else v-model="thisField.value" :placeholder="thisField.placeholder"></ion-input>
            </div>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button :disabled="!allowConnect" @click="confirmConnect" color="dark">Connect</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <ion-modal class="big-commerce-settings" v-if="bigCommerceSettingModal" :is-open="bigCommerceSettingModal" @didDismiss="() => {bigCommerceSettingModal = false;}">
      <ion-page>
        <ion-header>
          <h2 class="ion-no-margin">BigCommerce callback URL setup</h2>
        </ion-header>
        <ion-content class="ion-padding">
          <div>Copy the 'Callback URL' from below and paste it in your BigCommerce App's 'Technical settings' page:</div>
          <ion-item class="copy-url-wrapper" lines="none">
            <div value="url-text">{{bigCommerceCallBackUrl}}</div>
            <ion-buttons slot="end">
              <ion-button class="btn-copy-url" @click="copyBigCommerceCallBackUrl" fill="clear">
                <ion-icon class="ion-no-margin" slot="start" :icon="linkOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-content>
      </ion-page>
    </ion-modal>

    <ion-alert
      v-if="confirmDisconnect"
      :is-open="confirmDisconnect"
      header="Are you sure?"
      :message="'Are you sure you want to disconnect ' + toDisconnect.name + '?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {confirmDisconnect = false; toDisconnect = null;},
      },
      {
        text: 'Yes',
        handler: () => {
          disconnect()
        },
      }]"
      @didDismiss="() => {confirmDisconnect = false; toDisconnect = null;}"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, linkOutline, warningOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSelect, IonSelectOption, IonCheckbox } from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import resetIntegrationsData from "../../components/integration/resetIntegrationsData.vue"

export default {
  components: { IonSelect, IonSelectOption, IonCheckbox, resetIntegrationsData },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      close,
      activeTab: 'connected',
      connectModal: false,
      toConnect: null,
      confirmDisconnect: false,
      toDisconnect: null,
      bigCommerceCallBackUrl: 'https://jumperdevnew.appspot.com/bigcommerce/callback/6710478496595968',
      bigCommerceSettingModal: false,
      linkOutline,
      warningOutline
    }
  },
  computed: {
    ...mapGetters({
      availableMethods: "integration/getAvailable",
      connectedMethods: "integration/getConnected",
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
    allowConnect() {
      return this.toConnect && this.toConnect.fields && this.toConnect.fields.every((thisField) => thisField.value !== undefined) ? true : false;
    },
    permittedAvailableMethods() {
      return this.isAdmin ? this.availableMethods : this.availableMethods.filter(e => this.oldPermissions.integrations[e.code])
    },
    availableCategories() {
      let temp = {};
      this.permittedAvailableMethods.forEach(thisMethod => {
        if(!temp[thisMethod.category]) temp[thisMethod.category] = [];
        temp[thisMethod.category].push(thisMethod);
      })
      return temp;
    },
    connectedCategories() {
      let temp = {};
      this.connectedMethods.forEach(thisMethod => {
        if(!temp[thisMethod.category]) temp[thisMethod.category] = [];
        temp[thisMethod.category].push(thisMethod);
      })
      return temp;
    },
    isEcommerceConnected() {
      return this.connectedCategories?.Ecommerce?.length;
    },
    isShipmentConnected() {
      return this.connectedCategories['Shipment providers']?.length;
    }
  },
  methods: {
    ...mapActions({
      updateIntegration: "integration/updateIntegration"
    }),
    shipmentAlreadyConnectedText(key) {
      let text = "";
      switch (key) {
        case 'Ecommerce': {
          const connectedEcommerceName = this.connectedCategories.Ecommerce[0].name;
          text = `You have currently connected your ${connectedEcommerceName} account. To connect another ecommerce platform, please disconnect ${connectedEcommerceName}.`;
          break;
        }
        case 'Shipment providers': {
          const connectedShipments = this.connectedCategories['Shipment providers'];
          if (connectedShipments?.length) {
            const connectedShipmentName = connectedShipments[0].name;
            text = `You have currently connected your ${connectedShipmentName} account. To connect another shipment platform, please disconnect ${connectedShipmentName}.`;
          }
          break;
        }
        default:
          text = "";
          break;
      }
      return text;
    },
    isDisabledCategory(key) {
      let isDisabled;
      switch (key) {
        case 'Shipment providers':
          isDisabled = this.isShipmentConnected;
        break;
        case 'Ecommerce':
          isDisabled = this.isEcommerceConnected;
        break;
        default:
          isDisabled = false;
        break;
      }
      return isDisabled;
    },
    copyBigCommerceCallBackUrl() {
      navigator.clipboard.writeText(this.bigCommerceCallBackUrl).then(() => {
        this.updateToast({ open: true, message: "Callback url copied successfully.", type: "success" })
      }, function (err) {
        this.updateToast({ open: true, message: "Copy callback url failed.", type: "danger" })
      });
    },
    settings(thisMethod) {
      const { code } = thisMethod;
      switch (code) {
        case 'bigcommerce':
          this.bigCommerceSettingModal = true;
        break;
        default:
        break;
      }
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async ionViewWillEnter(){
      this.init();
    },
    async init() {
      console.log("Initializing");
    },
    async connect(method) {
      try {
        if(method.connection_type === 'form') {
          if(method.code === 'zapier' && method.form_link) window.open(method.form_link, '_blank');
          else {
            this.connectModal = true;
            this.toConnect = method;
          }
        } else if(method.connection_type === 'redirect_after_api_call' && method.api && method.api.link && method.api.type && method.api.response_param) {
          this.loading = true;
          let redirectLinkResponse = await jwtInterceptor[method.api.type](method.api.link);
          if ((redirectLinkResponse.status == 200 || redirectLinkResponse.status == 201) && redirectLinkResponse.data) {
            console.log(redirectLinkResponse.data);
            if (redirectLinkResponse.data.success) {
              await this.updateLocalState(method, true);
              // await this.Storage.remove({ key: 'jmpr_getCommerceData' });
              // window.open(redirectLinkResponse.data[method.api.response_param], '_blank');
            } else {
              this.updateToast({ open: true, message: redirectLinkResponse.data.errorMessage, type: "danger" })
            }
          } else {
            console.log("redirectLinkResponse", redirectLinkResponse);
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        } else if (method.connection_type === 'component') {
          window.location.href = `/settings/integrations/connect/${method.component_name}`
        } else if (method.connection_type === 'redirect') {
          window.location.href = method.redirectUrl;
        } else {
          console.log("Invalid connection type for: ", method);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async confirmConnect() {
      try {
        this.loading = true;
        let reqBody = {};
        this.toConnect.fields.forEach(thisField => !reqBody[thisField.field] ? reqBody[thisField.field] = thisField.value : '');
        
        let confirmConnectResponse = await jwtInterceptor.post(this.toConnect.form_link, reqBody);
        if ((confirmConnectResponse.status == 200 || confirmConnectResponse.status == 201) && confirmConnectResponse.data) {
          console.log(confirmConnectResponse.data);
          if(confirmConnectResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Integration Channel Connected Successfully",
              type: "success"
            })
            await this.Storage.remove({ key: 'jmpr_getCommerceData' });
            window.location.href = window.location.pathname;
          } else {
            this.updateToast({
              open: true,
              message: confirmConnectResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", confirmConnectResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.toConnect = null;
        this.connectModal = false;
        this.loading = false;
      }
    },
    async updateLocalState(toDisconnect, connected) {
      const { storageKey, name: toDisconnectName, easyship, code } = toDisconnect;
      let dataToUpdate = { is_active: true, active: true };
      if (connected) {
        dataToUpdate.is_active = true;
        dataToUpdate.active = true;
      } else {
        dataToUpdate.is_active = false;
        dataToUpdate.active = false;
        if (easyship) {
          dataToUpdate['easyship'] = [];
        }
      }

      const integrationDataToUpdate = {
        integrationName: code,
        dataToUpdate,
        storageName: storageKey
      };
      await this.updateIntegration(integrationDataToUpdate);
    },
    async disconnect() {
      try {
        this.loading = true;
        const cloneToDisconnect = this.toDisconnect;
        let disconnectResponse = await jwtInterceptor.post(this.toDisconnect.disconnect_url, cloneToDisconnect.disconnect_params);
        if ((disconnectResponse.status == 200 || disconnectResponse.status == 201) && disconnectResponse.data) {
          console.log(disconnectResponse.data);
          if(disconnectResponse.data.success) {
            this.updateToast({
              open: true,
              message: `${cloneToDisconnect.name} disconnected Successfully`,
              type: "success"
            })

            await this.updateLocalState(cloneToDisconnect, false);
          } else {
            this.updateToast({
              open: true,
              message: disconnectResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", disconnectResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.toDisconnect = null;
        this.confirmDisconnect = false;
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.copy-url-wrapper {
  margin: 20px 0 30px 0;
  color: var(--ion-color-medium);
  background-color: #f5f5f5;
  cursor: not-allowed;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.big-commerce-settings {
  --height: 220px;
}
.big-commerce-settings h2 {
  text-align: center;
  padding: 10px;
}
.big-commerce-settings ion-content{
  padding: 10px;
}
.warning-note-wrapper ion-icon {
  margin-top: 3px;
  min-width: 20px;
  font-size: 14px;
}
.warning-note {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--ion-color-warning);
  font-size: 14px;
}
.warning-note.danger {
  color: var(--ion-color-danger);
}
.available-list .disabled ion-list-header b,
.available-list .disabled ion-item { opacity: 0.4; }
.available-list ion-list-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.warning-note-wrapper {
  display: flex;
}
</style>