import allCountryCurrencyData from '/src/resources/js/all-countries-currencies-list';
import { environment } from '/src/shared/env';
import { Preferences as Storage } from "@capacitor/preferences";
import jwtInterceptor from "/src/shared/jwtInterceptor";

const POPULAR = 1, MANUAL = 2; // OTHER = 3;

const state = () => ({
  "authorize": {
    is_active: false,
    available: false,
    name: "Authorize.Net",
    description: "Accept credit cards payment on the go.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/authorizenet-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'authorize/connect',
    fields: [{ field: 'login_id', placeholder: 'Login ID', value: undefined }, { field: 'transaction_key', placeholder: 'Transaction Key', value: undefined }, { field: 'client_key', placeholder: 'Client Key', value: undefined }],
    type: POPULAR
  },
  "worldpay": {
    is_active: false,
    available: false,
    name: "WorldPay",
    description: "",
    logo: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA0ObzmrYIDA",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'worldpay/connect',
    fields: [{ field: 'username', placeholder: 'Username', value: undefined }, { field: 'password', placeholder: 'Password', value: undefined }, { field: 'merchant_code', placeholder: 'Merchant Code', value: undefined }, { field: 'installation_id', placeholder: 'Installation ID', value: undefined }, { field: 'mac_secret', placeholder: 'MAC Secret', value: undefined }],
    type: POPULAR
  },
  "adyen": {
    is_active: false,
    available: false,
    name: "Adyen",
    description: "Accept payments everywhere.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/adyen-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'adyen/connect',
    fields: [{ field: 'api_key', placeholder: 'API Key', value: undefined }, { field: 'merchant_account', placeholder: 'Merchant Account', value: undefined }, { field: 'company_name', placeholder: 'Company Name', value: undefined }, { field: 'random', placeholder: 'Random', value: undefined }],
    type: POPULAR
  },
  "paymongo": {
    is_active: false,
    available: false,
    name: "PayMongo",
    description: "Accept payments in chat via credit cards, GCash, GrabPay & more.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/logos/paymongo-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'paymongo/connect',
    fields: [{ field: 'public_key', placeholder: 'Public Key', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }],
    type: POPULAR
  },
  "payu": {
    is_active: false,
    available: false,
    name: "PayU",
    description: "Accept payments via cards, Netbanking, UPI and wallets.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/payu-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT_API') + 'payu-add',
    fields: [{ field: 'email', placeholder: 'Email', value: undefined }, { field: 'mobile', placeholder: 'Mobile', value: undefined }, { field: 'countrycode', placeholder: 'Country Code', value: '+91', disabled: true }],
    type: POPULAR
  },
  "checkoutcom": {
    is_active: false,
    available: false,
    name: "Checkout.com",
    description: "Accept credit cards payment on the go.",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/checkout-com-logo.svg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'checkoutcom/connect',
    fields: [{ field: 'public_key', placeholder: 'Public Key', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }],
    type: POPULAR
  },
  "twocheckout": {
    is_active: false,
    available: false,
    name: "2Checkout",
    description: "",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/2checkout-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + '2checkout/connect',
    fields: [{ field: 'account_number', placeholder: 'Account Number', value: undefined }, { field: 'publishable_key', placeholder: 'Publishable Key', value: undefined }, { field: 'private_key', placeholder: 'Private Key', value: undefined }],
    type: POPULAR
  },
  /* "ocbc": {
    is_active: false,
    available: false,
    name: "OCBC",
    description: "",
    logo: ""
  }, */
  "paystack": {
    is_active: false,
    available: false,
    name: "Paystack",
    description: "Modern online and offline payments for Africa.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/paystack-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'paystack/connect',
    fields: [{ field: 'secret_key', placeholder: 'Secret Key', value: undefined }, { field: 'public_key', placeholder: 'Public Key', value: undefined }],
    type: POPULAR
  },
  /* "spreedly": {
    is_active: false,
    available: false,
    name: "Spreedly",
    description: "",
    logo: ""
  }, */
  "mollie": {
    is_active: false,
    available: false,
    name: "mollie",
    description: "",
    logo: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA0Iagw5oKDA",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'mollie/connect',
    fields: [{ field: 'api_key', placeholder: 'API Key', value: undefined }],
    type: POPULAR
  },
  /* "hoolah": {
    is_active: false,
    available: false,
    name: "hoolah",
    description: "Buy Now. Pay Later.",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/hoolah-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'hoolah/connect',
    fields: [{ field: 'merchant_id', placeholder: 'Merchant ID', value: undefined }, { field: 'merchant_secret', placeholder: 'Merchant Secret', value: undefined }]
  }, */
  "billplz": {
    is_active: false,
    available: false,
    name: "Billplz",
    description: "Share a payment form anywhere for anyone to pay.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/billplz-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'billplz/connect',
    fields: [{ field: 'secret_key', placeholder: 'Secret Key', value: undefined }, { field: 'collection_id', placeholder: 'Collection ID', value: undefined }, { field: 'x_signature_key', placeholder: 'X Signature Key', value: undefined }, { field: 'deliver', value: false, type: 'checkbox' }],
    type: POPULAR
  },
  "razorpay": {
    is_active: false,
    available: false,
    name: "Razorpay",
    description: "Accept all major debit and credit cards, UPI, Bharat QR, Netbanking and Mobile Wallets.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/razorpay-logo.svg",
    connection_type: 'redirect',
    redirect_link: environment('razorpayConnectUrl'),
    type: POPULAR
  },
  "iyzico": {
    is_active: false,
    available: false,
    name: "Iyzico",
    description: "Accept credit cards payment on the go.",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/iyzico-logo.svg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'iyzico/connect',
    fields: [{ field: 'api_key', placeholder: 'API Key', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }],
    type: POPULAR
  },
  "pg2c2p": {
    is_active: false,
    available: false,
    name: "2c2p",
    description: "Simplifies e-commerce and m-commerce payments for merchants everyday.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/2c2p-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'pg2c2p/pg2c2p-create',
    fields: [{ field: 'merchantid', placeholder: 'Merchant ID', value: undefined }, { field: 'secretkey', placeholder: 'Secret Key', value: undefined }, { field: 'currencycode', placeholder: 'Currency Code', value: '764', disabled: true }, { field: 'pancountry', placeholder: 'Country', value: 'TH', disabled: true }],
    type: POPULAR
  },
  /* "eghl": {
    is_active: false,
    available: false,
    name: "eghl",
    description: "",
    logo: ""
  }, */
  /* "cybersource": {
    is_active: false,
    available: false,
    name: "CyberSource",
    description: "",
    logo: ""
  }, */
  "paymaya": {
    is_active: false,
    available: false,
    name: "PayMaya",
    description: "Make fast, secure, and hassle-free payments for personal and business use!",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/paymaya-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'paymaya/connect',
    fields: [{ field: 'public_key', placeholder: 'Public Key', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }, { field: 'merchant_name', placeholder: 'Merchant Name', value: undefined }],
    type: POPULAR
  },
  "ngenius": {
    is_active: false,
    available: false,
    name: "N-Genius",
    description: "",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/ngenius-logo.svg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'ngenius/connect',
    fields: [{ field: 'api_key', placeholder: 'API Key', value: undefined }, { field: 'secret_key', placeholder: 'Outlet ID', value: undefined }],
    type: POPULAR
  },
  "ipay88": {
    is_active: false,
    available: false,
    name: "iPay88",
    description: "Accept payments through your iPay88 merchant account.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/ipay88-logo-horizontal.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'ipay88/service/connect',
    fields: [{ field: 'merchant_code', placeholder: 'Merchant Code', value: undefined }, { field: 'merchant_key', placeholder: 'Merchant Key', value: undefined }],
    type: POPULAR
  },
  "paypal": {
    is_active: false,
    available: false,
    name: "PayPal",
    description: "Accept payments via PayPal.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/paypal-logo-square.png",
    connection_type: 'other',
    type: POPULAR
  },
  /* "toyyibpay": {
    is_active: false,
    available: false,
    name: "ToyyibPay",
    description: "",
    logo: ""
  }, */
  "stripe": {
    is_active: false,
    available: false,
    name: "Stripe",
    description: "Accept payments via cards, Apple Pay, Google Pay, and Alipay.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/stripe.png",
    connection_type: 'redirect',
    redirect_link: environment('stripeConnectUrl'),
    type: POPULAR
  },
  "bangkokbank": {
    is_active: false,
    available: false,
    name: "Bangkok Bank",
    description: "",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/bangkok-bank-logo.jpg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'bbl/create-bbl',
    fields: [{ field: 'merchantid', placeholder: 'Merchant ID', value: undefined }, { field: 'merchant_api_id', placeholder: 'Merchant API Key', value: undefined }, { field: 'merchant_api_password', placeholder: 'Merchant API Password', value: undefined }],
    type: POPULAR
  },
  /* "omise": {
    is_active: false,
    available: false,
    name: "Omise",
    description: "",
    logo: ""
  }, */
  "atome": {
    is_active: false,
    available: false,
    name: "Atome",
    description: "Get it today. Pay later over 3 months.",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/atome-logo.svg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'atome/connect',
    fields: [{ field: 'username', placeholder: 'Username', value: undefined }, { field: 'password', placeholder: 'Password', value: undefined }, { field: 'country_code', placeholder: 'Country Code', value: undefined, disabled: true }],
    type: POPULAR
  },
  "payulatam": {
    is_active: false,
    available: false,
    name: "PayU Latam",
    description: "",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/payu-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'payulatam/payulatam-create',
    fields: [{ field: 'apikey', placeholder: 'API Key', value: undefined }, { field: 'apilogin', placeholder: 'API Login', value: undefined }, { field: 'merchantid', placeholder: 'Merchant ID', value: undefined }, { field: 'accountid', placeholder: 'Account ID', value: undefined }, { field: 'publickey', placeholder: 'Public Key', value: undefined }, { field: 'language', placeholder: 'Language', value: undefined }],
    type: POPULAR
  },
  "gcash": {
    is_active: false,
    available: false,
    name: "GCash",
    description: "Make fast and secure mobile payments with GCash.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/gcash-logo.jpg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'gcash/connect',
    fields: [{ field: 'public_key', placeholder: 'Public Key', value: undefined }, { field: 'product_code', placeholder: 'Product Code', value: undefined }],
    type: POPULAR
  },
  "enets": {
    is_active: false,
    available: false,
    name: "eNETS",
    description: "Accept payments via NETS.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/enets-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'enets/enets-create',
    fields: [{ field: 'netsmid', placeholder: 'E-Nets MID', value: undefined }, { field: 'apikey', placeholder: 'API Key', value: undefined }, { field: 'apisecret', placeholder: 'API Secret', value: undefined }],
    type: POPULAR
  },
  "vnpay": {
    is_active: false,
    available: false,
    name: "VNPAY",
    description: "Leading Fintech in the field of electronic payment in Vietnam.",
    logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/vnpay-logo.svg",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'vnpay/connect',
    fields: [{ field: 'website_code', placeholder: 'Website Code', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }],
    type: POPULAR
  },
  "pesopay": {
    is_active: false,
    available: false,
    name: "Pesopay",
    description: "Accept payments via credit cards, ATM cards and e-Wallet.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/pesopay-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'pesopay/pesopay-create',
    fields: [{ field: 'merchantid', placeholder: 'Merchant ID', value: undefined, disabled: true }, { field: 'securehashsecret', placeholder: 'Secure Hash Secret', value: undefined, disabled: true }, { field: 'extrawebhook', placeholder: 'Extra Web-Hook', value: undefined, disabled: true }, { field: 'language', placeholder: 'Language', value: undefined }],
    type: POPULAR
  },
  "midtrans": {
    is_active: false,
    available: false,
    name: "Midtrans",
    description: "Accept payments via cards, bank transfer, direct debit, cardless credit and e-Wallet.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/midtrans-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'midtrans/midtrans-create',
    fields: [{ field: 'serverkey', placeholder: 'Server Key', value: undefined, disabled: true }, { field: 'clientkey', placeholder: 'Client Key', value: undefined, disabled: true }, { field: 'extrawebhook', placeholder: 'Extra Web-Hook', value: undefined, disabled: true }, { field: 'akulaku', value: false, type: 'checkbox' }, { field: 'gopay', value: false, type: 'checkbox' }],
    type: POPULAR
  },
  "paytm": {
    is_active: false,
    available: false,
    name: "Paytm",
    description: "Accept payments in your mobile app or website with Paytm payment gateway.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/paytm-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'paytm/connect',
    fields: [{ field: 'mid', placeholder: 'Merchant ID', value: undefined }, { field: 'secret_key', placeholder: 'Secret Key', value: undefined }, { field: 'industry_type_id', placeholder: 'Industry Type ID', value: undefined }, { field: 'website', placeholder: 'Website', value: undefined }],
    type: POPULAR
  },
  /* "squarepay": {
    is_active: false,
    available: false,
    name: "Square",
    description: "",
    logo: ""
  }, */
  /* "wirecard": {
    is_active: false,
    available: false,
    name: "Wirecard",
    description: "Accept payments via Credit and Debit Cards. More methods coming soon.",
    logo: environment('VUE_APP_ROOT') + "/landing/resources/images/wirecard-logo.png",
    connection_type: 'form',
    form_link: environment('VUE_APP_ROOT') + 'wirecard/wirecard-create',
    fields: [{ field: 'merchantid', placeholder: 'Merchant ID', value: undefined, disabled: true }, { field: 'secretkey', placeholder: 'Secret Key', value: undefined, disabled: true }, { field: 'username', placeholder: 'Username', value: undefined }, { field: 'password', placeholder: 'Password', value: undefined }]
  } */
  "cash_on_delivery": {
    is_active: false,
    available: true,
    name: "Cash on Delivery (CoD)",
    description: "Click settings below to set up locations where you support Cash on DeliveryAccept payments in your mobile app or website with Paytm payment gateway.",
    logo: `${environment('VUE_APP_ROOT')}/web-s/resources/images/cod-addon.svg`,
    type: MANUAL,
    code: 'cash_on_delivery'
  },
  "bank": {
    is_active: false,
    available: true ,
    name: "Bank transfer",
    description: "Share bank details for manual transfer",
    logo: `${environment('VUE_APP_ROOT')}/web-s/resources/images/bank-transfer-icon.svg`,
    connection_type: 'redirect',
    redirect_link: environment('razorpayConnectUrl'),
    fields: [{ field: 'title', placeholder: 'Button Name', value: undefined }, { field: 'instruction', placeholder: 'Instruction', value: undefined }],
    type: MANUAL,
    code: 'bank'
  },
  // "other": {
  //   type: OTHER
  // },

});

const getters = {
  getPaymentFullData(state) {
    return state;
  },
  getAvailablePopular(state) {
    let available = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(state[prop]['available'] && !state[prop]['is_active'] && state[prop]['type'] === POPULAR) available.push(state[prop])
    }
    return available;
  },
  availableManualMethods(state) {
    let available = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(!state[prop]['is_active'] && state[prop]['type'] === MANUAL) available.push(state[prop])
    }
    return available;
  },
  getConnectedPopular(state) {
    let connected = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(state[prop]['is_active'] && state[prop]?.type === POPULAR ) connected.push(state[prop])
    }
    return connected;
  },
  getConnectedManual(state) {
    let connected = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(state[prop]['is_active'] && state[prop]?.type === MANUAL ) connected.push(state[prop])
    }
    return connected;
  }
}

const actions = {
  updateAvailablePaymentMethods({ commit, dispatch }, payload) {
    if(payload.country) {
      let thisCountry = allCountryCurrencyData.allCountryCurrencyData.find(e => e.country_short_code === payload.country);
      thisCountry ? commit('updateAvailablePaymentMethods', thisCountry) : '';
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load country data in payments module",
        type: "warning"
      }, { root: true });
    }
  },
  savePaymentData({ commit, dispatch }, payload) {
    if (payload.success) {
      commit('savePaymentData', payload)
    } else {
      dispatch("updateToast", { open: true, message: "Failed to load payment data " + payload.errorMessage, type: "danger" }, { root: true });
    }
  },
  async updatePaymentMethods({commit}, { paymentGatewayName, dataToUpdate}) {
    commit('updatePaymentMethodsState', { paymentGatewayName, dataToUpdate});
    let paymentOptionsData = await Storage.get({
      key: "jmpr_fetchPaymentOptionsData",
    });
    paymentOptionsData = JSON.parse(paymentOptionsData.value);
    paymentOptionsData[paymentGatewayName] = {...paymentOptionsData[paymentGatewayName], ...dataToUpdate};
    await Storage.set({
      key: "jmpr_fetchPaymentOptionsData",
      value: JSON.stringify(paymentOptionsData),
    });
  },
  async updateManualPaymentMethods({commit}, { paymentGatewayName, dataToUpdate}) {
    commit('updatePaymentMethodsState', { paymentGatewayName, dataToUpdate});
    let paymentOptionsData = await Storage.get({
      key: "jmpr_fetchManualPaymentsData",
    });
    paymentOptionsData = JSON.parse(paymentOptionsData.value);
    paymentOptionsData[paymentGatewayName] = {...paymentOptionsData[paymentGatewayName], ...dataToUpdate};
    await Storage.set({
      key: "jmpr_fetchManualPaymentsData",
      value: JSON.stringify(paymentOptionsData),
    });
  }
}

const mutations = {
  savePaymentData(state, data) {
    for (var prop in state) {
      if (data[prop]) {
        state[prop]['is_active'] = !!data[prop]?.is_active || !!data[prop]?.status || !!data[prop]?.active;
      }
    }
  },
  updateAvailablePaymentMethods(state, country){
    (state["atome"].fields.find(e => e.field === 'country_code')).value = country.country_short_code.toUpperCase();
    for(var prop in state) {
      state[prop]['available'] = !!country[prop + '_enabled'];
    }
  },
  updatePaymentMethodsState(state, { paymentGatewayName, dataToUpdate }) {
    console.log(">>>>>");
    console.log(paymentGatewayName, dataToUpdate);
    state[paymentGatewayName] = { ...state[paymentGatewayName], ...dataToUpdate };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function redirectToTaxAndShipping () {
  window.location.href = "/settings/tax-and-shipping";
}