<template>
  <master-layout pageTitle="Messenger Chat Plugin">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
      <ion-toolbar class="ion-hide-md-up toolbar">
        <ion-segment scrollable color="dark" v-model="activeTab">
          <ion-segment-button value="chatPlugin">
            <ion-label class="common-label">chat-plugin</ion-label>
          </ion-segment-button>
          <ion-segment-button value="preview">
            <ion-label class="common-label">Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
      <ion-grid class="container">
        <ion-row>
            <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding"> 
              <div v-if="activeTab === 'chatPlugin'" class=" model-sections">   
               <h2 class="header">Customize Facebook Chat Plugin</h2>
                <ion-grid class="gridMargin">
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-text color="common-label">Domains</ion-text>
                    </ion-col>
                    <ion-col>
                      <span v-for="(thisDomain, dIndex) in domains" :key="dIndex">
                        <span v-if="thisDomain.active" >
                          <ion-chip class="chip">
                            <ion-label class="chip-label">{{ thisDomain.url }}</ion-label>
                            <ion-icon :icon="closeOutline" @click="() => domains.splice(dIndex, 1)"></ion-icon>
                          </ion-chip>
                        </span>
                        <div v-else>
                          <ion-input v-model="thisDomain.url" type="url" placeholder="Enter Domain" class="fullBordered input_custom_style domainInput"></ion-input>
                          <ion-button @click="addDomain(thisDomain.url)" color="dark" fill="solid" class="ion-no-margin">
                            Add
                          </ion-button>
                        </div>
                      </span>
                      <ion-toolbar>
                        <ion-buttons slot="end">
                          <ion-button @click="() => domains.push({ url: undefined, active: false })" color="dark" size="default" >
                            <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                          </ion-button>
                        </ion-buttons>
                      </ion-toolbar>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Theme Color</ion-label>
                    </ion-col>
                    <ion-col>
                      <div class="colorPickerWrapper" :style="{'background-color':welcomeMessage.theme_color}">
                        <ion-input v-model="welcomeMessage.theme_color" type="color" class="themePicker"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Greeting message</ion-label>
                    </ion-col>
                    <ion-col>
                      <ion-textarea v-model="welcomeMessage.welcome_screen_greeting" rows="4" placeholder="Enter greeting message" class="fullBordered input_custom_style"></ion-textarea>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Guest mode</ion-label>
                    </ion-col>
                    <ion-col class="colMargin">
                      <ion-select  v-model="welcomeMessage.guest_chat_mode" placeholder="Guest Mode" class="slctContainer">
                        <ion-select-option value="enabled">Enabled</ion-select-option>
                        <ion-select-option value="disabled">Disabled</ion-select-option>
                      </ion-select>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Alignment of the chat plugin</ion-label>
                    </ion-col>
                    <ion-col class="colMargin">
                      <ion-select v-model="welcomeMessage.alignment" placeholder="Alignment" class="slctContainer">
                        <ion-select-option value="left">Left</ion-select-option>
                        <ion-select-option value="right">Right</ion-select-option>
                      </ion-select>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6" class="colMargin">
                      <ion-label>Determine how the greeting dialog will be displayed</ion-label>
                    </ion-col>
                    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6" class="colMargin">
                      <ion-select :interface-options="slctOptions" v-model="welcomeMessage.greeting_dialog_display" placeholder="Greeting Dialog" class="slctContainer">
                        <ion-select-option value="show" class="slctOption">The greeting dialog is hidden until a user clicks on the plugin</ion-select-option>
                        <ion-select-option value="hide">The greeting dialog is shown and remains open</ion-select-option>
                        <ion-select-option value="welcome_message">The greeting text is shown above the plugin icon, but the greeting dialog is hidden until a user clicks on the plugin</ion-select-option>
                      </ion-select>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Desktop bottom spacing of the chat plugin <br>(Unit will be in pixel)</ion-label>
                    </ion-col>
                    <ion-col class="colMargin">
                      <ion-input v-model="welcomeMessage.desktop_bottom_spacing" type="number" class="fullBordered input_custom_style"></ion-input>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="colMargin">
                      <ion-label>Mobile bottom spacing of the chat plugin <br>(Unit will be in pixel)</ion-label>
                    </ion-col>
                    <ion-col class="colMargin">
                      <ion-input v-model="welcomeMessage.mobile_bottom_spacing" type="number" class="fullBordered input_custom_style"></ion-input>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ion-toolbar>
                  <ion-buttons slot="end">
                    <ion-button @click="saveChanges" color="dark" fill="solid" style="margin-right: 5px">
                      Save
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <div class="embedCodeAreaContainer">
                  <p>Copy and paste the below code inside the &lt;/body&gt; tag</p>
                  <figure class="highlight">
                    <pre>{{ widgetScript }}</pre>
                    <a @click="copy(widgetScript)">Copy to Clipboard</a>
                  </figure>
                 </div>
              </div>
            </ion-col>
          <!-- preview -->
          <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
            <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
              <div class="ion-text-right fbPageName">
                <a :href="pageUrl" target="_blank">
                  <ion-chip class="fbPageName" >
                    <ion-avatar>
                      <img alt="Silhouette of a person's head" :src="pageIconUrl" />
                    </ion-avatar>
                    <ion-label>{{pageName }}</ion-label>
                  </ion-chip>
                </a>
              </div>
              <div class="preview">
                <ion-card style="border-radius: 10px" class="previewContainer">
                  <ion-card-content>
                    <div style="margin-bottom: 15px">
                      <ion-avatar slot="start" style="height: auto; width: 50px;">
                        <img :src="pageIconUrl"/>
                      </ion-avatar>
                    </div>
                    <div class="brandNameArea">
                      <ion-label class="title" style="margin-left: 0px">Chat with {{ pageName}} </ion-label>
                      <h2 class="sub-title">Typically replies within a few minutes</h2>
                    </div>
                    <div class="greetingMessageArea">
                      <p class="txtMsg">{{ welcomeMessage.welcome_screen_greeting}}</p>
                    </div>
                    <div class="buttonsArea">
                        <div class="bttn-box" :style="{'background':welcomeMessage.theme_color}">Log into Messenger</div>
                        <div class="bttn-box" style="background: #F5F5F5; color: #262626;">Continue as Guest</div>
                    </div>
                    <div class="policyArea">
                      <p class="policyText"> By continuing as a guest, you agree to Facebook's <a class="textHightlight">Terms, Data Policy </a> and <a class="textHightlight">Cookies Policy.</a> If you choose to log in, <span v-if="!IS_ASIA">Jumper.ai</span> Demos will see the website you came from and info you've made public on your Facebook profile.</p>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
              <div class="previewContainerWidget">
                <div class="minusWidget">
                  <ion-icon :src="removeOutline" size="large" class="iconImg"></ion-icon>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, removeOutline, closeOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSelect, IonSelectOption, IonTextarea } from "@ionic/vue";

export default {
  components: {IonSelect, IonSelectOption, IonTextarea},
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      removeOutline,
      closeOutline,
      welcomeMessage: {
        alignment: undefined,
        desktop_bottom_spacing: undefined,
        entry_point_icon: "messenger_icon",
        entry_point_label: "none",
        greeting_dialog_display: undefined,
        guest_chat_mode: undefined,
        mobile_bottom_spacing: undefined,
        theme_color: '#0084ff',
        welcome_screen_greeting: undefined
      },
      pageIconUrl: null,
      domains: [],
      pageName: undefined,
      pageUrl: undefined,
      activeTab: 'chatPlugin',
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
   mounted() {
    this.initData();
  },
  setup() {
    const slctOptions = {
      cssClass: 'messenger-plugin',
    };
    return { slctOptions }
  },
  computed: {
     widgetScript() {
       return '<!-- Messenger Chat Plugin Code --> <div id="fb-root"></div> <!-- Your Chat Plugin code --> <div id="fb-customer-chat" class="fb-customerchat" page_id=' + this.facebookSocialData?.pages[0].messenger + '></div <script> var chatbox = document.getElementById("fb-customer-chat"); chatbox.setAttribute("page_id",'+ this.facebookSocialData?.pages[0].messenger + '); chatbox.setAttribute("attribution", "biz_inbox"); window.fbAsyncInit = function() { FB.init({ xfbml : true,version : "v12.0"});}; (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js" fjs.parentNode.insertBefore(js, fjs);}(document, "script", "facebook-jssdk");</scrip' + 't>'
    },
  },
  methods: {
    initData() {
      this.pageName = this.facebookSocialData?.pages[0]?.name;
      this.pageIconUrl = `https://graph.facebook.com/${this.facebookSocialData?.pages[0]?.messenger}/picture?type=normal`;
      this.pageUrl = this.facebookSocialData?.pages[0]?.link;
      if (this.pageUrl && !this.pageUrl.includes("http")) {
        this.pageUrl = `https://${this.pageUrl}`;
      }
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      this.fetchWelcomeMessage();
      this.fetchGrowthTools();
    },
    async fetchWelcomeMessage() {
      try {
        this.loading = true;
        const welcomeMessageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "facebook-get-welcome-message", { pageid: this.facebookSocialData.pages[0].messenger });
        if ((welcomeMessageResponse.status == 200 || welcomeMessageResponse.status == 201) && welcomeMessageResponse.data.success) {
          for(var prop in this.welcomeMessage) this.welcomeMessage[prop] = welcomeMessageResponse.data.data[prop];
          if(this.welcomeMessage.theme_color && !this.welcomeMessage.theme_color.includes('#')) this.welcomeMessage.theme_color = '#' + this.welcomeMessage.theme_color;
        } else {
          if(welcomeMessageResponse.data.errorMessage && welcomeMessageResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: welcomeMessageResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchGrowthTools() {
      try {
        this.loading = true;
        const fetchGrowthToolsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-growthtools");
        if ((fetchGrowthToolsResponse.status == 200 || fetchGrowthToolsResponse.status == 201) && fetchGrowthToolsResponse.data.success) {
          this.domains = fetchGrowthToolsResponse.data.messenger_sdk;
        } else {
          if(fetchGrowthToolsResponse.data.errorMessage && fetchGrowthToolsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchGrowthToolsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async saveChanges() {
      try {
        this.loading = true;
        let reqBody = {
          pageid: this.facebookSocialData.pages[0].messenger,
          welcome_screen_greeting: this.welcomeMessage.welcome_screen_greeting,
          guest_chat_mode: this.welcomeMessage.guest_chat_mode,
          theme_color: this.welcomeMessage.theme_color && this.welcomeMessage.theme_color.includes('#') ? this.welcomeMessage.theme_color.replace('#', '') : undefined,
          alignment: this.welcomeMessage.alignment,
          desktop_bottom_spacing: this.welcomeMessage.desktop_bottom_spacing,
          mobile_bottom_spacing: this.welcomeMessage.mobile_bottom_spacing,
          greeting_dialog_display: this.welcomeMessage.greeting_dialog_display
        }
        let setWelcomeMessageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'facebook-set-welcome-message', reqBody);
        if ((setWelcomeMessageResponse.status == 200 || setWelcomeMessageResponse.status == 201) && setWelcomeMessageResponse.data.success) {
          this.fetchWelcomeMessage();
        } else {
          if(setWelcomeMessageResponse.data.errorMessage && setWelcomeMessageResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: setWelcomeMessageResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async addDomain(domain) {
      try {
        this.loading = true;
        if(!domain || domain.length === 0) {
          this.updateToast({
            open: true,
            message: "Please enter a valid URL",
            type: "danger"
          })
          return;
        }
        let reqBody = {
          url: domain
        }
        let addDomainResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'create-messengersdk', reqBody);
        if ((addDomainResponse.status == 200 || addDomainResponse.status == 201) && addDomainResponse.data.success) {
          this.fetchGrowthTools();
        } else {
          if(addDomainResponse.data.errorMessage && addDomainResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: addDomainResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>

<style>
.messenger-plugin {
   --max-width: 700px;
   --width: 700px; 
}
</style>

<style scoped>
.previewContainer {
  box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
  padding: 10px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background-color: var(--ion-color-white);
}

.previewContainerWidget {
  margin: 20px 10px 0px 0px;
  float: right;
}
.minusWidget {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  background: var(--ion-color-white);
  box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
}
.iconImg {
  margin-top: 15px;
  color: var(--ion-color-black);
}

.greetingMessageArea {
  margin: 10px 0px 20px 0px;
}
.bttn-box {
    border-radius: 16px;
    display: block;
    margin-bottom: 15px;
    border: none;
    outline: none;
    -webkit-border-radius: 16px;
    font-size: 17px;
    font-weight: 400;
    color: var(--ion-color-white);
    text-align: center;
    padding: 12px;
}
.policyText {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  padding: 0px;
  margin: 0px;
  color: #aaa;
  text-align: center;
  margin-top: 10px; 
}
.policyText a {
  color: var(--ion-color-black);
}
ion-button {
  margin-left: 5px;
  margin-bottom: 10px;
}
.txtMsg{
  font-size: 16px;
  color: var(--ion-color-black);
}
.embedCodeAreaContainer {
  margin: 15px;
  border-top: 1px solid #eee;
}
figure {
  margin: 0;
}

figure.highlight {
  margin-top: 5px;
}

figure.highlight pre {
  white-space: normal;
  text-align: left;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
  margin-left: 10px;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.header {
  font-size: 20px;
  color: var(--ion-color-black);
  font-weight: 500;
  line-height: 1.4;
  margin: 10px 0px 20px 10px;
  padding: 10px 0px 0px 5px;
}
.jinput {
  width: 98%;
  background: transparent;
}
.colMargin {
  margin-bottom: 10px;
}

.gridMargin {
  margin: 5px;
}
.domainInput {
  display: inline-block;
  width: 60%;
  margin-top: 15px;
}
.textHightlight {
  cursor: default;
}
.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
  width: 100% !important;
}
.preview {
  margin: 10px 30px 30px 30px;
}
ion-chip.chip {
  --background: #B2C4FF;
}
.chip-label {
  color: #262626;
}
ion-chip.fbPageName {
  --background: var(--ion-color-white);
  border: 1px #ddd solid;
}

ion-toolbar {
  --background: var(--ion-color-white);
}

.colorPickerWrapper {
  min-height: 45px;
  min-width: 45px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  border: 1px #E3E3E3 solid;
  cursor: pointer;
}

.prevMobile {
  display: none;
}

.prevDesktop {
  display: block;
}

.container{
  margin: 15px 0px 0px 10px;
}

@media only screen and (max-width: 760px) {
  .container{
    margin: 5px;
  }
  .prevMobile {
  display: block;
 }
 .prevDesktop {
  display: none;
}
}

</style>