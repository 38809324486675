<template>
<master-layout pageTitle="Manage Team Members">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    ></ion-loading>
    <div v-else>
      <ion-toolbar class="chat-toolbar">
        <ion-buttons slot="start">
          <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{ (dummyMember.id ? 'Edit' : 'Add') + ' Member' }}</h2>
        </ion-buttons>
        <ion-buttons slot="end" class="paddingBox">
          <ion-button :disabled="fetchingLocations" @click="updateMember" class="bttn-bgnd">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <ion-card class="custom_btm_margin card_container">
              <ion-card-header>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Enter Email</ion-card-title>
                    <ion-input :disabled="dummyMember.id !== undefined" type="text" placeholder="Enter Email" v-model="dummyMember.email" class="fullBordered input_custom_style"></ion-input>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Select Role</ion-card-title>
                    <ion-select class="cust_adjsmt cust_style_select" v-model="dummyMember.role" ok-text="Okay" cancel-text="Dismiss" placeholder="Select Role">
                      <ion-select-option value="undefined" disabled>Select Role</ion-select-option>
                      <ion-select-option v-for="thisRole in allRoles" :key="thisRole.id" :value="thisRole.name">{{ thisRole.name }}</ion-select-option>
                    </ion-select>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Enter First Name</ion-card-title>
                    <ion-input type="text" placeholder="Enter First Name" v-model="dummyMember.firstName" class="fullBordered input_custom_style"></ion-input>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Enter Last Name</ion-card-title>
                    <ion-input type="text" placeholder="Enter Last Name" v-model="dummyMember.lastName" class="fullBordered input_custom_style"></ion-input>
                </div>
              </ion-card-header>
            </ion-card>
          </ion-col>
          <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <ion-card v-if="allLocations && allLocations.length">
              <ion-card-header class="card_container">
                <div class="prdct-section">
                  <h2 class="team-title ion-no-margin">Locations</h2>
                  <ion-col class="ion-no-padding " size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                    <div class="prdct-section">
                      <ion-item v-for="thisLocation in allLocations" :key="thisLocation.outletid" class="cust_mrg_lft_cont pos_adjsmt_1 no_bdr">
                        <ion-checkbox v-model="thisLocation.selected"></ion-checkbox>
                        <ion-label class="cust_min_width">{{ thisLocation.name + ", " + thisLocation.addressline1 + ", " + thisLocation.city + ", " + thisLocation.state + ", " + thisLocation.country + ", " + thisLocation.zipcode }}</ion-label>
                      </ion-item>
                    </div>
                  </ion-col>
                </div>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>
</master-layout>
</template>

<script>
import { chevronDownCircleOutline, arrowBackOutline } from 'ionicons/icons';
import { IonCheckbox, IonSelect, IonSelectOption } from "@ionic/vue";
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  components: {
    IonCheckbox,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      chevronDownCircleOutline, arrowBackOutline,
      loading: false,
      allRoles: [],
      allLocations: [],
      fetchingLocations: false,
      dummyMember: {
        admin: false,
        agent_nickname: null,
        businessroleid: undefined,
        dynamic: [],
        email: undefined,
        firstName: undefined,
        id: undefined,
        inf_data: null,
        is_admin: false,
        is_client: false,
        is_influencer: false,
        lastName: undefined,
        mobile: undefined,
        name: undefined,
        permissions: {},
        picture: undefined,
        role: undefined,
        routing_address: false
      }
    }
  },
  ionViewWillEnter() {
    this.init();
  },
  computed: {
    isEdit() {
      return this.dummyMember.id !== undefined;
    },
    selectedLocations() {
      return this.allLocations.filter(e => e.selected);
    }
  },
  watch: {
    'dummyMember.role': {
     async handler() {
      let thisRole = this.allRoles.find(e => e.name === this.dummyMember.role);
      if(thisRole && thisRole.id) this.dummyMember.businessroleid = thisRole.id;
      if(this.dummyMember.businessroleid) {
        this.fetchingLocations = true;
        let businessRoleShowResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "business-role-show", { businessroleid: this.dummyMember.businessroleid });
        if ((businessRoleShowResponse.status == 200 || businessRoleShowResponse.status == 201) && businessRoleShowResponse.data.success) {
          this.allLocations = businessRoleShowResponse.data.dynamics;
          this.allLocations = this.allLocations.map(e => {
            e.selected = this.dummyMember.dynamic && this.dummyMember.dynamic.length && this.dummyMember.dynamic.findIndex(d => d.outletid === e.outletid) > -1;
            return e;
          })
        } else {
          if(businessRoleShowResponse.data && !businessRoleShowResponse.data.success && businessRoleShowResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: businessRoleShowResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", businessRoleShowResponse);
          }
        }
        this.fetchingLocations = false;
      }
     },
     deep: true
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        let businessRolesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");
        if ((businessRolesResponse.status == 200 || businessRolesResponse.status == 201) && businessRolesResponse.data.success) {
          this.allRoles = businessRolesResponse.data.business_roles;
        } else {
          if(businessRolesResponse.data && !businessRolesResponse.data.success && businessRolesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: businessRolesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", businessRolesResponse);
          }
        }
        if(this.$route.query?.memberid) {
          let roleListResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-list");
          if ((roleListResponse.status == 200 || roleListResponse.status == 201) && roleListResponse.data.success) {
            let allMembers = roleListResponse.data.roles;
            this.dummyMember = allMembers.find(e => e.id == this.$route.query.memberid);
          } else {
            if(roleListResponse.data && !roleListResponse.data.success && roleListResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: roleListResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
              //console.log("Response:", roleListResponse);
            }
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async updateMember() {
      try {
        this.loading = true;

        if(this.dummyMember.firstName && this.dummyMember.firstName.length && this.dummyMember.lastName && this.dummyMember.lastName.length) this.dummyMember.name = this.dummyMember.firstName + " " + this.dummyMember.lastName;
        else {
          if(this.dummyMember.firstName && this.dummyMember.firstName.length) this.dummyMember.name = this.dummyMember.firstName;
          else {
            this.updateToast({
              open: true,
              message: "Please enter first name",
              type: "warning"
            });
            this.loading = false;
            return;
          }
        }
        if(this.dummyMember.role) {
          let thisRole = this.allRoles.find(e => e.name === this.dummyMember.role);
          if(thisRole && thisRole.id) this.dummyMember.businessroleid = thisRole.id;
          else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "warning"
            });
            this.loading = false;
            return;
          }
        } else {
          this.updateToast({
            open: true,
            message: "Please assign a role",
            type: "warning"
          });
          this.loading = false;
          return;
        }
        if(this.selectedLocations && this.selectedLocations.length) {
          this.dummyMember.dynamics = (this.selectedLocations.map(e => e.outletid)).join(",");
        }
        let reqBody = {
          name: this.dummyMember.name,
          firstName: this.dummyMember.firstName,
          lastName: this.dummyMember.lastName,
          email: this.dummyMember.email.toLowerCase(),
          businessroleid: this.dummyMember.businessroleid,
          dynamics: this.dummyMember.dynamics,
          edit: this.isEdit
        };

        // for ASIA env send password to member in email so he could login
        // for Jumper we allow user to reset password, but in case of ASIA there is not option for member to reset the password.
        if (this.IS_ASIA) {
          reqBody.with_password = true;
        }

        let memberSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-add", reqBody);
        if ((memberSaveResponse.status == 200 || memberSaveResponse.status == 201) && memberSaveResponse.data.success && memberSaveResponse.data.add) {
          this.loading = false;
          if(this.isEdit) {
            this.updateToast({
              open: true,
              message: "Member updated successfully!",
              type: "success"
            });
          } else {
            this.updateToast({
              open: true,
              message: "Member added successfully!",
              type: "success"
            });
          }
          this.$router.push({ path: '/settings/team' });
        }
        else {
          if(memberSaveResponse.data.errorMessage && memberSaveResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: memberSaveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
          this.loading = false;
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.team-title{
  padding: 0px 0px 10px 16px;
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}
</style>