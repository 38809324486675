import { mapGetters, mapActions } from "vuex";
import { environment } from "/src/shared/env";

export default {
  computed: {
    ...mapGetters({
      pusher: "getPUSHER"
    }),
  }, 
  created() {
    if(!this.IS_ASIA) {
      if(!window.Pusher) {
        let pusherScript = document.createElement('script');
        pusherScript.addEventListener('load', () => {
          console.log("pusher script loaded");
          if(!this.pusher) this.setUpPusher();
        })
        pusherScript.setAttribute('src', 'https://jumper.ai/web-s/resources/js/pusher.min.js');
        document.head.appendChild(pusherScript);
      } else {
        if(!this.pusher) this.setUpPusher();
        else console.log("Pusher already set up");
      }
    }
    if(!window.platform) {
      let platformScript = document.createElement('script');
      platformScript.addEventListener('load', () => {
        console.log("platform script loaded");
      })
      platformScript.setAttribute('src', 'https://jumper.ai/web-s/resources/js/platform.js');
      document.head.appendChild(platformScript);
    }
  },
  methods: {
    ...mapActions({
      updatePUSHER: "updatePUSHER"
    }),
    setUpPusher(){
      console.log("here");
      if(this.user && this.user.referallshopName){
        let tempPUSHER = new window.Pusher(environment('VUE_APP_PUSHER_KEY'), {
          cluster: environment('VUE_APP_PUSHER_CLUSTER'),
          forceTLS: true
        });
        this.updatePUSHER(tempPUSHER);

        if(this.$route.path.includes("/page-inbox") && (this.instagramConnected == true || this.messengerConnected == true)){
          if(typeof this.pusher_channel_liveChat_IG_reaction == 'undefined'){
            this.pusher.unsubscribe("reaction");
            this.pusher_channel_liveChat_IG_reaction = this.pusher.subscribe("reaction");
          }
        }

        if(this.$route.path.includes("/page-inbox") && (this.instagramConnected == true || this.messengerConnected == true)){
          if(typeof this.pusher_channel_liveChat_IG_ig_unsend_msg == 'undefined'){
            this.pusher.unsubscribe("ig_unsend_msg");
            this.pusher_channel_liveChat_IG_ig_unsend_msg = this.pusher.subscribe("ig_unsend_msg");
          }
        }

        //live chat start
        if(this.redRibbon_ph_manila != true && this.user.enterprise_Name != 'lbc'){
          var pusher_channel_pageInbox = this.pusher.subscribe(this.user.referallshopName);
          
          pusher_channel_pageInbox.bind('my-event',(data) => {
            console.log("===pusher hit: my-event===");
            
            /* var realNotificationBool = false;
            if(data.stuckerror == true){
              realNotificationBool = true;
            } */

            if(this.$route.path.includes("/page-inbox")){
              var event = new CustomEvent("new-message", {detail: data});
              document.dispatchEvent(event);
              //this.parseNewConversationDataReceivedFromPusher(data,false);
            }
          });

          pusher_channel_pageInbox.bind('remove-conversation',(data) => {
            console.log("===pusher hit: remove-conversation===start");
            console.log(data);
            console.log("===pusher hit: remove-conversation===end");

            if(this.$route.path.includes("/page-inbox")){
              var event = new CustomEvent("remove-conversation", {detail: data});
              document.dispatchEvent(event);
              //this.hideResolvedConversationFromLiveChat(data);
            }
          });

          pusher_channel_pageInbox.bind('my-event-'+ this.basicPermissionData.group_id,(data) => {
            console.log("===pusher hit: my-event-"+ this.basicPermissionData.group_id +"===");

            if(this.$route.path.includes("/page-inbox")){
              var event = new CustomEvent("new-message", {detail: data});
              document.dispatchEvent(event);
              //this.parseNewConversationDataReceivedFromPusher(data,false);
            }
          });

          var pusher_channel_notifications = this.authData ? this.pusher.subscribe(this.authData.authkey) : undefined;
          if(pusher_channel_notifications) {
            //page inbox - new conversation assigned to agent start
            pusher_channel_notifications.bind('get-conversation',(data) => {
              console.log("========== pusher:event=get-conversation ===========start");
              //console.log(data);
              console.log("========== pusher:event=get-conversation ===========end");
  
              if(this.$route.path.includes("/page-inbox")){
                var event = new CustomEvent("new-message-via-agent-routing", {detail: data});
                document.dispatchEvent(event);
                //this.parseNewConversationDataReceivedFromPusher(data,true);
              }
            });
            //page inbox - new conversation assigned to agent end

            //page inbox - re-assigned conversation from 1 agent/admin to another start
            //if this comes, then we need to hide the conversation from previously assigned agent/admin's dashboard
            pusher_channel_notifications.bind('reassign-conversation',(data) => {
              console.log("========== pusher:event=reassign-conversation ===========start");
              console.log(data);
              console.log("========== pusher:event=reassign-conversation ===========end");
  
              if(this.$route.path.includes("/page-inbox")){
                var event = new CustomEvent("reassign-conversation", {detail: data});
                document.dispatchEvent(event);
                //this.hideReAssignedConversationFromLiveChat(data);
              }
            });
            //page inbox - re-assigned conversation from 1 agent/admin to another end
          }
        }
        //live chat end

        //notifications start
        //pending: @kru (later)
        //notifications end
      }
    },
    scrollToBottom() {
      let chatContainer = document.getElementById("chat_container");
      chatContainer ? (chatContainer.scrollTop = chatContainer.scrollHeight) : setTimeout(() => {
        let chatContainer = document.getElementById("chat_container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 1000);
      return;
    },
    async hideResolvedConversationFromLiveChat(data){
      setTimeout(function(){
        for(var i=0; i < this.customersResults.length; i++){
          if(data.conversationid == this.customersResults[i].conversationid){
            this.customersResults.splice(i,1);

            this.removeItemFromAllTabArrays(data.conversationid);

            break;
          }
        }

        if(this.$route.query.conversation == data.conversationid){
          this.activeCustomer = {};
        }
      }, 10);
    },
    async hideReAssignedConversationFromLiveChat(data){
      setTimeout(function(){
        if(this.$route.query.conversation == data.conversationid){
          this.activeCustomer = {};
        }
        
        for(var i=0; i < this.customersResults.length; i++){
          if(data.conversationid == this.customersResults[i].conversationid){
            this.customersResults.splice(i,1);

            this.removeItemFromAllTabArrays(data.conversationid);

            break;
          }
        }
      }, 10);
    },
    async parseNewConversationDataReceivedFromPusher(data,comingViaAgentRouting){
      console.log(data);

      var force_playSoundNotification_bool = false;

      if(comingViaAgentRouting != true){
        comingViaAgentRouting = false;
      }

      var canProceedAndCallApiBool = true;

      if(typeof data.conversation_active != 'undefined'){
        if(data.conversation_active == null){
          data.conversation_active = true;
        }
      }
      
      //Note: using "this.$route.query.conversation" instead of "this.activeCustomer.id"
      if(data.conversationid == this.$route.query.conversation){
        this.activeUserConversationDataResult.unshift.apply(this.activeUserConversationDataResult, [data]);

        this.scrollToBottom();
      
        this.latestVertexIsForPayment = false;
        if (this.activeUserConversationDataResult.length > 0) {
          if (typeof this.activeUserConversationDataResult[this.activeUserConversationDataResult.length - 1] != 'undefined') {
            if (typeof this.activeUserConversationDataResult[this.activeUserConversationDataResult.length - 1].vertex != 'undefined') {
              if (this.activeUserConversationDataResult[this.activeUserConversationDataResult.length - 1].vertex != null) {
                if (this.activeUserConversationDataResult[this.activeUserConversationDataResult.length - 1].vertex == '7' || this.activeUserConversationDataResult[this.activeUserConversationDataResult.length - 1].vertex == '17') {
                  this.latestVertexIsForPayment = true;
                }
              }
            }
          }
        }
      
        if (typeof data.platform != 'undefined' && data.platform != '' && data.platform != null) {
          var data_platform = this.getChannelFromPageActive(data.platform);
      
          if (data_platform == 'instagram' || data_platform == 'facebook') {
            if (typeof data.messageid != 'undefined') {
              if (typeof this.pusher_channel_liveChat_IG_reaction != 'undefined') {
                this.pusher_channel_liveChat_IG_reaction.bind(data.messageid, function(data) {
                  console.log("---reaction pusher hit---");
                  console.log(data);
      
                  for (var j = 0; j < this.activeUserConversationDataResult.length; j++) {
                    if (data.messageid == this.activeUserConversationDataResult[j].messageid) {
                      if (data.reaction) {
                        this.activeUserConversationDataResult[j].reaction = data.reaction;
                      } else {
                        this.activeUserConversationDataResult[j].reaction = "";
                      }
      
                      break;
                    }
                  }
                });
              }
      
              if (typeof this.pusher_channel_liveChat_IG_ig_unsend_msg != 'undefined') {
                this.pusher_channel_liveChat_IG_ig_unsend_msg.bind(data.messageid, function(data) {
                  console.log("---unsend message pusher hit---");
                  console.log(data);
      
                  for (var j = 0; j < this.activeUserConversationDataResult.length; j++) {
                    if (data.messageid == this.activeUserConversationDataResult[j].messageid) {
                      this.activeUserConversationDataResult.splice(j, 1);
      
                      break;
                    }
                  }
                });
              }
            }
          }
        }
      
        if (this.user.enterprise_Name != 'lbc' && this.user.enterprise_Name != 'lakmeindia') {
          //this.getCurrentOrderProcessDetialsOfActiveCustomer();
        }
      
        this.disabling_rightClick_for_privacy_of_content();
      }
      else{
        var conversationidFoundBool = false;
      
        for (var i = 0; i < this.customersResults.length; i++) {
          if (data.conversationid == this.customersResults[i].conversationid) {
            conversationidFoundBool = true;
      
            break;
          }
        }
      
        if (conversationidFoundBool == false) {
          if ((this.searchByArchivedUsersText == true || this.searchByFollowupText == true || this.searchByNameOrEmailText != '' || this.searchByTagText != '' || this.searchByOrderIdText != '' || (this.searchByCustomFieldKey != '' && this.searchByCustomFieldText != ''))) {
            canProceedAndCallApiBool = false;
          }
          else if (this.searchByAssignedToBotText == true) {
            if (typeof data.conversation_active != 'undefined') {
              if (data.conversation_active == false) {
                canProceedAndCallApiBool = false;
              }
            }
          }
        }
      }
      
      if(this.customersResultsLoading == false && canProceedAndCallApiBool == true){
        //"api/get-conversation" call used to be here... we removed it for the optimization
        
        conversationidFoundBool = false;

        var result = {
          conversation: data
        };

        if(typeof result.conversation.active != 'undefined'){
          if(result.conversation.active == null){
            result.conversation.active = true;
          }
        }
        else if(typeof data.conversation_active != 'undefined'){
          result.conversation.active = data.conversation_active;
        }
        else if(typeof data.active != 'undefined'){
          result.conversation.active = data.active;
        }

        for (var i2 = 0; i2 < this.customersResults.length; i2++) {
          if (data.conversationid == this.customersResults[i2].conversationid) {
            conversationidFoundBool = true;

            result = {
              conversation: this.customersResults[i2]
            };

            if(typeof data.email != 'undefined' && data.email != null && data.email != 'None' && data.email != ''){
              result.conversation.email = data.email;
            }
            else{
              result.conversation.email = "unrecorded";
            }

            if(typeof data.image != 'undefined' && data.image != null && data.image != 'None' && data.image != ''){
              result.conversation.image = data.image;
            }
            else{
              result.conversation.image = "";
            }

            if(typeof data.name != 'undefined' && data.name != null && data.name != 'None' && data.name != ''){
              result.conversation.name = data.name;
            }
            else{
              result.conversation.name = "unrecorded";
            }

            if(typeof data.mobile != 'undefined' && data.mobile != null && data.mobile != 'None' && data.mobile != ''){
              result.conversation.mobile = data.mobile;
            }
            else{
              result.conversation.mobile = "";
            }

            if(typeof data.mobilecountrycode != 'undefined' && data.mobilecountrycode != null && data.mobilecountrycode != 'None' && data.mobilecountrycode != ''){
              result.conversation.mobilecountrycode = data.mobilecountrycode;
            }
            else{
              result.conversation.mobilecountrycode = "";
            }

            if(this.customersResults[i2].unread == 'undefined'){
              result.conversation['unread'] = 0;
            }
            else{
              result.conversation['unread'] = this.customersResults[i2].unread;
            }

            let this_is_status_message = false;
            if(typeof data.template != 'undefined'){
              if(typeof data.template.type != 'undefined'){
                if(data.template.type == 'status'){
                  this_is_status_message = true;
                }
              }
            }

            if(this_is_status_message == false){
              result.conversation.lastReplyTimestamp = data.created;
              result.conversation.userReplyTimestamp = data.created;
            }

            if(typeof data.assigned_to != 'undefined' && data.assigned_to != null){
              result.conversation.assigned_to = data.assigned_to;
            }

            if(typeof data.agent != 'undefined' && data.agent != null){
              /* if(this.agentRoutingEnabled_accountLevel == true){
                if(result.conversation.active == false){
                  result.conversation.assigned_to = data.agent;
                }
              }
              else{
                result.conversation.assigned_to = data.agent;
              } */
              result.conversation.assigned_to = data.agent;
            }
            
            if(typeof data.conversation_active != 'undefined'){
              result.conversation.active = data.conversation_active;
            }

            force_playSoundNotification_bool = false;
            if (typeof data.vertex != 'undefined') {
              if (data.vertex == 2125 || data.vertex == '2125' || data.vertex == 20080 || data.vertex == '20080') {
                result.conversation.active = false;

                force_playSoundNotification_bool = true;
              }
            }

            if (this.calculate24Plus1RuleForCustomer(result.conversation.userReplyTimestamp) >= 24) {
              if (result.conversation.channel == 'facebook' || result.conversation.channel == 'instagram_off') {
                //result.conversation.active = false;
                result.conversation.page_msg_subscription_restriction = true;
              } else {
                result.conversation.page_msg_subscription_restriction = false;
              }
            } else {
              result.conversation.page_msg_subscription_restriction = false;
            }

            if (this.calculate24Plus1RuleForCustomer(result.conversation.userReplyTimestamp) >= 24 && result.conversation.channel == 'whatsapp') {
              if (data.conversationid == this.$route.query.conversation) {
                this.isWhatsappChat_old24H = true;
              }
              if(data.replied == false) {
                if (data.conversationid == this.$route.query.conversation) {
                  this.isWhatsappChat_old24H = false;
                  this.isWhatsappChat_sendTemplate = false;
                }
              }
            } else {
              if (result.conversation.channel == 'whatsapp' && data.replied == false) {
                if (data.conversationid == this.$route.query.conversation) {
                  this.isWhatsappChat_old24H = false;
                  this.isWhatsappChat_sendTemplate = false;
                }
              }
            }

            if (result.conversation.conversationid == this.$route.query.conversation) {
              result.conversation.currentlyOpendChat = true;

              //result.conversation.active = true;

              this.activeCustomer = result.conversation;
            } else {
              result.conversation.currentlyOpendChat = false;
            }

            this.customersResults[i2] = result.conversation;

            //moving this customer to top of list - start
            var a = this.customersResults.splice(i2, 1);
            this.customersResults.unshift(a[0]);
            console.log('op1');

            var temp_customersArr_a = this.uniqueObjectiveArray(this.customersResults,'id');
            this.customersResults = temp_customersArr_a;
            
            this.adjustNew_customerData_intoTabs(result.conversation);
            //moving this customer to top of list - end

            if (result.conversation.conversationid != this.$route.query.conversation) {
              var currentCount = 0;

              for (var i4 = 0; i4 < this.customersResults.length; i4++) {
                currentCount = 0;

                if (comingViaAgentRouting == false) {
                  if (this.customersResults[i4].conversationid == data.conversationid) {
                    if ((result.conversation.active == true && data.stuckerror == true && data.replied == false) || (result.conversation.active == false && data.replied == false) || (force_playSoundNotification_bool == true && (this.checkIfChatIsAssignedToMe(result.conversation) == true || this.checkIfChatIsUnassigned(result.conversation) == true))) {
                      if(this.customersResults[i4].unread == 'undefined'){
                        currentCount = 0;
                      }
                      else{
                        currentCount = this.customersResults[i4].unread;
                      }

                      if (currentCount == 0) {
                        currentCount = 1;
                      } else {
                        currentCount = parseInt(currentCount);
                        currentCount = currentCount + 1;
                      }

                      this.customersResults[i4].unread = currentCount;

                      document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                  else{
                    if(result.conversation.active == false && data.replied == false){
                      document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
                else{
                  if (result.conversation.active == false) {
                    if(this.customersResults[i4].unread == 'undefined'){
                      currentCount = 0;
                    }
                    else{
                      currentCount = this.customersResults[i4].unread;
                    }

                    if (currentCount == 0) {
                      currentCount = 1;
                    } else {
                      currentCount = parseInt(currentCount);
                      currentCount = currentCount + 1;
                    }

                    this.customersResults[i4].unread = currentCount;

                    document.getElementById("customerUnReadMessagesAudio").play();
                  }
                }
              }

              for (var i4a = 0; i4a < this.activeChatsTab_customersResults.length; i4a++) {
                currentCount = 0;

                if (comingViaAgentRouting == false) {
                  if (this.activeChatsTab_customersResults[i4a].conversationid == data.conversationid) {
                    if ((result.conversation.active == true && data.stuckerror == true && data.replied == false) || (result.conversation.active == false && data.replied == false)) {
                      if(this.activeChatsTab_customersResults[i4a].unread == 'undefined'){
                        currentCount = 0;
                      }
                      else{
                        currentCount = this.activeChatsTab_customersResults[i4a].unread;
                      }

                      if (currentCount == 0) {
                        currentCount = 1;
                      } else {
                        currentCount = parseInt(currentCount);
                        currentCount = currentCount + 1;
                      }

                      this.activeChatsTab_customersResults[i4a].unread = currentCount;

                      //document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
                else{
                  if (result.conversation.active == false) {
                    if(this.activeChatsTab_customersResults[i4a].unread == 'undefined'){
                      currentCount = 0;
                    }
                    else{
                      currentCount = this.activeChatsTab_customersResults[i4a].unread;
                    }

                    if (currentCount == 0) {
                      currentCount = 1;
                    } else {
                      currentCount = parseInt(currentCount);
                      currentCount = currentCount + 1;
                    }

                    this.activeChatsTab_customersResults[i4a].unread = currentCount;

                    //document.getElementById("customerUnReadMessagesAudio").play();
                  }
                }
              }

              for (var i4b = 0; i4b < this.requestsChatsTab_customersResults.length; i4b++) {
                currentCount = 0;

                if (comingViaAgentRouting == false) {
                  if (this.requestsChatsTab_customersResults[i4b].conversationid == data.conversationid) {
                    if ((result.conversation.active == true && data.stuckerror == true && data.replied == false) || (result.conversation.active == false && data.replied == false)) {
                      if(this.requestsChatsTab_customersResults[i4b].unread == 'undefined'){
                        currentCount = 0;
                      }
                      else{
                        currentCount = this.requestsChatsTab_customersResults[i4b].unread;
                      }

                      if (currentCount == 0) {
                        currentCount = 1;
                      } else {
                        currentCount = parseInt(currentCount);
                        currentCount = currentCount + 1;
                      }

                      this.requestsChatsTab_customersResults[i4b].unread = currentCount;

                      //document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
                else{
                  if (result.conversation.active == false) {
                    if(this.requestsChatsTab_customersResults[i4b].unread == 'undefined'){
                      currentCount = 0;
                    }
                    else{
                      currentCount = this.requestsChatsTab_customersResults[i4b].unread;
                    }

                    if (currentCount == 0) {
                      currentCount = 1;
                    } else {
                      currentCount = parseInt(currentCount);
                      currentCount = currentCount + 1;
                    }

                    this.requestsChatsTab_customersResults[i4b].unread = currentCount;

                    //document.getElementById("customerUnReadMessagesAudio").play();
                  }
                }
              }

              for (var i4c = 0; i4c < this.botChatsTab_customersResults.length; i4c++) {
                currentCount = 0;

                if (comingViaAgentRouting == false) {
                  if (this.botChatsTab_customersResults[i4c].conversationid == data.conversationid) {
                    if ((result.conversation.active == true && data.stuckerror == true && data.replied == false) || (result.conversation.active == false && data.replied == false)) {
                      if(this.botChatsTab_customersResults[i4c].unread == 'undefined'){
                        currentCount = 0;
                      }
                      else{
                        currentCount = this.botChatsTab_customersResults[i4c].unread;
                      }

                      if (currentCount == 0) {
                        currentCount = 1;
                      } else {
                        currentCount = parseInt(currentCount);
                        currentCount = currentCount + 1;
                      }

                      this.botChatsTab_customersResults[i4c].unread = currentCount;

                      //document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
                else{
                  if (result.conversation.active == false) {
                    if(this.botChatsTab_customersResults[i4c].unread == 'undefined'){
                      currentCount = 0;
                    }
                    else{
                      currentCount = this.botChatsTab_customersResults[i4c].unread;
                    }

                    if (currentCount == 0) {
                      currentCount = 1;
                    } else {
                      currentCount = parseInt(currentCount);
                      currentCount = currentCount + 1;
                    }

                    this.botChatsTab_customersResults[i4c].unread = currentCount;

                    //document.getElementById("customerUnReadMessagesAudio").play();
                  }
                }
              }
            }

            //this.distribute_customersResults_intoTabs(this.customersResults);

            break;
          }
        }

        if (conversationidFoundBool != true){
          console.log('new coming chat...');
          result.conversation.id = result.conversation.conversationid;
          result.conversation.currentlyOpendChat = false;

          if(typeof data.email != 'undefined' && data.email != null && data.email != 'None' && data.email != ''){
            result.conversation.email = data.email;
          }
          else{
            result.conversation.email = "unrecorded";
          }

          if(typeof data.image != 'undefined' && data.image != null && data.image != 'None' && data.image != ''){
            result.conversation.image = data.image;
          }
          else{
            result.conversation.image = "";
          }

          if(typeof data.name != 'undefined' && data.name != null && data.name != 'None' && data.name != ''){
            result.conversation.name = data.name;
          }
          else{
            result.conversation.name = "unrecorded";
          }

          if(typeof data.mobile != 'undefined' && data.mobile != null && data.mobile != 'None' && data.mobile != ''){
            result.conversation.mobile = data.mobile;
          }
          else{
            result.conversation.mobile = "";
          }

          if(typeof data.mobilecountrycode != 'undefined' && data.mobilecountrycode != null && data.mobilecountrycode != 'None' && data.mobilecountrycode != ''){
            result.conversation.mobilecountrycode = data.mobilecountrycode;
          }
          else{
            result.conversation.mobilecountrycode = "";
          }

          if (typeof data.conversation_active != 'undefined') {
            result.conversation.active = data.conversation_active;
          }

          result.conversation.channel_id = data.pageid;
          result.conversation.channel = this.getChannelFromPageActive(data.pageid);

          let this_is_status_message = false;
          //can't check this here otherwise I will have nothing to put in these 2 variables "lastReplyTimestamp" & "userReplyTimestamp"
          /* if(typeof data.template != 'undefined'){
            if(typeof data.template.type != 'undefined'){
              if(data.template.type == 'status'){
                this_is_status_message = true;
              }
            }
          } */

          if(this_is_status_message == false){
            result.conversation.lastReplyTimestamp = data.created;
            result.conversation.userReplyTimestamp = data.created;
          }

          if (this.calculate24Plus1RuleForCustomer(result.conversation.userReplyTimestamp) >= 24) {
            if (result.conversation.channel == 'facebook' || result.conversation.channel == 'instagram_off') {
              //result.conversation.active = false;
              result.conversation.page_msg_subscription_restriction = true;
            } else {
              result.conversation.page_msg_subscription_restriction = false;
            }
          } else {
            result.conversation.page_msg_subscription_restriction = false;
          }

          if (this.calculate24Plus1RuleForCustomer(result.conversation.userReplyTimestamp) >= 24 && result.conversation.channel == 'whatsapp') {
            this.isWhatsappChat_old24H = true;
            if(data.replied == false) {
              this.isWhatsappChat_old24H = false;
              this.isWhatsappChat_sendTemplate = false;
            }
          } else {
            if (result.conversation.channel == 'whatsapp' && data.replied == false) {
              this.isWhatsappChat_old24H = false;
              this.isWhatsappChat_sendTemplate = false;
            }
          }

          if(typeof data.agent != 'undefined' && data.agent != null){
            result.conversation.assigned_to = data.agent;
          }

          force_playSoundNotification_bool = false;
          if(typeof data.vertex != 'undefined'){
            if(data.vertex == 2125 || data.vertex == '2125' || data.vertex == 20080 || data.vertex == '20080'){
              result.conversation.active = false;

              force_playSoundNotification_bool = true;
            }
          }
          
          var iAmTheAssignedAgentTothisOne = true;

          if (this.agentFocusedMode == true) {
            if(comingViaAgentRouting == true){
              this.customersResults.unshift(result.conversation);

              this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

              this.activeCustomer = result.conversation;

              console.log('op2a');

              document.getElementById("customerUnReadMessagesAudio").play();
            }
            else{
              if (result.conversation.assigned_to != null && Object.keys(result.conversation.assigned_to).length > 0 && result.conversation.active == false) {
                iAmTheAssignedAgentTothisOne = true;
                if (this.loggedInUser_id == result.conversation.assigned_to.id) {
                  iAmTheAssignedAgentTothisOne = true;
                } else {
                  iAmTheAssignedAgentTothisOne = false;
                }

                if (iAmTheAssignedAgentTothisOne == true) {
                  if (this.agentRoutingEnabled_accountLevel == true) {
                    if (comingViaAgentRouting == true) {
                      this.customersResults.unshift(result.conversation);

                      this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                      this.activeCustomer = result.conversation;

                      console.log('op2b');

                      document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  } else {
                    this.customersResults.unshift(result.conversation);

                    this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                    this.activeCustomer = result.conversation;

                    console.log('op3');
                  }
                }
              }
            }
          }
          else {
            if (this.agentRoutingEnabled_accountLevel == true && comingViaAgentRouting == true && this.searchByAssignedToMeText != true) {
              this.customersResults.unshift(result.conversation);

              this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

              console.log('op4');

              this.activeCustomer = result.conversation;

              document.getElementById("customerUnReadMessagesAudio").play();
            } else {
              this.activeCustomer = result.conversation;

              console.log('op5');

              if (this.searchByArchivedUsersText == true || this.searchByFollowupText == true || this.searchByNameOrEmailText != '' || this.searchByTagText != '' || this.searchByOrderIdText != '' || (this.searchByCustomFieldKey != '' && this.searchByCustomFieldText != '')) {
                //no need to show new conversation in this case
              } else if (this.searchByAssignedToBotText == true && result.conversation.active == true) {
                this.customersResults.unshift(result.conversation);

                this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                if(this.accountType_master != true){
                  document.getElementById("customerUnReadMessagesAudio").play();
                }
              } else if (this.searchByUnassignedConvoText == true && (result.conversation.active == false && result.conversation.assigned_to == null)) {
                this.customersResults.unshift(result.conversation);

                this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                if(this.accountType_master != true){
                  document.getElementById("customerUnReadMessagesAudio").play();
                }
              } else if (this.searchByAssignedToMeText == true) {
                if (result.conversation.assigned_to != null && Object.keys(result.conversation.assigned_to).length > 0) {
                  iAmTheAssignedAgentTothisOne = true;
                  if (this.loggedInUser_id == result.conversation.assigned_to.id) {
                    iAmTheAssignedAgentTothisOne = true;
                  } else {
                    iAmTheAssignedAgentTothisOne = false;
                  }

                  if (iAmTheAssignedAgentTothisOne == true) {
                    this.customersResults.unshift(result.conversation);

                    this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                    if(this.accountType_master != true){
                      document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
              } else if (this.searchByAssignedToAgentID != '') {
                if (result.conversation.assigned_to != null && Object.keys(result.conversation.assigned_to).length > 0) {
                  if (this.loggedInUser_id == result.conversation.assigned_to.id) {
                    this.customersResults.unshift(result.conversation);

                    this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                    if(this.accountType_master != true){
                      document.getElementById("customerUnReadMessagesAudio").play();
                    }
                  }
                }
              } else if (this.selectedChannel != 'all' && (this.selectedChannel == result.conversation.channel || this.selectedChannel == result.conversation.channel_id)) {
                this.customersResults.unshift(result.conversation);

                this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                if(this.accountType_master != true){
                  document.getElementById("customerUnReadMessagesAudio").play();
                }
              } else if (this.checkIfAnyFilterIsApplied() == false) {
                this.customersResults.unshift(result.conversation);

                this.checkAndAddTheSingleCustomerDataToRelevantTabArray(result.conversation, 'unshift');

                if(this.accountType_master != true){
                  document.getElementById("customerUnReadMessagesAudio").play();
                }
              }
            }
          }

          //--------
          if (this.accountType_master != true && data.replied == false && (this.user.enterprise_Name == 'smretail_ph' || this.user.enterprise_Name == 'frisogold_my')) {
            if (result.conversation.assigned_to != null && Object.keys(result.conversation.assigned_to).length > 0) {
              iAmTheAssignedAgentTothisOne = true;
              if (this.loggedInUser_id == result.conversation.assigned_to.id) {
                iAmTheAssignedAgentTothisOne = true;
              } else {
                iAmTheAssignedAgentTothisOne = true;
              }

              if (iAmTheAssignedAgentTothisOne == true) {
                /* var newSingleNotificationData = {
                  created: "now",
                  read: false,
                  point_to: "/page-inbox?conversation="+ data.conversationid,
                  type: "livechat_new_message",
                  id: Math.floor((Math.random() * 100000000000) + 1),
                  conversationid: data.conversationid,
                  subject: "You have got a new message from " + result.conversation.name
                };

                this.normalPusherNotifications.push(newSingleNotificationData);

                setTimeout(function() {
                  for (var i3 = 0; i3 < this.normalPusherNotifications.length; i3++) {
                    if (typeof data != 'undefined') {
                      if (newSingleNotificationData.id == this.normalPusherNotifications[i3].id) {
                        this.normalPusherNotifications.splice(i3, 1);
                      }
                    }
                  }
                }, 5000); */
                document.getElementById("customerUnReadMessagesAudio").play();
              }
            }
          }
          //--------
        }
      }
    },
    renderDate(input){
      if(typeof input != 'undefined'){
        input = input.split(' ');
        input = input[0].split('-');
        input = input[2] +'-'+ input[1] +'-'+ input[0];

        return input;
      }
    },
    renderTime(input){
      if(typeof input != 'undefined'){
        input = input.split(' ');
        input = input[1].split('.');

        return input[0];
      }
    },
    getFormattedReferredOriginName(name){
      if(typeof name != 'undefined' && name != null){
        var nameLowerCase = name.toLowerCase();

        var channel = "";

        if(nameLowerCase == 'facebook' || nameLowerCase == 'fb'){
          channel = "Messenger";
        }
        else if(nameLowerCase == 'messenger'){
          channel = "Messenger";
        }
        else if(nameLowerCase == 'line' || nameLowerCase == 'ln'){
          channel = "LINE";
        }
        else if(nameLowerCase == 'instagram' || nameLowerCase == 'ig'){
          channel = "Instagram";
        }
        else if(nameLowerCase == 'viber'){
          channel = "Viber";
        }
        else if(nameLowerCase == 'telegram'){
          channel = "Telegram";
        }
        else if(nameLowerCase == 'sms'){
          channel = "SMS";
        }
        else if(nameLowerCase == 'googlebusinessmsg'){
          channel = "Google business messages";
        }
        else if(nameLowerCase == 'twitter' || nameLowerCase == 'tw'){
          channel = "Twitter";
        }
        else if(nameLowerCase == 'youtube' || nameLowerCase == 'yt'){
          channel = "YouTube";
        }
        else if(nameLowerCase == 'web' || nameLowerCase == 'jmp' || nameLowerCase == 'webbot' || nameLowerCase == 'bot'){
          channel = "Web";
        }
        else if(nameLowerCase == 'imessage'){
          channel = "iMessage";
        }
        else if(nameLowerCase == 'whatsapp'){
          channel = "WhatsApp";
        }
        else if(nameLowerCase == 'adlingo'){
          channel = "AdLingo";
        }
        else if(nameLowerCase == 'pinterest'){
          channel = "Pinterest";
        }
        else if(nameLowerCase == 'snapchat'){
          channel = "Snapchat";
        }
        else if(nameLowerCase == 'wechat'){
          channel = "WeChat";
        }
        else if(nameLowerCase == 'blog' || nameLowerCase == 'incontext'){
          channel = "Blog chatbot";
        }
        else if(nameLowerCase == 'chatbox' || nameLowerCase == 'widget'){
          channel = "Chatbox";
        }
        else{
          channel = name;
        }

        return channel;
      }
    },
    renderFormattedReminderDateTime(input) {
      if (typeof input != 'undefined') {
        input = input.split(' ');
        var dateInput = input[0];
        var timeInput = input[1];
    
        dateInput = dateInput.split('-');
        var dayText = dateInput[0];
        var monthText = dateInput[1];
    
        timeInput = timeInput.split(':');
        var hoursText = timeInput[0];
        var minutesText = timeInput[1];
    
        if (hoursText.length == 1) {
          hoursText = "0" + hoursText;
        }
    
        if (minutesText.length == 1) {
          minutesText = "0" + minutesText;
        }
    
        if (monthText == '01' || monthText == '1') {
          monthText = "Jan";
        } else if (monthText == '02' || monthText == '2') {
          monthText = "Feb";
        } else if (monthText == '03' || monthText == '3') {
          monthText = "Mar";
        } else if (monthText == '04' || monthText == '4') {
          monthText = "Apr";
        } else if (monthText == '05' || monthText == '5') {
          monthText = "May";
        } else if (monthText == '06' || monthText == '6') {
          monthText = "Jun";
        } else if (monthText == '07' || monthText == '7') {
          monthText = "Jul";
        } else if (monthText == '08' || monthText == '8') {
          monthText = "Aug";
        } else if (monthText == '09' || monthText == '9') {
          monthText = "Sep";
        } else if (monthText == '10' || monthText == '10') {
          monthText = "Oct";
        } else if (monthText == '11' || monthText == '11') {
          monthText = "Nov";
        } else if (monthText == '12' || monthText == '12') {
          monthText = "Dec";
        }
    
        return dayText + " " + monthText + " at " + hoursText + ":" + minutesText;
      }
    },
    renderCustomerMobileCountryCodeAndInitMobileNumber() {
      /* if (this.activeCustomer.mobilecountrycode != '' && this.activeCustomer.mobilecountrycode != 'None' && this.activeCustomer.mobilecountrycode != 'none' && this.activeCustomer.mobilecountrycode != null && this.activeCustomer.mobilecountrycode != 'unrecorded') {
        for (var key in countryCodesData.countryCodesData) {
          if (countryCodesData.countryCodesData.hasOwnProperty(key)) {
            if (countryCodesData.countryCodesData[key].dial_code == this.activeCustomer.mobilecountrycode) {
              this.userShortCountyName = countryCodesData.countryCodesData[key].code.toLowerCase();
              this.userFullCountyName = countryCodesData.countryCodesData[key].name.toLowerCase();
            }
          }
        }
    
        $("#crmCustomerMobileInputText").intlTelInput({
          initialCountry: this.userShortCountyName,
          preferredCountries: preferredCountries,
          numberType: "MOBILE",
          separateDialCode: true,
          utilsScript: "https://jumper.ai/web-b/resources/js/utils.js"
        });
      } else {
        $("#crmCustomerMobileInputText").intlTelInput({
          initialCountry: this.usercountrySmall.toLowerCase(),
          preferredCountries: preferredCountries,
          numberType: "MOBILE",
          separateDialCode: true,
          utilsScript: "https://jumper.ai/web-b/resources/js/utils.js"
        });
      } */
    },
    renderFnbOrderType(takeaway, variables) {
      var delivery = false;
    
      for (var key in variables) {
        if (key == 'state') {
          if (variables[key] == 'delivery') {
            delivery = true;
          } else if (variables[key] == 'pickup') {
            return "Pickup";
          } else if (variables[key] == 'dinein') {
            return "Dine-in";
          } else if (variables[key] == 'takeout') {
            return "Take-out";
          }
        }
      }
    
      if (delivery == true) {
        return "Delivery";
      } else {
        if (takeaway == true) {
          return "Pickup";
        } else {
          return "Dine-in";
        }
      }
    },
    convertCommaToLineBreak(input) {
      if (typeof input != 'undefined') {
        if (input != null && input != 'None' && input != '') {
          input = input.replace(/,/g, "<br/>");
    
          return input;
        }
      }
    },
    getCommaSeparatedTags(tags) {
      if (typeof tags != 'undefined') {
        if (tags.length == 0) {
          return "";
        } else if (tags.length == 1) {
          return tags[0];
        } else {
          return tags.join(', ');
        }
      }
    },
    renderValueInCustomerField(input) {
      if (typeof input != 'undefined') {
        if (input != 'unrecorded' && input != 'valid' && input != '' && input != 'None' && input != 'null' && input != null) {
          return input;
        } else {
          return "";
        }
      }
    },
    renderActiveCustomerName(input) {
      if (typeof input != 'undefined') {
        if (input != 'unrecorded' && input != 'valid' && input != '' && input != 'None' && input != 'null' && input != null) {
          return input;
        } else {
          return "Anonymous";
        }
      }
    },
    renderFirstNameOfCustomer(input) {
      if (typeof input != 'undefined') {
        if (input.indexOf(' ') > -1) {
          input = input.split(' ');
    
          return input[0];
        }
      }
    },
    renderLastNameOfCustomer(input) {
      if (typeof input != 'undefined') {
        if (input.indexOf(' ') > -1) {
          input = input.split(' ');
    
          return input[1];
        }
      }
    },
    renderMobileNumberOfCustomerWithoutCountrycode(input) {
      if (typeof input != 'undefined') {
        if (input.indexOf('+') == -1) {
          return input;
        }
      }
    },
    checkIfTwoJsonObjectsAreEqual(obj1, obj2) {
      if (typeof obj1 != 'undefined') {
        var flag = true;
    
        if (Object.keys(obj1).length == Object.keys(obj2).length) {
          for (var key in obj1) {
            if (obj1[key] == obj2[key]) {
              continue;
            } else {
              flag = false;
    
              break;
            }
          }
        } else {
          flag = false;
        }
    
        return flag;
      }
    },
    checkIfTwoJsonArraysAreEqual(arr1, arr2) {
      if (arr1.length) {
        var flag = true;
    
        if (arr1.length == arr2.length) {
          for (var i = 0; i < arr1.length; i++) {
            if (typeof arr1[i] == 'object' && typeof arr2[i] == 'object') {
              if (Array.isArray(arr1[i])) {
                if (Array.isArray(arr2[i])) {
                  flag = this.checkIfTwoJsonArraysAreEqual(arr1[i], arr2[i]);
                } else {
                  flag = false;
                }
              } else {
                flag = this.checkIfTwoJsonObjectsAreEqual(arr1[i], arr2[i]);
              }
            } else {
              if (arr1[i] == arr2[i]) {
                continue;
              } else {
                flag = false;
              }
            }
            if (!flag) {
              break;
            }
          }
        } else {
          flag = false;
        }
    
        return flag;
      }
    },
    checkIfThisIsAdvanceOrder(input) {
      if (typeof input != 'undefined') {
        input = input.replace(' ', 'T').split('.');
        input = input[0];
        input = input.split(':');
        input = input[0] + ":" + input[1];
    
        var inputDate = new Date(input);
    
        inputDate = inputDate.getTime();
        var currentDate = new Date().getTime();
    
        if (inputDate > currentDate) {
          return true;
        } else if (inputDate <= currentDate) {
          return false;
        }
      }
    },
    renderDateTime_fnbOrder(input, convert, format) {
      if (typeof input != 'undefined') {
        var date = "";
        var date_dd = "";
        var date_mm = "";
        var date_yyyy = "";

        var time = "";
        var time_hh = "";
        var time_mm = "";

        var timezone = this.getFormattedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone,'slashName');

        if (convert != false) {
          input = input.replace(' ', 'T').split('.');
          //input = input[0] + '+00:00';
          //input = this.moment(input).format();
          input = input[0] +""+ timezone;
    
          input = input.split('T');
    
          date = input[0].split('-');
          date_dd = date[2];
          date_mm = date[1];
          date_yyyy = date[0];
    
          date = date_dd + '-' + date_mm + '-' + date_yyyy;
    
          time = input[1].split(':');
          time_hh = time[0];
          time_mm = time[1];
    
          time = time_hh + ':' + time_mm;
        } else {
          input = input.split(' ');
          date = input[0].split('-');
    
          date_dd = date[2];
          date_mm = date[1];
          date_yyyy = date[0];
    
          date = date_dd + '-' + date_mm + '-' + date_yyyy;
    
          time = input[1].split('.');
          time = time[0].split(':');
          time_hh = time[0];
          time_mm = time[1];
    
          time = time_hh + ':' + time_mm;
        }
    
        if (format == 'DD-M HH:MM AMPM') {
          var month_name = "";
          if (date_mm == '1' || date_mm == '01') {
            month_name = "Jan";
          } else if (date_mm == '2' || date_mm == '02') {
            month_name = "Feb";
          } else if (date_mm == '3' || date_mm == '03') {
            month_name = "Mar";
          } else if (date_mm == '4' || date_mm == '04') {
            month_name = "Apr";
          } else if (date_mm == '5' || date_mm == '05') {
            month_name = "May";
          } else if (date_mm == '6' || date_mm == '06') {
            month_name = "Jun";
          } else if (date_mm == '7' || date_mm == '07') {
            month_name = "Jul";
          } else if (date_mm == '8' || date_mm == '08') {
            month_name = "Aug";
          } else if (date_mm == '9' || date_mm == '09') {
            month_name = "Sep";
          } else if (date_mm == '10') {
            month_name = "Oct";
          } else if (date_mm == '11') {
            month_name = "Nov";
          } else if (date_mm == '12') {
            month_name = "Dec";
          }
    
          var dateFormatted = date_dd + ' ' + month_name;
    
          //ampm start
          time_hh = parseInt(time_hh);
          
          var AMPM = "";

          if (time_hh >= 12) {
            AMPM = 'PM';
    
            time_hh = time_hh - 12;
          } else {
            AMPM = 'AM';
          }
    
          if (time_hh == 0 || time_hh == '00') {
            time_hh = 12;
          }
          //ampm end
    
          var timeFormatted = time_hh + ':' + time_mm + ' ' + AMPM;
    
          return dateFormatted + ', ' + timeFormatted;
        } else {
          return date + ' ' + time;
        }
      }
    },
    renderRedRibbonDedicationCakeNotesPerLine(notes) {
      if (typeof notes != 'undefined') {
        notes = notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
    
        if (notes.indexOf('<br>') > -1) {
          return notes.split('<br>');
        }
      }
    },
    getMaxNumberOfButtonsForSIngleCard(result) {
      var maxButtonsCount = 0;
    
      for (var i = 0; i < result.length; i++) {
        if (result[i].buttons.length > maxButtonsCount) {
          maxButtonsCount = result[i].buttons.length;
        }
      }
    
      return maxButtonsCount;
    },
    checkIfMessageNeedsBindHtml(input) {
      if (typeof input != 'undefined' && input != null && input != 'None' && input != '') {
        input = input.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        input = input.replace(/:::::/g, "<br> ").replace(/(?:\r\n|\r|\n)/g, '<br> ');
    
        if (input.indexOf('http://') > -1 || input.indexOf('https://') > -1 || input.indexOf('<br') > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    isLetter(str) {
      //will return null if not from a-z
      return str.length === 1 && str.match(/[a-z]/i);
    },
    renderCurrencySymbol(normalCurrency) {
      if (normalCurrency != '' && typeof normalCurrency != 'undefined') {
        normalCurrency = normalCurrency.toUpperCase();
        var lastChar = normalCurrency.slice(-1);
    
        if (this.isLetter(lastChar) == null) {
          return normalCurrency;
        } else {
          for (var i = 0; i < this.allCountryCurrencyData.allCountryCurrencyData.length; i++) {
            if (this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code_with_symbol == normalCurrency) {
              if (normalCurrency == 'Rp') {
                return normalCurrency + " ";
              } else {
                if (this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code == normalCurrency) {
                  return normalCurrency + " ";
                } else {
                  return normalCurrency;
                }
              }
            } else if (this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code == normalCurrency) {
              lastChar = this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code_with_symbol.slice(-1);
    
              if (this.isLetter(lastChar) == null) {
                return this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code_with_symbol;
              } else {
                return this.allCountryCurrencyData.allCountryCurrencyData[i].currency_code_with_symbol + " ";
              }
            }
          }
        }
      }
    },
    checkIfImageUrlIsValidOrNot(value) {
      if (value == '' || value == null) {
        return false;
      } else {
        if ((value.indexOf('http://') > -1 || value.indexOf('https://') > -1) && value.indexOf('.') > -1) {
          return true;
        } else {
          /* if (this.inchat_uploadImageToChatImageFileObject != '') {
            return true;
          } else {
            return false;
          } */
          return false;
        }
      }
    },
    calculate24Plus1RuleForCustomer(input) {
      if (typeof input != 'undefined') {
        if (input != '' && input != 'None' && input != null) {
          //input = input.split(' ');
          
          var timezone = this.getFormattedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone,'slashName');

          input = input.replace(' ', 'T').split('.');
          //input = input[0] + '+00:00';
          //input = this.moment(input).format();
          input = input[0] +""+ timezone;
          input = input.split('T');
    
          var inputDate = input[0];
          var inputTime = input[1];
          inputDate = inputDate.split('-');
          inputDate = inputDate[1] + "/" + inputDate[2] + "/" + inputDate[0];
    
          inputTime = inputTime.split(':');
          inputTime = inputTime[0] + ":" + inputTime[1];
    
          var startDateFormatted = inputDate + " " + inputTime;
          var datetimeNow = new Date();
          var datetimeNowFormatted = (datetimeNow.getMonth() + 1) + "/" + datetimeNow.getDate() + "/" + datetimeNow.getFullYear() + " " + datetimeNow.getHours() + ":" + datetimeNow.getMinutes();

          var startDate = new Date(startDateFormatted);
          var endDate = new Date(datetimeNowFormatted);
          var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
          var hours = seconds / 3600;
          
          if ((this.activeCustomer.channel == 'facebook' || this.activeCustomer.channel == 'instagram') && hours >= 167) {
            this.composerInputValid_asPerTime = false;
          } else {
            this.composerInputValid_asPerTime = true;
          }
    
          return hours;
        } else {
          return 50;
        }
      }
    },
    getLengthOfAgentsListArrayExcudingSelf(allAgentsResult) {
      if (typeof allAgentsResult != 'undefined') {
        return 1;
      }
    },
    checkIfAnyFilterIsApplied() {
      if (this.searchByAssignedToMeText == true || this.searchByAssignedToBotText == true || this.searchByUnassignedConvoText == true || this.searchByFollowupText == true || this.searchByArchivedUsersText == true || this.searchByAssignedToAgentID != '' || this.searchByNameOrEmailText != '' || this.searchByTagText != '' || this.searchByOrderIdText != '' || (this.searchByCustomFieldKey != '' && this.searchByCustomFieldText != '') || this.selectedChannel != 'all') {
        return true;
      } else {
        return false;
      }
    },
    checkIfChatIsAssignedToMe(customer) {
      //assigned to me wheather its active true or false
    
      if (typeof customer != 'undefined') {
        if (customer.assigned_to != null && Object.keys(customer.assigned_to).length > 0) {
          if (this.loggedInUser_id == customer.assigned_to.id) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    checkIfChatIsUnassigned(customer) {
      //unassigned to any agent and active false
      //or assigned to my group (means current loggedin agent's group)
    
      if (typeof customer != 'undefined') {
        if (customer.assigned_to == null && customer.active == false) {
          return true;
        }
        else if(customer.assigned_to != null && Object.keys(customer.assigned_to).length > 1){
          if(this.basicPermissionData.group_id == customer.assigned_to.id){
            return true;
          }
          else{
            return false;
          }
        }
      }
    },
    checkIfChatIsAssignedToBot(customer) {
      //active true and (not assigned to me or my group)
    
      if (typeof customer != 'undefined') {
        if (customer.active == true) {
          if (customer.assigned_to != null && Object.keys(customer.assigned_to).length > 1) {
            if (this.loggedInUser_id != customer.assigned_to.id && this.basicPermissionData.group_id != customer.assigned_to.id) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      }
    },
    checkIfStoryIsImageOrVideo(url) {
      if (typeof url != 'undefined') {
        if (url.indexOf('.mp4') > -1) {
          return "video";
        } else {
          return "image";
        }
      }
    },
    checkIf_chatReaction_validForThisChannel(channel) {
      if (typeof channel != 'undefined') {
        if (channel == 'instagram' || channel == 'facebook') {
          return true;
        } else {
          return false;
        }
      }
    },
    checkIf_chatReaction_validForThisChannel_withTimeCheck(channel) {
      if (typeof channel != 'undefined') {
        if (channel == 'instagram') {
          return true;
        }
        /* else if(channel == 'facebook' && this.calculate24Plus1RuleForCustomer(this.activeCustomer.userReplyTimestamp) < 24){
            return true;
        } */
        else {
          return false;
        }
      }
    },
    removeItemFromAllTabArrays(conversationid){
      if(typeof conversationid != 'undefined'){
        if(this.show_liveChatConversationsListAreaTabs == true){
          for(var i1=0; i1 < this.activeChatsTab_customersResults.length; i1++){
            if(conversationid == this.activeChatsTab_customersResults[i1].conversationid){
              this.activeChatsTab_customersResults.splice(i1,1);

              break;
            }
          }

          for(var i2=0; i2 < this.requestsChatsTab_customersResults.length; i2++){
            if(conversationid == this.requestsChatsTab_customersResults[i2].conversationid){
              this.requestsChatsTab_customersResults.splice(i2,1);

              break;
            }
          }

          for(var i3=0; i3 < this.botChatsTab_customersResults.length; i3++){
            if(conversationid == this.botChatsTab_customersResults[i3].conversationid){
              this.botChatsTab_customersResults.splice(i3,1);

              break;
            }
          }
        }
      }
    },
    adjustNew_customerData_intoTabs(data){
      if(typeof data != 'undefined'){
        if(this.show_liveChatConversationsListAreaTabs == true){
          for(var i1=0; i1 < this.activeChatsTab_customersResults.length; i1++){
            if(data.conversationid == this.activeChatsTab_customersResults[i1].conversationid){
              this.activeChatsTab_customersResults.splice(i1,1);

              break;
            }
          }

          for(var i2=0; i2 < this.requestsChatsTab_customersResults.length; i2++){
            if(data.conversationid == this.requestsChatsTab_customersResults[i2].conversationid){
              this.requestsChatsTab_customersResults.splice(i2,1);

              break;
            }
          }

          for(var i3=0; i3 < this.botChatsTab_customersResults.length; i3++){
            if(data.conversationid == this.botChatsTab_customersResults[i3].conversationid){
              this.botChatsTab_customersResults.splice(i3,1);

              break;
            }
          }

          if(this.checkIfChatIsAssignedToMe(data) == true){
            this.activeChatsTab_customersResults.unshift(data);
          }
          else if(this.checkIfChatIsUnassigned(data) == true){
            this.requestsChatsTab_customersResults.unshift(data);
          }
          else if(this.checkIfChatIsAssignedToBot(data) == true){
            this.botChatsTab_customersResults.unshift(data);
          }
        }
      }
    },
    checkAndAddTheSingleCustomerDataToRelevantTabArray(data,type){
      if(typeof data != 'undefined'){
        if(this.show_liveChatConversationsListAreaTabs == true){
          if(this.checkIfChatIsAssignedToMe(data) == true){
            if(type == 'unshift'){
              this.activeChatsTab_customersResults.unshift(data);
            }
            else{
              this.activeChatsTab_customersResults.push(data);
            }
          }
          else if(this.checkIfChatIsUnassigned(data) == true){
            if(type == 'unshift'){
              this.requestsChatsTab_customersResults.unshift(data);
            }
            else{
              this.requestsChatsTab_customersResults.push(data);
            }
          }
          else if(this.checkIfChatIsAssignedToBot(data) == true){
            if(type == 'unshift'){
              this.botChatsTab_customersResults.unshift(data);
            }
            else{
              this.botChatsTab_customersResults.push(data);
            }
          }
        }
      }
    },
    disabling_rightClick_for_privacy_of_content(){
      setTimeout(function(){
        var image_component_elems = document.getElementsByClassName(".image_component");
        
        for(var cm1=0; cm1 < image_component_elems.length; cm1++){
          image_component_elems[cm1].addEventListener("contextmenu", e => e.preventDefault());
        }

        var video_component_elems = document.getElementsByClassName(".video_component");
        
        for(var cm2=0; cm2 < video_component_elems.length; cm2++){
          video_component_elems[cm2].addEventListener("contextmenu", e => e.preventDefault());
        }
      }, 50);
    },
    getLatLongFromTheGoogleMapsUrl(url){
      if(typeof url != 'undefined'){
        var latLong = "0,0";

        if(url.indexOf('/place/') > -1){
          latLong = url.split('/place/');

          latLong = latLong[1];
        }

        return "https://maps.google.com/maps?q="+ latLong +"&z=14&output=embed";
      }
    },
    checkAndShowTabSpecificUnreadMessageBubble(tabName){
      var unreadCount = 0;

      if(tabName == 'activeChatsTab'){
        for(var i1=0; i1 < this.activeChatsTab_customersResults.length; i1++){
          if(typeof this.activeChatsTab_customersResults[i1].unread != 'undefined'){
            unreadCount = unreadCount + this.activeChatsTab_customersResults[i1].unread;
          }
        }
      }
      else if(tabName == 'requestsChatsTab'){
        for(var i2=0; i2 < this.requestsChatsTab_customersResults.length; i2++){
          if(typeof this.requestsChatsTab_customersResults[i2].unread != 'undefined'){
            unreadCount = unreadCount + this.requestsChatsTab_customersResults[i2].unread;
          }
        }
      }
      else if(tabName == 'botChatsTab'){
        for(var i3=0; i3 < this.botChatsTab_customersResults.length; i3++){
          if(typeof this.botChatsTab_customersResults[i3].unread != 'undefined'){
            unreadCount = unreadCount + this.botChatsTab_customersResults[i3].unread;
          }
        }
      }

      if(unreadCount > 0){
        return true;
      }
      else{
        return false;
      }
    },
  }
}