<template>
<master-layout pageTitle="Facebook">
	<ion-loading
	v-if="loading"
	:is-open="loading"
	cssClass="my-custom-class"
	message="Loading..."
	></ion-loading>
	<div v-else>
		<ion-col v-if="facebookConnected">
      <div>
        <h2 class="title ion-no-margin" style="margin-left: 0px">Facebook & Messenger</h2>
      </div>
      <div v-if="facebookSocialData.pages && facebookSocialData.pages.length > 0">
        <ion-grid>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" router-link="/sales-channel/facebook/share-post">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-fb-share-post.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Share Post</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin" >Create and share post for your product, collection or bot and send automated messages when they click or comment on the post</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" router-link="/sales-channel/facebook/ad-json">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-fb-generate-ad-json.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Generate JSON</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">If you are using the Ad Manager to create a Click to Messenger Ad, generate and copy the JSON for your product, collection or bot flow</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" router-link="/sales-channel/facebook/messenger-chat-plugin" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-fb-messenge-chat-plugin.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Messenger Chat Plugin</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Add the Facebook Messenger chat plugin to your website</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
          <div>
            <ion-title class="sub-title">Settings</ion-title>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/facebook/page-settings" style="padding-top: 10px">
                    <ion-thumbnail slot="start">
                      <img src="https://jumper.ai/web-s/resources/images/sc-fb-connected-page.svg" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Page Settings</h2>
                      <p class="cardSubtitle ion-no-padding ion-no-margin">Manage your Facebook account and Page connected to Jumper</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
              <!-- <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/facebook/shop">
                    <ion-thumbnail slot="start">
                      <img src="https://jumper.ai/web-s/resources/images/sc-facebook-shop.svg" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Facebook Shop</h2>
                      <p class="cardSubtitle ion-no-padding">Create your store on Facebook and Instagram. Choose how which products and collections you want to display on your Facebook Shop and connect with customers through Messenger and WhatsApp to offer shopping advise, take orders and more</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col> -->
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/facebook/persistent-menu">
                    <ion-thumbnail slot="start">
                      <img src="https://jumper.ai/web-s/resources/images/sc-fb-persistent-menu.svg" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Persistent Menu</h2>
                      <p class="cardSubtitle ion-no-padding">The persistent menu gives users quick access options. It is open by default in Messenger and can be minimised at any time.</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/facebook/ice-breakers">
                    <ion-thumbnail slot="start">
                      <img src="https://jumper.ai/web-s/resources/images/sc-ice-breakers.svg" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Ice Breakers</h2>
                      <p class="cardSubtitle ion-no-padding">Ice Breakers provide a way for users to start a conversation with a list of frequently asked questions.</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/facebook/notification-opt-in">
                    <ion-thumbnail slot="start">
                      <img src="https://jumper.ai/web-s/resources/images/sc-fb-create-an-ad.svg" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Opt-In Notifications</h2>
                      <p class="cardSubtitle ion-no-padding">Create and manage opt-in notifications to messages to your customers at any time within their subscription period</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
      </div>
      <div v-else>
        <ion-col>
          <ion-card>
            <ion-item lines="none">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/web-s/resources/images/sc-fb-connected-page.svg" />
              </ion-thumbnail>
              <ion-label>
                <h2>Connect Facebook Page</h2>
              </ion-label>
            </ion-item>
            <ion-list v-if="pages && pages.length">
              <ion-list-header>
                <ion-label>Select a page you want to manage with {{ dashboardName }}</ion-label>
              </ion-list-header>
              <ion-item v-for="thisPage in pages" :key="thisPage.id">
                <ion-label>{{ thisPage.name }} <h6>{{ thisPage.link }}</h6>
                </ion-label>
                <ion-button @click="connectPage(thisPage)">Connect</ion-button>
              </ion-item>
              <ion-item>
                <ion-label>Create new Page</ion-label>
                <ion-button
                  href="https://www.facebook.com/pages/creation/"
                  target="_blank"
                  fill="outline"
                >Create</ion-button>
              </ion-item>
            </ion-list>
            <ion-item v-else>
              <ion-label>Whoops! Seems like you don't have a Facebook page, let's create one now and then connect Messenger to rock and roll! 🙂</ion-label>
              <ion-button
                href="https://www.facebook.com/pages/creation/"
                target="_blank"
                fill="outline"
              >Create a new Facebook Page</ion-button>
            </ion-item>
          </ion-card>
          <p>Can't see your page? Try <a @click="fetchPages(true)">refresh</a> your list or <a href="https://docs.jumper.ai/article/cant-see-my-page-after-i-connect-facebook">get help here.</a></p>
          <ion-item>
            <ion-label>Disconnect Facebook?</ion-label>
            <ion-button
              @click="() => disconnectMode = true"
              fill="outline"
            >Disconnect</ion-button>
          </ion-item>
        </ion-col>
      </div>
    </ion-col>
    <div v-else>
      <ion-card class="container">
      <ion-card-header>
        <ion-item lines="none">
          <ion-thumbnail slot="start">
            <img src="https://jumper.ai/landing/resources/images/facebook.png" />
          </ion-thumbnail>
          <ion-label>
            <ion-card-title style="margin-bottom: 5px">Connect Facebook</ion-card-title>
            <ion-card-subtitle>Share posts, create ads, and manage comment replies on Facebook and support customers, sell products and run promotions with Messenger</ion-card-subtitle>
          </ion-label>
        </ion-item>
      </ion-card-header>

      <ion-card-content>
        <ion-button @click="connectFacebook" fill="outline" expand="block" class="bttn">Connect</ion-button>
        <p class="smallHintText">
          <span>By connecting your account, you agree to Facebook's </span>
          
          <a class="hoverUnderline" href="https://www.facebook.com/legal/commerce_product_merchant_agreement" target="_blank" rel="noreferer noopener">terms and conditions</a>. 
          <span>You need to follow Facebook's </span>
          
          <a class="hoverUnderline" href="https://www.facebook.com/legal/customer_support_policy" target="_blank" rel="noreferer noopener">customer support</a> 
          <span> and </span>
          
          <a class="hoverUnderline" href="https://www.facebook.com/payments_terms/commerce_salespolicy" target="_blank" rel="noreferer noopener">delivery &amp; return</a> 
          <span> policies when selling on Facebook.</span>
        </p>
      </ion-card-content>
    </ion-card>
    <div class="info-container">
      <ion-card-subtitle class="text">We'll need permissions to manage your Page's messages to enable live chat and automate your responses. You'll be redirected to Facebook, but don't worry you'll be back here after you have granted <span v-if="!IS_ASIA">Jumper.ai</span> all requested permissions.</ion-card-subtitle>
    </div>
    <ion-button target="_blank" href="https://jumper.ai/sales-channel/facebook-messenger-commerce" fill="outline" expand="block" class="learn-bttn">
      <ion-icon slot="start" size="small" :icon="helpOutline"></ion-icon>
        Learn more about Facebook & Messenger
      </ion-button>
    </div>
    </div>
    <ion-alert
      v-if="disconnectMode"
      :is-open="disconnectMode"
      header="Are you sure?"
      :message="disconnectMessage"
      :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => disconnectMode = false,
    },
    {
      text: 'Yes',
      handler: () => {
        confirmDisconnect()
      },
    }]"
      @didDismiss="() => disconnectMode = false"
    />
  </master-layout>
</template>

<script>
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { helpOutline } from "ionicons/icons";

export default {
  data() {
    return {
      loading: false,
      helpOutline,
      pages: [],
      disconnectMode: false,
    };
  },
  computed: {
    disconnectMessage() {
      return `disconnecting your Facebook account will automatically disconnect your store / messenger account. ${this.dashboardName} will not be able to message to your prospective buyers to help them through a seamless checkout. Confirm disconnect?`;
    }
  },
  async mounted() {
    if (
      this.$route.query &&
      this.$route.query.callback &&
      this.$route.query.callback === "connect"
    ) {
      this.loading = true;
      let access_token = undefined;
      if (this.$route.hash) {
        let e = this.$route.hash.toString();
        access_token = e.substring(
          e.indexOf("#access_token=") + 14,
          e.indexOf("&data_access_expiration_time=")
        );
        this.connectFacebookToJumper(access_token);
      }
    } else if (this.facebookConnected) this.fetchPages();
  },
  methods: {
    async connectFacebookToJumper(accessToken) {
      this.loading = true;
      try {
        let reqBody = {
          sociallogin: "facebook",
          accessToken: accessToken,
          seller: true,
        };
        let connectFacebookResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "login",
          reqBody
        );
        if (
          (connectFacebookResponse.status == 200 ||
            connectFacebookResponse.status == 201) &&
          connectFacebookResponse.data.success
        ) {
          this.updateToast({
            open: true,
            message: "Your Facebook Account is now connected!",
            type: "success",
          });
          setTimeout(() => {
            window.location.href = window.location.pathname;
          }, 1500);
        } else {
          if (
            connectFacebookResponse.data.errorMessage &&
            connectFacebookResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: connectFacebookResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    connectFacebook() {
      console.log("Connecting Facebook");
      let fbScopePermission = this.fbScopePermission;
      window.location.href =
        "https://www.facebook.com/dialog/oauth?client_id=" +
        this.environment("VUE_APP_FB_APP_ID") +
        "&auth_type=rerequest&display=popup&response_type=token&granted_scopes&scope=" +
        fbScopePermission +
        "&redirect_uri=" +
        this.environment("VUE_APP_FE") +
        "sales-channel/facebook?callback=connect";
    },
    async fetchPages(refresh) {
      this.loading = true;
      try {
        let getFacebookResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-facebook"
        );
        if (
          (getFacebookResponse.status == 200 ||
            getFacebookResponse.status == 201) &&
          getFacebookResponse.data.success
        ) {
          this.pages = getFacebookResponse.data.pages;
          if (refresh)
            this.updateToast({
              open: true,
              message: "Your facebook pages list is now up to date!",
              type: "success",
            });
        } else {
          if (
            getFacebookResponse.data.errorMessage &&
            getFacebookResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: getFacebookResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async confirmDisconnect() {
      this.loading = true;
      try {
        let disconnectFacebookResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "delete-social",
          { platform: "facebook" }
        );
        if (
          (disconnectFacebookResponse.status == 200 ||
            disconnectFacebookResponse.status == 201) &&
          disconnectFacebookResponse.data.success
        ) {
          if (disconnectFacebookResponse.data.delete)
            this.updateToast({
              open: true,
              message: "Facebook disconnected successfully",
              type: "success",
            });
          await this.Storage.remove({ key: "jmpr_fetchSocialData" });
          window.location.href = window.location.pathname;
        } else {
          if (
            disconnectFacebookResponse.data.errorMessage &&
            disconnectFacebookResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: disconnectFacebookResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async connectPage(page) {
      this.loading = true;
      try {
        let reqBody = {
          pageid: page.id,
          accesstoken: page.access_token,
          name: page.name,
          hamburger: false,
        };
        let connectPageResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "add-page",
          reqBody
        );
        console.log(connectPageResponse);
        if (
          (connectPageResponse.status == 200 ||
            connectPageResponse.status == 201) &&
          connectPageResponse.data.success
        ) {
          this.updateToast({
            open: true,
            message: "Facebook page connected successfully",
            type: "success",
          });
          await this.Storage.remove({ key: "jmpr_fetchSocialData" });
          window.location.href = window.location.pathname;
        } else {
          if (
            connectPageResponse.data.errorMessage &&
            connectPageResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: connectPageResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.sub-title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 4px;
}

.cardContainer {
  min-height: 98px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}

.container {
  border-radius: 10px;
  max-width: 700px;
  margin: 50px auto 20px auto;
  text-align: center;
}

ion-button.bttn{
  border-radius: 5px;
  --border-width: 0px;
}

.info-container {
  max-width: 700px;
  margin: 0px auto;
  text-align: center;
}
.info-container .text{
  color: var(--ion-color-grey);
  font-size: 12px;
}

.learn-bttn {
  max-width: 350px;
  margin: 20px auto;
  color: #777;
}

ion-button.learn-bttn {
  border-radius: 5px;
  --border-width: 0px;
  border: 1px solid #aaa;
}

.bttn {
  max-width: 300px;
  margin: auto;
  margin-bottom: 20px;
  background-color: #3d39b9;
  color: var(--ion-color-white);
}
.cardSubtitle{
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-black);
  margin-top: 4px;
}

@media only screen and (max-width: 760px) {
  .container{
    max-width: 100%;
    margin: 20px;
    text-align: center;
  }
  .info-container {
    max-width: 100%;
    margin: 20px;
    text-align: center;
}
}
</style>