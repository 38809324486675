var localeFullFormData = [
    {
        "id": "bn_BD",
        "source": "bn-BD",
        "name": "Bangla (Bangladesh)"
    },
    {
        "id": "bn_IN",
        "source": "bn-IN",
        "name": "Bangla (India)"
    },
    {
        "id": "zh_CN",
        "source": "zh-CN",
        "name": "Mainland China, simplified characters"
    },
    {
        "id": "zh_TW",
        "source": "zh-TW",
        "name": "Taiwan, traditional characters"
    },
    {
        "id": "zh_HK",
        "source": "zh-HK",
        "name": "Hong Kong, traditional characters"
    },
    {
        "id": "nl_BE",
        "source": "nl-BE",
        "name": "Belgian Dutch"
    },
    {
        "id": "nl_NL",
        "source": "nl-NL",
        "name": "Standard Dutch (as spoken in The Netherlands)"
    },
    {
        "id": "en_GB",
        "source": "en-GB",
        "name": "British English"
    },
    {
        "id": "en_US",
        "source": "en-US",
        "name": "American English"
    },
    {
        "id": "en_CA",
        "source": "en-CA",
        "name": "Canadian English"
    },
    {
        "id": "en_IN",
        "source": "en-IN",
        "name": "Indian English"
    },
    {
        "id": "en_AU",
        "source": "en-AU",
        "name": "Australian English"
    },
    {
        "id": "en_NZ",
        "source": "en-NZ",
        "name": "New Zealand English"
    },
    {
        "id": "fr_BE",
        "source": "fr-BE",
        "name": "Belgian French"
    },
    {
        "id": "fr_CH",
        "source": "fr-CH",
        "name": "Swiss French"
    },
    {
        "id": "fr_FR",
        "source": "fr-FR",
        "name": "Standard French (especially in France)"
    },
    {
        "id": "fr_CA",
        "source": "fr-CA",
        "name": "Canadian French"
    },
    {
        "id": "de_AT",
        "source": "de-AT",
        "name": "Austrian German"
    },
    {
        "id": "de_DE",
        "source": "de-DE",
        "name": "Standard German (as spoken in Germany)"
    },
    {
        "id": "de_CH",
        "source": "de-CH",
        "name": "Swiss German"
    },
    {
        "id": "it_CH",
        "source": "it-CH",
        "name": "Swiss Italian"
    },
    {
        "id": "it_IT",
        "source": "it-IT",
        "name": "Standard Italian (as spoken in Italy)"
    },
    {
        "id": "pt_PT",
        "source": "pt-PT",
        "name": "European Portuguese (as written and spoken in Portugal)"
    },
    {
        "id": "pt_BR",
        "source": "pt-BR",
        "name": "Brazilian Portuguese"
    },
    {
        "id": "es_ES",
        "source": "es-ES",
        "name": "Castilian Spanish (as spoken in Central-Northern Spain)"
    },
    {
        "id": "es_MX",
        "source": "es-MX",
        "name": "Mexican Spanish"
    },
    {
        "id": "es_AR",
        "source": "es-AR",
        "name": "Argentine Spanish"
    },
    {
        "id": "es_CO",
        "source": "es-CO",
        "name": "Colombian Spanish"
    },
    {
        "id": "es_CL",
        "source": "es-CL",
        "name": "Chilean Spanish"
    },
    {
        "id": "es_US",
        "source": "es-US",
        "name": "American Spanish"
    },
    {
        "id": "ta_IN",
        "source": "ta-IN",
        "name": "Indian Tamil"
    },
    {
        "id": "ta_LK",
        "source": "ta-LK",
        "name": "Sri Lankan Tamil"
    }
];

var localeFullFormData_whatsapp = [
    {
      "id": "af",
      "name": "Afrikaans"
    },
    {
      "id": "sq",
      "name": "Albanian"
    },
    {
      "id": "ar",
      "name": "Arabic"
    },
    {
      "id": "az",
      "name": "Azerbaijani"
    },
    {
      "id": "bn",
      "name": "Bengali"
    },
    {
      "id": "bg",
      "name": "Bulgarian"
    },
    {
      "id": "ca",
      "name": "Catalan"
    },
    {
      "id": "zh_CN",
      "name": "Chinese (CHN)"
    },
    {
      "id": "zh_HK",
      "name": "Chinese (HKG)"
    },
    {
      "id": "zh_TW",
      "name": "Chinese (TAI)"
    },
    {
      "id": "hr",
      "name": "Croatian"
    },
    {
      "id": "cs",
      "name": "Czech"
    },
    {
      "id": "da",
      "name": "Danish"
    },
    {
      "id": "nl",
      "name": "Dutch"
    },
    {
      "id": "en",
      "name": "English"
    },
    {
      "id": "en_GB",
      "name": "English (UK)"
    },
    {
      "id": "en_US",
      "name": "English (US)"
    },
    {
      "id": "et",
      "name": "Estonian"
    },
    {
      "id": "fil",
      "name": "Filipino"
    },
    {
      "id": "fi",
      "name": "Finnish"
    },
    {
      "id": "fr",
      "name": "French"
    },
    {
      "id": "de",
      "name": "German"
    },
    {
      "id": "el",
      "name": "Greek"
    },
    {
      "id": "gu",
      "name": "Gujarati"
    },
    {
      "id": "ha",
      "name": "Hausa"
    },
    {
      "id": "he",
      "name": "Hebrew"
    },
    {
      "id": "hi",
      "name": "Hindi"
    },
    {
      "id": "hu",
      "name": "Hungarian"
    },
    {
      "id": "id",
      "name": "Indonesian"
    },
    {
      "id": "ga",
      "name": "Irish"
    },
    {
      "id": "it",
      "name": "Italian"
    },
    {
      "id": "ja",
      "name": "Japanese"
    },
    {
      "id": "kn",
      "name": "Kannada"
    },
    {
      "id": "kk",
      "name": "Kazakh"
    },
    {
      "id": "ko",
      "name": "Korean"
    },
    {
      "id": "lo",
      "name": "Lao"
    },
    {
      "id": "lv",
      "name": "Latvian"
    },
    {
      "id": "lt",
      "name": "Lithuanian"
    },
    {
      "id": "mk",
      "name": "Macedonian"
    },
    {
      "id": "ms",
      "name": "Malay"
    },
    {
      "id": "ml",
      "name": "Malayalam"
    },
    {
      "id": "mr",
      "name": "Marathi"
    },
    {
      "id": "nb",
      "name": "Norwegian"
    },
    {
      "id": "fa",
      "name": "Persian"
    },
    {
      "id": "pl",
      "name": "Polish"
    },
    {
      "id": "pt_BR",
      "name": "Portuguese (BR)"
    },
    {
      "id": "pt_PT",
      "name": "Portuguese (POR)"
    },
    {
      "id": "pa",
      "name": "Punjabi"
    },
    {
      "id": "ro",
      "name": "Romanian"
    },
    {
      "id": "ru",
      "name": "Russian"
    },
    {
      "id": "sr",
      "name": "Serbian"
    },
    {
      "id": "sk",
      "name": "Slovak"
    },
    {
      "id": "sl",
      "name": "Slovenian"
    },
    {
      "id": "es",
      "name": "Spanish"
    },
    {
      "id": "es_AR",
      "name": "Spanish (ARG)"
    },
    {
      "id": "es_ES",
      "name": "Spanish (SPA)"
    },
    {
      "id": "es_MX",
      "name": "Spanish (MEX)"
    },
    {
      "id": "sw",
      "name": "Swahili"
    },
    {
      "id": "sv",
      "name": "Swedish"
    },
    {
      "id": "ta",
      "name": "Tamil"
    },
    {
      "id": "te",
      "name": "Telugu"
    },
    {
      "id": "th",
      "name": "Thai"
    },
    {
      "id": "tr",
      "name": "Turkish"
    },
    {
      "id": "uk",
      "name": "Ukrainian"
    },
    {
      "id": "ur",
      "name": "Urdu"
    },
    {
      "id": "uz",
      "name": "Uzbek"
    },
    {
      "id": "vi",
      "name": "Vietnamese"
    },
    {
      "id": "zu",
      "name": "Zulu"
    }
];

export default { localeFullFormData: localeFullFormData, localeFullFormData_whatsapp: localeFullFormData_whatsapp }