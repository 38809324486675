<template>
  <master-layout pageTitle="Facebook Page Settings">
    <ion-content v-if="facebookSocialData.pages && facebookSocialData.pages.length && facebookSocialData.pages[0].id" class="page-settings">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" :value="activeTab">
            <ion-segment-button value="connected-page" @click="changeTab('connected-page')">
              <ion-label>Connected Page</ion-label>
            </ion-segment-button>
            <ion-segment-button value="welcome-screen" @click="changeTab('welcome-screen')">
              <ion-label>Welcome Screen</ion-label>
            </ion-segment-button>
            <ion-segment-button value="whitelist" @click="changeTab('whitelist')">
              <ion-label>Whitelist</ion-label>
            </ion-segment-button>
            <ion-segment-button value="fb-pixel" @click="changeTab('fb-pixel')">
              <ion-label>Facebook Pixel</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="activeTab === 'connected-page'">
         <div class="model-sections">
          <ion-item lines="none" style="padding: 15px;">
            <ion-avatar slot="start" style="height: auto; width: 38px;">
              <img src="https://jumperdevnew.appspot.com/web-s/resources/images/sc-fb-connected-page.svg"/>
            </ion-avatar>
            <ion-label>
              <h3 class="title">Connected Facebook Page</h3>
              <p class="sub-title">Your shop and Messenger bot is connected to the below page.</p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-col class="ion-no-padding"  size-xl="8" size-lg="8" size-md="8" size-sm="7" size-xs="7">
              <ion-item lines="none">
                <ion-avatar slot="start" style="height: auto; width: 38px;">
                  <img :src="pageIconUrl"/>
                </ion-avatar>
                <ion-label>
                  <h3 class="title">{{ facebookSocialData.pages[0].name }}</h3>
                  <p class="sub-title">
                    <a target="_blank" :href="'https://' + facebookSocialData.pages[0].link" style="text-decoration: none">{{ facebookSocialData.pages[0].link }}</a>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="5" size-xs="5">
              <ion-button @click="() => disconnectPageMode = true" fill="outline" color="danger">Disconnect Page</ion-button>
            </ion-col>
          </ion-item>
         </div>
         <div class="footer">
           <h3 class="sub-title" style="margin-left:2px">Disconnect Facebook?</h3>
           <ion-button @click="() => disconnectMode = true" fill="outline" color="danger" size="small">Disconnect Facebook</ion-button>
         </div>
        </div>
        <div v-if="activeTab === 'welcome-screen'">
          <ion-grid>
            <ion-row>
              <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
                <div class="model-sections">
                  <div style="margin: 10px 0px 0px 10px;">
                    <ion-item lines="none">
                      <ion-avatar slot="start" style="height: auto; width: 38px;">
                        <img src="https://jumperdevnew.appspot.com/web-s/resources/images/sc-fb-welcome-screen.svg"/>
                      </ion-avatar>
                      <ion-label>
                        <h3 class="title">Welcome Screen</h3>
                        <p class="sub-title">Customize the welcome screen for your customers see when they interact with your Page messenger for the first time.</p>
                      </ion-label>
                    </ion-item>
                  </div>
                  <div class="inputText">
                    <ion-label>
                      <h3 class="title">Greeting Message</h3>
                      <p class="sub-title">Add a greeting message to welcome your users. You can personalize the message using dynamic tags for person's name.</p>
                    </ion-label>
                  </div>
                  <div class="textArea">
                    <ion-item lines="none">
                      <my-input
                        textarea
                        id="template_body" 
                        emoji
                        name="post-text"
                        :value="menu.get_started.payload"
                        @onInput="(prop) => menu.get_started.payload = prop" 
                        placeholder="Enter a Greeting"
                        :maxlength="500"
                        :rows="4"
                        :textAreaAutoHeight="true"
                        :textAreaResizable="false"
                        :animateEmojiContainer="true"
                        :iconStyle="{color: 'var(--ion-color-step-300)'}"
                      />
                    </ion-item>
                  </div>
                   <div class="inputText">
                    <ion-label>
                      <h3 class="title">Get Started Button</h3>
                      <p class="sub-title">Select the flow you want to trigger after a user clicks the Get Started button.</p>
                    </ion-label>
                  </div>
                  <div class="slctBox">
                    <ion-select v-model="startButton" placeholder="Select get started button">
                      <ion-select-option disabled :value="undefined">Select get started button</ion-select-option>
                      <ion-select-option value="productCheckout">Place a product order</ion-select-option>
                      <ion-select-option value="collectionCheckout">Browse Collection</ion-select-option>
                      <ion-select-option value="storeDetails">Browse Store Details</ion-select-option>
                      <!-- <ion-select-option value="placeOrder">Place order</ion-select-option>
                      <ion-select-option value="makeReservation">Make a reservation</ion-select-option>
                      <ion-select-option value="restaurantDetails">Show restaurant details</ion-select-option>
                      <ion-select-option value="bookEvent">Book an Event</ion-select-option> -->
                      <ion-select-option value="customBot">Start a bot</ion-select-option>
                      <ion-select-option value="talkToAgent">Ping live chat</ion-select-option>
                      <ion-select-option value="agent_or_group">Ping live chat (assign to specific agent / group)</ion-select-option>
                    </ion-select>
                  </div>
                  <div v-if="startButton === 'productCheckout' || startButton === 'collectionCheckout' || startButton === 'customBot' || startButton === 'agent_or_group'">
                    <ion-item lines="none">
                      <ion-label class="opt-title">Select {{ toBeSelected }}</ion-label>
                      <ion-buttons slot="end">
                        <ion-button v-if="selectedItem === undefined || selectedItem.id === undefined" @click="() => selectItemMode = true">
                          <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                        </ion-button>
                        <ion-button v-else @click="() => selectItemMode = true" >
                          <ion-icon :icon="createOutline"></ion-icon> Edit Selection
                        </ion-button>
                      </ion-buttons>
                    </ion-item>
                    <div>
                      <ion-item v-if="(startButton === 'productCheckout' || startButton === 'collectionCheckout') && selectedItem && selectedItem.id" lines="none">
                        <ion-row>
                          <ion-item lines="none">
                            <img width="100" :src="selectedItem.image" class="imgPlaceholder"/>
                             <div style="display: inline-block; padding-left: 10px;">
                                <h2 class="catgory-title ion-no-padding ion-no-margin">{{selectedItem.name }}</h2>
                                <h2 class="common-label ion-no-padding ion-no-margin">{{selectedItem.hashtag }}</h2>
                             </div>
                          </ion-item>
                        </ion-row>
                      </ion-item>
                      <ion-item v-if="startButton === 'customBot' && selectedItem && selectedItem.id"  lines="none">
                        <ion-row>
                          <ion-item lines="none">
                             <div style="display: inline-block; padding-left: 10px;">
                                <h2 class="catgory-title ion-no-padding ion-no-margin">{{selectedItem.displayname }}</h2>
                                <h2 class="common-label ion-no-padding ion-no-margin">{{selectedItem.link }}</h2>
                             </div>
                          </ion-item>
                        </ion-row>
                      </ion-item>
                      <ion-item v-if="startButton === 'storeDetails'" lines="none">
                        <ion-row>
                          <ion-item lines="none">
                            You can view and edit your store detail cards <a router-link="/automations/personalization">here</a>.
                          </ion-item>
                        </ion-row>
                      </ion-item>
                      <ion-item v-if="startButton === 'agent_or_group' && selectedItem && selectedItem.id">
                        <ion-row>
                          <ion-item lines="none">
                            <div>Start Live Chat</div>
                            <div v-if="isAgentOrGroup === 'agent'">{{ selectedItem.name }}</div>
                            <div v-if="isAgentOrGroup === 'group'">Group name: {{ selectedItem.name }}</div>
                          </ion-item>
                        </ion-row>
                      </ion-item>
                    </div>
                  </div>
                  <ion-toolbar>
                    <ion-buttons slot="end">
                      <ion-button @click="() => { setShortDescription(); selectedItem && selectedItem.id ? setGetStarted() : ''; }" class="bttn" fill="solid" color="dark" size="default">Save and Publish</ion-button>
                    </ion-buttons>
                  </ion-toolbar>
                </div>
              </ion-col>
              <!-- preview -->
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-if="activeTab === 'whitelist'">
          <div class="model-sections">
            <ion-item lines="none" style="padding: 15px;">
              <ion-avatar slot="start" style="height: auto; width: 38px;">
                <img src="https://jumperdevnew.appspot.com/web-s/resources/images/sc-fb-connected-page.svg"/>
              </ion-avatar>
              <ion-label>
                <h3 class="title">Whitelisted domains</h3>
                <p class="sub-title">Messenger allows domains that are whitelisted for your page to be opened in the webview. Add your domain(s) your below. (grey out the box and text for the domains that are default)</p>
              </ion-label>
            </ion-item>
            <ion-item v-for="(thisDomain, dIndex) in whitelisted_domains" :key="dIndex" lines="none" style="padding-left: 15px">
              <h2 class="domain-title ion-no-margin"> Domain {{ dIndex + 1 }} </h2>
              <ion-input v-model="thisDomain.domain" :disabled="jumperDomain.indexOf(thisDomain.domain) > -1" type="text" placeholder="Enter Domain" class="fullBordered input_custom_style"></ion-input>
            </ion-item>
            <ion-row class="ion-no=padding" style="padding-left: 25px">
              <ion-col class="ion-no=padding">
                <ion-label class="sub-title">Add domain for whitelisting:</ion-label>
                <div>
                  <ion-button @click="() => whitelisted_domains.push({ domain: undefined })" fill="outline" color="dark" size="default">
                      <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                  </ion-button>
                </div>
              </ion-col>
              <ion-toolbar>
                <ion-buttons slot="end">
                  <ion-button class="bttn" fill="solid" color="dark" size="default" @click="setWhiteList">Save</ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-row>
          </div>
        </div>
        <div v-if="activeTab === 'fb-pixel'">
          <div class="model-sections">
            <ion-item lines="none" style="padding: 15px;">
              <ion-avatar slot="start" style="height: auto; width: 38px;">
                <img src="https://jumperdevnew.appspot.com/web-s/resources/images/facebook-pixel.png"/>
              </ion-avatar>
              <ion-row>
                <ion-col size-xl="10" size-lg="10" size-md="10" size-sm="8" size-xs="8">
                  <ion-label>
                    <h3 class="title">Facebook Pixel</h3>
                    <p class="sub-title">Facebook Pixel helps you create ad campaigns to find new customers that look most like your buyers</p>
                  </ion-label>
                </ion-col>
                <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="4" size-xs="4">
                <ion-label>
                 <ion-buttons slot="end" v-if="tools.fbpx && tools.fbpx.length && tools.fbpx[0].pixel_id">
                  <ion-button @click="() => deletePixelMode = true">
                    <ion-icon slot="icon-only" :icon="trashBinOutline" color="danger"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-label>
                </ion-col>
              </ion-row>
            </ion-item>
            <div style="padding: 0px 20px 0px 35px">
              <h3 class="sub-title ion-no-margin" style="margin-bottom: 5px">Your Facebook Pixel ID:</h3>
              <ion-input v-model="pixel_id" type="text" placeholder="Paste your Facebook Pixel ID here" class="fullBordered input_custom_style"></ion-input>
              <h6 class="sub-title ion-no-margin" style="margin-top: 5px">To replace the current Pixel with a new one, update your Pixel ID above.</h6>
            </div>
             <ion-toolbar>
              <ion-buttons slot="end">
                 <ion-button class="bttn" fill="solid" color="dark" size="default" @click="setFBPixel">Save</ion-button>
              </ion-buttons>
            </ion-toolbar>        
         </div>
        </div>
      </div>
    </ion-content>
    <ion-alert
      v-if="disconnectMode"
      :is-open="disconnectMode"
      header="Are you sure?"
      :message="fbDisconnectMsg"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disconnectMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnect()
        },
      }]"
      @didDismiss="() => disconnectMode = false"
    />
    <ion-alert
      v-if="disconnectPageMode"
      :is-open="disconnectPageMode"
      header="Are you sure?"
      :message="pageDisconnectMsg"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disconnectPageMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnectPage()
        },
      }]"
      @didDismiss="() => disconnectPageMode = false"
    />
    <ion-action-sheet
			:is-open="openInsertParamSheet"
			header="Insert Parameter"
			:buttons="paramsOptions"
			@didDismiss="() => openInsertParamSheet = false"
		>
		</ion-action-sheet>
    <ion-modal
      v-if="selectItemMode"
      :is-open="selectItemMode"
      @didDismiss="() => selectItemMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin custom_mrg_left">Select Item</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => selectItemMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
           <div v-if="startButton === 'productCheckout' || startButton === 'collectionCheckout' || startButton === 'customBot'" class="searchInput">
            <ion-input v-model="itemFilter" type="text" placeholder="Enter product name" class="fullBordered cust_input_hght input-width"></ion-input>
          </div>
          <ion-item v-if="startButton === 'agent_or_group'" class="slctOptions" lines="none">
            <ion-label>Select an option</ion-label>
            <ion-select v-model="itemFilter" placeholder="Select group" class="slctContainer">
              <ion-select-option v-for="thisRole in allRoles" :key="thisRole.id" :value="thisRole.name">{{ thisRole.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <h5 class="ion-no-margin" style="margin-left: 20px">Select {{ toBeSelected }}</h5>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row v-for="product in itemsToShow" :key="product.id" class="rowMargin">
              <ion-col  v-if="startButton === 'productCheckout' || startButton === 'collectionCheckout'" size="2">
                <img width="50" v-if="product.images && product.images.length" :src="product.images[0]" />
              </ion-col>
              <ion-col  v-if="startButton === 'productCheckout' || startButton === 'collectionCheckout'" size="6" class="ion-align-self-center">
                <h2 class="orderDesc ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
                <h2 class="common-label ion-no-margin custom_mrg_left">{{ product.hashtag }}</h2>
              </ion-col>
              <ion-col  v-if="startButton === 'productCheckout' || startButton === 'collectionCheckout'"  size="4">
                  <ion-radio-group v-model="selectedItemId">
                    <ion-radio slot="start" :value="product.id"></ion-radio> 
                  </ion-radio-group>
              </ion-col>
              <ion-col v-if="startButton === 'customBot'" class="ion-align-self-center">
                <h2 class="orderDesc ion-no-margin custom_mrg_left"> {{ product.displayname }} </h2>
                <h2 class="common-label ion-no-margin custom_mrg_left">{{ product.link }}</h2>
              </ion-col>
              <ion-col v-if="startButton === 'agent_or_group'">
                  <h2 class="orderDesc ion-no-margin custom_mrg_left"> {{ product.name }} </h2>
              </ion-col>
               <ion-col v-if="startButton === 'agent_or_group' && itemFilter && itemFilter.length">
                  <ion-label> Assign to Group: {{ itemFilter }}</ion-label>
                  <h2 class="common-label ion-no-margin custom_mrg_left"> The conversation will get assigned to one of the agents in the group </h2>
                  <ion-radio slot="start" :value="allRoles.find(e => e.name === itemFilter).id"></ion-radio>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="() => selectItemMode = false" color="dark">Add</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="deletePixelMode"
      :is-open="deletePixelMode"
      header="Are you sure?"
      :message="'You want to delete Facebook Pixel?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => deletePixelMode = false
      },
      {
        text: 'Yes',
        handler: () => disconnectFBPixel()
      }]"
      @didDismiss="() => deletePixelMode = false"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, createOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSelect, IonSelectOption, IonRadioGroup, IonRadio } from "@ionic/vue";
import { mapGetters } from "vuex";

export default {
  components: { IonSelect, IonSelectOption, IonRadioGroup, IonRadio },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      createOutline,
      close,
      activeTab: 'connected-page',
      disconnectMode: false,
      disconnectPageMode: false,
      pageIconUrl: null,
      menu: {
        composer_disabled: false,
        cta: [],
        get_started: {
          payload: undefined
        },
        hamburger: false,
        subscription: undefined,
        third_menu: false,
        whitelisted_domains: []
      },
      jumperDomain: ['https://jumperdevnew.appspot.com/', 'https://lh3.googleusercontent.com/', 'https://dev.jumperdevnew.appspot.com/'],
      openInsertParamSheet: false,
      paramsOptions: [
        {
          text: "User's First Name",
          handler: () => {
						this.menu.get_started.payload += "{User: First Name}";
          }
        },
        {
          text: "User's Last Name",
          handler: () => {
						this.menu.get_started.payload += "{User: Last Name}";
          }
        },
        {
          text: "User's Full Name",
          handler: () => {
						this.menu.get_started.payload += "{User: Full Name}";
          }
        }
			],
      startButton: undefined,
      selectItemMode: false,
      itemFilter: undefined,
      allProducts: [],
      allCollections: [],
      allBots: [],
      allRoles: [],
      allAgents: [],
      selectedItemId: undefined,
      whitelisted_domains: [],
      welcomeScreenInitialized: false,
      whitelistInitialized: false,
      pixel_id: undefined,
      tools: {
        fbpx: [],
        in_context_sdk: [],
        messenger_sdk: [],
        web_sdk: []
      },
      pixelInitialized: false,
      deletePixelMode: false
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.changeTab('connected-page');
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    ...mapGetters({
      userData: "user/getUser"
    }),
    referallshopName() {
      return this.userData.referallshopName && this.userData.referallshopName.length ? this.userData.referallshopName : undefined;
    },
    shopdescription() {
      return this.userData.shopdescription && this.userData.shopdescription.length ? this.userData.shopdescription : undefined;
    },
    toBeSelected() {
      switch(this.startButton) {
        case 'productCheckout': return 'Product';
        case 'collectionCheckout': return 'Collection';
        case 'customBot': return 'Bot';
        case 'agent_or_group': return 'Agent or Group';
        default: return undefined;
      }
    },
    itemsToShow() {
      switch(this.startButton) {
        case 'productCheckout': return this.itemFilter && this.itemFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.itemFilter, 'gi'))) : this.allProducts;
        case 'collectionCheckout': return this.itemFilter && this.itemFilter.length ? this.allCollections.filter(e => e.name.match(new RegExp(this.itemFilter, 'gi'))) : this.allCollections;
        case 'customBot': return this.itemFilter && this.itemFilter.length ? this.allBots.filter(e => e.displayname.match(new RegExp(this.itemFilter, 'gi'))) : this.allBots;
        case 'agent_or_group': return this.itemFilter && this.itemFilter.length ? this.allAgents.filter(e => e.role === this.itemFilter) : [];
        default: return []
      }
    },
    selectedItem() {
      switch(this.startButton) {
        case 'productCheckout': 
        case 'collectionCheckout': 
        case 'customBot': return this.itemsToShow.find(e => e.id === this.selectedItemId);
        case 'agent_or_group': {
          let thisAgent = this.allAgents.find(e => e.id === this.selectedItemId);
          let thisRole = this.allRoles.find(e => e.id === this.selectedItemId);
          if(thisAgent) return thisAgent; else if(thisRole) return thisRole; else return undefined;
        }
        default: return undefined;
      }
    },
    isAgentOrGroup() {
      if(this.selectedItemId) {
        let thisAgent = this.allAgents.find(e => e.id === this.selectedItemId);
        let thisRole = this.allRoles.find(e => e.id === this.selectedItemId);
        if(thisAgent) return 'agent'; else if(thisRole) return 'group'; else return 'none';
      } else return 'none';
    },
    fbDisconnectMsg() {
      return `disconnecting your Facebook account will automatically disconnect your store / messenger account. ${this.dashboardName} will not be able to message to your prospective buyers to help them through a seamless checkout. Confirm disconnect?`;
    },
    pageDisconnectMsg() {
      return `${this.dashboardName} will not be able to converse and help your customers check-out with their order if they comment on your posts with your product #hashtags. Are you sure you want to disconnect your Messenger account?`;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
     initData() {
      this.pageName = this.facebookSocialData?.pages[0]?.name;
      this.pageIconUrl = `https://graph.facebook.com/${this.facebookSocialData?.pages[0]?.messenger}/picture?type=normal`;
      this.pageUrl = this.facebookSocialData?.pages[0]?.link;
      if (this.pageUrl && !this.pageUrl.includes("http")) {
        this.pageUrl = `https://${this.pageUrl}`;
      }
    },
    async doRefresh(event) {
      this.whitelistInitialized = false;
      this.welcomeScreenInitialized = false;
      this.changeTab(this.activeTab);
      event.target.complete();
    },
    async confirmDisconnect() {
      this.loading = true;
      try {
        let disconnectFacebookResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-social", { platform: 'facebook' });
        if ((disconnectFacebookResponse.status == 200 || disconnectFacebookResponse.status == 201) && disconnectFacebookResponse.data.success) {
          if(disconnectFacebookResponse.data.delete) this.updateToast({
            open: true,
            message: "Facebook disconnected successfully",
            type: "success"
          })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(disconnectFacebookResponse.data.errorMessage && disconnectFacebookResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: disconnectFacebookResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async confirmDisconnectPage() {
      this.loading = true;
      try {
        let disconnectFacebookResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-social", { platform: 'messenger' });
        if ((disconnectFacebookResponse.status == 200 || disconnectFacebookResponse.status == 201) && disconnectFacebookResponse.data.success) {
          if(disconnectFacebookResponse.data.delete) this.updateToast({
            open: true,
            message: "Facebook page disconnected successfully",
            type: "success"
          })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(disconnectFacebookResponse.data.errorMessage && disconnectFacebookResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: disconnectFacebookResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchMenu() {
      this.loading = true;
      try {
        if(this.activeTab === 'welcome-screen' || this.activeTab === 'whitelist') {
          let fetchMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-menu", { pageid: this.facebookSocialData.pages[0].messenger });
          if ((fetchMenuResponse.status == 200 || fetchMenuResponse.status == 201) && fetchMenuResponse.data.success) {
            for(var prop in this.menu) this.menu[prop] = fetchMenuResponse.data[prop];
            if(this.activeTab === 'welcome-screen') {
              let payload = this.menu.get_started.payload;
              if(payload.includes('7@1k704um@9 ___')) {this.startButton = 'agent_or_group'; this.selectedItemId = payload.replace('7@1k704um@9 ___', ''); this.isAgentOrGroup === 'agent' ? this.itemFilter = this.selectedItem.role : this.itemFilter = this.selectedItem.name;}
              else if(payload.includes('7@1k704um@9')) {this.startButton = 'talkToAgent'; this.selectedItemId = undefined;}
              else {
                if(payload.includes('#')) {
                  let thisProduct = this.allProducts.find(e => payload.includes(e.hashtag));
                  let thisCollection = this.allCollections.find(e => payload.includes(e.hashtag));
                  if(thisProduct) {this.startButton = 'productCheckout'; this.selectedItemId = thisProduct.id;}
                  else if(thisCollection) {this.startButton = 'collectionCheckout'; this.selectedItemId = thisCollection.id;}
                  else if(payload.includes(this.referallshopName)) {this.startButton = 'storeDetails'; this.selectedItemId = undefined;}
                } else {
                  let thisBot = this.allBots.find(e => payload.includes(e.link));
                  if(payload.includes('bot') && thisBot) {this.startButton = 'customBot'; this.selectedItemId = thisBot.id;}
                  else {this.startButton = undefined; this.selectedItemId = undefined;}
                }
              }
              if(this.shopdescription) this.menu.get_started.payload = this.shopdescription;
              if(this.menu.get_started.payload.includes("{{user_first_name}}")) this.menu.get_started.payload = this.menu.get_started.payload.replace("{{user_first_name}}", "{User: First Name}");
              if(this.menu.get_started.payload.includes("{{user_last_name}}")) this.menu.get_started.payload = this.menu.get_started.payload.replace("{{user_last_name}}", "{User: Last Name}");
              if(this.menu.get_started.payload.includes("{{user_full_name}}")) this.menu.get_started.payload = this.menu.get_started.payload.replace("{{user_full_name}}", "{User: Full Name}");
            } else {
              this.whitelisted_domains = [];
              if(this.menu.whitelisted_domains && this.menu.whitelisted_domains.length) this.menu.whitelisted_domains.forEach(thisDomain => this.whitelisted_domains.push({ domain: thisDomain }));
              this.whitelisted_domains.push({ domain: undefined });
            }
          } else {
            if(fetchMenuResponse.data && !fetchMenuResponse.data.success && fetchMenuResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: fetchMenuResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } else {
          console.log("Active Tab: ", this.activeTab);
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async changeTab(newTab) {
      this.activeTab = newTab;
      switch(this.activeTab) {
        case 'welcome-screen': (!this.welcomeScreenInitialized && await Promise.all([this.fetchProducts(),
        this.fetchBots(), this.fetchAgents()]) && this.fetchMenu()); this.welcomeScreenInitialized = true;
        break;
        case 'whitelist': !this.whitelistInitialized && this.fetchMenu(); this.whitelistInitialized = true;
        break;
        case 'fb-pixel': !this.pixelInitialized && this.fetchGrowthTools(); this.pixelInitialized = true;
        break;
      }
    },
    async fetchProducts() {
      this.loading = true;
      try {
        let allProductsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((allProductsResponse.status == 200 || allProductsResponse.status == 201) && allProductsResponse.data.success && allProductsResponse.data.products && allProductsResponse.data.products.length) {
          this.allProducts = allProductsResponse.data.products.filter(e => !e.bundle && !e.catalog);
          this.allProducts.forEach(p => p.selected = false);
          this.allCollections = allProductsResponse.data.products.filter(e => !e.bundle && e.catalog);
          this.allCollections.forEach(p => p.selected = false);
        } else {
          if(allProductsResponse.data && !allProductsResponse.data.success && allProductsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allProductsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchBots() {
      this.loading = true;
      try {
        let fetchActionResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-action");
        if ((fetchActionResponse.status == 200 || fetchActionResponse.status == 201) && fetchActionResponse.data.success) {
          if(fetchActionResponse.data.actions && fetchActionResponse.data.actions.length) this.allBots = fetchActionResponse.data.actions.filter(e => e.active);
          this.allBots.forEach(p => p.selected = false);
        } else {
          if(fetchActionResponse.data && !fetchActionResponse.data.success && fetchActionResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchActionResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchAgents() {
      this.loading = true;
      try {
        let businessRolesResponse = undefined;
        let agentsResponse = undefined;
        businessRolesResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");
        agentsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-list");
        let allPromises = await Promise.all([businessRolesResponse, agentsResponse]);
        businessRolesResponse = allPromises[0];
        agentsResponse = allPromises[1];
        if ((businessRolesResponse.status == 200 || businessRolesResponse.status == 201) && businessRolesResponse.data.success) {
          this.allRoles = businessRolesResponse.data.business_roles;
        } else {
          if(businessRolesResponse.data && !businessRolesResponse.data.success && businessRolesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: businessRolesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
        if ((agentsResponse.status == 200 || agentsResponse.status == 201) && agentsResponse.data.success) {
          this.allAgents = agentsResponse.data.roles;
        } else {
          if(agentsResponse.data && !agentsResponse.data.success && agentsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: agentsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchGrowthTools() {
      this.loading = true;
      try {
        let fetchGrowthToolsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-growthtools");
        if ((fetchGrowthToolsResponse.status == 200 || fetchGrowthToolsResponse.status == 201) && fetchGrowthToolsResponse.data.success) {
          for(var prop in this.tools) this.tools[prop] = fetchGrowthToolsResponse.data[prop];
          this.pixel_id = this.tools.fbpx && this.tools.fbpx.length ? this.tools.fbpx[0].pixel_id : undefined;
        } else {
          if(fetchGrowthToolsResponse.data && !fetchGrowthToolsResponse.data.success && fetchGrowthToolsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchGrowthToolsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async setShortDescription() {
      this.loading = true;
      try {
        let text = this.menu.get_started.payload;
        if(this.menu.get_started.payload.includes("{User: First Name}")) text = this.menu.get_started.payload.replace("{User: First Name}", "{{user_first_name}}",);
        if(this.menu.get_started.payload.includes("{User: Last Name}")) text = this.menu.get_started.payload.replace("{User: Last Name}", "{{user_last_name}}");
        if(this.menu.get_started.payload.includes("{User: Full Name}")) text = this.menu.get_started.payload.replace("{User: Full Name}", "{{user_full_name}}");
        let reqBody = {
          text: text
        }
        let setShortDescriptionResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "shop-description", reqBody);
        if ((setShortDescriptionResponse.status == 200 || setShortDescriptionResponse.status == 201) && setShortDescriptionResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Greeting message updated successfully!",
            type: "success"
          })
        } else {
          if(setShortDescriptionResponse.data && !setShortDescriptionResponse.data.success && setShortDescriptionResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: setShortDescriptionResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async setGetStarted() {
      this.loading = true;
      try {
        let payload = undefined;
        switch(this.startButton) {
          case 'productCheckout': payload = this.selectedItemId ? ('#' + this.selectedItem.hashtag) : undefined;
          break;
          case 'collectionCheckout': payload = this.selectedItemId ? ('#' + this.selectedItem.hashtag) : undefined;
          break;
          case 'customBot': payload = this.selectedItemId ? this.selectedItem.link : undefined;
          break;
          case 'storeDetails': payload = this.referallshopName ? ('#@' + this.referallshopName) : undefined;
          break;
          case 'talkToAgent': payload = '7@1k704um@9';
          break;
          case 'agent_or_group': payload = this.selectedItemId ? ('7@1k704um@9 ___' + this.selectedItem.id) : undefined;
          break;
        }
        let reqBody = {
          pageid: this.facebookSocialData.pages[0].messenger,
          get_started_payload: payload
        }
        let setGetStartedResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "set-get-started", reqBody);
        if ((setGetStartedResponse.status == 200 || setGetStartedResponse.status == 201) && setGetStartedResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Get started action updated successfully!",
            type: "success"
          })
        } else {
          if(setGetStartedResponse.data && !setGetStartedResponse.data.success && setGetStartedResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: setGetStartedResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async setWhiteList() {
      this.loading = true;
      try {
        let reqBody = {
          domain_list: JSON.stringify(this.whitelisted_domains.map(e => e.domain)),
          pageid: this.facebookSocialData.pages[0].messenger
        }
        let setWhiteListResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "set-whitelist", reqBody);
        if ((setWhiteListResponse.status == 200 || setWhiteListResponse.status == 201) && setWhiteListResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Whitelist updated successfully!",
            type: "success"
          })
        } else {
          if(setWhiteListResponse.data && !setWhiteListResponse.data.success && setWhiteListResponse.data.errorMessage && setWhiteListResponse.data.errorMessage.message) {
            this.updateToast({
              open: true,
              message: setWhiteListResponse.data.errorMessage.message,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async disconnectFBPixel() {
      this.loading = true;
      this.deletePixelMode = false;
      try {
        let disconnectFBPixelResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-fbpixel");
        if ((disconnectFBPixelResponse.status == 200 || disconnectFBPixelResponse.status == 201) && disconnectFBPixelResponse.data.success && disconnectFBPixelResponse.data.delete) {
          this.updateToast({
            open: true,
            message: "FB Pixel disconnected successfully!",
            type: "success"
          })
          this.fetchGrowthTools();
        } else {
          if(disconnectFBPixelResponse.data && !disconnectFBPixelResponse.data.success && disconnectFBPixelResponse.data.errorMessage && disconnectFBPixelResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: disconnectFBPixelResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async setFBPixel() {
      this.loading = true;
      try {
        let reqBody = {
          pixel_id: this.pixel_id
        }
        let setFBPixelResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "create-fbpixel", reqBody);
        if ((setFBPixelResponse.status == 200 || setFBPixelResponse.status == 201) && setFBPixelResponse.data.success) {
          this.tools.fbpx && this.tools.fbpx.length ? this.tools.fbpx[0].pixel_id = setFBPixelResponse.data.pixel_id : this.tools.fbpx = [{ pixel_id: setFBPixelResponse.data.pixel_id }];
          this.pixel_id = setFBPixelResponse.data.pixel_id;
          this.updateToast({
            open: true,
            message: "FB Pixel updated successfully!",
            type: "success"
          })
        } else {
          if(setFBPixelResponse.data && !setFBPixelResponse.data.success && setFBPixelResponse.data.errorMessage && setFBPixelResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: setFBPixelResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>


<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.title {
  font-size: 18px;
  color: var(--ion-color-black);
  font-weight: 600;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.domain-title{
  font-size: 14px;
  color: var(--ion-color-black);
  margin-right: 10px;
}

.bttn {
  margin: 15px 25px 0px 35px;
}
.card_container_grid {
  margin: 20px;
}

.searchInput {
  padding: 15px;
}
.jinput {
  width: 98%;
  background: transparent;
}

.opt-title {
  margin-left: 10px;
}

.page-settings ::v-deep(.jinput textarea) {
    padding-right: 20px;
    border-top-right-radius: 0px !important; 
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;  
    border-bottom: 1px solid gray !important;
}
.page-settings ::v-deep(.add-emoji) {
  right: 3px;
  top: 3px;
}
.page-settings ::v-deep(.jinput) {
  background: transparent;
  padding: 0;
  width: 100%;
  margin-bottom: 10px;
}
.catgory-title {
  font-size: 18px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-bottom: 7px;
}
.imgPlaceholder {
  border-radius: 5px;
  width: 80px;
}

.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}

.footer{
  margin: 10px 0px 0px 10px;
}

.slctOptions {
  margin: 10px;
  position: sticky; 
  top: 0; 
  background-color: var(--ion-color-white);
}

.inputText{
  margin: 0px 0px 20px 30px;
}
@media only screen and (max-width: 760px) {
  .textArea {
    max-width: 100%;
    margin: 0px 15px 20px 20px;
}
}
@media only screen and (min-width: 760px) {
.textArea {
  margin: 0px 20px 20px 12px;
 }
}
.slctBox {
  border: 1px solid #666666;
  margin: 0px 30px 0px 30px;
}
</style>