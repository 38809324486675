<template>
  <ion-content class="whiteBg login-page-wrapper" :class="{ darkModeEnabled: isDarkMode }">
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    >
    </ion-loading>
    <div v-else>
      <ion-grid class="pad_zero" v-if="!halfwayLogin" style="min-height: 100vh;">
        <ion-row>
          <ion-col class="ion-no-padding" size-xl="4" size-lg="4" size-md="6" size-sm="12" size-xs="12">
            <form @submit.prevent="login">
              <ion-list class="height100">
                <ion-list-header>
                  <div v-if="!IS_ASIA" class="width100P text-center">
                    <img v-if="isDarkMode" src="../../resources/images/login-page-logo-dark.svg" style="height: 30px;" />
                    <img v-else src="../../resources/images/login-page-logo.svg" style="height: 30px;" />
                  </div>
                </ion-list-header>
                <div class="form-wrapper" lines="none">
                  <div class="form-container">
                    <div class="frm-header">
                      <h1>Sign in</h1>
                      <h2>Please enter your details</h2>
                    </div>
                    <ion-input @keyup.enter="login" name="username" type="text" class="fullBordered" v-model="userInfo.username" placeholder="Username"></ion-input>
                    <ion-input @keyup.enter="login" name="password" type="password" class="fullBordered" v-model="userInfo.password" placeholder="Password"></ion-input>
                    <ion-button color="primary" type="submit" size="default">Login</ion-button>
                  </div>
                </div>
              </ion-list>
            </form>
          </ion-col>
          <ion-col class="ion-no-padding bg_grey height100 ion-hide-md-down" size-xl="8" size-lg="8" size-md="6" size-sm="12" size-xs="12">
            <div class="width100P text-center">
              <ImageSlider>
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-1.svg" />
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-2.svg" />
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-3.svg" />
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-4.svg" />
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-5.svg" />
                <img class="loginIllustration_onTheRight" src="../../resources/images/login-img-6.svg" />
              </ImageSlider>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="bg_grey" style="min-height: 100vh;" v-else>
        <ion-toolbar v-if="!IS_ASIA" class="chat-toolbar" color="dark">
          <ion-avatar slot="start">
            <img src="https://jumper.ai/landing/resources/images/jumper-logo-256x256.png" style="padding: 10px;">
          </ion-avatar>
          <h2 class="ion-no-margin">Select an account</h2>
        </ion-toolbar>
        <ion-row>
          <ion-col v-if="accounts.length" size-lg="6" offset-lg="3" size-md="6" offset-md="3">
            <ion-card v-for="(thisAccount, aIndex) in accounts" :key="aIndex">
              <ion-item lines="none">
                <ion-avatar slot="start" class="img-container">
                  <img src="https://jumper.ai/web-s/resources/images/placeholder-product.svg" />
                </ion-avatar>
                <ion-label>
                  <ion-text slot="start" color="dark">
                    <h2>{{ thisAccount.shop_name }}</h2>
                  </ion-text>
                  <ion-text color="medium" v-if="thisAccount.shop_link">https://<span>{{ thisAccount.shop_link }}</span>.{{ HOST_NAME }}</ion-text>
                </ion-label>
              </ion-item>
              <ion-button color="light" @click="chooseLoginAccount(aIndex)">Get in</ion-button>
            </ion-card>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-content>
</template>

<style scoped>
.form-wrapper {
  height: 65%;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 95px;
}
.form-container {
  width: 100%;
}
.frm-header {
  margin-bottom: 40px;
}
ion-input {
  margin-bottom: 20px;
  border-radius: 5px;
  height: 40px;
  --background: #EDEDED;
}
.login-page-wrapper ion-input ::v-deep(input){
  height: 40px;
  border: none !important;
}
.darkModeEnabled ion-input {
  --background: #2D2D2D;
}
ion-button {
  width: 100%;
  height: 40px;
}
ion-list {
  margin: 0;
  padding: 0;
}
ion-list-header {
  padding-top: 20px;
}
h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: var(--ion-color-black);
  clear: both;
  text-align: center;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin: 10px 0;
}
.loginIllustration_onTheRight{
    padding: 50px;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100vh;
    padding-top: 18vh;
}
.tp_pad_v1{
  padding-top: 12vh;
}
.bg_grey{
  background-color: #f2f2f2;
}
.darkModeEnabled .bg_grey {
  background-color: #121212;
}
.height100{
  height: 100vh;
}
</style>

<script>
// import '../resources/css/login.css';

import { mapGetters, mapActions, mapState } from "vuex";
import { useRouter } from 'vue-router';
import ImageSlider from "../../components/ImageSlider.vue";

export default {
  components: { ImageSlider },
  data() {
    const router = useRouter();
    return {
      userInfo: {
        username: "",
        password: "",
      },
      halfwayLogin: false,
      router: router,
      loading: false,
      angular: {
        userid: undefined,
        token: undefined,
        redirect: undefined
      },
      jupiter: {
        authkey: undefined
      }
    };
  },
  async mounted() {
    try {
      console.log(this.$route.query);
      if (this.$route.query?.redirect) this.angular.redirect = this.$route.query.redirect;
      if (this.$route.query?.userid) this.angular.userid = this.$route.query.userid;
      if (this.$route.query?.token) this.angular.token = this.$route.query.token;
      if (this.$route.query?.authkey) this.jupiter.authkey = this.$route.query.authkey;
      this.loading = true;
      if (this.angular.userid && this.angular.token) {
        await this.clearStorageData();
        await this.loginAngularUser(this.angular);
        this.loading = false;
        switch(this.loginStatus) {
          case 'success': {
            this.updateToast({ open: true, message: "Welcome Back, You are successfully logged in!", type: "success" })
            this.router.push('/dashboard');
          }
          break;
          case 'halfway': {
            this.updateToast({ open: true, message: "Please Choose the Account you want to Login", type: "warning" })
            this.halfwayLogin = true;
          }
          break;
          default: this.updateToast({ open: true, message: "Please enter valid user details!", type: "danger" })
        }
      } if(this.jupiter.authkey) {
        await this.clearStorageData();
        await this.loginJupiterUser(this.jupiter);
        this.loading = false;
        switch(this.loginStatus) {
          case 'success': {
            this.updateToast({
              open: true,
              message: "Welcome Back, You are successfully logged in!",
              type: "success"
            })
            this.router.push('/dashboard');
          }
          break;
          case 'halfway': {
            this.updateToast({
              open: true,
              message: "Please Choose the Account you want to Login",
              type: "warning"
            })
            this.halfwayLogin = true;
          }
          break;
          default: this.updateToast({
            open: true,
            message: "Please enter valid user details!",
            type: "danger"
          })
        }
      }
      else {
        this.loading = false;
        this.updateToast({ open: true, message: "Please enter proper credentials", type: "warning" })
      }
    } catch (error) {
      console.log("Exception", error);
      this.updateToast({ open: true, message: "Something went wrong! Try again later.", type: "warning" })
    }
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
      accounts: "getMultipleAccounts"
    }),
    ...mapState(
      {
        isDarkMode: (state) => state.darkMode
      }
    )
  },
  methods: {
    ...mapActions({
      loginUser: "auth/loginUser",
      loginAngularUser: "auth/loginAngularUser",
      loginJupiterUser: "auth/loginJupiterUser"
    }),
    async login() {
      this.loading = true;
      if (this.userInfo.username && this.userInfo.password) {
        this.userInfo.username = this.userInfo.username.toLowerCase();
        let response = await this.loginUser(this.userInfo);
        this.loading = false;
        switch(this.loginStatus) {
          case 'success': {
            this.updateToast({ open: true, message: "Welcome Back, You are successfully logged in!", type: "success" })
            this.router.push('/dashboard');
          }
          break;
          case 'halfway': {
            this.updateToast({ open: true, message: "Please Choose the Account you want to Login", type: "light" })
            this.halfwayLogin = true;
          }
          break;
          default: {
            let errorMessage = "Please enter valid user details!";
            if (response?.data?.detail) {
              errorMessage = response?.data?.detail;
            }
            this.updateToast({ open: true, message: errorMessage, type: "danger" })
          }
        }
      } else {
        this.loading = false;
        this.updateToast({ open: true, message: "Please enter proper credentials", type: "warning" })
      }
    },
    async chooseLoginAccount (aIndex) {
      this.loading = true;
      await this.loginUser({halwayIndex: aIndex});
      if(this.loginStatus === 'success') {
        this.updateToast({ open: true, message: "Welcome Back, You are successfully logged in!", type: "success" })
        this.router.push('/dashboard');
      } else {
        this.updateToast({ open: true, message: "Login Failed!", type: "danger" })
      }
      this.loading = false;
      this.halfwayLogin = false;
    },
    async clearStorageData() {
      console.log("clearStorageData");
      await this.Storage.set({
        key: "access_token",
        value: undefined,
      });
      await this.Storage.set({
        key: "refresh_token",
        value: undefined,
      });
      await this.Storage.set({
        key: "authkey",
        value: undefined,
      });
      await this.Storage.set({
        key: "user",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_device_token",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_device_token___currently_saved_token",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_device_type",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_redirect_url",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_fetchAddUserData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_fetchSocialData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_getPermissionData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_subscriptionData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_roleDetailsData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_getCommerceData",
        value: null,
      });
      await this.Storage.set({
        key: "jmpr_fetchPaymentOptionsData",
        value: null,
      });
    }
  },
};
</script>