<template>
  <master-layout pageTitle="Store">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading...">
      </ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" :value="activeTab">
            <ion-segment-button v-if="isAdmin || oldPermissions.products.view" :class="{ disabled: permissions.product == false }" value="products" @click="tabClickHandler('product')">
              <div class="label-wrapper">
                <ion-label>Product</ion-label>
                <span v-if="permissions.product == false" class="upgrade-url" router-link="/plans">Upgrade</span>
              </div>
            </ion-segment-button>
            <ion-segment-button v-if="isAdmin || oldPermissions.bundles.view" :class="{ disabled: permissions.set == false }" value="sets" @click="tabClickHandler('set')">
              <div class="label-wrapper">
                <ion-label>Set</ion-label>
                <span v-if="permissions.set == false" class="upgrade-url" router-link="/plans">Upgrade</span>
              </div>
            </ion-segment-button>
            <ion-segment-button v-if="isAdmin || oldPermissions.collections.view" :class="{ disabled: permissions.collection == false }" value="collections" @click="tabClickHandler('collection')">
              <div class="label-wrapper">
                <ion-label>Collection</ion-label>
                <span v-if="permissions.collection == false" class="upgrade-url" router-link="/plans">Upgrade</span>
              </div>
            </ion-segment-button>
            <ion-segment-button v-if="isAdmin || oldPermissions.discounts" :class="{ disabled: permissions.discount == false }" value="discounts" @click="tabClickHandler('discount')">
              <div class="label-wrapper">
                <ion-label>Discount</ion-label>
                <span v-if="permissions.discount == false" class="upgrade-url" router-link="/plans">Upgrade</span>
              </div>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="selectedProducts.length" class="table-container deleteContainer" style="margin-top: 12px" >
          <ion-item lines="none">
            <ion-label> {{ selectedProducts.length }} product(s) selected</ion-label>
              <ion-buttons slot="end">
                <ion-button @click="() => confirmDeleteSelectedMode = true" fill="clear" size="default" color="danger">
                  <ion-icon :icon="trashOutline" slot="start"></ion-icon>
                  <ion-label style="padding-left: 5px"> Delete Selected Product(s) </ion-label>
                </ion-button>
              </ion-buttons>
          </ion-item>
        </div>      
        <!-- products -->
        <div v-if="activeTab === 'products'">
          <ion-toolbar class="chat-toolbar" color="light">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="productFilter" placeholder="Enter product name"></ion-input>
              <ion-buttons slot="end">
                <ion-button @click="() => filterModal = true">
                  <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-toolbar>
          <!-- headers -->
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-checkbox class="ion-hide-md-down" color="dark" v-model="selectAllProducts" style="margin-right: 12px"></ion-checkbox>
              <ion-col class="ion-hide-md-down">
                Image
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Inventory
              </ion-col>
              <ion-col class="ion-hide-md-down" >
                Price
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Visible on Store
              </ion-col>
              <ion-col class="ion-hide-md-down text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="product in productsToShow" :key="product.id" class="table-row ion-no-padding">
                <ion-checkbox color="dark" v-model="product.selected"></ion-checkbox>
                <ion-col>
                  <img width="50" v-if="product.images && product.images.length" :src="product.images[0]" class="img-cntner"/>
                </ion-col>
                <ion-col class="orderDesc">
                  {{ product.name }}
                </ion-col>
                <ion-col class="orderDesc">
                  {{ product.stock == 0 ? 'Stock Over' : product.stock }}
                </ion-col>
                <ion-col class="orderDesc">
                  {{ product.currency }} {{ product.totalcost }}
                </ion-col>
                <ion-col>
                  <ion-toggle v-model="product.shopActive" @ionChange="toggleVisibilityProduct(product.id, product.shopActive)" />
                </ion-col>
                <ion-col class="text-center">
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" fill="clear" size="default" color="dark">
                      <ion-icon :icon="shareSocialOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">share</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/add-product?productid=' + product.id)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="createOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => productIdToDelete = product.id" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="ion-hide-md-down">
            <ion-infinite-scroll class="ion-hide-md-down"
              @ionInfinite="fetchProducts($event)" 
              threshold="100px" 
              id="infinite-scroll"
              :disabled="productsLoadMore || allProducts.length <= 0"
            >
              <ion-infinite-scroll-content
                loading-spinner="bubbles"
                loading-text="Loading more products...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>

            <div class="text-center" v-if="(productFilter && productFilter.length || productStockFilter) && productsToShow.length == 0" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>No products matching this filter! 😟 <span @click="() => {productFilter = undefined; productStockFilter = undefined;}">Clear filter</span></h5>
            </div>
            
            <div class="text-center" v-if="allProducts.length == 0 && !productsLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"  class="filterImage" />
              <h5>No products yet! 😟</h5>
            </div>

            <div class="text-center" v-if="allProducts.length == 0 && productsLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"  class="filterImage"/>
              <h5>Fetching products...</h5>
            </div>
          </div>
        </div>
        <!-- sets -->
        <div v-if="activeTab === 'sets'" >
          <ion-toolbar class="chat-toolbar" color="light">
          <ion-item class="searchBar" lines="none">
            <ion-input v-model="setFilter" placeholder="Enter set name"></ion-input>
            <!-- <ion-buttons slot="end">
              <ion-button @click="() => filterModal = true">
                <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
              </ion-button>
            </ion-buttons> -->
          </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down">
                Image
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
                <ion-col class="ion-hide-md-down">
                Inventory
              </ion-col>
                <ion-col class="ion-hide-md-down" >
                Price
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Visible on Store
              </ion-col>
              <ion-col class="ion-hide-md-down text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div v-if="activeTab === 'sets'" class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="set in setsToShow" :key="set.id" class="table-row ion-no-padding">
                <ion-col>
                    <img width="50" v-if="set.image && set.image.length" :src="set.image" class="img-cntner" />
                </ion-col>
                <ion-col class="orderDesc">
                  {{ set.name }}
                </ion-col>
                <ion-col class="orderDesc">
                  {{ set.stock == 0 ? 'Stock Over' : set.stock }}
                </ion-col>
                <ion-col class="orderDesc">
                 {{ set.currency }} {{ set.totalcost }}
                </ion-col>
                <ion-col>
                  <ion-toggle v-model="set.shopActive" @ionChange="toggleVisibilityProduct(set.id, set.shopActive)" />
                </ion-col>
                <ion-col>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" fill="clear" size="default" color="dark">
                        <ion-icon :icon="shareSocialOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text">Share</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/add-set?setid=' + set.id)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="createOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => productIdToDelete = set.id" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>          
          </div>
          <div class="ion-hide-md-down">
            <ion-infinite-scroll
              @ionInfinite="fetchBundles($event)" 
              threshold="100px" 
              id="infinite-scroll"
              :disabled="bundlesLoadMore || allBundles.length <= 0"
            >
              <ion-infinite-scroll-content
                loading-spinner="bubbles"
                loading-text="Loading more sets...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>

            <div class="text-center" v-if="setFilter && setFilter.length && setsToShow.length == 0" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>No sets matching this filter! 😟 <span @click="() => setFilter = undefined">Clear filter</span></h5>
            </div>

            <div class="text-center" v-if="allBundles.length == 0 && !bundlesLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>No sets yet! 😟</h5>
            </div>

            <div class="text-center" v-if="allBundles.length == 0 && bundlesLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>Fetching sets...</h5>
            </div>
          </div>
        </div>
        <!-- collections -->
        <div v-if="activeTab === 'collections'">
          <ion-toolbar class="chat-toolbar" color="light">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="collectionFilter" placeholder="Enter collection name"></ion-input>
              <!-- <ion-buttons slot="end">
                <ion-button @click="() => filterModal = true">
                  <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
                </ion-button>
              </ion-buttons> -->
            </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding" style="margin-left:72px;">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down">
                Image
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
                <ion-col class="ion-hide-md-down">
                Checkout Trigger
              </ion-col>
                <ion-col class="ion-hide-md-down ion-justify-content-center" >
                Visible on Store
              </ion-col>
              <ion-col size="3" class="ion-hide-md-down ion-justify-content-center text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div v-if="activeTab === 'collections'" class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="collection in collectionsToShow" :key="collection.id" class="table-row ion-no-padding">
                <ion-col>
                  <img width="50" v-if="collection.image && collection.image.length" :src="collection.image" class="img-cntner" />
                </ion-col>
                <ion-col class="orderDesc">
                  {{ collection.name }}
                </ion-col>
                <ion-col class="orderDesc">
                  #{{  collection.hashtag }}
                </ion-col>
                <ion-col>
                  <ion-toggle v-model="collection.shopActive" @ionChange="toggleVisibilityProduct(collection.id, collection.shopActive)" />
                </ion-col>
                <ion-col>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" fill="clear" size="default" color="dark">
                      <ion-icon :icon="shareSocialOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Share</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/add-collection?collectionid=' + collection.id)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="createOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => productIdToDelete = collection.id" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>          
          </div>
          <div class="ion-hide-md-down">
            <ion-infinite-scroll
                @ionInfinite="fetchCatalogues($event)" 
                threshold="100px" 
                id="infinite-scroll"
                :disabled="cataloguesLoadMore || allCatalogues.length <= 0"
              >
                <ion-infinite-scroll-content
                  loading-spinner="bubbles"
                  loading-text="Loading more collections...">
                </ion-infinite-scroll-content>
            </ion-infinite-scroll>

            <div class="text-center" v-if="collectionFilter && collectionFilter.length && collectionsToShow.length == 0" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>No collections matching this filter! 😟 <span @click="() => collectionFilter = undefined">Clear filter</span></h5>
            </div>

            <div class="text-center" v-if="allCatalogues.length == 0 && !cataloguesLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>No collections yet! 😟</h5>
            </div>

            <div class="text-center" v-if="allCatalogues.length == 0 && cataloguesLoadMore" style="width: 100%; padding: 15px;">
              <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
              <h5>Fetching collections...</h5>
            </div>
          </div>
        </div>
        <!-- discounts -->
        <div v-if="activeTab === 'discounts'">
          <ion-toolbar class="chat-toolbar" color="light">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="discountFilter" placeholder="Enter discount coupon"></ion-input>
              <!-- <ion-buttons slot="end">
                <ion-button @click="() => filterModal = true">
                  <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
                </ion-button>
              </ion-buttons> -->
            </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="table-row ion-no-padding">
              <ion-col size="2" class="ion-hide-md-down">
                Coupon
              </ion-col>
              <ion-col size="2" class="ion-hide-md-down">
                Validity
              </ion-col>
              <ion-col size="2" class="ion-hide-md-down">
                Condition
              </ion-col>
                <ion-col size="1" class="ion-hide-md-down">
                Discount
              </ion-col>
                <ion-col size="1" class="ion-hide-md-down" >
                Type
              </ion-col>
              <ion-col size="1" class="ion-hide-md-down">
                Stackable
              </ion-col>
              <ion-col size="1" class="ion-hide-md-down">
                Status
              </ion-col>
              <ion-col size="2" class="ion-hide-md-down text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div v-if="activeTab === 'discounts'" class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="discount in discountsToShow" :key="discount.id" class="table-row ion-no-padding">
                <ion-col class="orderDesc" size="2" style="text-transform: uppercase;">
                  {{ discount.name }}
                </ion-col>
                <ion-col class="orderDesc" size="2">
                  {{ discount.validfrom }} - <span v-if="discount.validtill === '9999-09-09'">Forever</span><span v-else>{{ discount.validtill }}</span>
                </ion-col>
                <ion-col size="2">
                  {{ discount.ticketsize }} - <span v-if="discount.maxticketsize === 9999999">No limit</span><span v-else>{{ discount.maxticketsize }}</span>
                </ion-col>
                <ion-col size="1">
                  <span v-if="discount.percentage">{{ discount.percentage }}% off</span>
                  <span v-if="discount.offmoney">{{ discount.offmoney }} off</span>
                </ion-col>
                <ion-col size="1">
                  <span v-if="discount.automatic == true">Agent only (Agent applied discount)</span>
                  <span v-if="discount.automatic == false && discount.couponapplied == true">Manual</span>
                  <span v-if="discount.automatic == false && discount.couponapplied == false">Automatic</span>
                </ion-col>
                <ion-col size="1">
                  <span v-if="discount.stackable">Stackable</span><span v-else>Non-Stackable</span>
                </ion-col>
                <ion-col size="1">
                  <span v-if="discount.active">Active</span><span v-else>Inactive</span>
                </ion-col>
                <ion-col size="2" class="text-center">
                  <ion-row class="text-center ion-justify-content-between">
                    <ion-col>  
                      <span class="tooltip_container">                          
                        <ion-button @click="() => $router.push('/add-discount?discountid=' + discount.id)" fill="clear" size="default" color="dark">
                          <ion-icon :icon="createOutline"></ion-icon>
                        </ion-button>
                        <span class="tooltip_text">Edit</span>
                      </span> 
                    </ion-col>
                    <ion-col>
                      <ion-button @click="toggleActiveDiscount(discount.id, !discount.active)" fill="clear" size="default" color="dark">
                        {{ discount.active ? 'Deactivate' : 'Activate' }}
                      </ion-button>
                    </ion-col>
                    <ion-col>
                      <span class="tooltip_container">                             
                        <ion-button @click="() => discountIdToDelete = discount.id" fill="clear" size="default" color="danger">
                          <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-button>
                        <span class="tooltip_text">Delete</span>
                      </span>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="ion-hide-md-down"> 
              <ion-infinite-scroll
                @ionInfinite="fetchDiscounts($event)" 
                threshold="100px" 
                id="infinite-scroll"
                :disabled="!discountsLoadMore || allDiscounts.length <= 0"
              >
                <ion-infinite-scroll-content
                  loading-spinner="bubbles"
                  loading-text="Loading more discounts...">
                </ion-infinite-scroll-content>
              </ion-infinite-scroll>
              
              <div class="text-center" v-if="discountFilter && discountFilter.length && discountsToShow.length == 0" style="width: 100%; padding: 15px;">
                <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage"  />
                <h5>No discounts matching this filter! 😟 <span @click="() => discountFilter = undefined">Clear filter</span></h5>
              </div>

              <div class="text-center" v-if="allDiscounts.length == 0 && !discountsLoadMore" style="width: 100%; padding: 15px;">
                <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage"  />
                <h5>No discounts yet! 😟</h5>
              </div>

              <div class="text-center" v-if="allDiscounts.length == 0 && discountsLoadMore" style="width: 100%; padding: 15px;">
                <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
                <h5>Fetching discounts...</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile screen products-->
        <div v-if="activeTab === 'products'">
          <div  v-for="product in productsToShow" :key="product.id" class="table-responsive ion-hide-md-up">
            <div class="table-section">     
              <ion-grid class="ion-no-padding" style="margin: 0px 0px 20px 0px">  
                <ion-row class="rowMargin">
                  <ion-col size="3" class="ion-no-padding">
                    <img width="50" v-if="product.images && product.images.length" :src="product.images[0]" />
                  </ion-col>
                  <ion-col size="6" class="ion-align-self-center orderDesc">
                    <ion-label class="product-name"> {{ product.name }}</ion-label>
                  </ion-col>
                  <ion-col size="3">
                    <ion-item lines="none" class="ion-text-right">
                      <div class="action-wrapper category-action">
                        <ActionPopOver :style="{'--width': '170px'}">
                          <ion-list>
                            <ion-item>
                              <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" expand="block" fill="none" color="light">
                                <ion-icon :icon="shareSocialOutline" style="margin-right: 5px; color: black"></ion-icon>
                                  Share
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">
                              <ion-button
                                @click="() => $router.push('/add-product?productid=' + product.id)" color="light" expand="block" fill="none">
                                <ion-icon :icon="createOutline" style="margin-right: 5px; color: black"></ion-icon>
                                Edit Product
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')" lines="none">
                              <ion-button @click="() => productIdToDelete = product.id" color="light" expand="block" fill="none">
                                <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                                Delete Product
                              </ion-button>
                            </ion-item>
                          </ion-list>
                        </ActionPopOver>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                      
                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Inventory </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    {{ product.stock == 0 ? 'Stock Over' : product.stock }}
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Price </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                   {{ product.currency }} {{ product.totalcost }}
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Visible on store </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    <ion-toggle v-model="product.shopActive" @ionChange="toggleVisibilityProduct(product.id, product.shopActive)" />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>

        <!-- Mobile screen sets-->
        <div v-if="activeTab === 'sets'">
          <div  v-for="set in setsToShow" :key="set.id" class="table-responsive ion-hide-md-up">
            <div class="table-section">     
              <ion-grid class="ion-no-padding" style="margin: 0px 0px 20px 0px">  
                <ion-row class="rowMargin">
                  <ion-col size="3" class="ion-no-padding">
                    <img width="50" v-if="set.image && set.image.length" :src="set.image" />
                  </ion-col>
                  <ion-col size="6" class="ion-align-self-center orderDesc">
                    <ion-label class="product-name"> {{ set.name }} </ion-label>
                  </ion-col>
                  <ion-col size="3">
                    <ion-item lines="none" class="ion-text-right">
                      <div class="action-wrapper category-action">
                        <ActionPopOver :style="{'--width': '170px'}">
                          <ion-list>
                            <ion-item>
                              <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" expand="block" fill="none" color="light">
                                <ion-icon :icon="shareSocialOutline" style="margin-right: 5px; color: black"></ion-icon>
                                  Share
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">
                              <ion-button @click="() => $router.push('/add-set?setid=' + set.id)"  color="light" expand="block" fill="none">
                                <ion-icon :icon="createOutline" style="margin-right: 5px; color: black"></ion-icon>
                                Edit Sets
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')" lines="none">
                              <ion-button @click="() => productIdToDelete = set.id" color="light" expand="block" fill="none">
                                <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                                Delete Product
                              </ion-button>
                            </ion-item>
                          </ion-list>
                        </ActionPopOver>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                      
                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Inventory </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    {{ set.stock == 0 ? 'Stock Over' : set.stock }}
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Price </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                   {{ set.currency }} {{ set.totalcost }}
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Visible on store </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      <ion-toggle v-model="set.shopActive" @ionChange="toggleVisibilityProduct(set.id, set.shopActive)" />
                  </ion-col>
                </ion-row>

              </ion-grid>
            </div>
          </div>
        </div>

        <!-- Mobile screen collection-->
        <div v-if="activeTab === 'collections'">
          <div v-for="collection in collectionsToShow" :key="collection.id" class="table-responsive ion-hide-md-up">
            <div class="table-section">     
              <ion-grid class="ion-no-padding" style="margin: 0px 0px 20px 0px">  
                <ion-row class="rowMargin">
                  <ion-col size="3" class="ion-no-padding">
                    <img width="50" v-if="collection.image && collection.image.length" :src="collection.image" />
                  </ion-col>
                  <ion-col size="6" class="ion-align-self-center orderDesc">
                    <ion-label class="product-name"> {{ collection.name }} </ion-label>
                  </ion-col>
                  <ion-col size="3">
                    <ion-item lines="none" class="ion-text-right">
                      <div class="action-wrapper category-action">
                        <ActionPopOver :style="{'--width': '170px'}">
                          <ion-list>
                            <ion-item>
                              <ion-button @click="() => $router.push('/sales-channel/re-direct-links')" expand="block" fill="none" color="light">
                                <ion-icon :icon="shareSocialOutline" style="margin-right: 5px; color: black"></ion-icon>
                                  Share
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">
                              <ion-button @click="() => $router.push('/add-collection?collectionid=' + collection.id)"  color="light" expand="block" fill="none">
                                <ion-icon :icon="createOutline" style="margin-right: 5px; color: black"></ion-icon>
                                Edit Collection
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')" lines="none">
                              <ion-button @click="() => productIdToDelete = collection.id" color="light" expand="block" fill="none">
                                <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                                Delete Collection
                              </ion-button>
                            </ion-item>
                          </ion-list>
                        </ActionPopOver>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                      
                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Checkout Trigger </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    #{{  collection.hashtag }}
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col class="ion-no-padding">
                    <ion-label class="orderSubtitle"> Visible on store </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    <ion-toggle v-model="collection.shopActive" @ionChange="toggleVisibilityProduct(collection.id, collection.shopActive)" />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>

        <!-- Mobile screen discounts-->
        <div v-if="activeTab === 'discounts'">
          <div v-for="discount in discountsToShow" :key="discount.id" class="table-responsive ion-hide-md-up">
            <div class="table-section">     
              <ion-grid class="ion-no-padding" style="margin: 0px 0px 20px 0px">  
                <ion-row class="rowMargin">
                  <ion-col size="6" class="ion-align-self-center orderDesc">
                    <ion-label class="discount-name"> {{ discount.name }} </ion-label>
                  </ion-col>
                    <ion-col size="6">
                    <ion-item lines="none" class="ion-text-right">
                      <div class="action-wrapper category-action">
                        <ActionPopOver :style="{'--width': '170px'}">
                          <ion-list>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">
                              <ion-button @click="() => $router.push('/add-discount?discountid=' + discount.id)"  color="light" expand="block" fill="none">
                                <ion-icon :icon="createOutline" style="margin-right: 5px; color: black"></ion-icon>
                                Edit Discounts
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">
                              <ion-button @click="toggleActiveDiscount(discount.id, !discount.active)" expand="block" fill="none" color="light">
                                  {{ discount.active ? 'Deactivate' : 'Activate' }}
                              </ion-button>
                            </ion-item>
                            <ion-item v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')" lines="none">
                              <ion-button @click="() => discountIdToDelete = discount.id" color="light" expand="block" fill="none">
                                <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                                Delete Discounts
                              </ion-button>
                            </ion-item>
                          </ion-list>
                        </ActionPopOver>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                      
                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Validity </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                    {{ discount.validfrom }} - <span v-if="discount.validtill === '9999-09-09'">Forever</span><span v-else>{{ discount.validtill }}</span>
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Condition </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      {{ discount.ticketsize }} - <span v-if="discount.maxticketsize === 9999999">No limit</span><span v-else>{{ discount.maxticketsize }}</span>
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Discount </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      <span v-if="discount.percentage">{{ discount.percentage }}% off</span>
                      <span v-if="discount.offmoney">{{ discount.offmoney }} off</span>
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Type </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      <span v-if="discount.automatic">Automatic</span>
                      <span v-else>Manual</span>
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Stackable </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      <span v-if="discount.stackable">Stackable</span>
                      <span v-else>Non-Stackable</span>
                  </ion-col>
                </ion-row>

                <ion-row class="rowMargin">
                  <ion-col class="ion-no-padding">
                      <ion-label class="orderSubtitle"> Status </ion-label>
                  </ion-col>
                  <ion-col class="orderDesc text-right">
                      <span v-if="discount.active">Active</span>
                      <span v-else>Inactive</span>
                  </ion-col>                        
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>
       </div>
    </ion-content>

    <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => addEditModal = true">
      <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
    </button>
    <ion-action-sheet
      :is-open="addEditModal"
      header="Add to Store"
      :buttons="addOptionsToShow"
      @didDismiss="() => addEditModal = false"
    >
    </ion-action-sheet>
    <ion-alert
      v-if="productIdToDelete !== undefined"
      :is-open="productIdToDelete !== undefined"
      header="Are you sure?"
      :message="'You want to delete selected item?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          productIdToDelete = undefined;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteSingleProduct()
        },
      }]"
      @didDismiss="() => {productIdToDelete = undefined;}"
    >
    </ion-alert>
    <ion-alert
      v-if="discountIdToDelete !== undefined"
      :is-open="discountIdToDelete !== undefined"
      header="Are you sure?"
      :message="'You want to delete selected discount?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          discountIdToDelete = undefined;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteDiscount()
        },
      }]"
      @didDismiss="() => {discountIdToDelete = undefined;}"
    >
    </ion-alert>
    <ion-alert
      v-if="confirmDeleteSelectedMode"
      :is-open="confirmDeleteSelectedMode"
      header="Are you sure?"
      :message="'You want to delete selected product(s)?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          this.allProducts.forEach(p => p.selected = false);
          confirmDeleteSelectedMode = false;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteSelectedProducts()
        },
      }]"
      @didDismiss="() => {confirmDeleteSelectedMode = false; this.allProducts.forEach(p => p.selected = false);}"
    >
    </ion-alert>

<!-- Filter Modal  -->
   <ion-modal
      v-if="filterModal"
      :is-open="filterModal"
      @didDismiss="dismissFilter()"
    >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin" style="padding-left: 12px">Filter by</h2>
          <ion-buttons slot="end">
            <ion-button @click="dismissFilter()">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-radio-group v-model="productStockFilter" value="stock">
          <ion-list-header>
            <ion-label style="font-size: 18px">Filter By Stock</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>View Low Stock Items</ion-label>
            <ion-radio slot="start" value="lowStock" disabled selected></ion-radio>
          </ion-item>
          <ion-item v-if="productStockFilter">
            <ion-label>Clear filter</ion-label>
            <ion-radio slot="start" value="clearFilter"></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label>Stock is Over</ion-label>
            <ion-radio slot="start" value="over"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 5</ion-label>
            <ion-radio slot="start" value="lessThan5"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 10</ion-label>
            <ion-radio slot="start" value="lessThan10"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 15</ion-label>
            <ion-radio slot="start" value="lessThan15"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 20</ion-label>
            <ion-radio slot="start" value="lessThan20"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 25</ion-label>
            <ion-radio slot="start" value="lessThan25"></ion-radio>
          </ion-item>
            <ion-item>
            <ion-label>Stock is less than 50</ion-label>
            <ion-radio slot="start" value="lessThan50"></ion-radio>
          </ion-item>
          <ion-item style="float:right" lines="none">
            <ion-button @click="dismissFilter" color="dark" expand="block">
                    Filter
            </ion-button>
          </ion-item>
        </ion-radio-group>
      </ion-content>
    </ion-page>
   </ion-modal>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, addCircleOutline, close, trashBinOutline, trashOutline, createOutline, shareSocialOutline, pencilOutline, filterOutline, arrowUndoOutline, addOutline } from 'ionicons/icons';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonToggle, IonCheckbox, IonRadio,IonRadioGroup} from "@ionic/vue";
import ActionPopOver from "../../components/ActionPopOver.vue";
import { mapState, mapGetters } from "vuex";

const PermissionTabNameMapper = { product: 'products', set: 'sets', collection: 'collections', discount: 'discounts' };

export default {
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonToggle,
    IonCheckbox,
    IonRadio,
    IonRadioGroup,
    ActionPopOver,
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      createOutline,
      trashOutline,
      shareSocialOutline,
      pencilOutline,
      arrowUndoOutline,
      addOutline,
      close,
      filterOutline,
      loading: false,
      activeTab: 'products',
      allProducts: [],
      productsLoadMore: false,
      productsLoaded: false,
      allBundles: [],
      bundlesLoadMore: false,
      bundlesLoaded: false,
      allCatalogues: [],
      cataloguesLoadMore: false,
      cataloguesLoaded: false,
      allDiscounts: [],
      discountsLoadMore: false,
      discountsLoaded: false,
      selectAllProducts: false,
      addEditModal: false,
      filterModal: false,
      addOptions: [
        {
          text: 'Add Product',
          key: 'products',
          handler: () => {
            this.$router.push('/add-product');
          }
        },
        {
          text: 'Add Set',
          key: 'bundles',
          handler: () => {
            this.$router.push('/add-set');
          }
        },
        {
          text: 'Add Collection',
          key: 'collections',
          handler: () => {
            this.$router.push('/add-collection');
          }
        },
        {
          text: 'Add Discount',
          key: 'discounts',
          handler: () => {
            this.$router.push('/add-discount');
          }
        }
      ],
      confirmDeleteSelectedMode: false,
      productIdToDelete: undefined,
      productFilter: undefined,
      productStockFilter: undefined,
      setFilter: undefined,
      collectionFilter: undefined,
      discountFilter: undefined,
      discountIdToDelete: undefined
    }
  },
  computed: {
    ...mapState({
      isEnterprise: (state) => state.user.enterprise
    }),
    ...mapGetters({
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
    addOptionsToShow() {
      return this.isAdmin ? this.addOptions : this.addOptions.filter(e => (e.key === 'discounts' ? this.oldPermissions[e.key] : this.oldPermissions[e.key]['create']))
    },
    permissions() {
      return this.jupiter?.permissions || {};
    },
    productsToShow() {
      if(this.productStockFilter) {
        let products = this.allProducts.filter(e => {
          switch(this.productStockFilter) {
            case 'over': return e.stock === 0;
            case 'lessThan5': return e.stock <= 5;
            case 'lessThan10': return e.stock <= 10;
            case 'lessThan15': return e.stock <= 15;
            case 'lessThan20': return e.stock <= 20;
            case 'lessThan25': return e.stock <= 25;
            case 'lessThan50': return e.stock <= 50;
            default: return true;
          }
        })
        return this.productFilter && this.productFilter.length ? products.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : products;
      }
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    selectedProducts() {
      return this.allProducts.filter(e => e.selected);
    },
    setsToShow() {
      return this.setFilter && this.setFilter.length ? this.allBundles.filter(e => e.name.match(new RegExp(this.setFilter, 'gi'))) : this.allBundles;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCatalogues.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCatalogues;
    },
    discountsToShow() {
      return this.discountFilter && this.discountFilter.length ? this.allDiscounts.filter(e => e.name.match(new RegExp(this.discountFilter, 'gi'))) : this.allDiscounts;
    }
  },
  async ionViewWillEnter() {
    this.productsLoaded = false;
    this.bundlesLoaded = false;
    this.cataloguesLoaded = false;
    this.discountsLoaded = false;
    if(this.$route.query?.tab && this.$route.query.tab.length) this.changeTab(this.$route.query.tab);
    else this.changeTab('products');
    this.onMountCheckTabPermissions();
  },
  mounted() {
    this.onMountCheckTabPermissions();
  },
  watch: {
    selectAllProducts() {
      if(this.selectAllProducts) this.productsToShow.forEach(p => p.selected = true);
      else this.allProducts.forEach(p => p.selected = false);
    }
  },
  methods: {
    onMountCheckTabPermissions() {
      let permittedTab = ['product', 'set', 'collection', 'discount'].find((elm) => this.permissions[elm] != false);
      if (permittedTab && PermissionTabNameMapper[permittedTab]) {
        this.changeTab(PermissionTabNameMapper[permittedTab]);
      } else {
        this.$router.push('/plans');
      }
    },
    tabClickHandler(permissionName) {
      const tabName = PermissionTabNameMapper[permissionName];
      if (this.permissions[permissionName] == false) {
        this.$router.push('/plans');
      } else {
        this.changeTab(tabName);
      }
    },
    doRefresh(e) {
      this.productsLoaded = false;
      this.bundlesLoaded = false;
      this.cataloguesLoaded = false;
      this.discountsLoaded = false;
      if(this.$route.query?.tab && this.$route.query.tab.length) this.changeTab(this.$route.query.tab);
      else this.changeTab('products');
      e.target.complete();
    },
    changeTab(newTab) {
      this.activeTab = newTab;
      this.$router.replace({ path: '/store', query: { tab: newTab }});
      switch(this.activeTab) {
        case 'products': !this.productsLoaded ? this.fetchProducts() : '';
        break;
        case 'sets': !this.bundlesLoaded ? this.fetchBundles() : '';
        break;
        case 'collections': !this.cataloguesLoaded ? this.fetchCatalogues() : '';
        break;
        case 'discounts': !this.discountsLoaded ? this.fetchDiscounts() : '';
        break;
        default: this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async fetchProducts(e) {
      if(!e) this.loading = true;
      else this.productsLoadMore = true;

      try {
        let allProductsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((allProductsResponse.status == 200 || allProductsResponse.status == 201) && allProductsResponse.data.success) {
          this.allProducts = allProductsResponse.data.products.filter(e => !e.bundle && !e.catalog);
          this.allProducts.forEach(p => p.selected = false);
          this.productsLoaded = true;
        } else {
          if(allProductsResponse.data && !allProductsResponse.data.success && allProductsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allProductsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allProductsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.productsLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchBundles(e) {
      if(!e) this.loading = true;
      else this.bundlesLoadMore = true;

      try {
        let allBundlesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-bundle");

        if ((allBundlesResponse.status == 200 || allBundlesResponse.status == 201) && allBundlesResponse.data.success) {
          this.allBundles = allBundlesResponse.data.posts;
          this.bundlesLoaded = true;
        } else {
          if(allBundlesResponse.data && !allBundlesResponse.data.success && allBundlesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allBundlesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allBundlesResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.bundlesLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchCatalogues(e) {
      if(!e) this.loading = true;
      else this.cataloguesLoadMore = true;

      try {
        let allCataloguesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-catalogue");

        if ((allCataloguesResponse.status == 200 || allCataloguesResponse.status == 201) && allCataloguesResponse.data.success) {
          this.allCatalogues = allCataloguesResponse.data.posts;
          this.cataloguesLoaded = true;
        } else {
          if(allCataloguesResponse.data && !allCataloguesResponse.data.success && allCataloguesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allCataloguesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allCataloguesResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.cataloguesLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchDiscounts(e) {
      if(!e) this.loading = true;
      else this.discountsLoadMore = true;

      try {
        let allDiscountsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "discount/list");

        if ((allDiscountsResponse.status == 200 || allDiscountsResponse.status == 201) && allDiscountsResponse.data.success) {
          this.allDiscounts = allDiscountsResponse.data && allDiscountsResponse.data.data && allDiscountsResponse.data.data.data && allDiscountsResponse.data.data.data.length ? allDiscountsResponse.data.data.data : [];
          this.discountsLoaded = true;
        } else {
          if(allDiscountsResponse.data && !allDiscountsResponse.data.success && allDiscountsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allDiscountsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allDiscountsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.discountsLoadMore = false;}
        else this.loading = false;
      }
    },
    async toggleVisibilityProduct(id, active) {
      try {
        let toggleVisibilityResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-product", { productid: id, shopActive: active });

        if ((toggleVisibilityResponse.status == 200 || toggleVisibilityResponse.status == 201) && toggleVisibilityResponse.data.success) {
          this.updateToast({
              open: true,
              message: "Successfully updated item",
              type: "success"
            })
        } else {
          if(toggleVisibilityResponse.data && !toggleVisibilityResponse.data.success && toggleVisibilityResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: toggleVisibilityResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", toggleVisibilityResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      }
    },
    async confirmDeleteSingleProduct() {
      this.loading = true;
      try {
        let deleteProductResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-product", { productid: this.productIdToDelete });

        if ((deleteProductResponse.status == 200 || deleteProductResponse.status == 201) && deleteProductResponse.data.success && deleteProductResponse.data.delete) {
          switch(this.activeTab) {
            case 'products': this.fetchProducts();
            break;
            case 'sets': this.fetchBundles();
            break;
            case 'collections': this.fetchCatalogues();
            break;
            default: console.log("Impossible");
          }
          this.updateToast({
            open: true,
            message: "Deleted Successfully",
            type: "success"
          })
        } else {
          if(deleteProductResponse.data && !deleteProductResponse.data.success && deleteProductResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: deleteProductResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", deleteProductResponse);
          }
          this.loading = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
      } finally {
        this.productIdToDelete = undefined;
      }
    },
    async confirmDeleteSelectedProducts() {
      this.loading = true;
      try {
        let deleteProductsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-bulk-products", { productids: (this.selectedProducts.map(e => e.id)).join(',') });

        if ((deleteProductsResponse.status == 200 || deleteProductsResponse.status == 201) && deleteProductsResponse.data.success && deleteProductsResponse.data.removed) {
          this.fetchProducts();
        } else {
          if(deleteProductsResponse.data && !deleteProductsResponse.data.success && deleteProductsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: deleteProductsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", deleteProductsResponse);
          }
          this.loading = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
      }
    },
    async toggleActiveDiscount(id, active) {
      try {
        this.loading = true;
        let toggleActiveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-discount", { discountid: id, active: active.toString() });

        if ((toggleActiveResponse.status == 200 || toggleActiveResponse.status == 201) && toggleActiveResponse.data.success && toggleActiveResponse.data.saved) {
          this.updateToast({
            open: true,
            message: "Successfully updated discount",
            type: "success"
          })
          this.fetchDiscounts();
        } else {
          if(toggleActiveResponse.data && !toggleActiveResponse.data.success && toggleActiveResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: toggleActiveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", toggleActiveResponse);
          }
          this.loading = false;
        }
      } catch (error) {
        console.log("Exception", error);
      }
    },
     async  dismissFilter() {
      this.filterModal = false;
    },
    async confirmDeleteDiscount() {
      this.loading = true;
      try {
        let deleteDiscountResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-discount", { discountid: this.discountIdToDelete });

        if ((deleteDiscountResponse.status == 200 || deleteDiscountResponse.status == 201) && deleteDiscountResponse.data.success && deleteDiscountResponse.data.delete) {
          this.fetchDiscounts();
          this.updateToast({
            open: true,
            message: "Deleted Successfully",
            type: "success"
          })
        } else {
          if(deleteDiscountResponse.data && !deleteDiscountResponse.data.success && deleteDiscountResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: deleteDiscountResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", deleteDiscountResponse);
          }
          this.loading = false;
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
      } finally {
        this.discountIdToDelete = undefined;
      }
    }
  }
}
  
</script>

<style scoped>
ion-segment-button.disabled ion-label {
  opacity: 0.4;
}
.label-wrapper {
  display: flex;
  column-gap: 10px;
}
.label-wrapper span {
  cursor: pointer;
  color: blue;
  font-size: 12px;
  text-transform: capitalize;
}
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}

.filterImage {
  max-width: 200px; 
  width: 100%;
  height: auto; 
  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: 760px){
.table-responsive {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 0px 20px 20px;
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}
}

.table-section {
  padding: 20px 20px 0px;
}

.gridMargin {
  margin: 20px;
}

.rowMargin {
  margin-bottom: 25px;
}


.orderSubtitle {
   font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}

.product-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
  margin-left: 15px;
}

.deleteContainer {
  padding: 5px;
}

.discount-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}
.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}

.action-wrapper {
  width: 100%;
}
.category-action {
  padding-right: 20px;
}
.tooltip_container .tooltip_text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}
</style>