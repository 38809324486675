<template>
  <master-layout pageTitle="2020 analytics">
    <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
    <ion-content v-else>
      <iframe id="googleDataStudioAnalyticsIframe" style="width: 100%; height: calc(100vh - 56px); border: none;" :src="iframeUrl" title="Analytics" allowfullscreen></iframe>
    </ion-content>
  </master-layout>
</template>

<script>
import { mapGetters } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
export default {
  data() {
    return {
      loading: true,
      iframeUrl: ""
    }
  },
  ionViewWillEnter(){
    this.Get_iframeUrl();
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    }),
  },
  methods: {
    Get_iframeUrl() {
      this.getGoogleDataStudioUrl();
    },
    async getGoogleDataStudioUrl() {
      try {
        let reqBody = {
          userid: this.authData.authkey
        }
        let getGDSUrlResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "analytic-url", reqBody);
        if ((getGDSUrlResponse.status == 200 || getGDSUrlResponse.status == 201) && getGDSUrlResponse.data.success) {
          this.loading = false;

          var filteredUrl = getGDSUrlResponse.data.url.split('params=');
          var url_part1 = filteredUrl[0] +"params=%7B";
          var url_lastPart = "%7D";
          
          var url_key = filteredUrl[1];
          url_key = url_key.split('seller_key%22:%22');
          url_key = url_key[1];

          if(url_key.indexOf('%7D') > -1){
            url_key = url_key.split('%7D');
            url_key = url_key[0];
          }

          var sellerKeys_part = "%22jumper0.seller_key%22:%22"+ url_key +"%22,%22jumper1.seller_key%22:%22"+ url_key +"%22,%22jumper2.seller_key%22:%22"+ url_key +"%22,%22jumper3.seller_key%22:%22"+ url_key +"%22,%22jumper4.seller_key%22:%22"+ url_key +"%22,%22jumper5.seller_key%22:%22"+ url_key +"%22,%22jumper6.seller_key%22:%22"+ url_key +"%22";

          if(this.redRibbon_ph_manila == true){
            url_part1 = "https://datastudio.google.com/embed/reporting/af83fca5-13fe-4c95-84ab-7198705dfbb7/page/BmhtB?params=%7B";
          }
          else if(this.loreal_my_org == true){
            url_part1 = "https://datastudio.google.com/embed/reporting/8c29bac7-e0be-43f9-9072-ecaea9fc0ef7/page/28VtB?params=%7B";
          }

          this.iframeUrl = url_part1 +""+ sellerKeys_part +""+ url_lastPart;
        } else {
          this.loading = false;

          if(getGDSUrlResponse.data.errorMessage && getGDSUrlResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: getGDSUrlResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        this.loading = false;
        
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      }
    }
  }
}
</script>