<template>
  <master-layout pageTitle="SMS">
    <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
    <div v-else>
      <div v-if="!smsConnected">
        <ion-card class="container">
          <ion-card-header>
            <ion-item lines="none">
              <ion-thumbnail slot="start">
                <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg" />
              </ion-thumbnail>
              <ion-label>
                <ion-card-title style="margin-bottom: 5px">SMS</ion-card-title>
                <ion-card-subtitle>Register a mobile number</ion-card-subtitle>
              </ion-label>
            </ion-item>
          </ion-card-header>

          <ion-card-content>
            <ion-list class="ion-no-padding">
              <ion-item>
                <ion-label>Mobile No.</ion-label>
                <ion-item slot="end" lines="none">
                  <ion-icon slot="start" :icon="globeOutline" style="margin-right: 5px"></ion-icon>
                  <ion-select v-model="countrycode">
                    <ion-select-option disabled :value="undefined">Select Country Code</ion-select-option>
                    <ion-select-option v-for="(thisCountry, thisCode) in countryCodesData.countryCodesData" :key="thisCode" :value="thisCode">{{ thisCountry.name }}</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-input slot="end" @keypress="numberOnlyValidation($event)" v-model="mobilenumber" type="text" placeholder="Enter Mobile Number" class="fullBordered input_custom_style" style="margin-left: 0px"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">Account name</ion-label>
                <ion-input type="text" class="fullBordered" v-model="account_name" @keypress="alphaNumericOnlyValidation"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">API key</ion-label>
                <ion-input type="text" class="fullBordered" v-model="apikey"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label position="stacked">API secret</ion-label>
                <ion-input type="text" class="fullBordered" v-model="apisecret"></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-buttons slot="end" class="paddingBox">
                  <ion-button :disabled="disabled" @click="connectSMS" class="bttn-bgnd" color="white">Submit</ion-button>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
      <div v-else>
        <div class="cust-margin">
          <h2 class="header ion-no-margin">SMS</h2>
        </div>
        <div class="model-sections cust-margin">
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
              <ion-item lines="none">
                <ion-avatar slot="start" style="height: auto; width: 38px;">
                  <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3 class="title">Connected SMS account</h3>
                  <p class="sub-title">Following SMS account is connected <span v-if="!IS_ASIA">to Jumper.ai</span></p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
              <ion-item lines=none class="status-container">
                <span class="status-title">Status</span>
                <span class="status">All Good</span>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item lines="none">
            <div class="google-message-container">
              <ion-row>
                <ion-col class="ion-no-padding" size-md="6" size-sm="12" size-xs="12">
                  <h3 class="text"><b>Account Name:</b> {{ smsSocialData.account_name }}</h3>
                  <h4 class="sub-title"><b>Mobile Number:</b> &nbsp; {{ mobileNumberToShow || '--' }}</h4>
                  <h4 class="sub-title"><b>webhook:</b> &nbsp; {{ webhookToShow || '--' }}</h4>
                </ion-col>
                <ion-col class="ion-no-padding btn-disconnect-wrapper" size-md="6" size-sm="12" size-xs="12">
                  <ion-button @click="disconnectSMS" class="bttn-bgnd" color="white">Disconnect</ion-button>
                </ion-col>
              </ion-row>
            </div>
          </ion-item>
        </div>
      </div>
    </div>
  </master-layout>
</template>
  
<script>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import { globeOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  components: { IonSelect, IonSelectOption },
  data() {
    return {
      globeOutline,
      loading: false,
      mobilenumber: "",
      countrycode: undefined,
      account_name: "",
      apikey: undefined,
      apisecret: undefined,
      webhookToShow: "",
      mobileNumberToShow: ""
    }
  },
  mounted() {
    try {
      var url = new URL(this.smsSocialData.webhook);
      url.hostname = 'XXXXXXXXXX';
      this.webhookToShow = url.href;
    } catch (error) {
      console.error(error);
    }

    try {
      this.mobileNumberToShow = `${this.smsSocialData.countrycode || ''} ${this.smsSocialData.mobile_number || ''}`?.trim();
    } catch (error) {
      console.error(error)
    }
  },
  computed: {
    disabled() {
      return !this.account_name || this.account_name.length == 0 || !this.apikey || this.apikey.length == 0 || !this.apisecret || this.apisecret.length == 0;
    }
  },
  watch: {

  },
  methods: {
    numberOnlyValidation($event) {
      const pattern = /[0-9]/;
      let inputChar = String.fromCharCode($event.charCode);

      if (!pattern.test(inputChar) || this.mobilenumber.toString().length >= 15 ) {
        $event.preventDefault();
      }
    },
    alphaNumericOnlyValidation($event) {
      const pattern = /^[a-zA-Z0-9]*$/;
      let inputChar = String.fromCharCode($event.charCode);
      if (!pattern.test(inputChar) || this.account_name.toString().length >= 11) {
        $event.preventDefault();
      }
    },
    async connectSMS() {
      try {
        const isValidFromValue = /^[a-zA-Z0-9]*$/;
        const isValidMobile = /^[0-9]*$/;
        if (!isValidMobile.test(this.mobilenumber)) {
          this.updateToast({ open: true, message: "Please enter numeric value only for mobile number.", type: "danger" });
          return;
        }

        if ((this.mobilenumber.toString()[0] == 0 && this.mobilenumber.toString()[1] == 0) || this.mobilenumber.toString()[0] == "+") {
          this.updateToast({ open: true, message: "Leading zeros or plus sign are not required in mobile number.", type: "danger" });
          return;
        }
        
        if (!isValidFromValue.test(this.account_name)) {
          this.updateToast({ open: true, message: "Please enter valid alphanumeric value for account name.", type: "danger" });
          return;
        }
        if (this.mobilenumber.toString().length > 15) {
          this.updateToast({ open: true, message: "Mobile number should not be longer than 15 characters.", type: "danger" });
          return;
        }
        if (this.account_name.length > 11) {
          this.updateToast({ open: true, message: "Account name should not be longer than 11 characters", type: "danger" });
          return;
        }
        this.loading = true;
        let reqBody = {
          mobilenumber: this.mobilenumber,
          countrycode: this.countrycode,
          apikey: this.apikey,
          apisecret: this.apisecret,
          account_name: this.account_name
        }
        let connectSMSResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "sms/connect", reqBody);
        if ((connectSMSResponse.status == 200 || connectSMSResponse.status == 201) && connectSMSResponse.data.success && connectSMSResponse.data.created) {
          this.updateToast({ open: true, message: "Your SMS Account is now connected!", type: "success" })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          setTimeout(() => {
            window.location.href = window.location.pathname;
          }, 1000);
        }
        else {
          if (connectSMSResponse.data.errorMessage && connectSMSResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: connectSMSResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        this.loading = false;
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      }
    },
    async disconnectSMS() {
      try {
        this.loading = true;
        let reqBody = { platform: 'sms' }
        let connectSMSResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "api/delete-social", reqBody);
        if ((connectSMSResponse.status == 200 || connectSMSResponse.status == 201) && connectSMSResponse.data.success) {
          this.updateToast({ open: true, message: "Your SMS Account is now disconnected!", type: "success" })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          setTimeout(() => {
            window.location.href = window.location.pathname;
          }, 1000);
        } else {
          if (connectSMSResponse.data.errorMessage && connectSMSResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: connectSMSResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      }
    }
  }
}
</script>
  
<style scoped>
.container {
  border-radius: 10px;
  border-radius: 10px;
  max-width: 700px;
  margin: 50px auto 20px auto;
  text-align: center;
}

.cust-margin {
  margin: 20px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.status-container {
  float: right;
}

.status {
  background-color: #eafdf2;
  color: #099e6e;
  margin-left: 10px;
  padding: 4px 11px;
  border-radius: 3px;
}

.status-title {
  font-size: 14px;
  color: var(--ion-color-black);
  font-weight: 500;
}

.google-message-container {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.title {
  font-size: 16px;
  color: var(--ion-color-black);
  font-weight: 600;
  line-height: 1.4;
}

.text {
  font-size: 18px;
}

.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}

.btn-disconnect-wrapper {
  text-align: right;
}

@media only screen and (max-width: 760px) {
  .status-container {
    float: left;
  }

  .btn-disconnect-wrapper {
    text-align: left;
  }
}</style>