import { Preferences as Storage } from "@capacitor/preferences";
import moment from 'moment';
import paypalSupportedCurrencies from '../resources/js/all-countries-currencies-list';
import stripeSupportedCurrencies from '../resources/js/all-countries-currencies-list';
import allCountryCurrencyData from '../resources/js/all-countries-currencies-list';
import country_arr from '../resources/js/country-state-data';
import state_arr from '../resources/js/country-state-data';
import countryCodesData from '../resources/js/country-codes';
import localeFullFormData from '../resources/js/all-locale-data';
import localeFullFormData_whatsapp from '../resources/js/all-locale-data';
import allTimezonesData from '../resources/js/all-timezones-data';
import mcc_mnc_country_network_list from '../resources/js/mcc-mnc-country-network-list';
import defaultAdminUserRolesPermission from '../resources/js/defaultAdminUserRolesPermission';

import { mapGetters, mapActions } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { environment } from '/src/shared/env';

export default {
	mounted() {
		if(this.IS_ASIA) {
			window.document.title = 'Broadcasting Tool';
			let link = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.head.appendChild(link);
			}
			link.href = '/img/apac/favicon.ico';
		}
		document.addEventListener("jmpr_redirect_url", this.redirectTo, true)

		let gTranslateElm = document.getElementById("google_translate_element");
		if(gTranslateElm) {
			if (this.$route.path == "/login" || this.$route.path == "/signup") {
				gTranslateElm.style.display = 'none';
			}
			else{
				gTranslateElm.style.display = 'block';
			}
		}
	},
	data() {
		return {
			Storage: Storage,
			paypalSupportedCurrencies: paypalSupportedCurrencies,
			stripeSupportedCurrencies: stripeSupportedCurrencies,
			allCountryCurrencyData: allCountryCurrencyData,
			country_arr: country_arr,
			state_arr: state_arr,
			countryCodesData: countryCodesData,
			localeFullFormData: localeFullFormData,
			localeFullFormData_whatsapp: localeFullFormData_whatsapp,
			allTimezonesData: allTimezonesData,
			mcc_mnc_country_network_list: mcc_mnc_country_network_list,
			defaultAdminUserRolesPermission: defaultAdminUserRolesPermission,
			shopLogoPlaceholderImageUrl: "https://jumper.ai/web-s/resources/images/placeholder-product.svg",
			paginationSizeGenericPagination: 6,
			paginationOffsetGenericPagination: 0,
			maxTagLimitForSingleAiResponse: 25,
			maxKeywordLimitForSingleAiResponse: 25,
			proTrialPeriodIsOn: false,
			currentPlanName_forntendUseOnly: "free",
			upgradeToProVisible: false,
			userRolesLimit: 0,
			cartRecoveryScheduleLimit: 0,
			botBuilderActiveBotsLimit: 0,
			crmCustomersLimit: 50,
			automatedResponses_nonDialogFlow_limit: 10,
			loyalty_permissionEnabled: false,
			discount_permissionEnabled: false,
			pageInbox_permissionEnabled: false,
			aiSetup_permissionEnabled: false,
			broadcast_permissionEnabled: false,
			botBuilder_permissionEnabled: false,
			popupBuilder_permissionEnabled: false,
			manualPaymentsPermissionEnabled: false,
			whiteLabelPermissionEnabled: false,
			fnbReservationsPermissionEnabled: false,
			youtubePermissionEnabled: false,
			imessagePermissionEnabled: false,
			instagramPermissionEnabled: false,
			whatsappPermissionEnabled: false,
			vonagesmsPermissionEnabled: false,
			magentoPermissionEnabled: false,
			bulkProductsImport_permissionEnabled: false,
			analyticsPermissionEnabled: false,
			personalization_permissionEnabled: false,
			paymentMethods_permissionEnabled: false,
			fb_version: "v14.0",
			fbScopePermission: "public_profile,email,read_page_mailboxes,pages_messaging,pages_manage_metadata,pages_show_list,pages_manage_posts,pages_manage_engagement,pages_read_engagement,pages_read_user_content,page_events",
			fbScopePermission_business: "ads_management,ads_read,read_insights",
			fbScopePermission_shop: "business_management,catalog_management",
			csvExportWithDateRangeModal: false,
      csvExportWithDateRangeModal_type: "",
			csvExportWithDateRangeModal_title: ""
		}
	},
	computed: {
		myTimeZone() {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		},
    ...mapGetters({
			jupiter: "auth/getJupiterSubscription",
			fetchSocialFullData: "social/getFetchSocialFullData",
			facebookConnected: "social/isFacebookConnected",
			messengerConnected: "social/isMessengerConnected",
			instagramConnected: "social/isInstagramConnected",
			twitterConnected: "social/isTwitterConnected",
			youtubeConnected: "social/isYoutubeConnected",
			pinterestConnected: "social/isPinterestConnected",
			imessageConnected: "social/isIMessageConnected",
			adlingoConnected: "social/isAdLingoConnected",
			lineConnected: "social/isLineConnected",
			viberConnected: "social/isViberConnected",
			telegramConnected: "social/isTelegramConnected",
			whatsappConnected: "social/isWhatsAppConnected",
			smsConnected: "social/isSMSConnected",
			gBusinessMessagesConnected: "social/isGBusinessMessagesConnected",
			facebookSocialData: "social/getFacebook",
			instagramSocialData: "social/getInstagram",
			twitterSocialData: "social/getTwitter",
			youtubeSocialData: "social/getYoutube",
			pinterestSocialData: "social/getPinterest",
			imessageSocialData: "social/getIMessage",
			adlingoSocialData: "social/getAdLingo",
			lineSocialData: "social/getLine",
			viberSocialData: "social/getViber",
			telegramSocialData: "social/getTelegram",
			whatsAppSocialData: "social/getWhatsApp",
			smsSocialData: "social/getSMS",
			gBusinessMessagesSocialData: "social/getGBusiness",
			user: "user/getUser",
			basicPermissionData: "user/getBasicPermission",
			subscriptionDetails: "user/fetchSubscriptionDetails",
			roleDetails: "user/fetchRoleDetailsDetails",
			integrationFullData: "integration/getFullData",
			acommerceConnected: "integration/isAcommerceConnected",
			shopifyConnected: "integration/isShopifyConnected",
			woocommerceConnected: "integration/isWoocommerceConnected",
			bigcommerceConnected: "integration/isBigcommerceConnected",
			amazonConnected: "integration/isAmazonConnected",
			printfulConnected: "integration/isPrintfulConnected",
			magentoConnected: "integration/isMagentoConnected",
			squareConnected: "integration/isSquareConnected",
		}),
		socialPlatformConnected() {
			return this.facebookConnected || this.instagramConnected || this.twitterConnected || this.youtubeConnected || this.pinterestConnected || this.imessageConnected || this.adlingoConnected || this.lineConnected || this.viberConnected || this.telegramConnected || this.smsConnected || this.gBusinessMessagesConnected || this.whatsappConnected;
		},
		socialPlatformConnectedArray() {
			let connectedSocialPlatforms = [];
			if(!this.socialPlatformConnected) return connectedSocialPlatforms;
			this.facebookConnected ? connectedSocialPlatforms.push("facebook") : 0;
			this.instagramConnected ? connectedSocialPlatforms.push("instagram") : 0;
			this.twitterConnected ? connectedSocialPlatforms.push("twitter") : 0;
			this.youtubeConnected ? connectedSocialPlatforms.push("youtube") : 0;
			this.pinterestConnected ? connectedSocialPlatforms.push("pinterest") : 0;
			this.imessageConnected ? connectedSocialPlatforms.push("imessage") : 0;
			this.adlingoConnected ? connectedSocialPlatforms.push("adlingo") : 0;
			this.lineConnected ? connectedSocialPlatforms.push("line") : 0;
			this.viberConnected ? connectedSocialPlatforms.push("viber") : 0;
			this.smsConnected ? connectedSocialPlatforms.push("sms") : 0;
			this.gBusinessMessagesConnected ? connectedSocialPlatforms.push("googlebusinessmsg") : 0;
			this.telegramConnected ? connectedSocialPlatforms.push("telegram") :0;
			this.whatsappConnected ? connectedSocialPlatforms.push("whatsapp") : 0;
			return connectedSocialPlatforms;
		},
		commercePlatformConnected() {
			return this.shopifyConnected || this.woocommerceConnected || this.bigcommerceConnected || this.amazonConnected || this.printfulConnected || this.magentoConnected;
		},
		shopLinkConsideringCustomDomain() {
			if(this.IS_LIVE == true){
				return "https://"+ this.user.referallshopName +".jumper.ai";
			}
			else{
				return "https://"+ this.user.referallshopName +".jumperdevnew.appspot.com";
			}
		},
		getShopLogoImage(){
			if(typeof this.user.shopLogo != 'undefined' && this.user.shopLogo != '' && this.user.shopLogo != 'None' && this.user.shopLogo != null){
				return this.user.shopLogo;
			}
			else{
				return "https://jumper.ai/web-b/resources/images/messaging-commerce-grey-with-padding.jpg";
			}
		},
		accountServiceType(){
			if(this.user && this.user.sellerproducttype) {
				if(this.user.sellerproducttype.indexOf('product') > -1){
					return "product";
				}
				else if(this.user.sellerproducttype.indexOf('restaurant') > -1){
					return "restaurant";
				}
				else if(this.user.sellerproducttype.indexOf('event') > -1){
					return "event";
				}
			} else return undefined;
		},
		accountType_master(){
			//this is same as "loggedInUserAdmin"
			if(typeof this.basicPermissionData.admin != 'undefined'){
				return this.basicPermissionData.admin;
			}
			else if(Object.keys(this.basicPermissionData.permissions).length === 0){
				return true;
			}
		},
		currentUserGetPermissionApi_admin(){
			if(typeof this.basicPermissionData.admin != 'undefined'){
				return this.basicPermissionData.admin;
			}
			else if(Object.keys(this.basicPermissionData.permissions).length === 0){
				return true;
			}
		},
		currentUserBasicPermissions(){
			if(this.accountType_master == true){
				return this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission;
			}
			else{
				if(Array.isArray(this.basicPermissionData.permissions) == true){
					return this.basicPermissionData.permissions[0];
				}
				else{
					return this.basicPermissionData.permissions;
				}
			}
		},
		userRolesPermission(){
			var finalPermissionsData = this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission;

			if(this.accountType_master != true){
				finalPermissionsData = JSON.stringify(finalPermissionsData);
				finalPermissionsData = finalPermissionsData.replace(/true/g ,'false');
				finalPermissionsData = JSON.parse(finalPermissionsData);
			}

			for(var key in this.currentUserBasicPermissions){
				if(typeof this.currentUserBasicPermissions[key] == 'boolean'){
					finalPermissionsData[key] = this.currentUserBasicPermissions[key];
				}
				else if(typeof this.currentUserBasicPermissions[key] == 'object'){
					for(var key2 in this.currentUserBasicPermissions[key]){
						finalPermissionsData[key][key2] = this.currentUserBasicPermissions[key][key2];
					}
				}
			}

			if(typeof this.currentUserBasicPermissions.live_chat_channels == 'undefined'){
				finalPermissionsData.live_chat_channels = this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission.live_chat_channels;

				//console.log(finalPermissionsData);
			}

			//adding following line as we are not using this currently, so considering all as true, and please un-comment once we want to make it active
			finalPermissionsData.live_chat_channels = this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission.live_chat_channels;

			if(finalPermissionsData.events.create == true){
				finalPermissionsData.events.view = true;
			}
			if(finalPermissionsData.orders.manage == true){
				finalPermissionsData.orders.view = true;
			}
			if(finalPermissionsData.products.create == true){
				finalPermissionsData.products.view = true;
			}
			if(finalPermissionsData.collections.create == true){
				finalPermissionsData.collections.view = true;
			}
			if(finalPermissionsData.bundles.create == true){
				finalPermissionsData.bundles.view = true;
			}
			if(finalPermissionsData.promote.create == true){
				finalPermissionsData.promote.view = true;
			}
			if(finalPermissionsData.restaurant.create == true){
				finalPermissionsData.restaurant.view = true;
			}
			
			return finalPermissionsData;
		},
		subscriptionPlan(){
			if(typeof this.subscriptionDetails.plan == 'undefined'){
				return "free";
			}
			else{
				return this.subscriptionDetails.plan;
			}
		},
		lbc_soshop_MerchantBool(){
			return (this.user.enterprise_Name == 'soshop' || this.user.referallshopName == 'soshop' || this.user.referallshopName == 'soshop1' || this.user.referallshopName == 'soshop2');
		},
		clarins_my_MerchantBool(){
			return (this.user.enterprise_Name == 'clarins_my');
		},
		redRibbon_ph_manila(){
			return (this.user.enterprise_Name == 'redribbon_manila' || this.user.enterprise_Name == 'burgerking_ph' ||this.user.enterprise_Name == 'chowking_ph' || this.user.enterprise_Name == 'manginasal_ph' || this.user.enterprise_Name == 'jollibee_ph' || this.user.enterprise_Name == 'greenwich_ph');
		},
		acommerce_family_user(){
			return (this.user.enterprise_Name == 'eucerin' || this.user.enterprise_Name == 'olay_acom' ||this.user.enterprise_Name == 'jnj_acom_ph' || this.user.enterprise_Name == 'adidas_acom_id' || this.user.enterprise_Name == 'elc_family_th_lamer');
		},
		elc_family_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_') > -1);
		},
		elc_family_sg_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_sg_') > -1);
		},
		elc_family_my_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_my_') > -1);
		},
		elc_family_th_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_th_') > -1);
		},
		elc_family_nz_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_nz_') > -1);
		},
		elc_family_au_user(){
			return (this.user.enterprise_Name?.indexOf('elc_family_au_') > -1);
		},
		alcon_family_user(){
			return (this.user.enterprise_Name?.indexOf('alcon_') > -1);
		},
		ipalfish_family_user(){
			return (this.user.enterprise_Name?.indexOf('ipalfish_') > -1);
		},
		samsung_family_MerchantBool(){
			return (this.user.enterprise_Name?.indexOf('samsung_') > -1);
		},
		intrepid_family_user(){
			return (this.user.enterprise_Name?.indexOf('_intrepid') > -1);
		},
		vonagedemo_MerchantBool(){
			return (this.user.enterprise_Name == 'vonagedemo' || this.user.enterprise_Name?.indexOf('vonagedemo_') > -1);
		},
		loreal_my_org(){
			return (this.user.enterprise_Name == 'kiehlsmalaysia' || this.user.enterprise_Name == 'notkiehlsmalaysia' ||this.user.enterprise_Name == 'lancome' || this.user.enterprise_Name == 'armanibeauty' || this.user.enterprise_Name == 'ysl' || this.user.enterprise_Name == 'lorealmy' || this.user.enterprise_Name == 'biotherm' || this.user.enterprise_Name == 'loreal_my_family_user');
		},
		amorepacific_family_user(){
			return (this.user.enterprise_Name?.indexOf('_amorepacific') > -1);
		},
		amorepacific_family_vn_user(){
			return (this.user.enterprise_Name?.indexOf('_vn_amorepacific') > -1);
		},
		amorepacific_family_my_user(){
			return (this.user.enterprise_Name?.indexOf('_my_amorepacific') > -1);
		},
		loreal_my_family_user(){
			return (this.user.enterprise_Name == 'laneige_my' || this.user.enterprise_Name == 'clarins_my' || this.user.enterprise_Name == 'shiseido_id' || this.user.enterprise_Name == 'uriage_th' || this.user.enterprise_Name == 'frisogold_my' || this.user.enterprise_Name == 'abbott_my' || this.user.enterprise_Name == 'getha' || this.user.enterprise_Name == 'heartlandhealth_sg' || this.user.enterprise_Name == 'countryroad_au' || this.user.enterprise_Name == 'iams_ph' || this.user.enterprise_Name == 'hadalabo_my' || this.user.enterprise_Name == 'jumperdemo' || this.user.enterprise_Name == 'jumperdemo2' || this.user.enterprise_Name == 'nestle_id' || this.loreal_my_org == true || this.elc_family_user == true || this.acommerce_family_user == true || this.alcon_family_user == true || this.ipalfish_family_user == true || this.samsung_family_MerchantBool == true || this.intrepid_family_user == true || this.vonagedemo_MerchantBool == true || this.amorepacific_family_user == true);
		},
		manageUserRoles_v2(){
			return (this.user.referallshopName == 'retaildemo' || this.user.enterprise_Name == 'kiehlsmalaysia' || this.user.enterprise_Name == 'notkiehlsmalaysia' || (this.elc_family_user == true && this.user.enterprise_Name != 'elc_family_sg_lamer' && this.user.enterprise_Name != 'elc_family_sg_jomalone'));
		},
		agentRoutingEnabled_accountLevel(){
			return (this.user.enterprise_Name == 'lbc' || this.user.enterprise_Name == 'smretail_ph' || this.user.enterprise_Name == 'lancome');
		},
		agentRoutingPermissionEnabled(){
			return (this.agentRoutingEnabled_accountLevel == true && this.userRolesPermission.agent_routing == true);
		},
		checkoutDirectAvailable(){
			return ((this.loreal_my_family_user == true || this.user.enterprise_Name == 'samsungid' || this.user.enterprise_Name == 'jumperdemo' || this.IS_LIVE == false) && this.accountServiceType == 'product');
		},
		checkoutDirectPermissionEnabled(){
			return (this.checkoutDirectAvailable == true && this.userRolesPermission.checkout_direct == true);
		},
		clickAndCollectPermissionEnabled(){
			return (this.accountServiceType == 'product' && (this.IS_LIVE == false || this.loreal_my_family_user == true || this.user.enterprise_Name == 'smretail_ph' || this.user.referallshopName == 'cac'));
		},
		bundlePermissionEnabled(){
			return (this.IS_LIVE == false || this.loreal_my_family_user == true);
		},
		bulkUpdateOfShippingLink_permissionEnabled(){
			return (this.IS_LIVE == false || this.loreal_my_family_user == true);
		},
		veryHugeNumberOfProducts_inThousands(){
			return (this.user.enterprise_Name == 'soshop' || this.user.enterprise_Name == 'smretail_ph');
		},
		grabAvailable(){
			return (this.user.usercountry.toUpperCase() == 'SG' || this.user.usercountry.toUpperCase() == 'MY' || this.user.usercountry.toUpperCase() == 'PH');
		},
		squareAvailable(){
			return (this.user.usercountry.toUpperCase() == 'US' || this.user.usercountry.toUpperCase() == 'AU' || this.user.usercountry.toUpperCase() == 'CA' || this.user.usercountry.toUpperCase() == 'UK' || this.user.usercountry.toUpperCase() == 'JP');
		},
		easyshipAvailable(){
			return (this.user.usercountry.toUpperCase() == 'AU' || this.user.usercountry.toUpperCase() == 'CA' || this.user.usercountry.toUpperCase() == 'HK' || this.user.usercountry.toUpperCase() == 'SG' || this.user.usercountry.toUpperCase() == 'US');
		},
		easyparcelAvailable(){
			return (this.user.usercountry.toUpperCase() == 'SG' || this.user.usercountry.toUpperCase() == 'MY' || this.user.usercountry.toUpperCase() == 'ID' || this.user.usercountry.toUpperCase() == 'TH');
		},
		inpostAvailable(){
			return (this.user.usercountry.toUpperCase() == 'PL');
		},
		agentProfilePictureUploadImage(){
			if(this.roleDetails.picture == '' || this.roleDetails.picture == 'None' || this.roleDetails.picture == null) {
				this.roleDetails.picture = 'https://jumper.ai/web-s/resources/images/default-agent-image.svg';
			}

			return this.roleDetails.picture;
		},
		loggedInUser_id(){
			return this.roleDetails.id;
		},
		IS_LIVE(){
			if(process.env.VUE_APP_NODE_ENV.includes('production')) return true;
			else return false;
		},
		IS_ASIA() {
			if(process.env.VUE_APP_NODE_ENV.includes('asia')) return true;
			else return false;
		},
		HOST_NAME() {
			return location.hostname;
		},
		EU_SERVER(){
			if(location.hostname.indexOf('jumperdevnew-messages.appspot.com') > -1 || location.hostname.indexOf('jumper-emea.com') > -1) return true;
			else return false;
		},
		BROADCAST_SERVER(){
			if(location.hostname.indexOf('jumperdevnew-eu.appspot.com') > -1 || location.hostname.indexOf('jumper-broadcast.com') > -1) return true;
			else return false;
		},
		TYMEBANK_SERVER(){
			if(location.hostname.indexOf('jumperdevnew-tymebank.appspot.com') > -1 || location.hostname.indexOf('jumper-tymebank.com') > -1) return true;
			else return false;
		},
		LITE_SERVER(){
			if(location.hostname.indexOf('jumperdevnew-lite.appspot.com') > -1 || location.hostname.indexOf('jumper-lite.com') > -1) return true;
			else return false;
		},
		accountLevelPermissions_string(){
			return this.user.permission ? this.user.permission : "";
		},
		accountLevelPermissions_array(){
			return this.user.permission ? this.user.permission.split(",") : [];
		},
		dashboardName() {
			return this.IS_ASIA ? 'Dashboard' : 'Jumper';
		}
  },
  methods: {
		environment: environment,
		redirectTo(e) {
			this.$router.push({ path: e.detail });
		},
		...mapActions({
      updateToast: "updateToast"
    }),
    moment(date, format){
			if(format) return moment(date, format);
      else return moment(date);
		},
		populateStatespopulateStates( countryElementId, stateElementId ){
	
			var selectedCountryIndex = document.getElementById( countryElementId ).selectedIndex;
			
			var stateElement = document.getElementById( stateElementId );
			
			stateElement.length = 0;
		
			if(document.getElementById( countryElementId ).value == 'Singapore'){
				stateElement.options[0] = new Option('Singapore','Singapore');
			}
			else{
				stateElement.options[0] = new Option('Select State','');
				stateElement.selectedIndex = 0;
				
				if(selectedCountryIndex != -1){
					var state_arr = this.state_arr[selectedCountryIndex].split("|");
		
					console.log(state_arr);
					
					for(var i=0; i<state_arr.length; i++){
						if(state_arr[i] == 'Manila' && state_arr[i+1] == 'Metro Manila'){
							console.log("Empty block statement");
						}
						else{
							stateElement.options[stateElement.length] = new Option(state_arr[i],state_arr[i]);
						}
					}
				}	
			}
		},
		populateShippingStates( countryElementId, stateElementId ){
	
			var selectedCountryIndex = document.getElementById( countryElementId ).selectedIndex;
		
			var stateElement = document.getElementById( stateElementId );
			
			stateElement.length = 0;
			stateElement.selectedIndex = 0;
			
			var state_arr = this.state_arr[selectedCountryIndex].split("|");
			
			for (var i=0; i<state_arr.length; i++) {
				stateElement.options[stateElement.length] = new Option(state_arr[i],state_arr[i]);
			}
		},
		populateCountries(countryElementId, stateElementId, cityElementId, selectCountryPersonalized){
			// given the id of the <select> tag as function argument, it inserts <option> tags
			if(document.getElementById(countryElementId)){
				var countryElement = document.getElementById(countryElementId);
				countryElement.length=0;
		
				if(cityElementId == 'merchantShippingPage'){
					countryElement.options[0] = new Option('Rest of world','*');
				}
				else{
					if(typeof selectCountryPersonalized != 'undefined'){
						countryElement.options[0] = new Option(selectCountryPersonalized,'-1');
					}
					else{
						countryElement.options[0] = new Option('Select Country','-1');
					}
				}
		
				countryElement.selectedIndex = 0;
				for (var i=0; i<country_arr.length; i++) {
					countryElement.options[countryElement.length] = new Option(country_arr[i],country_arr[i]);
				}
		
				// Assigned all countries. Now assign event listener for the states.
		
				if( stateElementId ){
					countryElement.onchange = function(){
						if(cityElementId == 'merchantShippingPage'){
							this.populateShippingStates( countryElementId, stateElementId );
						}
						else{
							if(document.getElementById( countryElementId ).value == 'Singapore'){
								document.getElementById( cityElementId ).value = 'Singapore';
							}
							else{
								document.getElementById( cityElementId ).value = '';
							}
		
							this.populateStates( countryElementId, stateElementId );
						}
					};
				}
			}
		},
    checkIfThereIsNoLogoThenShowTransparentImage(input) {
			if(input) {
				if(typeof input == 'object') input = input[0];
				if(typeof input == 'undefined' || input == null || input == 'None' || input == '') return "https://jumper.ai/web-s/resources/images/transparent-bg.png";
				else if(input.indexOf('.') == -1) return "https://jumper.ai/web-s/resources/images/transparent-bg.png";
				else return input;
			} else return "https://jumper.ai/web-s/resources/images/transparent-bg.png";
    },
		externalSiteImage_convertIntoDownloadableLink(image){
			if(typeof image != 'undefined'){
				if(image != ''){
					if(image.indexOf('drive.google.com/file/d/') > -1 && image.indexOf('/view') > -1){
						let id = image.split('drive.google.com/file/d/');
						id = id[1].split('/view');
						id = id[0];

						return "https://drive.google.com/uc?export=download&id="+ id;
					}
					else if(image.indexOf('drive.google.com/file/') > -1 && image.indexOf('/view') > -1){
						let id = image.split('drive.google.com/file/');
						id = id[1].split('/view');
						id = id[0];

						return "https://drive.google.com/uc?export=download&id="+ id;
					}
					else{
						return image;
					}
				}
				else{
					return image;
				}
			}
		},
    renderDateTime_lastReplyTimestamp(input,format,readableTimeFormat) {
      if(input) {
        var date = new Date();

        input = input.replace(' ', 'T').split('.');
        input = input[0] +'+00:00';
        //check if its a same day or not start
        var inputDate = input.split('T');
        inputDate = inputDate[0].split('-');
        inputDate = inputDate[0] +'-'+ inputDate[1] +'-'+ inputDate[2];
        var currentFullDate = new Date();
        var currentMonth = currentFullDate.getMonth()+1;
        var currentDate = currentFullDate.getDate();
        var currentYear = currentFullDate.getFullYear();
        if(parseInt(currentDate) < 10){
          currentDate = "0"+ currentDate;
        }
        if(parseInt(currentMonth) < 10){
          currentMonth = "0"+ currentMonth;
        }
        currentFullDate = currentYear +'-'+ currentMonth +'-'+ currentDate;
        var isSameDay = moment(inputDate).isSame(currentFullDate);
        var isSameYear = moment(inputDate).isSame(currentFullDate, 'year');
        //check if its a same day or not end
        if(format == 'customReadable'){
          if(isSameDay == true){
            input = moment(input).format('LT');
          }
          else{
            if(isSameYear == true){
              input = moment(input).format('MMM DD, LT');
            }
            else{
              input = moment(input).format('MMM DD, YYYY LT');
            }
          }
          return input;
        }
        else{
          input = moment(input).format();
          input = input.split('T');
          var dateFull = input[0].split('-');
          if(format == 'YMDHS'){
            date = dateFull[0] +'-'+ dateFull[1] +'-'+ dateFull[2];
          }
          else{
            date = dateFull[2] +'-'+ dateFull[1] +'-'+ dateFull[0];
          }
          var time = input[1].split(':');
          var h = time[0];
          var m = time[1];
          var s = "";
          if(time[2].indexOf('+') > -1){
            s = time[2].split('+');
          }
          else{
            s = time[2].split('-');
          }
          time = h +':'+ m +':'+ s[0];
          if(readableTimeFormat == true){
            return moment(dateFull[0] +''+ dateFull[1] +''+ dateFull[2] +''+ h +''+ m, "YYYYMMDDHHmm").fromNow();
          }
          else{
            return date +' '+ time;
          }
        }
      } return undefined;
    },
    renderFormattedMessage(message){
			return message.replace(/<br>/g, '\n');
    },
    renderLinkHttps(link,secure){
			if(typeof link != 'undefined')
				if(link.indexOf('http://') > -1 || link.indexOf('https://') > -1) return link;
				else 
					if(secure == false) return "http://"+ link;
					else return "https://"+ link;
			else return undefined

    },
    removeFromArray(arr){
			var what, a = arguments, L = a.length, ax;
			while (L > 1 && arr.length) {
				what = a[--L];
				while ((ax= arr.indexOf(what)) !== -1) {
					arr.splice(ax, 1);
				}
			}
			return arr;
    },
    removeFromObject(obj){
			var what, a = arguments, L = a.length;
			while (L > 1 && Object.keys(obj).length) {
				what = a[--L];
				if(Object.keys(obj).includes(what)){
					delete obj[what];
				}
			}
			return obj;
    },
    removeObjectFromArrayByKey(arr){
			var whatKey, whatVal, l, a = arguments, L = a.length;
			while (L > 1 && arr.length){
				whatVal = a[--L];
				whatKey = a[--L];
				l = arr.length;
				while(l--){
					if(arr[l] && Object.prototype.hasOwnProperty.call(arr[l], whatKey) && arr[l][whatKey] === whatVal){
						arr.splice(l,1);
					}
				}
			}
			return arr;
    },
    validateMobile(mobile, countrycode){
			var re = "";
			if(countrycode == '+65'){
				re = /^[0-9]\d{7}$/;
				return re.test(mobile);
			}
			else if(countrycode == '+91'){
				re = /^[6789]\d{9}$/;
				return re.test(mobile);
			}
    },
    validateEmail(email){
			//eslint-disable-next-line
			var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		validateUrl(url){
			//eslint-disable-next-line
			var re = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
							'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
							'((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
							'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
							'(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
							'(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
			return !!re.test(url);
    },
    unique(list){
			var result = [];
			list.forEach(e => result.indexOf(e) === -1 ? result.push(e) : 0);
			return result;
    },
    uniqueObjectiveArray(list, varname){
			let b = [];
			list.forEach(event => {
				let events = b.filter(e => {
					if(varname == 'id') return event.id === e.id;
					else if(varname == 'vertex') return event.vertex === e.vertex;
					else return event.currency_code === e.currency_code;
				})
				if(events.length === 0) b.push(event);
			})
			return b;
    },
    renderImage(input){
			if(input){
				if(typeof input == 'object' && input.length > 0){
					input = input[0];
					if(input.length >= 3){
						input = input.split(', ');
						input = input[0].replace('[','').replace(']','').replace(/'/g ,'');
						return this.checkIfThereIsNoLogoThenShowTransparentImage(input);
					}
				} else {
					input = input.split(', ');
					input = input[0].replace('[','').replace(']','').replace(/'/g ,'');
					return this.checkIfThereIsNoLogoThenShowTransparentImage(input);
				}
			} else return undefined;
    },
    renderDateOnlyWithSlash(input){
			if(input){
				input = input.split('-');
				input = input[2] + '/' + input[1] + '/' + input[0];
				return input;
			} else return undefined;
    },
    renderPaymentModeName(gateway){
			let gatewayNames = {
				'stripe': "Card",
				'stripe3d': "Card",
				'square': "Square",
				'payu': "PayU",
				'razorpay': "Razorpay",
				'paytm': "Paytm",
				'androidpay': "Google pay",
				'applepay': "Apple pay",
				'Paypal': "PayPal",
				'paypal': "PayPal",
				'alipay': "Alipay",
				'bank': "Bank transfer",
				'manual pay': "Bank transfer",
				'banktransfer': "Bank transfer",
				'meetme': "Meet & pay",
				'link': "Payment link",
				'cash on delivery': "Cash on Delivery",
				'midtrans': "Midtrans Credit/Debit Card",
				'midtrans-bank': "Midtrans Bank Transfer",
				'midtrans-installment': "Midtrans Installment",
				'pesopay': "Pesopay",
				'wirecard': "Wirecard",
				'enets': "eNETS",
				'2c2p': "2c2p",
				'pg2c2p': "2c2p",
				'payulatam': "PayU Latam",
				'billplz': "Billplz",
				'paystack': "Paystack",
				'bblipay': "BBL Merchant iPay",
				'bblqr': "BBL Thai QR Payment",
				'adyen': "Adyen",
				'twocheckout': "2checkout",
				'2checkout': "2checkout",
				'authorize': "Authorize.Net",
				'authorizenet': "Authorize.Net",
				'paymaya': "PayMaya",
				'gcash': "GCash",
				'ipay88': "iPay88",
				'paymongo': "PayMongo",
				'iyzico': "Iyzico",
				'ngenius': "N-Genius",
				'checkoutcom': "Checkout.com",
				'hoolah': "hoolah",
				'atome': "Atome",
				'vnpay': "VNPAY",
				'momo': "MoMo",
				'worldpay': "Worldpay",
				'mollie': "Mollie",
				'eghl': "eGHL",
				'toyyibpay': "toyyibPay",
				'cybersource': "CyberSource",
				'whatsapp pay': "WhatsApp"
			}
			if(gateway) {
				if(gateway == 'paymaya'){
					if(this.user.enterprise_Name == 'greenwich_ph'){
						return "Online Payment - PayMaya";
					}
					else if(this.redRibbon_ph_manila == true){
						return "Credit Card - PayMaya";
					}
					else{
						return "PayMaya";
					}
				}
				else if(gateway == 'gcash'){
					if(this.user.enterprise_Name == 'greenwich_ph'){
						return "Online Payment - GCash";
					}
					else if(this.redRibbon_ph_manila == true){
						return "Credit Card - GCash";
					}
					else{
						return "GCash";
					}
				}
				else return (gatewayNames[gateway] ? gatewayNames[gateway] : gateway)
			} else return undefined
    },
    checkIfPaymentModeIsManualOrNotForSingleOrder(gateway){
			let allowedGateways = ['bank', 'meetme', 'link', 'cash on delivery', 'cash on arrival', 'cod', 'COD', 'bblqr', 'midtrans-bank', 'manual pay', 'banktransfer'];
			if(gateway && allowedGateways.indexOf(gateway) > -1) return true;
			else return false;
		},
		getLocaleName(input) {
			if (input)
				for (var i = 0; i < localeFullFormData.localeFullFormData.length; i++)
					if (localeFullFormData.localeFullFormData[i].id == input || localeFullFormData.localeFullFormData[i].source == input) return localeFullFormData.localeFullFormData[i].name;
			return undefined;
		},
		getFormattedTimezone(input, compareWith) {
			if (input)
				for (var i = 0; i < allTimezonesData.allTimezonesData.length; i++)
					if(compareWith == 'slashName' && allTimezonesData.allTimezonesData[i].slashName == input) return allTimezonesData.allTimezonesData[i].id;
					else if (allTimezonesData.allTimezonesData[i].source == input) return "GMT " + allTimezonesData.allTimezonesData[i].id;
					else return undefined;
			return undefined;
		},
		getMobileNetworkName_fromMccMncList(mcc, mnc) {
			if (typeof mcc != 'undefined' && typeof mnc != 'undefined') {
				if (mcc != '' && mcc != 'None' && mcc != null && mnc != '' && mnc != 'None' && mnc != null) {
					for (var i = 0; i < mcc_mnc_country_network_list.mcc_mnc_country_network_list.length; i++) {
						if (mcc_mnc_country_network_list.mcc_mnc_country_network_list[i].mcc == mcc && mcc_mnc_country_network_list.mcc_mnc_country_network_list[i].mnc == mnc) {
							if (typeof mcc_mnc_country_network_list.mcc_mnc_country_network_list[i].network != 'undefined') {
								return mcc_mnc_country_network_list.mcc_mnc_country_network_list[i].network;
							} else {
								return "Unknown";
							}
						}
					}
				}
			}
		},
		getFullCountryNameFrom2CharName(input) {
			if (input)
				for (var i = 0; i < allCountryCurrencyData.allCountryCurrencyData.length; i++)
					if (input.toLowerCase() == allCountryCurrencyData.allCountryCurrencyData[i].country_short_code.toLowerCase()) return allCountryCurrencyData.allCountryCurrencyData[i].country_name;
			return undefined;
		},
		splitFlowTriggersIntoCommas(triggers) {
			if (typeof triggers != 'undefined') {
				return triggers.join(', ');
			}
		},
		getFirstFlowTriggerValue(triggers) {
			if (typeof triggers != 'undefined') {
				return triggers[0];
			}
		},
		joinArrayIntoCommaSeparatedString(list) {
			if (typeof list != 'undefined') {
				list = list.join(', ');
		
				return list;
			}
		},
		getFirstTriggerOfBotFlow(triggers) {
			if (typeof triggers != 'undefined') {
				return triggers[0];
			}
		},
		checkForValidSalesChannelOfFlowAndUser(salesChannelsOfFlow, customerChannel) {
			var match = false;
			if (typeof salesChannelsOfFlow != 'undefined') {
				for (var i = 0; i < salesChannelsOfFlow.length; i++) {
					if (salesChannelsOfFlow[i] == customerChannel) {
						match = true;
					}
		
					if ((salesChannelsOfFlow[i] == 'web' || salesChannelsOfFlow[i] == 'webbot' || salesChannelsOfFlow[i] == 'bot') && (customerChannel == 'web' || customerChannel == 'webbot' || customerChannel == 'bot')) {
						match = true;
					}
				}
			}
			return match;
		},
		renderMobileCountryCodeAccordingToMobileNo(mobilecountrycode, mobileNumber) {
			if (typeof mobilecountrycode != 'undefined') {
				if (mobileNumber != null && mobileNumber != '' && mobileNumber != 'None') {
					if (mobileNumber.length == 8 && mobilecountrycode != '+65' && mobilecountrycode == '+91') {
						return "+65";
					} else {
						return mobilecountrycode;
					}
				} else {
					return mobilecountrycode;
				}
			}
		},
		convertCommaIntoCommaAndSpace(stringText) {
			if (typeof stringText != 'undefined') {
				if (stringText.indexOf(',') > -1) {
					return stringText.replace(/,/g, ', ');
				} else {
					return stringText;
				}
			}
		},
		renderDateReadable_moment(input) {
			if (input) {
				input = input.replace(' ', 'T').split('.');
				input = input[0] + '+00:00';
				input = moment(input).format('LL');
				return input;
			}
			return undefined;
		},
		renderDateTime_optionalMoment(input, format, readableTimeFormat) {
			if (input) {
				input = input.replace(' ', 'T').split('.');
				input = input[0] + '+00:00';
				input = moment(input).format();
		
				input = input.split('T');
		
				var dateFull = input[0].split('-');
		
				var date = "";

				if (format == 'YMDHS') {
					date = dateFull[0] + '-' + dateFull[1] + '-' + dateFull[2];
				} else {
					date = dateFull[2] + '-' + dateFull[1] + '-' + dateFull[0];
				}
		
				var time = input[1].split(':');
				var h = time[0];
				var m = time[1];
				var s = "";
				if (time[2].indexOf('+') > -1) {
					s = time[2].split('+');
				} else {
					s = time[2].split('-');
				}
				time = h + ':' + m + ':' + s[0];
		
				if (readableTimeFormat == true) {
					return moment(dateFull[0] + '' + dateFull[1] + '' + dateFull[2] + '' + h + '' + m, "YYYYMMDDHHmm").fromNow();
				} else {
					return date + ' ' + time;
				}
			} return undefined;
		},
		whitelistedFileHexes(hex) {
			if (typeof hex != 'undefined') {
				if (hex == '89504E47' || hex == '89504E470D0A1A0A' || hex == 'FFD8FFE0' || hex == 'FFD8FFE1' || hex == 'FFD8FFDB' || hex == '47494638' || hex == '3C3F786D' || hex == '00020' || hex == '00014' || hex == '4F726465' || hex == '68617368' || hex == '504B34' || hex == '504B0304' || hex == '504B0506' || hex == 'FFFB' || hex == '494433' || hex == 'D0CF11E0' || hex == 'FFD8FFE2' || hex == '25504446' || hex == 'd0cf11e0') {
					return true;
				} else {
					return false;
				}
			}
		},
		validateImage(file, size) {
			return new Promise((resolve, reject) => {
				try {
					let extension = file.name.split('.').pop();
					switch(extension) {
						case 'jpg':
						case 'jpeg':
						case 'png':
						case 'PNG':
						case 'JPG':
						case 'JPEG':
						case 'gif': {
							let fileSize = file.size/1024/1024;
							fileSize = parseFloat(fileSize).toFixed(2);
							
							if(fileSize <= size) {
								let filereader = new FileReader();
								filereader.onloadend = (evt) => {
									if(evt.target.readyState === FileReader.DONE){
										let uint = new Uint8Array(evt.target.result);
										let bytes = [];
										uint.forEach((byte) => {
											bytes.push(byte.toString(16))
										});
										let hex = bytes.join('').toUpperCase();
										if(this.whitelistedFileHexes(hex)){
											resolve(true);
										} else {
											this.updateToast({
												open: true,
												message: "Sorry! We couldn't read the uploaded file. Please upload a valid image file",
												type: "danger"
											})
											resolve(false);
										}
									}
								}
								let blob = file.slice(0, 4);
								filereader.readAsArrayBuffer(blob);
							} else {
								this.updateToast({
									open: true,
									message: "You can't upload an image of more than " + size + " MB in size!",
									type: "danger"
								})
								console.timeEnd("You can't upload an image of more than " + size + " MB in size!");
								resolve(false);
							}
						}
						break;
						default:
							this.updateToast({ open: true, message: "Please upload only .png, .jpeg, or .gif image.", type: "danger" })
							console.timeEnd("Please upload only .png, .jpeg, or .gif image.");
							resolve(false);
					}
				} catch (error) {
					console.log("Exception in validating picture", error);
					reject(error);
				}
			})
		},
		validateVideo(file, size) {
			return new Promise((resolve, reject) => {
				try {
					let extension = file.name.split('.')[1];
					switch(extension) {
						case 'mp4':
						case 'mpeg4':
						case 'MP4':
						case 'MPEG4': {
							let fileSize = file.size/1024/1024;
							fileSize = parseFloat(fileSize).toFixed(2);
							
							if(fileSize <= size) {
								let filereader = new FileReader();
								filereader.onloadend = (evt) => {
									if(evt.target.readyState === FileReader.DONE){
										resolve(true);
									}
								}
								let blob = file.slice(0, 4);
								filereader.readAsArrayBuffer(blob);
							} else {
								this.updateToast({
									open: true,
									message: "You can't upload a video of more than " + size + " MB in size!",
									type: "danger"
								})
								console.timeEnd("You can't upload a video of more than " + size + " MB in size!");
								resolve(false);
							}
						}
						break;
						default:
							this.updateToast({
								open: true,
								message: "Please upload only .mp4, or .mpeg video.",
								type: "danger"
							})
							console.timeEnd("Please upload only .mp4, or .mpeg video.");
							resolve(false);
					}
				} catch (error) {
					console.log("Exception in validating video", error);
					reject(error);
				}
			})
		},
		validateDocument(file, size) {
			return new Promise((resolve, reject) => {
				try {
					let extension = file.name.split('.')[1];
					switch(extension) {
						case 'csv':
						case 'xls':
						case 'xlsx':
						case 'docx':
						case 'doc':
						case 'docm':
						case 'txt':
						case 'rtf':
						case 'wps':
						case 'gif':
						case 'ppt':
						case 'pdf': {
							let fileSize = file.size/1024/1024;
							fileSize = parseFloat(fileSize).toFixed(2);
							
							if(fileSize <= size) {
								let filereader = new FileReader();
								filereader.onloadend = (evt) => {
									if(evt.target.readyState === FileReader.DONE){
										resolve(true);
									}
								}
								let blob = file.slice(0, 4);
								filereader.readAsArrayBuffer(blob);
							} else {
								this.updateToast({
									open: true,
									message: "You can't upload a document of more than " + size + " MB in size!",
									type: "danger"
								})
								console.timeEnd("You can't upload a document of more than " + size + " MB in size!");
								resolve(false);
							}
						}
						break;
						default:
							this.updateToast({
								open: true,
								message: "The uploaded file format is not supported.",
								type: "danger"
							})
							console.timeEnd("Please upload only .pdf file.");
							resolve(false);
					}
				} catch (error) {
					console.log("Exception in validating document", error);
					reject(error);
				}
			})
		},
		uploadImage(file, paramsUrl) {
			return new Promise((resolve, reject) => {
				try {
					jwtInterceptor.get(environment('VUE_APP_ROOT_API') + "begin-upload", { params: { url: paramsUrl } }).then((uploadResponse) => {
						if ((uploadResponse.status == 200 || uploadResponse.status == 201) && uploadResponse.data.success) {
							let uploadUrl = uploadResponse.data.url;
							let fd = new FormData();
							fd.append('file0', file);
							jwtInterceptor.post(uploadUrl, fd).then((uploadSendResponse) => {
								const { data, status } = uploadSendResponse;
								if ([200, 201].includes(status) && (data.url || data.shopLogo || data?.data?.h || data.success)) {
									resolve(uploadSendResponse.data)
								} else {
									console.log("Response:", uploadSendResponse);
									if(uploadResponse.data.errorMessage && uploadResponse.data.errorMessage.length) {
										this.updateToast({
											open: true,
											message: uploadSendResponse.data.errorMessage,
											type: "danger"
										})
										reject(uploadSendResponse.data)
									} else {
										this.updateToast({
											open: true,
											message: "Something went wrong",
											type: "danger"
										})
										reject(false)
									}
								}
							});
						} else {
							console.log("Response:", uploadResponse);
							if(uploadResponse.data.errorMessage && uploadResponse.data.errorMessage.length) {
								this.updateToast({
									open: true,
									message: uploadResponse.data.errorMessage,
									type: "danger"
								})
								reject(false)
							} else {
								this.updateToast({
									open: true,
									message: "Something went wrong",
									type: "danger"
								})
								reject(false)
							}
						}
					});
				} catch (error) {
					console.log("Exception", error);
					this.updateToast({
						open: true,
						message: "Something went wrong!",
						type: "danger"
					})
					reject(false)
				}
			})
		},
		uploadVideo(file, paramsUrl) {
			return new Promise((resolve, reject) => {
				try {
					jwtInterceptor.get(environment('VUE_APP_ROOT_API') + "begin-upload", { params: { url: paramsUrl } }).then((uploadResponse) => {
						if ((uploadResponse.status == 200 || uploadResponse.status == 201) && uploadResponse.data.success) {
							let uploadUrl = uploadResponse.data.url;
							let fd = new FormData();
							fd.append('file0', file);
							jwtInterceptor.post(uploadUrl, fd).then((uploadSendResponse) => {
								if ((uploadSendResponse.status == 200 || uploadSendResponse.status == 201) && (uploadSendResponse.data.url || uploadSendResponse.data?.data?.h)) {
									resolve(uploadSendResponse.data)
								} else {
									console.log("Response:", uploadSendResponse);
									if(uploadResponse.data?.errorMessage && uploadResponse.data.errorMessage.length) {
										this.updateToast({
											open: true,
											message: uploadSendResponse.data.errorMessage,
											type: "danger"
										})
										reject(uploadSendResponse.data)
									} else {
										this.updateToast({
											open: true,
											message: "Something went wrong",
											type: "danger"
										})
										reject(false)
									}
								}
							});
						} else {
							console.log("Response:", uploadResponse);
							if(uploadResponse.data.errorMessage && uploadResponse.data.errorMessage.length) {
								this.updateToast({
									open: true,
									message: uploadResponse.data.errorMessage,
									type: "danger"
								})
								reject(false)
							} else {
								this.updateToast({
									open: true,
									message: "Something went wrong",
									type: "danger"
								})
								reject(false)
							}
						}
					});
				} catch (error) {
					console.log("Exception", error);
					this.updateToast({
						open: true,
						message: "Something went wrong!",
						type: "danger"
					})
					reject(false)
				}
			})
		},
		uploadDocument(file, paramsUrl){
			return new Promise((resolve, reject) => {
				try {
					jwtInterceptor.get(environment('VUE_APP_ROOT_API') + "begin-upload", { params: { url: paramsUrl } }).then((uploadResponse) => {
						if ((uploadResponse.status == 200 || uploadResponse.status == 201) && uploadResponse.data.success) {
							let uploadUrl = uploadResponse.data.url;
							let fd = new FormData();
							fd.append('file0', file);
							jwtInterceptor.post(uploadUrl, fd).then((uploadSendResponse) => {
								if ((uploadSendResponse.status == 200 || uploadSendResponse.status == 201) && (uploadSendResponse.data.url || uploadSendResponse.data.data.h)) {
									resolve(uploadSendResponse.data)
								} else {
									console.log("Response:", uploadSendResponse);
									if(uploadResponse.data.errorMessage && uploadResponse.data.errorMessage.length) {
										this.updateToast({
											open: true,
											message: uploadSendResponse.data.errorMessage,
											type: "danger"
										})
										reject(uploadSendResponse.data)
									} else {
										this.updateToast({
											open: true,
											message: "Something went wrong",
											type: "danger"
										})
										reject(false)
									}
								}
							});
						} else {
							console.log("Response:", uploadResponse);
							if(uploadResponse.data.errorMessage && uploadResponse.data.errorMessage.length) {
								this.updateToast({
									open: true,
									message: uploadResponse.data.errorMessage,
									type: "danger"
								})
								reject(false)
							} else {
								this.updateToast({
									open: true,
									message: "Something went wrong",
									type: "danger"
								})
								reject(false)
							}
						}
					});
				} catch (error) {
					console.log("Exception", error);
					this.updateToast({
						open: true,
						message: "Something went wrong!",
						type: "danger"
					})
					reject(false)
				}
			})
		},
		contentEditableToFormattedTextWithLineBreak(input) {
			if (input) {
				input = input.replace(/<div>/g, '\n').replace(/<\/div>/g, '').replace(/<br>/g, '\n');
				input = input.replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ');
				return input;
			} return input;
		},
		formatContentEditableDiv() {
			//pending
		},
		genericSanitizeHtml(input) {
			if (input) {
				if (input == '' || input == null || input == 'None') {
					return input;
				} else {
					var words_arr = input.split(' ');
		
					for (var i in words_arr) {
						var single_word = words_arr[i];
		
						if (single_word.indexOf('=') > -1) {
							var fullstring = single_word.split('=');
							var first_string = fullstring[0];
							var leftOut_string = fullstring[1];
		
							if (first_string != 'href' && first_string != 'src' && first_string != 'style') {
								words_arr[i] = first_string + "ext=" + leftOut_string;
							}
						}
					}
		
					var outputText = words_arr.join(' ');
		
					if (outputText.indexOf('<script>') > -1 || outputText.indexOf('</script>') > -1) {
						//outputText = outputText.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/\//g, '&#x2F;');
					}
		
					outputText = outputText.replace(/<script>/g, 'na').replace(/<\/script>/g, 'na');
		
					//outputText = outputText.replace(/</g, '&lt;').replace(/>/g, '&gt;');
					//outputText = outputText.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/\//g, '&#x2F;');
		
					//return $sce.trustAsHtml(outputText);
					return outputText;
				}
			} return undefined;
		},
		renderNormalChat(input) {
			if (typeof input != 'undefined' && input != null && input != 'None' && input != '') {
				var outputText = this.genericSanitizeHtml(input);
				outputText = outputText.replace(/</g, '&lt;').replace(/>/g, '&gt;');
				outputText = outputText.replace(/:::::/g, "<br> ").replace(/(?:\r\n|\r|\n)/g, '<br> ');
		
				var words_arr = outputText.split(' ');
		
				for (var i in words_arr) {
					if (words_arr[i].indexOf('http://') > -1 || words_arr[i].indexOf('https://') > -1) {
						words_arr[i] = '<a href="' + words_arr[i] + '" target="_blank">' + words_arr[i] + '</a>';
					}
				}
		
				outputText = words_arr.join(' ');
		
				//return $sce.trustAsHtml(outputText);
				return outputText;
			}
		},
		sanitizeHardLinkUrl(input) {
			if (typeof input != 'undefined') {
				if(typeof input == 'object'){
					input = input[0];
				}

				if (input == '' || input == null || input == 'None') {
					return input;
				}
				else if(input.indexOf('http://') == -1 && input.indexOf('https://') == -1 && input.indexOf('www.') == -1 && input.indexOf('data:') == -1){
					return "https://jumper.ai/web-s/resources/images/transparent-bg.png";
				}
				else {
					input = input.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '');
		
					return input;
				}
			}
		},
		checkIfItsNotDefaultLogo(input) {
			if (input && input == this.shopLogoPlaceholderImageUrl) return "https://jumper.ai/web-s/resources/images/upload-image-placeholder-purple.svg";
			return input;
		},
		getChannelFromPageActive(pageActive, strictBool) {
			if (typeof pageActive != 'undefined') {
				var channel = "";
		
				if (pageActive == '' || pageActive == 'None' || pageActive == null) {
					if (this.messengerConnected == true) {
						channel = "facebook";
					} else if (this.whatsappConnected == true) {
						channel = "whatsapp";
					} else if (this.instagramConnected == true) {
						channel = "instagram";
					} else if (this.viberConnected == true) {
						channel = "viber";
					} else if (this.telegramConnected == true) {
						channel = "telegram";
					} else if (this.smsConnected == true) {
						channel = "sms";
					} else if (this.gBusinessMessagesConnected == true) {
						channel = "googlebusinessmsg";
					} else if (this.lineConnected == true) {
						channel = "line";
					} else if (this.imessageConnected == true) {
						channel = "imessage";
					} else if (this.adlingoConnected == true) {
						channel = "adlingo";
					} else {
						channel = "web";
					}
				}
				else {
					var fbPageItIs = false;
		
					if(this.messengerConnected == true){
						if(typeof this.facebookSocialData.pages != 'undefined') {
							for(var i = 0; i < this.facebookSocialData.pages.length; i++) {
								if(pageActive == this.facebookSocialData.pages[i].messenger) {
									fbPageItIs = true;
								}
							}
						}	
					}

					var igPageItIs = false;
		
					if(this.instagramConnected == true){
						if(typeof this.instagramSocialData != 'undefined') {
							for(var j = 0; j < this.instagramSocialData.length; j++) {
								if(pageActive == this.instagramSocialData[j].id || pageActive == this.instagramSocialData[j].pageid) {
									igPageItIs = true;
								}
							}
						}	
					}
		
					if (fbPageItIs == true || pageActive == 'messenger' || pageActive == 'facebook') {
						channel = "facebook";
					}
					else if (this.whatsappConnected == true && (pageActive == this.whatsAppSocialData.id || pageActive == this.whatsAppSocialData.business_id || pageActive == 'whatsapp')) {
						channel = "whatsapp";
					}
					else if (this.imessageConnected == true && (pageActive == this.imessageSocialData.businessid || pageActive == this.imessageSocialData.id || pageActive == 'imessage')) {
						channel = "imessage";
					}
					else if (this.instagramConnected == true && (igPageItIs == true || pageActive == 'instagram')) {
						channel = "instagram";
					}
					else if (this.twitterConnected == true && (pageActive == this.twitterSocialData.twitterId || pageActive == 'twitter')) {
						channel = "twitter";
					}
					else if (this.lineConnected == true && (pageActive == this.lineSocialData.linechannelid || pageActive == this.lineSocialData.channelaccesstoken || pageActive == 'line')) {
						channel = "line";
						//pending: channelaccesstoken for line doesn't come in fetch-social
					}
					else if (this.adlingoConnected == true && (pageActive == this.adlingoSocialData.id || pageActive == 'adlingo')) {
						channel = "adlingo";
					}
					else if (this.viberConnected == true && (pageActive == this.viberSocialData.channelname || pageActive == this.viberSocialData.pageid || pageActive == 'viber')) {
						channel = "viber";
					}
					else if (this.telegramConnected == true && (pageActive == this.telegramSocialData.id || pageActive == this.telegramSocialData.botname || pageActive == 'telegram')) {
						channel = "telegram";
					}
					else if (this.smsConnected == true && (pageActive == this.smsSocialData.account_name || pageActive == this.smsSocialData.id || pageActive == this.smsSocialData.mobile_number || pageActive == 'sms')) {
						channel = "sms";
					}
					else if (this.gBusinessMessagesConnected == true && (pageActive == this.gBusinessMessagesSocialData.bot_name || pageActive == 'googlebusinessmsg')) {
						channel = "googlebusinessmsg";
					}
					else if (pageActive == this.user.referallshopName || pageActive == 'web' || pageActive == 'webbot') {
						channel = "web";
					}
					else {
						if (strictBool == true) {
							channel = "INVALID_MERCHANT";
						}
						else {
							channel = "web";
						}
					}
				}
		
				return channel;
			}
		},
		getFacebookPageName(id) {
			if (typeof id != 'undefined') {
				if (id == 'facebook' || id == 'messenger') {
					return "Messenger";
				}
				else {
					if(this.messengerConnected == true){
						if(typeof this.facebookSocialData.pages != 'undefined') {
							for(var i = 0; i < this.facebookSocialData.pages.length; i++) {
								if(id == this.facebookSocialData.pages[i].messenger) {
									return this.facebookSocialData.pages[i].name;
								}
							}
						}	
					}
				}
			}
		},
		formatAmountInLocale(total, currency) {
			if (typeof total != 'undefined') {
				if (currency == 'Rp' || currency == 'Rp ' || currency == 'IDR' || currency == 'idr') {
					total = parseFloat(total);
		
					total = total.toFixed(2);
		
					return Number(total).toLocaleString('id-ID');
				} else {
					return total;
				}
			} else return undefined;
		},
		getDeviceTypeFromUseragent(userAgentText) {
			if (typeof userAgentText != 'undefined') {
				var info = window.platform.parse(userAgentText);
		
				var result = "Unknown";
		
				if (typeof info != 'undefined') {
					if (typeof info.description != 'undefined') {
						result = info.description;
					}
				}
		
				return result;
			}
		},
		getBrowserNameFromUseragent(userAgentText) {
			if (typeof userAgentText != 'undefined') {
				var info = window.platform.parse(userAgentText);
		
				var result = "Unknown";
		
				if (typeof info != 'undefined') {
					if (typeof info.name != 'undefined') {
						result = info.name;
					}
		
					if (typeof info.version != 'undefined') {
						result = result + " (" + info.version + ")";
					}
				}
		
				return result;
			}
		},
		checkIfVariableExistInJsonObject(jsonObject,variableName){
			if(typeof variableName != 'undefined'){
				if(typeof jsonObject != 'undefined'){
					if(typeof jsonObject[variableName] != 'undefined'){
						return true;
					}
					else{
						return false;
					}
				}
			}
		},
		renderCustomerImage_style_background(input){
      if(input) {
        input = this.checkIfThereIsNoLogoThenShowTransparentImage(input);
        return "background: url("+ input +") no-repeat;";
      }
    },
    getAgentNameInitials(fullName){
      if(typeof fullName != 'undefined'){
        if(fullName.indexOf(' ') > -1){
          fullName = fullName.split(' ');

          var fname = fullName[0];
          var lname = fullName[1];

          return fname.charAt(0) +""+ lname.charAt(0);
        }
        else{
          return fullName.charAt(0) +""+ fullName.charAt(1);
        }
      }
		},
    prevPagesGenericPagination(changeByPaginationSize){
			if(this.paginationOffsetGenericPagination != 0) {
				if(changeByPaginationSize) {
					this.paginationOffsetGenericPagination = this.paginationOffsetGenericPagination > this.paginationSizeGenericPagination ? this.paginationOffsetGenericPagination - this.paginationSizeGenericPagination : 0;
				}
				else{
					this.paginationOffsetGenericPagination--;
				}
			}
    },
    nextPagesGenericPagination(changeByPaginationSize,lenPageList){
			if(this.paginationOffsetGenericPagination + this.paginationSizeGenericPagination < lenPageList){
				if(changeByPaginationSize){
					this.paginationOffsetGenericPagination = this.paginationOffsetGenericPagination + (this.paginationSizeGenericPagination * 2) < lenPageList ? this.paginationOffsetGenericPagination + this.paginationSizeGenericPagination : lenPageList - this.paginationSizeGenericPagination;
				}
				else{
					this.paginationOffsetGenericPagination++;
				}
			}
    },
		countryCodeWithout_plusSign(input){
			if(input && input.indexOf('+') > -1) return input.replace('+','');
			return input;
		},
		feedback_of_initFeaturesBasedOnPermission(){
			console.log("currentPlanName", this.currentPlanName_forntendUseOnly);
		},
    csvExportWithDateRangeModal_open(id,name){
      this.csvExportWithDateRangeModal_type = id;
      this.csvExportWithDateRangeModal_title = name;

      this.csvExportWithDateRangeModal = true;
		}
	}
}