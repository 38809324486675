<template>
  <ion-card-content class="facebook-post-wrapper">
    <ion-item
      lines="none"
      class="ion-no-padding"
    >
      <div class="facebook-post-header">
        <div class="fb-icon-container">
          <img
            height="40"
            :src="pageIconUrl"
          />
          <div class="text-container">
            <p class="first-line">
              <a
                :href="pageUrl"
                target="_blank"
              >
                {{pageName}}
              </a>
            </p>
            <div class="second-line">
              <a href="#">16 August at 11:42 · </a>
              <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                class="b6ax4al1 m4pnbp5e somyomsx ahndzqod gnhxmgs4 k250bvdn cv5aopd8"
                title="Shared with Public"
              >
                <title>Shared with Public</title>
                <g
                  fill-rule="evenodd"
                  transform="translate(-448 -544)"
                >
                  <g>
                    <path
                      d="M109.5 408.5c0 3.23-2.04 5.983-4.903 7.036l.07-.036c1.167-1 1.814-2.967 2-3.834.214-1 .303-1.3-.5-1.96-.31-.253-.677-.196-1.04-.476-.246-.19-.356-.59-.606-.73-.594-.337-1.107.11-1.954.223a2.666 2.666 0 0 1-1.15-.123c-.007 0-.007 0-.013-.004l-.083-.03c-.164-.082-.077-.206.006-.36h-.006c.086-.17.086-.376-.05-.529-.19-.214-.54-.214-.804-.224-.106-.003-.21 0-.313.004l-.003-.004c-.04 0-.084.004-.124.004h-.037c-.323.007-.666-.034-.893-.314-.263-.353-.29-.733.097-1.09.28-.26.863-.8 1.807-.22.603.37 1.166.667 1.666.5.33-.11.48-.303.094-.87a1.128 1.128 0 0 1-.214-.73c.067-.776.687-.84 1.164-1.2.466-.356.68-.943.546-1.457-.106-.413-.51-.873-1.28-1.01a7.49 7.49 0 0 1 6.524 7.434"
                      transform="translate(354 143.5)"
                    ></path>
                    <path
                      d="M104.107 415.696A7.498 7.498 0 0 1 94.5 408.5a7.48 7.48 0 0 1 3.407-6.283 5.474 5.474 0 0 0-1.653 2.334c-.753 2.217-.217 4.075 2.29 4.075.833 0 1.4.561 1.333 2.375-.013.403.52 1.78 2.45 1.89.7.04 1.184 1.053 1.33 1.74.06.29.127.65.257.97a.174.174 0 0 0 .193.096"
                      transform="translate(354 143.5)"
                    ></path>
                    <path
                      fill-rule="nonzero"
                      d="M110 408.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1 0a7 7 0 1 0-14 0 7 7 0 0 0 14 0z"
                      transform="translate(354 143.5)"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <p class="facebook-post-text">
          {{ postMessage || "Your Message Here" }}
        </p>
      </div>

    </ion-item>
    <ion-item
      lines="none"
      class="ion-no-padding"
    >
      <ion-card-content class="swiper-container ion-no-padding">
        <ion-buttons class="navigate-preview backward">
          <ion-button
            @click="slidePrev()"
            :disabled="!isEnablePrevButton"
          >
            <ion-icon
              slot="icon-only"
              :icon="chevronBackCircleOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-buttons class="navigate-preview forward">
          <ion-button
            @click="slideNext()"
            :disabled="!isEnableNextButton"
          >
            <ion-icon
              slot="icon-only"
              :icon="chevronForwardCircleOutline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <swiper
          ref="swiperRef"
          :modules="modules"
          :autoplay="false"
          :keyboard="true"
          :pagination="false"
          :scrollbar="false"
          :zoom="true"
          :mousewheel="true"
          :slidesPerView="1.5"
          :spaceBetween="10"
          :autoHeight="true"
          :centeredSlides="true"
          :centerInsufficientSlides="true"
          :centeredSlidesBounds="true"
          :width="459"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="item in selectedItems"
            :key="item.productid"
            class="swiper-slide"
          >
            <div class="slide-wrapper">
              <div
                class="image-wrapper"
                :style="`background-image: url(${item.image});`"
              >
              </div>
              <div class="image-footer">
                <div>
                  <div class="item-title">{{item.title}}</div>
                  <div class="item-subtitle">{{item.subtitle}}</div>
                </div>
                <div class="btn-action">
                  <button
                    class="call_to_action ng-binding ng-scope"
                    ng-if="product.cta"
                    ng-bind="product.cta"
                    data-payload="BUY_NOW"
                  >{{callToAction || 'Shop Now'}}</button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </ion-card-content>
    </ion-item>
  </ion-card-content>
</template>

<script>
import {
  arrowBackOutline,
  arrowForward,
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
} from "ionicons/icons";

import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { IonicSlides } from "@ionic/vue";

import "swiper/swiper-bundle.min.css";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/keyboard";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import "swiper/css/zoom";
// import "@ionic/vue/css/ionic-swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    postMessage: {
      type: String,
      default: "",
    },
    callToAction: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      arrowBackOutline,
      arrowForward,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      swiper: null,
      isEnablePrevButton: false,
      isEnableNextButton: true,
      pageIconUrl: null,
      pageName: null,
      pageUrl: null,
    };
  },
  setup() {
    return {
      modules: [Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides],
    };
  },
  async ionViewWillEnter() {
    this.init();
    this.productsSharePostTextsArray = [
      "Comment with #CheckoutTrigger below to buy now! You can also click the button below to checkout on Messenger ",
      "Buy now by simply commenting #CheckoutTrigger below or clicking on the button below.",
      "Get your own product today! Simply comment #CheckoutTrigger or click on the button below on the post. Happy shopping ",
      "Just in - check out our new product! Get your own one by commenting #CheckoutTrigger or clicking on the button below. Follow the instructions as per the order request prompt via DM to complete checkout.",
      "You got to try it to believe it! Shop our product now by commenting #CheckoutTrigger or clicking on the button below. You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "You gotta love our new product! How to get one? Easy! Just comment #CheckoutTrigger below or click on the button below. Follow the instructions you get via Facebook Messenger to complete checkout. Happy shopping ",
      "Ready. Set. Buy  Get one of our new product today by commenting #CheckoutTrigger below or clicking on the button below. You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "Have this product yet? No? Well, time to get one then  Buy now by commenting #CheckoutTrigger below or clicking button below.",
      "Love it? Get it! Our new product. Just comment #CheckoutTrigger below or click on the button below to buy! You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "The easiest way to buy our product? Comment #CheckoutTrigger below or click on the button below and watch the magic happen  You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "Hey " +
        this.user.referallshopName +
        " lovers - we have a brand new way to buy this product on Facebook. Drop in a comment below with #CheckoutTrigger, and our automated checkout bot will guide you through the purchase in a breeze. Happy shopping ",
      "Our brand new Facebook store - you can buy from the Collection by commenting with #CheckoutTrigger below to buy now! Or alternatively, click on the Shop Now button and let us walk you through the sale in Facebook Messenger!",
      "HURRY!! Our limited edition of product is available for sale right here on Facebook. You can now buy directly with us on Facebook Messenger by commenting #CheckoutTrigger below - and we will DM you in a few seconds. Chat soon :)",
      "New products, and new ways to buy 'em! Get your product now by clicking on button below or comment #CheckoutTrigger to start the checkout process. And then, just follow the instructions as per the order request prompt via DM to complete checkout",
    ];
  },
  watch: {},
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.pageName = this.facebookSocialData?.pages[0]?.name;
      this.pageIconUrl = `https://graph.facebook.com/${this.facebookSocialData?.pages[0]?.messenger}/picture?type=normal`;
      this.pageUrl = this.facebookSocialData?.pages[0]?.link;
      if (this.pageUrl && !this.pageUrl.includes("http")) {
        this.pageUrl = `https://${this.pageUrl}`;
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slidNavigationHandler() {
      this.isEnableNextButton = !this.swiper.isEnd;
      this.isEnablePrevButton = !this.swiper.isBeginning;
    },
    slideNext() {
      this.swiper.slideNext();
      this.slidNavigationHandler();
    },
    slidePrev() {
      this.swiper.slidePrev();
      this.slidNavigationHandler();
    },
    navigateToSlide(index) {
      this.swiper.slideTo(index);
      this.slidNavigationHandler();
    },
  },
};
</script>
<style scoped>
.facebook-post-wrapper {
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 5px;
  padding: 12px 16px;
}
.swiper-container {
  height: 400px;
  width: 100%;
  position: relative;
}
.swiper,
.swiper-slide {
  height: 100%;
}
.navigate-preview {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background: transparent;
}
.navigate-preview.backward {
  left: 0;
}
.navigate-preview.forward {
  right: 0;
}
:deep .swiper-wrapper {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.fb-icon-container {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 11px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular",
    sans-serif;
}
.fb-icon-container img {
  border-radius: 50%;
}

.fb-icon-container .first-line,
.fb-icon-container .first-line a {
  font-size: 15px;
  color: #050505;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
  text-decoration: none;
}
.fb-icon-container .second-line,
.fb-icon-container .second-line a {
  color: #65676b;
  font-size: 13px;
  text-decoration: none;
}
.fb-icon-container .second-line {
  display: flex;
  align-items: center;
  gap: 3px;
}
.facebook-post-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  margin-bottom: 10px;
}

.slide-wrapper {
  height: 100%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%) inset, 0 1px 4px rgb(0 0 0 / 5%);
  white-space: normal;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: column;
}
.image-wrapper {
  flex: 1;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-size: auto 100%;
}
.image-footer {
  height: 73px;
  padding: 10px;
  border-top: 1px solid rgb(0 0 0 / 10%);
  position: relative;
  display: flex;
  align-items: center;
}
.item-title {
  max-width: 190px;
  overflow: hidden;
  position: relative;
  line-height: 1.3333;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  font-weight: bold;
  color: #050505;
}
.btn-action {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
.btn-action button {
  background-color: #f6f7f8;
  background-repeat: repeat-x;
  border: 1px solid;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-sizing: content-box;
  font-family: helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-color: #cccccc #c5c6c8 #b6b7b9;
  color: #4e5665;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: button;
  cursor: pointer;
  line-height: 20px;
}
</style>