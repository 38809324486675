<template>
  <div v-if="open">
    <ion-modal @willDismiss="() => $emit('close')" :is-open="open">
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Order {{ order.ordernumber }}</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => modalController.dismiss()">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" id="printOrderReceiptArea">
          <ion-row>
            <ion-col size-md="6" size-sm="12">
              <table class="noBorder">
                <tr>
                  <th colspan="2" class="text-left">Order details</th>
                </tr>
                <tr>
                  <td>Order date</td>
                  <td>{{moment(order.created).format("DD-MMM-YYYY")}}</td>
                </tr>
                <tr>
                  <td>Ref. order ID</td>
                  <td>{{ order.orderkeyid }}</td>
                </tr>
                <tr v-if="user.enterprise_Name == 'samsungid'">
                  <td>Ordered via</td>
                  <td>{{ platformNameWhereOrderPlacedOn(order.type) }}</td>
                </tr>
                <tr v-if="orderKind == 'askPayment'">
                  <td>Payment note</td>
                  <td>{{ order.notes }}</td>
                </tr>
                <tr v-if="orderKind != 'askPayment' && order.notes != '' && order.notes != null && order.notes != 'None'">
                  <td>Notes</td>
                  <td>{{ order.notes }}</td>
                </tr>
                <tr v-if="order.variables.wrap_gift && order.variables.wrap_gift == true && user.enterprise_Name != 'elc_family_sg_lelabo'">
                  <td>Gift wrapping required</td>
                  <td>Yes</td>
                </tr>
                <tr v-if="order.variables.gift_message && order.variables.gift_message != ''">
                  <td>
                    <span v-if="user.enterprise_Name == 'elc_family_sg_lelabo'">Personalised message</span>
                    <span v-else>Personalized gift message</span>
                  </td>
                  <td v-html="renderNormalChat(order.variables.gift_message)"></td>
                </tr>
              </table>

              <table class="noBorder" v-if="orderKind == 'generic'">
                <!-- <tbody>
                    <tr>
                    <th colspan="2" class="text-left" v-if="clickAndCollectUser != true">Shipping details</th>
                    <th colspan="2" class="text-left" v-if="clickAndCollectUser == true">Order status</th>
                  </tr>
                </tbody> -->
                <tbody v-if="acommerceConnected == true">
                  <tr v-if="order.shipping.name">
                    <td>Type</td>
                    <td>{{order.shipping.name}}</td>
                  </tr>
                  <tr v-if="order.shippedlink != 'None' && order.shippedlink != null && order.shippedlink != ''">
                    <td>shipment tracking link</td>
                    <td>
                      <a :href="order.shippedlink" target="_blank" rel="noreferer noopener">Click here</a>
                    </td>
                  </tr>
                  <tr v-if="user.enterprise_Name == 'samsungid' && order.variables['Status']">
                    <td>Status</td>
                    <td>{{order.variables['Status']}}</td>
                  </tr>
                  <tr v-if="user.enterprise_Name == 'samsungid' && order.variables['Status date']">
                    <td>Status date</td>
                    <td>{{order.variables['Status date']}}</td>
                  </tr>
                  <tr v-if="user.enterprise_Name == 'samsungid' && order.variables['Tracking link']">
                    <td>Tracking link</td>
                    <td>
                      <a :href="order.variables['Tracking link']" target="_blank" rel="noreferer noopener">Click here</a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <!-- grab shipping start -->
                  <tr v-if="order.variables.Grab_Express && order.variables.Grab_Express == true">
                    <td>
                      <ion-text class="displayBlock" style="display: inline-block; vertical-align: middle;">
                        <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAkOn0m84KDA" style="height: 18px; width: auto;" />
                      </ion-text>
                      <ion-text class="displayBlock" style="display: inline-block; vertical-align: middle;">
                        <span class="singleDot green" v-if="order.variables.status == 'COMPLETED'" data-toggle="tooltip" data-placement="top" title="Completed"></span>
                        
                        <span class="singleDot yellow" v-if="order.variables.status != 'COMPLETED' && order.variables.status != 'FAILED'" data-toggle="tooltip" data-placement="top" title="In progress"></span>

                        <span class="singleDot red" v-if="order.variables.status == 'FAILED'" data-toggle="tooltip" data-placement="top" title="Failed"></span>
                      </ion-text>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>Shipping details</td>
                    <td>
                      <div>
                        <span v-html="grabShippingDetailsHTML"></span>
                      </div>
                    </td>
                  </tr> -->
                  <!-- grab shipping end -->
                </tbody>
              </table>
            </ion-col>

            <ion-col size-md="6" size-sm="12">
              <table class="noBorder">
                <tr>
                  <th colspan="2" class="text-left">Customer details</th>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{{ order.buyer.name }}</td>
                </tr>
                <tr v-if="accountType_master == true || loreal_my_org != true">
                  <td>Email</td>
                  <td>
                    <span v-if="order.buyer.email != '' && order.buyer.email != 'None' && order.buyer.email != null">{{ order.buyer.email }}</span>
                    <span v-if="order.buyer.email == '' || order.buyer.email == 'None' || order.buyer.email == null">Not provided</span>
                  </td>
                </tr>
                <tr v-if="order.buyer.mobileNumber != '' && order.buyer.mobileNumber != 'None' && order.buyer.mobileNumber != null">
                  <td>Mobile no.</td>
                  <td>{{order.buyer.mobilecountrycode}} {{ order.buyer.mobileNumber }}</td>
                </tr>
                <tr v-if="(user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink') && order.variables['Real Name']">
                  <td>Real name</td>
                  <td>{{ order.variables['Real Name'] }}</td>
                </tr>
                <tr v-if="(clickAndCollectUser != true || checkIfClickAndCollectDeliveryTrue(order.address) == true) && (accountType_master == true || loreal_my_org != true)">
                  <td>Shipping address</td>
                  <td>
                    <div v-if="order.address.addressLine1">
                      <span v-html="billAddressHTML"></span>
                    </div>
                    <div v-if="!order.address.addressLine1">
                      Not provided
                    </div>
                  </td>
                </tr>
                <tr v-if="order.address.addressLine1 && order.billing_address && order.billing_address.addressLine1 && elc_family_user == true && (accountType_master == true || loreal_my_org != true)">
                  <td>Billing address</td>
                  <td>
                    <div v-if="order.billing_address.addressLine1 != ''">
                      <span v-html="billAddressHTML"></span>
                    </div>
                    <div v-if="order.billing_address.addressLine1 == ''">
                      Same as shipping address
                    </div>
                  </td>
                </tr>
              </table>
            </ion-col>
          </ion-row>

          <ion-row v-if="(user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink' || user.enterprise_Name == 'redribbon_manila') && order.voucher_codes && order.voucher_codes.length > 0">
            <ion-col>
              <table class="withBorder">
                <tr>
                  <th>E-voucher code</th>
                  <th>Expiry date</th>
                </tr>
                <tr v-for="result in order.voucher_codes" :key="result.code">
                  <td>{{ result.code }}</td>
                  <td>{{ result.exipry }}</td>
                </tr>
              </table>
            </ion-col>
          </ion-row>

          <!-- cart items start -->
          <div v-if="orderKind != 'askPayment'">
            <ion-row v-if="order.cart.length == 0">
              <ion-col>
                <table class="withBorder table-container">
                  <tr>
                    <th>Item name</th>
                    <th v-if="order.variation != ''">Variation</th>
                    <th>Quantity</th>
                    <th>Unit price</th>
                  </tr>
                  <tr>
                    <td>{{ order.product.name }}</td>
                    <td v-if="order.variation != ''">{{ order.variation }}</td>
                    <td>{{ order.quantity }}</td>
                    <td>{{ order.unitprice }}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right">Subtotal</td>
                    <td class="text-right">{{formatAmountInLocale(order.subtotal,order.product.currency)}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right">Discount</td>
                    <td class="text-right">- {{formatAmountInLocale(order.discount,order.product.currency)}}</td>
                  </tr>
                  <tr v-if="order['reward discount'] && order['reward discount'] != '' && order['reward discount'] != 'None' && order['reward discount'] != null && order['reward discount'] != '0.00' && order['reward discount'] != '0' && order['reward discount'] != 0.00 && order['reward discount'] != 0">
                    <td colspan="3" class="text-right">Reward</td>
                    <td class="text-right">- {{formatAmountInLocale(order['reward discount'],order.product.currency)}}</td>
                  </tr>
                  <tr v-if="order['discount'] != order['total discount'] && order['additional discount'] && order['additional discount'] != '' && order['additional discount'] != 'None' && order['additional discount'] != null && order['additional discount'] != '0.00' && order['additional discount'] != '0' && order['additional discount'] != 0.00 && order['additional discount'] != 0">
                    <td colspan="3" class="text-right">Additional discount</td>
                    <td class="text-right">- {{formatAmountInLocale(order['additional discount'],order.product.currency)}}</td>
                  </tr>
                  <tr v-if="order['discount'] != order['total discount'] && order['total discount'] && order['total discount'] != '' && order['total discount'] != 'None' && order['total discount'] != null && order['total discount'] != '0.00' && order['total discount'] != '0' && order['total discount'] != 0.00 && order['total discount'] != 0">
                    <td colspan="3" class="text-right">Total discount</td>
                    <td class="text-right">- {{formatAmountInLocale(order['total discount'],order.product.currency)}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right">Shipping</td>
                    <td class="text-right">{{formatAmountInLocale(order.shippingcost,order.product.currency)}}</td>
                  </tr>
                  <tr v-if="elc_family_sg_user != true && elc_family_nz_user != true && elc_family_au_user != true">
                    <td colspan="3" class="text-right">Tax</td>
                    <td class="text-right">{{formatAmountInLocale(order.tax,order.product.currency)}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right">
                      <b>Total</b>
                    </td>
                    <td class="text-right">
                      <b>{{ order.product.currency }} {{formatAmountInLocale(order.total,order.product.currency)}}</b>
                    </td>
                  </tr>
                </table>
              </ion-col>
            </ion-row>
            <ion-row v-else>
              <ion-col>
                <div style="overflow-x: auto;">
                  <table class="withBorder table-container">
                    <tr>
                      <th>Item name</th>
                      <th>SKU</th>
                      <th>Item details</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                    <tr v-for="cart in order.cart" :key="cart.id">
                      <td>{{ cart.name }}</td>
                      <td>{{ cart.sku }}</td>
                      <td>
                        <span v-if="renderCartVarInfo(cart.subtitle) != ''">{{ renderCartVarInfo(cart.sutitle) }}</span>
                        <span v-if="renderCartVarInfo(cart.subtitle) == ''">-</span>
                      </td>
                      <td class="text-center">{{ cart.quantity }}</td>
                      <td class="text-right">{{formatAmountInLocale(cart.cost,cart.currency)}}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">Subtotal</td>
                      <td class="text-right">{{formatAmountInLocale(order.subtotal,order.cart[0].currency)}}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">Discount</td>
                      <td class="text-right">- {{formatAmountInLocale(order.discount,order.cart[0].currency)}}</td>
                    </tr>
                    <tr v-if="order['reward discount'] && order['reward discount'] != '' && order['reward discount'] != 'None' && order['reward discount'] != null && order['reward discount'] != '0.00' && order['reward discount'] != '0' && order['reward discount'] != 0.00 && order['reward discount'] != 0">
                      <td colspan="4" class="text-right">Reward</td>
                      <td class="text-right">- {{formatAmountInLocale(order['reward discount'],order.cart[0].currency)}}</td>
                    </tr>
                    <tr v-if="order['discount'] != order['total discount'] && order['additional discount'] && order['additional discount'] != '' && order['additional discount'] != 'None' && order['additional discount'] != null && order['additional discount'] != '0.00' && order['additional discount'] != '0' && order['additional discount'] != 0.00 && order['additional discount'] != 0">
                      <td colspan="4" class="text-right">Additional discount</td>
                      <td class="text-right">- {{formatAmountInLocale(order['additional discount'],order.cart[0].currency)}}</td>
                    </tr>
                    <tr v-if="order['discount'] != order['total discount'] && order['total discount'] && order['total discount'] != '' && order['total discount'] != 'None' && order['total discount'] != null && order['total discount'] != '0.00' && order['total discount'] != '0' && order['total discount'] != 0.00 && order['total discount'] != 0">
                      <td colspan="4" class="text-right">Total discount</td>
                      <td class="text-right">- {{formatAmountInLocale(order['total discount'],order.cart[0].currency)}}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">Shipping</td>
                      <td class="text-right">{{formatAmountInLocale(order.shippingcost,order.cart[0].currency)}}</td>
                    </tr>
                    <tr v-if="elc_family_sg_user != true && elc_family_nz_user != true && elc_family_au_user != true">
                      <td colspan="4" class="text-right">Tax</td>
                      <td class="text-right">{{formatAmountInLocale(order.tax,order.cart[0].currency)}}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">
                        <b>Total</b>
                      </td>
                      <td class="text-right">
                        <b>{{ order.cart[0].currency }} {{formatAmountInLocale(order.total,order.cart[0].currency)}}</b>
                      </td>
                    </tr>
                  </table>
                </div>
              </ion-col>
            </ion-row>
          </div>
          <!-- cart items end -->
        </ion-content>
      </ion-page>
    </ion-modal>
    <!-- Ship Modal -->
    <ion-modal
      v-if="productToShip !== null"
      :is-open="productToShip !== null"
      @didDismiss="() => productToShip = null"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Shipping Details</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => productToShip = null">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label>Add shipment tracking ID:</ion-label>
            <ion-input slot="end" v-model="shipmentID" placeholder="Enter shipment tracking ID"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Select provider name & auto-populate shipment tracking link:</ion-label>
            <ion-select slot="end" v-model="shipmentWay" placeholder="Provider">
              <ion-select-option value="ninja">Ninja Van</ion-select-option>
              <ion-select-option value="lala">Lalamove</ion-select-option>
              <ion-select-option value="custom">Enter custom link</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Add shipment tracking link:</ion-label>
            <ion-input :disabled="shipmentWay !== 'custom'" slot="end" v-model="shipmentLink" placeholder="Enter shipment tracking link"></ion-input>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="shipProduct">Ship Now</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import {
  close, checkmarkOutline, chatbubblesOutline, closeOutline
} from 'ionicons/icons';
import { IonSelect, IonSelectOption, modalController } from "@ionic/vue";

export default {
  props: ['open', 'order', 'clickAndCollectUser', 'orderKind', 'soldByAgent', 'agentName', 'agentImage', 'billAddressHTML'],
  components: {
    IonSelect, IonSelectOption
  },
  data(){
    return {
      close, checkmarkOutline, chatbubblesOutline, closeOutline,
      modalController,
      productToShip: null,
      shipmentID: undefined,
      shipmentWay: undefined,
      shipmentLink: undefined
    }
  },
  methods: {
    checkIfClickAndCollectDeliveryTrue(address){
      if(typeof address != 'undefined'){
        if(this.clickAndCollectUser == true){
          if(typeof address.outletid == 'undefined'){
            return true;
          }
        }
      }
    },
    renderCartVarInfo(input) {
      if(input == null || typeof input === undefined) return ''
      if(input.indexOf(",") > -1) return input.replace(/,/g, ', ')
      return input
    },
    checkIfToShowShipNowBtn(result) {
      if(typeof result != 'undefined'){
        if(typeof result.variables.Grab_Express != 'undefined'){
          if(result.variables.Grab_Express == true){
            return false;
          }
        }

        if((result.issent == false && (result.shippedlink == '' || result.shippedlink == 'None' || result.shippedlink == null)) && result.cancel == false && this.userRolesPermission.orders && this.userRolesPermission.orders.manage) {
          if(typeof result.variables != 'undefined') {
            let trackingLink_variable_found = false;
            for(var key in result.variables) {
              if(key == 'Tracking link') {
                trackingLink_variable_found = true;
                if(key == '' || key == null || key == 'None') return true;
              }
            }
            if(trackingLink_variable_found == false) return true;
          }
          else return true;
        }
      }
      return undefined;
    },
    async shipProduct() {
      try {
        this.loading = true;

        if(!this.productToShip.id) {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
          return;
        }
        if(!this.shipmentLink) {
          this.updateToast({
            open: true,
            message: "Please add relevant data!",
            type: "danger"
          })
          return;
        }
        if(!this.shipmentID) {
          this.updateToast({
            open: true,
            message: "Please add relevant data!",
            type: "danger"
          })
          return;
        }
        let reqBody = {
          transactionid: this.productToShip.id,
          shippedlink: this.shipmentLink,
          shippedid: this.shipmentID,
        }

        let shipProductResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "order-prepared", reqBody);

        if ((shipProductResponse.status == 200 || shipProductResponse.status == 201) && shipProductResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Product Shipped",
            type: "success"
          })
          this.productToShip.variables.shippedid = this.shipmentID;
          this.productToShip.shippedlink = this.shipmentLink;
        } else if((shipProductResponse.status == 200 || shipProductResponse.status == 201) && shipProductResponse.data.errorMessage && shipProductResponse.data.errorMessage.length){
          console.log("Response:", shipProductResponse);
          this.updateToast({
            open: true,
            message: shipProductResponse.data.errorMessage,
            type: "danger"
          })
        } else {
          console.log("Response:", shipProductResponse);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.productToShip = null;
        this.shipmentID = undefined;
        this.shipmentWay = undefined;
        this.shipmentLink = undefined;
      }
    }
  }
}
</script>

<style scoped>
.content-box {
  margin: 20px 0;
  width: 100%;
}
.content-box > .title {
  font-size: 1.3rem;
  padding: 10px;
  border-bottom: 1px solid gainsboro;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
.content-box > .content {
  padding: 15px;
  width: 100%;
}

@media only screen and (max-width: 780px) { 
  table.table-container {
    margin: 15px 0px;
    width: 100%;
  }
}
</style>