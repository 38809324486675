<template>
  <master-layout pageTitle="Payment Settings">
    <ion-content>
      <ion-refresher
        slot="fixed"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-card>
          <ion-card-header>
            <ion-card-title>Country and Currency Settings</ion-card-title>
            <ion-card-subtitle>
              <ion-item lines="none">
                Accepts payments in
                <div
                  @click="() => editSettingsMode = true"
                  slot="end"
                  style="cursor:pointer"
                >Edit</div>
              </ion-item>
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-item lines="none">
              <ion-label>
                <h2 class="ion-no-margin">Country</h2>
              </ion-label>
              <ion-thumbnail slot="end">
                <img :src="allCountryCurrencyData.allCountryCurrencyData.find(e => e.country_short_code === user.usercountry.toUpperCase()).flag_img" />
              </ion-thumbnail>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                <h2 class="ion-no-margin">Currency</h2>
              </ion-label>
              <div slot="end">{{ allCountryCurrencyData.allCountryCurrencyData.find(e => e.currency_code === user.currency).currency_code }}</div>
            </ion-item>
          </ion-card-content>
        </ion-card>
        <ion-toolbar color="light">
          <ion-segment
            scrollable
            color="dark"
            v-model="activeTab"
          >
            <ion-segment-button value="connected">
              <ion-label>Connected</ion-label>
            </ion-segment-button>
            <ion-segment-button value="available">
              <ion-label>Available</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="activeTab === 'connected'">
          <h2 class="ion-no-padding payment-header ion-no-margin">Connected Payment Methods</h2>
          <h2 class="ion-no-padding payment-header ion-no-margin">The payment methods active for your {{ dashboardName }} Account</h2>
          <ion-grid class="gridMargin">
            <template v-if="connectedPopularMethods && connectedPopularMethods.length">
              <div class="payment-type-text">Popular methods</div>
              <ion-row>
                <ion-col
                  v-for="(thisMethod, mIndex) in connectedPopularMethods"
                  :key="mIndex"
                  class="ion-justify-content-evenly rowMargin"
                  size-xl="3"
                  size-lg="3"
                  size-sm="12"
                  size-xs="12"
                >
                  <div class="payment-container">
                    <div class="imgArea">
                      <img
                        v-if="thisMethod.logo"
                        :src="thisMethod.logo"
                      />
                    </div>
                    <div class="descArea">
                      <h2 class="ion-no-padding payment-title ion-no-margin">{{ thisMethod.name }}</h2>
                      <p class="ion-no-padding payment-subtitle">{{ thisMethod.description ? thisMethod.description : '' }}</p>
                      <ion-buttons style="justify-content: center">
                        <ion-button
                          @click="() => {confirmDisconnect = true; toDisconnect = thisMethod;}"
                          expand="block"
                          fill="outline"
                          class="submit-bttn"
                        >Disconnect</ion-button>
                      </ion-buttons>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </template>
            <template v-if="connectedManualMethods && connectedManualMethods.length">
              <div class="payment-type-text">Manual methods</div>
              <ion-row>
                <ion-col
                  v-for="(thisMethod, mIndex) in connectedManualMethods"
                  :key="mIndex"
                  class="ion-justify-content-evenly rowMargin"
                  size-xl="3"
                  size-lg="3"
                  size-sm="12"
                  size-xs="12"
                >
                  <div class="payment-container">
                    <div class="imgArea">
                      <img
                        v-if="thisMethod.logo"
                        :src="thisMethod.logo"
                      />
                    </div>
                    <div class="descArea">
                      <h2 class="ion-no-padding payment-title ion-no-margin">{{ thisMethod.name }}</h2>
                      <p class="ion-no-padding payment-subtitle">{{ thisMethod.description ? thisMethod.description : '' }}</p>
                      <ion-buttons style="justify-content: center">
                        <ion-button
                          @click="manualMethodSettingClickHandler(thisMethod)"
                          expand="block"
                          fill="outline"
                          class="submit-bttn"
                        >Settings</ion-button>
                        <ion-button
                          @click="() => {confirmDisconnect = true; toDisconnect = thisMethod;}"
                          expand="block"
                          fill="outline"
                          class="disable-bttn"
                        >Disable</ion-button>
                      </ion-buttons>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </template>
          </ion-grid>
        </div>
      </div>
      <div v-if="activeTab === 'available'">
        <h2 class="ion-no-padding payment-header ion-no-margin">Available Payment Methods</h2>
        <h2 class="ion-no-padding payment-header ion-no-margin">The payment methods available for your country</h2>
        <ion-grid class="gridMargin">
          <div class="payment-type-text">Popular methods</div>
          <ion-row>
            <ion-col
              v-for="(thisMethod, mIndex) in availablePopularMethods"
              :key="mIndex"
              class="ion-justify-content-evenly rowMargin"
              size-xl="3"
              size-lg="3"
              size-sm="12"
              size-xs="12"
            >
              <div class="payment-container">
                <div class="imgArea">
                  <img
                    v-if="thisMethod.logo"
                    :src="thisMethod.logo"
                  />
                </div>
                <div class="descArea">
                  <h2 class="ion-no-padding payment-title ion-no-margin">{{ thisMethod.name }}</h2>
                  <p class="ion-no-padding payment-subtitle">{{ thisMethod.description ? thisMethod.description : '' }}</p>
                  <ion-buttons style="justify-content: center">
                    <ion-button
                      v-if="thisMethod.code =='paypal'"
                      expand="block"
                      fill="outline"
                      class="submit-bttn btn-paypal"
                    ><a
                        data-paypal-button="true"
                        href="https://www.sandbox.paypal.com/us/merchantsignup/partner/onboardingentry?token=M2UyZThkYTktNzNiNC00Y2ZhLWFkNmEtZTQxYzI0MjQyY2M3ZS9sSzFVMm1PYVdYckZ3MkF0b3lyMXRXMXJ5NCtSWnNwUXlTNE5oYjBoOD12Mg==&amp;displayMode=minibrowser"
                        target="PPFrame"
                      >Connect</a></ion-button>

                    <ion-button
                      v-else
                      @click="connect(thisMethod)"
                      expand="block"
                      fill="outline"
                      class="submit-bttn"
                    >Connect</ion-button>
                  </ion-buttons>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <div class="payment-type-text">Manual methods</div>
          <ion-row>
            <ion-col
              v-for="(thisMethod, mIndex) in availableManualMethods"
              :key="mIndex"
              class="ion-justify-content-evenly rowMargin"
              size-xl="3"
              size-lg="3"
              size-sm="12"
              size-xs="12"
            >
              <div class="payment-container">
                <div class="imgArea">
                  <img
                    v-if="thisMethod.logo"
                    :src="thisMethod.logo"
                  />
                </div>
                <div class="descArea">
                  <h2 class="ion-no-padding payment-title ion-no-margin">{{ thisMethod.name }}</h2>
                  <p class="ion-no-padding payment-subtitle">{{ thisMethod.description ? thisMethod.description : '' }}</p>
                  <ion-buttons style="justify-content: center">
                    <ion-button expand="block" fill="outline" class="submit-bttn btn-paypal" @click="manualMethodEnableClickHandler(thisMethod, button)" >
                      Enable
                    </ion-button>
                  </ion-buttons>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-modal
      v-if="editSettingsMode"
      :is-open="editSettingsMode"
      @didDismiss="() => editSettingsMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Edit Country and Currency settings</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => editSettingsMode = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label>Country</ion-label>
            <ion-select
              slot="end"
              v-model="country"
              placeholder="Select Country"
            >
              <ion-select-option
                v-for="(thisCountry, cIndex) in allCountryCurrencyData.allCountryCurrencyData"
                :key="cIndex"
                :value="thisCountry.country_short_code"
              >{{ thisCountry.country_name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Currency</ion-label>
            <ion-select
              slot="end"
              v-model="currency"
              placeholder="Select Country"
            >
              <ion-select-option
                v-for="(thisCountry, cIndex) in allCountryCurrencyData.allCountryCurrencyData"
                :key="cIndex"
                :value="thisCountry.currency_code"
              >{{ thisCountry.currency_code }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="updateCountryCurrency"
              color="dark"
            >Save</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="confirmCurrencyChange"
      :is-open="confirmCurrencyChange"
      header="Are you sure"
      message="If you change your default currency than it will not update currency for your existing products / events. if you want you can delete them and create again with the new currency."
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {confirmCurrencyChange = false; country = user.usercountry; currency = user.currency;},
      },
      {
        text: 'Yes',
        handler: () => {
          confirmUpdateCountryCurrency()
        },
      }]"
      @didDismiss="() => {confirmCurrencyChange = false; country = user.usercountry; currency = user.currency;}"
    >
    </ion-alert>
    <ion-modal
      v-if="connectModal"
      :is-open="connectModal"
      @didDismiss="() => {connectModal = false; toConnect = null;}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Connect {{ toConnect.name }}</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => {connectModal = false; toConnect = null;}">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item
            v-for="(thisField, fIndex) in toConnect.fields"
            :key="fIndex"
          >
            <ion-label>{{ thisField.placeholder }}</ion-label>
            <ion-select
              :disabled="thisField.disabled"
              slot="end"
              v-if="thisField.type === 'select'"
              v-model="thisField.value"
              :placeholder="thisField.placeholder"
            >
              <ion-select-option
                v-for="(thisOption, oIndex) in thisField.options"
                :key="oIndex"
                :value="thisOption.value"
              >{{ thisOption.name }}</ion-select-option>
            </ion-select>
            <div
              slot="end"
              v-else
            >
              <ion-checkbox
                :disabled="thisField.disabled"
                slot="end"
                v-if="thisField.type === 'checkbox'"
                v-model="thisField.value"
                color="dark"
              ></ion-checkbox>
              <ion-input
                :disabled="thisField.disabled"
                slot="end"
                v-else
                v-model="thisField.value"
                :placeholder="thisField.placeholder"
              ></ion-input>
            </div>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              v-if="toConnect.code=='bank'"
              :disabled="!allowConnect"
              @click="confirmConnectBankMethod"
              color="dark"
            >Save</ion-button>
            <ion-button
              v-else
              :disabled="!allowConnect"
              @click="confirmConnect"
              color="dark"
            >Connect</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="confirmDisconnect"
      :is-open="confirmDisconnect"
      header="Are you sure?"
      :message="'Are you sure you want to disconnect ' + toDisconnect.name + '?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {confirmDisconnect = false; toDisconnect = null;},
      },
      {
        text: 'Yes',
        handler: () => {
          disconnectHandler()
        },
      }]"
      @didDismiss="() => {confirmDisconnect = false; toDisconnect = null;}"
    >
    </ion-alert>
  </master-layout>
</template>
<script>
import {
  chevronDownCircleOutline,
  addCircleOutline,
  trashBinOutline,
  close,
} from "ionicons/icons";
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { IonSelect, IonSelectOption, IonCheckbox } from "@ionic/vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { loadCustomScript } from "@paypal/paypal-js";
import { Preferences as Storage } from "@capacitor/preferences";

export default {
  components: { IonSelect, IonSelectOption, IonCheckbox },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      close,
      activeTab: "connected",
      editSettingsMode: false,
      country: undefined,
      currency: undefined,
      confirmCurrencyChange: false,
      connectModal: false,
      toConnect: null,
      confirmDisconnect: false,
      toDisconnect: null,
    };
  },
  mounted() {
    this.loadManualPaymentMethods();
  },
  computed: {
    ...mapGetters({
      availablePopularMethods: "payment/getAvailablePopular",
      availableManualMethods: "payment/availableManualMethods",
      connectedPopularMethods: "payment/getConnectedPopular",
      connectedManualMethods: "payment/getConnectedManual"
    }),
    allowConnect() {
      return this.toConnect &&
        this.toConnect.fields &&
        this.toConnect.fields.every(
          (thisField) => thisField.value !== undefined
        )
        ? true
        : false;
    },
  },
  watch: {
    activeTab: {
      immediate: true,
      handler(value) {
        if (value === "available") {
          this.loadPaypalScript();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      updatePaymentMethods: "payment/updatePaymentMethods",
      updateManualPaymentMethods: "payment/updateManualPaymentMethods"
    }),
    ...mapMutations({
      savePaymentData: "payment/savePaymentData"
    }),
    async loadPaypalScript() {
      document.getElementById("signup-js")?.remove();
      document.getElementById("paypal-js")?.remove();
      await loadCustomScript({
        url: "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js",
        attributes: {
          id: "paypal-js",
          async: true,
        },
      });
    },
    async manualMethodEnableClickHandler(thisMethod) {
      const { code } = thisMethod;
      if (code == 'bank') {
        this.toConnect = thisMethod;
        this.connectModal = true;
      } else if (code == 'cash_on_delivery') {
        await this.manualMethodConnectionHandler({ methodName: 'cash_on_delivery', active: true });
        const dataToUpdate = { is_active: true };
        this.updateManualPaymentMethods({ paymentGatewayName: 'cash_on_delivery', dataToUpdate });
        this.updateToast({ open: true, message: "Successfully connected to Cash On Delivery payment method", type: "success", });
      }
    },
    async manualMethodSettingClickHandler(thisMethod) {
      const { code } = thisMethod;
      if (code == 'bank') {
        this.toConnect = thisMethod;
        this.connectModal = true;
      } else if (code == 'cash_on_delivery') {
        window.location.href = "/settings/tax-and-shipping";
      }
    },
    async confirmConnectBankMethod() {
      let title = "", instruction = "";
      this.toConnect.fields.forEach((thisField) => {
        if (thisField.field == 'title') {
          title = thisField.value;
        } else {
          instruction = thisField.value;
        }
      });
      try {
        this.loading = true;
        await this.manualMethodConnectionHandler({ methodName: 'bank', active: true, extraData: { title, instruction } });
        const dataToUpdate = { title, instruction, is_active: true };
        this.updateManualPaymentMethods({ paymentGatewayName: 'bank', dataToUpdate });
        this.updateToast({ open: true, message: "Successfully connected to bank payment method", type: "success", });
        this.toConnect = null;
        this.connectModal = false;
        this.loading = false;
      } catch (error) {
        this.updateToast({ open: true, message: "Connecting to bank payment method is failed", type: "danger", });
      }
    },
    async manualMethodConnectionHandler({ methodName, active, extraData }) {
      let reqBody = {
        payment_type: methodName,
        active: active
      };
      if (extraData) {
        reqBody = { ...reqBody, ...extraData };
      }
      let confirmConnectResponse = await jwtInterceptor.post(
        `${this.environment('VUE_APP_ROOT_API')}add-bank-transfer`,
        reqBody
      );
      if (
        (confirmConnectResponse.status == 200 ||
          confirmConnectResponse.status == 201) &&
        confirmConnectResponse.data
      ) {
        if (!confirmConnectResponse.data.success) {
          throw(confirmConnectResponse.data.errorMessage);
        }
      } else {
        throw(confirmConnectResponse);
      }
    },
    async loadManualPaymentMethods() {
      let manualPaymentsResponseData = JSON.parse((await(Storage.get({ key: 'jmpr_fetchManualPaymentsData' }))).value);
      if(!manualPaymentsResponseData) var manualPaymentsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-bank-transfer");
      if (manualPaymentsResponse?.data && ([200, 201].includes(manualPaymentsResponse.status))) {
        manualPaymentsResponseData = {};
        manualPaymentsResponse.data.bank_transfers.forEach((elm) => {
          manualPaymentsResponseData[elm.payment_type] = elm;
        });
        await Storage.set({
          key: "jmpr_fetchManualPaymentsData",
          value: JSON.stringify(manualPaymentsResponseData),
        });
      }
      this.savePaymentData(manualPaymentsResponseData);
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async ionViewWillEnter() {
      this.init();
    },
    async init() {
      console.log("Initializing");
      if (this.user?.usercountry) this.country = this.user.usercountry;
      if (this.user?.currency) this.currency = this.user.currency;
    },
    updateCountryCurrency() {
      if (this.user?.currency !== this.currency) {
        this.confirmCurrencyChange = true;
        return;
      }
      this.confirmUpdateCountryCurrency();
    },
    async confirmUpdateCountryCurrency() {
      try {
        this.loading = true;
        let reqBody = {
          currency: this.currency,
          usercountry: this.country,
        };
        let confirmSwitchResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "add-user",
          reqBody
        );

        if (
          (confirmSwitchResponse.status == 200 ||
            confirmSwitchResponse.status == 201) &&
          confirmSwitchResponse.data
        ) {
          console.log(confirmSwitchResponse.data);
          if (confirmSwitchResponse.data.success) {
            await this.Storage.remove({ key: "jmpr_fetchAddUserData" });
            window.location.href = window.location.pathname;
          } else {
            this.updateToast({
              open: true,
              message: confirmSwitchResponse.data.errorMessage,
              type: "danger",
            });
          }
        } else {
          console.log("Response:", confirmSwitchResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.confirmCurrencyChange = false;
        this.loading = false;
      }
    },
    connect(method) {
      try {
        if (method.connection_type === "form") {
          this.connectModal = true;
          this.toConnect = method;
        } else if (
          method.connection_type === "redirect" &&
          method.redirect_link
        ) {
          window.open(method.redirect_link, "_blank");
        } else {
          console.log("Invalid connection type for: ", method);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      }
    },
    async confirmConnect() {
      try {
        this.loading = true;
        let reqBody = {};
        this.toConnect.fields.forEach((thisField) =>
          !reqBody[thisField.field]
            ? (reqBody[thisField.field] = thisField.value)
            : ""
        );

        let confirmConnectResponse = await jwtInterceptor.post(
          this.toConnect.form_link,
          reqBody
        );
        if (
          (confirmConnectResponse.status == 200 ||
            confirmConnectResponse.status == 201) &&
          confirmConnectResponse.data
        ) {
          console.log(confirmConnectResponse.data);
          if (confirmConnectResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Payment Gateway Connected Successfully",
              type: "success",
            });
            this.updatePaymentMethods({
              paymentGatewayName: this.toConnect.code,
              dataToUpdate: { is_active: true },
            });
          } else {
            this.updateToast({
              open: true,
              message: confirmConnectResponse.data.errorMessage,
              type: "danger",
            });
          }
        } else {
          console.log("Response:", confirmConnectResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.toConnect = null;
        this.connectModal = false;
        this.loading = false;
      }
    },
    async disconnectHandler() {
      if (this.toDisconnect.type == 2) { // disconnect manual
        this.loading = true;
        const {name: paymentMethodName, code} = this.toDisconnect;
        try {
          await this.manualMethodConnectionHandler({ methodName: code, active: false });
          this.updateToast({ open: true, message: `${paymentMethodName} method is disconnected successfully`, type: "success", });
          const dataToUpdate = { is_active: false };
          this.updateManualPaymentMethods({ paymentGatewayName: code, dataToUpdate });
        } catch (error) {
          this.updateToast({ open: true, message: `${paymentMethodName} method's disconnection is failed`, type: "success", });
        }
        this.loading = false;
      } else {
        this.disconnect();
      }
    },
    async disconnect() {
      try {
        this.loading = true;
        let reqBody = {
          type: this.toDisconnect.code,
        };
        const toDisconnectCode = this.toDisconnect.code;
        let disconnectResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "delete-payment",
          reqBody
        );
        if (
          (disconnectResponse.status == 200 ||
            disconnectResponse.status == 201) &&
          disconnectResponse.data
        ) {
          if (disconnectResponse.data.success) {
            this.updatePaymentMethods({
              paymentGatewayName: toDisconnectCode,
              dataToUpdate: { is_active: false, status: false },
            });
            this.updateToast({
              open: true,
              message: "Payment Gateway Disconnected Successfully",
              type: "success",
            });
          } else {
            this.updateToast({
              open: true,
              message: disconnectResponse.data.errorMessage,
              type: "danger",
            });
          }
        } else {
          console.log("Response:", disconnectResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger",
          });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.toDisconnect = null;
        this.confirmDisconnect = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-bttn {
  color: var(--ion-color-white);
  background: #881fff;
  border-radius: 5px;
  width: 100%;
}
.disable-bttn {
  color: var(--ion-color-white);
  background: var(--ion-color-danger, #eb445a);
  border-radius: 5px;
  width: 100%;
  width: 100%;
}
.payment-container {
  display: block;
  max-width: 100%;
  margin-right: 0px;
  margin-bottom: 0px;
  background-color: var(--ion-color-white);
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
}

.imgArea {
  height: 150px;
  display: block;
  position: relative;
  overflow: hidden;
}

.imgArea img {
  display: block;
  max-width: calc(100% - 50px);
  width: auto;
  max-height: 80px;
  height: auto;
  margin: 50px auto 20px;
}

.descArea {
  padding: 20px;
}

.payment-title {
  font-size: 18px;
  font-weight: 400;
  color: var(--ion-color-black);
}

.payment-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  line-height: 1.5;
  margin: 5px 0px 10px 0px;
  min-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.payment-header {
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0px 0px 30px;
}

.btn-paypal a {
  color: white;
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.payment-type-text {
  padding: 5px;
}

@media only screen and (max-width: 760px) {
  .payment-header {
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px 0px 34px;
    word-wrap: break-word;
  }
}
</style>