
<template>
  <div class="date-time-module-wrappers ion-no-padding">
    <ion-button  id="openDateInput" ref="openDateInput" fill="clear" color="dark">
      <ion-icon slot="start" :icon="calendar"></ion-icon>
    </ion-button>
    <ion-popover side="top" trigger="openDateInput" alignment="center" style="top: -341px">
      <ion-datetime
        :show-default-buttons="true"
        display-format="DD/MM/YY hh:mm a"
        :min="moment().add(5, 'minutes').toISOString()"
        ref="sendDateTime"
        name="sendDateTime"
        presentation="date-time"
        id="popover-datetime"
        :value="timeToRender"
        @ionChange="onChange"
      ></ion-datetime>
    </ion-popover>
  </div>
</template>

<script>
import { calendar } from "ionicons/icons";
import { IonPopover } from "@ionic/vue";
import moment from 'moment-timezone';

export default {
  components: {
    IonPopover
  },
  data() {
    return {
      calendar
    }
  },
  emits:['onChange'],
  props: {
    dateTime: {
      type: String
    }
  },
  computed: {
    dateToRender() {
      return "";
    },
    timeToRender() {
      const now = this.dateTime ? moment(this.dateTime) : moment();
      const date = moment(now.format(), moment.ISO_8601).format();
      return date;
    }
  },
  watch: {
  },
  mounted() {},
  methods: {
    onChange(event) {
      let errorMessage = "";
      try {
        if (event?.detail?.value) {
          const formattedDate = this.moment(event?.detail?.value).format('MM/DD/YYYY, HH:mm:ss');
          console.log(formattedDate);
          this.$emit('onChange', formattedDate || null);
        } else {
          errorMessage = "Something went wrong while selecting date and time.";
        }
      } catch (error) {
        errorMessage = "Something went wrong while selecting date and time.";
      }
      if(errorMessage) {
        this.updateToast({ open: true, message: errorMessage, type: "danger", });
      }
    }
  }
};
</script>

<style scoped>
</style>