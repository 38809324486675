<template>
  <master-layout pageTitle="Default Templates">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content :pulling-icon="chevronDownCircleOutline" pulling-text="Pull to refresh"
          refreshing-spinner="circles" refreshing-text="Refreshing..."> </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading...">
      </ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" v-model="activeTab" class="ion-justify-content-center">
            <ion-segment-button value="conversations">
              <ion-label>Conversations</ion-label>
            </ion-segment-button>
            <ion-segment-button value="webviews">
              <ion-label>Webviews</ion-label>
            </ion-segment-button>
            <ion-segment-button value="emails">
              <ion-label>Emails</ion-label>
            </ion-segment-button>
            <ion-segment-button value="dashboard-messages">
              <ion-label>Dashboard messages</ion-label>
            </ion-segment-button>
            <ion-segment-button value="comments">
              <ion-label>Comments</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <!-- conversations -->
        <div v-if="activeTab === 'conversations'">
          <h3 class="pageTitle">Customize default checkout messages</h3>
          <div class="container">
            <ion-grid>
              <ion-row>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                  <div class="table-container">
                    <div class="panel-heading">
                      <h3 class="csvImportStepHeading ion-no-margin">Upload CSV file of customized default checkout
                        messages</h3>
                    </div>
                    <div class="panel-body">
                      <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                        <div class="panel-text">Download the CSV file by
                          <a class="hoverUnderline"
                            href="https://storage.googleapis.com/polygon-prod-123/personalization/jumper-default-checkout-template-messages.csv"
                            download>clicking here</a>
                          and write all your customized messages and then upload the file below!
                        </div>
                      </div>
                      <ion-loading v-if="csvFileUploaded" :is-open="csvFileUploaded" cssClass="my-custom-class"
                        message="uploading...">
                      </ion-loading>
                      <div class="csvFileContainerOuter text-center">
                        <input type="file" style="visibility: hidden;" id="flowMessagesCsvFile"
                          ref="flowMessagesCsvFile" @input="handleUploadCSV($event)" name="flowMessagesCsvFile"
                          accept=".csv" />
                        <div class="startUploadCsvFileContainer"
                          onclick="document.getElementById('flowMessagesCsvFile').click(); return false">
                          <img
                            src="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/file-only.svg"
                            class="uploadIcon" />
                          <p class="title">Click here to select file and upload</p>
                          <p class="subTitle" v-if="csvToUpload != undefined">
                            <b>{{ csvToUpload.name}}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                  <div class="table-container">
                    <div class="panel-heading">
                      <h3 class="csvImportStepHeading ion-no-margin">Upload CSV file of all the buttons inside the
                        default checkout journey</h3>
                    </div>
                    <div class="panel-body">
                      <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                        <div class="panel-text">Download the CSV file by
                          <a class="hoverUnderline"
                            href="https://storage.googleapis.com/polygon-prod-123/personalization/jumper-default-checkout-template-messages.csv"
                            download>clicking here</a>
                          and write all your button texts and then upload the file below!
                        </div>
                      </div>
                      <ion-loading v-if="csvButtonFileUploaded" :is-open="csvButtonFileUploaded"
                        cssClass="my-custom-class" message="uploading...">
                      </ion-loading>
                      <input type="file" style="visibility: hidden;" id="buttonMessagesCsvFile"
                        ref="buttonMessagesCsvFile" @input="handleUploadButtonCSV($event)" name="buttonMessagesCsvFile"
                        accept=".csv" />
                      <div class="csvFileContainerOuter text-center">
                        <div class="startUploadCsvFileContainer"
                          onclick="document.getElementById('buttonMessagesCsvFile').click(); return false">
                          <img
                            src="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/file-only.svg"
                            class="uploadIcon" />
                          <p class="title">Click here to select file and upload</p>
                          <p class="subTitle" v-if="csvButtonToUpload != undefined">
                            <b>{{ csvButtonToUpload.name}}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <!-- webviews -->
        <div v-if="activeTab === 'webviews'">
          <ion-accordion-group class="webview-container">
            <ion-accordion class="model-sections" @click="expandedAccordion = result.id"
              v-for="result in localizationWebviewData" :key="result.id">
              <ion-item slot="header" lines="none">
                <ion-text class="order-title"> {{ result.name}} </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding">
                <div class="cust-border">
                  <ion-grid v-if="expandedAccordion == result.id">
                    <ion-row>
                      <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                        <div v-for="resultSections in result.sections" :key="resultSections.id" class="singleSection">
                          <div class="title-container">
                            <p class="localizationSubheading" v-if="resultSections.id == 'subject'">Title (meta tag
                              title):</p>
                            <p class="localizationSubheading" v-if="resultSections.id == 'variable'">Texts</p>
                          </div>
                          <div class="panel-header">
                            <ion-grid>
                              <ion-row>
                                <ion-col>
                                  <ion-row>
                                    <ion-col size="5">
                                      <ion-card-title class="sctn-labels">Default:</ion-card-title>
                                    </ion-col>
                                    <ion-col size="5">
                                      <ion-card-title class="sctn-labels">Custom:</ion-card-title>
                                    </ion-col>
                                  </ion-row>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                          <div v-for="(resultFields,index) in resultSections.fields" :key="index" class="panel-body"
                            style="padding-top: 0px">
                            <ion-grid>
                              <ion-row>
                                <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                  <ion-input :value="resultFields.default_word" :disabled="true" type="text"
                                    class="fullBordered input_custom_style sctn-input"></ion-input>
                                </ion-col>
                                <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                                  <ion-input :value="resultFields.user_word"
                                    v-model="allWordsKeyPair[resultFields.word].user_word" type="text"
                                    class="fullBordered input_custom_style"></ion-input>
                                </ion-col>
                                <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="12" size-xs="12">
                                  <ion-button fill="solid" size="default" color="dark" class="webViewSaveBttn"
                                    @click="saveWord(allWordsKeyPair[resultFields.word])">
                                    Save
                                  </ion-button>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                        </div>
                      </ion-col>
                      <!-- preview -->
                      <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                        <div class="webviewPreviewContainer">
                          <div class="singleWebviewPreview" id="singleWebviewPreview-address"
                            v-if="result.id == 'address'">
                            <div class="webviewPreviewTitle"> shipping address </div>
                            <div class="containerPadding">
                              <div class="addressForm">
                                <div class="prdct-section" :class="field.word" v-for="field in shippingAddressPreview"
                                  :key="field.word">
                                  <ion-card-title class="sctn-labels preview-text">{{ field.user_word ||
                                  field.default_word }}</ion-card-title>
                                  <ion-input type="text" class="fullBordered input_custom_style"></ion-input>
                                </div>
                                <div class="webviewButtonArea text-center">
                                  <p class="closeWebviewForNonMessenger">{{ allWordsKeyPair.wb_close_window.user_word ||
                                  allWordsKeyPair.wb_close_window.default_word }}</p>
                                  <ion-button color="primary" fill="solid" expand="full">
                                    {{ allWordsKeyPair.wb_update_cart_update_cart.user_word ||
                                    allWordsKeyPair.wb_update_cart_update_cart.default_word }}
                                  </ion-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="singleWebviewPreview" id="singleWebviewPreview-viewDetails"
                            v-if="result.id == 'viewDetails'">
                            <div class="containerPadding text-center">
                              <img src="https://jumper.ai/web-s/resources/images/placeholder-product.svg"
                                class="viewImageWebviewImgContainer" />
                            </div>
                            <div class="viewImageWebviewArea">
                              <div class="containerPadding noTopPadding">
                                <ion-row class="singleSection">
                                  <ion-col size="8">
                                    <h5 class="viewImageWebviewTitle">Product/event name</h5>
                                  </ion-col>
                                  <ion-col size="4">
                                    <p class="viewImageWebviewCost">THB
                                      <span>350.00</span>
                                    </p>
                                  </ion-col>
                                </ion-row>
                                <p class="order-details padding10pxAll"> Your product / event description goes here...
                                </p>
                                <div class="webviewButtonArea text-center">
                                  <p class="closeWebviewForNonMessenger">{{ allWordsKeyPair.wb_close_window.user_word ||
                                  allWordsKeyPair.wb_close_window.default_word}}</p>
                                  <ion-button color="primary" fill="solid" expand="full">
                                    {{ allWordsKeyPair.wb_go_back_chat.user_word ||
                                    allWordsKeyPair.wb_go_back_chat.default_word }}
                                  </ion-button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-alreadyUpdated"
                            v-if="result.id == 'alreadyUpdated'">
                            <div class=" addressForm text-center">
                              <img
                                src="https://jumperdevnew.appspot.com/web-b/resources/images/action-completed-illustration.svg"
                                class="paymentSuccessIconImg" />
                              <div>
                                <h5 class="ion-no-margin">{{ allWordsKeyPair.wb_already_over.user_word ||
                                allWordsKeyPair.wb_already_over.default_word }}</h5>
                                <p class="closeWebviewForNonMessenger">{{ allWordsKeyPair.wb_close_window.user_word ||
                                allWordsKeyPair.wb_close_window.default_word }}</p>
                              </div>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-shopPolicies"
                            v-if="result.id == 'shopPolicies'">
                            <div class="addressForm">
                              <p class="webviewCard ion-no-margin">Your policy content comes here...</p>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-orderConfirmationAfterPayment"
                            v-if="result.id == 'orderConfirmationAfterPayment'">
                            <div class="containerPadding padding10pxAll">
                              <p> {{ allWordsKeyPair.wb_order_cnfrm_title.user_word ||
                              allWordsKeyPair.wb_order_cnfrm_title.default_word }} </p>
                              <div class="localizationSubheading">
                                <img src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product-new.png"
                                  class="logoImg">
                                <div class="productNamePriceArea">
                                  <p class="ion-no-margin order-title">Item name</p>
                                  <p class="ion-no-margin order-title">{{ allWordsKeyPair.wb_order_cnfrm_qty.user_word
                                  || allWordsKeyPair.wb_order_cnfrm_qty.default_word }}
                                    <span>1</span>
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div class="delivery-container">
                                  <p class="order-address ion-no-margin"> {{
                                  allWordsKeyPair.wb_order_cnfrm_deliver_to.user_word ||
                                  allWordsKeyPair.wb_order_cnfrm_deliver_to.default_word }} </p>
                                  <p class="ion-no-margin common-label"> Address line 1,</p> <span
                                    class="common-label">city, country - 123456</span>
                                </div>
                                <table class="table table-borderless order-confirm">
                                  <tbody>
                                    <tr>
                                      <td>{{ allWordsKeyPair.wb_order_cnfrm_subtotal.user_word ||
                                      allWordsKeyPair.wb_order_cnfrm_subtotal.default_word }}</td>
                                      <td class="text-right">THB 350.00</td>
                                    </tr>

                                    <tr>
                                      <td>{{ allWordsKeyPair.wb_order_cnfrm_shipping.user_word ||
                                      allWordsKeyPair.wb_order_cnfrm_shipping.default_word }}</td>
                                      <td class="text-right">THB 0.00</td>
                                    </tr>

                                    <tr>
                                      <td>{{ allWordsKeyPair.wb_order_cnfrm_tax.user_word ||
                                      allWordsKeyPair.wb_order_cnfrm_tax.default_word }}</td>
                                      <td class="text-right">THB 0.00</td>
                                    </tr>

                                    <tr>
                                      <td class="boldWeight">{{ allWordsKeyPair.wb_order_cnfrm_total.user_word ||
                                      allWordsKeyPair.wb_order_cnfrm_total.default_word }}</td>
                                      <td class="boldWeight text-right">THB 350.00</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="webviewButtonArea text-center">
                                  <ion-button disabled color="primary" fill="solid" expand="full">
                                    {{ allWordsKeyPair.wb_order_cnfrm_send_btn.user_word ||
                                    allWordsKeyPair.wb_order_cnfrm_send_btn.default_word }}
                                  </ion-button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-checkoutDirect"
                            v-if="result.id == 'checkoutDirect'">
                            <div class="logoCommonHeader text-center">
                              <img
                                src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAoKKfro0KDA"
                                class="logoImg">
                            </div>
                            <div class="headerWebview">
                              <ion-text class="ion-no-margin">{{ allWordsKeyPair.chk_dir_review_cart.user_word ||
                              allWordsKeyPair.chk_dir_review_cart.default_word }}</ion-text>
                            </div>
                            <div>
                              <div class="marginLR15px">
                                <div class="collectionSingleItem">
                                  <img class="dishImage"
                                    src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product-new.png">
                                  <div class="dishDataBesideImage">
                                    <h5 class="title"> Cart item name
                                      <span>x</span> 1
                                    </h5>
                                    <ion-text>THB 350.00</ion-text>
                                  </div>
                                </div>
                                <table class="table checkout-table">
                                  <tbody>
                                    <tr>
                                      <td>{{ allWordsKeyPair.chk_dir_subtotal.user_word ||
                                      allWordsKeyPair.chk_dir_subtotal.default_word }}</td>
                                      <td class="text-right">THB 350.00</td>
                                    </tr>
                                    <tr>
                                      <td class="">{{ allWordsKeyPair.chk_dir_shipping_text.user_word ||
                                      allWordsKeyPair.chk_dir_shipping_text.default_word }}</td>
                                      <td class="text-right">THB 0.00</td>
                                    </tr>
                                    <tr>
                                      <td>{{ allWordsKeyPair.chk_dir_tax.user_word ||
                                      allWordsKeyPair.chk_dir_tax.default_word }}</td>
                                      <td class="text-right">THB 0.00</td>
                                    </tr>
                                    <tr>
                                      <td class="boldWeight">{{ allWordsKeyPair.chk_dir_totalamount.user_word ||
                                      allWordsKeyPair.chk_dir_totalamount.default_word }}</td>
                                      <td class="boldWeight text-right">THB 350.00</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="webviewButtonArea text-center">
                                <ion-button color="primary" fill="solid" expand="full">
                                  {{ allWordsKeyPair.chk_dir_checkout.user_word ||
                                  allWordsKeyPair.chk_dir_checkout.default_word }}
                                </ion-button>
                              </div>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-singleProductCart"
                            v-if="result.id == 'singleProductCart'">
                            <div class="product-cart">
                              <div class="containerPadding text-center">
                                <img src="https://jumper.ai/web-s/resources/images/placeholder-product.svg"
                                  class="viewImageWebviewImgContainer" />
                              </div>
                              <div class="viewImageWebviewArea">
                                <div class="containerPadding noTopPadding">
                                  <ion-row class="singleSection">
                                    <ion-col size="8">
                                      <h5 class="viewImageWebviewTitle">Product name</h5>
                                    </ion-col>
                                    <ion-col size="4">
                                      <p class="viewImageWebviewCost">THB
                                        <span>11.00</span>
                                      </p>
                                    </ion-col>
                                  </ion-row>
                                </div>
                              </div>
                            </div>
                            <ion-grid>
                              <ion-row>
                                <ion-col>
                                  <label>{{ allWordsKeyPair.wb_pro_cart_select.user_word ||
                                  allWordsKeyPair.wb_pro_cart_select.default_word }} &lt;
                                    <span>variation name</span>
                                    &gt;</label>
                                </ion-col>
                                <ion-col>
                                  <span class="custom-dropdown">
                                    <ion-select ok-text="Okay" class="fullBordered" cancel-text="Dismiss">
                                      <ion-select-option value="," selected>Comma (,)</ion-select-option>
                                      <ion-select-option value="|">Pipe (|)</ion-select-option>
                                    </ion-select>
                                  </span>
                                </ion-col>
                              </ion-row>
                              <ion-row>
                                <ion-col>
                                  <label>{{ allWordsKeyPair.wb_pro_cart_select.user_word ||
                                  allWordsKeyPair.wb_pro_cart_select.default_word }} &lt;
                                    <span>variation name</span>
                                    &gt;</label>
                                </ion-col>
                                <ion-col>
                                  <span class="custom-dropdown">
                                    <ion-select ok-text="Okay" class="fullBordered" cancel-text="Dismiss">
                                      <ion-select-option value="," selected>Comma (,)</ion-select-option>
                                      <ion-select-option value="|">Pipe (|)</ion-select-option>
                                    </ion-select>
                                  </span>
                                </ion-col>
                              </ion-row>
                              <ion-row>
                                <ion-col>
                                  <label>{{ allWordsKeyPair.wb_pro_cart_select.user_word ||
                                  allWordsKeyPair.wb_pro_cart_select.default_word }} &lt;
                                    <span>variation name</span>
                                    &gt;</label>
                                </ion-col>
                                <ion-col>
                                  <span class="custom-dropdown">
                                    <ion-select ok-text="Okay" class="fullBordered" cancel-text="Dismiss">
                                      <ion-select-option value="," selected>Comma (,)</ion-select-option>
                                      <ion-select-option value="|">Pipe (|)</ion-select-option>
                                    </ion-select>
                                  </span>
                                </ion-col>
                              </ion-row>
                              <ion-row>
                                <ion-col>
                                  <label>{{ allWordsKeyPair.wb_pro_cart_sel_qty.user_word ||
                                  allWordsKeyPair.wb_pro_cart_sel_qty.default_word }}</label>
                                </ion-col>
                                <ion-col>
                                  <button type="button" class="qtyMinusBtn">-</button>
                                  <input type="text" class="fullBordered input_custom_style qtyText" value="1" />
                                  <button type="button" class="qtyPlusBtn">+</button>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                            <div class="webviewButtonArea text-center">
                              <ion-button color="primary" fill="solid" expand="full">
                                {{ allWordsKeyPair.wb_pro_cart_addtocart_text.user_word ||
                                allWordsKeyPair.wb_pro_cart_addtocart_text.default_word }}
                              </ion-button>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-loyaltyRegister"
                            v-if="result.id == 'loyaltyRegister'">
                            <div class="addressForm">
                              <div class="prdct-section" :class="field.word" v-for="field in loyaltyPreview"
                                :key="field.word">
                                <ion-card-title class="sctn-labels preview-text">{{ field.user_word ||
                                field.default_word }}</ion-card-title>
                                <ion-input type="text" class="fullBordered input_custom_style"></ion-input>
                              </div>
                            </div>
                            <div class="webviewButtonArea text-center">
                              <p class="closeWebviewForNonMessenger">{{
                              allWordsKeyPair.wb_loyalty_reg_close_window.user_word ||
                              allWordsKeyPair.wb_loyalty_reg_close_window.default_word }}</p>
                              <ion-button color="primary" fill="solid" expand="full">
                                {{ allWordsKeyPair.wb_loyalty_reg_save.user_word ||
                                allWordsKeyPair.wb_loyalty_reg_save.default_word }}
                              </ion-button>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-squarePay"
                            v-if="result.id == 'squarePay'">
                            <div class="cart-container">
                              <h5 class="webviewHeading text-center">{{ allWordsKeyPair.wb_square_pay_total.user_word ||
                              allWordsKeyPair.wb_square_pay_total.default_word }}: THB
                                <span>100</span>
                              </h5>
                              <p class="common-label text-center">{{ allWordsKeyPair.wb_square_pay_info.user_word ||
                              allWordsKeyPair.wb_square_pay_info.default_word }}</p>
                              <div>
                                <div class="newStripeLikeCard">
                                  <input autocapitalize="off" pattern="\d*" maxlength="24"
                                    placeholder="XXXX  XXXX  XXXX  XXXX">
                                  <div class="third">
                                    <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                      pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                      :placeholder=" allWordsKeyPair.wb_square_pay_cvv.user_word || allWordsKeyPair.wb_square_pay_cvv.default_word">
                                  </div>
                                  <div class="third">
                                    <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                      pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                      :placeholder=" allWordsKeyPair.wb_square_pay_mmyy.user_word || allWordsKeyPair.wb_square_pay_mmyy.default_word">
                                  </div>
                                  <div class="third">
                                    <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                      pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                      :placeholder=" allWordsKeyPair.wb_square_pay_postalcode.user_word || allWordsKeyPair.wb_square_pay_postalcode.default_word">
                                  </div>
                                </div>
                                <p class="common-label text-center" style="margin-top:60px">{{
                                allWordsKeyPair.wb_square_pay_name_email_info.user_word ||
                                allWordsKeyPair.wb_square_pay_name_email_info.default_word }}</p>
                                <div class="newStripeLikeCard">
                                  <input type="text" name="name"
                                    :placeholder="allWordsKeyPair.wb_square_pay_Yourname.user_word || allWordsKeyPair.wb_square_pay_Yourname.default_word" />
                                  <input type="email" name="email"
                                    :placeholder="allWordsKeyPair.wb_square_pay_Youremail.user_word || allWordsKeyPair.wb_square_pay_Youremail.default_word" />
                                </div>
                                <br />
                                <div class="text-center">
                                  <ion-button type="submit" id="sq-creditcard" color="dark" fill="solid" expand="full"
                                    style="margin-bottom: 5px;" disabled>{{
                                    allWordsKeyPair.wb_square_pay_purchase_btn.user_word ||
                                    allWordsKeyPair.wb_square_pay_purchase_btn.default_word }}</ion-button>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p class="jumperText text-center">
                                <span>Commerce enabled by</span>
                                <a class="poweredByJumperLink"
                                  href="https://jumper.ai/?utm_medium=merchant&utm_source=webbot&utm_campaign=webview"
                                  target="_blank" rel="noreferer noopener">Jumper.ai</a>
                              </p>
                              <div class="supportedCardTypesLogos text-center">
                                <img v-for="img in paymentImages" :key="img" :src="img" />
                              </div>
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-2c2pPayment"
                            v-if="result.id == '2c2pPayment'">
                            <div class="cart-container">
                              <h5 class="webviewHeading text-center">{{ allWordsKeyPair.wb_2c2p_pay_total.user_word ||
                              allWordsKeyPair.wb_2c2p_pay_total.default_word }}: THB
                                <span>100</span>
                              </h5>
                              <p class="common-label text-center">{{ allWordsKeyPair.wb_2c2p_pay_info.user_word ||
                              allWordsKeyPair.wb_2c2p_pay_info.default_word }}</p>
                              <div class="newStripeLikeCard">
                                <input spellcheck="off" type="tel" pattern="\d*" placeholder="XXXX  XXXX  XXXX  XXXX">
                                <div class="third">
                                  <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                    pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                    :placeholder=" allWordsKeyPair.wb_2c2p_pay_mm.user_word || allWordsKeyPair.wb_2c2p_pay_mm.default_word">
                                </div>
                                <div class="third">
                                  <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                    pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                    :placeholder=" allWordsKeyPair.wb_2c2p_pay_yyyy.user_word || allWordsKeyPair.wb_2c2p_pay_yyyy.default_word">
                                </div>
                                <div class="third">
                                  <input autocapitalize="off" autocorrect="off" spellcheck="off" type="tel"
                                    pattern="\d*" maxlength="5" autocomplete="cc-exp"
                                    :placeholder=" allWordsKeyPair.wb_2c2p_pay_cvv.user_word || allWordsKeyPair.wb_2c2p_pay_cvv.default_word">
                                </div>
                              </div>
                              <div class="newStripeLikeCard">
                                <input type="text" name="name"
                                  :placeholder="allWordsKeyPair.wb_2c2p_pay_Yourname.user_word || allWordsKeyPair.wb_2c2p_pay_Yourname.default_word"
                                  proximityElem />
                              </div>
                              <br />
                              <div class="text-center">
                                <ion-button type="submit" id="sq-creditcard" color="dark" fill="solid" expand="full"
                                  style="margin-bottom: 5px;" disabled>{{
                                  allWordsKeyPair.wb_2c2p_pay_purchase_btn.user_word ||
                                  allWordsKeyPair.wb_2c2p_pay_purchase_btn.default_word }}</ion-button>
                              </div>
                            </div>
                            <div class="supportedCardTypesLogos text-center">
                              <img v-for="img in paymentImages" :key="img" :src="img" />
                            </div>
                          </div>

                          <div class="singleWebviewPreview" id="singleWebviewPreview-updateCartGeneric"
                            v-if="result.id == 'updateCartGeneric'">
                            <div class="cart-container">
                              <p class="ion-no-margin" style="margin-bottom: 10px">{{
                              allWordsKeyPair.wb_update_cart_title.user_word ||
                              allWordsKeyPair.wb_update_cart_title.default_word }} </p>
                              <ion-grid class="ion-no-padding">
                                <ion-row>
                                  <ion-col>
                                    <img class="comment-img"
                                      src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product-new.png" />
                                    <div class="dishDataBesideImage">
                                      <h5 class="title ion-no-margin">Item name</h5>
                                      <p class="subData ion-no-margin">
                                        <span>
                                          <b>{{ allWordsKeyPair.wb_update_cart_price.user_word ||
                                          allWordsKeyPair.wb_update_cart_price.default_word }}: </b>THB
                                          <span>THB 50.00</span>
                                        </span>
                                      </p>
                                    </div>
                                  </ion-col>
                                  <ion-col>
                                    <div class="qtySelectorArea">
                                      <button class="btn qtyPlusMinusBtn qtyMinusBtn">-</button>
                                      <input type="text" class="qtyText topMostQty" name="qtyText" value="0" disabled />
                                      <button class="btn qtyPlusMinusBtn qtuPlusBtn">+</button>
                                    </div>
                                  </ion-col>
                                </ion-row>
                                <ion-row>
                                  <ion-col>
                                    <span v-if="result.subtitle != ''">
                                      <h5 class="title lightColor">{{ allWordsKeyPair.wb_update_cart_options.user_word
                                      || allWordsKeyPair.wb_update_cart_options.default_word }}:</h5>
                                    </span>
                                  </ion-col>
                                  <ion-col>
                                    <div class="text-right">
                                      <ion-button color="danger" fill="outline">{{
                                      allWordsKeyPair.wb_update_cart_remove_btn.user_word ||
                                      allWordsKeyPair.wb_update_cart_remove_btn.default_word}}</ion-button>
                                    </div>
                                  </ion-col>
                                </ion-row>
                              </ion-grid>
                              <div class="updateCartOptionsTags">
                                <h5 class="title">{{ allWordsKeyPair.wb_update_cart_special_note.user_word ||
                                allWordsKeyPair.wb_update_cart_special_note.default_word }}:</h5>
                                <span>Special instructions comes here (if any)</span>
                              </div>
                            </div>
                            <div class="webviewButtonArea text-center">
                              <p><b>{{ allWordsKeyPair.wb_update_cart_total.user_word ||
                              allWordsKeyPair.wb_update_cart_total.default_word }}</b> TBH 50.00</p>
                              <p class="closeWebviewForNonMessenger">{{
                              allWordsKeyPair.wb_update_cart_close_window.user_word ||
                              allWordsKeyPair.wb_update_cart_close_window.default_word }}</p>
                              <ion-button color="primary" fill="solid" expand="full">
                                {{ allWordsKeyPair.wb_update_cart_update_cart.user_word ||
                                allWordsKeyPair.wb_update_cart_update_cart.default_word }}
                              </ion-button>
                            </div>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </div>
        <!-- emails -->
        <div v-if="activeTab === 'emails'">
          <ion-accordion-group class="webview-container">
            <ion-accordion class="model-sections" @click="expandedAccordion = result.id"
              v-for="result in localizationEmailData" :key="result.id">
              <ion-item slot="header" lines="none">
                <ion-text class="order-title"> {{ result.name}} </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding" :id="'loc-email-' + result.id">
                <div v-if="expandedAccordion == result.id" class="cust-border">
                  <div v-for="resultSections in result.sections" :key="resultSections.id" class="singleSection">
                    <div class="title-container">
                      <p class="localizationSubheading" v-if="resultSections.id == 'subject'">Email subject:</p>
                    </div>
                    <div class="panel-header">
                      <ion-grid>
                        <ion-row>
                          <ion-col size="5">
                            <ion-card-title class="sctn-labels">Default</ion-card-title>
                          </ion-col>
                          <ion-col size="5">
                            <ion-card-title class="sctn-labels">Custom</ion-card-title>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                    <div v-for="resultFields in resultSections.fields" :key="resultFields.id" class="panel-body"
                      :id="'singleWord-email-' +result.id + '-' + removeAtTheRate(resultFields.word)">
                      <ion-grid>
                        <ion-row>
                          <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                            <div class="prdct-section">
                              <ion-textarea v-if="resultFields.element == 'textarea'" :value="resultFields.default_word"
                                :disabled="true" rows="4" class="fullBordered input_custom_style sctn-input">
                              </ion-textarea>
                              <ion-input v-else :value="resultFields.default_word" :disabled="true" type="text"
                                class="fullBordered input_custom_style sctn-input"></ion-input>
                            </div>
                          </ion-col>
                          <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                            <div class="prdct-section">
                              <ion-textarea v-if="resultFields.element == 'textarea'" :value="resultFields.user_word"
                                v-model="allWordsKeyPair[resultFields.word].user_word" rows="4"
                                class="fullBordered input_custom_style"></ion-textarea>
                              <ion-input v-else :value="resultFields.user_word"
                                v-model="allWordsKeyPair[resultFields.word].user_word" type="text"
                                class="fullBordered input_custom_style"></ion-input>
                            </div>
                          </ion-col>
                          <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="12" size-xs="12">
                            <ion-button @click="saveWord(allWordsKeyPair[resultFields.word])" fill="solid"
                              size="default" color="dark" style="margin-top: -1px; float: right;">
                              Save
                            </ion-button>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                  </div>
                </div>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </div>
        <!-- dashboard-messages -->
        <div v-if="activeTab === 'dashboard-messages'">
          <ion-accordion-group class="webview-container">
            <ion-accordion class="model-sections" v-for="result in localizationDashboardData" :key="result.id">
              <ion-item slot="header" lines="none">
                <ion-text class="order-title"> {{ result.name}} </ion-text>
              </ion-item>
              <ion-list slot="content" class="ion-no-padding" :id="'loc-dashboard-' + result.id">
                <div class="cust-border">
                  <div v-for="resultSections in result.sections" :key="resultSections.id" class="singleSection">
                    <div class="panel-header">
                      <ion-grid>
                        <ion-row>
                          <ion-col size="5">
                            <ion-card-title class="sctn-labels">Default:</ion-card-title>
                          </ion-col>
                          <ion-col size="5">
                            <ion-card-title class="sctn-labels">Custom:</ion-card-title>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                    <div v-for="resultFields in resultSections.fields" :key="resultFields.id"
                      class="panel-body singleWord-dashboard"
                      :id="'singleWord-dashboard-' + result.id + '-' + resultFields.word">
                      <ion-grid>
                        <ion-row>
                          <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                            <ion-textarea v-model="resultFields.default_word" disabled rows="4"
                              class="fullBordered input_custom_style sctn-input"></ion-textarea>
                          </ion-col>
                          <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12">
                            <ion-textarea :value="resultFields.user_word"
                              v-model="allWordsKeyPair[resultFields.word].user_word" rows="4"
                              class="fullBordered input_custom_style"></ion-textarea>
                          </ion-col>
                          <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="12" size-xs="12">
                            <ion-button fill="solid" size="default" color="dark" style="margin-top: -1px; float: right;"
                              @click="saveWord(allWordsKeyPair[resultFields.word])">
                              Save
                            </ion-button>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                  </div>
                </div>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </div>
        <!-- comments -->
        <div v-if="activeTab === 'comments'">
          <div class="model-sections">
            <div class="panel-heading">
              <h4 class="order-title">Comment messages</h4>
            </div>
            <div class="panel-body">
              <div v-for="(result,index) in listOfCommentsResult" :key="index" class="singleSection">
                <p class="comments-subHeading">{{ result.name }} </p>
                <div class="panel-body">
                  <ion-text class="sctn-labels prdct-section">Default</ion-text>
                  <ion-grid class="ion-no-padding">
                    <ion-row>
                      <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                        <div class="prdct-section comments-textarea">
                          <ion-textarea v-model="result.message" disabled name="defaultChatMessage"
                            class="fullBordered input_custom_style sctn-input"></ion-textarea>
                        </div>
                        <div class="requiredVariablesColoredBox" v-if="(result.message).length > 0">
                          <h6 class="tags-message ion-no-margin">Mandatory tags for message:</h6>
                          <span v-for="resultReqTegs in renderRequiredVariables(result.message)"
                            :key="resultReqTegs.id">{{ resultReqTegs }}</span>
                        </div>
                      </ion-col>
                      <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                        <div :id="'singleChatMessagesTemplateArea_' + result.vertex">
                          <div class="sppChatBubble">
                            <img :src="getShopLogoImage" class="comment-img" />
                            <div style="display: inline">
                              <div class="comments-box">
                                <ion-textarea :value="result.custom_message"
                                  v-model="commentsPair[result.vertex].custom_message" rows="4" name="chatMessage"
                                  class="fullBordered input_custom_style chatMessage"></ion-textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  <ion-toolbar class="saveBttn">
                    <ion-buttons slot="end">
                      <ion-button fill="solid" size="default" color="dark" style="margin-top: -1px"
                        @click="saveCommentsMessage(commentsPair[result.vertex])">
                        Save
                      </ion-button>
                    </ion-buttons>
                  </ion-toolbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, helpCircleOutline } from 'ionicons/icons';
import { IonSelect, IonSelectOption, IonTextarea, IonAccordion, IonAccordionGroup } from "@ionic/vue";

const localizationWebviewData = [
  {
    "id": "address",
    "template_url": "/web-b/address-webview.html",
    "name": "Add new address",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_shipping_address"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_mobile_number"
          },
          {
            "word": "wb_address_line1"
          },
          {
            "word": "wb_address_line2"
          },
          {
            "word": "wb_country"
          },
          {
            "word": "wb_state"
          },
          {
            "word": "wb_country_first"
          },
          {
            "word": "wb_select_state"
          },
          {
            "word": "wb_select_country"
          },
          {
            "word": "wb_city"
          },
          {
            "word": "wb_district"
          },
          {
            "word": "wb_postal_code"
          },
          {
            "word": "wb_address_tag"
          },
          {
            "word": "wb_address_tag_placeholder"
          },
          {
            "word": "wb_close_window"
          },
          {
            "word": "wb_chat"
          },
          {
            "word": "wb_sending"
          },
          {
            "word": "wb_update_address"
          },
          {
            "word": "wb_address_mobile_invalid"
          }
        ]
      }
    ]
  },
  {
    "id": "fnbDeliveryAddress",
    "template_url": "/web-b/address-webview-fnb-delivery.html",
    "name": "Delivery address - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_fnb_delivery_address_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_mobile_number"
          },
          {
            "word": "wb_address_line1"
          },
          {
            "word": "wb_country"
          },
          {
            "word": "wb_state"
          },
          {
            "word": "wb_city"
          },
          {
            "word": "wb_postal_code"
          },
          {
            "word": "wb_address_tag"
          },
          {
            "word": "wb_address_tag_placeholder"
          },
          {
            "word": "wb_close_window"
          },
          {
            "word": "wb_chat"
          },
          {
            "word": "wb_sending"
          },
          {
            "word": "wb_address_mobile_invalid"
          }
        ]
      }
    ]
  },
  {
    "id": "viewDetails",
    "template_url": "/web-b/event-detail.html",
    "name": "View product / event details",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_event_detail_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_close_window"
          },
          {
            "word": "wb_go_back_chat"
          },
          {
            "word": "wb_event_detail_talktosupport_btn"
          },
          {
            "word": "wb_event_detail_buynow_btn"
          }
        ]
      }
    ]
  },
  {
    "id": "eventTerms",
    "template_url": "/web-b/event-terms.html",
    "name": "Event terms & conditions",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_terms"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_complete_register"
          },
          {
            "word": "wb_accept"
          }
        ]
      }
    ]
  },
  {
    "id": "alreadyUpdated",
    "template_url": "/web-b/action-completed.html",
    "name": "Action completed",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_already_over"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_close_window"
          }
        ]
      }
    ]
  },
  {
    "id": "alreadyUpdatedFnb",
    "template_url": "/web-b/restaurant-action-completed.html",
    "name": "Action completed - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_already_over"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_close_window"
          }
        ]
      }
    ]
  },
  {
    "id": "shopPolicies",
    "template_url": "/web-b/merchant-policy.html",
    "name": "Shop policies",
    "sections": [
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_policy_privacy"
          },
          {
            "word": "wb_policy_return_refund"
          },
          {
            "word": "wb_policy_shipping"
          },
          {
            "word": "wb_policy_cancellation"
          }
        ]
      }
    ]
  },
  {
    "id": "restaurantOrder",
    "template_url": "/web-b/poha-menu.html",
    "name": "Add to cart - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_fnb_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_fnb_price"
          },
          {
            "word": "wb_fnb_fnb_total"
          },
          {
            "word": "wb_fnb_add_to_cart"
          },
          {
            "word": "wb_fnb_place_order"
          },
          {
            "word": "wb_fnb_saving_btn"
          },
          {
            "word": "wb_fnb_add_more_items"
          },
          {
            "word": "wb_fnb_onwards"
          },
          {
            "word": "wb_fnb_customization_avail"
          },
          {
            "word": "wb_fnb_duplicate_selection"
          },
          {
            "word": "wb_fnb_select_dish_option"
          },
          {
            "word": "wb_fnb_select_atleast_1_option"
          },
          {
            "word": "wb_fnb_max_option_msg"
          },
          {
            "word": "wb_fnb_notes_text"
          },
          {
            "word": "wb_fnb_notes_placeholder"
          },
          {
            "word": "wb_fnb_add_another_customz_to_item"
          },
          {
            "word": "wb_close_window"
          }
        ]
      }
    ]
  },
  {
    "id": "stripeCard",
    "template_url": "/web-b/stripe-card.html",
    "name": "Payment - powered by Stripe",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_stripe_pay_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_total_cost"
          },
          {
            "word": "wb_payments_powered"
          },
          {
            "word": "wb_yourname"
          },
          {
            "word": "wb_youremail"
          },
          {
            "word": "wb_purchase"
          },
          {
            "word": "wb_pay_googlepay"
          },
          {
            "word": "wb_pay_applepay"
          }
        ]
      }
    ]
  },
  {
    "id": "paypalPayment",
    "template_url": "/web-b/paypal-payment.html",
    "name": "Payment - powered by PayPal",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_paypal_pay_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_paypal_pay_order_summary"
          },
          {
            "word": "wb_paypal_pay_qty"
          },
          {
            "word": "wb_paypal_pay_deliver_to"
          },
          {
            "word": "wb_paypal_pay_venue"
          },
          {
            "word": "wb_paypal_pay_summary"
          },
          {
            "word": "wb_paypal_pay_subtotal"
          },
          {
            "word": "wb_paypal_pay_discount"
          },
          {
            "word": "wb_paypal_pay_reward"
          },
          {
            "word": "wb_paypal_pay_addtnl_discount"
          },
          {
            "word": "wb_paypal_pay_total_discount"
          },
          {
            "word": "wb_paypal_pay_shipping"
          },
          {
            "word": "wb_paypal_pay_tax"
          },
          {
            "word": "wb_paypal_pay_tip"
          },
          {
            "word": "wb_paypal_pay_total"
          }
        ]
      }
    ]
  },
  {
    "id": "checkoutDirect",
    "template_url": "/web-b/review-cart-and-checkout-webview.html",
    "name": "Webview based checkout (create order)",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "chk_dir_meta_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "chk_dir_review_cart"
          },
          {
            "word": "chk_dir_gift_with_purchase"
          },
          {
            "word": "chk_dir_subtotal"
          },
          {
            "word": "chk_dir_discount_text"
          },
          {
            "word": "chk_dir_reward"
          },
          {
            "word": "chk_dir_additional_discount"
          },
          {
            "word": "chk_dir_total_discount"
          },
          {
            "word": "chk_dir_shipping_text"
          },
          {
            "word": "chk_dir_tax"
          },
          {
            "word": "chk_dir_tip"
          },
          {
            "word": "chk_dir_totalamount"
          },
          {
            "word": "chk_dir_link_will_expire_in"
          },
          {
            "word": "chk_dir_order_notes_label"
          },
          {
            "word": "chk_dir_order_notes_placeholder"
          },
          {
            "word": "chk_dir_checkout"
          },
          {
            "word": "chk_dir_your_cart_is_empty"
          },
          {
            "word": "chk_dir_view_details"
          },
          {
            "word": "chk_dir_confirm_and_view_cart"
          },
          {
            "word": "chk_dir_choose_an_option"
          },
          {
            "word": "chk_dir_delivery_or_collect_title"
          },
          {
            "word": "chk_dir_collection_title"
          },
          {
            "word": "chk_dir_collection_desc"
          },
          {
            "word": "chk_dir_collection_btn"
          },
          {
            "word": "chk_dir_delivery_title"
          },
          {
            "word": "chk_dir_delivery_desc"
          },
          {
            "word": "chk_dir_delivery_btn"
          },
          {
            "word": "chk_dir_select_an_outlet"
          },
          {
            "word": "chk_dir_address_text"
          },
          {
            "word": "chk_dir_opening_hours"
          },
          {
            "word": "chk_dir_select_text"
          },
          {
            "word": "chk_dir_enter_your_details"
          },
          {
            "word": "chk_dir_first_name"
          },
          {
            "word": "chk_dir_last_name"
          },
          {
            "word": "chk_dir_email"
          },
          {
            "word": "chk_dir_phone_no"
          },
          {
            "word": "chk_dir_gift_wrapping_required"
          },
          {
            "word": "chk_dir_personalize_your_gift_message"
          },
          {
            "word": "chk_dir_personalize_gift_textarea_placeholder"
          },
          {
            "word": "chk_dir_save_and_proceed"
          },
          {
            "word": "chk_dir_select_shipping_address"
          },
          {
            "word": "chk_dir_no_address_found"
          },
          {
            "word": "chk_dir_please_add_new_address"
          },
          {
            "word": "chk_dir_add_new_address"
          },
          {
            "word": "chk_dir_shipping_address"
          },
          {
            "word": "chk_dir_address_line_1"
          },
          {
            "word": "chk_dir_address_line_2"
          },
          {
            "word": "chk_dir_address_line1_placeholder"
          },
          {
            "word": "chk_dir_address_line2_placeholder"
          },
          {
            "word": "chk_dir_country"
          },
          {
            "word": "chk_dir_district"
          },
          {
            "word": "chk_dir_state"
          },
          {
            "word": "chk_dir_select_country_first"
          },
          {
            "word": "chk_dir_city"
          },
          {
            "word": "chk_dir_postal_code"
          },
          {
            "word": "chk_dir_billing_address_is_same_as_shipping_address"
          },
          {
            "word": "chk_dir_billing_address_text"
          },
          {
            "word": "chk_dir_save_address_text"
          },
          {
            "word": "chk_dir_select_a_shipping_package"
          },
          {
            "word": "chk_dir_price_text"
          },
          {
            "word": "chk_dir_order_summary"
          },
          {
            "word": "chk_dir_name"
          },
          {
            "word": "chk_dir_requested_for_gift_wrapping"
          },
          {
            "word": "chk_dir_message_text"
          },
          {
            "word": "chk_dir_message_not_provided"
          },
          {
            "word": "chk_dir_summary"
          },
          {
            "word": "chk_dir_proceed_to_payment"
          },
          {
            "word": "chk_dir_select_payment_option"
          },
          {
            "word": "chk_dir_pay_with_adyen"
          },
          {
            "word": "chk_dir_pay_with_atome"
          },
          {
            "word": "chk_dir_payment_success"
          },
          {
            "word": "chk_dir_order_confirmation"
          },
          {
            "word": "chk_dir_wait_for_qr_title"
          },
          {
            "word": "chk_dir_wait_for_qr_desc"
          },
          {
            "word": "chk_dir_payment_failed_title"
          },
          {
            "word": "chk_dir_payment_failed_desc"
          },
          {
            "word": "chk_dir_something_went_wrong"
          },
          {
            "word": "chk_dir_please_close_window"
          },
          {
            "word": "chk_dir_privacy_policy"
          },
          {
            "word": "chk_dir_terms_of_sale"
          },
          {
            "word": "chk_dir_please_wait"
          },
          {
            "word": "chk_dir_select_country_text"
          },
          {
            "word": "chk_dir_select_state"
          },
          {
            "word": "chk_dir_no_payment_method_available"
          },
          {
            "word": "chk_dir_onwards"
          },
          {
            "word": "chk_dir_please_select_qty_items"
          },
          {
            "word": "chk_dir_you_cant_select_more_than_qty_items"
          },
          {
            "word": "chk_dir_is_out_of_stock"
          },
          {
            "word": "chk_dir_item_added_to_cart"
          },
          {
            "word": "chk_dir_variant_selection_is_pending"
          },
          {
            "word": "chk_dir_edit"
          },
          {
            "word": "chk_dir_remove"
          },
          {
            "word": "chk_dir_note"
          },
          {
            "word": "chk_dir_please_select_edit_to_select_variant"
          },
          {
            "word": "chk_dir_items_included_in_bundle"
          },
          {
            "word": "chk_dir_please_select_any"
          },
          {
            "word": "chk_dir_item_text"
          },
          {
            "word": "chk_dir_items_text"
          },
          {
            "word": "chk_dir_from_below"
          },
          {
            "word": "chk_dir_qty_capital"
          },
          {
            "word": "chk_dir_apply_discount"
          },
          {
            "word": "chk_dir_discount_code"
          },
          {
            "word": "chk_dir_skip"
          },
          {
            "word": "chk_dir_apply_coupon"
          },
          {
            "word": "chk_dir_manual_discounts"
          },
          {
            "word": "chk_dir_code_text"
          },
          {
            "word": "chk_dir_select_qty_out_of_total"
          },
          {
            "word": "chk_dir_qty_text"
          },
          {
            "word": "chk_dir_manual_data"
          },
          {
            "word": "chk_dir_select_promotion"
          },
          {
            "word": "chk_dir_apply_promotion"
          },
          {
            "word": "chk_dir_select_required_no_of_choice_items"
          },
          {
            "word": "chk_dir_you_dont_have_anything_in_cart"
          },
          {
            "word": "chk_dir_item_updated"
          }
        ]
      }
    ]
  },
  {
    "id": "orderConfirmationAfterPayment",
    "template_url": "/web-b/order-receipt-webview.html",
    "name": "Order confirmation with email form",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_order_cnfrm_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_order_cnfrm_checking_pay_status"
          },
          {
            "word": "wb_order_cnfrm_qty"
          },
          {
            "word": "wb_order_cnfrm_deliver_to"
          },
          {
            "word": "wb_order_cnfrm_venue"
          },
          {
            "word": "wb_order_cnfrm_name"
          },
          {
            "word": "wb_order_cnfrm_email"
          },
          {
            "word": "wb_order_cnfrm_mobile_no"
          },
          {
            "word": "wb_order_cnfrm_billing_address"
          },
          {
            "word": "wb_order_cnfrm_requested_for_gift_wrapping"
          },
          {
            "word": "wb_order_cnfrm_gift_message"
          },
          {
            "word": "wb_order_cnfrm_not_provided"
          },
          {
            "word": "wb_order_cnfrm_summary"
          },
          {
            "word": "wb_order_cnfrm_subtotal"
          },
          {
            "word": "wb_order_cnfrm_discount"
          },
          {
            "word": "wb_order_cnfrm_reward"
          },
          {
            "word": "wb_order_cnfrm_addtnl_discount"
          },
          {
            "word": "wb_order_cnfrm_total_discount"
          },
          {
            "word": "wb_order_cnfrm_shipping"
          },
          {
            "word": "wb_order_cnfrm_tax"
          },
          {
            "word": "wb_order_cnfrm_tip"
          },
          {
            "word": "wb_order_cnfrm_total"
          },
          {
            "word": "wb_order_cnfrm_order_no"
          },
          {
            "word": "wb_order_cnfrm_get_email"
          },
          {
            "word": "wb_order_cnfrm_send_btn"
          },
          {
            "word": "wb_order_cnfrm_trans_failed"
          },
          {
            "word": "wb_order_cnfrm_sending"
          },
          {
            "word": "wb_order_cnfrm_suc_msg"
          },
          {
            "word": "wb_order_cnfrm_err_msg"
          }
        ]
      }
    ]
  },
  {
    "id": "singleProductCart",
    "template_url": "/web-b/cart-single-product-webview.html",
    "name": "Add product to cart",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_pro_cart_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_pro_cart_tobeadded"
          },
          {
            "word": "wb_pro_cart_qty"
          },
          {
            "word": "wb_pro_cart_addanother"
          },
          {
            "word": "wb_pro_cart_select"
          },
          {
            "word": "wb_pro_cart_sel_qty"
          },
          {
            "word": "wb_pro_cart_note"
          },
          {
            "word": "wb_pro_cart_addtocart_text"
          },
          {
            "word": "wb_pro_cart_description_text"
          },
          {
            "word": "wb_pro_cart_addtocart_btn"
          },
          {
            "word": "wb_pro_cart_addmore_btn"
          },
          {
            "word": "wb_pro_cart_proceed_to_checkout_btn"
          },
          {
            "word": "wb_pro_cart_bundle_alrdy_added_note"
          },
          {
            "word": "wb_pro_cart_proceed_btn"
          },
          {
            "word": "wb_pro_cart_proceedwithitem_btn"
          },
          {
            "word": "wb_pro_cart_soldout"
          },
          {
            "word": "wb_pro_cart_cantordermore"
          },
          {
            "word": "wb_pro_cart_selectvar"
          },
          {
            "word": "wb_pro_cart_alreadyincart"
          },
          {
            "word": "wb_pro_cart_adding"
          }
        ]
      }
    ]
  },
  {
    "id": "fnbPickupCalendar",
    "template_url": "/web-b/fnb-pickup-calendar.html",
    "name": "Schedule pickup - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_fnb_pickup_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_fnb_pickup_note"
          },
          {
            "word": "wb_fnb_pickup_note1"
          },
          {
            "word": "wb_fnb_pickup_note2"
          },
          {
            "word": "wb_fnb_pickup_close_window"
          },
          {
            "word": "wb_fnb_pickup_confirm_btn"
          },
          {
            "word": "wb_fnb_pickup_confirming"
          },
          {
            "word": "wb_fnb_pickup_time_unavail"
          },
          {
            "word": "wb_fnb_pickup_success_msg"
          }
        ]
      }
    ]
  },
  {
    "id": "fnbReservationCalendar",
    "template_url": "/web-b/fnb-reservation-calendar.html",
    "name": "Reserve a table - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_fnb_reservation_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_fnb_reservation_choosedate"
          },
          {
            "word": "wb_fnb_reservation_close_window"
          },
          {
            "word": "wb_fnb_reservation_confirm_btn"
          },
          {
            "word": "wb_fnb_reservation_confirming"
          },
          {
            "word": "wb_fnb_reservation_date_unavail"
          },
          {
            "word": "wb_fnb_reservation_select_date"
          },
          {
            "word": "wb_fnb_reservation_time_unavail"
          },
          {
            "word": "wb_fnb_reservation_success_msg"
          }
        ]
      }
    ]
  },
  {
    "id": "loyaltyRegister",
    "template_url": "/web-b/loyalty-program-register.html",
    "name": "Register for loyalty program",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_loyalty_reg_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_loyalty_reg_name"
          },
          {
            "word": "wb_loyalty_reg_email"
          },
          {
            "word": "wb_loyalty_reg_mobile"
          },
          {
            "word": "wb_loyalty_reg_dob"
          },
          {
            "word": "wb_loyalty_reg_close_window"
          },
          {
            "word": "wb_loyalty_reg_save"
          },
          {
            "word": "wb_loyalty_reg_saving"
          },
          {
            "word": "wb_loyalty_reg_mobile_invalid"
          },
          {
            "word": "wb_loyalty_reg_email_invalid"
          }
        ]
      }
    ]
  },
  {
    "id": "squarePay",
    "template_url": "/web-b/square-pay.html",
    "name": "Payment - powered by Square",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_square_pay_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_square_pay_total"
          },
          {
            "word": "wb_square_pay_info"
          },
          {
            "word": "wb_square_pay_name_email_info"
          },
          {
            "word": "wb_square_pay_Yourname"
          },
          {
            "word": "wb_square_pay_Youremail"
          },
          {
            "word": "wb_square_pay_purchase_btn"
          },
          {
            "word": "wb_square_pay_enter_valid_cardinfo"
          },
          {
            "word": "wb_square_pay_cvv"
          },
          {
            "word": "wb_square_pay_mmyy"
          },
          {
            "word": "wb_square_pay_postalcode"
          }
        ]
      }
    ]
  },
  {
    "id": "2c2pPayment",
    "template_url": "/web-b/2c2p.html",
    "name": "Payment - powered by 2c2p",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_2c2p_pay_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_2c2p_pay_total"
          },
          {
            "word": "wb_2c2p_pay_info"
          },
          {
            "word": "wb_2c2p_pay_credit_card_number"
          },
          {
            "word": "wb_2c2p_pay_mm"
          },
          {
            "word": "wb_2c2p_pay_yyyy"
          },
          {
            "word": "wb_2c2p_pay_cvv"
          },
          {
            "word": "wb_2c2p_pay_Yourname"
          },
          {
            "word": "wb_2c2p_pay_purchase_btn"
          }
        ]
      }
    ]
  },
  {
    "id": "updateCartGeneric",
    "template_url": "/web-b/poha-order.html",
    "name": "Update cart",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "wb_update_cart_title"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "wb_update_cart_price"
          },
          {
            "word": "wb_update_cart_options"
          },
          {
            "word": "wb_update_cart_free_item_tag"
          },
          {
            "word": "wb_update_cart_remove_btn"
          },
          {
            "word": "wb_update_cart_special_note"
          },
          {
            "word": "wb_update_cart_total"
          },
          {
            "word": "wb_update_cart_close_window"
          },
          {
            "word": "wb_update_cart_update_cart"
          },
          {
            "word": "wb_update_cart_soldout"
          },
          {
            "word": "wb_update_cart_cantordermore"
          },
          {
            "word": "wb_update_cart_saving"
          },
          {
            "word": "wb_update_cart_empty_text"
          }
        ]
      }
    ]
  }
];

const mandatory_tags_fields = [
  "@new_order", "@word_Hello", '@product_message', '@word_email_body_for_cac', '@word_Hello', '@shipping_message'
]

const localizationEmailData = [
  {
    "id": "order_confirmation",
    "template_url": "/email/buyer-order-confirmation-cart.html",
    "name": "Order confirmation",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "@new_order",
            "tags_mandatory": false
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "@Order_Received"
          },
          {
            "word": "@word_Hello",
            "tags_mandatory": false
          },
          {
            "word": "@product_message",
            "tags_mandatory": false,
            "element": "textarea"
          },
          {
            "word": "@word_email_body_for_cac",
            "tags_mandatory": false,
            "element": "textarea"
          },
          {
            "word": "@word_order_id"
          },
          {
            "word": "@ordervia_word"
          },
          {
            "word": "@word_order_date"
          },
          {
            "word": "@word_here_ordered_items"
          },
          {
            "word": "@word_Item"
          },
          {
            "word": "@word_Quantity"
          },
          {
            "word": "@word_price"
          },
          {
            "word": "@word_Subtotal"
          },
          {
            "word": "@word_Tax"
          },
          {
            "word": "@word_Shipping"
          },
          {
            "word": "@word_discount"
          },
          {
            "word": "@word_reward"
          },
          {
            "word": "@word_additional_discount"
          },
          {
            "word": "@word_total_discount"
          },
          {
            "word": "@word_Total"
          },
          {
            "word": "@word_Delivery_Address"
          },
          {
            "word": "@word_Billing_Address"
          },
          {
            "word": "@word_gift_wrap_required"
          },
          {
            "word": "@word_gift_wrap_message"
          },
          {
            "word": "@product_shipment",
            "element": "textarea"
          }
        ]
      }
    ]
  },
  {
    "id": "order_confirmation_restaurant",
    "template_url": "/email/buyer-new-order-restaurant.html",
    "name": "Order confirmation - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "@fnb_new_order_subject"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "@fnb_order_details"
          },
          {
            "word": "@fnb_date_time"
          },
          {
            "word": "@fnb_order_id"
          },
          {
            "word": "@fnb_havehere_takeaway"
          },
          {
            "word": "@fnb_product_details"
          },
          {
            "word": "@word_note"
          },
          {
            "word": "@fnb_subtotal"
          },
          {
            "word": "@fnb_tax"
          },
          {
            "word": "@word_discount"
          },
          {
            "word": "@word_reward"
          },
          {
            "word": "@word_total_discount"
          },
          {
            "word": "@word_tip"
          },
          {
            "word": "@fnb_total"
          },
          {
            "word": "@fnb_payment_method"
          }
        ]
      }
    ]
  },
  {
    "id": "booking_confirmation",
    "template_url": "/email/buyer-booking-confirmation.html",
    "name": "Booking confirmation",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "@Booking_Confirmation"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "@word_Hello",
            "tags_mandatory": false
          },
          {
            "word": "@Booking_id"
          },
          {
            "word": "@event_message"
          },
          {
            "word": "@event_admit"
          },
          {
            "word": "@Event_day_and_date"
          },
          {
            "word": "@Event_venue"
          },
          {
            "word": "@Event_url"
          },
          {
            "word": "@Event_details"
          },
          {
            "word": "@event_booked"
          },
          {
            "word": "@free_event"
          }
        ]
      }
    ]
  },
  {
    "id": "shipping_tracking_link",
    "template_url": "/email/shipping-tracking-link.html",
    "name": "Track shipment",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "@shipping_tracking_email_subject"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "@shipping_subject"
          },
          {
            "word": "@word_Hello",
            "tags_mandatory": false
          },
          {
            "word": "@word_order_id"
          },
          {
            "word": "@shipping_productname"
          },
          {
            "word": "@shipping_message",
            "tags_mandatory": false,
            "element": "textarea"
          },
          {
            "word": "@shipping_track"
          }
        ]
      }
    ]
  },
  {
    "id": "fnb_email_verification",
    "template_url": "/email/restaurant-buyer-email-verification.html",
    "name": "Email verification - F&B",
    "sections": [
      {
        "id": "subject",
        "fields": [
          {
            "word": "@fnb_email_verif_subject"
          }
        ]
      },
      {
        "id": "variable",
        "fields": [
          {
            "word": "@fnb_email_verif_hellothere"
          },
          {
            "word": "@fnb_email_verif_body"
          },
          {
            "word": "@fnb_email_verif_confirm_btn"
          },
          {
            "word": "@fnb_email_verif_regards"
          }
        ]
      }
    ]
  }
];

const localizationDashboardData = [
  {
    "id": "dashboard_livechat",
    "template_url": "",
    "name": "Live chat",
    "sections": [
      {
        "id": "variable",
        "fields": [
          {
            "word": "dashboard_livechat_your_cart_is_ready"
          }
        ]
      }
    ]
  }
];

const paymentImages = [
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/visa.png',
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/master.png',
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/jcb.png',
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/american-express.png',
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/diners-club.png',
  'https://jumperdevnew.appspot.com/web-b/resources/images/card-types/discover.png'
];

export default {
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonTextarea,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      chevronDownCircleOutline,
      helpCircleOutline,
      activeTab: 'conversations',
      loading: false,
      csvToUpload: undefined,
      csvButtonToUpload: undefined,
      csvFileUploaded: false,
      csvButtonFileUploaded: false,
      listOfCommentsResult: [],
      localizationEmailData,
      localizationWebviewData,
      localizationDashboardData,
      mandatory_tags_fields,
      paymentImages,
      shippingAddressPreview: [],
      loyaltyPreview: [],
      allWords: [],
      allWordsKeyPair: {},
      commentsPair: {},
      expandedAccordion: '',
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    allWordsKeyPair: {
      handler: function (newValue) {
        const { wb_mobile_number, wb_address_line1, wb_country, wb_state, wb_city, wb_postal_code, wb_address_tag, wb_loyalty_reg_name, wb_loyalty_reg_email, wb_loyalty_reg_mobile, wb_loyalty_reg_dob } = this.allWordsKeyPair;
        this.shippingAddressPreview = [wb_mobile_number, wb_address_line1, wb_country, wb_state, wb_city, wb_postal_code, wb_address_tag];
        this.loyaltyPreview = [wb_loyalty_reg_name, wb_loyalty_reg_email, wb_loyalty_reg_mobile, wb_loyalty_reg_dob]
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      this.fetchWords();
      this.fetchListOfComments();
    },
    async fetchWords() {
      try {
        this.loading = true;
        let personalizationResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-words");
        if ((personalizationResponse.status == 200 || personalizationResponse.status == 201) && personalizationResponse.data.success) {
          for (let i = 0; i < personalizationResponse.data.words.length; i++) {
            // code to keep all words in single  array and with key pair object 
            const field = personalizationResponse.data.words[i];
            if (field.word) {
              let fieldToUse = { ...field };
              if (this.mandatory_tags_fields.includes(field.word)) {
                fieldToUse.tags_mandatory = true;
              }
              this.allWords.push(fieldToUse);
              this.allWordsKeyPair[field.word] = fieldToUse;
            }
          }
          this.generateEmailLangData();
          this.generateWebviewLangData();
          this.generateDashboardLangData();
        } else {
          if (personalizationResponse.data && !personalizationResponse.data.success && personalizationResponse.data.errorMessage) {
            this.updateToast({ open: true, message: personalizationResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        this.loading = false;
      }
    },
    async fetchListOfComments() {
      try {
        let fetchCommentsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "list-comments");
        if ((fetchCommentsResponse.status == 200 || fetchCommentsResponse.status == 201) && fetchCommentsResponse.data.success) {
          this.listOfCommentsResult = fetchCommentsResponse.data.messages;
          for (let i = 0; i < fetchCommentsResponse.data.messages.length; i++) {
            const comments = fetchCommentsResponse.data.messages[i];
            if (comments.vertex) {
              this.commentsPair[comments.vertex] = comments;
            }
          }
        } else {
          if (fetchCommentsResponse.data && !fetchCommentsResponse.data.success && fetchCommentsResponse.data.errorMessage) {
            this.updateToast({ open: true, message: fetchCommentsResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      }
    },
    generateEmailLangData() {
      for (let i = 0; i < this.localizationEmailData.length; i++) {
        for (let j = 0; j < this.localizationEmailData[i].sections.length; j++) {
          for (let k = 0; k < this.localizationEmailData[i].sections[j].fields.length; k++) {
            let field = this.localizationEmailData[i].sections[j].fields[k];
            let fieldToUse = this.allWordsKeyPair[field.word];
            this.localizationEmailData[i].sections[j].fields[k] = { ...fieldToUse };
          }
        }
      }
    },
    generateWebviewLangData() {
      for (let i = 0; i < this.localizationWebviewData.length; i++) {
        for (let j = 0; j < this.localizationWebviewData[i].sections.length; j++) {
          for (let k = 0; k < this.localizationWebviewData[i].sections[j].fields.length; k++) {
            let field = this.localizationWebviewData[i].sections[j].fields[k];
            let fieldToUse = this.allWordsKeyPair[field.word];
            this.localizationWebviewData[i].sections[j].fields[k] = { ...fieldToUse };
          }
        }
      }
      // TODO conditions

      // if($rootScope.sellerproducttypeProductEnabled != true && $rootScope.sellerproducttypeEventEnabled != true){
      //     $('#panel-loc-webview-viewDetails').hide();
      //     $('#panel-loc-webview-alreadyUpdated').hide();
      //     $('#panel-loc-shopsp-singlePage').hide();
      //     $('#panel-loc-shopsp-shop').hide();
      //     $('#panel-loc-email-otp_email').hide();
      // }

      // if($rootScope.sellerproducttypeProductEnabled != true && $rootScope.sellerproducttypeRestaurantEnabled != true){
      //     $('#panel-loc-webview-updateCartGeneric').hide();
      //     $('#panel-loc-webview-loyaltyRegister').hide();
      // }

      // if($rootScope.sellerproducttypeProductEnabled != true){
      //     $('#panel-loc-webview-address').hide();
      //     $('#panel-loc-webview-shopPolicies').hide();
      //     $('#panel-loc-webview-singleProductCart').hide();
      //     $('#panel-loc-email-order_confirmation').hide();
      //     $('#panel-loc-email-shipping_tracking_link').hide();
      // }

      // if($rootScope.sellerproducttypeEventEnabled != true){
      //     $('#panel-loc-webview-eventTerms').hide();
      //     $('#panel-loc-email-booking_confirmation').hide();
      // }

      // if($rootScope.sellerproducttypeRestaurantEnabled != true){
      //     $('#panel-loc-webview-restaurantOrder').hide();
      //     $('#panel-loc-webview-fnbDeliveryAddress').hide();
      //     $('#panel-loc-webview-alreadyUpdatedFnb').hide();
      //     $('#panel-loc-webview-fnbPickupCalendar').hide();
      //     $('#panel-loc-webview-fnbReservationCalendar').hide();
      //     $('#panel-loc-email-order_confirmation_restaurant').hide();
      //     $('#panel-loc-email-fnb_email_verification').hide();
      //     $('#panel-loc-shopsp-singleRestaurantPage').hide();
      // }

    },
    generateDashboardLangData() {
      for (let i = 0; i < this.localizationDashboardData.length; i++) {
        for (let j = 0; j < this.localizationDashboardData[i].sections.length; j++) {
          for (let k = 0; k < this.localizationDashboardData[i].sections[j].fields.length; k++) {
            let field = this.localizationDashboardData[i].sections[j].fields[k];
            let fieldToUse = this.allWordsKeyPair[field.word];
            this.localizationDashboardData[i].sections[j].fields[k] = { ...fieldToUse };
          }
        }
      }
    },
    async handleUploadCSV(event) {
      try {
        this.csvFileUploaded = true;
        let fileToSend = event.target.files[0];
        if (fileToSend && fileToSend.name.lastIndexOf(".csv") !== -1) {
          let newCsv = new FileReader();
          newCsv.readAsText(fileToSend);
          newCsv.onload = async (event) => {
            this.csvFileUploaded = true;
            let csv = event.target.result;
            let lines = csv.split("\n");
            let headers = lines[0].split(",");
            if (headers.length == 0) {
              this.csvFileUploaded = false;
              this.updateToast({ open: true, message: "Please upload valid file with the data!", type: "danger", });
              return;
            }
            this.csvToUpload = fileToSend;
            const responseUpload = await this.uploadDocument(this.csvToUpload, '/add-doc');
            let requestData = {
              url: responseUpload.url,
            };
            const uploadedCsvResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "import-custom-talk", requestData);
            if (uploadedCsvResponse.data.success) {
              this.updateToast({ open: true, message: "Your customized CSV file has been uploaded successfully! Please try any default checkout journey to verify that your customized messages/buttons are there.", type: "success" });
            } else {
              this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
            }
            this.csvFileUploaded = false;
          };
          newCsv.onerror = function () {
            this.updateToast({ open: true, message: "Unable to read " + fileToSend.name, type: "danger", });
          };
        } else {
          this.updateToast({ open: true, message: "Please add a valid CSV", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.csvFileUploaded = false;
      }
    },
    async handleUploadButtonCSV(event) {
      try {
        this.csvButtonFileUploaded = true;
        let fileToSend = event.target.files[0];
        if (fileToSend && fileToSend.name.lastIndexOf(".csv") !== -1) {
          let newCsv = new FileReader();
          newCsv.readAsText(fileToSend);
          newCsv.onload = async (event) => {
            this.csvButtonFileUploaded = true;
            let csv = event.target.result;
            let lines = csv.split("\n");
            let headers = lines[0].split(",");
            if (headers.length == 0) {
              this.csvButtonFileUploaded = false;
              this.updateToast({ open: true, message: "Please upload valid file with the data!", type: "danger", });
              return;
            }
            this.csvButtonToUpload = fileToSend;
            const responseUpload = await this.uploadDocument(this.csvButtonToUpload, '/add-doc');
            let requestData = {
              url: responseUpload.url,
            };
            const uploadedBttnCsvResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "import-templates", requestData);
            if (uploadedBttnCsvResponse.data.success) {
              this.updateToast({ open: true, message: "Your customized CSV file has been uploaded successfully! Please try any default checkout journey to verify that your customized messages/buttons are there.", type: "success" });
            } else {
              this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
            }
            this.csvButtonFileUploaded = false;
          };
          newCsv.onerror = function () {
            this.updateToast({ open: true, message: "Unable to read " + fileToSend.name, type: "danger", });
          };
        } else {
          this.updateToast({ open: true, message: "Please add a valid CSV", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.csvButtonFileUploaded = false;
      }
    },
    renderFormattedMessage(message) {
      message = message.replace(/<br>/g, '\n');
      return message;
    },
    renderTemplateChatMessage(data) {
      let outputMessage = "";
      if (data.custom_message.length == 0) {
        outputMessage = data.message[0];
      }
      else {
        outputMessage = data.custom_message[0];
      }
      return outputMessage;
    },
    removeAtTheRate(input) {
      input = input.replace('@', '');
      return input;
    },
    // Method to find All the TAGs used within message
    renderRequiredVariables(text) {
      let tags = [];
      if (typeof text == "undefined") {
        return 0
      }
      else if (typeof text == "string") {
        let singleLine = text;
        if (singleLine.indexOf('<') > -1 && singleLine.indexOf('>') > -1) {
          singleLine = singleLine.split('<');
          for (let j = 1; j < singleLine.length; j++) {
            let singleTag = singleLine[j].split('>');
            singleTag = singleTag[0];
            singleTag = "<" + singleTag + ">";
            if (singleTag != '<br>' && singleTag != '<br/>' && singleTag != '<br />') {
              tags.push(singleTag);
            }
          }
        }
      }
      console.log(tags);
      return tags;
    },
    async saveWord(field) {
      const { word, user_word: button_word, default_word, tags_mandatory } = field;
      if (button_word.length == 0) {
        this.updateToast({ open: true, message: "Please enter your personalised text!", type: "danger" })
      }
      else {
        let tagsMatched = true;
        let needTags;
        let defaultTagValue = [];
        let customTagValue = [];
        defaultTagValue = this.renderRequiredVariables(default_word);
        customTagValue = this.renderRequiredVariables(button_word);
        if (defaultTagValue.length >= 1) {
          needTags = true;
        }
        else {
          needTags = false;
        }
        if (needTags) {
          if (customTagValue.length >= 1) {
            tagsMatched = true;
          }
          else {
            tagsMatched = false;
          }
        }
        if (tagsMatched == false && tags_mandatory == true) {
          this.updateToast({ open: true, message: "Please enter all mandatory tags (&lt;TAG&gt;) in your text!", type: "danger" })
        }
        else {
          try {
            this.loading = true;
            let saveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-word", { word, button_word });
            if ((saveResponse.status == 200 || saveResponse.status == 201) && saveResponse.data.success) {
              console.log(saveResponse);
            } else {
              if (saveResponse.data && !saveResponse.data.success && saveResponse.data.errorMessage) {
                this.updateToast({ open: true, message: saveResponse.data.errorMessage, type: "danger" })
              } else {
                this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
              }
            }
          } catch (error) {
            console.log("Exception: ", error);
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          } finally {
            this.loading = false;
          }
        }
      }
    },
    async saveCommentsMessage(field) {
      const { custom_message: message, message: default_message, vertex } = field;
      if (message.length == 0) {
        this.updateToast({ open: true, message: "Please enter your personalised text!", type: "danger" })
      }
      else {
        let tagsMatched = true;
        let needTags;
        let defaultTagValue = [];
        let customTagValue = [];
        defaultTagValue = this.renderRequiredVariables(default_message);
        customTagValue = this.renderRequiredVariables(message);
        if (defaultTagValue.length >= 1) {
          needTags = true;
        }
        else {
          needTags = false;
        }
        if (needTags) {
          if (customTagValue.length >= 1) {
            tagsMatched = true;
          }
          else {
            tagsMatched = false;
          }
        }
        if (!tagsMatched) {
          this.updateToast({ open: true, message: "Mandatory tags (&lt;TAG&gt;) missing, kindly re-check", type: "danger" })
        }
        else {
          let requestData = {
            vertex: vertex,
            message: message,
          };
          try {
            this.loading = true;
            let saveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-custom", requestData);
            if ((saveResponse.status == 200 || saveResponse.status == 201) && saveResponse.data.success) {
              this.updateToast({ open: true, message: 'You have successfully personalised the messages!', type: "success" })
            } else {
              if (saveResponse.data && !saveResponse.data.success && saveResponse.data.errorMessage) {
                this.updateToast({ open: true, message: saveResponse.data.errorMessage, type: "danger" })
              } else {
                this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
              }
            }
          } catch (error) {
            console.log("Exception: ", error);
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          } finally {
            this.loading = false;
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin: 10px 10px;
}

.pageTitle {
  font-weight: 400;
  font-size: 28px;
  margin: 10px 20px 7px;
}

.table-container {
  padding: 10px;
  border-radius: 5px;
  border-width: 0px;
  font-size: 13px;
  margin: 15px 0px 20px 0px;
  background: var(--ion-background-color, #fff);
  -webkit-border-radius: 5px;
  overflow: hidden;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid #EDEDED;
  color: var(--ion-color-black);
}

.panel-body {
  padding: 15px;
}

.delivery-container {
  margin-bottom: 20px;
  margin-top: 10px;
}

.delivery-container p {
  padding-top: 3px;
}

.csvImportStepHeading {
  text-transform: none;
  font-weight: 600;
  font-size: 17px;
  color: var(--ion-color-black)
}

.panel.panel-default {
  background-color: var(--ion-background-info-primary);
  border-radius: 5px;
  border: none;
  box-shadow: none;
}

.viewImageWebviewImgContainer {
  height: 95px;
  width: 95px;
}

.panel-header {
  padding-left: 15px;
}

.panel-text {
  font-size: 16px;
  color: #000000;
  padding-bottom: 10px;
  padding: 15px;
}

.panel .panel-text {
  border-left: 3px var(--ion-color-primary) solid;
}

.csvFileContainerOuter {
  margin: 20px auto;
  max-width: 100%;
}

.startUploadCsvFileContainer {
  width: 100%;
  cursor: pointer;
  border: 2px #ccc dashed;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 25px;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.startUploadCsvFileContainer p.title {
  font-size: 16px;
  color: var(--ion-color-black);
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  line-height: 1.4;
}

.startUploadCsvFileContainer p.subTitle {
  font-size: 13px;
  color: var(--ion-color-grey);
  padding: 0px;
  margin: 0px;
  margin-top: 5px;
  line-height: 1.4;
}

.startUploadCsvFileContainer img.uploadIcon {
  height: 40px;
  width: auto;
}

.webview-container {
  margin: 15px 20px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
}

.cust-border {
  border-top: 1px solid #EDEDED;
}

.singleSection {
  margin-bottom: 10px;
  border-bottom: 1px #EDEDED solid;
}

.singleSection:last-child {
  border-bottom: 0px;
}

.localizationSubheading {
  font-weight: 600;
  font-size: 15px;
  padding: 0px 0px 15px;
  margin: 0px;
  text-decoration: underline;
}

.comments-subHeading {
  font-weight: 600;
  font-size: 15px;
  padding: 0px 0px 15px;
  margin-right: 3px;
  text-decoration: underline;
  display: inline;
}

ion-input.sctn-input {
  --background: #f1f1f1;
  color: #000000;
}

ion-textarea.sctn-input {
  --background: #f1f1f1;
  color: #000000;
}

.comments-textarea {
  color: #262626
}

.title-container {
  margin: 20px 0px 0px 30px;
}

.sctn-labels {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-bottom: 0px;
}

.comment-img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.comments-box {
  display: inline-block;
  vertical-align: bottom;
  margin: 0px 0px 5px 3px;
  width: calc(100% - 33px);
}

ion-toolbar.saveBttn {
  --background: var(--ion-color-white);
}

.newStripeLikeCard.suqareForm {
  margin: 30px 0px 20px;
  overflow: hidden;
}

.newStripeLikeCard input,
.newStripeLikeCard {
  padding: 12px;
  background-color: var(--ion-color-white);
  width: 100%;
  border: 1px transparent solid;
  line-height: 24px;
}

.newStripeLikeCard input {
  border-bottom: 1px #e6ebf1 solid;
}

.newStripeLikeCard .third {
  float: left;
  width: calc(100% / 3);
  padding: 0;
}

p.jumperText {
  font-size: 11px;
  font-weight: normal;
  color: #aaa;
  line-height: 1.3;
  padding: 3px 0px 0px;
  margin-top: 20px;
  text-transform: none;
  height: 20px;
  text-align: center;
}

.poweredByJumperLink {
  color: var(--ion-color-primary);
  padding-left: 5px;
}

.supportedCardTypesLogos {
  width: 100%;
  display: block;
  position: relative;
}

.supportedCardTypesLogos img {
  height: 30px;
  width: auto;
  display: inline-block;
  margin: 10px 0px;
  vertical-align: middle;
}

.webviewButtonArea {
  padding: 15px;
}

.updateCartOptionsTags span {
  font-size: 13px;
  margin-bottom: 5px;
  padding: 0px 10px 0px 0px;
  font-weight: 400;
  color: var(--ion-color-black);
}

.closeWebviewForNonMessenger {
  padding: 5px 10px 15px;
  font-size: 13px;
  color: var(--ion-preview-grey);
  font-weight: 400;
  text-align: center;
  display: block;
}

.requiredVariablesColoredBox span {
  display: inline-block;
  margin: 5px 5px 5px 0px;
  padding: 5px;
  color: var(--ion-color-white);
  background-color: #ff0000;
  font-size: 13px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.webviewPreviewContainer {
  width: 100%;
  display: block;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  pointer-events: none;
  cursor: not-allowed;
  border: 1px #ddd solid;
  background-color: #FAFAFA;
  color: #000000;
  max-width: 450px;
  margin: 20px auto 5px;
}

.webviewPreviewContainer:empty {
  border: none;
}

.webviewPreviewContainer .addressForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.webviewPreviewContainer .addressForm .prdct-section {
  width: 100%;
}

.webviewPreviewContainer .addressForm .wb_country,
.webviewPreviewContainer .addressForm .wb_state,
.webviewPreviewContainer .addressForm .wb_city,
.webviewPreviewContainer .addressForm .wb_postal_code {
  width: calc(50% - 5px)
}

.viewImageWebviewArea h5.viewImageWebviewTitle {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0px 10px 0px 20px;
  font-weight: 500;
  line-height: 1.4;
}

.containerPadding {
  padding-top: 10px;
}

.addressForm {
  padding: 15px;
}

.webviewCard {
  background-color: var(--ion-color-white);
  border-radius: 5px;
  box-shadow: 0 0px 5px rgb(0 0 0 / 20%);
  width: calc(100% - 10px);
  margin: 5px auto;
  padding: 10px 15px;
}

img.paymentSuccessIconImg {
  margin: 0 auto 10px;
  height: 70px;
  width: auto;
  max-width: 100%;
  display: block;
}

.productNamePriceArea {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.order-address {
  font-size: 12px;
  color: var(--ion-color-grey);
  line-height: 1.5;
}

.preview-text {
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-preview-grey);
}

.webviewPreviewTitle {
  padding: 15px 15px 0px 15px;
}

.logoCommonHeader {
  padding: 12px 15px;
  border-bottom: 1px #dadada solid;
}

.headerWebview {
  padding: 15px 15px 15px;
  font-size: 16px;
  text-align: center;
  color: var(--ion-color-black);
}

.qtySelectorArea {
  text-align: right;
}

.qtySelectorArea .qtyPlusMinusBtn {
  height: 27px;
  width: 27px;
  border: 1px solid var(--ion-color-primary);
  font-weight: bold;
  font-size: 18px;
  color: var(--ion-color-primary);
  border-radius: 5px;
  background-color: #fff;
}

.qtySelectorArea input.qtyText {
  height: 28px;
  width: 35px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px #ddd solid;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px 5px;
}

.product-cart {
  background: var(--ion-color-white);
}

.marginLR15px {
  margin: 0px 15px;
}

.collectionSingleItem {
  border: 1px #dadada solid;
  border-radius: 10px;
  padding: 15px;
  background-color: var(--ion-color-white);
}

.padding10pxAll {
  padding: 10px;
}

.logoImg {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.dishImage {
  height: auto;
  width: 90px;
  vertical-align: middle;
  border-radius: 4px;
}

.updateCartOptionsTags {
  margin-top: 10px;
}

.collectionSingleItem h5.title {
  font-size: 13px;
  margin-top: 0px;
  color: var(--ion-color-grey);
}

.collectionSingleItem span {
  padding: 0px 5px;
  color: #aaa;
}

.dishDataBesideImage {
  margin-left: 15px;
  vertical-align: middle;
  display: inline-block;
}

.cart-container .dishDataBesideImage {
  margin-bottom: 18px;
}

.cart-container h5.title {
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 2px;
  padding: 0px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--ion-color-black);
}

.cart-container p.subData {
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;
  padding: 0px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--ion-color-grey);
}

.cart-container {
  border-bottom: 1px #eee solid;
  padding: 15px;
}

.panel-body table.table {
  margin-bottom: 0px;
  width: 100%;
}

.table {
  background: none;
  margin: 5px;
}

.table tbody:last-child td {
  padding: 0px;
}

.order-confirm {
  width: calc(100% - 170px);
  margin-left: 0px;
}

.productImageArea {
  display: inline-block;
}

.webviewHeading {
  font-size: 15px;
  margin-top: 12px;
  color: var(--ion-color-black);
}

.qtyPlusBtn {
  border-radius: 0px 5px 5px 0px;
  opacity: 0.5;
  cursor: not-allowed;
  color: #000;
  border: 1px #000 solid;
  height: 34px;
}

.qtyMinusBtn {
  border-radius: 5px 0px 0px 5px;
  opacity: 0.5;
  cursor: not-allowed;
  color: #000;
  border: 1px #000 solid;
  height: 34px;
}

.qtyText {
  width: 120px;
  height: 32px;
  text-align: center;
}

.webViewSaveBttn {
  margin-top: -1px;
}

@media only screen and (max-width: 760px) {
  .panel-body {
    padding: 0px;
  }

  .prdct-section {
    margin: 5px 15px;
  }

  .localizationSubheading {
    margin-top: 20px;
  }

  .sppChatBubble {
    padding-left: 5px;
    margin-top: 15px;
  }

  .requiredVariablesColoredBox {
    margin-left: 15px;
  }

  .tags-message {
    margin-top: 15px;
  }

  .webViewSaveBttn {
    margin-top: -1px;
    float: right;
  }

  .comments-box {
    margin: 0px 0px 5px -3px;
  }
}
</style>