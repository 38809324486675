<template>
  <div class="content-box">
    <div v-if="!userCredits" class="loading">Loading...</div>
    <div v-else-if="loading" class="inline-loading">Loading...</div>

    <div v-if="userCredits && userCredits.errorMessage" class="error">{{ userCredits.errorMessage }}</div>
    <div v-else-if="userCredits && !userCredits.success" class="error">Error while fetching credits</div>
    <div v-else-if="userCredits && userCredits.credit != undefined">
      <div class="total-credits">Total Credits: ${{ userCredits.credit }}</div>
      <div class="msg-count-wrapper">
        <div class="label">Available message count</div>
        <div class="available-msg">
          <img :src="imageChannelMapping.telegram">
          <div v-if="userCredits.messages.telegram > 1">{{ userCredits.messages.telegram }} messages</div>
          <div v-else>{{ userCredits.messages.telegram }} message</div>
        </div>
        <div class="available-msg">
            <img :src="imageChannelMapping.line">
            <div v-if="userCredits.messages.line > 1">{{ userCredits.messages.line }} messages</div>
            <div v-else>{{ userCredits.messages.line }} message</div>
        </div>
        <div class="available-msg">
          <img :src="imageChannelMapping.sms">
          <div v-if="userCredits.messages.sms > 1">{{ userCredits.messages.sms }} messages</div>
          <div v-else>{{ userCredits.messages.sms }} message</div>
        </div>
      </div>
    </div>
    <div v-else class="error">Yet to set credits</div>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
const imageChannelMapping = {
  sms: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg",
  line: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/LINE.png",
  telegram: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA"
};

export default {
  props: {
  },
  mounted() {
    this.loadCredits();
  },
  computed: {
    ...mapState({
      userCredits: (state) => state.user.credits,
    }),
  },
  data(){
    return {
      imageChannelMapping,
      loading: false
    }
  },
  methods: {
    ...mapActions({
      fetchCreditsForUser: "user/fetchCreditsForUser",
    }),
    async loadCredits() {
      let url = this.environment('VUE_APP_ROOT_API') + "get-user-credits";
      this.loading = true;
      await this.fetchCreditsForUser(url);
      this.loading = false;
    }
  }
}
</script>

<style scoped>
ion-popover {
  --background: #f2f2f2;
}
.inline-loading {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--ion-color-light-shade);
  font-size: 10px;
  padding: 2px 4px;
}
.error, .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90px;
}
.content-box {
  background: #f2f2f2;
  color: black;
  min-height: 100px;
}
.total-credits{
  padding-bottom: 10px;
  font-weight: bold;
}
.msg-count-wrapper .label {
  padding-bottom: 5px;
}
.available-msg img {
  margin-right: 10px;
}
.available-msg {
  padding-bottom: 5px;
  font-size: 14px;
}
.available-msg {
  display: flex;
}
.available-msg img{
  height: 20px;
}
</style>