<template>
  <ion-item lines="none root">
    <div class="step-progress-wrapper" >
      <div class="connect-line"></div>
      <div v-for="(step, index) in steps" :key="index" lines="none"  class="step-icon ion-no-padding ion-no-margin">
          <div @click="onClickStep(step, index)" class="step-row"  :class="{active: index == activeStep, disabled: step.disabled}">
            <div class="step-button-wrapper">
              <ion-icon v-if="step.code == 'done'" :icon="checkmark" class="step-done"></ion-icon>
              <ion-icon class="active-icon" v-else-if="index == activeStep" slot="start" :icon="playOutline"></ion-icon>
              <div v-else class="step-number">{{index+1}}</div>
            </div>
            <div class="step-title">
              <span>{{step.title}}</span>
            </div>
          </div>
      </div>
    </div>
  </ion-item>
</template>

<script>
import { ellipseOutline, caretForwardCircleOutline, playOutline, checkmark } from 'ionicons/icons';
import { IonIcon } from "@ionic/vue";

export default {
  components: { IonIcon },
  data() {
    return {
      activeStep: 0,
      ellipseOutline,
      caretForwardCircleOutline,
      playOutline,
      checkmark
    }
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    propActiveStep: {
      type: Number,
      default: 0
    }
  },
  watch: {
    propActiveStep: {
      handler: function (val) {
        this.activeStep = val;
      },
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    onClickStep(step, index) {
      if (step.disabled) {
        return
      }
      this.activeStep = index;
      this.$emit('onSelectStep', { step, index });
    }
  }
}
</script>

<style scoped>
.root{
  margin-bottom: 10px;
}
.step-button-wrapper{
  border-radius: 50%;
  border: 1px solid;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-medium);
  color: #fff;
}
.active .step-button-wrapper{
  border-color: var(--ion-color-dark);
  color: var(--ion-color-medium);
  background-color: var(--ion-color-dark);
}

.active-icon{
  padding-left: 2px;
}
.connect-line{
  width: 100%;
  height: 1px;
  position: absolute;
  background: var(--ion-color-medium);
  left: 0;
  bottom: 12px;
}
.step-progress-wrapper{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: flex-end;
}
.step-icon{
  height: 25px;
  min-height: 16px;
  min-width: 16px;
  background: none;
  opacity: 1;
  z-index: 99;
}
.step-button-wrapper{
  cursor: pointer;
}
.step-row{
  display: flex;
  align-items: center;
  background: #fff;
}
.step-title {
  padding-left: 5px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  display: none;
  max-width: 110px;
}
.step-icon:hover .step-title{
  display: block;
}
.step-icon:last-of-type .step-title {
  right: 0;
}
.step-title span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled > * {
  opacity: 0.6;
  cursor: auto;
}
</style>