import jwtInterceptor from '../../shared/jwtInterceptor';

const state = () => ({
  user: {
    adSpend: undefined,
    agent_nickname: undefined,
    alreadySelling: undefined,
    appsumo: false,
    blocked: false,
    businessName: undefined,
    businessType: undefined,
    business_contact: undefined,
    business_contact_verified: false,
    business_country_code: undefined,
    business_email: undefined,
    business_email_verified: false,
    created: undefined,
    currency: undefined,
    discount_code: false,
    email: undefined,
    emailverified: false,
    enterprise: false,
    enterprise_Name: undefined,
    first_promoter_auth: undefined,
    first_promoter_link: undefined,
    lastActiveTime: undefined,
    loginCredential: false,
    mobileNumber: undefined,
    mobilecountrycode: undefined,
    mobileverified: false,
    name: undefined,
    nric: null,
    permission: undefined,
    referallshopName: undefined,
    seller: false,
    sellerToggle: false,
    sellerproducttype: undefined,
    shopLogo: undefined,
    shopName: undefined,
    shopdescription: undefined,
    shopifyactive: false,
    shopifyconnected: false,
    shopifyprivate: false,
    skipaddress: false,
    skipemail: false,
    skipemailverification: false,
    stackcommerce: false,
    support_contact: undefined,
    support_contact_verified: false,
    support_country_code: undefined,
    support_email: undefined,
    support_email_verified: false,
    support_number: false,
    timezone: undefined,
    tutorial: false,
    type: undefined,
    usercountry: undefined,
    woocommerce: false,
    wooshippingapi: undefined,
  },
  permission: {},
  subscription: {},
  roleDetails: {},
  credits: undefined
});

const getters = {
  getPermissions(state) {
    return state.permission.permissions;
  },
  getUser(state) {
    return state.user;
  },
  getBasicPermission(state) {
    return state.permission;
  },
  fetchSubscriptionDetails(state) {
    return state.subscription;
  },
  fetchRoleDetailsDetails(state) {
    return state.roleDetails;
  },
  isAdmin(state) {
    if(state.permission?.admin === false) return false;
    return true;
  }
};

const actions = {
  saveUserData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('saveUserData', payload)
      dispatch('payment/updateAvailablePaymentMethods', { country: payload.usercountry, currency: payload.currency }, { root: true })
      dispatch('integration/updateAvailableIntegrationMethods', { accountServiceType: payload.sellerproducttype, country: payload.usercountry, enterprise_Name: payload.enterprise_Name }, { root: true })
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load user data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  },
  savePermissionData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('savePermissionData', payload)
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load permission data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  },
  saveSubscriptionData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('saveSubscriptionData', payload)
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load subscription data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  },
  saveRoleDetailsData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('saveRoleDetailsData', payload)
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load profile data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  },
  async fetchCreditsForUser({ commit, dispatch }, url) {
    try {
				let response = await jwtInterceptor.post(url);
        commit('setCredits', response.data)
      } catch (error) {
				console.error("Exception", error);
				this.merchantCredits = { error: true }
      }
		}
}

const mutations = {
  saveUserData(state, data){
    for(var prop in data) {
      state.user[prop] = data[prop];
    }
  },
  savePermissionData(state, data){
    for(var prop in data) {
      state.permission[prop] = data[prop];
    }
  },
  saveSubscriptionData(state, data){
    for(var prop in data) {
      state.subscription[prop] = data[prop];
    }
  },
  saveRoleDetailsData(state, data){
    for(var prop in data) {
      state.roleDetails[prop] = data[prop];
    }
  },
  setCredits(state, credits) {
    state.credits = credits;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};