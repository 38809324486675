<template>
  <ion-icon
    @click="openPopover($event)"
    style="cursor: pointer"
    :icon="helpCircleOutline"
  ></ion-icon>
  <ion-popover
    :is-open="popoverOpen"
    :event="event"
    @didDismiss="popoverOpen = false"
    :style="style"
    :side="side"
    :alignment="alignment"
    :showBackdrop="false"
  >
    <ion-content
      class="content-text ion-no-padding"
      @click="closePopover($event)"
    >
      <div class="tooltip-wrapper">
        <div v-if="title" class="header">{{title}}</div>
        <div class="content"><slot></slot></div>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script>
import { IonContent, IonPopover } from "@ionic/vue";
import { helpCircleOutline } from "ionicons/icons";

export default {
  data: () => {
    return {
      popoverOpen: false,
      event: null,
      helpCircleOutline,
    };
  },
  props: {
    // eg. :style="{'--width': '170px'}"
    style: {
      type: Object,
      default: () => {
        return {
          "--offset-y": "-28px"
        };
      },
    },
    side: {
      type: String,
      default: "top",
    },
    alignment: {
      type: String,
      default: "center",
    },
    title: {
      type: String,
      default: ""
    }
  },
  components: {
    IonContent,
    IonPopover,
  },
  methods: {
    openPopover(e) {
      console.log('Test');
      this.event = e;
      this.popoverOpen = true;
    },
    closePopover(e) {
      this.event = e;
      this.popoverOpen = false;
    },
  },
};
</script>

<style scoped>
ion-popover{
    --width: auto;
    padding: 10px;
  }
  .tooltip-wrapper{
    width: 100%;
  }
  .header{
    padding: 5px;
  }
  .content {
    padding: 20px 5px;
    font-size: 12px;
  }
  .header {
    background-color: var(--ion-color-medium);
  }
</style>