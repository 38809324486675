<template>
  <master-layout pageTitle="iMessage">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="imessageConnected">
          <ion-toolbar color="light">
            <ion-segment scrollable color="dark" v-model="activeTab" class="segment ion-justify-content-center">
              <ion-segment-button value="account">
                <ion-label>Connected Account</ion-label>
              </ion-segment-button>
              <ion-segment-button value="greeting">
                <ion-label>Greeting Message</ion-label>
              </ion-segment-button>
              <ion-segment-button value="button">
                <ion-label>Customise Button</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-toolbar>
          <div v-if="activeTab === 'account'" class="model-sections">
            <ion-item lines="none" style="padding: 15px;">
              <ion-avatar slot="start" style="height: auto; width: 38px;">
                <img src="https://jumper.ai/landing/resources/images/imessage-logo.png"/>
              </ion-avatar>
              <ion-label>
                <h3 class="title">Connected Apple Business Chat account</h3>
                <p class="sub-title">Your store and iMessage bot is connected to the below Apple Business Chat account.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-col class="ion-no-padding"  size="12">
                <ion-item lines="none" class="imessage-container">
                  <ion-label>
                    <h3 class="title">{{ imessageSocialData.name }}</h3>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-item>
          </div>
          <div v-if="activeTab === 'greeting'" class="model-sections">
            <ion-item lines="none" class="cust-padding">
              <ion-label>
                <h3 class="title">Greeting message</h3>
                <p class="sub-title">This message is sent automatically to greet a customer that messages you.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none" class="cust-padding" style="padding-bottom: 0px">
              <div class="prdct-section">
                <ion-card-title class="sctn-labels">Enter your response message:</ion-card-title>
                <ion-textarea v-model="message" rows="4" placeholder="Enter some message here..." class="fullBordered input_custom_style text-area"></ion-textarea>
              </div>
            </ion-item>
            <ion-toolbar style="padding-right: 10px">
              <ion-buttons slot="end">
                <ion-button :disabled="!messageChanged"  @click="updateGreeting" fill="solid" expand="block" > 
                  Save
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </div>
          <div v-if="activeTab === 'button'">
            <ion-grid class="container">
              <ion-row>
                <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding"> 
                  <div class="model-sections gridPadding">   
                    <div class="header">
                      <ion-label>
                        <h3 class="title">Apple Business Chat Button</h3>
                        <p class="sub-title">This button appears on your online store and lets customers contact you with Messages on their iPhone, iPad, or Mac.</p>
                      </ion-label>
                    </div>
                    <div class="infoBanner">
                      <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                        <div class="panel-body">This button will only be visible to customers using a compatible Apple device.</div>
                      </div>
                    </div>
                    <ion-grid class="imessage-customization">
                      <ion-row class="rowMargin">
                        <ion-col class="colMargin">
                          <ion-label>Button Style</ion-label>
                        </ion-col>
                        <ion-col class="colMargin">
                          <ion-select v-model="button.type" placeholder="Select button style" class="slctContainer">
                            <ion-select-option value="big_button">Button with message</ion-select-option>
                            <ion-select-option value="round_button">Button with icon only</ion-select-option>
                          </ion-select>
                        </ion-col>
                      </ion-row>
                      <ion-row class="rowMargin" v-if="button.type === 'big_button'">
                        <ion-col class="colMargin">
                          <ion-label>Background Color</ion-label>
                        </ion-col>
                        <ion-col>
                          <div class="colorPickerWrapper" :style="{'background-color':button.bgcolor}">
                            <ion-input v-model="button.bgcolor" type="color" class="themePicker"></ion-input>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row class="rowMargin" v-if="button.type === 'big_button'">
                        <ion-col class="colMargin">
                          <ion-label>Text Color</ion-label>
                        </ion-col>
                        <ion-col>
                          <div class="colorPickerWrapper" :style="{'background-color':button.color}">
                            <ion-input v-model="button.color" type="color" class="themePicker"></ion-input>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row class="rowMargin">
                        <ion-col class="colMargin">
                          <ion-label>Call to action</ion-label>
                        </ion-col>
                        <ion-col>
                          <ion-input type="text" placeholder="Enter CTA" v-model="button.title" class="fullBordered input_custom_style sctn-input"></ion-input>
                        </ion-col>
                      </ion-row>
                      <ion-row class="rowMargin" v-if="button.type === 'big_button'">
                        <ion-col class="colMargin">
                          <ion-label>Context message</ion-label>
                        </ion-col>
                        <ion-col>
                          <ion-input type="text" placeholder="Enter context message" v-model="button.subtitle" class="fullBordered input_custom_style sctn-input"></ion-input>
                        </ion-col>
                      </ion-row>
                      <ion-row class="rowMargin">
                        <ion-col class="colMargin">
                          <ion-label>Intent message</ion-label>
                        </ion-col>
                        <ion-col>
                          <ion-input type="text" placeholder="Enter a message" v-model="button.intent_body" class="fullBordered input_custom_style sctn-input"></ion-input>
                        </ion-col>
                      </ion-row>
                      <ion-toolbar class="cust-padding">
                        <ion-buttons slot="end">
                          <ion-button :disabled="!buttonChanged" @click="updateButton" fill="solid" expand="block" > 
                            Save
                          </ion-button>
                        </ion-buttons>
                      </ion-toolbar>
                    </ion-grid>
                    <div class="embedCodeAreaContainer">
                      <p>Copy and paste the below code to the section of your website:</p>
                      <figure class="highlight">
                        <pre>{{ widgetScript }}</pre>
                        <a @click="copy(widgetScript)">Copy to Clipboard</a>
                      </figure>
                    </div>
                  </div>
                </ion-col>

                <!-- preview -->
                <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
                  <div class="imessage-preview">
                    <h3 class="title ion-no-margin apple-business-chat-banner-container">Preview</h3>
                    <div v-if="button.type !== 'big_button'" class="apple-business-chat-banner-container">
                      <span class="jumpr_imessage_roundButton">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" fill="#017aff" d="M25,8.94C14.38,8.94,5.77,15.6,5.77,23.83c0,5.36,3.67,10,9.15,12.67a5.94,5.94,0,0,1-3,4.1s3.22,1,5.75,0,3.17-1.87,4.54-1.85l2.82,0c10.62,0,19.23-6.67,19.23-14.9S35.62,8.94,25,8.94Z"></path></g></svg>
                      </span>
                    </div>
                    <div v-if="button.type === 'big_button'" class="apple-business-chat-banner-container">
                      <div class="bigButton" :style="{'background-color':button.bgcolor}">
                        <div class="lhs">
                          <h5 class="ctaText" :style="{'color':button.color}"> {{ button.title }}</h5>
                          <p class="contextText ion-no-margin" :style="{'color':button.color}"> {{ button.subtitle }} </p>
                        </div>
                        <div class="rhs">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" fill="#017aff" d="M25,8.94C14.38,8.94,5.77,15.6,5.77,23.83c0,5.36,3.67,10,9.15,12.67a5.94,5.94,0,0,1-3,4.1s3.22,1,5.75,0,3.17-1.87,4.54-1.85l2.82,0c10.62,0,19.23-6.67,19.23-14.9S35.62,8.94,25,8.94Z"></path></g></svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div v-else>
          <ion-grid class="gridMargin">
            <ion-row>
              <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding"> 
                <div class="imessage-container">
                  <h2 class="title ion-no-margin">iMessage</h2>
                  <div>
                    <h2 class="header">Connect with your customers on iMessage</h2>
                    <p class="sub-title ion-no-margin">Apple Business Chat is a powerful new way for your business to connect with customers directly from within Messages.</p>
                    <p class="sub-title">Using Business Chat, your customers can get answers to questions, resolve issues and complete transactions on their iPhone, iPad, Mac and Apple Watch. Customers can also find your business and start conversations from Maps, Search and Siri.</p>
                    <p class="sub-title">Click on the button below to connect your Business Chat account with Jumper</p>
                  </div>
                  <ion-toolbar class="toolbar-bttn">
                    <ion-buttons slot="end">
                      <ion-button expand="block" fill="solid" size="default" href="https://register.apple.com" target="_blank" >
                        Go to Apple Business Register
                      </ion-button>
                    </ion-buttons>
                  </ion-toolbar>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
                <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-landing/images/new-landing-imgs/iMessage/iMessage-header.svg" class="img-container"/>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, close } from 'ionicons/icons';

export default {
  components: { IonSelect, IonSelectOption },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      close,
      activeTab: 'account',
      message: undefined,
      ogMessage: undefined,
      button: {
        type: undefined,
        bgcolor: undefined,
        color: undefined,
        title: undefined,
        subtitle: undefined,
        intent_body: undefined,
        position: 'bottom_right',
        bcrwimessageid: undefined,
        active: false
      },
      ogButton: {
        type: undefined,
        bgcolor: undefined,
        color: undefined,
        title: undefined,
        subtitle: undefined,
        intent_body: undefined,
        position: 'bottom_right',
        bcrwimessageid: undefined,
        active: false
      }
    }
  },
  computed: {
    widgetScript() {
      if(this.ogButton.type === 'big_button') {
        return '<div id="apple-business-chat-banner-container" class="apple-business-chat-banner-container" data-apple-business-id="' + this.imessageSocialData.businessid + '" data-apple-business-body="' + this.button.intent_body + '" data-apple-banner-background-color="' + this.button.bgcolor + '" data-apple-banner-text-color="' + this.button.color + '" data-apple-icon-title="' + this.button.title + '" data-apple-banner-cta="' + this.button.title + '" data-apple-banner-context="' + this.button.subtitle + '" style="position: fixed; z-index: 1000000; -webkit-backface-visibility: hidden; backface-visibility: hidden; bottom: 20px; right: 20px; display: inline-block; line-height: 1;"> </div><script src="https://insights.jumper.ai/wp-content/jumper-resources/widget-cdn/js/apple-business-chat-button.js"></scrip' + 't>';
      } else if(this.ogButton.type === 'round_button'){
        return '<div id="apple-business-chat-banner-container" class="apple-business-chat-message-container" data-apple-business-id="' + this.imessageSocialData.businessid + '" data-apple-business-body="' + this.button.intent_body + '" data-apple-icon-title="' + this.button.title + '" style="position: fixed; z-index: 1000000; -webkit-backface-visibility: hidden; backface-visibility: hidden; bottom: 20px; right: 20px; display: inline-block; line-height: 1;"> </div><script src="https://insights.jumper.ai/wp-content/jumper-resources/widget-cdn/js/apple-business-chat-button.js"></scrip' + 't>';
      } else return '';
    },
    buttonChanged() {
      return JSON.stringify(this.button) !== JSON.stringify(this.ogButton);
    },
    messageChanged() {
      return this.message !== this.ogMessage;
    }
  },
  async ionViewWillEnter() {
    await this.init();
  },
  methods: {
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      try {
        this.loading = true;
        let a = this.fetchGreeting();
        let b = this.fetchButton();
        await Promise.all([a,b]);
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchGreeting() {
      try {
        this.loading = true;
        let fetchGreetingResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "imessage/get-greeting-message");
        if ((fetchGreetingResponse.status == 200 || fetchGreetingResponse.status == 201) && fetchGreetingResponse.data.success) {
          if(fetchGreetingResponse.data.message && fetchGreetingResponse.data.message.length) {
            this.ogMessage = fetchGreetingResponse.data.message[0];
            this.message = fetchGreetingResponse.data.message[0];
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
        else {
          if(fetchGreetingResponse.data.errorMessage && fetchGreetingResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchGreetingResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchButton() {
      try {
        this.loading = true;
        let fetchButtonResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "imessage/fetch-bcrw");
        if ((fetchButtonResponse.status == 200 || fetchButtonResponse.status == 201) && fetchButtonResponse.data.success) {
          for(var prop in this.ogButton) this.ogButton[prop] = fetchButtonResponse.data[prop];
          this.button = JSON.parse(JSON.stringify(this.ogButton));
          console.log(this.button);
        }
        else {
          if(fetchButtonResponse.data.errorMessage && fetchButtonResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchButtonResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateGreeting() {
      try {
        this.loading = true;
        let reqBody = { message: this.message }
        let updateGreetingResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "imessage/set-greeting-message", reqBody);
        if ((updateGreetingResponse.status == 200 || updateGreetingResponse.status == 201) && updateGreetingResponse.data.success) {
          await this.fetchGreeting();
        }
        else {
          if(updateGreetingResponse.data.errorMessage && updateGreetingResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: updateGreetingResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateButton() {
      try {
        this.loading = true;

        let updateButtonResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "imessage/update-bcrw", this.button);
        if ((updateButtonResponse.status == 200 || updateButtonResponse.status == 201) && updateButtonResponse.data.success) {
          await this.fetchButton();
        }
        else {
          if(updateButtonResponse.data.errorMessage && updateButtonResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: updateButtonResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    }
  }
}
</script>

<style scoped>
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
}

.infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}

.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}

.infoBanner.success .panel .panel-body{
  border-left: 3px #099e6e solid;
}

.infoBanner.danger .panel.panel-default{
  background-color: #ffd4d4;
}

.infoBanner.danger .panel .panel-body{
  border-left: 3px #ff0000 solid;
}

.panel-body {
  padding: 20px;
}
ion-toolbar.toolbar-bttn {
  --background: inherit
}
.img-container {
  max-width: 100%;
  height: auto;
  display: block;
}
.sctn-input {
  color: #888;
}
ion-button {
  margin-left: 5px;
  margin-bottom: 10px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.text-area {
  width: 720px;
  height: auto;
}
.prdct-section {
  max-width: 100%;
}
.imessage-customization {
  border-top: 1px solid #eee;
  padding-top: 25px;
  margin-top: 25px;
}

.cust-padding{
  padding: 15px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-black);
  font-weight: 500;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}

figure {
  margin: 0;
}

figure.highlight {
  margin-top: 5px;
}

figure.highlight pre {
  white-space: normal;
  text-align: left;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.imessage-container {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}
.gridPadding {
  padding: 20px;
}
.colorPickerWrapper {
  min-height: 45px;
  min-width: 45px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  border: 1px #E3E3E3 solid;
  cursor: pointer;
}
.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}
.embedCodeAreaContainer {
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.jumpr_imessage_roundButton {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
}
.apple-business-chat-banner-container {
  margin-top: 20px;
}
.apple-business-chat-banner-container .bigButton {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 10%);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  max-width: 100%;
  width: 300px;
  padding: 12px;
}
.apple-business-chat-banner-container .bigButton .lhs {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 58px);
}
.apple-business-chat-banner-container .bigButton .rhs {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #efeff4;
}
.apple-business-chat-banner-container .bigButton .rhs svg {
  height: 40px;
  margin-top: 5px;
}
ion-segment.segment {
  --background: var(--ion-color-light-grey);
}
.imessage-preview{
  margin: 20px;
}
.jumpr_imessage_roundButton svg {
  height: 40px;
  margin-top: 5px;
}
.ctaText {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
}
.contextText {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  padding: 0px;
  margin: 0px;
}
@media only screen and (max-width: 760px) {
  .imessage-container {
    margin: 10px;
    max-width: 100%;
  }
  .text-area {
    max-width: 100%;
    height: auto;
  }
}
</style>