const state = () => ({
  adlingo: [],
  facebook: [],
  imessage: [],
  instagram: [],
  line: {pages: []},
  pinterest: [],
  twilio: [],
  twitter: [],
  viber: {pages: []},
  whatsapp: {},
  youtube: [],
  sms: [],
  googlebusinessmsg: [],
  telegram:[],
});

const getters = {
  getFetchSocialFullData(state) {
    return state;
  },
  connectedChannels(state, getters) {
    let connectedChannels = [];
    if (getters.isAdLingoConnected) {
      connectedChannels.push('facebook');
    }
    if (getters.isMessengerConnected) {
      connectedChannels.push('facebook-messenger');
    }
    if (getters.isInstagramConnected) {
      connectedChannels.push('instagram');
    }
    if (getters.isTwitterConnected) {
      connectedChannels.push('twitter');
    }
    if (getters.isYoutubeConnected) {
      connectedChannels.push('youtube');
    }
    if (getters.isPinterestConnected) {
      connectedChannels.push('pinterest');
    }
    if (getters.isIMessageConnected) {
      connectedChannels.push('imessage');
    }
    if (getters.isAdLingoConnected) {
      connectedChannels.push('adlingo');
    }
    if (getters.isLineConnected) {
      connectedChannels.push('line');
    }
    if (getters.isViberConnected) {
      connectedChannels.push('viber');
    }
    if (getters.isTelegramConnected) {
      connectedChannels.push('telegram');
    }
    if (getters.isWhatsAppConnected) {
      connectedChannels.push('whatsapp');
    }
    if (getters.isSMSConnected) {
      connectedChannels.push('sms');
    }
    if (getters.isGBusinessMessagesConnected) {
      connectedChannels.push('googlebusinessmsg');
    }
    return connectedChannels;
  },
  isFacebookConnected(state){
    if(state.facebook.length === 0) return false
    return true
  },
  getFacebook(state) {
    return state.facebook.length ? state.facebook[0] : []
  },
  isMessengerConnected(state){
    var returnBool = false;
    if(state.facebook.length > 0){
      if(state.facebook[0].pages){
        if(state.facebook[0].pages.length > 0){
          returnBool = true;
        }
      }
    }
    return returnBool;
  },
  isInstagramConnected(state){
    var returnBool = false;
    if(state.instagram.length > 0){
      if(state.instagram[0].id != '' && state.instagram[0].id != 'None' && state.instagram[0].id != null){
        returnBool = true;
      }
    }
    return returnBool;
  },
  getInstagram(state) {
    return state.instagram.length ? state.instagram : null
  },
  isTwitterConnected(state){
    var returnBool = false;
    if(state.twitter.length > 0){
      if(state.twitter[0].twitterName != '' && state.twitter[0].twitterName != 'None' && state.twitter[0].twitterName != null){
        returnBool = true;
      }
    }
    return returnBool;
  },
  getTwitter(state) {
    return state.twitter.length ? state.twitter[0] : null
  },
  isYoutubeConnected(state){
    var returnBool = false;
    if(state.youtube.length > 0){
      if(state.youtube[0].youtube_channel != '' && state.youtube[0].youtube_channel != 'None' && state.youtube[0].youtube_channel != null){
        returnBool = true;
      }
    }
    return returnBool;
  },
  getYoutube(state) {
    return state.youtube.length ? state.youtube[0] : null
  },
  isPinterestConnected(state){
    if(state.pinterest.length === 0) return false
    return true
  },
  getPinterest(state) {
    return state.pinterest.length ? state.pinterest[0] : null
  },
  isIMessageConnected(state){
    if(state.imessage.length === 0) return false
    return true
  },
  getIMessage(state) {
    return state.imessage.length ? state.imessage[0] : null
  },
  isAdLingoConnected(state){
    if(state.adlingo.length === 0) return false
    return true
  },
  getAdLingo(state) {
    return state.adlingo.length ? state.adlingo[0] : null
  },
  isLineConnected(state){
    var returnBool = false;
    if(state.line.pages){
      if(state.line.pages.length > 0){
        if(state.line.pages[0].active == true){
          returnBool = true;
        }
      }
    }
    return returnBool;
  },
  getLine(state) {
    var returnData = null;
    if(state.line.pages){
      if(state.line.pages.length > 0){
        if(state.line.pages[0].active == true){
          returnData = state.line.pages[0];
        }
      }
    }
    return returnData;
  },
  isViberConnected(state){
    var returnBool = false;
    if(state.viber.pages){
      if(state.viber.pages.length > 0){
        returnBool = true;
      }
    }
    return returnBool;
  },
  getViber(state) {
    var returnData = null;
    if(state.viber.pages){
      if(state.viber.pages.length > 0){
        returnData = state.viber.pages[0];
      }
    }
    return returnData;
  },
  isTelegramConnected(state){
    if(state.telegram.length === 0) return false
    return true
  },
  getTelegram(state){
    return state.telegram.length ? state.telegram[0] : null
  },
  isWhatsAppConnected(state) {
    if(Object.keys(state.whatsapp).length === 0) return false;
    if(state.whatsapp.id === '' && state.whatsapp.id == 'None' && state.whatsapp.id == null) return false;
    return true;
  },
  getWhatsApp(state) {
    return state.whatsapp;
  },
  isSMSConnected(state) {
    if(state.sms.length === 0) return false
    return true
  },
  getSMS(state) {
    return state.sms.length ? state.sms[0] : null;
  },
  isGBusinessMessagesConnected(state) {
    if(state.googlebusinessmsg.length === 0) return false
    return true
  },
  getGBusiness(state) {
    return state.googlebusinessmsg.length ? state.googlebusinessmsg[0] : null;
  },
};

const actions = {
  saveSocialData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('saveSocialData', payload)
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load social data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  }
}

const mutations = {
  saveSocialData(state, data){
    for(var prop in data) {
      state[prop] = data[prop];
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};