<template>
<master-layout pageTitle="Manage Team Members">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    ></ion-loading>
    <div v-else>
      <ion-toolbar class="chat-toolbar">
        <ion-buttons slot="start">
          <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{ (dummyGroup.id ? 'Edit' : 'Add') + ' Group' }}</h2>
        </ion-buttons>
        <ion-buttons slot="end" class="paddingBox">
          <ion-button @click="updateGroup" class="bttn-bgnd">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <ion-card class="custom_btm_margin card_container">
              <ion-card-header>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Group Name</ion-card-title>
                    <ion-input type="text" placeholder="Enter Name" v-model="dummyGroup.name" class="fullBordered input_custom_style"></ion-input>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Report to</ion-card-title>
                    <ion-select class="cust_adjsmt cust_style_select" v-model="dummyGroup.report_to" ok-text="Okay" cancel-text="Dismiss" placeholder="Select role">
                      <ion-select-option value="undefined" disabled>Select Role</ion-select-option>
                      <ion-select-option :disabled="thisRole.id === dummyGroup.id" v-for="thisRole in allRoles" :key="thisRole.id" :value="thisRole.id">{{ thisRole.name }}</ion-select-option>
                    </ion-select>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Conversation Routing Method</ion-card-title>
                    <ion-select class="cust_adjsmt cust_style_select" v-model="dummyGroup.routing" ok-text="Okay" cancel-text="Dismiss">
                      <ion-select-option value="undefined" disabled>Select Conversation Routing Method</ion-select-option>
                      <ion-select-option value="automatic">Automatic Routing</ion-select-option>
                      <ion-select-option value="manual">Manual Routing</ion-select-option>
                    </ion-select>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Show Reporting Group Chats</ion-card-title>
                    <ion-select class="cust_adjsmt cust_style_select" v-model="dummyGroup.heirarchy" ok-text="Okay" cancel-text="Dismiss">
                      <ion-select-option value="undefined" disabled>Select Yes/No</ion-select-option>
                      <ion-select-option :value="true">Yes</ion-select-option>
                      <ion-select-option :value="false">No</ion-select-option>
                    </ion-select>
                </div>
              </ion-card-header>
            </ion-card>
          </ion-col>
          <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
            <ion-card v-if="allLocations && allLocations.length">
              <ion-card-header>
                <div class="prdct-section">
                  <ion-card-title class="team-title">Locations</ion-card-title>
                  <ion-col class="tb_pad_zero" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
                    <div class="prdct-section">
                      <ion-item v-for="thisLocation in allLocations" :key="thisLocation.outletid" class="cust_mrg_lft_cont pos_adjsmt_1 no_bdr">
                        <ion-checkbox v-model="thisLocation.selected"></ion-checkbox>
                        <ion-label class="cust_min_width">{{ thisLocation.name + ", " + thisLocation.addressline1 + ", " + thisLocation.city + ", " + thisLocation.state + ", " + thisLocation.country + ", " + thisLocation.zipcode }}</ion-label>
                      </ion-item>
                    </div>
                  </ion-col>
                </div>
              </ion-card-header>
            </ion-card>
          </ion-col>
          <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
             <ion-card class="custom_btm_margin card_container">
              <ion-card-header>
                <div class="prdct-section">
                  <ion-card-title class="team-title">Permissions</ion-card-title>
                  <div style="padding-left: 16px;">Select permissions you want to give to this member: <a @click="selectAll(true)" style="text-decoration: none;">Check all</a> | <a @click="selectAll(false)" style="text-decoration: none;">Un-check all</a></div>
                  <ion-card-content>
                    <div v-for="(value, propertyName, index) in defaultAdminUserRolesPermission.defaultAdminUserRolesPermission" :key="index">
                      <ion-item lines="none" v-if="typeof value !== 'object'">
                        <ion-label>{{ defaultAdminUserRolesPermission['dictionary'][propertyName]['label'] }}</ion-label>
                        <ion-checkbox slot="end" v-model="defaultAdminUserRolesPermission['dictionary'][propertyName]['selected']"></ion-checkbox>
                      </ion-item>
                      <div v-else> 
                        <ion-label class="sub-text ion-no-padding">{{ defaultAdminUserRolesPermission['dictionary'][propertyName]['label'] }}</ion-label>
                        <div style="display:inline-block"><a @click="selectAll(true, propertyName)" style="text-decoration: none;">Check all</a> | <a @click="selectAll(false, propertyName)" style="text-decoration: none;">Un-check all</a></div>
                        <ion-list class="grid_col_auto_v1">
                          <ion-item lines="none" v-for="(value2, propertyName2, index2) in value" :key="index2">
                            <ion-checkbox slot="start" v-model="defaultAdminUserRolesPermission['dictionary'][propertyName][propertyName2]['selected']" style="margin-right: 12px"></ion-checkbox>
                            <ion-label>{{ defaultAdminUserRolesPermission['dictionary'][propertyName][propertyName2]['label'] }}</ion-label>
                          </ion-item>
                        </ion-list>
                      </div>
                    </div>
                  </ion-card-content>
                </div>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>
</master-layout>
</template>

<script>
import { chevronDownCircleOutline, arrowBackOutline } from 'ionicons/icons';
import { IonCheckbox, IonSelect, IonSelectOption } from "@ionic/vue";
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  components: {
    IonCheckbox,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      chevronDownCircleOutline,
      arrowBackOutline,
      loading: false,
      allRoles: [],
      allLocations: [],
      dummyGroup: {
        admin: false,
        default: false,
        dynamics: [],
        heirarchy: false,
        id: undefined,
        name: undefined,
        permissions: {},
        report_to: [],
        routing: "automatic"
      }
    }
  },
  ionViewWillEnter() {
    this.init();
  },
  computed: {
    selectedLocations() {
      return this.allLocations.filter(e => e.selected);
    }
  },
  methods: {
    selectAll(n, propName) {
      if(propName) {
        for(let propertyName2 in this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission[propName]) {
          this.defaultAdminUserRolesPermission['dictionary'][propName][propertyName2]['selected'] = n;
        }
      } else {
        for(let propertyName in this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission) {
          if(typeof this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission[propertyName] !== 'object') {
            this.defaultAdminUserRolesPermission['dictionary'][propertyName]['selected'] = n;
          } else {
            for(let propertyName2 in this.defaultAdminUserRolesPermission.defaultAdminUserRolesPermission[propertyName]) {
              this.defaultAdminUserRolesPermission['dictionary'][propertyName][propertyName2]['selected'] = n;
            }
          }
        }
      }
    },
    async init() {
      try {
        this.loading = true;
        let businessRolesResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");
        let fetchOutletsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-outlets");

        let allPromises = await Promise.all([businessRolesResponse, fetchOutletsResponse]);
        businessRolesResponse = allPromises[0];
        fetchOutletsResponse = allPromises[1];
        
        if ((businessRolesResponse.status == 200 || businessRolesResponse.status == 201) && businessRolesResponse.data.success) {
          this.allRoles = businessRolesResponse.data.business_roles;
          console.log(this.allRoles);
        } else {
          if(businessRolesResponse.data && !businessRolesResponse.data.success && businessRolesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: businessRolesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", businessRolesResponse);
          }
        }
        if ((fetchOutletsResponse.status == 200 || fetchOutletsResponse.status == 201) && fetchOutletsResponse.data.success) {
          this.allLocations = fetchOutletsResponse.data.outlets;
        } else {
          if(fetchOutletsResponse.data && !fetchOutletsResponse.data.success && fetchOutletsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchOutletsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", fetchOutletsResponse);
          }
        }

        if(this.$route.query?.groupid) {
          let businessRoleShowResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "business-role-show", { businessroleid: this.$route.query.groupid });
          if ((businessRoleShowResponse.status == 200 || businessRoleShowResponse.status == 201) && businessRoleShowResponse.data.success) {
            this.dummyGroup = businessRoleShowResponse.data;
            delete this.dummyGroup.success;
            delete this.dummyGroup.errorMessage;
            this.dummyGroup.report_to = this.dummyGroup.report_to[0].id;
            for(let thisPermission in this.defaultAdminUserRolesPermission['dictionary']){
              if(typeof this.defaultAdminUserRolesPermission['dictionary'][thisPermission]['selected'] === 'boolean') this.defaultAdminUserRolesPermission['dictionary'][thisPermission]['selected'] = this.dummyGroup.permissions[thisPermission];
              else for(let thisPermission2 in this.defaultAdminUserRolesPermission['dictionary'][thisPermission]) if(thisPermission2 !== 'label') this.defaultAdminUserRolesPermission['dictionary'][thisPermission][thisPermission2]['selected'] = this.dummyGroup.permissions[thisPermission][thisPermission2];
            }
            this.allLocations.forEach(thisLocation => this.dummyGroup.dynamics.findIndex(e => e.outletid === thisLocation.urlid) > -1 ? thisLocation.selected = true : thisLocation.selected = false)
            console.log(this.dummyGroup);
          } else {
            if(businessRoleShowResponse.data && !businessRoleShowResponse.data.success && businessRoleShowResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: businessRoleShowResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
              //console.log("Response:", businessRoleShowResponse);
            }
          }
        } else this.allLocations.forEach(thisLocation => thisLocation.selected = false);

      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async updateGroup() {
      try {
        this.loading = true;
        let permissions = {};
        let noPermissionSelected = true;
        for(let thisPermission in this.defaultAdminUserRolesPermission['dictionary']){
          if(typeof this.defaultAdminUserRolesPermission['dictionary'][thisPermission]['selected'] === 'boolean') {
            permissions[thisPermission] = this.defaultAdminUserRolesPermission['dictionary'][thisPermission]['selected'];
            if(permissions[thisPermission]) noPermissionSelected = false;
          } else {
            for(let thisPermission2 in this.defaultAdminUserRolesPermission['dictionary'][thisPermission]) {
              if(thisPermission2 !== 'label') {
                if(permissions[thisPermission]) {
                  permissions[thisPermission][thisPermission2] = this.defaultAdminUserRolesPermission['dictionary'][thisPermission][thisPermission2]['selected'];
                } else { 
                  permissions[thisPermission] = {};
                  permissions[thisPermission][thisPermission2] = this.defaultAdminUserRolesPermission['dictionary'][thisPermission][thisPermission2]['selected']; 
                }
                if(permissions[thisPermission][thisPermission2]) noPermissionSelected = false;
              }
            }
          }
        }
        let reqBody = {
          name: this.dummyGroup.name,
          businessroleid: this.dummyGroup.id,
          report_to: this.dummyGroup.report_to,
          routing: this.dummyGroup.routing,
          heirarchy: this.dummyGroup.heirarchy,
          permissions: JSON.stringify(permissions),
          dynamics: this.selectedLocations && this.selectedLocations.length ? (this.selectedLocations.map(e => e.urlid).join(',')) : undefined
        }
        if(!reqBody.name || reqBody.name.length === 0) {
          this.updateToast({
            open: true,
            message: "Please add a valid group name",
            type: "warning"
          })
          return;
        }
        if(!reqBody.report_to || reqBody.report_to.length === 0) {
          this.updateToast({
            open: true,
            message: "Please select report to",
            type: "warning"
          })
          return;
        }
        if(noPermissionSelected) {
          this.updateToast({
            open: true,
            message: "Please select atleast one permission",
            type: "warning"
          })
          return;
        }
        let reqUrl = undefined;
        if(reqBody.businessroleid) reqUrl = "update-business-roles";
        else reqUrl = "add-business-role";
        let groupSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + reqUrl, reqBody);
        if ((groupSaveResponse.status == 200 || groupSaveResponse.status == 201) && groupSaveResponse.data.success) {
          if(reqBody.businessroleid && groupSaveResponse.data.updated) {
            this.updateToast({
              open: true,
              message: "Group updated successfully!",
              type: "success"
            });
          } else {
            this.updateToast({
              open: true,
              message: "Group added successfully!",
              type: "success"
            });
          }
          this.$router.push({ path: '/settings/team' });
        }
        else {
          if(groupSaveResponse.data.errorMessage && groupSaveResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: groupSaveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.team-title {
  padding: 0px 0px 10px 16px;
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.sub-text {
  font-size: 16px;
  color: var(--ion-color-grey);
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  text-decoration: underline;
}
</style>