<template>
  <div class="ion-padding static-button-conf-wrapper">
    <ion-item class="ion-no-padding">
      <ion-label position="stacked">Button title</ion-label>
      <ion-input :value="title" :readonly="true"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding">
      <ion-label position="stacked">Button action <span class="sub-label">({{ subLabel }})</span>
      </ion-label>
      <ion-input :value="valueToShow" :readonly="true"></ion-input>
    </ion-item>

  </div>
</template>

<script>

export default {
  components: { },
  data() {
    return {
      urlParam: "",
      updatedButtonObject: {}
    }
  },
  props: {
    clickedButton: {
      type: Object,
      default: () => { return {}; }
    }
  },
  mounted() {
  },
  computed: {
    urlLabelToShow() {
      return this.clickedButton?.url?.replace("{{1}}", "") || "";
    },
    isDynamicUrl() {
      return this.clickedButton?.url?.includes("{{1}}");
    },
    title() {
      return this.clickedButton.text;
    },
    valueToShow() {
      if (this.clickedButton.sub_type == "phone_number") {
        return this.clickedButton.phone_number;
      }
      if (this.clickedButton.sub_type == "quick_reply") {
        return this.clickedButton.text;
      }
      return "";
    },
    subLabel() {
      if (this.clickedButton.sub_type == "phone_number") {
        return 'Phone number';
      }
      if (this.clickedButton.sub_type == "quick_reply") {
        return 'Custom';
      }
      return "";
    }
  },
  watch: {
  },
  methods: {
    buttonObjectHandler() {
      this.updatedButtonObject = { ...this.clickedButton };
      this.updatedButtonObject.parameters = [
        {
          "type": "text",
          "text": this.urlParam
        }
      ]
    }
  }
}
</script>

<style scoped>
.sub-label {
  color: var(--ion-color-light-shade);
}
</style>