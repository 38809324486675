<template>
  <ion-select :interface-options="timeZoneOptions" class="cust_adjsmt" :disabled="disabled" :value="value" @input="$emit('input', $event.target.value)" ok-text="Okay" cancel-text="Dismiss">
    <ion-select-option value="" disabled>-- Select time zone --</ion-select-option>
    <ion-select-option value="Pacific/Midway">Midway (UTC -11:00)</ion-select-option>
    <ion-select-option value="Pacific/Niue">Niue (UTC -11:00)</ion-select-option>
    <ion-select-option value="Pacific/Pago_Pago">Pago Pago (UTC -11:00)</ion-select-option>
    <ion-select-option value="America/Adak">Adak (UTC -10:00)</ion-select-option>
    <ion-select-option value="Pacific/Honolulu">Honolulu (UTC -10:00)</ion-select-option>
    <ion-select-option value="Pacific/Rarotonga">Rarotonga (UTC -10:00)</ion-select-option>
    <ion-select-option value="Pacific/Tahiti">Tahiti (UTC -10:00)</ion-select-option>
    <ion-select-option value="Pacific/Marquesas">Marquesas (UTC -09:30)</ion-select-option>
    <ion-select-option value="America/Anchorage">Anchorage (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Juneau">Juneau (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Metlakatla">Metlakatla (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Nome">Nome (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Sitka">Sitka (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Yakutat">Yakutat (UTC -09:00)</ion-select-option>
    <ion-select-option value="Pacific/Gambier">Gambier (UTC -09:00)</ion-select-option>
    <ion-select-option value="America/Dawson">Dawson (UTC -08:00)</ion-select-option>
    <ion-select-option value="America/Los_Angeles">Los Angeles (UTC -08:00)</ion-select-option>
    <ion-select-option value="America/Tijuana">Tijuana (UTC -08:00)</ion-select-option>
    <ion-select-option value="America/Vancouver">Vancouver (UTC -08:00)</ion-select-option>
    <ion-select-option value="America/Whitehorse">Whitehorse (UTC -08:00)</ion-select-option>
    <ion-select-option value="Pacific/Pitcairn">Pitcairn (UTC -08:00)</ion-select-option>
    <ion-select-option value="America/Boise">Boise (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Cambridge_Bay">Cambridge Bay (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Chihuahua">Chihuahua (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Creston">Creston (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Dawson_Creek">Dawson Creek (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Denver">Denver (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Edmonton">Edmonton (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Fort_Nelson">Fort Nelson (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Hermosillo">Hermosillo (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Inuvik">Inuvik (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Mazatlan">Mazatlan (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Ojinaga">Ojinaga (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Phoenix">Phoenix (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Yellowknife">Yellowknife (UTC -07:00)</ion-select-option>
    <ion-select-option value="America/Bahia_Banderas">Bahia Banderas (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Belize">Belize (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Chicago">Chicago (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Costa_Rica">Costa Rica (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/El_Salvador">El Salvador (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Guatemala">Guatemala (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Managua">Managua (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Matamoros">Matamoros (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Menominee">Menominee (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Merida">Merida (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Mexico_City">Mexico City (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Monterrey">Monterrey (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Rainy_River">Rainy River (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Rankin_Inlet">Rankin Inlet (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Regina">Regina (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Resolute">Resolute (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Swift_Current">Swift Current (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Tegucigalpa">Tegucigalpa (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Winnipeg">Winnipeg (UTC -06:00)</ion-select-option>
    <ion-select-option value="Indiana/Knox">Knox (UTC -06:00)</ion-select-option>
    <ion-select-option value="Indiana/Tell_City">Tell City (UTC -06:00)</ion-select-option>
    <ion-select-option value="North_Dakota/Beulah">Beulah (UTC -06:00)</ion-select-option>
    <ion-select-option value="North_Dakota/Center">Center (UTC -06:00)</ion-select-option>
    <ion-select-option value="North_Dakota/New_Salem">New Salem (UTC -06:00)</ion-select-option>
    <ion-select-option value="Pacific/Galapagos">Galapagos (UTC -06:00)</ion-select-option>
    <ion-select-option value="America/Atikokan">Atikokan (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Bogota">Bogota (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Cancun">Cancun (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Cayman">Cayman (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Detroit">Detroit (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Eirunepe">Eirunepe (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Guayaquil">Guayaquil (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Havana">Havana (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Iqaluit">Iqaluit (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Jamaica">Jamaica (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Lima">Lima (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Nassau">Nassau (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/New_York">New York (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Nipigon">Nipigon (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Panama">Panama (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Pangnirtung">Pangnirtung (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Rio_Branco">Rio Branco (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Thunder_Bay">Thunder Bay (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Toronto">Toronto (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Indianapolis">Indianapolis (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Marengo">Marengo (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Petersburg">Petersburg (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Vevay">Vevay (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Vincennes">Vincennes (UTC -05:00)</ion-select-option>
    <ion-select-option value="Indiana/Winamac">Winamac (UTC -05:00)</ion-select-option>
    <ion-select-option value="Kentucky/Louisville">Louisville (UTC -05:00)</ion-select-option>
    <ion-select-option value="Kentucky/Monticello">Monticello (UTC -05:00)</ion-select-option>
    <ion-select-option value="Pacific/Easter">Easter (UTC -05:00)</ion-select-option>
    <ion-select-option value="au-Prince">Prince (UTC -05:00)</ion-select-option>
    <ion-select-option value="America/Anguilla">Anguilla (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Antigua">Antigua (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Aruba">Aruba (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Barbados">Barbados (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Boa_Vista">Boa Vista (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Caracas">Caracas (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Curacao">Curacao (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Dominica">Dominica (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Glace_Bay">Glace Bay (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Goose_Bay">Goose Bay (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Grand_Turk">Grand Turk (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Grenada">Grenada (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Guadeloupe">Guadeloupe (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Guyana">Guyana (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Halifax">Halifax (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Kralendijk">Kralendijk (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/La_Paz">La Paz (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Lower_Princes">Lower Princes (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Manaus">Manaus (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Marigot">Marigot (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Martinique">Martinique (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Moncton">Moncton (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Montserrat">Montserrat (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Port_of_Spain">Port of Spain (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Porto_Velho">Porto Velho (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Puerto_Rico">Puerto Rico (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Santo_Domingo">Santo Domingo (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Barthelemy">St Barthelemy (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Kitts">St Kitts (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Lucia">St Lucia (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Thomas">St Thomas (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Vincent">St Vincent (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Thule">Thule (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/Tortola">Tortola (UTC -04:00)</ion-select-option>
    <ion-select-option value="Atlantic/Bermuda">Bermuda (UTC -04:00)</ion-select-option>
    <ion-select-option value="Blanc-Sablon">Sablon (UTC -04:00)</ion-select-option>
    <ion-select-option value="America/St_Johns">St Johns (UTC -03:30)</ion-select-option>
    <ion-select-option value="America/Araguaina">Araguaina (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Asuncion">Asuncion (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Bahia">Bahia (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Belem">Belem (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Campo_Grande">Campo Grande (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Cayenne">Cayenne (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Cuiaba">Cuiaba (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Fortaleza">Fortaleza (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Godthab">Godthab (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Maceio">Maceio (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Miquelon">Miquelon (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Montevideo">Montevideo (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Paramaribo">Paramaribo (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Punta_Arenas">Punta Arenas (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Recife">Recife (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Santarem">Santarem (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Santiago">Santiago (UTC -03:00)</ion-select-option>
    <ion-select-option value="Antarctica/Palmer">Palmer (UTC -03:00)</ion-select-option>
    <ion-select-option value="Antarctica/Rothera">Rothera (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Buenos_Aires">Buenos Aires (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Catamarca">Catamarca (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Cordoba">Cordoba (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Jujuy">Jujuy (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/La_Rioja">La Rioja (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Mendoza">Mendoza (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Rio_Gallegos">Rio Gallegos (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Salta">Salta (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/San_Juan">San Juan (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/San_Luis">San Luis (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Tucuman">Tucuman (UTC -03:00)</ion-select-option>
    <ion-select-option value="Argentina/Ushuaia">Ushuaia (UTC -03:00)</ion-select-option>
    <ion-select-option value="Atlantic/Stanley">Stanley (UTC -03:00)</ion-select-option>
    <ion-select-option value="America/Noronha">Noronha (UTC -02:00)</ion-select-option>
    <ion-select-option value="America/Sao_Paulo">Sao Paulo (UTC -02:00)</ion-select-option>
    <ion-select-option value="Atlantic/South_Georgia">South Georgia (UTC -02:00)</ion-select-option>
    <ion-select-option value="America/Scoresbysund">Scoresbysund (UTC -01:00)</ion-select-option>
    <ion-select-option value="Atlantic/Azores">Azores (UTC -01:00)</ion-select-option>
    <ion-select-option value="Atlantic/Cape_Verde">Cape Verde (UTC -01:00)</ion-select-option>
    <ion-select-option value="Africa/Abidjan">Abidjan (UTC)</ion-select-option>
    <ion-select-option value="Africa/Accra">Accra (UTC)</ion-select-option>
    <ion-select-option value="Africa/Bamako">Bamako (UTC)</ion-select-option>
    <ion-select-option value="Africa/Banjul">Banjul (UTC)</ion-select-option>
    <ion-select-option value="Africa/Bissau">Bissau (UTC)</ion-select-option>
    <ion-select-option value="Africa/Casablanca">Casablanca (UTC)</ion-select-option>
    <ion-select-option value="Africa/Conakry">Conakry (UTC)</ion-select-option>
    <ion-select-option value="Africa/Dakar">Dakar (UTC)</ion-select-option>
    <ion-select-option value="Africa/El_Aaiun">El Aaiun (UTC)</ion-select-option>
    <ion-select-option value="Africa/Freetown">Freetown (UTC)</ion-select-option>
    <ion-select-option value="Africa/Lome">Lome (UTC)</ion-select-option>
    <ion-select-option value="Africa/Monrovia">Monrovia (UTC)</ion-select-option>
    <ion-select-option value="Africa/Nouakchott">Nouakchott (UTC)</ion-select-option>
    <ion-select-option value="Africa/Ouagadougou">Ouagadougou (UTC)</ion-select-option>
    <ion-select-option value="Africa/Sao_Tome">Sao Tome (UTC)</ion-select-option>
    <ion-select-option value="America/Danmarkshavn">Danmarkshavn (UTC)</ion-select-option>
    <ion-select-option value="Antarctica/Troll">Troll (UTC)</ion-select-option>
    <ion-select-option value="Atlantic/Canary">Canary (UTC)</ion-select-option>
    <ion-select-option value="Atlantic/Faroe">Faroe (UTC)</ion-select-option>
    <ion-select-option value="Atlantic/Madeira">Madeira (UTC)</ion-select-option>
    <ion-select-option value="Atlantic/Reykjavik">Reykjavik (UTC)</ion-select-option>
    <ion-select-option value="Atlantic/St_Helena">St Helena (UTC)</ion-select-option>
    <ion-select-option value="Europe/Dublin">Dublin (UTC)</ion-select-option>
    <ion-select-option value="Europe/Guernsey">Guernsey (UTC)</ion-select-option>
    <ion-select-option value="Europe/Isle_of_Man">Isle of Man (UTC)</ion-select-option>
    <ion-select-option value="Europe/Jersey">Jersey (UTC)</ion-select-option>
    <ion-select-option value="Europe/Lisbon">Lisbon (UTC)</ion-select-option>
    <ion-select-option value="Europe/London">London (UTC)</ion-select-option>
    <ion-select-option value="Africa/Algiers">Algiers (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Bangui">Bangui (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Brazzaville">Brazzaville (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Ceuta">Ceuta (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Douala">Douala (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Kinshasa">Kinshasa (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Lagos">Lagos (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Libreville">Libreville (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Luanda">Luanda (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Malabo">Malabo (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Ndjamena">Ndjamena (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Niamey">Niamey (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Tunis">Tunis (UTC +01:00)</ion-select-option>
    <ion-select-option value="Arctic/Longyearbyen">Longyearbyen (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Amsterdam">Amsterdam (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Andorra">Andorra (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Belgrade">Belgrade (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Berlin">Berlin (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Bratislava">Bratislava (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Brussels">Brussels (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Budapest">Budapest (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Busingen">Busingen (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Copenhagen">Copenhagen (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Gibraltar">Gibraltar (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Ljubljana">Ljubljana (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Luxembourg">Luxembourg (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Madrid">Madrid (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Malta">Malta (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Monaco">Monaco (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Oslo">Oslo (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Paris">Paris (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Podgorica">Podgorica (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Prague">Prague (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Rome">Rome (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/San_Marino">San Marino (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Sarajevo">Sarajevo (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Skopje">Skopje (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Stockholm">Stockholm (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Tirane">Tirane (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Vaduz">Vaduz (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Vatican">Vatican (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Vienna">Vienna (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Warsaw">Warsaw (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Zagreb">Zagreb (UTC +01:00)</ion-select-option>
    <ion-select-option value="Europe/Zurich">Zurich (UTC +01:00)</ion-select-option>
    <ion-select-option value="Porto-Novo">Novo (UTC +01:00)</ion-select-option>
    <ion-select-option value="Africa/Blantyre">Blantyre (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Bujumbura">Bujumbura (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Cairo">Cairo (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Gaborone">Gaborone (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Harare">Harare (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Johannesburg">Johannesburg (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Kigali">Kigali (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Lubumbashi">Lubumbashi (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Lusaka">Lusaka (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Maputo">Maputo (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Maseru">Maseru (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Mbabane">Mbabane (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Tripoli">Tripoli (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Windhoek">Windhoek (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Amman">Amman (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Beirut">Beirut (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Damascus">Damascus (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Gaza">Gaza (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Hebron">Hebron (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Jerusalem">Jerusalem (UTC +02:00)</ion-select-option>
    <ion-select-option value="Asia/Nicosia">Nicosia (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Athens">Athens (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Bucharest">Bucharest (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Chisinau">Chisinau (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Helsinki">Helsinki (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Kaliningrad">Kaliningrad (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Kiev">Kiev (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Mariehamn">Mariehamn (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Riga">Riga (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Sofia">Sofia (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Tallinn">Tallinn (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Uzhgorod">Uzhgorod (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Vilnius">Vilnius (UTC +02:00)</ion-select-option>
    <ion-select-option value="Europe/Zaporozhye">Zaporozhye (UTC +02:00)</ion-select-option>
    <ion-select-option value="Africa/Addis_Ababa">Addis Ababa (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Asmara">Asmara (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Dar_es_Salaam">Dar es Salaam (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Djibouti">Djibouti (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Juba">Juba (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Kampala">Kampala (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Khartoum">Khartoum (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Mogadishu">Mogadishu (UTC +03:00)</ion-select-option>
    <ion-select-option value="Africa/Nairobi">Nairobi (UTC +03:00)</ion-select-option>
    <ion-select-option value="Antarctica/Syowa">Syowa (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Aden">Aden (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Baghdad">Baghdad (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Famagusta">Famagusta (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Kuwait">Kuwait (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Riyadh">Riyadh (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Istanbul">Istanbul (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Kirov">Kirov (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Minsk">Minsk (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Moscow">Moscow (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Simferopol">Simferopol (UTC +03:00)</ion-select-option>
    <ion-select-option value="Europe/Volgograd">Volgograd (UTC +03:00)</ion-select-option>
    <ion-select-option value="Indian/Antananarivo">Antananarivo (UTC +03:00)</ion-select-option>
    <ion-select-option value="Indian/Comoro">Comoro (UTC +03:00)</ion-select-option>
    <ion-select-option value="Indian/Mayotte">Mayotte (UTC +03:00)</ion-select-option>
    <ion-select-option value="Asia/Tehran">Tehran (UTC +03:30)</ion-select-option>
    <ion-select-option value="Asia/Bahrain">Bahrain (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Baku">Baku (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Dubai">Dubai (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Muscat">Muscat (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Qatar">Qatar (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Tbilisi">Tbilisi (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Yerevan">Yerevan (UTC +04:00)</ion-select-option>
    <ion-select-option value="Europe/Astrakhan">Astrakhan (UTC +04:00)</ion-select-option>
    <ion-select-option value="Europe/Samara">Samara (UTC +04:00)</ion-select-option>
    <ion-select-option value="Europe/Saratov">Saratov (UTC +04:00)</ion-select-option>
    <ion-select-option value="Europe/Ulyanovsk">Ulyanovsk (UTC +04:00)</ion-select-option>
    <ion-select-option value="Indian/Mahe">Mahe (UTC +04:00)</ion-select-option>
    <ion-select-option value="Indian/Mauritius">Mauritius (UTC +04:00)</ion-select-option>
    <ion-select-option value="Indian/Reunion">Reunion (UTC +04:00)</ion-select-option>
    <ion-select-option value="Asia/Kabul">Kabul (UTC +04:30)</ion-select-option>
    <ion-select-option value="Antarctica/Mawson">Mawson (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Aqtau">Aqtau (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Aqtobe">Aqtobe (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Ashgabat">Ashgabat (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Atyrau">Atyrau (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Dushanbe">Dushanbe (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Karachi">Karachi (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Oral">Oral (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Samarkand">Samarkand (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Tashkent">Tashkent (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Yekaterinburg">Yekaterinburg (UTC +05:00)</ion-select-option>
    <ion-select-option value="Indian/Kerguelen">Kerguelen (UTC +05:00)</ion-select-option>
    <ion-select-option value="Indian/Maldives">Maldives (UTC +05:00)</ion-select-option>
    <ion-select-option value="Asia/Colombo">Colombo (UTC +05:30)</ion-select-option>
    <ion-select-option value="Asia/Calcutta">Kolkata (UTC +05:30)</ion-select-option>
    <ion-select-option value="Asia/Kathmandu">Kathmandu (UTC +05:45)</ion-select-option>
    <ion-select-option value="Antarctica/Vostok">Vostok (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Almaty">Almaty (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Bishkek">Bishkek (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Dhaka">Dhaka (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Omsk">Omsk (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Qyzylorda">Qyzylorda (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Thimphu">Thimphu (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Urumqi">Urumqi (UTC +06:00)</ion-select-option>
    <ion-select-option value="Indian/Chagos">Chagos (UTC +06:00)</ion-select-option>
    <ion-select-option value="Asia/Yangon">Yangon (UTC +06:30)</ion-select-option>
    <ion-select-option value="Indian/Cocos">Cocos (UTC +06:30)</ion-select-option>
    <ion-select-option value="Antarctica/Davis">Davis (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Bangkok">Bangkok (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Barnaul">Barnaul (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Ho_Chi_Minh">Ho Chi Minh (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Hovd">Hovd (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Jakarta">Jakarta (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Krasnoyarsk">Krasnoyarsk (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Novokuznetsk">Novokuznetsk (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Novosibirsk">Novosibirsk (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Phnom_Penh">Phnom Penh (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Pontianak">Pontianak (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Tomsk">Tomsk (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Vientiane">Vientiane (UTC +07:00)</ion-select-option>
    <ion-select-option value="Indian/Christmas">Christmas (UTC +07:00)</ion-select-option>
    <ion-select-option value="Asia/Brunei">Brunei (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Choibalsan">Choibalsan (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Hong_Kong">Hong Kong (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Irkutsk">Irkutsk (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Kuala_Lumpur">Kuala Lumpur (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Kuching">Kuching (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Macau">Macau (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Makassar">Makassar (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Manila">Manila (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Shanghai">Shanghai (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Singapore">Singapore (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Taipei">Taipei (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Ulaanbaatar">Ulaanbaatar (UTC +08:00)</ion-select-option>
    <ion-select-option value="Australia/Perth">Perth (UTC +08:00)</ion-select-option>
    <ion-select-option value="Asia/Pyongyang">Pyongyang (UTC +08:30)</ion-select-option>
    <ion-select-option value="Australia/Eucla">Eucla (UTC +08:45)</ion-select-option>
    <ion-select-option value="Asia/Chita">Chita (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Dili">Dili (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Jayapura">Jayapura (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Khandyga">Khandyga (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Seoul">Seoul (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Tokyo">Tokyo (UTC +09:00)</ion-select-option>
    <ion-select-option value="Asia/Yakutsk">Yakutsk (UTC +09:00)</ion-select-option>
    <ion-select-option value="Pacific/Palau">Palau (UTC +09:00)</ion-select-option>
    <ion-select-option value="Australia/Darwin">Darwin (UTC +09:30)</ion-select-option>
    <ion-select-option value="Antarctica/DumontDUrville">DumontDUrville (UTC +10:00)</ion-select-option>
    <ion-select-option value="Asia/Vladivostok">Vladivostok (UTC +10:00)</ion-select-option>
    <ion-select-option value="Australia/Brisbane">Brisbane (UTC +10:00)</ion-select-option>
    <ion-select-option value="Australia/Lindeman">Lindeman (UTC +10:00)</ion-select-option>
    <ion-select-option value="Pacific/Chuuk">Chuuk (UTC +10:00)</ion-select-option>
    <ion-select-option value="Pacific/Guam">Guam (UTC +10:00)</ion-select-option>
    <ion-select-option value="Pacific/Port_Moresby">Port Moresby (UTC +10:00)</ion-select-option>
    <ion-select-option value="Pacific/Saipan">Saipan (UTC +10:00)</ion-select-option>
    <ion-select-option value="Ust-Nera">Nera (UTC +10:00)</ion-select-option>
    <ion-select-option value="Australia/Adelaide">Adelaide (UTC +10:30)</ion-select-option>
    <ion-select-option value="Australia/Broken_Hill">Broken Hill (UTC +10:30)</ion-select-option>
    <ion-select-option value="Antarctica/Casey">Casey (UTC +11:00)</ion-select-option>
    <ion-select-option value="Antarctica/Macquarie">Macquarie (UTC +11:00)</ion-select-option>
    <ion-select-option value="Asia/Magadan">Magadan (UTC +11:00)</ion-select-option>
    <ion-select-option value="Asia/Sakhalin">Sakhalin (UTC +11:00)</ion-select-option>
    <ion-select-option value="Asia/Srednekolymsk">Srednekolymsk (UTC +11:00)</ion-select-option>
    <ion-select-option value="Australia/Currie">Currie (UTC +11:00)</ion-select-option>
    <ion-select-option value="Australia/Hobart">Hobart (UTC +11:00)</ion-select-option>
    <ion-select-option value="Australia/Lord_Howe">Lord Howe (UTC +11:00)</ion-select-option>
    <ion-select-option value="Australia/Melbourne">Melbourne (UTC +11:00)</ion-select-option>
    <ion-select-option value="Australia/Sydney">Sydney (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Bougainville">Bougainville (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Efate">Efate (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Guadalcanal">Guadalcanal (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Kosrae">Kosrae (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Norfolk">Norfolk (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Noumea">Noumea (UTC +11:00)</ion-select-option>
    <ion-select-option value="Pacific/Pohnpei">Pohnpei (UTC +11:00)</ion-select-option>
    <ion-select-option value="Asia/Anadyr">Anadyr (UTC +12:00)</ion-select-option>
    <ion-select-option value="Asia/Kamchatka">Kamchatka (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Funafuti">Funafuti (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Kwajalein">Kwajalein (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Majuro">Majuro (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Nauru">Nauru (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Tarawa">Tarawa (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Wake">Wake (UTC +12:00)</ion-select-option>
    <ion-select-option value="Pacific/Wallis">Wallis (UTC +12:00)</ion-select-option>
    <ion-select-option value="Antarctica/McMurdo">McMurdo (UTC +13:00)</ion-select-option>
    <ion-select-option value="Pacific/Auckland">Auckland (UTC +13:00)</ion-select-option>
    <ion-select-option value="Pacific/Enderbury">Enderbury (UTC +13:00)</ion-select-option>
    <ion-select-option value="Pacific/Fakaofo">Fakaofo (UTC +13:00)</ion-select-option>
    <ion-select-option value="Pacific/Fiji">Fiji (UTC +13:00)</ion-select-option>
    <ion-select-option value="Pacific/Chatham">Chatham (UTC +13:45)</ion-select-option>
    <ion-select-option value="Pacific/Apia">Apia (UTC +14:00)</ion-select-option>
    <ion-select-option value="Pacific/Kiritimati">Kiritimati (UTC +14:00)</ion-select-option>
    <ion-select-option value="Pacific/Tongatapu">Tongatapu (UTC +14:00)</ion-select-option>
  </ion-select>
</template>

<script>
import { IonSelect, IonSelectOption } from "@ionic/vue";

export default {
  props: ['value', 'disabled'],
  components: {
    IonSelect,
    IonSelectOption
  },
  setup() {
    const timeZoneOptions = {
      cssClass: 'time-zone',
    };
    return { timeZoneOptions }
  },
}
</script>

<style>
.time-zone {
   --max-width: 350px;
   --width: 350px; 
}
</style>
