<template>
  <master-layout pageTitle="Settings">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-grid class="gridContainer ion-no-padding">
          <ion-row>
            <ion-col v-if="isAdmin || oldPermissions.business_details" size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="ion-no-padding">
              <ion-card router-link="/settings/account" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-general.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title"> Business Profile </ion-card-title>
                      <ion-card-subtitle class="subtitle">View and Update your Business Details. </ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col v-if="!IS_ASIA" size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="ion-no-padding" >
              <ion-card router-link="/settings/integrations" class="cardContainer">
                  <ion-row
                    class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-integrations.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title">Integrations</ion-card-title>
                      <ion-card-subtitle class="subtitle">Sync Jumper with your existing tools.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col v-if="(isAdmin || oldPermissions.payment_methods) && !IS_ASIA" size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="ion-no-padding" >
              <ion-card router-link="/settings/payments" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-payment.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title">Payments</ion-card-title>
                      <ion-card-subtitle class="subtitle">Enable and manage, how you accept payments.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col v-if="!IS_ASIA && (isAdmin || oldPermissions.shipping)" size-xl="4" size-lg="4"  size-sm="12" size-xs="12">
              <ion-card router-link="/settings/tax-and-shipping" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-taxes-and-shipping.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title"> Taxes and Shipping </ion-card-title>
                      <ion-card-subtitle class="subtitle"> Manage how you ship orders and charge taxes. </ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col size-xl="4" size-lg="4"  size-sm="12" size-xs="12">
              <ion-card router-link="/settings/team" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-user-roles.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title"> Team Members </ion-card-title>
                      <ion-card-subtitle class="subtitle"> Manage who can access your account and their permissions.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col v-if="!IS_ASIA" size-xl="4" size-lg="4"  size-sm="12" size-xs="12">
              <ion-card router-link="/settings/live-chat" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-live-chat-settings.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title">Live Chat Settings</ion-card-title>
                      <ion-card-subtitle class="subtitle">Settings such as away message, message shortcuts etc.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col v-if="!IS_ASIA" size-xl="4" size-lg="4"  size-sm="12" size-xs="12">
              <ion-card router-link="/settings/store-locations" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-locations.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <ion-card-title class="title">Store Locations</ion-card-title>
                      <ion-card-subtitle class="subtitle">Manage where customers can come and pick orders.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col v-if="!IS_ASIA && (isAdmin || oldPermissions.policies)" size-xl="4" size-lg="4"  size-sm="12" size-xs="12">
              <ion-card router-link="/settings/policies" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-policies.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>  
                      <ion-card-title class="title">Policies and Consent</ion-card-title>
                      <ion-card-subtitle class="subtitle">Manage your store's policies and consents.</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
            <ion-col size-xl="4" size-lg="4"  size-sm="12" size-xs="12" v-if="!IS_ASIA">
              <ion-card router-link="/settings/password" class="cardContainer">
                  <ion-row class="ion-align-items-center ion-justify-content-between cust-pad">
                    <ion-thumbnail style="width: 50px; margin-right: 10px;">
                      <img
                        src="https://jumper.ai/web-s/resources/images/store-settings-password-settings.svg"
                        style="max-width: 100%; width: auto;"
                      />
                    </ion-thumbnail>
                    <ion-col>
                      <!-- <ion-card-title class="title">Password Settings</ion-card-title>
                      <ion-card-subtitle class="subtitle">Change Password, manage password auto expiry for team etc.</ion-card-subtitle> -->
                      <ion-card-title class="title">Change Password</ion-card-title>
                      <ion-card-subtitle class="subtitle">Change your account password</ion-card-subtitle>
                    </ion-col>
                  </ion-row>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from "ionicons/icons";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
  },
  ionViewWillEnter() {
    this.init();
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      console.log("Initializing...");
    },
  },
};
</script>

<style scoped>

.gridContainer {
  margin: 30px;
}
.title {
  font-size: 18px;
  font-weight: 700;
}
.subtitle {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}
.cust-pad{
  padding: 15px;
}
@media only screen and (min-width: 780px){
.cardContainer {
  height: 90px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}
}

@media only screen and (max-width: 780px){
.gridContainer{
  margin: 10px;
}
.cardContainer {
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
}

}
</style>
