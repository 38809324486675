<template>
  <master-layout pageTitle="LINE">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="lineConnected">
          <div>
            <ion-title class="header">LINE</ion-title>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col v-if="!IS_ASIA" size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" router-link="/sales-channel/re-direct-links">
                    <ion-thumbnail slot="start">
                      <img width="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4MKFuOcKDA" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">LINE re-direct links</h2>
                      <p class="cardSubtitle ion-no-padding ion-no-margin">Send customers directly to your LINE account to interact with your bots, purchase your products or browse collections</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none"  @click="() => $router.push('/sales-channel/line/invite-friends')" style="padding-top: 10px">
                    <ion-thumbnail slot="start">
                      <img width="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4KLCzpgJDA" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Invite LINE Friends</h2>
                      <p class="cardSubtitle ion-no-padding ion-no-margin">Find out about all the ways you can advertise your official account and gain more friends</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div>
            <ion-title class="sub-title">Settings</ion-title>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" @click="() => settingsMode = true" style="padding-top: 10px">
                    <ion-thumbnail slot="start">
                      <img width="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PTYv5IJDA" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">LINE OA settings</h2>
                      <p class="cardSubtitle ion-no-padding ion-no-margin">Manage your LINE OA Integration</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
              <ion-col v-if="!IS_ASIA" size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="cardContainer">
                  <ion-item lines="none" @click="() => $router.push('/sales-channel/line/rich-menu')" style="padding-top: 10px">
                    <ion-thumbnail slot="start">
                      <img width="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4Myms4cIDA" />
                    </ion-thumbnail>
                    <ion-label>
                      <h2 class="cardTitle ion-no-padding ion-no-margin">Rich Menu</h2>
                      <p class="cardSubtitle ion-no-padding ion-no-margin">Manage your LINE Rich Menus</p>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-else>
          <ion-toolbar color="light">
            <ion-segment scrollable color="dark" :value="activeTab">
              <ion-segment-button value="step1" :disabled="step1disabled || activeTab === 'step4'">
                <ion-label>Step 1: Enter channel ID & secret</ion-label>
              </ion-segment-button>
              <ion-segment-button :disabled="step2disabled || activeTab === 'step4'" value="step2">
                <ion-label>Step 2: Enter access token</ion-label>
              </ion-segment-button>
              <ion-segment-button :disabled="step3disabled || activeTab === 'step4'" value="step3">
                <ion-label>Step 3: Enter channel name</ion-label>
              </ion-segment-button>
              <ion-segment-button :disabled="step4disabled" value="step4">
                <ion-label>Step 4: Finish setup</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-toolbar>
          <ion-card v-if="activeTab === 'step1'">
            <ion-card-header>
              <ion-card-title>Connect LINE</ion-card-title>
              <ion-card-subtitle>Step 1: Enter channel ID & secret</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div class="infoBanner">
                <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                  <div class="panel-body">
                    <h4>If you do not have a LINE account with a registered e-mail address, here's what you need to do:</h4>
                    <ol>
                      <li>Download LINE app from Google Play Store or Apple App Store</li>
                      <li>Head to Settings > Account and register your e-mail</li>
                    </ol>
                  </div>
                </div>
              </div>
              <ol>
                <li>
                  <p>Head over to the <a href="https://developers.line.biz/console/register/line-login/provider/" target="_blank">LINE Developer Portal</a> and log into your LINE account. Accept the terms and conditions for the Developer Portal.</p>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/1.png" class="img-responsive stepImg"><br/>
                </li>
                <li>
                  <p>Once you're in the LINE Developers Console, click Create a New Provider.</p>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/5.png" class="img-responsive stepImg">
                  <br/>
                  <p v-if="IS_ASIA">Enter a <b>Provider name</b>.</p>
                  <p v-else>Enter "Jumper.ai" as a <b>Provider name</b>.</p>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/6_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/6.png" class="img-responsive stepImg">
                  <br/>
                </li>
                <li>
                  <p>Once the provider is created, we need you to set-up two channels: <b>LINE Login</b> and <b>Messaging API</b>.<br/>Let's start with <b>LINE Login</b> by clicking on it.</p>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/7.png" class="img-responsive stepImg"><br/>
                </li>
                <li>
                  <p>On the <b>Create new channel</b> page, please enter the following details and then click on the <b>Create</b> button:</p>
                  <ol type="a">
                    <li>App icon: Upload your shop logo</li>
                    <li>App name: <span v-if="IS_ASIA">My App</span><span v-else>Jumper</span></li>
                    <li>App description: Enter a short description, for example Social commerce via LINE</li>
                    <li>App type: Use Web [WEB] & Use NATIVE_APP [NATIVE_APP]</li>
                    <li>E-mail address: Your e-mail address</li>
                  </ol>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/8a_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/8a.png" class="img-responsive stepImg"/>
                  <br/>
                </li>
                <li>
                  <p>Once done, you will be in the <span v-if="IS_ASIA">My App</span><span v-else>jumper</span> channel for LINE Login. Scroll down the page and please copy the following details in the fields below:</p>
                  <ol type="a">
                    <li>Channel ID</li>
                    <li>Channel Secret</li>
                  </ol>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/9a_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/9a.png" class="img-responsive stepImg" /><br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/9b.png" class="img-responsive stepImg" /><br/>
                </li>
              </ol>
              <div class="prdct-section">
                <ion-card-title class="sctn-labels">a.) Channel ID:</ion-card-title>
                <ion-input v-model="channelId" placeholder="Enter Channel ID" class="fullBordered input_custom_style domainInput"></ion-input>
              </div>
               <div class="prdct-section">
                <ion-card-title class="sctn-labels">b.) Channel Secret:</ion-card-title>
                <ion-input v-model="channelSecret" placeholder="Enter Channel Secret" class="fullBordered input_custom_style domainInput"></ion-input>
              </div>
              <ion-button @click="goToStep(2)" color="dark">Next</ion-button>
            </ion-card-content>
          </ion-card>
          <ion-card v-if="activeTab === 'step2'">
            <ion-card-header>
              <ion-card-title>Connect LINE</ion-card-title>
              <ion-card-subtitle>Step 2: Enter access token</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ol start="7">
                <li>
                  <p>Now, click on <b>TOP >{CHANNEL_NAME}</b> and then click on <b>Create a new channel.</b></p>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/10_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/10.png" class="img-responsive stepImg"><br/>
                </li>
                <li>
                  <p>Next select <b>Messaging API</b>.</p>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/11_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/11.png" class="img-responsive stepImg">
                  <br/>
                </li>
                <li>
                  <p>On this page, fill in the following details:</p>
                  <br/>
                  <ol type="a">
                    <li>App icon: your shop logo</li>
                    <li>App name: shop name</li>
                    <li>App description: Short description, for example Social commerce via LINE</li>
                    <li>Category: Local Businesses and E-commerce</li>
                    <li>Subcategory: Select appropriate value</li>
                    <li>Email address: your email address</li>
                  </ol>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/12a_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/12a.png"><br/>
                </li>
                <li>
                  <p>Once it is created, you have to go to <b>Messaging API</b> from the top bar.</p>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/12b_asia.png" class="img-responsive stepImg">
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/12b.png" class="img-responsive stepImg" /><br/>
                </li>
                <li>
                  <p>Scroll down to the <b>LINE Official Account features</b> and click on <b>Edit</b> to disable <b>Greeting messages</b>.</p><br/>
                  <p>Once you click on <b>Edit</b>, it will open a new window, where you have to select <b>Disabled</b> for <b>Greeting message</b>, <b>Disabled</b> for <b>Auto-response</b> and <b>Enabled</b> for <b>Webhooks</b></p><br/>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/12b1.png" class="img-responsive stepImg" /><br/>
                  <p>Now you can close the window and go back to the previous window which is <b>Messaging API</b> and refresh the page. Now you will see that your settings are saved.</p><br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/12b2.png" class="img-responsive stepImg" /><br/>
                </li>
                <li>
                  <p>Now scroll down to <b>Channel access token</b> and click on <b>Issue</b> button.</p><br/>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/12c.png" class="img-responsive stepImg" /><br/>
                  <p>Copy <b>Channel access token</b> and paste it in the field below:</p><br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/13.png" class="img-responsive stepImg" /><br/>
                </li>
              </ol>
              <ion-item>
                <ion-label>Channel Access Token:</ion-label>
                <ion-input v-model="channelAccessToken" placeholder="Enter Channel Access Token"></ion-input>
              </ion-item>
              <ion-row>
                <ion-button color="dark" @click="goToStep(1)">Prev</ion-button>
                <ion-button @click="goToStep(3)">Next</ion-button>
              </ion-row>
            </ion-card-content>
          </ion-card>
          <ion-card v-if="activeTab === 'step3'">
            <ion-card-header>
              <ion-card-title>Connect LINE</ion-card-title>
              <ion-card-subtitle>Step 3: Enter channel name</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ol start="11">
                <li>
                  <p>Scroll up again to the <b>Bot information</b> section and copy the <b>Bot basic ID</b> (excluding @ sign) for ex.: <b>123abcde</b>.</p>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/14.png" class="img-responsive stepImg"><br/>
                </li>
              </ol>
              <ion-item>
                <ion-label>Bot channel name:</ion-label>
                <span>@</span>
                <ion-input v-model="channelName" placeholder="Bot Channel Name"></ion-input>
              </ion-item>
              <ion-row>
                <ion-button color="dark" @click="goToStep(2)">Prev</ion-button>
                <ion-button @click="goToStep(4)">Connect</ion-button>
              </ion-row>
            </ion-card-content>
          </ion-card>
          <ion-card v-if="activeTab === 'step4'">
            <ion-card-header>
              <ion-card-title>Connect LINE</ion-card-title>
              <ion-card-subtitle>Step 4: Finish setup</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ol start="12">
                <li>
                  <p>Copy <b>Webhook URL</b> and paste it in Webhook settings > Webhook URL and click on <b>Update</b> button) and then click on <b>Verify</b> button.</p>
                  <br/>
                  <ion-item>
                    <ion-label>{{ webhookurlToShow }}</ion-label>
                    <ion-button @click="copyURL(webhookurl)">Copy</ion-button>
                  </ion-item>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/15_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/15.png" class="img-responsive stepImg"><br/>
                </li>
                <li>
                  <p>And now click on <b>Verify</b> button and enable <b>Use webhook</b>.</p>
                  <br/>
                  <img src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/16.png" class="img-responsive stepImg"><br/>
                </li>
                <li>
                  <p>Finally go to the <b>LINE Login</b> channel > click on <b>Developing</b> to <b>Publish</b> it.</p>
                  <br/>
                  <img v-if="IS_ASIA" src="../../../resources/images/line-connect/17_asia.png" class="img-responsive stepImg">
                  <img v-else src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/connect-line/17.png" class="img-responsive stepImg"><br/>
                  <p>You're set! The channel will show as Published.</p><br/>
                </li>
              </ol>
              <div class="infoBanner">
                <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                  <div class="panel-body">
                    <h4>Note: Please check the Messaging API channel once again to ensure that <b>Use webhooks</b> is <b>Enabled</b></h4>
                  </div>
                </div>
              </div>
              <ion-button @click="goToStep(5)">Done</ion-button>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
    <ion-modal
      v-if="settingsMode"
      :is-open="settingsMode"
      @didDismiss="() => settingsMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="prdt-title ion-no-margin">LINE OA Settings</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => settingsMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <div>
            <ion-item lines="none" style="padding: 15px;">
              <ion-avatar slot="start" style="height: auto; width: 38px;">
                <img width="50" src="https://jumperdevnew.appspot.com/landing/resources/images/line.svg"/>
              </ion-avatar>
              <ion-label>
                <h3 class="heading">Connected LINE account</h3>
                <p class="sub-heading">Your shop and LINE bot is connected to the below account.</p>
              </ion-label>
            </ion-item>

            <ion-card-content>
              <div>
                <ion-item lines="none" class="line-container prdct-section">
                  <ion-label>
                    <h3 class="heading">@{{ lineSocialData.channelname }}</h3>
                  </ion-label>
                  <ion-button @click="() => disconnectMode = true" fill="outline" color="danger">Disconnect</ion-button>
                </ion-item>
              </div>

              <div class="line-message">
                <p class="sub-heading prdct-section">If you have forgotten to put the Webhook URL in LINE, please copy the below and put that in your LINE Dashboard -> Messaging Settings -> Webhook URL</p>
                <ion-item lines="none" class="ion-no-padding cust-margin-top">
                  <div type="url" class="url-input"> {{ webhookurlToShow }}</div>
                  <ion-button class="url-bttn" @click="copyURL(webhookurl)" color="primary">Copy</ion-button>
                </ion-item>
              </div>
              <div v-if="!IS_ASIA" class="infoBanner">
                <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                    <div class="panel-body">
                      <h4><b>Note:</b> You can send upto <b>500 messages (limited)</b> in a month while sending broadcast to your customers.</h4>
                    </div>
                </div>
              </div>
            </ion-card-content>
          </div>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="disconnectMode"
      :is-open="disconnectMode"
      header="Are you sure?"
      :message="disconnectMessage"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          disconnectMode = false;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnect()
        },
      }]"
      @didDismiss="() => disconnectMode = false"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, close } from 'ionicons/icons';

export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      close,
      activeTab: 'step1',
      channelId: undefined,
      channelSecret: undefined,
      channelAccessToken: undefined,
      channelName: undefined,
      webhookurl: undefined,
      step1disabled: false,
      step2disabled: true,
      step3disabled: true,
      step4disabled: true,
      finalChannel: undefined,
      inviteMode: false,
      active: false,
      settingsMode: false,
      disconnectMode: false
    }
  },
  computed: {
    QRHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"> <img src="https://qr-official.line.me/sid/M/' + this.channelName + '.png" alt="LINE Add friend" height="180" width="180" border="0"> </a>';
    },
    buttonHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" alt="Add friend" height="36"></a>';
    },
    webhookurlToShow() {
      if(this.webhookurl) {
        try {
          var url = new URL(this.webhookurl);
          url.hostname = 'XXXXXXXXXX';
          return url.href;
        } catch (error) {
          console.error(error);
          return "";
        }
      } else {
        return "";
      }
    },
    disconnectMessage() {
      let message = "";
      if (this.IS_ASIA) {
        message = "We will not be able to converse and help your customers check-out with their order if they comment on your channel with your #hashtag. Are you sure you want to disconnect your LINE account?";
      } else {
        message = "Jumper will not be able to converse and help your customers check-out with their order if they comment on your channel with your #hashtag. Are you sure you want to disconnect your LINE account?";
      }
      return message;
    }
  },
  watch: {
    
  },
  mounted() {
    this.init();
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      try {
        this.loading = true;
        if(this.lineConnected) {
          this.active = this.lineSocialData.active;
          this.channelAccessToken = this.lineSocialData.channelaccesstoken;
          this.channelName = this.lineSocialData.channelname;
          this.webhookurl = this.lineSocialData.webhookurl;
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async goToStep(n) {
      switch(n) {
        case 1: {
          this.step2disabled = true;
          this.step3disabled = true;
          this.step4disabled = true;
          this.channelId = undefined;
          this.channelSecret = undefined;
          this.activeTab = 'step1';
        } break;
        case 2: {
          this.step1disabled = true; this.step3disabled = true; this.step4disabled = true;
          if(this.channelId && this.channelSecret) { this.step2disabled = false; this.activeTab = 'step2';}
          else this.updateToast({
            open: true,
            message: "Missing details",
            type: "warning"
          })
        } break;
        case 3: {
          if(this.channelId && this.channelSecret && this.channelAccessToken) { this.step2disabled = true; this.step3disabled = false; this.activeTab = 'step3';}
          else this.updateToast({
            open: true,
            message: "Missing details",
            type: "warning"
          })
        } break;
        case 4: {
          if(this.channelId && this.channelSecret && this.channelAccessToken && this.channelName) { 
            this.step1disabled = true; this.step2disabled = false; this.step3disabled = false; this.step4disabled = false; this.activeTab = 'step4';
            try {
              this.loading = true;
              let reqBody = {
                accesstoken: this.channelAccessToken,
                channelid: this.channelId,
                channelsecret: this.channelSecret,
                channelname: this.channelName
              };
              let connectLineResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-page", reqBody);
              if ((connectLineResponse.status == 200 || connectLineResponse.status == 201) && connectLineResponse.data.success) {
                if(connectLineResponse.data.webhookurl && connectLineResponse.data.channel) {
                  this.webhookurl = connectLineResponse.data.webhookurl;
                  this.finalChannel = connectLineResponse.data.channel;
                  this.updateToast({
                    open: true,
                    message: "Channel " + connectLineResponse.data.channel + " connected successfully",
                    type: "success"
                  })
                } else {
                  this.updateToast({
                    open: true,
                    message: "Something went wrong",
                    type: "danger"
                  })
                }
              } else {
                if(connectLineResponse.data && !connectLineResponse.data.success && connectLineResponse.data.errorMessage && connectLineResponse.data.errorMessage.length) {
                  this.updateToast({
                    open: true,
                    message: connectLineResponse.data.errorMessage,
                    type: "danger"
                  })
                } else {
                  this.updateToast({
                    open: true,
                    message: "Something went wrong",
                    type: "danger"
                  })
                }
              }
            } catch (error) {
              console.log("Exception: ", error);
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            } finally {
              this.loading = false;
            }
          }
          else this.updateToast({
            open: true,
            message: "Missing details",
            type: "warning"
          })
        } break;
        case 5: {
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        } break;
        default: console.log("Something went wrong");
      }
    },
    copyURL(link) {
      try {
        switch(link) {
          case 'QR': navigator.clipboard.writeText(this.QRHTML);
          break;
          case 'button': navigator.clipboard.writeText(this.buttonHTML);
          break;
          default: navigator.clipboard.writeText(link);
        }
        this.updateToast({
          open: true,
          message: "Webhook URL copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async confirmDisconnect() {
      this.loading = true;
      try {
        let disconnectLineResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-social", { platform: 'line' });
        if ((disconnectLineResponse.status == 200 || disconnectLineResponse.status == 201) && disconnectLineResponse.data.success) {
          if(disconnectLineResponse.data.delete) this.updateToast({
            open: true,
            message: "LINE disconnected successfully",
            type: "success"
          })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(disconnectLineResponse.data.errorMessage && disconnectLineResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: disconnectLineResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
}

.infoBanner .panel .panel-body{
  border-left: 3px solid #FFC409;
}

.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}

.infoBanner.success .panel .panel-body{
  border-left: 3px #099e6e solid;
}

.infoBanner.danger .panel.panel-default{
  background-color: #ffd4d4;
}

.infoBanner.danger .panel .panel-body{
  border-left: 3px #ff0000 solid;
}

.panel-body {
  padding: 20px;
}
.header {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
  margin-top: 20px;
}
.sub-title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.cardContainer {
  min-height: 90px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 4px;
}

.cust-margin-top {
  margin-top: 15px
}
.heading {
  font-size: 18px;
  font-weight: 400;
  color: var(--ion-color-black);
}
.sub-heading {
  font-size: 14px;
  color: var(--ion-color-grey);
}
.line-container {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
}

.url-bttn {
	height: 37px;
}
ion-button.url-bttn {
	--border-radius: 0 4px 4px 0px;
}
.url-input {
	border: 1px solid #e3e3e3;
	background-color: #f5f5f5;
	color: #888;
	cursor: not-allowed;
	border-right: 0 none;
	border-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	height: 37px;
	padding: 7px;
}

@media only screen and (max-width: 760px) {
  .url-input{
    height: auto;
    width: 85%;
  }
  .url-bttn{
    height: -webkit-fill-available;
    margin: 0px;
  }
}

</style>