var allTimezonesData = [
    {
        "slashName": "Pacific/Midway",
        "name": "Midway",
        "id": "-11:00",
        "source": "-11"
    },
    {
        "slashName": "Pacific/Niue",
        "name": "Niue",
        "id": "-11:00",
        "source": "-11"
    },
    {
        "slashName": "Pacific/Pago_Pago",
        "name": "Pago Pago",
        "id": "-11:00",
        "source": "-11"
    },
    {
        "slashName": "America/Adak",
        "name": "Adak",
        "id": "-10:00",
        "source": "-10"
    },
    {
        "slashName": "Pacific/Honolulu",
        "name": "Honolulu",
        "id": "-10:00",
        "source": "-10"
    },
    {
        "slashName": "Pacific/Rarotonga",
        "name": "Rarotonga",
        "id": "-10:00",
        "source": "-10"
    },
    {
        "slashName": "Pacific/Tahiti",
        "name": "Tahiti",
        "id": "-10:00",
        "source": "-10"
    },
    {
        "slashName": "Pacific/Marquesas",
        "name": "Marquesas",
        "id": "-09:30",
        "source": "-9.5"
    },
    {
        "slashName": "America/Anchorage",
        "name": "Anchorage",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Juneau",
        "name": "Juneau",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Metlakatla",
        "name": "Metlakatla",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Nome",
        "name": "Nome",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Sitka",
        "name": "Sitka",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Yakutat",
        "name": "Yakutat",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "Pacific/Gambier",
        "name": "Gambier",
        "id": "-09:00",
        "source": "-9"
    },
    {
        "slashName": "America/Dawson",
        "name": "Dawson",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "America/Los_Angeles",
        "name": "Los Angeles",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "America/Tijuana",
        "name": "Tijuana",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "America/Vancouver",
        "name": "Vancouver",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "America/Whitehorse",
        "name": "Whitehorse",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "Pacific/Pitcairn",
        "name": "Pitcairn",
        "id": "-08:00",
        "source": "-8"
    },
    {
        "slashName": "America/Boise",
        "name": "Boise",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Cambridge_Bay",
        "name": "Cambridge Bay",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Chihuahua",
        "name": "Chihuahua",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Creston",
        "name": "Creston",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Dawson_Creek",
        "name": "Dawson Creek",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Denver",
        "name": "Denver",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Edmonton",
        "name": "Edmonton",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Fort_Nelson",
        "name": "Fort Nelson",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Hermosillo",
        "name": "Hermosillo",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Inuvik",
        "name": "Inuvik",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Mazatlan",
        "name": "Mazatlan",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Ojinaga",
        "name": "Ojinaga",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Phoenix",
        "name": "Phoenix",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Yellowknife",
        "name": "Yellowknife",
        "id": "-07:00",
        "source": "-7"
    },
    {
        "slashName": "America/Bahia_Banderas",
        "name": "Bahia Banderas",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Belize",
        "name": "Belize",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Chicago",
        "name": "Chicago",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Costa_Rica",
        "name": "Costa Rica",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/El_Salvador",
        "name": "El Salvador",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Guatemala",
        "name": "Guatemala",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Managua",
        "name": "Managua",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Matamoros",
        "name": "Matamoros",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Menominee",
        "name": "Menominee",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Merida",
        "name": "Merida",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Mexico_City",
        "name": "Mexico City",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Monterrey",
        "name": "Monterrey",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Rainy_River",
        "name": "Rainy River",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Rankin_Inlet",
        "name": "Rankin Inlet",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Regina",
        "name": "Regina",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Resolute",
        "name": "Resolute",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Swift_Current",
        "name": "Swift Current",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Tegucigalpa",
        "name": "Tegucigalpa",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Winnipeg",
        "name": "Winnipeg",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "Indiana/Knox",
        "name": "Knox",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "Indiana/Tell_City",
        "name": "Tell City",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "North_Dakota/Beulah",
        "name": "Beulah",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "North_Dakota/Center",
        "name": "Center",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "North_Dakota/New_Salem",
        "name": "New Salem",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "Pacific/Galapagos",
        "name": "Galapagos",
        "id": "-06:00",
        "source": "-6"
    },
    {
        "slashName": "America/Atikokan",
        "name": "Atikokan",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Bogota",
        "name": "Bogota",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Cancun",
        "name": "Cancun",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Cayman",
        "name": "Cayman",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Detroit",
        "name": "Detroit",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Eirunepe",
        "name": "Eirunepe",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Guayaquil",
        "name": "Guayaquil",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Havana",
        "name": "Havana",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Iqaluit",
        "name": "Iqaluit",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Jamaica",
        "name": "Jamaica",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Lima",
        "name": "Lima",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Nassau",
        "name": "Nassau",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/New_York",
        "name": "New York",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Nipigon",
        "name": "Nipigon",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Panama",
        "name": "Panama",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Pangnirtung",
        "name": "Pangnirtung",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Rio_Branco",
        "name": "Rio Branco",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Thunder_Bay",
        "name": "Thunder Bay",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Toronto",
        "name": "Toronto",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Indianapolis",
        "name": "Indianapolis",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Marengo",
        "name": "Marengo",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Petersburg",
        "name": "Petersburg",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Vevay",
        "name": "Vevay",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Vincennes",
        "name": "Vincennes",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Indiana/Winamac",
        "name": "Winamac",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Kentucky/Louisville",
        "name": "Louisville",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Kentucky/Monticello",
        "name": "Monticello",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "Pacific/Easter",
        "name": "Easter",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "au-Prince",
        "name": "Prince",
        "id": "-05:00",
        "source": "-5"
    },
    {
        "slashName": "America/Anguilla",
        "name": "Anguilla",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Antigua",
        "name": "Antigua",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Aruba",
        "name": "Aruba",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Barbados",
        "name": "Barbados",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Boa_Vista",
        "name": "Boa Vista",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Caracas",
        "name": "Caracas",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Curacao",
        "name": "Curacao",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Dominica",
        "name": "Dominica",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Glace_Bay",
        "name": "Glace Bay",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Goose_Bay",
        "name": "Goose Bay",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Grand_Turk",
        "name": "Grand Turk",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Grenada",
        "name": "Grenada",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Guadeloupe",
        "name": "Guadeloupe",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Guyana",
        "name": "Guyana",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Halifax",
        "name": "Halifax",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Kralendijk",
        "name": "Kralendijk",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/La_Paz",
        "name": "La Paz",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Lower_Princes",
        "name": "Lower Princes",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Manaus",
        "name": "Manaus",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Marigot",
        "name": "Marigot",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Martinique",
        "name": "Martinique",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Moncton",
        "name": "Moncton",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Montserrat",
        "name": "Montserrat",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Port_of_Spain",
        "name": "Port of Spain",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Porto_Velho",
        "name": "Porto Velho",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Puerto_Rico",
        "name": "Puerto Rico",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Santo_Domingo",
        "name": "Santo Domingo",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Barthelemy",
        "name": "St Barthelemy",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Kitts",
        "name": "St Kitts",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Lucia",
        "name": "St Lucia",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Thomas",
        "name": "St Thomas",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Vincent",
        "name": "St Vincent",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Thule",
        "name": "Thule",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/Tortola",
        "name": "Tortola",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "Atlantic/Bermuda",
        "name": "Bermuda",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "Blanc-Sablon",
        "name": "Sablon",
        "id": "-04:00",
        "source": "-4"
    },
    {
        "slashName": "America/St_Johns",
        "name": "St Johns",
        "id": "-03:30",
        "source": "-3.5"
    },
    {
        "slashName": "America/Araguaina",
        "name": "Araguaina",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Asuncion",
        "name": "Asuncion",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Bahia",
        "name": "Bahia",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Belem",
        "name": "Belem",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Campo_Grande",
        "name": "Campo Grande",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Cayenne",
        "name": "Cayenne",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Cuiaba",
        "name": "Cuiaba",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Fortaleza",
        "name": "Fortaleza",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Godthab",
        "name": "Godthab",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Maceio",
        "name": "Maceio",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Miquelon",
        "name": "Miquelon",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Montevideo",
        "name": "Montevideo",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Paramaribo",
        "name": "Paramaribo",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Punta_Arenas",
        "name": "Punta Arenas",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Recife",
        "name": "Recife",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Santarem",
        "name": "Santarem",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Santiago",
        "name": "Santiago",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Antarctica/Palmer",
        "name": "Palmer",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Antarctica/Rothera",
        "name": "Rothera",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Buenos_Aires",
        "name": "Buenos Aires",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Catamarca",
        "name": "Catamarca",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Cordoba",
        "name": "Cordoba",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Jujuy",
        "name": "Jujuy",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/La_Rioja",
        "name": "La Rioja",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Mendoza",
        "name": "Mendoza",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Rio_Gallegos",
        "name": "Rio Gallegos",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Salta",
        "name": "Salta",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/San_Juan",
        "name": "San Juan",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/San_Luis",
        "name": "San Luis",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Tucuman",
        "name": "Tucuman",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Argentina/Ushuaia",
        "name": "Ushuaia",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "Atlantic/Stanley",
        "name": "Stanley",
        "id": "-03:00",
        "source": "-3"
    },
    {
        "slashName": "America/Noronha",
        "name": "Noronha",
        "id": "-02:00",
        "source": "-2"
    },
    {
        "slashName": "America/Sao_Paulo",
        "name": "Sao Paulo",
        "id": "-02:00",
        "source": "-2"
    },
    {
        "slashName": "Atlantic/South_Georgia",
        "name": "South Georgia",
        "id": "-02:00",
        "source": "-2"
    },
    {
        "slashName": "America/Scoresbysund",
        "name": "Scoresbysund",
        "id": "-01:00",
        "source": "-1"
    },
    {
        "slashName": "Atlantic/Azores",
        "name": "Azores",
        "id": "-01:00",
        "source": "-1"
    },
    {
        "slashName": "Atlantic/Cape_Verde",
        "name": "Cape Verde",
        "id": "-01:00",
        "source": "-1"
    },
    {
        "slashName": "Africa/Abidjan",
        "name": "Abidjan",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Accra",
        "name": "Accra",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Bamako",
        "name": "Bamako",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Banjul",
        "name": "Banjul",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Bissau",
        "name": "Bissau",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Casablanca",
        "name": "Casablanca",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Conakry",
        "name": "Conakry",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Dakar",
        "name": "Dakar",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/El_Aaiun",
        "name": "El Aaiun",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Freetown",
        "name": "Freetown",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Lome",
        "name": "Lome",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Monrovia",
        "name": "Monrovia",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Nouakchott",
        "name": "Nouakchott",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Ouagadougou",
        "name": "Ouagadougou",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Sao_Tome",
        "name": "Sao Tome",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "America/Danmarkshavn",
        "name": "Danmarkshavn",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Antarctica/Troll",
        "name": "Troll",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Atlantic/Canary",
        "name": "Canary",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Atlantic/Faroe",
        "name": "Faroe",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Atlantic/Madeira",
        "name": "Madeira",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Atlantic/Reykjavik",
        "name": "Reykjavik",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Atlantic/St_Helena",
        "name": "St Helena",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/Dublin",
        "name": "Dublin",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/Guernsey",
        "name": "Guernsey",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/Isle_of_Man",
        "name": "Isle of Man",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/Jersey",
        "name": "Jersey",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/Lisbon",
        "name": "Lisbon",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Europe/London",
        "name": "London",
        "id": "+00:00",
        "source": "0"
    },
    {
        "slashName": "Africa/Algiers",
        "name": "Algiers",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Bangui",
        "name": "Bangui",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Brazzaville",
        "name": "Brazzaville",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Ceuta",
        "name": "Ceuta",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Douala",
        "name": "Douala",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Kinshasa",
        "name": "Kinshasa",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Lagos",
        "name": "Lagos",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Libreville",
        "name": "Libreville",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Luanda",
        "name": "Luanda",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Malabo",
        "name": "Malabo",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Ndjamena",
        "name": "Ndjamena",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Niamey",
        "name": "Niamey",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Tunis",
        "name": "Tunis",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Arctic/Longyearbyen",
        "name": "Longyearbyen",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Amsterdam",
        "name": "Amsterdam",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Andorra",
        "name": "Andorra",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Belgrade",
        "name": "Belgrade",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Berlin",
        "name": "Berlin",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Bratislava",
        "name": "Bratislava",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Brussels",
        "name": "Brussels",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Budapest",
        "name": "Budapest",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Busingen",
        "name": "Busingen",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Copenhagen",
        "name": "Copenhagen",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Gibraltar",
        "name": "Gibraltar",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Ljubljana",
        "name": "Ljubljana",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Luxembourg",
        "name": "Luxembourg",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Madrid",
        "name": "Madrid",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Malta",
        "name": "Malta",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Monaco",
        "name": "Monaco",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Oslo",
        "name": "Oslo",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Paris",
        "name": "Paris",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Podgorica",
        "name": "Podgorica",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Prague",
        "name": "Prague",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Rome",
        "name": "Rome",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/San_Marino",
        "name": "San Marino",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Sarajevo",
        "name": "Sarajevo",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Skopje",
        "name": "Skopje",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Stockholm",
        "name": "Stockholm",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Tirane",
        "name": "Tirane",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Vaduz",
        "name": "Vaduz",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Vatican",
        "name": "Vatican",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Vienna",
        "name": "Vienna",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Warsaw",
        "name": "Warsaw",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Zagreb",
        "name": "Zagreb",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Europe/Zurich",
        "name": "Zurich",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Porto-Novo",
        "name": "Novo",
        "id": "+01:00",
        "source": "1"
    },
    {
        "slashName": "Africa/Blantyre",
        "name": "Blantyre",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Bujumbura",
        "name": "Bujumbura",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Cairo",
        "name": "Cairo",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Gaborone",
        "name": "Gaborone",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Harare",
        "name": "Harare",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Johannesburg",
        "name": "Johannesburg",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Kigali",
        "name": "Kigali",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Lubumbashi",
        "name": "Lubumbashi",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Lusaka",
        "name": "Lusaka",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Maputo",
        "name": "Maputo",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Maseru",
        "name": "Maseru",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Mbabane",
        "name": "Mbabane",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Tripoli",
        "name": "Tripoli",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Windhoek",
        "name": "Windhoek",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Amman",
        "name": "Amman",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Beirut",
        "name": "Beirut",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Damascus",
        "name": "Damascus",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Gaza",
        "name": "Gaza",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Hebron",
        "name": "Hebron",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Jerusalem",
        "name": "Jerusalem",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Asia/Nicosia",
        "name": "Nicosia",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Athens",
        "name": "Athens",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Bucharest",
        "name": "Bucharest",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Chisinau",
        "name": "Chisinau",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Helsinki",
        "name": "Helsinki",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Kaliningrad",
        "name": "Kaliningrad",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Kiev",
        "name": "Kiev",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Mariehamn",
        "name": "Mariehamn",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Riga",
        "name": "Riga",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Sofia",
        "name": "Sofia",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Tallinn",
        "name": "Tallinn",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Uzhgorod",
        "name": "Uzhgorod",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Vilnius",
        "name": "Vilnius",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Europe/Zaporozhye",
        "name": "Zaporozhye",
        "id": "+02:00",
        "source": "2"
    },
    {
        "slashName": "Africa/Addis_Ababa",
        "name": "Addis Ababa",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Asmara",
        "name": "Asmara",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Dar_es_Salaam",
        "name": "Dar es Salaam",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Djibouti",
        "name": "Djibouti",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Juba",
        "name": "Juba",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Kampala",
        "name": "Kampala",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Khartoum",
        "name": "Khartoum",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Mogadishu",
        "name": "Mogadishu",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Africa/Nairobi",
        "name": "Nairobi",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Antarctica/Syowa",
        "name": "Syowa",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Aden",
        "name": "Aden",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Baghdad",
        "name": "Baghdad",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Famagusta",
        "name": "Famagusta",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Kuwait",
        "name": "Kuwait",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Riyadh",
        "name": "Riyadh",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Istanbul",
        "name": "Istanbul",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Kirov",
        "name": "Kirov",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Minsk",
        "name": "Minsk",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Moscow",
        "name": "Moscow",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Simferopol",
        "name": "Simferopol",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Europe/Volgograd",
        "name": "Volgograd",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Indian/Antananarivo",
        "name": "Antananarivo",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Indian/Comoro",
        "name": "Comoro",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Indian/Mayotte",
        "name": "Mayotte",
        "id": "+03:00",
        "source": "3"
    },
    {
        "slashName": "Asia/Tehran",
        "name": "Tehran",
        "id": "+03:30",
        "source": "3.5"
    },
    {
        "slashName": "Asia/Bahrain",
        "name": "Bahrain",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Baku",
        "name": "Baku",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Dubai",
        "name": "Dubai",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Muscat",
        "name": "Muscat",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Qatar",
        "name": "Qatar",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Tbilisi",
        "name": "Tbilisi",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Yerevan",
        "name": "Yerevan",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Europe/Astrakhan",
        "name": "Astrakhan",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Europe/Samara",
        "name": "Samara",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Europe/Saratov",
        "name": "Saratov",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Europe/Ulyanovsk",
        "name": "Ulyanovsk",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Indian/Mahe",
        "name": "Mahe",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Indian/Mauritius",
        "name": "Mauritius",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Indian/Reunion",
        "name": "Reunion",
        "id": "+04:00",
        "source": "4"
    },
    {
        "slashName": "Asia/Kabul",
        "name": "Kabul",
        "id": "+04:30",
        "source": "4.5"
    },
    {
        "slashName": "Antarctica/Mawson",
        "name": "Mawson",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Aqtau",
        "name": "Aqtau",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Aqtobe",
        "name": "Aqtobe",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Ashgabat",
        "name": "Ashgabat",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Atyrau",
        "name": "Atyrau",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Dushanbe",
        "name": "Dushanbe",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Karachi",
        "name": "Karachi",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Oral",
        "name": "Oral",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Samarkand",
        "name": "Samarkand",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Tashkent",
        "name": "Tashkent",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Yekaterinburg",
        "name": "Yekaterinburg",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Indian/Kerguelen",
        "name": "Kerguelen",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Indian/Maldives",
        "name": "Maldives",
        "id": "+05:00",
        "source": "5"
    },
    {
        "slashName": "Asia/Colombo",
        "name": "Colombo",
        "id": "+05:30",
        "source": "5.5"
    },
    {
        "slashName": "Asia/Calcutta",
        "name": "Kolkata",
        "id": "+05:30",
        "source": "5.5"
    },
    {
        "slashName": "Asia/Kathmandu",
        "name": "Kathmandu",
        "id": "+05:45",
        "source": "5.75"
    },
    {
        "slashName": "Antarctica/Vostok",
        "name": "Vostok",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Almaty",
        "name": "Almaty",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Bishkek",
        "name": "Bishkek",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Dhaka",
        "name": "Dhaka",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Omsk",
        "name": "Omsk",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Qyzylorda",
        "name": "Qyzylorda",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Thimphu",
        "name": "Thimphu",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Urumqi",
        "name": "Urumqi",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Indian/Chagos",
        "name": "Chagos",
        "id": "+06:00",
        "source": "6"
    },
    {
        "slashName": "Asia/Yangon",
        "name": "Yangon",
        "id": "+06:30",
        "source": "6.5"
    },
    {
        "slashName": "Indian/Cocos",
        "name": "Cocos",
        "id": "+06:30",
        "source": "6.5"
    },
    {
        "slashName": "Antarctica/Davis",
        "name": "Davis",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Bangkok",
        "name": "Bangkok",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Barnaul",
        "name": "Barnaul",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Ho_Chi_Minh",
        "name": "Ho Chi Minh",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Hovd",
        "name": "Hovd",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Jakarta",
        "name": "Jakarta",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Krasnoyarsk",
        "name": "Krasnoyarsk",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Novokuznetsk",
        "name": "Novokuznetsk",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Novosibirsk",
        "name": "Novosibirsk",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Phnom_Penh",
        "name": "Phnom Penh",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Pontianak",
        "name": "Pontianak",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Tomsk",
        "name": "Tomsk",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Vientiane",
        "name": "Vientiane",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Indian/Christmas",
        "name": "Christmas",
        "id": "+07:00",
        "source": "7"
    },
    {
        "slashName": "Asia/Brunei",
        "name": "Brunei",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Choibalsan",
        "name": "Choibalsan",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Hong_Kong",
        "name": "Hong Kong",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Irkutsk",
        "name": "Irkutsk",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Kuala_Lumpur",
        "name": "Kuala Lumpur",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Kuching",
        "name": "Kuching",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Macau",
        "name": "Macau",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Makassar",
        "name": "Makassar",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Manila",
        "name": "Manila",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Shanghai",
        "name": "Shanghai",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Singapore",
        "name": "Singapore",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Taipei",
        "name": "Taipei",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Ulaanbaatar",
        "name": "Ulaanbaatar",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Australia/Perth",
        "name": "Perth",
        "id": "+08:00",
        "source": "8"
    },
    {
        "slashName": "Asia/Pyongyang",
        "name": "Pyongyang",
        "id": "+08:30",
        "source": "8.5"
    },
    {
        "slashName": "Australia/Eucla",
        "name": "Eucla",
        "id": "+08:45",
        "source": "8.75"
    },
    {
        "slashName": "Asia/Chita",
        "name": "Chita",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Dili",
        "name": "Dili",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Jayapura",
        "name": "Jayapura",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Khandyga",
        "name": "Khandyga",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Seoul",
        "name": "Seoul",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Tokyo",
        "name": "Tokyo",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Asia/Yakutsk",
        "name": "Yakutsk",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Pacific/Palau",
        "name": "Palau",
        "id": "+09:00",
        "source": "9"
    },
    {
        "slashName": "Australia/Darwin",
        "name": "Darwin",
        "id": "+09:30",
        "source": "9.5"
    },
    {
        "slashName": "Antarctica/DumontDUrville",
        "name": "DumontDUrville",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Asia/Vladivostok",
        "name": "Vladivostok",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Australia/Brisbane",
        "name": "Brisbane",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Australia/Lindeman",
        "name": "Lindeman",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Pacific/Chuuk",
        "name": "Chuuk",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Pacific/Guam",
        "name": "Guam",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Pacific/Port_Moresby",
        "name": "Port Moresby",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Pacific/Saipan",
        "name": "Saipan",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Ust-Nera",
        "name": "Nera",
        "id": "+10:00",
        "source": "10"
    },
    {
        "slashName": "Australia/Adelaide",
        "name": "Adelaide",
        "id": "+10:30",
        "source": "10.5"
    },
    {
        "slashName": "Australia/Broken_Hill",
        "name": "Broken Hill",
        "id": "+10:30",
        "source": "10.5"
    },
    {
        "slashName": "Antarctica/Casey",
        "name": "Casey",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Antarctica/Macquarie",
        "name": "Macquarie",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Asia/Magadan",
        "name": "Magadan",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Asia/Sakhalin",
        "name": "Sakhalin",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Asia/Srednekolymsk",
        "name": "Srednekolymsk",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Australia/Currie",
        "name": "Currie",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Australia/Hobart",
        "name": "Hobart",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Australia/Lord_Howe",
        "name": "Lord Howe",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Australia/Melbourne",
        "name": "Melbourne",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Australia/Sydney",
        "name": "Sydney",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Bougainville",
        "name": "Bougainville",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Efate",
        "name": "Efate",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Guadalcanal",
        "name": "Guadalcanal",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Kosrae",
        "name": "Kosrae",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Norfolk",
        "name": "Norfolk",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Noumea",
        "name": "Noumea",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Pacific/Pohnpei",
        "name": "Pohnpei",
        "id": "+11:00",
        "source": "11"
    },
    {
        "slashName": "Asia/Anadyr",
        "name": "Anadyr",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Asia/Kamchatka",
        "name": "Kamchatka",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Funafuti",
        "name": "Funafuti",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Kwajalein",
        "name": "Kwajalein",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Majuro",
        "name": "Majuro",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Nauru",
        "name": "Nauru",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Tarawa",
        "name": "Tarawa",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Wake",
        "name": "Wake",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Pacific/Wallis",
        "name": "Wallis",
        "id": "+12:00",
        "source": "12"
    },
    {
        "slashName": "Antarctica/McMurdo",
        "name": "McMurdo",
        "id": "+13:00",
        "source": "13"
    },
    {
        "slashName": "Pacific/Auckland",
        "name": "Auckland",
        "id": "+13:00",
        "source": "13"
    },
    {
        "slashName": "Pacific/Enderbury",
        "name": "Enderbury",
        "id": "+13:00",
        "source": "13"
    },
    {
        "slashName": "Pacific/Fakaofo",
        "name": "Fakaofo",
        "id": "+13:00",
        "source": "13"
    },
    {
        "slashName": "Pacific/Fiji",
        "name": "Fiji",
        "id": "+13:00",
        "source": "13"
    },
    {
        "slashName": "Pacific/Chatham",
        "name": "Chatham",
        "id": "+13:45",
        "source": "13.75"
    },
    {
        "slashName": "Pacific/Apia",
        "name": "Apia",
        "id": "+14:00",
        "source": "14"
    },
    {
        "slashName": "Pacific/Kiritimati",
        "name": "Kiritimati",
        "id": "+14:00",
        "source": "14"
    },
    {
        "slashName": "Pacific/Tongatapu",
        "name": "Tongatapu",
        "id": "+14:00",
        "source": "14"
    }
];

export default { allTimezonesData: allTimezonesData }