<template>
  <ion-item class="shopify-wrapper">
    <ion-grid class="shopify-wrapper-grid">
      <ion-row class="first-row">
        <ion-col size-md="12" size-sm="12" class="left-panel">
          <stepProgress :steps="steps" :propActiveStep="propActiveStep" @onSelectStep="onSelectStep"></stepProgress>
        
          <ion-item v-if="propActiveStep == 0" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Log into your EasyParcel account and click on integrations from menu on the left.</div>
              <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwJz3vckLDA"></div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 1" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Click on the 'API Key' button from the API integration section.</div>
              <div><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwOz_86AIDA"> </div>
              <div><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwJz3koAKDA"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 2" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Select the 'Individual API' & fill up your details in the form and then click on the 'Save' button.</div>
              <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwOyxx9sIDA"> </div>
              <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAkP7924gJDA"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 3" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Your details are saved with EasyParcel, now you can copy the 'API Key' by clicking 'Copy' button and paste it in the below field.</div>
              <div><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAkIHA5-EKDA"> </div>

              <div class="slide-header">Select the 'Individual API' & fill up your details in the form and then click on the 'Save' button.</div>
              <ion-item class="margin-next" :class="{error: form.isInvalidEasyParcelApiKey}">
                <InputValidation v-slot="slotProps" :inputVal="form.easyParcelApiKey" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="easyParcelApiKey" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidEasyParcelApiKey'})">
                  <ion-label> API Key </ion-label>
                  <HelpTooltip title="API Key">Enter your EasyParcel account API Key mentioned on EasyParcel's dashboard.</HelpTooltip>
                  <ion-input v-model="form.easyParcelApiKey" @change="slotProps.onChange"></ion-input>
                </InputValidation>
              </ion-item>

            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 4" lines="none">
            <div class="slide-content done">
              <div class="slide-header margin-next ion-text-center">Congratulations! You have successfully connected your EasyParcel account </div>
              <ion-item class="margin-next" lines="none">
                <ion-button v-if="!isStepAtFirst" class="btn-import-products ion-no-padding" @click="$router.push('/dashboard')" fill="solid" color="primary" size="default">
                  Happy Shipping!
                </ion-button>
              </ion-item>
            </div>
          </ion-item>
          <ion-item class="action" v-if="!isStepAtLast">
            <ion-buttons slot="end">
              <ion-button v-if="!isStepAtFirst" class="btn-previous ion-no-padding" @click="actionClick(0)"
                fill="outline">
                Previous Step
                <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="!isStepAtLast" class="btn-next ion-no-padding" @click="actionClick(1)" fill="solid"
                :disabled="isNextButtonDisabled">
                Next Step
                <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

          <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapActions } from "vuex";
import stepProgress from "./step-progress.vue"
import HelpTooltip from "../HelpTooltip.vue";
import InputValidation from "../InputValidation.vue";


const steps = [
  {
    title: 'Go to integrations',
    code: 'integration'
  },
  {
    title: 'API integration',
    code: 'api_integration'
  },
  {
    title: 'Individual API',
    code: 'individual_api'
  },
  {
    title: 'Enter API key',
    code: 'api_key'
  },
  {
    title: 'Done',
    code: 'done',
    disabled: true
  }
];

export default {
  components: { stepProgress, HelpTooltip, InputValidation },
  data() {
    return {
      steps,
      propActiveStep: 0,
      selectedStep: steps[0],
      chevronForwardOutline,
      chevronBackOutline,
      helpCircleOutline,
      form: {
        privateKey: "",
        easyParcelApiKey: "",
        isInvalidEasyParcelApiKey: "",
        invalidInputs: []
      },
      loading: false
    }
  },
  computed: {
    isStepAtFirst() {
      return this.propActiveStep === 0;
    },
    isStepAtLast() {
      return this.steps.length === this.propActiveStep + 1;
    },
    isNextButtonDisabled() {
      return this.propActiveStep == 3 && this.isFormInvalid;
    },
    isFormInvalid() { // used this compute variable to keep next button enabled/disabled
      return this.form.invalidInputs.length;
    }
  },
  watch: {
    // propActiveStep(value) {
    //   if (value === 3) {
    //     this.steps[3].disabled = false;
    //   } else {
    //     this.steps[3].disabled = true;
    //   }
    // }
  },
  methods: {
    ...mapActions({
      updateIntegration: "integration/updateIntegration"
    }),
    validationHandler({ isInvalid, onChange, inputName }) {
      if (isInvalid) {
        if (!this.form.invalidInputs.includes(inputName)) {
          this.form.invalidInputs.push(inputName);
        }
      } else {
        const index = this.form.invalidInputs.indexOf(inputName);
        if (index > -1) {
          this.form.invalidInputs.splice(index, 1);
        }
      }
      if (onChange) {
        this.form[inputName] = isInvalid;
      }
    },
    onSelectStep({ step, index }) {
      this.propActiveStep = index;
      this.selectedStep = step;
    },
    async actionClick(forward) {
      if (this.propActiveStep == 3 && forward) {
        const result = await this.connect();
        if (!result) {
          return;
        }
      }
      if (forward && this.propActiveStep < this.steps.length) {
        this.propActiveStep++;
      } else if (this.propActiveStep > 0) {
        this.propActiveStep--;
      }
      this.selectedStep = this.steps[this.propActiveStep];
    },
    async connect() {
      this.loading = true;
      const message = {
        success: () => this.updateToast({ open: true, message: "Connected to EasyParcel successfully.", type: "success" }),
        error: (message) => this.updateToast({ open: true, message: message || "EasyParcel connection failed.", type: "danger" })
      }
      try {
        const reqBody = { access_token: this.form.easyParcelApiKey };
        const response = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "create-easyparcel", reqBody);
        if (response.data.success) {
          const easyParcelDataToUpdate = {
            integrationName: 'easyparcel',
            dataToUpdate: {
              easyship: [{ token: "sdasdf" }],
              is_active: true
            },
            storageName: 'jmpr_easyParcel'
          };
          this.updateIntegration(easyParcelDataToUpdate);
          message.success();
          this.loading = false;
          return true;
        }
        message.error(response.data.errorMessage);
      } catch (error) {
        message.error();
      }
      this.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
.shopify-wrapper-grid {
  height: 100%;
}

.shopify-wrapper-grid .first-row {
  height: 100%;
}

.btn-next {
  width: 160px;
  padding-right: 0;
}

.btn-next button {
  padding-right: 0;
}

.btn-previous {
  width: 160px;
}

.slide-content {
  padding-top: 10px;
}

.margin-next {
  margin-bottom: 10px;
}

.unordered-list ul {
  padding-left: 15px;
}

.action ion-buttons {
  padding: 20px 0;
}

.btn-import-products {
  width: 160px;
}

.slide-content.done {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
}

.slide-content.done .sub-text {
  font-size: 14px;
  color: var(--ion-color-medium);
}

ion-item.error {
  --ion-item-border-color: var(--ion-color-danger);
  --border-color: var(--ion-color-danger);
}

:deep .input-validator-wrapper {
  width: 100%;
}
</style>