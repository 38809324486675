var defaultAdminUserRolesPermission = {
    "live_chat_channels": {
        "facebook": true,
        "whatsapp": true,
        "instagram": true,
        "line": true,
        "viber": true,
        "telegram": true,
        "sms": true,
        "googlebusinessmsg": true,
        "imessage": true,
        "twitter": true,
        "youtube": true,
        "adlingo": true,
        "webbot": true
    },
    "live_chat": {
        "edit_customer_details": true,
        "edit_order_price": true
    },
    "integrations": {
        "facebook": true,
        "youtube": true,
        "line": true,
        "instagram": true,
        "twitter": true,
        "imessage": true,
        "whatsapp": true,
        "viber": true,
        "telegram": true,
        "sms": true,
        "googlebusinessmsg": true,
        //"pinterest": true,
        "zapier": true,
        "calendar": true,
        "shopify": true,
        "printful": true,
        "amazon": true,
        "bigcommerce": true,
        "woocommerce": true,
        "magento": true,
        "square": true,
        "easyship": true,
        "easyparcel": true,
        "grab": true,
        "inpost": true
    },
    "growth_tools": {
        "fb_pixel": true,
        "web_bot": true
    },
    "orders": {
        "manage": true,
        "view": true,
        "orders_export": true
    },
    "products": {
        "create": true,
        "view": true
    },
    "collections": {
        "create": true,
        "view": true
    },
    "bundles": {
        "create": true,
        "view": true
    },
    "events": {
        "create": true,
        "view": true
    },
    "promote": {
        "create": true,
        "view": true
    },
    "restaurant": {
        "menu": true,
        "settings": true
    },
    "roles_management": true,
    "business_details": true,
    "payment_methods": true,
    "analytics": true,
    "team_analytics": true,
    "discounts": true,
    "loyalty": true,
    "shipping": true,
    "policies": true,
    "custom_domain": true,
    "personalisation": true,
    "referral": true,
    //"quick_reply": true,
    "billing": true,
    "reservations": true,
    "broadcast": true,
    "cart_abandonment": true,
    "ai_setup": true,
    "shop_faq": true,
    "popup_builder": true,
    "bot_builder": true,
    "manage_collect_locations": true,
    "customers": true,
    "page_inbox": true,
    "products_bulk_import": true,
    "bulk_update_order_shipping_link": true,
    "agent_routing": true,
    "checkout_direct": true
};

var dictionary = {
    "live_chat_channels": {
        "label": "Live chat",
        "facebook": { "label": "Facebook", "selected": false },
        "whatsapp": { "label": "WhatsApp", "selected": false },
        "instagram": { "label": "Instagram", "selected": false },
        "line": { "label": "LINE", "selected": false },
        "viber": { "label": "Viber", "selected": false },
        "telegram": { "label": "Telegram", "selected": false },
        "sms": { "label": "SMS", "selected": false },
        "googlebusinessmsg": { "label": "Google Business Messages", "selected": false },
        "imessage": { "label": "iMessage", "selected": false },
        "twitter": { "label": "Twitter", "selected": false },
        "youtube": { "label": "YouTube", "selected": false },
        "adlingo": { "label": "AdLingo", "selected": false },
        "webbot": { "label": "Webbot", "selected": false }
    },
    "live_chat": {
        "label": "Live chat (additional permissions)",
        "edit_customer_details": { "label": "Edit customer details", "selected": false },
        "edit_order_price": { "label": "Edit order price", "selected": false }
    },
    "integrations": {
        "label": "Integrations",
        "facebook": { "label": "Facebook", "selected": false },
        "youtube": { "label": "YouTube", "selected": false },
        "line": { "label": "LINE", "selected": false },
        "instagram": { "label": "Instagram", "selected": false },
        "twitter": { "label": "Twitter", "selected": false },
        "imessage": { "label": "iMessage", "selected": false },
        "whatsapp": { "label": "WhatsApp", "selected": false },
        "viber": { "label": "Viber", "selected": false },
        "telegram": { "label": "Telegram", "selected": false },
        "sms": { "label": "SMS", "selected": false },
        "googlebusinessmsg": { "label": "Google Business Messages", "selected": false },
        //"pinterest": { "label": "Pinterest", "selected": false },
        "zapier": { "label": "Zapier", "selected": false },
        "calendar": { "label": "Calendar", "selected": false },
        "shopify": { "label": "Shopify", "selected": false },
        "printful": { "label": "Printful", "selected": false },
        "amazon": { "label": "Amazon", "selected": false },
        "bigcommerce": { "label": "BigCommerce", "selected": false },
        "woocommerce": { "label": "WooCommerce", "selected": false },
        "magento": { "label": "Magento", "selected": false },
        "square": { "label": "Square", "selected": false },
        "easyship": { "label": "Easyship", "selected": false },
        "easyparcel": { "label": "EasyParcel", "selected": false },
        "grab": { "label": "Grab", "selected": false },
        "inpost": { "label": "InPost", "selected": false },
    },
    "growth_tools": {
        "label": "Growth tools",
        "fb_pixel": { "label": "Facebook Pixel", "selected": false },
        "web_bot": { "label": "Attentive widget", "selected": false },
    },
    "orders": {
        "label": "Orders",
        "manage": { "label": "Manage", "selected": false },
        "view": { "label": "View", "selected": false },
        "orders_export": { "label": "Download orders CSV/Excel", "selected": false }
    },
    "products": {
        "label": "Products",
        "create": { "label": "Create", "selected": false },
        "view": { "label": "View", "selected": false }
    },
    "collections": {
        "label": "Collections",
        "create": { "label": "Create", "selected": false },
        "view": { "label": "View", "selected": false }
    },
    "bundles": {
        "label": "Sets",
        "create": { "label": "Create", "selected": false },
        "view": { "label": "View", "selected": false }
    },
    "events": {
        "label": "Events",
        "create": { "label": "Create", "selected": false },
        "view": { "label": "View", "selected": false }
    },
    "promote": {
        "label": "Promote",
        "create": { "label": "Create", "selected": false },
        "view": { "label": "View", "selected": false }
    },
    "restaurant": {
        "label": "F&B",
        "menu": { "label": "Menu", "selected": false },
        "settings": { "label": "Settings", "selected": false }
    },
    "roles_management": { "label": "Role management", "selected": false },
    "business_details": { "label": "Business details", "selected": false },
    "payment_methods": { "label": "Payment methods", "selected": false },
    "analytics": { "label": "Analytics", "selected": false },
    "team_analytics": { "label": "Team analytics", "selected": false },
    "discounts": { "label": "Discounts", "selected": false },
    "loyalty": { "label": "Loyalty", "selected": false },
    "shipping": { "label": "Tax & shipping", "selected": false },
    "policies": { "label": "Policies", "selected": false },
    "custom_domain": { "label": "Custom domain", "selected": false },
    "personalisation": { "label": "Personalization", "selected": false },
    "referral": { "label": "Referral", "selected": false },
    //"quick_reply": { "label": "Quick reply", "selected": false },
    "billing": { "label": "Billing", "selected": false },
    "reservations": { "label": "Reservations", "selected": false },
    "broadcast": { "label": "Broadcast", "selected": false },
    "cart_abandonment": { "label": "Cart recovery", "selected": false },
    "ai_setup": { "label": "Automations", "selected": false },
    "shop_faq": { "label": "Shop FAQ", "selected": false },
    "popup_builder": { "label": "Popup builder", "selected": false },
    "bot_builder": { "label": "Bot builder", "selected": false },
    "manage_collect_locations": { "label": "Manage click & collect locations", "selected": false },
    "customers": { "label": "Customers (CRM)", "selected": false },
    "page_inbox": { "label": "Live chat", "selected": false },
    "products_bulk_import": { "label": "Products bulk import", "selected": false },
    "bulk_update_order_shipping_link": { "label": "Bulk update order shipping link", "selected": false },
    "agent_routing": { "label": "Agent routing", "selected": false },
    "checkout_direct": { "label": "Checkout direct (create order)", "selected": false }
}

export default { defaultAdminUserRolesPermission: defaultAdminUserRolesPermission, dictionary: dictionary }