<template>
  <div id="printOrderReceiptArea">
    <div class="row singleFnbOrderDetailsInModal">
      <div class="col-sm-6">
        <h5>Order details:</h5>
        <p>
          <b>Ref. order ID:</b>
          {{ order.orderkeyid }}
        </p>

        <p v-if="moment(order.created).isSameOrAfter(moment()) || order.variables.regalo" style="padding-top: 5px; padding-bottom: 10px;">
          <span class="orderPickupStatusTag" style="padding: 0px; margin: 0px; margin-right: 5px !important;" v-if="moment(order.created).isSameOrAfter(moment())">
            <span>Advance order</span>
          </span>

          <span class="orderPickupStatusTag" style="padding: 0px; margin: 0px;" v-if="order.variables.regalo">
            <span>ReGALO {{ renderFnbOrderType(order.takeaway,order.variables) }}</span>
          </span>
        </p>

        <!-- <p v-if="!moment(order.created).isSameOrAfter(moment())"> -->
        <p>
          <b>Order date & time:</b>
          {{ moment(order.order_updated_on).format("DD-MMM hh:mm a") }}
        </p>

        <p>
          <b>Payment via:</b>
          <span v-if="renderPaymentModeName(order.gateway) === 'COD'">{{ renderPaymentModeName(order.gateway) }}</span>
          <span v-if="renderPaymentModeName(order.gateway) !== 'COD' && user.enterprise_Name !== 'greenwich_ph'">{{ renderPaymentModeName(order.gateway) }}</span>
          <span v-if="renderPaymentModeName(order.gateway) !== 'COD' && user.enterprise_Name === 'greenwich_ph'">Online Payment</span>
        </p>
        
        <p>
          <b>Type:</b>
          <span v-if="order.variables.fromgrab">
            GrabExpress Pick-up

            <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAoMa8mdIIDA" v-if="order.variables.fromgrab" style="height: 16px; width: auto; display: inline-block; margin-left: 5px; vertical-align: top; margin-top: 3px;" />
          </span>

          <span v-if="order.variables.fromlalamove">
            Lalamove Pick-up

            <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4I6KhrAIDA" v-if="order.variables.fromlalamove" style="height: 16px; width: auto; display: inline-block; margin-left: 5px; vertical-align: top; margin-top: 3px;" />
          </span>

          <span v-if="!order.variables.fromgrab && !order.variables.fromlalamove">
            <span v-if="!order.variables.carplate || order.variables.carplate == '' || order.variables.carplate == null || order.variables.carplate == 'None'">{{ renderFnbOrderType(order.takeaway, order.variables) }}</span>

            <span v-if="order.variables.carplate && order.variables.carplate != '' && order.variables.carplate != null && order.variables.carplate != 'None'">Curbside pick-up</span>

            <span v-if="renderFnbOrderType(order.takeaway,order.variables) == 'Delivery'">
              <img src="https://jumper.ai/web-s/resources/images/fnb-delivery-icon.png" style="height: 25px; width: auto; display: inline-block; margin-left: 5px; vertical-align: middle;">
            </span>
            <span v-if="renderFnbOrderType(order.takeaway,order.variables) == 'Pickup' && (!order.variables.carplate || order.variables.carplate == '' || order.variables.carplate == null || order.variables.carplate == 'None')">
              <img src="https://jumper.ai/web-s/resources/images/fnb-pickup-icon.png" style="height: 25px; width: auto; display: inline-block; margin-left: 5px; vertical-align: middle;">
            </span>
            <span v-if="renderFnbOrderType(order.takeaway,order.variables) == 'Dine-in'">
              <img src="https://jumper.ai/web-s/resources/images/fnb-dinein-icon.png" style="height: 25px; width: auto; display: inline-block; margin-left: 5px; vertical-align: middle;">
            </span>
            <span v-if="renderFnbOrderType(order.takeaway,order.variables) == 'Take-out'">
              <img src="https://jumper.ai/web-s/resources/images/fnb-takeout-icon.png" style="height: 25px; width: auto; display: inline-block; margin-left: 5px; vertical-align: middle;">
            </span>

            <span v-if="order.variables.carplate && order.variables.carplate != '' && order.variables.carplate != null && order.variables.carplate != 'None'">
              <img src="https://jumper.ai/web-s/resources/images/fnb-curbside-car-icon.png" style="height: 25px; width: auto; display: inline-block; margin-left: 5px; vertical-align: middle;">
            </span>
          </span>
        </p>

        <p v-if="order.variables.tablenumber && order.variables.tablenumber != '' && order.variables.tablenumber != null && order.variables.tablenumber != 'None' && order.variables.tablenumber != '0' && order.variables.tablenumber != 0">
          <b>Table no.:</b>
          <span>{{ order.variables.tablenumber }}</span>
        </p>

        <p v-if="order.variables.carplate && order.variables.carplate != '' && order.variables.carplate != null && order.variables.carplate != 'None'">
          <b>Curbside details:</b>
          <span>{{ order.variables.carplate }}</span>
        </p>

        <p v-if="order.pickuptime != '' && order.pickuptime != 'None' && order.pickuptime != null">
          <b v-if="user.enterprise_Name !== 'walls_my'">Pickup time:</b>
          <b v-else>Delivery date:</b>
          
          <span v-if="user.enterprise_Name === 'walls_my'">
            {{ moment(order.pickuptime).format("DD-MMM") }}
          </span>
          <span v-else>
            {{ moment(order.pickuptime).format("DD-MMM hh:mm a") }}
          </span>
        </p>

        <p v-if="order.variables.deliverydate != '' && order.variables.deliverydate != 'None' && order.variables.deliverydate != null">
          <b>Delivery date & time:</b>
          {{ moment(order.variables.deliverydate).format("DD-MMM-YY") }}, {{ moment(order.variables.deliverytime).format('hh:mm a') }}
        </p>

        <p v-if="order.variables.eventtype && order.variables.eventtype != '' && order.variables.eventtype != 'None' && order.variables.eventtype != null">
          <b>Event type:</b>
          {{ order.variables.eventtype }}
        </p>
        
        <!-- outlet name start -->
        <p v-if="squareConnected == true && order.address.name && order.address.name != ''">
          <b>Outlet name:</b>
          {{ order.address.name }}
        </p>

        <p v-if="squareConnected != true && order.location.name">
          <b>Outlet name:</b>
          <span v-if="order.location.name != ''">{{ order.location.name }}</span>
          <span v-else>{{ order.location.addressLine1 }}</span>
        </p>
        <!-- outlet name end -->

        <p class="orderPickupStatusTag" style="padding: 0px; margin: 0px; margin: 10px 0px !important;" v-if="checkIfPaymentModeIsManualOrNotForSingleOrder(order.gateway) && !order.orderready">
          <span class="red">Payment pending</span>
        </p>
      </div>
      <div class="col-sm-6">
        <h5>Customer details:</h5>

        <p>
          <b>Name:</b>
          <span v-if="order.buyer.name && order.buyer.name != '' && order.buyer.name != 'None' && order.buyer.name != null">{{ order.buyer.name }}</span>
          <span v-else>Anonymous</span>
        </p>
        <p>
          <b>Email:</b>
          {{ order.buyer.email }}
        </p>
        <p>
          <b>Mobile no.:</b>
          <span v-if="order.buyer.mobileNumber != '' && order.buyer.mobileNumber != 'None' && order.buyer.mobileNumber != null">{{ renderMobileCountryCodeAccordingToMobileNo(order.buyer.mobilecountrycode,order.buyer.mobileNumber) }} {{ order.buyer.mobileNumber }}</span>
          <span v-else>Not provided</span>
        </p>

        <p v-if="renderFnbOrderType(order.takeaway,order.variables) == 'Delivery'">
          <b>Address:</b>
          {{ order.address.addressLine1 }},
          <span v-if="order.address.addressLine2 != '' && order.address.addressLine2 != 'None' && order.address.addressLine2 != null">{{ order.address.addressLine2 }},</span>
          {{ order.address.pincode }},
          {{ order.address.city }},
          {{ order.address.state }},
          {{ order.address.country }}
        </p>

        <p v-if="order.variables.documentdiscount == true && order.variables.documentname && order.variables.documentname != '' && order.variables.documentname != null && order.variables.documentname != 'None' && order.variables.discount_pwd_sc">
          <b>
            <span>
              <span style="text-transform: uppercase;">
                {{ order.variables.documenttype }}
              </span>
              name:
            </span>
          </b>
          {{ order.variables.documentname }}
        </p>
        <p v-if="order.variables.documentdiscount && order.variables.discount_pwd_sc">
          <b>
            <span>
              <span style="text-transform: uppercase;">
                {{ order.variables.documenttype }}
              </span>
              ID:
            </span>
          </b>
          {{ order.variables.documentid }}
        </p>
      </div>
    </div>

    <div class="row singleFnbOrderDetailsInModal" v-if="order.variables.regalo && (order.variables.regalo == true || order.variables.regalo == 'true')">
      <div class="col-sm-12">
        <div class="infoBanner lighterBlueFullBordered">
          <div class="panel panel-default">
            <div class="panel-body" style="position: relative;">
              <div class="addNewProductWithSidebarContainer">
                <div class="leftSide fullWidth">
                  <div class="row">
                    <div class="col-sm-12 col-md-8">
                      <h5 style="margin-top: 0px; padding-top: 0px;">ReGALO details:</h5>
                      <p>
                        <b>Sender name:</b>
                        {{ order.variables.sender_name }}
                      </p>
                      <p>
                        <b>Sender contact no.:</b>
                        {{ order.variables.sender_contact }}
                      </p>
                      <p v-if="order.variables.recipient_name && order.variables.recipient_name != '' && order.variables.recipient_name != 'None' && order.variables.recipient_name != null">
                        <b>Recipient name:</b>
                        {{ order.variables.recipient_name }}
                      </p>
                      <p v-if="order.variables.recipient_contact && order.variables.recipient_contact != '' && order.variables.recipient_contact != 'None' && order.variables.recipient_contact != null">
                        <b>Recipient contact no.:</b>
                        {{ order.variables.recipient_contact }}
                      </p>
                      <p v-if="order.variables.recipient_email && order.variables.recipient_email != '' && order.variables.recipient_email != 'None' && order.variables.recipient_email != null">
                        <b>Recipient e-mail:</b>
                        {{ order.variables.recipient_email }}
                      </p>
                      <p>
                        <b>{{ renderFnbOrderType(order.takeaway,order.variables) }} date & time:</b>
                        {{ order.variables.regalo_date }} {{ order.variables.regalo_time }}
                      </p>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <p v-if="order.variables.cardtemplate && order.variables.cardtemplate != '' && order.variables.cardtemplate != 'None' && order.variables.cardtemplate != null">
                        <b>Selected card template:</b>
                        <img :src="order.variables.cardtemplate" style="max-width: 100%; max-height: 140px; width: auto; height: auto; margin-top: 5px; margin-bottom: 5px;" />
                      </p>
                      <p v-if="order.variables.sender_message && order.variables.cardtemplate != '' && order.variables.cardtemplate != 'None' && order.variables.cardtemplate != null">
                        <b>Message for card template:</b>
                        <i style="color: #000;">
                          <span>{{ order.variables.sender_message }}</span>
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row singleFnbOrderDetailsInModal noMarginTB">
      <div class="col-sm-12">
        <h5>Ordered items:</h5>
      </div>
        
      <div class="col-sm-12">
        <table class="table noMarginBottom" style="border-top: 1px #eee solid; border-bottom: 1px #eee solid;">
          <thead>
            <tr>
              <th>Image</th>
              <th>Item details</th>
              <th class="text-right">Quantity</th>
              <th class="text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, iIndex) in order.cart" :key="iIndex">
              <td>
                <img :src="item.image ? item.image : 'https://jumper.ai/web-s/resources/images/add-product.png'" class="img-responsive productsListImage" />
              </td>
              <td class="orderInfoTableTd">
                <h5><b>{{ item.name }}</b></h5>
                <p v-html="item.subtitle.replace(/,/g, '<br/>')"></p>

                <p v-if="item.notes != '' && item.notes != null">
                  <span v-if="user.enterprise_Name != 'redribbon_manila'">
                    <b>Note: </b>[[item.notes]]
                  </span>
                  <span v-else>
                    <b>Lines to be written on the cake:</b>
                    <br />
                    <span v-for="(noteLine, nIndex) in renderRedRibbonDedicationCakeNotesPerLine(item.notes)" :key="nIndex" style="display: block; line-height: 2;">
                      <b>Line {{ nIndex+1 }}: </b>{{ noteLine }}
                    </span>
                  </span>
                </p>
              </td>
              <td class="orderInfoTableTd text-right"><p>{{ item.quantity }}</p></td>
              <td class="orderInfoTableTd text-right"><p>{{ user.currency }} {{ item.cost }}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
        
      <div class="col-sm-12">
        <table class="table noMarginBottom slimTableNoBorder">
          <tbody>
            <tr>
              <td class="orderInfoTableTd text-right">
                <p><b>Subtotal:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>{{ user.currency }} {{ order.subtotal }}</p>
              </td>
            </tr>

            <tr v-if="order.variables.discount_pwd_sc">
              <td class="orderInfoTableTd text-right">
                <p>
                  <b>
                    <span style="text-transform: uppercase;">
                      {{ order.variables.documenttype }}
                    </span>
                    discount:
                  </b>
                </p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} {{ order.variables.discount_pwd_sc }}</p>
              </td>
            </tr>

            <tr v-if="order.variables.random_discount && order.variables.random_discount != '' && order.variables.random_discount != null && order.variables.random_discount != 'None' && order.variables.random_discount != '0' && order.variables.random_discount != '0.00' && order.variables.random_discount != 0">
              <td class="orderInfoTableTd text-right">
                <p><b>Discount:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} {{ order.variables.random_discount }}</p>
              </td>
            </tr>

            <tr v-if="order.discount && order.discount != '' && order.discount != 'None' && order.discount != null && order.discount != '0.00' && order.discount != '0' && order.discount != 0.00 && order.discount != 0">
              <td class="orderInfoTableTd text-right">
                <p><b>Discount:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} {{ order.discount }}</p>
              </td>
            </tr>

            <tr v-if="order['reward discount'] && order['reward discount'] != '' && order['reward discount'] != 'None' && order['reward discount'] != null && order['reward discount'] != '0.00' && order['reward discount'] != '0' && order['reward discount'] != 0.00 && order['reward discount'] != 0">
              <td class="orderInfoTableTd text-right">
                <p><b>Reward:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} <span>{{ order['reward discount'] }}</span></p>
              </td>
            </tr>

            <tr v-if="order['discount'] != order['total discount'] && order['additional discount'] && order['additional discount'] != '' && order['additional discount'] != 'None' && order['additional discount'] != null && order['additional discount'] != '0.00' && order['additional discount'] != '0' && order['additional discount'] != 0.00 && order['additional discount'] != 0">
              <td class="orderInfoTableTd text-right">
                <p><b>Additional discount:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} <span>{{ order['additional discount'] }}</span></p>
              </td>
            </tr>

            <tr v-if="order['discount'] != order['total discount'] && order['total discount'] && order['total discount'] != '' && order['total discount'] != 'None' && order['total discount'] != null && order['total discount'] != '0.00' && order['total discount'] != '0' && order['total discount'] != 0.00 && order['total discount'] != 0">
              <td class="orderInfoTableTd text-right">
                <p><b>Total discount:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} <span>{{ order['total discount'] }}</span></p>
              </td>
            </tr>

            <tr v-if="order.variables.delivery_fee">
              <td class="orderInfoTableTd text-right">
                <p><b>Delivery fees:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>{{ user.currency }} {{ order.variables.delivery_fee }}</p>
              </td>
            </tr>

            <tr v-if="order.variables.tax_exempt">
              <td class="orderInfoTableTd text-right">
                <p><b>VAT exemption:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>- {{ user.currency }} {{ order.variables.tax_exempt }}</p>
              </td>
            </tr>

            <tr v-if="redRibbon_ph_manila != true">
              <td class="orderInfoTableTd text-right">
                <p><b>Tax:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>{{ user.currency }} {{ order.tax }}</p>
              </td>
            </tr>

            <tr v-if="squareConnected == true">
              <td class="orderInfoTableTd text-right">
                <p><b>Tip:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p>{{ user.currency }} {{ order.tipamount }}</p>
              </td>
            </tr>

            <tr>
              <td class="orderInfoTableTd text-right">
                <p><b>Total:</b></p>
              </td>
              <td class="orderInfoTableTd text-right" style="width: 150px;">
                <p><b>{{ user.currency }} {{ order.total }}</b></p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="infoBanner" v-if="user.enterprise_Name == 'greenwich_ph' && order.variables.contactnotes && order.variables.contactnotes != 'None' && order.variables.contactnotes != '' && order.variables.contactnotes != null && order.variables.contactnotes != true">
          <div class="panel panel-default" style="margin-bottom: px; margin-top: 15px;">
            <div class="panel-body">
              <div class="addNewProductWithSidebarContainer">
                <div class="leftSide fullWidth" style="margin-bottom: 0px;">
                  <p class="description">
                    <b>Special instructions:</b>
                    {{ order.variables.contactnotes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="infoBanner" v-if="order.notes && order.notes != 'None' && order.notes != '' && order.notes != null">
          <div class="panel panel-default" style="margin-bottom: px; margin-top: 15px;">
            <div class="panel-body">
              <div class="addNewProductWithSidebarContainer">
                <div class="leftSide fullWidth" style="margin-bottom: 0px;">
                  <p class="description">
                    <span v-if="user.enterprise_Name == 'greenwich_ph'"><b>Need utensils:</b></span>
                    <span v-else><b>Special instructions:</b></span>
                    {{ order.notes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="infoBanner success" v-if="redRibbon_ph_manila && order.variables.giveback && order.variables.giveback != null && order.variables.giveback != '' && order.variables.giveback != 'None'">
          <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
            <div class="panel-body">
              <div class="addNewProductWithSidebarContainer">
                <div class="leftSide fullWidth" style="margin-bottom: 0px;">
                  <p class="description">
                    Please carry
                    <b>change for {{ user.currency }} {{ order.variables.changefor }} (Change = {{ user.currency }} {{ order.variables.giveback }})</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['order'],
  methods: {
    renderFnbOrderType(takeaway, variables) {
      let delivery = false;
      for(var key in variables) {
        if(key == 'state') {
          if(variables[key] == 'delivery') delivery = true;
          else if(variables[key] == 'pickup') return "Pickup";
          else if(variables[key] == 'dinein') return "Dine-in";
          else if(variables[key] == 'takeout') return "Take-out";
        }
      }
      if(delivery) return "Delivery";
      else {
        if(takeaway == true) return "Pickup";
        else return "Dine-in";
      }
    },
    renderRedRibbonDedicationCakeNotesPerLine(notes) {
      if (typeof notes != 'undefined') {
        notes = notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
    
        if (notes.indexOf('<br>') > -1) {
          return notes.split('<br>');
        }
      }
    }
  }
}
</script>