<template>
  <ion-item class="shopify-wrapper" lines="none">
    <ion-card>
      <ion-card-header>
        <ion-card-title>Connectiing Easyship...</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <img src="https://jumperdevnew.appspot.com/landing/resources/images/easyship-logo.svg" style="height: 30px; width: auto;">
        <div>Please click the button below to check the connection once you are done with the authentication.</div>
        <ion-buttons>
          <ion-button class="btn-previous ion-no-padding" @click="checkConnection" fill="outline">
            Check connection
          </ion-button>
        </ion-buttons>
      </ion-card-content>
    </ion-card>
    <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
  </ion-item>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
  },
  mounted() {
    this.redirectToConnectionUrl();
  },
  methods: {
    ...mapActions({
      updateIntegration: "integration/updateIntegration"
    }),
    checkConnection() {
      location.href = "https://jumperdevnew.appspot.com/s/?check_connection=easyship";
    },
    async redirectToConnectionUrl() {
      this.loading = true;
      const message = {
        error: (message) => this.updateToast({ open: true, message: message || "Easyship connection failed.", type: "danger" })
      }
      try {
        const response = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "connect-easyship");
        this.loading = false;
        if (response.data.success) {
          window.open(response.data.redirect_url, '_blank');
          return true;
        }
        message.error(response.data.errorMessage);
      } catch (error) {
        message.error();
      }
      this.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
ion-card {
  width: 100%;
}
ion-card-title {
  text-align: center;
}
ion-card-content {
  text-align: center;
  padding-top: 10px;
}
ion-card-content div {
  text-align: center;
}
ion-buttons {
  padding-top: 10px;
  justify-content: center;
}
</style>