<template>
  <master-layout pageTitle="Segments">
    <ion-content v-if="!editMode">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="loading" cssClass="my-custom-class" message="Loading..." ></ion-loading>
      <!-- desktop -->
      <div v-else>
        <p class="segment-Info">You can create the segments of your customers using different set of filters and use it in CRM and Broadcast</p>
        <ion-toolbar class="chat-toolbar" color="light">
          <ion-item class="searchBar" lines="none">
            <ion-input v-model="segmentFilter" placeholder="Enter segment name"></ion-input>
            <ion-buttons slot="end">
              <ion-button>
                <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-toolbar>
        <section class="table-responsive-container">
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col>
                Name
              </ion-col>
              <ion-col>
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container">
            <ion-grid v-for="(segment, sIndex) in segmentsToShow" :key="sIndex" class="table-row ion-no-padding">
              <ion-row else class="ion-justify-content-between">
                <ion-col>
                  <ion-text class="displayBlock orderDesc">
                    {{ segment.name }}
                  </ion-text>
                </ion-col>
                <!-- Action buttons -->
                <ion-col>
                  <span class="tooltip_container">
                    <ion-button @click="() => { editMode = true; segmentIndexToEdit = sIndex;}" fill="clear" size="default" color="dark">
                      <ion-icon :icon="pencilOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left" style="width: 80px">edit segments</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button  @click="() => { segmentIndexToEdit = segment.id; deleteSegmentMode = true; }" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashBinOutline" slot="start"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left" style="width: 80px"> Delete segments </span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </section>
        <div class="text-center" v-if="allSegments.length == 0" style="width: 100%; padding: 15px;">
          <img
            src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
            style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
          />
          <h5>No segments created yet! 😟</h5>
        </div>
        <div class="text-center" v-else-if="segmentFilter && segmentFilter.length && segmentsToShow.length === 0" style="width: 100%; padding: 15px;">
            <img
              src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
              style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
            />
            <h5> No segments matching this filter! 😟 <a @click="() => (segmentFilter = undefined)">Clear filter</a> </h5>
          </div>
      </div>   
      <button
        type="button"
        class="submitBtn addNewBttn"
        id="addBtn"
        @click="onClickAddNewSegment"
      >
        <ion-icon
          class="addIcon"
          slot="icon-only"
          :icon="addCircleOutline"
        ></ion-icon>
      </button>
      <ion-alert
        v-if="deleteSegmentMode"
        :is-open="deleteSegmentMode"
        header="Are you sure?"
        :message="'you want to delete this segment?'"
        :buttons="[{
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            deleteSegmentMode = false;
            segmentIndexToEdit = -1;
          },
        },
        {
          text: 'Yes',
          handler: () => {
            deleteSegment()
          },
        }]"
        @didDismiss="() => {deleteSegmentMode = false; segmentIndexToEdit = -1;}"
      >
      </ion-alert>      
    </ion-content>
    <ion-content v-else> 
      <ion-loading v-if="loading" cssClass="my-custom-class" message="Loading..." ></ion-loading>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-icon @click="() => {editMode = false; segmentIndexToEdit = -1; }" size="large" :icon="arrowBackOutline"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{segmentIndexToEdit > -1 ? 'Edit' : 'Create new'}} Segment</h2>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="saveSegment" class="bttn-bgnd">Save</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col size-xl="8" size-lg="8" size-sm="12" size-xs="12">
            <ion-accordion-group value="addSegments">
              <ion-accordion class="model-sections" value="addSegments">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Basic
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding">
                  <ion-grid class="ion-no-padding gridMargin">
                    <ion-row class="rowMargin">
                      <ion-col class="ion-no-padding">
                        <h2 class="orderSubtitle ion-no-padding ion-no-margin" style="margin: 0px 0px 20px 0px">
                          Name
                        </h2>
                        <ion-input
                          type="text"
                          placeholder="Enter Name"
                          v-model="editSegment.name"
                          class="fullBordered input_custom_style"
                        ></ion-input>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-list>
              </ion-accordion>
              <ion-accordion class="model-sections" value="addSegments">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Targeting Conditions
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding" style="border-top: 1px solid #EDEDED">
                  <div class="segment-filter-section">
                    <CustomerSegments ref="CustomerSegments" :editSegment="editSegment"></CustomerSegments>
                    <SegmentsTargetingCount ref="SegmentsTargetingCount" @callFetchTargetedCustomerCount="callFetchTargetedCustomerCount"></SegmentsTargetingCount>
                  </div>
                </ion-list>
              </ion-accordion>
            </ion-accordion-group>
          </ion-col>
        </ion-row>
      </ion-grid>


    </ion-content>

  </master-layout>
</template>

<script>
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, pencilOutline, arrowBackOutline, searchOutline, refreshOutline } from "ionicons/icons";
import { IonAccordion, IonAccordionGroup } from "@ionic/vue";
import CustomerSegments from "../../components/customer-segments.vue";
import SegmentsTargetingCount from "../../components/SegmentsTargetingCount.vue";

const EDIT_SEGMENT = {
  id: undefined,
  name: "Jumper Segment #",
  targeting_conditions: [],
  targeting_count: 0
};

export default {
  components: {
    IonAccordionGroup,
    IonAccordion,
    CustomerSegments,
    SegmentsTargetingCount
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      arrowBackOutline,
      pencilOutline,
      searchOutline,
      loading: false,
      allSegments: [],
      segmentIndexToEdit: -1,
      segmentFilter: undefined,
      editMode: false,
      deleteSegmentMode: false,
      editSegment: { ...EDIT_SEGMENT },
      refreshOutline,
    };
  },
  computed: {
    segmentsToShow() {
      return this.segmentFilter && this.segmentFilter.length ? this.allSegments.filter((e) => e.name.match(new RegExp(this.segmentFilter, "gi")) ): this.allSegments;
    },
  },
  async ionViewWillEnter() {
    await this.fetchSegments();
  },
  watch: {
    segmentIndexToEdit() {
      const segmentToEdit = this.segmentsToShow[this.segmentIndexToEdit];
      if (this.segmentIndexToEdit > -1 && segmentToEdit) {
        this.editSegment = this.segmentsToShow[this.segmentIndexToEdit];
      } else {
        this.editSegment = { id: undefined, name: "Jumper Segment #" + this.allSegments.length, targeting_conditions: [], targeting_count: 0 }
      }
    },
  },
  mounted() {},
  methods: {
    onClickAddNewSegment() {
      this.editSegment = { ...EDIT_SEGMENT };
      this.editMode = true;
    },
    callFetchTargetedCustomerCount() {
      let formattedConditionJson = this.$refs.CustomerSegments.getFormattedJsonToSave();
      if(formattedConditionJson.length) {
        this.$refs.SegmentsTargetingCount.fetchTargetedCustomerCount(formattedConditionJson);
      } else {
        this.updateToast({ open: true, message: "Minimum one condition is required for segment.", type: "danger", });
      }
    },
    async doRefresh(e) {
      await this.fetchSegments();
      e.target.complete();
    },
    async fetchSegments() {
      this.loading = true;
      try {
        let allSegmentsResponse = await jwtInterceptor.get(this.environment('VUE_APP_ROOT_API') + "fbrt/customer-segment");
        if ((allSegmentsResponse.status == 200 || allSegmentsResponse.status == 201) && allSegmentsResponse.data.success
        ) {
          this.allSegments = allSegmentsResponse.data.data;
          this.allSegments.forEach((thisSegment) => thisSegment.targeting_conditions = JSON.parse(thisSegment.targeting_conditions));
        } else {
          if (allSegmentsResponse.data && !allSegmentsResponse.data.success && allSegmentsResponse.data.errorMessage
          ) {
            this.updateToast({ open: true, message: allSegmentsResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
            //console.log("Response:", allSegmentsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async saveSegment() {
      let errorMessage = "";
      let updateResponse = "";
      let successMessage = "";
      try {
        const payloadToSave = this.$refs.CustomerSegments.getFormattedJsonToSave();  //this.getFormattedJsonToSave();
        // start Validation
        if (!this.editSegment?.name?.trim()) {
          this.updateToast({ open: true, message: "Please enter segment name.", type: "danger", });
          return;
        }
        if (!payloadToSave?.length) {
          this.updateToast({ open: true, message: "Minimum one condition is required for segment.", type: "danger", });
          return;
        }

        if (payloadToSave?.length) { // check if interaction channel is selected or not.
          let isInteractionChannelSelected = false;
          for (let i = 0; i < payloadToSave.length; i++) {
            const condition = payloadToSave[i];
            if(condition.query.includes('platform')) {
              isInteractionChannelSelected = true;
              break;
            }
          }
          if(!isInteractionChannelSelected) {
            this.updateToast({ open: true, message: "Please add 'Last Interaction => Interaction Channel' to the targeting condition!", type: "danger", });
            return;
          }
        }
        // *** end validation ***

        this.loading = true;
        let reqBody = {
          name: this.editSegment.name,
          targeting_conditions: payloadToSave && payloadToSave.length ? JSON.stringify(payloadToSave) : undefined,
        };
        let message = '';
        if (this.editSegment.id) {
          reqBody.segment_id = this.editSegment.id;
          updateResponse = await jwtInterceptor.put(this.environment("VUE_APP_ROOT_API") + "fbrt/customer-segment", reqBody)
          message = `"${ this.editSegment.name }" segment updated successfully`;
        } else {
          updateResponse = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "fbrt/customer-segment", reqBody);
          message = `"${this.editSegment.name}" segment created successfully`;
        }
        if ([200, 201].includes(updateResponse.status) && updateResponse.data.success) {
          successMessage = message;
        }
        if (updateResponse.data.errorMessage) {
          errorMessage = updateResponse.data.errorMessage;
        }
        await this.fetchSegments();
      } catch (error) {
        errorMessage = 'Something went wrong';
      }

      if(errorMessage) {
        this.updateToast({ open: true, message: errorMessage, type: "danger", });
      } else if (successMessage) {
        this.editMode = false;
        this.segmentIndexToEdit = -1;
        this.updateToast({ open: true, message: successMessage, type: "success", });
      }
      this.loading = false;
    },
    async deleteSegment() {
      let errorMessage = '';
      this.loading = true;
      try {
        var reqBody = new FormData();
        reqBody.append('segment_id', this.segmentIndexToEdit || undefined);

        let targetedCustomerCountResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fbrt/delete-customer-segment", reqBody,
        { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" } }
        );

        if ([200, 201].includes(targetedCustomerCountResponse.status) && targetedCustomerCountResponse.data.success) {
          this.updateToast({ open: true, message: "Segment is deleted successfully.", type: "success" });
          await this.fetchSegments();
        } else {
          errorMessage = targetedCustomerCountResponse?.data?.errorMessage || "Something went wrong please try again";
        }
      } catch (error) {
        console.error("Exception", error);
        errorMessage = "Something went wrong please try again";
      } finally {
        this.loading = false;
        if (errorMessage) {
          this.updateToast({ open: true, message: errorMessage, type: "danger", });
        }
      }
    }
  },
};
</script>

<style scoped>
.segment-filter-section {
  margin: 20px;
}

.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}
.table-row:last-child {
  border-bottom: 0px;
}
.gridMargin {
  margin: 20px;
}

.rowMargin {
  margin-bottom: 25px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 20px;
}
.chat-toolbar {
  margin-top: 15px;
}
.segment-Info {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin: 15px 0px 10px 20px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.tooltip_container .tooltip_text.left {
  top: 5%;
}
</style>
