<template>
  <master-layout pageTitle="Automations">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div style="height: 100%;" v-else>
        <ion-row>
          <ion-card v-if="isAdmin || oldPermissions.ai_setup" router-link="/automations/automate-responses" style="max-width: 500px;">
            <ion-card-header>
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-thumbnail style="width: 50px; margin-right: 10px;">
                  <img src="https://jumper.ai/web-s/resources/images/store-settings-general.svg" style="max-width: 100%; width: auto;">
                </ion-thumbnail>
                <ion-col>
                  <ion-card-title>Automate Responses</ion-card-title>
                  <ion-card-subtitle>Add automated response to train your bot.</ion-card-subtitle>
                </ion-col>
              </ion-row>
            </ion-card-header>
          </ion-card>
          <ion-card router-link="/automations/bot-builder" style="max-width: 500px;">
            <ion-card-header>
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-thumbnail style="width: 50px; margin-right: 10px;">
                  <img src="https://jumper.ai/web-s/resources/images/store-settings-general.svg" style="max-width: 100%; width: auto;">
                </ion-thumbnail>
                <ion-col>
                  <ion-card-title>Bot Builder</ion-card-title>
                  <ion-card-subtitle>Create your own automated and fully customizable bots.</ion-card-subtitle>
                </ion-col>
              </ion-row>
            </ion-card-header>
          </ion-card>
          <ion-card router-link="/automations/personalization" style="max-width: 500px;">
            <ion-card-header>
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-thumbnail style="width: 50px; margin-right: 10px;">
                  <img src="https://jumper.ai/web-s/resources/images/store-settings-general.svg" style="max-width: 100%; width: auto;">
                </ion-thumbnail>
                <ion-col>
                  <ion-card-title>Default Templates</ion-card-title>
                  <ion-card-subtitle>Customize the automated messages</ion-card-subtitle>
                </ion-col>
              </ion-row>
            </ion-card-header>
          </ion-card>
          <ion-card router-link="/automations/cart-recovery" style="max-width: 500px;">
            <ion-card-header>
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-thumbnail style="width: 50px; margin-right: 10px;">
                  <img src="https://jumper.ai/web-s/resources/images/store-settings-general.svg" style="max-width: 100%; width: auto;">
                </ion-thumbnail>
                <ion-col>
                  <ion-card-title>Cart Recovery</ion-card-title>
                  <ion-card-subtitle>Lets bring back potential customers by automatically reminding them to complete purchases.</ion-card-subtitle>
                </ion-col>
              </ion-row>
            </ion-card-header>
          </ion-card>
          <ion-card v-if="isAdmin || oldPermissions.broadcast" router-link="/automations/broadcast" style="max-width: 500px;">
            <ion-card-header>
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-thumbnail style="width: 50px; margin-right: 10px;">
                  <img src="https://jumper.ai/web-s/resources/images/store-settings-general.svg" style="max-width: 100%; width: auto;">
                </ion-thumbnail>
                <ion-col>
                  <ion-card-title>Broadcast</ion-card-title>
                  <ion-card-subtitle>Send messages to people who have interacted with you in the past.</ion-card-subtitle>
                </ion-col>
              </ion-row>
            </ion-card-header>
          </ion-card>
        </ion-row>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
  },
  ionViewWillEnter() {
    this.init();
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      console.log("Initializing...")
    }
  }
}
</script>