import { environment } from '/src/shared/env';
import { Preferences as Storage } from "@capacitor/preferences";

const state = () => ({
  "square": {
		is_active: false,
		available: false,
		accountServiceType: "restaurant",
		available_in_countries: ["US","AU","UK","GB","JP"],
		category: "POS (Point of sale)",
		name: "Square",
		description: "Connect your Square F&B account to import your menu, outlet locations.",
		logo: environment('VUE_APP_ROOT') + "/landing/resources/images/square-logo.svg",
		connection_type: 'redirect_after_api_call',
		api: { link: environment('VUE_APP_ROOT') + 'square/start-square', type: 'post', response_param: 'url' },
		storageKey: 'jmpr_getCommerceData',
		code: 'square'
	},
	"acommerce": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: ["acommerce_family_user"],
		category: "Ecommerce",
		name: "aCommerce",
		description: "Leading brand ecommerce enabler in southeast asia",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/acommerce-logo-square.png",
		connection_type: 'form',
		form_link: environment('VUE_APP_ROOT') + 'acommerce/acommerce-create',
		fields: [
			{field: 'username', placeholder: 'Username', value: undefined },
			{ field: 'apikey', placeholder: 'API key', value: undefined },
			{ field: 'merchantid', placeholder: 'Merchant ID', value: undefined },
			{ field: 'channelid', placeholder: 'Channel ID', value: undefined },
			{ field: 'partnerid', placeholder: 'Partner ID', value: undefined }
		],
		storageKey: 'jmpr_getCommerceData',
		code: 'acommerce'
	},
	"shopify": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Ecommerce",
		name: "Shopify",
		description: "Import products, manage inventory and get orders directly on Shopify.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/shopify.png",
		component_name: 'shopify',
		connection_type: 'component',
		form_link: environment('VUE_APP_ROOT') + 'api/connect-shopify',
		disconnect_params: {platform: 'shopify'},
		disconnect_url: environment('VUE_APP_ROOT_API') + 'delete-commerce',
		fields: [
			{field: 'privateusername', placeholder: 'Private API key', value: undefined },
			{ field: 'privatepassword', placeholder: 'Private password', value: undefined },
			{ field: 'storefrontaccess', placeholder: 'Storefront access token', value: undefined },
			{ field: 'shop', placeholder: 'YOUR-SHOP-DOMAIN.myshopify.com', value: undefined }
		],
		storageKey: 'jmpr_getCommerceData',
		code: 'shopify'
	},
	"printful": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Ecommerce",
		name: "Printful",
		description: "Import and create products, and manage orders dynamically with Printful.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/printful.png",
		connection_type: 'form',
		form_link: environment('VUE_APP_ROOT') + 'printful/connect-printful',
		fields: [
			{field: 'apikey', placeholder: 'API key', value: undefined }
		],
		storageKey: 'jmpr_getCommerceData',
		code: 'printful'
	},
	"amazon": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: ["US","UK","GB"],
		category: "Ecommerce",
		name: "Amazon",
		description: "Import products, manage inventory and get order notifications directly on Amazon.",
		logo: environment('VUE_APP_ROOT') + "/landing/resources/images/amazon-logo-square.png",
		connection_type: 'form',
		form_link: environment('VUE_APP_ROOT') + 'amazon/connect',
		fields: [
			{field: 'country_code', placeholder: 'Select country', value: undefined, type: 'select', options: [{ value: "US", name: 'United States' }, { value: "GB", name: 'United Kingdom' }] },
			{ field: 'seller_id', placeholder: 'Amazon seller ID', value: undefined },
			{ field: 'auth_token', placeholder: 'Amazon MWS auth token', value: undefined }
		],
		storageKey: 'jmpr_getCommerceData',
		code: 'amazon'
	},
	"bigcommerce": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Ecommerce",
		name: "BigCommerce",
		description: "Import products, manage inventory and get orders directly on BigCommerce.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/bigcommerce-logo-square.png",
		component_name: 'bigCommerce',
		connection_type: 'component',
		form_link: environment('VUE_APP_ROOT') + 'bigcommerce/bigcommerce-auth',
		disconnect_params: {platform: 'bigcommerce'},
		disconnect_url: environment('VUE_APP_ROOT_API') + 'delete-commerce',
		fields: [
			{field: 'client_secret', placeholder: 'Client secret', value: undefined },
			{ field: 'client_id', placeholder: 'Client ID', value: undefined }
		],
		storageKey: 'jmpr_getCommerceData',
		code: 'bigcommerce',
		settingButton: true
	},
	"magento": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Ecommerce",
		name: "Magento",
		description: "Import products, manage inventory and get orders directly on Magento.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/magento-logo-icon.png",
		component_name: 'magento',
		connection_type: 'component',
		form_link: '',
		fields: [],
		storageKey: 'jmpr_getCommerceData',
		code: 'magento'
	},
	"easyship": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: ["AU","CA","HK","SG","US"],
		category: "Shipment providers",
		name: "Easyship",
		description: "Connect your Easyship account and ship with 250+ shipping solutions.",
		logo: environment('VUE_APP_ROOT') + "/landing/resources/images/easyship-logo-box.png",
		component_name: 'easyship',
		connection_type: 'component',
		api: { link: environment('VUE_APP_ROOT') + 'api/connect-easyship', type: 'post', response_param: 'redirect_url' },
		storageKey: 'jmpr_getCommerceData',
		code: 'easyship'
	},
	"easyparcel": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: ["SG","MY","ID","TH"],
		category: "Shipment providers",
		name: "EasyParcel",
		description: "Connect your EasyParcel account to ship domestic and international.",
		logo: environment('VUE_APP_ROOT') + "/landing/resources/images/easyparcel-logo-box.png",
		component_name: 'easyparcel',
		connection_type: 'component',
		disconnect_url: environment('VUE_APP_ROOT_API') + 'delete-easyparcel',
		form_link: environment('VUE_APP_ROOT') + 'api/create-easyparcel',
		fields: [
			{field: 'token', placeholder: 'API key', value: undefined }
		],
		storageKey: 'jmpr_easyParcel',
		code: 'easyparcel'
	},
	"grab": {
		is_active: false,
		available: false,
		accountServiceType: "product",
		available_in_countries: ["SG","MY","PH"],
		category: "Shipment providers",
		name: "Grab Express",
		description: "Your express solution to safe and reliable on-demand delivery service",
		logo: environment('VUE_APP_ROOT') + "/landing/resources/images/grab-logo-square.png",
		connection_type: 'form',
		form_link: environment('VUE_APP_ROOT') + 'api/connect-grab',
		disconnect_url: environment('VUE_APP_ROOT_API') + 'delete-grab',
		fields: [
			{field: 'client_id', placeholder: 'Client ID', value: undefined },
			{ field: 'client_secret', placeholder: 'Client secret', value: undefined },
			{ field: 'delivery_method', placeholder: 'Delivery method', value: undefined, type: 'select', options: [{ value: "INSTANT", name: 'Instant' }, { value: "SAME_DAY", name: 'Same Day' }] }
		],
		storageKey: 'jmpr_grab',
		code: 'grab'
	},
	"dialogflow": {
		is_active: false,
		available: true,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Workflow",
		name: "Dialogflow",
		description: "Dialogflow is a Google service that provides machine learning to understand what users are saying accordingly send automated responses.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/dialogflow-logo-square.png",
		component_name: 'dialogflow',
		connection_type: 'component',
		form_link: '',
		fields: [],
		storageKey: 'jmpr_getCommerceData',
		code: 'dialogflow'
	},
	"zapier": {
		is_active: false,
		available: true,
		accountServiceType: "product",
		available_in_countries: [],
		category: "Workflow",
		name: "Zapier",
		description: "Connect and leverage on our orders and abandoned product webhooks.",
		logo: environment('VUE_APP_ROOT') + "/web-s/resources/images/zapier-logo.svg",
		connection_type: 'redirect',
		redirectUrl: environment('zapierConnectUrl'),
		storageKey: 'jmpr_getCommerceData',
		code: 'zapier'
	},
});

const getters = {
  getFullData(state) {
    return state;
  },
  getAvailable(state) {
    let available = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(state[prop]['available'] && !state[prop]['is_active']) available.push(state[prop])
    }
    return available;
  },
  getConnected(state) {
    let connected = [];
    for(var prop in state) {
      state[prop]['code'] = prop;
      if(state[prop]['is_active']) connected.push(state[prop])
    }
    return connected;
  },
  isAcommerceConnected(state){
    return state['acommerce']['is_active'];
  },
  isShopifyConnected(state){
    return state['shopify']['is_active'];
  },
  isWoocommerceConnected(state){
    return state['woocommerce'] && state['woocommerce']['is_active'];
  },
  isBigcommerceConnected(state){
    return state['bigcommerce']['is_active'];
  },
  isAmazonConnected(state){
    return state['amazon']['is_active'];
  },
  isPrintfulConnected(state){
    return state['printful']['is_active'];
  },
  isMagentoConnected(state){
    return state['magento']['is_active'];
  },
  isSquareConnected(state){
    return state['square']['is_active'];
  },
}

const actions = {
	async updateIntegration({ commit }, { integrationName, dataToUpdate, storageName }) {
		commit('updateIntegrationState', { integrationName, dataToUpdate });
		if(storageName) {
			let commerceData = await Storage.get({
				key: storageName,
			});
			if (commerceData?.value) {
				try {
					commerceData = JSON.parse(commerceData.value);
				} catch (error) {
					commerceData = null;
				}
			}

			if (commerceData && integrationName && Object.prototype.hasOwnProperty.call(commerceData, integrationName)) {
				commerceData[integrationName] = { ...commerceData[integrationName], ...dataToUpdate };
			} else if (commerceData) {
				commerceData = { ...commerceData, ...dataToUpdate };
			} else {
				commerceData = dataToUpdate;
			}
			await Storage.set({
				key: storageName,
				value: JSON.stringify(commerceData),
			});
		}
  },
	updateAvailableIntegrationMethods({ commit, dispatch }, payload) {
    if(payload.accountServiceType && payload.country) {
      let accountServiceType = undefined;
      if(payload.accountServiceType.indexOf('product') > -1) accountServiceType = "product";
			else if(payload.accountServiceType.indexOf('restaurant') > -1) accountServiceType = "restaurant";
      else if(payload.accountServiceType.indexOf('event') > -1) accountServiceType = "event";
      commit('updateAvailableIntegrationMethods', { accountServiceType: accountServiceType, country: payload.country.toUpperCase(), enterprise_Name: payload.enterprise_Name });
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load country data in payments module",
        type: "warning"
      }, { root: true });
    }
  },
  saveIntegrationData({ commit, dispatch }, payload) {
    if(payload.success) {
      commit('saveIntegrationData', payload)
    } else {
      dispatch("updateToast", {
        open: true,
        message: "Failed to load payment data " + payload.errorMessage,
        type: "danger"
      }, { root: true });
    }
  }
}

const mutations = {
  saveIntegrationData(state, data){
		for (var prop in state) {
			if (data[prop]) {
				state[prop]['is_active'] = Object.keys(data[prop]).length !== 0;
			}
			state[prop] = { ...state[prop], ...data[prop] };
    }
  },
  updateAvailableIntegrationMethods(state, data){
    for(var prop in state) {
		let availableInCountry = false;
		if(state[prop]['available_in_countries'] && state[prop]['available_in_countries'].length === 0) availableInCountry = true;
		else if(state[prop]['available_in_countries'].indexOf(data.country) > -1) availableInCountry = true;
		else state[prop]['available_in_countries'].indexOf('acommerce_family_user') > -1 && (data.enterprise_Name == 'eucerin' || data.enterprise_Name == 'olay_acom' ||data.enterprise_Name == 'jnj_acom_ph' || data.enterprise_Name == 'rb_acom_ph' || data.enterprise_Name == 'adidas_acom_id') ? availableInCountry = true : '';

		state[prop]['available'] = availableInCountry && state[prop]['accountServiceType'] === data.accountServiceType;
    }
  },
  updateIntegrationState(state, { integrationName, dataToUpdate }) {
    state[integrationName] = { ...state[integrationName], ...dataToUpdate };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};