<template>
  <ion-icon
    @click="openPopover($event)"
    style="cursor: pointer"
    :icon="ellipsisVerticalOutline"
  ></ion-icon>
  <ion-popover
    :is-open="popoverOpen"
    :event="event"
    @didDismiss="popoverOpen = false"
    :style="style"
    :side="side"
    :alignment="alignment"
  >
    <ion-content
      class="ion-no-padding"
      @click="closePopover($event)"
    >
      <slot></slot>
    </ion-content>
  </ion-popover>
</template>

<script>
import { IonContent, IonPopover } from "@ionic/vue";
import { ellipsisVerticalOutline } from "ionicons/icons";

export default {
  data: () => {
    return {
      popoverOpen: false,
      event: null,
      ellipsisVerticalOutline,
    };
  },
  props: {
    // eg. :style="{'--width': '170px'}"
    style: {
      type: Object,
      default: () => {
        return {};
      },
    },
    side: {
      type: String,
      default: "left",
    },
    alignment: {
      type: String,
      default: "start",
    },
  },
  components: {
    IonContent,
    IonPopover,
  },
  methods: {
    openPopover(e) {
      this.event = e;
      this.popoverOpen = true;
    },
    closePopover(e) {
      this.event = e;
      this.popoverOpen = false;
    },
  },
};
</script>

<style scoped>
</style>