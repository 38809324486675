<template>
  <ion-toolbar color="light">
    <ion-segment scrollable color="dark" v-model="active_customerDetailTab">
      <ion-segment-button value="userDetails">
        <ion-label>
          Customer info.
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="tags">
        <ion-label>
          Tags
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="customData">
        <ion-label>
          Custom data
        </ion-label>
      </ion-segment-button>
      <ion-segment-button v-if="!IS_ASIA" @click="!loadedAgents ? loadPastAgents() : ''" value="pastInteractions">
        <ion-label>
          Past interactions
        </ion-label>
      </ion-segment-button>
      <ion-segment-button v-if="!IS_ASIA" @click="!loadedPurchases ? loadPastPurchases() : ''" value="pastPurchases">
        <ion-label>
          Past purchases
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
  <ion-loading
    :is-open="loading"
    v-if="loading"
    cssClass="my-custom-class"
    message="Loading..."
  >
  </ion-loading>
  <div v-else>
    <div v-if="active_customerDetailTab === 'userDetails'">
      <ion-grid>
        <ion-row>
          <ion-col class="text-center ion-padding-top">
            <div class="crmSingleCustomerImageContainer">
              <div class="crmSingleCustomerImageContainerInner" :style="checkIfThereIsNoLogoThenShowTransparentImage(activeCustomer.image)">
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'facebook' || activeCustomer.channel == 'Facebook' || activeCustomer.channel == 'Messenger' || activeCustomer.channel == 'FB'" title="{{ getFacebookPageName(activeCustomer.channel_id) }}">
                  <img src="https://jumper.ai/landing/resources/images/messenger.png" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'line' || activeCustomer.channel == 'Line' || activeCustomer.channel == 'LN'" title="LINE">
                  <img src="https://jumper.ai/landing/resources/images/line.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'instagram' || activeCustomer.channel == 'Instagram' || activeCustomer.channel == 'IG'" title="Instagram">
                  <img src="https://jumper.ai/landing/resources/images/instagram.png" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'twitter' || activeCustomer.channel == 'TW'" title="Twitter">
                  <img src="https://jumper.ai/landing/resources/images/twitter.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'Youtube' || activeCustomer.channel == 'youtube' || activeCustomer.channel == 'YT'" title="Youtube">
                  <img src="https://jumper.ai/landing/resources/images/youtube.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'web' || activeCustomer.channel == 'webbot' || activeCustomer.channel == 'bot' || activeCustomer.channel == 'JMP'" title="Webbot">
                  <img src="https://jumper.ai/web-s/resources/images/sc-web-sell-via-chatbox.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'imessage'" title="iMessage">
                  <img src="https://jumper.ai/landing/resources/images/imessage-logo.png" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'viber'" title="Viber">
                  <img src="https://jumper.ai/landing/resources/images/viber.png" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'telegram'" title="Telegram">
                  <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'sms'" title="SMS">
                  <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'googlebusinessmsg'" title="Google business messages">
                  <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'whatsapp'" title="WhatsApp">
                  <img src="https://jumper.ai/landing/resources/images/whatsapp.svg" class="platformIconInLineWithText">
                </p>
                <p class="overUserImageChannelIconContainer" v-if="activeCustomer.channel == 'adlingo'" title="AdLingo">
                  <img src="https://jumper.ai/web-s/resources/images/adlingo-logo-square.png" class="platformIconInLineWithText">
                </p>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row v-if="activeCustomer.channel == 'facebook' || activeCustomer.channel == 'Facebook' || activeCustomer.channel == 'Messenger' || activeCustomer.channel == 'FB'">
          <ion-col class="text-center ion-padding">
            <ion-chip color="dark" outline="true">
              <ion-avatar>
                <img src="https://jumper.ai/web-s/resources/images/shop-image-placeholder.png">
              </ion-avatar>
              <ion-label>{{ getFacebookPageName(activeCustomer.channel_id) }}</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list>
        <ion-item-group v-if="activeCustomer.assigned_to != null && agentReAssignmentAllowed && !crm">
          <ion-item-divider>
            <ion-label>Assigned agent</ion-label>
          </ion-item-divider>

          <ion-item lines="full">
            <ion-avatar class="img-container" slot="start" v-if="activeCustomer.assigned_to != null">
              <div class="img-containerInner" v-if="activeCustomer.assigned_to.picture != null" :style="renderCustomerImage_style_background(activeCustomer.assigned_to.picture)"></div>
              <div class="img-containerInner" v-if="activeCustomer.assigned_to.picture == null" style="background: url(https://jumper.ai/web-s/resources/images/transparent-bg.png) no-repeat;"></div>
            </ion-avatar>
            <ion-label v-if="activeCustomer.assigned_to != null">{{ activeCustomer.assigned_to.name }}</ion-label>
            <ion-label @click="() => $emit('reassign')" color="primary" class="ion-text-end" v-if="activeCustomer.assigned_to != null">Re-assign</ion-label>
            <ion-label @click="() => $emit('reassign')" color="primary" class="ion-text-end" v-if="activeCustomer.assigned_to == null">Assign</ion-label>
          </ion-item>
        </ion-item-group>

        <ion-item-group>
          <ion-item-divider>
            <ion-label>Customer details</ion-label>
          </ion-item-divider>

            <ion-item>
              <ion-label>Name</ion-label>
                <ion-input v-if="userRolesPermission.live_chat && userRolesPermission.live_chat.edit_customer_details" slot="end" @change="updateUser($event, 'name')" v-model="newName" type="text" placeholder="Enter Name" class="fullBordered input_custom_style"></ion-input>
                <ion-item v-else slot="end">
                    <ion-label v-if="activeCustomer.name != 'unrecorded'"> {{ activeCustomer.name }} </ion-label>
                    <ion-label v-if="activeCustomer.name == 'unrecorded' && activeCustomer.email != 'unrecorded' && activeCustomer.email != 'valid'">{{ activeCustomer.email }}</ion-label>
                    <ion-label v-if="activeCustomer.name == 'unrecorded' && (activeCustomer.email == 'unrecorded' || activeCustomer.email == 'valid')">Anonymous</ion-label>
                </ion-item>
            </ion-item>

          <ion-item>
            <ion-label>Email</ion-label>
              <ion-input v-if="userRolesPermission.live_chat && userRolesPermission.live_chat.edit_customer_details" slot="end" @change="updateUser($event, 'email')" v-model="newEmail" type="text" placeholder="Enter Email" class="fullBordered input_custom_style"></ion-input>
            <ion-item v-else slot="end" lines="none">
              <ion-label v-if="activeCustomer.email != 'unrecorded' && activeCustomer.email != 'valid' && activeCustomer.email != '' && activeCustomer.email != 'None' && activeCustomer.email != null">{{ activeCustomer.email }}</ion-label>
              <ion-label v-if="activeCustomer.email == 'unrecorded' || activeCustomer.email == 'valid' || activeCustomer.email == '' || activeCustomer.email == 'None' || activeCustomer.email == null">Not captured</ion-label>
            </ion-item>
          </ion-item>

        <ion-item>
            <ion-label>Mobile No.</ion-label>
              <ion-item v-if="userRolesPermission.live_chat && userRolesPermission.live_chat.edit_customer_details && activeCustomer.channel !== 'whatsapp'" slot="end" lines="none">
                <ion-icon slot="start" :icon="globeOutline" style="margin-right: 5px"></ion-icon>
                <ion-select @ionChange="updateUser($event, 'countryCode')" v-model="newCountryCode">
                  <ion-select-option disabled :value="undefined">Select Country Code</ion-select-option>
                  <ion-select-option v-for="(thisCountry, thisCode) in countryCodesData.countryCodesData" :key="thisCode" :value="thisCode">{{ thisCode }}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-input v-if="userRolesPermission.live_chat && userRolesPermission.live_chat.edit_customer_details && activeCustomer.channel !== 'whatsapp'" slot="end" @change="updateUser($event, 'mobile')" v-model="newMobile" type="text" placeholder="Enter Mobile Number" class="fullBordered input_custom_style" style="margin-left: 0px"></ion-input>
              <ion-item v-else slot="end" lines="none">
                <ion-label v-if="activeCustomer.mobile != 'unrecorded' && activeCustomer.mobile != 'None' && activeCustomer.mobile != '' && activeCustomer.mobile != null">{{ activeCustomer.mobilecountrycode }} {{ activeCustomer.mobile }}</ion-label>
                <ion-label v-if="activeCustomer.mobile == 'unrecorded' || activeCustomer.mobile == 'None' || activeCustomer.mobile == '' || activeCustomer.mobile == null">Not captured</ion-label>
              </ion-item>
          </ion-item>

          <ion-item v-if="activeCustomer.gender != 'None' && activeCustomer.gender != '' && activeCustomer.gender != null">
            <ion-label>Gender</ion-label>
            <ion-label>{{ activeCustomer.gender }}</ion-label>
          </ion-item>

          <ion-item v-if="activeCustomer.dob != 'None' && activeCustomer.dob != '' && activeCustomer.dob != null">
            <ion-label>Date of birth</ion-label>
            <ion-label>{{ renderDate(activeCustomer.dob) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.timezone != 'None' && activeCustomer.timezone != '' && activeCustomer.timezone != null">
            <ion-label>Timezone</ion-label>
            <ion-label>{{ getFormattedTimezone(activeCustomer.timezone) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'userTimezone') == true && activeCustomer.headerinfo.userTimezone != 'None' && activeCustomer.headerinfo.userTimezone != '' && activeCustomer.headerinfo.userTimezone != null">
            <ion-label>Timezone</ion-label>
            <ion-label>{{ getFormattedTimezone(activeCustomer.headerinfo.userTimezone) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.locale != 'None' && activeCustomer.locale != '' && activeCustomer.locale != null">
            <ion-label>Locale</ion-label>
            <ion-label>{{ getLocaleName(activeCustomer.locale) }} ({{ activeCustomer.locale }})</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'language') == true && activeCustomer.headerinfo.language != 'None' && activeCustomer.headerinfo.language != '' && activeCustomer.headerinfo.language != null">
            <ion-label>Locale</ion-label>
            <ion-label>{{ getLocaleName(activeCustomer.headerinfo.language) }} ({{ activeCustomer.headerinfo.language }})</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.lastReplyTimestamp != 'None' && activeCustomer.lastReplyTimestamp != null && activeCustomer.lastReplyTimestamp != ''">
            <ion-label>Last interaction</ion-label>
            <ion-label>{{ renderDateTime_lastReplyTimestamp(activeCustomer.lastReplyTimestamp, 'customReadable', false) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.psid && activeCustomer.psid != ''">
            <ion-label>PSID</ion-label>
            <ion-label>{{ activeCustomer.psid }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.consented_time != '' && activeCustomer.consented_time != 'None' && activeCustomer.consented_time != null">
            <ion-label>Privacy consent accepted on</ion-label>
            <ion-label>{{ renderDateTime_lastReplyTimestamp(activeCustomer.consented_time, '', false) }}</ion-label>
          </ion-item>
        </ion-item-group>

        <ion-item-group>
          <ion-item-divider>
            <ion-label>Source</ion-label>
          </ion-item-divider>

          <ion-item v-if="!activeCustomer.headerinfo || !activeCustomer.headerinfo.Referer">
            <ion-label>Origin</ion-label>
            <!-- <ion-label v-if="singleCustomerOrdersResult[0].referred && singleCustomerOrdersResult[0].referred != ''">{{ getFormattedReferredOriginName(singleCustomerOrdersResult[0].referred) }}</ion-label>
            <ion-label v-if="!singleCustomerOrdersResult[0].referred || singleCustomerOrdersResult[0].referred == ''">{{ getFormattedReferredOriginName(activeCustomer.channel) }}</ion-label> -->
            <ion-label>{{ customerOrigin }}</ion-label>
          </ion-item>

          <ion-item v-if="activeCustomer.channel == 'facebook' || activeCustomer.channel == 'Facebook' || activeCustomer.channel == 'Messenger' || activeCustomer.channel == 'FB'">
            <ion-label>Page name</ion-label>
            
            <ion-label>{{ getFacebookPageName(activeCustomer.channel_id) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.Referer">
            <ion-label>Origin</ion-label>
            
            <ion-label>
              <a href="{{ activeCustomer.headerinfo.Referer }}" target="_blank" rel="noreferer noopener">{{ activeCustomer.headerinfo.Referer }}</a>
            </ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'language') == true">
            <ion-label>Locale</ion-label>
            
            <ion-label>{{ getLocaleName(activeCustomer.headerinfo.language) }} ({{ activeCustomer.headerinfo.language }})</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'X-AppEngine-City') == true">
            <ion-label>City</ion-label>
            
            <ion-label>
              <span>{{ activeCustomer.headerinfo['X-AppEngine-City'] }}</span>,
              <span>{{ activeCustomer.headerinfo['X-AppEngine-Country'] }}</span>
            </ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'country') == true">
            <ion-label>Country</ion-label>
            
            <ion-label>{{ getFullCountryNameFrom2CharName(activeCustomer.headerinfo['country']) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'userCountry') == true && activeCustomer.headerinfo.userCountry != '' && activeCustomer.headerinfo.userCountry != null && activeCustomer.headerinfo.userCountry != 'None'">
            <ion-label>Country</ion-label>
            
            <ion-label>
              <span v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'userState') == true && activeCustomer.headerinfo.userState != '' && activeCustomer.headerinfo.userState != null && activeCustomer.headerinfo.userState != 'None'">
                  <span>{{ activeCustomer.headerinfo['userState'] }}</span>,
              </span>
              <span>{{ getFullCountryNameFrom2CharName(activeCustomer.headerinfo['userCountry']) }}</span>
            </ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.ip">
            <ion-label>IP address</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo.ip }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'User-Agent') == true">
            <ion-label>Device</ion-label>
            
            <ion-label>{{ getDeviceTypeFromUseragent(activeCustomer.headerinfo['User-Agent']) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'userDevice') == true">
            <ion-label>Device</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo['userDevice'] }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.device_type && activeCustomer.headerinfo.device_type != '' && activeCustomer.headerinfo.device_type != 'None' && activeCustomer.headerinfo.device_type != null">
            <ion-label>Device</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo.device_type }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.primary_device_os && activeCustomer.headerinfo.primary_device_os != '' && activeCustomer.headerinfo.primary_device_os != 'None' && activeCustomer.headerinfo.primary_device_os != null">
            <ion-label>Device OS</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo.primary_device_os }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.mcc && activeCustomer.headerinfo.mcc != '' && activeCustomer.headerinfo.mcc != 'None' && activeCustomer.headerinfo.mcc != null && activeCustomer.headerinfo.mnc && activeCustomer.headerinfo.mnc != '' && activeCustomer.headerinfo.mnc != 'None' && activeCustomer.headerinfo.mnc != null">
            <ion-label>Mobile network</ion-label>
            
            <ion-label>{{ getMobileNetworkName_fromMccMncList(activeCustomer.headerinfo.mcc,activeCustomer.headerinfo.mnc) }}</ion-label>
          </ion-item>
          
          <ion-item v-if="activeCustomer.headerinfo && activeCustomer.headerinfo.viber_version && activeCustomer.headerinfo.viber_version != '' && activeCustomer.headerinfo.viber_version != 'None' && activeCustomer.headerinfo.viber_version != null">
            <ion-label>Viber App Version</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo.viber_version }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'creativeId') == true">
            <ion-label>Creative ID</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo['creativeId'] }}</ion-label>
          </ion-item>
          
          <ion-item v-if="checkIfVariableExistInJsonObject(activeCustomer.headerinfo, 'placementId') == true">
            <ion-label>Placement ID</ion-label>
            
            <ion-label>{{ activeCustomer.headerinfo['placementId'] }}</ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </div>
    <div v-if="active_customerDetailTab === 'tags'">
      <div style="padding: 15px 15px 0px 15px;">
        <ion-chip color="dark" v-for="(thisTag, tIndex) in activeCustomer.tags" :key="tIndex">
          <ion-label>{{ thisTag }}</ion-label>
          <ion-icon :icon="closeCircleOutline" @click="deleteTag(tIndex)"></ion-icon>
        </ion-chip>
      </div>

      <ion-list>
        <ion-item lines="none">
          <ion-label position="stacked">Add a new tag</ion-label>
          <ion-input @keyup.enter="saveNewTag" type="text" class="fullBordered input_custom_style sctn-input" v-model="newTag" placeholder="Enter tag"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-button fill="solid" size="default" color="dark" @click="saveNewTag" slot="start">Add</ion-button>
        </ion-item>
      </ion-list>
    </div>
    <div v-if="active_customerDetailTab === 'customData'">
      <table>
        <thead>
          <tr>
            <th class="text-center">Field name</th>
            <th class="text-center">Field value</th>
            <th class="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr class="singleCustomFieldArea actual" v-for="(thisField, index) in activeCustomer.custom_fields" :key="index" :thisindex="index">
            <td>
              <ion-input type="text" class="fullBordered keyText" placeholder="Field name" id="customFieldLabel" name="customFieldLabel" v-model="thisField.field"></ion-input>
            </td>
            <td>
              <ion-input type="text" class="fullBordered valueText" placeholder="Data value" id="customFieldValue" name="customFieldValue" v-model="thisField.value"></ion-input>
            </td>
            <td class="text-center">
              <ion-button fill="outline" size="default" color="danger" @click="deleteField(index)">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>
            </td>
          </tr>
          <tr class="singleCustomFieldArea" id="singleCustomFieldArea-new">
            <td>
              <ion-input type="text" class="fullBordered keyText" placeholder="Add new field" id="customFieldLabel-new" name="customFieldLabel" v-model="newField.field"></ion-input>
            </td>
            <td>
              <ion-input type="text" class="fullBordered valueText" placeholder="Enter value" id="customFieldValue-new" name="customFieldValue" v-model="newField.value"></ion-input>
            </td>
            <td class="text-center">
              <!-- PENDING: @lovish -->
              <!-- <ion-button fill="outline" size="default" color="dark" @click="addNewField()" :disabled="newField.field == '' || newField.value == '' || newField.field == null || newField.value == null">
                <ion-icon :icon="addOutline"></ion-icon>
              </ion-button> -->
            </td>
          </tr>
        </tbody>
      </table>

      <ion-list>
        <ion-item lines="none">
          <ion-button fill="solid" size="default" color="dark" @click="saveNewField" class="agent_nicknameFormSubmitBtn">
            Save
          </ion-button>
        </ion-item>
      </ion-list>
    </div>
    <div v-if="active_customerDetailTab === 'pastInteractions'">
      <div v-if="!loadedAgents || (pastAgents && pastAgents.length)">
        <ion-list v-if="pastAgents && pastAgents.length">
          <ion-item-group v-for="thisAgent in pastAgents" :key="thisAgent.id">
            <ion-item lines="full">
              <ion-avatar class="img-container" slot="start">
                <div class="img-containerInner" v-if="thisAgent.picture != null" :style="renderCustomerImage_style_background(thisAgent.picture)"></div>
                <div class="img-containerInner" v-else style="background: url(https://jumper.ai/web-s/resources/images/transparent-bg.png) no-repeat;"></div>
              </ion-avatar>
              <ion-label>
                <h2>{{ thisAgent.name }}</h2>
                <p color="medium" v-if="thisAgent.email">{{ thisAgent.email }}</p>
              </ion-label>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </div>
      <div v-else>
        <div class="text-center" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
          <h5>No past agents found who had a conversation with this customer</h5>
        </div>
      </div>
    </div>
    <div v-if="active_customerDetailTab === 'pastPurchases'">
      <div v-if="!loadedPurchases || (pastPurchases && pastPurchases.length)">
        <ion-list v-if="pastPurchases && pastPurchases.length">
          <ion-item lines="full" v-for="thisPurchase in pastPurchases" :key="thisPurchase.id">
            <ion-thumbnail v-if="thisPurchase.cart && thisPurchase.cart.length && thisPurchase.cart[0].image" class="img-container" slot="start">
              <img :src="thisPurchase.cart[0].image" width="30" style="border: 1px #eee solid;" />
            </ion-thumbnail>
            <ion-label>
              <h2>{{ thisPurchase.ordernumber }}</h2>
              <!-- <p color="primary">
                View order details
                <ion-icon slot="icon-only" :icon="chevronForwardOutline" style="vertical-align: middle"></ion-icon>
              </p> -->
              <p><b>Total:</b> {{ thisPurchase.total }}</p>
            </ion-label>
            <span class="tooltip_container">
              <ion-button fill="clear" size="default" color="dark" @click="() => { currentSingleOrderData = thisPurchase; singleOrderExpandedView_opened = true; }">
                <ion-icon :icon="eyeOutline"></ion-icon>
              </ion-button>
              <span class="tooltip_text left">View</span>
            </span>
          </ion-item>
        </ion-list>
      </div>
      <div v-else>
        <div class="text-center" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage" />
          <h5>No past purchases found for this customer</h5>
        </div>
      </div>
    </div>
    <order-details v-if="singleOrderExpandedView_opened" :open="singleOrderExpandedView_opened" @close="() => { singleOrderExpandedView_opened = false; currentSingleOrderData = null; }" :order="currentSingleOrderData" :clickAndCollectUser="false" :orderKind="getKindOfOrder(currentSingleOrderData)" :soldByAgent="checkIfOrderSoldByAgent(currentSingleOrderData)" :agentName="orderSoldByAgent_name(currentSingleOrderData)" :agentImage="orderSoldByAgent_image(currentSingleOrderData)" :billAddressHTML="getBillingAddressForThisOrder_html(currentSingleOrderData.address)" />
  </div>
</template>

<script>
import '/src/resources/css/page-inbox.css';
import chatMixin from '../mixins/chat.js';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import {
  chevronForwardOutline,
  closeCircleOutline,
  trashOutline,
  addOutline,
  globeOutline,
  eyeOutline
} from 'ionicons/icons';
import { IonSelect, IonSelectOption } from "@ionic/vue";
import orderDetails from './orderDetails.vue';

export default {
  components: { IonSelect, IonSelectOption, orderDetails },
  mixins: [chatMixin],
  emits: ['custom_fields_updated', 'tags_updated', 'reassign', 'user_info_updated'],
  props: {
    conversationid: {
      type: String,
      required: true
    },
    activeCustomer: {
      type: Object,
      default: null,
      required: true
    },
    agentReAssignmentAllowed: {
      type: Boolean,
      default: false
    },
    crm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      active_customerDetailTab: 'userDetails',
      newField: {
        field: undefined,
        value: undefined
      },
      addNewTagMode: false,
      newTag: undefined,
      chevronForwardOutline,
      closeCircleOutline,
      trashOutline,
      addOutline,
      globeOutline,
      eyeOutline,
      newName: undefined,
      newEmail: undefined,
      newMobile: undefined,
      newCountryCode: undefined,
      loadedAgents: false,
      loadedPurchases: false,
      pastAgents: [],
      pastPurchases: [],
      singleOrderExpandedView_opened: false,
      currentSingleOrderData: null,
      customerOrigin: '',
    }
  },
  mounted() {
    this.newName = this.activeCustomer.name;
    this.newEmail = this.activeCustomer.email;
    this.newMobile = this.activeCustomer.mobile;
    this.newCountryCode = this.activeCustomer.mobilecountrycode;
    this.customerOrigin = this.getFormattedReferredOriginName(this.getChannelFromPageActive(this.activeCustomer.channel))
  },
  methods: {
    async loadPastAgents() {
      try {
        this.loading = true;
        let reqBody = {
          conversation: this.conversationid
        };
        let agentsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "past-agents", reqBody);
        if ((agentsResponse.status == 200 || agentsResponse.status == 201) && agentsResponse.data.success) {
          this.pastAgents = agentsResponse.data.assigned;
          this.loadedAgents = true;
        } else {
          if(agentsResponse.data.errorMessage && agentsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: agentsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong.",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async loadPastPurchases() {
      try {
        this.loading = true;
        let reqBody = {
          conversationid: this.conversationid
        };
        let transactionsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "buyer-transaction", reqBody);
        if ((transactionsResponse.status == 200 || transactionsResponse.status == 201) && transactionsResponse.data.success) {
          this.pastPurchases = transactionsResponse.data.transactions;
          this.loadedPurchases = true;
        } else {
          if(transactionsResponse.data.errorMessage && transactionsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: transactionsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong.",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async saveNewField() {
      try {
        if(!this.newField.field || !this.newField.field.length || !this.newField.value || !this.newField.value.length) {
          this.updateToast({
            open: true,
            message: "Please add both key and value for a custom field!",
            type: "danger"
          })
          return;
        }
        let allFieldsData = (JSON.parse(JSON.stringify(this.activeCustomer.custom_fields)));
        allFieldsData.push(this.newField);
        console.log(allFieldsData);

        let reqBody = {
          conversationid: this.conversationid,
          custom_fields: JSON.stringify(allFieldsData)
        }
        
        this.loading = true;

        let addTagResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fbrt/createtag", reqBody);

        if ((addTagResponse.status == 200 || addTagResponse.status == 201) && addTagResponse.data.success && Object.keys(addTagResponse.data.errorMessage).length === 0) {
          this.$emit('custom_fields_updated');
          this.updateToast({
            open: true,
            message: "Custom fields saved successfully!",
            type: "success"
          })
        } else if((addTagResponse.status == 200 || addTagResponse.status == 201) && addTagResponse.data.success && Object.keys(addTagResponse.data.errorMessage).length > 0){
          console.log("Response:", addTagResponse);
          this.updateToast({
            open: true,
            message: "Each custom field key must be unique!",
            type: "danger"
          })
        } else {
          console.log("Response:", addTagResponse);
          this.updateToast({
            open: true,
            message: addTagResponse.data.errorMessage,
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.newField = {
          field: undefined,
          value: undefined
        }
      }
    },
    async saveNewTag() {
      try {
        if(!this.newTag || !this.newTag.length) {
          this.updateToast({
            open: true,
            message: "Please enter a tag first!",
            type: "danger"
          })
          return;
        }

        this.loading = true;

        let reqBody = {
          conversationid: this.conversationid,
          tag: this.newTag
        }
        let addTagResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fbrt/createtag", reqBody);

        if ((addTagResponse.status == 200 || addTagResponse.status == 201) && addTagResponse.data.success) {
          this.$emit('tags_updated');
          this.updateToast({
            open: true,
            message: "New tag added successfully!",
            type: "success"
          })
        } else {
          console.log("Response:", addTagResponse);
          this.updateToast({
            open: true,
            message: addTagResponse.data.errorMessage,
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;

        this.newTag = undefined;
        this.addNewTagMode = false;
      }
    },
    async deleteField(index) {
      try {
        if(index < 0) {
          this.updateToast({
            open: true,
            message: "Something went wrong, please refresh!",
            type: "danger"
          })
          return;
        }
        let fieldToDelete = (this.activeCustomer.custom_fields[index]).field;
        this.loading = true;
        let reqBody = {
          conversationid: this.conversationid,
          custom_field: fieldToDelete
        }
        let deleteFieldResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fbrt/deletetag", reqBody);
        if ((deleteFieldResponse.status == 200 || deleteFieldResponse.status == 201) && deleteFieldResponse.data.success) {
          this.$emit('custom_fields_updated');
          this.updateToast({
            open: true,
            message: "Custom field data deleted successfully",
            type: "success"
          })
        } else {
          console.log("Response:", deleteFieldResponse);
          this.updateToast({
            open: true,
            message: deleteFieldResponse.data.errorMessage,
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async deleteTag(index) {
      try {
        if(index < 0) {
          this.updateToast({
            open: true,
            message: "Something went wrong, please refresh!",
            type: "danger"
          })
          return;
        }
        this.loading = true;
        let tagToDelete = this.activeCustomer.tags[index];
        let reqBody = {
          conversationid: this.conversationid,
          tag: tagToDelete
        }
        let deleteTagResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fbrt/deletetag", reqBody);
        if ((deleteTagResponse.status == 200 || deleteTagResponse.status == 201) && deleteTagResponse.data.success) {
          this.$emit('tags_updated');
          this.updateToast({
            open: true,
            message: "Tag deleted successfully.",
            type: "success"
          })
        } else {
          console.log("Response:", deleteTagResponse);
          this.updateToast({
            open: true,
            message: deleteTagResponse.data.errorMessage,
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateUser(e, item) {
      try {
        this.loading = true;
        let reqBody = {
          conversationid: this.conversationid
        }
        switch(item) {
          case 'name': if(this.newName !== this.activeCustomer.name) reqBody['name'] = this.newName;
          break;
          case 'mobile': if(this.newMobile !== this.activeCustomer.mobile) reqBody['mobileNumber'] = this.newMobile;
          if(reqBody.mobileNumber) reqBody['countryCode'] = this.newCountryCode;
          break;
          case 'email': if(this.newEmail !== this.activeCustomer.email) reqBody['email'] = this.newEmail;
          break;
          case 'countryCode': if(this.newCountryCode !== this.activeCustomer.mobilecountrycode) reqBody['countryCode'] = this.newCountryCode;
          if(reqBody.countryCode) reqBody['mobileNumber'] = this.newMobile;
          break;
        }
        if(reqBody.name || reqBody.mobileNumber || reqBody.email || reqBody.countryCode) {
          let prop = undefined;
          let value = undefined;
          for(var p in reqBody) if(p !== 'conversationid') { prop = p; value = reqBody[p]; }
          
          let updateUserResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-user-agent", reqBody);
          if ((updateUserResponse.status == 200 || updateUserResponse.status == 201) && updateUserResponse.data.success) {
            this.$emit('user_info_updated', { prop: prop, value: value });
            this.updateToast({
              open: true,
              message: "User Info updated successfully.",
              type: "success"
            })
          } else {
            console.log("Response:", updateUserResponse);
            this.updateToast({
              open: true,
              message: updateUserResponse.data.errorMessage,
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    getKindOfOrder(orderData) {
      if(typeof orderData != 'undefined') {
        if(typeof orderData.cart != 'undefined') {
          if(orderData.cart.length > 0) return "generic";
          else if(typeof orderData.product.hashtag != 'undefined') {
            if(typeof orderData.product.itemtype != 'undefined') return "botBuilderFlow";
            else return "generic";
          }
          else if(orderData.notes != null) return "askPayment";
          else if(orderData.gateway == 'whatsapp pay') return "generic";
          else return "botBuilderFlow";
        }
        else return "generic";
      } else return undefined;
    },
    checkIfOrderSoldByAgent(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          return true;
        }
        else{
          if(eba_id != ''){
            return true;
          }
          else{
            return false;
          }
        }
      }
    },
    orderSoldByAgent_name(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          return agentData.name;
        }
        else{
          if(eba_id != ''){
            return eba_id;
          }
        }
      }
    },
    orderSoldByAgent_image(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          return agentData.picture;
        }
        else{
          if(eba_id != ''){
            return "https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg";
          }
        }
      }
    },
    getBillingAddressForThisOrder_html(address){
      if(typeof address != 'undefined'){
        var html_text = "";

        if(address.customer_name && address.customer_name != ''){
          html_text += "<u>Name:</u> "+ address.customer_name +"<br />";
        }
        if(address.customer_email && address.customer_email != ''){
          html_text += "<u>Email:</u> "+ address.customer_email +"<br />";
        }
        if(address.customer_mobile && address.customer_mobile != ''){
          html_text += "<u>Mobile no.:</u> "+ address.customer_countrycode +" "+ address.customer_mobile +"<br />";
        }

        html_text += address.addressLine1 +", ";

        if(address.addressLine2 && address.addressLine2 != '' && address.addressLine2 != null && address.addressLine2 != 'None'){
          html_text += address.addressLine2 +", ";
        }

        html_text += address.pincode +", "+ address.city +", "+ address.state +", "+ address.country;

        return html_text;
      }
    }
  }
}
</script>

<style scoped>

.ionText{
  color: red;

}
.filterImage {
  max-width: 300px; 
  width: 100%;
  height: auto; 
  display: block;
  margin: 0 auto;
}
</style>