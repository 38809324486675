<template>
  <master-layout pageTitle="Login Activities">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div style="height: 100%;" v-else>
        <div class="text-center"  v-if="loginDetails.length == 0" style="width: 100%; padding: 15px; height: 100%;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No activity yet! 😟</h5>
          <ion-text color="medium">Seems like there is no login activity for your account</ion-text>
        </div>
        <ion-card v-else>
          <ion-card-header>
            <ion-card-title>Login audit</ion-card-title>
            <ion-card-subtitle>Monitor login activities</ion-card-subtitle>
          </ion-card-header>
          <div>
            <ion-list style="height: 100%;" class="ion-no-padding">
              <!-- <ion-list-header>
                <b>Login Activities</b>
              </ion-list-header> -->
              <ion-item v-for="(thisDetail, dIndex) in loginDetails" :key="dIndex">
                <ion-grid>
                  <ion-col>
                    <ion-row justify-content-between>
                      <b>Session:&nbsp;</b>
                      <div>{{ moment(thisDetail.started).format("DD-MMM-YY hh:mm a") }} to {{ moment(thisDetail.ended).format("DD-MMM-YY hh:mm a") }}</div>
                    </ion-row>
                    <ion-row justify-content-between>
                      <b>IP Address:&nbsp;</b>
                      <div>{{ thisDetail.ip }}</div>
                    </ion-row>
                    <ion-row justify-content-between>
                      <b>Device:&nbsp;</b>
                      <div>{{ thisDetail.agent }}</div>
                    </ion-row>
                  </ion-col>
                </ion-grid>
              </ion-item>
            </ion-list>
            <ion-infinite-scroll
              @ionInfinite="fetchActivities($event)" 
              threshold="100px" 
              id="infinite-scroll"
              :disabled="!cursor || !more || loginDetails.length <= 0"
            >
              <ion-infinite-scroll-content
                loading-spinner="bubbles"
                loading-text="Loading more activities...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </div>
        </ion-card>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";

export default {
  components: { IonInfiniteScroll, IonInfiniteScrollContent },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      loginDetails: [],
      more: false,
      cursor: undefined
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async ionViewWillEnter(){
      this.init();
    },
    async init() {
      console.log("Initializing");
      this.fetchActivities();
    },
    async fetchActivities(event) {
      try {
        if(!event) this.loading = true;
        let reqBody = {};
        if(event && this.more && this.cursor) reqBody = { cursor: this.cursor }
        let loginDetailsResponse = reqBody.cursor ? await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "login-details", reqBody) : await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "login-details");
        if ((loginDetailsResponse.status == 200 || loginDetailsResponse.status == 201) && loginDetailsResponse.data) {
          console.log(loginDetailsResponse.data);
          if(loginDetailsResponse.data.success) {
            if(this.loginDetails && this.loginDetails.length) this.loginDetails = this.loginDetails.concat(loginDetailsResponse.data.login_details);
            else this.loginDetails = loginDetailsResponse.data.login_details;
            this.more = loginDetailsResponse.data.more;
            this.cursor = loginDetailsResponse.data.cursor;
          } else {
            this.updateToast({
              open: true,
              message: loginDetailsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", loginDetailsResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        if(event) event.target.complete();
        else this.loading = false;
      }
    }
  }
}
</script>