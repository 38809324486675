import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom css */
import './resources/css/common.css';

/* Store */
import store from './store/index';

/* Layout */
import MasterLayout from './components/MasterLayout.vue';

/* Global Mixin */
import globalMixin from './mixins/global';

/* Rich text editor */
import CKEditor from '@ckeditor/ckeditor5-vue';

/* Common components */
import {
  IonItem,IonLabel,IonInput,IonList,IonListHeader,IonAvatar,IonGrid,IonCol,IonRow,IonItemGroup,IonItemDivider,IonChip,IonButton,IonLoading,IonHeader,IonFooter,IonToolbar,IonButtons,IonIcon,IonRefresher,IonRefresherContent,IonContent,IonModal,IonTitle,IonPage,IonCard,IonCardTitle,IonCardSubtitle,IonCardHeader,IonCardContent,IonThumbnail,IonActionSheet,IonDatetime,IonSegment,IonSegmentButton,IonText,IonAlert,IonBadge
} from "@ionic/vue";

/* Custom components */
import myInput from './components/myInput.vue';
import pageAccessNotAllowed from './components/pageAccessNotAllowed.vue';

import './registerServiceWorker'

const app = createApp(App);
app.use(IonicVue);
app.use(router);
app.mixin(globalMixin);
app.use(store);
app.use(CKEditor);
app.component('master-layout', MasterLayout);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-input', IonInput);
app.component('ion-list', IonList);
app.component('ion-list-header', IonListHeader);
app.component('ion-avatar', IonAvatar);
app.component('ion-grid', IonGrid);
app.component('ion-col', IonCol);
app.component('ion-row', IonRow);
app.component('ion-item-group', IonItemGroup);
app.component('ion-item-divider', IonItemDivider);
app.component('ion-chip', IonChip);
app.component('ion-button', IonButton);
app.component('ion-loading', IonLoading);
app.component('ion-header', IonHeader);
app.component('ion-footer', IonFooter);
app.component('ion-toolbar', IonToolbar);
app.component('ion-buttons', IonButtons);
app.component('ion-icon', IonIcon);
app.component('my-input', myInput);
app.component('page-access-not-allowed', pageAccessNotAllowed);
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('ion-content', IonContent);
app.component('ion-modal', IonModal);
app.component('ion-title', IonTitle);
app.component('ion-page', IonPage);
app.component('ion-card', IonCard);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-content', IonCardContent);
app.component('ion-thumbnail', IonThumbnail);
app.component('ion-action-sheet', IonActionSheet);
app.component('ion-datetime', IonDatetime);
app.component('ion-segment', IonSegment);
app.component('ion-segment-button', IonSegmentButton);
app.component('ion-text', IonText);
app.component('ion-alert', IonAlert);
app.component('ion-badge', IonBadge);

router.isReady().then(() => {
  app.mount('#app');
  if(!process.env.VUE_APP_NODE_ENV.includes('asia_staging')) {
    let pusherScript = document.createElement('script');
    pusherScript.setAttribute('src', 'https://jumper.ai/web-s/resources/js/pusher.min.js');
    document.head.appendChild(pusherScript);
  }
  let platformScript = document.createElement('script');
  platformScript.setAttribute('src', 'https://jumper.ai/web-s/resources/js/platform.js');
  document.head.appendChild(platformScript);
});