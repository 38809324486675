<template>
  <div>
    <ion-page>
      <ion-content class="live-chat-home-container">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)" >
          <ion-refresher-content :pulling-icon="chevronDownCircleOutline" pulling-text="Pull to refresh" refreshing-spinner="circles" refreshing-text="Refreshing..." >
          </ion-refresher-content>
        </ion-refresher>
        <ion-loading v-if="loading && !pullRefreshing" :is-open="loading && !pullRefreshing" cssClass="my-custom-class" message="Loading..." ></ion-loading>
        <ion-toolbar v-if="anySearchOrFilterIsApplied" class="chat-toolbar" color="light" >
          <h2 class="ion-no-margin search-by-text">{{ searchByText }}</h2>
          <ion-buttons slot="end">
            <ion-button size="small" color="dark" @click="clearFilter" > Clear filter </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar v-else class="chat-toolbar" color="light" >
          <b style="margin-left: 10px; font-size: 1.2rem;">Filters</b>
          <ion-buttons slot="end">
            <ion-button @click="() => searchModal = true">
              <ion-icon slot="icon-only" :icon="searchOutline" ></ion-icon>
            </ion-button>
            <ion-button @click="() => filterModal = true">
              <ion-icon slot="icon-only" :icon="funnelOutline" ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <div v-if="!loading || pullRefreshing">
          <div class="text-center" v-if="customersResults.length == 0 && !pullRefreshing" style="width: 100%; padding: 15px;" >
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No customers yet! 😟</h5>
            <ion-text color="medium">Seems like the one you're looking for, hasn't interacted with you yet</ion-text>
          </div>
          <div v-if="customersResults.length > 0 || pullRefreshing">
            <div v-if="show_liveChatConversationsListAreaTabs == true">
              <ion-toolbar color="light">
                <ion-segment scrollable color="dark" v-model="active_conversationsListTab" >
                  <ion-segment-button value="activeChatsTab">
                    <ion-label>
                      <div class="newMessageAlertBubbleForSingleChatTab" v-if="checkAndShowTabSpecificUnreadMessageBubble('activeChatsTab') == true" ></div>
                      Active chats
                    </ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="requestsChatsTab">
                    <ion-label>
                      <div class="newMessageAlertBubbleForSingleChatTab" v-if="checkAndShowTabSpecificUnreadMessageBubble('requestsChatsTab') == true" ></div>
                      Requests
                    </ion-label>
                  </ion-segment-button>
                  <ion-segment-button value="botChatsTab">
                    <ion-label>
                      <div class="newMessageAlertBubbleForSingleChatTab" v-if="checkAndShowTabSpecificUnreadMessageBubble('botChatsTab') == true" ></div>
                      Bot
                    </ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-toolbar>

              <div v-if="active_conversationsListTab == 'activeChatsTab'">
                <ion-list class="ion-no-padding">
                  <ion-item :color="active === customer.conversationid ? 'light' : ''" v-for="customer in activeChatsTab_customersResults" :key="customer.id" @click="customer.unread = 0; $emit('customer', customer.conversationid)" >
                    <singleChatListCard :customer="customer" />
                  </ion-item>
                </ion-list>

                <ion-infinite-scroll @ionInfinite="fetchCustomers($event, activeChatsTab_currentPaginationNumber + 1, false, 'activeChatsTab')" threshold="100px" id="infinite-scroll" :disabled="!activeChatsTab_totalCustomers_loadMore || activeChatsTab_customersResults.length <= 0" >
                  <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more conversations..." >
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

                <div class="text-center" v-if="activeChatsTab_customersResults.length == 0 && activeChatsTab_customersResultsLoading == false" style="width: 100%; padding: 15px;" >
                  <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                  <!-- <h5>No customers yet! 😟</h5> -->
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_activeChatsTab == false" >Click on "Load more" button to view all your active conversations.</ion-text>
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_activeChatsTab == true" >Seems like you don't have any active chats 💬</ion-text>

                  <a style="display: flex; justify-content: center; margin: 30px 0;" v-if="fetchCustomerApiCalledFor_activeChatsTab == false" @click="fetchCustomers(null, activeChatsTab_currentPaginationNumber+1,false,'activeChatsTab')" >Load more</a>
                </div>

                <div class="text-center" v-if="activeChatsTab_customersResults.length == 0 && activeChatsTab_customersResultsLoading == true" style="width: 100%; padding: 15px;" >
                  <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                  <h5>Fetching customers...</h5>
                </div>
              </div>
              <div v-if="active_conversationsListTab == 'requestsChatsTab'">
                <ion-list class="ion-no-padding">
                  <ion-item :color="active === customer.conversationid ? 'light' : ''" v-for="customer in (requestsChatsTab_customersResults || cloneRequestsChatsTab_customersResults)" :key="customer.id" @click="customer.unread = 0; $emit('customer', customer.conversationid)" >
                    <singleChatListCard :customer="customer" />
                  </ion-item>
                </ion-list>

                <ion-infinite-scroll @ionInfinite="fetchCustomers($event, requestsChatsTab_currentPaginationNumber + 1, false, 'requestsChatsTab')" threshold="100px" id="infinite-scroll" :disabled="!requestsChatsTab_totalCustomers_loadMore || requestsChatsTab_customersResults.length <= 0" >
                  <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more conversations..." >
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

                <div class="text-center" v-if="requestsChatsTab_customersResults.length == 0 && requestsChatsTab_customersResultsLoading == false" style="width: 100%; padding: 15px;" >
                  <img
                    src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
                    style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
                  />
                  <!-- <h5>No customers yet! 😟</h5> -->
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_requestsChatsTab == false" >Click on "Load more" button to view all your active conversations.</ion-text>
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_requestsChatsTab == true" >Seems like you don't have any active chats 💬</ion-text>

                  <a style="display: flex; justify-content: center; margin: 30px 0;" v-if="fetchCustomerApiCalledFor_requestsChatsTab == false" @click="fetchCustomers(null, requestsChatsTab_currentPaginationNumber+1,false,'requestsChatsTab')" >Load more</a>
                </div>

                <div class="text-center" v-if="requestsChatsTab_customersResults.length == 0 && requestsChatsTab_customersResultsLoading == true" style="width: 100%; padding: 15px;" >
                  <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                  <h5>Fetching customers...</h5>
                </div>
              </div>
              <div v-if="active_conversationsListTab == 'botChatsTab'">
                <ion-list class="ion-no-padding">
                  <ion-item :color="active === customer.conversationid ? 'light' : ''" v-for="customer in botChatsTab_customersResults" :key="customer.id" @click="customer.unread = 0; $emit('customer', customer.conversationid)" >
                    <singleChatListCard :customer="customer" />
                  </ion-item>
                </ion-list>

                <ion-infinite-scroll @ionInfinite="fetchCustomers($event, botChatsTab_currentPaginationNumber + 1, false, 'botChatsTab')" threshold="100px" id="infinite-scroll" :disabled="!botChatsTab_totalCustomers_loadMore || botChatsTab_customersResults.length <= 0" >
                  <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more conversations..." >
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

                <div class="text-center" v-if="botChatsTab_customersResults.length == 0 && botChatsTab_customersResultsLoading == false" style="width: 100%; padding: 15px;" >
                  <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                  <!-- <h5>No customers yet! 😟</h5> -->
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_botChatsTab == false" >Click on "Load more" button to view all your active conversations.</ion-text>
                  <ion-text color="medium" v-if="fetchCustomerApiCalledFor_botChatsTab == true" >Seems like you don't have any active chats 💬</ion-text>

                  <a style="display: flex; justify-content: center; margin: 30px 0;" v-if="fetchCustomerApiCalledFor_botChatsTab == false" @click="fetchCustomers(null, botChatsTab_currentPaginationNumber+1,false,'botChatsTab')" >Load more</a>
                </div>

                <div class="text-center" v-if="botChatsTab_customersResults.length == 0 && botChatsTab_customersResultsLoading == true" style="width: 100%; padding: 15px;" >
                  <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                  <h5>Fetching customers...</h5>
                </div>
              </div>
            </div>
            <div v-else>
              <ion-list class="ion-no-padding">
                <ion-item :color="active === customer.conversationid ? 'light' : ''" v-for="customer in customersResultsToLoad" :key="customer.id" @click="customer.unread = 0; $emit('customer', customer.conversationid)" >
                  <singleChatListCard :customer="customer" />
                </ion-item>
              </ion-list>
              <ion-infinite-scroll @ionInfinite="fetchCustomers($event, currentPaginationNumber + 1, false)" threshold="100px" id="infinite-scroll" :disabled="!totalCustomers_loadMore" >
                <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more conversations..." >
                </ion-infinite-scroll-content>
              </ion-infinite-scroll>
            </div>
          </div>
        </div>
      </ion-content>
    </ion-page>
    <ion-modal v-if="searchModal" :is-open="searchModal" @didDismiss="dismissSearch()" >
      <ion-page>
        <ion-header>
          <ion-toolbar v-if="searchVal" color="light" >
            <ion-buttons slot="start">
              <ion-button @click="() => searchVal = undefined">
                <ion-icon slot="icon-only" :icon="arrowBack" ></ion-icon>
              </ion-button>
            </ion-buttons>
            <h2 class="ion-no-margin">Search by</h2>
            <ion-buttons slot="end">
              <ion-button @click="dismissSearch">
                <ion-icon slot="icon-only" :icon="close" ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar v-else>
            <h2 class="ion-no-margin">Search by</h2>
            <ion-buttons slot="end">
              <ion-button @click="dismissSearch">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list v-if="searchVal" class="ion-no-padding" >
            <ion-item lines="none" v-if="searchVal === 'Name / email'" >
              <ion-label position="stacked">Name / email</ion-label>
              <ion-input type="text" class="fullBordered" v-model="searchByNameOrEmailText" ></ion-input>
            </ion-item>
            <ion-item lines="none" v-if="searchVal === 'Order ID'" >
              <ion-label position="stacked">Order ID</ion-label>
              <ion-input type="text" class="fullBordered" v-model="searchByOrderIdText" ></ion-input>
            </ion-item>
            <ion-item lines="none" v-if="searchVal === 'Tag'" >
              <ion-label position="stacked">Tag</ion-label>
              <ion-input type="text" class="fullBordered" v-model="searchByTagText" ></ion-input>
            </ion-item>

            <ion-item lines="none" v-if="searchVal === 'Custom field'" >
              <ion-label position="stacked">Custom Field</ion-label>
              <ion-input type="text" class="fullBordered" v-model="searchByCustomFieldKey" ></ion-input>
              <ion-label position="stacked">Custom Field Value</ion-label>
              <ion-input type="text" class="fullBordered" v-model="searchByCustomFieldText" ></ion-input>
            </ion-item>

            <section style="padding: 15px;">
              <ion-button expand="block" size="default" :disabled="isSearchDisabled" @click="search" >Search</ion-button>
            </section>
          </ion-list>
          <ion-list v-else class="ion-no-padding" >
            <ion-item @click="searchVal = 'Name / email'">
              <ion-label>Name / email</ion-label>
            </ion-item>
            <ion-item @click="searchVal = 'Order ID'">
              <ion-label>Order ID</ion-label>
            </ion-item>
            <ion-item @click="searchVal = 'Tag'">
              <ion-label>Tag</ion-label>
            </ion-item>
            <ion-item @click="searchVal = 'Custom field'">
              <ion-label>Custom field</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal v-if="filterModal" :is-open="filterModal" @didDismiss="dismissFilter()" >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Filter by</h2>
            <ion-buttons slot="end">
              <ion-button @click="dismissFilter()">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item-group v-if="agentFocusedMode == false">
            <ion-item-divider>
              <ion-label>Assigned to</ion-label>
            </ion-item-divider>

            <ion-item @click="filter('assigned_to', 'me'); filterVal = 'Assigned to me';" v-if="agentFocusedMode == false" >
              <ion-label>Me</ion-label>
            </ion-item>
            <!-- <ion-item @click="filter('assigned_to', 'agent'); filterVal = 'Assigned to agent';" v-if="agentFocusedMode == false && allAgentsResult.length > 0">
              <ion-label>Agent</ion-label>
            </ion-item> -->
            <ion-item @click="filter('assigned_to', 'bot'); filterVal = 'Assigned to bot';" v-if="agentFocusedMode == false" >
              <ion-label>Bot</ion-label>
            </ion-item>
            <ion-item @click="filter('assigned_to', 'unassigned'); filterVal = 'Unassigned';" v-if="agentFocusedMode == false" >
              <ion-label>No one (Unassigned)</ion-label>
            </ion-item>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Chat status</ion-label>
            </ion-item-divider>

            <ion-item @click="filter('chat_status', 'followup'); filterVal = 'Status - Follow-up';">
              <ion-label>Follow-up</ion-label>
            </ion-item>
            <ion-item @click="filter('chat_status', 'resolved'); filterVal = 'Status - Resolved';" v-if="agentFocusedMode == false" >
              <ion-label>Resolved</ion-label>
            </ion-item>
          </ion-item-group>

          <ion-item-group v-if="agentFocusedMode == false">
            <ion-item-divider>
              <ion-label>Channel</ion-label>
            </ion-item-divider>

            <ion-item @click="filter('channel', 'facebook'); filterVal = 'Channel - Messenger';" v-if="messengerConnected == true && agentFocusedMode == false" >
              <ion-label>Messenger</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/messenger.png">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'imessage'); filterVal = 'Channel - iMessage';" v-if="imessageConnected == true && agentFocusedMode == false" >
              <ion-label>iMessage</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/imessage-logo.png">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'viber'); filterVal = 'Channel - Viber';" v-if="viberConnected == true && agentFocusedMode == false" >
              <ion-label>Viber</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/viber.png">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'telegram'); filterVal = 'Channel - Telegram';" v-if="telegramConnected == true && agentFocusedMode == false" >
              <ion-label>Telegram</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'sms'); filterVal = 'Channel - SMS';" v-if="smsConnected == true && agentFocusedMode == false" >
              <ion-label>SMS</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'googlebusinessmsg'); filterVal = 'Channel - Google business messages';" v-if="gBusinessMessagesConnected == true && agentFocusedMode == false" >
              <ion-label>Google business messages</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'line'); filterVal = 'Channel - LINE';" v-if="lineConnected == true && agentFocusedMode == false" >
              <ion-label>LINE</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/line.svg">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'twitter'); filterVal = 'Channel - Twitter';" v-if="twitterConnected == true && agentFocusedMode == false" >
              <ion-label>Twitter</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/twitter.svg">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'whatsapp'); filterVal = 'Channel - WhatsApp';" v-if="whatsappConnected == true && agentFocusedMode == false" >
              <ion-label>WhatsApp</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/whatsapp.svg">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'adlingo'); filterVal = 'Channel - AdLingo';" v-if="adlingoConnected == true && agentFocusedMode == false" >
              <ion-label>AdLingo</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/web-s/resources/images/adlingo-logo-square.png">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'instagram'); filterVal = 'Channel - Instagram';" v-if="instagramConnected == true && agentFocusedMode == false" >
              <ion-label>Instagram</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/landing/resources/images/instagram.png">
              </ion-thumbnail>
            </ion-item>
            <ion-item @click="filter('channel', 'webbot'); filterVal = 'Channel - Web';" v-if="agentFocusedMode == false" >
              <ion-label>Web</ion-label>
              <ion-thumbnail slot="end" class="filterByChannel" >
                <img src="https://jumper.ai/web-s/resources/images/sc-web-sell-via-chatbox.svg">
              </ion-thumbnail>
            </ion-item>
          </ion-item-group>
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jwtInterceptor from "/src/shared/jwtInterceptor";
import {
  chevronDownCircleOutline,
  close,
  arrowBack,
  searchOutline,
  funnelOutline,
} from "ionicons/icons";
/* import mixins */
import chatMixin from "../mixins/chat.js";
/* import components */
import singleChatListCard from "../components/singleChatListCard.vue";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";

export default {
  mixins: [chatMixin],
  components: { singleChatListCard, IonInfiniteScroll, IonInfiniteScrollContent, },
  emits: ["customer", "onFilterCustomers", "onClearFilter"],
  props: {
    active: { type: String, default: undefined, },
  },
  data() {
    return {
      loading: false,
      searchOutline,
      funnelOutline,
      chevronDownCircleOutline,
      close,
      arrowBack,
      totalCustomers_loadMore: false,
      activeChatsTab_totalCustomers_loadMore: false,
      requestsChatsTab_totalCustomers_loadMore: false,
      botChatsTab_totalCustomers_loadMore: false,
      activeCustomer: {},
      activeUserConversationDataResult: [],
      customersResults: [],
      activeChatsTab_customersResults: [],
      requestsChatsTab_customersResults: [],
      botChatsTab_customersResults: [],
      selectedChannel: "all",
      customersResultsLoading: true,
      activeChatsTab_customersResultsLoading: true,
      requestsChatsTab_customersResultsLoading: true,
      botChatsTab_customersResultsLoading: true,
      fetchCustomerApiCalledFor_activeChatsTab: false,
      fetchCustomerApiCalledFor_requestsChatsTab: false,
      fetchCustomerApiCalledFor_botChatsTab: false,
      currentPaginationNumber: 1,
      activeChatsTab_currentPaginationNumber: 1,
      requestsChatsTab_currentPaginationNumber: 1,
      botChatsTab_currentPaginationNumber: 1,
      searchByNameOrEmailText: "",
      searchByOrderIdText: "",
      searchByTagText: "",
      searchByCustomFieldText: "",
      searchByCustomFieldKey: "",
      searchByAssignedToMeText: false,
      searchByAssignedToBotText: false,
      searchByAssignedToAgentText: "",
      searchByAssignedToAgentID: "",
      searchByUnassignedConvoText: false,
      searchByFollowupText: false,
      searchByArchivedUsersText: false,
      nextCursor: undefined,
      activeChatsTab_nextCursor: undefined,
      requestsChatsTab_nextCursor: undefined,
      botChatsTab_nextCursor: undefined,
      anySearchOrFilterIsApplied: false,
      searchModal: false,
      filterModal: false,
      filterVal: undefined,
      searchVal: undefined,
      convListOffset: 10,
      agentFocusedMode: false,
      show_liveChatConversationsListAreaTabs: false,
      liveChatConversationsView_type: "1tabView",
      active_conversationsListTab: "activeChatsTab",
      pusher_channel_liveChat_IG_reaction: undefined,
      pusher_channel_liveChat_IG_ig_unsend_msg: undefined,
      latestVertexIsForPayment: false,
      normalPusherNotifications: [],
      pullRefreshing: false,
      cloneCustomersResults: [],
    };
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
    isSearchDisabled() {
      const isCustomSearch = !!this.searchByCustomFieldKey.trim() && !!this.searchByCustomFieldText.trim();
      const isNormalSearch = !!this.searchByNameOrEmailText.trim() || !!this.searchByOrderIdText.trim() || !!this.searchByTagText.trim();
      return !isCustomSearch && !isNormalSearch;
    },
    customersResultsToLoad() {
      if (this.customersResults.length) {
        return this.customersResults;
      }
      return this.cloneCustomersResults;
    },
    searchByText() {
      if (this.filterVal?.trim()) {
        return `Filter by: ${this.filterVal}`;
      } else if (this.searchByCustomFieldKey) {
        return `Search ${this.searchByCustomFieldKey} by: ${this.searchByCustomFieldText}`;
      } else {
        const searchValue = this.searchByNameOrEmailText?.trim() || this.searchByOrderIdText?.trim() || this.searchByTagText?.trim();
        return `Search by: ${searchValue}`;
      }
    },
  },
  async mounted() {
    await this.init();
    document.addEventListener("new-message", this.newMessageHandler, true);
    document.addEventListener( "new-message-via-agent-routing", this.newMessageHandler_via_agentRouting, true );
    document.addEventListener( "remove-conversation", this.removeConversationHandler, true );
    document.addEventListener( "reassign-conversation", this.reassignConversationHandler, true );
  },
  methods: {
    async init() {
      //---enterprise/account based settings start
      if (
        this.accountType_master == true || this.user.enterprise_Name == "frisogold_my"
      ) {
        this.liveChatConversationsView_type = "1tabView";
        this.show_liveChatConversationsListAreaTabs = false;
      } else {
        this.liveChatConversationsView_type = "3tabView";
        this.show_liveChatConversationsListAreaTabs = true;
      }

      if ( this.agentRoutingEnabled_accountLevel == true && this.accountType_master == false ) {
        this.agentFocusedMode = true;

        //pending - @kru
        //this.getAgentStatus();
      } else {
        this.agentFocusedMode = false;
      }
      //---enterprise/account based settings end

      this.empty_customersResults_like_arrays();

      if (this.liveChatConversationsView_type === "3tabView") {
        await this.fetchCustomers(null, 0, true, "activeChatsTab");
      } else {
        await this.fetchCustomers(null, 0, true);
      }
      //this.loading = false;
    },
    async newMessageHandler(e) {
      this.parseNewConversationDataReceivedFromPusher(e.detail, false);
    },
    async newMessageHandler_via_agentRouting(e) {
      this.parseNewConversationDataReceivedFromPusher(e.detail, true);
    },
    async removeConversationHandler(e) {
      this.hideResolvedConversationFromLiveChat(e.detail);
    },
    async reassignConversationHandler(e) {
      this.hideReAssignedConversationFromLiveChat(e.detail);
    },
    async fetchCustomers(event, pageOffset, firstTime, tabBasedFilter) {
      try {
        //this.loading = true;
        if (!event) {
          this.loading = true;
        }

        if (typeof tabBasedFilter == "undefined") {
          tabBasedFilter = "";
        }

        var pageSize = 12;
        if (firstTime == true && tabBasedFilter == "") {
          pageSize = 12;
        } else if (tabBasedFilter == "" && pageOffset == 0) {
          pageSize = 12;
        }

        var reqBody = {
          channel: this.selectedChannel,
          size: pageSize,
          retarget: "false",
        };

        if (typeof pageOffset != "undefined") {
          if (pageOffset > 1) {
            reqBody["offset"] = this.pageSize * (pageOffset - 1);
          }
        }
        if (!pageOffset) {
          if (tabBasedFilter == "activeChatsTab") {
            this.activeChatsTab_nextCursor = null;
          } else if (tabBasedFilter == "requestsChatsTab") {
            this.requestsChatsTab_nextCursor = null;
          } else if (tabBasedFilter == "botChatsTab") {
            this.botChatsTab_nextCursor = null;
          } else {
            this.nextCursor = null;
          }
        }

        if (tabBasedFilter == "activeChatsTab") {
          if (this.activeChatsTab_nextCursor) {
            reqBody["cursor"] = this.activeChatsTab_nextCursor;
          }
        } else if (tabBasedFilter == "requestsChatsTab") {
          if (this.requestsChatsTab_nextCursor) {
            reqBody["cursor"] = this.requestsChatsTab_nextCursor;
          }
        } else if (tabBasedFilter == "botChatsTab") {
          if (this.botChatsTab_nextCursor) {
            reqBody["cursor"] = this.botChatsTab_nextCursor;
          }
        } else {
          if (this.nextCursor) {
            reqBody["cursor"] = this.nextCursor;
          }
        }

        this.anySearchOrFilterIsApplied = false;

        if (this.searchByTagText != "") {
          this.anySearchOrFilterIsApplied = true;

          reqBody["tags"] = this.searchByTagText;

          reqBody["retarget"] = "both";
        } else if (
          this.searchByCustomFieldKey != "" &&
          this.searchByCustomFieldText != ""
        ) {
          this.anySearchOrFilterIsApplied = true;

          var arrData = [];
          var name = this.searchByCustomFieldKey.trim();
          var value = this.searchByCustomFieldText.trim();
          var objData = {
            name: name,
            value: value,
          };
          arrData.push(objData);

          reqBody["custom_tags"] = JSON.stringify(arrData);

          reqBody["retarget"] = "both";
        } else if (this.searchByNameOrEmailText != "") {
          this.anySearchOrFilterIsApplied = true;

          reqBody["search_string"] = this.searchByNameOrEmailText.toLowerCase();

          reqBody["retarget"] = "both";
        } else if (this.searchByOrderIdText != "") {
          this.anySearchOrFilterIsApplied = true;

          reqBody["orderid"] = this.searchByOrderIdText;

          reqBody["retarget"] = "both";
        } else if (
          this.searchByAssignedToMeText == true ||
          tabBasedFilter == "activeChatsTab"
        ) {
          if (tabBasedFilter != "activeChatsTab") {
            this.anySearchOrFilterIsApplied = true;
          }

          reqBody["assigned_to_me"] = "0";
        } else if (this.searchByFollowupText == true) {
          this.anySearchOrFilterIsApplied = true;

          reqBody["followup"] = "true";
        } else if (this.searchByArchivedUsersText == true) {
          this.anySearchOrFilterIsApplied = true;

          reqBody["retarget"] = "true";
        } else if (
          this.searchByAssignedToBotText == true ||
          tabBasedFilter == "botChatsTab"
        ) {
          if (tabBasedFilter != "botChatsTab") {
            this.anySearchOrFilterIsApplied = true;
          }

          reqBody["agent"] = "bot";
        } else if (this.searchByAssignedToAgentID != "") {
          this.anySearchOrFilterIsApplied = true;

          reqBody["agent"] = this.searchByAssignedToAgentID;
        } else if (
          this.searchByUnassignedConvoText == true ||
          tabBasedFilter == "requestsChatsTab"
        ) {
          if (tabBasedFilter != "requestsChatsTab") {
            this.anySearchOrFilterIsApplied = true;
          }

          reqBody["agent"] = "unassigned";
        }

        if (this.agentFocusedMode == true) {
          this.anySearchOrFilterIsApplied = true;

          reqBody["assigned_to_me"] = "0";

          this.show_liveChatConversationsListAreaTabs = false;
        } else {
          if (
            this.anySearchOrFilterIsApplied == true ||
            this.selectedChannel != "all"
          ) {
            this.anySearchOrFilterIsApplied = true;
            this.show_liveChatConversationsListAreaTabs = false;
          } else {
            if (
              this.accountType_master == true ||
              this.user.enterprise_Name == "frisogold_my"
            ) {
              this.show_liveChatConversationsListAreaTabs = false;
            } else {
              this.show_liveChatConversationsListAreaTabs = true;
            }
          }
        }

        const response = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fbrt/user-list",
          reqBody
        );
        console.log(response);
        if (response.status == 200 || response.status == 201) {
          if (
            tabBasedFilter == "" &&
            this.anySearchOrFilterIsApplied == false &&
            pageOffset == 0
          ) {
            this.fetchCustomerApiCalledFor_activeChatsTab = false;
            this.fetchCustomerApiCalledFor_requestsChatsTab = false;
            this.fetchCustomerApiCalledFor_botChatsTab = false;
          } else if (tabBasedFilter == "activeChatsTab") {
            this.fetchCustomerApiCalledFor_activeChatsTab = true;
          } else if (tabBasedFilter == "requestsChatsTab") {
            this.fetchCustomerApiCalledFor_requestsChatsTab = true;
          } else if (tabBasedFilter == "botChatsTab") {
            this.fetchCustomerApiCalledFor_botChatsTab = true;
          }

          if (tabBasedFilter == "activeChatsTab") {
            this.activeChatsTab_nextCursor = response.data.next;
          } else if (tabBasedFilter == "requestsChatsTab") {
            this.requestsChatsTab_nextCursor = response.data.next;
          } else if (tabBasedFilter == "botChatsTab") {
            this.botChatsTab_nextCursor = response.data.next;
          } else {
            this.nextCursor = response.data.next;
          }

          for (var user of response.data.data) {
            if (typeof user.conversationid == "undefined") {
              user.conversationid = user.id;
            }

            if (user.name == "" || user.name == null || user.name == "None") {
              user.name = "unrecorded";
            }

            if (
              user.email == "" ||
              user.email == null ||
              user.email == "None"
            ) {
              user.email = "unrecorded";
            }

            user["unread"] = 0;

            var conversationidFoundBool = false;

            for (var i = 0; i < this.customersResults.length; i++) {
              if (
                user.conversationid == this.customersResults[i].conversationid
              ) {
                conversationidFoundBool = true;
              }
            }

            if (conversationidFoundBool == false) {
              if (typeof user.active != "undefined") {
                if (user.active == null) {
                  user.active = true;
                }
              }

              var obj = user;
              obj.id = user.conversationid;
              obj.currentlyOpendChat = false;
              obj.channel_id = obj.channel;
              obj.channel = this.getChannelFromPageActive(obj.channel);

              if (
                this.calculate24Plus1RuleForCustomer(user.userReplyTimestamp) >=
                24
              ) {
                if ( obj.channel == "facebook" || obj.channel == "whatsapp" || obj.channel == "instagram_off" ) {
                  //obj.active = false;
                  obj.page_msg_subscription_restriction = true;

                  if (obj.channel == "whatsapp") {
                    //this.isWhatsappChat_old24H = true;
                  }
                } else {
                  obj.page_msg_subscription_restriction = false;

                  if (obj.channel == "whatsapp") {
                    //this.isWhatsappChat_old24H = false;
                    //this.isWhatsappChat_sendTemplate = false;
                  }
                }
              } else {
                obj.page_msg_subscription_restriction = false;

                if (obj.channel == "whatsapp") {
                  //this.isWhatsappChat_old24H = false;
                  //this.isWhatsappChat_sendTemplate = false;
                }
              }

              //pending: for later when we merge conversations list view & single detailed view
              /* if(this.pageInboxPriorityConversationId != obj.conversationid){
                //pending: here we can filter out customers whos channel is not permitted to loggedin agent
                this.customersResults.push(obj);
                this.checkAndAddTheSingleCustomerDataToRelevantTabArray(obj,'push');
              } */
              this.customersResults.push(obj);
              if (tabBasedFilter == "activeChatsTab") {
                this.activeChatsTab_customersResults.push(obj);
              } else if (tabBasedFilter == "requestsChatsTab") {
                this.requestsChatsTab_customersResults.push(obj);
              } else if (tabBasedFilter == "botChatsTab") {
                this.botChatsTab_customersResults.push(obj);
              } else {
                this.checkAndAddTheSingleCustomerDataToRelevantTabArray( obj, "push" );
              }
            }
          }

          if (typeof pageOffset != "undefined") {
            if (pageOffset == 1) {
              if (tabBasedFilter == "activeChatsTab") {
                this.activeChatsTab_currentPaginationNumber = 1;
              } else if (tabBasedFilter == "requestsChatsTab") {
                this.requestsChatsTab_currentPaginationNumber = 1;
              } else if (tabBasedFilter == "botChatsTab") {
                this.botChatsTab_currentPaginationNumber = 1;
              } else {
                this.currentPaginationNumber = 1;
              }
            } else if (pageOffset != 0) {
              if (tabBasedFilter == "activeChatsTab") {
                this.activeChatsTab_currentPaginationNumber = pageOffset;
              } else if (tabBasedFilter == "requestsChatsTab") {
                this.requestsChatsTab_currentPaginationNumber = pageOffset;
              } else if (tabBasedFilter == "botChatsTab") {
                this.botChatsTab_currentPaginationNumber = pageOffset;
              } else {
                this.currentPaginationNumber = pageOffset;
              }
            }
          }

          if (response.data.data.length > 0) {
            this.customersResultsLoading = false;
            this.activeChatsTab_customersResultsLoading = false;
            this.requestsChatsTab_customersResultsLoading = false;
            this.botChatsTab_customersResultsLoading = false;

            if (response.data.next) {
              if (tabBasedFilter == "activeChatsTab") {
                this.activeChatsTab_totalCustomers_loadMore = true;
              } else if (tabBasedFilter == "requestsChatsTab") {
                this.requestsChatsTab_totalCustomers_loadMore = true;
              } else if (tabBasedFilter == "botChatsTab") {
                this.botChatsTab_totalCustomers_loadMore = true;
              } else {
                this.totalCustomers_loadMore = true;

                if (pageOffset == 0) {
                  this.activeChatsTab_totalCustomers_loadMore = true;
                  this.requestsChatsTab_totalCustomers_loadMore = true;
                  this.botChatsTab_totalCustomers_loadMore = true;
                }
              }
            } else {
              if (tabBasedFilter == "activeChatsTab") {
                this.activeChatsTab_totalCustomers_loadMore = false;
              } else if (tabBasedFilter == "requestsChatsTab") {
                this.requestsChatsTab_totalCustomers_loadMore = false;
              } else if (tabBasedFilter == "botChatsTab") {
                this.botChatsTab_totalCustomers_loadMore = false;
              } else {
                this.totalCustomers_loadMore = false;

                if (pageOffset == 0) {
                  this.activeChatsTab_totalCustomers_loadMore = false;
                  this.requestsChatsTab_totalCustomers_loadMore = false;
                  this.botChatsTab_totalCustomers_loadMore = false;
                }
              }
            }
          } else {
            this.customersResultsLoading = false;
            this.activeChatsTab_customersResultsLoading = false;
            this.requestsChatsTab_customersResultsLoading = false;
            this.botChatsTab_customersResultsLoading = false;

            if (tabBasedFilter == "activeChatsTab") {
              this.activeChatsTab_totalCustomers_loadMore = false;
            } else if (tabBasedFilter == "requestsChatsTab") {
              this.requestsChatsTab_totalCustomers_loadMore = false;
            } else if (tabBasedFilter == "botChatsTab") {
              this.botChatsTab_totalCustomers_loadMore = false;
            } else {
              this.totalCustomers_loadMore = false;

              if (pageOffset == 0) {
                this.activeChatsTab_totalCustomers_loadMore = false;
                this.requestsChatsTab_totalCustomers_loadMore = false;
                this.botChatsTab_totalCustomers_loadMore = false;
              }
            }
          }

          //pending: @kru
          //this.setUnreadCountsOnPageLoadFromLocalStorage();

          if ( tabBasedFilter == "" && this.anySearchOrFilterIsApplied == false && pageOffset == 0 && this.show_liveChatConversationsListAreaTabs == true ) {
            //calling this to get more conversations if "assigned to me"
            this.fetchCustomers( null, this.activeChatsTab_currentPaginationNumber + 1, false, "activeChatsTab" );
          }

          if ( this.anySearchOrFilterIsApplied == false && this.show_liveChatConversationsListAreaTabs == true ) {
            if (tabBasedFilter == "activeChatsTab") {
              this.fetchCustomers(null, 0, firstTime, "requestsChatsTab");
            } else if (tabBasedFilter == "requestsChatsTab") {
              this.fetchCustomers(null, 0, firstTime, "botChatsTab");
            } else if (tabBasedFilter == "botChatsTab") {
              //do nothing
            }
          }

          if ( (firstTime == true && this.show_liveChatConversationsListAreaTabs == true && tabBasedFilter == "botChatsTab") || (firstTime == true && this.show_liveChatConversationsListAreaTabs == false) ) {
            //pending: @kru
            //this.fetchTagCloudOfMerchant();
            //this.fetchCustomFieldCloudOfMerchant();
          }
        } else {
          console.log("Response:", response);

          this.loading = false;
          this.customersResultsLoading = false;
          this.activeChatsTab_customersResultsLoading = false;
          this.requestsChatsTab_customersResultsLoading = false;
          this.botChatsTab_customersResultsLoading = false;

          if (tabBasedFilter == "activeChatsTab") {
            this.fetchCustomerApiCalledFor_activeChatsTab = true;
          } else if (tabBasedFilter == "requestsChatsTab") {
            this.fetchCustomerApiCalledFor_requestsChatsTab = true;
          } else if (tabBasedFilter == "botChatsTab") {
            this.fetchCustomerApiCalledFor_botChatsTab = true;
          }

          this.activeChatsTab_totalCustomers_loadMore = false;
          this.requestsChatsTab_totalCustomers_loadMore = false;
          this.botChatsTab_totalCustomers_loadMore = false;
          this.totalCustomers_loadMore = false;
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
        if (event) event.target.complete();
      }
    },
    pullRefreshingHandler() {
      this.pullRefreshing = !this.pullRefreshing;
    },
    async doRefresh(event) {
      this.pullRefreshingHandler();
      this.cloneCustomersResults = [...this.customersResults];
      await this.init();
      this.cloneCustomersResults = [this.cloneCustomersResults[0]];
      this.pullRefreshingHandler();
      event.target.complete();
    },
    async search() {
      this.filterVal = undefined;

      if (this.searchVal != "Name / email") {
        this.searchByNameOrEmailText = "";
      }
      if (this.searchVal != "Order ID") {
        this.searchByOrderIdText = "";
      }
      if (this.searchVal != "Tag") {
        this.searchByTagText = "";
      }
      this.searchByAssignedToAgentText = "";
      this.searchByAssignedToAgentID = "";
      this.selectedChannel = "all";
      this.searchByAssignedToMeText = false;
      this.searchByAssignedToBotText = false;
      this.searchByUnassignedConvoText = false;
      this.searchByFollowupText = false;
      this.searchByArchivedUsersText = false;

      this.searchModal = false;
      this.$emit("onFilterCustomers");

      this.empty_customersResults_like_arrays();
      await this.fetchCustomers(null, 0, false);
    },
    async filter(method, type) {
      this.searchVal = undefined;

      this.searchByNameOrEmailText = "";
      this.searchByOrderIdText = "";
      this.searchByTagText = "";
      this.searchByAssignedToAgentText = "";
      this.searchByAssignedToAgentID = "";
      if (method == "channel") {
        this.selectedChannel = type;
      } else {
        this.selectedChannel = "all";
      }
      if (method == "assigned_to" && type == "me") {
        this.searchByAssignedToMeText = true;
      } else {
        this.searchByAssignedToMeText = false;
      }
      if (method == "assigned_to" && type == "bot") {
        this.searchByAssignedToBotText = true;
      } else {
        this.searchByAssignedToBotText = false;
      }
      if (method == "assigned_to" && type == "unassigned") {
        this.searchByUnassignedConvoText = true;
      } else {
        this.searchByUnassignedConvoText = false;
      }
      if (method == "chat_status" && type == "followup") {
        this.searchByFollowupText = true;
      } else {
        this.searchByFollowupText = false;
      }
      if (method == "chat_status" && type == "resolved") {
        this.searchByArchivedUsersText = true;
      } else {
        this.searchByArchivedUsersText = false;
      }

      this.filterModal = false;
      this.$emit("onFilterCustomers");

      this.empty_customersResults_like_arrays();
      await this.fetchCustomers(null, 0, false);
    },
    async clearFilter() {
      this.$emit("onClearFilter");
      this.searchVal = undefined;
      this.filterVal = undefined;

      this.searchByNameOrEmailText = "";
      this.searchByOrderIdText = "";
      this.searchByTagText = "";
      this.searchByAssignedToAgentText = "";
      this.searchByAssignedToAgentID = "";
      this.selectedChannel = "all";
      this.searchByCustomFieldKey = "";
      this.searchByCustomFieldText = "";
      this.searchByAssignedToMeText = false;
      this.searchByAssignedToBotText = false;
      this.searchByUnassignedConvoText = false;
      this.searchByFollowupText = false;
      this.searchByArchivedUsersText = false;

      this.empty_customersResults_like_arrays();

      if (this.liveChatConversationsView_type === "3tabView") {
        await this.fetchCustomers(null, 0, false, "activeChatsTab");
      } else {
        await this.fetchCustomers(null, 0, false);
      }
    },
    dismissSearch() {
      setTimeout(() => {
        this.searchModal = false;
        this.searchVal = undefined;
      }, 500);
    },
    dismissFilter() {
      console.log("Dismiss filter");
      setTimeout(() => {
        this.filterModal = false;
      }, 500);
    },
    empty_customersResults_like_arrays() {
      this.customersResultsLoading = true;
      this.activeChatsTab_customersResultsLoading = true;
      this.requestsChatsTab_customersResultsLoading = true;
      this.botChatsTab_customersResultsLoading = true;
      this.customersResults = [];
      this.activeChatsTab_customersResults = [];
      this.requestsChatsTab_customersResults = [];
      this.botChatsTab_customersResults = [];
      this.activeCustomer = {};
    },
    distribute_customersResults_intoTabs(data) {
      if (typeof data != "undefined") {
        if (this.show_liveChatConversationsListAreaTabs == true) {
          this.activeChatsTab_customersResults = [];
          this.requestsChatsTab_customersResults = [];
          this.botChatsTab_customersResults = [];

          for (var i = 0; i < data.length; i++) {
            if (this.checkIfChatIsAssignedToMe(data[i]) == true) {
              this.activeChatsTab_customersResults.push(data[i]);
            } else if (this.checkIfChatIsUnassigned(data[i]) == true) {
              this.requestsChatsTab_customersResults.push(data[i]);
            } else if (this.checkIfChatIsAssignedToBot(data[i]) == true) {
              this.botChatsTab_customersResults.push(data[i]);
            }
          }
        }
      }
    },
    markRead(id) {
      for (let i = 0; i < this.customersResults.length; i++) {
        if (id === this.customersResults[i].conversationid) {
          this.customersResults[i].read = true;
          break;
        }
      }
      for (let i = 0; i < this.activeChatsTab_customersResults.length; i++) {
        if (id === this.activeChatsTab_customersResults[i].conversationid) {
          this.activeChatsTab_customersResults[i].read = true;
          break;
        }
      }
      for (let i = 0; i < this.requestsChatsTab_customersResults.length; i++) {
        if (id === this.requestsChatsTab_customersResults[i].conversationid) {
          this.requestsChatsTab_customersResults[i].read = true;
          break;
        }
      }
      for (let i = 0; i < this.botChatsTab_customersResults.length; i++) {
        if (id === this.botChatsTab_customersResults[i].conversationid) {
          this.botChatsTab_customersResults[i].read = true;
          break;
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener("new-message", this.newMessageHandler, true);
    document.removeEventListener(
      "new-message-via-agent-routing",
      this.newMessageHandler_via_agentRouting,
      true
    );
    document.removeEventListener(
      "remove-conversation",
      this.removeConversationHandler,
      true
    );
    document.removeEventListener(
      "reassign-conversation",
      this.reassignConversationHandler,
      true
    );
  },
};
</script>
<style scoped>
.search-by-text {
  font-size: 14px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 780px) {
  .live-chat-home-container {
    border-right: 1px solid;
    border-color: var(--ion-color-medium, #92949c);
  }
}
</style>>