<template>
  <master-layout pageTitle="Business Profile">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div style="position: sticky; top: 0px; z-index: 2;" v-if="userAccChanged">
          <ion-item lines="none">
            <p class="connectNote">
              <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
              You have pending changes to be saved, please click on "Save all changes" button!
            </p>
            <ion-button slot="end" @click="saveUserChanges" color="dark">Save</ion-button>
          </ion-item>
        </div>
        <div class="container" style="margin-left: 25px">
          <h2 class="title ion-no-padding ion-no-margin">Business details</h2>
          <ion-subtitle class="sub-title">Setup and manage your business profile here.</ion-subtitle>
        </div>
        <div class="container">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col size-xl="6" size-lg="6"  size-sm="12" size-xs="12">
                <ion-card class="model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Brand logo</h2>
                    <ion-subtitle class="cardSubtitle">This logo will appear on your web store, chatbot and emails sent to customers.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <div class="img-sctn">
                      <input type="file" style="display: none;" id="brandLogoInput" ref="brandLogoInput" @change="handleImageUpload" accept="image/*" />
                      <div v-if="userAccount.shopLogo && userAccount.shopLogo.length" lines="none">
                        <ion-avatar @click="() => $refs.brandLogoInput.click()" style="display: inline-block; height: 120px; width: 120px;">
                          <img :src="userAccount.shopLogo" style="height: 120px; width: 120px;">
                        </ion-avatar>
                      </div>
                    </div>
                    <div class="img-sctn" style="margin: 5px 0px">
                      <p>Recommended size: 500px x 500px</p>
                    </div>
                    <div class="img-sctn">
                     <ion-button fill="solid" size="default" color="dark" @click="() => $refs.brandLogoInput.click()">
                            Upload
                     </ion-button>
                    </div>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections" style="height: 288px">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Store details</h2>
                    <ion-subtitle class="cardSubtitle">Your brand name and web URL appear in your conversations, order summary and emails. You can update these details anytime.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none" style="margin-bottom: 15px">
                      <ion-icon slot="start" :icon="personOutline" style="margin-inline-end: 10px"></ion-icon>
                      <div class="input-wrapper">
                        <div class="label">User Name</div>
                        <ion-input type="text" v-model="userAccount.name" placeholder="Name" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </div>
                    </ion-item>
                    <ion-item lines="none" style="margin-bottom: 15px">
                      <ion-icon slot="start" :icon="briefcaseOutline" style="margin-inline-end: 10px"></ion-icon>
                      <div class="input-wrapper">
                        <div class="label">Shop Name</div>
                        <ion-input type="text" v-model="userAccount.businessName" placeholder="Business Name" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </div>
                    </ion-item>
                    <ion-item v-if="IS_ASIA" lines="none" style="margin-bottom: 15px">
                      <ion-icon slot="start" :icon="briefcaseOutline" style="margin-inline-end: 10px"></ion-icon>
                      <div class="input-wrapper">
                        <div class="label">Referral Shop Name</div>
                        <ion-input type="text" v-model="userAccount.referallshopName" placeholder="Business Name" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </div>
                    </ion-item>
                    <ion-item v-else lines="none">
                      <ion-row class="ion-align-items-center">
                        <ion-icon style="margin-right: 30px; margin-inline-end: 10px" :icon="linkOutline"></ion-icon>
                        <div class="linkSctn" style="margin-right: 10px">https://</div>
                        <ion-input type="text"  v-model="userAccount.referallshopName" placeholder="Link Address" class="fullBordered input_custom_style sctn-input"></ion-input>
                        <div class="linkSctn" style="margin-left:10px ">.{{ HOST_NAME }}</div>
                      </ion-row>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Account email (login email)</h2>
                    <ion-subtitle class="cardSubtitle">The email using which you want to login into your dashboard. You can update it anytime.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="mailOutline" class="imgIcon" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input type="text" v-model="userAccount.email" placeholder="Login Email" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
               <ion-card class="ion-no-padding model-sections">
                <ion-card-header>
                  <h2 class="cardTitle ion-no-padding ion-no-margin">Primary contact number</h2>
                  <ion-subtitle class="cardSubtitle">Your primary contact number (Your customers will not see this if you have entered a number for support).</ion-subtitle>
                </ion-card-header>
                <ion-card-content>
                  <ion-row>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="globeOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-select v-model="userAccount.mobilecountrycode">
                        <ion-select-option disabled :value="undefined">Select Country Code</ion-select-option>
                        <ion-select-option v-for="(thisCountry, thisCode) in countryCodesData.countryCodesData" :key="thisCode" :value="thisCode">{{ thisCountry.name }}</ion-select-option>
                      </ion-select>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="callOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input type="number" v-model="userAccount.mobileNumber" placeholder="Primary Mobile Number" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                  </ion-row>
                </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Business email</h2>
                    <ion-subtitle>Your customers will receive communication for welcome, verification, order confirmation, etc. from this email address.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="mailOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input v-model="userAccount.business_email" placeholder="Business Email" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6"  size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Business phone number</h2>
                    <ion-subtitle>We will use this information to contact you. If you have not added a contact number, this number will also be used as the default contact number and shared with your customers.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-row>
                      <ion-item lines="none">
                        <ion-icon slot="start" :icon="globeOutline" style="margin-inline-end: 10px"></ion-icon>
                        <ion-select v-model="userAccount.business_country_code">
                          <ion-select-option disabled :value="undefined">Select Country Code</ion-select-option>
                          <ion-select-option v-for="(thisCountry, thisCode) in countryCodesData.countryCodesData" :key="thisCode" :value="thisCode">{{ thisCountry.name }}</ion-select-option>
                        </ion-select>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon slot="start" :icon="callOutline" style="margin-inline-end: 10px"></ion-icon>
                        <ion-input v-model="userAccount.business_contact" placeholder="Business Mobile Number"  class="fullBordered input_custom_style sctn-input"></ion-input>
                      </ion-item>
                    </ion-row>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xl="6" size-lg="6"  size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Contact email for customer</h2>
                    <ion-subtitle>This email will be shared with customers in order receipts and when they ask support related queries.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="mailOutline"  style="margin-inline-end: 10px"></ion-icon>
                      <ion-input v-model="userAccount.support_email" placeholder="Support Email" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6"  size-sm="12" size-xs="12">
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Contact number for customer</h2>
                    <ion-subtitle>This number will be shared with customers in order receipts and when they ask support related queries.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-row>
                      <ion-item lines="none">
                        <ion-icon slot="start" :icon="globeOutline"  style="margin-inline-end: 10px"></ion-icon>
                        <ion-select v-model="userAccount.support_country_code">
                          <ion-select-option disabled :value="undefined">Select Country Code</ion-select-option>
                          <ion-select-option v-for="(thisCountry, thisCode) in countryCodesData.countryCodesData" :key="thisCode" :value="thisCode">{{ thisCountry.name }}</ion-select-option>
                        </ion-select>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon slot="start" :icon="callOutline" style="margin-inline-end: 10px"></ion-icon>
                        <ion-input v-model="userAccount.support_contact" placeholder="Support Mobile Number" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </ion-item>
                    </ion-row>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col>
                <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Business address</h2>
                    <ion-subtitle>Your shipping rates will be calculated using this address. We will also use this address if we need to communicate with your via mail.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="locationOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input v-model="address.addressLine1" placeholder="Address Line 1" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="trailSignOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input v-model="address.addressLine2" placeholder="Address Line 2" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                    <ion-item class="ion-justify-content-between" lines="none">
                        <ion-icon :icon="businessOutline" style="margin-inline-end: 10px"></ion-icon>
                        <ion-input v-model="address.city" placeholder="City" class="fullBordered input_custom_style sctn-input"></ion-input>
                      <ion-item lines="none">
                        <ion-input v-model="address.state" placeholder="State/Province" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-input v-model="address.pincode" placeholder="Postal Code" class="fullBordered input_custom_style sctn-input"></ion-input>
                      </ion-item>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="globeOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-input v-model="address.country" placeholder="Country" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </ion-item>
                    <ion-item v-if="addChanged" style="margin-top: 10px;" lines="none">
                      <ion-button fill="solid" size="default" color="dark" @click="saveAddressChanges">
                        Update
                      </ion-button>
                    </ion-item>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
               <ion-card class="ion-no-padding model-sections">
                  <ion-card-header>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Timezone</h2>
                    <ion-subtitle>Set timezone of the region where your store is located.</ion-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-item lines="none">
                      <ion-icon slot="start" :icon="globeOutline" style="margin-inline-end: 10px"></ion-icon>
                      <ion-select v-model="userAccount.timezone">
                        <ion-select-option disabled :value="undefined">Select Timezone</ion-select-option>
                        <ion-select-option v-for="thisZone in allTimezonesData.allTimezonesData" :key="thisZone.id" :value="thisZone.slashName">{{ thisZone.slashName }}</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-card-content>
               </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="footer">
          <h2 class="cardSubtitle ion-no-padding ion-no-margin" style="margin-bottom: 5px">Delete Account ?</h2>
          <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="() => deleteAccountMode = true" color="danger" fill="outline" class="sctn-input">Delete</ion-button>
          </ion-buttons>
          </ion-toolbar>
        </div>
      </div>
    </ion-content>
    <ion-alert
      v-if="deleteAccountMode"
      :is-open="deleteAccountMode"
      header="Are you sure?"
      :message="'You want to delete your account?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          deleteAccountMode = false;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDelete();
        },
      }]"
      @didDismiss="() => {deleteTmingMode = false; dummyTiming = JSON.parse(JSON.stringify(newTiming));}"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, globeOutline, informationCircleOutline, locationOutline, trailSignOutline, businessOutline, callOutline, personOutline, briefcaseOutline, linkOutline, mailOutline } from 'ionicons/icons';
import { IonSelect, IonSelectOption } from "@ionic/vue";
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  components: { IonSelect, IonSelectOption },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      globeOutline,
      informationCircleOutline,
      locationOutline,
      trailSignOutline,
      businessOutline,
      callOutline,
      personOutline,
      briefcaseOutline,
      mailOutline,
      linkOutline,
      loginDetails: [],
      more: false,
      cursor: undefined,
      userAccount: {
        name: undefined,
        businessName: undefined,
        referallshopName: undefined,
        shopLogo: undefined,
        email: undefined,
        emailverified: false,
        mobilecountrycode: undefined,
        mobileverified: false,
        mobileNumber: undefined,
        business_contact: undefined,
        business_contact_verified: false,
        business_country_code: undefined,
        business_email: undefined,
        business_email_verified: false,
        support_contact: undefined,
        support_contact_verified: false,
        support_country_code: undefined,
        support_email: undefined,
        support_email_verified: false,
        timezone: undefined
      },
      address: {
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        pincode: undefined
      },
      addressClone: {
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        pincode: undefined
      },
      deleteAccountMode: false
    }
  },
  ionViewWillEnter() {
    this.init();
  },
  computed: {
    userAccChanged() {
      for(var prop in this.userAccount) if(this.userAccount[prop] !== this.user[prop]) return true;
      return false;
    },
    addChanged() {
      for(var prop in this.address) if(this.address[prop] !== this.addressClone[prop]) return true;
      return false;
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      for(var prop in this.userAccount) this.userAccount[prop] = this.user[prop];
      await this.fetchAddress();
    },
    async fetchAddress() {
      try {
        this.loading = true;
        const fetchAddressResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-address", { addresstype: "business" });
        if ((fetchAddressResponse.status == 200 || fetchAddressResponse.status == 201) && fetchAddressResponse.data.success) {
          console.log(fetchAddressResponse.data.address);
          if(fetchAddressResponse.data.address && fetchAddressResponse.data.address.length) {
            for(var prop in this.address) this.address[prop] = fetchAddressResponse.data.address[0][prop];
            this.addressClone = JSON.parse(JSON.stringify(this.address));
          } else console.log("Address not available");
        } else {
          if(fetchAddressResponse.data.errorMessage && fetchAddressResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchAddressResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async handleImageUpload (event) {
      try {
        this.loading = true;
        if(event.target.files && event.target.files.length) {
          let newProfilePicFile = event.target.files[0];
          if(await this.validateImage(newProfilePicFile, 4)) {
            let uploadSendResponseData = await this.uploadImage(newProfilePicFile, '/api/add-user');
            this.updateToast({
              open: true,
              message: "Your logo has been updated successfully!",
              type: "success"
            })
            this.userAccount.shopLogo = uploadSendResponseData.shopLogo;
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async saveUserChanges() {
      try {
        this.loading = true;
        let reqBody = {};
        for(var prop in this.userAccount) if(this.userAccount[prop] !== this.user[prop]) reqBody[prop] = this.userAccount[prop];
        console.log(reqBody);
        const saveChangesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-user", reqBody);
        if ((saveChangesResponse.status == 200 || saveChangesResponse.status == 201) && saveChangesResponse.data.success) {
          await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
          window.location.href = window.location.pathname;
        } else {
          if(saveChangesResponse.data.errorMessage && saveChangesResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveChangesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async saveAddressChanges() {
      try {
        this.loading = true;
        let reqBody = {
          addresstype: 'business'
        };
        for(var prop in this.address) reqBody[prop] = this.address[prop];
        console.log(reqBody);
        const saveChangesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-address", reqBody);
        if ((saveChangesResponse.status == 200 || saveChangesResponse.status == 201) && saveChangesResponse.data.success) {
          for(var prop1 in this.address) this.address[prop1] = saveChangesResponse.data[prop1];
          this.addressClone = JSON.parse(JSON.stringify(this.address));
        } else {
          if(saveChangesResponse.data.errorMessage && saveChangesResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveChangesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async confirmDelete() {
      try {
        this.loading = true;
        const confirmDeleteResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-seller");
        if ((confirmDeleteResponse.status == 200 || confirmDeleteResponse.status == 201) && confirmDeleteResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Deleted!\nYour account has been deleted successfully. Logging you out...",
            type: "danger"
          })
        } else {
          if(confirmDeleteResponse.data.errorMessage && confirmDeleteResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: confirmDeleteResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteAccountMode = false;
      }
    }
  }
}
</script>


<style scoped>
.input-wrapper {
  position: relative;
  padding-top: 14px;
  width: 100%;
}
.input-wrapper .label {
  position: absolute;
  font-size: 11px;
  top: 0px;
  left: 0px;
}
.connectNote {
 color: var(--ion-color-grey); 
 font-size: 13px;
padding-top: 2px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  margin-bottom: 5px;
}
.sub-title {
  font-size: 16px;
  margin-top: 15px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-grey);
}
.cardContainer {
  min-height: 100px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
}

.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
  margin-bottom: 6px;
}

.cardSubtitle{
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
}

.img-sctn {
  display: flex;
  justify-content: center;
}

.sctn-input {
  border-radius: 5px;
}

.linkSctn {
  color:  var(--ion-color-grey);
}

.container {
  margin: 20px 0px 20px 10px;
}

ion-toolbar {
  --background: #f2f2f2;
}

.footer{
  margin: 0px 20px 10px 30px;
  float: right;
}

ion-button.sctn-input{
  --border-radius: 5px;
}

@media only screen and (max-width: 760px){
.container {
  margin: 10px 0px 0px 0px;
}
}

</style>