<template>
  <master-layout pageTitle="Create Order & Generate Checkout Link">
    <ion-content>
      <iframe style="width: 100%; height: calc(100% - 51px); border: none;" :src="iframeUrl" title="Create order"></iframe>
    </ion-content>
  </master-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    }),
    iframeUrl() {
      return this.environment('VUE_APP_ROOT') + 'mobile-order-create-checkout-direct?convuser=' + this.authData.token;
    }
  }
}
</script>