<template>
  <master-layout pageTitle="Broadcast analytics">
    <ion-content>
      <ion-toolbar class="chat-toolbar">
        <ion-buttons slot="start">
          <router-link to="/automations/broadcast">
            <ion-icon  size="large" :icon="arrowBackOutline" class="backBttn"></ion-icon>
          </router-link>
          <h2 class="prdt-title ion-no-margin">Broadcast analytics</h2>
        </ion-buttons>
      </ion-toolbar>
      <div class="gridMargin">
        <iframe id="googleDataStudioAnalyticsIframe" class="iframe-container" loading="lazy" :src="iframeUrl" title="Analytics" allowfullscreen></iframe>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { arrowBackOutline } from 'ionicons/icons';

export default {
  async ionViewWillEnter() {
    try {
      if(this.IS_ASIA) {
        const response = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "analytic-url");
        if(response.data?.success){
          let filteredUrl = response.data.url.split('params=');
          let url_key = filteredUrl[1];
          url_key = url_key.split('seller_key%22:%22');
          url_key = url_key[1];
          if(url_key.indexOf('%7D') > -1){
            url_key = url_key.split('%7D');
            url_key = url_key[0];
          }
          this.sellerKeys_part = "%22jumper0.seller_key%22:%22"+ url_key +"%22,%22jumper1.seller_key%22:%22"+ url_key +"%22,%22jumper2.seller_key%22:%22"+ url_key +"%22,%22jumper3.seller_key%22:%22"+ url_key +"%22,%22jumper4.seller_key%22:%22"+ url_key +"%22,%22jumper5.seller_key%22:%22"+ url_key +"%22,%22jumper6.seller_key%22:%22"+ url_key +"%22";
        } else {
          this.updateToast({ open: true, message: "Something went wrong", type: "danger" });
        }
      }
    } catch (error) {
      console.log("Exception", error);
      this.updateToast({ open: true, message: "Internal server error", type: "danger" });
    }
  },
  data() {
    return {
      arrowBackOutline,
      loading: true,
      sellerKeys_part: undefined
    }
  },
  computed: {
    iframeUrl() {
      if (this.IS_ASIA) {
        return encodeURI("https://lookerstudio.google.com/embed/reporting/f30f1e12-62d8-4fbf-8bdc-81c5a0f8e630/page/PGl7C?params=%7B" + this.sellerKeys_part + "%7D");
      } else if (this.IS_LIVE) {
        return "https://datastudio.google.com/embed/reporting/1a1afa39-6dde-4523-8fa9-acb1a2a4274d/page/PGl7C?params=%7B";
      } else {
        return "https://datastudio.google.com/embed/reporting/95c999c7-5baa-4f77-a30e-2545d722163d/page/p_7150bndyxc?params=%7B";
      }
    }
  }
}
</script>

<style scoped>

.iframe-container{
  width: 100%;
  height: calc(100vh - 56px);
  border: none;
}
.gridMargin{
  margin: 20px;
}
.backBttn{
  cursor: pointer;
  color: var(--ion-color-grey);
}
</style>