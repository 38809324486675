<template>
  <master-layout pageTitle="Facebook Share Post">
    <ion-content class="share-post-wrapper">
      <ion-refresher
        slot="fixed"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="post-action-toolbar">
          <ion-buttons slot="start">
            <ion-icon
              @click="() => $router.go(-1)"
              size="large"
              :icon="arrowBackOutline"
              style="cursor: pointer"
            ></ion-icon>
            <ion-title class="prdt-title">Create a Post</ion-title>
          </ion-buttons>
          <ion-buttons
            slot="end"
            class="paddingBox"
          >
            <ion-button
              :disabled="isPostNowDisabled"
              @click="postToFacebook"
            >Post Now</ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-card class="ion-padding-bottom">
          <ion-item lines="none">
            <ion-button
              @click="randomizeText"
              expand="block"
              fill="outline"
              class="btn-mobile-random-text"
            >Get Random Post Text</ion-button>
          </ion-item>
          <ion-item>
            <my-input
              textarea
              id="post-text"
              emoji
              name="post-text"
              :value="post.text"
              @onInput="(prop) => post.text = prop"
              placeholder="Post Text"
              :maxlength="500"
              :rows="4"
              :textAreaAutoHeight="true"
              :textAreaResizable="false"
              :animateEmojiContainer="true"
              :iconStyle="{color: 'var(--ion-color-step-300)'}"
            />
          </ion-item>
          <ion-item-group>
            <ion-item lines="none">
              <ion-label class="bold-text">Share Type {{shareType}}</ion-label>
            </ion-item>
            <ion-item>
              <ion-grid>
                <ion-radio-group v-model="shareType">
                  <ion-row>
                    <ion-col
                      size="12"
                      size-md="4"
                      v-for="(thisType, tIndex) in availableTypes"
                      :key="tIndex"
                    >
                      <ion-item
                        size="12"
                        lines="none"
                        class="ion-no-padding"
                      >
                        <ion-label>{{ thisType }}</ion-label>
                        <ion-radio
                          mode="ios"
                          slot="start"
                          :value="thisType"
                        ></ion-radio>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-radio-group>
              </ion-grid>
            </ion-item>
          </ion-item-group>
          <ion-item lines="none">
            <ion-label class="bold-text">Select Items</ion-label>
            <ion-buttons slot="end">
              <ion-button
                @click="previewModalHandler"
                class="ion-hide-lg-up"
                v-if="selectedItems && selectedItems.length"
              >
                <ion-icon
                  size="large"
                  :icon="eyeOutline"
                  style="cursor: pointer"
                ></ion-icon>
              </ion-button>
              <ion-button @click="() => selectItemsMode = true">
                <ion-icon
                  slot="icon-only"
                  :icon="addCircleOutline"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-item
            lines="none"
            v-if="selectedItems && selectedItems.length"
          >
            <ion-grid class="ion-no-padding item-selection-grid">
              <ion-row>
                <ion-col
                  size-lg="6"
                  size-sm="12"
                >
                  <ItemsToPost
                    :itemsProp="selectedItems"
                    @onItemSelect="onItemSelect"
                    @onChangeCallToAction="(type) => { callToAction = type }"
                    @onImageSelect="handleImageUpload"
                    @onUpdatedItemToEdit="onUpdatedItemToEdit"
                  ></ItemsToPost>
                </ion-col>
                <ion-col
                  size-lg="6"
                  size-sm="12"
                  class="ion-hide-lg-down"
                >
                  <preview
                    ref="previewPost"
                    :selectedItems="selectedItems"
                    :postMessage="post.text"
                    :callToAction="callToAction"
                  ></preview>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item
            class="empty-items"
            lines="none"
            v-else
          >
            <div class="ion-text-center text">
              <ion-text color="dark">No item selected to post</ion-text>
            </div>
          </ion-item>
        </ion-card>
      </div>
    </ion-content>

    <!-- product selection modal -->
    <ion-modal
      class="select-item-modal"
      v-if="selectItemsMode"
      :is-open="selectItemsMode"
      @didDismiss="() => selectItemsMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-icon
                @click="() => selectItemsMode = false"
                size="large"
                :icon="arrowBackOutline"
                style="cursor: pointer"
                class="ion-hide-md-up"
              ></ion-icon>
              <ion-title>Select Items to Share</ion-title>
            </ion-buttons>
            <ion-buttons
              slot="end"
              class="ion-hide-md-down"
            >
              <ion-button @click="() => selectItemsMode = false">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-item>
          <ion-label class="ion-padding-end">Filter</ion-label>
          <ion-input
            v-model="itemFilter"
            type="text"
            placeholder="Enter name"
            class="fullBordered input_custom_style"
          ></ion-input>
        </ion-item>
        <ion-content>
          <ion-grid class="prod-items-grid header">
            <ion-row>
              <ion-col size="1">
                <ion-checkbox
                  color="dark"
                  v-model="selectAllProducts"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="3">Image</ion-col>
              <ion-col size="4">Name</ion-col>
              <ion-col size="4">Hashtag</ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid class="prod-items-grid">
            <ion-row
              v-for="product in itemsToShow"
              :key="product.id"
            >
              <ion-col size="1">
                <ion-checkbox
                  color="dark"
                  v-model="product.selected"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="3">
                <img
                  v-if="product.images && product.images.length"
                  :src="product.images[0]"
                />
              </ion-col>
              <ion-col size="4">{{ product.name }}</ion-col>
              <ion-col size="4">{{ product.hashtag }}</ion-col>
            </ion-row>
          </ion-grid>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              class="ion-padding-end btn-cancel"
              @click="() => selectItemsMode = false"
              color="medium"
            >Close</ion-button>
            <ion-button
              @click="() => selectItemsMode = false"
              color="dark"
            >Add</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <!-- preview modal -->
    <ion-modal
      class="select-item-modal"
      v-if="isOpenPreviewModal"
      :is-open="isOpenPreviewModal"
      @didDismiss="previewModalHandler"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-icon
                @click="previewModalHandler"
                size="large"
                :icon="arrowBackOutline"
                style="cursor: pointer"
                class="ion-hide-md-up"
              ></ion-icon>
              <ion-title class="prdt-title">Preview Post</ion-title>
            </ion-buttons>
            <ion-buttons
              slot="end"
              class="ion-hide-md-down"
            >
              <ion-button @click="previewModalHandler">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <preview
            ref="previewPost"
            :selectedItems="selectedItems"
            :postMessage="post.text"
            :callToAction="callToAction"
          ></preview>
        </ion-content>
      </ion-page>
    </ion-modal>
  </master-layout>
</template>

<script>
import {
  chevronDownCircleOutline,
  addCircleOutline,
  trashBinOutline,
  close,
  arrowBackOutline,
  eyeOutline,
} from "ionicons/icons";
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { IonCheckbox, IonRadio, IonRadioGroup } from "@ionic/vue";

import { IonContent, IonPage } from "@ionic/vue";
import ItemsToPost from "./items-to-post.vue";
import Preview from "../../../components/fb-post-preview.vue";

export default {
  components: {
    IonCheckbox,
    IonContent,
    IonPage,
    ItemsToPost,
    Preview,
    IonRadio,
    IonRadioGroup,
  },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      close,
      productsSharePostTextsArray: [],
      post: {
        text: undefined,
      },
      shareType: "Product",
      availableTypes: ["Product", "Collection", "Bot"],
      allProducts: [],
      allCollections: [],
      allBots: [],
      itemFilter: undefined,
      selectItemsMode: false,
      selectAllProducts: false,
      itemToEdit: {
        productid: undefined,
        title: undefined,
        subtitle: undefined,
        image: undefined,
        type: "BUY_NOW",
      },
      pendingUpload: false,
      arrowBackOutline,
      eyeOutline,
      isOpenPreviewModal: false,
      callToAction: null,
    };
  },
  async ionViewWillEnter() {
    this.init();
    this.productsSharePostTextsArray = [
      "Comment with #CheckoutTrigger below to buy now! You can also click the button below to checkout on Messenger ",
      "Buy now by simply commenting #CheckoutTrigger below or clicking on the button below.",
      "Get your own product today! Simply comment #CheckoutTrigger or click on the button below on the post. Happy shopping ",
      "Just in - check out our new product! Get your own one by commenting #CheckoutTrigger or clicking on the button below. Follow the instructions as per the order request prompt via DM to complete checkout.",
      "You got to try it to believe it! Shop our product now by commenting #CheckoutTrigger or clicking on the button below. You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "You gotta love our new product! How to get one? Easy! Just comment #CheckoutTrigger below or click on the button below. Follow the instructions you get via Facebook Messenger to complete checkout. Happy shopping ",
      "Ready. Set. Buy  Get one of our new product today by commenting #CheckoutTrigger below or clicking on the button below. You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "Have this product yet? No? Well, time to get one then  Buy now by commenting #CheckoutTrigger below or clicking button below.",
      "Love it? Get it! Our new product. Just comment #CheckoutTrigger below or click on the button below to buy! You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "The easiest way to buy our product? Comment #CheckoutTrigger below or click on the button below and watch the magic happen  You will be automatically guided through the checkout on Facebook Messenger. Happy shopping ",
      "Hey " +
        this.user.referallshopName +
        " lovers - we have a brand new way to buy this product on Facebook. Drop in a comment below with #CheckoutTrigger, and our automated checkout bot will guide you through the purchase in a breeze. Happy shopping ",
      "Our brand new Facebook store - you can buy from the Collection by commenting with #CheckoutTrigger below to buy now! Or alternatively, click on the Shop Now button and let us walk you through the sale in Facebook Messenger!",
      "HURRY!! Our limited edition of product is available for sale right here on Facebook. You can now buy directly with us on Facebook Messenger by commenting #CheckoutTrigger below - and we will DM you in a few seconds. Chat soon :)",
      "New products, and new ways to buy 'em! Get your product now by clicking on button below or comment #CheckoutTrigger to start the checkout process. And then, just follow the instructions as per the order request prompt via DM to complete checkout",
    ];
  },
  computed: {
    itemsToShow() {
      switch (this.shareType) {
        case "Product":
          return this.itemFilter && this.itemFilter.length
            ? this.allProducts.filter((e) =>
                e.name.match(new RegExp(this.itemFilter, "gi"))
              )
            : this.allProducts;
        case "Collection":
          return this.itemFilter && this.itemFilter.length
            ? this.allCollections.filter((e) =>
                e.name.match(new RegExp(this.itemFilter, "gi"))
              )
            : this.allCollections;
        case "Bot":
          return this.itemFilter && this.itemFilter.length
            ? this.allBots.filter((e) =>
                e.displayname.match(new RegExp(this.itemFilter, "gi"))
              )
            : this.allBots;
        default:
          return [];
      }
    },
    selectedItems() {
      return this.itemsToShow
        .filter((e) => e.selected)
        .map((e) => {
          return {
            productid: e.id,
            title: e.name,
            subtitle: "⚡️ by Jumper.ai",
            image: e.images && e.images.length ? e.images[0] : undefined,
            type: "BUY_NOW",
          };
        });
    },
    isPostNowDisabled() {
      return !(this.selectedItems && this.selectedItems.length);
    },
  },
  watch: {
    shareType(n, o) {
      if (n !== o) this.selectAllProducts = false;
    },
    selectedItems() {
      if (this.selectedItems[0]) this.itemToEdit = this.selectedItems[0];
      if (this.selectedItems.length === 10)
        this.updateToast({
          open: true,
          message: "Maximum of 10 items can be only shared in a single post",
          type: "danger",
        });
    },
    selectAllProducts() {
      if (this.selectAllProducts)
        for (var i = 0; i < 10; i++) this.itemsToShow[i].selected = true;
      else this.itemsToShow.forEach((e) => (e.selected = false));
    },
  },
  methods: {
    init() {
      this.fetchProducts();
      this.fetchBots();
      this.itemToEdit = {
        productid: undefined,
        title: undefined,
        subtitle: undefined,
        image: undefined,
        type: "BUY_NOW",
      };
      this.selectAllProducts = false;
    },
    doRefresh(e) {
      this.init();
      e.target.complete();
    },
    randomizeText() {
      this.post.text =
        this.productsSharePostTextsArray[
          Math.floor(Math.random() * this.productsSharePostTextsArray.length)
        ];
    },
    onItemSelect(data) {
      this.itemToEdit = data.selectedItem;
      this.$refs.previewPost.navigateToSlide(data.index);
    },
    previewModalHandler() {
      this.isOpenPreviewModal = !this.isOpenPreviewModal;
    },
    onUpdatedItemToEdit(updatedItemToEdit) {
      this.itemToEdit = updatedItemToEdit;
    },
    async fetchProducts() {
      this.loading = true;
      try {
        let allProductsResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-shop-list"
        );
        if (
          (allProductsResponse.status == 200 ||
            allProductsResponse.status == 201) &&
          allProductsResponse.data.success &&
          allProductsResponse.data.products &&
          allProductsResponse.data.products.length
        ) {
          this.allProducts = allProductsResponse.data.products.filter(
            (e) => !e.bundle && !e.catalog
          );
          this.allProducts.forEach((p) => (p.selected = false));
          this.allCollections = allProductsResponse.data.products.filter(
            (e) => !e.bundle && e.catalog
          );
          this.allCollections.forEach((p) => (p.selected = false));
        } else {
          if (
            allProductsResponse.data &&
            !allProductsResponse.data.success &&
            allProductsResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: allProductsResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
            //console.log("Response:", allProductsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchBots() {
      this.loading = true;
      try {
        let fetchActionResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-action"
        );
        if (
          (fetchActionResponse.status == 200 ||
            fetchActionResponse.status == 201) &&
          fetchActionResponse.data.success
        ) {
          if (
            fetchActionResponse.data.actions &&
            fetchActionResponse.data.actions.length
          )
            this.allBots = fetchActionResponse.data.actions.filter(
              (e) => e.active
            );
          this.allBots.forEach((p) => (p.selected = false));
        } else {
          if (
            fetchActionResponse.data &&
            !fetchActionResponse.data.success &&
            fetchActionResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: fetchActionResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    async handleImageUpload(event) {
      try {
        this.loading = true;
        if (event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          if (await this.validateImage(newImage, 4)) {
            let uploadSendResponseData = await this.uploadImage(
              newImage,
              "/add-image"
            );
            if (
              uploadSendResponseData.image &&
              uploadSendResponseData.image.length
            ) {
              this.itemToEdit.image = uploadSendResponseData.url;
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong uploading the image",
                type: "danger",
              });
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async postToFacebook() {
      this.loading = true;
      try {
        const itemsToPost = this.selectedItems.map((elm) => {
          if (elm.productid === this.itemToEdit?.productid) {
            return this.itemToEdit;
          }
        });
        let reqBody = {
          message: this.post.text,
          case: "card",
          cards: JSON.stringify(itemsToPost),
        };
        let postToFacebookResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "share-facebook-post",
          reqBody
        );
        if (
          (postToFacebookResponse.status == 200 ||
            postToFacebookResponse.status == 201) &&
          postToFacebookResponse.data.success
        ) {
          this.updateToast({
            open: true,
            message: "Your post has been successfully shared to Facebook",
            type: "danger",
          });
          this.$router.go(-1);
        } else {
          if (
            postToFacebookResponse.data &&
            !postToFacebookResponse.data.success &&
            postToFacebookResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: postToFacebookResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.share-post-wrapper ::v-deep(.add-emoji) {
  right: 3px;
  top: 3px;
}
.share-post-wrapper ::v-deep(.jinput) {
  background: transparent;
  padding: 0;
  width: 100%;
  margin-bottom: 10px;
}
.share-post-wrapper ::v-deep(.jinput textarea) {
  padding-right: 20px;
}
.select-item-modal .prod-items-grid {
  text-align: center;
}
.select-item-modal .prod-items-grid img {
  max-height: 70px;
}
.select-item-modal .prod-items-grid.header {
  position: sticky;
  top: 0;
  background: var(
    --ion-card-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  z-index: 99999;
  border-bottom: 1px solid var(--ion-color-medium);
}
.select-item-modal tbody td {
  text-align: center;
}
.select-item-modal table {
  margin-top: 0;
  background: var(
    --ion-card-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  padding: 0;
  margin: 0;
  width: 100%;
}
.btn-cancel {
  display: none;
}
.btn-mobile-random-text {
  --padding-start: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-end: 10px;
  margin: 0;
}
.post-action-toolbar {
  position: sticky;
  z-index: 999;
  top: 0;
}
.selected-item-wrapper ion-row {
  padding: 10px 0 10px 0;
}
.swiper-container {
  height: 300px;
}
.item-selection-grid {
  width: 100%;
}
.empty-items .text {
  width: 100%;
  margin-bottom: 10px;
}
.bold-text {
  font-weight: bold;
}
@media only screen and (max-width: 780px) {
  .btn-cancel {
    display: inline-block;
  }
}
</style>