<template>
  <div>
    <div class="intro-step" v-for="(step, index) in steps" :key="index" v-show="currentStep === index">
      <div class="title">{{ step.title }}</div>
      <div class="desc">{{ step.description }}</div>
      <div class="intro-buttons">
        <button v-if="currentStep > 0" @click="prev">Prev</button>
        <button class="primary" v-if="currentStep < steps.length - 1" @click="next">Next</button>
        <button class="primary" v-else @click="() => $emit('finish')">Finish</button>
      </div>
      <div :style="{ 'top': highlight.top + 'px', 'left': highlight.left + 'px', 'width': highlight.width + 'px', 'height': highlight.height + 'px' }" class="intro-overlay-mask"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) isEscape = (evt.key === "Escape" || evt.key === "Esc");
      else isEscape = (evt.keyCode === 27);
      if (isEscape) this.$emit('finish');
    };
    this.checkElement = setInterval(this.highlightElement, 200);
    window.onresize = () => {
      clearInterval(this.checkElement);
      this.checkedTimes = 0;
      this.checkElement = setInterval(this.highlightElement, 200);
    };
  },
  props: {
    steps: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      currentStep: 0,
      highlight: {
        top: undefined,
        left: undefined,
        width: undefined,
        height: undefined
      },
      checkElement: undefined,
      checkedTimes: 0
    }
  },
  methods: {
    highlightElement() {
      if(this.steps[this.currentStep].element) {
        const element = document.getElementById(this.steps[this.currentStep].element);
        this.checkedTimes++;
        if(this.checkedTimes === 50) clearInterval(this.checkElement);
        if(element) {
          element.scrollIntoView();
          clearInterval(this.checkElement);
          this.checkedTimes = 0;
          const rect = element.getBoundingClientRect();
          this.highlight.top = rect.top;
          this.highlight.left = rect.left;
          this.highlight.width = rect.width;
          this.highlight.height = rect.height;
          return;
        } else {
          clearInterval(this.checkElement);
          this.checkedTimes = 0;
          return;
        }
      } else {
        clearInterval(this.checkElement);
        this.checkedTimes = 0;
        return;
      }
    },
    prev() {
      if(this.steps[this.currentStep].prevFunc) this.steps[this.currentStep].prevFunc();
      this.currentStep--;
      clearInterval(this.checkElement);
      this.checkedTimes = 0;
      this.checkElement = setInterval(this.highlightElement, 200);
    },
    next() {
      if(this.steps[this.currentStep].func) this.steps[this.currentStep].func();
      this.currentStep++;
      clearInterval(this.checkElement);
      this.checkedTimes = 0;
      this.checkElement = setInterval(this.highlightElement, 200);
    }
  }
}
</script>

<style scoped>
.intro-step {
  background-color: white;
  padding: 15px 25px;
  border-radius: 8px;
  text-align: center;
  border: 2px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  transition: all .3s ease-out;
}
.intro-step > .title {
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: bold;
}
.intro-step > .desc {
  margin-bottom: 10px;
  font-size: 1.1rem;
  white-space: pre-line;
}
.intro-step > .intro-buttons {
  margin-top: 20px;
}
.intro-step > .intro-buttons > button {
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 1rem;
  margin: 10px 20px;
}
.intro-step > .intro-buttons > button.primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.intro-overlay-mask {
  position: absolute;
  z-index: 99999;
  border: 3px solid var(--ion-color-primary-contrast);
  transition: all .3s ease-out;
}
</style>