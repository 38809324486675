<template>
  <ion-loading
    :is-open="loading"
    v-if="loading"
    cssClass="my-custom-class"
    message="Loading..."
  >
  </ion-loading>
  <div
    class="unReadMessagesCountBubble"
    v-if="!crm && customer.unread > 0"
  >{{ customer.unread }}</div>

  <div
    class="unReadMessagesCountBubble_visible"
    v-if="!crm && user.enterprise_Name === 'smretail_ph' && customer.read == false"
  >1+</div>

  <ion-avatar
    slot="start"
    class="img-container"
  >
    <div
      class="img-containerInner"
      v-if="customer.image != null"
      :style="renderCustomerImage_style_background(customer.image)"
    ></div>
    <div
      class="img-containerInner"
      v-if="customer.image == null"
      style="background: url(https://jumper.ai/web-s/resources/images/transparent-bg.png) no-repeat;"
    ></div>

    <p
      class="overUserImageChannelIconContainer custom_tooltip_container"
      v-if="customer.channel == 'facebook' || customer.channel == 'Facebook' || customer.channel == 'Messenger' || customer.channel == 'FB'"
    >
      <img
        src="https://jumper.ai/landing/resources/images/messenger.png"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'line' || customer.channel == 'Line' || customer.channel == 'LN'"
      title="LINE"
    >
      <img
        src="https://jumper.ai/landing/resources/images/line.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'instagram' || customer.channel == 'Instagram' || customer.channel == 'IG'"
      title="Instagram"
    >
      <img
        src="https://jumper.ai/landing/resources/images/instagram.png"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'twitter' || customer.channel == 'TW'"
      title="Twitter"
    >
      <img
        src="https://jumper.ai/landing/resources/images/twitter.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'Youtube' || customer.channel == 'youtube' || customer.channel == 'YT'"
      title="Youtube"
    >
      <img
        src="https://jumper.ai/landing/resources/images/youtube.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'web' || customer.channel == 'webbot' || customer.channel == 'bot' || customer.channel == 'JMP'"
      title="Webbot"
    >
      <img
        src="https://jumper.ai/web-s/resources/images/sc-web-sell-via-chatbox.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'imessage'"
      title="iMessage"
    >
      <img
        src="https://jumper.ai/landing/resources/images/imessage-logo.png"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'whatsapp'"
      title="WhatsApp"
    >
      <img
        src="https://jumper.ai/landing/resources/images/whatsapp.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'adlingo'"
      title="AdLingo"
    >
      <img
        src="https://jumper.ai/web-s/resources/images/adlingo-logo-square.png"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'viber'"
      title="Viber"
    >
      <img
        src="https://jumper.ai/landing/resources/images/viber.png"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'telegram'"
      title="Telegram"
    >
      <img
        src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'sms'"
      title="SMS"
    >
      <img
        src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg"
        class="platformIconInLineWithText"
      >
    </p>
    <p
      class="overUserImageChannelIconContainer"
      v-if="customer.channel == 'googlebusinessmsg'"
      title="GBM(Google business messages)"
    >
      <img
        src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA"
        class="platformIconInLineWithText"
      >
    </p>
  </ion-avatar>
  <ion-label :class="user.enterprise_Name === 'smretail_ph' && !customer.read ? 'unReadMessages' : ''">
    <h2 v-if="customer.name != 'unrecorded'">{{ customer.name }}</h2>
    <h2 v-if="customer.name == 'unrecorded' && customer.email != 'unrecorded' && customer.email != 'valid'">{{ customer.email }}</h2>
    <h2 v-if="customer.name == 'unrecorded' && (customer.email == 'unrecorded' || customer.email == 'valid')">Anonymous</h2>

    <p v-if="!crm && customer.lastReplyTimestamp != 'None' && customer.lastReplyTimestamp != null && customer.lastReplyTimestamp != ''">{{ renderDateTime_lastReplyTimestamp(customer.lastReplyTimestamp, 'customReadable', false) }}</p>

    <p v-if="crm && customer.email != 'unrecorded' && customer.email != 'valid'">
      <b>Email:</b>
      {{ customer.email }}
    </p>
    <p v-if="crm && (customer.email == 'unrecorded' || customer.email == 'valid')">Email not captured</p>

    <div
      class="singleCustomerModeTakeoverTypeIconArea custom_tooltip_container"
      v-if="!crm && (!customer.status || customer.status != 'invalid')"
    >
      <span v-if="customer.active == false">
        <img
          src="https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg"
          v-if="customer.assigned_to == null"
        >
        <span
          class="custom_tooltip_text right"
          v-if="customer.assigned_to == null"
        >Requested an Agent</span>

        <span v-if="customer.assigned_to != null">
          <img
            :src="customer.assigned_to.picture ? customer.assigned_to.picture : 'https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg'"
            v-if="customer.assigned_to.picture != ''"
          />

          <div
            class="botProfileImageInitialCharTextOnly"
            v-if="customer.assigned_to.picture == ''"
          >{{ getAgentNameInitials(customer.assigned_to.name) }}</div>

          <span class="custom_tooltip_text right">Assigned to {{customer.assigned_to.name}}</span>
        </span>
      </span>

      <img
        src="https://jumper.ai/web-s/resources/images/page-inbox-bot.svg"
        title="Bot is active"
        v-if="customer.active == true"
        data-toggle="tooltip"
        data-placement="left"
      >
      <span
        class="custom_tooltip_text right"
        v-if="customer.active == true"
      >Bot is active</span>
    </div>
    <div
      class="singleCustomerModeTakeoverTypeIconArea custom_tooltip_container"
      v-if="customer.status == 'invalid'"
    >
      <img
        src="https://jumper.ai/web-s/resources/images/attention-sign.png"
        title="Conversation is invalid"
        v-if="customer.active == true"
        data-toggle="tooltip"
        data-placement="left"
      >
      <span class="custom_tooltip_text right">Conversation is invalid</span>
    </div>
  </ion-label>
  <!-- Action buttons -->
  <span v-if="crm">
    <span class="tooltip_container">
      <ion-button
        @click="openDetail"
        fill="clear"
        size="default"
        color="dark"
      >
        <ion-icon :icon="eyeOutline"></ion-icon>
      </ion-button>
      <span class="tooltip_text left" style="width: 86px" >View Details</span>
    </span>
    <span v-if="!IS_ASIA" class="tooltip_container">
      <ion-button
        @click="() => $router.push('/page-inbox?conversation='+ customer.conversationid)"
        fill="clear"
        size="default"
        color="danger"
      >
        <ion-icon :icon="chatbubblesOutline"></ion-icon>
      </ion-button>
      <span class="tooltip_text left" style="width: 90px" >View Conversation</span>
    </span>
  </span>
  <ion-modal
    v-if="viewMode"
    :is-open="viewMode"
    @didDismiss="() => viewMode = false"
  >
  <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Customer Info</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="() => viewMode = false">
              <ion-icon
                slot="icon-only"
                :icon="close"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <single-user-detail
          @custom_fields_updated="openDetail"
          @tags_updated="openDetail"
          @user_info_updated="(body) => {$emit('user_info_updated', body); openDetail();}"
          :conversationid="detailCustomer.conversationid"
          :activeCustomer="detailCustomer"
          crm
        />
      </ion-content>
    </ion-page>
  </ion-modal>
</template>

<script>
import { eyeOutline, chatbubblesOutline, close } from "ionicons/icons";
import singleUserDetail from "../components/singleUserDetail";
import jwtInterceptor from "/src/shared/jwtInterceptor";

export default {
  components: { singleUserDetail },
  emits: ["user_info_updated"],
  data() {
    return {
      eyeOutline,
      chatbubblesOutline,
      close,
      loading: false,
      viewMode: false,
      detailCustomer: {},
    };
  },
  props: {
    customer: {
      type: Object,
    },
    crm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async openDetail() {
      try {
        if (this.customer.conversationid) {
          this.loading = true;
          let reqBody = {
            conversationid: this.customer.conversationid,
          };
          let receiverResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "fbrt/user-detail",
            reqBody
          );
          if (
            (receiverResponse.status == 200 ||
              receiverResponse.status == 201) &&
            receiverResponse.data.success
          ) {
            this.detailCustomer = receiverResponse.data.data;
            this.detailCustomer.conversationid = this.customer.conversationid;
            this.viewMode = true;
          } else {
            if (
              receiverResponse.data.errorMessage &&
              receiverResponse.data.errorMessage.length
            ) {
              this.updateToast({
                open: true,
                message: receiverResponse.data.errorMessage,
                type: "danger",
              });
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger",
              });
            }
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.chatComponentsContainer {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 15px 110px 15px;
}

.inner_chatComponentsContainer {
  display: flex;
  flex-direction: column-reverse;
}

.singleChatComponent {
  position: relative;
}

.messageTemplateContainer {
  position: relative;
  display: inline-block;
  /* width: auto; */
  width: calc(100% - 30px);
  max-width: 100%;
  padding: 5px 0px 15px 5px;
  vertical-align: bottom;
}

.messageTemplateContainer.botMsgBubble {
  text-align: right;
  padding: 5px 5px 15px 0px;
}

.normalchat_component {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 300px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 8px 8px;
  background-color: #7171fc;
  color: #fff;
  line-height: 1.3;
}

.normalchat_component a,
.normalchat_component a:hover,
.normalchat_component a:active,
.normalchat_component a:focus {
  color: #fff;
  text-decoration: underline;
}

.botMsgBubble .msg_component {
  text-align: left;
}

.botMsgBubble .normalchat_component {
  background-color: #fff;
  color: #000;
}

.botMsgBubble .normalchat_component a,
.botMsgBubble .normalchat_component a:hover,
.botMsgBubble .normalchat_component a:active,
.botMsgBubble .normalchat_component a:focus {
  color: #000;
}

.fbcomment .normalchat_component,
.externallinkopened .normalchat_component,
.noteMessageType .normalchat_component,
.statusMessageType .normalchat_component,
.scheduleMessageType .normalchat_component,
.storyMentionPreview .normalchat_component {
  /* background-color: var(--ion-color-medium) !important; */
}

.image_component {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 240px;
  max-width: 100%;
  height: auto;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px #e4e6eb solid;
  overflow: hidden;
  font-size: 0;
}

.video_component {
  position: relative;
  display: inline-block;
  width: 240px;
  max-width: 100%;
  height: auto;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px #e4e6eb solid;
  overflow: hidden;
  line-height: 1;
  font-size: 0;
}

.videoInChat {
  max-width: 100%;
}

.video_component .videoInChat {
  width: 240px;
  max-width: 100%;
  height: auto;
  line-height: 1;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

.quickRepliesArea {
  position: relative;
  width: 100%;
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 17px;
}

.quickRepliesArea .singleQuickReply {
  pointer-events: none;
  display: inline-block;
  width: auto;
  background-color: transparent;
  color: #7171fc;
  border: 1px #7171fc solid;
  margin-right: 8px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  font-size: 15px;
}

.buttonMessage_component {
  position: relative;
  display: inline-block;
  width: 300px;
  max-width: 100%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px #e4e6eb solid;
  overflow: hidden;
  background-color: #fff;
}

.buttonMessage_component .messageArea {
  padding: 8px;
}

.buttonMessage_component .buttonsArea .singleCardBigButton,
.carousel_component .buttonsArea .singleCardBigButton {
  pointer-events: none;
  display: block;
  width: 100%;
  border-top: 1px #e4e6eb solid;
  background-color: transparent;
  padding: 10px 8px;
}

.carousel_component {
  position: relative;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 15px;
  text-align: right !important;
}

.carousel_component .singleCardArea {
  position: relative;
  display: inline-block;
  width: 270px;
  max-width: 100%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px #e4e6eb solid;
  overflow: hidden;
  margin-left: 15px;
  text-align: left !important;
  background-color: #fff;
}

.carousel_component .singleCardArea img.carouselCardImg {
  height: 150px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px #e4e6eb solid;
  object-fit: contain;
}

.carousel_component .singleCardArea .titleArea {
  padding: 8px;
}

.carousel_component .singleCardArea p.title {
  padding: 0px;
  margin: 0px;
  white-space: normal;
}

.carousel_component .singleCardArea p.subTitle {
  padding: 0px;
  margin: 0px;
  margin-top: 3px;
  font-size: 13px;
  color: #777;
  white-space: normal;
}
.chat-toolbar {
  position: sticky;
  top: 0;
}

.botActiveTextNoticeBarOverInput {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  display: block;
  margin: 5px 5px 0px 5px;
  width: calc(100% - 10px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  white-space: normal;
  text-align: left;
}

.inputContainerInnerInline.mobileLikeInputContainer {
  margin: 0px 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: calc(100% - 14px);
  position: relative;
}

.chatInputBarLoadingArea {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  z-index: 1000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-top: 1px var(--ion-color-light) solid;
  background-color: var(--ion-color-light);
}

.humanTakeoverMessageInputContainer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  z-index: 1000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.humanTakeoverMessageInputContainer .jinput {
  width: calc(100% - 44px) !important;
  display: inline-block;
  padding: 0px !important;
  padding-right: 5px !important;
}

.humanTakeoverMessageInputContainer .jinput .emoji-icon img,
.humanTakeoverMessageInputContainer .jinput .add-emoji {
  width: auto;
  height: 22px;
}

#newChatMessage {
  resize: none;
  width: 100%;
  background-color: inherit;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  height: 40px;
  padding: 11px 30px 11px 10px;
  color: #000;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
}

#newChatMessage:hover,
#newChatMessage:focus,
#newChatMessage:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.humanTakeoverMessageInputContainer button.bottomProductChatSendBtn {
  border: none;
  outline: none;
  background-color: #881fff;
  box-shadow: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  padding: 8px;
}

button.bottomProductChatSendBtn img {
  height: 16px;
  width: auto;
  margin-top: 2px;
}

.actionButtonsOverChatSendInputBar_container {
  position: absolute;
  bottom: 11px;
  right: 78px;
  z-index: 2;
}

.actionButtonsOverChatSendInputBar_container img.singleActionButtonIcon {
  display: inline-block;
  width: auto;
  height: 22px;
  margin: 0px 5px;
}

.msg-shortcut-cntnr.chat-list .list-item {
  padding: 5px 15px;
}

ion-avatar.img-container,
ion-thumbnail.img-container {
  position: relative;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background: url(https://jumper.ai/web-s/resources/images/default-customer.svg)
    no-repeat;
}

ion-avatar.img-container.documentImage,
ion-thumbnail.img-container.documentImage {
  background: url(https://jumper.ai/web-s/resources/images/add-product.png)
    no-repeat;
}

ion-avatar.img-container .img-containerInner {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}

ion-thumbnail.img-container .img-containerInner {
  width: 56px;
  height: 56px;
  overflow: hidden;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}

img.platformIconInLineWithText {
  height: auto;
  width: 20px;
  vertical-align: text-bottom;
  margin-right: 5px;
}

p.overUserImageChannelIconContainer {
  position: absolute;
  bottom: -5px;
  right: -5px;
  padding: 0px;
  margin: 0px;
  line-height: 1;
  width: auto;
  display: inline-block;
}

p.overUserImageChannelIconContainer img.platformIconInLineWithText {
  margin-right: 0px;
}

.crmSingleCustomerImageContainer {
  width: 80px;
  height: 80px;
  margin: 10px auto;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  /* overflow: hidden; */
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background: url(https://jumper.ai/web-s/resources/images/default-customer.svg)
    no-repeat;
}

.crmSingleCustomerImageContainerInner {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  /* overflow: hidden; */
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  position: relative;
}

.crmSingleCustomerImageContainer p.overUserImageChannelIconContainer {
  bottom: -2px;
  right: -2px;
}

.singleCustomerModeTakeoverTypeIconArea {
  position: absolute;
  right: 15px;
  top: 23px;
  width: 20px;
  display: inline-block;
  vertical-align: bottom;
}

.singleCustomerModeTakeoverTypeIconArea img,
.singleCustomerModeTakeoverTypeIconArea i {
  height: 20px;
  width: 20px;
  color: #aaa;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.botProfileImageInitialChar.bgImgContainer {
  height: 25px;
  width: 25px;
  position: relative;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background: url(https://jumper.ai/web-s/resources/images/default-customer.svg)
    no-repeat;
}

.botProfileImageInitialChar.bgImgContainer .bgImgContainerInner {
  width: 25px;
  height: 25px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}

.botProfileImageInitialCharTextOnly {
  height: 25px;
  width: 25px;
  background-color: #1a5ff8;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
}

.singleCustomerModeTakeoverTypeIconArea .botProfileImageInitialCharTextOnly {
  height: 20px;
  width: 20px;
  font-size: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  padding-top: 5px;
  font-weight: 700;
  line-height: 10px;
}

.unReadMessagesCountBubble,
.unReadMessagesCountBubble_visible {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 1px 5px;
  font-size: 12px;
  background-color: #42c490;
  color: #fff;
  -webkit-border-radius: 0px 0px 0px 5px;
  border-radius: 0px 0px 0px 5px;
}

.unReadBubble_noCount {
  display: block;
  position: absolute;
  left: 3px;
  top: calc(50% - 6px);
  height: 12px;
  width: 12px;
  background-color: #7171fc;
  color: #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.singleChatComponent .botProfileImageInitialChar {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  height: 25px;
  width: 25px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  /* vertical-align: -webkit-baseline-middle; */
  vertical-align: bottom;
  overflow: hidden;
  position: relative;
  /* -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: center left !important; */
  -webkit-box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.repliedTrue_container [data-type="quick_replies"] .botProfileImageInitialChar,
.repliedTrue_container
  [data-type="payment_replies"]
  .botProfileImageInitialChar {
  margin-bottom: 32px;
}

.repliedTrue_container
  [data-type="generic_message"]
  .botProfileImageInitialChar {
  margin-bottom: 30px;
}

.singleChatComponent .botProfileImageInitialChar img {
  height: 25px;
  width: 25px;
  vertical-align: top;
}

.confirmationBubbleWithCart {
  text-align: left;
  width: 320px;
  max-width: calc(100% - 30px);
  padding: 0;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  border: 1px #ddd solid;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  color: #221e38;
}
.confirmationBubbleWithCart .row {
  margin-left: 0px;
  margin-right: 0px;
}
.confirmationBubbleWithCart .pad_zero {
  padding: 0;
}
.confirmationBubbleWithCart .recp_cont_img {
  /* width: 50px; */
  /* border: 1px solid #eee; */
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.confirmationBubbleWithCart .recp_cont_img img {
  width: 100%;
  max-width: calc(100% - 10px);
}
.confirmationBubbleWithCart .top_recpt {
  background-color: #eee;
  margin-bottom: 10px;
}
.confirmationBubbleWithCart .top_recpt > div {
  padding: 8px 8px;
}
.confirmationBubbleWithCart .top_recpt p {
  font-size: 15px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
}
.confirmationBubbleWithCart .mrg_zero {
  margin: 0;
}
.confirmationBubbleWithCart .inline {
  display: block;
}
.confirmationBubbleWithCart .inlineBlock {
  display: inline-block;
}
.confirmationBubbleWithCart .recpt_sec1 {
  margin-top: 0px;
  margin-bottom: 10px;
}
.confirmationBubbleWithCart .recpt_sec2 {
  /* margin-top: 8px; */
}
.confirmationBubbleWithCart .recpt_sec2 h3 {
  text-align: left;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
  padding-bottom: 2px;
}
.confirmationBubbleWithCart .recpt_sec3 p {
  font-size: 12px;
  color: #777;
  line-height: 1.3;
  margin-bottom: 5px;
  margin-top: 7px;
}
.confirmationBubbleWithCart .recpt_sec4 {
  margin-top: 10px;
}
.confirmationBubbleWithCart .recpt_sec5 p {
  color: #777;
  margin-bottom: 2px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.3;
}
.confirmationBubbleWithCart .recpt_sec5 .displayBlock {
  display: block;
  width: 100%;
  clear: both;
  position: relative;
}
.confirmationBubbleWithCart .recpt_sec5 h4,
.confirmationBubbleWithCart .recpt_sec5 h4 span {
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 5px;
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
}
.confirmationBubbleWithCart .left_algn {
  float: left;
}
.confirmationBubbleWithCart .right_algn {
  float: right;
}
.confirmationBubbleWithCart .recpt_sec1,
.confirmationBubbleWithCart .recpt_sec5,
.confirmationBubbleWithCart .recpt_sec6 {
  padding: 0 10px;
}
.confirmationBubbleWithCart .recpt_sec7 {
  border-top: 1px solid #eee;
  padding: 0 5px;
  margin-top: 5px;
}
.confirmationBubbleWithCart .recpt_sec7 p {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1.3;
  font-size: 13px;
}

.singleChatComponent .undeliveredMessageInfoArea {
  font-size: 14px;
  position: absolute;
  bottom: 13px;
  right: -10px;
}

.singleChatComponent .undeliveredMessageInfoArea.posRelativeRightSide {
  position: relative;
  display: inline-block;
  top: -5px;
}

.singleChatComponent .messageReadStatusArea {
  position: absolute;
  /* top: 7px;
  right: 3px; */
  bottom: 12px;
  right: -10px;
}

.singleChatComponent
  .repliedTrue_container
  [data-type="quick_replies"]
  .messageReadStatusArea,
.singleChatComponent
  .repliedTrue_container
  [data-type="payment_replies"]
  .messageReadStatusArea {
  bottom: 27px;
}

.singleChatComponent
  .repliedTrue_container
  [data-type="generic_message"]
  .messageReadStatusArea {
  bottom: 25px;
}

.singleChatComponent .messageReadStatusArea ion-icon {
  font-size: 15px;
}

.replytomessage_container {
  width: auto;
  max-width: 300px;
}

.replytomessage_container .innerDiv {
  position: relative;
  display: block;
  width: 100%;
  border: 1px #e4e6eb solid;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.replytomessage_container .innerDiv .sourceMessage {
  border-left: 5px #000 solid;
  border-color: var(--ion-color-primary);
}

.replytomessage_container .innerDiv .sourceMessage.textType {
  padding: 8px;
  background-color: var(--ion-color-light);
}

.replytomessage_container .innerDiv .replyMessage.textType {
  padding: 8px;
}

.replytomessage_container .innerDiv .sourceMessage.imageType,
.replytomessage_container .innerDiv .sourceMessage.videoType {
  font-size: 0px;
}

.replytomessage_container .innerDiv .replyMessage.imageType,
.replytomessage_container .innerDiv .replyMessage.videoType {
  font-size: 0px;
}

.replytomessage_container .imageInChat,
.replytomessage_container .videoInChat {
  width: 100%;
  display: block;
}

.waMsgTemplateTextarea {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  width: 100%;
  border-radius: 25px;
  background-color: #f1f1f1;
  outline: none;
  border: 1px solid gray;
  padding: 10px;
  width: 100%;
}

.filterByChannel {
  height: auto;
  width: 24px;
}

.filterByChannel img {
  vertical-align: middle;
}

.newMessageAlertBubbleForSingleChatTab {
  background-color: var(--ion-color-success);
  height: 8px;
  width: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-top: -4px;
  vertical-align: middle;
  /* display: none; */
  display: inline-block;
}

.newMessageAlertBubbleForSingleChatTab.active {
  display: inline-block;
}

.waTemplateLoadingText {
  padding: 20px 15px;
  width: 100%;
  display: block;
}

figure.waTemplateMessageFigure {
  display: block;
  position: relative;
  width: 100%;
  margin: 15px 0px !important;
  /* cursor: not-allowed; */
}

pre.waTemplateMessagePre {
  display: block;
  padding: 15px;
  margin: 0px;
  line-height: 1.4;
  color: var(--ion-color-dark);
  word-wrap: break-word;
  background-color: var(--ion-color-light);
  border: 1px var(--ion-color-medium) solid;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  white-space: normal;
  font-family: inherit !important;
  /* pointer-events: none; */
}

pre.waTemplateMessagePre p {
  padding: 0px;
  margin: 0px;
  padding-bottom: 10px;
}

.waTemplateMessagePre input.inline-text-input {
  display: inline-block;
  width: 200px;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px;
  vertical-align: middle;
  line-height: 30px;
  font-size: 14px;
  margin: 0;
  padding: 0px 5px;
  padding-bottom: 0;
  overflow: auto;
  background-color: #fff;
  border: 1px var(--ion-color-medium) solid;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.waTemplateMessagePre input.inline-text-input:hover,
.waTemplateMessagePre input.inline-text-input:active,
.waTemplateMessagePre input.inline-text-input:focus {
  border: 1px var(--ion-color-primary) solid;
}

/* custom_tooltip_text related code start */
.chatComponentsContainer .botProfileImageInitialChar {
  overflow: visible;
}

.chatComponentsContainer .botProfileImageInitialChar img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.chatComponentsContainer
  .botProfileImageInitialChar
  .botProfileImageInitialCharTextOnly {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.custom_tooltip_container .custom_tooltip_text {
  width: 140px;
  font-size: 13px;
  background-color: #000;
  color: #fff;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  display: inline-block;
}

.custom_tooltip_container:hover .custom_tooltip_text {
  opacity: 1;
}

.custom_tooltip_container .custom_tooltip_text.left {
  /* top: -5px; */
  bottom: 0px;
  left: 105%;
}

.custom_tooltip_container .custom_tooltip_text.right {
  /* top: -5px; */
  bottom: 0px;
  right: 105%;
}

.custom_tooltip_container .custom_tooltip_text.left::after {
  content: " ";
  position: absolute;
  /* top: 50%; */
  bottom: 10px;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}

.custom_tooltip_container .custom_tooltip_text.right::after {
  content: " ";
  position: absolute;
  /* top: 50%; */
  bottom: 10px;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #000;
}
/* custom_tooltip_text related code end */

ion-label.unReadMessages h2 {
  font-weight: 500;
}

/* custom fields start */
.crmCustomFieldsDataContainer {
  position: relative;
  display: block;
  width: 100%;
  /* border: 1px #eee solid; */
  /* margin-bottom: 15px; */
  padding: 15px;
}

.crmCustomFieldsDataContainer .singleCustomFieldArea {
  padding: 0px;
  position: relative;
  overflow: hidden;
}

.crmCustomFieldsDataContainer .singleCustomFieldArea input,
input.form-control.crmCustomFieldLikeInput {
  float: left;
  width: 50%;
  height: 36px;
  font-size: 14px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border-color: #d1d6e1;
  border: 1px #d1d6e1 solid;
}

input.form-control.crmCustomFieldLikeInput {
  float: none;
  width: 100%;
  border: 1px #eee solid;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.crmCustomFieldsDataContainer .singleCustomFieldArea input:hover,
.crmCustomFieldsDataContainer .singleCustomFieldArea input:active,
.crmCustomFieldsDataContainer .singleCustomFieldArea input:focus,
input.form-control.crmCustomFieldLikeInput:hover,
input.form-control.crmCustomFieldLikeInput:active,
input.form-control.crmCustomFieldLikeInput:focus {
  /* border-color: #8b93a7; */
  border: 1px #d1d6e1 solid;
  /* border: none; */
  background-color: #fbfbfb;
}

.crmCustomFieldsDataContainer .singleCustomFieldArea input.keyText {
  font-weight: 700;
}

.crmCustomFieldsDataContainer .singleCustomFieldArea button.btnDeleteWithIcon {
  position: absolute;
  z-index: 10;
  right: 2px;
  top: 5px;
  opacity: 0;
  pointer-events: none;
}

.crmCustomFieldsDataContainer
  .singleCustomFieldArea:hover
  button.btnDeleteWithIcon,
.crmCustomFieldsDataContainer
  .singleCustomFieldArea:active
  button.btnDeleteWithIcon {
  opacity: 1;
  pointer-events: all;
}
/* custom fields end */
</style>