<template>
  <master-layout pageTitle="Telegram">
    <ion-loading
    v-if="loading"
    :is-open="loading"
    cssClass="my-custom-class"
    message="Loading..."
    ></ion-loading>
    <div v-else>
      <ion-grid class="ion-no-padding cust-margin" v-if="!telegramConnected">
        <ion-row class="ion-no-padding">
          <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="model-sections">
            <ion-item lines="none" class="telegram-container sectionPadding">
              <ion-avatar slot="start">
                <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA" class="channelImage" />
              </ion-avatar>
              <ion-label>
                <h2 class="title">Connect Telegram</h2>
                <p class="sub-title">Run promotions, take orders, support customers, and send updates on Telegram</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none" class="addToken sectionPadding">
              <ion-input type="text" class="sctn-input ion-no-border" v-model="authtoken" placeholder="Enter your Telegram Auth token"></ion-input>
              <ion-button slot="end" fill="solid" size="default" class="bttnPrimary" @click="connectTelegram" :disabled="!authtoken" >
                <ion-label v-if="customLoader">Connect</ion-label>
                <ion-spinner v-else name="lines-small"/>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-else class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col size-xl="4" size-lg="4" size-md="4" class="model-sections sectionPadding cust-margin">
            <ion-item lines="none" class="channel-container">
              <ion-avatar slot="start">
                <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA" class="channelImage"/>
              </ion-avatar>
              <ion-label>
                <h2 class="title custPadding">{{ telegramSocialData.botusername }}</h2>
              </ion-label>
              <div slot="end">
                <p class="ion-no-margin connected">Connected</p>
                <ion-label class="status"></ion-label>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col size-xl="4" size-lg="4" size-md="4" class="model-sections sectionPadding cust-margin disconnect-wrapper">
            <ion-item lines="none" class="channel-container">
              <div slot="start" class="bot-name-wrapper"><span class="label">Bot name:</span> <span class="value">{{ telegramSocialData.botname }}</span></div>
              <div slot="end"> <ion-button @click="() => disconnectMode = true" fill="outline" color="danger">Disconnect</ion-button> </div>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ion-alert
      v-if="disconnectMode"
      :is-open="disconnectMode"
      header="Are you sure?"
      :message="disconnectMessage"
      :buttons="[{
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            disconnectMode = false;
          },
        },
        {
          text: 'Yes',
          handler: () => {
            confirmDisconnect()
          },
        }]"
      @didDismiss="() => disconnectMode = false"
      >
      </ion-alert>
  </master-layout>
</template>
  
<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSpinner } from '@ionic/vue';

export default {
  components: { IonSpinner },
  data() {
    return {
      loading: false,
      customLoader: true,
      authtoken: undefined,
      disconnectMode: false
    }
  },
  computed: {
    disconnectMessage() {
      let message = "";
      if (this.IS_ASIA) {
        message = "We will not be able to converse and help your customers check-out with their order if they comment on your channel with your #hashtag. Are you sure you want to disconnect your Telegram account?";
      } else {
        message = "Jumper will not be able to converse and help your customers check-out with their order if they comment on your channel with your #hashtag. Are you sure you want to disconnect your Telegram account?";
      }
      return message;
    }
  },
  methods: {
    async connectTelegram() {
      try {
				this.customLoader = false;
				let reqBody = {
					telegram_token: this.authtoken
				}
				let connectTelegramResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "telegram/connect", reqBody);
				if ((connectTelegramResponse.status == 200 || connectTelegramResponse.status == 201) && connectTelegramResponse.data.success) {
					this.updateToast({
						open: true,
						message: "Your Telegram Account is now connected!",
						type: "success"
					})
					await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
					window.location.href = window.location.pathname;
				}
				else {
					if(connectTelegramResponse.data.errorMessage && connectTelegramResponse.data.errorMessage.length) {
						this.updateToast({
							open: true,
							message: connectTelegramResponse.data.errorMessage,
							type: "danger"
						})
					} else {
						this.updateToast({
							open: true,
							message: "Something went wrong",
							type: "danger"
						})
					}
				}
			} catch (error) {
				console.log("Exception", error);
				this.updateToast({
					open: true,
					message: "Something went wrong",
					type: "danger"
				})
			} finally {
				this.customLoader = true;
			}
    },
    async confirmDisconnect() {
      this.loading = true;
      try {
        let disconnectLineResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-social", { platform: 'telegram' });
        if ((disconnectLineResponse.status == 200 || disconnectLineResponse.status == 201) && disconnectLineResponse.data.success) {
          if (disconnectLineResponse.data.delete) {
            this.updateToast({ open: true, message: "Telegram disconnected successfully", type: "success" });
          }
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          setTimeout(() => {
            window.location.href = window.location.pathname;
          }, 500);
        } else {
          this.loading = false;
          if (disconnectLineResponse.data.errorMessage && disconnectLineResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: disconnectLineResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      }
    }
  }
}
</script>

<style scoped>
.sectionPadding{
  padding: 15px 20px;
}
.status {
  background-color: var(--ion-color-success);
  margin-left: 10px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block !important;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--ion-color-black);
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}

.cust-margin{
  margin: 20px;
}
.custPadding{
  padding-bottom: 5px;
}
.model-sections {
  background: var(--ion-color-white);
  border-radius: 5px;
}
.connected {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--ion-color-black);
  display: inline-block;
}
.channelImage{
  border-radius: 5px;
}
.channel-container{
  --background: var(--ion-color-white);
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
.telegram-container{
  --background: var(--ion-color-white);
  --padding-start: 0px;
  --inner-padding-end: 0px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
}
ion-item.addToken{
  --background: var(--ion-color-white);
  --padding-start: 0px;
}
ion-input.sctn-input{
  background: #ededed;
  border-radius: 5px;
  --padding-start: 10px;
}
ion-button.bttnPrimary{
  --background: var(--ion-color-primary);
  color: var(--ion-color-white);
  --border-radius: 5px;
  --border-width: 0px;
}

.disconnect-wrapper {
  margin-top: 0px;
}
</style>