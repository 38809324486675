<template>
<master-layout pageTitle="Scan QR">
  <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
  <div v-else class="center-align">
    <div v-if="clickAndCollectUser" class="center-align">
      <ion-item>
        <ion-label>Select Outlet</ion-label>
        <ion-select interface="popover" v-model="selectedOutlet" placeholder="Outlet">
          <ion-select-option v-for="thisOutlet in allOutlets" :key="thisOutlet.outletid" :value="thisOutlet.outletid">{{ thisOutlet.name }}</ion-select-option>
        </ion-select>
      </ion-item>
      <br/>
      <br/>
      <ion-button :disabled="selectedOutlet === undefined" @click="scanQR">
        <ion-icon slot="start" :icon="qrCodeOutline"></ion-icon>
        Scan QR
      </ion-button>
    </div>
    <div v-else class="center-align">
      <ion-button disabled @click="scanQR">
        <ion-icon slot="start" :icon="qrCodeOutline"></ion-icon>
        Scan QR
      </ion-button>
      <div>You are not a Click and Collect User</div>
    </div>
  </div>
  <ion-alert
    v-if="permissionRejected"
    :is-open="permissionRejected"
    header="Need access to device camera to scan a QR"
    sub-header="Please go to device setting and enable camera access for Jumper application"
    message="Go to settings?"
    :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        goBack();
      },
    },
    {
      text: 'Yes',
      handler: () => {
        goToSettings();
      },
    }]"
    @didDismiss="() => permissionRejected = false"
  >
  </ion-alert>
  <ion-modal
    v-if="openLink"
    :is-open="openLink"
    @didDismiss="() => {openLink = false; scannedText = undefined;}"
  >
    <ion-page>
      <ion-content :fullscreen="true">
        <ion-toolbar class="chat-toolbar" color="light">
          <h2 class="ion-no-margin">Mark Order</h2>

          <ion-buttons slot="end">
            <ion-button @click="() => {openLink = false; scannedText = undefined;}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <iframe style="width: 100%; height: 100vh; border: none;" :src="scannedText + '?userid=' + authData.authkey + '&outletid=' + selectedOutlet" title="Scan QR"></iframe>
      </ion-content>
    </ion-page>
  </ion-modal>
</master-layout>
</template>

<script>
import { qrCodeOutline, close } from 'ionicons/icons';
import { IonSelect, IonSelectOption, useBackButton } from '@ionic/vue';
import { mapGetters } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export default {
  setup() {
    useBackButton(-2, () => {
      console.log("Back button pressed");
      this.stopScan();
    });
  },
  deactivated() {
    this.stopScan();
  },
  beforeUnmount() {
    this.stopScan();
  },
  components: {
    IonSelect, IonSelectOption
  },
  data() {
    return {
      qrCodeOutline,
      close,
      loading: true,
      permissionRejected: false,
      scannedText: undefined,
      openLink: false,
      allOutlets: [],
      clickAndCollectUser: true,
      selectedOutlet: undefined
    }
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    })
  },
  async ionViewWillEnter(){
    await this.initView();
  },
  methods: {
    async initView() {
      this.loading = true;
      let outletsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-outlets");
      this.loading = false;
      if((outletsResponse.status === 200 || outletsResponse.status === 201) && outletsResponse.data) {
        if(outletsResponse.data.success) {
          this.allOutlets = outletsResponse.data.outlets;
          if(this.allOutlets.length) this.clickAndCollectUser = true;
        } else {
          this.updateToast({
            open: true,
            message: outletsResponse.data.errorMessage,
            type: "danger"
          })
        }
      } else {
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    scanQR() {
      /* if(this.selectedOutlet === undefined) {
        this.updateToast({
          open: true,
          message: "Please select outlet first",
          type: "warning"
        })
        return
      } */
      this.askUser();
    },
    displayContents(err, text) {
      if(err){
        console.error("Error in scanning QR:", err);
      } else {
        this.scannedText = text;
        this.stopScan();
        this.openLink = true;
        //alert(text);
      }
    },
    goToSettings() {
      BarcodeScanner.openAppSettings();
      this.permissionRejected = false;
      this.stopScan();
    },
    goBack(){
      this.stopScan();
      this.$router.go(-1);
    },
    prepare() {
      BarcodeScanner.prepare();
    },
    async startScan() {
      BarcodeScanner.hideBackground();
      document.querySelector('body').classList.add('scanner-active');
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        console.log(result.content);
        this.displayContents(result, result.content);
      }
      this.displayContents(result, result.content);
    },
    stopScan() {
      document.querySelector('body').classList.remove('scanner-active');
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      this.openLink = false;
    },
    async askUser() {
      var hasPermission = await this.didUserGrantPermission();
      if(!hasPermission) {
        await this.checkPermission();
        hasPermission = await this.didUserGrantPermission();
        if(!hasPermission) return;
      }
      this.prepare();
      const c = confirm('Do you want to scan a QR Code?');
      if (c) {
        this.startScan();
      } else {
        this.stopScan();
      }
    },
    async checkPermission() {
      const status = await BarcodeScanner.checkPermission();
      if (status.denied) {
        const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
        if (c) {
          BarcodeScanner.openAppSettings();
        }
      }
    },
    async didUserGrantPermission() {
      // check if user already granted permission
      const status = await BarcodeScanner.checkPermission({ force: false });
      if (status.granted) {
        return true;
      }
      if (status.denied) {
        return false;
      }
      if (status.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }
      if (status.neverAsked) {
        // user has not been requested this permission before
        // it is advised to show the user some sort of prompt
        // this way you will not waste your only chance to ask for the permission
        const c = confirm('We need your permission to use your camera to be able to scan QR Codes');
        if (!c) {
          return false;
        }
      }
      if (status.restricted || status.unknown) {
        // ios only
        // probably means the permission has been denied
        return false;
      }
      // user has not denied permission
      // but the user also has not yet granted the permission
      // so request it
      const statusRequest = await BarcodeScanner.checkPermission({ force: true });
      if (statusRequest.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }
      if (statusRequest.granted) {
        // the user did grant the permission now
        return true;
      }
      // user did not grant the permission, so he must have declined the request
      return false;
    }
  }
}
</script>

<style scoped>
.center-align {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
