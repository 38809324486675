<template>
  <master-layout pageTitle="Website">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <h2 class="title ion-no-margin" style="margin-top: 20px">Website</h2>
        <div class="link-text">
          <a class="btn textButton externalLinkBtnWithIcon" :href="'http://' + user.referallshopName + '.jumperdevnew.appspot.com'" target="_blank" rel="noreferer noopener">
              Visit your store
            <ion-icon slot="start" size="small" :icon="openOutline" class="external-link"></ion-icon>
          </a>
        </div>
        <ion-card class="container">
          <div class="container-items">
            <ion-item lines="none">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/web-s/resources/images/sc-web-custom-domain.svg" />
              </ion-thumbnail>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin">Host your jumper store on your own domain</h2>
                <p class="cardSubtitle ion-no-padding ion-no-margin">Note: It may take 24-48 hours after you have mapped your domain or subdomain to your jumper store.</p>
              </ion-label>
            </ion-item>
          </div>
          <div class="sctn">
            <div class="prdct-section">
              <ion-card-title class="sctn-labels">Your jumper store URL:</ion-card-title>
              <ion-input disabled type="text" placeholder="Store URL" :value="'http://' + user.referallshopName + '.jumperdevnew.appspot.com'" class="fullBordered input_custom_style sctn-input"></ion-input>
            </div>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels">Do you want to use a subdomain?</ion-card-title>
              <ion-radio-group v-model="subdomain">
                <ion-grid class="ion-no-padding">
                  <ion-row class="radio-bttns">
                    <ion-col class="ion-no-padding ion-align-self-end">
                      <ion-item lines="none" class="radio-items">
                        <h2 class="ion-no-padding orderDesc ion-no-margin">Yes</h2>
                        <ion-radio  slot="start" :value="true"></ion-radio>
                      </ion-item>
                    </ion-col>
                    <ion-col class="ion-no-padding">
                      <ion-item lines="none" class="radio-items">
                        <h2 class="ion-no-padding orderDesc ion-no-margin">No</h2>
                        <ion-radio slot="start" :value="false"></ion-radio>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-radio-group>
            </div>
          </div>
            <div class="sctn">
              <div class="prdct-section">
                <ion-card-title class="sctn-labels">Your subdomain:</ion-card-title>
                <ion-input type="text" :placeholder="subdomain ? 'shop.mydomain.com' : 'mydomain.com'" v-model="domain" class="fullBordered input_custom_style"></ion-input>
                <h6 class="text-info">Enter the URL you want to use.</h6>
              </div>
            </div>
            <ion-toolbar>
              <ion-buttons slot="end">
                <ion-button expand="block" fill="solid" size="default" class="save-bttn" @click="setCustomDomain">
                  Save
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
        </ion-card>  
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline,openOutline } from 'ionicons/icons';
import { IonRadioGroup, IonRadio } from "@ionic/vue";

export default {
  components: { IonRadioGroup, IonRadio },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      openOutline,
      domain: undefined,
      subdomain: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      try {
        this.loading = true;
        const getCustomDomainResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-custom-domain");
        if ((getCustomDomainResponse.status == 200 || getCustomDomainResponse.status == 201) && getCustomDomainResponse.data.success) {
          this.subdomain = !!getCustomDomainResponse.data.subdomain;
          this.domain = getCustomDomainResponse.data.domain;
        } else {
          if(getCustomDomainResponse.data.errorMessage && getCustomDomainResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: getCustomDomainResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async setCustomDomain() {
      try {
        this.loading = true;
        let reqBody = {
          domain: this.domain,
          subdomain: this.subdomain
        }
        const setCustomDomainResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-custom-domain", reqBody);
        if ((setCustomDomainResponse.status == 200 || setCustomDomainResponse.status == 201) && setCustomDomainResponse.data.success) {
          this.subdomain = !!setCustomDomainResponse.data.subdomain;
          this.domain = setCustomDomainResponse.data.domain;
        } else {
          if(setCustomDomainResponse.data.errorMessage && setCustomDomainResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: setCustomDomainResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

a.btn.textButton.externalLinkBtnWithIcon {
  border: none;
  color: var(--primary-theme-color, #3d39b9);
  background-color: transparent;
  text-decoration: none;
}
.btn.textButton {
  background-color: var(--primary-theme-color, #3d39b9);
  color: var(--button-text-color, #fff);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* text-transform: capitalize; */
  opacity: 1;
  /* white-space: pre-wrap; */
  cursor: pointer;
  outline: none !important;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}
.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 4px;
}
.container {
  border-radius: 10px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
}
.container .container-items {
  margin: 10px;
}
ion-button.save-bttn {
  border-radius: 5px;
  --border-width: 0px;
  margin: 0px 20px 20px 0px;
}
.external-link {
  vertical-align: middle;
}
.sctn-input {
  background-color: #f5f5f5;
  color: #888;
  cursor: not-allowed;
  width: 550px;
  border-right: 0 none;
}
.radio-bttns {
  max-width: 300px;
}
.link-text {
  text-align: right;
}
.radio-items {
  margin-left: -20px;
}
.sctn {
  margin: 28px;
}
.text-info {
  margin: 5px 0px;
  font-size: 12px;
}
@media only screen and (max-width: 760px) {
  .sctn-input {
    width: 100%;
  }
}
</style>