<template>
  <master-layout pageTitle="Broadcasts">
    <!-- show listing if non edit mode -->
    <ion-content v-if="!editMode">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="loading || broadcastListLoading" :is-open="loading || broadcastListLoading" cssClass="my-custom-class" message="Loading..." ></ion-loading>
      <!-- desktop -->
      <div v-else>
        <!-- Search bar -->
        <div class="broadcast-search-container">
          <ion-item class="searchBar" lines="none">
            <ion-input v-model="broadcastFilter" placeholder="Search broadcast"></ion-input>
            <ion-buttons slot="end">
              <ion-button>
                <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

          <div class="ion-hide-md-down btn-actions-right-top">
            <ion-button @click="fetchBroadcasts()">
              <ion-icon slot="icon-only" :icon="refreshCircleOutline"></ion-icon>
            </ion-button>
            <ion-button class="analyticsBttn" router-link="/analytics/broadcast"> View analytics </ion-button>
            <ion-button v-if="IS_ASIA" class="btn-watch-credits" id="click-trigger">Watch Credits</ion-button>
            <ion-popover trigger="click-trigger" trigger-action="click" side="left">
              <ion-content class="ion-padding merchant-credit-container">
                <MerchantCredits></MerchantCredits>
              </ion-content>
            </ion-popover>
          </div>
          <div class="ion-hide-md-up">
            <ActionPopOver :style="{'--width': '150px'}">
              <ion-list>
                <ion-item :button="true" :detail="false" router-link="/analytics/broadcast">View analytics</ion-item>
                <ion-item :button="true" id="nested-trigger" @click="showCreditsModal = true">Watch Credits</ion-item>
                <ion-item :button="true" id="nested-trigger" @click="fetchBroadcasts()">Refresh</ion-item>
              </ion-list>
            </ActionPopOver>
          </div> 
        </div>
        <section class="table-responsive-container ion-hide-md-down">
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Channel
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Created On
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Executions
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Targeted Customers (CSV)
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Status
              </ion-col>
              <ion-col class="ion-hide-md-down ">
                <div class="action-header"> Actions </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container ion-hide-md-down">
            <ion-grid v-for="(broadcast, bIndex) in broadcastsToShow" :key="bIndex" class="table-row ion-no-padding">
              <ion-row else class="ion-justify-content-between">
                <ion-col>
                  <span class="tooltip_container broadcast-name-wrapper">
                    <ion-text class="broadcast-name">
                      {{ broadcast.name }}
                    </ion-text>
                    <span class="tooltip_text right">{{ broadcast.name }}</span>
                  </span>
                </ion-col>
                <ion-col>
                  <ion-text class="displayBlock orderDesc">
                    {{ broadcast.channel }}
                  </ion-text>
                </ion-col>
                <ion-col>
                  <ion-text class="displayBlock orderDesc">
                    {{ broadcast.createdOnFormatted }}
                  </ion-text>
                </ion-col>
                <ion-col>
                  <div class="execution-cell-wrapper">
                    <div class="date-text">
                      <div class="label-text">
                        <ion-text>{{ broadcast.executionLabel }}:</ion-text>
                      </div>
                      <div>
                        <ion-text>
                          {{ broadcast.executionDate }}
                        </ion-text>
                      </div>
                    </div>
                    <div class="time-zone-text">
                      <ion-text>{{ broadcast.executionTz }}</ion-text>
                    </div>
                  </div>
                </ion-col>
                <ion-col>
                  <a v-if="broadcast.showDownloadCsv" :href="environment('VUE_APP_ROOT') + 'api/download-broadcast-preset-file?id=' + broadcast.id" target="_blank" class="hoverUnderline"> Download CSV </a>
                </ion-col>
                <ion-col>
                  <ion-text v-if="broadcast.sent_status === 'done'" class="displayBlock orderDesc broadcastStatus">Completed</ion-text>
                  <ion-text v-else class="displayBlock orderDesc broadcastStatus">{{ statusMapping[broadcast.sent_status] || broadcast.sent_status }}</ion-text>
                </ion-col>
                <!-- Action buttons -->
                <ion-col>
                  <div class="action-buttons-wrapper">
                    <span v-if="broadcast.isVisibleBroadcastOutcomeButton"  class="tooltip_container">
                      <div v-if="broadcast.outcomeLoading" class="outcome-loading-text">
                        <div class="text">Downloading... </div>
                      </div>
                      <ion-button v-else @click="downloadCsv_broadcastOutcome(broadcast.id)" fill="clear" size="default" color="dark">
                        <ion-icon :icon="cloudDownloadOutline"></ion-icon>
                      </ion-button>
                      <span v-if="!broadcast.outcomeLoading" class="tooltip_text left" style="width: 80px">Broadcast Outcome</span>
                    </span>

                    <span v-if="['running', 'done', 'Loading'].includes(broadcast.sent_status)"  class="tooltip_container">
                      <ion-button @click="() => { editMode = true; broadcastIndexToEdit = bIndex;}" fill="clear" size="default" color="dark">
                        <ion-icon :icon="eyeOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text left" style="width: 80px">View Details</span>
                    </span>

                    <span v-else class="tooltip_container">
                      <ion-button @click="() => { editMode = true; broadcastIndexToEdit = bIndex;}" fill="clear" size="default" color="dark">
                        <ion-icon :icon="createOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text left" style="width: 80px">View Details</span>
                    </span>

                    <span class="tooltip_container">
                      <ion-button :disabled="!broadcast.isEnabledDeleteButton" @click="() => (broadcastIndexToDelete = bIndex)"  fill="clear" size="default" color="danger">
                        <ion-icon :icon="trashOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text left" style="width: 80px">Delete</span>
                    </span>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        <ion-infinite-scroll
          @ionInfinite="fetchBroadcasts($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="broadcastsLoadMore || allbroadcasts.length <= 0"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more broadcasts..."
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
        </section>
        <div class="text-center" v-if="!loading && broadcastFilter && broadcastFilter.length && broadcastsToShow.length === 0" style="width: 100%; padding: 15px;">
          <img
            src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
            style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
          />
          <h5> No broadcasts matching this filter! 😟 <a @click="(e) => { e.preventDefault(); broadcastFilter = undefined;}">Clear filter</a> </h5>
        </div>
        <div class="text-center" v-else-if="!loading && allbroadcasts.length == 0 && !broadcastsLoadMore" style="width: 100%; padding: 15px;">
          <img
            src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
            style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
          />
          <h5>No broadcasts yet! 😟</h5>
        </div>
        <div class="text-center" v-if="allbroadcasts.length == 0 && broadcastsLoadMore" style="width: 100%; padding: 15px;">
          <img
            src="https://jumper.ai/web-s/resources/images/no-customer-image.svg"
            style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;"
          />
          <h5>Fetching broadcasts...</h5>
        </div>
      </div>

      <!-- mobile -->
       <div v-for="(broadcast, bIndex) in broadcastsToShow" :key="bIndex" class="table-responsive ion-hide-md-up">
        <div class="table-section">
          <ion-grid class="ion-no-padding" style="margin: 0px 0px 20px 0px">
             <ion-row class="rowMargin">
                <ion-col class="ion-no-padding">
                  <ion-label class="orderSubtitle"> Name </ion-label>
                </ion-col>
                <ion-col class="orderDesc text-right">
                  {{ broadcast.name }}
                </ion-col>
             </ion-row>
             <ion-row class="rowMargin">
                <ion-col class="ion-no-padding">
                  <ion-label class="orderSubtitle"> Channel </ion-label>
                </ion-col>
                <ion-col class="orderDesc text-right">
                  {{ broadcast.channel }}
                </ion-col>
             </ion-row>
             <ion-row class="rowMargin">
                <ion-col class="ion-no-padding">
                  <ion-label class="orderSubtitle"> Created On</ion-label>
                </ion-col>
                <ion-col class="orderDesc text-right">
                  {{ broadcast.createdOnFormatted }}
                </ion-col>
             </ion-row>
             <ion-row class="rowMargin">
              <ion-col class="ion-no-padding">
                  <ion-label class="orderSubtitle">{{ broadcast.executionLabel }}</ion-label>
              </ion-col>
              <ion-col class="orderDesc text-right">
                <div class="execution-date-wrapper-mobile">
                 <ion-text class="orderSubtitle"> {{ broadcast.executionDate }} </ion-text>
                 <ion-text class="orderSubtitle"> ({{ broadcast.executionTz }}) </ion-text>
                </div>
              </ion-col>
             </ion-row>

             <ion-row class="rowMargin">
              <ion-col class="ion-no-padding">
                <ion-label class="orderSubtitle"> Targeted Customers (CSV)</ion-label>
              </ion-col>
              <ion-col class="orderDesc text-right">
                <a :href="environment('VUE_APP_ROOT') + '/api/download-broadcast-preset-file?id=' + broadcast.id" target="_blank" class="hoverUnderline"> Download CSV </a>
              </ion-col>
            </ion-row>

             <ion-row class="rowMargin">
              <ion-col class="ion-no-padding">
                  <ion-label class="orderSubtitle"> Status </ion-label>
              </ion-col>
              <ion-col class="orderDesc text-right">
                 <ion-text v-if="broadcast.sent_status === 'done'" class="displayBlock orderDesc">Completed</ion-text>
                 <ion-text v-else class="displayBlock orderDesc">{{ statusMapping[broadcast.sent_status] || broadcast.sent_status }}</ion-text>
              </ion-col>
             </ion-row>
             <ion-row class="ion-justify-content-between">
                <ion-col class="ion-text-start">
                    <ion-button @click="() => { editMode = true; broadcastIndexToEdit = bIndex;}" fill="clear" size="default" color="dark">
                      <ion-icon :icon="eyeOutline"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col class="ion-text-end" v-if="accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila')">  
                  <ion-button :disabled="!broadcast.isEnabledDeleteButton"  @click="() => (broadcastIndexToDelete = bIndex)"  fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                </ion-col>
             </ion-row>
          </ion-grid>
        </div>
       </div>             
    </ion-content>
    <!-- Loading message if edit mode and no channels added -->
    <ion-content v-else-if="editMode && !connectedChannels.length" class="no-channel-connected-content">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-icon class="btn-back" @click="() => { editMode = false; broadcastIndexToEdit = -1 }" size="large" :icon="arrowBackOutline"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{ pageTitle }} </h2>
        </ion-buttons>
      </ion-toolbar>
      <div class="not-channel-connected-text-wrapper">
        <ion-text class="hyperlink_cust">No channel is connect for Broadcast. Please connect any channel to create the Broadcast.</ion-text>
      </div>
    </ion-content>
    <!-- show edit mode -->
    <ion-content v-else-if="editMode">
      <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-icon class="btn-back" @click="() => {editMode = false; broadcastIndexToEdit = -1}" size="large" :icon="arrowBackOutline"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{ pageTitle }} </h2>
        </ion-buttons>
        <ion-buttons v-if="editMode && !isViewMode" slot="end">
          <ion-button @click="saveBroadcastHandler" class="bttn-bgnd">{{ sendNow ? "Save and Send" : "Save" }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar class="ion-hide-md-up">
        <ion-segment scrollable color="dark" :value="activeTab">
          <ion-segment-button value="template" @click="() => { editMode = true; broadcastIndexToEdit = bIndex;}">
            <ion-label class="common-label">Template</ion-label>
          </ion-segment-button>
          <ion-segment-button value="preview">
            <ion-label class="common-label">Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col size-xl="8" size-lg="8" size-sm="12" size-xs="12">
            <ion-accordion-group>
              <ion-accordion class="model-sections">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Basic
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding">
                  <ion-grid class="ion-no-padding gridMargin">
                    <ion-row class="rowMargin">
                      <ion-col class="ion-no-padding">
                        <h2 class="orderSubtitle ion-no-padding ion-no-margin" style="margin: 0px 0px 20px 0px">
                          Name
                        </h2>
                        <ion-input
                          type="text"
                          placeholder="Enter Name"
                          v-model="editBroadcast.name"
                          class="fullBordered input_custom_style"
                        ></ion-input>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-list>
              </ion-accordion>

              <ion-accordion class="model-sections">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Messaging Channels
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding" style="border-top: 1px solid #EDEDED">
                  <div class="ion-no-padding gridMargin">
                    <div class="channels-wrapper">
                      <div v-for="thisCategory in channelsToRender" :key="thisCategory.value" :value="thisCategory.value" @click="onSelectBroadCastChannel($event, thisCategory)" size-xl="4" size-lg="4" size-sm="6" size-xs="6" class="slct-channel slct-channel-column ion-no-padding">
                          <div size="3" class="ion-align-self-center">
                            <img class="chnl-img" :src="thisCategory.img" />
                          </div>
                          <div size="6" class="ion-align-self-center">
                            <ion-label class="sctn-labels">{{ thisCategory.name }}</ion-label>
                          </div>
                          <div  size="3" class="ion-align-self-center" style="text-align: end">
                            <ion-checkbox :checked="selectedBroadCastChannel.value == thisCategory.value" @ionChange="onChangeChannel"></ion-checkbox>
                          </div>
                          <div v-if="IS_ASIA" class="available-message-count">
                            <span class="tooltip_container remaining-message-count-wrapper">
                              <ion-badge slot="end">
                                <span class="remaining-message-count"> {{thisCategory.messageLimitAbbreviated }} </span>
                                msg
                              </ion-badge>
                              <span class="tooltip_text top">{{ thisCategory.messageLimitAbbreviated }} msg left</span>
                            </span>
                          </div>
                      </div>
                    </div>
                  </div>
                </ion-list>
              </ion-accordion>

              <ion-accordion class="model-sections">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Create message
                  </ion-text>
                </ion-item>
                <div class="ion-padding" slot="content">
                  <WhatsAppCreateMessage v-if="selectedBroadCastChannel.value == 'whatsapp'" :editBroadcast="editBroadcast" ref="whatsAppCreateMessage" @onUpdatePreview="(value) => { objWhatsAppPreview = value }"></WhatsAppCreateMessage>
                  <JsonBuilderWrapper ref="JsonBuilderWrapper" v-if="['facebook', 'line', 'googlebusinessmsg', 'telegram', 'viber', 'sms'].includes(selectedBroadCastChannel.value)" :editBroadcast="editBroadcast" @finalJsonElements="adMessageJsonElementsHandler"></JsonBuilderWrapper>
                </div>
              </ion-accordion>

              <ion-accordion class="model-sections">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Settings
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding" style="border-top: 1px solid #EDEDED">

                  <!-- Facebook specific Settings -->
                  <div class="facebook-settings" v-if="editBroadcast.channel == 'facebook'">
                    <div class="fb-setting-header">
                      <div class="title">Objective</div>
                      <div class="sub-title">Select the purpose for the broadcast message</div>
                    </div>
                    <ion-radio-group :value="messagePurpose" v-model="messagePurpose">
                      <ion-row>
                      <ion-col size="12" size-sm="6">
                        <ion-item >
                          <ion-label>Post purchase update</ion-label>
                          <ion-radio slot="end" value="POST_PURCHASE_UPDATE"></ion-radio>
                        </ion-item>
                      </ion-col>

                      <ion-col size="12" size-sm="6">
                        <ion-item>
                          <ion-label>Account update</ion-label>
                          <ion-radio slot="end" value="ACCOUNT_UPDATE"></ion-radio>
                        </ion-item>
                      </ion-col>

                      <ion-col size="12" size-sm="6">
                        <ion-item>
                          <ion-label>Confirmed event update</ion-label>
                          <ion-radio slot="end" value="CONFIRMED_EVENT_UPDATE"></ion-radio>
                        </ion-item>
                      </ion-col>

                      <ion-col size="12" size-sm="6">
                        <ion-item>
                          <ion-label>Interacted within 24hours</ion-label>
                          <ion-radio slot="end" value="NON_PROMOTIONAL_SUBSCRIPTION"></ion-radio>
                        </ion-item>
                      </ion-col>

                      <ion-col size="12" size-sm="6">
                        <ion-item>
                          <ion-label>Opt-in notification</ion-label>
                          <ion-radio slot="end" value="NOTIFICATION_FB_"></ion-radio>
                        </ion-item>
                      </ion-col>
                      </ion-row>
                    </ion-radio-group>
                  </div>
                  <!-- End Facebook specific Settings -->

                  <!-- Common settings options -->
                    <div class="ion-no-padding gridMargin">
                      <h2 class="ion-no-padding sctn-labels ion-no-margin" style="padding-bottom: 0px">
                        How would you like to proceed with the targeting?
                      </h2>
                    </div>

                    <ion-radio-group :value="targetingOption" v-model="targetingOption">
                      <ion-row>
                        <ion-col size="12" size-sm="6">
                          <ion-item>
                            <ion-label>By selecting filters below</ion-label>
                            <ion-radio value="SELECT_FILTERS" @click="() => csvToUpload = undefined"></ion-radio>
                          </ion-item>
                        </ion-col>

                        <ion-col size="12" size-sm="6" v-if="['whatsapp', 'sms', 'viber'].includes(editBroadcast.channel)">
                          <ion-item>
                            <ion-label>By uploading a CSV file with contacts</ion-label>
                            <ion-radio value="UPLOAD_FILE" @click="() => csvToUpload = undefined"></ion-radio>
                          </ion-item>
                        </ion-col>

                        <ion-col size="12" size-sm="6" v-if="allSegments.length > 0">
                          <ion-item>
                            <ion-label>By selecting segments</ion-label>
                            <ion-radio value="SELECT_SEGMENT" @click="() => csvToUpload = undefined"></ion-radio>
                          </ion-item>
                        </ion-col>

                      </ion-row>
                    </ion-radio-group>
                  <!-- End Common settings options -->

                  <div v-if="targetingOption == 'UPLOAD_FILE'" class="ion-no-padding gridMargin">
                    <h2 class="ion-no-padding hyperlink_cust ion-no-margin" style="padding-bottom: 20px">Targeting</h2>
                    <ion-grid>
                      <ion-row>
                        <div class="warning-img"> - </div>  
                        <ion-col>
                          <span class="warning-desc"> Upload a CSV file with the following mandatory columns: Country Code, Mobile Number, First Name,Last Name.You can also include additional columns that you may want to save or use for the broadcast.<br/>Download a sample CSV from <a class="link-text" :href="environment('VUE_APP_ROOT')+`/web-s/resources/samplebroadcast.csv`" target="_blank">here</a></span>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                    <ion-item lines="none">
                      <input v-if="!csvToUpload" type="file" style="display: none;" id="headerDocument" ref="headerDocument" @input="onSelectUploadFile" accept=".csv" />
                      <div v-if="csvToUpload" class="selectedCSV"> 
                        <p class="ion-no-margin file-name">{{ csvToUpload.name }} </p>
                        <ion-icon :icon="closeCircleOutline" class="closeBttn" @click="() => csvToUpload = undefined"></ion-icon>
                      </div>
                      <div v-if="editBroadcast.file_path && editBroadcast.file_path.length" style="margin-right: 10px;">  
                        <a target="_blank" :href="editBroadcast.file_path" style="text-decoration: none">
                          Download previously uploaded CSV
                        </a>
                      </div>
                      <ion-button v-if="!csvToUpload" @click="() => $refs.headerDocument.click()" color="light" expand="full" background="solid" class="uploadBttn" >
                        <ion-icon :icon="documentOutline"  style="margin-right: 5px; color: black"></ion-icon>
                        Upload a CSV file with contacts
                      </ion-button>
                    </ion-item>
                  </div>

                  <div v-if="targetingOption == 'SELECT_FILTERS'" class="segment-filter-section" >
                    <h2 class="ion-no-padding hyperlink_cust ion-no-margin">Targeting</h2>
                    <CustomerSegments ref="CustomerSegments" :editBroadcast="editBroadcast" :selectedSocialPageId="selectSocialPageId"></CustomerSegments>
                    <SegmentsTargetingCount ref="SegmentsTargetingCount" @callFetchTargetedCustomerCount="callFetchTargetedCustomerCount"></SegmentsTargetingCount>
                  </div>

                  <div v-if="targetingOption == 'SELECT_SEGMENT' && allSegments.length > 0" class="select-segment-wrapper">
                    <div class="sub-text">
                        <ion-label slot="start">Select a segment</ion-label>
                    </div>
                    <ion-list >
                      <ion-item lines="none">
                        <ion-select v-model="selectedSegment" slot="start" placeholder="Select a segment" class="ion-no-padding">
                          <ion-select-option v-for="segment in allSegments" :value="segment" :key="segment.id">{{ segment.name }}</ion-select-option>
                        </ion-select>
                      </ion-item>
                      <ion-item lines="none">
                        <SegmentsTargetingCount ref="SegmentsTargetingCount" @callFetchTargetedCustomerCount="callFetchTargetedCustomerCount"></SegmentsTargetingCount>
                      </ion-item>
                    </ion-list>
                  </div>
                </ion-list>
              </ion-accordion>

              <ion-accordion v-if="!IS_ASIA" class="model-sections">
                <ion-item slot="header" lines="none">
                  <ion-text class="hyperlink_cust">
                    Scheduling
                  </ion-text>
                </ion-item>
                <ion-list  slot="content"  class="ion-no-padding"  style="border-top: 1px solid #EDEDED">
                  <div class="ion-no-padding gridMargin">
                    <h2 class="ion-no-padding sctn-labels ion-no-margin">When would you like to send this broadcast?</h2>
                    <div>
                      <span class="warning">-</span>
                      <span class="sctn-labels"> {{ sendNow === true ? "This broadcast will be sent as soon as you hit the “Save & Send” button!" : "This broadcast will be sent on the date & time selected below taking into account the timezone selected as well." }}</span>
                    </div>
                    <ion-grid class="ion-no-padding gridMargin">
                      <ion-row class="rowMargin ion-justify-content-between">
                        <ion-col size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="slct-channel ion-no-padding ion-align-self-center slct-fltr" style="padding: 20px">
                          <div style="display: inline-block">
                            <ion-label class="ion-no-padding orderDesc"> Send now</ion-label>
                          </div>
                          <div style="float: right">
                            <ion-radio-group v-model="sendNow">
                              <ion-radio slot="start" :value="true"></ion-radio>
                            </ion-radio-group>
                          </div>
                        </ion-col>
                        <ion-col size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="slct-channel ion-no-padding ion-align-self-center slct-fltr" style="padding: 20px">
                          <div style="display: inline-block">
                            <ion-label class="ion-no-padding orderDesc"> Send later</ion-label>
                          </div>
                          <div style="float: right">
                            <ion-radio-group v-model="sendNow">
                              <ion-radio slot="start" :value="false"></ion-radio>
                            </ion-radio-group>
                          </div>
                        </ion-col>
                        <ion-col size-xl="2" size-lg="2" size-sm="0" size-xs="0"></ion-col>
                      </ion-row>
                    </ion-grid>
                    
                    <div :class="{ hideSendLaterSection: sendNow }">
                     <ion-label for="date-input" @click="$refs.openDateInput.click()" class="common-label ion-hide-md-up" style="margin-left: 24px" >Select Date &amp; Time</ion-label>   <!-- Responsive send Text -->
                      <ion-item lines="none" class="slct-channel">
                        <div class="date-time-wrapper">
                          <div class="left">
                            <ion-label for="date-input" @click="$refs.openDateInput.click()"  class="ion-hide-md-down">Select Date &amp; Time</ion-label>  <!-- Desktop send text -->
                            <!-- <ion-input @focus="$refs.openDateInput.click()" v-model="editBroadcast.send_date_time"  id="date-input ion-no-padding"></ion-input> -->
                            <span class="str-date-time-span">{{ scheduledDateTime }}</span>
                          </div>
                          <div class="right">
                            <!-- <ion-button  id="openDateInput" ref="openDateInput" fill="clear" color="dark">
                              <ion-icon slot="start" :icon="calendar"></ion-icon>
                            </ion-button>
                            <ion-popover side="top" trigger="openDateInput" alignment="center" style="top: -341px">
                              <ion-datetime
                                display-format="DD/MM/YY hh:mm a"
                                :min="moment().add(5, 'minutes').toISOString()"
                                v-model="editBroadcast.send_date_time"
                                ref="sendDateTime"
                                name="sendDateTime"
                                
                                presentation="date-time"
                                id="popover-datetime"
                              ></ion-datetime>
                            </ion-popover> -->
                            <DateTime :dateTime="editBroadcast.send_date_time" @onChange="(changedDateTime) => editBroadcast.send_date_time = changedDateTime"></DateTime>
                          </div>
                        </div>
                      </ion-item>
                       <ion-label class="common-label ion-hide-md-up" style="margin-left: 18px">Select Timezone</ion-label>
                      <ion-item lines="none" class="slct-channel">
                        <ion-label class="ion-hide-md-down">Select Timezone</ion-label>
                        <ion-select v-model="editBroadcast.timezone" style="max-width: 100%">
                          <ion-select-option disabled :value="undefined">Select Timezone</ion-select-option>
                          <ion-select-option v-for="thisZone in allTimezonesData.allTimezonesData" :key="thisZone.id" disabled:value="thisZone.id"> 
                            {{ thisZone.slashName }}
                          </ion-select-option>
                        </ion-select>
                      </ion-item>
                    </div>                     
                  </div>
                </ion-list>
              </ion-accordion>
            </ion-accordion-group>
          </ion-col>

          <!-- Preview Section -->
          <template v-if="editBroadcast && adMessageJsonElements">
            <ion-col v-if="adMessageJsonElements.length || selectedBroadCastChannel.value == 'whatsapp'" size-xl="4" size-lg="4" size-sm="12" size-xs="12">
              <WhatsAppPreview v-if="selectedBroadCastChannel.value == 'whatsapp'" :body="objWhatsAppPreview"></WhatsAppPreview>
              <JsonBuilderPreview v-else :adMessageJsonElements="adMessageJsonElements"></JsonBuilderPreview>
            </ion-col>
          </template>

        </ion-row>
      </ion-grid>
    </ion-content>

    <button v-if="!editMode" type="button" class="submitBtn addNewBttn" id="addBtn" @click="onClickAddNewBroadcast" >
      <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline" ></ion-icon>
    </button>
    <ion-alert
      v-if="broadcastIndexToDelete > -1"
      :is-open="broadcastIndexToDelete > -1"
      header="Are you sure?"
      :message="'You want to delete selected broadcast?'"
      :buttons="[
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => (broadcastIndexToDelete = -1),
        },
        {
          text: 'Yes',
          handler: () => deleteBroadcast(),
        },
      ]"
      @didDismiss="() => (broadcastIndexToDelete = -1)"
    >
    </ion-alert>

    <ion-modal v-if="showCreditsModal" :is-open="showCreditsModal" @didDismiss="() => (showCreditsModal = false)">
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">User Credit Details</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => (showCreditsModal = false)">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="mobile-merchant-credit-wrapper">
          <MerchantCredits></MerchantCredits>
        </ion-content>
      </ion-page>
    </ion-modal>
  </master-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, eyeOutline, informationCircleOutline, calendar, arrowBackOutline, documentOutline, addOutline, videocamOutline, searchOutline, trashOutline, close, createOutline, cloudDownloadOutline, closeCircleOutline, refreshCircleOutline } from "ionicons/icons";
import { IonInfiniteScroll, IonInfiniteScrollContent, IonAccordion,IonAccordionGroup,IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonPopover, IonCheckbox } from "@ionic/vue";
import JsonBuilderWrapper from "./json-builder-wrapper.vue";
import JsonBuilderPreview from "./json-builder-preview.vue";
import CustomerSegments from "../../../components/customer-segments.vue";
import SegmentsTargetingCount from "../../../components/SegmentsTargetingCount.vue";
import WhatsAppCreateMessage from "./whats-app-create-message.vue";
import WhatsAppPreview from "./whats-app-preview.vue";
import moment from 'moment-timezone';
import ActionPopOver from "../../../components/ActionPopOver.vue";
import MerchantCredits from "../../../components/MerchantCredits.vue";
import DateTime from "../../../components/DateTime.vue";

const availableCategories = [
  {
    name: "Messenger",
    value: "facebook",
    img: "https://jumperdevnew.appspot.com/landing/resources/images/messenger.png",
    selected: true,
    supported_templates: "all",
    active: true,
    id: "facebook-messenger"
  },
  {
    name: "WhatsApp",
    value: "whatsapp",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/whatsapp.png",
    selected: false,
    supported_templates: "all",
    active: true,
    id: "whatsapp"
  },
  {
    name: "iMessage",
    value: "imessage",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/iMessage.png",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: false,
    id: "imessage"
  },
  {
    name: "LINE",
    value: "line",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/LINE.png",
    selected: false,
    supported_templates: "all",
    active: true,
    id: "line"
  },
  {
    name: "Google Business Messages",
    value: "googlebusinessmsg",
    img: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA0Irc-7ALDA",
    selected: false,
    supported_templates: "all",
    active: true,
    id: "googlebusinessmsg"
  },
  {
    name: "Telegram",
    value: "telegram",
    img: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: true,
    id: "telegram"
  },
  {
    name: "SMS",
    value: "sms",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg",
    selected: false,
    supported_templates: "buttonreply",
    active: true,
    id: "sms",
  },
  {
    name: "Viber",
    value: "viber",
    img: "https://jumperdevnew.appspot.com/landing/resources/images/viber.png",
    selected: false,
    supported_templates: "all",
    active: true,
    id: "viber",
  },
  {
    name: "Twitter",
    value: "twitter",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/Twitter.png",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: false,
    id: "twitter",
  },
  {
    name: "Web",
    value: "jumper",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/ecommerce-website.png",
    selected: false,
    supported_templates: "all",
    active: false,
    id: "jumper",
  }
];

const editBroadcast = {
  answers: [],
  category: null,
  channel: availableCategories[0].value,
  commentmessages: "",
  custom_field: null,
  custom_messages: [],
  file_path: undefined,
  id: undefined,
  last_sent: null,
  message: undefined,
  name: "",
  pageid: null,
  runaction: "",
  send_date_time: null,
  sendtag: undefined,
  sent_status: undefined,
  subcategory: null,
  targeting_conditions: [],
  targeting_count: 0,
  targeting_created: undefined,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  version: "v4.0",
  wa_language: "en",
  wa_message_params: { BODY: [] },
  wa_templateid: undefined,
};

const statusMapping = {
  not_ran_yet: "Pending"
}

export default {
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonAccordionGroup,
    IonAccordion,
    IonSelectOption,
    IonSelect,
    IonRadio,
    IonRadioGroup,
    IonPopover,
    IonCheckbox,
    JsonBuilderWrapper,
    JsonBuilderPreview,
    CustomerSegments,
    WhatsAppCreateMessage,
    WhatsAppPreview,
    ActionPopOver,
    MerchantCredits,
    SegmentsTargetingCount,
    DateTime
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      arrowBackOutline,
      documentOutline,
      videocamOutline,
      eyeOutline,
      addOutline,
      searchOutline,
      trashOutline,
      informationCircleOutline,
      createOutline,
      calendar,
      close,
      closeCircleOutline,
      refreshCircleOutline,
      loading: false,
      broadcastListLoading: false,
      allbroadcasts: [],
      allTemplates: [],
      allTags: [],
      allAgents: [],
      allProducts: [],
      allCollections: [],
      allBots: [],
      broadcastsLoadMore: false,
      broadcastsLoaded: false,
      broadcastIndexToDelete: -1,
      broadcastIndexToEdit: -1,
      broadcastFilter: undefined,
      editMode: false,
      prev: undefined,
      next: undefined,
      sendMode: "csv",
      activeTab: 'template',
      csvToUpload: undefined,
      filterConditions: "and", //or,
      addCondtionMode: false,
      tagFilter: false,
      interactionFilter: false,
      interactionDateFilter: false,
      interactionDate: { operator: undefined, date: undefined },
      interactionTimeFilter: false,
      interactionTime: { operator: undefined, time: undefined },
      agentFilter: false,
      productFilter: false,
      collectionFilter: false,
      botFilter: false,
      channelFilter: false,
      personalFilter: false,
      nameFilter: false,
      emailFilter: false,
      mobileFilter: false,
      personal: {
        name: undefined,
        email: undefined,
        mobile: undefined,
        operator: undefined,
      },
      genderFilter: false,
      customFilter: false,
      custom: {
        field: undefined,
        operator: undefined,
      },
      sendNow: true,
      availableCategories,
      dummyTemplate: {
        category: undefined,
        collection_id: undefined,
        id: undefined,
        language: undefined,
        status: undefined,
        template_id: undefined,
        template_name: undefined,
      },
      multipleButtons: "single",
      allButtons: [],
      singleButton: {
        text: undefined,
        type: undefined,
        payload: undefined,
      },
      body: {
        header: {
          type: undefined,
          text: undefined,
          example: [],
        },
        body: {
          text: undefined,
          params: [],
          message_params: [],
        },
        footer: undefined,
      },
      selectedBroadCastChannel: availableCategories[0],
      editBroadcast: {...editBroadcast },
      adMessageJsonElements: [],
      messagePurpose: "NON_PROMOTIONAL_SUBSCRIPTION",
      targetingOption: 'SELECT_FILTERS',
      allSegments: [],
      selectedSegment: '',
      objWhatsAppPreview: {},
      statusMapping,
      cloudDownloadOutline,
      showCreditsModal: false,
      broadCastOutcomeId: undefined
    };
  },
  computed: {
    ...mapGetters(
      {
        faceBookPages: 'social/getFacebook',
        getLine: 'social/getLine',
        getWhatsApp: 'social/getWhatsApp',
        getGBusiness: 'social/getGBusiness',
        getSMS: 'social/getSMS',
        getViber: 'social/getViber',
        getTelegram: 'social/getTelegram',
        connectedChannels: 'social/connectedChannels',
        authData: "auth/getAuthData"
      },
    ),
    ...mapState({
      userCredits: (state) => state.user.credits,
    }),
    pageTitle() {
      return this.isViewMode ? 'View Broadcast' : this.editBroadcast?.id ? 'Update Broadcast' : 'Create Broadcast';
    },
    isViewMode() {
      return ['running', 'done', "Loading"].includes(this.editBroadcast?.sent_status);
    },
    selectSocialPageId() {
      let pageId;
      switch (this.editBroadcast.channel) {
        case 'facebook':
          pageId = this.faceBookPages?.pages[0]?.messenger;
        break;
        case 'whatsapp':
          pageId = this.getWhatsApp.id;
        break;
        case 'line':
          pageId = this.getLine.channelaccesstoken;
        break;
        case 'googlebusinessmsg':
          pageId = this.getGBusiness.id;
          break;
        case 'sms':
          pageId = this.getSMS.id;
          break;
        case 'viber':
          pageId = this.getViber.pageid;
        break;
        case 'telegram':
          pageId = this.getTelegram.id;
        break;
      }
      return pageId;
    },
    broadcastsToShow() {
      let broadcasts = [];
      this.allbroadcasts.forEach((broadcast) => {
        let extendObj = {};
        const { send_date_time, last_sent, created, timezone, sent_status, targeting_count, channel } = broadcast;
        extendObj.executionLabel = sent_status === 'done' ? 'Executed on' : 'Scheduled for';
        extendObj.executionTz = timezone || this.user.timezone || moment.tz.guess();
        extendObj.outcomeLoading = this.broadCastOutcomeId == broadcast.id;

        // set delete button enabled or not
        extendObj.isEnabledDeleteButton = ['done', 'not_ran_yet'].includes(broadcast.sent_status);
        extendObj.isEnabledEditButton = ['done', 'not_ran_yet'].includes(broadcast.sent_status)

        // visibility of download broadcast csv
        extendObj.showDownloadCsv = ![null, undefined, '', 'null', 'undefined'].includes(broadcast.file_path?.trim()) && broadcast.targeting_count && broadcast.targeting_count != null && broadcast.targeting_count <= 100000;


        // format created date
        const createdUtcFormat = moment.utc(created).toDate();
        if (this.user.timezone) {
          extendObj.createdOnFormatted = moment(createdUtcFormat).tz(this.user.timezone).format('DD-MM-YYYY HH:mm');
        } else {
          extendObj.createdOnFormatted = moment(createdUtcFormat).local().format('DD-MM-YYYY HH:mm');
        }
        // ***** end format created date *****


        // format execution date
        extendObj.executionDate = send_date_time || last_sent;
        if (extendObj.executionDate) {
          const executionUtcFormat = moment(extendObj.executionDate).toDate();
          extendObj.executionDate = moment(executionUtcFormat).format('DD-MM-YYYY HH:mm');
        } else {
          extendObj.executionDate = extendObj.createdOnFormatted
        }
        // ***** end format created date *****

        // broadcast outcome button visibility
        const isValidTargetingCount = targeting_count && targeting_count != null && targeting_count <= 100000;
        const statusDone = sent_status == "done";
        const isAllowedChannels = ["whatsapp", "sms", "viber", "telegram"].includes(channel);
        const isExecuted_30min_later = this.broadcastExecuted_30mins_later(last_sent, created, timezone) == true;
        const now = moment().tz(extendObj.executionTz);
        const createdTime = moment(createdUtcFormat).tz(extendObj.executionTz);
        const is_30min_passed_from_created = now.diff(createdTime, 'minutes') > 30 && now > createdTime;
        extendObj.isVisibleBroadcastOutcomeButton = is_30min_passed_from_created && isValidTargetingCount && statusDone && isAllowedChannels && isExecuted_30min_later;
        // ***** end broadcast outcome visibility
        
        if (this.broadcastFilter?.length) {
          if (broadcast.name.match(new RegExp(this.broadcastFilter, "gi"))) {
            broadcasts.push({...broadcast, ...extendObj});
          }
        } else {
          broadcasts.push({ ...broadcast, ...extendObj });
        }
      })
      return broadcasts;
    },
    allLanguages() {
      let languages = [];
      try {
        languages = this.allTemplates.find((e) => e.templates[0]?.template_id === this.editBroadcast?.wa_templateid) ? [this.allTemplates.find((e) => e.templates[0]?.template_id === this.editBroadcast.wa_templateid).templates[0]?.language] : [];       
      } catch (error) {
        console.error("allLanguages loading failed.", error);
      }
      return languages;
    },
    allEditBroadcastFilterQueries() {
      return this.editBroadcast.targeting_conditions.map((e) => decodeURIComponent(e.query));
    },
    allEditBroadcastConditions() {
      return this.allEditBroadcastFilterQueries?.map((e) => {
        if (e.includes("in unnest(tags)"))
          return {
            name: "Tags",
            value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
          };
        if (e.includes("LastActive")) {
          if (e.includes("LastActiveTimestamp"))
            return {
              name: "Last Interaction Time",
              type: "timestampWithFilter",
              operator: e.substring(
                e.indexOf("LastActiveTimestamp ") + 20,
                e.indexOf(' "')
              ),
              value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
            };
          return {
            name: "Last Interaction Date",
            type: "dateWithFilter",
            operator: e.substring(
              e.indexOf("LastActive ") + 11,
              e.indexOf(' "')
            ),
            value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
          };
        }
        if (e.includes("in unnest(agent_id)")) {
          let aId = e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'));
          let agent = this.allAgents.find((a) => a.id == aId);
          if (agent) {
            return {
              name: "Talked with Agent",
              value: agent.name,
            };
          }
        }
        if (e.includes("in unnest(Products)")) {
          let pId = undefined;
          if (e.indexOf('"') > -1)
            pId = e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'));
          else pId = e.substring(0, e.indexOf(" in unnest(Products)"));
          let product = this.allProducts.find((p) => p.id == pId);
          let collection = this.allCollections.find((p) => p.id == pId);
          let bot = this.allBots.find((p) => p.id == pId);
          if (product)
            return {
              name: "Engaged with Product Flow",
              value: product.name,
            };
          if (collection)
            return {
              name: "Engaged with Collection Flow",
              value: collection.name,
            };
          if (bot)
            return {
              name: "Engaged with Bot Flow",
              value: bot.displayname,
            };
        }
        if (e.includes("platform =")) {
          let platform = undefined;
          switch (e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'))) {
            case "facebook":
              platform = "Messenger";
              break;
            case "whatsapp":
              platform = "WhatsApp";
              break;
            case "line":
              platform = "Line";
              break;
          }
          if (platform)
            return {
              name: "Interaction Channel",
              value: platform,
            };
        }
        if (e.includes("ItemInCartValue_L24H"))
          return {
            name: "Has Item in Cart and not Paid",
            value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
          };
        if (e.includes("user_name"))
          return {
            name: "Name",
            operator:
              e.indexOf(' "') > -1
                ? e.substring(e.indexOf("user_name ") + 10, e.indexOf(' "'))
                : e.substring(e.indexOf("user_name ") + 10),
            value:
              e.indexOf(' "') > -1
                ? e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'))
                : undefined,
          };
        if (e.includes("user_email"))
          return {
            name: "Email",
            operator:
              e.indexOf(' "') > -1
                ? e.substring(e.indexOf("user_email ") + 11, e.indexOf(' "'))
                : e.substring(e.indexOf("user_email ") + 11),
            value:
              e.indexOf(' "') > -1
                ? e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'))
                : undefined,
          };
        if (e.includes("user_mobilenumber"))
          return {
            name: "Number",
            operator:
              e.indexOf(' "') > -1
                ? e.substring(
                    e.indexOf("user_mobilenumber ") + 18,
                    e.indexOf(' "')
                  )
                : e.substring(e.indexOf("user_mobilenumber ") + 18),
            value:
              e.indexOf(' "') > -1
                ? e.substring(e.indexOf('"') + 1, e.lastIndexOf('"'))
                : undefined,
          };
        if (e.includes("user_gender"))
          return {
            name: "Gender",
            value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
          };
        if (e.includes("in unnest(custom_fields)")) {
          if (e.includes("not in unnest(custom_fields)"))
            return {
              name: "Custom Field",
              operator: "isn't",
              value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
            };
          return {
            name: "Custom Field",
            operator: "is",
            value: e.substring(e.indexOf('"') + 1, e.lastIndexOf('"')),
          };
        }
        if (e.includes("custom_fields is null"))
          return {
            name: "Custom Field",
            operator: "is null",
            value: undefined,
          };
      });
    },
    channelsToRender() {
      let channelsToRender = [];
      this.availableCategories.map((channel) => {
        if (channel.active && this.connectedChannels.includes(channel.id)) {
          let messageLimit = 0, messageLimitAbbreviated = 0;
          if(this.userCredits?.messages) {
            messageLimit = this.userCredits?.messages[channel.value];
            messageLimitAbbreviated = Intl.NumberFormat('en-US', {
              notation: "compact",
              maximumFractionDigits: 0
            }).format(messageLimit);
          }
          channelsToRender.push({...channel, ...{ messageLimit, messageLimitAbbreviated }});
        }
      });
      return channelsToRender;
    },
    scheduledDateTime() {
      const { send_date_time } = this.editBroadcast;
      if (send_date_time) {
        return moment(send_date_time).format('DD/MM/YYYY HH:mm:ss');
      }
      return "";
    }
  },
  ionViewWillEnter() {
    this.fetchTemplates();
    this.fetchBroadcasts();
    // this.fetchConditionOptions();
    this.fetchSegments();
    let url = this.environment('VUE_APP_ROOT_API') + "get-user-credits";
    this.fetchCreditsForUser(url);
    this.onPageLoad();
  },
  watch: {
    broadcastIndexToEdit: {
      handler: function() {
        if (this.broadcastIndexToEdit > -1 && this.editBroadcast.wa_templateid) {
          this.fetchTemplate(this.editBroadcast.wa_templateid);
        }
        this.resetSettings();
        this.editBroadcastObjectHandler();
      },
      immediate: true
    },
    "editBroadcast.wa_templateid"() {
      if (this.editBroadcast.wa_templateid)
        this.fetchTemplate(this.editBroadcast.wa_templateid);
    },
    "editBroadcast.file_path"() {
      if (this.editBroadcast.file_path && this.editBroadcast.file_path.length)
        this.sendMode = "csv";
    },
    // "editBroadcast.channel"(nVal) {
    //   console.log('>>>>> editBroadcast.channel');
    //   let thisCondIndex = this.allEditBroadcastConditions?.findIndex(
    //     (c) => c?.name == "Interaction Channel"
    //   );
    //   if (thisCondIndex > -1)
    //     this.editBroadcast.targeting_conditions[
    //       thisCondIndex
    //     ].query = encodeURIComponent('platform = "' + nVal + '"');

    //   this.resetSettings();
    // },
    "editBroadcast.send_date_time"() {
      if (this.editBroadcast.send_date_time) this.sendNow = false;
    },
    "editBroadcast.targeting_conditions": {
      handler: function(newValue) {
        if (newValue && newValue.length) {
          if (newValue[0].operator === "and") this.filterConditions = "and";
          else this.filterConditions = "or";
        }
      },
      deep: true,
    },
    allLanguages: {
      handler: function(newVal) {
        if (newVal && newVal.length)
          this.editBroadcast.wa_language = this.allLanguages[0];
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      fetchCreditsForUser: "user/fetchCreditsForUser",
    }),
    onPageLoad() {
      this.broadcastIndexToEdit = -1;
      this.editBroadcast = { ...editBroadcast };
      this.editMode = false;
      this.resetSettings();
    },
    onChangeChannel() {
      this.csvToUpload = undefined;
      this.targetingOption = 'SELECT_FILTERS';
      this.$refs.JsonBuilderWrapper?.clearElements();
    },
    callFetchTargetedCustomerCount() {
      let formattedConditionJson;
      if (this.targetingOption == 'SELECT_SEGMENT') {
        formattedConditionJson = this.selectedSegment.targeting_conditions;
      } else {
        formattedConditionJson = this.$refs.CustomerSegments.getFormattedJsonToSave();
      }
      this.$refs.SegmentsTargetingCount.fetchTargetedCustomerCount(formattedConditionJson);
    },
    broadcastExecuted_30mins_later(last_sent, created, timezone) {
        if (typeof created != 'undefined') {
          let executedDate_tz = this.user.timezone;
          let executedDate = "";
          if (typeof timezone != 'undefined' && timezone != '' && timezone != 'None' && timezone != null) {
            executedDate_tz = timezone;
          }
          if (typeof last_sent != 'undefined' && last_sent != '' && last_sent != 'None' && last_sent != null) {
            executedDate = last_sent;
          }
          else {
            executedDate = created;
          }
          const startDate = this.moment(executedDate).tz(executedDate_tz);
          const endDate = this.moment().tz(executedDate_tz);
          if (endDate.diff(startDate, 'minutes') >= 30) {
            return true;
          } else {
            return false;
          }
        }
    },
    preloadEditValues() {
      this.availableCategories.forEach((channel) => {
        if (channel.value == this.editBroadcast.channel) {
          this.selectedBroadCastChannel = channel;
        }
      });
      if (this.editBroadcast.segmentid) {
        this.targetingOption = 'SELECT_SEGMENT';        
      } else if (this.editBroadcast?.targeting_conditions?.length) {
        this.targetingOption = 'SELECT_FILTERS';
      } else if (this.editBroadcast?.file_path?.trim()) {
        this.targetingOption = 'UPLOAD_FILE';
      }
    },
    resetSettings() {
      this.messagePurpose = "NON_PROMOTIONAL_SUBSCRIPTION",
      this.targetingOption = 'SELECT_FILTERS';
      this.csvToUpload = undefined;
    },
    onClickAddNewBroadcast() {
      this.editMode = true;
      this.editBroadcastObjectHandler();
    },
    adMessageJsonElementsHandler({ finalJsonElements }) {
      this.adMessageJsonElements = finalJsonElements;
    },
    editBroadcastObjectHandler() {
      if (this.broadcastIndexToEdit > -1) {
        this.editBroadcast = { ...this.broadcastsToShow[this.broadcastIndexToEdit] };
        // this.editBroadcast.send_date_time = this.moment(this.editBroadcast.send_date_time).toISOString();
        this.preloadEditValues();
      } else {
        if (this.IS_ASIA) {
          this.editBroadcast = { ...editBroadcast, ...{ name: "Broadcast #" + this.allbroadcasts.length } };
        } else {
          this.editBroadcast = { ...editBroadcast, ...{ name: "Jumper Broadcast #" + this.allbroadcasts.length } };
        }
        setTimeout(() => {
          this.selectedBroadCastChannel = this.channelsToRender[0];
        }, 500);
        this.editBroadcast.channel = this.channelsToRender[0]?.value;
      }
    },
    doRefresh(e) {
      this.broadcastsLoaded = false;
      e.target.complete();
    },
    onSelectBroadCastChannel(event, selectedChannel) {
      event.stopImmediatePropagation();
      this.selectedBroadCastChannel = { ...selectedChannel };
      this.editBroadcast.channel = selectedChannel.value;
      if(this.$refs?.CustomerSegments?.conditions?.length) {
        this.$refs.CustomerSegments.conditions = [];
      }

      // timeout is used to get right page id, selectedSocialPageId is prop passed to CustomerSegments component.
      // selectedSocialPageId is compute function which runs later.
      setTimeout(() => {
        this.$refs?.CustomerSegments?.setDefaultConditions();
      }, 500);
    },
    async fetchTemplates() {
      this.loading = true;
      try {
        let allTemplatesResponse = await jwtInterceptor.post(
          this.environment('VUE_APP_ROOT') + "whatsapp/message-template/fetch",
          { limit: "all" }
        );
        if (
          (allTemplatesResponse.status == 200 ||
            allTemplatesResponse.status == 201) &&
          allTemplatesResponse.data.success
        ) {
          this.allTemplates = allTemplatesResponse.data.data;
        } else {
          if (
            allTemplatesResponse.data &&
            !allTemplatesResponse.data.success &&
            allTemplatesResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: allTemplatesResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
            //console.log("Response:", allTemplatesResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchTemplate(toFetchId) {
      try {
        this.loading = true;
        let id = this.allTemplates.find(
          (e) => e.templates[0]?.template_id === toFetchId
        ).templates[0].id;
        let getTemplateResponse = await jwtInterceptor.post(
          this.environment('VUE_APP_ROOT') + "whatsapp/message-template/get",
          { id: id }
        );
        if (
          (getTemplateResponse.status == 200 ||
            getTemplateResponse.status == 201) &&
          getTemplateResponse.data.success
        ) {
          let thisTemplate = getTemplateResponse.data.data;
          this.dummyTemplate.category = thisTemplate.category;
          this.dummyTemplate.collection_id = thisTemplate.collection_id;
          this.dummyTemplate.id = thisTemplate.id;
          this.dummyTemplate.language = thisTemplate.language;
          this.dummyTemplate.status = thisTemplate.status;
          this.dummyTemplate.template_id = thisTemplate.template_id;
          this.dummyTemplate.template_name = thisTemplate.template_name;
          this.body.body.text = thisTemplate.message.BODY.text;
          if (
            thisTemplate.message.BUTTONS &&
            thisTemplate.message.BUTTONS.length
          ) {
            if (thisTemplate.message.BUTTONS[0].type === "QUICK_REPLY") {
              this.multipleButtons = "multiple";
              thisTemplate.message.BUTTONS.forEach((thisButton) => {
                this.allButtons.push({
                  text: thisButton.text,
                  type: thisButton.type,
                  payload: thisButton.payload,
                });
              });
            } else {
              let thisButton = thisTemplate.message.BUTTONS[0];
              this.multipleButtons = "single";
              this.singleButton.text = thisButton.text;
              thisButton.example &&
              thisButton.example.length &&
              thisButton.example[0].includes("http")
                ? (this.singleButton.type = "url")
                : (this.singleButton.type = "tel");
              this.singleButton.payload = thisButton.url;
            }
          }
          if (
            thisTemplate.message_params.BODY &&
            thisTemplate.message_params.BODY.length
          ) {
            thisTemplate.message_params.BODY.forEach(
              (thisParam, paramIndex) => {
                let param = Object.keys(thisParam)[0];
                let pIndex = paramIndex + 1;
                switch (param) {
                  case "<username>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{User's First Name}"
                      );
                      this.body.body.params.push({
                        text: "{User's First Name}",
                        param: { "<username>": "text" },
                        example: "John",
                      });
                    }
                    break;
                  case "<shopname>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Store Name}"
                      );
                      this.body.body.params.push({
                        text: "{Store Name}",
                        param: { "<shopname>": "text" },
                        example: "My Store",
                      });
                    }
                    break;
                  case "<agentname>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Agent Name}"
                      );
                      this.body.body.params.push({
                        text: "{Agent Name}",
                        param: { "<agentname>": "text" },
                        example: "Bob Parker",
                      });
                    }
                    break;
                  case "<sellername>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Account Owner Name}"
                      );
                      this.body.body.params.push({
                        text: "{Account Owner Name}",
                        param: { "<sellername>": "text" },
                        example: "Steve Braun",
                      });
                    }
                    break;
                  case "<selleremail>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Support E-mail}"
                      );
                      this.body.body.params.push({
                        text: "{Support E-mail}",
                        param: { "<selleremail>": "text" },
                        example: "support@mybrandstore.com",
                      });
                    }
                    break;
                  case "<sellerphone>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Support Phone}"
                      );
                      this.body.body.params.push({
                        text: "{Support Phone}",
                        param: { "<sellerphone>": "text" },
                        example: "+6590000000",
                      });
                    }
                    break;
                  case "<menuaddress>":
                    {
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{Outlet Address}"
                      );
                      this.body.body.params.push({
                        text: "{Outlet Address}",
                        param: { "<menuaddress>": "text" },
                        example:
                          "01-02, Evershine Avenue, Beach Road, Singapore - 409902",
                      });
                    }
                    break;
                  default: {
                    if (param.includes("<custom_field_")) {
                      let customFieldText = param.replace("<custom_field_", "");
                      customFieldText = customFieldText.replace(">", "");
                      this.body.body.text = this.body.body.text.replace(
                        "{{" + pIndex + "}}",
                        "{custom_field_" + customFieldText + "}"
                      );
                      let paramName = "<custom_field_" + customFieldText + ">";
                      let obj = {};
                      obj[paramName] = "text";
                      this.body.body.params.push({
                        text: "{custom_field_" + customFieldText + "}",
                        key: customFieldText,
                        param: obj,
                        example: "Custom field",
                      });
                    }
                  }
                }
              }
            );
          }
          this.body.header.format = thisTemplate.message.HEADER
            ? thisTemplate.message.HEADER.format
            : undefined;
          this.body.header.format === "TEXT"
            ? (this.body.header.text = thisTemplate.message.HEADER.text)
            : "";
          this.body.footer = thisTemplate.message.FOOTER
            ? thisTemplate.message.FOOTER.text
            : undefined;
        } else {
          if (
            getTemplateResponse.data &&
            !getTemplateResponse.data.success &&
            getTemplateResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: getTemplateResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
            //console.log("Response:", getTemplateResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async onSelectUploadFile(event) {
      try {
        const fileToSend = event.target.files[0];
        let $this = this;
        if (fileToSend && fileToSend.name.lastIndexOf(".csv") !== -1) {
          let newCsv = new FileReader();
          newCsv.readAsText(fileToSend);
          newCsv.onload = function (event) {
            let csv = event.target.result;
            let lines = csv.split("\n");
            let headers = lines[0].split(",");
            if (headers.length < 4) {
              this.updateToast({ open: true, message: "There should be atleast 4 columns in CSV", type: "danger", });
              return;
            }
            $this.csvToUpload = fileToSend;
          };
          newCsv.onerror = function () {
            this.csvToUpload = undefined;
            this.updateToast({ open: true, message: "Unable to read " + fileToSend.name, type: "danger", });
          };
        } else {
          this.csvToUpload = undefined;
          this.updateToast({ open: true, message: "Please add a valid CSV", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      }
    },
    async fetchBroadcasts(e) {
      if (!e) {
        this.broadcastListLoading = true;
      } else {
        this.broadcastsLoadMore = true;
      }

      try {
        let reqBody = null;
        if (this.next && e) {
          reqBody = {
            cursor: this.next,
          };
        }
        let allbroadcastsResponse = reqBody
          ? await jwtInterceptor.post(
              this.environment("VUE_APP_ROOT_API") + "fetch-broadcast-preset",
              reqBody
            )
          : await jwtInterceptor.post(
              this.environment("VUE_APP_ROOT_API") + "fetch-broadcast-preset"
            );
        if (
          (allbroadcastsResponse.status == 200 ||
            allbroadcastsResponse.status == 201) &&
          allbroadcastsResponse.data.success
        ) {
          if (this.next && e) {
            this.allbroadcasts = this.allbroadcasts.concat(allbroadcastsResponse.data.broadcasts);
          } else {
            this.allbroadcasts = allbroadcastsResponse.data.broadcasts;
          }
          this.prev = allbroadcastsResponse.data.prev;
          this.next = allbroadcastsResponse.data.next;
          this.broadcastsLoaded = true;
          this.allbroadcasts = this.allbroadcasts.slice();
        } else {
          if (
            allbroadcastsResponse.data &&
            !allbroadcastsResponse.data.success &&
            allbroadcastsResponse.data.errorMessage
          ) {
            this.updateToast({ open: true, message: allbroadcastsResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
            //console.log("Response:", allbroadcastsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if (e) {
          e.target.complete();
          this.broadcastsLoadMore = false;
        } else {
          setTimeout(() => {
            this.broadcastListLoading = false;
          }, 2000);
        }
      }
    },
    async deleteBroadcast() {
      this.loading = true;
      try {
        let thisBroadcastToDelete = this.broadcastsToShow[
          this.broadcastIndexToDelete
        ];
        let deleteBroadcastResponse = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "delete-broadcast-preset", { broadcastid: thisBroadcastToDelete.id });
        const { status, data } = deleteBroadcastResponse;
        if ( [200, 201].includes(status) && data.success ) {
          this.updateToast({ open: true, message: "Deleted Successfully", type: "success", });
          this.fetchBroadcasts();
        } else if (data?.errorMessage) {
          this.updateToast({ open: true, message: data.errorMessage, type: "danger", });
        } else {
          this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
        this.broadcastIndexToDelete = -1;
      }
    },
    addInteractionDate() {
      this.interactionDateFilter = false;
      if (this.interactionDate.date && this.interactionDate.operator) {
        this.editBroadcast.targeting_conditions.push({
          operator: this.filterConditions,
          query: encodeURIComponent(
            "LastActive " +
              this.interactionDate.operator +
              ' "' +
              this.interactionDate.date +
              '"'
          ),
        });
        this.interactionDate.operator = undefined;
        this.interactionDate.date = undefined;
      } else {
        this.updateToast({
          open: true,
          message: "Please select valid condition and date",
          type: "danger",
        });
      }
    },
    addInteractionTime() {
      this.interactionTimeFilter = false;
      if (this.interactionTime.time && this.interactionTime.operator) {
        this.editBroadcast.targeting_conditions.push({
          operator: this.filterConditions,
          query: encodeURIComponent(
            "LastActiveTimestamp " +
              this.interactionTime.operator +
              ' "' +
              this.interactionTime.date +
              '"'
          ),
        });
        this.interactionTime.operator = undefined;
        this.interactionTime.time = undefined;
      } else {
        this.updateToast({
          open: true,
          message: "Please select valid condition and time",
          type: "danger",
        });
      }
    },
    addPersonal() {
      if (this.personal.operator) {
        if (this.nameFilter) {
          if (this.personal.operator === "is null")
            this.editBroadcast.targeting_conditions.push({
              operator: this.filterConditions,
              query: encodeURIComponent("user_name is null"),
            });
          else
            this.personal.name
              ? this.editBroadcast.targeting_conditions.push({
                  operator: this.filterConditions,
                  query: encodeURIComponent(
                    "user_name " +
                      this.personal.operator +
                      ' "' +
                      this.personal.name +
                      '"'
                  ),
                })
              : this.updateToast({
                  open: true,
                  message: "Please enter name",
                  type: "danger",
                });
          this.nameFilter = false;
          this.personal.name = undefined;
        }
        if (this.mobileFilter) {
          if (this.personal.operator === "is null")
            this.editBroadcast.targeting_conditions.push({
              operator: this.filterConditions,
              query: encodeURIComponent("user_mobilenumber is null"),
            });
          else
            this.personal.mobile
              ? this.editBroadcast.targeting_conditions.push({
                  operator: this.filterConditions,
                  query: encodeURIComponent(
                    "user_mobilenumber " +
                      this.personal.operator +
                      ' "' +
                      this.personal.mobile +
                      '"'
                  ),
                })
              : this.updateToast({
                  open: true,
                  message: "Please enter mobile",
                  type: "danger",
                });
          this.mobileFilter = false;
          this.personal.mobile = undefined;
        }
        if (this.emailFilter) {
          if (this.personal.operator === "is null")
            this.editBroadcast.targeting_conditions.push({
              operator: this.filterConditions,
              query: encodeURIComponent("user_email is null"),
            });
          else
            this.personal.email
              ? this.editBroadcast.targeting_conditions.push({
                  operator: this.filterConditions,
                  query: encodeURIComponent(
                    "user_email " +
                      this.personal.operator +
                      ' "' +
                      this.personal.email +
                      '"'
                  ),
                })
              : this.updateToast({
                  open: true,
                  message: "Please enter email",
                  type: "danger",
                });
          this.emailFilter = false;
          this.personal.email = undefined;
        }
      } else {
        this.updateToast({
          open: true,
          message: "Please select valid condition",
          type: "danger",
        });
      }
    },
    addCustom() {
      switch (this.custom.operator) {
        case "=":
          this.custom.field
            ? this.editBroadcast.targeting_conditions.push({
                operator: this.filterConditions,
                query: encodeURIComponent(
                  '"' + this.custom.field + '" in unnest(custom_fields)'
                ),
              })
            : this.updateToast({
                open: true,
                message: "Please enter PROP:VALUE",
                type: "danger",
              });
          break;
        case "!=":
          this.custom.field
            ? this.editBroadcast.targeting_conditions.push({
                operator: this.filterConditions,
                query: encodeURIComponent(
                  '"' + this.custom.field + '" not in unnest(custom_fields)'
                ),
              })
            : this.updateToast({
                open: true,
                message: "Please enter PROP:VALUE",
                type: "danger",
              });
          break;
        case "is null":
          this.editBroadcast.targeting_conditions.push({
            operator: this.filterConditions,
            query: encodeURIComponent("custom_fields is null"),
          });
          break;
        default:
          this.updateToast({
            open: true,
            message: "Please select valid condition",
            type: "danger",
          });
      }
      this.customFilter = false;
      this.custom.field = undefined;
      this.custom.operator = undefined;
    },
    getAdMessageJson() {
      const adMessageElement = this.adMessageJsonElements[0] || {};
      const { type, payload, buttons, title, subtitle } = adMessageElement;
      let adMessageJson = {};
      switch (type) {
        case 'text':
          if (buttons?.length) {
            let buttonsToUse = [];
            buttons.forEach((elm) => {
              let elmButton = {
                title: elm.text,
                description: elm.text,
                type: elm.type,
              }
              if (elm.payload) {
                elmButton.payload = elm.payload;
              } else if(elm.url) {
                elmButton.url = elm.url;
                elmButton.height_ratio = "full";
              } else {
                elmButton.url = elm.url;
                elmButton.height_ratio = "full";
              }
              buttonsToUse.push(elmButton);
            });

            adMessageJson = [
              {
                message: [payload],
                template_type: "buttonreply",
                template: { buttons: buttonsToUse }
              }
            ]
          } else {
            adMessageJson = [{
              message: [payload],
              template_type: "normalchat"
            }]
          }
        break;
        case 'sms':
          if (buttons?.length) {
            let buttonsToUse = [];
            buttons.forEach((elm) => {
              let elmButton = {
                title: elm.text,
                description: "Button",
                type: "postback",
              }
              if (elm.payload) {
                elmButton.payload = elm.payload;
              } else {
                elmButton.url = "";
                elmButton.height_ratio = "full"
              }
              buttonsToUse.push(elmButton);
            });

            adMessageJson = [
              {
                message: [payload],
                template_type: "buttonreply",
                template: {buttons: buttonsToUse}
              }
            ]
          } else {
          adMessageJson = [{
            message: [payload],
            template_type: "normalchat"
            }]
          }
          break;
        case 'image':
          adMessageJson = [{
            message: [payload || ""],
            template_type: "cancelled"
          }]
        break;
        case 'customCard': {
          let buttonsToUse = [];
          buttons.forEach((elm) => {
            let elmButton = {
              title: elm.text,
              description: elm.text,
              type: elm.type,
            }
            if (elm.payload) {
              elmButton.payload = elm.payload;
            } else if (elm.url) {
              elmButton.url = elm.url;
              elmButton.height_ratio = "full";
            } else {
              elmButton.url = elm.url;
              elmButton.height_ratio = "full";
            }
            buttonsToUse.push(elmButton);
          });

          adMessageJson = [
            {
              message: [""],
              template_type: "carousel",
              template: {
                elements: [{
                  title,
                  subtitle,
                  imageurl: payload,
                  buttons: buttonsToUse
                }]
              }
            }
          ]
          break;
        }  
        case 'video':
          adMessageJson = [
            {
              message: [ payload || "" ],
              template_type: "videoreply",
              mediatype: "video"
            }
          ];
          break;
        case 'product':
        case 'collection':
        {
          let buttonsToUse = [];
          buttons.forEach((elm) => {
            let elmButton = {
              title: elm.text,
              description: "",
              type: "postback",
            }
            if (elm.payload) {
              elmButton.payload = elm.payload;
            }
            if(type == "product") {
              elmButton.url = "";
              elmButton.height_ratio = "full";
            }
            if (type == "collection") {
              elmButton.description = elm.text;
            }
            buttonsToUse.push(elmButton);
          });

          adMessageJson = [
            {
              message: ["carousel"],
              template_type: "carousel",
              template: {
                elements: [{
                  buttons: buttonsToUse,
                  imageurl: payload.image,
                  subtitle: `${payload.currency}${payload.totalcost}`,
                  title: payload.name
                }]
              }
            }
          ]
        }
        break;
        case 'reply':
          {
            let buttonsToUse = [];
            buttons.forEach((elm) => {
              let elmButton = {
                title: elm.text,
                description: elm.text,
              }
              if (elm.payload) {
                elmButton.payload = elm.payload;
              }
              buttonsToUse.push(elmButton);
            });

            adMessageJson = [
              {
                message: [ payload ],
                template_type: "quickreplies",
                template: {
                    buttons: buttonsToUse
                }
              }
            ]
          }
          break;
        default:
        break;
      }
      return adMessageJson;
    },
    getFormattedMessageRequestBody() {
      let reqBody, messages;
      try {
        messages = this.getAdMessageJson();

        reqBody = {
          channel: this.editBroadcast.channel,
          name: this.editBroadcast.name,
          mode: this.sendNow ? "send" : "save",
          pageid: this.selectSocialPageId,
          messages: JSON.stringify(messages),
          messagelisted: "[]",
          sendtag: this.messagePurpose,
        };

        // set params for send later
        if(!this.sendNow) {
          reqBody.timezone = this.editBroadcast.timezone;
          reqBody.send_date_time = this.moment(this.editBroadcast.send_date_time).format('DD/MM/YYYY HH:mm:ss');
        }

        // set targeting conditions
        if (this.targetingOption == 'SELECT_SEGMENT') {
          reqBody.segmentid = `${this.selectedSegment.id}`;
        } else {
          const targetingConditions = this.$refs.CustomerSegments.getFormattedJsonToSave();
          reqBody.targeting_conditions = JSON.stringify(targetingConditions);
        }

        // in case edit mode pass broadcast id
        if (this.editBroadcast?.id) {
          reqBody.id = this.editBroadcast?.id;
        }
      } catch (error) {
        console.error(error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
        throw new Error('Something went wrong');
      }
      return reqBody;
    },
    async saveBroadcastHandler() {
      // ------------ validations -----------

      if (!this.editBroadcast.name.trim()) {
        return this.updateToast({ open: true, message: "Please enter broadcast name.", type: "danger" });
      }

      const messages = this.getAdMessageJson();
      if (!messages || !messages[0] || !messages[0].message || [undefined, null].includes(messages[0].message[0])) { // validate empty message
        return this.updateToast({ open: true, message: "Please enter message string inside create message section.", type: "danger" });
      }

      const isTextOrQuickReply = ["quickreplies", "normalchat", "reply", "buttonreply"].includes(messages[0].template_type);
      const isEmptyMessage = isTextOrQuickReply && messages[0].message[0].trim() == "";
      if (isEmptyMessage) { // validate empty message
        return this.updateToast({ open: true, message: "Please enter message string inside create message section.", type: "danger" });
      }

      if (!messages || typeof messages != "object" || Object.keys(messages).length < 1) {
        return this.updateToast({ open: true, message: "Please create message inside create message section.", type: "danger" });
      }

      if (!this.adMessageJsonElements[0] && this.selectedBroadCastChannel.value != 'whatsapp') {
        return this.updateToast({ open: true, message: "Please create message inside create message section.", type: "danger" });
      }
      if (this.targetingOption == 'SELECT_SEGMENT' && !this.selectedSegment?.id) {
        return this.updateToast({ open: true, message: "Please select valid segment.", type: "danger" });
      } else if (this.targetingOption == 'SELECT_FILTERS') {
        const targetingConditionsFinalJson = this.$refs.CustomerSegments.getFormattedJsonToSave();
        if (!targetingConditionsFinalJson?.length) {
          return this.updateToast({ open: true, message: "Please set valid targeting conditions.", type: "danger" });
        }
      }
      if (this.targetingOption == 'UPLOAD_FILE' && !this.csvToUpload) {
        return this.updateToast({ open: true, message: "Please select valid csv file.", type: "danger" });
      }
      if (!this.sendNow) {
        const endDate = this.moment(this.editBroadcast.send_date_time);
        const startDate = this.moment();
        if (endDate.diff(startDate, 'minutes') <= 5 || !this.editBroadcast?.send_date_time) {
          return this.updateToast({ open: true, message: "The selected schedule time should be 5 minutes later from now.", type: "danger" });
        }
      }
      // ************** end validations **************

      if (this.selectedBroadCastChannel.value == 'whatsapp') {

        //check whatsapp validation
        const isValid = this.$refs.whatsAppCreateMessage.isValidToSubmit();
        if (!isValid) {
          return;
        }

        this.loading = true;
        let options = { sendNow: this.sendNow, sendMode: this.sendMode, pageId: this.selectSocialPageId };

        // set targeting conditions
        if (this.targetingOption == 'SELECT_SEGMENT') {
          options.segmentId = `${this.selectedSegment.id}`;
        } else {
          const targetingConditions = this.$refs.CustomerSegments.getFormattedJsonToSave();
          options.targetingConditions = JSON.stringify(targetingConditions);
        }

        const saveBroadcastResponse = await this.$refs.whatsAppCreateMessage.saveWhatsAppBroadcast(options);
        if (saveBroadcastResponse?.data) {
          let thisBroadcast = this.allbroadcasts.find((e) => e.id === this.editBroadcast.id);
          for (var prop in thisBroadcast) {
            thisBroadcast[prop] = saveBroadcastResponse.data[prop];
          }
          this.editMode = false;
        }
        this.loading = false;
      } else if (this.targetingOption == 'UPLOAD_FILE') {
        this.csvBroadCastUpload();
      } else {
        this.saveBroadcast();
      }
    },
    async saveBroadcast() {
      try {
        this.loading = true;
        let reqBody;
        reqBody = this.getFormattedMessageRequestBody();
        let saveBroadcastResponse = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "add-broadcast-preset", reqBody);
        const { data, status } = saveBroadcastResponse;
        if ( [200, 201].includes(status) && data.success ) {
          let thisBroadcast = this.allbroadcasts.find(
            (e) => e.id === this.editBroadcast.id
          );
          for (var prop in thisBroadcast)
            thisBroadcast[prop] = data[prop];

          const message = this.editBroadcast?.id ? 'Broadcast updated successfully' : 'Broadcast created successfully';
          this.updateToast({ open: true, message, type: "success", });
          this.editMode = false;
          this.broadcastIndexToEdit = -1;
          this.fetchBroadcasts();
       } else {
          if ( data?.errorMessage ) {
            this.updateToast({ open: true, message: data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong please try again", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async csvBroadCastUpload() {
      try {
        const messages = this.getAdMessageJson();
        this.loading = true;
        var formData = new FormData();
        formData.append("messages", JSON.stringify(messages));
        formData.append("messagelisted", "[]");
        formData.append("shopname", this.user.shopName);
        formData.append("csv", this.csvToUpload);
        formData.append("name", this.editBroadcast.name);
        formData.append("channel", this.editBroadcast.channel);
        formData.append("pageid", this.selectSocialPageId);
        formData.append("mode", this.sendNow ? "send" : "save");
        formData.append("sendtag", this.messagePurpose);

        // set params for send later
        if (!this.sendNow) {
          formData.append("timezone", this.editBroadcast.timezone);
          formData.append("send_date_time", this.moment(this.editBroadcast.send_date_time).format('DD/MM/YYYY HH:mm:ss'));
        }

        // // in case edit mode pass broadcast id
        if (this.editBroadcast?.id) {
          formData.append("id", this.editBroadcast?.id);
        }

        let url = "";
        if (this.IS_ASIA) {
          url = 'https://broadcast-ioiovyy4xq-df.a.run.app/send'; 
        } else {
          url = 'https://broadcast-spt5fcneaa-uc.a.run.app/send';
        }
        // production https://broadcast-nmcfc6mj7a-uc.a.run.app 
        let saveBroadcastResponse = await jwtInterceptor.post(url, formData,
          { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" } }
        );
        const { data, status } = saveBroadcastResponse;
        if ([200, 201].includes(status) && data.success) {
          let thisBroadcast = this.allbroadcasts.find(
            (e) => e.id === this.editBroadcast.id
          );
          for (var prop in thisBroadcast)
            thisBroadcast[prop] = data[prop];

          this.fetchBroadcasts();
          // this.fetchCreditsForUser();
          this.editMode = false;
          this.broadcastIndexToEdit = -1;
          this.updateToast({ open: true, message: "Broadcast create successfully", type: "success", });
        } else if (data?.errorMessage) {
          this.updateToast({ open: true, message: data.errorMessage, type: "danger", });
        } else {
          this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong please try again", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async saveWhatsAppBroadcast() {

      if (!this.editBroadcast.wa_templateid) {
        return this.updateToast({ open: true, message: "Please select a whatsapp Template", type: "danger", });
      }
      if (!this.editBroadcast.wa_language) {
        return this.updateToast({ open: true, message: "Please select the whatsapp Template Language", type: "danger", });
      }
      if (this.sendMode === "csv" && !this.csvToUpload) {
        return this.updateToast({ open: true, message: "Please upload a valid CSV", type: "danger", });
      }
      if ( this.sendMode === "filter" && (!this.editBroadcast.targeting_conditions || this.editBroadcast.targeting_conditions.length == 0) ) {
        return this.updateToast({ open: true, message: "Please add a few filters", type: "danger", });
      }

      let customParams = this.body.body.params.filter( (e) => e.example === "Custom field" );
      if (customParams && customParams.length) {
        for (var i = 0; i < customParams.length; i++) {
          if (!customParams[i].value || customParams[i].value.length === 0)
            return this.updateToast({ open: true, message: "Please enter all custom field values", type: "danger", });
        }
      }
      try {
        this.loading = true;
        let pId = this.whatsAppSocialData.id;

        let reqBody = {
          name: this.editBroadcast.name,
          mode: this.sendNow ? "send" : "save",
          channel: this.editBroadcast.channel,
          id: this.editBroadcast.id ? this.editBroadcast.id : undefined,
          custom_messages:
            customParams && customParams.length
              ? customParams.map((e) => {
                return { key: e.key, value: e.value };
              })
              : undefined,
          pageid: pId,
          template_id: this.editBroadcast.wa_templateid,
          message_params: JSON.stringify({
            BODY:
              this.body.body.params && this.body.body.params.length
                ? this.body.body.params.filter(
                  (e) => e.example != "Custom field"
                )
                : [],
          }),
          targeting_conditions:
            this.editBroadcast.targeting_conditions &&
              this.editBroadcast.targeting_conditions.length
              ? JSON.stringify(this.editBroadcast.targeting_conditions)
              : undefined,
        };
        let saveBroadcastResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") +
          "add-whatsapp-broadcast-preset",
          reqBody
        );
        if ( (saveBroadcastResponse.status == 200 || saveBroadcastResponse.status == 201) && saveBroadcastResponse.data.success ) {
          let thisBroadcast = this.allbroadcasts.find( (e) => e.id === this.editBroadcast.id );
          for (var prop in thisBroadcast) {
            thisBroadcast[prop] = saveBroadcastResponse.data[prop];
          }
        } else {
          if ( saveBroadcastResponse.data && !saveBroadcastResponse.data.success && saveBroadcastResponse.data.errorMessage ) {
            this.updateToast({ open: true, message: saveBroadcastResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
            //console.log("Response:", allTemplatesResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong please try again", type: "danger", });
      } finally {
        this.editMode = false;
        this.loading = false;
      }
    },
    async fetchSegments() {
      try {
        let allSegmentsResponse = await jwtInterceptor.get(this.environment('VUE_APP_ROOT_API') + "fbrt/customer-segment");
        const { status, data } = allSegmentsResponse;
        if ((status == 200 || status == 201) && data.success) {
          this.allSegments = allSegmentsResponse.data.data;
          this.allSegments.forEach((thisSegment) => thisSegment.targeting_conditions = JSON.parse(thisSegment.targeting_conditions));
        } else if (data?.success && data?.errorMessage) {
          this.updateToast({ open: true, message: allSegmentsResponse.data.errorMessage, type: "danger", });
        } else {
          this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
        }
      } catch (error) {
        console.error("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      }
    },
    downloadCsv_broadcastOutcome(broadcast_id) {
      let host = "jumper.ai";
      if (this.IS_LIVE == false) {
        host = "jumperdevnew.appspot.com";
      }

      let apiUrl = this.environment("VUE_APP_ANALYTICS_URL") + "" + this.user.referallshopName;
      apiUrl = apiUrl + "-broadcastTracker=csv?broadcast_id=" + broadcast_id;

      let filename = `${this.user.shopName}-broadcast-outcome-` + broadcast_id + ".csv";

      this.broadCastOutcomeId = broadcast_id;
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "userid": this.authData.authkey,
          //"Authorization": "JWT "+ this.authData.token,
          "host": host
        }
      })
        .then(response => response.blob())
        .then(blob => {

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }).catch(error => {
          console.error(error);
          this.updateToast({ open: true, message: "Something went wrong, please try again.", type: "danger" });
        }).finally(() => {
          this.broadCastOutcomeId = null;
        });
    }
  },
};
</script>

<style scoped>
.broadcastStatus {
  text-transform: capitalize;
}
.segment-filter-section {
  margin: 20px;
}
.outcome-loading-text {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.outcome-loading-text .text {
  padding: 5px;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 5px;
  background: var(--ion-color-light-tint);
}
.mobile-merchant-credit-wrapper .content-box{
  padding: 20px !important;
}
.merchant-credit-container {
  --background: #f2f2f2 !important;
}
.btn-actions-right-top {
  display: flex;
  column-gap: 10px;
}
.broadcast-search-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 15px 16px 15px 0px;
}
ion-button.analyticsBttn, .btn-watch-credits{
  --background: var(--ion-color-primary);
  --box-shadow: none;
  color: var(--ion-color-white);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.searchBar{
  border-radius: 20px;
  margin: 10px 20px 10px 20px;
  width: 30%;
}
.not-channel-connected-text-wrapper {
  height: calc(100% - 60px);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.action-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
}
.action-header {
  text-align: right;
  padding-right: 42px;
}
.broadcast-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.remaining-message-count {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  display: inline-flex;
}
.broadcast-name-wrapper .tooltip_text {
  top: -10px;
  left: calc(100% + 5px);
  position: absolute;
  width: 300px;
  z-index: 9999;
}
.remaining-message-count-wrapper .tooltip_text {
  width: auto;
  max-width: 200px;
  white-space: nowrap;
}
.execution-cell-wrapper .date-text {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  padding-bottom: 5px;
}
.execution-cell-wrapper .date-text .label-text {
  font-weight: bold;
}
.date-time-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
.date-time-wrapper .left {
  display: flex;
  flex: 1;
  column-gap: 10px;
}
.btn-back {
  cursor: pointer;
}
.channels-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.slct-channel-column {
  display: flex;
  width: 33.33%;
  cursor: pointer;
  column-gap: 10px;
  align-items: center;
}
.select-segment-wrapper {
  margin-top: 20px
}
.select-segment-wrapper .sub-text{
  padding-left: 15px;
}
.fb-setting-header {
  padding: 15px;
}
.fb-setting-header .title {
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}
.fb-setting-header .sub-title {
  color: #9a9a9a;
  padding-top: 10px;
}
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row:last-child {
  border-bottom: 0px;
}
.table-section {
  padding: 20px 20px 0px;
}

.gridMargin {
  margin: 20px;
}

.rowMargin {
  margin-bottom: 25px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.slct-channel {
  padding: 10px;
  height: 65px;
}
.sctn-labels {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color:  var(--ion-color-grey);
}

.chnl-img {
  width: 40px;
}

.warning {
  width: 3px;
  height: 20px;
  background-color: #ffc409;
  color: #ffc409;
  margin-right: 8px;
}

.warning-desc {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  line-height: 21px;
}

.link-text {
  color: #3dc2ff;
  text-decoration: none;
}

.radio-bttns {
  margin-right: 10px;
}

.conditionsTitle {
  font-size: 14px;
  color: var(--ion-color-white);
  font-weight: 400;
  background-color: #881fff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.conditionsOperator {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 400;
  background-color: var(--ion-color-white);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  border: 1px solid var(--ion-color-grey);
}

.selctBox {
  border: 1px solid var(--ion-color-grey);
  border-radius: 5px;
}

.textArea {
  --background: #ededed;
  border: 1px solid var(--ion-color-grey);
  border-radius: 5px;
}

.uploadBttn {
  border: 1px solid var(--ion-color-black);
  --ion-color-base: var(--ion-color-white) !important;
  height: 35px;
  --box-shadow: none;
}

ion-textarea {
  min-height: 80px;
}
.table-row {
  border-bottom: 1px solid #ededed;
  margin: 21px 19px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 20px;
}
.chat-toolbar {
  margin-top: 15px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.tooltip_container .tooltip_text.left {
  top: 5%;
}
.warning-img {
  height: 52px;
  margin-top: 10px;
  width: 3px;
  height: 20px;
  background-color: #ffc409;
  color: #ffc409;
  margin-right: 8px;
}

.hideSendLaterSection {
  display: none;
}
.file-name{
  font-size: 16px;
  font-weight: 700;
  color: var(--ion-color-black);
  line-height: 16px;
}
.closeBttn{
  padding-left: 5px;
  cursor: pointer;
}
.selectedCSV{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (min-width: 768px) {
  .slct-channel-column {
    border-bottom: 1px solid var(--ion-color-grey);
    border-right: 1px solid var(--ion-color-grey);
  }

  .slct-channel-column:nth-of-type(-n+3) {
    border-top: 1px solid var(--ion-color-grey);
  }

  .slct-channel-column:nth-of-type(3n+1) {
    border-left: 1px solid var(--ion-color-grey);
  }
}

@media only screen and (max-width: 768px) {
  .slct-channel-column {
    border-bottom: 1px solid var(--ion-color-grey);
    border-right: 1px solid var(--ion-color-grey);
  }

  .slct-channel-column:first-of-type {
    border-top: 1px solid var(--ion-color-grey);
  }
  .slct-channel-column {
    width: 100%;
    border-left: 1px solid var(--ion-color-grey);
  }
  .table-responsive {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 0px 20px 20px;
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
  }
  .slct-fltr {
    height: 25px;
  }
  .warning-img {
    height: 116px;
  }
  .conditionsEdit {
    margin-bottom: 20px;
  }
  .searchBar{
    width: 70%;
  }
}
</style>
