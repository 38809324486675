<template>
  <master-layout pageTitle="Attentive Widget">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="customWidget.widgets_data && customWidget.widgets_data.length && !editAttentiveWidget">
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col>
                Name
              </ion-col>
              <ion-col>
                Type
              </ion-col>
              <ion-col>
                Activate
              </ion-col>
              <ion-col class="text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="(thisWidget, wIndex) in customWidget.widgets_data" :key="thisWidget.widgetid" class="table-row ion-no-padding">
                <ion-col class="orderDesc">
                  {{ thisWidget.extra_options.name }}
                </ion-col>
                <ion-col v-if="thisWidget.trigger_type === 'chatbox'" class="orderDesc">
                  Chatbox
                </ion-col>
                <ion-col v-if="thisWidget.trigger_type === 'messagePop'" class="orderDesc">
                  Message
                </ion-col>
                <ion-col v-if="thisWidget.trigger_type === 'incontext'" class="orderDesc">
                  Blog
                </ion-col>
                <ion-col>
                  <ion-toggle @ionChange="updateWidget(true, true)" v-model="thisWidget.extra_options.active"></ion-toggle>
                </ion-col>
                <ion-col class="text-center">
                  <span class="tooltip_container">
                    <ion-button @click="editWidget(wIndex)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="pencilOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">edit</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => widgetIndexToDelete = wIndex" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashBinOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div v-else-if="editAttentiveWidget">
          <ion-toolbar class="chat-toolbar">
            <ion-buttons slot="start">
              <ion-icon   @click="() => editAttentiveWidget = false" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
              <h2 class="prdt-title ion-no-margin">{{widgetIndexToEdit > -1 ? 'Edit ' : 'Add '}} Message</h2>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar color="light" class="ion-hide-md-up">
            <ion-segment scrollable color="dark" v-model="activeTab" class="segment ion-justify-content-center">
              <ion-segment-button value="widget-message">
                  <ion-label>{{  widgetIndexToEdit > -1 ? 'Edit ' : 'Add '}} Message</ion-label>
              </ion-segment-button>
              <ion-segment-button value="preview">
                  <ion-label>Preview</ion-label>
              </ion-segment-button>
            </ion-segment>
				  </ion-toolbar>
          <ion-grid>
            <ion-row>
              <ion-col v-if="activeTab === 'widget-message'" size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12" class="ion-no-padding">
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Name</ion-card-title>
                  <ion-input v-model="dummyMessage.extra_options.name" type="text" placeholder="Enter a Name" class="fullBordered input_custom_style sctn-input"></ion-input>
                </div>
                <ion-accordion-group value="addProduct" class="product-container">
                  <ion-accordion class="model-sections" value="addProduct">
                    <ion-item slot="header" lines="none">
                      <ion-text class="order-title">
                          Trigger Type
                      </ion-text>
                    </ion-item>
                    <ion-list slot="content" class="ion-no-padding">
                      <ion-grid class="ion-no-padding" style="margin-top: 0px">
                        <ion-row class="ion-no-padding trigger-container" style="margin-bottom: 5px">
                            <ion-col style="padding-top: 20px"> 
                              <div :class="dummyMessage.trigger_type == 'incontext' ? 'img-block-selected' : 'img-block'">
                                <ion-button @click="trigger_type('incontext')" class="img-bttn" color="white">
                                  <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/in-content-message-pop.svg" class="img-container" />
                                </ion-button>
                                <ion-label>Blog</ion-label>
                              </div>
                            </ion-col>
                            <ion-col style="padding-top: 20px"> 
                              <div :class="dummyMessage.trigger_type == 'messagePop' ? 'img-block-selected' : 'img-block'">
                                <ion-button @click="trigger_type('messagePop')" class="img-bttn" color="white">
                                  <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/corner-message-pop.svg" class="img-container" />
                                </ion-button>
                                <ion-label>Message</ion-label>
                              </div>
                            </ion-col>
                            <ion-col style="padding-top: 20px"> 
                              <div :class="dummyMessage.trigger_type == 'chatbox' ? 'img-block-selected' : 'img-block'">
                                <ion-button @click="trigger_type('chatbox')" class="img-bttn" color="white">
                                  <img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/open-entire-chatbox.svg" class="img-container" />
                                </ion-button>
                                <ion-label>Chatbox</ion-label>
                              </div>
                            </ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-list>
                  </ion-accordion>
                  <ion-accordion class="model-sections" value="addProduct">
                    <ion-item slot="header" lines="none">
                        <ion-text class="order-title">
                          Trigger message
                        </ion-text>
                      </ion-item>
                      <ion-list slot="content" class="ion-no-padding">
                        <div v-for="(thisElement, eIndex) in dummyMessage.trigger_message_data" :key="eIndex" class="content-area">
                          <!-- Text -->
                          <div v-if="thisElement.type === 'normalchat'" class="normalchatarea">
                            <ion-textarea v-model="thisElement.message[0]" rows="4" auto-grow="true" autofocus="true" class="textarea-box"></ion-textarea>
                            <div class="deleteElement">
                              <ion-icon  @click="() => dummyMessage.trigger_message_data.splice(eIndex, 1)" :icon="removeCircleOutline" color="danger"></ion-icon>
                            </div>
                          </div>
                          <!-- Image -->
                          <div v-if="thisElement.type === 'cancelled'" class="upload-area">
                            <div>
                              <input type="file" style="display: none;" id="image" ref="image" @change="handleImageUpload($event, eIndex)" accept="image/png, image/gif, image/jpeg" />
                              <img v-if="thisElement.message[0] && thisElement.message[0].length" :src="thisElement.message[0]" width="200" @click="() => $refs.image.click()" class="upload-img-container" />
                              <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="upload-img-container"/>
                              <div class="upload-img">
                                <ion-button @click="() => $refs.image.click()" expand="block" fill="outline">Upload Image</ion-button>
                              </div>
                            </div>
                            <div class="deleteElement">
                              <ion-icon @click="() => dummyMessage.trigger_message_data.splice(eIndex, 1)" :icon="removeCircleOutline" color="danger"></ion-icon>
                            </div>	
                          </div>
                          <!-- Video -->
                          <div v-if="thisElement.type === 'videoreply'" class="upload-area">
                            <div>
                              <input type="file" style="display: none;" id="video" ref="video" @change="handleVideoUpload($event, eIndex)" accept="video/mp4, video/mpeg4" />
                              <video v-if="thisElement.message[0] && thisElement.message[0].length" :src="thisElement.message[0]" width="200" @click="() => $refs.video.click()" class="upload-img-container"></video>
                              <video v-else  poster="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/video-only.svg" class="upload-img-container"></video>
                              <div class="upload-img">
                                <ion-button @click="() => $refs.video.click()" expand="block" fill="outline">Upload Video</ion-button>
                              </div>
                              <div class="deleteElement">
                                <ion-icon @click="() => dummyMessage.trigger_message_data.splice(eIndex, 1)" :icon="removeCircleOutline" color="danger"></ion-icon>
                              </div>	
                            </div>
                          </div>  
                          <!-- Product -->
                          <div v-if="thisElement.type === 'carousel' && thisElement.template && thisElement.template.elements && thisElement.template.elements.length" class="upload-area">
                            <div>
                              <img width="150" v-if="thisElement.template.elements[0].imageurl && thisElement.template.elements[0].imageurl.length" :src="thisElement.template.elements[0].imageurl" style="margin: 20px auto 20px auto; display: block;" />
                              <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" style="margin: 20px auto 20px auto; display: block;"/>
                              <ion-input v-model="thisElement.template.elements[0].title" type="text" placeholder="Enter Title" class="fullBordered input_custom_style inpt-container"></ion-input>
                              <ion-input v-model="thisElement.template.elements[0].subtitle" type="text" placeholder="Enter subtitle" class="fullBordered input_custom_style inpt-container"></ion-input>
                              <div v-if="thisElement.template.elements[0].buttons && thisElement.template.elements[0].buttons.length">
                                <ion-button v-for="(thisButton, bIndex) in thisElement.template.elements[0].buttons" :key="'element' + eIndex + 'button' + bIndex"  class="upload-img-container" fill="outline" @click="() => { isPopperOpen = true; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                                  {{ thisButton.title }}
                                </ion-button>
                              </div>
                              <div v-if="thisElement.template.elements[0].buttons.length < 3" class="addNew">
                                <div class="addRow" @click="() => thisElement.template.elements[0].buttons.push({ title: 'Button', description: 'Button', type: 'postback', payload: undefined })">+ Add New</div>
                              </div>
                              <div class="deleteElement">
                                <ion-icon @click="() => dummyMessage.trigger_message_data.splice(eIndex, 1)" :icon="removeCircleOutline" color="danger"></ion-icon>
                              </div>
                            </div>
                          </div> 
                          <!-- quickreplies -->
                          <div v-if="thisElement.type === 'quickreplies'">
                            <div class="addNew"> 
                              <my-input id="template_body" emoji name="template_body" :value="thisElement.message[0]" @onInput="(prop) => thisElement.message[0] = prop" placeholder="Enter a Reply" :maxlength="1024" />
                            </div>
                            <div v-if="thisElement.template && thisElement.template.buttons && thisElement.template.buttons.length" class="addNew">
                              <ion-button v-for="(thisButton, bIndex) in thisElement.template.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container" fill="outline" @click="() => { isPopperOpen = true; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                                {{ thisButton.title }}
                              </ion-button>
                            </div>
                            <div v-if="thisElement.buttons.length < 5" class="addNew">
                              <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                            </div>
                            <div class="deleteElement">
                              <ion-icon @click="() => dummyMessage.trigger_message_data.splice(eIndex, 1)" :icon="removeCircleOutline" color="danger"></ion-icon>
                            </div>
                          </div>
                        </div>

                        <ion-grid class="ion-no-padding" style="margin-top: 0px">
                          <ion-row class="ion-no-padding trigger-container" style="margin-bottom: 5px">
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'img-block-disabled' : 'img-block'">
                                <ion-button :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" @click="dummyMessage.trigger_message_data.push({ type: 'normalchat', templateType: 'normalchat', message: [undefined] })" class="img-bttn" color="white">
                                  <ion-icon src="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/normal-text.svg"></ion-icon>
                                </ion-button>
                                <ion-label>Text</ion-label>
                              </div>
                            </ion-col>
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'img-block-disabled' : 'img-block'">
                                <ion-button @click="dummyMessage.trigger_message_data.push({ type: 'cancelled', templateType: 'cancelled', message: [undefined] })" :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" class="img-bttn" color="white">
                                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/image-only.svg" class="img-container" />
                                </ion-button>
                                <ion-label class="sctn-labels">Image</ion-label>
                              </div>
                            </ion-col>
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'img-block-disabled' : 'img-block'">
                                <ion-button @click="dummyMessage.trigger_message_data.push({ type: 'videoreply', templateType: 'videoreply', message: [undefined] })" :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" class="img-bttn" color="white">
                                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/video-only.svg" class="img-container" />
                                </ion-button>
                                <ion-label class="sctn-labels">Video</ion-label>
                              </div>
                            </ion-col>
                          </ion-row>
                          <ion-row class="trigger-message">
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'img-block-disabled' : 'img-block'">
                                <ion-button @click="addProductMode = true" :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" class="img-bttn" color="white">
                                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/fbAdJSON-Product.svg" class="img-container" />
                                </ion-button>
                                <ion-label class="sctn-labels">Product</ion-label>
                              </div>
                            </ion-col>
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'img-block-disabled' : 'img-block'">
                                <ion-button @click="addCollectionMode = true" :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" class="img-bttn" color="white">
                                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/fbAdJSON-Collection.svg" class="img-container" />
                                </ion-button>
                                <ion-label class="sctn-labels">Collection</ion-label>
                              </div>
                            </ion-col>
                          </ion-row>
                          <ion-row class="reply-message">
                            <ion-col style="padding-top: 20px"> 
                              <div :class="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3 ? 'reply-block-disabled' : 'reply-block'" class="reply-block">
                                <ion-button @click="dummyMessage.trigger_message_data.push({ type: 'quickreplies', templateType: 'quickreplies', message: [undefined], template: { buttons: [{ title: 'Button 1', description: 'Button 1', type: 'postback', payload: undefined, url: undefined }] } })" :disabled="!dummyMessage.trigger_message_data || dummyMessage.trigger_message_data.length == 3" class="reply-bttn" color="white">
                                Quick replies
                                </ion-button>
                              </div>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-list>
                  </ion-accordion>
                  <ion-accordion class="model-sections" value="addProduct">
                    <ion-item slot="header" lines="none">
                        <ion-text class="order-title">
                          Trigger event
                        </ion-text>
                    </ion-item>
                    <ion-list slot="content" class="ion-no-padding">
                      <ion-grid class="card_container_grid ion-no-padding" style="margin-top: 0px">
                        <ion-row class="ion-no-padding" style="margin-bottom: 5px">
                          <ion-col style="padding-top: 20px"> 
                            <ion-text class="common-label">
                              On URLs
                            </ion-text>
                          </ion-col>
                          <ion-col class="ion-no-padding text-right"> 
                            <ion-item lines="none">
                              <ion-icon @click="() => dummyMessage.include_urls.push({url: undefined})" slot="end" :icon="addCircleOutline" style="cursor: pointer"></ion-icon>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                        <div v-for="(thisUrl, uIndex) in dummyMessage.include_urls" :key="uIndex" class="input-container"> 
                          <ion-input v-model="thisUrl.url" type="url" placeholder="Enter the Page URL" class="fullBordered input_custom_style domainInput"></ion-input>
                        </div> 
                        <ion-row v-if="dummyMessage.trigger_type !== 'incontext'" class="ion-no-padding" style="margin-bottom: 5px">
                          <ion-col style="padding-top: 20px">
                            <ion-text class="common-label">
                              Exclude URLs
                            </ion-text>
                            </ion-col>
                          <ion-col class="ion-no-padding text-right">
                            <ion-item lines="none">
                              <ion-icon @click="() => dummyMessage.include_urls.push({url: undefined})" slot="end" :icon="addCircleOutline" style="cursor: pointer"></ion-icon>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                          <div v-for="(thisUrl, uIndex) in dummyMessage.exclude_urls" :key="uIndex" class="input-container"> 
                            <ion-input v-model="thisUrl.url" type="url" placeholder="Enter the Page URL" class="fullBordered input_custom_style domainInput"></ion-input>
                          </div> 
                        <ion-row v-if="dummyMessage.trigger_type !== 'incontext'" class="ion-no-padding" style="margin-bottom: 5px">
                          <ion-col style="padding-top: 20px">
                            <ion-text class="common-label">
                              After delay
                            </ion-text>
                          </ion-col>
                          <ion-col class="ion-no-padding text-right">
                            <ion-toggle v-model="delay.container" class="cust_toggle"></ion-toggle>
                          </ion-col>
                        </ion-row>
                        <div v-if="delay.container" class="input-container"> 
                          <ion-item lines="none">
                            <ion-select v-model="dummyMessage.open_after_delay" placeholder="Delay(in seconds)" class="slctContainer">
                              <ion-select-option :value="0">0 Seconds</ion-select-option>
                              <ion-select-option :value="3">3 Seconds</ion-select-option>
                              <ion-select-option :value="5">5 Seconds</ion-select-option>
                              <ion-select-option :value="10">10 Seconds</ion-select-option>
                              <ion-select-option :value="15">15 Seconds</ion-select-option>
                              <ion-select-option :value="30">30 Seconds</ion-select-option>
                            </ion-select>
                          </ion-item>
                        </div> 
                      </ion-grid>
                    </ion-list>
                  </ion-accordion>
                  <ion-accordion class="model-sections" value="addProduct">
                    <ion-item slot="header" lines="none">
                      <ion-text class="order-title">
                        Publish
                      </ion-text>
                      </ion-item>
                      <ion-list slot="content" class="ion-no-padding publish-text">
                        <div v-if="dummyMessage.trigger_type === 'incontext'" class="embedCodeAreaContainer">
                          <p>Place the below code inside your blog post wherever your want the magic to begin</p>
                          <figure class="highlight">
                            <pre>{{ blogDiv }}</pre>
                            <a @click="copy(blogDiv)">Copy to Clipboard</a>
                          </figure>
                        </div>
                        <div v-if="dummyMessage.trigger_type === 'incontext'">
                          <ion-label class="sub-title">Save changes and launch the Attentive chat on your blog</ion-label>
                          <ion-toolbar>
                            <ion-buttons slot="end">
                              <ion-button color="dark" fill="solid" size="default" @click="updateWidget(true)">Save</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </div>
                        <div v-if="dummyMessage.trigger_type === 'messagePop'">
                          <ion-label class="sub-title">Save changes and launch the Message pop on your webpage</ion-label>
                          <ion-toolbar>
                            <ion-buttons slot="end">
                              <ion-button color="dark" fill="solid" size="default" @click="updateWidget(true)">Save</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </div>
                        <div v-if="dummyMessage.trigger_type === 'chatbox'">
                          <h3 class="sub-title">Save changes and launch the Chatbox on your webpage</h3>
                          <ion-toolbar>
                            <ion-buttons slot="end">
                              <ion-button color="dark" fill="solid" size="default" @click="updateWidget(true)">Save</ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </div>
                      </ion-list>
                  </ion-accordion>
                </ion-accordion-group>
              </ion-col>
              <!-- preview -->
              <ion-col size-xl="7" size-lg="7" size-md="7" size-sm="12" size-xs="12" class="ion-no-padding">
                <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
                <div class="preview-container">
                  <div v-if="dummyMessage.trigger_type === 'incontext'" class="incontent-msg-pop-widget">
                    <div class="chatbot_preview_container">
                      <div class="introBubbleContainer">
                        <div class="chatboxExtentionCard">
                          <div class="imageContainer">
                            <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwPP0hqIKDA"/>
                          </div>
                          <div class="ctaButtonContainer">
                            <p class="messageOverCard">Hey there, browse through our winter collection by clicking the button below</p>
                          </div>
                          <div class="ctaButtonContainer">
                            <ion-button class="buybutton" size="default" fill="solid">
                              Browse now!
                            </ion-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="dummyMessage.trigger_type === 'messagePop'" class="messagepop-widget">
                    <div class="chatbot_messagepop_container"> 	
                      <div class="chatboxExtentionCard">
                        <div class="productCardContainer">
                          <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAoKjk_9wLDA"/>
                          <div class="dataArea">
                            <div class="lhs">
                              <p class="productNameText">Mens White T-shirt</p>
                            </div>
                            <div class="lhs">
                              <p class="priceText">$15.00</p>
                            </div>
                          </div>
                        </div>
                        <div class="ctaButtonContainer">
                          <ion-toolbar class="buyNow">
                            <ion-buttons slot="end">
                              <ion-button class="buybutton" size="default" fill="solid">
                                Buy now!
                              </ion-button>
                            </ion-buttons>
                          </ion-toolbar>
                        </div>
                        <div class="widgetOpenerFloatingButton">
                          <img src="https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg" class="imgFloating" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="dummyMessage.trigger_type === 'chatbox'" class="messagepop-widget">
                    <div class="chatbot_messagepop_container">
                      <div class="chatboxExtentionCard">
                        <div class="widgetPreviewContainer">
                          <div class="widgetBody">
                            <div class="widgetBodyInnerScrollableArea">
                              <div class="widgetBodyTop">
                                <div class="widgetBodyCompanyHeader">
                                  <div class="widgetBodyCompanyLogo">
                                    <img :src="customWidget.brand_logo" class="img-container">
                                  </div>
                                  <div class="widgetBodyCompanyDetails">
                                    <h5 title="name" class="ion-no-margin">{{ user.shopName }}</h5>
                                  </div>
                                  <div class="widgetBodyShopDetails">
                                    <p class="ion-no-margin">Browse product on my awesome store!</p>
                                  </div>
                                </div>
                              </div>
                              <div class="widgetBodyMainArea">
                                <div class="widgetBodyMainChatArea">
                                  <div class="widgetChatContainer">
                                    <div class="chatBubble chatBubbleLeft">
                                      <img :src="customWidget.brand_logo" class="left-img-container">
                                      <div class="chatBubbleContent">Hello, how may I help you?</div>
                                    </div>
                                    <div class="chatBubble chatBubbleRight">
                                      <div class="chatBubbleContent">Hi, I want to order this now!</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="widgetFooterArea">
                              <div class="widgetChatInputArea">
                                <div class="chatInputBarArea">
                                  <div class="widgetChatForm">
                                    <ion-button class="widgetHamburgarOpener" size="small" expand="block" fill="clear" >
                                      <ion-icon :icon="menuOutline"></ion-icon>
                                    </ion-button>
                                    <ion-input type="text" class="widgetChatInputText" autocomplete="off" placeholder="Type a message..."></ion-input>
                                    <ion-button class="widgetChatSendButton" size="small" expand="block" fill="clear">
                                      <ion-icon :icon="sendOutline"></ion-icon>
                                    </ion-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="widgetFloatingButton">
                        <img src="https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg" class="imgFloating" />
                      </div>
                      </div>
                    </div>
                  </div>	
                </div>
              </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-else style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; min-height: 400px;">
          <img width="100" src="https://insights.jumper.ai/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/verification-success-2.svg" />
          <h3>Create a Message</h3>
          <p>The widget is ready on your site. Compose a message to show to your visitors when they visit your website page/article.</p>
        </div>
        <button type="button" class="submitBtn addNewBttns" id="addBtns"  @click="() => editAttentiveWidget = true">
          Create new
        </button>
        <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="$router.push('/sales-channel/attentive-widget-settings')">
          <ion-icon class="addIcon" slot="icon-only" :icon="settingsOutline"></ion-icon>
        </button>
      </div>
    </ion-content>
    <ion-action-sheet
			:is-open="addMode"
			header="Insert Element"
			:buttons="addOptions"
			@didDismiss="() => addMode = false"
		>
		</ion-action-sheet>
    <ion-modal
      v-if="addProductMode"
      :is-open="addProductMode"
      @didDismiss="() => addProductMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Choose Product</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addProductMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-searchbar v-model="productFilter" show-cancel-button="always"></ion-searchbar>
          <ion-list>
            <ion-item @click="() => { dummyMessage.trigger_message_data.push({ type: 'carousel', templateType: 'carousel', template: { elements: [{ title: thisProduct.name, subtitle: undefined, imageurl: thisProduct.images && thisProduct.images.length ? thisProduct.images[0] : undefined, buttons: [{ title: 'Buy Now', description: 'Buy Now', type: 'postback', payload: ('#' + thisProduct.hashtag)}] }] } }); addProductMode = false; }" v-for="thisProduct in productsToShow" :key="thisProduct.id">
              <ion-label>{{ thisProduct.name }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="addCollectionMode"
      :is-open="addCollectionMode"
      @didDismiss="() => addCollectionMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">Choose Collection</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addCollectionMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-searchbar v-model="collectionFilter" show-cancel-button="always"></ion-searchbar>
          <ion-list>
            <ion-item @click="() => { dummyMessage.trigger_message_data.push({ type: 'carousel', templateType: 'carousel', template: { elements: [{ title: thisCollection.name, subtitle: undefined, imageurl: thisCollection.images && thisCollection.images.length ? thisCollection.images[0] : undefined, buttons: [{ title: 'Buy Now', description: 'Buy Now', type: 'postback', payload: ('#' + thisCollection.hashtag)}] }] } }); addCollectionMode = false; }" v-for="thisCollection in collectionsToShow" :key="thisCollection.id">
              <ion-label>{{ thisCollection.name }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="widgetIndexToDelete > -1"
      :is-open="widgetIndexToDelete > -1"
      header="Are you sure?"
      :message="'You want to delete this widget?'"
      :buttons="[{
        text: 'No',
        role: 'cancel',
        handler: () => {
          widgetIndexToDelete = -1;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDelete();
        },
      }]"
      @didDismiss="() => widgetIndexToDelete = -1"
    >
    </ion-alert>
    <popper webHeight v-if="isPopperOpen" is_agent_or_group_req type="detailed" :config="popperConfig" @close="() => {editIndex = -1; isPopperOpen = false; buttonIndex = -1;}" @submit="popperSubmit" />
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, pencilOutline, settingsOutline, arrowBackOutline,removeCircleOutline, menuOutline, sendOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSearchbar, IonToggle, IonSelect, IonSelectOption, IonTextarea, IonAccordion, IonAccordionGroup } from '@ionic/vue';
import popper from '/src/components/popper';

var newMessage = {
  widgetid: undefined,
  site_url: "",
  trigger_type: undefined,
  trigger_message_data: [],
  open_after_delay: 0,
  include_urls: [],
  exclude_urls: [],
  extra_options: {
    name: undefined,
    active: false
  }
}
export default {
  components: { popper, IonSearchbar, IonToggle, IonSelect, IonSelectOption, IonTextarea, IonAccordion, IonAccordionGroup },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      close,
      pencilOutline,
      settingsOutline,
      arrowBackOutline,
      removeCircleOutline,
      menuOutline,
      sendOutline,
      customWidget: {
        brand_logo: 'https://lh3.googleusercontent.com/VW6R9_VWIOn6EqNp-lElvPiiam3cfMyQIxYgwWDlPb6UK8XasSO8gixWNRO6FmKBHHEZg-LNPLaHD8vf5V2RDr0AYEQB3Qm_rPcUVg',
        bubble_image: 'https://jumper.ai/web-b/resources/images/jmpr-chatbox-icon.svg',
        domain_url: undefined,
        id: undefined,
        primary_bgcolor: '#3d39b9ff',
        store_info: null,
        subheading: undefined,
        widgets_data: []
      },
      widgetIndexToEdit: -1,
      widgetIndexToDelete: -1,
      newMessage: newMessage,
      dummyMessage: JSON.parse(JSON.stringify(newMessage)),
      allProducts: [],
      allCollections: [],
      editIndex: -1,
      buttonIndex: -1,
      settingsMode: false,
      isPopperOpen: false,
      addCollectionMode: false,
      addProductMode: false,
      productFilter: undefined,
      collectionFilter: undefined,
      addMode: false,
      addEditMode: false,
      editAttentiveWidget: false,
      activeTab: 'widget-message',
      delay: {
				container: true,
			},
    }
  },
  async ionViewWillEnter(){
    this.init();
  },
  computed: {
    addOptions() {
      let allOptions = [
        {
          text: "Text",
          handler: () => {
						this.dummyMessage.trigger_message_data.push({ type: 'normalchat', templateType: 'normalchat', message: [undefined] });
          }
        },
        {
          text: "Image",
          handler: () => {
						this.dummyMessage.trigger_message_data.push({ type: 'cancelled', templateType: 'cancelled', message: [undefined] });
          }
        },
        {
          text: "Video",
          handler: () => {
						this.dummyMessage.trigger_message_data.push({ type: 'videoreply', templateType: 'videoreply', message: [undefined] });
          }
        },
        {
          text: "Product",
          handler: () => {
            this.addProductMode = true;
          }
        },
        {
          text: "Collection",
          handler: () => {
            this.addCollectionMode = true;
          }
        },
        {
          text: "Quick Replies",
          handler: () => {
						this.dummyMessage.trigger_message_data.push({ type: 'quickreplies', templateType: 'quickreplies', message: [undefined], template: { buttons: [{ title: 'Button 1', description: 'Button 1', type: 'postback', payload: undefined, url: undefined }] } });
          }
        }
      ];
      let existingTriggerData = this.dummyMessage.trigger_message_data.map(e => e.type);
      if(existingTriggerData.indexOf('cancelled') > -1 || existingTriggerData.indexOf('videoreply') > -1) allOptions = allOptions.filter(e => e.text !== 'Image' && e.text !== 'Video' && e.text !== 'Product' && e.text !== 'Collection');
      if(existingTriggerData.indexOf('carousel') > -1) allOptions = allOptions.filter(e => e.text !== 'Image' && e.text !== 'Video' && e.text !== 'Product' && e.text !== 'Collection' && e.text !== 'Quick Replies');
      if(existingTriggerData.indexOf('quickreplies') > -1) allOptions = allOptions.filter(e => e.text !== 'Product' && e.text !== 'Collection' && e.text !== 'Quick Replies');
      return allOptions;
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined; let webHeight = undefined;
      if(this.editIndex > -1 && this.buttonIndex > -1) {
        let thisTemplate = this.dummyMessage.trigger_message_data[this.editIndex].template;
        if(this.dummyMessage.trigger_message_data[this.editIndex].type === 'carousel') {
          text = thisTemplate.elements[0].buttons[this.buttonIndex].title;
          if(thisTemplate.elements[0].buttons[this.buttonIndex].type === 'webview') {
            payload = thisTemplate.elements[0].buttons[this.buttonIndex].url;
            action = 'website';
            webHeight = thisTemplate.elements[0].buttons[this.buttonIndex].height_ratio;
          } else payload = thisTemplate.elements[0].buttons[this.buttonIndex].payload;
        } else {
          text = thisTemplate.buttons[this.buttonIndex].title;
          if(thisTemplate.buttons[this.buttonIndex].type === 'webview') {
            payload = thisTemplate.buttons[this.buttonIndex].url;
            action = 'website';
            webHeight = thisTemplate.buttons[this.buttonIndex].height_ratio;
          } else payload = thisTemplate.buttons[this.buttonIndex].payload;
        }
      }
      return { text: text, action: action, payload: payload, webHeight: webHeight }
    },
    productsToShow() {
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCollections.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCollections;
    },
    widgetScript() {
      return '<script src="https://jumper.ai/widget/js/attentive-widget.js" id="jmpr_attentive_js" widget-id="' + this.customWidget.id + '"></scrip' + 't>';
    },
    blogDiv() {
      return '<div id="jumperInContextContainer"></div>';
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      let a = this.getCustomWidget();
      let b = this.fetchProducts();
      await Promise.all([a, b]);
    },
    async getCustomWidget() {
      try {
        this.loading = true;
        let getCustomWidgetResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-customized-widget");
        if ((getCustomWidgetResponse.status == 200 || getCustomWidgetResponse.status == 201) && getCustomWidgetResponse.data.success) {
          for(var prop in this.customWidget) {
            if((prop === 'store_info' || prop === 'widgets_data') && getCustomWidgetResponse.data[prop]) this.customWidget[prop] = JSON.parse(getCustomWidgetResponse.data[prop]);
            else this.customWidget[prop] = getCustomWidgetResponse.data[prop];
          }
        } else {
          if(getCustomWidgetResponse.data && !getCustomWidgetResponse.data.success && getCustomWidgetResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: getCustomWidgetResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      try {
        this.loading = true;
        let shopListResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((shopListResponse.status == 200 || shopListResponse.status == 201) && shopListResponse.data.success) {
          if(shopListResponse.data.products && shopListResponse.data.products.length) {
            this.allProducts = shopListResponse.data.products.filter(e => !e.bundle && !e.catalog);
            this.allCollections = shopListResponse.data.products.filter(e => !e.bundle && e.catalog);
          }
        } else {
          if(shopListResponse.data && !shopListResponse.data.success && shopListResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: shopListResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    editWidget(wIndex) {
      try {
        let thisWidget = this.customWidget.widgets_data[wIndex];
        this.editAttentiveWidget = true;
          this.widgetIndexToEdit = wIndex;
        this.dummyMessage = JSON.parse(JSON.stringify(thisWidget));
        this.dummyMessage.include_urls = this.dummyMessage.include_urls.map(e => {
          return { url: e }
        });
        this.dummyMessage.exclude_urls = this.dummyMessage.exclude_urls.map(e => {
          return { url : e }
        });
      } catch (error) {
        console.log("Exception", error);
      }
    },
    popperSubmit(data) {
      console.log(data);
      let text = data.text; let action = data.action; let payload = undefined;
      switch(action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
        case 'website': payload = data.payload;
        break;
        case 'custom': payload = data.payload;
        break;
      }
      let toEdit = [];
      if(this.editIndex > -1) {

        if(this.dummyMessage.trigger_message_data[this.editIndex].type === 'carousel') toEdit = this.dummyMessage.trigger_message_data[this.editIndex].template.elements[0].buttons;
        else toEdit = this.dummyMessage.trigger_message_data[this.editIndex].template.buttons;

        if(this.buttonIndex > -1) {
          toEdit[this.buttonIndex].title = text;
          toEdit[this.buttonIndex].description = text;

          if(action === 'website') {
            toEdit[this.buttonIndex].type = 'webview';
            toEdit[this.buttonIndex].height_ratio = data.webHeight;
            toEdit[this.buttonIndex].url = payload;
          } else {
            toEdit[this.buttonIndex].action = 'postback';
            toEdit[this.buttonIndex].payload = payload;
          }
          
        } else {
          if(action === 'website') toEdit.push({ title: text, description: text, type: 'webview', height_ratio: data.webHeight, url: payload })
          else toEdit.push({ title: text, description: text, action: 'postback', payload: payload })
        }
      } else console.log("Something went wrong");

      this.isPopperOpen = false;
      this.editIndex = -1;
      this.buttonIndex = -1;
    },
    async handleImageUpload (event, elemIndex) {
      try {
        this.loading = true;
        if(elemIndex === 'logo' || elemIndex === 'bubble') {
          if(event.target.files && event.target.files.length) {
            let newImage = event.target.files[0];
            if(await this.validateImage(newImage, 1)) {
              let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                elemIndex === 'logo' ? this.customWidget.brand_logo = uploadSendResponseData.url : this.customWidget.bubble_image = uploadSendResponseData.url;
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong uploading the image",
                  type: "danger"
                })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({
                open: true,
                message: "Invalid picture",
                type: "danger"
              })
            }
          }
        } else {
          if(elemIndex > -1) {
            if(event.target.files && event.target.files.length) {
              let newImage = event.target.files[0];
              if(await this.validateImage(newImage, 4)) {
                let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
                if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                  this.dummyMessage.trigger_message_data[elemIndex].message[0] = uploadSendResponseData.url;
                } else {
                  this.updateToast({
                    open: true,
                    message: "Something went wrong uploading the image",
                    type: "danger"
                  })
                }
              } else {
                console.log("Invalid picture");
                this.updateToast({
                  open: true,
                  message: "Invalid picture",
                  type: "danger"
                })
              }
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async handleVideoUpload (event, elemIndex) {
      try {
        if(elemIndex > -1) {
          this.loading = true;
          if(event.target.files && event.target.files.length) {
            let newVideo = event.target.files[0];
            console.log(newVideo);
            if(await this.validateVideo(newVideo, 20)) {
              let uploadSendResponseData = await this.uploadVideo(newVideo, '/add-doc');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                this.dummyMessage.trigger_message_data[elemIndex].message[0] = uploadSendResponseData.url;
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong uploading the image",
                  type: "danger"
                })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({
                open: true,
                message: "Invalid picture",
                type: "danger"
              })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    trigger_type(value) {
			this.dummyMessage.trigger_type = value;
		},
    async updateWidget(widgets_data_change, deleting) {
      try {
        this.loading = true;
        console.log(this.customWidget);
        let reqBody = JSON.parse(JSON.stringify(this.customWidget));
        reqBody.store_info = JSON.stringify(reqBody.store_info);

        if(widgets_data_change) {
          if(deleting) reqBody.widgets_data = this.customWidget.widgets_data;
          else {
            if(this.dummyMessage.include_urls && this.dummyMessage.include_urls.length) this.dummyMessage.include_urls = this.dummyMessage.include_urls.map(e => e.url);
            if(this.dummyMessage.exclude_urls && this.dummyMessage.exclude_urls.length) this.dummyMessage.exclude_urls = this.dummyMessage.exclude_urls.map(e => e.url);

            if(this.widgetIndexToEdit > -1) for(var prop in reqBody.widgets_data[this.widgetIndexToEdit]) reqBody.widgets_data[this.widgetIndexToEdit][prop] = this.dummyMessage[prop];
            else reqBody.widgets_data.push(this.dummyMessage);
          }
        }

        reqBody.widgets_data = JSON.stringify(reqBody.widgets_data);

        console.log(reqBody);
        let updateWidgetResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-customized-widget", reqBody);
        if ((updateWidgetResponse.status == 200 || updateWidgetResponse.status == 201) && updateWidgetResponse.data.success) {
          if(updateWidgetResponse.data.result) {
            this.updateToast({
              open: true,
              message: "Widget successfully updated",
              type: "success"
            })
          }
          await this.getCustomWidget();
        } else {
          if(updateWidgetResponse.data && !updateWidgetResponse.data.success && updateWidgetResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: updateWidgetResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.settingsMode = false;
        // this.addEditMode = false;
        this.editAttentiveWidget = false;
        this.dummyMessage = JSON.parse(JSON.stringify(newMessage));
      }
    },
    async confirmDelete() {
      try {
        this.loading = true;
        if(this.widgetIndexToDelete > -1) this.customWidget.widgets_data.splice(this.widgetIndexToDelete, 1);
        else console.log("Something went wrong!");
        await this.updateWidget(true, true);
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.widgetIndexToDelete = -1;
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
td {
  text-align: center;
}
.table-container {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  border-width: 0px;
  font-size: 13px;
  margin: 15px 16px;
  min-width: calc(100% - 32px);
  width: calc(100% - 32px);
  background: var(--ion-background-color, #fff);
  -webkit-border-radius: 10px;
  overflow: hidden;
}
.addNewBttns {
  position: fixed;
  bottom: 35px;
  right: 90px;
  z-index: 2;
  width: 100px;
  height: 36px;
  border-radius: 5px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 15px 0px 15px 15px;
}
.table-header {
  margin: 15px 15px 0px 40px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}
.tooltip_container {
  position: relative;
  display: inline-block;
}
.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin: 10px 0px 10px 20px;
  padding: 10px;
  border-radius: 10px;
}
.trigger-container {
	max-width: 350px;
	margin: auto;
}
.sctn-labels {
	color: var(--ion-color-black);
	margin: 15px 0px 0px 0px;
}
.domainInput {
  display: inline-block;
  width: 60%;
  margin-top: 15px;
}
.sub-title {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.3;
	color: var(--ion-color-black);
}
.publish-text {
	margin-left: 15px;
}
.img-container{
	width: 40px;
	height: 40px;
	vertical-align: middle;
}
.upload-img-container {
	width: 70%;
	margin-right: auto;
	display: block;
	margin-left: auto;
}
.upload-img{
	margin-top: 15px;
}
figure {
  margin: 0;
}
figure.highlight {
  margin-top: 5px;
}
figure.highlight pre {
  white-space: normal;
  text-align: left;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.img-block{
	border-radius: 20px;
	border: 2px #eee dashed;
	display: inline-block;
	text-align: center;
	height: auto;
	width: 100px;
	font-size: 13px;
	padding: 15px;
	color: var(--ion-color-black);
}
.img-block:hover {
  border: 2px #3d39b9 dashed;
}
.img-block-disabled{
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
  border-radius: 20px;
	border: 2px #eee dashed;
	display: inline-block;
	text-align: center;
	height: auto;
	width: 100px;
	font-size: 13px;
	padding: 15px;
	color: var(--ion-color-black);
}
.reply-block{
	border-radius: 20px;
	border: 2px #eee dashed;
	display: inline-block;
	text-align: center;
	height: auto;
}
ion-button.img-bttn {
	--box-shadow: none;
}

.img-block-selected{
  border-radius: 20px;
	border: 2px #3d39b9 dashed;
	display: inline-block;
	text-align: center;
	height: auto;
	width: 100px;
	font-size: 13px;
	padding: 15px;
	color: var(--ion-color-black);
}

.card_container_grid {
  margin: 20px;
}
.input-container{
	margin: 20px 0px;
}
ion-button.reply-bttn {
	--box-shadow: none;
}

ion-chip {
  --background : #881fff;
  --color: var(--ion-color-white);
}
.textarea-box {
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
}

.content-area {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
}

.inpt-container {
  margin: 14px 0px;
}
.reply-bttn{
	color: var(--ion-color-black);
}

.upload-area {
	border-radius: 5px;
	border: 2px #eee dashed;
	margin: 20px 0px;
	display: flex;
	justify-content: center;
	padding: 15px;
	position: relative;
}
.normalchatarea {
	position: relative;
}

.trigger-message {
	max-width: 250px;
	margin: auto;
}
.reply-message {
	max-width: 160px;
	margin: auto;
	margin-bottom: 20px;
}
.addRow:hover {
   border: 2px #881fff dashed;
}
.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
	padding: 10px;
}

.preview-container {
	margin-left: 70px;
  margin-top: 100px;
}
.incontent-msg-pop-widget{
	background: url(https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/browser-tab.png) no-repeat;
	width: 100%;
	-webkit-background-size: contain;
	background-size: contain;
	position: relative;
	height: 500px;
}
.messagepop-widget{
	background: url(https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/attentive-widget/webpage.png) no-repeat;
	width: 100%;
	-webkit-background-size: contain;
	background-size: contain;
	position: relative;
	height: 500px;
}
.sctn-input {
	max-width: 500px;
}
.prdct-section {
	margin-left: 25px;
}
.chatbot_preview_container{
	position: absolute;
	height: auto;
	zoom: 0.6;
	bottom: 90px;
	left: 20px;
	padding: 15px;
}
.chatbot_messagepop_container {
	position: absolute;
	height: 520px;
	zoom: 0.8;
	bottom: 0px;
	right: 130px;
}
.introBubbleContainer {
	position: relative;
	height: 380px;
	margin-left: auto;
	margin-right: 0;
	right: 0px;
	bottom: 15px;
	width: 330px;
}
.chatboxExtentionCard {
	position: relative;
	width: 100%;
	max-width: 300px;
}
.imageContainer img {
	width: 100%;
	height: auto;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
	box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
	vertical-align:middle;
}
.ctaButtonContainer {
	margin: 15px 0px 10px 0px;
  width: 100%;
}
.messageOverCard {
	border-radius: 8px;
	background-color: #fff;
	color: #000;
	font-size: 14px;
	line-height: 1.4;
	padding: 10px 15px;
	margin: 0px;
	width: 100%;
	margin-bottom: 15px;
	text-align: left;
	-webkit-box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
}
.embedCodeAreaContainer {
	margin-bottom: 10px;
}
.productCardContainer {
	position: relative;
	width: 100%;
	text-align: left;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	-webkit-box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
	box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 15%);
	overflow: hidden;
	background-color: #fff;
}
.deleteElement {
	position: absolute;
	top: 0px;
	bottom: auto;
	right: -23px;
	left: auto;
	cursor: pointer;
}
.dataArea {
	padding: 10px 15px;
}
.dataArea .lhs{
	display: inline-block;
	width: calc(100% - 102px);
	vertical-align: top;
}
.productNameText {
	color: var(--ion-color-black);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.4;
	padding: 0px;
	margin: 0px;
}
.priceText {
	color: var(--ion-color-black);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
	padding: 0px;
	margin: 0px;
	margin-top: 1px;
}
.dataArea.rhs {
	display: inline-block;
	width: 95px;
	text-align: right;
	vertical-align: top;
}

ion-toolbar.buyNow {
	--background: none;
}

.widgetOpenerFloatingButton {
	margin-left: auto;
	margin-right: 0px;
	z-index: 1;
	position: absolute;
	bottom: -70px;
	right: 6px;
	background-color: #3d39b9;
	border-radius: 5px;
}
.widgetFloatingButton {
	bottom: -75px;
	right: -50px;
	margin-left: auto;
	margin-right: 0px;
	z-index: 1;
	position: absolute;
	background-color: #3d39b9;
	border-radius: 5px;
}

.imgFloating {
	padding-top: 0px;
	padding-left: 0px;
	height: 60px;
	width: 60px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
ion-button.buybutton {
	--background: #3d39b9;
}
.widgetPreviewContainer{
	margin: 30px 20px;
	pointer-events: none;
}
.widgetBody{
	width: 330px;
	height: auto;
	border-radius: 5px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-box-shadow: 0px 0px 12px rgb(0 0 0 / 12%), 0px 0px 12px rgb(0 0 0 / 12%);
	-moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.12);
	-o-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 12px rgb(0 0 0 / 12%), 0px 0px 12px rgb(0 0 0 / 12%);
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	border-radius: 6px;
}
.widgetBodyTop {
	background: url(https://jumperdevnew.appspot.com/widget/images/chatbox-header-bg.png) no-repeat;
	-webkit-background-size: cover ;
	-moz-background-size: cover ;
	-o-background-size: cover ;
	background-size: cover ;
	background-position: bottom center ;
	opacity: 1 ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
	z-index: 1 ;
	width: 100% ;
}
.widgetBodyCompanyHeader {
	padding: 30px 20px 20px ;
	display: block ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
	position: relative ;
	background-color: #3d39b9;
}
.widgetBodyCompanyLogo {
	display: inline-block;
  width: 60px;
}

.widgetBodyCompanyLogo img {
	display: inline-block ;
	height: 45px ;
	width: 45px ;
	object-fit: contain ;
	-webkit-border-radius: 5px ;
	-moz-border-radius: 5px ;
	-o-border-radius: 5px ;
	border-radius: 5px ;
	vertical-align: middle ;
	/* background-color: #fff ; */
	background-color: transparent ;
}
.widgetBodyCompanyDetails {
	display: inline-block ;
	width: calc(100% - 70px) ;
	vertical-align: middle ;
	text-transform: none ;
}

.widgetBodyCompanyDetails h5 {
	font-weight: 400 ;
	font-size: 17px ;
	line-height: 1.2 ;
	letter-spacing: 0.5px ;
	text-transform: none ;
	color: var(--ion-color-white);
}
.widgetBodyShopDetails {
	padding-top: 20px ;
}

.widgetBodyShopDetails p {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.5;
	text-transform: none;
	color: var(--ion-color-white);
}
.widgetBodyMainArea{
	background-color: var(--ion-color-white);
}

.widgetBodyMainChatArea {
	overflow-x: hidden ;
	padding-bottom: 80px ;
	-webkit-transition: all 0.3s ease ;
	transition: all 0.3s ease ;
}
.widgetChatContainer {
	padding: 15px 15px 5px;
}
.prevMobile {
  display: none;
}

.prevDesktop {
  display: block;
}
.chatBubble {
	font-size: 13px ;
	line-height: 1.5 ;
	display: inline-block ;
	position: relative;
	clear: both ;
	margin-bottom: 25px ;
	vertical-align: top ;
	max-width: 80% ;
	-webkit-animation-duration: 0.3s ;
	-moz-animation-duration: 0.3s ;
	-o-animation-duration: 0.3s ;
	animation-duration: 0.3s ;
	text-transform: none ;
}
.chatBubbleLeft {
	float: left ;
	color: #000 ;
	-webkit-animation-name: slideFromLeft ;
	animation-name: slideFromLeft ;
}
.chatBubbleRight {
	float: right ;
	color: #fff ;
	-webkit-animation-name: slideFromRight ;
	animation-name: slideFromRight ;
}

.chatBubbleRight .chatBubbleContent {
	background-color: var(--main-bg-color, #3d39b9) ;
	padding: 8px 10px ;
	float: left ;
	-webkit-border-radius: 8px ;
	-moz-border-radius: 8px ;
	-o-border-radius: 8px ;
	border-radius: 8px ;
	word-break: break-word ;
}
.left-img-container {
	height: 20px ;
	width: 20px ;
	float: left ;
	margin: 4px 4px 2px 0px ;
	bottom: 0px ;
	left: 0px ;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	vertical-align: middle;
}

.chatBubbleContent {
	background-color: #e9e8e9 ;
	padding: 8px 10px ;
	-webkit-border-radius: 8px ;
	-moz-border-radius: 8px ;
	-o-border-radius: 8px ;
	border-radius: 8px ;
	margin-left: 25px ;
	display: inline-block ;
}

.widgetFooterArea {
	width: 100% ;
	text-align: center ;
	background-color: #fff ;
}

.widgetChatInputArea {
	border-top: none;
}
.chatInputBarArea {
	padding: 10px 10px 10px 10px;
}
.widgetChatForm {
	margin: 0px ;
	text-align: left ;
	position: relative ;
}
.widgetHamburgarOpener {
	display: inline-block;
	margin: 0px;
	padding-bottom: 10px;
}
.widgetChatInputText {
	width: 265px;
	height: 40px ;
	padding-left: 15px ;
	display: inline-block;
	font-size: 15px ;
	border: 1px #ccc solid ;
	background-color: #fff ;
	color: #221e38 ;
	-webkit-border-radius: 25px ;
	border-radius: 25px ;
}
.widgetChatSendButton {
	z-index: 2;
	margin: 0px ;
	background-color: transparent ;
	cursor: pointer ;
	position: absolute ;
	right: 5px ;
	top: 13px ;
	height: 18px ;
	display: block ;
}
@media only screen and (max-width: 760px){
  .product-container {
    margin: 10px;
  }
	.trigger-container {
		max-width: 100%;
		margin: auto;
	}
	.trigger-message {
		max-width: 100%;
		margin: auto 50px;
	}
	.addNew {
    max-width: 100%;
    margin-left: 0px;
  }
  .prevMobile {
		display: block;
	}
 .prevDesktop {
  	display: none;
	}
	.model-sections {
		margin: 10px 0px 10px 0px;
	}
  .preview-container{
    margin-top: 10px;
  }
}
</style>