<template>
<master-layout pageTitle="Live chat settings">
  <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
  <div v-else>
    <ion-toolbar color="light">
      <ion-segment scrollable color="dark" :value="activeTab">
        <ion-segment-button value="office-hours" @click="changeTab('office-hours')">
          <ion-label>office hours</ion-label>
        </ion-segment-button>
        <ion-segment-button value="away-message" @click="changeTab('away-message')">
          <ion-label>Away message</ion-label>
        </ion-segment-button>
        <ion-segment-button value="message-shortcuts" @click="changeTab('message-shortcuts')">
          <ion-label>Message Shortcuts</ion-label>
        </ion-segment-button>
        <ion-segment-button value="support-message" @click="changeTab('support-message')">
          <ion-label>Talk to Support message</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
    <div v-if="activeTab === 'office-hours'">
      <div class="time-section">
        <h3 class="title" style="padding-left:5px; font-size: 24px; margin-bottom: 10px;">Set office hours</h3>
        <ion-grid>
          <ion-row v-for="(thisTiming, tIndex) in allTimings" :key="tIndex" class="row-section">
            <ion-col>
              <div class="daysRow">
                <span class="day" @click="thisTiming.validdays.indexOf(thisDay.value) > -1 ? thisTiming.validdays.splice(thisTiming.validdays.indexOf(thisDay.value), 1) : thisTiming.validdays.push(thisDay.value)" v-for="thisDay in days" :key="thisDay.value" :class="thisTiming.validdays.indexOf(thisDay.value) > -1 ? 'selected' : ''">{{ thisDay.day }}</span>
              </div>
            </ion-col>
            <ion-col>
              <div class="timeRow">
                <div :for="'validfrom' + tIndex" class="timeText">Start Time</div>
                <input type="time" :name="'validfrom' + tIndex" :disabled="thisTiming.is24Hr" v-model="thisTiming.validfrom" />
              </div>
            </ion-col>
            <ion-col>
              <div class="timeRow">
                <div :for="'validtill' + tIndex" class="timeText">End Time</div>
                <input type="time" :name="'validtill' + tIndex" :disabled="thisTiming.is24Hr" v-model="thisTiming.validtill" />
              </div>
            </ion-col>
            <ion-col  size-xl="2" size-lg="2" size-md="2" size-sm="6" size-xs="6">
              <div class="checkboxRow">
                <input type="checkbox" :name="'is24Hr' + tIndex" v-model="thisTiming.is24Hr" @change="check24Hrs(tIndex)" style="cursor: pointer">
                <label :for="'is24Hr' + tIndex">24 hrs</label>
              </div>
            </ion-col>
            <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="6" size-xs="6">
              <ion-button @click="() => {deleteTmingMode = true; dummyTiming = JSON.parse(JSON.stringify(thisTiming));}" fill="clear" size="default" color="danger" style="margin: 28px">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="addRow" @click="addTiming">+ Add New</div>
        <div class="saveRow">
          <ion-buttons slot="end" class="paddingBox">
            <ion-button :disabled="busyOffice" @click="saveTimings" class="bttn-bgnd">{{busyOffice ? 'Saving...' : 'Save'}}</ion-button>
          </ion-buttons>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'away-message'">
      <div class="model-sections">
        <ion-item lines="none" style="padding: 15px;">
          <ion-label>
            <h3 class="title" style="padding-left:0px; font-size: 24px;">Away message</h3>
            <p class="sub-title">This message will be sent to customers who are willing to talk to live chat agent and no agent is available.</p>
            <textarea name="away-message" id="away-message" rows="3" v-model="awayMessage"></textarea>
            <ion-toolbar>
              <ion-buttons slot="end" class="paddingBox">
                <ion-button :disabled="busyAway" @click="updateAwayMessage" class="bttn-bgnd">{{ busyAway ? 'Saving...' : 'Save' }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-label>
        </ion-item>
      </div>
    </div>

    <div v-if="activeTab === 'message-shortcuts'">
      <ion-item lines="none" style="padding: 15px;">
        <ion-label style="margin: 10px 10px 20px 10px">
          <ion-icon class="addIcon" slot="icon-only" item-right :icon="addCircleOutline" @click="() => {editShortcutModal = true; dummyShortcut = JSON.parse(JSON.stringify(newShortcut));}" style="cursor: pointer"></ion-icon>
          <h3 class="title" style="padding-left:0px; font-size: 24px;">Manage Message Shortcuts</h3>
          <p class="sub-title" style="margin-top: 5px">Message shortcuts are used by the live chat agents to send the preset messages to commonly asked queries of the customers.</p>
        </ion-label>
      </ion-item>
      <ion-grid class="table-header ion-no-padding">
        <ion-row class="ion-justify-content-between">
          <ion-col>
            <ion-label>SHORTCUT</ion-label>
          </ion-col>
          <ion-col>
            <ion-label>MESSAGE</ion-label>
          </ion-col>
          <ion-col>
            <ion-label>ACTIONS</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="shortcut-sctn">
        <div class="table-container">
          <ion-grid class="ion-no-padding">
            <ion-row v-for="shortcut in allShortcuts" :key="shortcut.id" class="table-row ion-no-padding">
              <ion-col class="orderDesc">
                <ion-label class="shortcutRow">/{{ shortcut.name }} </ion-label>
              </ion-col>
              <ion-col class="orderDesc">
                <ion-label class="shortcutRow">/{{ shortcut.response }} </ion-label>
              </ion-col>
              <ion-col>
                <ion-button @click="() => {editShortcutModal = true; dummyShortcut = JSON.parse(JSON.stringify(shortcut));}" fill="clear" size="small" color="dark">
                  <ion-icon slot="icon-only" :icon="pencilOutline"></ion-icon>
                </ion-button>
                <ion-button @click="() => {deleteShortcutMode = true; dummyShortcut = JSON.parse(JSON.stringify(shortcut));}" fill="clear" size="small" color="danger">
                  <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>

     <div v-if="activeTab === 'support-message'">
      <div class="model-sections">
        <ion-item lines="none" style="padding: 15px;">
          <ion-label>
            <h3 class="title" style="padding-left:0px; font-size: 24px;">Talk to support message</h3>
            <p class="sub-title">The following message will be sent to a user when they ask for manual support by clicking the "Talk to support" button.</p>
            <textarea name="support-message" id="support-message" rows="3" v-model="talkToSupportMessage"></textarea>
            <div class="notice">
              <h3 style="margin-bottom: 5px;">Mandatory tags for message:</h3>
              <span class="tag danger tag-title">&lt;username&gt;</span>
            </div>
            <ion-toolbar>
              <ion-buttons slot="end" class="paddingBox">
                <ion-button :disabled="busySupport" @click="updateSupportMessage" class="bttn-bgnd">{{ busySupport ? 'Saving...' : 'Save' }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-label>
        </ion-item>
      </div>
      <div>
        <ion-item lines="none" style="padding: 15px;">
          <ion-label>
            <h3 class="title" style="padding-left:0px; font-size: 24px;">Unique Link Welcome Message</h3>
            <p class="sub-title">When users come to the brand's channel from the agent's unique links, they will be greeted with the welcome message below and then assigned to the respective agent.</p>
            <div style="margin: 20px 0px 10px 0px">
              <span class="warning-img"> - </span>  
              <span class="warning-desc"><b>Tip:</b> This can be used to greet the user, and also display your policy (e..g, privacy policy and/or terms and conditions) that you need to display to your customers before they interact with your brand page.</span>
            </div>
            <textarea name="agent-message" id="agent-message" rows="3" v-model="agentMessage"></textarea>
            <div class="notice">
              <h3 style="margin-bottom: 5px">Mandatory tags for message:</h3>
              <span class="tag danger tag-title">&lt;username&gt;</span>
              <span class="tag danger tag-title">&lt;agentname&gt;</span>
            </div>
            <ion-toolbar>
              <ion-buttons slot="end" class="paddingBox">
                <ion-button :disabled="busyAgent" @click="updateAgentMessage" class="bttn-bgnd">{{ busyAgent ? 'Saving...' : 'Save' }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-label>
        </ion-item>
      </div>
    </div>
  </div>
  <ion-alert
    v-if="deleteTmingMode"
    :is-open="deleteTmingMode"
    header="Are you sure?"
    :message="'You want to remove this timing?'"
    :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        dummyTiming = JSON.parse(JSON.stringify(newTiming));
        deleteTmingMode = false;
      },
    },
    {
      text: 'Yes',
      handler: () => {
        confirmRemoveTiming()
      },
    }]"
    @didDismiss="() => {deleteTmingMode = false; dummyTiming = JSON.parse(JSON.stringify(newTiming));}"
  >
  </ion-alert>
  <ion-alert
    v-if="deleteShortcutMode"
    :is-open="deleteShortcutMode"
    header="Are you sure?"
    :sub-header="dummyShortcut.name +' will be removed from the list of shortcuts.'"
    :message="'Delete ' + dummyShortcut.name + ' ?'"
    :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        dummyShortcut = JSON.parse(JSON.stringify(newShortcut));
        deleteShortcutMode = false;
      },
    },
    {
      text: 'Yes',
      handler: () => {
        confirmDeleteShortcut()
      },
    }]"
    @didDismiss="() => {deleteShortcutMode = false; dummyShortcut = JSON.parse(JSON.stringify(newShortcut));}"
  >
  </ion-alert>
  <ion-modal
    v-if="editShortcutModal"
    :is-open="editShortcutModal"
    @didDismiss="() => {editShortcutModal = false; dummyShortcut = JSON.parse(JSON.stringify(newShortcut));}"
  >
    <ion-page>
      <ion-content :fullscreen="true">
        <ion-toolbar class="chat-toolbar" color="light">
          <h2 class="ion-no-margin" style="margin-left: 15px">{{ dummyShortcut.id ? 'Edit' : 'Add' }} Shortcut</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => {editShortcutModal = false; dummyShortcut = JSON.parse(JSON.stringify(newShortcut));}">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <form id="quickReplyForm">
          <div>
            <ion-label class="sctn-labels">Shortcut</ion-label>
            <span style="margin: 0px 10px">&frasl;</span><input type="text" name="editShortcutName" v-model="dummyShortcut.name" />
          </div>
          <div>
            <ion-label class="sctn-labels">Message</ion-label>
            <textarea name="editShortcutResponse" id="editShortcutResponse" rows="4" v-model="dummyShortcut.response"></textarea>
          </div>
           <ion-buttons slot="start" class="paddingBox">
              <ion-button :disabled="busyShortcut" @click.prevent="updateShortcut" class="bttn-bgnd">{{ busyShortcut ? 'Saving...' : 'Save' }}</ion-button>
          </ion-buttons>
        </form>
      </ion-content>
    </ion-page>
  </ion-modal>
</master-layout>
</template>

<script>
import {
  addCircleOutline,
  close,
  pencilOutline,
  trashOutline
} from 'ionicons/icons';

import jwtInterceptor from '/src/shared/jwtInterceptor';

const newTiming = {
  validdays: [],
  validfrom: undefined,
  validtill: undefined,
  is24Hr: false
}
const newShortcut = {
  name: undefined,
  response: undefined
}

export default {
  data() {
    return {
      addCircleOutline,
      close,
      pencilOutline,
      trashOutline,
      loading: true,
      allTimings: [],
      awayMessage: undefined,
      agentMessage: undefined,
      talkToSupportMessage: undefined,
      allShortcuts: [],
      allComments: [],
      busyOffice: false,
      busyAway: false,
      busyShortcut: false,
      busyAgent: false,
      busySupport: false,
      deleteTmingMode: false,
      dummyTiming: JSON.parse(JSON.stringify(newTiming)),
      newTiming: JSON.parse(JSON.stringify(newTiming)),
      newShortcut: JSON.parse(JSON.stringify(newShortcut)),
      dummyShortcut: JSON.parse(JSON.stringify(newShortcut)),
      editShortcutModal: false,
      deleteShortcutMode: false,
      activeTab: 'office-hours',
      officeHoursInitialized: false,
      awayMessageInitialized: false,
      supportMessageInitialized: false,
      messageShortcutsInitialized: false,
      days: [
        {
          day: 'S',
          value: '7'
        },
        {
          day: 'M',
          value: '1'
        },
        {
          day: 'T',
          value: '2'
        },
        {
          day: 'W',
          value: '3'
        },
        {
          day: 'T',
          value: '4'
        },
        {
          day: 'F',
          value: '5'
        },
        {
          day: 'S',
          value: '6'
        }
      ]
    }
  },
  ionViewWillEnter(){
    this.initView();
  },
  methods: {
    async initView() {
      try {
        let timingsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-timing");
        let awayResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-away-message");
        let agentResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-agent-message");
        let shortcutsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-shortcut");
        let commentsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "list-comments");
        let allResponses = await Promise.all([timingsResponse, awayResponse, agentResponse, shortcutsResponse, commentsResponse]);
        timingsResponse = allResponses[0];
        awayResponse = allResponses[1];
        agentResponse = allResponses[2];
        shortcutsResponse = allResponses[3];
        commentsResponse = allResponses[4];
        if((timingsResponse.status === 200 || timingsResponse.status === 201) && timingsResponse.data) {
          if(timingsResponse.data.success) {
            this.allTimings = timingsResponse.data.timings;
            this.allTimings = this.allTimings.map(e => {
              e.validfrom === '00:00' && e.validtill === '23:59' ? e.is24Hr = true : e.is24Hr = false;
              return e;
            })
          } else {
            this.updateToast({
              open: true,
              message: timingsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
        if((awayResponse.status === 200 || awayResponse.status === 201) && awayResponse.data) {
          if(awayResponse.data.success) {
            this.awayMessage = awayResponse.data.return_message && awayResponse.data.return_message.length ? awayResponse.data.return_message[0] : undefined;
          } else {
            this.updateToast({
              open: true,
              message: awayResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
        if((agentResponse.status === 200 || agentResponse.status === 201) && agentResponse.data) {
          if(agentResponse.data.success) {
            this.agentMessage = agentResponse.data.return_message && agentResponse.data.return_message.length ? agentResponse.data.return_message[0] : undefined;
          } else {
            this.updateToast({
              open: true,
              message: agentResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
        if((shortcutsResponse.status === 200 || shortcutsResponse.status === 201) && shortcutsResponse.data) {
          if(shortcutsResponse.data.success) {
            this.allShortcuts = shortcutsResponse.data.shortcuts;
          } else {
            this.updateToast({
              open: true,
              message: shortcutsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
        if((commentsResponse.status === 200 || commentsResponse.status === 201) && commentsResponse.data) {
          if(commentsResponse.data.success) {
            this.allComments = commentsResponse.data.messages;
            this.talkToSupportMessage = this.allComments.find(e => e.vertex == "20080").message;
          } else {
            this.updateToast({
              open: true,
              message: commentsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    addTiming() {
      this.allTimings.push(JSON.parse(JSON.stringify(newTiming)));
    },
    async confirmRemoveTiming() {
      try {
        let reqBody = {
          timing_id: this.dummyTiming.id
        }
        console.log(reqBody);
        let deleteTimingResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-office-timing", reqBody);

        if ((deleteTimingResponse.status == 200 || deleteTimingResponse.status == 201) && deleteTimingResponse.data) {
          console.log(deleteTimingResponse.data);
          if(deleteTimingResponse.data.success && deleteTimingResponse.data.deleted) {
            this.allTimings.splice(this.allTimings.findIndex(e => e.id === reqBody.timing_id), 1);
            this.updateToast({
              open: true,
              message: "Timing deleted successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: deleteTimingResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", deleteTimingResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.dummyTiming = JSON.parse(JSON.stringify(newTiming));
        this.deleteTmingMode = false;
      }
    },
    check24Hrs(index) {
      if(this.allTimings[index].is24Hr) {
        this.allTimings[index].validfrom = '00:00';
        this.allTimings[index].validtill = '23:59';
      }
    },
    async saveTimings() {
      try {
        let formError = false;
        this.busyOffice = true;
        this.allTimings.forEach(e => {
          if(!e.validdays || !e.validdays.length) formError = true;
          if(!e.validfrom) formError = true;
          if(!e.validtill) formError = true;
        })
        if(formError) {
          this.updateToast({
            open: true,
            message: "Please fill in the required data",
            type: "danger"
          })
          return
        }
        let reqBody = {
          timings: JSON.stringify(this.allTimings.map(e => {
            e.name = 'office_timings';
            e.validdays = e.validdays.join(",");
            delete e.is24Hr;
            delete e.status;
            delete e.validenddate;
            delete e.validstartdate;
            delete e.id;
            return e;
          })),
          name: 'office_timings',
          is_agent: true
        }
        console.log(reqBody);
        let saveTimingsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-timings", reqBody);

        if ((saveTimingsResponse.status == 200 || saveTimingsResponse.status == 201) && saveTimingsResponse.data) {
          console.log(saveTimingsResponse.data);
          if(saveTimingsResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Timings updated successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: saveTimingsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", saveTimingsResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.busyOffice = false;
      }
    },
    async updateAwayMessage() {
      try {
        this.busyAway = true;
        if(!this.awayMessage || !this.awayMessage.length) {
          this.updateToast({
            open: true,
            message: "Away message can not be empty",
            type: "danger"
          })
          return
        }
        let reqBody = {
          inputmessage: this.awayMessage
        }
        console.log(reqBody);
        let updateAwayMessageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-away-message", reqBody);

        if ((updateAwayMessageResponse.status == 200 || updateAwayMessageResponse.status == 201) && updateAwayMessageResponse.data) {
          console.log(updateAwayMessageResponse.data);
          if(updateAwayMessageResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Away message updated successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: updateAwayMessageResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", updateAwayMessageResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.busyAway = false;
      }
    },
    async updateSupportMessage() {
      try {
        this.busySupport = true;
        if(this.talkToSupportMessage.indexOf('<username>') === -1) {
          this.updateToast({
            open: true,
            message: "Please enter the required tag <username>",
            type: "danger"
          })
          return
        }
        let reqBody = {
          message: this.talkToSupportMessage,
          vertex: 20080
        }
        console.log(reqBody);
        let updateSupportMessageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-custom", reqBody);

        if ((updateSupportMessageResponse.status == 200 || updateSupportMessageResponse.status == 201) && updateSupportMessageResponse.data) {
          console.log(updateSupportMessageResponse.data);
          if(updateSupportMessageResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Support message updated successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: updateSupportMessageResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", updateSupportMessageResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.busySupport = false;
      }
    },
    async updateAgentMessage() {
      try {
        this.busyAgent = true;
        if(this.agentMessage.indexOf('<username>') === -1 || this.agentMessage.indexOf('<agentname>') === -1) {
          this.updateToast({
            open: true,
            message: "Please enter the required tags <username> and <agentname>",
            type: "danger"
          })
          return
        }
        let reqBody = {
          inputmessage: this.agentMessage
        }
        console.log(reqBody);
        let updateAgentMessageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-agent-message", reqBody);

        if ((updateAgentMessageResponse.status == 200 || updateAgentMessageResponse.status == 201) && updateAgentMessageResponse.data) {
          console.log(updateAgentMessageResponse.data);
          if(updateAgentMessageResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Welcome message updated successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: updateAgentMessageResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", updateAgentMessageResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.busyAgent = false;
      }
    },
    async updateShortcut() {
      try {
        this.busyShortcut = true;
        let formError = false;
        if(!this.dummyShortcut.name || !this.dummyShortcut.name.length) formError = true;
        if(!this.dummyShortcut.response || !this.dummyShortcut.response.length) formError = true;
        if(formError) {
          this.updateToast({
            open: true,
            message: "Shortcut or message can not be empty!",
            type: "danger"
          })
          return
        }
        let reqBody = {
          name: this.dummyShortcut.name,
          response: this.dummyShortcut.response,
          shortcutid: this.dummyShortcut.id
        }
        if(!this.dummyShortcut.id) delete reqBody.shortcutid;
        console.log(reqBody);
        let editShortcutResponse = this.dummyShortcut.id ? await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-shortcut", reqBody) : await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "create-shortcut", reqBody);

        if ((editShortcutResponse.status == 200 || editShortcutResponse.status == 201) && editShortcutResponse.data) {
          console.log(editShortcutResponse.data);
          if(editShortcutResponse.data.success) {
            reqBody.shortcutid ? this.allShortcuts.splice(this.allShortcuts.findIndex(e => e.id === reqBody.shortcutid), 1, {id: editShortcutResponse.data.id, name: editShortcutResponse.data.name, response: editShortcutResponse.data.response}) : this.allShortcuts.unshift({id: editShortcutResponse.data.id, name: editShortcutResponse.data.name, response: editShortcutResponse.data.response});
            this.updateToast({
              open: true,
              message: "Shortcut deleted successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: editShortcutResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", editShortcutResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.dummyShortcut = JSON.parse(JSON.stringify(newShortcut));
        this.editShortcutModal = false;
        this.busyShortcut = false;
      }
    },
    async confirmDeleteShortcut() {
      try {
        let reqBody = {
          shortcutid: this.dummyShortcut.id
        }
        console.log(reqBody);
        let deleteShortcutResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-shortcut", reqBody);

        if ((deleteShortcutResponse.status == 200 || deleteShortcutResponse.status == 201) && deleteShortcutResponse.data) {
          console.log(deleteShortcutResponse.data);
          if(deleteShortcutResponse.data.success) {
            this.allShortcuts.splice(this.allShortcuts.findIndex(e => e.id === reqBody.shortcutid), 1);
            this.updateToast({
              open: true,
              message: "Shortcut deleted successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: deleteShortcutResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", deleteShortcutResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.dummyShortcut = JSON.parse(JSON.stringify(newShortcut));
        this.deleteShortcutMode = false;
      }
    },
    async changeTab(newTab) {
      this.activeTab = newTab;
      switch(this.activeTab) {
        case 'message-shortcuts': !this.messageShortcutsInitialized && this.initView(); this.messageShortcutsInitialized = true;
        break;
        case 'support-message': !this.supportMessageInitialized && this.initView(); this.supportMessageInitialized = true;
        break;
        case 'away-message': !this.awayMessageInitialized && this.initView(); this.awayMessageInitialized = true;
        break;
        case 'office-hours': !this.officeHoursInitialized && this.initView(); this.officeHoursInitialized = true;
        break;
      }
    },
  }
}
</script>

<style scoped>
button:disabled {
  opacity: 0.5 !important;
}
.daysRow {
  margin: 30px;
  padding: 10px 0;
  cursor: pointer;
}

.daysRow > .day {
  padding: 5px;
  border: 1px solid black;
  border-radius: 3px;
  margin: 2px;
}
.daysRow > .day.selected {
  background-color: #881fff;
  color: white;
}

.timeRow, .shortcutRow {
  margin: 10px 0px;
  padding: 10px 0px;
}

.row-section {
  border: 1px #eee solid;
  background: rgba(246, 245, 246, 0.3);
  margin-bottom: 5px;
  border-radius: 5px;
}

.checkboxRow {
  margin: 20px 0;
  padding: 10px 0;
}
.tag-title{
  background-color: #ff0000;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  margin: 0px 5px 5px 0px;
  color: #ffffff;
}

.checkboxRow > input {
  margin-right: 10px;
}

.addRow {
  border-radius: 5px;
  border: 2px #eee dashed;
  color: #881fff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
}

.addIcon {
  font-size: 40px;
  background: #881fff;
  color: white;
  border-radius: 50%;
  float: right;
  margin: 10px;
}

input, textarea {
  outline: none;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}

textarea {
  width: 100%;
}
.timeText {
  font-size: 16px;
  margin-right: 12px;
  color: var(--ion-color-grey);
}

.saveRow {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
  padding: 10px 0;
}

.saveRow > button.primary {
  background-color: #881fff;
  color: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* text-transform: capitalize; */
  opacity: 1;
  /* white-space: pre-wrap; */
  cursor: pointer;
  outline: none !important;
}

#quickReplyForm {
  padding: 20px;
}

#quickReplyForm * {
  margin: 10px 0;
}
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}



.sctn-labels {
    padding-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--ion-color-grey);
}

.notice {
  margin-bottom: 15px;
}

.time-section {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin: 12px;
  padding-bottom: 20px;
  padding: 15px;
}
.shortcut-sctn{
  margin-top: 20px;
}
.warning-desc {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  line-height: 21px;
}

.warning-img {
  height: 52px;
  margin-top: 10px;
  width: 3px;
  height: 20px;
  background-color: #ffc409;
  color: #ffc409;
  margin-right: 8px;
}

.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
  margin-top: 10px;
}
.sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-grey);
  margin-top: 10px;
}
@media only screen and (max-width: 760px){
  .checkboxRow {
    margin: 20px;
  }
  .timeRow {
    margin: 15px;
  }
}

</style>