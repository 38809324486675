<template>
  <div id="slider-wrapper" :class="{ darkModeEnabled: isDarkMode }">
    <input checked="" type="radio" name="slider" id="slide1" selected="false">
    <input type="radio" name="slider" id="slide2" selected="false">
    <input type="radio" name="slider" id="slide3" selected="false">
    <input type="radio" name="slider" id="slide4" selected="false">
    <input type="radio" name="slider" id="slide5" selected="false">
    <input type="radio" name="slider" id="slide6" selected="false">
    <div id="slides">
      <div id="overflow">
        <div class="inner" ref="sliderWrapper">
          <slot></slot>
        </div> <!-- .inner -->
      </div> <!-- #overflow -->
    </div>
    <div class="label-wrapper">
      <label for="slide1"></label>
      <label for="slide2"></label>
      <label for="slide3"></label>
      <label for="slide4"></label>
      <label for="slide5"></label>
      <label for="slide6"></label>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => {
    return {
      imageCount: 0
    };
  },
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  mounted() {
    this.imageCount = this.$refs.sliderWrapper.children.length;
  },
  components: {
  },
  computed: {
    ...mapState(
      {
        isDarkMode: (state) => state.darkMode
      }
    )
  },
  methods: {
    slideHandler() {
    }
  },
};
</script>

<style scoped>
#slider-wrapper {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

#overflow {
  width: 100%;
  overflow: hidden;
}

#slides .inner {
  width: 600%;
}

#slides .inner {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -ms-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -ms-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

:slotted(img) {
  width: calc(100% / 6) !important;
  float: left;
}

#slide1:checked~#slides .inner {
  margin-left: 0;
}

#slide2:checked~#slides .inner {
  margin-left: -100%;
}

#slide3:checked~#slides .inner {
  margin-left: -200%;
}

#slide4:checked~#slides .inner {
  margin-left: -300%;
}

#slide5:checked~#slides .inner {
  margin-left: -400%;
}

#slide6:checked~#slides .inner {
  margin-left: -500%;
}

input[type="radio"] {
  display: none;
}

label {
  background: #FFFFFF;
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.darkModeEnabled label {
  background: #212121;
}

#slide1:checked~.label-wrapper label[for="slide1"],
#slide2:checked~.label-wrapper label[for="slide2"],
#slide3:checked~.label-wrapper label[for="slide3"],
#slide4:checked~.label-wrapper label[for="slide4"],
#slide5:checked~.label-wrapper label[for="slide5"],
#slide6:checked~.label-wrapper label[for="slide6"] {
  background: #D9DCE3;
}

.darkModeEnabled #slide1:checked~.label-wrapper label[for="slide1"],
.darkModeEnabled #slide2:checked~.label-wrapper label[for="slide2"],
.darkModeEnabled #slide3:checked~.label-wrapper label[for="slide3"],
.darkModeEnabled #slide4:checked~.label-wrapper label[for="slide4"],
.darkModeEnabled #slide5:checked~.label-wrapper label[for="slide5"],
.darkModeEnabled #slide6:checked~.label-wrapper label[for="slide6"] {
  background: #7F7F7F;
}

.label-wrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
</style>