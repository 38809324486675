<template>
<master-layout pageTitle="Edit profile">
  <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
  <div v-else>
    <ion-card>
      <ion-card-header>
        <ion-card-title>Profile picture</ion-card-title>
      </ion-card-header>
      <ion-card-content class="text-center">
        <ion-avatar style="display: inline-block; height: 120px; width: 120px;">
          <img :src="agentProfilePictureUploadImage" style="height: 120px; width: 120px;">
        </ion-avatar>
        <div style="margin-top: 10px;">
          <ion-button fill="solid" size="default" color="dark" @click="uploadNewDP" v-if="profilePictureUploading == false">
            Upload
          </ion-button>
          <ion-button fill="solid" size="default" color="dark" disabled v-else>
            Uploading...
          </ion-button>
        </div>
        <input type="file" style="display: none;" id="profilePicInput" ref="profilePicInput" @change="handleImageUpload" accept="image/*" />
      </ion-card-content>
    </ion-card>
    <ion-card v-if="messengerConnected == true || whatsappConnected == true">
      <ion-card-header>
        <ion-card-title>Unique live chat link</ion-card-title>
      </ion-card-header>
      <div v-if="!agent_nickname || agent_nickname == ''">
        <ion-item lines="none">
          <ion-label position="stacked">Enter your link keyword</ion-label>
          <ion-input type="text" class="fullBordered" minlength="3" maxlength="50" placeholder="Enter a keyword here" v-model="new_agent_nickname"></ion-input>
          <ion-text color="medium" style="font-size: 12px; padding: 0px; margin: 0px; margin-top: 5px;">For example: it will appear as https://{{ user.shopLink }}.jumper.ai/talktous/fb/&lt;pageid&gt;/&lt;keyword&gt;</ion-text>
        </ion-item>
        <ion-item lines="none">
          <ion-button fill="solid" size="default" color="dark" @click="agent_nicknameFormSubmit" class="agent_nicknameFormSubmitBtn" slot="start" style="margin-top: 16px; margin-bottom: 16px;">
            Save
          </ion-button>
        </ion-item>
      </div>
      <div v-if="agent_nickname && agent_nickname != ''">
        <ion-item lines="full" v-for="thisLink in listOfUniqueLiveChatLinks" :key="thisLink.id">
          <ion-thumbnail slot="start">
            <img :src="thisLink.image">
          </ion-thumbnail>
          <ion-label>
            <h2 class="title">{{ thisLink.title }}</h2>
            <p class="subTitle">{{ thisLink.link }}</p>

            <input :id="thisLink.id" type="text" :value="thisLink.link" style="opacity: 0; position: absolute; pointer-events: none;" />
          </ion-label>
          <ion-button fill="outline" size="default" color="dark" slot="end" @click="copyToClipboard(thisLink.id)">Copy</ion-button>
        </ion-item>
      </div>
    </ion-card>
  </div>
</master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
export default {
  data() {
    return {
      loading: true,
      agent_nickname___dynamicArray: [],
      agent_nickname: "",
      new_agent_nickname: "",
      listOfUniqueLiveChatLinks: [],
      profilePictureUploading: false
    }
  },
  mounted(){
    try {
      this.agent_nickname___dynamicArray = [];

      if(typeof this.roleDetails.dynamics != 'undefined'){
        this.agent_nickname___dynamicArray = this.roleDetails.dynamics;
      }

      this.agent_nickname = this.roleDetails.agent_nickname;

      if(typeof this.roleDetails.agent_nickname == 'boolean'){
        this.agent_nickname = "";
      }
      else if(this.roleDetails.agent_nickname == '' || this.roleDetails.agent_nickname == 'None' || this.roleDetails.agent_nickname == null){
        this.agent_nickname = "";
      }
      
      //adding all kind of links in 1 array: listOfUniqueLiveChatLinks
      if(this.agent_nickname___dynamicArray.length == 0){
        if(this.facebookSocialData && this.facebookSocialData.pages && this.facebookSocialData.pages.length) {
          for(var i=0; i < this.facebookSocialData.pages.length; i++){
            this.listOfUniqueLiveChatLinks.push({
              "id": "facebook-"+ this.facebookSocialData.pages[i].messenger,
              "image": "https://graph.facebook.com/"+ this.facebookSocialData.pages[i].messenger +"/picture?type=normal",
              "title": this.facebookSocialData.pages[i].name,
              "link": this.shopLinkConsideringCustomDomain +"/talktous/fb/"+ this.facebookSocialData.pages[i].messenger +"/"+ this.agent_nickname
            });
          }
        }

        if(this.whatsappConnected == true){
          this.listOfUniqueLiveChatLinks.push({
            "id": "whatsapp",
            "image": "https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/whatsapp.png",
            "title": "WhatsApp",
            "link": this.shopLinkConsideringCustomDomain +"/talktous/wa/"+ this.agent_nickname
          });
        }
      }
      else{
        for(var h=0; h < this.agent_nickname___dynamicArray.length; h++){
          for(var j=0; j < this.facebookSocialData.pages.length; j++){
            this.listOfUniqueLiveChatLinks.push({
              "id": "facebook-"+ this.agent_nickname___dynamicArray[h].agent_nickname +"-"+ this.facebookSocialData.pages[j].messenger,
              "image": "https://jumper.ai/web-s/resources/images/shop-image-placeholder.png",
              "title": this.agent_nickname___dynamicArray[h].outlet +" "+ this.facebookSocialData.pages[j].name,
              "link": this.shopLinkConsideringCustomDomain +"/talktous/fb/"+ this.facebookSocialData.pages[j].messenger +"/"+ this.agent_nickname___dynamicArray[h].agent_nickname
            });
          }
        }
      }

      this.loading = false;
    } catch (error) {
      console.log("Exception", error);
    }
  },
  computed: {
    
  },
  methods: {
    uploadNewDP() {
      try {
        this.$refs.profilePicInput.click()
      } catch (error) {
        console.log("Exception: ", error);
      }
    },
    async handleImageUpload (event) {
      try {
        if(event.target.files && event.target.files.length) {
          this.profilePictureUploading = true;
          let newProfilePicFile = event.target.files[0];
          if(await this.validateImage(newProfilePicFile, 4)) {
            let uploadSendResponseData = await this.uploadImage(newProfilePicFile, '/add-image');
            let reqBody = {
              image_url: uploadSendResponseData.image
            };
            let picUpdateResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-picture", reqBody);
            if ((picUpdateResponse.status == 200 || picUpdateResponse.status == 201) && picUpdateResponse.data.success) {
              this.updateToast({
                open: true,
                message: "Your profile picture has been updated successfully!",
                type: "success"
              })
              this.roleDetails.picture = uploadSendResponseData.url;
              await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
              await this.Storage.remove({ key: 'jmpr_roleDetailsData' });
              //window.location.href = window.location.pathname;
              this.profilePictureUploading = false;
            } else {
              console.log("Response:", picUpdateResponse);
              this.updateToast({
                open: true,
                message: picUpdateResponse.data.errorMessage,
                type: "danger"
              })
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      }
    },
    copyToClipboard(linkElemId) {
      var copyText = document.getElementById(linkElemId);
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      this.updateToast({
        open: true,
        message: "Copied to clipboard",
        type: "success"
      })
    },
    async agent_nicknameFormSubmit() {
      try {
        //this.new_agent_nickname = this.new_agent_nickname.replace(/[<>%{}|\~`? ;/?:@=&#$()+/`~!^*=',.]+/gi,''); //pending
        var hasError = false;
        if(this.new_agent_nickname == ''){
          hasError = true;
        }
        else if(this.new_agent_nickname.length < 3){
          hasError = true;
        }

        if(hasError == true){
          this.updateToast({
            open: true,
            message: "Please enter link keyword with at least 3 characters",
            type: "warning"
          })
          return;
        }

        let reqBody = {
          agent_nickname: this.new_agent_nickname
        }
        let agentNicknameSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-user-nickname", reqBody);
        if ((agentNicknameSaveResponse.status == 200 || agentNicknameSaveResponse.status == 201) && agentNicknameSaveResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Your unique live chat link has benn created successfully!",
            type: "success"
          });
          this.agent_nickname = this.new_agent_nickname;
          await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
          await this.Storage.remove({ key: 'jmpr_roleDetailsData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(agentNicknameSaveResponse.data.errorMessage && agentNicknameSaveResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: agentNicknameSaveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      }
    },
  }
}
</script>