<template>
<div class="ion-padding view-whats-app-wrapper">
  <i-phone-preview channelName="whatsapp">
    <div class="iphone-screen" v-if="body.body">
      <img src="https://jumperdevnew.appspot.com/web-s/resources/images/iphone-statusbar.png" style="height: auto; width: 100%; margin-top: -8px;">
      <div class="chat">
        <div class="chat-container">
          <div class="user-bar">
            <div class="back">
              <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
            </div>
            <div class="avatar">
              <img src="https://jumperdevnew.appspot.com/web-s/resources/images/shop-image-placeholder.png" alt="Avatar">
            </div>
            <div class="name">
              <span>Brand name</span>
              <span class="status">online</span>
            </div>
            <div class="actions more">
              <ion-icon size="small" :icon="ellipsisVerticalOutline"></ion-icon>
            </div>
            <div class="actions attachment">
              <ion-icon size="small" :icon="attachOutline"></ion-icon>
            </div>
            <div class="actions">
              <ion-icon size="small" :icon="callOutline"></ion-icon>
            </div>
          </div>
          <div class="conversation">
            <div class="conversation-container">
              <div class="message--cont received">
                <span id="random">
                  <template v-if="body.header">
                    <div class="img--cont" v-if="body.header.format === 'IMAGE'">
                      <img height="50" src="https://jumperdevnew.appspot.com/web-s/resources/images/upload-image-placeholder-dotted.svg">
                    </div>
                    <div class="img--cont" v-if="body.header.format === 'VIDEO'">
                      <img height="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PDb-r4KDA">
                    </div>
                    <div class="img--cont" v-if="body.header.format === 'DOCUMENT'">
                      <img height="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4MCSnukKDA" alt="">
                    </div>
                    <p class="mb-0" v-if="body.header.format === 'TEXT'">
                      <strong>{{ body.header.text }}</strong>
                    </p>
                  </template>
                  <br />
                  <div v-if="body.body.text">
                    {{ body.body.text }}
                  </div>
                  <template v-if="body.body.html">
                  <div v-html="body.body.html"></div>
                  </template>
                </span>
                <span class="metadata" style="float: left; padding-left: 2px;">
                  <span class="time">{{ body.footer }}</span>
                </span>
                <span class="metadata">
                  <span class="time">10:10 PM</span>
                </span>
                <div class="btn--cont" v-if="body.allButtons && body.allButtons.length">
                  <div class="btn--forWhatsapp" v-for="(thisButton, bIndex) in allButtons" :key="bIndex">
                    <span>{{ thisButton.text }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="conversation-compose">
              <div class="emoji">
                <ion-icon size="small" :icon="happyOutline"></ion-icon>
              </div>
              <input class="input-msg" name="input" placeholder="Type a message" autocomplete="off" autofocus="">
              <div class="photo">
                <ion-icon size="small" :icon="cameraOutline"></ion-icon>
              </div>
              <button class="send">
                <div class="circle">
                  <ion-icon :icon="sendOutline"></ion-icon>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </i-phone-preview>
</div>
</template>

<script>
import { sendOutline, cameraOutline, happyOutline, callOutline, attachOutline, ellipsisVerticalOutline, arrowBackOutline } from 'ionicons/icons';
import iPhonePreview from '/src/components/iPhonePreview';

export default {
  components: { iPhonePreview },
  data() {
    return {
      arrowBackOutline,
      ellipsisVerticalOutline,
      attachOutline,
      callOutline,
      happyOutline,
      cameraOutline,
      sendOutline
    }
  },
  props: {
    body: {
      type: Object,
      default: () => { return {}; }
    },
    allButtons: {
      type: Array,
      default: () => { return []; }
    },
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {}
}
</script>

<style scoped>
.view-whats-app-wrapper {
  padding-top: 0px;
}
.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgb(255 255 255 / 10%);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 13px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  text-align: left;
  margin: 0px 0px 0 7px;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

.user-bar .actions {
  float: right;
  margin: 0;
}

.user-bar .actions .more {
  margin: 0 12px 0 32px;
}

.user-bar .actions .attachment {
  margin: 0px -10px 0px 20px;
}

ion-button.templateAddButton {
  margin-top: 4px;
}

.user-bar ::after {
  content: "";
  display: table;
  clear: both;
}
.iphone-screen {
  background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
  background-color: #cccccc;
  height: 463px;
  width: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.chat {
  height: calc(100% - 69px);
  position: relative;
  top: -6px;
}

.chat-container {
  height: 100%;
}

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url(https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg) repeat;
  z-index: 0;
}

.conversation .conversation-container {
  height: calc(100% - 48px);
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .message--cont {
  color: #000;
  clear: both;
  line-height: 14px;
  font-size: 13px;
  padding: 8px;
  width: 100%;
  border: none;
  padding: 0;
  text-align: left;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.conversation .received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.conversation-container .message--cont {
  color: #000;
  clear: both;
  line-height: 14px;
  font-size: 13px;
  padding: 8px;
  width: 100%;
  border: none;
  text-align: left;
  position: relative;
  margin: 8px 0;
  word-wrap: break-word;
  z-index: -1;
}

.conversation-container .message--cont:first-child {
  margin: 16px 0 8px;
}

.metadata {
  display: inline-block;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.message--cont.received .metadata {
  padding: 0 0 0 16px;
  margin-top: 10px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.conversation-container .btn--cont {
  width: 100%;
  float: left;
  margin-top: 10px;
  border-top: 1px solid #eee;
  text-align: center;
}

.conversation-container .btn--cont .btn--forWhatsapp {
  background: #fff;
  font-size: 13px;
  padding: 6px 0;
  color: #049CE5;
}

.message--cont.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message--cont:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.message--cont .img--cont {
  text-align: center;
  background: #cccfd4;
  padding: 20px 10px;
}

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 40px;
  width: 100%;
  z-index: 2;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 40px;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 15px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 40px;
  padding-top: 10px;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 760px) {
  .prevMobile {
    display: block;
  }

  .prevDesktop {
    display: none;
  }
}
</style>