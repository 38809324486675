import { jwtDecrypt } from "../../shared/helper";
import { Preferences as Storage } from "@capacitor/preferences";
import axios from 'axios';
import jwtInterceptor from '../../shared/jwtInterceptor';
import { environment } from '/src/shared/env';

const state = () => ({
  authData: {
    token: undefined,
    refreshToken: undefined,
    authkey: undefined,
    tokenExp: undefined,
    userId: undefined,
    userName: undefined,
    email: undefined,
    userType: undefined
  },
  loginStatus: 'failed',
  multipleTokens: [],
  subscription: {}
});
 
const getters = {
  getLoginStatus(state){
    return state.loginStatus;
  },
  getAuthData(state){
    return state.authData;
  },
  getMultipleAccounts(state){
    return state.multipleTokens.map(e => { return {name: e.name, shop_logo: e.shop_logo, shop_name: e.shop_name, shop_link: e.shop_link}})
  },
  getJupiterSubscription(state) {
    return state.subscription;
  }
};
 
const actions = {
  async checkToken({ commit, dispatch }){
    try {
      let jupiterResponseData = await jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "jupiter-current-subscription");
      commit('setJupiterSubscription', jupiterResponseData?.data || {});

      let addUserResponseData = JSON.parse((await (Storage.get({key: 'jmpr_fetchAddUserData'}))).value || null);
      let socialResponseData = JSON.parse((await (Storage.get({key: 'jmpr_fetchSocialData'}))).value || null);
      let permissionsResponseData = JSON.parse((await (Storage.get({key: 'jmpr_getPermissionData'}))).value || null);
      let subscriptionResponseData = JSON.parse((await (Storage.get({key: 'jmpr_subscriptionData'}))).value || null);
      let roleDetailsResponseData = JSON.parse((await (Storage.get({key: 'jmpr_roleDetailsData'}))).value || null);
      let commerceResponseData = JSON.parse((await (Storage.get({key: 'jmpr_getCommerceData'}))).value || null);
      let paymentsResponseData = JSON.parse((await (Storage.get({ key: 'jmpr_fetchPaymentOptionsData' }))).value || null);
      let easyParcelResponseData = JSON.parse((await (Storage.get({ key: 'jmpr_easyParcel' }))).value || null);
      let grabResponseData = JSON.parse((await (Storage.get({ key: 'jmpr_grab' }))).value || null);
      let dialogFlowData;

      if(!addUserResponseData) var addUserResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "add-user");
      if(!socialResponseData) var socialResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "fetch-social");
      if(!permissionsResponseData) var permissionsResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "get-permission");
      if(!subscriptionResponseData) var subscriptionResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "subscription-detail");
      if(!roleDetailsResponseData) var roleDetailsResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "role-details");
      if(!commerceResponseData) var commerceResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "get-commerce");
      if(!paymentsResponseData) var paymentsResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "get-payment");
      if(!easyParcelResponseData) var easyParcelResponse = jwtInterceptor.get(environment('VUE_APP_ROOT_API') + "get-easyparcel");
      if(!grabResponseData) var grabResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "fetch-grab");
      if(!dialogFlowData) var dialogFlowResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "list-dialogflow");

      let allResponses = await Promise.all([addUserResponse, socialResponse, permissionsResponse, subscriptionResponse, roleDetailsResponse, commerceResponse, paymentsResponse, easyParcelResponse, grabResponse, dialogFlowResponse]);
      addUserResponse = allResponses[0];
      socialResponse = allResponses[1];
      permissionsResponse = allResponses[2];
      subscriptionResponse = allResponses[3];
      roleDetailsResponse = allResponses[4];
      commerceResponse = allResponses[5];
      paymentsResponse = allResponses[6];
      easyParcelResponse = allResponses[7];
      grabResponse = allResponses[8];
      dialogFlowResponse = allResponses[9];

      if (addUserResponse && (addUserResponse.status == 200 || addUserResponse.status == 201) && addUserResponse.data && addUserResponse.data.success) {
        addUserResponseData = addUserResponse.data;
        await Storage.set({
          key: "jmpr_fetchAddUserData",
          value: JSON.stringify(addUserResponseData),
        });
      }

      if(addUserResponseData) dispatch("user/saveUserData", addUserResponseData, { root: true });
      else {
        commit("saveLoginStatus", "failed");
        return;
      }

      if (socialResponse && (socialResponse.status == 200 || socialResponse.status == 201) && socialResponse.data && socialResponse.data.success) {
        socialResponseData = socialResponse.data;
        await Storage.set({
          key: "jmpr_fetchSocialData",
          value: JSON.stringify(socialResponseData),
        });
      }
      dispatch("social/saveSocialData", socialResponseData, { root: true });

      if (permissionsResponse && (permissionsResponse.status == 200 || permissionsResponse.status == 201) && permissionsResponse.data && permissionsResponse.data.success) {
        permissionsResponseData = permissionsResponse.data;
        await Storage.set({
          key: "jmpr_getPermissionData",
          value: JSON.stringify(permissionsResponseData),
        });
      }
      dispatch("user/savePermissionData", permissionsResponseData, { root: true });

      if (subscriptionResponse && (subscriptionResponse.status == 200 || subscriptionResponse.status == 201) && subscriptionResponse.data && subscriptionResponse.data.success) {
        subscriptionResponseData = subscriptionResponse.data;
        await Storage.set({
          key: "jmpr_subscriptionData",
          value: JSON.stringify(subscriptionResponseData),
        }); 
      }
      dispatch("user/saveSubscriptionData", subscriptionResponseData, { root: true });

      if (roleDetailsResponse && (roleDetailsResponse.status == 200 || roleDetailsResponse.status == 201) && roleDetailsResponse.data && roleDetailsResponse.data.success) {
        roleDetailsResponseData = roleDetailsResponse.data;
        await Storage.set({
          key: "jmpr_roleDetailsData",
          value: JSON.stringify(roleDetailsResponseData),
        });
      }
      dispatch("user/saveRoleDetailsData", roleDetailsResponseData, { root: true });

      if (commerceResponse && (commerceResponse.status == 200 || commerceResponse.status == 201) && commerceResponse.data && commerceResponse.data.success) {
        commerceResponseData = commerceResponse.data;
        await Storage.set({
          key: "jmpr_getCommerceData",
          value: JSON.stringify(commerceResponseData),
        });
      }
      dispatch("integration/saveIntegrationData", commerceResponseData, { root: true });

      if (paymentsResponse && (paymentsResponse.status == 200 || paymentsResponse.status == 201) && paymentsResponse.data && paymentsResponse.data.success) {
        paymentsResponseData = paymentsResponse.data;
        await Storage.set({
          key: "jmpr_fetchPaymentOptionsData",
          value: JSON.stringify(paymentsResponseData),
        });
      }
      dispatch("payment/savePaymentData", paymentsResponseData, { root: true });

      if (easyParcelResponse && (easyParcelResponse.status == 200 || easyParcelResponse.status == 201) && easyParcelResponse.data && easyParcelResponse.data.success) {
        easyParcelResponseData = easyParcelResponse.data;
        await Storage.set({
          key: "jmpr_easyParcel",
          value: JSON.stringify(easyParcelResponseData),
        });
      }
      if (easyParcelResponseData?.easyship?.length) {
        easyParcelResponseData['is_active'] = true;
      }
      const easyParcelDataToUpdate = {
        integrationName: 'easyparcel', dataToUpdate: easyParcelResponseData, storageName: 'jmpr_easyParcel'
      }
      dispatch("integration/updateIntegration", easyParcelDataToUpdate, { root: true });

      if (grabResponse && (grabResponse.status == 200 || grabResponse.status == 201) && grabResponse.data && grabResponse.data.success) {
        grabResponseData = grabResponse.data;
        await Storage.set({
          key: "jmpr_easyParcel",
          value: JSON.stringify(grabResponseData),
        });
      }
      if (grabResponseData?.grab) {
        grabResponseData['is_active'] = true;
      }
      const integrationDataToUpdate = {
        integrationName: 'grab', dataToUpdate: grabResponseData, storageName: 'jmpr_grab'
      }
      dispatch("integration/updateIntegration", integrationDataToUpdate, { root: true });

      if (dialogFlowResponse && (dialogFlowResponse.status == 200 || dialogFlowResponse.status == 201) && dialogFlowResponse.data && dialogFlowResponse.data.success) {
        dialogFlowData = dialogFlowResponse.data;
        // await Storage.set({
        //   key: "jmpr_easyParcel",
        //   value: JSON.stringify(dialogFlowData),
        // });
      }
      if (dialogFlowData?.email) {
        dialogFlowData['is_active'] = true;
      }
      const dialogFlowDataToUpdate = {
        integrationName: 'dialogflow', dataToUpdate: dialogFlowData
      }
      dispatch("integration/updateIntegration", dialogFlowDataToUpdate, { root: true });

      commit("saveLoginStatus", "success");
      
      /* if(getPlatforms().includes("android") || getPlatforms().includes("ios")) {
        
      } else {
        console.log("Skipping add-reg api for web");
      } */
      var jmpr_device_token = (await Storage.get({ key: "jmpr_device_token" })).value || undefined;
      var jmpr_device_token___currently_saved_token = (await Storage.get({ key: "jmpr_device_token___currently_saved_token" })).value || undefined;

      if(!jmpr_device_token___currently_saved_token && jmpr_device_token) {
        let jmpr_device_type = (await Storage.get({ key: "jmpr_device_type" })).value;
        let reqBody = {
          registration_id: jmpr_device_token,
          ios: jmpr_device_type === 'ios' ? true : undefined
        };
        
        let notificationResponse = await jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "add-registration", reqBody);

        if ((notificationResponse.status == 200 || notificationResponse.status == 201) && notificationResponse.data) {
          await Storage.remove({ key: "jmpr_device_token" });
          await Storage.set({ key: "jmpr_device_token___currently_saved_token", value: jmpr_device_token });
        } else {
          console.log("Error in reg for notifications", notificationResponse);
        }
      } else {
        console.log("Token already registered");
      }

    } catch (error) {
      console.log("Exception", error);
      commit("saveLoginStatus", "failed");
    }
  },
  async loginJupiterUser({ commit, dispatch, state }, payload) {
    try {
      let response = await axios.post(environment('VUE_APP_ROOT_API') + "get-merchant-jwt", { userid: payload.authkey, mobile_api: true });
      if ((response.status == 200 || response.status == 201) && response.data.tokens && response.data.tokens.length > 0) {
        if(response.data.tokens.length === 1) {
          response.data = response.data.tokens[0];
          await Storage.set({
            key: "access_token",
            value: response.data.access,
          });
          await Storage.set({
            key: "refresh_token",
            value: response.data.refresh,
          });
          await Storage.set({
            key: "authkey",
            value: response.data.authkey,
          });
          commit("saveAuthToken", response.data);
          await dispatch("checkToken");
        } else {
          commit("saveMultipleTokens", response.data.tokens);
          commit("saveLoginStatus", "halfway");
        }
      } else {
        commit("saveAuthToken", undefined);
        commit("saveLoginStatus", "failed");
      }
    } catch (error) {
     console.log("Exception", error);
     return false; 
    }
  },
  async loginAngularUser({ commit, dispatch, state }, payload) {
    try {
      let response = await axios.post(environment('VUE_APP_ROOT_API') + "get-merchant-jwt", { userid: payload.userid, token: payload.token, mobile_api: true });
      if ((response.status == 200 || response.status == 201) && response.data.tokens && response.data.tokens.length > 0) {
        if(response.data.tokens.length === 1) {
          response.data = response.data.tokens[0];
          await Storage.set({
            key: "access_token",
            value: response.data.access,
          });
          await Storage.set({
            key: "refresh_token",
            value: response.data.refresh,
          });
          await Storage.set({
            key: "authkey",
            value: response.data.authkey,
          });
          commit("saveAuthToken", response.data);
          await dispatch("checkToken");
        } else {
          commit("saveMultipleTokens", response.data.tokens);
          commit("saveLoginStatus", "halfway");
        }
      } else {
        commit("saveAuthToken", undefined);
        commit("saveLoginStatus", "failed");
      }
    } catch (error) {
     console.log("Exception", error);
     return false; 
    }
  },
  async loginUser({ commit, dispatch, state }, payload) {
    try {
      if(payload.halwayIndex > -1) {
        let thisAccount = state.multipleTokens[payload.halwayIndex];
        await Storage.set({
          key: "access_token",
          value: thisAccount.access,
        });
        await Storage.set({
          key: "refresh_token",
          value: thisAccount.refresh,
        });
        await Storage.set({
          key: "authkey",
          value: thisAccount.authkey,
        });
        commit("saveAuthToken", thisAccount);
        await dispatch("checkToken");
        commit("saveMultipleTokens", []);
      } else {
        let response = await axios.post(environment('VUE_APP_AUTH_API') + "token/", payload).catch((err) => {
          if (err?.response) {
            throw err?.response;
          }
          throw err;
        });
        if ((response.status == 200 || response.status == 201) && response.data.tokens && response.data.tokens.length > 0) {
          if(response.data.tokens.length === 1) {
            response.data = response.data.tokens[0];
            await Storage.set({
              key: "access_token",
              value: response.data.access,
            });
            await Storage.set({
              key: "refresh_token",
              value: response.data.refresh,
            });
            await Storage.set({
              key: "authkey",
              value: response.data.authkey,
            });
            commit("saveAuthToken", response.data);
            await dispatch("checkToken");
          } else {
            commit("saveMultipleTokens", response.data.tokens);
            commit("saveLoginStatus", "halfway");
          }
        } else {
          commit("saveAuthToken", undefined);
          commit("saveLoginStatus", "failed");
        }
        return response;
      }
    } catch (error) {
     console.log("Exception", error);
     return error;
    }
  },
  async loadStorageTokens({ commit, dispatch }) {
    const access_token = (await Storage.get({ key: "access_token" })).value;
    const refresh_token = (await Storage.get({ key: "refresh_token" })).value;
    const authkey = (await Storage.get({ key: "authkey" })).value;
    if (access_token && refresh_token) {
      const tokenData = {
        access: access_token,
        refresh: refresh_token,
        authkey: authkey
      };
      commit("saveAuthToken", tokenData);
      await dispatch("checkToken");
    } else {
      commit("saveAuthToken", undefined);
      commit("saveLoginStatus", "failed");
    }
  },
  async saveTokensToStorage({ commit }, payload){
    await Storage.set({
      key: "access_token",
      value: payload.access,
    });
    commit("saveAuthToken", payload);
  },
  async logoutUser({ commit }) {
    try {
      const logoutUserResponse = await jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "logout");
      if ((logoutUserResponse.status == 200 || logoutUserResponse.status == 201) && logoutUserResponse.data) {
        if(logoutUserResponse.data.success) {
          await Storage.set({
            key: "access_token",
            value: undefined,
          });
          await Storage.set({
            key: "refresh_token",
            value: undefined,
          });
          await Storage.set({
            key: "authkey",
            value: undefined,
          });
          await Storage.set({
            key: "user",
            value: null,
          });
          await Storage.set({
            key: "jmpr_device_token",
            value: null,
          });
          await Storage.set({
            key: "jmpr_device_token___currently_saved_token",
            value: null,
          });
          await Storage.set({
            key: "jmpr_device_type",
            value: null,
          });
          await Storage.set({
            key: "jmpr_redirect_url",
            value: null,
          });
          await Storage.set({
            key: "jmpr_fetchAddUserData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_fetchSocialData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_getPermissionData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_subscriptionData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_roleDetailsData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_getCommerceData",
            value: null,
          });
          await Storage.set({
            key: "jmpr_fetchPaymentOptionsData",
            value: null,
          });
          commit("saveAuthToken", undefined);
          commit("saveLoginStatus", "failed");
        } else {
          console.log("Error: ", logoutUserResponse.data.errorMessage);
        }
      } else {
        console.log("Oops! Something went wrong");
      }
    } catch (error) {
      console.log("Exception: ", error);
      console.log("Something went wrong, please try after some time");
    }
  }
};
 
const mutations = {
  setJupiterSubscription(state, subscription) {
    state.subscription = subscription;
  },
  saveAuthToken(state, payload) {
    if(payload) {
      const jwtDecodeUserInfo = jwtDecrypt(payload.access);
      if(jwtDecodeUserInfo) {
        const newAuthData = {
          token: payload.access,
          refreshToken: payload.refresh,
          authkey: payload.authkey,
          tokenExp: jwtDecodeUserInfo.exp,
          userId: jwtDecodeUserInfo.user_id,
          userName: jwtDecodeUserInfo.username,
          email: jwtDecodeUserInfo.username,
          userType: jwtDecodeUserInfo.userType
        };
        state.authData = newAuthData;
      } else {
        const newAuthData = {
          token: undefined,
          refreshToken: undefined,
          authkey: undefined,
          tokenExp: undefined,
          userId: undefined,
          userName: undefined,
          email: undefined,
          userType: undefined
        };
        state.authData = newAuthData;
      }
    } else {
      const newAuthData = {
        token: undefined,
        refreshToken: undefined,
        authkey: undefined,
        tokenExp: undefined,
        userId: undefined,
        userName: undefined,
        email: undefined,
        userType: undefined
      };
      state.authData = newAuthData;
    }
  },
  saveLoginStatus(state, status){
    state.loginStatus = status;
  },
  saveMultipleTokens(state, payload) {
    state.multipleTokens = payload;
  }
};
 
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};