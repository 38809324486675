<template>
<master-layout pageTitle="Password settings">
  <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
  <ion-col v-else>
    <ion-card>
      <ion-card-header>
        <ion-card-title>Change password</ion-card-title>
        <ion-card-subtitle>To ensure your account security, your password must have minimum 12 characters. 1 numeric, 1 capital case, 1 small case and a special character.</ion-card-subtitle>
      </ion-card-header>
      <ion-item lines="none">
        <ion-label position="stacked">Current Password</ion-label>
        <ion-input type="password" class="fullBordered" v-model="currPwd"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">New Password</ion-label>
        <ion-input type="password" class="fullBordered" v-model="newPwd"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Confirm new Password</ion-label>
        <ion-input type="password" class="fullBordered" v-model="confirmPwd"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <div class="passwordValidationChecksWithIconsArea" style="margin-top: 15px; text-align: left;">
          <div class="singleCharSetBox" :class="pwValidCheckCapitalChar ? 'success' : ''">
            A
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
          <div class="singleCharSetBox" :class="pwValidCheckSmallChar ? 'success' : ''">
            a
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
          <div class="singleCharSetBox" :class="pwValidCheckNumber ? 'success' : ''">
            1
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
          <div class="singleCharSetBox" :class="pwValidCheckSpecialChar ? 'success' : ''">
            @
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
          <div class="singleCharSetBox" :class="pwValidCheckLength ? 'success' : ''">
            <span>minimum 12 characters long</span>
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
          <div class="singleCharSetBox" :class="pwValidMatch ? 'success' : ''">
            <span>confirm password matched</span>
            <img src="https://jumper.ai/web-s/resources/images/green-checked-icon.svg">
          </div>
        </div>
      </ion-item>
      <ion-item lines="none">
        <ion-button slot="start" fill="solid" size="default" color="dark" @click="confirmChange" style="margin-top: 0px; margin-bottom: 16px;">
          Change password
        </ion-button>
      </ion-item>
    </ion-card>
    <ion-row>
      <ion-col size-md="8" size-lg="6">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Password settings</ion-card-title>
            <ion-card-subtitle>Manage settings related password reset for all team member accounts</ion-card-subtitle>
          </ion-card-header>
          <ion-item lines="none">
            <ion-label position="stacked">Passwords expire in</ion-label>
            <ion-input type="number" class="fullBordered" :max="180" :min="1" v-model="trigger_days" placeholder="Eg.: 30"></ion-input>
            <ion-text color="medium" style="font-size: 13px; padding-top: 5px;"><b>Note:</b> Enter a value in days. Max value = 180</ion-text>
          </ion-item>
          <ion-item lines="none">
            <ion-button fill="solid" size="default" color="dark" :disabled="busySave" @click="addPasswordSetup" slot="start" style="margin-top: 16px; margin-bottom: 16px;">
              {{busySave ? 'Saving...' : 'Save'}}
            </ion-button>
          </ion-item>
        </ion-card>

        <ion-card color="light" v-if="active !== undefined">
          <ion-item lines="none">
            <ion-label slot="start" v-if="active == true">Want to disable password expiry setting?</ion-label>
            <ion-label slot="start" v-else>Want to enable password expiry setting?</ion-label>
            
            <ion-button fill="outline" size="default" color="danger" :disabled="busySwitch" v-if="active == true" @click="() => switchSetupMode = true" slot="end" style="margin-top: 16px; margin-bottom: 16px;">{{busySwitch ? 'Disabling...' : 'Disable'}}</ion-button>
            <ion-button fill="outline" size="default" color="dark" :disabled="busySwitch" v-else @click="() => switchSetupMode = true" slot="end" style="margin-top: 16px; margin-bottom: 16px;">{{busySwitch ? 'Enabling...' : 'Enable'}}</ion-button>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-alert
    v-if="switchSetupMode"
    :is-open="switchSetupMode"
    header="Are you sure"
    :message="'you want to '+(active ? 'disabled' : 'enabled')+' the password expiry setting?'"
    :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => switchSetupMode = false,
    },
    {
      text: 'Yes',
      handler: () => {
        confirmSwitch()
      },
    }]"
    @didDismiss="() => switchSetupMode = false"
  >
  </ion-alert>
</master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
export default {
  data() {
    return {
      trigger_days: undefined,
      active: undefined,
      switchSetupMode: false,
      loading: true,
      busySave: false,
      busySwitch: false,
      currPwd: undefined,
      newPwd: undefined,
      confirmPwd: undefined,
      pwValidCheckLength: false,
      pwValidCheckSmallChar: false,
      pwValidCheckCapitalChar: false,
      pwValidCheckNumber: false,
      pwValidCheckSpecialChar: false
    }
  },
  ionViewWillEnter(){
    this.initView();
  },
  computed: {
    pwValidMatch(){
      return this.newPwd && this.newPwd.length && this.newPwd === this.confirmPwd;
    }
  },
  watch: {
    newPwd(){
      this.passwordValidationChecks();
    },
  },
  methods: {
    async initView() {
      try {
        let setupResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "show-password-setup");
        if((setupResponse.status === 200 || setupResponse.status === 201) && setupResponse.data) {
          if(setupResponse.data.success) {
            this.active = setupResponse.data.active;
            this.trigger_days = "";

            if(typeof setupResponse.data.trigger_days != 'undefined'){
              if(setupResponse.data.trigger_days != '' && setupResponse.data.trigger_days != 'None' && setupResponse.data.trigger_days != null){
                this.trigger_days = parseInt(setupResponse.data.trigger_days);

                if(this.trigger_days < 0){
                  this.trigger_days = 0;
                }
              }
            }
          } else {
            this.updateToast({
              open: true,
              message: setupResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async confirmSwitch() {
      try {
        this.busySwitch = true;
        let confirmSwitchResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "switch-password-setup");

        if ((confirmSwitchResponse.status == 200 || confirmSwitchResponse.status == 201) && confirmSwitchResponse.data) {
          console.log(confirmSwitchResponse.data);
          if(confirmSwitchResponse.data.success) {
            this.active = !this.active;
            this.updateToast({
              open: true,
              message: (this.active ? "Enabled" : "Disabled") + " successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: confirmSwitchResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", confirmSwitchResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.switchSetupMode = false;
        this.busySwitch = false;
      }
    },
    async addPasswordSetup() {
      try {
        this.busySave = true;
        let reqBody = {
          trigger_days: this.trigger_days
        }
        let addPasswordSetupResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-password-setup", reqBody);

        if ((addPasswordSetupResponse.status == 200 || addPasswordSetupResponse.status == 201) && addPasswordSetupResponse.data) {
          console.log(addPasswordSetupResponse.data);
          if(addPasswordSetupResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Settings saved successfully!",
              type: "success"
            })
          } else {
            this.updateToast({
              open: true,
              message: addPasswordSetupResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", addPasswordSetupResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.busySave = false;
      }
    },
    async confirmChange() {
      try {
        if(!this.pwValidCheckLength || !this.pwValidCheckSmallChar || !this.pwValidCheckCapitalChar || !this.pwValidCheckNumber || !this.pwValidCheckSpecialChar) {
          this.updateToast({
            open: true,
            message: "Please fulfill the new password validations",
            type: "warning"
          })
          return
        }
        if(this.pwValidMatch) {
          let reqBody = {
            old_password: this.currPwd,
            password: this.confirmPwd
          }
          let changePwdResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "change-password", reqBody);
          if ((changePwdResponse.status == 200 || changePwdResponse.status == 201) && changePwdResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Your account password has been changed successfully!",
              type: "success"
            })
            this.$router.replace("/");
          } else {
            if(changePwdResponse.data.errorMessage && changePwdResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: changePwdResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } else {
          this.updateToast({
            open: true,
            message: "The new password and confirm password do not match",
            type: "warning"
          })
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      }
    },
    passwordValidationChecks(passwordMinLength = 12) {
      let password = this.newPwd;
      password.length >= passwordMinLength ? this.pwValidCheckLength = true : this.pwValidCheckLength = false;
      password.match(/[a-z]/) ? this.pwValidCheckSmallChar = true : this.pwValidCheckSmallChar = false;
      password.match(/[A-Z]/) ? this.pwValidCheckCapitalChar = true : this.pwValidCheckCapitalChar = false;
      password.match(/\d/) ? this.pwValidCheckNumber = true : this.pwValidCheckNumber = false;
      //eslint-disable-next-line
      password.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/) ? this.pwValidCheckSpecialChar = true : this.pwValidCheckSpecialChar = false;
    }
  }
}
</script>

<style scoped>
button:disabled {
  opacity: 0.5 !important;
}
input {
  outline: none;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  width: 100%;
}

.saveRow {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
  padding: 10px 0;
}

.saveRow > button.primary {
  background-color: #881fff;
  color: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* text-transform: capitalize; */
  opacity: 1;
  /* white-space: pre-wrap; */
  cursor: pointer;
  outline: none !important;
}
.dangerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  margin: 10px 0;
  padding: 10px 0;
}
.dangerRow > button.danger {
  border: 1px #fb404b solid;
  color: #fb404b;
  padding: 7px 20px;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* text-transform: capitalize; */
  opacity: 1;
  /* white-space: pre-wrap; */
  cursor: pointer;
  outline: none !important;
}
.passwordValidationChecksWithIconsArea{
  text-align: right;
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.passwordValidationChecksWithIconsArea .singleCharSetBox{
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 11px;
  padding: 5px;
  line-height: 1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffd4d4;
  color: #ff0000;
  min-width: 21px;
  cursor: default;
  overflow: visible;
  position: relative;
}

.passwordValidationChecksWithIconsArea .singleCharSetBox.success{
  background-color: #eafdf2;
  color: #099e6e;
}

.passwordValidationChecksWithIconsArea .singleCharSetBox img{
  display: none;
}

.passwordValidationChecksWithIconsArea .singleCharSetBox.success img{
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>