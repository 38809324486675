<template>
  <master-layout pageTitle="Live Chat">
    <ion-content class="hide-xs page-inbox-container">
      <ion-grid class="ion-no-padding ion-no-margin grid-container">
        <ion-row class="ion-no-padding ion-no-margin inbox">
          <ion-col class="ion-no-padding ion-no-margin home">
            <live-chat-home :active="activeChatId" @customer="changeActiveChat" @onFilterCustomers="goToHome" @onClearFilter="goToHome" />
          </ion-col>
          <ion-col id="chat_container" class="ion-no-padding ion-no-margin chat" >
            <single-customer-chats @home="goToHome" :conversationid="activeChatId" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-content class="show-xs">
      <ion-grid class="ion-no-padding ion-no-margin grid-container">
        <ion-row class="ion-no-padding ion-no-margin inbox">
          <ion-col v-if="active === 'home'" class="ion-no-padding ion-no-margin home" >
            <live-chat-home :active="activeChatId" @customer="changeActiveChat" @onFilterCustomers="goToHome" @onClearFilter="goToHome" />
          </ion-col>
          <ion-col v-else id="chat_container" class="ion-no-padding ion-no-margin chat" >
            <single-customer-chats @home="goToHome" :conversationid="activeChatId" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </master-layout>
</template>

<script>
import LiveChatHome from "./LiveChatHome.vue";
import SingleCustomerChats from "./SingleCustomerChats.vue";

export default {
  components: { LiveChatHome, SingleCustomerChats },
  data() {
    return {
      active: "home",
      activeChatId: undefined,
    };
  },
  beforeCreate() {
    if (this.$route.query && this.$route.query.conversation)
      this.activeChatId = this.$route.query.conversation;
  },
  mounted() {
    if (this.$route.query && this.$route.query.conversation)
      this.activeChatId = this.$route.query.conversation;
  },
  watch: {
    activeChatId() {
      if (this.activeChatId) this.active = "chat";
      else this.active = "home";
    },
  },
  methods: {
    changeActiveChat(customer) {
      this.activeChatId = customer;
      this.$router.replace({ path: '/page-inbox', query: { conversation: this.activeChatId } });
    },
    goToHome() {
      this.activeChatId = undefined;
      this.$router.replace({ path: '/page-inbox' });
    },
  },
  async beforeRouteLeave() {
    await this.Storage.remove({ key: "jmpr_role-list" });
    await this.Storage.remove({ key: "jmpr_fetch-shortcut" });
    await this.Storage.remove({ key: "jmpr_fetch-business-role" });
    await this.Storage.remove({ key: "jmpr_whatsapp-message-template" });
    await this.Storage.remove({ key: "jmpr_whatsapp-list-uploaded-document" });
    await this.Storage.remove({ key: "jmpr_notification-template-fetch" });
  }
};
</script>

<style scoped>
.inbox {
  height: 100%;
}
.home {
  flex: 0 0 30%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}
.chat {
  flex: 0 0 70%;
  height: 100%;
  overflow-y: auto;
}
@media only screen and (max-width: 780px) {
  .home {
    flex: 0 0 100%;
  }
  .chat {
    flex: 0 0 100%;
  }
}
.grid-container {
  height: 100%;
}
</style>