<template>
  <ion-app>
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    ></ion-loading>

    <div v-if="showIntro" class="intro-overlay">
      <Intro @finish="() => { showIntro = false; Storage.set({ key: 'showIntro', value: false }); }" :steps="steps"></Intro>
    </div>
    <ion-split-pane class="main-content" content-id="main-content" disabled="true">
      <ion-menu id="app-menu" v-show="loginStatus === 'success'" content-id="main-content" type="overlay">
        <ion-list id="inbox-list" style="padding-top: 0px; cursor: pointer">
          <!-- <ion-list-header style="background-color: var(--ion-color-light); padding: 15px 15px 15px 7px; border-radius: 4px; margin-bottom: 20px;">
            <ion-thumbnail style="width: auto; height: 40px;">
              <img src="https://jumper.ai/landing/resources/images/logo-light-bg-length-wise.svg" style="max-width: 100%; width: auto; height: 40px;">
            </ion-thumbnail>
          </ion-list-header> -->

          <ion-list-header class="outer-list" style="margin-top: 0px;">
            <ion-thumbnail style="width: 40px; height: 40px;">
              <img :src="getShopLogoImage" style="width: 100%; height: 40px;">
            </ion-thumbnail>
            <ion-label style="padding-left: 8px; font-size: 18px;">{{ user.shopName }}</ion-label>
          </ion-list-header>
          <div class="complete-list">
            <div class="inner-list">
              <ion-menu-toggle>
                <ion-item @click="() => expand = undefined" router-link="/dashboard" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/dashboard' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="homeOutline"></ion-icon>
                  <ion-label>Dashboard</ion-label>
                </ion-item>
                <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.page_inbox)" :class="{ disabled: permissions.livechat == false }" @click="menuClickHandler('livechat', '/page-inbox')" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('/page-inbox') ? 'dark' : ''">
                  <ion-icon slot="start" :icon="chatbubblesOutline"></ion-icon>
                  <ion-label>Live chat</ion-label>
                  <span v-if="permissions.livechat == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle>
                <ion-item v-if="IS_ASIA && (isAdmin || oldPermissions.broadcast)" :class="{ disabled: permissions.broadcast == false }" @click="menuClickHandler('broadcast', '/automations/broadcast')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/broadcast' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="megaphoneOutline"></ion-icon>
                  <ion-label>Broadcast</ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.ai_setup || oldPermissions.bot_builder || oldPermissions.broadcast || oldPermissions.cart_abandonment || oldPermissions.personalisation)" @click="() => expand = 'automations'" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('automations') ? 'light' : ''" style="cursor: pointer">
                <ion-icon slot="start" :icon="cogOutline"></ion-icon>
                <ion-label>Automations</ion-label>
              </ion-item>
              <ion-menu-toggle v-if="!IS_ASIA">
                <div style="margin-left: 55px;" v-if="thisRouterPath.includes('automations') || expand === 'automations'">
                  <ion-item v-if="isAdmin || oldPermissions.ai_setup" :class="{ disabled: permissions.automatedResponses == false }" @click="menuClickHandler('automatedResponses', '/automations/automate-responses')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/automate-responses' ? 'dark' : ''">
                    <ion-label>Automate responses</ion-label>
                    <span v-if="permissions.automatedResponses == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.bot_builder" :class="{ disabled: permissions.botBuilder == false }" @click="menuClickHandler('botBuilder', '/automations/bot-builder')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/bot-builder' ? 'dark' : ''">
                    <ion-label>Bot builder</ion-label>
                    <span v-if="permissions.botBuilder == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.broadcast" :class="{ disabled: permissions.broadcast == false }" @click="menuClickHandler('broadcast', '/automations/broadcast')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/broadcast' ? 'dark' : ''">
                    <ion-label>Broadcast</ion-label>
                    <span v-if="permissions.broadcast == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.cart_abandonment" :class="{ disabled: permissions.cartRecovery == false }" @click="menuClickHandler('cartRecovery', '/automations/cart-recovery')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/cart-recovery' ? 'dark' : ''">
                    <ion-label>Cart recovery</ion-label>
                    <span v-if="permissions.cartRecovery == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.personalisation" :class="{ disabled: permissions.personalization == false }" @click="menuClickHandler('personalization', '/automations/personalization')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/automations/personalization' ? 'dark' : ''">
                    <ion-label>Default templates</ion-label>
                    <span v-if="permissions.personalization == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                </div>
              </ion-menu-toggle>
              <ion-item v-if="!IS_ASIA" @click="() => expand = 'orders'" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('orders') ? 'light' : ''" style="cursor: pointer">
                <ion-icon slot="start" :icon="listOutline"></ion-icon>
                <ion-label>Orders</ion-label>
              </ion-item>
              <ion-menu-toggle v-if="!IS_ASIA">
                <div style="margin-left: 55px;" v-if="thisRouterPath.includes('orders') || expand === 'orders'">
                  <ion-item v-if="isAdmin || oldPermissions.orders.view" :class="{ disabled: permissions.manageOrders == false }" @click="menuClickHandler('createOrder', '/orders')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/orders' ? 'dark' : ''">
                    <ion-label>View orders</ion-label>
                    <span v-if="permissions.manageOrders == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.checkout_direct" :class="{ disabled: permissions.createOrder == false }" @click="menuClickHandler('createOrder', '/orders/create')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/orders/create' ? 'dark' : ''">
                    <ion-label>Create order</ion-label>
                    <span v-if="permissions.createOrder == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <!-- <ion-item router-link="/orders/restaurant" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/orders/restaurant' ? 'dark' : ''">
                    <ion-label>F&B orders</ion-label>
                  </ion-item> -->
                </div>
              </ion-menu-toggle>
              <ion-item v-if="isAdmin || oldPermissions.customers" @click="() => expand = 'customers'" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('customers') ? 'light' : ''" style="cursor: pointer">
                <ion-icon slot="start" :icon="peopleOutline"></ion-icon>
                <ion-label>Customers</ion-label>
              </ion-item>
              <ion-menu-toggle>
                <div style="margin-left: 55px;" v-if="thisRouterPath.includes('customers') || expand === 'customers'">
                  <ion-item v-if="isAdmin || oldPermissions.customers" :class="{ disabled: permissions.manageCustomers == false }" @click="menuClickHandler('manageCustomers', '/customers')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/customers' ? 'dark' : ''">
                    <ion-label>Customers</ion-label>
                    <span v-if="permissions.manageCustomers == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.customers" :class="{ disabled: permissions.segments == false }" @click="menuClickHandler('segments', '/customers/segments')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/customers/segments' ? 'dark' : ''">
                    <ion-label>Segments</ion-label>
                    <span v-if="permissions.segments == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                </div>
              </ion-menu-toggle>
              <ion-item v-if="salesChannelMenuToShow.length > 0" @click="() => expand = 'sales-channel'" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('sales-channel') ? 'light' : ''" style="cursor: pointer">
                <ion-icon slot="start" :icon="shareSocialOutline"></ion-icon>
                <ion-label>Sales channel</ion-label>
              </ion-item>
              <ion-menu-toggle>
                <div style="margin-left: 55px;" v-if="thisRouterPath.includes('sales-channel') || expand === 'sales-channel'">
                  <template v-for="(channel, index) in salesChannelMenuToShow" :key="index">
                    <ion-item :class="{ disabled: permissions[channel.key] == false }" @click="menuClickHandler(channel.key, channel.route)" lines="none" detail="false" class="hydrated" :color="thisRouterPath === channel.route ? 'dark' : ''">
                      <ion-label>
                        <div class="tooltip_container">
                          <span class="sales-channel-title">{{ channel.title }}</span>
                          <!-- <span class="tooltip_text left" style="width: 80px">{{ channel.title }}</span> -> To be fixed: Sachin -->
                        </div>
                      </ion-label>
                      <span v-if="permissions[channel.key] == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                    </ion-item>
                  </template>
                </div>
              </ion-menu-toggle>
              <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.analytics)" @click="() => expand = 'analytics'" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('analytics') ? 'light' : ''" style="cursor: pointer">
                <ion-icon slot="start" :icon="analyticsOutline"></ion-icon>
                <ion-label>Analytics</ion-label>
              </ion-item>
              <ion-menu-toggle>
                <div style="margin-left: 55px;" v-if="!IS_ASIA && (thisRouterPath.includes('analytics') || expand === 'analytics')">
                  <ion-item :class="{ disabled: permissions.storeAnalytics == false }" @click="menuClickHandler('storeAnalytics', '/analytics/store')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/store' ? 'dark' : ''">
                    <ion-label>Store</ion-label>
                    <span v-if="permissions.storeAnalytics == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item :class="{ disabled: permissions.insights == false }" @click="menuClickHandler('store', '/analytics/insights')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/insights' ? 'dark' : ''">
                    <ion-label>Insights</ion-label>
                    <span v-if="permissions.insights == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item router-link="/analytics/survey" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/survey' ? 'dark' : ''" v-if="user.enterprise_Name == 'greenwich_ph'">
                    <ion-label>Survey</ion-label>
                  </ion-item>
                  <ion-item router-link="/analytics/y2020" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/y2020' ? 'dark' : ''" v-if="redRibbon_ph_manila == true || loreal_my_org == true">
                    <ion-label>2020 analytics</ion-label>
                  </ion-item>
                  <ion-item router-link="/analytics/data-signals-bcg" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/data-signals-bcg' ? 'dark' : ''" v-if="user.enterprise_Name == 'greenwich_ph'">
                    <ion-label>Data signals</ion-label>
                  </ion-item>
                  <ion-item :class="{ disabled: permissions.baReport == false }" @click="menuClickHandler('baReport', '/analytics/team')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/team' ? 'dark' : ''">
                    <ion-label v-if="loreal_my_family_user == true">BA report</ion-label>
                    <ion-label v-else>BA report (Live chat)</ion-label>
                    <span v-if="permissions.baReport == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <!-- <ion-item router-link="/analytics/ad-performance" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/analytics/ad-performance' ? 'dark' : ''" v-if="loreal_my_org == true">
                    <ion-label>Ad performance</ion-label>
                  </ion-item> -->
                </div>
                <ion-item v-if="!IS_ASIA && (isAdmin || (oldPermissions.products.view || oldPermissions.collections.view || oldPermissions.bundles.view  || oldPermissions.discounts) || isStorePageVisibleForEnterpriseAccount)" :class="{ disabled: isStorePageDisabled }" @click="menuClickHandler('store', '/store')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/store' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="storefrontOutline"></ion-icon>
                  <ion-label>Store</ion-label>
                  <span v-if="isStorePageDisabled" class="upgrade-url" router-link="/plans">Upgrade</span>
                </ion-item>
                <ion-item v-if="(isAdmin || oldPermissions.manage_collect_locations) && isAndroidOrIOS" router-link="/scanner" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/scanner' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="scanCircleOutline"></ion-icon>
                  <ion-label>Scan QR</ion-label>
                </ion-item>
                <ion-item @click="() => expand = undefined" router-link="/settings" lines="none" detail="false" class="hydrated" :color="thisRouterPath.includes('/settings') ? 'light' : ''">
                  <ion-icon slot="start" :icon="settingsOutline"></ion-icon>
                  <ion-label>Settings</ion-label>
                </ion-item>
                <div style="margin-left: 55px;" v-if="thisRouterPath.includes('/settings')">
                  <ion-item v-if="isAdmin || oldPermissions.business_details" router-link="/settings/account" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/account' ? 'dark' : ''">
                    <ion-label>Business profile</ion-label>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA" router-link="/settings/integrations" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/integrations' ? 'dark' : ''">
                    <ion-label>Integrations</ion-label>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.payment_methods)" :class="{ disabled: permissions.managePayments == false }" @click="menuClickHandler('managePayments', '/settings/payments')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/payments' ? 'dark' : ''">
                    <ion-label>Payments</ion-label>
                    <span v-if="permissions.managePayments == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.shipping)" :class="{ disabled: permissions.taxesAndShipping == false }" @click="menuClickHandler('taxesAndShipping', '/settings/tax-and-shipping')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/tax-and-shipping' ? 'dark' : ''">
                    <ion-label>Tax and shipping</ion-label>
                    <span v-if="permissions.taxesAndShipping == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="isAdmin || oldPermissions.roles_management" :class="{ disabled: permissions.manageTeamMembers == false }" @click="menuClickHandler('manageTeamMembers', '/settings/team')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/team' ? 'dark' : ''">
                    <ion-label>Team members</ion-label>
                    <span v-if="permissions.manageTeamMembers == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA" :class="{ disabled: permissions.liveChatSettings == false }" @click="menuClickHandler('liveChatSettings', '/settings/live-chat')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/live-chat' ? 'dark' : ''">
                    <ion-label>Live chat settings</ion-label>
                    <span v-if="permissions.liveChatSettings == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.manage_collect_locations)" router-link="/settings/store-locations" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/store-locations' ? 'dark' : ''">
                    <ion-label>Store locations</ion-label>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA && (isAdmin || oldPermissions.policies)" router-link="/settings/policies" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/policies' ? 'dark' : ''">
                    <ion-label>Policies and consent</ion-label>
                  </ion-item>
                  <ion-item v-if="!IS_ASIA" :class="{ disabled: permissions.passwordSettings == false }" @click="menuClickHandler('passwordSettings', '/settings/password')" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/settings/password' ? 'dark' : ''">
                    <ion-label>Password settings</ion-label>
                    <span v-if="permissions.passwordSettings == false" class="upgrade-url" router-link="/plans">Upgrade</span>
                  </ion-item>
                </div>
                <ion-item v-if="!IS_ASIA" @click="() => expand = undefined" router-link="/account/edit-profile" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/account/edit-profile' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="personCircleOutline"></ion-icon>
                  <ion-label>Edit profile</ion-label>
                </ion-item>
                <ion-item v-if="!IS_ASIA" @click="() => expand = undefined" router-link="/account/login-activities" lines="none" detail="false" class="hydrated" :color="thisRouterPath === '/account/login-activities' ? 'dark' : ''">
                  <ion-icon slot="start" :icon="logInOutline"></ion-icon>
                  <ion-label>Login activities</ion-label>
                </ion-item>
                <ion-item lines="none" detail="false" class="hydrated" @click="logout">
                  <ion-icon slot="start" :icon="logOutOutline"></ion-icon>
                  <ion-label>Logout</ion-label>
                </ion-item>
              </ion-menu-toggle>
              <!-- <a class="menu_link" href="https://teamjumper.crunch.help/" target="_blank">
                <ion-item lines="none" detail="false" class="hydrated" style="cursor: pointer">
                  <ion-icon slot="start" :icon="helpCircleOutline"></ion-icon>
                  <ion-label>Helpdesk</ion-label>
                </ion-item>
              </a> -->
            </div>
            <div v-if="!IS_ASIA" id="old-dashboard" class="dark-mode" :value="false">
              <ion-item lines="none" detail="false" class="hydrated" @click="() => { feedbackForm = true }">
                <div class="title">Switch to old Dashboard</div>
              </ion-item>
            </div>
            <div id="dark-mode" class="dark-mode">
              <ion-item lines="none" detail="false" class="hydrated">
                <ion-label slot="start">
                  <div class="title">Dark mode</div>
                  <div class="version">v {{ version }}</div>
                </ion-label>
                <ion-toggle slot="end" v-model="isDarkMode"></ion-toggle>
              </ion-item>
            </div>
          </div>
        </ion-list>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    <ion-toast
      :is-open="toast.open"
      :message="toast.message"
      :duration="toast.duration"
      :color="toast.type"
    >
    </ion-toast>
    <ion-modal
      v-if="feedbackForm"
      :is-open="feedbackForm"
      @didDismiss="feedbackForm = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-title>Please provide us your valuable feedback</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="feedbackForm = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="rating-modal-content-wrapper">
          <ion-list class="ion-no-padding">
            <ion-item lines="none">
              <div class="stars-container">
                <span>Rating: </span>
                <div class="stars-wrapper">
                  <ion-icon v-for="(n, index) in 5" slot="start" :icon="feedback.rating >= n ? star : starOutline" :key="index" @click="() => { feedback.rating = n }" size="medium"></ion-icon>
                </div>
              </div>
            </ion-item>
            <ion-item lines="none">
              <my-input
                :textarea="true"
                :rows="10"
                :textAreaAutoHeight="true"
                id="feedbackMessage"
                name="feedbackMessage"
                :value="feedback.message"
                @onInput="(prop) => feedback.message = prop"
                placeholder="Type your feedback here"
                :maxlength="500"
              />
            </ion-item>
            <ion-item lines="none">
              <div class="screenshot-upload-wrapper">
                <div class="select-screenshot-row">
                  <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
                  <ion-button :disabled="feedback.screenshots.length >= 5" @click="() => $refs.productImage.click()" expand="block" fill="outline">Upload screenshots</ion-button>
                </div>

                <template v-if="feedback.screenshots.length">
                  <div v-for="(url, index) in feedback.screenshots" :key="index" class="screenshot-urls">
                    <a :href="url" target="_blank">{{url}}</a>
                    <ion-icon slot="start" :icon="closeCircle" @click="() => { feedback.screenshots.splice(index, 1) }" size="medium"></ion-icon>
                  </div>
                </template>
              </div>
            </ion-item>
          </ion-list>
        </ion-content>

        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="submitFeedback" :disabled="isDisabledFeedbackButton" >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
  </ion-app>
</template>

<script>
import { IonApp, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonToast, useBackButton, useIonRouter, getPlatforms, IonToggle } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, homeOutline, chatbubblesOutline, personCircleOutline, shieldCheckmarkOutline, logOutOutline, scanCircleOutline, peopleOutline, settingsOutline, storefrontOutline, listOutline, restaurantOutline, receiptOutline, peopleCircleOutline, cogOutline, shareSocialOutline, logInOutline, analyticsOutline/* , helpCircleOutline */, star, starOutline, closeCircle, close, megaphoneOutline } from 'ionicons/icons';
import { mapGetters, mapActions, mapState } from "vuex";
import { App } from '@capacitor/app';
import {version} from '/package.json';
import Intro from "/src/components/Intro.vue";
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, IonToast, IonToggle, Intro
  },
  async mounted(){
    if(this.$route.path.includes("login") || this.IS_ASIA) this.showIntro = false;
    else {
      var showIntro = JSON.parse((await (this.Storage.get({key: 'showIntro'}))).value);
      if(showIntro === false) this.showIntro = false;
      else this.showIntro = true;
    }
    var elem = document.getElementById("app-menu");
    if( !this.IS_ASIA ) {
      window.setInterval( function() {   
        let className = elem.className;
        if(className.includes('show-menu')) document.getElementById("google_translate_element").style.display = "block";
        else document.getElementById("google_translate_element").style.display = "none";
      }, 10);
    } else {
      document.getElementById("google_translate_element").remove();
    }

    if(this.user.referallshopName) this.initFeaturesBasedOnPermission();

    let isDarkMode = JSON.parse((await (this.Storage.get({key: 'jmpr_isDarkMode'}))).value);
    
    if(isDarkMode == true || isDarkMode == false) this.updateDarkMode(isDarkMode);
    else window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? this.updateDarkMode(true) : this.updateDarkMode(false);

    await this.Storage.set({
      key: "jmpr_isDarkMode",
      value: this.isDarkMode,
    });
    
    if(this.isDarkMode) {
      document.body.classList.toggle('dark', true);
      document.body.classList.toggle('light', false);
    } else {
      document.body.classList.toggle('dark', false);
      document.body.classList.toggle('light', true);
    }
    
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async event => {
      event.matches ? this.updateDarkMode(true) : this.updateDarkMode(false);
      await this.Storage.set({
        key: "jmpr_isDarkMode",
        value: this.isDarkMode,
      });
    })
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', async event => {
      event.matches ? this.updateDarkMode(false) : this.updateDarkMode(true);
      await this.Storage.set({
        key: "jmpr_isDarkMode",
        value: this.isDarkMode,
      });
    })

    document.addEventListener('restartIntro', () => {
      this.showIntro = true;
    })
    this.initSteps();
  },
  setup() {
    const route = useRoute();
    const ionRouter = useIonRouter();

    useBackButton(-1, () => {
      console.log("Back button pressed");
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      } else {
        //automatically goes one step back
        //this.$router.go(-1);
      }
    });
    return {
      isSelected: url => url === route.path ? 'selected' : ''
    }
  },
  data() {
    return {
      feedbackForm: false,
      feedback: {
        rating: "3",
        message: undefined,
        screenshots: []
      },
      showIntro: false,
      steps: [],
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp,
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      homeOutline,
      chatbubblesOutline,
      personCircleOutline,
      peopleOutline,
      settingsOutline,
      shieldCheckmarkOutline,
      logOutOutline,
      scanCircleOutline,
      storefrontOutline,
      listOutline,
      restaurantOutline,
      receiptOutline,
      peopleCircleOutline,
      cogOutline,
      shareSocialOutline,
      logInOutline,
      analyticsOutline,
      /* helpCircleOutline, */
      star,
      starOutline,
      close,
      closeCircle,
      megaphoneOutline,
      expand: undefined,
      version: version,
      salesChannelMenuAsia: [
        {
          key: 'line',
          title: 'LINE',
          route: '/sales-channel/line'
        },
        {
          key: 'sms',
          title: 'SMS',
          route: '/sales-channel/sms'
        },
        {
          key: 'telegram',
          title: 'Telegram',
          route: '/sales-channel/telegram'
        }
      ],
      salesChannelMenu: [
        {
          key: 'facebook',
          title: 'Facebook',
          route: '/sales-channel/facebook'
        },
        {
          key: 'instagram',
          title: 'Instagram',
          route: '/sales-channel/instagram'
        },
        {
          key: 'whatsapp',
          title: 'WhatsApp',
          route: '/sales-channel/whatsapp'
        },
        {
          key: 'line',
          title: 'LINE',
          route: '/sales-channel/line'
        },
        {
          key: 'web',
          title: 'Web',
          route: '/sales-channel/web'
        },
        {
          key: 'imessage',
          title: 'iMessage',
          route: '/sales-channel/imessage'
        },
        {
          key: 'viber',
          title: 'Viber',
          route: '/sales-channel/viber'
        },
        {
          key: 'telegram',
          title: 'Telegram',
          route: '/sales-channel/telegram'
        },
        {
          key: 'sms',
          title: 'SMS',
          route: '/sales-channel/sms'
        },
        {
          key: 'googlebusinessmsg',
          title: 'GMB(Google business messages)',
          route: '/sales-channel/google-business-messages'
        }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      loginStatus: "auth/getLoginStatus",
      toast: "getToast",
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
    ...mapState({
      isEnterprise: (state) => state.user.enterprise,
      authKey: (state) => state.auth.authData.authkey
    }),
    permissions() {
      return this.jupiter?.permissions || {};
    },
    salesChannelMenuToShow() {
      let menu = this.IS_ASIA ? this.salesChannelMenuAsia : this.salesChannelMenu;
      return this.isAdmin ? menu : menu.filter(e => this.oldPermissions?.integrations[e.key]); 
    },
    isDarkMode: {
      get() {
        return this.$store.state.darkMode;
      },
      async set(value) {
        this.$store.dispatch('updateDarkMode', value);
        if(value) {
          document.body.classList.toggle('dark', true);
          document.body.classList.toggle('light', false);
        } else {
          document.body.classList.toggle('dark', false);
          document.body.classList.toggle('light', true);
        }
        await this.Storage.set({
          key: "jmpr_isDarkMode",
          value: !!value,
        });
      }
    },
    thisRouterPath() {
      return this.$route.path;
    },
    isAndroidOrIOS() {
      return getPlatforms().includes("android") || getPlatforms().includes("ios");
    },
    isStorePageDisabled() {
      return this.permissions.product == false && this.permissions.set == false && this.permissions.collection == false && this.permissions.discount == false;
    },
    isStorePageVisibleForEnterpriseAccount() {
      if (this.isEnterprise && this.isStorePageDisabled) {
        return false;
      }
      return true;
    },
    isDisabledFeedbackButton() {
      return !this.feedback.message?.trim() || this.feedback.message.trim()?.length < 2;
    }
  },
  methods: {
    ...mapActions({
      logoutUser: "auth/logoutUser",
      updateDarkMode: "updateDarkMode"
    }),
    initSteps() {
      this.steps = [
        {
          title: "Welcome to our new Dashboard 🤩",
          description: "I am Jumpman to assist you with what's new! Please press next to continue.\n\nYou can skip the intro by pressing escape and restart whenever you feel like. 🫣",
          element: "restart-intro"
        },
        {
          title: "Menu",
          description: "We have collapsed all the menu items here, to see the items please press next. 😇",
          element: "menu-button",
          func: () => {
            let menuButton = document.getElementById('menu-button');
            if (menuButton) menuButton.click();
          }
        },
        {
          title: "Language Changer",
          description: "Now you can use Jumper in any language you prefer 😎.\n\nNote: This feature is not yet available for automated responses. ⚙️",
          hideInAsia: true,
          element: "google_translate_element",
          prevFunc: async () => {
            let menuButton = document.getElementById('menu-button');
            if (menuButton) menuButton.click();
          }
        },
        {
          title: "Dark mode",
          description: `Use ${this.dashboardName} in light/dark mode as you like! 😁`,
          element: "dark-mode",
          func: async () => {
            let isDarkMode = JSON.parse((await (this.Storage.get({ key: 'jmpr_isDarkMode' }))).value);
            if (isDarkMode == true || isDarkMode == false) this.updateDarkMode(!isDarkMode);
          }
        },
        {
          title: "Switch to old dashboard 😣",
          description: `Don't like the new look? Feel free to use ${this.dashboardName} in the old way, just as you love.\n\nBut please don't forget to give us your valuable feedback before leaving. 🤝`,
          element: 'old-dashboard',
          prevFunc: async () => {
            let isDarkMode = JSON.parse((await (this.Storage.get({ key: 'jmpr_isDarkMode' }))).value);
            if (isDarkMode == true || isDarkMode == false) this.updateDarkMode(!isDarkMode);
          }
        },
        {
          title: "Finish",
          description: "We are working with blazing fast speed to provide you with more updates. 🥷\n\nThank you for your business ✌️"
        }
      ]
      if(this.IS_ASIA) {
        this.steps = this.steps.filter((elm) => elm.hideInAsia != true)
      }
    },
    async handleImageUpload(event) {
      try {
        this.loading = true;
        if (event.target.files && event.target.files.length) {
          let newProfilePicFile = event.target.files[0];
          if (await this.validateImage(newProfilePicFile, 4)) {
            let uploadSendResponseData = await this.uploadImage(newProfilePicFile, '/add-image');
            this.feedback.screenshots.push(uploadSendResponseData.url);
          } else {
            console.log("Invalid screenshot");
            this.updateToast({ open: true, message: "Invalid invalid screenshot", type: "danger" });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    isVisibleForEnterprise(permissionName) {
      if (this.isEnterprise && this.permissions[permissionName] == false) {
        return false;
      }
      return true;
    },
    closeToast(){
      this.updateToast({open: false});
    },
    menuClickHandler(permissionName, routTo) {
      if (permissionName == 'store' && this.isStorePageDisabled) {
        this.$router.push('/plans');
      } else if (this.permissions[permissionName] == false) {
        this.$router.push('/plans');
      } else {
        this.$router.push(routTo);
      }
    },
    async logout(){
      await this.logoutUser();
      this.updateToast({
        open: true,
        message: "You've successfully logged out. See you back soon! 👋🏼",
        type: "secondary"
      })
      this.$router.push("/login");
      location.reload();
    },
    login(){
      this.$router.push("/login"); 
    },
    initFeaturesBasedOnPermission(){
      if(this.user.enterprise == true){
        this.proTrialPeriodIsOn = false;
        this.currentPlanName_forntendUseOnly = "enterprise";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 50;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 100;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 50;
        this.loyalty_permissionEnabled = true;
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = true;
        this.fnbReservationsPermissionEnabled = true;
        this.youtubePermissionEnabled = true;
        this.imessagePermissionEnabled = true;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = true;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.user.appsumo == true){
        this.currentPlanName_forntendUseOnly = "appsumo";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 0;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 0;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        this.loyalty_permissionEnabled = false;
        this.discount_permissionEnabled = false;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = false;
        this.broadcast_permissionEnabled = false;
        this.botBuilder_permissionEnabled = false;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = false;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = false;
        this.personalization_permissionEnabled = true;
        this.analyticsPermissionEnabled = false;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.user.stackcommerce == true){
        this.currentPlanName_forntendUseOnly = "stackcommerce";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 1;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 0;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        this.loyalty_permissionEnabled = false;
        this.discount_permissionEnabled = false;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = false;
        this.broadcast_permissionEnabled = false;
        this.botBuilder_permissionEnabled = false;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = false;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = false;
        this.personalization_permissionEnabled = true;
        this.analyticsPermissionEnabled = false;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.subscriptionPlan == 'trail_pro_plan___deprecated'){
        this.currentPlanName_forntendUseOnly = "trial";
        this.proTrialPeriodIsOn = true;
        this.upgradeToProVisible = false;
        this.userRolesLimit = 4;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 10;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        this.loyalty_permissionEnabled = true;
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = false;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = false;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.subscriptionPlan == 'lite-pro-plan' || this.subscriptionPlan == 'annual-lite-plan' || this.subscriptionPlan == '0919-lite-pro-plan' || this.subscriptionPlan == 'shopify-lite-pro-plan' || this.subscriptionPlan == 'shopify-lite-plan'){
        this.currentPlanName_forntendUseOnly = "lite";
        this.upgradeToProVisible = true;
        if(this.accountServiceType == 'restaurant'){
            this.userRolesLimit = 2;
        }
        else{
            this.userRolesLimit = 1;
        }
        this.cartRecoveryScheduleLimit = 1;
        this.botBuilderActiveBotsLimit = 10;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        if(this.accountServiceType == 'restaurant'){
            this.loyalty_permissionEnabled = true;
        }
        else{
            this.loyalty_permissionEnabled = false;
        }
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = false;
        this.fnbReservationsPermissionEnabled = true;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.subscriptionPlan == 'pro-plan' || this.subscriptionPlan == 'annual-pro-plan' || this.subscriptionPlan == '0919-pro-plan' || this.subscriptionPlan == 'shopify-pro-plan' || this.subscriptionPlan == 'jumper-custom-300-pm'){
        this.currentPlanName_forntendUseOnly = "pro";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 4;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 999999;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        this.loyalty_permissionEnabled = true;
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = true;
        this.fnbReservationsPermissionEnabled = true;
        this.youtubePermissionEnabled = true;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = true;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }
      else if(this.subscriptionPlan == 'plus-pro-plan' || this.subscriptionPlan == '0919-plus-pro-plan'){
        this.currentPlanName_forntendUseOnly = "pro+";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 9;
        this.cartRecoveryScheduleLimit = 3;
        this.botBuilderActiveBotsLimit = 999999;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 10;
        this.loyalty_permissionEnabled = true;
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = true;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = true;
        this.fnbReservationsPermissionEnabled = true;
        this.youtubePermissionEnabled = true;
        this.imessagePermissionEnabled = true;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = true;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }
      else{
        //else it is treated as free or trail_pro_plan
        
        this.currentPlanName_forntendUseOnly = "free";
        //this.upgradeToProVisible = true;
        this.upgradeToProVisible = false;
        if(this.accountServiceType == 'restaurant'){
          this.userRolesLimit = 0;
        }
        else{
          this.userRolesLimit = 0;
        }
        this.cartRecoveryScheduleLimit = 0;
        this.botBuilderActiveBotsLimit = 0;
        this.crmCustomersLimit = 50;
        this.automatedResponses_nonDialogFlow_limit = 10;
        if(this.accountServiceType == 'restaurant'){
          this.loyalty_permissionEnabled = false;
        }
        else{
          this.loyalty_permissionEnabled = false;
        }
        this.discount_permissionEnabled = false;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = false;
        this.broadcast_permissionEnabled = false;
        this.botBuilder_permissionEnabled = false;
        this.popupBuilder_permissionEnabled = false;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = false;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = false;
        this.analyticsPermissionEnabled = false;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = false;
      }

      if(this.BROADCAST_SERVER == true){
        this.proTrialPeriodIsOn = false;
        this.currentPlanName_forntendUseOnly = "BROADCAST_SERVER";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 11;
        this.cartRecoveryScheduleLimit = 0;
        this.botBuilderActiveBotsLimit = 10;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 50;
        this.loyalty_permissionEnabled = false;
        this.discount_permissionEnabled = false;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = false;
        this.broadcast_permissionEnabled = true;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = false;
        this.manualPaymentsPermissionEnabled = false;
        this.whiteLabelPermissionEnabled = true;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = false;
        this.whatsappPermissionEnabled = true;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = false;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = false;
        this.paymentMethods_permissionEnabled = false;
      }

      if(this.TYMEBANK_SERVER == true){
        this.proTrialPeriodIsOn = false;
        this.currentPlanName_forntendUseOnly = "TYMEBANK_SERVER";
        this.upgradeToProVisible = false;
        this.userRolesLimit = 11;
        this.cartRecoveryScheduleLimit = 0;
        this.botBuilderActiveBotsLimit = 10;
        this.crmCustomersLimit = 9999999999;
        this.automatedResponses_nonDialogFlow_limit = 25;
        this.loyalty_permissionEnabled = false;
        this.discount_permissionEnabled = true;
        this.pageInbox_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
        this.broadcast_permissionEnabled = false;
        this.botBuilder_permissionEnabled = true;
        this.popupBuilder_permissionEnabled = false;
        this.manualPaymentsPermissionEnabled = true;
        this.whiteLabelPermissionEnabled = true;
        this.fnbReservationsPermissionEnabled = false;
        this.youtubePermissionEnabled = false;
        this.imessagePermissionEnabled = false;
        this.instagramPermissionEnabled = true;
        this.whatsappPermissionEnabled = false;
        this.vonagesmsPermissionEnabled = false;
        this.magentoPermissionEnabled = false;
        this.bulkProductsImport_permissionEnabled = true;
        this.analyticsPermissionEnabled = true;
        this.personalization_permissionEnabled = true;
        this.paymentMethods_permissionEnabled = true;
      }

      //dynamic_userRolesLimit_found | dynamic_botBuilderActiveBotsLimit_found - start
      var dynamic_userRolesLimit_found = false;
      var dynamic_botBuilderActiveBotsLimit_found = false;

      if(typeof this.accountLevelPermissions_array != 'undefined'){
        for(var i=0; i < this.accountLevelPermissions_array.length; i++){
          var this_permission = this.accountLevelPermissions_array[i];
          
          if(this_permission.indexOf('roles_limit=') > -1){
            var roles_limit = this_permission.split('=');
            roles_limit = roles_limit[1];

            dynamic_userRolesLimit_found = true;
            this.userRolesLimit = parseInt(roles_limit);
          }

          if(this_permission.indexOf('active_bots_limit=') > -1){
            var active_bots_limit = this_permission.split('=');
            active_bots_limit = active_bots_limit[1];

            dynamic_botBuilderActiveBotsLimit_found = true;
            this.botBuilderActiveBotsLimit = parseInt(active_bots_limit);
          }
        }
      }

      if(dynamic_userRolesLimit_found == false){
        if(this.user.enterprise_Name == 'lakmeindia'){
          this.userRolesLimit = 1000;
        }
        
        if(this.user.enterprise_Name == 'smretail_ph'){
          this.userRolesLimit = 1000;
        }
        
        if(this.redRibbon_ph_manila == true){
          this.userRolesLimit = 5000;
        }
        
        if(this.user.referallshopName == 'ladyschoice'){
          this.userRolesLimit = 10;
        }
        
        if(this.user.referallshopName == 'navgurukul'){
          this.userRolesLimit = 3;
        }
        
        if(this.user.referallshopName == 'rsvp'){
          this.userRolesLimit = 10;
        }
        
        if(this.user.referallshopName == 'trimore'){
          this.userRolesLimit = 6;
        }
        
        if(this.user.enterprise_Name == 'bmw'){
          this.userRolesLimit = 15;
        }
        
        if(this.loreal_my_family_user == true){
          this.userRolesLimit = 15;
        }
        
        if(this.loreal_my_org == true){
          this.userRolesLimit = 1000;
        }
        
        if(this.user.enterprise_Name == 'eucerin'){
          this.userRolesLimit = 30;
        }
        
        if(this.user.enterprise_Name == 'clarins_my'){
          this.userRolesLimit = 110;
        }
        
        if(this.elc_family_user == true){
          this.userRolesLimit = 110;
        }
        
        if(this.user.enterprise_Name == 'lancome'){
          this.userRolesLimit = 120;
        }
        
        if(this.user.enterprise_Name == 'kiehlsmalaysia' || this.user.enterprise_Name == 'notkiehlsmalaysia'){
          this.userRolesLimit = 180;
        }
        
        if(this.user.enterprise_Name == 'getha'){
          this.userRolesLimit = 6;
        }
        
        if(this.IS_LIVE == false){
          this.userRolesLimit = 10000;
        }
      }

      if(dynamic_botBuilderActiveBotsLimit_found == false){
        if(this.user.enterprise_Name == 'lakmeindia'){
          this.botBuilderActiveBotsLimit = 999999;
        }
        
        if(this.user.enterprise_Name == 'olay_acom'){
          this.botBuilderActiveBotsLimit = 5;
        }
        
        if(this.user.enterprise_Name == 'jnj_acom_ph'){
          this.botBuilderActiveBotsLimit = 5;
        }
        
        if(this.user.enterprise_Name == 'rb_acom_ph'){
          this.botBuilderActiveBotsLimit = 15;
        }
        
        if(this.user.enterprise_Name == 'adidas_acom_id'){
          this.botBuilderActiveBotsLimit = 5;
        }
        
        if(this.loreal_my_family_user == true){
          this.botBuilderActiveBotsLimit = 25;
        }
        
        if(this.acommerce_family_user == true){
          this.botBuilderActiveBotsLimit = 25;
        }
        
        if(this.user.enterprise_Name == 'eucerin'){
          this.botBuilderActiveBotsLimit = 70;
        }
        
        if(this.user.referallshopName == 'retaildemo'){
          this.botBuilderActiveBotsLimit = 500;
        }
        
        if(this.elc_family_user == true){
          this.botBuilderActiveBotsLimit = 60;
        }
      }
      //dynamic_userRolesLimit_found | dynamic_botBuilderActiveBotsLimit_found - end

      if(this.user.referallshopName == 'teavillacafe'){
        this.fnbReservationsPermissionEnabled = true;
      }

      if(this.user.referallshopName == 'ronsplayground' || (this.currentPlanName_forntendUseOnly == 'pro' && this.user.referallshopName == 'binks')){
        this.imessagePermissionEnabled = true;
      }

      if(this.IS_LIVE == false || this.accountLevelPermissions_string.indexOf('instagram') > -1 || this.user.enterprise == true){
        this.instagramPermissionEnabled = true;
      }

      if(this.IS_LIVE == false || this.accountLevelPermissions_string.indexOf('whatsapp') > -1 || this.user.enterprise == true){
        this.whatsappPermissionEnabled = true;
      }

      if(this.IS_LIVE == false || this.accountLevelPermissions_string.indexOf('sms') > -1){
        this.vonagesmsPermissionEnabled = true;
      }

      if(this.accountServiceType == 'restaurant'){
        if(this.IS_LIVE == true && this.user.enterprise == false){
          this.analyticsPermissionEnabled = false;
          
          this.botBuilder_permissionEnabled = false;

          if(this.user.referallshopName != 'socialmediacafe'){
            this.aiSetup_permissionEnabled = false;
          }
        }
      }

      if(this.user.referallshopName == 'socialmediacafe'){
        this.aiSetup_permissionEnabled = true;
      }

      if(this.loreal_my_family_user == true){
        this.cartRecoveryScheduleLimit = 3;
        this.loyalty_permissionEnabled = false;
        this.discount_permissionEnabled = true;
        this.aiSetup_permissionEnabled = true;
      }

      this.feedback_of_initFeaturesBasedOnPermission();
    },
    async submitFeedback() {
      try {
        this.loading = true;
        let reqBody = {
          userid: this.authKey,
          recipients: ((this.IS_LIVE || this.IS_ASIA) ? [ 'lovish.aggarwal@vonage.com', 'sachin.gavas@vonage.com', 'beeTing.lim@vonage.com', 'ashish.neela@vonage.com' ] : ['lovish.aggarwal@vonage.com', 'sachin.gavas@vonage.com']),
          subject: "Vue JS Feedback Email | Automated",
          template: 'email/dashboard-feedback.html',
          message: {
            '@shopName': this.user.shopName,
            '@email': this.user.email,
            '@rating': this.feedback.rating,
            '@feedback': this.feedback.message,
            '@screenshots': this.feedback.screenshots
          }
        }
        const submitFeedbackResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "new-dashboard/send-feedback", reqBody);
        if ((submitFeedbackResponse.status == 200 || submitFeedbackResponse.status == 201) && submitFeedbackResponse.data.success) {
          this.updateToast({ open: true, message: "We appreciate your feedback and we'll work on the same. Thank you!", type: "success" })
        } else {
          if(submitFeedbackResponse.data.errorMessage && submitFeedbackResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: submitFeedbackResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        window.open(this.environment('VUE_APP_ROOT') + "s/load-dashboard?jumper_seller_id=" + this.authKey + "&admin=" + (this.isAdmin === true ? 'true' : 'false') + "&expired=false", "_blank");
        this.loading = false;
        this.feedbackForm = false;
      }
    }
  }
});
</script>

<style scoped>
.stars-container {
  display: flex;
  column-gap: 10px;
  align-items: center;
  align-content: center;
}
.screenshot-upload-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding-top: 10px;
}
.stars-wrapper {
  display: flex;
  column-gap: 10px;
}
.stars-wrapper ion-icon {
  cursor: pointer;
}

.select-screenshot-row ion-button {
  width: fit-content;
  margin-bottom: 10px;
}

.screenshot-urls {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.screenshot-urls a {
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.screenshot-urls ion-icon {
  cursor: pointer;
}
.rating-modal-content-wrapper ::v-deep(.jinput),
.rating-modal-content-wrapper ::v-deep(.jinput textarea) {
  border-radius: 5px;
} 
.sales-channel-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#inbox-list {
  height: 100vh;
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.outer-list {
  /* padding: 10px 20px 20px 20px; */
  padding: 15px 20px 15px 20px;
}

ion-menu.ios .complete-list {
  height: calc(100% - 140px);
}

.complete-list {
  height: calc(100% - 90px);
  overflow-y: auto;
  padding: 5px;
}

.inner-list {
  border-top: 1px solid var(--ion-color-step-150, #d7d8da);
  padding: 10px;
}

.dark-mode {
  border-top: 1px solid var(--ion-color-step-150, #d7d8da);
  padding: 5px 0px;
  width: 100%;
}

.dark-mode ion-label {
  display: flex;
  flex-direction: column;
}

.dark-mode ion-label .version {
  font-size: 12px;
  opacity: 0.6;
  padding: 5px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}
ion-item.selected {
  --color: var(--ion-color-primary);
}

.menu_link {
  text-decoration: none;
}
.upgrade-url {
  cursor: pointer;
  color: blue;
  font-size: 12px;
}
.disabled ion-icon, .disabled ion-label {
  opacity: 0.4;
}
.tooltip_container .tooltip_text.left {
  top: 5%;
  z-index: 999;
}
.intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all .3s ease-out;
}
</style>