<template>
  <div>
    <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
    ></ion-loading>
    <ion-modal
      v-else
      :is-open="type !== undefined"
      @didDismiss="() => $emit('close')"
    >
      <ion-page>
        <ion-content v-if="type === 'simple'" :fullscreen="true">
          <ion-toolbar class="chat-toolbar" color="light">
            <h2 class="ion-no-margin" style="padding-left: 10px">Button Configuration</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => modalController.dismiss()">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div v-if="note && note.length" class="infoBanner">
            <div class="panel panel-default">
              <div class="panel-body">
                <div style="vertical-align: middle;">
                  <p v-html="note" class="description ion-no-margin"></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="image">
            <ion-item lines="none">
              <ion-label>Upload Image</ion-label>
              <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
              <img v-if="text" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PG9pIcIDA" @click="() => $refs.productImage.click()" width="40" />
              <ion-button v-else @click="() => $refs.productImage.click()" expand="block" fill="outline">Upload image</ion-button>
            </ion-item>
            <ion-item lines="none">
              <ion-label class="cust_marg_right">Or Paste Image URL</ion-label>
              <ion-input @change="setImageFromUrl" v-model="imageUrl" type="text" placeholder="Image URL" class="fullBordered input_custom_style"></ion-input>
            </ion-item>
          </div>
          <ion-item v-else lines="none">
            <ion-input v-model="text" placeholder="Enter Text" class="fullBordered input_custom_style"></ion-input>
          </ion-item>
          <ion-list v-if="action === undefined">
            <ion-radio-group v-model="action">
              <ion-list-header>
                <ion-label>Action (Start a)</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-label>Open a website</ion-label>
                <ion-radio slot="start" value="website"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>Call a phone number</ion-label>
                <ion-radio slot="start" value="phone"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <div v-if="action === 'website'">
            <ion-item v-if="payload === undefined" lines="none">
              <ion-input v-model="website" type="url" placeholder="Enter Website" class="fullBordered input_custom_style"></ion-input>
              <ion-button @click="() => website && website.length ? payload = website : ''" color="secondary">Add</ion-button>
            </ion-item>
            <div v-else class="website-box">
              <p class="ion-no-margin text">Website to follow:</p>
              <a target="_blank" :href="payload">{{ payload }}</a>
               <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger" class="ion-no-margin">
                  <ion-icon :icon="trashOutline"></ion-icon>
               </ion-button>
            </div>
            <ion-item v-if="webHeight" >
              <ion-label>Webview height ratio</ion-label>
              <ion-select v-model="selectedWebHeight" placeholder="Select height ratio">
                <ion-select-option value="full">Full (Open in a new tab)</ion-select-option>
                <ion-select-option value="tall">Tall (75%)</ion-select-option>
                <ion-select-option value="compact">Compact (50%)</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <div v-if="action === 'phone'">
             <ion-item v-if="payload === undefined" lines="none">
                <ion-input v-model="number" type="tel" placeholder="Enter number" class="fullBordered input_custom_style" style="height: 38px"></ion-input>
                <ion-button @click="() => number && number.length ? payload = number : ''"  size="default" fill="solid" color="dark">Add</ion-button>
            </ion-item>
            <ion-item v-else>
              <ion-label>
                Call <a target="_blank" :href="'tel:' + payload">{{ payload }}</a>
              </ion-label>
               <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger" class="ion-no-margin">
                  <ion-icon :icon="trashOutline"></ion-icon>
               </ion-button>
            </ion-item>
          </div>
        </ion-content>
        <ion-content v-if="type === 'detailed'" :fullscreen="true">
          <ion-toolbar class="chat-toolbar" color="light">
            <h2 class="ion-no-margin" style="padding-left: 10px">Button Configuration</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => { action = undefined; modalController.dismiss(); }">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div v-if="note && note.length" class="infoBanner">
            <div class="panel panel-default">
              <div class="panel-body">
                <div style="vertical-align: middle;">
                  <p v-html="note" class="description ion-no-margin"></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="image">
            <ion-item lines="none">
              <ion-label>Upload Image</ion-label>
              <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
              <img v-if="text" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PG9pIcIDA" @click="() => $refs.productImage.click()" width="40" />
              <ion-button v-else @click="() => $refs.productImage.click()" expand="block" fill="outline">Upload image</ion-button>
            </ion-item>
            <ion-item lines="none">
              <ion-label class="cust_marg_right">Or Paste Image URL</ion-label>
              <ion-input @change="setImageFromUrl" v-model="imageUrl" type="text" placeholder="Image URL" class="fullBordered input_custom_style"></ion-input>
            </ion-item>
          </div>
          <div v-else class="prdct-section">
            <ion-card-title class="sctn-labels"> Add a new question </ion-card-title>
            <ion-input type="text" placeholder="Enter Text" v-model="text" :maxlength="textLimit" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <ion-list v-if="action === undefined">
            <ion-radio-group v-model="action">
              <ion-list-header>
                <ion-label>Action (Start a)</ion-label>
              </ion-list-header>
              <ion-item v-if="options.productCheckout.enable" class="bttns" lines="none">
                <ion-radio slot="start" value="productFlow" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-product.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.productCheckout.text }}</h3>
                  <p>Allow user to purchase a single product via the bot</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.collectionFlow.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="collectionFlow" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-collection.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.collectionFlow.text }}</h3>
                  <p>Allow users to purchase products from the selected collection</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.botFlow.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="botFlow" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/start-a-flow.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.botFlow.text }}</h3>
                  <p>Link user to another active bot</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.pingLiveChat.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="livechat" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.pingLiveChat.text }}</h3>
                  <p>Move conversation to live chat mode and let an available agent take it up</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.liveChat.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="agent_or_group" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.liveChat.text }}</h3>
                  <p>Assign conversation to a specific agent or agent group</p>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.openWebSite.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="website" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-collection.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.openWebSite.text }}</h3>
                </ion-label>
              </ion-item>
              <ion-item v-if="options.customPayload.enable" lines="none" class="bttns">
                <ion-radio slot="start" value="custom" style="margin-inline-end: 15px"></ion-radio>
                <ion-avatar slot="start" style="height: auto; width: 25px;">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/send-a-message.svg"/>
                </ion-avatar>
                <ion-label>
                  <h3>{{ options.customPayload.text }}</h3>
                </ion-label>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <div v-if="action === 'productFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="productFilter" show-cancel-button="always" @ionCancel="action = undefined"></ion-searchbar>
              <ion-list v-if="productsToShow.length">
                <div @click="() => payload = thisProduct.hashtag" v-for="thisProduct in productsToShow" :key="thisProduct.id" class="products">
                  <ion-row class="product-container">
                      <ion-col size="2" class="ion-no-padding">
                        <img :src="thisProduct.image" class="img-container" />
                      </ion-col>
                      <ion-col size="3" class="ion-align-self-center ion-no-padding">
                        <h2 class="common-label ion-no-margin"> {{ thisProduct.name }} </h2>
                      </ion-col>
                  </ion-row>
                </div>
                
              </ion-list>
              <div v-else class="no-data-found-container"> <ion-text>No Product Found!</ion-text> </div>
            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Product: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src=" allProducts.find(e => e.hashtag === payload).image" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{ allProducts.find(e => e.hashtag === payload).name }} </h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'collectionFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="collectionFilter" show-cancel-button="always" @ionCancel="action = undefined"></ion-searchbar>
              <ion-list v-if="collectionsToShow.length">
                <div @click="() => payload = thisCollection.hashtag" v-for="thisCollection in collectionsToShow" :key="thisCollection.id" class="products">
                 <ion-row class="product-container">
                    <ion-col size="2" class="ion-no-padding">
                      <img :src="thisCollection.image" class="img-container" />
                    </ion-col>
                    <ion-col size="3" class="ion-align-self-center ion-no-padding">
                      <h2 class="common-label ion-no-margin"> {{ thisCollection.name }} </h2>
                    </ion-col>
                  </ion-row>
                </div>
              </ion-list>
              <div v-else class="no-data-found-container"> <ion-text>No Collection Found!</ion-text> </div>

            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Collection: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src=" allCollections.find(e => e.hashtag === payload).image" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{ allCollections.find(e => e.hashtag === payload).name }} </h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'botFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="botFilter" show-cancel-button="always" @ionCancel="action = undefined"></ion-searchbar>
              <div @click="() => payload = thisBot.link" v-for="thisBot in botsToShow" :key="thisBot.id" class="products">
                <ion-row class="product-container">
                  <ion-col size="2" class="ion-no-padding">
                    <img :src="thisBot.images[0]" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ thisBot.displayname  }} </h2>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Bot: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src="allBots.find(e => e.link === payload).images[0]" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{  allBots.find(e => e.link === payload).displayname }} </h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'agent_or_group'">
            <div v-if="payload === undefined">
              <ion-item lines="none">
                <ion-label>Group</ion-label>
                <ion-select v-model="selectedRole" placeholder="Select group" class="slctContainer">
                  <ion-select-option v-for="thisRole in allRoles" :key="thisRole.id" :value="thisRole.name">{{ thisRole.name }}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-list v-if="selectedRole">
                <div @click="() => payload = (allRoles.find(e => e.name == selectedRole)).id" class="prdct-section common-label">Assign to Group: {{ selectedRole }}</div>
                <div @click="() => payload = thisAgent.id" v-for="thisAgent in agentsToShow" :key="thisAgent.id" class="products">
                  <ion-row class="slctd-prdct">
                    <ion-col size="2" class="ion-no-padding">
                      <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                    </ion-col>
                    <ion-col size="3" class="ion-align-self-center ion-no-padding">
                      <h2 class="common-label ion-no-margin"> {{ thisAgent.name }}</h2>
                    </ion-col>
                   </ion-row>
                </div>
              </ion-list>
            </div>
            <div v-else>
              <div v-if="payloadRole" class="prdct-section">
                <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
                <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Group: </h6>
                <ion-row class="slctd-prdct">
                  <ion-col size="2" class="ion-no-padding">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ payloadRole.name }}</h2>
                  </ion-col>
                  <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                </ion-row>
              </div>
              <div v-if="payloadAgent" class="prdct-section">
                <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
                <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Agent: </h6>
                <ion-row class="slctd-prdct">
                  <ion-col size="2" class="ion-no-padding">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ payloadAgent.name }}</h2>
                  </ion-col>
                  <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                </ion-row>
              </div>
            </div>
          </div>
          <div v-if="action === 'livechat'">
            <div class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin"> Start Live Chat</h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'website'">
            <ion-item v-if="payload === undefined" lines="none">
              <ion-input v-model="website" type="url" placeholder="Enter Website" class="fullBordered input_custom_style" style="height: 38px"></ion-input>
              <ion-button @click="websiteUrlAddHandler"  size="default" fill="solid" color="dark">Add</ion-button>
            </ion-item>
            <div v-else class="website-box">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Website to follow: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/start-a-flow.svg" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <a class="ion-no-margin" target="_blank" :href="payload"> {{payload}}</a>
                </ion-col>
                <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                </ion-button>
              </ion-row>
            </div>
            <ion-item v-if="webHeight">
              <ion-label>Webview height ratio</ion-label>
              <ion-select v-model="selectedWebHeight" placeholder="Select height ratio">
                <ion-select-option value="full">Full (Open in a new tab)</ion-select-option>
                <ion-select-option value="tall">Tall (75%)</ion-select-option>
                <ion-select-option value="compact">Compact (50%)</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <div v-if="action === 'custom'" class="custom-payload-value-wrapper">
              <ion-input v-model="payload" type="text" placeholder="Enter Text" class="fullBordered input_custom_style"></ion-input>
          </div>
        </ion-content>
        <ion-content v-if="type === 'persistent'" :fullscreen="true">
          <ion-toolbar class="chat-toolbar" color="light">
            <h2 class="ion-no-margin"  style="padding-left: 10px">Button Configuration</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => modalController.dismiss()">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div v-if="note && note.length" class="infoBanner">
            <div class="panel panel-default">
              <div class="panel-body">
                <div style="vertical-align: middle;">
                  <p v-html="note" class="description ion-no-margin"></p>
                </div>
              </div>
            </div>
          </div>
          <ion-item  lines="none">
            <ion-label>When user clicks</ion-label>
            <ion-input v-model="text" placeholder="Enter Text" class="fullBordered input_custom_style"></ion-input>
          </ion-item>
          <ion-list>
            <ion-radio-group v-model="superAction">
              <ion-list-header>
                <ion-label>
                  Action
                </ion-label>
              </ion-list-header>
              <ion-item @click="() => {action = undefined; payload = undefined;}">
                <ion-label>Reply with message</ion-label>
                <ion-radio slot="start" value="reply"></ion-radio>
              </ion-item>
              <ion-item @click="() => {action = undefined; payload = undefined;}">
                <ion-label>Manage automation</ion-label>
                <ion-radio slot="start" value="automation"></ion-radio>
              </ion-item>
              <ion-item @click="() => {action = undefined; payload = undefined;}">
                <ion-label>Start a Live Chat</ion-label>
                <ion-radio slot="start" value="livechat"></ion-radio>
              </ion-item>
              <ion-item @click="() => {action = undefined; payload = undefined;}">
                <ion-label>Open a Website</ion-label>
                <ion-radio slot="start" value="website"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <ion-list v-if="action === undefined && superAction !== undefined">
            <ion-radio-group v-model="action">
              <div v-if="superAction === 'reply'">
                <ion-list-header>
                  <ion-label>
                    Reply Type
                  </ion-label>
                </ion-list-header>
                <ion-item class="bttns" lines="none">
                  <ion-radio slot="start" value="productFlow" style="margin-inline-end: 15px"></ion-radio>
                  <ion-avatar slot="start" style="height: auto; width: 25px;">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-product.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3>Start a Product Flow</h3>
                    <p>Allow user to purchase a single product via the bot</p>
                  </ion-label>
                </ion-item>
                <ion-item lines="none" class="bttns">
                  <ion-radio slot="start" value="collectionFlow" style="margin-inline-end: 15px"></ion-radio>
                  <ion-avatar slot="start" style="height: auto; width: 25px;">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-collection.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3>Start a Collection Flow</h3>
                    <p>Allow users to purchase products from the selected collection</p>
                  </ion-label>
                </ion-item>
                <ion-item  lines="none" class="bttns">
                  <ion-radio slot="start" value="botFlow" style="margin-inline-end: 15px"></ion-radio>
                  <ion-avatar slot="start" style="height: auto; width: 25px;">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/start-a-flow.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3>Start a Bot Flow</h3>
                    <p>Link user to another active bot</p>
                  </ion-label>
                </ion-item>
              </div>
              <div v-if="superAction === 'automation'">
                <ion-list-header>
                  <ion-label>
                    Automation Type
                  </ion-label>
                </ion-list-header>
                <ion-item>
                  <ion-label>Cancel Transaction</ion-label>
                  <ion-radio slot="start" value="cancel"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Go back one Step</ion-label>
                  <ion-radio slot="start" value="back"></ion-radio>
                </ion-item>
              </div>
              <div v-if="superAction === 'livechat'">
                <ion-list-header>
                  <ion-label>
                    Live chat Type
                  </ion-label>
                </ion-list-header>
                <ion-item lines="none" class="bttns">
                  <ion-radio slot="start" value="livechat" style="margin-inline-end: 15px"></ion-radio>
                  <ion-avatar slot="start" style="height: auto; width: 25px;">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3>Ping live chat</h3>
                    <p>Move conversation to live chat mode and let an available agent take it up</p>
                  </ion-label>
                </ion-item>
                <ion-item lines="none" class="bttns">
                  <ion-radio slot="start" value="agent_or_group" style="margin-inline-end: 15px"></ion-radio>
                  <ion-avatar slot="start" style="height: auto; width: 25px;">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3>Ping live chat (Assign to Agent)</h3>
                    <p>Assign conversation to a specific agent or agent group</p>
                  </ion-label>
                </ion-item>
              </div>
              <div v-if="superAction === 'website'">
                <ion-list-header>
                  <ion-label>
                    Website Type
                  </ion-label>
                </ion-list-header>
                <ion-item>
                  <ion-label>Custom Website</ion-label>
                  <ion-radio slot="start" value="website"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Shipping website</ion-label>
                  <ion-radio slot="start" value="shop_url"></ion-radio>
                </ion-item>
              </div>
            </ion-radio-group>
          </ion-list>
          <div v-if="action === 'productFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="productFilter" show-cancel-button="always" @ionCancel="action = undefined"></ion-searchbar>
              <div @click="() => payload = thisProduct.hashtag" v-for="thisProduct in productsToShow" :key="thisProduct.id" class="products">
                <ion-row class="product-container">
                  <ion-col size="2" class="ion-no-padding">
                    <img :src="thisProduct.image" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ thisProduct.name }} </h2>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Product: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src=" allProducts.find(e => e.hashtag === payload).image" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{  allProducts.find(e => e.hashtag === payload).name }} </h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'collectionFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="collectionFilter" show-cancel-button="always" @ionCancel="action = undefined"></ion-searchbar>
              <div @click="() => payload = thisCollection.hashtag" v-for="thisCollection in collectionsToShow" :key="thisCollection.id" class="products">
                <ion-row class="product-container">
                  <ion-col size="2" class="ion-no-padding">
                    <img :src="thisCollection.image" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ thisCollection.name }} </h2>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Collection: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src="allCollections.find(e => e.hashtag === payload).image" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{  allCollections.find(e => e.hashtag === payload).name }} </h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'botFlow'">
            <div v-if="payload === undefined">
              <ion-searchbar v-model="botFilter" show-cancel-button="always"></ion-searchbar>
               <div  @click="() => payload = thisBot.link" v-for="thisBot in botsToShow" :key="thisBot.id" class="products">
                <ion-row class="product-container">
                    <ion-col size="2" class="ion-no-padding">
                      <img :src="thisBot.images[0]" class="img-container" />
                    </ion-col>
                    <ion-col size="3" class="ion-align-self-center ion-no-padding">
                      <h2 class="common-label ion-no-margin"> {{ thisBot.displayname }} </h2>
                    </ion-col>
                  </ion-row>
                </div>
            </div>
            <div v-else class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Bot: </h6>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img :src="allBots.find(e => e.link === payload).images[0]" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin">  {{ allBots.find(e => e.link === payload).displayname }} </h2>
                </ion-col>
                <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'agent_or_group'">
            <div v-if="payload === undefined">
              <ion-item>
                <ion-label>Group</ion-label>
                <ion-select v-model="selectedRole" placeholder="Select group">
                  <ion-select-option v-for="thisRole in allRoles" :key="thisRole.id" :value="thisRole.name">{{ thisRole.name }}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-list v-if="selectedRole">
                <ion-item value="undefined" disabled>Select a group</ion-item>
                <ion-item @click="() => payload = (allRoles.find(e => e.name == selectedRole)).id">Assign to Group: {{ selectedRole }}</ion-item>
                <div @click="() => payload = thisAgent.id" v-for="thisAgent in agentsToShow" :key="thisAgent.id" class="products">
                  <ion-row class="slctd-prdct">
                    <ion-col size="2" class="ion-no-padding">
                      <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                    </ion-col>
                    <ion-col size="3" class="ion-align-self-center ion-no-padding">
                      <h2 class="common-label ion-no-margin"> {{ thisAgent.name }}</h2>
                    </ion-col>
                   </ion-row>
                </div>
              </ion-list>
            </div>
            <div v-else>
              <div v-if="payloadRole" class="prdct-section">
                <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
                <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Group: </h6>
                <ion-row class="slctd-prdct">
                  <ion-col size="2" class="ion-no-padding">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ payloadRole.name }}</h2>
                  </ion-col>
                  <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                </ion-row>
              </div>
              <div v-if="payloadAgent" class="prdct-section">
                <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
                <h6 class="ion-no-margin common-label" style="color: var( --ion-color-primary)"> Selected Agent: </h6>
                <ion-row class="slctd-prdct">
                  <ion-col size="2" class="ion-no-padding">
                    <img src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg" class="img-container" />
                  </ion-col>
                  <ion-col size="3" class="ion-align-self-center ion-no-padding">
                    <h2 class="common-label ion-no-margin"> {{ payloadAgent.name }}</h2>
                  </ion-col>
                  <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                </ion-row>
              </div>
            </div>
          </div>
          <div v-if="action === 'livechat'">
            <div class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/persistent-menu-icons/ping-live-chat.svg" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin"> Start Live Chat</h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'storeInfo'">
            <div class="prdct-section">
              <ion-card-title class="sctn-labels"> When the customer selects the ice breaker </ion-card-title>
              <ion-row class="slctd-prdct">
                <ion-col size="2" class="ion-no-padding">
                  <img src="https://jumperdevnew.appspot.com/web-s/resources/images/crm-icons/share-collection.svg" class="img-container" />
                </ion-col>
                <ion-col size="3" class="ion-align-self-center ion-no-padding">
                  <h2 class="common-label ion-no-margin"> Store Info</h2>
                </ion-col>
                 <ion-button @click="() => {payload = undefined; action = undefined;}" size="default" fill="clear" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                 </ion-button>
              </ion-row>
            </div>
          </div>
          <div v-if="action === 'cancel'">
            <ion-item>
              <ion-label>
                Cancel Transaction
              </ion-label>
              <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>            
            </ion-item>
          </div>
          <div v-if="action === 'back'">
            <ion-item>
              <ion-label>
                Go back one Step
              </ion-label>
              <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>            
            </ion-item>
          </div>
          <div v-if="action === 'shop_url'">
            <ion-item>
              <ion-label>
                Open shopping Website
              </ion-label>
               <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger">
                <ion-icon :icon="trashOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </div>
          <div v-if="action === 'website'">
            <ion-item v-if="payload === undefined" lines="none">
              <ion-input v-model="website" type="url" placeholder="Enter Website" class="fullBordered input_custom_style"></ion-input>
              <ion-button @click="websiteUrlAddHandler" color="dark" fill="solid" size="default">Add</ion-button>
            </ion-item>
            <div v-else class="website-box">
              <p class="ion-no-margin text">Website to follow:</p>
              <a target="_blank" :href="payload">{{ payload }}</a>
               <ion-button @click="() => {payload = undefined; action = undefined;}" fill="clear" size="default" color="danger" class="ion-no-margin">
                  <ion-icon :icon="trashOutline"></ion-icon>
               </ion-button>
            </div>
            <ion-item v-if="webHeight">
              <ion-label>Webview height ratio</ion-label>
              <ion-select v-model="selectedWebHeight" placeholder="Select height ratio">
                <ion-select-option value="full">Full (Open in a new tab)</ion-select-option>
                <ion-select-option value="tall">Tall (75%)</ion-select-option>
                <ion-select-option value="compact">Compact (50%)</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button v-if="removeButton" @click="() => { $emit('removeButton'); modalController.dismiss(); }" color="danger">Remove Button</ion-button>
            <ion-button @click="submit" color="dark">Save</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script>
import { IonRadioGroup, IonRadio, IonSearchbar, IonIcon, modalController, IonSelect, IonSelectOption } from "@ionic/vue";
import { close, trashOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String
    },
    image: {
      type: Boolean,
      default: false
    },
    note: {
      type: String
    },
    webHeight: {
      type: Boolean,
      default: false
    },
    config: {
      text: {
        type: String
      },
      action: {
        type: String
      },
      payload: {
        type: String
      },
      webHeight: {
        type: String
      }
    },
    is_agent_or_group_req: {
      type: Boolean,
      default: false
    },
    textLimit: {
      type: Number,
      default: null
    },
    removeButton: {
      type: Boolean,
      default: false
    },
    optionsConf: {
      type: Object,
      default: null
    }
  },
  components: {
    IonRadioGroup,
    IonRadio,
    IonSearchbar,
    IonIcon,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      close,
      trashOutline,
      modalController,
      loading: true,
      superAction: undefined,
      text: undefined,
      action: undefined,
      website: undefined,
      payload: undefined,
      productFilter: undefined,
      collectionFilter: undefined,
      botFilter: undefined,
      allProducts: [],
      allCollections: [],
      allBots: [],
      number: undefined,
      allRoles: [],
      selectedRole: undefined,
      selectedWebHeight: undefined,
      imageUrl: undefined,
      options: {
        productCheckout: {
          text: 'Start A Product Flow',
          enable: true
        },
        collectionFlow: {
            text: 'Start A Collection Flow',
            enable: true
        },
        botFlow: {
            text: 'Start A Bot Flow',
            enable: true
        },
        pingLiveChat: {
          text: 'Ping live chat',
          enable: true
        },
        liveChat: {
            text: 'Start A Live Chat',
            enable: true
        },
        openWebSite: {
            text: 'Open A Website',
            enable: true
        },
        customPayload: {
            text: 'Custom Payload',
            enable: true
        }
      }
    }
  },
  mounted() {
    console.log(this.config);
    this.init();
    this.setOptionsVisibility();
  },
  computed: {
    ...mapGetters({
      userData: "user/getUser"
    }),
    referallshopName() {
      return this.userData.referallshopName && this.userData.referallshopName.length ? this.userData.referallshopName : undefined;
    },
    productsToShow() {
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCollections.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCollections;
    },
    botsToShow() {
      return this.botFilter && this.botFilter.length ? this.allBots.filter(e => e.displayname.match(new RegExp(this.botFilter, 'gi'))) : this.allBots;
    },
    agentsToShow() {
      return this.selectedRole ? this.allAgents.filter(e => e.role === this.selectedRole) : [];
    },
    payloadRole() {
      return this.allRoles.find(e => e.id == this.payload);
    },
    payloadAgent() {
      return this.allAgents.find(e => e.id == this.payload);
    }
  },
  methods: {
    async init() {
      try {
        console.log(this.config);
        if(this.config && this.config.text) this.text = this.config.text;
        if(this.config && this.config.action) this.action = this.config.action;
        if(this.config && this.config.payload) this.payload = this.config.payload;
        if(this.webHeight && this.config && this.config.webHeight) this.selectedWebHeight = this.config.webHeight;

        let shopListResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        let fetchActionResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-action");
        let businessRolesResponse = undefined;
        let agentsResponse = undefined;
        if(this.is_agent_or_group_req) {
          businessRolesResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");
          agentsResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-list");
        }
        let allPromises = await Promise.all([shopListResponse, fetchActionResponse, businessRolesResponse, agentsResponse]);
        shopListResponse = allPromises[0];
        fetchActionResponse = allPromises[1];
        businessRolesResponse = allPromises[2];
        agentsResponse = allPromises[3];
        
        if ((shopListResponse.status == 200 || shopListResponse.status == 201) && shopListResponse.data.success) {
          if(shopListResponse.data.products && shopListResponse.data.products.length) {
            this.allProducts = shopListResponse.data.products.filter(e => !e.bundle && !e.catalog);
            this.allCollections = shopListResponse.data.products.filter(e => !e.bundle && e.catalog);
          }
        } else {
          if(shopListResponse.data && !shopListResponse.data.success && shopListResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: shopListResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
        if ((fetchActionResponse.status == 200 || fetchActionResponse.status == 201) && fetchActionResponse.data.success) {
          if(fetchActionResponse.data.actions && fetchActionResponse.data.actions.length) {
            this.allBots = fetchActionResponse.data.actions.filter(e => e.active);
          }
        } else {
          if(fetchActionResponse.data && !fetchActionResponse.data.success && fetchActionResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchActionResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
        if(this.is_agent_or_group_req) {
          if ((businessRolesResponse.status == 200 || businessRolesResponse.status == 201) && businessRolesResponse.data.success) {
            this.allRoles = businessRolesResponse.data.business_roles;
          } else {
            if(businessRolesResponse.data && !businessRolesResponse.data.success && businessRolesResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: businessRolesResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
          if ((agentsResponse.status == 200 || agentsResponse.status == 201) && agentsResponse.data.success) {
            this.allAgents = agentsResponse.data.roles;
          } else {
            if(agentsResponse.data && !agentsResponse.data.success && agentsResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: agentsResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        }
        if(this.payload && this.action == undefined) {
          if(this.payload.includes('#@[[shopLink]]')) {this.action = 'storeInfo'; this.superAction = 'reply';}
          else if(this.payload.includes('cancel')) {this.action = 'cancel'; this.superAction = 'automation';}
          else if(this.payload.includes('back')) {this.action = 'back'; this.superAction = 'automation';}
          else {
            if(this.payload.includes('7@1k704um@9 ___')) {this.action = 'agent_or_group'; this.payload = this.payload.replace('7@1k704um@9 ___', ''); this.superAction = 'livechat';}
            else if(this.payload.includes('7@1k704um@9')) {this.action = 'livechat'; this.payload = '7@1k704um@9'; this.superAction = 'livechat';}
            else {
              if(this.payload.includes('#')) {
                let thisProduct = this.allProducts.find(e => this.payload.includes(e.hashtag));
                let thisCollection = this.allCollections.find(e => this.payload.includes(e.hashtag));
                if(thisProduct) {this.action = 'productFlow'; this.payload = thisProduct.hashtag; this.superAction = 'reply';}
                else if(thisCollection) {this.action = 'collectionFlow'; this.payload = thisCollection.hashtag; this.superAction = 'reply';}
              } else {
                if(this.payload.includes('bot')) {
                  this.action = 'botFlow'; this.superAction = 'reply';
                  let thisBot = this.allBots.find(e => this.payload.includes(e.link));
                  this.payload = thisBot.link;
                }
                else if(this.payload.includes('jumperdevnew.appspot.com/policy/shipping') || this.payload.includes('jumper.ai/policy/shipping')) {this.action = 'shop_url'; this.superAction = 'website';}
                else if(this.payload.includes('http://') || this.payload.includes('https://')) {this.action = 'website'; this.superAction = 'website';}
                else {this.action = 'custom'; this.superAction = 'website';}
              }
            }
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    websiteUrlAddHandler() {
      if (!this.validateUrl(this.website)) {
        this.updateToast({ open: true, message: "Invalid url", type: "danger" });
        return;
      }
      this.website?.length ? this.payload = this.website : ''
    },
    setOptionsVisibility() {
      if(this.optionsConf){
        for (const confKey in this.optionsConf) {
          const objConf = this.optionsConf[confKey];
          if (Object.prototype.hasOwnProperty.call(this.options, confKey)) {
            if (Object.prototype.hasOwnProperty.call(objConf, 'enable')) {
              this.options[confKey].enable = objConf.enable;
            }
            if (Object.prototype.hasOwnProperty.call(objConf, 'text')) {
              this.options[confKey].text = objConf.text;
            }
          }
        }
      }

      // turn off below options for asia env
      if (this.IS_ASIA) {
        this.options.productCheckout.enable = false;
        this.options.collectionFlow.enable = false;
        this.options.botFlow.enable = false;
        this.options.pingLiveChat.enable = false;
        this.options.liveChat.enable = false;
      }
    },
    async handleImageUpload (event) {
      try {
        this.loading = true;
        if(event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          console.log(newImage);
          if(await this.validateImage(newImage, 4)) {
            this.text = newImage;
          } else {
            console.log("Invalid picture");
            this.updateToast({ open: true, message: "Invalid picture", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        this.loading = false;
      }
    },
    setImageFromUrl() {
      this.text = this.imageUrl;
    },
    submit() {
      try {
        if (this.textLimit && this.text.trim().length > this.textLimit) {
          this.updateToast({ open: true, message: `Maximum text length in ${this.textLimit} exceeded. (valid text should be less than ${this.textLimit} characters in length)`, type: "danger" });
          return;
        }

        if(this.action === 'livechat') this.payload = "7@1k704um@9";
        else if(this.action === 'storeInfo') this.payload = "#@[[shopLink]]";
        else if(this.action === 'cancel') this.payload = "cancel";
        else if(this.action === 'back') this.payload = "back";
        else if(this.action === 'shop_url') {
          if(this.IS_LIVE) this.payload = "https://" + this.referallshopName + ".jumper.ai/policy/shipping";
          else this.payload = "http://" + this.referallshopName + ".jumperdevnew.appspot.com/policy/shipping";
        }
        if(this.text && this.action && this.payload) {
          if(this.webHeight && this.selectedWebHeight) {
            if(this.selectedWebHeight) {
              this.$emit('submit', { text: this.text, action: this.action, payload: this.payload, webHeight: this.selectedWebHeight })
              this.modalController.dismiss();
            } else {
              this.updateToast({ open: true, message: "Please select webview height", type: "danger" })
            }
          } else {
            this.$emit('submit', { text: this.text, action: this.action, payload: this.payload })
            this.modalController.dismiss();
          }
        } else {
          this.updateToast({ open: true, message: "Please enter required details", type: "danger" })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      }
    }
  }
}
</script>

<style scoped>
.no-data-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
  margin-bottom: 0px;
  margin-top: 15px;
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}
/* .infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
} */
.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}
.infoBanner.success .panel .panel-body{
  border-left: 3px #099e6e solid;
}
.infoBanner.danger .panel.panel-default{
  background-color: #ffd4d4;
}
.infoBanner.danger .panel .panel-body{
  border-left: 3px #ff0000 solid;
}
.panel-body {
  padding: 0px 10px;
}

.infoBanner .description {
  padding: 15px 0px;
  font-size: 14px;
}
.bttns {
  border: 2px dashed #ccc;
  margin: 0px 20px 10px 20px;
}
.sctn-input {
  border-radius: 5px;
}

.text{
  margin: 10px 0px;
}

.website-box {
  margin-left: 20px;
}
.slctd-prdct {
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
}

.products{
  margin: 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}
.product-container {
  padding-bottom: 5px;
  max-width: 400px;
}
.prdct-section {
  margin: 15px;
}
.img-container {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.cust_marg_right {
  margin-right: 30px;
}

ion-list-header{
  padding-left: 20px;
}
.custom-payload-value-wrapper {
  padding: 15px !important;
}

@media only screen and (max-width: 760px) {
  .product-container {
    padding-bottom: 5px;
    max-width: 100%;
  }
}
</style>