<template>
  <ion-grid class="shopify-wrapper-grid">
    <ion-row class="first-row">
      <ion-col size-md="12" size-sm="12" class="left-panel">
        <stepProgress :steps="steps" :propActiveStep="propActiveStep" @onSelectStep="onSelectStep"></stepProgress>
      
        <ion-item class="step-item" v-if="propActiveStep == 0" lines="none">
        <div class="slide-content">
          <div class="slide-header margin-next">To get started, login to your BigCommerce account. Next go to the <a href="https://developer.bigcommerce.com/">BigCommerce developer portal</a> and click "My Apps"</div>
          <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwOjB8MQJDA"> </div>
        </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 1" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">If you are using the BigCommerce Developer Portal for the first time, it would ask you for permission to enable custom apps and integrations like Jumper.ai to access your account.<br />Click "Allow" to enable the same.</div> <div>
              <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjX3t0JDA">
            </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 2" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">On the next screen you'd see the option to "Create an app now"</div>
            <div><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwKjoyrYIDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 3" lines="none">
          <div class="slide-content">
            <div class="slide-header">After you click "Create an app now" it would ask you to Enter a name for your app. You can name it Jumper to help you easily recognise it in the future and click the "Create" Button</div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIictdEJDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 4" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">If you see a pop up automatically open for further details, scroll down the pop up page and click the "Cancel" button.<br>Now you'd see the screen below. Once here, click on “View Client ID”, copy the Client ID and Client secret and paste them below: </div>
            <div><img class="margin-next" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjVp_sKDA"> </div>
            <div><img class="margin-next" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwMi7tK8IDA"> </div>
            <ion-item class="margin-next" :class="{error: form.isInvalidBigCommerceClientId}">
              <InputValidation v-slot="slotProps" :inputVal="form.bigCommerceClientId" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="bigCommerceClientId" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidBigCommerceClientId'})">
                <ion-label> BigCommerce client ID </ion-label>
                <HelpTooltip title="BigCommerce client ID">You can find it at in your BigCommerce dashboard, as shown in above image.</HelpTooltip>
                <ion-input v-model="form.bigCommerceClientId" @change="slotProps.onChange"></ion-input>
              </InputValidation>
            </ion-item>
            <ion-item class="margin-next" :class="{error: form.isInvalidBigCommerceClientSecret}">
              <InputValidation v-slot="slotProps" :inputVal="form.bigCommerceClientSecret" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="bigCommerceClientSecret" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidBigCommerceClientSecret'})">
                <ion-label> BigCommerce client secret </ion-label>
                <HelpTooltip title="BigCommerce client secret">You can find it at in your BigCommerce dashboard, as shown in above image.</HelpTooltip>
                <ion-input v-model="form.bigCommerceClientSecret" @change="slotProps.onChange"></ion-input>
              </InputValidation>
            </ion-item>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 5" lines="none">
          <div class="slide-content">
            <div class="slide-header">Now click on "Edit App" and head straight to the "3. Technical" section </div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjVp_sKDA"> </div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwMi5_aEIDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 6" lines="none">
          <div class="slide-content">
            <div class="slide-header">Copy the "Callback URL" below and paste it in both "Auth Callback URL" and "Load Callback URL" field on Technical section </div>

            <ion-item class="copy-url-wrapper" lines="none">
              <div value="url-text">{{callBackUrl}}</div>
              <ion-buttons slot="end">
                <ion-button class="btn-copy-url" @click="copyCallBackUrl" fill="clear">
                  <ion-icon class="ion-no-margin" slot="start" :icon="linkOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjVp_sKDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 7" lines="none">
          <div class="slide-content">
            <div class="slide-header">Next make sure you give Jumper access to all the below permission in the ”OAuth scopes"<br> section:</div>
            <ul type="disc" class="margin-next">
              <li>Orders: Modify</li>
              <li>Order Transactions: Modify</li>
              <li>Create Payments: Create</li>
              <li>Get Payment Methods: Get</li>
              <li>Products: Read-only</li>
              <li>Customers: Modify</li>
              <li>Content: Modify</li>
              <li>Checkout Content: Modify</li>
              <li>Carts: Modify</li>
              <li>Checkouts: Modify</li>
              <li>Information &amp; Settings: Read-Only</li>
            </ul>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwOjh5ZwKDA"> </div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjNlvAKDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 8" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">Save the changes you just made, by clicking the "Update & Close" button, followed by the "Confirm Update" button</div>
            <div class="margin-next margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwKizybgKDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 9" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">Now go to your go to Apps -&gt; My Apps in your <a href="https://www.bigcommerce.com" target="_blank" rel="noreferer noopener">BigCommerce store admin dashboard</a>. And hover over the App you created and click on "Learn more"</div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwPDlhJUJDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 10" lines="none">
          <div class="slide-content">
            <div class="slide-header margin-next">Click on the "Install" button to install the app and confirm it by clicking “Confirm” button.</div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwMjU4dILDA"> </div>
            <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwKizybgJDA"> </div>
          </div>
        </ion-item>

        <ion-item class="step-item" v-if="propActiveStep == 11" lines="none">
          <div class="slide-content done">
            <div class="slide-header margin-next ion-text-center">Congratulations! You have successfully connected your BigCommerce account</div>
            <div class="sub-text ion-text-center margin-next">Now you can import your BigCommerce products and increase your sales. Please click the button below to import products.</div>
            <ion-item class="margin-next" lines="none">
              <ion-button v-if="!isStepAtFirst" class="btn-import-products ion-no-padding"
                fill="solid" color="primary" size="default">
                Import Products
              </ion-button>
            </ion-item>
          </div>
        </ion-item>

        <ion-item class="action" lines="none">
          <ion-buttons slot="end">
            <ion-button v-if="!isStepAtFirst" class="btn-previous ion-no-padding" @click="actionClick(0)" fill="outline">
              Previous Step
              <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="!isStepAtLast" class="btn-next ion-no-padding" @click="actionClick(1)" fill="solid" :disabled="isNextButtonDisabled">
              Next Step
              <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>

        <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, helpCircleOutline, linkOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapActions } from "vuex";
import stepProgress from "./step-progress.vue"
import HelpTooltip from "../HelpTooltip.vue";
import InputValidation from "../InputValidation.vue";


const steps = [
  {
    title: 'Go to Developer Portal',
    code: 'developer_portal'
  },
  {
    title: 'Authorize Application',
    code: 'auth_app'
  },
  {
    title: 'Create an App',
    code: 'create_app'
  },
  {
    title: 'Enter App Name',
    code: 'enter_app_name'
  },
  {
    title: 'Provide App Details',
    code: 'app_details'
  },
  {
    title: 'Go to Technical section',
    code: 'technical_section',
    disabled: true
  },
  {
    title: 'Setup Callback URLs',
    code: 'callback_urls',
    disabled: true
  },
  {
    title: 'OAuth Scopes',
    code: 'oauth_scope',
    disabled: true
  },
  {
    title: 'Confirm Updates',
    code: 'confirm_updates',
    disabled: true
  },
  {
    title: 'Go to My Apps in dashboard',
    code: 'my_apps',
    disabled: true
  },
  {
    title: 'Install App',
    code: 'install_app',
    disabled: true
  },
  {
    title: 'Done',
    code: 'done',
    disabled: true
  }
];

export default {
  components: { stepProgress, HelpTooltip, InputValidation },
  data() {
    return {
      steps,
      propActiveStep: 0,
      selectedStep: steps[0],
      chevronForwardOutline,
      chevronBackOutline,
      helpCircleOutline,
      linkOutline,
      callBackUrl: "https://jumperdevnew.appspot.com/bigcommerce/callback/5331170754560000",
      form: {
        bigCommerceClientSecret: "",
        bigCommerceClientId: "",
        isInvalidBigCommerceClientId: "",
        isInvalidBigCommerceClientSecret: "",
        invalidInputs: []
      },
      loading: false,
      connected: false
    }
  },
  computed: {
    isStepAtFirst() {
      return this.propActiveStep === 0;
    },
    isStepAtLast() {
      return this.steps.length === this.propActiveStep + 1;
    },
    isNextButtonDisabled() {
      return this.propActiveStep == 4 && this.isFormInvalid;
    },
    isFormInvalid() { // used this compute variable to keep next button enabled/disabled
      return this.form.invalidInputs.length;
    }
  },
  watch: {
    propActiveStep(value) {
      if (value > 4 || this.connected) {
        for (let i = 5; i < 12; i++) {
          this.steps[i].disabled = false;
        }
      } else {
        for (let i = 5; i < 12; i++) {
          this.steps[i].disabled = true;
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateIntegration: "integration/updateIntegration"
    }),
    copyCallBackUrl() {
      navigator.clipboard.writeText(this.callBackUrl).then( () => {
        this.updateToast({ open: true, message: "Callback url copied successfully.", type: "success" })
      }, function (err) {
        this.updateToast({ open: true, message: "Copy callback url failed.", type: "danger" })
      });
    },
    validationHandler({ isInvalid, onChange, inputName }) {
      if (isInvalid) {
        if (!this.form.invalidInputs.includes(inputName)) {
          this.form.invalidInputs.push(inputName);
        }
      } else {
        const index = this.form.invalidInputs.indexOf(inputName);
        if (index > -1) {
          this.form.invalidInputs.splice(index, 1);
        }
      }
      if (onChange) {
        this.form[inputName] = isInvalid;
      }
    },
    onSelectStep({ step, index }) {
      this.propActiveStep = index;
      this.selectedStep = step;
    },
    async actionClick(forward) {
      if (this.propActiveStep == 4 && forward) {
        const result = await this.connect();
        if (!result) {
          return;
        }
      }
      if (forward && this.propActiveStep < this.steps.length) {
        this.propActiveStep++;
      } else if (this.propActiveStep > 0) {
        this.propActiveStep--;
      }
      this.selectedStep = this.steps[this.propActiveStep];
    },
    async updateLocalState() {
      const easyParcelDataToUpdate = {
        integrationName: 'bigcommerce',
        dataToUpdate: { is_active: true },
        storageName: 'jmpr_getCommerceData'
      };
      await this.updateIntegration(easyParcelDataToUpdate);
    },
    async connect() {
      const reqBody = {
        client_secret: this.form.bigCommerceClientSecret,
        client_id: this.form.bigCommerceClientId
      }
      this.loading = true;
      const message = {
        success: () => this.updateToast({ open: true, message: "Connected to BigCommerce successfully.", type: "success" }),
        error: (message) => this.updateToast({ open: true, message: message || "BigCommerce connection failed.", type: "danger" })
      }
      try {
        const response = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "bigcommerce/bigcommerce-auth", reqBody);
        if (response.data.success) {
          this.updateLocalState();
          message.success();
          this.loading = false;
          this.connect = true;
          return true;
        }
        message.error(response.data.errorMessage);
      } catch (error) {
        message.error();
      }
      this.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
.shopify-wrapper-grid {
  height: 100%;
}

.shopify-wrapper-grid .first-row {
  height: 100%;
}

.btn-next {
  width: 160px;
  padding-right: 0;
}

.btn-next button {
  padding-right: 0;
}

.btn-previous {
  width: 160px;
}

.slide-content {
  padding-top: 10px;
}

.margin-next {
  margin-bottom: 10px;
}

.unordered-list ul {
  padding-left: 15px;
}

.action ion-buttons {
  padding: 20px 0;
}

.btn-import-products {
  width: 160px;
}

.slide-content.done {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
}

.slide-content.done .sub-text {
  font-size: 14px;
  color: var(--ion-color-medium);
}

ion-item.error {
  --ion-item-border-color: var(--ion-color-danger);
  --border-color: var(--ion-color-danger);
}

:deep .input-validator-wrapper {
  width: 100%;
}

.copy-url-wrapper {
  margin: 20px 0 30px 0;
  color: var(--ion-color-medium);
  background-color: #f5f5f5;
  cursor: not-allowed;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.step-item {
  min-height: 200px;
}
</style>