<template>
  <ion-page>
    <ion-header v-if="loginStatus === 'success'">
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-menu-button id="menu-button" color="light"></ion-menu-button>
        </ion-buttons>
        <ion-title id="page-title">{{ pageTitle }}</ion-title>
        <div v-if="!IS_ASIA" id="restart-intro" @click="restartIntro" class="start-intro" slot="end">
          Restart Intro
          <ion-icon class="start-intro-icon" slot="start" :icon="refreshCircleOutline"></ion-icon>
        </div>
        <ion-thumbnail v-if="!IS_ASIA" slot="end" class="ion-hide-md-down" style="width: auto; height: 40px; padding-left: 15px; padding-right: 15px;">
          <img v-if="isDarkMode" src="../resources/images/Vonage_Jumper_icon_whitebg_horizontal.svg" style="max-width: 100%; width: auto; height: 30px; margin-top: 5px;" />
          <img v-else src="../resources/images/Vonage_Jumper_icon_blackbg_horizontal.svg" style="max-width: 100%; width: auto; height: 30px; margin-top: 5px;" />
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot> </slot>
    </ion-content>
    <audio id="customerUnReadMessagesAudio" style="height: 0px; width: 0px; opacity: 0; position: fixed; bottom: -50px;">
      <source src="https://jumper.ai/web-s/resources/page-inbox-new-message.mp3" type="audio/mpeg">
      <source src="https://jumper.ai/web-s/resources/page-inbox-new-message.wav" type="aaudio/wav">
    </audio>
  </ion-page>
</template>
<script>
import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,IonMenuButton,IonButtons
} from "@ionic/vue";
import {
  arrowBackOutline, refreshCircleOutline
} from 'ionicons/icons';
import { mapGetters } from "vuex";
export default {
  components: {
    IonPage,IonHeader,IonContent,IonToolbar,IonTitle,IonMenuButton,IonButtons
  },
  props: ["pageTitle"],
  computed: {
    ...mapGetters({
      loginStatus: "auth/getLoginStatus",
      isDarkMode: "isDarkMode"
    }),
    thisRouterPath() {
      return this.$route.path;
    }
  },
  data(){
    return {
      arrowBackOutline, refreshCircleOutline
    }
  },
  methods: {
    openSearchModal(){
      document.dispatchEvent(new CustomEvent("openSearchModal"));
    },
    openFilterModal(){
      document.dispatchEvent(new CustomEvent("openFilterModal"));
    },
    restartIntro() {
      document.dispatchEvent(new CustomEvent("restartIntro"));
    }
  }
};
</script>

<style scoped>
.start-intro {
  margin: 0 20px;
  border: 1px solid var(--ion-color-primary-contrast);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
.start-intro-icon {
  font-size: 1.2rem;
  padding-left: 10px;
}
</style>