<template>
<master-layout pageTitle="Products">
  <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
   <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-toolbar class="chat-toolbar">
      <ion-buttons slot="start">
        <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline"></ion-icon>
        <h2 class="prdt-title ion-no-margin">Bulk add new products</h2>
      </ion-buttons>
    </ion-toolbar>

  <!-- if needed in future for switching tabs  -->
  <!-- <ion-toolbar color="light">
    <ion-segment color="dark" :value="activeTab">
      <ion-segment-button value="productData" @click="changeTab('productData')">
        <ion-label>Upload product data file</ion-label>
      </ion-segment-button>
      <ion-segment-button value="productVariations" @click="changeTab('productVariations')">
        <ion-label>Upload product variations date file</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar> -->

  <div style="width: 600px; margin: auto;" v-if="activeTab === 'productData'">
    <ion-card v-if="errorUploading && errorUploading.length">
      <ion-card-header>
        <ion-card-title>Error Uploading</ion-card-title>
        <ion-card-subtitle>These products have not been saved to your account, please check the remark and try again for
          these products</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-item>
          <div>Name</div>
          <div>Original Hashtag</div>
          <div>Final Hashtag</div>
          <div>Hashtag Changed</div>
          <div>Upload Status</div>
          <div>Remark</div>
        </ion-item>
        <ion-item v-for="(thisProduct, pIndex) in errorUploading" :key="'error' + pIndex">
          <div>{{ thisProduct.name }}</div>
          <div>{{ thisProduct.og_hashtag }}</div>
          <div>{{ thisProduct.new_hashtag }}</div>
          <div>{{ thisProduct.hashtag_changed }}</div>
          <div>{{ thisProduct.status }}</div>
          <div>{{ thisProduct.remark }}</div>
        </ion-item>
      </ion-card-content>
    </ion-card>
    <ion-card v-else-if="!csvToUpload">
      <ion-card-content>
        <ion-item lines="none">
          <input type="file" style="display: none;" id="productsDocument" ref="productsDocument" @input="selectFileToImport" accept=".csv" />
          <ion-button class="uploadBtn" @click="() => $refs.productsDocument.click()" expand="block" fill="">Select a .CSV or .TSV file</ion-button>
        </ion-item>
        <div class="ion-text-center selected-file-name" v-if="selectedFile">
          <span>{{selectedFile.name}}</span>
          <span>Selected</span>
          <span>
            <ion-buttons slot="start">
              <ion-button @click="removeSelectedFile" fill="clear" color="danger">
                <ion-icon size="small" :icon="closeCircleOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </span>
        </div>
        <ion-card-subtitle class="ion-text-center" style="margin: 24px auto;">Your files must have a .CSV or .TSV format and should have the product data added as per the sample file <a href="https://jumperdevnew.appspot.com/landing/resources/csv_files/jumperai-sample-csv-tsv-files.zip">here</a></ion-card-subtitle>
        <div class="ion-text-center">
          <ion-button :disabled="!selectedFile" @click="handleUpload" color="dark">Import</ion-button>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card v-else-if="successfullyUploaded && successfullyUploaded.length">
      <ion-card-header>
        <ion-card-title>Successfully uploaded products</ion-card-title>
        <ion-card-subtitle>These products have been successfully saved to your account</ion-card-subtitle>
      </ion-card-header>
      <!-- <ion-card-content>
        <ion-item>
          <div>Name</div>
          <div>Original Hashtag</div>
          <div>Final Hashtag</div>
          <div>Hashtag Changed</div>
          <div>Upload Status</div>
        </ion-item>
        <ion-item v-for="(thisProduct, pIndex) in successfullyUploaded" :key="'success' + pIndex">
          <div>{{ thisProduct.name }}</div>
          <div>{{ thisProduct.og_hashtag }}</div>
          <div>{{ thisProduct.new_hashtag }}</div>
          <div>{{ thisProduct.hashtag_changed }}</div>
          <div>{{ thisProduct.status }}</div>
        </ion-item>
      </ion-card-content> -->

      <table>
              <thead>
                <tr style="position: sticky; top: 0; background-color: white; z-index: 1;">
                  <th>Name</th>
                  <th>Original Hashtag</th>
                  <th>Final Hashtag</th>
                  <th>Hashtag Changed</th>
                  <th>Upload Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(thisProduct, pIndex) in successfullyUploaded" :key="'success' + pIndex" style="text-align:center;">
                  <td class="ion-text-center">{{ thisProduct.name }}</td>
                  <td class="ion-text-center">{{ thisProduct.og_hashtag }}</td>
                  <td class="ion-text-center">{{ thisProduct.new_hashtag }}</td>
                  <td class="ion-text-center">{{ thisProduct.hashtag_changed }}</td>
                  <td class="ion-text-center">{{ thisProduct.status }}</td>
                </tr>
              </tbody>
            </table>

    </ion-card>
  </div>
  <div v-if="activeTab === 'productVariations'">
    <h1>Nothing</h1>
  </div>

</master-layout>
</template>

<script>
import { chevronDownCircleOutline, arrowBackOutline, closeCircleOutline } from "ionicons/icons";
import jwtInterceptor from '/src/shared/jwtInterceptor';
export default {
  data() {
    return {
      chevronDownCircleOutline, arrowBackOutline,
      csvToUpload: undefined,
      loading: false,
      activeTab: 'productData',
      successfullyUploaded: [],
      errorUploading: [],
      selectedFile: undefined,
      closeCircleOutline
    }
  },
  methods: {
    singleErrorHandler(message) {
      this.updateToast({ open: true, message: message, type: "danger" });
      this.csvToUpload = undefined;
      this.errorUploading = [];
      this.loading = false;
    },
    removeSelectedFile() {
      this.$refs.productsDocument.value = "";
      this.selectedFile = undefined;
      this.csvToUpload = undefined;
      this.errorUploading = [];
      this.loading = false;
    },
    async validateAndUpload(headers, product) {
      let errorMessage = undefined;
      if (headers.length === 0 || product.length === 0) {
        return "Invalid Row";
      }

      let hashtagIndex = headers.indexOf('hashtag');
      if (hashtagIndex < 0) {
        return "Invalid Row, hashtag is required header";
      }

      let hashtag = product[hashtagIndex];
      if (!hashtag) {
        return "Invalid Row, hashtag is required header";
      }

      hashtag = hashtag.replace(/[^a-zA-Z0-9]/g,'').toLowerCase();
      if (hashtag == '' || hashtag.length > 150) {
        return "Invalid Row, hashtag can not be empty or more than 150 characters";
      }

      let quantityLimitIndex = headers.indexOf('max_quantity_buyer_can_order');
      if(quantityLimitIndex > -1) {
        var quantityLimit = product[quantityLimitIndex];
        if(!quantityLimit) quantityLimit = '1';
        if(quantityLimit == '' || Number.isInteger(quantityLimit) != true) quantityLimit = '99999999';
      }
      let accept_custom_notes_from_buyer_index = headers.indexOf('accept_custom_notes_from_buyer');
      if(accept_custom_notes_from_buyer_index > -1) {
        var accept_custom_notes_from_buyer = product[accept_custom_notes_from_buyer_index];
        if(accept_custom_notes_from_buyer !== undefined) {
          accept_custom_notes_from_buyer = accept_custom_notes_from_buyer.replace(' ', '');
          accept_custom_notes_from_buyer = accept_custom_notes_from_buyer.toLowerCase();
          if(accept_custom_notes_from_buyer == false || accept_custom_notes_from_buyer == 'false') accept_custom_notes_from_buyer = false;
          if(accept_custom_notes_from_buyer == true || accept_custom_notes_from_buyer == 'true') accept_custom_notes_from_buyer = true;
        }
      }
      let visible_on_shop_page_index = headers.indexOf('visible_on_shop_page');
      if(visible_on_shop_page_index > -1) {
        var visible_on_shop_page = product[visible_on_shop_page_index];
        if(visible_on_shop_page !== undefined) {
          visible_on_shop_page = visible_on_shop_page.replace(' ', '');
          visible_on_shop_page = visible_on_shop_page.toLowerCase();
          if(visible_on_shop_page == false || visible_on_shop_page == 'false') visible_on_shop_page = false;
          if(visible_on_shop_page == true || visible_on_shop_page == 'true') visible_on_shop_page = true;
        }
      }
      let click_and_collect_available_index = headers.indexOf('click_and_collect_available');
      if(click_and_collect_available_index > -1) {
        var click_and_collect_available = product[click_and_collect_available_index];
        if(click_and_collect_available !== undefined) {
          click_and_collect_available = click_and_collect_available.replace(' ', '');
          click_and_collect_available = click_and_collect_available.toLowerCase();
          if(click_and_collect_available == false || click_and_collect_available == 'false') click_and_collect_available = false;
          if(click_and_collect_available == true || click_and_collect_available == 'true') click_and_collect_available = true;
        }
      }
      let allow_the_shopper_to_buy_more_than_one_quantity_index = headers.indexOf('allow_the_shopper_to_buy_more_than_one_quantity');
      if(allow_the_shopper_to_buy_more_than_one_quantity_index > -1) {
        var allow_the_shopper_to_buy_more_than_one_quantity = product[allow_the_shopper_to_buy_more_than_one_quantity_index];
        if(allow_the_shopper_to_buy_more_than_one_quantity !== undefined) {
          allow_the_shopper_to_buy_more_than_one_quantity = allow_the_shopper_to_buy_more_than_one_quantity.replace(' ', '');
          allow_the_shopper_to_buy_more_than_one_quantity = allow_the_shopper_to_buy_more_than_one_quantity.toLowerCase();
          if(allow_the_shopper_to_buy_more_than_one_quantity == false || allow_the_shopper_to_buy_more_than_one_quantity == 'false') allow_the_shopper_to_buy_more_than_one_quantity = false;
          if(allow_the_shopper_to_buy_more_than_one_quantity == true || allow_the_shopper_to_buy_more_than_one_quantity == 'true') allow_the_shopper_to_buy_more_than_one_quantity = true;
        }
      }
      let weight_in_Kgs_index = headers.indexOf('weight_in_Kgs');
      if(weight_in_Kgs_index > -1) {
        let weight_in_Kgs = product[weight_in_Kgs_index];
        if(weight_in_Kgs !== undefined) {
          var grams = 0;
          //eslint-disable-next-line
          weight_in_Kgs = weight_in_Kgs.toString().replace(/[^0-9\.]/g,'');
          grams = parseFloat(weight_in_Kgs);
          grams = grams*1000;
        }
      }
      let height_index = headers.indexOf('height');
      if(height_index > -1) {
        var height = 0;
        height = product[height_index];
        //eslint-disable-next-line
        height = height.toString().replace(/[^0-9\.]/g,'');
        height = parseFloat(height);
      }
      let width_in_cm_index = headers.indexOf('width_in_cm');
      if(width_in_cm_index > -1) {
        var width = 0;
        width = product[width_in_cm_index];
        //eslint-disable-next-line
        width = width.toString().replace(/[^0-9\.]/g,'');
        width = parseFloat(width);
      }
      let length_in_cm_index = headers.indexOf('length_in_cm');
      if(length_in_cm_index > -1) {
        var length = 0;
        length = product[length_in_cm_index];
        //eslint-disable-next-line
        length = length.toString().replace(/[^0-9\.]/g,'');
        length = parseFloat(length);
      }
      var tags = "";
      let tags_index = headers.indexOf("tags");
      if(tags_index > -1) {
        tags = product[tags_index];
        if(tags.indexOf('@@') > -1){
          tags = tags.replace(/@@/g ,'');
        }
        else if(tags.indexOf(':::') > -1){
          tags = tags.replace(/:::/g ,'');
        }
        if(tags == '') tags = hashtag;
      } else {
        tags = hashtag;
      }
      let short_description_index = headers.indexOf('short_description');
      if(short_description_index > -1) {
        var short_description = product[short_description_index];
        short_description = short_description.toString().replace(/\\n/g ,'\n');
      }
      let name_index = headers.indexOf('name');
      if(name_index > -1) {
        var name = product[name_index];
        if (!name || name == '' || name.length > 300) {
          return "Name can not be empty or more than 300 in characters";
        }
      }
      let price_index = headers.indexOf('price');
      if (price_index < 0) {
        return "Price header is required";
      }
      var price = product[price_index];
      price = price.toString().replace(/,/g ,'').replace(/ /g ,'');
      price = parseFloat(price);
      if (Number.isInteger(price) != true && (price === +price && price !== (price | 0)) != true) {
        return "Price is required";
      }
      let stock_index = headers.indexOf('stock');
      if(stock_index > -1) {
        var stock = product[stock_index];
        stock = stock.toString().replace(/,/g ,'').replace(/ /g ,'');
        Number.isInteger(stock) != true && (stock === +stock && stock !== (stock|0)) != true ? stock = 0 : '';
      }
      let sku_index = headers.indexOf('sku');
      if(sku_index > -1) {
        var sku = product[sku_index];
      }
      var requestData = {
        currency: this.user.currency,
        hashtag: hashtag,
        name: name,
        description: short_description,
        cost: price ? price.toString() : undefined,
        totalcost: price ? price.toString() : undefined,
        stock: stock === undefined ? '0' : stock.toString(),
        sku: sku ? sku.toString() : undefined,
        notes: accept_custom_notes_from_buyer === undefined ? false : accept_custom_notes_from_buyer,
        shopActive: visible_on_shop_page === undefined ? true : visible_on_shop_page,
        askquantity: allow_the_shopper_to_buy_more_than_one_quantity === undefined ? true : allow_the_shopper_to_buy_more_than_one_quantity,
        quantity_limit: (allow_the_shopper_to_buy_more_than_one_quantity === false || allow_the_shopper_to_buy_more_than_one_quantity === undefined) ? '1' : quantityLimit,
        grams: grams ? grams.toString() : undefined,
        height: height ? height.toString() : undefined,
        width: width ? width.toString() : undefined,
        length: length ? length.toString() : undefined,
        weight_si_unit: "kg",
        length_si_unit: "cm",
        tax: "0",
        splitpayamount: "0",
        sellergetcost: "0",
        //image_urls: "",
        tags: tags,
        cash_on_delivery: "false",
        passfees: "false",
        calendarinvite: "false",
        skipaddress: "false",
        skipemail: "false",
        selloffline: click_and_collect_available === undefined ? false : click_and_collect_available
      };
      let image_url_index = headers.indexOf('image_url');
      if(image_url_index > -1) {
        let image_url = product[image_url_index];
        image_url ? requestData['image_url'] = this.externalSiteImage_convertIntoDownloadableLink(image_url) : requestData['image_url'] = "https://jumper.ai/web-s/resources/images/default-carousel-for-product.png";
      }
      let external_product_url_index = headers.indexOf('external_product_url');
      if(external_product_url_index > -1) {
        let external_product_url = product[external_product_url_index];
        requestData['url'] = external_product_url;
      }
      //adding extra fields in api request start
      for(var k=0; k < headers.length; k++){
        var key_label = headers[k];
        if(key_label != '' && key_label != ' ' && key_label != 'null' && key_label != 'None' && key_label != null && key_label != 'hashtag' && key_label != 'name' && key_label != 'short_description' && key_label != 'description' && key_label != 'image_url' && key_label != 'price' && key_label != 'sku' && key_label != 'stock' && key_label != 'weight_in_Kgs' && key_label != 'accept_custom_notes_from_buyer' && key_label != 'visible_on_shop_page' && key_label != 'click_and_collect_available' && key_label != 'allow_the_shopper_to_buy_more_than_one_quantity' && key_label != 'max_quantity_buyer_can_order' && key_label != 'height_in_cm' && key_label != 'width_in_cm' && key_label != 'length_in_cm') if(product[k] != '' && product[k] != null) requestData[key_label] = product[k];
      }
      //adding extra fields in api request end
      console.log(requestData);
      if(errorMessage === undefined) {
        try {
          let productSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "import-product", requestData);
          if ((productSaveResponse.status == 200 || productSaveResponse.status == 201) && productSaveResponse.data.success && productSaveResponse.data.id) {
            if(productSaveResponse.data.hashtag_changed == false){
              var finalHashtag = undefined;
              if(typeof productSaveResponse.data.hashtag != 'undefined' && productSaveResponse.data.hashtag != '' && productSaveResponse.data.hashtag != 'None' && productSaveResponse.data.hashtag != null) finalHashtag = productSaveResponse.data.hashtag.toString().replace(/[^a-zA-Z0-9]/g,'').toLowerCase();
              else finalHashtag = "-";
            }

            var long_description = requestData.description;
            if(typeof long_description != 'undefined' && long_description != null && long_description != ''){
              long_description = long_description.replace(/\n/g, '<br>');
            }
            let eventDetailReqBody = {
              productid: productSaveResponse.data.id,
              content: long_description
            };
            let addEventResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-event-detail", eventDetailReqBody);
            console.log("addEventResponse", addEventResponse);
            if ((addEventResponse.status == 200 || addEventResponse.status == 201) && addEventResponse.data.success) {
              this.successfullyUploaded.push({
                "id": productSaveResponse.data.id,
                "name": productSaveResponse.data.name,
                "hashtag": productSaveResponse.data.hashtag,
                "og_hashtag": hashtag,
                "new_hashtag": finalHashtag,
                "hashtag_changed": productSaveResponse.data.hashtag_changed,
                "status": productSaveResponse.data.status,
                "remark": ""
              })
            }
            else {
              if(addEventResponse.data.errorMessage && addEventResponse.data.errorMessage.length) {
                this.errorUploading.push({
                  "id": productSaveResponse.data.id,
                  "name": productSaveResponse.data.name,
                  "hashtag": productSaveResponse.data.hashtag,
                  "og_hashtag": hashtag,
                  "new_hashtag": finalHashtag,
                  "hashtag_changed": productSaveResponse.data.hashtag_changed,
                  "status": "failed",
                  "remark": addEventResponse.data.errorMessage
                })
              } else {
                this.errorUploading.push({
                  "id": productSaveResponse.data.id,
                  "name": productSaveResponse.data.name,
                  "hashtag": productSaveResponse.data.hashtag,
                  "og_hashtag": hashtag,
                  "new_hashtag": finalHashtag,
                  "hashtag_changed": productSaveResponse.data.hashtag_changed,
                  "status": "failed",
                  "remark": "Something went wrong in adding event detail"
                })
              }
            }
          }
          else {
            if(productSaveResponse.data.errorMessage && productSaveResponse.data.errorMessage.length) {
              this.errorUploading.push({
                "id": "none",
                "name": name,
                "hashtag": '#' + hashtag,
                "og_hashtag": '#' + hashtag,
                "new_hashtag": '#' + hashtag,
                "hashtag_changed": false,
                "status": 'failed',
                "remark": productSaveResponse.data.errorMessage
              })
            } else {
              this.errorUploading.push({
                "id": "none",
                "name": name,
                "hashtag": '#' + hashtag,
                "og_hashtag": '#' + hashtag,
                "new_hashtag": '#' + hashtag,
                "hashtag_changed": false,
                "status": 'failed',
                "remark": "Something went wrong in adding the product"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.errorUploading.push({
            "id": "none",
            "name": name,
            "hashtag": '#' + hashtag,
            "og_hashtag": '#' + hashtag,
            "new_hashtag": '#' + hashtag,
            "hashtag_changed": false,
            "status": 'failed',
            "remark": "Something went wrong in saving the data"
          })
        }
      } else {
        this.errorUploading.push({
          "id": "none",
          "name": name,
          "hashtag": '#' + hashtag,
          "og_hashtag": '#' + hashtag,
          "new_hashtag": '#' + hashtag,
          "hashtag_changed": false,
          "status": 'failed',
          "remark": errorMessage
        })
      }
    },
    selectFileToImport(event) {
      this.selectedFile = event.target.files[0];
    },
    async handleUpload() {
      try {
        this.loading = true;
        if (this.selectedFile && this.selectedFile.name.lastIndexOf('.csv') !== -1){
            let newCsv = new FileReader();
            newCsv.readAsText(this.selectedFile);
            newCsv.onload = async (event) => {
              this.loading = true;
              let csv = event.target.result;
              let lines = csv.split("\n");
              let headers = lines[0].split(",");
              if(headers.length < 4) {
                return "There should be minimum 4 columns in CSV";
              }
              this.successfullyUploaded = [];
              this.errorUploading = [];
              let validation;
              for(let i = 1; i < lines.length; i++) {
                validation = await this.validateAndUpload(headers, lines[i].split(","));
                console.log(">>>>", validation);
                if(validation) break;
              }
              if (validation && typeof validation === 'string' || validation instanceof String) {
                this.singleErrorHandler(validation);
                this.loading = false;
                return;
              }
              this.csvToUpload = this.selectedFile;
              this.loading = false;
            }
            newCsv.onerror = () => { 
              this.updateToast({
                open: true,
                message: "Unable to read " + this.selectedFile.name,
                type: "danger"
              })
            };
        } else {
          this.updateToast({
            open: true,
            message: "Please add a valid CSV",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        console.log("***** >>>>>");
        this.loading = false;
      }
    },
    changeTab(newTab) {
      this.activeTab = newTab;
    }
  }
}
</script>

<style scoped>
.uploadBtn {
  border-style: dashed;
  border-width: 2px;
  width: 60%;
  font-size: 16px;
  font-weight: 600;
  border-radius: 16px;
  margin: 0 auto;
  height: 200px;
  border-color: var(--ion-color-primary);
  color: var(--ion-color-primary);
  overflow: hidden;
}

.roundedBorder button {
  border-radius: 50px !important;
}
.selected-file-name {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.selected-file-name span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color: var(--ion-color-black);
}
</style>