<template>
  <master-layout pageTitle="Facebook Generate JSON">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div style="margin: 15px">
          <ion-label class="title">Generate JSON</ion-label>
          <p class="sub-title ion-no-margin">Create structured Ad message (Ad JSON).&nbsp;<a target="_blank" href="https://www.facebook.com/business/help/1646890868956360">Learn more</a></p>
        </div>
        <div style="margin: 15px" class="model-sections">
          <ion-grid>
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                 <ion-reorder-group @ionItemReorder="doReorder($event)" v-if="allElements.length" disabled="false">
                  <ion-row v-for="(thisElement, eIndex) in allElements" :key="eIndex" class="table-reorder ion-no-padding ion-justify-content-between rowMargin">
                    <ion-col class="ion-no-padding">
                      <!-- text -->
                      <div v-if="thisElement.type === 'text'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                        <ion-textarea v-model="thisElement.payload" rows="4" auto-grow="true" autofocus="true" class="textarea-box"></ion-textarea>
                        <div v-if="thisElement.buttons && thisElement.buttons.length" class="addNew">
                          <ion-button v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex" expand="block" fill="outline" @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                            {{ thisButton.text }}
                          </ion-button>
                        </div>
                        <div v-if="thisElement.buttons.length < 3" class="addNew">
                          <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                        </div>
                      </div>
                      <!-- image -->
                      <div v-if="thisElement.type === 'image'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                        <div class="img-block">
                          <input type="file" style="display: none;" id="image" ref="image" @change="handleImageUpload($event, eIndex)" accept="image/png, image/gif, image/jpeg" />
                          <img v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.image.click()" class="img-container" />
                          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"/>
                        </div>
                        <div class="upload-img">
                          <ion-button @click="() => $refs.image.click()" expand="block" fill="outline">Upload Image</ion-button>
                        </div>
                      </div>
                      <!-- video -->
                      <div v-if="thisElement.type === 'video'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                         <div class="img-block">
                          <input type="file" style="display: none;" id="video" ref="video" @change="handleVideoUpload($event, eIndex)" accept="video/mp4, video/mpeg4" />
                          <video v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.video.click()" class="img-container"></video>
                          <video v-else  poster="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/video-only.svg" class="img-container"></video>
                        </div>
                        <div class="upload-img">
                          <ion-button @click="() => $refs.video.click()" expand="block" fill="outline">Upload Video</ion-button>
                        </div>
                      </div>
                      <!-- product -->
                      <div v-if="thisElement.type === 'product'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                        <div class="img-block">
                          <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length" :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
                          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container" style="margin: 20px auto 20px auto"/>
                          <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                          <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                          <div v-if="thisElement.buttons && thisElement.buttons.length">
                            <ion-button v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container" fill="outline" @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                              {{ thisButton.text }}
                            </ion-button>
                          </div>
                        </div>
                        <div v-if="thisElement.buttons.length < 3" class="addNew">
                          <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                        </div>
                      </div>
                      <!-- collection -->
                      <div v-if="thisElement.type === 'collection'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                        <div class="img-block">
                          <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length" :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
                          <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container" style="margin: 20px auto 20px auto"/>
                          <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                          <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                          <div v-if="thisElement.buttons && thisElement.buttons.length">
                            <ion-button v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container" fill="outline" @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                              {{ thisButton.text }}
                            </ion-button>
                          </div>
                        </div>
                        <div v-if="thisElement.buttons.length < 3" class="addNew">
                          <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                        </div>
                      </div>
                      <!-- reply -->
                      <div v-if="thisElement.type === 'reply'">
                        <div style="display: inline-block; padding:10px 10px 0px 10px;">
                          <ion-reorder></ion-reorder>
                        </div>
                        <div class="addNew"> 
                          <my-input id="template_body" emoji name="template_body" :value="thisElement.payload" @onInput="(prop) => thisElement.payload = prop" placeholder="Enter a Reply" :maxlength="1024" />
                        </div>
                        <div v-if="thisElement.buttons && thisElement.buttons.length"  class="addNew">
                          <ion-button v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container" fill="outline" @click="() => { addEditModal = 'detailed'; editIndex = eIndex; buttonIndex = bIndex; }" style="margin-bottom: 15px">
                            {{ thisButton.text }}
                          </ion-button>
                        </div>
                        <div v-if="thisElement.buttons.length < 3" class="addNew">
                          <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                 </ion-reorder-group>
              </ion-col>
              <!-- JSON CODE Preview -->
              <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                <p v-if="finalJson && finalJson.length" class="sub-text">Ad JSON code:</p>
                <figure v-if="finalJson && finalJson.length">
                  <pre id="jsonText">{{ finalJson }}</pre>
                   <a @click="copy(finalJson)">Copy JSON to clipboard</a>
                </figure>
              </ion-col>
               <div class="text-center" v-if="finalJson && finalJson.length == 0" style="width: 100%; padding: 15px;">
                <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" class="filterImage"  />
                <h5>No JSON generated yet!</h5>
              </div>
            </ion-row>
          </ion-grid>
        </div>
        <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => addMode = true">
          <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
        </button>
      </div>
    </ion-content>
    <popper v-if="isPopperOpen" is_agent_or_group_req :type="addEditModal" :config="popperConfig" @close="() => {editIndex = -1; addEditModal = undefined; buttonIndex = -1;}" @submit="popperSubmit" />
    <ion-action-sheet
			:is-open="addMode"
			header="Insert Element"
			:buttons="addOptions"
			@didDismiss="() => addMode = false"
		>
		</ion-action-sheet>
    <ion-modal
      v-if="addProductMode"
      :is-open="addProductMode"
      @didDismiss="() => addProductMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin" style="padding-left: 10px">Choose Product</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addProductMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-searchbar v-model="productFilter" show-cancel-button="always"></ion-searchbar>
          <ion-list>
            <ion-item @click="() => { allElements.push({ type: 'product', payload: thisProduct, buttons: [{ text: 'Buy Now', payload: ('#' + thisProduct.hashtag) }] }); addProductMode = false; }" v-for="thisProduct in productsToShow" :key="thisProduct.id">
              <ion-label>{{ thisProduct.name }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="addCollectionMode"
      :is-open="addCollectionMode"
      @didDismiss="() => addCollectionMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin" style="padding-left: 10px">Choose Collection</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addCollectionMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-searchbar v-model="collectionFilter" show-cancel-button="always"></ion-searchbar>
          <ion-list>
            <ion-item @click="() => { allElements.push({ type: 'collection', payload: thisCollection, buttons: [{ text: 'Buy Now', payload: ('#' + thisCollection.hashtag) }] }); addCollectionMode = false; }" v-for="thisCollection in collectionsToShow" :key="thisCollection.id">
              <ion-label>{{ thisCollection.name }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, trashOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonReorderGroup, IonReorder, IonSearchbar, IonTextarea } from '@ionic/vue';
import popper from '/src/components/popper';

export default {
  components: {IonReorderGroup, IonReorder, popper, IonSearchbar, IonTextarea},
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      trashOutline,
      close,
      allProducts: [],
      allCollections: [],
      allElements: [],
      editIndex: -1,
      buttonIndex: -1,
      addEditModal: undefined,
      deleteAllMode: false,
      addCollectionMode: false,
      addProductMode: false,
      productFilter: undefined,
      collectionFilter: undefined,
      addMode: false,
      addOptions: [
        {
          text: "Text",
          handler: () => {
						this.allElements.push({ type: 'text', payload: undefined, buttons: [] });
          }
        },
        {
          text: "Image",
          handler: () => {
						this.allElements.push({ type: 'image', payload: undefined, attachment_id: undefined });
          }
        },
        {
          text: "Video",
          handler: () => {
						this.allElements.push({ type: 'video', payload: undefined, attachment_id: undefined });
          }
        },
        {
          text: "Product",
          handler: () => {
            this.addProductMode = true;
          }
        },
        {
          text: "Collection",
          handler: () => {
            this.addCollectionMode = true;
          }
        },
        {
          text: "Quick Replies",
          handler: () => {
						this.allElements.push({ type: 'reply', payload: undefined, buttons: [{ text: 'Button 1', payload: 'button 1' }] });
          }
        },
      ]
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    isPopperOpen() {
      return this.addEditModal === 'simple' || this.addEditModal === 'detailed';
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined;
      if(this.editIndex > -1 && this.buttonIndex > -1) {
        text = this.allElements[this.editIndex].buttons[this.buttonIndex].text;
        payload = this.allElements[this.editIndex].buttons[this.buttonIndex].payload;
      }
      return { text: text, action: action, payload: payload }
    },
    productsToShow() {
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCollections.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCollections;
    },
    finalJson() {
      return this.allElements.map(e => {
        switch(e.type) {
          case 'text': return e.buttons === undefined || e.buttons.length === 0 ? { "message": {
            "text": e.payload
          }} : {
            "message": {
              "attachment": {
                "type": "template",
                "payload": {
                  "template_type": "button",
                  "text": e.payload,
                  "buttons": e.buttons.map(f => {return { "type": "postback", "title": f.text, "payload": f.payload }})
                }
              }
            }
          }
          case 'image': return {
            "message": {
              "attachment": {
                "type": "template",
                "payload": {
                  "template_type": "media",
                  "elements": [
                    {
                      "media_type": "image",
                      "attachment_id": e.attachment_id
                    }
                  ]
                }
              }
            }
          }
          case 'video': return {
            "message": {
              "attachment": {
                "type": "template",
                "payload": {
                  "template_type": "media",
                  "elements": [
                    {
                      "media_type": "video",
                      "attachment_id": e.attachment_id
                    }
                  ]
                }
              }
            }
          }
          case 'product': return {
            "message": {
              "attachment": {
                "type": "template",
                "payload": {
                  "template_type": "generic",
                  "image_aspect_ratio": "square",
                  "elements": [
                    {
                      "title": e.payload.title,
                      "subtitle": e.payload.currency + " " + e.payload.totalcost,
                      "image_url": e.payload.images && e.payload.images.length ? e.payload.images[0] : undefined,
                      "buttons": e.buttons.map(f => {return { "type": "postback", "title": f.text, "payload": f.payload }})
                    }
                  ]
                }
              }
            }
          }
          case 'collection': return {
            "message": {
              "attachment": {
                "type": "template",
                "payload": {
                  "template_type": "generic",
                  "image_aspect_ratio": "square",
                  "elements": [
                    {
                      "title": e.payload.title,
                      "subtitle": e.payload.currency + " " + e.payload.totalcost,
                      "image_url": e.payload.images && e.payload.images.length ? e.payload.images[0] : undefined,
                      "buttons": e.buttons.map(f => {return { "type": "postback", "title": f.text, "payload": f.payload }})
                    }
                  ]
                }
              }
            }
          }
          case 'reply': return e.buttons === undefined || e.buttons.length === 0 ? { "message": {
            "text": e.payload
          }} : {
            "message": {
              "text": e.payload,
              "quick_replies": e.buttons.map(f => {return { "content_type": "text", "title": f.text, "payload": f.payload }})
            }
          }
        }
      })
    }
  },
  methods: {
    // eslint-disable-next-line
    doReorder(e) {
      this.allElements = e.detail.complete(this.allElements);
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      console.log("Initializing");
      this.fetchProducts();
    },
    async fetchProducts() {
      try {
        this.loading = true;
        let shopListResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((shopListResponse.status == 200 || shopListResponse.status == 201) && shopListResponse.data.success) {
          if(shopListResponse.data.products && shopListResponse.data.products.length) {
            this.allProducts = shopListResponse.data.products.filter(e => !e.bundle && !e.catalog);
            this.allCollections = shopListResponse.data.products.filter(e => !e.bundle && e.catalog);
          }
        } else {
          if(shopListResponse.data && !shopListResponse.data.success && shopListResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: shopListResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    popperSubmit(data) {
      console.log(data);
      let text = data.text; let payload = undefined;
      switch(data.action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
        case 'custom': payload = data.payload;
        break;
      }
      if(this.editIndex > -1 && this.buttonIndex > -1) {
        this.allElements[this.editIndex].buttons[this.buttonIndex].text = text;
        this.allElements[this.editIndex].buttons[this.buttonIndex].payload = payload;
      } else {
        if(this.editIndex > -1) this.allElements[this.editIndex].buttons.push({ text: text, payload: payload });
        else console.log("Something went wrong");
      }
      this.addEditModal = undefined;
      this.editIndex = -1;
      this.buttonIndex = -1;
    },
    async handleImageUpload (event, elemIndex) {
      try {
        if(elemIndex > -1) {
          this.loading = true;
          if(event.target.files && event.target.files.length) {
            let newImage = event.target.files[0];
            console.log(newImage);
            if(await this.validateImage(newImage, 4)) {
              let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                this.allElements[elemIndex].payload = uploadSendResponseData.url;
                let reqBody = {
                  mediatype: 'image',
                  url: this.allElements[elemIndex].payload
                }
                let uploadAttachmentResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'upload-attachment', reqBody);
                if ((uploadAttachmentResponse.status == 200 || uploadAttachmentResponse.status == 201) && uploadAttachmentResponse.data.success && uploadAttachmentResponse.data.attachment_id) {
                  this.allElements[elemIndex].attachment_id = uploadAttachmentResponse.data.attachment_id;
                  this.updateToast({
                    open: true,
                    message: "Uploaded attachment successfully!",
                    type: "success"
                  })
                } else {
                  if(uploadAttachmentResponse.data.errorMessage && uploadAttachmentResponse.data.errorMessage.length) {
                    this.updateToast({
                      open: true,
                      message: uploadAttachmentResponse.data.errorMessage,
                      type: "danger"
                    })
                  } else {
                    this.updateToast({
                      open: true,
                      message: "Something went wrong",
                      type: "danger"
                    })
                  }
                }
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong uploading the image",
                  type: "danger"
                })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({
                open: true,
                message: "Invalid picture",
                type: "danger"
              })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async handleVideoUpload (event, elemIndex) {
      try {
        if(elemIndex > -1) {
          this.loading = true;
          if(event.target.files && event.target.files.length) {
            let newVideo = event.target.files[0];
            console.log(newVideo);
            if(await this.validateVideo(newVideo, 20)) {
              let uploadSendResponseData = await this.uploadVideo(newVideo, '/add-doc');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                this.allElements[elemIndex].payload = uploadSendResponseData.url;
                let reqBody = {
                  mediatype: 'video',
                  url: this.allElements[elemIndex].payload
                }
                let uploadAttachmentResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'upload-attachment', reqBody);
                if ((uploadAttachmentResponse.status == 200 || uploadAttachmentResponse.status == 201) && uploadAttachmentResponse.data.success && uploadAttachmentResponse.data.attachment_id) {
                  this.allElements[elemIndex].attachment_id = uploadAttachmentResponse.data.attachment_id;
                  this.updateToast({
                    open: true,
                    message: "Uploaded attachment successfully!",
                    type: "success"
                  })
                } else {
                  if(uploadAttachmentResponse.data.errorMessage && uploadAttachmentResponse.data.errorMessage.length) {
                    this.updateToast({
                      open: true,
                      message: uploadAttachmentResponse.data.errorMessage,
                      type: "danger"
                    })
                  } else {
                    this.updateToast({
                      open: true,
                      message: "Something went wrong",
                      type: "danger"
                    })
                  }
                }
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong uploading the image",
                  type: "danger"
                })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({
                open: true,
                message: "Invalid picture",
                type: "danger"
              })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  padding-left: 15px;
  padding-bottom: 5px;
}
.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-left: 15px;
}

.sub-text{
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 38px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
}
.upload-img {
  max-width: 453px;
  margin-left: 50px;
}
.filterImage {
  max-width: 300px; 
  width: 100%;
  height: auto; 
  display: block;
  margin: 0 auto;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
}
.inpt-container {
  width: 360px;
  margin: 0px 30px 10px 46px;
}
.addRow:hover {
   border: 2px #881fff dashed;
}

.addNew {
  max-width: 450px;
  margin-left: 50px;
}
.textarea-box {
  width: 450px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
}
.img-block{
   border-radius: 5px;
    border: 2px #eee dashed;
    width: 450px;
    display: inline-block;
}
.jinput {
  background-color: #ffffff;
}
@media only screen and (max-width: 760px){
  .addNew {
    max-width: 100%;
    margin-left: 0px;
  }
  .upload-img {
    max-width: 100%;
    margin-left: 0px;
  }
  .textarea-box{
    width: 100%;
  }
  .img-block{
    width: 100%;
  }
  .inpt-container{
    margin: 0px 10px 10px 10px;
    width: 95%;
  }
  .jinput{
    width: 98%;
  }

}
</style>