<template>
  <master-layout pageTitle="Facebook Persistent Menu">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="ion-hide-md-up toolbar">
          <ion-segment scrollable color="dark" v-model="activeTab">
            <ion-segment-button value="menu">
              <ion-label class="common-label">menu</ion-label>
            </ion-segment-button>
            <ion-segment-button value="preview">
              <ion-label class="common-label">Preview</ion-label>
            </ion-segment-button>
          </ion-segment>
         </ion-toolbar>
        <div v-if="changed">
          <ion-item>
            <p class="connectNote" style="color: #aaa; font-size: 13px; padding-top: 2px;">
              <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
              You have pending changes to be saved, please click on "Save all changes" button!
            </p>
            <ion-button slot="end" @click="saveChanges" color="dark">Save</ion-button>
          </ion-item>
        </div>
        <div v-if="activeTab === 'menu'">
          <h2 class="title ion-no-margin">Persistent menu</h2>
        </div>
        <ion-grid>
          <ion-row>
              <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
               <div v-if="activeTab === 'menu'">
                <ion-card style="border-radius: 10px">
                    <ion-card-header>
                      <ion-card-subtitle class="sub-title">Manage if users can use the text composer to send messages. If disabled users can only interact via buttons in the automated messages and main menu.</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                      <div class="text-center" v-if="allCtas.length == 0" style="width: 100%; padding: 15px;">
                        <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
                        <h5>No CTAs yet! 😟</h5>
                        <ion-text color="medium">Seems like there are no CTAs added yet</ion-text>
                      </div>
                      <div>
                        <ion-checkbox v-model="userInput" slot="end" :value="true"></ion-checkbox>
                        <ion-label class="text">Allow user input</ion-label>
                      </div>
                      <div class="container">
                        <ion-reorder-group @ionItemReorder="doReorder($event)" v-if="allCtas.length > 0" :disabled="false">
                          <ion-row v-for="(thisCta, cIndex) in allCtas" :key="cIndex" class="table-reorder ion-no-padding ion-justify-content-between rowMargin">
                            <ion-col class="ion-no-padding" style="border: 1px solid #666666">
                                <div style="display: inline-block; padding:9px 10px 0px 10px;">
                                  <ion-reorder></ion-reorder>
                                </div>
                                <div class="reorderText">
                                  <ion-label class="common-label ion-no-padding ion-no-margin">{{ thisCta.title }}</ion-label>
                                </div>
                                <span style="float:right;">
                                  <ion-button @click="() => { addEditModal = 'detailed'; editIndex = cIndex; }" fill="clear" size="default" color="dark">
                                    <ion-icon :icon="pencilOutline"></ion-icon>
                                  </ion-button>
                                  <ion-button @click="() => allCtas.splice(cIndex, 1)" size="default" fill="clear" color="danger">
                                    <ion-icon :icon="trashOutline"></ion-icon>
                                  </ion-button>
                                </span>
                            </ion-col>
                          </ion-row>
                        </ion-reorder-group>
                      </div>
                      <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => { addEditModal = 'detailed'; editIndex = -1 }">
                        <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
                      </button>
                    </ion-card-content>
                  </ion-card>
                  <div v-if="menu.hamburger">
                    <ion-label class="sub-title" style="margin-left: 10px">Do you want to disable the persistent menu from Messenger?</ion-label>
                    <div style="margin-top: 10px; margin-left: 5px">
                    <ion-button slot="end" @click="() => disableHamburgerMode = true" fill="outline" color="danger" style="border: 1px solid #eb445a; border-radius: 5px;">
                      Disable Menu
                    </ion-button>
                    </div>
                  </div>
               </div>
              </ion-col>
            <!-- preview -->
            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
              <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
                <i-phone-preview>
                  <div v-for="(thisCta, cIndex) in allCtas" :key="cIndex">
                    <input class="preview" type="text" placeholder="New menu" :value="thisCta.title" disabled />
                  </div>
                </i-phone-preview>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <popper v-if="isPopperOpen" is_agent_or_group_req type="persistent" :config="popperConfig" @close="() => {editIndex = -1; addEditModal = undefined;}" @submit="popperSubmit" />
    <ion-alert
      v-if="disableHamburgerMode"
      :is-open="disableHamburgerMode"
      header="Are you sure?"
      :message="'You want to disable the hamburger menu from messenger?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disableHamburgerMode = false,
      },
      {
        text: 'Yes',
        handler: () => confirmDisableHamburger(),
      }]"
      @didDismiss="() => disableHamburgerMode = false"
    />
  </master-layout>
</template>

<script>
import { IonCheckbox, IonReorderGroup, IonReorder } from '@ionic/vue';
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, pencilOutline, informationCircleOutline, trashOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import popper from '/src/components/popper';
import iPhonePreview from '/src/components/iPhonePreview';

export default {
  components: { IonCheckbox, IonReorderGroup, IonReorder, popper, iPhonePreview },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      trashOutline,
      pencilOutline,
      informationCircleOutline,
      menu: {
        composer_disabled: false,
        cta: [],
        get_started: {
          payload: undefined
        },
        hamburger: false,
        subscription: undefined,
        third_menu: false,
        whitelisted_domains: []
      },
      jumperDomain: ['https://jumperdevnew.appspot.com/', 'https://lh3.googleusercontent.com/', 'https://dev.jumperdevnew.appspot.com/'],
      userInput: false,
      allCtas: [],
      allCtasClone: [],
      addEditModal: undefined,
      editIndex: -1,
      disableHamburgerMode: false,
      activeTab: 'menu'
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) await this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    isPopperOpen() {
      return this.addEditModal === 'simple' || this.addEditModal === 'detailed';
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined;
      if(this.editIndex > -1) {
        text = this.allCtas[this.editIndex].title;
        payload = this.allCtas[this.editIndex].payload;
      }
      return { text: text, action: action, payload: payload }
    },
    changed() {
      return JSON.stringify(this.allCtas) !== JSON.stringify(this.allCtasClone) || this.menu.composer_disabled === this.userInput
    }
  },
  methods: {
    // eslint-disable-next-line
    doReorder(e) {
      this.allCtas = e.detail.complete(this.allCtas);
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchMenu();
    },
    async fetchMenu() {
      this.loading = true;
      try {
        let fetchMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-menu", { pageid: this.facebookSocialData.pages[0].messenger });
        if ((fetchMenuResponse.status == 200 || fetchMenuResponse.status == 201) && fetchMenuResponse.data.success) {
          this.allCtas = [];
          for(var prop in this.menu) this.menu[prop] = fetchMenuResponse.data[prop];
          this.userInput = !this.menu.composer_disabled;
          if(this.menu.cta && this.menu.cta.length) {
            this.menu.cta.forEach(thisCta => {
              if(thisCta.url) this.allCtas.push({ title: thisCta.title, payload: thisCta.url })
              else this.allCtas.push({ title: thisCta.title, payload: thisCta.payload })
            })
          }
          this.allCtasClone = JSON.parse(JSON.stringify(this.allCtas));
        } else {
          if(fetchMenuResponse.data && !fetchMenuResponse.data.success && fetchMenuResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: fetchMenuResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    popperSubmit(data) {
      let title = data.text; let payload = undefined;
      switch(data.action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
        default: payload = data.payload && data.payload.length ? data.payload : undefined
      }
      if(this.editIndex > -1) {
        this.allCtas[this.editIndex].title = title;
        this.allCtas[this.editIndex].payload = payload;
        this.editIndex = -1;
      } else this.allCtas.push({ title: title, payload: payload });
      this.addEditModal = undefined;
    },
    async saveChanges() {
      try {
        this.loading = true;
        let reqBodyCTAs = [];
        let reqBodyURLs = [];
        if(this.allCtas && this.allCtas.length) {
          this.allCtas.forEach(thisCTA => {
            if(thisCTA.payload.includes("https://") || thisCTA.payload.includes("http://")) {
              reqBodyCTAs.push({ type: "web_url", title: thisCTA.title, url: thisCTA.payload });
              reqBodyURLs.push(thisCTA.payload);
            }
            else reqBodyCTAs.push({ type: "postback", title: thisCTA.title, payload: thisCTA.payload })
          })
        }
        let reqBody = {
          pageid: this.facebookSocialData.pages[0].messenger,
          get_started: JSON.stringify(this.menu.get_started),
          composer_disabled: !this.userInput,
          cta: JSON.stringify(reqBodyCTAs),
          urls: reqBodyURLs.join(',')
        }
        let saveCtasResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'add-custom-menu', reqBody);
        if ((saveCtasResponse.status == 200 || saveCtasResponse.status == 201) && saveCtasResponse.data.success) {
          if(saveCtasResponse.data.changed) {
            this.updateToast({
              open: true,
              message: "Menu updated successfully",
              type: "success"
            })
            await this.init();
          }
        } else {
          if(saveCtasResponse.data.errorMessage && saveCtasResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveCtasResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async confirmDisableHamburger() {
      try {
        this.loading = true;
        let confirmDisableHamburgerResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'delete-hamburger');
        if ((confirmDisableHamburgerResponse.status == 200 || confirmDisableHamburgerResponse.status == 201) && confirmDisableHamburgerResponse.data.success) {
          if(confirmDisableHamburgerResponse.data.changed) {
            this.updateToast({
              open: true,
              message: "Your hamburger menu has been removed from Messenger.",
              type: "success"
            })
            await this.init();
          }
        } else {
          if(confirmDisableHamburgerResponse.data.errorMessage && confirmDisableHamburgerResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: confirmDisableHamburgerResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.disableHamburgerMode = false;
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
  margin-top: 10px;
}
.sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-grey);
}
.container {
  max-width: 450px;
  margin-top: 20px;
}
.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}
.text {
  font-size: 16px;
  color: var(--ion-color-black);
  margin-left: 10px;
  font-weight: 400;
}
.reorderText {
  display: inline-block;
  position: absolute;
  top: 13px;
}
input.preview {
  padding: 10px;
  border: none;
  background: transparent;
  width: 100%;
  color: #262626;
}

@media only screen and (max-width: 760px) {
  .prevMobile {
  display: block;
 }
  .prevDesktop {
    display: none;
  }
}
</style>