<template>
  <master-layout pageTitle="Policies & consent">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" v-model="activeTab">
            <ion-segment-button value="privacy-consent">
              <ion-label>Privacy Consent</ion-label>
            </ion-segment-button>
            <ion-segment-button value="privacy-policy">
              <ion-label>Privacy Policy</ion-label>
            </ion-segment-button>
            <ion-segment-button value="shipping-policy">
              <ion-label>Shipping Policy</ion-label>
            </ion-segment-button>
            <ion-segment-button value="refund-policy">
              <ion-label>Refund Policy</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="activeTab === 'privacy-consent'">
          <div class="infoBanner">
            <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
              <div class="panel-body">
                <h4>We will be migrating our privacy policy consent feature o a brand new version. V1 will be depricated n 1st June 2022. You are encouraged to setup before 15th to avoid discrepancies.</h4>
                <ion-button slot="end">Go to Policies V2</ion-button>
              </div>
            </div>
          </div>
          <ion-card>
            <ion-card-header>
              <ion-card-title>Privacy consent</ion-card-title>
              <ion-card-subtitle>When a user starts a conversation with your channel for the first time, they will be prompted with privacy consent messages. A user will be prompted this only once for every version you create, and you can set the method of collecting consent below.</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-radio-group v-model="tutorial">
                <ion-list-header>
                  <ion-label>Consent</ion-label>
                </ion-list-header>
                <ion-item>
                  <ion-label>Ask for consent</ion-label>
                  <ion-radio slot="start" :value="false"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Don't ask for consent</ion-label>
                  <ion-radio slot="start" :value="true"></ion-radio>
                </ion-item>
              </ion-radio-group>
              <div v-if="tutorial === false" style="width: 100%;">
                <ion-item lines="none">
                  <ion-label>Customers will be asked for their consent before they provide their personal details while making their first purchase.</ion-label>
                </ion-item>
                <ion-text color="primary" @click="() => $router.push('/automations/personalization')">Edit Message</ion-text>
                <div class="chatBubbleContainerForPreview">
                  <p>Before we move further we need you to agree to our Privacy Policy, as we'll be collecting personal information for the purpose of your order. You can read our policy by clicking on the button below.</p>
                  <div class="chatBubbleInnerbtn">
                    <button><a>Privacy policy</a></button>
                    <button><a>I agree</a></button>
                  </div>
                </div>
                <div class="infoBanner danger">
                  <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
                    <div class="panel-body">
                      <h4>Make sure you have added your privacy policy and keep it up to date. When a customer clicks the 'Privacy Policy' button above, we will open your privacy policy in a web view.</h4>
                      <ion-text color="primary" @click="() => activeTab = 'privacy-policy'">Add Privacy Policy</ion-text>
                    </div>
                  </div>
                </div>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
        <div v-if="activeTab === 'privacy-policy'">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Privacy policy</ion-card-title>
              <ion-card-subtitle>When saved, customers can open a link to this on web landing pages, message button and menus (optional).</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ckeditor rows="4" placeholder="Enter Refund Policy" style="height: 500px;" :editor="editor" v-model="privacyPolicy" :config="editorConfig"></ckeditor>
              <ion-item lines="none" style="margin: 20px 0;">
                <ion-button @click="updatePolicy('privacy')" slot="end">Update</ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </div>
        <div v-if="activeTab === 'shipping-policy'">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Shipping policy</ion-card-title>
              <ion-card-subtitle>When saved, customers can open a link to this on web landing pages, message button and menus (optional).</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ckeditor rows="4" placeholder="Enter Refund Policy" style="height: 500px;" :editor="editor" v-model="shippingPolicy" :config="editorConfig"></ckeditor>
              <ion-item lines="none" style="margin: 20px 0;">
                <ion-button @click="updatePolicy('shipping')" slot="end">Update</ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </div>
        <div v-if="activeTab === 'refund-policy'">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Refund policy</ion-card-title>
              <ion-card-subtitle>When saved, customers can open a link to this on web landing pages, message button and menus (optional).</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ckeditor rows="4" placeholder="Enter Refund Policy" style="height: 500px;" :editor="editor" v-model="returnPolicy" :config="editorConfig"></ckeditor>
              <ion-item lines="none" style="margin: 20px 0;">
                <ion-button @click="updatePolicy('return')" slot="end">Update</ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { IonRadioGroup, IonRadio } from "@ionic/vue";

export default {
  components: { IonRadioGroup, IonRadio },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      chevronDownCircleOutline,
      loading: false,
      activeTab: 'privacy-consent',
      allPolicies: [],
      privacyPolicy: undefined,
      shippingPolicy: undefined,
      returnPolicy: undefined,
      tutorial: undefined
    }
  },
  watch: {
    async tutorial(n, o) {
      if(o === undefined) return;
      else {
        if(n === o) return;
        else {
          try {
            this.loading = true;
            let reqBody = {
              tutorial: this.tutorial
            }
            let updateTutorialResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-user", reqBody);
            if ((updateTutorialResponse.status == 200 || updateTutorialResponse.status == 201) && updateTutorialResponse.data.success) {
              this.tutorial = updateTutorialResponse.data.tutorial;
              this.updateToast({
                open: true,
                message: this.tutorial ? "GDPR consent is disabled successfully" : "GDPR consent is enabled successfully",
                type: "success"
              })
              await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
              window.location.href = window.location.pathname;
            } else {
              if(updateTutorialResponse.data && !updateTutorialResponse.data.success && updateTutorialResponse.data.errorMessage && updateTutorialResponse.data.errorMessage.length) {
                this.updateToast({
                  open: true,
                  message: updateTutorialResponse.data.errorMessage,
                  type: "danger"
                })
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong",
                  type: "danger"
                })
              }
            }
          } catch (error) {
            console.log("Exception: ", error);
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          } finally {
            this.loading = false;
          }
        }
      }
    }
  },
  async ionViewWillEnter() {
    await this.init();
  },
  methods: {
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      this.tutorial = this.user.tutorial;
      await this.fetchPolicies();
    },
    async fetchPolicies() {
      try {
        this.loading = true;
        let fetchPoliciesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-policy");
        if ((fetchPoliciesResponse.status == 200 || fetchPoliciesResponse.status == 201) && fetchPoliciesResponse.data.success) {
          this.allPolicies = fetchPoliciesResponse.data.policy;
          this.allPolicies.forEach(thisPolicy => {
            switch(thisPolicy.type) {
              case 'privacy': this.privacyPolicy = thisPolicy.content;
              break;
              case 'shipping': this.shippingPolicy = thisPolicy.content;
              break;
              case 'return': this.returnPolicy = thisPolicy.content;
              break;
              default: console.log("Something is not right in fetch response");
            }
          })
        } else {
          if(fetchPoliciesResponse.data && !fetchPoliciesResponse.data.success && fetchPoliciesResponse.data.errorMessage && fetchPoliciesResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchPoliciesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updatePolicy(type) {
      try {
        this.loading = true;
        let thisPolicy = this.allPolicies.find(e => e.type === type);
        let policy_id = thisPolicy.id ? thisPolicy.id : undefined;
        let reqBody = {
          type: undefined,
          content: undefined,
          policy_id: policy_id
        }
        switch(type) {
          case 'privacy': reqBody.type = 'privacy';
          reqBody.content = this.privacyPolicy;
          break;
          case 'shipping': reqBody.type = 'shipping';
          reqBody.content = this.shippingPolicy;
          break;
          case 'return': reqBody.type = 'return';
          reqBody.content = this.returnPolicy;
          break;
          default: console.log("Something is not right");
        }
        let updatePolicyResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-policy", reqBody);
        if ((updatePolicyResponse.status == 200 || updatePolicyResponse.status == 201) && updatePolicyResponse.data.success) {
          await this.fetchPolicies();
        } else {
          if(updatePolicyResponse.data && !updatePolicyResponse.data.success && updatePolicyResponse.data.errorMessage && updatePolicyResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: updatePolicyResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.chatBubbleContainerForPreview p{
  padding: 20px;
  text-align: left;
  font-size: 12px;
}
.chatBubbleContainerForPreview{
  background: #f6f7f9;
  border: 1px solid #eee;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  margin: 0 auto;
  width: 80%;
  position: relative;
  margin-left: 40px;
}
.chatBubbleContainerForPreview::before{
  content: '';
  height: 25px;
  width: 25px;
  background-color: #f97873;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -33px;
  top: 10px;
}
.chatBubbleInnerbtn{
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: #fff;
}
.chatBubbleInnerbtn button{
  width: 100%;
  border-top: 0.5px solid #eee;
  border-bottom: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  padding: 8px 10px;
}
.chatBubbleInnerbtn button:focus{
  outline: none;
}
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
}

.infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}

.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}

.infoBanner.success .panel .panel-body{
  border-left: 3px #099e6e solid;
}

.infoBanner.danger .panel.panel-default{
  background-color: #ffd4d4;
}

.infoBanner.danger .panel .panel-body{
  border-left: 3px #ff0000 solid;
}

.panel-body {
  padding: 20px;
}
</style>