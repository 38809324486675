<template>
  <master-layout pageTitle="Whatsapp Widget">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
       <ion-toolbar class="ion-hide-md-up toolbar">
        <ion-segment scrollable color="dark" v-model="activeTab">
          <ion-segment-button value="widget">
            <ion-label class="common-label">whatsapp-widget</ion-label>
          </ion-segment-button>
          <ion-segment-button value="preview">
            <ion-label class="common-label">Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
       </ion-toolbar>
        <h2 class="header">WhatsApp widget</h2>
        <ion-grid class="container">
          <ion-row>
            <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding"> 
              <div v-if="activeTab === 'widget'" class="model-sections">   
                <div class="header" style="margin-bottom: 10px">
                  <ion-label>
                    <h3 class="title">Generate embed code</h3>
                    <p class="sub-title">Customize your WhatsApp widget and embed it on your webview!</p>
                  </ion-label>
                </div>
                <ion-grid class="gridMargin">
                  <ion-row class="rowMargin">
                    <ion-col class="colMargin">
                      <ion-label>Background Color</ion-label>
                    </ion-col>
                    <ion-col>
                      <div class="colorPickerWrapper" :style="{'background-color':welcomeMessage.theme_color}">
                        <ion-input v-model="welcomeMessage.theme_color" type="color" class="themePicker"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row class="rowMargin">
                    <ion-col class="colMargin">
                      <ion-label>Text Color</ion-label>
                    </ion-col>
                    <ion-col>
                      <div class="colorPickerWrapper" :style="{'background-color':welcomeMessage.text_color}">
                        <ion-input v-model="welcomeMessage.text_color" type="color" class="themePicker"></ion-input>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row class="rowMargin">
                    <ion-col class="colMargin">
                      <ion-label>Greeting text</ion-label>
                    </ion-col>
                    <ion-col>
                      <ion-textarea v-model="welcomeMessage.welcome_screen_greeting" rows="1" placeholder="Enter greeting text" class="fullBordered input_custom_style"></ion-textarea>
                    </ion-col>
                  </ion-row>
                  <ion-row class="rowMargin">
                    <ion-col class="colMargin">
                      <ion-label>Intent message</ion-label>
                    </ion-col>
                    <ion-col>
                      <ion-textarea v-model="welcomeMessage.intent_message" rows="1" placeholder="Enter a message" class="fullBordered input_custom_style"></ion-textarea>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <div class="embedCodeAreaContainer">
                  <p>Copy and paste the below code to the section of your website:</p>
                    <figure class="highlight">
                    <pre>{{ widgetScript }}</pre>
                    <a @click="copy(widgetScript)">Copy to Clipboard</a>
                    </figure>
                </div>
              </div>
            </ion-col>

            <!-- preview -->
            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
              <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
                <h3 class="title ion-no-margin" style="margin-left: 10px">Preview</h3>
                <span class="jmpr_whatsapp_roundButton">
                  <img class="preview" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAkIWl_6gIDA" />
                  <span class="jmpr_whatsapp_greeting_title_tooltip" :style="{'background-color':welcomeMessage.theme_color, 'color': welcomeMessage.text_color}">{{welcomeMessage.welcome_screen_greeting}}</span>
                </span>
              </div>
            </ion-col>
          </ion-row>
      </ion-grid>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
import {  IonTextarea } from "@ionic/vue";


export default {
  components: {IonTextarea},
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      activeTab: 'widget',
       welcomeMessage: {
        theme_color: '#075e54ff',
        text_color: '#ffffffff',
        intent_message: 'Hi there...',
        welcome_screen_greeting: 'Question? We can help.'
      },
    }
  },
  computed: {
     widgetScript() {
       return '<!-- WhatsApp Widget - by Jumper.ai --> <style type="text/css"> .jmpr_whatsapp_roundButton:hover .jmpr_whatsapp_greeting_title_tooltip{visibility: visible;opacity: 1}.jmpr_whatsapp_roundButton .jmpr_whatsapp_greeting_title_tooltip::after{ content: " "; position: absolute; top: 100%; left: 50%; margin-left: -5px; border-width: 5px; border-style: solid; border-color:'+ this.welcomeMessage.theme_color + ' '+'transparent transparent transparent;}  </style> <div id="jmpr-whatsapp-widget-container" class="jmpr-whatsapp-widget-container" data-whatsapp-number="' + this.whatsAppSocialData.id + '" data-whatsapp-intent-message="' + this.welcomeMessage.intent_message + '"data-whatsapp-background-color="' + this.welcomeMessage.theme_color + '"data-whatsapp-text-color="' + this.welcomeMessage.text_color + '"data-whatsapp-greeting-title="' + this.welcomeMessage.welcome_screen_greeting +'" style="position: fixed; z-index: 1000000; -webkit-backface-visibility: hidden; backface-visibility: hidden; bottom: 20px; right: 20px; display: inline-block; line-height: 1;"> </div> <script src="https://jumper.ai/widget/js/whatsapp-widget.js"></scrip' + 't> <!-- WhatsApp Widget - by Jumper.ai -->';
    },
  },
  methods: {
    async doRefresh(event) {
    event.target.complete();
    },
    copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>

<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.header {
  font-size: 20px;
  color: var(--ion-color-black);
  font-weight: 500;
  line-height: 1.4;
  margin: 10px 0px 0px 10px;
  padding: 10px 0px 0px 5px;
}
.jinput {
  width: 98%;
  background: transparent;
}
.colMargin {
  margin-bottom: 10px;
}

.gridMargin {
  margin: 5px;
}

.embedCodeAreaContainer {
  margin: 15px;
}

.preview {
  margin: 10px 30px 30px 30px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 10%);
}

ion-toolbar {
  --background: var(--ion-color-white);
}

.colorPickerWrapper {
  min-height: 45px;
  min-width: 45px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  border: 1px #E3E3E3 solid;
  cursor: pointer;
}

figure {
  margin: 0;
}

figure.highlight {
  margin-top: 5px;
}

figure.highlight pre {
  white-space: normal;
  text-align: left;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}

.container{
  margin: 0px 0px 0px 10px;
}

.jmpr_whatsapp_roundButton {
  position: relative;
  display: inline-block;
}

.jmpr_whatsapp_roundButton .jmpr_whatsapp_greeting_title_tooltip {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.jmpr_whatsapp_roundButton:hover .jmpr_whatsapp_greeting_title_tooltip {
  visibility: visible;
  opacity: 1;
}

.jmpr_whatsapp_roundButton .jmpr_whatsapp_greeting_title_tooltip::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}

@media only screen and (max-width: 760px) {
  .container{
    margin: 5px;
  }
  .prevMobile {
    display: block;
  }
 .prevDesktop {
    display: none;
  }
}

</style>