<template>
  <div class="jinput">
    <label
      v-if="label && label.length"
      :for="name"
    >{{ this.label }}</label>
    <input
      :accept="accept"
      class="inpt--cstm"
      v-if="!isDateTime && !textarea"
      :ref="id"
      :id="id"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @change="onChange"
      @keypress="(e) => $emit('onKeyPress', e)"
      :required="
      required"
      :pattern="pattern"
      :maxlength="maxlength"
      :rows="rows"
      :disabled="disabled"
    />
    <textarea
      :accept="accept"
      class="inpt--cstm"
      v-if="!isDateTime && textarea"
      :rows="rows"
      :ref="id"
      :id="id"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @change="onChange"
      @keypress="(e) => $emit('onKeyPress', e)"
      :required="required"
      :pattern="pattern"
      :maxlength="maxlength"
      :disabled="disabled"
      :class="{'auto-height': textAreaAutoHeight, 'text-area-non-resizable': !textAreaResizable}"
    />
    <ion-icon
      v-if="emoji"
      @click="toggleEmojiPickerHandler"
      slot="end"
      :icon="happyOutline"
      class="add-emoji"
      :style="iconStyle"
    ></ion-icon>
    <ion-icon
      v-if="insert"
      @click="() => $emit('onInsert')"
      slot="end"
      :icon="codeWorkingOutline"
    ></ion-icon>
    <emoji-picker
      v-if="isEmojiPickerOpen"
      class="emoji-picker"
      :class="{'animate-start': startEmojiContainerAnimate, animate: animateEmojiContainer, 'hide-emoji': !isEmojiVisible}"
    ></emoji-picker>
    <!-- <datetime class="inpt--cstm" v-else :ref="id" :id="id" :type="type" :name="name" :placeholder="placeholder" :value="value" @input="onInput" :required="required"></datetime> -->
  </div>
</template>

<script>
import { codeWorkingOutline, happyOutline } from "ionicons/icons";
import "emoji-picker-element";
/* import { Datetime } from 'vue-datetime';
import '~/css/vue-datetime.css'; */

export default {
  components: {
    /* Datetime */
  },
  emits: ["onKeyPress", "input", "onInput", "change"],
  props: {
    label: {
      type: String,
    },
    pattern: {
      type: String,
    },
    id: {
      type: String,
      default: new Date().getTime().toString() + parseInt(Math.random() * 100),
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    emoji: {
      type: Boolean,
      default: false,
    },
    insert: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textAreaAutoHeight: {
      type: Boolean,
      default: false,
    },
    textAreaResizable: {
      type: Boolean,
      default: false,
    },
    animateEmojiContainer: {
      type: Boolean,
      default: true,
    },
    iconStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      codeWorkingOutline,
      happyOutline,
      files: undefined,
      openEmoji: false,
      startEmojiContainerAnimate: false,
      isEmojiVisible: true,
    };
  },
  computed: {
    isDateTime() {
      return (
        this.type === "date" || this.type === "time" || this.type === "datetime"
      );
    },
    isEmojiPickerOpen() {
      return this.emoji && this.openEmoji;
    },
  },
  mounted() {
    if (this.animateEmojiContainer) {
      this.toggleEmojiPicker();
      this.isEmojiVisible = false;
    }
  },
  methods: {
    toggleEmojiPickerHandler() {
      if (this.animateEmojiContainer) {
        this.isEmojiVisible = !this.isEmojiVisible;
        this.emojiAnimateHandler();
      } else {
        this.toggleEmojiPicker();
      }
    },
    toggleEmojiPicker() {
      if (!this.openEmoji) {
        this.openEmoji = true;
        setTimeout(() => {
          let emojiPicker = document.querySelector("emoji-picker");
          if(emojiPicker) {
            emojiPicker.scrollIntoView();
            emojiPicker.addEventListener("emoji-click", (event) => {
              event.detail.unicode ? this.$emit("onInput", (this.value ? this.value : "") + event.detail.unicode) : 0;
            })
          }
        }, 100);
      } else {
        this.openEmoji = false;
      }
    },
    onInput(e) {
      if (!this.isDateTime) {
        this.$emit("onInput", e.target.value);
        this.type === "file" ? (this.files = e.target.files) : 0;
      } else {
        console.log(e);
        this.$emit("input", e);
      }
      this.textareaAutoHeight(e);
    },
    onChange() {
      switch (this.type) {
        case "file":
          this.$emit("change", this.files);
          break;
        default:
          this.$emit("change", this.value);
      }
    },
    textareaAutoHeight(e) {
      if (this.textAreaAutoHeight) {
        e.target.style.height = "auto";
        e.target.style.overflow = "hidden";
        e.target.style.height = e.target.scrollHeight + "px";
      }
    },
    emojiAnimateHandler() {
      if (this.isEmojiVisible) {
        setTimeout(() => {
          this.startEmojiContainerAnimate = this.isEmojiVisible;
        }, 100);
      } else {
        this.startEmojiContainerAnimate = this.isEmojiVisible;
      }
    },
  },
};
</script>

<style scoped>
.emoji-icon,
.add-emoji {
  position: absolute;
  cursor: pointer;
}
.emoji-picker {
  width: 100%;
  height: 250px;
}
.jinput {
  margin: 0;
  width: 100%;
  position: relative;
  border-radius: 25px;
  background-color: #f1f1f1;
}
.jinput > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 0.8rem;
  font-weight: bold;
}
.jinput > input:focus,
.jinput > textarea:focus {
  border: 1px solid black;
}
.jinput > input,
.jinput > textarea {
  outline: none;
  border: 1px solid gray;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}
.jinput input.error,
.jinput textarea.error {
  border: 1px solid red;
}

textarea.text-area-non-resizable {
  resize: none;
}
/*----- css to animate emoji -----*/
.animate.emoji-picker {
  position: relative;
  height: 0px;
  transition: height 0.3s ease-out;
}
.animate-start.emoji-picker {
  position: relative;
  height: 250px;
}
.hide-emoji {
  display: none;
}
/******* end css to animate emoji ****/

/*---- css for mobile device -----*/
@media only screen and (max-width: 780px) {
  .jinput input,
  .jinput textarea {
    width: 100%;
    height: 35px;
    padding: 5px;
    border-radius: 6px;
  }
}

textarea.auto-height {
  overflow: hidden;
  height: auto;
}
</style>