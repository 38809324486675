<template>
  <master-layout pageTitle="Manage Team">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" :value="activeTab" slot="start">
            <ion-segment-button value="team-members" @click="changeTab('team-members')">
              <ion-label>Team Members</ion-label>
            </ion-segment-button>
            <ion-segment-button v-if="!IS_ASIA" value="groups" @click="changeTab('groups')">
              <ion-label>Groups</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <!-- Desktop Team Members -->
        <div v-if="activeTab === 'team-members'">
          <ion-toolbar class="chat-toolbar" color="light">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="teamFilter" type="text" placeholder="Enter name"></ion-input>
            </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
              <ion-col class="ion-hide-md-down">
                Email
              </ion-col>
                <ion-col class="ion-hide-md-down">
                Role
              </ion-col>
                <ion-col class="ion-hide-md-down" >
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="member in teamToShow" :key="member.id" class="table-row ion-no-padding">
                <ion-col class="orderDesc">
                  {{ member.name }}
                </ion-col>
                <ion-col class="orderDesc">
                  {{ member.email }}
                </ion-col>
                <ion-col class="orderDesc">
                  {{ member.role }}
                </ion-col>
                <ion-col>
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/settings/team/add-member?memberid=' + member.id)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="pencilOutline" ></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                    <span class="tooltip_container">
                      <ion-button @click="() => memberToDelete = JSON.parse(JSON.stringify(member))" fill="clear" size="default" color="danger">
                        <ion-icon :icon="trashOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text">Delete</span>
                    </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="text-center" v-if="teamFilter && teamFilter.length && teamToShow.length == 0 && allMembers.length > 0" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No members matching this filter! 😟 <a @click="() => teamFilter = undefined">Clear filter</a></h5>
          </div>
          
          <div class="text-center" v-if="allMembers.length == 0 && !membersLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No members yet! 😟</h5>
          </div>

          <div class="text-center" v-if="allMembers.length == 0 && membersLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>Fetching members...</h5>
          </div>
        </div>
        <!-- Desktop Groups -->
        <div v-if="activeTab === 'groups'">
          <ion-toolbar class="chat-toolbar" color="light">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="groupFilter" type="text" placeholder="Enter Group name"></ion-input>
            </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down">
                Name
              </ion-col>
              <ion-col class="ion-hide-md-down" >
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
           <div class="table-container ion-hide-md-down">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="group in groupsToShow" :key="group.id" class="table-row ion-no-padding">
                <ion-col class="orderDesc" style="min-height: 40px">
                  {{ group.name }}
                </ion-col>
                <ion-col v-if="!group.default">
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/settings/team/add-group?groupid=' + group.id)" fill="clear" size="default" color="dark">
                     <ion-icon :icon="pencilOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                    <span class="tooltip_container">
                        <ion-button @click="() => groupToDelete = JSON.parse(JSON.stringify(group))" fill="clear" size="default" color="danger">
                          <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-button>
                      <span class="tooltip_text">Delete</span>
                    </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>

          <div class="text-center" v-if="groupFilter && groupFilter.length && groupsToShow.length == 0 && allGroups.length > 0" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No groups matching this filter! 😟 <a @click="() => groupFilter = undefined">Clear filter</a></h5>
          </div>

          <div class="text-center" v-if="allGroups.length == 0 && !groupsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No groups yet! 😟</h5>
          </div>

          <div class="text-center" v-if="allGroups.length == 0 && groupsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>Fetching groups...</h5>
          </div>
        </div>
        <!-- Mobile Team Members -->
        <div v-if="activeTab === 'team-members'">
            <div v-for="member in teamToShow" :key="member.id" class="table-responsive ion-hide-md-up">
              <div class="table-section">
                <div class="action-wrapper ion-text-right">
                  <ActionPopOver :style="{'--width': '190px'}">
                    <ion-list>
                      <ion-item>
                        <ion-button
                          @click="() => $router.push('/settings/team/add-member?memberid=' + member.id)" color="light" expand="block" fill="none">
                          <ion-icon :icon="pencilOutline" style="margin-right: 5px; color: black"></ion-icon>
                          Edit Team Member
                        </ion-button>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-button @click="() => memberToDelete = JSON.parse(JSON.stringify(member))" color="light" expand="block" fill="none">
                          <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                          Delete
                        </ion-button>
                      </ion-item>
                    </ion-list>
                  </ActionPopOver>
                </div>     
                <ion-grid class="ion-no-padding">
                  <ion-row class="rowMargin">
                    <ion-col class="ion-no-padding">
                        <ion-label class="orderSubtitle"> Name </ion-label>
                    </ion-col>
                    <ion-col class="orderDesc text-right">
                        {{ member.name }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="rowMargin">
                    <ion-col class="ion-no-padding">
                        <ion-label class="orderSubtitle"> Email </ion-label>
                    </ion-col>
                    <ion-col class="orderDesc text-right">
                        {{ member.email }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="rowMargin">
                    <ion-col class="ion-no-padding">
                        <ion-label class="orderSubtitle"> Role </ion-label>
                    </ion-col>
                    <ion-col class="orderDesc text-right">
                        {{ member.role }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
        </div>
         <!-- Mobile Groups -->
        <div v-if="activeTab === 'groups'">
            <div v-for="group in groupsToShow" :key="group.id" class="table-responsive ion-hide-md-up">
              <div class="table-section">     
                    <div class="action-wrapper ion-text-right">
                  <ActionPopOver :style="{'--width': '190px'}">
                    <ion-list>
                      <ion-item v-if="!group.default">
                        <ion-button
                          @click="() => $router.push('/settings/team/add-group?groupid=' + group.id)" color="light" expand="block" fill="none">
                          <ion-icon :icon="pencilOutline" style="margin-right: 5px; color: black"></ion-icon>
                          Edit Group
                        </ion-button>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-button  @click="() => groupToDelete = JSON.parse(JSON.stringify(group))" color="light" expand="block" fill="none">
                          <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger"></ion-icon>
                          Delete
                        </ion-button>
                      </ion-item>
                    </ion-list>
                  </ActionPopOver>
                </div> 
                <ion-grid class="ion-no-padding">
                  <ion-row class="rowMargin">
                    <ion-col class="ion-no-padding">
                        <ion-label class="orderSubtitle"> Name </ion-label>
                    </ion-col>
                    <ion-col class="orderDesc text-right">
                        {{ group.name }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
        </div>
      </div>
    </ion-content>
    <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => addEditModal = true">
      <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
    </button>
    <ion-action-sheet
      :is-open="addEditModal"
      header="Add"
      :buttons="addOptions"
      @didDismiss="() => addEditModal = false"
    >
    </ion-action-sheet>
    <ion-modal
      v-if="memberToDelete !== null"
      :is-open="memberToDelete !== null"
      @didDismiss="() => memberToDelete = null"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin cust_mrg_lft header">Re-assign your conversations and delete this user</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => memberToDelete = null">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <div class="container">
            <h2 class="common-label custom_mrg_bottom"> Before you delete this user <b>{{ memberToDelete.name }} </b> you need to re-assign the conversations that are assigned to you to any of the user below.r</h2>
            <ion-grid class="pad_zero">
              <ion-row class="ion-justify-content-between">
                <ion-col v-for="thisMember in allMembers" :key="thisMember.id" size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                  <div class="user-container">
                    <ion-radio-group v-model="reassignId">
                    <ion-item lines="none">
                      <ion-radio :disabled="thisMember.id === memberToDelete.id" slot="start" :value="thisMember.id" class="radioBttn"></ion-radio>
                      <ion-avatar slot="start" style="height: auto; width: 25px;">
                        <img width="50" src="https://jumperdevnew.appspot.com/web-s/resources/images/page-inbox-human-support.svg"/>
                      </ion-avatar>
                      <ion-label>
                        <h3>{{ thisMember.name }}</h3>
                      </ion-label>
                    </ion-item>
                    </ion-radio-group>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button :disabled="reassignId == undefined" @click="reassignConversations" color="dark">Re-assign</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="confirmMemberToDelete"
      :is-open="confirmMemberToDelete"
      header="Are you sure?"
      :message="'You want to remove this member?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          confirmMemberToDelete = false;
          memberToDelete = null;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          deleteMember();
        },
      }]"
      @didDismiss="() => {confirmMemberToDelete = false; memberToDelete = null;}"
    >
    </ion-alert>
    <ion-alert
      v-if="groupToDelete"
      :is-open="groupToDelete"
      header="Are you sure?"
      :message="'You want to remove this group?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          groupToDelete = null;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          deleteGroup()
        },
      }]"
      @didDismiss="() => groupToDelete = null"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import '/src/resources/css/manage-store.css';
//import { mapGetters } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, addCircleOutline, close, trashOutline, shareSocialOutline, pencilOutline, arrowUndoOutline } from 'ionicons/icons';
import { IonRadioGroup, IonRadio } from "@ionic/vue";
import ActionPopOver from "../../../components/ActionPopOver.vue";

export default {
  components: {
    IonRadioGroup,
    IonRadio,
    ActionPopOver
    /* IonToggle,
    IonCheckbox,
    IonSelect,
    IonSelectOption */
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashOutline,
      shareSocialOutline,
      pencilOutline,
      arrowUndoOutline,
      close,
      loading: false,
      activeTab: 'team-members',
      allMembers: [],
      membersLoadMore: false,
      membersLoaded: false,
      allGroups: [],
      groupsLoadMore: false,
      groupsLoaded: false,
      addEditModal: false,
      addOptions: [],
      memberToDelete: null,
      groupToDelete: null,
      confirmMemberToDelete: false,
      reassignId: undefined,
      teamFilter: undefined,
      groupFilter: undefined
    }
  },
  computed: {
    teamToShow() {
      return this.teamFilter && this.teamFilter.length ? this.allMembers.filter(e => e.name.match(new RegExp(this.teamFilter, 'gi'))) : this.allMembers;
    },
    groupsToShow() {
      return this.groupFilter && this.groupFilter.length ? this.allGroups.filter(e => e.name.match(new RegExp(this.groupFilter, 'gi'))) : this.allGroups;
    },
  },
  ionViewWillEnter(){
    this.membersLoaded = false;
    this.groupsLoaded = false;
    this.changeTab('team-members');
  },
  watch: {
    memberToDelete() {
      console.log(this.memberToDelete);
    }
  },
  mounted() {
    this.setAddOptions();
  },
  methods: {
    setAddOptions() {
      this.addOptions = [
        {
          text: 'Add Member',
          handler: () => {
            this.addTeamMembers();
          }
        }
      ];

      if (!this.IS_ASIA) {
        this.addOptions.push(
          {
            text: 'Add Group',
            handler: () => {
              this.$router.push('/settings/team/add-group');
            }
          }
        )
      }
    },
    doRefresh(e) {
      this.membersLoaded = false;
      this.groupsLoaded = false;
      this.changeTab('team-members');
      e.target.complete();
    },
    changeTab(newTab) {
      this.activeTab = newTab;
      switch(this.activeTab) {
        case 'team-members': !this.membersLoaded ? this.fetchMembers() : '';
        break;
        case 'groups': !this.groupsLoaded ? this.fetchGroups() : '';
        break;
        default: this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async fetchMembers(e) {
      if(!e) this.loading = true;
      else this.membersLoadMore = true;

      try {
        let allMembersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-list");
        if ((allMembersResponse.status == 200 || allMembersResponse.status == 201) && allMembersResponse.data.success) {
          this.allMembers = allMembersResponse.data.roles;
          this.membersLoaded = true;
        } else {
          if(allMembersResponse.data && !allMembersResponse.data.success && allMembersResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allMembersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allMembersResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.membersLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchGroups(e) {
      if(!e) this.loading = true;
      else this.groupsLoadMore = true;

      try {
        let allGroupsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-business-role");

        if ((allGroupsResponse.status == 200 || allGroupsResponse.status == 201) && allGroupsResponse.data.success) {
          this.allGroups = allGroupsResponse.data.business_roles;
          this.groupsLoaded = true;
        } else {
          if(allGroupsResponse.data && !allGroupsResponse.data.success && allGroupsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allGroupsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allGroupsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.groupsLoadMore = false;}
        else this.loading = false;
      }
    },
    async reassignConversations() {
      if(this.reassignId && this.memberToDelete.id) {
        try {
          this.loading = true;
          let reassignConversationsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "start-reroute-conversation", {agent: this.memberToDelete.id, newagent: this.reassignId});

          if ((reassignConversationsResponse.status == 200 || reassignConversationsResponse.status == 201) && reassignConversationsResponse.data.success && reassignConversationsResponse.data.assigned) {
            this.loading = false;
            this.updateToast({
              open: true,
              message: "Reassigned successfully",
              type: "success"
            })
            this.confirmMemberToDelete = true;
          } else {
            if(reassignConversationsResponse.data && !reassignConversationsResponse.data.success && reassignConversationsResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: reassignConversationsResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
              this.loading = false;
              //console.log("Response:", reassignConversationsResponse);
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } else {
        this.updateToast({
          open: true,
          message: "Something went wrong, please try again",
          type: "danger"
        })
      }
    },
    async deleteMember() {
      try {
        this.loading = true;

        if(!this.memberToDelete.email) {
          this.updateToast({
            open: true,
            message: "Something went wrong, please try again",
            type: "error"
          })
          this.loading = false;
          return;
        }

        let deleteMemberResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "role-delete", { email: this.memberToDelete.email });

        if ((deleteMemberResponse.status == 200 || deleteMemberResponse.status == 201) && deleteMemberResponse.data.success && deleteMemberResponse.data.delete) {
          this.updateToast({
            open: true,
            message: "Member deleted successfully",
            type: "success"
          })
          await this.fetchMembers();
        } else {
          if(deleteMemberResponse.data && !deleteMemberResponse.data.success && deleteMemberResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: deleteMemberResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", deleteMemberResponse);
          }
        }        
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.memberToDelete = null;
        this.reassignId = undefined;
        this.confirmMemberToDelete = false;
      }
    },
    async deleteGroup() {
      try {
        this.loading = true;

        if(!this.groupToDelete.id) {
          this.updateToast({
            open: true,
            message: "Something went wrong, please try again",
            type: "error"
          })
          return;
        }

        let deleteGroupResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-business-roles", { businessroleid: this.groupToDelete.id });

        if ((deleteGroupResponse.status == 200 || deleteGroupResponse.status == 201) && deleteGroupResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Group deleted successfully",
            type: "success"
          })
          await this.fetchGroups();
        } else {
          if(deleteGroupResponse.data && !deleteGroupResponse.data.success && deleteGroupResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: deleteGroupResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", deleteGroupResponse);
          }
        }        
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    addTeamMembers() {
      // ASIA specific condition
      if(this.IS_ASIA) {   
        if( this.allMembers.length < 3) {
         this.$router.push('/settings/team/add-member');
        } else {
          this.updateToast({
            open: true,
            message: "Sorry You cannot add More than 3 Team members",
            type: "warning"
          })
        }
      } else {
        this.$router.push('/settings/team/add-member');
      }
    }
  }
}
</script>

<style scoped>

.container {
  margin: 20px;
}

.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 19px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}
@media only screen and (max-width: 760px) {
.table-responsive {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 0px 20px 20px;
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
  }
}

.table-section {
  padding: 10px 20px 0px;
}
.chat-toolbar {
  margin-top: 15px;
}

.orderSubtitle {
   font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.tooltip_container {
  position: relative;
  display: inline-block;
}

.header {
  font-size: 18px;
  color: var(--ion-color-black);
  font-weight: 600;
}
ion-segment {
  justify-content: center;
}

.common-label {
  margin-bottom: 10px;
}

.user-container {
  border: 1px solid grey;
}
.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}

.tooltip_container .tooltip_text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}
.action-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
ion-radio.radioBttn {
  margin-right: 10px;
}
</style>


