<template>
<master-layout pageTitle="Not Enough Permissions">
  <div class="center-align">
    <div class="section_404_img">
      <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIHJ85sKDA">
    </div>    
  </div>
</master-layout>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.center-align {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.section_404_img {
  width: 60vw;
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
</style>