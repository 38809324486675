<template>
  <master-layout pageTitle="Re-direct links">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <h2 class="heading custom_mrg_left">Re-direct links</h2>
        <h4 class="sub-title custom_mrg_left ion-no-margin">Upon click, the link would auto-redirect to the messaging channel and start an automated conversation with the selected action</h4>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" :value="activeTab" class="segment ion-justify-content-center">
            <ion-segment-button value="products" @click="changeTab('products')">
              <ion-label>Products</ion-label>
            </ion-segment-button>
            <ion-segment-button value="collections" @click="changeTab('collections')">
              <ion-label>Collections</ion-label>
            </ion-segment-button>
            <ion-segment-button value="bots" @click="changeTab('bots')">
              <ion-label>Bots</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div v-if="activeTab === 'products'">
          <ion-toolbar class="chat-toolbar">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="productFilter" type="text"  placeholder="Enter product name"></ion-input>
            </ion-item>
          </ion-toolbar>
          <ion-grid class="table-header ion-no-padding">
            <ion-row class="table-row ion-no-padding">
              <ion-col size="2">
                Image
              </ion-col>
              <ion-col size="2">
                Name
              </ion-col>
              <ion-col size="2">
                Web Link
              </ion-col>
              <ion-col size="3">
                Messenger Link
              </ion-col>
              <ion-col size="3">
                Line Link
              </ion-col>
            </ion-row>
          </ion-grid>
           <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="product in productsToShow" :key="product.id" class="table-row ion-no-padding">
                <ion-col size="2">
                  <img width="50" v-if="product.images && product.images.length" :src="product.images[0]" class="img-cntner" />
                </ion-col>
                <ion-col  size="2" class="orderDesc">
                  {{ product.name }}
                </ion-col>
                <ion-col  size="2" class="orderDesc">
                  <div style="display: flex; flex-direction: row;">
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + product.hashtag)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + product.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + product.hashtag)" fill="clear" size="small" color="dark">
                       <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col  size="3" class="orderDesc">
                   <div style="display: flex; flex-direction: row;">
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + product.hashtag + '/m')" fill="clear" size="small" color="dark">
                        <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + product.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + product.hashtag + '/m')" fill="clear" size="small" color="dark">
                        <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col  size="3">
                  <div style="display: flex; flex-direction: row;">
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/ln/' + product.hashtag)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + product.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/ln/' + product.hashtag)" fill="clear" size="small" color="dark">
                        <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-infinite-scroll
            @ionInfinite="fetchProducts($event)" 
            threshold="100px" 
            id="infinite-scroll"
            :disabled="productsLoadMore || allProducts.length <= 0"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more products...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <div class="text-center" v-if="productFilter && productFilter.length && productsToShow.length == 0" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No products matching this filter! 😟 <span @click="() => productFilter = undefined">Clear filter</span></h5>
          </div>
          <div class="text-center" v-if="allProducts.length == 0 && !productsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No products yet! 😟</h5>
          </div>
          <div class="text-center" v-if="allProducts.length == 0 && productsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>Fetching products...</h5>
          </div>
        </div>
        <div v-if="activeTab === 'collections'">
          <ion-toolbar class="chat-toolbar">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="collectionFilter" type="text"  placeholder="Enter collection name"></ion-input>
            </ion-item>
          </ion-toolbar>
           <ion-grid class="table-header ion-no-padding">
            <ion-row class="table-row ion-no-padding">
              <ion-col size="2">
                Image
              </ion-col>
              <ion-col size="2">
                Name
              </ion-col>
              <ion-col size="2">
                Web Link
              </ion-col>
              <ion-col size="3">
                Messenger Link
              </ion-col>
              <ion-col size="3">
                Line Link
              </ion-col>
            </ion-row>
          </ion-grid>
           <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="collection in collectionsToShow" :key="collection.id" class="table-row ion-no-padding">
                <ion-col size="2">
                  <img width="50" v-if="collection.image && collection.image.length" :src="collection.image" class="img-cntner" />
                </ion-col>
                <ion-col  size="2" class="orderDesc">
                  {{ collection.name }}
                </ion-col>
                <ion-col  size="2" class="orderDesc">
                  <div style="display: flex; flex-direction: row;">
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + collection.hashtag)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + collection.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + collection.hashtag)" fill="clear" size="small" color="dark">
                       <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col  size="3" class="orderDesc">
                   <div style="display: flex; flex-direction: row;">
                     <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + collection.hashtag + '/m')" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + collection.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + collection.hashtag + '/m')" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col  size="3">
                  <div style="display: flex; flex-direction: row;">
                     <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/ln/' + collection.hashtag)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + collection.name + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/ln/' + collection.hashtag)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-infinite-scroll
            @ionInfinite="fetchCatalogues($event)" 
            threshold="100px" 
            id="infinite-scroll"
            :disabled="cataloguesLoadMore || allCatalogues.length <= 0"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more collections...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <div class="text-center" v-if="collectionFilter && collectionFilter.length && collectionsToShow.length == 0" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No collections matching this filter! 😟 <span @click="() => collectionFilter = undefined">Clear filter</span></h5>
          </div>
          <div class="text-center" v-if="allCatalogues.length == 0 && !cataloguesLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No collections yet! 😟</h5>
          </div>
          <div class="text-center" v-if="allCatalogues.length == 0 && cataloguesLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>Fetching collections...</h5>
          </div>
        </div>
        <div v-if="activeTab === 'bots'">
          <ion-toolbar class="chat-toolbar">
            <ion-item class="searchBar" lines="none">
              <ion-input v-model="botFilter" type="text" placeholder="Enter bot name"></ion-input>
            </ion-item>
          </ion-toolbar>
           <ion-grid class="table-header ion-no-padding">
            <ion-row class="table-row ion-no-padding">
              <ion-col>
                Name
              </ion-col>
              <ion-col>
                Web Link
              </ion-col>
              <ion-col>
                Messenger Link
              </ion-col>
            </ion-row>
          </ion-grid>
           <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="bot in botsToShow" :key="bot.id" class="table-row ion-no-padding">
                <ion-col class="orderDesc">
                  {{  bot.displayname}}
                </ion-col>
                <ion-col class="orderDesc">
                  <div>
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + bot.link)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + bot.displayname + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + bot.link)" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col class="orderDesc">
                   <div v-if="bot.saleschannel.indexOf('facebook') > -1">
                    <ion-button @click="copyLink('http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + bot.link + '/m')" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="linkOutline" color="dark"></ion-icon>
                    </ion-button>
                    <ion-button @click="openWhatsapp('Get more details and shop this *' + bot.displayname + '* here: ' + 'http://' + user.referallshopName + '.jumperdevnew.appspot.com/' + bot.link + '/m')" fill="clear" size="small" color="dark">
                      <ion-icon width="20" class="addIcon" slot="icon-only" :icon="logoWhatsapp" color="dark"></ion-icon>
                    </ion-button>
                  </div>
                  <div v-else>N/A</div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <ion-infinite-scroll
            @ionInfinite="fetchBots($event)" 
            threshold="100px" 
            id="infinite-scroll"
            :disabled="!botsLoadMore || allBots.length <= 0"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more Bots...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
          <div class="text-center" v-if="botFilter && botFilter.length && botsToShow.length == 0" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No Bots matching this filter! 😟 <span @click="() => botFilter = undefined">Clear filter</span></h5>
          </div>
          <div class="text-center" v-if="allBots.length == 0 && !botsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No Bots yet! 😟</h5>
          </div>
          <div class="text-center" v-if="allBots.length == 0 && botsLoadMore" style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>Fetching Bots...</h5>
          </div>
        </div>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline , filterOutline, logoWhatsapp,linkOutline} from 'ionicons/icons';
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";

export default {
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  data() {
    return {
      chevronDownCircleOutline,
      filterOutline,
      logoWhatsapp,
      linkOutline,
      loading: false,
      activeTab: 'products',
      allProducts: [],
      productsLoadMore: false,
      productsLoaded: false,
      allCatalogues: [],
      cataloguesLoadMore: false,
      cataloguesLoaded: false,
      allBots: [],
      botsLoadMore: false,
      botsLoaded: false,
      productFilter: undefined,
      collectionFilter: undefined,
      botFilter: undefined
    }
  },
  computed: {
    productsToShow() {
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCatalogues.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCatalogues;
    },
    botsToShow() {
      return this.botFilter && this.botFilter.length ? this.allBots.filter(e => e.name.match(new RegExp(this.botFilter, 'gi'))) : this.allBots;
    }
  },
  async mounted(){
    this.changeTab('products');
  },
  methods: {
    doRefresh(e) {
      this.productsLoaded = false;
      this.cataloguesLoaded = false;
      this.botsLoaded = false;
      this.changeTab('products');
      e.target.complete();
    },
    changeTab(newTab) {
      this.activeTab = newTab;
      switch(this.activeTab) {
        case 'products': !this.productsLoaded ? this.fetchProducts() : '';
        break;
        case 'collections': !this.cataloguesLoaded ? this.fetchCatalogues() : '';
        break;
        case 'bots': !this.botsLoaded ? this.fetchBots() : '';
        break;
        default: this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async fetchProducts(e) {
      if(!e) this.loading = true;
      else this.productsLoadMore = true;
      try {
        let allProductsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((allProductsResponse.status == 200 || allProductsResponse.status == 201) && allProductsResponse.data.success) {
          this.allProducts = allProductsResponse.data.products.filter(e => !e.bundle && !e.catalog);
          this.allProducts.forEach(p => p.selected = false);
          this.productsLoaded = true;
        } else {
          if(allProductsResponse.data && !allProductsResponse.data.success && allProductsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allProductsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.productsLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchCatalogues(e) {
      if(!e) this.loading = true;
      else this.cataloguesLoadMore = true;
      try {
        let allCataloguesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-catalogue");
        if ((allCataloguesResponse.status == 200 || allCataloguesResponse.status == 201) && allCataloguesResponse.data.success) {
          this.allCatalogues = allCataloguesResponse.data.posts;
          this.cataloguesLoaded = true;
        } else {
          if(allCataloguesResponse.data && !allCataloguesResponse.data.success && allCataloguesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allCataloguesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.cataloguesLoadMore = false;}
        else this.loading = false;
      }
    },
    async fetchBots(e) {
      if(!e) this.loading = true;
      else this.botsLoadMore = true;
      try {
        let allBotsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-action");
        if ((allBotsResponse.status == 200 || allBotsResponse.status == 201) && allBotsResponse.data.success) {
          if(allBotsResponse.data.actions && allBotsResponse.data.actions.length) this.allBots = allBotsResponse.data.actions.filter(e => e.active);
          this.botsLoaded = true;
        } else {
          if(allBotsResponse.data && !allBotsResponse.data.success && allBotsResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allBotsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.botsLoadMore = false;}
        else this.loading = false;
      }
    },
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.updateToast({
        open: true,
        message: "Copied to clipboard",
        type: "success"
      })
    },
    openWhatsapp(msg) {
      window.open("https://wa.me?text=" + encodeURIComponent(msg), '_blank');
    }
  }
}
</script>

<style scoped>
td {
  text-align: center;
}
td > div {
  justify-content: center;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
	margin-right: 10px;
  margin-bottom: 20px;
}
.heading {
  font-size: 28px;
  color: var(--ion-color-black);
  font-weight: 400;
  line-height: 1.4;
	margin-bottom: 0px;
}
.custom_mrg_left {
  margin-left: 20px
}
ion-toolbar.chat-toolbar{
  --background: var(--ion-color-light-grey);
  margin-top: 10px;
}

ion-segment.segment {
  --background: var(--ion-color-light-grey);

}

.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}

.img-cntner {
  margin-bottom: 10px;
}

@media only screen and (max-width: 780px) {
  .img-cntner {
    width: 35px;
    height: 35px;
    margin-left: 0px;
  }
}

</style>
