<template>
  <master-layout pageTitle="Tax and shipping settings">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="editTaxMode">
          <ion-toolbar class="chat-toolbar">
            <ion-buttons slot="start">
              <ion-icon  @click="() => { editTaxMode = false; editIndex = -1; }" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
              <h2 class="prdt-title ion-no-margin">Edit Tax Rates</h2>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-header>
            <ion-card-title class="sub-title">Manage tax rates for {{ allZones[editIndex].country }}</ion-card-title>
            <p class="description"><b>States:&nbsp;</b>{{ allZones[editIndex].state.join(', ') }}</p>
          </ion-card-header>
          <ion-grid v-if="allTaxes && allTaxes.length" class="container">
            <ion-row>
              <ion-col>
                LABEL
              </ion-col>
              <ion-col>
                RATE
              </ion-col>
              <ion-col>
                ACTIONS
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid class="model-sections container" v-if="allTaxes && allTaxes.length">
            <ion-row v-for="(thisTax, tIndex) in allTaxes" :key="thisTax.id" class="table-rows">
              <ion-col>
                {{ thisTax.label }}
              </ion-col>
              <ion-col>
                {{ thisTax.rate }}{{ thisTax.is_percent ? '%' : '' }}
              </ion-col>
              <ion-col>
                <span class="tooltip_container">
                  <ion-button @click="() => { taxIndex = tIndex; addEditTaxMode = true; }" fill="clear" size="default" color="dark">
                    <ion-icon :icon="createOutline"></ion-icon>
                  </ion-button>
                  <span class="tooltip_text">Edit</span>
                </span>
                <span class="tooltip_container">
                  <ion-button  @click="() => { taxIndex = tIndex; deleteTaxMode = true; }" fill="clear" size="default" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                  <span class="tooltip_text">Delete</span>
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="text-center" v-else style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No tax rates added yet! 😟</h5>
            <ion-text color="medium">You have not added any tax rates for this zone.</ion-text>
          </div>
          <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => { taxIndex = -1; addEditTaxMode = true; }">
            <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
          </button>
        </div>
        <div v-if="editShipMode">
          <ion-toolbar class="chat-toolbar">
            <ion-buttons slot="start">
              <ion-icon @click="() => { editShipMode = false; editIndex = -1; }" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
              <h2 class="prdt-title ion-no-margin">Edit Shipping Rates</h2>
            </ion-buttons>
          </ion-toolbar>
          <ion-card-header>
            <ion-card-title class="sub-title">Manage shipping rates for {{ allZones[editIndex].country }}</ion-card-title>
            <p class="description"><b>States:&nbsp;</b>{{ allZones[editIndex].state.join(', ') }}</p>
          </ion-card-header>
          <ion-grid v-if="allShips && allShips.length" class="container">
            <ion-row>
              <ion-col>
                BASE TYPE	
              </ion-col>
              <ion-col>
                RANGE
              </ion-col>
              <ion-col>
                ACTIONS
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid class="model-sections container" v-if="allShips && allShips.length">
            <ion-row v-for="(thisShip, sIndex) in allShips" :key="thisShip.id" class="table-rows">
              <ion-col>
                {{ thisShip.shipping_type }}
              </ion-col>
              <ion-col v-if="thisShip.shipping_type === 'weight'">
                <span v-if="thisShip.weight_si_unit === 'kg'">{{ (thisShip.down/1000).toFixed(2) }}&nbsp;kg&nbsp;-&nbsp;{{ (thisShip.up/1000).toFixed(2) }}&nbsp;kg</span>
                <span v-if="thisShip.weight_si_unit === 'lbs'">{{ (thisShip.down/453.592).toFixed(2) }}&nbsp;lbs&nbsp;-&nbsp;{{ (thisShip.up/453.592).toFixed(2) }}&nbsp;lbs</span>
                <span v-if="thisShip.weight_si_unit === 'oz'">{{ (thisShip.down/28.3495).toFixed(2) }}&nbsp;oz&nbsp;-&nbsp;{{ (thisShip.up/28.3495).toFixed(2) }}&nbsp;oz</span>
              </ion-col>
              <ion-col v-if="thisShip.shipping_type === 'price'">
                <ion-text>{{ user.currency }}&nbsp;{{ thisShip.down }}&nbsp;-&nbsp;{{ user.currency }}&nbsp;{{ thisShip.up }}</ion-text>
              </ion-col>
              <ion-col>
                <span class="tooltip_container">
                  <ion-button @click="() => { shipIndex = sIndex; addEditShipMode = true; fetchShipOptions(); }" fill="clear" size="default" color="dark">
                    <ion-icon :icon="createOutline"></ion-icon>
                  </ion-button>
                  <span class="tooltip_text">Edit</span>
                </span>
                <span class="tooltip_container">
                  <ion-button  @click="() => { shipIndex = sIndex; deleteShipMode = true; }" fill="clear" size="default" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                  <span class="tooltip_text">Delete</span>
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="text-center" v-else style="width: 100%; padding: 15px;">
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No shippping rates added yet! 😟</h5>
            <ion-text color="medium">You have not added any shipping rates for this zone.</ion-text>
          </div>
          <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => { shipIndex = -1; addEditShipMode = true; }">
            <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
          </button>
        </div>
        <div v-if="!editTaxMode && !editShipMode">
         <div v-if="allZones && allZones.length">
          <div class="table-responsive">
          <table class="table table-header">
            <thead>
              <tr>
                <th>COUNTRY</th>
                <th>STATES</th>
                <th>CITY</th>
                <th>PINCODE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody >
              <tr v-for="(thisZone, zIndex) in allZones" :key="thisZone.id">
                <th>{{ thisZone.country === '*' ? 'Rest of the world' : thisZone.country }}</th>
                <th class="states">
                  <div class="state-text">
                    {{ thisZone.state[0] === '*' ? 'All' : thisZone.state.join(',') }}
                  </div>
                </th>
                <th v-if="thisZone.city && thisZone.city.length">
                  <span v-for="(thisCity, cIndex) in thisZone.city" :key="cIndex">{{ thisCity === '*' ? 'All' : thisCity }}</span>
                </th>
                <th v-if="thisZone.pincode && thisZone.pincode.length">
                  <span v-for="(thisCode, cIndex) in thisZone.pincode" :key="cIndex">{{ thisCode === '*' ? 'All' : thisCode }}</span>
                </th>
                <th>
                  <ion-button @click="() => { editIndex = zIndex; editTaxMode = true; fetchTaxes(); }" fill="outline" size="default" color="dark">
                    Edit Tax Rates
                  </ion-button>
                  <ion-button @click="() => { editIndex = zIndex; editShipMode = true; fetchShips(); }" fill="outline" size="default" color="dark">
                    Edit Shipping Rates
                  </ion-button>
                  <span class="tooltip_container">
                    <ion-button @click="() => { editIndex = zIndex; addEditMode = true; }" fill="clear" size="default" color="dark">
                      <ion-icon :icon="createOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edit</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => { editIndex = zIndex; deleteMode = true; }" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
          </div>
         </div>
         <div class="text-center" v-else style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No zones added yet! 😟</h5>
          <ion-text color="medium">Seems like you haven't added any zones yet!</ion-text>
         </div>
         <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => {addEditMode = true; editIndex = -1;}">
          <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
         </button>
        </div>
      </div>
    </ion-content>
    <ion-modal
      v-if="addEditMode"
      :is-open="addEditMode"
      @didDismiss="() => { addEditMode = false; editIndex = -1; }"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">{{ editIndex > -1 ? 'Update' : 'Create new' }} Zone</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => { addEditMode = false; editIndex = -1; }">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <h4 class="title">Zone details</h4>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Country</h2>
            <ion-select slot="end" placeholder="Select Country" v-model="dummyZone.country" class="slctContainer">
              <ion-select-option disabled selected>Select Country</ion-select-option>
              <ion-select-option :value="'*'">Rest of the world</ion-select-option>
              <ion-select-option v-for="(thisCountry, cIndex) in country_arr.country_arr" :key="cIndex" :value="thisCountry">{{ thisCountry }}</ion-select-option>
            </ion-select>
          </div>
          <div v-if="dummyZone.country && dummyZone.country !== '*'" class="prdct-section">
            <ion-row class="ion-no-padding" style="margin-bottom: 20px">
              <ion-col class="ion-no-padding">
                <ion-text class="common-label">
                  All States
                </ion-text>
              </ion-col>
              <ion-col class="ion-no-padding text-right">
                <ion-checkbox class="cust_toggle" v-model="selectAllStates"></ion-checkbox>
              </ion-col>
            </ion-row>
            <div v-if="!selectAllStates">
              <h2 class="sctn-labels ion-no-margin">States</h2>
              <ion-select slot="end" multiple placeholder="Select States" v-model="dummyZone.state" class="slctContainer">
                <ion-select-option disabled selected>Select States</ion-select-option>
                <ion-select-option v-for="(thisState, sIndex) in availableStates" :key="sIndex" :value="thisState">{{ thisState }}</ion-select-option>
              </ion-select>
            </div>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="updateZone" color="dark">{{ editIndex > -1 ? 'Update' : 'Save' }}</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="deleteMode"
      :is-open="deleteMode"
      header="Are you sure?"
      :message="'You want to delete this zone?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          deleteMode = false;
          editIndex = -1;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDelete();
        },
      }]"
      @didDismiss="() => {deleteMode = false; editIndex = -1;}"
    >
    </ion-alert>
    <ion-modal
      v-if="addEditTaxMode"
      :is-open="addEditTaxMode"
      @didDismiss="() => { addEditTaxMode = false; taxIndex = -1; }"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">{{ taxIndex > -1 ? 'Update' : 'Create new' }} Tax Rate</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => { addEditTaxMode = false; taxIndex = -1; }">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>   
          <h4 class="title">Tax details</h4>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Label</h2>
            <ion-input v-model="dummyTax.label" placeholder="Enter label" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Rate(in %)</h2>
            <ion-input v-model="dummyTax.rate" placeholder="Enter rate in %" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="updateTax" color="dark">{{ taxIndex > -1 ? 'Update' : 'Save' }}</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="deleteTaxMode"
      :is-open="deleteTaxMode"
      header="Are you sure?"
      :message="'You want to delete this tax rate?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          deleteTaxMode = false;
          taxIndex = -1;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteTax();
        },
      }]"
      @didDismiss="() => {deleteTaxMode = false; taxIndex = -1;}"
    >
    </ion-alert>
    <ion-modal
      v-if="addEditShipMode"
      :is-open="addEditShipMode"
      @didDismiss="() => { addEditShipMode = false; shipIndex = -1; }"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">{{ shipIndex > -1 ? 'Update' : 'Create new' }} Ship Rate</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => { addEditShipMode = false; shipIndex = -1; }">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>   
          <h4 class="title">Ship details</h4>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Rates are based on</h2>
            <ion-select slot="end" placeholder="Select Basis" v-model="dummyShip.shipping_type" class="slctContainer">
              <ion-select-option disabled selected>Select Basis</ion-select-option>
              <ion-select-option :value="'price'">Price</ion-select-option>
              <ion-select-option :value="'weight'">Weight</ion-select-option>
            </ion-select>
          </div>
          <div v-if="dummyShip.shipping_type === 'price'" class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Minimum Order Price {{ user.currency }}</h2>
            <ion-input v-model="dummyShip.down" placeholder="Minimum order price" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div v-if="dummyShip.shipping_type === 'price'" class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Maximum Order Price {{ user.currency }}</h2>
            <ion-input v-model="dummyShip.up" placeholder="Maximum order price" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div v-if="dummyShip.shipping_type === 'weight'" class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Weight Measure</h2>
            <ion-select slot="end" placeholder="Select Weight Measure" v-model="dummyShip.weight_si_unit" class="slctContainer">
              <ion-select-option disabled selected>Select Weight Measure</ion-select-option>
              <ion-select-option :value="'kg'">Kg</ion-select-option>
              <ion-select-option :value="'lbs'">lbs</ion-select-option>
              <ion-select-option :value="'oz'">oz</ion-select-option>
            </ion-select>
          </div>
          <div v-if="dummyShip.shipping_type === 'weight'" class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Minimum Order Weight {{ dummyShip.weight_si_unit }}</h2>
            <ion-input v-model="dummyShip.down" placeholder="Minimum Order Weight" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div v-if="dummyShip.shipping_type === 'weight'" class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Maximum Order Weight {{ dummyShip.weight_si_unit }}</h2>
            <ion-input v-model="dummyShip.up" placeholder="Maximum Order Weight" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div class="prdct-section">
            <ion-checkbox class="cod_toggle" v-model="dummyShip.cash_on_delivery"></ion-checkbox>
            <span class="common-label ion-no-margin"> Cash on Delivery is available in this shipping zone </span>
          </div>
          <div v-if="shipIndex > -1">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <h4 class="title custom_mrg_left">Rates</h4>
                </ion-col>
                <ion-col>
                  <ion-toolbar>
                    <ion-buttons slot="end">
                      <ion-button @click="() => { shipOptionIndex = -1; addEditShipOptionMode = true; }" fill="solid" size="default" color="dark" class="addbttn">Add new rate</ion-button>
                    </ion-buttons>
                  </ion-toolbar>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid v-if="allShipOptions && allShipOptions.length" class="table-header ion-no-padding">
              <ion-row class="ion-justify-content-between">
                <ion-col>
                  NAME
                </ion-col>
                <ion-col>
                  DESCRIPTION
                </ion-col>
                <ion-col>
                  RATE
                </ion-col>
                <ion-col class="text-center">
                  ACTIONS
                </ion-col>
              </ion-row>
            </ion-grid>
            <div v-if="allShipOptions && allShipOptions.length" class="rates-container">
              <ion-grid class="ion-no-padding">
                <ion-row v-for="(thisOption, oIndex) in allShipOptions" :key="thisOption.id" class="table-row ion-no-padding">
                  <ion-col class="orderDesc">
                     {{ thisOption.name }}
                  </ion-col>
                  <ion-col class="orderDesc">
                    {{ thisOption.description }}
                  </ion-col>
                  <ion-col class="orderDesc">
                    {{ user.currency }}&nbsp;{{ thisOption.rate }}
                  </ion-col>
                  <ion-col class="col-padding text-center">
                    <span class="tooltip_container">
                      <ion-button @click="() => { shipOptionIndex = oIndex; addEditShipOptionMode = true; }" fill="clear" size="default" color="dark">
                        <ion-icon :icon="createOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text">Edit</span>
                    </span>
                    <span class="tooltip_container">
                      <ion-button @click="() => { shipOptionIndex = oIndex; deleteShipOptionMode = true; }" fill="clear" size="default" color="danger">
                        <ion-icon :icon="trashOutline"></ion-icon>
                      </ion-button>
                      <span class="tooltip_text">Delete</span>
                    </span>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="updateShip" color="dark">{{ shipIndex > -1 ? 'Update' : 'Save' }}</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="deleteShipMode"
      :is-open="deleteShipMode"
      header="Are you sure?"
      :message="'You want to delete this ship rate?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          deleteShipMode = false;
          shipIndex = -1;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteShip();
        },
      }]"
      @didDismiss="() => {deleteShipMode = false; shipIndex = -1;}"
    >
    </ion-alert>
    <ion-modal
      v-if="addEditShipOptionMode"
      :is-open="addEditShipOptionMode"
      @didDismiss="() => { addEditShipOptionMode = false; shipOptionIndex = -1; }"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin">{{ shipOptionIndex > -1 ? 'Update' : 'Create new' }} Shipping Option</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => { addEditShipOptionMode = false; shipOptionIndex = -1; }">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <h4 class="title">Add new Rate</h4>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Name</h2>
            <ion-input v-model="dummyOption.name" placeholder="Enter Name" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Description</h2>
            <ion-input v-model="dummyOption.description" placeholder="Enter Description" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
          <div class="prdct-section">
            <h2 class="sctn-labels ion-no-margin">Rate(in {{ user.currency }})</h2>
            <ion-input v-model="dummyOption.rate" placeholder="Enter rate" class="fullBordered input_custom_style sctn-input"></ion-input>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button @click="updateShipOption" color="dark">{{ shipOptionIndex > -1 ? 'Update' : 'Save' }}</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
    <ion-alert
      v-if="deleteShipOptionMode"
      :is-open="deleteShipOptionMode"
      header="Are you sure?"
      :message="'You want to delete this shipping option?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          deleteShipOptionMode = false;
          shipOptionIndex = -1;
        },
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDeleteShipOption();
        },
      }]"
      @didDismiss="() => {deleteShipOptionMode = false; shipOptionIndex = -1;}"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, createOutline, trashOutline, addCircleOutline, close, arrowBackOutline } from 'ionicons/icons';
import { IonSelect, IonSelectOption, IonCheckbox } from "@ionic/vue";

export default {
  components: { IonSelect, IonSelectOption, IonCheckbox },
  data() {
    return {
      chevronDownCircleOutline,
      createOutline,
      trashOutline,
      addCircleOutline,
      arrowBackOutline,
      close,
      loading: false,
      allZones: [],
      allTaxes: [],
      allShips: [],
      allShipOptions: [],
      addEditMode: false,
      editTaxMode: false,
      editShipMode: false,
      addEditTaxMode: false,
      addEditShipMode: false,
      addEditShipOptionMode: false,
      editIndex: -1,
      taxIndex: -1,
      shipIndex: -1,
      shipOptionIndex: -1,
      deleteMode: false,
      deleteTaxMode: false,
      deleteShipMode: false,
      deleteShipOptionMode: false,
      dummyZone: {
        country: undefined,
        state: [],
        city: undefined,
        pincode: undefined
      },
      selectAllStates: false,
      dummyTax: {
        label: undefined,
        rate: undefined
      },
      dummyShip: {
        shipping_type: undefined,
        weight_si_unit: undefined,
        up: undefined,
        down: undefined,
        cash_on_delivery: false
      },
      dummyOption: {
        name: undefined,
        description: undefined,
        rate: undefined
      }
    }
  },
  async ionViewWillEnter() {
    await this.init();
  },
  watch: {
    editIndex(n) {
      if(n > -1) this.dummyZone = JSON.parse(JSON.stringify(this.allZones[this.editIndex]));
      else this.dummyZone = {
        country: undefined,
        state: [],
        city: undefined,
        pincode: undefined
      }
      if(this.dummyZone.state && this.dummyZone.state.length === this.availableStates.length) this.selectAllStates = true;
      else this.selectAllStates = false;
    },
    selectAllStates(n) {
      if(n) this.dummyZone.state = this.availableStates;
    },
    taxIndex(n) {
      if(n > -1) this.dummyTax = JSON.parse(JSON.stringify(this.allTaxes[this.taxIndex]));
      else this.dummyTax = {
        label: undefined,
        rate: undefined
      }
    },
    shipIndex(n) {
      if(n > -1) this.dummyShip = JSON.parse(JSON.stringify(this.allShips[this.shipIndex]));
      else this.dummyShip = {
        shipping_type: undefined,
        weight_si_unit: undefined,
        up: undefined,
        down: undefined,
        cash_on_delivery: false
      }
      switch(this.dummyShip.weight_si_unit) {
        case 'kg': {
          this.dummyShip.up /= 1000;
          this.dummyShip.down /= 1000;
        }
        break;
        case 'oz': {
          this.dummyShip.up /= 28.3495;
          this.dummyShip.down /= 28.3495;
        }
        break;
        case 'lbs': {
          this.dummyShip.up /= 453.592;
          this.dummyShip.down /= 453.592;
        }
        break;
        default: console.log("New Value being added");
      }
    },
    shipOptionIndex(n) {
      if(n > -1) this.dummyOption = JSON.parse(JSON.stringify(this.allShipOptions[this.shipOptionIndex]));
      else this.dummyOption = {
        name: undefined,
        description: undefined,
        rate: undefined
      }
    },
  },
  computed: {
    availableStates() {
      let countryIndex = this.country_arr.country_arr.indexOf(this.dummyZone.country);
      if(countryIndex > -1 && countryIndex < 252) return this.country_arr.state_arr[ countryIndex + 1 ].split("|");
      return [];
    }
  },
  methods: {
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchZones();
    },
    async fetchZones() {
      try {
        this.loading = true;
        let fetchZonesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-zone");
        if ((fetchZonesResponse.status == 200 || fetchZonesResponse.status == 201) && fetchZonesResponse.data.success) this.allZones = fetchZonesResponse.data.zones;
        else {
          if(fetchZonesResponse.data && !fetchZonesResponse.data.success && fetchZonesResponse.data.errorMessage && fetchZonesResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchZonesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateZone() {
      try {
        this.loading = true;
        let reqBody = {
          country: this.dummyZone.country,
          state: this.dummyZone.state && this.dummyZone.state.length ? this.dummyZone.state.join(',') : undefined,
          city: '*',
          pincode: '*'
        };
        if(!reqBody.country || (!reqBody.state && reqBody.country !== '*')) {
          this.updateToast({
            open: true,
            message: "Missing details",
            type: "warning"
          })
          return;
        }
        if(this.editIndex > -1) reqBody['zoneid'] = this.allZones[this.editIndex].id;
        if(reqBody.country === '*') reqBody.state = '*';
        let updateZoneResponse = reqBody.zoneid ? await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-zone", reqBody) : await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-zone", reqBody);
        if ((updateZoneResponse.status == 200 || updateZoneResponse.status == 201) && updateZoneResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Zone " + updateZoneResponse.data.zone + " successfully",
            type: "success"
          })
          await this.fetchZones();
        } else {
          if(updateZoneResponse.data && !updateZoneResponse.data.success && updateZoneResponse.data.errorMessage && updateZoneResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: updateZoneResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.editIndex = -1;
        this.addEditMode = false;
      }
    },
    async confirmDelete() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.editIndex > -1 && this.deleteMode) reqBody['zoneid'] = this.allZones[this.editIndex].id;
        let deleteZoneResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-zone", reqBody);
        if ((deleteZoneResponse.status == 200 || deleteZoneResponse.status == 201) && deleteZoneResponse.data.success) {
          if(deleteZoneResponse.data.delete) this.updateToast({
            open: true,
            message: "Zone deleted successfully",
            type: "success"
          })
          await this.fetchZones();
        } else {
          if(deleteZoneResponse.data && !deleteZoneResponse.data.success && deleteZoneResponse.data.errorMessage && deleteZoneResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: deleteZoneResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteMode = false;
        this.editIndex = -1;
      }
    },
    async fetchTaxes() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.editIndex > -1) reqBody['zoneid'] = this.allZones[this.editIndex].id;
        else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
          return;
        }
        let fetchTaxesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-tax", reqBody);
        if ((fetchTaxesResponse.status == 200 || fetchTaxesResponse.status == 201) && fetchTaxesResponse.data.success) this.allTaxes = fetchTaxesResponse.data.taxes;
        else {
          if(fetchTaxesResponse.data && !fetchTaxesResponse.data.success && fetchTaxesResponse.data.errorMessage && fetchTaxesResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchTaxesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateTax() {
      try {
        this.loading = true;
        let reqBody = {
          label: this.dummyTax.label,
          rate: this.dummyTax.rate,
          zoneid: this.allZones[this.editIndex].id
        };
        if(!reqBody.zoneid || !reqBody.label || !reqBody.rate) {
          this.updateToast({ open: true, message: "Missing details", type: "warning" })
          return;
        }
        if(this.taxIndex > -1) reqBody['taxid'] = this.allTaxes[this.taxIndex].id;
        let updateTaxResponse = reqBody.taxid ? await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "edit-tax", reqBody) : await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "create-tax", reqBody);
        if ((updateTaxResponse.status == 200 || updateTaxResponse.status == 201) && updateTaxResponse.data.success) {
          this.updateToast({ open: true, message: "Tax Rate " + updateTaxResponse.data.tax + " successfully", type: "success" })
          await this.fetchTaxes();
        } else {
          if(updateTaxResponse.data && !updateTaxResponse.data.success && updateTaxResponse.data.errorMessage && updateTaxResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: updateTaxResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        this.loading = false;
        this.taxIndex = -1;
        this.addEditTaxMode = false;
      }
    },
    async confirmDeleteTax() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.taxIndex > -1 && this.deleteTaxMode) reqBody['taxid'] = this.allTaxes[this.taxIndex].id;
        let deleteTaxResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-tax", reqBody);
        if ((deleteTaxResponse.status == 200 || deleteTaxResponse.status == 201) && deleteTaxResponse.data.success) {
          if(deleteTaxResponse.data.delete) this.updateToast({
            open: true,
            message: "Tax Rate deleted successfully",
            type: "success"
          })
          await this.fetchTaxes();
        } else {
          if(deleteTaxResponse.data && !deleteTaxResponse.data.success && deleteTaxResponse.data.errorMessage && deleteTaxResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: deleteTaxResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteTaxMode = false;
        this.taxIndex = -1;
      }
    },
    async fetchShips() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.editIndex > -1) reqBody['zoneid'] = this.allZones[this.editIndex].id;
        else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
          return;
        }
        let fetchShipsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-shipment", reqBody);
        if ((fetchShipsResponse.status == 200 || fetchShipsResponse.status == 201) && fetchShipsResponse.data.success) this.allShips = fetchShipsResponse.data.shipping;
        else {
          if(fetchShipsResponse.data && !fetchShipsResponse.data.success && fetchShipsResponse.data.errorMessage && fetchShipsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: fetchShipsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async updateShip() {
      try {
        this.loading = true;
        let reqBody = {
          rate: 0,
          shipping_type: this.dummyShip.shipping_type,
          weight_si_unit: this.dummyShip.weight_si_unit,
          cash_on_delivery: this.dummyShip.cash_on_delivery,
          up: parseInt(this.dummyShip.up),
          down: parseInt(this.dummyShip.down),
          zoneid: this.allZones[this.editIndex].id
        };
        if(!reqBody.zoneid || !reqBody.up || !reqBody.down || !reqBody.shipping_type || (reqBody.shipping_type === 'weight' && !reqBody.weight_si_unit)) {
          this.updateToast({ open: true, message: "Missing details", type: "warning" })
          return;
        }
        if(reqBody.shipping_type === 'weight') {
          switch(reqBody.weight_si_unit) {
            case 'kg': {
              reqBody.up *= 1000;
              reqBody.down *= 1000;
            }
            break;
            case 'oz': {
              reqBody.up *= 28.3495;
              reqBody.down *= 28.3495;
            }
            break;
            case 'lbs': {
              reqBody.up *= 453.592;
              reqBody.down *= 453.592;
            }
            break;
            default: {
              this.updateToast({ open: true, message: "Missing details", type: "warning" })
              return;
            }
          }
        }
        if (this.shipIndex > -1) reqBody['zoneshippingid'] = this.allShips[this.shipIndex].id;

        let formDataToPost = new FormData();

        for (let key in reqBody) {
          formDataToPost.append(key, reqBody[key]);
        }

        let updateShipResponse = await jwtInterceptor.post(
          this.environment('VUE_APP_ROOT_API') + "add-shipment",
          formDataToPost,
          { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" } }
        );
        if ((updateShipResponse.status == 200 || updateShipResponse.status == 201) && updateShipResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Shipping Rate " + updateShipResponse.data.shipping + " successfully",
            type: "success"
          })
          await this.fetchShips();
        } else {
          if(updateShipResponse.data && !updateShipResponse.data.success && updateShipResponse.data.errorMessage && updateShipResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: updateShipResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.shipIndex = -1;
        this.addEditShipMode = false;
      }
    },
    async confirmDeleteShip() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.shipIndex > -1 && this.deleteShipMode) reqBody['zoneshippingid'] = this.allShips[this.shipIndex].id;
        let deleteShipResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-shipment", reqBody);
        if ((deleteShipResponse.status == 200 || deleteShipResponse.status == 201) && deleteShipResponse.data.success) {
          if(deleteShipResponse.data.delete) this.updateToast({
            open: true,
            message: "Shipment Rate deleted successfully",
            type: "success"
          })
          await this.fetchShips();
        } else {
          if(deleteShipResponse.data && !deleteShipResponse.data.success && deleteShipResponse.data.errorMessage && deleteShipResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: deleteShipResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteShipMode = false;
        this.shipIndex = -1;
      }
    },
    async fetchShipOptions() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.shipIndex > -1) reqBody['zoneshippingid'] = this.allShips[this.shipIndex].id;
        else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
          return;
        }
        let fetchShipOptionsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-shipping-option", reqBody);
        if ((fetchShipOptionsResponse.status == 200 || fetchShipOptionsResponse.status == 201) && fetchShipOptionsResponse.data.success) {
          this.allShipOptions = fetchShipOptionsResponse.data.options;
        } else {
          if(fetchShipOptionsResponse.data && !fetchShipOptionsResponse.data.success && fetchShipOptionsResponse.data.errorMessage && fetchShipOptionsResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: fetchShipOptionsResponse.data.errorMessage, type: "danger" })
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        this.loading = false;
      }
    },
    async confirmDeleteShipOption() {
      try {
        this.loading = true;
        let reqBody = {};
        if(this.shipOptionIndex > -1 && this.deleteShipOptionMode) reqBody['option_id'] = this.allShipOptions[this.shipOptionIndex].id;
        let deleteShipOptionResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "delete-shipping-option", reqBody);
        if ((deleteShipOptionResponse.status == 200 || deleteShipOptionResponse.status == 201) && deleteShipOptionResponse.data.success) {
          if(deleteShipOptionResponse.data.option) this.updateToast({
            open: true,
            message: "Shipping Option deleted successfully",
            type: "success"
          })
          await this.fetchShipOptions();
        } else {
          if(deleteShipOptionResponse.data && !deleteShipOptionResponse.data.success && deleteShipOptionResponse.data.errorMessage && deleteShipOptionResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: deleteShipOptionResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteShipOptionMode = false;
        this.shipOptionIndex = -1;
      }
    },
    async updateShipOption() {
      try {
        this.loading = true;
        let reqBody = {
          name: this.dummyOption.name,
          description: this.dummyOption.description,
          rate: this.dummyOption.rate,
          zoneshippingid: this.allShips[this.shipIndex].id
        };
        if(!reqBody.zoneshippingid || !reqBody.name || !reqBody.rate) {
          this.updateToast({ open: true, message: "Missing details", type: "warning" });
          return;
        }
        if(this.shipOptionIndex > -1) reqBody['option_id'] = this.allShipOptions[this.shipOptionIndex].id;
        let updateShipOptResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-shipping-option", reqBody);
        if ((updateShipOptResponse.status == 200 || updateShipOptResponse.status == 201) && updateShipOptResponse.data.success) {
          this.updateToast({ open: true, message: "Shipping Option " + (reqBody.option_id ? 'created' : 'updated') + " successfully", type: "success" });
          await this.fetchShipOptions();
        } else {
          if(updateShipOptResponse.data && !updateShipOptResponse.data.success && updateShipOptResponse.data.errorMessage && updateShipOptResponse.data.errorMessage.length) {
            this.updateToast({ open: true, message: updateShipOptResponse.data.errorMessage, type: "danger" });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger" });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" });
      } finally {
        this.loading = false;
        this.shipOptionIndex = -1;
        this.addEditShipOptionMode = false;
      }
    }
  }
}
</script>

<style scoped>
.table-container {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  border-width: 0px;
  font-size: 13px;
  margin: 15px 16px;
  min-width: calc(100% - 32px);
  width: calc(100% - 32px);
  background: var(--ion-background-color, #fff);
  -webkit-border-radius: 10px;
  overflow: hidden;
}
.rates-container {
  border-radius: 5px;
  border: 1px solid #EDEDED;
  margin: 15px 16px;
  min-width: calc(100% - 32px);
  width: calc(100% - 32px);
  background: var(--ion-background-color, #fff);
  -webkit-border-radius: 10px;
  overflow: hidden;
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}
.tooltip_container {
  position: relative;
  display: inline-block;
}
.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}
.tooltip_container .tooltip_text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}
.state-text {
  font-size: 14px;
  font-weight: 500;
  padding: 0px;
  margin: 5px 0px;
  line-height: 1.3;
  color: var(--ion-color-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  font-size: 20px;
  font-weight: 500;
  margin: 15px 10px;
  color: var(--ion-color-black);
}
.sctn-input {
  border-radius: 5px;
}
.prdct-section{
  margin: 15px 10px;
}
.slctContainer {
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}
.col-padding{
  padding-bottom: 10px;
}
.sub-title{
  font-size: 28px;
  font-weight: 400;
}
.description {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 800px;
  color: var(--ion-color-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
}
.table{
  width: 100%;
  max-width: 100%;
  margin-bottom: 0px;
  border-spacing: 0;
  border-collapse: collapse;
}

table th {
  text-transform: none;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.states{
  max-width: 250px;
}
.cod_toggle{
  position: relative;
  top: 5px;
  margin-right: 5px;
}
.container {
  margin: 10px;
}
.table-rows {
  border-bottom: 1px solid #EDEDED;
  margin: 10px 0px;
  padding: 5px 0px;
}
.table-rows:last-child {
  border-bottom: 0px;
}
ion-button.addbttn {
  --border-radius: 5px;
}
@media only screen and (max-width: 760px){
.table-row {
    margin: 35px 10px 20px 10px;
}
.table-responsive{
  border: none;
  margin-bottom: 20px;
}
}
</style>