<template>
  <div class="pagination text-right" v-if="totalCount">
        <ul>
          <li>
            <a class="page-link page-next-prev" @click="() => isPrevEnabled && navigate(1)" v-bind:class="{'disabled': !isPrevEnabled}">
              First
            </a>
          </li>
          <li>
            <a class="page-link page-next-prev" @click="() => isPrevEnabled && navigate(--currentSelectedPage)" v-bind:class="{'disabled': !isPrevEnabled}">
              <ion-icon :icon="chevronBackOutline"></ion-icon>
            </a>
          </li>
        </ul>

        <ul v-for="(page, index) in pages" :key="index">
          <li :class="{active: page == currentSelectedPage}"> 
            <a class="page-link" @click="navigate(page)">{{ page }}</a>
          </li>
        </ul>

        <ul>
          <li>
            <a class="page-link page-next-prev" @click="() => isNextEnabled && navigate(++currentSelectedPage)" v-bind:class="{'disabled': !isNextEnabled}">
              <ion-icon :icon="chevronForwardOutline"></ion-icon>
            </a>
          </li>
          <li>
            <a class="page-link page-next-prev" @click="() => isNextEnabled && navigate(totalPages)" v-bind:class="{'disabled': !isNextEnabled}">
              Last
            </a>
          </li>
        </ul>
      </div>
</template>

<script>
import {
  chevronBackOutline,
  chevronForwardOutline
} from 'ionicons/icons';

export default {
  data() {
    return {
      currentSelectedPage: 0,
      currentNavBarLength: 0,
      totalPages: 0,
      isPrevEnabled: true,
      isNextEnabled: true,
      pages: [],
      chevronBackOutline,
      chevronForwardOutline
    }
  },
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    navBarLength: {
      type: Number,
      default: 10
    }
  },
  mounted() {
    this.setInitialValues();
    this.pagesHandler();
    this.navButtonsEnabledHandler();
  },
  methods: {
    navigate(pageNumber) {
      this.currentSelectedPage = pageNumber;
      this.pagesHandler();
      let dataToEmit = { pageNumber, offset: this.pageSize * (pageNumber - 1) }
      this.$emit('navigate', dataToEmit);
      this.navButtonsEnabledHandler();
    },
    navButtonsEnabledHandler() {
      this.isPrevEnabled = !(this.currentSelectedPage <= 1);
      this.isNextEnabled = this.currentSelectedPage < this.totalPages;
    },
    setInitialValues() {
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.currentSelectedPage = this.currentPage;
      this.currentNavBarLength = Math.min(this.navBarLength, this.totalPages);
    },
    pagesHandler() {
      this.pages = [];
      const isFirstPageSelected = this.currentSelectedPage === 1;
      const isLastSetSelected = this.currentSelectedPage <= this.totalPages && this.currentSelectedPage >= (this.totalPages - (this.navBarLength - 1));
      const isInvalidPageNumber = (pageNumber) => {
        if( pageNumber >= this.totalPages || pageNumber < 1 ) {
            return true;
        }
      };
      for( let i = 0; i < this.currentNavBarLength; i++ ) {
        if( isFirstPageSelected ) {
          const pageNumber = i + 1;
          this.pages.push(pageNumber);
          if(isInvalidPageNumber(pageNumber)) break;
        } else if(isLastSetSelected) {
          const pageNumber = this.totalPages - (this.currentNavBarLength - (i + 1));
          this.pages.push(pageNumber);
          if(isInvalidPageNumber(pageNumber)) break;
        } else {
          const indexToAdd = i - 1;
          const pageNumber = this.currentSelectedPage + indexToAdd;
          if(isInvalidPageNumber(pageNumber)) break;
          this.pages.push(pageNumber);
        }
      }
    },
    reset() {
      this.setInitialValues();
      this.navButtonsEnabledHandler();
    }
  }
}
</script>

<style scoped>

</style>