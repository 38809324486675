<template>
<master-layout pageTitle="WhatsApp">
	<ion-loading
	v-if="loading"
	:is-open="loading"
	cssClass="my-custom-class"
	message="Loading..."
	></ion-loading>
	<div v-else>
	  <div v-if="whatsappConnected">
		<div>
		   <h2 class="title ion-no-margin">WhatsApp</h2>
		</div>
		<ion-grid>
      <ion-row>
        <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
          <ion-card class="cardContainer">
            <ion-item lines="none" router-link="/sales-channel/whatsapp/whatsapp-business-settings">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/web-s/resources/images/whatsapp-business-settings.svg" />
              </ion-thumbnail>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin">Business settings</h2>
                <p class="cardSubtitle ion-no-padding ion-no-margin">Manage your WhatsApp Business API account settings</p>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
        <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
          <ion-card class="cardContainer">
            <ion-item lines="none" router-link="/sales-channel/whatsapp/message-templates">
              <ion-thumbnail slot="start">
                <img src="https://jumperdevnew.appspot.com/web-s/resources/images/store-settings-faq-v1.svg" />
              </ion-thumbnail>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin">Message templates</h2>
                <p class="cardSubtitle ion-no-padding ion-no-margin">Create and manage your WhatsApp Business API message templates</p>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
		  <ion-row>
			<ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
        <ion-card class="cardContainer">
          <ion-item lines="none" router-link="/sales-channel/whatsapp/widget">
            <ion-thumbnail slot="start">
              <img src="https://jumper.ai/web-s/resources/images/sc-web-whatsapp-widget.svg" />
            </ion-thumbnail>
            <ion-label>
              <h2 class="cardTitle ion-no-padding ion-no-margin">WhatsApp widget</h2>
              <p class="cardSubtitle ion-no-padding ion-no-margin">Add the WhatsApp chat widget on your website</p>
            </ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
		  </ion-row>
    </ion-grid>
	  </div>
		<div v-else>
			<page-access-not-allowed></page-access-not-allowed>
		</div>
	</div>
</master-layout>
</template>

<script>
//import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  data() {
    return {
      loading: false,
    }
  },
}
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  margin: 15px 0px 0px 20px;
}
.sub-title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.cardContainer {
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}

.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 4px;
}
</style>