import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from "../pages/account/login.vue";
import EditProfile from "../pages/account/edit-profile.vue";
import Dashboard from "../pages/account/dashboard.vue";
import LoginActivities from "../pages/account/login-activities.vue";
import PageInbox from "../pages/PageInbox.vue";
import LiveChatHome from "../pages/LiveChatHome.vue";
import Customers from "../pages/customers/index.vue";
import CustomerSegments from "../pages/customers/segments.vue";
import Orders from "../pages/orders/index.vue";
import CheckoutDirect from "../pages/orders/create.vue";
import RestaurantOrders from "../pages/orders/restaurant.vue";
import Scanner from "../pages/scanner.vue";
import Store from "../pages/store/index.vue";
import AddProduct from "../pages/store/add-product.vue";
import AddSet from "../pages/store/add-set.vue";
import AddCollection from "../pages/store/add-collection.vue";
import AddDiscount from "../pages/store/add-discount.vue";
import Automations from "../pages/automations/index.vue";
import BroadcastWhatsapp from "../pages/automations/broadcast/index.vue";
import AutomateResponses from "../pages/automations/automate-responses.vue";
import BotBuilder from "../pages/automations/bot-builder.vue";
import DefaultTemplates from "../pages/automations/default-templates.vue";
import CartRecovery from "../pages/automations/cart-recovery.vue";
import BulkUpdateProduct from "../pages/bulk-update/product.vue";
import SalesChannelGoogleBusinessMessages from "../pages/sales-channel/google-business-messages.vue";
import SalesChannelSMS from "../pages/sales-channel/sms.vue";
import SalesChannelReDirectLinks from "../pages/sales-channel/re-direct-links.vue";
import SalesChannelAttentiveWidget from "../pages/sales-channel/attentive-widget.vue";
import SalesChannelAttentiveWidgetSettings from "../pages/sales-channel/attentive-widget-settings.vue";
import SalesChannelWeb from "../pages/sales-channel/web/index.vue";
import SalesChannelWebCustomDomain from "../pages/sales-channel/web/custom-domain.vue";
import SalesChannelIMessage from "../pages/sales-channel/imessage/index.vue";
import SalesChannelWhatsapp from "../pages/sales-channel/whatsapp/index.vue";
import SalesChannelWhatsappWidget from "../pages/sales-channel/whatsapp/widget.vue";
import SalesChannelWhatsappMessageTemplates from "../pages/sales-channel/whatsapp/message-templates.vue";
import SalesChannelWhatsappAddTemplate from "../pages/sales-channel/whatsapp/add-template.vue";
import SalesChannelWhatsappBusinessSettings from "../pages/sales-channel/whatsapp/whatsapp-business-settings.vue"
import SalesChannelInstagram from "../pages/sales-channel/instagram/index.vue";
import SalesChannelInstagramIceBreakers from "../pages/sales-channel/instagram/ice-breakers.vue";
import SalesChannelInstagramBusinessSettings from "../pages/sales-channel/instagram/business-settings.vue";
import SalesChannelInstagramNotificationOptIn from "../pages/sales-channel/instagram/instagram-notification-opt-in.vue";
import SalesChannelLine from "../pages/sales-channel/line/index.vue";
import SalesChannelLineRichMenu from "../pages/sales-channel/line/rich-menu.vue";
import SalesChannelInviteLineFriends from "../pages/sales-channel/line/invite-friends.vue";
import SalesChannelAddRichMenu from "../pages/sales-channel/line/add-rich-menu.vue";
import SalesChannelViber from "../pages/sales-channel/viber/index.vue";
import SalesChannelTelegram from "../pages/sales-channel/telegram/index.vue";
import SalesChannelFacebook from "../pages/sales-channel/facebook/index.vue";
import SalesChannelFacebookAdJSON from "../pages/sales-channel/facebook/ad-json.vue";
import SalesChannelFacebookIceBreakers from "../pages/sales-channel/facebook/ice-breakers.vue";
import SalesChannelFacebookMessengerChatPlugin from "../pages/sales-channel/facebook/messenger-chat-plugin.vue";
import SalesChannelFacebookNotificationOptIn from "../pages/sales-channel/facebook/notification-opt-in.vue";
import SalesChannelFacebookPageSettings from "../pages/sales-channel/facebook/page-settings.vue";
import SalesChannelFacebookSharePost from "../pages/sales-channel/facebook/share-post.vue";
import SalesChannelFacebookShop from "../pages/sales-channel/facebook/shop.vue";
import SalesChannelFacebookPersistentMenu from "../pages/sales-channel/facebook/persistent-menu.vue";
import Settings from "../pages/settings/index.vue";
import LiveChatSettings from "../pages/settings/live-chat.vue";
import ManageTeam from "../pages/settings/team/index.vue";
import AddMember from "../pages/settings/team/add-member.vue";
import AddGroup from "../pages/settings/team/add-group.vue";
import PasswordSettings from "../pages/settings/password.vue";
import PaymentSettings from "../pages/settings/payments.vue";
import IntegrationSettings from "../pages/settings/integrations.vue";
import IntegrationConnect from "../pages/settings/integration-connect.vue";
import BusinessAccountSettings from "../pages/settings/account.vue";
import PolicySettings from "../pages/settings/policies.vue";
import TaxAndShippingSettings from "../pages/settings/tax-and-shipping.vue";
import Analytics from "../pages/analytics/store.vue";
import StoreAnalytics from "../pages/analytics/store.vue";
import InsightsAnalytics from "../pages/analytics/insights.vue";
import SurveyAnalytics from "../pages/analytics/survey.vue";
import Y2020Analytics from "../pages/analytics/y2020.vue";
import DataSignalsBcgAnalytics from "../pages/analytics/data-signals-bcg.vue";
import TeamAnalytics from "../pages/analytics/team.vue";
import AdPerformanceAnalytics from "../pages/analytics/ad-performance.vue";
import BroadcastAnalytics from "../pages/analytics/broadcast.vue";
import IntegrationImportProducts from "../pages/settings/integration-import-products.vue";
import Plans from "../pages/plans/plans.vue";
import NotFound from "../pages/NotFound.vue";

import STORE from "../store/index";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/account/edit-profile",
    component: EditProfile,
    meta: { requiredAuth: true }
  },
  {
    path: "/account/login-activities",
    component: LoginActivities,
    meta: { requiredAuth: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { requiredAuth: true },
  },
  {
    path: "/livechathome",
    component: LiveChatHome,
    meta: { requiredAuth: true, permissionKey: 'livechat', oldPermKey: 'page_inbox' },
  },
  {
    path: "/page-inbox",
    component: PageInbox,
    meta: { requiredAuth: true, permissionKey: 'livechat', oldPermKey: 'page_inbox' },
  },
  {
    path: "/customers",
    component: Customers,
    meta: { requiredAuth: true, permissionKey: 'manageCustomers', oldPermKey: 'customers' },
  },
  {
    path: "/customers/segments",
    component: CustomerSegments,
    meta: { requiredAuth: true, permissionKey: 'segments', oldPermKey: 'customers' },
  },
  {
    path: "/orders",
    component: Orders,
    meta: { requiredAuth: true, permissionKey: 'manageOrders' },
  },
  {
    path: "/order/:id",
    component: Orders,
    meta: { requiredAuth: true, permissionKey: 'createOrder' },
  },
  {
    path: "/orders/restaurant",
    component: RestaurantOrders,
    meta: { requiredAuth: true, permissionKey: 'manageOrders' },
  },
  {
    path: "/scanner",
    component: Scanner,
    meta: { requiredAuth: true, oldPermKey: 'manage_collect_locations' },
  },
  {
    path: "/settings",
    component: Settings,
    meta: { requiredAuth: true },
  },
  {
    path: "/settings/live-chat",
    component: LiveChatSettings,
    meta: { requiredAuth: true, permissionKey: 'liveChatSettings'},
  },
  {
    path: "/settings/password",
    component: PasswordSettings,
    meta: { requiredAuth: true, permissionKey: 'passwordSettings' },
  },
  {
    path: "/settings/policies",
    component: PolicySettings,
    meta: { requiredAuth: true, permissionKey: 'policiesAndConsent', oldPermKey: 'policies' },
  },
  {
    path: "/settings/tax-and-shipping",
    component: TaxAndShippingSettings,
    meta: { requiredAuth: true, permissionKey: 'taxesAndShipping', oldPermKey: 'shipping' },
  },
  {
    path: "/store",
    component: Store,
    meta: { requiredAuth: true, permissionKey: 'storePage' },
  },
  {
    path: "/add-product",
    component: AddProduct,
    meta: { requiredAuth: true, permissionKey: 'product' },
  },
  {
    path: "/add-set",
    component: AddSet,
    meta: { requiredAuth: true, permissionKey: 'set' },
  },
  {
    path: "/add-collection",
    component: AddCollection,
    meta: { requiredAuth: true, permissionKey: 'collection' },
  },
  {
    path: "/add-discount",
    component: AddDiscount,
    meta: { requiredAuth: true, permissionKey: 'discount' },
  },
  {
    path: "/orders/create",
    component: CheckoutDirect,
    meta: { requiredAuth: true, permissionKey: 'createOrder', oldPermKey: 'checkout_direct' },
  },
  {
    path: "/settings/team",
    component: ManageTeam,
    meta: { requiredAuth: true, permissionKey: 'manageTeamMembers', oldPermKey: 'roles_management' },
  },
  {
    path: "/settings/team/add-member",
    component: AddMember,
    meta: { requiredAuth: true, permissionKey: 'manageTeamMembers', oldPermKey: 'roles_management' },
  },
  {
    path: "/settings/team/add-group",
    component: AddGroup,
    meta: { requiredAuth: true, permissionKey: 'manageTeamMembers', oldPermKey: 'roles_management' },
  },
  {
    path: "/automations",
    component: Automations,
    meta: { requiredAuth: true }
  },
  {
    path: "/automations/broadcast",
    component: BroadcastWhatsapp,
    meta: { requiredAuth: true, permissionKey: 'broadcast', oldPermKey: 'broadcast' }
  },
  {
    path: "/automations/automate-responses",
    component: AutomateResponses,
    meta: { requiredAuth: true, permissionKey: 'automatedResponses', oldPermKey: 'ai_setup' },
  },
  {
    path: "/automations/bot-builder",
    component: BotBuilder,
    meta: { requiredAuth: true, permissionKey: 'botBuilder', oldPermKey: 'bot_builder' },
  },
  {
    path: "/automations/personalization",
    component: DefaultTemplates,
    meta: { requiredAuth: true, permissionKey: 'personalization', oldPermKey: 'personalisation' },
  },
  {
    path: "/automations/cart-recovery",
    component: CartRecovery,
    meta: { requiredAuth: true, permissionKey: 'cartRecovery', oldPermKey: 'cart_abandonment' },
  },
  {
    path: "/bulk-update/product",
    component: BulkUpdateProduct,
    meta: { requiredAuth: true, permissionKey: 'bulkProductsImport' }
  },
  {
    path: "/sales-channel/google-business-messages",
    component: SalesChannelGoogleBusinessMessages,
    meta: { requiredAuth: true, permissionKey: 'googlebusinessmsg' },
  },
  {
    path: "/sales-channel/sms",
    component: SalesChannelSMS,
    meta: { requiredAuth: true },
  },
  {
    path: "/sales-channel/re-direct-links",
    component: SalesChannelReDirectLinks,
    meta: { requiredAuth: true },
  },
  {
    path: "/sales-channel/attentive-widget",
    component: SalesChannelAttentiveWidget,
    meta: { requiredAuth: true },
  },
  {
    path: "/sales-channel/attentive-widget-settings",
    component: SalesChannelAttentiveWidgetSettings,
    meta: { requiredAuth: true },
  },

  {
    path: "/sales-channel/imessage",
    component: SalesChannelIMessage,
    meta: { requiredAuth: true, permissionKey: 'imessage' },
  },
  {
    path: "/sales-channel/web",
    component: SalesChannelWeb,
    meta: { requiredAuth: true, permissionKey: 'web' },
  },
  {
    path: "/sales-channel/web/custom-domain",
    component: SalesChannelWebCustomDomain,
    meta: { requiredAuth: true, oldPermKey: 'custom_domain' },
  },
  {
    path: "/sales-channel/whatsapp",
    component: SalesChannelWhatsapp,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' },
  },
  {
    path: "/sales-channel/whatsapp/widget",
    component: SalesChannelWhatsappWidget,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' },
  },
  {
    path: "/sales-channel/whatsapp/message-templates",
    component: SalesChannelWhatsappMessageTemplates,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' },
  },
  {
    path: "/sales-channel/whatsapp/add-template",
    component: SalesChannelWhatsappAddTemplate,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' }
  },
  {
    path: "/sales-channel/whatsapp/add-template/:id",
    component: SalesChannelWhatsappAddTemplate,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' }
  },
  {
    path: "/sales-channel/whatsapp/whatsapp-business-settings",
    component: SalesChannelWhatsappBusinessSettings,
    meta: { requiredAuth: true, permissionKey: 'whatsapp' }
  },
  {
    path: "/sales-channel/instagram",
    component: SalesChannelInstagram,
    meta: { requiredAuth: true, permissionKey: 'instagram' },
  },
  {
    path: "/sales-channel/instagram/ice-breakers",
    component: SalesChannelInstagramIceBreakers,
    meta: { requiredAuth: true, permissionKey: 'instagram' },
  },
  {
    path: "/sales-channel/instagram/notification-optin",
    component: SalesChannelInstagramNotificationOptIn,
    meta: { requiredAuth: true, permissionKey: 'instagram' },
  },
  {
    path: "/sales-channel/instagram/business-settings",
    component: SalesChannelInstagramBusinessSettings,
    meta: { requiredAuth: true, permissionKey: 'instagram' },
  },
  {
    path: "/sales-channel/facebook",
    component: SalesChannelFacebook,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/share-post",
    component: SalesChannelFacebookSharePost,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/ad-json",
    component: SalesChannelFacebookAdJSON,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/messenger-chat-plugin",
    component: SalesChannelFacebookMessengerChatPlugin,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/page-settings",
    component: SalesChannelFacebookPageSettings,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/shop",
    component: SalesChannelFacebookShop,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/ice-breakers",
    component: SalesChannelFacebookIceBreakers,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/persistent-menu",
    component: SalesChannelFacebookPersistentMenu,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/facebook/notification-opt-in",
    component: SalesChannelFacebookNotificationOptIn,
    meta: { requiredAuth: true, permissionKey: 'facebook' },
  },
  {
    path: "/sales-channel/line",
    component: SalesChannelLine,
    meta: { requiredAuth: true, permissionKey: 'line' },
  },
  {
    path: "/sales-channel/line/rich-menu",
    component: SalesChannelLineRichMenu,
    meta: { requiredAuth: true, permissionKey: 'line' },
  },
  {
    path: "/sales-channel/line/invite-friends",
    component: SalesChannelInviteLineFriends,
    meta: { requiredAuth: true, permissionKey: 'line' },
  },
  {
    path: "/add-rich-menu",
    component: SalesChannelAddRichMenu,
    meta: { requiredAuth: true, permissionKey: 'line' },
  },
  {
    path: "/sales-channel/viber",
    component: SalesChannelViber,
    meta: { requiredAuth: true, permissionKey: 'viber' },
  },
  {
    path: "/sales-channel/telegram",
    component: SalesChannelTelegram,
    meta: { requiredAuth: true, permissionKey: 'telegram' },
  },
  {
    path: "/settings/payments",
    component: PaymentSettings,
    meta: { requiredAuth: true, permissionKey: 'managePayments', oldPermKey: 'payment_methods' },
  },
  {
    path: "/settings/integrations",
    component: IntegrationSettings,
    meta: { requiredAuth: true, permissionKey: 'integrations' }
  },
  {
    path: "/settings/integrations/connect/:component",
    component: IntegrationConnect,
    meta: { requiredAuth: true, permissionKey: 'integrations' }
  },
  {
    path: "/settings/integrations/import/:importSource",
    component: IntegrationImportProducts,
    meta: { requiredAuth: true, permissionKey: 'integrations' }
  },
  {
    path: "/settings/account",
    component: BusinessAccountSettings,
    meta: { requiredAuth: true, oldPermKey: 'business_details' },
  },
  {
    path: "/analytics",
    component: Analytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/store",
    component: StoreAnalytics,
    meta: { requiredAuth: true, permissionKey: 'storeAnalytics', oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/insights",
    component: InsightsAnalytics,
    meta: { requiredAuth: true, permissionKey: 'insights', oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/survey",
    component: SurveyAnalytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/y2020",
    component: Y2020Analytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/data-signals-bcg",
    component: DataSignalsBcgAnalytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/team",
    component: TeamAnalytics,
    meta: { requiredAuth: true, permissionKey: 'baReport', oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/ad-performance",
    component: AdPerformanceAnalytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/analytics/broadcast",
    component: BroadcastAnalytics,
    meta: { requiredAuth: true, oldPermKey: 'analytics' }
  },
  {
    path: "/plans",
    component: Plans,
    meta: { requiredAuth: true }
  },
  {
    path: "/404",
    component: NotFound,
    meta: { requiredAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function guard(to, from, next, loginStatus) {
  if (to.meta && to.meta.requiredAuth) {

    if (loginStatus !== 'success') return next({ path: "/login" });
    if(to.path === '/404') return next();

    const isEnterprise = STORE._state.data.user.enterprise;

    let jupiterPermissions = process.env.VUE_APP_NODE_ENV.includes('asia') ? true : checkPermission(to);
    if(jupiterPermissions) {
      if(checkOldPermission(to)) return next();
      else return next({ path: "/404" });
    } else {
      if(isEnterprise) return next({ path: "/dashboard" });
      else return next({ path: "/plans" });
    }
    
  } else {
    if (loginStatus === 'success') {
      if(to.query?.token && to.query.userid) return next();
      return next({ path: "/dashboard" });
    }
    return next();
  }
}

function checkOldPermission(to) {
  try {
    const permissions = STORE.getters["user/getPermissions"];
    const isAdmin = STORE.getters["user/isAdmin"];
    const permissionKey = to.meta.oldPermKey;
    if(permissionKey === undefined) return true;
    if(isAdmin) return true;
    if(permissions[permissionKey] === true) return true;
    console.log(to.path);
    if((to.path === '/orders' || to.path === '/order/:id') && permissions.orders.view === true) return true;
    if(to.path === '/store' && (permissions.bundles.view === true || permissions.collections.view === true || permissions.products.view === true || permissions.discounts === true)) return true;
    if(to.path === '/add-product' && permissions.products.create === true) return true;
    if(to.path === '/add-set' && permissions.bundles.create === true) return true;
    if(to.path === '/add-collection' && permissions.collections.create === true) return true;
    if(to.path === '/add-discount' && permissions.discounts === true) return true;
    return false;
  } catch (error) {
    console.error(error);
  }
}

function checkPermission(to) {
  try {
    const jupiter = STORE.getters["auth/getJupiterSubscription"];
    const permissions = jupiter?.permissions || {};
    const permissionKey = to.meta.permissionKey;
    if(permissionKey === undefined) return true;
    if(permissions[permissionKey] === true) return true;
    console.log(to.path);
    if((to.path === '/orders' || to.path === '/order/:id') && permissions.manageOrders === true) return true;
    if(to.path === '/store' && (permissions.product === true || permissions.set === true || permissions.collection === true || permissions.discount === true)) return true;
    if(to.path === '/add-product' && permissions.product === true) return true;
    if(to.path === '/add-set' && permissions.set === true) return true;
    if(to.path === '/add-collection' && permissions.collection === true) return true;
    if(to.path === '/add-discount' && permissions.discount === true) return true;
    return false;
  } catch (error) {
    console.error(error);
  }
}
 
router.beforeEach(async (to, from, next) => {
  var loginStatus = STORE.getters["auth/getLoginStatus"];
  if (loginStatus === 'failed') {
    await STORE.dispatch("auth/loadStorageTokens");
    loginStatus = STORE.getters["auth/getLoginStatus"];
    return guard(to, from, next, loginStatus);
  } else {
    return guard(to, from, next, loginStatus);
  }
});

export default router
