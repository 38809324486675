<template>
  <div class="infoBanner">
    <div class="panel panel-default">
      <div class="panel-body">
        <h4>If you are unable to see your changes, consider pressing this refresh icon.</h4>
        <ion-buttons slot="end">
          <ion-button @click="refreshIntegrations">
            <ion-icon class="refresh-button" size="large" :icon="refreshCircleOutline" ></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </div>
  </div>
</template>

<script>

import { refreshCircleOutline } from 'ionicons/icons';
import jwtInterceptor from '../../shared/jwtInterceptor';
import { environment } from '/src/shared/env';
import { Preferences as Storage } from "@capacitor/preferences";
import { mapActions } from "vuex";

export default {
  components: {  },
  data() {
    return {
      refreshCircleOutline
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions({
      saveIntegrationData: "integration/saveIntegrationData",
      updateIntegration: "integration/updateIntegration"
    }),
    async refreshIntegrations() {
      this.$emit("isLoading", true);
      try {
        var commerceResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "get-commerce");
        var easyParcelResponse = jwtInterceptor.get(environment('VUE_APP_ROOT_API') + "get-easyparcel");
        var grabResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "fetch-grab");
        var dialogFlowResponse = jwtInterceptor.post(environment('VUE_APP_ROOT_API') + "list-dialogflow");
        let allResponses = await Promise.all([commerceResponse, easyParcelResponse, grabResponse, dialogFlowResponse]);
        commerceResponse = allResponses[0];
        easyParcelResponse = allResponses[1];
        grabResponse = allResponses[2];
        dialogFlowResponse = allResponses[3];

        await this.commerceDataHandler(commerceResponse);
        await this.easyParcelDataHandler(easyParcelResponse);
        await this.grabDataHandler(grabResponse);
        await this.dataFlowDataHandler(dialogFlowResponse);
      } catch (error) {
        this.updateToast({ open: true, message: "Refresh failed. Please try again.", type: "danger" })
      }
      this.$emit("isLoading", false);
    },
    async commerceDataHandler(commerceResponse) {
      let commerceResponseData;
      if (commerceResponse && (commerceResponse.status == 200 || commerceResponse.status == 201) && commerceResponse.data && commerceResponse.data.success) {
        commerceResponseData = commerceResponse.data;
        await Storage.set({
          key: "jmpr_getCommerceData",
          value: JSON.stringify(commerceResponseData),
        });
        this.saveIntegrationData(commerceResponseData);
      } else {
        throw "Something went wrong, please try again";
      }
    },
    async easyParcelDataHandler(easyParcelResponse) {
      let easyParcelResponseData;
      if (easyParcelResponse && (easyParcelResponse.status == 200 || easyParcelResponse.status == 201) && easyParcelResponse.data && easyParcelResponse.data.success) {
        easyParcelResponseData = easyParcelResponse.data;
        if (easyParcelResponseData?.easyship?.length) {
          easyParcelResponseData['is_active'] = true;
        }
        await Storage.remove({ key: "jmpr_easyParcel" });
        const easyParcelDataToUpdate = {
          integrationName: 'easyparcel', dataToUpdate: easyParcelResponseData, storageName: 'jmpr_easyParcel'
        }
        await this.updateIntegration(easyParcelDataToUpdate);
      } else {
        throw "Something went wrong, please try again";
      }
    },
    async grabDataHandler(grabResponse) {
      let grabResponseData;
      if (grabResponse && (grabResponse.status == 200 || grabResponse.status == 201) && grabResponse.data && grabResponse.data.success) {
        grabResponseData = grabResponse.data;
        if (grabResponseData?.grab) {
          grabResponseData['is_active'] = true;
        }
        await Storage.remove({ key: "jmpr_easyParcel" });

        const integrationDataToUpdate = {
          integrationName: 'grab', dataToUpdate: grabResponseData, storageName: 'jmpr_grab'
        }
        this.updateIntegration(integrationDataToUpdate);
      } else {
        throw "Something went wrong, please try again";
      }
    },
    async dataFlowDataHandler(dialogFlowResponse) {
      let dialogFlowData;
      if (dialogFlowResponse && (dialogFlowResponse.status == 200 || dialogFlowResponse.status == 201) && dialogFlowResponse.data && dialogFlowResponse.data.success) {
        dialogFlowData = dialogFlowResponse.data;
        // await Storage.set({
        //   key: "jmpr_easyParcel",
        //   value: JSON.stringify(dialogFlowData),
        // });
        if (dialogFlowData?.email) {
          dialogFlowData['is_active'] = true;
        }
        const dialogFlowDataToUpdate = {
          integrationName: 'dialogflow', dataToUpdate: dialogFlowData
        }
        this.updateIntegration(dialogFlowDataToUpdate);
      } else {
        throw "Something went wrong, please try again";
      }

    }
  }
}
</script>

<style scoped>
.infoBanner .panel.panel-default {
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
}

.infoBanner .panel .panel-body {
  border-left: 3px solid #FFC409;
  display: flex;
  justify-content: space-between;
}
.infoBanner .panel .panel-body h4{
  padding: 10px 0;
}

.infoBanner.success .panel.panel-default {
  background-color: #eafdf2;
}

.infoBanner.success .panel .panel-body {
  border-left: 3px #099e6e solid;
}

.infoBanner.danger .panel.panel-default {
  background-color: #ffd4d4;
}

.infoBanner.danger .panel .panel-body {
  border-left: 3px #ff0000 solid;
}
</style>