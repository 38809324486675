<template>
  <ion-item>
    <RecycleScroller
      class="scroller"
      :items="items"
      :item-size="80"
      key-field="productid"
      direction="horizontal"
      mode="ios"
    >
      <template
        #default="{ item, index }"
        class="recycler-scroller-template"
      >
        <div
          class="img-product-wrapper"
          :class="{selected: item.selected}"
          :style="`background-image: url(${item.image});`"
          @click="onItemSelect(item, index)"
        >
        </div>
      </template>
    </RecycleScroller>
  </ion-item>
  <ion-item
    lines="none"
    class="item-configuration-wrapper ion-no-padding ion-no-margin"
    v-if="itemToEdit && itemToEdit.productid"
  >
    <ion-list>
      <ion-item>
        <ion-label class="label_custom_style">Item Configuration</ion-label>
      </ion-item>
      <ion-item lines="inset">
        <ion-label class="label">Upload Image</ion-label>
        <div class="input-wrapper">
          <input
            type="file"
            style="display: none;"
            id="productImage"
            ref="productImage"
            @change="(event) => $emit('onImageSelect', event)"
            accept="image/*"
          />
          <ion-button
            @click="() => $refs.productImage.click()"
            expand="block"
            fill="outline"
            class="btn-upload"
          >Upload image</ion-button>
        </div>
      </ion-item>
      <ion-item lines="inset">
        <ion-label class="label">Or Paste Image URL</ion-label>
        <ion-input
          v-model="itemToEdit.image"
          type="text"
          placeholder="Image URL"
          class="fullBordered input_custom_style input-wrapper"
          @ionChange="itemToEditUpdated"
        ></ion-input>
      </ion-item>
      <ion-item lines="inset">
        <ion-label class="label">Headline</ion-label>
        <ion-input
          v-model="itemToEdit.title"
          type="text"
          placeholder="Headline"
          class="fullBordered input_custom_style input-wrapper"
          @ionChange="itemToEditUpdated"
        ></ion-input>
      </ion-item>
      <ion-item lines="inset">
        <ion-label class="label">Description</ion-label>
        <ion-input
          v-model="itemToEdit.subtitle"
          type="text"
          placeholder="Headline"
          class="fullBordered input_custom_style input-wrapper"
          @ionChange="itemToEditUpdated"
        ></ion-input>
      </ion-item>
      <ion-item lines="inset">
        <ion-label class="label">Call to Action</ion-label>
        <div class="input-wrapper">
          <ion-select
            v-model="itemToEdit.type"
            placeholder="Select Type"
            @ionChange="emitCallToActionValue"
            class="select-action-type"
            mode="ios"
            slots="end"
          >
            <ion-select-option
              v-for="(thisOption, oIndex) in ctaOptions"
              :key="oIndex"
              :value="thisOption.payload"
              slots="end"
            >{{ thisOption.name }}</ion-select-option>
          </ion-select>
        </div>
      </ion-item>
    </ion-list>
  </ion-item>
</template>

<script>
import { ref } from "vue";
import { IonSelect, IonSelectOption } from "@ionic/vue";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: {
    RecycleScroller,
    IonSelect,
    IonSelectOption,
  },
  emits: [
    "onItemSelect",
    "onChangeCallToAction",
    "onImageSelect",
    "onUpdatedItemToEdit",
  ],
  props: {
    itemsProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      itemToEdit: null,
      ctaOptions: [
        {
          name: "Shop Now",
          payload: "SHOP_NOW",
        },
        {
          name: "Buy Now",
          payload: "BUY_NOW",
        },
        {
          name: "Order Now",
          payload: "ORDER_NOW",
        },
        {
          name: "Add To Cart",
          payload: "ADD_TO_CART",
        },
        {
          name: "Buy",
          payload: "BUY",
        },
        {
          name: "Message Page",
          payload: "MESSAGE_PAGE",
        },
        {
          name: "Get Offer",
          payload: "GET_OFFER",
        },
        {
          name: "View Offer",
          payload: "GET_OFFER_VIEW",
        },
        {
          name: "Remove button",
          payload: "NO_BUTTON",
        },
      ],
    };
  },
  watch: {
    items: {
      handler: function () {
        if (!this.itemToEdit && this.items?.length) {
          this.itemToEdit = this.items[0];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  setup() {
    const list = ref([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    return { list };
  },
  computed: {
    items() {
      return this.itemsProp.map((item) => {
        if (this.itemToEdit && item.productid == this.itemToEdit.productid) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
    },
  },
  methods: {
    onItemSelect(selectedItem, index) {
      this.itemToEdit = selectedItem;
      this.$emit("onItemSelect", { selectedItem, index });
    },
    emitCallToActionValue(event) {
      const selectedValueObject = this.ctaOptions.find(
        (elm) => elm.payload == event?.detail?.value
      );
      this.$emit("onChangeCallToAction", selectedValueObject?.name);
      this.itemToEditUpdated();
    },
    itemToEditUpdated() {
      this.$emit("onUpdatedItemToEdit", this.itemToEdit);
    },
  },
};
</script>
<style scoped>
.scroller {
  height: 100px;
}
.img-product {
  border-radius: 0;
}
.img-product-wrapper {
  width: 75px;
  height: 80px;
  display: flex;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-size: auto 100%;
}
.img-product-wrapper.selected {
  border: 1px solid var(--ion-color-dark-tint);
}
.item-configuration-wrapper ion-list {
  width: 100%;
}
.item-configuration-wrapper ion-item {
  display: flex;
}
.item-configuration-wrapper .label {
  flex: 3;
}
.item-configuration-wrapper .input-wrapper {
  flex: 7;
  display: flex;
}
.item-configuration-wrapper .input-wrapper .select-action-type {
  width: fit-content;
  padding-left: 0;
}
.item-configuration-wrapper .input-wrapper .btn-upload {
  width: fit-content;
  padding-left: 0;
}

@media only screen and (max-width: 780px) {
  .item-configuration-wrapper .input-wrapper .select-action-type {
    width: 100%;
    max-width: 100%;
  }
}
</style>