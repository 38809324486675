<template>
  <master-layout pageTitle="Whatsapp Templates">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="chat-toolbar" color="light">
          <ion-item class="searchBar" lines="none">
            <ion-input v-model="templateFilter" placeholder="Enter template name"></ion-input>
          </ion-item>
        </ion-toolbar>
        <ion-grid class="table-header ion-no-padding">
            <ion-row class="ion-justify-content-between">
              <ion-col class="ion-hide-md-down"  size="4">
                Name
              </ion-col>
              <ion-col class="ion-hide-md-down"  size="3">
                Category
              </ion-col>
              <ion-col class="ion-hide-md-down"  size="2">
                Status
              </ion-col>
              <ion-col class="ion-hide-md-down"  size="1">
                Language
              </ion-col>
              <ion-col class="ion-hide-md-down" size="2">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="(template, tIndex) in templatesToShow" :key="tIndex" class="table-row ion-no-padding ">
                <ion-col class="orderDesc colMargin" size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6">
                  {{ template.template_name }}
                </ion-col>
                <ion-col class="orderDesc colMargin" size-xl="3" size-lg="3" size-md="3" size-sm="6" size-xs="6">
                  {{ template.category }}
                </ion-col>
                <ion-col v-if="template.templates && template.templates.length" class="orderDesc colMargin" size-xl="2" size-lg="2" size-md="2" size-sm="6" size-xs="6">
                  {{ template.templates[0].status }}
                </ion-col>
                <ion-col v-if="template.templates && template.templates.length" class="orderDesc colMargin" size-xl="1" size-lg="1" size-md="1" size-sm="6" size-xs="6">
                  {{  template.templates[0].language }}
                </ion-col>
                <ion-col size-xl="2" size-lg="2" size-md="2" size-sm="6" size-xs="6" class="colMargin">
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/sales-channel/whatsapp/add-template' + (template.templates && template.templates.length ? ('/' + template.templates[0].id) : ''))" fill="clear" size="default" color="dark">
                      <ion-icon :icon="eyeOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">view</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="() => templateIndexToDelete = tIndex" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        <ion-infinite-scroll
          @ionInfinite="fetchTemplates($event)" 
          threshold="100px" 
          id="infinite-scroll"
          :disabled="templatesLoadMore || allTemplates.length <= 0"
        >
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more templates...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <div class="text-center" v-if="templateFilter && templateFilter.length && templatesToShow.length === 0" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No templates matching this filter! 😟 <span @click="() => templateFilter = undefined">Clear filter</span></h5>
        </div>
        
        <div class="text-center" v-if="allTemplates.length == 0 && !templatesLoadMore" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No templates yet! 😟</h5>
        </div>

        <div class="text-center" v-if="allTemplates.length == 0 && templatesLoadMore" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>Fetching templates...</h5>
        </div>
      </div>
    </ion-content>
    <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => $router.push('/sales-channel/whatsapp/add-template')">
      <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
    </button>
    <ion-alert
      v-if="templateIndexToDelete > -1"
      :is-open="templateIndexToDelete > -1"
      header="Are you sure?"
      :message="'You want to delete selected template?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => templateIndexToDelete = -1
      },
      {
        text: 'Yes',
        handler: () => deleteTemplate()
      }]"
      @didDismiss="() => templateIndexToDelete = -1"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
//import { mapGetters } from "vuex";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { chevronDownCircleOutline, addCircleOutline, trashOutline, eyeOutline } from 'ionicons/icons';
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";

export default {
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashOutline,
      eyeOutline,
      loading: false,
      allTemplates: [],
      templatesLoadMore: false,
      templatesLoaded: false,
      templateIndexToDelete: -1,
      templateFilter: undefined
    }
  },
  computed: {
    templatesToShow() {
      return this.templateFilter && this.templateFilter.length ? this.allTemplates.filter(e => e.template_name.match(new RegExp(this.templateFilter, 'gi'))) : this.allTemplates;
    }
  },
  ionViewWillEnter() {
    this.fetchTemplates();
  },
  methods: {
    doRefresh(e) {
      this.templatesLoaded = false;
      e.target.complete();
    },
    async fetchTemplates(e) {
      if(!e) this.loading = true;
      else this.templatesLoadMore = true;
      try {
        let allTemplatesResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/message-template/fetch", { limit: 'all' });
        if ((allTemplatesResponse.status == 200 || allTemplatesResponse.status == 201) && allTemplatesResponse.data.success) {
          this.allTemplates = allTemplatesResponse.data.data;
          console.log(this.allTemplates);
          this.templatesLoaded = true;
        } else {
          if(allTemplatesResponse.data && !allTemplatesResponse.data.success && allTemplatesResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: allTemplatesResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", allTemplatesResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        if(e) {e.target.complete(); this.templatesLoadMore = false;}
        else this.loading = false;
      }
    },
    async deleteTemplate() {
      this.loading = true;
      try {
        let thisTemplateToDelete = this.templatesToShow[this.templateIndexToDelete];
        console.log(thisTemplateToDelete.template_name);
        if(thisTemplateToDelete.templates && thisTemplateToDelete.templates.length) {
          let deleteTemplateResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/message-template/delete", { collection_id: thisTemplateToDelete.templates[0].collection_id });
          if ((deleteTemplateResponse.status == 200 || deleteTemplateResponse.status == 201) && deleteTemplateResponse.data.success && deleteTemplateResponse.data.data && deleteTemplateResponse.data.data.success) {
            this.fetchTemplates();
            this.updateToast({
              open: true,
              message: "Deleted Successfully",
              type: "success"
            })
          } else {
            if(deleteTemplateResponse.data && !deleteTemplateResponse.data.success && deleteTemplateResponse.data.errorMessage) {
              this.updateToast({
                open: true,
                message: deleteTemplateResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
              //console.log("Response:", deleteTemplateResponse);
            }
            this.loading = false;
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.loading = false;
      } finally {
        this.templateIndexToDelete = -1;
      }
    }
  }
}
</script>

<style scoped>
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}
.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}
.tooltip_container .tooltip_text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}
@media only screen and (max-width: 760px) {
.colMargin {
  margin-bottom: 20px;
}
}

</style>