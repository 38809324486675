/* eslint-disable no-console */
//importScripts('/cache-polyfill.js');
import { register } from 'register-service-worker';
import { getPlatforms } from "@ionic/vue";
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Preferences as Storage } from "@capacitor/preferences";
import { environment } from '/src/shared/env';

if (process.env.VUE_APP_NODE_ENV.includes('production')) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is available; please refresh.')
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      })
      location.reload()
      self.skipWaiting();
      console.log('New content is downloading.');
    },
    updated () {
      console.log('New content has been updated.');
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
  
}

if(getPlatforms().includes("android") || getPlatforms().includes("ios")) {
  //eslint-disable-next-line
  const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {
      console.info('Push registration success, token: ', token.value);
      FCM.getToken().then(async (r) => {
        let jmpr_device_token = (await Storage.get({ key: "jmpr_device_token" })).value || "";
        let jmpr_device_token___currently_saved_token = (await Storage.get({ key: "jmpr_device_token___currently_saved_token" })).value || "";
        if(jmpr_device_token___currently_saved_token == '' || jmpr_device_token___currently_saved_token == null || jmpr_device_token != jmpr_device_token___currently_saved_token){
          await Storage.remove({ key: "jmpr_device_token___currently_saved_token" });
          await Storage.set({ key: "jmpr_device_token", value: r.token });
          if(getPlatforms().includes("android")) await Storage.set({ key: "jmpr_device_type", value: "android" });
          if(getPlatforms().includes("ios")) await Storage.set({ key: "jmpr_device_type", value: "ios" });
        }
      }).catch((err) => console.log(err));
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed',async notification => {
      console.log('Push notification action performed', JSON.stringify(notification));
      if(notification.notification && notification.notification.data && notification.notification.data.id) {
        await Storage.set({ key: "jmpr_redirect_url", value: "/page-inbox?conversation="+ notification.notification.data.id });
        let event = new CustomEvent("jmpr_redirect_url", { detail: "/page-inbox?conversation="+ notification.notification.data.id });
        document.dispatchEvent(event);
      }
      else {
        await Storage.set({ key: "jmpr_redirect_url", value: "/page-inbox" });
        let event = new CustomEvent("jmpr_redirect_url", { detail: "/page-inbox" });
        document.dispatchEvent(event);
      }
      console.log("notification succeeded");
    });
  }
  //eslint-disable-next-line
  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
  
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      console.log('User denied permissions!');
    }
  
    await PushNotifications.register();
  }
  //eslint-disable-next-line
  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }
} else {
  // Import the functions you need from the SDKs you need
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig;
  firebaseConfig = {
    apiKey: environment("VUE_APP_FIREBASE_API_KEY"),
    authDomain: environment("VUE_APP_FIREBASE_AUTH_DOMAIN"),
    databaseURL: environment("VUE_APP_FIREBASE_DB_URL"),
    projectId: environment("VUE_APP_FIREBASE_PROJECT_ID"),
    storageBucket: environment("VUE_APP_FIREBASE_BUCKET"),
    messagingSenderId: environment("VUE_APP_FIREBASE_SENDER_ID"),
    appId: environment("VUE_APP_FIREBASE_APP_ID"),
    measurementId: environment("VUE_APP_FIREBASE_MEASUREMENT_ID")
  };
  // Initialize Firebase
  //eslint-disable-next-line
  firebase.initializeApp(firebaseConfig);
  //eslint-disable-next-line
  const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

  if(messaging) {
    messaging.requestPermission().then(() => {
      console.log("Notifications allowed");
      return messaging.getToken();
    }).then(async token => {
      console.log("Token Is : " + token);
      let jmpr_device_token = (await Storage.get({ key: "jmpr_device_token" })).value || "";
      let jmpr_device_token___currently_saved_token = (await Storage.get({ key: "jmpr_device_token___currently_saved_token" })).value || "";
      if(jmpr_device_token___currently_saved_token == '' || jmpr_device_token___currently_saved_token == null || jmpr_device_token != jmpr_device_token___currently_saved_token){
        await Storage.remove({ key: "jmpr_device_token___currently_saved_token" });
        await Storage.set({ key: "jmpr_device_token", value: token });
        await Storage.set({ key: "jmpr_device_type", value: "web" });
      }
    }).catch(err => {
      console.log("; " + err);
      console.log("No permission to send push", err);
    });
    messaging.onMessage(payload => {
      console.log("Message received. ", payload);
      const { title, ...options } = payload.notification;
      console.log(title);
      console.log(options);
    });
  }
}