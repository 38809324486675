<template>
<master-layout pageTitle="Store">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."></ion-loading>
    <div v-else>
      <ion-toolbar class="chat-toolbar">
        <ion-buttons slot="start">
          <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
          <h2 class="prdt-title ion-no-margin">{{(dummyProduct.id ? 'Edit' : 'Add') + ' Product'}}</h2>
        </ion-buttons>
        <ion-buttons slot="end" class="paddingBox">
          <ion-button :disabled="checkingHashtag && !validHashtag" @click="updateProduct" color="white" class="bttn-bgnd">Save Product</ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-grid>
        <ion-row>
          <!-- left panel -->
          <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
            <ion-accordion-group value="addProduct" class="product-container">
              <ion-accordion class="model-sections" value="addProduct">
                <ion-item slot="header" lines="none">
                  <ion-text class="order-title">
                      Product details
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding">
                  <ion-card-header>
                    <div>
                      <ion-card-title class="common-label">Product images (up to 5)</ion-card-title>
                      <div v-if="!dummyProduct.images || dummyProduct.images.length === 0" class="custom_ThumbnailBox">
                        <img src="https://jumper.ai/web-s/resources/images/upload-image-placeholder.png" alt="" class="img-container" />
                      </div>
                      <div v-else>
                        <ion-grid>
                          <ion-row>
                            <ion-col v-for="(thisImage, iIndex) in dummyProduct.images" :key="iIndex" >
                              <img :src="thisImage" alt="" class="img-container" />
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </div>
                      <div>
                        <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
                        <ion-button @click="() => $refs.productImage.click()" expand="block" fill="outline">Upload images</ion-button>
                      </div> 
                    </div>
                  </ion-card-header>
                  <ion-card-header>
                    <div class="section_style_v1">
                      <ion-card-title class="label_custom_style common-label"> Product tags </ion-card-title>
                      <div>
                        <ion-input v-model="newTag" @keyup.enter="addTag()" type="text" placeholder="Enter tag" class="fullBordered input_custom_style"></ion-input>
                        <div class="input_custom_style input-tags">
                          <ion-chip v-for="(thisTag, tIndex) in dummyProduct.tags" :key="tIndex">
                            <ion-label>{{ thisTag }}</ion-label>
                            <ion-icon @click="removeTag(tIndex)" :icon="closeOutline" class="icon-color"></ion-icon>
                          </ion-chip>
                        </div>
                        <div>
                          <ion-button :disabled="!dummyProduct.description || dummyProduct.description.length === 0 || suggestedTags.length !== 0" @click="generateTags" color="dark">Generate Suggested Tags</ion-button>
                        </div>
                        <div v-if="suggestedTags && suggestedTags.length && !dummyProduct.tags.some(r => suggestedTags.indexOf(r) >= 0)">
                          <ion-chip v-for="(thisTag, tIndex) in suggestedTags" :key="tIndex">
                            <ion-label>{{ thisTag }}</ion-label>
                            <ion-icon @click="addTag(thisTag)" name="add-circle-outline"></ion-icon>
                          </ion-chip>
                          <ion-button @click="addTag(suggestedTags)" color="dark">Add All</ion-button>
                        </div>
                      </div>
                    </div>
                  </ion-card-header>
                </ion-list>
              </ion-accordion>
              <ion-accordion class="model-sections" value="addProduct">
                <ion-item slot="header" lines="none">
                    <ion-text class="order-title">
                      Other Settings
                    </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding">
                    <ion-grid class="card_container_grid ion-no-padding" style="margin-top: 0px">
                      <ion-row class="ion-no-padding" style="margin-bottom: 5px">
                        <ion-col style="padding-top: 20px"> 
                          <ion-text class="common-label">
                              Visible on store
                          </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right"> 
                          <ion-toggle v-model="dummyProduct.shopActive" class="cust_toggle"></ion-toggle>
                        </ion-col>
                      </ion-row>
                      <ion-row row class="ion-no-padding" style="margin-bottom: 5px">
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label">
                              Ask customer for their address  
                          </ion-text>
                          </ion-col>
                        <ion-col class="ion-no-padding text-right">
                        <ion-toggle v-model="dummyProduct.skipaddress" class="cust_toggle"></ion-toggle>
                        </ion-col>
                      </ion-row>
                      <ion-row row class="ion-no-padding" style="margin-bottom: 5px">
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label">
                              Ask customer for their email
                          </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right">
                        <ion-toggle v-model="dummyProduct.skipemail" class="cust_toggle"></ion-toggle>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-list>
              </ion-accordion>
            </ion-accordion-group>
          </ion-col>
          <!-- right panel -->
          <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
            <ion-accordion-group value="productDetails" style="margin-top: 5px">
              <ion-accordion class="model-sections"  value="productDetails">
                <ion-item slot="header" lines="none">
                  <ion-text class="order-title">
                      Product & Stock details
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding">
                  <ion-card-header>
                    <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Product Name</ion-card-title>
                        <ion-input type="text" placeholder="Enter product name" v-model="dummyProduct.name" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </div>
                    <div v-if="dummyProduct.id === undefined" class="prdct-section">
                        <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                        <ion-input type="text" v-model="dummyProduct.hashtag" @blur="checkHashtag" placeholder="Enter your product's checkout trigger" class="fullBordered input_custom_style"></ion-input>
                    </div>
                    <ion-grid v-else class="pad_zero">
                      <ion-row class="pad_zero">
                        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                          <div class="prdct-section">
                            <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                            <div class="pre_post_text_cont">
                              <span class="pre_text"> # </span>
                              <ion-input type="text" disabled v-model="dummyProduct.hashtag" placeholder="Enter your product's checkout trigger" class="fullBordered input_custom_style"></ion-input>
                              <span class="post_text"> ! </span>
                            </div>
                          </div>
                        </ion-col>
                    
                        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                          <div class="prdct-section">
                            <ion-card-title class="sctn-labels">Product URL</ion-card-title>
                            <div style="margin-top: 5px">
                              <a :href="shopLinkConsideringCustomDomain + '/' + dummyProduct.hashtag" class="hyperlink_cust">
                                  {{ shopLinkConsideringCustomDomain + '/' + dummyProduct.hashtag }}
                              </a>
                            </div>
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                    <!-- Only for two entreprises -->
                    <!-- <div class="prdct-section">
                      <ion-card-title class="sctn-labels">Product external URL</ion-card-title>
                        <ion-input type="text" v-model="dummyProduct.online_event_url" placeholder="Enter / Paste URL" class="fullBordered input_custom_style sctn-input"></ion-input>
                    </div> -->
                    <div class="prdct-section">
                      <ion-card-title class="sctn-labels">Short Description</ion-card-title>
                      <div>
                        <ion-textarea v-model="dummyProduct.description" rows="4" placeholder="Enter short description" class="fullBordered input_custom_style sctn-input"></ion-textarea>
                      </div>
                    </div>
                    <div class="prdct-section">
                      <ion-card-title class="sctn-labels">Product description</ion-card-title>
                      <div>
                        <ckeditor rows="4" placeholder="Enter product description" style="height: 200px;" :editor="editor" v-model="eventDetail" :config="editorConfig"></ckeditor>
                      </div>
                    </div>
                  </ion-card-header>
                </ion-list>
              </ion-accordion>
              <ion-accordion class="model-sections"  value="productDetails">
                <ion-item slot="header" lines="none">
                  <ion-text class="order-title">
                    Stock Details
                  </ion-text>
                </ion-item>
                <ion-list slot="content" class="ion-no-padding">
                  <ion-card-header style="border: 1px solid #EDEDED"> 
                    <ion-grid>
                      <ion-row>
                        <ion-col>
                          <div class="prdct-section">
                            <ion-card-title class="sctn-labels">Price</ion-card-title>
                            <div class="pre_text_cont">
                              <!-- Will always be by default user.currency -->
                                <span class="pre_text">
                                  {{ dummyProduct.currency }}
                                </span>
                                <ion-input type="text" v-model="dummyProduct.cost" placeholder="100.00" class="fullBordered input_custom_style sctn-input"></ion-input>
                            </div>
                          </div>
                        </ion-col>
                        <ion-col>
                          <div class="prdct-section">
                            <ion-card-title class="sctn-labels">Product Weight</ion-card-title>
                            <div class="post_select_cont">
                              <ion-input type="text" v-model="dummyProduct.grams" placeholder="0.50" class="fullBordered input_custom_style sctn-input"></ion-input>
                              <span>
                                <ion-list>
                                  <ion-item class="no_bdr">
                                    <ion-select v-model="dummyProduct.weight_si_unit" ok-text="Okay" cancel-text="Dismiss">
                                      <ion-select-option value="kg">kg</ion-select-option>
                                      <ion-select-option value="lbs">lbs</ion-select-option>
                                      <ion-select-option value="oz">oz</ion-select-option>
                                    </ion-select>
                                  </ion-item>
                                </ion-list>
                              </span>
                            </div>
                          </div>
                        </ion-col>
                        <ion-col>
                          <div class="prdct-section">
                            <ion-card-title class="sctn-labels">Stock</ion-card-title>
                              <ion-input v-model="dummyProduct.stock" type="text" placeholder="500" class="fullBordered input_custom_style sctn-input"></ion-input>
                          </div>
                        </ion-col>
                      </ion-row>
                        <ion-row>
                          <ion-col>
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">SKU</ion-card-title>
                                <ion-input v-model="dummyProduct.sku" type="text" placeholder="SKU" class="fullBordered input_custom_style sctn-input"></ion-input>
                            </div>
                          </ion-col>
                        </ion-row>
                    </ion-grid>
                  </ion-card-header>
                  <ion-card-header>
                    <div class="prdct-section">
                      <!-- <ion-grid>
                        <ion-row>
                          <ion-col class="ion-no-padding">
                            <ion-text class="sctn-labels">Does your product have variations ?</ion-text>
                          </ion-col>
                          <ion-col class="ion-no-padding text-right">
                            <ion-checkbox v-model="dummyProduct.variationEnabled"></ion-checkbox>
                          </ion-col>
                        </ion-row>
                      </ion-grid> -->
                      <ion-grid v-if="dummyProduct.variationEnabled" style="margin-top: 20px"> 
                        <ion-row>
                          <ion-col  size-xl="2" size-lg="2" size-md="2" size-sm="2" size-xs="4">
                            <ion-card-title class="sctn-labels">
                              <div class="placeholder_img_cont">
                                 <img class="placeholder_img" src="https://jumper.ai/web-s/resources/images/upload-image-placeholder.png" alt="">
                              </div>
                            </ion-card-title>
                          </ion-col>

                          <ion-col size-xl="4" size-lg="12" size-md="12" size-sm="12" size-xs="12">
                              <h2 class="sctn-labels ion-no-margin">Attribute name</h2>
                              <ion-input type="text" placeholder="Eg. Color" class="fullBordered input_custom_style"></ion-input>
                          </ion-col>

                          <ion-col size-xl="4" size-lg="12" size-md="12" size-sm="12" size-xs="12">
                              <h2 class="sctn-labels ion-no-margin">Attribute values</h2>
                          <ion-input type="text" placeholder="Eg. Red, Green, Blue" class="fullBordered input_custom_style"></ion-input>
                          </ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col size="12">
                            <ion-button color="light" expand="full" style="border: 1px solid #666666">
                              <ion-icon :icon="addOutline" style="margin-right: 5px; color: black" color="dark"></ion-icon>
                              Add another attribute
                              </ion-button>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </div>
                  </ion-card-header>
                </ion-list>
              </ion-accordion>
              <ion-accordion class="model-sections"  value="productDetails">
                <ion-item slot="header" lines="none">
                    <ion-text class="order-title">
                        Stock settings
                    </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding">
                    <ion-card-header>
                      <ion-grid>
                        <ion-row>
                          <ion-col class="sctn-labels">Ask shoppers for customizations / provide special instructions</ion-col>
                          <ion-col class="text-right"> 
                              <ion-checkbox v-model="dummyProduct.notes"></ion-checkbox>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col class="sctn-labels">Allow customer to buy more than one quantity of this product per order</ion-col>
                          <ion-col class="text-right">
                              <ion-checkbox v-model="dummyProduct.askquantity"></ion-checkbox>
                          </ion-col>
                        </ion-row>
                        <ion-row v-if="dummyProduct.askquantity">
                          <ion-col class="sctn-labels">Maximum quantity per order</ion-col>
                          <div class="text-right">
                          <ion-col>
                            <ion-input v-model="dummyProduct.quantity_limit" type="number" placeholder="Enter Maximum quantity per order" class="fullBordered input_custom_style" style="width: 125px"></ion-input>
                          </ion-col>
                          </div>
                        </ion-row>
                        <ion-row v-if="dummyProduct.askquantity">
                          <ion-col class="sctn-labels">Maximum quantity per customer</ion-col>
                          <div class="text-right">
                          <ion-col>
                            <ion-input v-model="dummyProduct.customer_quantity_limit" type="number" placeholder="Enter Maximum quantity per customer" style="width: 125px" class="fullBordered input_custom_style"></ion-input>
                          </ion-col>
                          </div>
                        </ion-row>
                      </ion-grid>
                    </ion-card-header>
                  </ion-list>
              </ion-accordion>
            </ion-accordion-group>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>
</master-layout>
</template>

<script>
import { chevronDownCircleOutline, closeOutline, addOutline, arrowBackOutline } from 'ionicons/icons';
import { IonTextarea, IonCheckbox, IonSelect, IonSelectOption, IonToggle, IonAccordion, IonAccordionGroup } from "@ionic/vue";
import jwtInterceptor from '/src/shared/jwtInterceptor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters } from "vuex";

export default {
  components: {
    IonTextarea,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonToggle, IonAccordion, IonAccordionGroup
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      chevronDownCircleOutline,
      closeOutline,
      addOutline,
      arrowBackOutline,
      loading: false,
      eventDetailId: undefined,
      eventDetail: undefined,
      eventDetailClone: undefined,
      checkingHashtag: false,
      validHashtag: true,
      newTag: undefined,
      suggestedTags: [],
      selectCustomisation: false,
      selectQuantity: true,
      dummyProduct: {
        status: undefined,
        weight_si_unit: "kg",
        woocommerce: false,
        hashtags: undefined,
        height: undefined,
        currency: undefined,
        cost: undefined,
        magento: false,
        length_si_unit: "cm",
        images: [],
        lowername: undefined,
        taxpercent: false,
        event: "False",
        category: "",
        shopify: false,
        lead: false,
        inventory_policy: "jumper",
        online_event_url: false,
        id: undefined,
        width: undefined,
        live: false,
        version: null,
        stock: undefined,
        totalcost: undefined,
        sku: undefined,
        selloffline: false,
        mockuprequired: true,
        description: undefined,
        tags: [],
        skipaddress: false,
        bigcommerce: false,
        amazon: false,
        hashtag: undefined,
        active: true,
        customer_quantity_limit: 0,
        quantity_limit: undefined,
        name: undefined,
        created: undefined,
        notes: undefined,
        askquantity: false,
        variationEnabled: false,
        variations: {},
        skipemailverification: false,
        printfulmockup: false,
        shopActive: true,
        length: undefined,
        printful: false,
        isbundle: false,
        grams: undefined,
        skipemail: false
      },
      imageChanged: false
    }
  },
  ionViewWillEnter() {
    if(this.$route.query && this.$route.query.productid) this.init();
    this.dummyProduct.currency = this.user.currency;
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      authData: "auth/getAuthData"
    }),
    eventDetailChanged() {
      return this.eventDetail !== this.eventDetailClone;
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        let reqBody = {
          productid: this.$route.query.productid
        }
        let editProductResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-product", reqBody);
        let getEventDetailResponse = jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-event-detail", reqBody);

        let allResponses = await Promise.all([editProductResponse, getEventDetailResponse]);
        editProductResponse = allResponses[0];
        getEventDetailResponse = allResponses[1];

        if ((editProductResponse.status == 200 || editProductResponse.status == 201) && editProductResponse.data.success) {
          this.dummyProduct = editProductResponse.data;
          if(this.dummyProduct.weight_si_unit === 'oz') this.dummyProduct.grams = this.dummyProduct.grams / 28.3495;
          else if(this.dummyProduct.weight_si_unit === 'lbs') this.dummyProduct.grams = this.dummyProduct.grams / 453.592;
          else this.dummyProduct.grams = this.dummyProduct.grams / 1000;
        } else {
          if(editProductResponse.data && !editProductResponse.data.success && editProductResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: editProductResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", editProductResponse);
          }
        }

        if ((getEventDetailResponse.status == 200 || getEventDetailResponse.status == 201) && getEventDetailResponse.data.success) {
          this.eventDetail = getEventDetailResponse.data.content;
          this.eventDetailClone = this.eventDetail;
          this.eventDetailId = getEventDetailResponse.data.id;
        } else {
          if(getEventDetailResponse.data && !getEventDetailResponse.data.success && getEventDetailResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: getEventDetailResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", editProductResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async checkHashtag() {
      try {
        if(!this.dummyProduct.hashtag || this.dummyProduct.hashtag.length === 0) {
          this.updateToast({
            open: true,
            message: "Please enter a valid input for hashtag!",
            type: "warning"
          });
          return;
        }
        this.checkingHashtag = true;
        this.validHashtag = false;

        let checkHashtagResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "check-hashtag", { hashtag: this.dummyProduct.hashtag });

        if ((checkHashtagResponse.status == 200 || checkHashtagResponse.status == 201) && checkHashtagResponse.data.success) {
          this.checkingHashtag = false;
          if(checkHashtagResponse.data.valid) this.validHashtag = true;
          else this.validHashtag = false;
        }
        else {
          if(checkHashtagResponse.data.errorMessage && checkHashtagResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: checkHashtagResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      }
    },
    addTag(toAdd) {
      if(toAdd === undefined) {
        if(this.newTag && this.newTag.length) this.dummyProduct.tags && this.dummyProduct.tags.length ? this.dummyProduct.tags.push(this.newTag) : this.dummyProduct.tags = [this.newTag];
        this.newTag = undefined;
      } else {
        if(typeof toAdd === 'object') {
          if(toAdd && toAdd.length) this.dummyProduct.tags && this.dummyProduct.tags.length ? this.dummyProduct.tags = this.dummyProduct.tags.concat(toAdd) : this.dummyProduct.tags = [].concat(toAdd);
        } else {
          if(toAdd && toAdd.length) this.dummyProduct.tags && this.dummyProduct.tags.length ? this.dummyProduct.tags.push(toAdd) : this.dummyProduct.tags = [toAdd];
        }
      }
    },
    removeTag(index) {
      this.dummyProduct.tags.splice(index, 1);
    },
    async generateTags() {
      try {
        this.loading = true;

        let generateTagsResponse = await jwtInterceptor.get('https://hashtag-spt5fcneaa-uc.a.run.app/generate-tags-from-text?userid=' + this.authData.authkey + '&abstract=' + encodeURI(this.dummyProduct.description));

        if ((generateTagsResponse.status == 200 || generateTagsResponse.status == 201) && generateTagsResponse.data.success) {
          if(generateTagsResponse.data.tag && generateTagsResponse.data.tag.length) this.suggestedTags = generateTagsResponse.data.tag;
          else this.updateToast({
            open: true,
            message: "No Suggested Tags for given description",
            type: "warning"
          })
        } else {
          if(generateTagsResponse.data.errorMessage && generateTagsResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: generateTagsResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async handleImageUpload (event) {
      try {
        this.loading = true;
        if(event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          if(await this.validateImage(newImage, 4)) {
            let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
            if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
              this.dummyProduct.images.push(uploadSendResponseData.url);
              this.imageChanged = true;
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong uploading the picture",
                type: "danger"
              })
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async updateProduct() {
      try {
        this.loading = true;

        let reqBody = {
          tax: "0",
          splitpayamount: "0",
          sellergetcost: "0",
          cash_on_delivery: "false",
          passfees: "false",
          calendarinvite: "false",
          productid: this.dummyProduct.id,
          hashtag: this.dummyProduct.hashtag,
          name: this.dummyProduct.name,
          tags: this.dummyProduct.tags && this.dummyProduct.tags.length ? this.dummyProduct.tags.join(",") : undefined,
          hashtags: this.dummyProduct.hashtags && this.dummyProduct.hashtags.length ? this.dummyProduct.hashtags.join(",") : undefined,
          description: this.dummyProduct.description,
          sku: this.dummyProduct.sku,
          weight_si_unit: this.dummyProduct.weight_si_unit,
          length_si_unit: this.dummyProduct.length_si_unit,
          cost: this.dummyProduct.cost,
          totalcost: this.dummyProduct.cost,
          basecost: this.dummyProduct.cost,
          askquantity: this.dummyProduct.askquantity.toString(),
          quantity_limit: this.dummyProduct.quantity_limit?.toString(),
          notes: this.dummyProduct.notes?.toString(),
          shopActive: this.dummyProduct.shopActive.toString(),
          skipaddress: this.dummyProduct.skipaddress.toString(),
          selloffline: this.dummyProduct.selloffline.toString(),
          skipemail: this.dummyProduct.skipemail.toString(),
          stock: this.dummyProduct.stock,
          image_urls: this.imageChanged && this.dummyProduct.images && this.dummyProduct.images.length ? this.dummyProduct.images.join(",") : undefined,
          grams: this.dummyProduct.grams,
          inventory_policy: this.dummyProduct.inventory_policy,
          currency: this.dummyProduct.currency,
          customer_quantity_limit: this.dummyProduct.customer_quantity_limit?.toString()
        };

        if(reqBody.weight_si_unit === 'oz') reqBody.grams = reqBody.grams * 28.3495;
        else if(reqBody.weight_si_unit === 'lbs') reqBody.grams = reqBody.grams * 453.592;
        else reqBody.grams = reqBody.grams * 1000;

        reqBody.grams ? reqBody.grams = reqBody.grams.toString() : '';

        if(!reqBody.name || reqBody.name.length === '0') {
          this.updateToast({
            open: true,
            message: "Please enter a product name",
            type: "warning"
          });
          return;
        }
        if(!reqBody.description || reqBody.description.length === '0') {
          this.updateToast({
            open: true,
            message: "Please enter a product description",
            type: "warning"
          });
          return;
        }
        if(!reqBody.description || reqBody.description.length === '0') {
          this.updateToast({
            open: true,
            message: "Please enter a product description",
            type: "warning"
          });
          return;
        }
        if(!reqBody.hashtag || reqBody.hashtag.length === '0') {
          this.updateToast({
            open: true,
            message: "Please enter a valid hashtag",
            type: "warning"
          });
          return;
        }
        if(!reqBody.cost || reqBody.cost == '0') {
          this.updateToast({
            open: true,
            message: "Cost can not be zero for a product",
            type: "warning"
          });
          return;
        }
        if(!reqBody.stock || parseInt(reqBody.stock) < 0) {
          this.updateToast({
            open: true,
            message: "Stock can not be in negative",
            type: "warning"
          });
          return;
        }
        if(!this.dummyProduct.images || this.dummyProduct.images.length == '0') {
          this.updateToast({
            open: true,
            message: "Please add an image to the product",
            type: "warning"
          });
          return;
        }
        if(!reqBody.grams || reqBody.grams == '0') {
          this.updateToast({
            open: true,
            message: "Weight of a product can not be zero",
            type: "warning"
          });
          return;
        }
        if(!this.eventDetail || this.eventDetail.length == '0') {
          this.updateToast({
            open: true,
            message: "Please add a valid product description",
            type: "warning"
          });
          return;
        }
        
        let productSaveResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-product", reqBody);
        if ((productSaveResponse.status == 200 || productSaveResponse.status == 201) && productSaveResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Product updated successfully!",
            type: "success"
          });
          /* for(var prop in this.dummyProduct) this.dummyProduct[prop] = productSaveResponse.data[prop];

          if(this.dummyProduct.weight_si_unit === 'oz') this.dummyProduct.grams = this.dummyProduct.grams / 28.3495;
          else if(this.dummyProduct.weight_si_unit === 'lbs') this.dummyProduct.grams = this.dummyProduct.grams / 453.592;
          else this.dummyProduct.grams = this.dummyProduct.grams / 1000; */
          this.$router.replace({ path: '/store', query: { tab: 'products' }});
        }
        else {
          if(productSaveResponse.data.errorMessage && productSaveResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: productSaveResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
        if(this.eventDetailChanged) {
          reqBody = {
            productid: productSaveResponse.data.id,
            detailid: this.eventDetailId,
            content: this.eventDetail
          }
          let addEventResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-event-detail", reqBody);
          if ((addEventResponse.status == 200 || addEventResponse.status == 201) && addEventResponse.data.success && addEventResponse.data.created) {
            this.updateToast({
              open: true,
              message: "Successfully updated the description",
              type: "success"
            })
            this.eventDetailClone = this.eventDetail;
            this.$router.replace({ path: '/store', query: { tab: 'products' }});
          } else {
            if(addEventResponse.data.errorMessage && addEventResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: addEventResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.card_container_grid {
  margin: 20px;
}

.icon-color {
   color: var(--ion-color-white);
}

.input-tags {
  margin-top: 5px;
}

ion-chip {
  --background : #881fff;
  --color: var(--ion-color-white);
}

.sctn-input {
  border-radius: 5px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 10px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
  border-radius: 5px;
}
.placeholder_img {
  width: 55px;
  margin-top: 30px;
}
@media only screen and (min-width: 760px){
  .product-container {
    margin: 5px 20px 5px 5px;
  }
}

</style>