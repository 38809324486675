var countryCodesData = {
    "+1":{
        "name": "United States",
        "dial_code": "+1",
        "code": "US",
        "flag_img": "https://flagpedia.net/data/flags/h80/us.png"
    },
    "+65":{
        "name": "Singapore",
        "dial_code": "+65",
        "code": "SG",
        "flag_img": "https://flagpedia.net/data/flags/h80/sg.png"
    },
    "+60":{
        "name": "Malaysia",
        "dial_code": "+60",
        "code": "MY",
        "flag_img": "https://flagpedia.net/data/flags/h80/my.png"
    },
    "+63":{
        "name": "Philippines",
        "dial_code": "+63",
        "code": "PH",
        "flag_img": "https://flagpedia.net/data/flags/h80/ph.png"
    },
    "+66":{
        "name": "Thailand",
        "dial_code": "+66",
        "code": "TH",
        "flag_img": "https://flagpedia.net/data/flags/h80/th.png"
    },
    "+62":{
        "name": "Indonesia",
        "dial_code": "+62",
        "code": "ID",
        "flag_img": "https://flagpedia.net/data/flags/h80/id.png"
    },
    "+91":{
        "name": "India",
        "dial_code": "+91",
        "code": "IN",
        "flag_img": "https://flagpedia.net/data/flags/h80/in.png"
    },
    "+82":{
        "name": "South Korea",
        "dial_code": "+82",
        "code": "KR",
        "flag_img": "https://flagpedia.net/data/flags/h80/kr.png"
    },
    "+84":{
        "name": "Vietnam",
        "dial_code": "+84",
        "code": "VN",
        "flag_img": "https://flagpedia.net/data/flags/h80/vn.png"
    },
    "+852":{
        "name": "Hong Kong",
        "dial_code": "+852",
        "code": "HK",
        "flag_img": "https://flagpedia.net/data/flags/h80/hk.png"
    },
    "+81":{
        "name": "Japan",
        "dial_code": "+81",
        "code": "JP",
        "flag_img": "https://flagpedia.net/data/flags/h80/jp.png"
    },
    "+44":{
        "name": "United Kingdom",
        "dial_code": "+44",
        "code": "GB",
        "flag_img": "https://flagpedia.net/data/flags/h80/gb.png"
    },
    "+93":{
        "name": "Afghanistan",
        "dial_code": "+93",
        "code": "AF",
        "flag_img": "https://flagpedia.net/data/flags/h80/af.png"
    },
    /* "+358":{
        "name": "Aland Islands",
        "dial_code": "+358",
        "code": "AX",
        "flag_img": "https://flagpedia.net/data/flags/h80/ax.png"
    }, */
    "+355":{
        "name": "Albania",
        "dial_code": "+355",
        "code": "AL",
        "flag_img": "https://flagpedia.net/data/flags/h80/al.png"
    },
    "+213":{
        "name": "Algeria",
        "dial_code": "+213",
        "code": "DZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/dz.png"
    },
    "+1684":{
        "name": "AmericanSamoa",
        "dial_code": "+1684",
        "code": "AS",
        "flag_img": "https://flagpedia.net/data/flags/h80/as.png"
    },
    "+376":{
        "name": "Andorra",
        "dial_code": "+376",
        "code": "AD",
        "flag_img": "https://flagpedia.net/data/flags/h80/ad.png"
    },
    "+244":{
        "name": "Angola",
        "dial_code": "+244",
        "code": "AO",
        "flag_img": "https://flagpedia.net/data/flags/h80/ao.png"
    },
    "+1264":{
        "name": "Anguilla",
        "dial_code": "+1264",
        "code": "AI",
        "flag_img": "https://flagpedia.net/data/flags/h80/ai.png"
    },
    "+672":{
        "name": "Antarctica",
        "dial_code": "+672",
        "code": "AQ",
        "flag_img": "https://flagpedia.net/data/flags/h80/aq.png"
    },
    "+1268":{
        "name": "Antigua and Barbuda",
        "dial_code": "+1268",
        "code": "AG",
        "flag_img": "https://flagpedia.net/data/flags/h80/ag.png"
    },
    "+54":{
        "name": "Argentina",
        "dial_code": "+54",
        "code": "AR",
        "flag_img": "https://flagpedia.net/data/flags/h80/ar.png"
    },
    "+374":{
        "name": "Armenia",
        "dial_code": "+374",
        "code": "AM",
        "flag_img": "https://flagpedia.net/data/flags/h80/am.png"
    },
    "+297":{
        "name": "Aruba",
        "dial_code": "+297",
        "code": "AW",
        "flag_img": "https://flagpedia.net/data/flags/h80/aw.png"
    },
    "+61":{
        "name": "Australia",
        "dial_code": "+61",
        "code": "AU",
        "flag_img": "https://flagpedia.net/data/flags/h80/au.png"
    },
    "+43":{
        "name": "Austria",
        "dial_code": "+43",
        "code": "AT",
        "flag_img": "https://flagpedia.net/data/flags/h80/at.png"
    },
    "+994":{
        "name": "Azerbaijan",
        "dial_code": "+994",
        "code": "AZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/az.png"
    },
    "+1242":{
        "name": "Bahamas",
        "dial_code": "+1242",
        "code": "BS",
        "flag_img": "https://flagpedia.net/data/flags/h80/bs.png"
    },
    "+973":{
        "name": "Bahrain",
        "dial_code": "+973",
        "code": "BH",
        "flag_img": "https://flagpedia.net/data/flags/h80/bh.png"
    },
    "+880":{
        "name": "Bangladesh",
        "dial_code": "+880",
        "code": "BD",
        "flag_img": "https://flagpedia.net/data/flags/h80/bd.png"
    },
    "+1246":{
        "name": "Barbados",
        "dial_code": "+1246",
        "code": "BB",
        "flag_img": "https://flagpedia.net/data/flags/h80/bb.png"
    },
    "+375":{
        "name": "Belarus",
        "dial_code": "+375",
        "code": "BY",
        "flag_img": "https://flagpedia.net/data/flags/h80/by.png"
    },
    "+32":{
        "name": "Belgium",
        "dial_code": "+32",
        "code": "BE",
        "flag_img": "https://flagpedia.net/data/flags/h80/be.png"
    },
    "+501":{
        "name": "Belize",
        "dial_code": "+501",
        "code": "BZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/bz.png"
    },
    "+229":{
        "name": "Benin",
        "dial_code": "+229",
        "code": "BJ",
        "flag_img": "https://flagpedia.net/data/flags/h80/bj.png"
    },
    "+1441":{
        "name": "Bermuda",
        "dial_code": "+1441",
        "code": "BM",
        "flag_img": "https://flagpedia.net/data/flags/h80/bm.png"
    },
    "+975":{
        "name": "Bhutan",
        "dial_code": "+975",
        "code": "BT",
        "flag_img": "https://flagpedia.net/data/flags/h80/bt.png"
    },
    "+591":{
        "name": "Bolivia",
        "dial_code": "+591",
        "code": "BO",
        "flag_img": "https://flagpedia.net/data/flags/h80/bo.png"
    },
    "+387":{
        "name": "Bosnia and Herzegovina",
        "dial_code": "+387",
        "code": "BA",
        "flag_img": "https://flagpedia.net/data/flags/h80/ba.png"
    },
    "+267":{
        "name": "Botswana",
        "dial_code": "+267",
        "code": "BW",
        "flag_img": "https://flagpedia.net/data/flags/h80/bw.png"
    },
    "+55":{
        "name": "Brazil",
        "dial_code": "+55",
        "code": "BR",
        "flag_img": "https://flagpedia.net/data/flags/h80/br.png"
    },
    "+246":{
        "name": "British Indian Ocean Territory",
        "dial_code": "+246",
        "code": "IO",
        "flag_img": "https://flagpedia.net/data/flags/h80/io.png"
    },
    "+673":{
        "name": "Brunei Darussalam",
        "dial_code": "+673",
        "code": "BN",
        "flag_img": "https://flagpedia.net/data/flags/h80/bn.png"
    },
    "+359":{
        "name": "Bulgaria",
        "dial_code": "+359",
        "code": "BG",
        "flag_img": "https://flagpedia.net/data/flags/h80/bg.png"
    },
    "+226":{
        "name": "Burkina Faso",
        "dial_code": "+226",
        "code": "BF",
        "flag_img": "https://flagpedia.net/data/flags/h80/bf.png"
    },
    "+257":{
        "name": "Burundi",
        "dial_code": "+257",
        "code": "BI",
        "flag_img": "https://flagpedia.net/data/flags/h80/bi.png"
    },
    "+855":{
        "name": "Cambodia",
        "dial_code": "+855",
        "code": "KH",
        "flag_img": "https://flagpedia.net/data/flags/h80/kh.png"
    },
    "+237":{
        "name": "Cameroon",
        "dial_code": "+237",
        "code": "CM",
        "flag_img": "https://flagpedia.net/data/flags/h80/cm.png"
    },
    /* "+1":{
        "name": "Canada",
        "dial_code": "+1",
        "code": "CA",
        "flag_img": "https://flagpedia.net/data/flags/h80/ca.png"
    }, */
    "+238":{
        "name": "Cape Verde",
        "dial_code": "+238",
        "code": "CV",
        "flag_img": "https://flagpedia.net/data/flags/h80/cv.png"
    },
    "+345":{
        "name": "Cayman Islands",
        "dial_code": "+345",
        "code": "KY",
        "flag_img": "https://flagpedia.net/data/flags/h80/ky.png"
    },
    "+236":{
        "name": "Central African Republic",
        "dial_code": "+236",
        "code": "CF",
        "flag_img": "https://flagpedia.net/data/flags/h80/cf.png"
    },
    "+235":{
        "name": "Chad",
        "dial_code": "+235",
        "code": "TD",
        "flag_img": "https://flagpedia.net/data/flags/h80/td.png"
    },
    "+56":{
        "name": "Chile",
        "dial_code": "+56",
        "code": "CL",
        "flag_img": "https://flagpedia.net/data/flags/h80/cl.png"
    },
    "+86":{
        "name": "China",
        "dial_code": "+86",
        "code": "CN",
        "flag_img": "https://flagpedia.net/data/flags/h80/cn.png"
    },
    /* "+61":{
        "name": "Christmas Island",
        "dial_code": "+61",
        "code": "CX",
        "flag_img": "https://flagpedia.net/data/flags/h80/cx.png"
    }, */
    /* "+61":{
        "name": "Cocos (Keeling) Islands",
        "dial_code": "+61",
        "code": "CC",
        "flag_img": "https://flagpedia.net/data/flags/h80/cc.png"
    }, */
    "+57":{
        "name": "Colombia",
        "dial_code": "+57",
        "code": "CO",
        "flag_img": "https://flagpedia.net/data/flags/h80/co.png"
    },
    "+269":{
        "name": "Comoros",
        "dial_code": "+269",
        "code": "KM",
        "flag_img": "https://flagpedia.net/data/flags/h80/km.png"
    },
    "+242":{
        "name": "Congo",
        "dial_code": "+242",
        "code": "CG",
        "flag_img": "https://flagpedia.net/data/flags/h80/cg.png"
    },
    "+243":{
        "name": "Congo",
        "dial_code": "+243",
        "code": "CD",
        "flag_img": "https://flagpedia.net/data/flags/h80/cd.png"
    },
    "+682":{
        "name": "Cook Islands",
        "dial_code": "+682",
        "code": "CK",
        "flag_img": "https://flagpedia.net/data/flags/h80/ck.png"
    },
    "+506":{
        "name": "Costa Rica",
        "dial_code": "+506",
        "code": "CR",
        "flag_img": "https://flagpedia.net/data/flags/h80/cr.png"
    },
    "+225":{
        "name": "Cote d'Ivoire",
        "dial_code": "+225",
        "code": "CI",
        "flag_img": "https://flagpedia.net/data/flags/h80/ci.png"
    },
    "+385":{
        "name": "Croatia",
        "dial_code": "+385",
        "code": "HR",
        "flag_img": "https://flagpedia.net/data/flags/h80/hr.png"
    },
    "+53":{
        "name": "Cuba",
        "dial_code": "+53",
        "code": "CU",
        "flag_img": "https://flagpedia.net/data/flags/h80/cu.png"
    },
    "+357":{
        "name": "Cyprus",
        "dial_code": "+357",
        "code": "CY",
        "flag_img": "https://flagpedia.net/data/flags/h80/cy.png"
    },
    "+420":{
        "name": "Czech Republic",
        "dial_code": "+420",
        "code": "CZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/cz.png"
    },
    "+45":{
        "name": "Denmark",
        "dial_code": "+45",
        "code": "DK",
        "flag_img": "https://flagpedia.net/data/flags/h80/dk.png"
    },
    "+253":{
        "name": "Djibouti",
        "dial_code": "+253",
        "code": "DJ",
        "flag_img": "https://flagpedia.net/data/flags/h80/dj.png"
    },
    "+1767":{
        "name": "Dominica",
        "dial_code": "+1767",
        "code": "DM",
        "flag_img": "https://flagpedia.net/data/flags/h80/dm.png"
    },
    "+1849":{
        "name": "Dominican Republic",
        "dial_code": "+1849",
        "code": "DO",
        "flag_img": "https://flagpedia.net/data/flags/h80/do.png"
    },
    "+593":{
        "name": "Ecuador",
        "dial_code": "+593",
        "code": "EC",
        "flag_img": "https://flagpedia.net/data/flags/h80/ec.png"
    },
    "+20":{
        "name": "Egypt",
        "dial_code": "+20",
        "code": "EG",
        "flag_img": "https://flagpedia.net/data/flags/h80/eg.png"
    },
    "+503":{
        "name": "El Salvador",
        "dial_code": "+503",
        "code": "SV",
        "flag_img": "https://flagpedia.net/data/flags/h80/sv.png"
    },
    "+240":{
        "name": "Equatorial Guinea",
        "dial_code": "+240",
        "code": "GQ",
        "flag_img": "https://flagpedia.net/data/flags/h80/gq.png"
    },
    "+291":{
        "name": "Eritrea",
        "dial_code": "+291",
        "code": "ER",
        "flag_img": "https://flagpedia.net/data/flags/h80/er.png"
    },
    "+372":{
        "name": "Estonia",
        "dial_code": "+372",
        "code": "EE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ee.png"
    },
    "+251":{
        "name": "Ethiopia",
        "dial_code": "+251",
        "code": "ET",
        "flag_img": "https://flagpedia.net/data/flags/h80/et.png"
    },
    "+500":{
        "name": "Falkland Islands (Malvinas)",
        "dial_code": "+500",
        "code": "FK",
        "flag_img": "https://flagpedia.net/data/flags/h80/fk.png"
    },
    "+298":{
        "name": "Faroe Islands",
        "dial_code": "+298",
        "code": "FO",
        "flag_img": "https://flagpedia.net/data/flags/h80/fo.png"
    },
    "+679":{
        "name": "Fiji",
        "dial_code": "+679",
        "code": "FJ",
        "flag_img": "https://flagpedia.net/data/flags/h80/fj.png"
    },
    "+358":{
        "name": "Finland",
        "dial_code": "+358",
        "code": "FI",
        "flag_img": "https://flagpedia.net/data/flags/h80/fi.png"
    },
    "+33":{
        "name": "France",
        "dial_code": "+33",
        "code": "FR",
        "flag_img": "https://flagpedia.net/data/flags/h80/fr.png"
    },
    "+594":{
        "name": "French Guiana",
        "dial_code": "+594",
        "code": "GF",
        "flag_img": "https://flagpedia.net/data/flags/h80/gf.png"
    },
    "+689":{
        "name": "French Polynesia",
        "dial_code": "+689",
        "code": "PF",
        "flag_img": "https://flagpedia.net/data/flags/h80/pf.png"
    },
    "+241":{
        "name": "Gabon",
        "dial_code": "+241",
        "code": "GA",
        "flag_img": "https://flagpedia.net/data/flags/h80/ga.png"
    },
    "+220":{
        "name": "Gambia",
        "dial_code": "+220",
        "code": "GM",
        "flag_img": "https://flagpedia.net/data/flags/h80/gm.png"
    },
    "+995":{
        "name": "Georgia",
        "dial_code": "+995",
        "code": "GE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ge.png"
    },
    "+49":{
        "name": "Germany",
        "dial_code": "+49",
        "code": "DE",
        "flag_img": "https://flagpedia.net/data/flags/h80/de.png"
    },
    "+233":{
        "name": "Ghana",
        "dial_code": "+233",
        "code": "GH",
        "flag_img": "https://flagpedia.net/data/flags/h80/gh.png"
    },
    "+350":{
        "name": "Gibraltar",
        "dial_code": "+350",
        "code": "GI",
        "flag_img": "https://flagpedia.net/data/flags/h80/gi.png"
    },
    "+30":{
        "name": "Greece",
        "dial_code": "+30",
        "code": "GR",
        "flag_img": "https://flagpedia.net/data/flags/h80/gr.png"
    },
    "+299":{
        "name": "Greenland",
        "dial_code": "+299",
        "code": "GL",
        "flag_img": "https://flagpedia.net/data/flags/h80/gl.png"
    },
    "+1473":{
        "name": "Grenada",
        "dial_code": "+1473",
        "code": "GD",
        "flag_img": "https://flagpedia.net/data/flags/h80/gd.png"
    },
    "+590":{
        "name": "Guadeloupe",
        "dial_code": "+590",
        "code": "GP",
        "flag_img": "https://flagpedia.net/data/flags/h80/gp.png"
    },
    "+1671":{
        "name": "Guam",
        "dial_code": "+1671",
        "code": "GU",
        "flag_img": "https://flagpedia.net/data/flags/h80/gu.png"
    },
    "+502":{
        "name": "Guatemala",
        "dial_code": "+502",
        "code": "GT",
        "flag_img": "https://flagpedia.net/data/flags/h80/gt.png"
    },
    /* "+44":{
        "name": "Guernsey",
        "dial_code": "+44",
        "code": "GG",
        "flag_img": "https://flagpedia.net/data/flags/h80/gg.png"
    }, */
    "+224":{
        "name": "Guinea",
        "dial_code": "+224",
        "code": "GN",
        "flag_img": "https://flagpedia.net/data/flags/h80/gn.png"
    },
    "+245":{
        "name": "Guinea-Bissau",
        "dial_code": "+245",
        "code": "GW",
        "flag_img": "https://flagpedia.net/data/flags/h80/gw.png"
    },
    /* "+595":{
        "name": "Guyana",
        "dial_code": "+595",
        "code": "GY",
        "flag_img": "https://flagpedia.net/data/flags/h80/gy.png"
    }, */
    "+509":{
        "name": "Haiti",
        "dial_code": "+509",
        "code": "HT",
        "flag_img": "https://flagpedia.net/data/flags/h80/ht.png"
    },
    "+379":{
        "name": "Holy See (Vatican City State)",
        "dial_code": "+379",
        "code": "VA",
        "flag_img": "https://flagpedia.net/data/flags/h80/va.png"
    },
    "+504":{
        "name": "Honduras",
        "dial_code": "+504",
        "code": "HN",
        "flag_img": "https://flagpedia.net/data/flags/h80/hn.png"
    },
    "+36":{
        "name": "Hungary",
        "dial_code": "+36",
        "code": "HU",
        "flag_img": "https://flagpedia.net/data/flags/h80/hu.png"
    },
    "+354":{
        "name": "Iceland",
        "dial_code": "+354",
        "code": "IS",
        "flag_img": "https://flagpedia.net/data/flags/h80/is.png"
    },
    "+98":{
        "name": "Iran",
        "dial_code": "+98",
        "code": "IR",
        "flag_img": "https://flagpedia.net/data/flags/h80/ir.png"
    },
    "+964":{
        "name": "Iraq",
        "dial_code": "+964",
        "code": "IQ",
        "flag_img": "https://flagpedia.net/data/flags/h80/iq.png"
    },
    "+353":{
        "name": "Ireland",
        "dial_code": "+353",
        "code": "IE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ie.png"
    },
    /* "+44":{
        "name": "Isle of Man",
        "dial_code": "+44",
        "code": "IM",
        "flag_img": "https://flagpedia.net/data/flags/h80/im.png"
    }, */
    "+972":{
        "name": "Israel",
        "dial_code": "+972",
        "code": "IL",
        "flag_img": "https://flagpedia.net/data/flags/h80/il.png"
    },
    "+39":{
        "name": "Italy",
        "dial_code": "+39",
        "code": "IT",
        "flag_img": "https://flagpedia.net/data/flags/h80/it.png"
    },
    "+1876":{
        "name": "Jamaica",
        "dial_code": "+1876",
        "code": "JM",
        "flag_img": "https://flagpedia.net/data/flags/h80/jm.png"
    },
    /* "+44":{
        "name": "Jersey",
        "dial_code": "+44",
        "code": "JE",
        "flag_img": "https://flagpedia.net/data/flags/h80/je.png"
    }, */
    "+962":{
        "name": "Jordan",
        "dial_code": "+962",
        "code": "JO",
        "flag_img": "https://flagpedia.net/data/flags/h80/jo.png"
    },
    "+77":{
        "name": "Kazakhstan",
        "dial_code": "+77",
        "code": "KZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/kz.png"
    },
    "+254":{
        "name": "Kenya",
        "dial_code": "+254",
        "code": "KE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ke.png"
    },
    "+686":{
        "name": "Kiribati",
        "dial_code": "+686",
        "code": "KI",
        "flag_img": "https://flagpedia.net/data/flags/h80/ki.png"
    },
    "+850":{
        "name": "North Korea",
        "dial_code": "+850",
        "code": "KP",
        "flag_img": "https://flagpedia.net/data/flags/h80/kp.png"
    },
    "+965":{
        "name": "Kuwait",
        "dial_code": "+965",
        "code": "KW",
        "flag_img": "https://flagpedia.net/data/flags/h80/kw.png"
    },
    "+996":{
        "name": "Kyrgyzstan",
        "dial_code": "+996",
        "code": "KG",
        "flag_img": "https://flagpedia.net/data/flags/h80/kg.png"
    },
    "+856":{
        "name": "Laos",
        "dial_code": "+856",
        "code": "LA",
        "flag_img": "https://flagpedia.net/data/flags/h80/la.png"
    },
    "+371":{
        "name": "Latvia",
        "dial_code": "+371",
        "code": "LV",
        "flag_img": "https://flagpedia.net/data/flags/h80/lv.png"
    },
    "+961":{
        "name": "Lebanon",
        "dial_code": "+961",
        "code": "LB",
        "flag_img": "https://flagpedia.net/data/flags/h80/lb.png"
    },
    "+266":{
        "name": "Lesotho",
        "dial_code": "+266",
        "code": "LS",
        "flag_img": "https://flagpedia.net/data/flags/h80/ls.png"
    },
    "+231":{
        "name": "Liberia",
        "dial_code": "+231",
        "code": "LR",
        "flag_img": "https://flagpedia.net/data/flags/h80/lr.png"
    },
    "+218":{
        "name": "Libyan Arab Jamahiriya",
        "dial_code": "+218",
        "code": "LY",
        "flag_img": "https://flagpedia.net/data/flags/h80/ly.png"
    },
    "+423":{
        "name": "Liechtenstein",
        "dial_code": "+423",
        "code": "LI",
        "flag_img": "https://flagpedia.net/data/flags/h80/li.png"
    },
    "+370":{
        "name": "Lithuania",
        "dial_code": "+370",
        "code": "LT",
        "flag_img": "https://flagpedia.net/data/flags/h80/lt.png"
    },
    "+352":{
        "name": "Luxembourg",
        "dial_code": "+352",
        "code": "LU",
        "flag_img": "https://flagpedia.net/data/flags/h80/lu.png"
    },
    "+853":{
        "name": "Macao",
        "dial_code": "+853",
        "code": "MO",
        "flag_img": "https://flagpedia.net/data/flags/h80/mo.png"
    },
    "+389":{
        "name": "Macedonia",
        "dial_code": "+389",
        "code": "MK",
        "flag_img": "https://flagpedia.net/data/flags/h80/mk.png"
    },
    "+261":{
        "name": "Madagascar",
        "dial_code": "+261",
        "code": "MG",
        "flag_img": "https://flagpedia.net/data/flags/h80/mg.png"
    },
    "+265":{
        "name": "Malawi",
        "dial_code": "+265",
        "code": "MW",
        "flag_img": "https://flagpedia.net/data/flags/h80/mw.png"
    },
    "+960":{
        "name": "Maldives",
        "dial_code": "+960",
        "code": "MV",
        "flag_img": "https://flagpedia.net/data/flags/h80/mv.png"
    },
    "+223":{
        "name": "Mali",
        "dial_code": "+223",
        "code": "ML",
        "flag_img": "https://flagpedia.net/data/flags/h80/ml.png"
    },
    "+356":{
        "name": "Malta",
        "dial_code": "+356",
        "code": "MT",
        "flag_img": "https://flagpedia.net/data/flags/h80/mt.png"
    },
    "+692":{
        "name": "Marshall Islands",
        "dial_code": "+692",
        "code": "MH",
        "flag_img": "https://flagpedia.net/data/flags/h80/mh.png"
    },
    "+596":{
        "name": "Martinique",
        "dial_code": "+596",
        "code": "MQ",
        "flag_img": "https://flagpedia.net/data/flags/h80/mq.png"
    },
    "+222":{
        "name": "Mauritania",
        "dial_code": "+222",
        "code": "MR",
        "flag_img": "https://flagpedia.net/data/flags/h80/mr.png"
    },
    "+230":{
        "name": "Mauritius",
        "dial_code": "+230",
        "code": "MU",
        "flag_img": "https://flagpedia.net/data/flags/h80/mu.png"
    },
    "+262":{
        "name": "Mayotte",
        "dial_code": "+262",
        "code": "YT",
        "flag_img": "https://flagpedia.net/data/flags/h80/yt.png"
    },
    "+52":{
        "name": "Mexico",
        "dial_code": "+52",
        "code": "MX",
        "flag_img": "https://flagpedia.net/data/flags/h80/mx.png"
    },
    "+691":{
        "name": "Micronesia",
        "dial_code": "+691",
        "code": "FM",
        "flag_img": "https://flagpedia.net/data/flags/h80/fm.png"
    },
    "+373":{
        "name": "Moldova",
        "dial_code": "+373",
        "code": "MD",
        "flag_img": "https://flagpedia.net/data/flags/h80/md.png"
    },
    "+377":{
        "name": "Monaco",
        "dial_code": "+377",
        "code": "MC",
        "flag_img": "https://flagpedia.net/data/flags/h80/mc.png"
    },
    "+976":{
        "name": "Mongolia",
        "dial_code": "+976",
        "code": "MN",
        "flag_img": "https://flagpedia.net/data/flags/h80/mn.png"
    },
    "+382":{
        "name": "Montenegro",
        "dial_code": "+382",
        "code": "ME",
        "flag_img": "https://flagpedia.net/data/flags/h80/me.png"
    },
    "+1664":{
        "name": "Montserrat",
        "dial_code": "+1664",
        "code": "MS",
        "flag_img": "https://flagpedia.net/data/flags/h80/ms.png"
    },
    "+212":{
        "name": "Morocco",
        "dial_code": "+212",
        "code": "MA",
        "flag_img": "https://flagpedia.net/data/flags/h80/ma.png"
    },
    "+258":{
        "name": "Mozambique",
        "dial_code": "+258",
        "code": "MZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/mz.png"
    },
    "+95":{
        "name": "Myanmar",
        "dial_code": "+95",
        "code": "MM",
        "flag_img": "https://flagpedia.net/data/flags/h80/mm.png"
    },
    "+264":{
        "name": "Namibia",
        "dial_code": "+264",
        "code": "NA",
        "flag_img": "https://flagpedia.net/data/flags/h80/na.png"
    },
    "+674":{
        "name": "Nauru",
        "dial_code": "+674",
        "code": "NR",
        "flag_img": "https://flagpedia.net/data/flags/h80/nr.png"
    },
    "+977":{
        "name": "Nepal",
        "dial_code": "+977",
        "code": "NP",
        "flag_img": "https://flagpedia.net/data/flags/h80/np.png"
    },
    "+31":{
        "name": "Netherlands",
        "dial_code": "+31",
        "code": "NL",
        "flag_img": "https://flagpedia.net/data/flags/h80/nl.png"
    },
    "+599":{
        "name": "Netherlands Antilles",
        "dial_code": "+599",
        "code": "AN",
        "flag_img": "https://flagpedia.net/data/flags/h80/an.png"
    },
    "+687":{
        "name": "New Caledonia",
        "dial_code": "+687",
        "code": "NC",
        "flag_img": "https://flagpedia.net/data/flags/h80/nc.png"
    },
    "+64":{
        "name": "New Zealand",
        "dial_code": "+64",
        "code": "NZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/nz.png"
    },
    "+505":{
        "name": "Nicaragua",
        "dial_code": "+505",
        "code": "NI",
        "flag_img": "https://flagpedia.net/data/flags/h80/ni.png"
    },
    "+227":{
        "name": "Niger",
        "dial_code": "+227",
        "code": "NE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ne.png"
    },
    "+234":{
        "name": "Nigeria",
        "dial_code": "+234",
        "code": "NG",
        "flag_img": "https://flagpedia.net/data/flags/h80/ng.png"
    },
    "+683":{
        "name": "Niue",
        "dial_code": "+683",
        "code": "NU",
        "flag_img": "https://flagpedia.net/data/flags/h80/nu.png"
    },
    /* "+672":{
        "name": "Norfolk Island",
        "dial_code": "+672",
        "code": "NF",
        "flag_img": "https://flagpedia.net/data/flags/h80/nf.png"
    }, */
    "+1670":{
        "name": "Northern Mariana Islands",
        "dial_code": "+1670",
        "code": "MP",
        "flag_img": "https://flagpedia.net/data/flags/h80/mp.png"
    },
    "+47":{
        "name": "Norway",
        "dial_code": "+47",
        "code": "NO",
        "flag_img": "https://flagpedia.net/data/flags/h80/no.png"
    },
    "+968":{
        "name": "Oman",
        "dial_code": "+968",
        "code": "OM",
        "flag_img": "https://flagpedia.net/data/flags/h80/om.png"
    },
    "+92":{
        "name": "Pakistan",
        "dial_code": "+92",
        "code": "PK",
        "flag_img": "https://flagpedia.net/data/flags/h80/pk.png"
    },
    "+680":{
        "name": "Palau",
        "dial_code": "+680",
        "code": "PW",
        "flag_img": "https://flagpedia.net/data/flags/h80/pw.png"
    },
    "+970":{
        "name": "Palestinian",
        "dial_code": "+970",
        "code": "PS",
        "flag_img": "https://flagpedia.net/data/flags/h80/ps.png"
    },
    "+507":{
        "name": "Panama",
        "dial_code": "+507",
        "code": "PA",
        "flag_img": "https://flagpedia.net/data/flags/h80/pa.png"
    },
    "+675":{
        "name": "Papua New Guinea",
        "dial_code": "+675",
        "code": "PG",
        "flag_img": "https://flagpedia.net/data/flags/h80/pg.png"
    },
    "+595":{
        "name": "Paraguay",
        "dial_code": "+595",
        "code": "PY",
        "flag_img": "https://flagpedia.net/data/flags/h80/py.png"
    },
    "+51":{
        "name": "Peru",
        "dial_code": "+51",
        "code": "PE",
        "flag_img": "https://flagpedia.net/data/flags/h80/pe.png"
    },
    "+872":{
        "name": "Pitcairn",
        "dial_code": "+872",
        "code": "PN",
        "flag_img": "https://flagpedia.net/data/flags/h80/pn.png"
    },
    "+48":{
        "name": "Poland",
        "dial_code": "+48",
        "code": "PL",
        "flag_img": "https://flagpedia.net/data/flags/h80/pl.png"
    },
    "+351":{
        "name": "Portugal",
        "dial_code": "+351",
        "code": "PT",
        "flag_img": "https://flagpedia.net/data/flags/h80/pt.png"
    },
    "+1939":{
        "name": "Puerto Rico",
        "dial_code": "+1939",
        "code": "PR",
        "flag_img": "https://flagpedia.net/data/flags/h80/pr.png"
    },
    "+974":{
        "name": "Qatar",
        "dial_code": "+974",
        "code": "QA",
        "flag_img": "https://flagpedia.net/data/flags/h80/qa.png"
    },
    "+40":{
        "name": "Romania",
        "dial_code": "+40",
        "code": "RO",
        "flag_img": "https://flagpedia.net/data/flags/h80/ro.png"
    },
    "+7":{
        "name": "Russia",
        "dial_code": "+7",
        "code": "RU",
        "flag_img": "https://flagpedia.net/data/flags/h80/ru.png"
    },
    "+250":{
        "name": "Rwanda",
        "dial_code": "+250",
        "code": "RW",
        "flag_img": "https://flagpedia.net/data/flags/h80/rw.png"
    },
    /* "+262":{
        "name": "Reunion",
        "dial_code": "+262",
        "code": "RE",
        "flag_img": "https://flagpedia.net/data/flags/h80/re.png"
    }, */
    /* "+590":{
        "name": "Saint Barthelemy",
        "dial_code": "+590",
        "code": "BL",
        "flag_img": "https://flagpedia.net/data/flags/h80/bl.png"
    }, */
    "+290":{
        "name": "Saint Helena",
        "dial_code": "+290",
        "code": "SH",
        "flag_img": "https://flagpedia.net/data/flags/h80/sh.png"
    },
    "+1869":{
        "name": "Saint Kitts and Nevis",
        "dial_code": "+1869",
        "code": "KN",
        "flag_img": "https://flagpedia.net/data/flags/h80/kn.png"
    },
    "+1758":{
        "name": "Saint Lucia",
        "dial_code": "+1758",
        "code": "LC",
        "flag_img": "https://flagpedia.net/data/flags/h80/lc.png"
    },
    /* "+590":{
        "name": "Saint Martin",
        "dial_code": "+590",
        "code": "MF",
        "flag_img": "https://flagpedia.net/data/flags/h80/mf.png"
    }, */
    "+508":{
        "name": "Saint Pierre and Miquelon",
        "dial_code": "+508",
        "code": "PM",
        "flag_img": "https://flagpedia.net/data/flags/h80/pm.png"
    },
    "+1784":{
        "name": "Saint Vincent and the Grenadines",
        "dial_code": "+1784",
        "code": "VC",
        "flag_img": "https://flagpedia.net/data/flags/h80/vc.png"
    },
    "+685":{
        "name": "Samoa",
        "dial_code": "+685",
        "code": "WS",
        "flag_img": "https://flagpedia.net/data/flags/h80/ws.png"
    },
    "+378":{
        "name": "San Marino",
        "dial_code": "+378",
        "code": "SM",
        "flag_img": "https://flagpedia.net/data/flags/h80/sm.png"
    },
    "+239":{
        "name": "Sao Tome and Principe",
        "dial_code": "+239",
        "code": "ST",
        "flag_img": "https://flagpedia.net/data/flags/h80/st.png"
    },
    "+966":{
        "name": "Saudi Arabia",
        "dial_code": "+966",
        "code": "SA",
        "flag_img": "https://flagpedia.net/data/flags/h80/sa.png"
    },
    "+221":{
        "name": "Senegal",
        "dial_code": "+221",
        "code": "SN",
        "flag_img": "https://flagpedia.net/data/flags/h80/sn.png"
    },
    "+381":{
        "name": "Serbia",
        "dial_code": "+381",
        "code": "RS",
        "flag_img": "https://flagpedia.net/data/flags/h80/rs.png"
    },
    "+248":{
        "name": "Seychelles",
        "dial_code": "+248",
        "code": "SC",
        "flag_img": "https://flagpedia.net/data/flags/h80/sc.png"
    },
    "+232":{
        "name": "Sierra Leone",
        "dial_code": "+232",
        "code": "SL",
        "flag_img": "https://flagpedia.net/data/flags/h80/sl.png"
    },
    "+421":{
        "name": "Slovakia",
        "dial_code": "+421",
        "code": "SK",
        "flag_img": "https://flagpedia.net/data/flags/h80/sk.png"
    },
    "+386":{
        "name": "Slovenia",
        "dial_code": "+386",
        "code": "SI",
        "flag_img": "https://flagpedia.net/data/flags/h80/si.png"
    },
    "+677":{
        "name": "Solomon Islands",
        "dial_code": "+677",
        "code": "SB",
        "flag_img": "https://flagpedia.net/data/flags/h80/sb.png"
    },
    "+252":{
        "name": "Somalia",
        "dial_code": "+252",
        "code": "SO",
        "flag_img": "https://flagpedia.net/data/flags/h80/so.png"
    },
    "+27":{
        "name": "South Africa",
        "dial_code": "+27",
        "code": "ZA",
        "flag_img": "https://flagpedia.net/data/flags/h80/za.png"
    },
    "+211":{
        "name": "South Sudan",
        "dial_code": "+211",
        "code": "SS",
        "flag_img": "https://flagpedia.net/data/flags/h80/ss.png"
    },
    /* "+500":{
        "name": "South Georgia and the South Sandwich Islands",
        "dial_code": "+500",
        "code": "GS",
        "flag_img": "https://flagpedia.net/data/flags/h80/gs.png"
    }, */
    "+34":{
        "name": "Spain",
        "dial_code": "+34",
        "code": "ES",
        "flag_img": "https://flagpedia.net/data/flags/h80/es.png"
    },
    "+94":{
        "name": "Sri Lanka",
        "dial_code": "+94",
        "code": "LK",
        "flag_img": "https://flagpedia.net/data/flags/h80/lk.png"
    },
    "+249":{
        "name": "Sudan",
        "dial_code": "+249",
        "code": "SD",
        "flag_img": "https://flagpedia.net/data/flags/h80/sd.png"
    },
    "+597":{
        "name": "Suriname",
        "dial_code": "+597",
        "code": "SR",
        "flag_img": "https://flagpedia.net/data/flags/h80/sr.png"
    },
    /* "+47":{
        "name": "Svalbard and Jan Mayen",
        "dial_code": "+47",
        "code": "SJ",
        "flag_img": "https://flagpedia.net/data/flags/h80/sj.png"
    }, */
    "+268":{
        "name": "Swaziland",
        "dial_code": "+268",
        "code": "SZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/sz.png"
    },
    "+46":{
        "name": "Sweden",
        "dial_code": "+46",
        "code": "SE",
        "flag_img": "https://flagpedia.net/data/flags/h80/se.png"
    },
    "+41":{
        "name": "Switzerland",
        "dial_code": "+41",
        "code": "CH",
        "flag_img": "https://flagpedia.net/data/flags/h80/ch.png"
    },
    "+963":{
        "name": "Syrian Arab Republic",
        "dial_code": "+963",
        "code": "SY",
        "flag_img": "https://flagpedia.net/data/flags/h80/sy.png"
    },
    "+886":{
        "name": "Taiwan",
        "dial_code": "+886",
        "code": "TW",
        "flag_img": "https://flagpedia.net/data/flags/h80/tw.png"
    },
    "+992":{
        "name": "Tajikistan",
        "dial_code": "+992",
        "code": "TJ",
        "flag_img": "https://flagpedia.net/data/flags/h80/tj.png"
    },
    "+255":{
        "name": "Tanzania",
        "dial_code": "+255",
        "code": "TZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/tz.png"
    },
    "+670":{
        "name": "Timor-Leste",
        "dial_code": "+670",
        "code": "TL",
        "flag_img": "https://flagpedia.net/data/flags/h80/tl.png"
    },
    "+228":{
        "name": "Togo",
        "dial_code": "+228",
        "code": "TG",
        "flag_img": "https://flagpedia.net/data/flags/h80/tg.png"
    },
    "+690":{
        "name": "Tokelau",
        "dial_code": "+690",
        "code": "TK",
        "flag_img": "https://flagpedia.net/data/flags/h80/tk.png"
    },
    "+676":{
        "name": "Tonga",
        "dial_code": "+676",
        "code": "TO",
        "flag_img": "https://flagpedia.net/data/flags/h80/to.png"
    },
    "+1868":{
        "name": "Trinidad and Tobago",
        "dial_code": "+1868",
        "code": "TT",
        "flag_img": "https://flagpedia.net/data/flags/h80/tt.png"
    },
    "+216":{
        "name": "Tunisia",
        "dial_code": "+216",
        "code": "TN",
        "flag_img": "https://flagpedia.net/data/flags/h80/tn.png"
    },
    "+90":{
        "name": "Turkey",
        "dial_code": "+90",
        "code": "TR",
        "flag_img": "https://flagpedia.net/data/flags/h80/tr.png"
    },
    "+993":{
        "name": "Turkmenistan",
        "dial_code": "+993",
        "code": "TM",
        "flag_img": "https://flagpedia.net/data/flags/h80/tm.png"
    },
    "+1649":{
        "name": "Turks and Caicos Islands",
        "dial_code": "+1649",
        "code": "TC",
        "flag_img": "https://flagpedia.net/data/flags/h80/tc.png"
    },
    "+688":{
        "name": "Tuvalu",
        "dial_code": "+688",
        "code": "TV",
        "flag_img": "https://flagpedia.net/data/flags/h80/tv.png"
    },
    "+256":{
        "name": "Uganda",
        "dial_code": "+256",
        "code": "UG",
        "flag_img": "https://flagpedia.net/data/flags/h80/ug.png"
    },
    "+380":{
        "name": "Ukraine",
        "dial_code": "+380",
        "code": "UA",
        "flag_img": "https://flagpedia.net/data/flags/h80/ua.png"
    },
    "+971":{
        "name": "United Arab Emirates",
        "dial_code": "+971",
        "code": "AE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ae.png"
    },
    "+598":{
        "name": "Uruguay",
        "dial_code": "+598",
        "code": "UY",
        "flag_img": "https://flagpedia.net/data/flags/h80/uy.png"
    },
    "+998":{
        "name": "Uzbekistan",
        "dial_code": "+998",
        "code": "UZ",
        "flag_img": "https://flagpedia.net/data/flags/h80/uz.png"
    },
    "+678":{
        "name": "Vanuatu",
        "dial_code": "+678",
        "code": "VU",
        "flag_img": "https://flagpedia.net/data/flags/h80/vu.png"
    },
    "+58":{
        "name": "Venezuela",
        "dial_code": "+58",
        "code": "VE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ve.png"
    },
    "+1284":{
        "name": "British Virgin Islands",
        "dial_code": "+1284",
        "code": "VG",
        "flag_img": "https://flagpedia.net/data/flags/h80/vg.png"
    },
    "+1340":{
        "name": "U.S. Virgin Islands",
        "dial_code": "+1340",
        "code": "VI",
        "flag_img": "https://flagpedia.net/data/flags/h80/vi.png"
    },
    "+681":{
        "name": "Wallis and Futuna",
        "dial_code": "+681",
        "code": "WF",
        "flag_img": "https://flagpedia.net/data/flags/h80/wf.png"
    },
    "+967":{
        "name": "Yemen",
        "dial_code": "+967",
        "code": "YE",
        "flag_img": "https://flagpedia.net/data/flags/h80/ye.png"
    },
    "+260":{
        "name": "Zambia",
        "dial_code": "+260",
        "code": "ZM",
        "flag_img": "https://flagpedia.net/data/flags/h80/zm.png"
    },
    "+263":{
        "name": "Zimbabwe",
        "dial_code": "+263",
        "code": "ZW",
        "flag_img": "https://flagpedia.net/data/flags/h80/zw.png"
    }
};

export default {countryCodesData: countryCodesData}