<template>

    <div>
      <div>
        <div v-if="allElements.length" class="model-sections">
            <div lines="none" v-for="(thisElement, eIndex) in allElements" :key="eIndex" class="table-reorder ion-no-padding ion-justify-content-between rowMargin">
                <!-- text -->
                <div class="element-container" v-if="thisElement.type === 'text'">
                  <ion-textarea v-model="thisElement.payload" rows="4" autofocus="true" class="textarea-box"></ion-textarea>
                  <div v-if="thisElement.buttons && thisElement.buttons.length" class="addNew" :class="{ complete: thisElement.buttons && thisElement.buttons.length == 3 }">
                    <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex" expand="block" fill="outline" @click="onclickButton('text', bIndex, eIndex)" class="btn-template-dynamic-action">
                      {{ thisButton.text }}
                    </div>
                  </div>
                  <div v-if="thisElement.buttons && thisElement.buttons.length < buttonLimit && editBroadcast.channel != 'sms'" class="addNew">
                    <div class="addRow" @click="addEmptyButton(thisElement)">+ Add New</div>
                  </div>
                </div>
                <!-- image -->
                <div v-if="thisElement.type === 'image'">
                  <div class="img-block" :class="{'no-image': thisElement.payload == DEFAULT_IMAGE || !!!thisElement.payload}">
                    <input type="file" style="display: none;" id="image" ref="image" @change="handleImageUpload($event, eIndex)" accept="image/png, image/gif, image/jpeg" />
                    <img v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.image.click()" class="img-container" />
                    <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"/>
                  </div>
                  <div class="upload-img">
                    <ion-button @click="() => $refs.image.click()" expand="block" fill="outline">Upload Image</ion-button>
                  </div>
                </div>
                <!-- custom card -->
                <div v-if="thisElement.type === 'customCard'">
                  <div class="img-block">
                    <input type="file" style="display: none;" id="image2" ref="image2" @change="handleImageUpload($event, eIndex)" accept="image/png, image/gif, image/jpeg" />
                    <img v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.image2.click()" class="img-container" />
                    <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container"/>
                    <div class="upload-img">
                      <ion-button @click="() => $refs.image2.click()" expand="block" fill="outline">Upload Image</ion-button>
                    </div>
                    <ion-input v-model="thisElement.title" rows="4" autofocus="true" placeholder="Enter Title" class="fullBordered input_custom_style"></ion-input>
                    <ion-input v-model="thisElement.subtitle" rows="4" autofocus="true" placeholder="Enter Subtitle" class="fullBordered input_custom_style"></ion-input>
                    <div v-if="thisElement.buttons && thisElement.buttons.length" class="addNew" :class="{ complete: thisElement.buttons && thisElement.buttons.length == 3 }">
                      <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex" expand="block" fill="outline" @click="onclickButton('customCard', bIndex, eIndex)" class="btn-template-dynamic-action">
                        {{ thisButton.text }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- video -->
                <div v-if="thisElement.type === 'video'">
                  <div class="img-block">
                    <input type="file" style="display: none;" id="video" ref="video" @change="handleVideoUpload($event, eIndex)" accept="video/mp4, video/mpeg4" />
                    <video v-if="thisElement.payload && thisElement.payload.length" :src="thisElement.payload" width="200" @click="() => $refs.video.click()" class="img-container"></video>
                    <video v-else  poster="https://jumperdevnew.appspot.com/web-s/resources/images/bot-builder/templates/video-only.svg" class="img-container"></video>
                  </div>
                  <div class="upload-img">
                    <ion-button @click="() => $refs.video.click()" expand="block" fill="outline">Upload Video</ion-button>
                  </div>
                </div>
                <!-- product -->
                <div v-if="thisElement.type === 'product'">
                  <div class="img-block">
                    <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length" :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
                    <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container" style="margin: 20px auto 20px auto"/>
                    <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                    <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                    <div v-if="thisElement.buttons && thisElement.buttons.length">
                      <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container btn-template-dynamic-action" fill="outline" @click="onclickButton('product', bIndex, eIndex)">
                        {{ thisButton.text }}
                      </div>
                    </div>
                  </div>
                  <!-- <div v-if="thisElement.buttons.length < 3" class="addNew">
                    <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                  </div> -->
                </div>
                <!-- collection -->
                <div v-if="thisElement.type === 'collection'">
                  <div class="img-block">
                    <img width="150" v-if="thisElement.payload.images && thisElement.payload.images.length" :src="thisElement.payload.images[0]" class="img-container" style="margin: 20px auto 20px auto" />
                    <img v-else src="https://jumperdevnew.appspot.com/web-s/resources/images/add-product.png" class="img-container" style="margin: 20px auto 20px auto"/>
                    <ion-input v-model="thisElement.payload.name" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                    <ion-input v-model="thisElement.payload.totalcost" type="text" placeholder="Enter name" class="fullBordered input_custom_style inpt-container"></ion-input>
                    <div v-if="thisElement.buttons && thisElement.buttons.length">
                      <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container btn-template-dynamic-action" fill="outline" @click="onclickButton('collection', bIndex, eIndex)">
                        {{ thisButton.text }}
                      </div>
                    </div>
                  </div>
                  <!-- <div v-if="thisElement.buttons.length < 3" class="addNew">
                    <div class="addRow" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                  </div> -->
                </div>
                <!-- reply -->
                <div class="element-container" v-if="thisElement.type === 'reply'">
                  <ion-textarea v-model="thisElement.payload" rows="4" autofocus="true" class="textarea-box"></ion-textarea>
                    <!-- <my-input class="quick-reply" id="template_body" emoji name="template_body" :value="thisElement.payload" @onInput="(prop) => thisElement.payload = prop" placeholder="Enter a Reply" :maxlength="1024" /> -->
                  
                  <div class="quick-reply-button-container">
                    <div v-if="thisElement.buttons && thisElement.buttons.length"  class="quick-reply-buttons-wrapper" :class="{ complete: thisElement.buttons.length == 3 }">
                      <div v-for="(thisButton, bIndex) in thisElement.buttons" :key="'element' + eIndex + 'button' + bIndex"  class="img-container btn-template-dynamic-action" fill="outline" @click="onclickButton('reply', bIndex, eIndex)">
                        {{ thisButton.text }}
                      </div>
                    </div>
                    <div v-if="thisElement.buttons.length < quickReplyButtonLimit" class="quick-reply-add-new-button">
                      <div class="btn-reply-add-new" @click="() => thisElement.buttons.push({ text: 'Button', payload: undefined })">+ Add New</div>
                    </div>
                  </div>
                </div>

                <span class="tooltip_container delete-field">
                  <ion-button @click="() => (allElements = [])" fill="clear" size="default" color="danger">
                    <ion-icon :icon="trashOutline"></ion-icon>
                  </ion-button>
                  <span class="tooltip_text left" style="width: 80px">Delete</span>
                </span>

            </div>
        </div>
      </div>
    </div>
    <popper v-if="isPopperOpen" is_agent_or_group_req :textLimit="limitButtonText" :removeButton="true" :type="addEditModal" :config="popperConfig" :optionsConf="optionsConf" @close="() => {editIndex = -1; addEditModal = undefined; buttonIndex = -1;}" @submit="popperSubmit" @removeButton="removeButtonHandler" />
    <ion-modal
      v-if="addProductMode"
      :is-open="addProductMode"
      @didDismiss="() => addProductMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin" style="padding-left: 10px">Choose Product</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addProductMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <template v-if="loadingProducts">
            <div class="loading">Loading...</div>
          </template>
          <template v-else>
            <ion-searchbar v-model="productFilter" show-cancel-button="always"></ion-searchbar>
            <ion-list>
              <ion-item @click="() => { allElements.push({ type: 'product', payload: thisProduct, buttons: [{ text: 'Buy Now', payload: ('#' + thisProduct.hashtag) }] }); addProductMode = false; }" v-for="thisProduct in productsToShow" :key="thisProduct.id">
                <ion-label>{{ thisProduct.name }}</ion-label>
              </ion-item>
            </ion-list>
          </template>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
      v-if="addCollectionMode"
      :is-open="addCollectionMode"
      @didDismiss="() => addCollectionMode = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin" style="padding-left: 10px">Choose Collection</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => addCollectionMode = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <template v-if="loadingProducts">
            <div class="loading">Loading...</div>
          </template>
          <template v-else>
            <ion-searchbar v-model="collectionFilter" show-cancel-button="always"></ion-searchbar>
            <ion-list>
              <ion-item @click="() => { allElements.push({ type: 'collection', payload: thisCollection, buttons: [{ text: 'Browse Now', payload: ('#' + thisCollection.hashtag) }] }); addCollectionMode = false; }" v-for="thisCollection in collectionsToShow" :key="thisCollection.id">
                <ion-label>{{ thisCollection.name }}</ion-label>
              </ion-item>
            </ion-list>
          </template>
        </ion-content>
      </ion-page>
    </ion-modal>
    <ion-modal
        v-if="addCustomCardMode"
        :is-open="addCustomCardMode"
        @didDismiss="() => addCustomCardMode = false"
      >
        <ion-page>
          <ion-header>
            <ion-toolbar>
              <h2 class="ion-no-margin" style="padding-left: 10px">Custom Card</h2>
              <ion-buttons slot="end">
                <ion-button @click="() => addCustomCardMode = false">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <template>
              <div class="loading">Custom Card</div>
            </template>
          </ion-content>
        </ion-page>
      </ion-modal>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, trashOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSearchbar, IonTextarea } from '@ionic/vue';
import popper from '/src/components/popper';

const DEFAULT_IMAGE = "https://jumper.ai/web-s/resources/images/upload-image-placeholder.png";

export default {
  components: {popper, IonSearchbar, IonTextarea},
  data() {
    return {
      loading: false,
      loadingProducts: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      trashOutline,
      close,
      allProducts: [],
      allCollections: [],
      allElements: [],
      editIndex: -1,
      buttonIndex: -1,
      addEditModal: undefined,
      deleteAllMode: false,
      addCollectionMode: false,
      addProductMode: false,
      addCustomCardMode: false,
      productFilter: undefined,
      collectionFilter: undefined,
      optionsConf: {},
      DEFAULT_IMAGE,
    }
  },
  props: {
    editBroadcast: {
      type: Object
    }
  },
  mounted() {
    if(this.editBroadcast.id) {
      this.generateAdMessageJsonElements();
    }
  },
  emits: ['finalJson', 'finalJsonElements', 'loading'],
  computed: {
    limitButtonText() {
      if (this.editIndex > -1 && this.buttonIndex > -1) {
        return 20;
      }
      return null;
    },
    buttonLimit() {
      let limit = 3;
      switch (this.editBroadcast.channel) {
        case 'facebook':
          limit = 3;
        break;
        case 'whatsapp':
          limit = 3;
        break;
        case 'line':
          limit = 3;
        break;
        case 'telegram':
          limit = 3;
        break;
        case 'viber':
          limit = 3;
          break;
        case 'imessage':
          limit = 10;
        break;
        case 'sms':
          limit = 3;
          break;
        default:
          limit = 3;
        break;
      }
      return limit;
    },
    quickReplyButtonLimit() {
      let limit = 3;
      switch (this?.editBroadcast?.channel) {
        case 'facebook':
          limit = 11;
          break;
        case 'whatsapp':
          limit = 3;
          break;
        case 'line':
          limit = 11;
          break;
        case 'telegram':
          limit = 15;
          break;
        case 'viber':
          limit = 3;
          break;
        case 'imessage':
          limit = 10;
          break;
        case 'sms':
          limit = 3;
          break;
        default:
          limit = 3;
          break;
      }
      return limit;
    },
    isPopperOpen() {
      return this.addEditModal === 'simple' || this.addEditModal === 'detailed';
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined;
      if(this.editIndex > -1 && this.buttonIndex > -1) {
        text = this.allElements[this.editIndex].buttons[this.buttonIndex].text;
        payload = this.allElements[this.editIndex].buttons[this.buttonIndex].payload || this.allElements[this.editIndex].buttons[this.buttonIndex].url;
        action = this.allElements[this.editIndex].buttons[this.buttonIndex].action;
      }
      console.log({ text: text, action: action, payload: payload });
      return { text: text, action: action, payload: payload };
    },
    productsToShow() {
      return this.productFilter && this.productFilter.length ? this.allProducts.filter(e => e.name.match(new RegExp(this.productFilter, 'gi'))) : this.allProducts;
    },
    collectionsToShow() {
      return this.collectionFilter && this.collectionFilter.length ? this.allCollections.filter(e => e.name.match(new RegExp(this.collectionFilter, 'gi'))) : this.allCollections;
    }
  },
  watch: {
    allElements: {
      handler: function (allElements) {
        this.$emit('finalJsonElements', { finalJsonElements: allElements })
      },
      immediate: true
    }
  },
  methods: {
    addEmptyButton(thisElement) {
      thisElement.buttons.push({ text: 'Button', url: "", type: "postback" })
    },
    onclickButton(type, bIndex, eIndex) {
      this.addEditModal = 'detailed';
      this.editIndex = eIndex;
      this.buttonIndex = bIndex;
      switch (type) {
        case 'text':
        case 'customCard':
        case 'product':
        case 'collection':
          this.optionsConf = {
            productCheckout: {
              text: 'Start A Product Checkout',
              enable: true
            },
            pingLiveChat: {
              enable: false
            }
          }
        break;
        case 'reply':
          this.optionsConf = {
            productCheckout: {
              text: 'Start A Product Checkout',
              enable: false
            },
            pingLiveChat: {
              enable: false
            },
            openWebSite: {
              enable: false
            }
          }
        break;
        default:
        break;
      }
    },
    // on edit or view broadcast build elements
    generateAdMessageJsonElements() {
      this.allElements = [];
      this.editBroadcast?.answers?.forEach((answer) => {
        const { type, message, template, id } = answer;
        let field;
        if (type.includes("normalchat")) {
          field = { type: "text", payload: message || '' }
        }

        if (type.includes('buttonreply')) {
          field = { type: "text", payload: message || '' }
          if (template?.buttons?.length) {
            let buttons = [];
            template.buttons.forEach((button) => {
              if (button.type == 'webview') {
                buttons.push({
                  url: button.url,
                  text: button.title
                });  
              } else if (button.type == 'postback') {
                buttons.push({
                  payload: button.payload,
                  text: button.title
                });
              }
            });
            field.buttons = buttons;
          }
        }

        if (type.includes('cancelled')) {
          field = { type: "image", payload: message || '' }
        }

        if (type.includes('videoreply')) {
          field = { type: "video", payload: message || '' }
        }

        if (type.includes('quickreplies')) {
          field = { type: "reply", payload: message || '' }
          if (template?.buttons?.length) {
            let buttons = [];
            template.buttons.forEach((button) => {
              buttons.push({
                payload: button.payload,
                text: button.title
              });
            });
            field.buttons = buttons;
          }
        }

        if (type.includes('carousel')) {
          const details = template.elements[0]
          // const currency = details.subtitle[0];
          // const cost = details.subtitle.substring(1);
          let fieldType = Object.prototype.hasOwnProperty.call(details, 'sku') ? 'product' : 'collection';
          let payload = "";
          if (details.image_url) {
            fieldType = "customCard";
            payload = details.image_url;
          }
          field = {
            type: fieldType,
            payload,
            title: details.title,
            subtitle: details.subtitle
          }

          if (details?.buttons?.length) {
            let buttons = [];
            details.buttons.forEach((button) => {
              if (button.type == 'webview') {
                buttons.push({
                  url: button.url,
                  text: button.title
                });
              } else if (button.type == 'postback') {
                buttons.push({
                  payload: button.payload,
                  text: button.title
                });
              }
            });
            field.buttons = buttons;
          }
        }

        if (field) {
          this.allElements.push(field);
        }
      });
    },
    // eslint-disable-next-line
    doReorder(e) {
      this.allElements = e.detail.complete(this.allElements);
    },
    actions() {
      return {
        addText: () => {
          this.allElements.push({ type: 'text', payload: undefined, buttons: [] });
        },
        addImage: () => {
          this.allElements.push({ type: 'image', payload: DEFAULT_IMAGE, attachment_id: undefined });
        },
        addVideo: () => {
          this.allElements.push({ type: 'video', payload: undefined, attachment_id: undefined });
        },
        addProduct: () => {
          this.loadProducts();
          this.addProductMode = true;
        },
        addCollection: () => {
          this.loadProducts();
          this.addCollectionMode = true;
        },
        addQuickReplies: () => {
          this.allElements.push({ type: 'reply', payload: undefined, buttons: [] });
        },
        addCustomCard: () => {
          this.allElements.push({ type: 'customCard', title: 'title', subtitle: 'subtitle', payload: DEFAULT_IMAGE, buttons: [{ text: 'Button', url: "", type: "postback" }] });
        }
      };
    },
    async loadProducts() {
      if (!this.allProducts.length || !this.allCollections) {
        this.fetchProducts();
      }
    },
    async fetchProducts() {
      try {
        this.loadingProducts = true;
        let shopListResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-shop-list");
        if ((shopListResponse.status == 200 || shopListResponse.status == 201) && shopListResponse.data.success) {
          if(shopListResponse.data.products && shopListResponse.data.products.length) {
            this.allProducts = shopListResponse.data.products.filter(e => !e.bundle && !e.catalog);
            this.allCollections = shopListResponse.data.products.filter(e => !e.bundle && e.catalog);
          }
        } else {
          if(shopListResponse.data && !shopListResponse.data.success && shopListResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: shopListResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loadingProducts = false;
      }
    },
    popperSubmit(data) {
      console.log(data);
      let text = data.text; let payload = undefined;
      switch(data.action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
        case 'custom':
          if (this.editIndex > -1 && this.buttonIndex > -1) {
            this.allElements[this.editIndex].buttons[this.buttonIndex].type = 'postback';
            this.allElements[this.editIndex].buttons[this.buttonIndex].payload = data.payload;
            this.allElements[this.editIndex].buttons[this.buttonIndex].text = text;
          }
        break;
        case 'website':
          if (this.editIndex > -1 && this.buttonIndex > -1) {
            this.allElements[this.editIndex].buttons[this.buttonIndex].type = 'webview';
            this.allElements[this.editIndex].buttons[this.buttonIndex].url = data.payload;
            this.allElements[this.editIndex].buttons[this.buttonIndex].text = text;
          }
        break;
      }
      if( payload && text ) {
        if(this.editIndex > -1 && this.buttonIndex > -1) {
          this.allElements[this.editIndex].buttons[this.buttonIndex].text = text;
          this.allElements[this.editIndex].buttons[this.buttonIndex].payload = payload;
        } else {
          if(this.editIndex > -1) this.allElements[this.editIndex].buttons.push({ text: text, payload: payload });
          else console.log("Something went wrong");
        }
      }
      this.addEditModal = undefined;
      this.editIndex = -1;
      this.buttonIndex = -1;
      this.optionsConf = {};
    },
    removeButtonHandler() {
      if (this.editIndex > -1 && this.buttonIndex > -1) {
        this.allElements[this.editIndex].buttons.splice(this.buttonIndex, 1);
      }

      this.addEditModal = undefined;
      this.editIndex = -1;
      this.buttonIndex = -1;
    },
    async handleImageUpload (event, elemIndex) {
      try {
        if(elemIndex > -1) {
          this.$emit('loading', { loading: true });
          if(event.target.files && event.target.files.length) {
            let newImage = event.target.files[0];
            if(await this.validateImage(newImage, 4)) {
              let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                this.allElements[elemIndex].payload = uploadSendResponseData.url;
                // let reqBody = {
                //   mediatype: 'image',
                //   url: this.allElements[elemIndex].payload
                // }
                // let uploadAttachmentResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'upload-attachment', reqBody);
                // if ((uploadAttachmentResponse.status == 200 || uploadAttachmentResponse.status == 201) && uploadAttachmentResponse.data.success && uploadAttachmentResponse.data.attachment_id) {
                //   this.allElements[elemIndex].attachment_id = uploadAttachmentResponse.data.attachment_id;
                //   this.updateToast({ open: true, message: "Uploaded attachment successfully!", type: "success" })
                // } else {
                //   if(uploadAttachmentResponse.data.errorMessage && uploadAttachmentResponse.data.errorMessage.length) {
                //     this.updateToast({ open: true, message: uploadAttachmentResponse.data.errorMessage, type: "danger" })
                //   } else {
                //     this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
                //   }
                // }
              } else {
                this.updateToast({ open: true, message: "Something went wrong while uploading the image", type: "danger" })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({ open: true, message: "Invalid picture", type: "danger" })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Image uploading failed", type: "danger" });
      } finally {
        this.$emit('loading', { loading: false });
      }
    },
    async handleVideoUpload (event, elemIndex) {
      try {
        if(elemIndex > -1) {
          this.$emit('loading', { loading: true });
          if(event.target.files && event.target.files.length) {
            let newVideo = event.target.files[0];
            console.log(newVideo);
            if(await this.validateVideo(newVideo, 20)) {
              let uploadSendResponseData = await this.uploadVideo(newVideo, '/add-doc');
              if(uploadSendResponseData.image && uploadSendResponseData.image.length) {
                this.allElements[elemIndex].payload = uploadSendResponseData.url;
                let reqBody = {
                  mediatype: 'video',
                  url: this.allElements[elemIndex].payload
                }
                let uploadAttachmentResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'upload-attachment', reqBody);
                if ((uploadAttachmentResponse.status == 200 || uploadAttachmentResponse.status == 201) && uploadAttachmentResponse.data.success && uploadAttachmentResponse.data.attachment_id) {
                  this.allElements[elemIndex].attachment_id = uploadAttachmentResponse.data.attachment_id;
                  this.updateToast({ open: true, message: "Uploaded attachment successfully!", type: "success" })
                } else {
                  if(uploadAttachmentResponse.data.errorMessage && uploadAttachmentResponse.data.errorMessage.length) {
                    this.updateToast({ open: true, message: uploadAttachmentResponse.data.errorMessage, type: "danger" })
                  } else {
                    this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
                  }
                }
              } else {
                this.updateToast({ open: true, message: "Something went wrong uploading the image", type: "danger" })
              }
            } else {
              console.log("Invalid picture");
              this.updateToast({ open: true, message: "Invalid picture", type: "danger" })
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.$emit('loading', { loading: false });
      }
    },
    async copy(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>

<style scoped>
.quick-reply-button-container {
  display: flex;
  column-gap: 10px;
  max-width: 450px;
  overflow-x: scroll;
  padding: 10px 10px;
}

.quick-reply-button-container::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #F5F5F5;
}
.quick-reply-buttons-wrapper {
  display: flex;
  column-gap: 10px;
}
.quick-reply-buttons-wrapper .btn-template-dynamic-action {
  color: #0084ff;
  border: 1px #0084ff solid;
  border-radius: 15px;
  cursor: pointer;
  max-width: 180px;
  width: 180px !important;
  height: 30px;
}

.btn-reply-add-new {
  text-align: center;
  display: inline-block;
  width: 130px;
  font-weight: 500;
  background-color: transparent;
  color: #aaa;
  border: 1px #aaa dashed;
  margin-right: 8px;
  padding: 5px 18px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
}

.quick-reply-button-container::-webkit-scrollbar-thumb{
  background-color: var(--primary-theme-color, #3d39b9); 
  background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}


.element-container{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-template-dynamic-action {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px #eee dashed;
  border-bottom: none;
  height: 40px;
  width: 100% !important;
  color: var(--ion-color-primary);
  cursor: pointer;
  color: var(--ion-color-black);
}
.img-block .btn-template-dynamic-action {
  border-left: none;
  border-right: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  padding-left: 15px;
  padding-bottom: 5px;
}
.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-left: 15px;
}

.sub-text{
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 38px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
}
.upload-img {
  max-width: 453px;
}
.filterImage {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.model-sections {
  background: var(--ion-color-white);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  width: fit-content;
}
.inpt-container {
  width: 360px;
  margin: 0px 30px 10px 46px;
}
.addRow{
  margin-top: 0;
  margin-bottom: 0;
}
.addRow:hover {
   border: 2px #881fff dashed;
}

.addNew {
  max-width: 450px;
  width: 100%;
}
.addNew.complete {
  border-bottom: 2px #eee dashed;
}
.textarea-box {
  width: 450px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
  color: #262626;
}
.img-block{
  border-radius: 5px;
  border: 2px #eee dashed;
  width: 450px;
  display: inline-block;
}
.img-block.no-image{min-height: 200px;}
.jinput {
  background-color: #ffffff;
}
.delete-field{
  position: absolute;
  top: 0;
  right: -40px;
}
@media only screen and (max-width: 760px){
  .model-sections, .element-container {
    width: calc( 100% - 40px );
  }

  .addNew {
    max-width: 100%;
    margin-left: 0px;
  }
  .upload-img {
    max-width: 100%;
    margin-left: 0px;
  }
  .textarea-box{
    width: 100%;
  }
  .img-block{
    width: 100%;
  }
  .inpt-container{
    margin: 0px 10px 10px 10px;
    width: 95%;
  }
  .jinput{
    width: 98%;
  }

}

.tooltip_container .tooltip_text.left {
  top: 5%;
  z-index: 999;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>