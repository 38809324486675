export function environment(variable) {
  
  const STAGING = {
    name: 'staging',
    access: ''
  };
  const PROD = {
    name: 'production',
    access: '_PROD'
  };
  const EU_STAGING = {
    name: 'eu_staging',
    access: '_EU'
  };
  const EU_PROD = {
    name: 'eu_production',
    access: '_EU_PROD'
  };
  const ASIA_STAG = {
    name: 'asia_staging',
    access: '_ASIA_STAG'
  };

  switch(process.env.VUE_APP_NODE_ENV) {
    case STAGING.name: variable += STAGING.access; break;
    case PROD.name: variable += PROD.access; break;
    case EU_STAGING.name: variable += EU_STAGING.access; break;
    case EU_PROD.name: variable += EU_PROD.access; break;
    case ASIA_STAG.name: variable += ASIA_STAG.access; break;
    default: variable = null;
  }

  const calendarURLElements = ['https://accounts.google.com/o/oauth2/auth?client_id=', '&redirect_uri=', 's/calendarlogin&scope=https://www.googleapis.com/auth/calendar&response_type=code&access_type=offline&approval_prompt=force'];
  const youtubeURLElements = ['https://accounts.google.com/o/oauth2/auth?client_id=', '&redirect_uri=', 's/youtube&scope=https://www.googleapis.com/auth/youtube.force-ssl&response_type=code&access_type=offline&approval_prompt=force'];
  const instagramURLElements = ['https://api.instagram.com/oauth/authorize/?client_id=', '&redirect_uri=', 's/start-instagram&response_type=token&scope=public_content+comments'];
  const zapierURLElements = ['https://zapier.com/developer/invite/'];
  const shopifyURLElements = ['admin/oauth/authorize?client_id=', '&redirect_uri=', 's/shopifylogin&scope=read_products,write_products,read_orders,write_orders,read_reports,write_reports,read_price_rules,write_price_rules,read_shipping,write_shipping,read_fulfillments,write_fulfillments,read_content,write_content,read_customers,write_customers,read_product_listings,read_collection_listings,read_checkouts,write_checkouts'];
  const pinterestURLElements = ['https://api.pinterest.com/oauth/?response_type=code&redirect_uri=', 's/connect&client_id=', '&scope=read_public,write_public&state='];
  const paypalIndiaURLElements = ['https://www.paypal.com/signin/authorize?client_id=', '&response_type=code&scope=profile+email+address+phone&redirect_uri=', 's/paypalindialogin&state=paypal'];
  const razorPayURLElements = ['https://auth.razorpay.com/authorize?client_id=', '&response_type=code&redirect_uri=', 'settings/payments&scope=read_write&state=razorpay'];
  const stripeURLElements = ['https://connect.stripe.com/oauth/authorize?response_type=code&client_id=', '&scope=read_write&redirect_uri=', 's/stripe'];
  const stripeIndiaURLElements = ['https://connect.stripe.com/oauth/authorize?response_type=code&client_id=', '&scope=read_write'];

  switch(variable) {
    case 'VUE_APP_ROOT_API': return (process.env['VUE_APP_ROOT'] + "api/");
    case 'VUE_APP_ROOT_API_PROD': return (process.env['VUE_APP_ROOT_PROD'] + "api/");
    case 'VUE_APP_ROOT_API_EU': return (process.env['VUE_APP_ROOT_EU'] + "api/");
    case 'VUE_APP_ROOT_API_EU_PROD': return (process.env['VUE_APP_ROOT_EU_PROD'] + "api/");
    case 'VUE_APP_ROOT_API_ASIA_STAG': return (process.env['VUE_APP_ROOT_ASIA_STAG'] + "api/");

    case 'googleClientId': return process.env['VUE_APP_GOOGLE'];
    case 'googleClientId_PROD': return process.env['VUE_APP_GOOGLE_PROD'];
    case 'googleClientId_EU': return process.env['VUE_APP_GOOGLE_EU'];
    case 'googleClientId_EU_PROD': return process.env['VUE_APP_GOOGLE_EU_PROD'];
    case 'googleClientId_ASIA_STAG': return process.env['VUE_APP_GOOGLE_ASIA_STAG'];


    case 'calendarConnectUrl': return (calendarURLElements[0] + process.env['VUE_APP_CALENDAR'] + calendarURLElements[1] + process.env['VUE_APP_FE'] + calendarURLElements[2]);
    case 'calendarConnectUrl_PROD': return (calendarURLElements[0] + process.env['VUE_APP_CALENDAR_PROD'] + calendarURLElements[1] + process.env['VUE_APP_FE_PROD'] + calendarURLElements[2]);
    case 'calendarConnectUrl_EU': return (calendarURLElements[0] + process.env['VUE_APP_CALENDAR_EU'] + calendarURLElements[1] + process.env['VUE_APP_FE_EU'] + calendarURLElements[2]);
    case 'calendarConnectUrl_EU_PROD': return (calendarURLElements[0] + process.env['VUE_APP_CALENDAR_EU_PROD'] + calendarURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + calendarURLElements[2]);
    case 'calendarConnectUrl_ASIA_STAG': return (calendarURLElements[0] + process.env['VUE_APP_CALENDAR_ASIA_STAG'] + calendarURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + calendarURLElements[2]);

    case 'youtubeConnectUrl': return (youtubeURLElements[0] + process.env['VUE_APP_YOUTUBE'] + youtubeURLElements[1] + process.env['VUE_APP_FE'] + youtubeURLElements[2]);
    case 'youtubeConnectUrl_PROD': return (youtubeURLElements[0] + process.env['VUE_APP_YOUTUBE_PROD'] + youtubeURLElements[1] + process.env['VUE_APP_FE_PROD'] + youtubeURLElements[2]);
    case 'youtubeConnectUrl_EU': return (youtubeURLElements[0] + process.env['VUE_APP_YOUTUBE_EU'] + youtubeURLElements[1] + process.env['VUE_APP_FE_EU'] + youtubeURLElements[2]);
    case 'youtubeConnectUrl_EU_PROD': return (youtubeURLElements[0] + process.env['VUE_APP_YOUTUBE_EU_PROD'] + youtubeURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + youtubeURLElements[2]);
    case 'youtubeConnectUrl_ASIA_STAG': return (youtubeURLElements[0] + process.env['VUE_APP_YOUTUBE_ASIA_STAG'] + youtubeURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + youtubeURLElements[2]);

    case 'instagramConnectUrl': return (instagramURLElements[0] + process.env['VUE_APP_INSTA'] + instagramURLElements[1] + process.env['VUE_APP_FE'] + instagramURLElements[2]);
    case 'instagramConnectUrl_PROD': return (instagramURLElements[0] + process.env['VUE_APP_INSTA_PROD'] + instagramURLElements[1] + process.env['VUE_APP_FE_PROD'] + instagramURLElements[2]);
    case 'instagramConnectUrl_EU': return (instagramURLElements[0] + process.env['VUE_APP_INSTA_EU'] + instagramURLElements[1] + process.env['VUE_APP_FE_EU'] + instagramURLElements[2]);
    case 'instagramConnectUrl_EU_PROD': return (instagramURLElements[0] + process.env['VUE_APP_INSTA_EU_PROD'] + instagramURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + instagramURLElements[2]);
    case 'instagramConnectUrl_ASIA_STAG': return (instagramURLElements[0] + process.env['VUE_APP_INSTA_ASIA_STAG'] + instagramURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + instagramURLElements[2]);

    case 'zapierConnectUrl': return (zapierURLElements[0] + process.env['VUE_APP_ZAPIER']);
    case 'zapierConnectUrl_PROD': return (zapierURLElements[0] + process.env['VUE_APP_ZAPIER_PROD']);
    case 'zapierConnectUrl_EU': return (zapierURLElements[0] + process.env['VUE_APP_ZAPIER_EU']);
    case 'zapierConnectUrl_EU_PROD': return (zapierURLElements[0] + process.env['VUE_APP_ZAPIER_EU_PROD']);
    case 'zapierConnectUrl_ASIA_STAG': return (zapierURLElements[0] + process.env['VUE_APP_ZAPIER_ASIA_STAG']);

    case 'shopifyConnectUrl': return (shopifyURLElements[0] + process.env['VUE_APP_SHOPIFY'] + shopifyURLElements[1] + process.env['VUE_APP_FE'] + shopifyURLElements[2]);
    case 'shopifyConnectUrl_PROD': return (shopifyURLElements[0] + process.env['VUE_APP_SHOPIFY_PROD'] + shopifyURLElements[1] + process.env['VUE_APP_FE_PROD'] + shopifyURLElements[2]);
    case 'shopifyConnectUrl_EU': return (shopifyURLElements[0] + process.env['VUE_APP_SHOPIFY_EU'] + shopifyURLElements[1] + process.env['VUE_APP_FE_EU'] + shopifyURLElements[2]);
    case 'shopifyConnectUrl_EU_PROD': return (shopifyURLElements[0] + process.env['VUE_APP_SHOPIFY_EU_PROD'] + shopifyURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + shopifyURLElements[2]);
    case 'shopifyConnectUrl_ASIA_STAG': return (shopifyURLElements[0] + process.env['VUE_APP_SHOPIFY_ASIA_STAG'] + shopifyURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + shopifyURLElements[2]);

    case 'pinterestConnectUrl': return (pinterestURLElements[0] + process.env['VUE_APP_FE'] + pinterestURLElements[1] + process.env['VUE_APP_PINTEREST'] + pinterestURLElements[2]);
    case 'pinterestConnectUrl_PROD': return (pinterestURLElements[0] + process.env['VUE_APP_FE_PROD'] + pinterestURLElements[1] + process.env['VUE_APP_PINTEREST_PROD'] + pinterestURLElements[2]);
    case 'pinterestConnectUrl_EU': return (pinterestURLElements[0] + process.env['VUE_APP_FE_EU'] + pinterestURLElements[1] + process.env['VUE_APP_PINTEREST_EU'] + pinterestURLElements[2]);
    case 'pinterestConnectUrl_EU_PROD': return (pinterestURLElements[0] + process.env['VUE_APP_FE_EU_PROD'] + pinterestURLElements[1] + process.env['VUE_APP_PINTEREST_EU_PROD'] + pinterestURLElements[2]);
    case 'pinterestConnectUrl_ASIA_STAG': return (pinterestURLElements[0] + process.env['VUE_APP_FE_ASIA_STAG'] + pinterestURLElements[1] + process.env['VUE_APP_PINTEREST_ASIA_STAG'] + pinterestURLElements[2]);
    
    case 'paypalIndiaConnectUrl': return (paypalIndiaURLElements[0] + process.env['VUE_APP_PAYPAL_INDIA'] + paypalIndiaURLElements[1] + process.env['VUE_APP_FE'] + paypalIndiaURLElements[2]);
    case 'paypalIndiaConnectUrl_PROD': return (paypalIndiaURLElements[0] + process.env['VUE_APP_PAYPAL_INDIA_PROD'] + paypalIndiaURLElements[1] + process.env['VUE_APP_FE_PROD'] + paypalIndiaURLElements[2]);
    case 'paypalIndiaConnectUrl_EU': return (paypalIndiaURLElements[0] + process.env['VUE_APP_PAYPAL_INDIA_EU'] + paypalIndiaURLElements[1] + process.env['VUE_APP_FE_EU'] + paypalIndiaURLElements[2]);
    case 'paypalIndiaConnectUrl_EU_PROD': return (paypalIndiaURLElements[0] + process.env['VUE_APP_PAYPAL_INDIA_EU_PROD'] + paypalIndiaURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + paypalIndiaURLElements[2]);
    case 'paypalIndiaConnectUrl_ASIA_STAG': return (paypalIndiaURLElements[0] + process.env['VUE_APP_PAYPAL_INDIA_ASIA_STAG'] + paypalIndiaURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + paypalIndiaURLElements[2]);

    case 'razorpayConnectUrl': return (razorPayURLElements[0] + process.env['VUE_APP_RAZOR'] + razorPayURLElements[1] + process.env['VUE_APP_FE'] + razorPayURLElements[2]);
    case 'razorpayConnectUrl_PROD': return (razorPayURLElements[0] + process.env['VUE_APP_RAZOR_PROD'] + razorPayURLElements[1] + process.env['VUE_APP_FE_PROD'] + razorPayURLElements[2]);
    case 'razorpayConnectUrl_EU': return (razorPayURLElements[0] + process.env['VUE_APP_RAZOR_EU'] + razorPayURLElements[1] + process.env['VUE_APP_FE_EU'] + razorPayURLElements[2]);
    case 'razorpayConnectUrl_EU_PROD': return (razorPayURLElements[0] + process.env['VUE_APP_RAZOR_EU_PROD'] + razorPayURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + razorPayURLElements[2]);
    case 'razorpayConnectUrl_ASIA_STAG': return (razorPayURLElements[0] + process.env['VUE_APP_RAZOR_ASIA_STAG'] + razorPayURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + razorPayURLElements[2]);

    case 'stripeConnectUrl': return (stripeURLElements[0] + process.env['VUE_APP_STRIPE'] + stripeURLElements[1] + process.env['VUE_APP_FE'] + stripeURLElements[2]);
    case 'stripeConnectUrl_PROD': return (stripeURLElements[0] + process.env['VUE_APP_STRIPE_PROD'] + stripeURLElements[1] + process.env['VUE_APP_FE_PROD'] + stripeURLElements[2]);
    case 'stripeConnectUrl_EU': return (stripeURLElements[0] + process.env['VUE_APP_STRIPE_EU'] + stripeURLElements[1] + process.env['VUE_APP_FE_EU'] + stripeURLElements[2]);
    case 'stripeConnectUrl_EU_PROD': return (stripeURLElements[0] + process.env['VUE_APP_STRIPE_EU_PROD'] + stripeURLElements[1] + process.env['VUE_APP_FE_EU_PROD'] + stripeURLElements[2]);
    case 'stripeConnectUrl_ASIA_STAG': return (stripeURLElements[0] + process.env['VUE_APP_STRIPE_ASIA_STAG'] + stripeURLElements[1] + process.env['VUE_APP_FE_ASIA_STAG'] + stripeURLElements[2]);

    case 'stripeIndiaConnectUrl': return (stripeIndiaURLElements[0] + process.env['VUE_APP_STRIPE_IND'] + stripeIndiaURLElements[1]);
    case 'stripeIndiaConnectUrl_PROD': return (stripeIndiaURLElements[0] + process.env['VUE_APP_STRIPE_IND_PROD'] + stripeIndiaURLElements[1]);
    case 'stripeIndiaConnectUrl_EU': return (stripeIndiaURLElements[0] + process.env['VUE_APP_STRIPE_IND_EU'] + stripeIndiaURLElements[1]);
    case 'stripeIndiaConnectUrl_EU_PROD': return (stripeIndiaURLElements[0] + process.env['VUE_APP_STRIPE_IND_EU_PROD'] + stripeIndiaURLElements[1]);
    case 'stripeIndiaConnectUrl_ASIA_STAG': return (stripeIndiaURLElements[0] + process.env['VUE_APP_STRIPE_IND_ASIA_STAG'] + stripeIndiaURLElements[1]);

    default: return process.env[variable];
  }
}