<template>
  <master-layout pageTitle="Survey">
    <ion-loading
  v-if="loading"
  :is-open="loading"
  cssClass="my-custom-class"
  message="Loading..."
  ></ion-loading>
    <ion-content v-else>
      <iframe id="googleDataStudioAnalyticsIframe" style="width: 100%; height: calc(100vh - 56px); border: none;" :src="iframeUrl" title="Analytics" allowfullscreen></iframe>
    </ion-content>
  </master-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      iframeUrl: ""
    }
  },
  ionViewWillEnter(){
    this.Get_iframeUrl();
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    }),
  },
  methods: {
    Get_iframeUrl() {
      this.loading = false;

      if(this.user.enterprise_Name == 'greenwich_ph'){
        this.iframeUrl = "https://datastudio.google.com/embed/reporting/d79d34fd-9dc1-4dd1-9aa5-3ddd4d96b624/page/gPOaB";
      }
    }
  }
}
</script>