<template>
<master-layout pageTitle="Whatsapp message templates">
	 <ion-content class="whatsApp-message-templates">
		<ion-loading
		v-if="loading"
		:is-open="loading"
		cssClass="my-custom-class"
		message="Loading..."
		></ion-loading>
		<div v-else>
			<div v-if="whatsappConnected">
			<ion-toolbar class="ion-hide-md-up toolbar">
        <ion-segment scrollable color="dark" v-model="activeTab">
          <ion-segment-button value="message">
            <ion-label class="common-label">Message Template</ion-label>
          </ion-segment-button>
          <ion-segment-button value="preview">
            <ion-label class="common-label">Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
	  	</ion-toolbar>
      <div v-if="activeTab === 'message'">
        <h2 class="title ion-no-margin">Design your message templates</h2>
      </div>
        <ion-grid>
          <ion-row>
            <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
              <div v-if="activeTab === 'message'">
              <ion-row>
                <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size-xs="12">
                  <ion-item lines="none" class="border">
                    <ion-input v-model="dummyTemplate.template_name" placeholder="Enter Name" :disabled="thisTemplate !== undefined"></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size-xs="12">
                  <ion-item lines="none" class="border">
                    <ion-label>Category</ion-label>
                    <ion-select v-model="dummyTemplate.category" :disabled="thisTemplate !== undefined" class="slct-option">
                      <ion-select-option :value="undefined">Select Category</ion-select-option>
                      <ion-select-option v-for="thisCategory in availableCategories" :key="thisCategory.value" :value="thisCategory.value">{{ thisCategory.name }}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>
                <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size-xs="12">
                  <ion-item lines="none" class="border">
                    <ion-label>Language</ion-label>
                    <ion-select v-model="dummyTemplate.language"  :disabled="thisTemplate !== undefined" class="slct-option">
                      <ion-select-option :value="undefined">Select Language</ion-select-option>
                      <ion-select-option v-for="thisLang in localeFullFormData_whatsapp.localeFullFormData_whatsapp" :key="thisLang.id" :value="thisLang.id">{{ thisLang.name }}</ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>
              </ion-row>
              <div v-if="dummyTemplate.template_name && dummyTemplate.category && dummyTemplate.language">
                <ion-card class="border">
                  <div class="card-content">
                    <div class="header--cont">
                      <div class="custom_mrg_bottom">
                        <ion-card-title class="orderDesc">{{ dummyTemplate.language }} Message Content</ion-card-title>
                      </div>
                      <ion-card-title class="orderDesc"> {{dummyTemplate.status}}</ion-card-title>
                    </div>
                    <ion-card-header>
                      <ion-card-title class="default_txt">Header (optional)</ion-card-title>
                      <ion-card-subtitle>Add a title or choose which type of media you'll use for this header</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                      <ion-grid class="ion-no-padding">
                        <ion-row class="ion-no-padding"> 
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                            <ion-item class="slctOptions" lines="none">
                              <h4 class="sub-title">Type</h4>
                              <ion-select v-model="body.header.format" :disabled="thisTemplate !== undefined"  class="slctContainer">
                                <ion-select-option v-for="thisType in availableHeaderTypes" :key="thisType.id" :value="thisType.id">{{ thisType.name }}</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" v-if="body.header.format === 'TEXT'">
                              <ion-input v-model="body.header.text"  :disabled="thisTemplate !== undefined" placeholder="Enter header Text" class="fullBordered input_custom_style sctn-input"></ion-input>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" v-if="body.header.format === 'IMAGE'">
                            <ion-item lines="none">
                              <ion-label>Sample Image</ion-label>
                              <input type="file" style="display: none;" id="headerImage" ref="headerImage" @input="handleUpload" accept="image/png, image/jpeg, image/jpg, image/gif" />
                              <ion-button @click="() => $refs.headerImage.click()" expand="block" fill="outline"  :disabled="thisTemplate !== undefined">Upload</ion-button>
                            </ion-item>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" v-if="body.header.format === 'VIDEO'">
                            <ion-item lines="none">
                              <ion-label>Sample Video</ion-label>
                              <input type="file" style="display: none;" id="headerVideo" ref="headerVideo" @input="handleUpload" accept="video/mp4, video/mpeg4" />
                              <ion-button @click="() => $refs.headerVideo.click()" expand="block" fill="outline"  :disabled="thisTemplate !== undefined">Upload</ion-button>
                            </ion-item>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" v-if="body.header.format === 'DOCUMENT'">
                            <ion-item lines="none">
                              <ion-label>Sample File</ion-label>
                              <input type="file" style="display: none;" id="headerDocument" ref="headerDocument" @input="handleUpload" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, .csv" />
                              <ion-button @click="() => $refs.headerDocument.click()" expand="block" fill="outline"  :disabled="thisTemplate !== undefined">Upload</ion-button>
                            </ion-item>
                          </ion-col>
                        </ion-row>
                      </ion-grid>   
                    </ion-card-content>
                  </div>
                  <div class="card-content">
                    <ion-row>
                      <ion-col size-xl="8" size-lg="8" size-md="10" size-sm="12" size-xs="12">
                        <ion-card-header>
                          <ion-card-title class="default_txt">Body</ion-card-title>
                          <ion-card-subtitle>Enter the text for your message in the language you've selected</ion-card-subtitle>
                        </ion-card-header>
                        <ion-card-content>
                        <my-input
                          textarea
                          id="template_body"
                          class="templateInput"
                          emoji
                          name="template_body"
                          :value="body.body.text"
                          @onInput="(prop) => body.body.text = prop"
                          placeholder="Type a message"
                          :maxlength="500"
                          :rows="4"
                          insert
                          @onInsert="() => openInsertParamSheet = true"
                          :textAreaAutoHeight="true"
                          :textAreaResizable="false"
                          :animateEmojiContainer="true"
                          :iconStyle="{color: 'var(--ion-color-step-300)'}"
                          :disabled="thisTemplate !== undefined"
                          />
                          <ion-button @click="() => {multipleButtons = 'single'; openPopper = 'simple'; popperConfig = singleButton}" class="bottomRoundedBorder templateAddButton ion-no-margin" expand="block" fill="outline" :style="{ opacity: multipleButtons !== 'none' && multipleButtons !== 'single' ? '0.5' : '1' }" :disabled="thisTemplate !== undefined"> {{singleButton.text}} </ion-button>
                          <div class="floatCenter">
                            <span v-for="(thisButton, bIndex) in allButtons" :key="bIndex">
                              <ion-chip class="chip" :disabled="thisTemplate !== undefined">
                                <ion-label class="chip-label"  :disabled="thisTemplate !== undefined" @click="() => {multipleButtons = 'multiple'; openPopper = 'detailed'; popperIndex = bIndex; popperConfig = allButtons[bIndex]}">{{ thisButton.text }}</ion-label>
                                <ion-icon :icon="closeOutline" @click="() => allButtons.splice(bIndex, 1)"></ion-icon>
                              </ion-chip>
                            </span>
                            <span v-if="allButtons.length < 3">
                              <ion-chip class="chip-button" :disabled="thisTemplate !== undefined"  @click="() => allButtons.length < 3 ? allButtons.push({ text: 'Button', type: undefined, payload: undefined }) : ''">
                                <ion-label class="chip-label" :disabled="thisTemplate !== undefined">+ Add New</ion-label>
                              </ion-chip>
                            </span>
                          </div>
                        </ion-card-content>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-card>
                <ion-card>
                  <div class="card-content">
                    <ion-card-header>
                      <ion-card-title class="default_txt">Footer (optional)</ion-card-title>
                      <ion-card-subtitle>Add a short line or text to the button of your message template</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-input v-model="body.footer" :disabled="thisTemplate !== undefined" placeholder="Enter text" class="fullBordered input_custom_style sctn-input"></ion-input>
                      <ion-toolbar>
                        <ion-buttons slot="end">
                          <ion-button :disabled="thisTemplate !== undefined" @click="saveTemplate" color="dark" fill="solid" class="border">Save</ion-button>
                        </ion-buttons>
                      </ion-toolbar>
                    </ion-card-content>
                  </div>
                </ion-card>
              </div>
              </div>
            </ion-col>
            <!-- preview -->
            <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
              <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
                <i-phone-preview :channelName="salesChannel">
									<div class="iphone-screen">
										<img src="https://jumperdevnew.appspot.com/web-s/resources/images/iphone-statusbar.png" style="height: auto; width: 100%; margin-top: -8px;">
										<div class="chat">
											<div class="chat-container">
												<div class="user-bar">
													<div class="back">
														<ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
													</div>
													<div class="avatar">
														<img src="https://jumperdevnew.appspot.com/web-s/resources/images/shop-image-placeholder.png" alt="Avatar">
													</div>
													<div class="name">
														<span>Brand name</span>
														<span class="status">online</span>
													</div>
													<div class="actions more">
														<ion-icon size="small" :icon="ellipsisVerticalOutline"></ion-icon>
													</div>
													<div class="actions attachment">
														<ion-icon size="small" :icon="attachOutline"></ion-icon>
													</div>
													<div class="actions">
														<ion-icon size="small" :icon="callOutline"></ion-icon>
													</div>
												</div>
												<div class="conversation">
													<div class="conversation-container">
														<div class="message--cont received">
															<span id="random">
																<div class="img--cont" v-if="body.header.format === 'IMAGE'">
																		<img height="50" src="https://jumperdevnew.appspot.com/web-s/resources/images/upload-image-placeholder-dotted.svg">
																</div>
																<div class="img--cont" v-if="body.header.format === 'VIDEO'">
																		<img height="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PDb-r4KDA">
																</div>
																<div class="img--cont" v-if="body.header.format === 'DOCUMENT'">
																		<img height="50" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4MCSnukKDA" alt="">
																</div>
																<p class="mb-0" v-if="body.header.format === 'TEXT'">
																	<strong>{{ body.header.text }}</strong>
																</p>
																<br/>
																<div>
																	{{ body.body.text}}
																</div>
															</span>	
															<span class="metadata" style="float: left; padding-left: 2px;">
																<span class="time">{{ body.footer }}</span>
															</span>
															<span class="metadata">
																<span class="time">10:10 PM</span>
															</span>
															<div class="btn--cont" v-if="allButtons.length">
																<div class="btn--forWhatsapp" v-for="(thisButton, bIndex) in allButtons" :key="bIndex">
																		<span>{{ thisButton.text}}</span>
																</div>
															</div>
														</div>
													</div>
													<div class="conversation-compose">
														<div class="emoji">
															<ion-icon size="small" :icon="happyOutline"></ion-icon>
														</div>
														<input class="input-msg" name="input" placeholder="Type a message" autocomplete="off" autofocus="">
														<div class="photo">
															<ion-icon size="small" :icon="cameraOutline"></ion-icon>
														</div>
														<button class="send">
															<div class="circle">
																<ion-icon :icon="sendOutline"></ion-icon>
															</div>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
                </i-phone-preview>
              </div>
            </ion-col>			
          </ion-row>
        </ion-grid>
			</div>
			<div v-else>
				<page-access-not-allowed></page-access-not-allowed>
			</div>
			<ion-action-sheet
				:is-open="openInsertParamSheet"
				header="Insert Parameter"
				:buttons="paramsOptions"
				@didDismiss="() => openInsertParamSheet = false"
			>
			</ion-action-sheet>
			<ion-modal
				v-if="addCustomField"
				:is-open="addCustomField"
				@didDismiss="() => addCustomField = false"
			>
				<ion-page>
					<ion-header>
						<ion-toolbar>
							<h2 class="ion-no-margin">Add Custom Field</h2>
							<ion-buttons slot="end">
								<ion-button @click="() => addCustomField = false">
									<ion-icon slot="icon-only" :icon="close"></ion-icon>
								</ion-button>
							</ion-buttons>
						</ion-toolbar>
					</ion-header>
					<ion-content>
						<ion-item>
							<ion-label>Custom Field</ion-label>
							<ion-input v-model="customFieldText" placeholder="Enter..."></ion-input>
						</ion-item>
					</ion-content>
					<ion-footer>
						<ion-toolbar class="fxd_btm_block">
							<ion-button @click="confirmAddCustomField" color="primary">Done</ion-button>
						</ion-toolbar>
					</ion-footer>
				</ion-page>
			</ion-modal>
			<popper :type="openPopper" :config="popperConfig" @close="() => {openPopper = undefined; popperIndex = -1;}" @submit="popperSubmit" />
		</div>
	</ion-content>
</master-layout>
</template>

<script>
import '/src/resources/css/sales-channel.css';

import { IonSelect, IonSelectOption, /* IonRadioGroup, IonRadio */ } from "@ionic/vue";
import { addCircleOutline, trashBinOutline, eyeOutline, trashOutline, closeOutline,arrowBackOutline,ellipsisVerticalOutline,attachOutline,callOutline,cameraOutline, happyOutline, sendOutline } from 'ionicons/icons';
import myInput from '/src/components/myInput';
import popper from '/src/components/popper';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import iPhonePreview from '/src/components/iPhonePreview';


export default {
	components: {
		IonSelect,
		IonSelectOption,
		/* IonRadioGroup,
		IonRadio, */
		myInput,
		popper,
		iPhonePreview
	},
  data() {
    return {
			addCircleOutline,
			trashBinOutline,
			eyeOutline,
			trashOutline,
			closeOutline,
			arrowBackOutline,
			ellipsisVerticalOutline,
			cameraOutline,
			attachOutline,
			sendOutline,
			callOutline,
			happyOutline,
			loading: false,
			openPopper: undefined,
			activeTab: 'message',
			popperIndex: -1,
			thisTemplate: undefined,
			salesChannel:'whatsapp',
			availableCategories: [
        {
					name: "Account Update",
					value: "ACCOUNT_UPDATE"
        },
        {
					name: "Alert Update",
					value: "ALERT_UPDATE"
        },
        {
					name: "Appointment Update",
					value: "APPOINTMENT_UPDATE"
        },
        {
					name: "Auto-Reply",
					value: "AUTO_REPLY"
        },
        {
					name: "Issue Resolution",
					value: "ISSUE_RESOLUTION"
        },
        {
					name: "Payment Update",
					value: "PAYMENT_UPDATE"
        },
        {
					name: "Reservation Update",
					value: "RESERVATION_UPDATE"
        },
        {
					name: "Shipping Update",
					value: "SHIPPING_UPDATE"
        },
        {
					name: "Ticket Update",
					value: "TICKET_UPDATE"
        }
			],
			availableHeaderTypes: [
				{ name: 'None', id: undefined },
				{ name: 'Text', id: 'TEXT' },
				{ name: 'Image', id: 'IMAGE' },
				{ name: 'Video', id: 'VIDEO' },
				{ name: 'File', id: 'DOCUMENT' },
			],
			dummyTemplate: {
				category: undefined,
				collection_id: undefined,
				id: undefined,
				language: undefined,
				status: undefined,
				template_id: undefined,
				template_name: undefined
			},
			body: {
				header: {
					format: undefined,
					text: undefined,
					example: []
				},
				body: {
					text: undefined,
					params: []
				},
				footer: undefined
			},
			multipleButtons: "none", //possible values: none, single and multiple
			singleButton: { text: 'Button Text', type: undefined, payload: undefined },
			allButtons: [{ text: 'Button', type: undefined, payload: undefined }],
			openInsertParamSheet: false,
			paramsOptions: [
        {
          text: "User's First Name",
          handler: () => {
						this.body.body.text += "{User's First Name}";
						this.body.body.params.push({
							text: "{User's First Name}",
							param: {"<username>":"text"},
							example: "John"
						});
          }
        },
        {
          text: 'Store Name',
          handler: () => {
						this.body.body.text += "{Store Name}";
						this.body.body.params.push({
							text: "{Store Name}",
							param: {"<shopname>":"text"},
							example: "My Store"
						});
          }
        },
        {
          text: 'Agent Name',
          handler: () => {
						this.body.body.text += "{Agent Name}";
						this.body.body.params.push({
							text: "{Agent Name}",
							param: {"<agentname>":"text"},
							example: "Bob Parker"
						});
          }
        },
        {
          text: 'Account Owner Name',
          handler: () => {
						this.body.body.text += "{Account Owner Name}";
						this.body.body.params.push({
							text: "{Account Owner Name}",
							param: {"<sellername>":"text"},
							example: "Steve Braun"
						});
          }
        },
        {
          text: 'Support E-mail',
          handler: () => {
						this.body.body.text += "{Support E-mail}";
						this.body.body.params.push({
							text: "{Support E-mail}",
							param: {"<selleremail>":"text"},
							example: "support@mybrandstore.com"
						});
          }
        },
        {
          text: 'Support Phone',
          handler: () => {
						this.body.body.text += "{Support Phone}";
						this.body.body.params.push({
							text: "{Support Phone}",
							param: {"<sellerphone>":"text"},
							example: "+6590000000"
						});
          }
        },
        {
          text: 'Outlet Address',
          handler: () => {
						this.body.body.text += "{Outlet Address}";
						this.body.body.params.push({
							text: "{Outlet Address}",
							param: {"<menuaddress>":"text"},
							example: "01-02, Evershine Avenue, Beach Road, Singapore - 409902"
						});
          }
        },
        {
          text: 'Custom Field',
          handler: () => this.addCustomField = true
				}
			],
			addCustomField: false,
			popperConfig: {
				text: undefined,
				action: undefined,
				payload: undefined
			}
    }
	},
	ionViewWillEnter() {
		this.$route.params.id ? this.init() : '';
	},
  methods: {
		async init() {
			try {
        this.loading = true;
        let reqBody = {
          id: this.$route.params.id
        }
        let getTemplateResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/message-template/get", reqBody);
        if ((getTemplateResponse.status == 200 || getTemplateResponse.status == 201) && getTemplateResponse.data.success) {
					this.thisTemplate = getTemplateResponse.data.data;
					this.dummyTemplate.category = this.thisTemplate.category;
					this.dummyTemplate.collection_id = this.thisTemplate.collection_id;
					this.dummyTemplate.id = this.thisTemplate.id;
					this.dummyTemplate.language = this.thisTemplate.language;
					this.dummyTemplate.status = this.thisTemplate.status;
					this.dummyTemplate.template_id = this.thisTemplate.template_id;
					this.dummyTemplate.template_name = this.thisTemplate.template_name;
					this.body.body.text = this.thisTemplate.message.BODY.text;
					if(this.thisTemplate.message.BUTTONS && this.thisTemplate.message.BUTTONS.length) {
						if(this.thisTemplate.message.BUTTONS[0].type === 'QUICK_REPLY') {
							this.multipleButtons = 'multiple';
							this.thisTemplate.message.BUTTONS.forEach((thisButton) => {
								this.allButtons.push({
									text: thisButton.text,
									type: thisButton.type,
									payload: thisButton.payload
								})
							})
						} else {
							let thisButton = this.thisTemplate.message.BUTTONS[0];
							this.multipleButtons = 'single';
							this.singleButton.text = thisButton.text;
							thisButton.example && thisButton.example.length && thisButton.example[0].includes('http') ? this.singleButton.type = 'url' : this.singleButton.type = 'tel';
							this.singleButton.payload = thisButton.url;
						}
					}
					if(this.thisTemplate.message_params.BODY && this.thisTemplate.message_params.BODY.length) {
						this.thisTemplate.message_params.BODY.forEach((thisParam, paramIndex) => {
							let param = Object.keys(thisParam)[0];
							let pIndex = paramIndex + 1;
							switch(param) {
								case '<username>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', "{User's First Name}");
									this.body.body.params.push({
										text: "{User's First Name}",
										param: {"<username>":"text"},
										example: "John"
									});
								} break;
								case '<shopname>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Store Name}');
									this.body.body.params.push({
										text: "{Store Name}",
										param: {"<shopname>":"text"},
										example: "My Store"
									});
								} break;
								case '<agentname>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Agent Name}');
									this.body.body.params.push({
										text: "{Agent Name}",
										param: {"<agentname>":"text"},
										example: "Bob Parker"
									});
								} break;
								case '<sellername>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Account Owner Name}');
									this.body.body.params.push({
										text: "{Account Owner Name}",
										param: {"<sellername>":"text"},
										example: "Steve Braun"
									});
								} break;
								case '<selleremail>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Support E-mail}');
									this.body.body.params.push({
										text: "{Support E-mail}",
										param: {"<selleremail>":"text"},
										example: "support@mybrandstore.com"
									});
								} break;
								case '<sellerphone>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Support Phone}');
									this.body.body.params.push({
										text: "{Support Phone}",
										param: {"<sellerphone>":"text"},
										example: "+6590000000"
									});
								} break;
								case '<menuaddress>': {
									this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', '{Outlet Address}');
									this.body.body.params.push({
										text: "{Outlet Address}",
										param: {"<menuaddress>":"text"},
										example: "01-02, Evershine Avenue, Beach Road, Singapore - 409902"
									});
								} break;
								default: {
									if(param.includes('<custom_field_')) {
										let customFieldText = param.replace("<custom_field_", "");
										customFieldText = customFieldText.replace(">", "");
										this.body.body.text = this.body.body.text.replace('{{'+pIndex+'}}', "{custom_field_" + customFieldText + "}");
										let paramName = "<custom_field_" + customFieldText + ">";
										let obj = {};
										obj[paramName] = "text";
										this.body.body.params.push({
											text: "{custom_field_" + customFieldText + "}",
											param: obj,
											example: "Custom field"
										});
									}
								}
							}
						})
					}
					this.body.header.format = this.thisTemplate.message.HEADER ? this.thisTemplate.message.HEADER.format : undefined;
					this.body.header.format === 'TEXT' ? this.body.header.text = this.thisTemplate.message.HEADER.text : '';
					this.body.footer = this.thisTemplate.message.FOOTER ? this.thisTemplate.message.FOOTER.text : undefined;
        } else {
          if(getTemplateResponse.data && !getTemplateResponse.data.success && getTemplateResponse.data.errorMessage) {
            this.updateToast({
              open: true,
              message: getTemplateResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
            //console.log("Response:", getTemplateResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
		},
		async handleUpload(event) {
      try {
        var fileToSend = event.target.files[0];
				switch(this.body.header.format) {
					case 'IMAGE': {
						if(await this.validateImage(fileToSend, 4)) {
							let uploadSendResponseData = await this.uploadImage(fileToSend, '/api/facebook-upload-file');
							this.body.header.example = [uploadSendResponseData.data.h];
						} else {
							this.updateToast({
								open: true,
								message: "Sorry! We couldn't read the uploaded file. Please upload a valid video",
								type: "danger"
							})
						}
					}
					break;
					case 'VIDEO': {
						if(await this.validateVideo(fileToSend, 10)) {
							let uploadSendResponseData = await this.uploadVideo(fileToSend, '/api/facebook-upload-file');
							this.body.header.example = [uploadSendResponseData.data.h];
						} else {
							this.updateToast({
								open: true,
								message: "Sorry! We couldn't read the uploaded file. Please upload a valid video",
								type: "danger"
							})
						}
					}
					break;
					case 'DOCUMENT': {
						if(await this.validateDocument(fileToSend, 10)) {
							let uploadSendResponseData = await this.uploadDocument(fileToSend, '/api/facebook-upload-file');
							this.body.header.example = [uploadSendResponseData.data.h];
						} else {
							this.updateToast({
								open: true,
								message: "Sorry! We couldn't read the uploaded file. Please upload a valid document",
								type: "danger"
							})
						}
					}
					break;
					default: this.updateToast({
						open: true,
						message: "Something went wrong",
						type: "danger"
					})
				}
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
		},
		confirmAddCustomField() {
			if(this.customFieldText && this.customFieldText.length) {
				this.body.body.text += "{custom_field_" + this.customFieldText + "}";
				let paramName = "<custom_field_" + this.customFieldText + ">";
				let obj = {};
				obj[paramName] = "text";
				this.body.body.params.push({
					text: "{custom_field_" + this.customFieldText + "}",
					param: obj,
					example: "Custom field"
				});
				this.addCustomField = false;
				this.customFieldText = undefined;
			} else {
				this.updateToast({
					open: true,
					message: "Custom Field can not be empty",
					type: "danger"
				})
			}
		},
		popperSubmit(data) {
			console.log(data);
			if(this.multipleButtons === 'single') {this.singleButton.text = data.text; this.singleButton.type = data.action; this.singleButton.payload = data.payload;}
			if(this.multipleButtons === 'multiple' && this.popperIndex > -1) {
				this.allButtons[this.popperIndex].text = data.text;
				this.allButtons[this.popperIndex].type = 'QUICK_REPLY';
				this.allButtons[this.popperIndex].payload = data.payload;
			}
			this.openPopper = undefined;
			this.popperIndex = -1;
		},
		async saveTemplate() {
			console.log(this.dummyTemplate);
			console.log(this.body);
			console.log(this.singleButton);
			console.log(this.allButtons);
			if(!this.dummyTemplate.category || !this.dummyTemplate.language || !this.dummyTemplate.template_name) {
				this.updateToast({
          open: true,
          message: "Please enter required details",
          type: "danger"
				})
				return;
			}
			try {
				let reqBody = {
					category: this.dummyTemplate.category,
					language: this.dummyTemplate.language,
					template_name: this.dummyTemplate.template_name,
					message: {
						"BODY": {"text": this.body.body.text},
						"BUTTONS": []
					},
					message_params: this.body.body.params && this.body.body.params.length ? JSON.stringify(this.body.body.params) : undefined
				}
				if(this.multipleButtons === 'single') reqBody.message.BUTTONS = [this.singleButton];
				if(this.multipleButtons === 'multiple') reqBody.message.BUTTONS = JSON.stringify(this.allButtons);
				if(!reqBody.message.BODY.text || reqBody.message.BODY.text.length === 0) {
					this.updateToast({
						open: true,
						message: "Body text is required",
						type: "danger"
					})
					return;
				}
				console.log(reqBody);

				let saveTemplateResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "whatsapp/message-template", reqBody);
				if ((saveTemplateResponse.status == 200 || saveTemplateResponse.status == 201) && saveTemplateResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Template Added Successfully!",
            type: "success"
					});
					this.$router.push({ path: '/sales-channel/whatsapp/message-templates' });
        }
        else {
          if(saveTemplateResponse.data.errorMessage && saveTemplateResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveTemplateResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
			} catch (error) {
				console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
			}
		}
  }
}
</script>

<style scoped>

.header--cont{
	display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
	padding: 16px 16px 0px 16px;
}
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
  margin: 10px 0px;
}

.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
	padding: 10px;
}
.slctOptions {
  position: sticky; 
  top: 0; 
  background-color: var(--ion-color-white);
	padding-left: 0px;
}
ion-chip.chip {
  --background: var(--ion-color-white);
  border: 1px solid var(--ion-color-primary);
  width: 125px;
  padding: 0px 28px;
}

ion-chip.chip-button {
	--background: var(--ion-color-white);
	border: 1px dashed var(--ion-color-primary);
}
.chip-label {
  color: var(--ion-color-primary);
}

.border {
	border-radius: 5px;
}
.sub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-grey);
	margin-right: 10px;
}
.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}
.sctn-input {
  border-radius: 5px;
	padding: 10px;
}

ion-select.slct-option{
	max-width: 66%;
}
.card-content {
	border-bottom: 1px solid #eee;
}
.jinput {
  margin: 0;
  width: 100%;
  border-radius: 10px;
	padding: 0px;
}

.user-bar {
	height: 55px;
	background: #005e54;
	color: #fff;
	padding: 0 8px;
	font-size: 24px;
	position: relative;
	z-index: 1;
}
.user-bar div{
	float: left;
	transform: translateY(-50%);
	position: relative;
	top: 50%;
}
.user-bar .avatar{
	margin: 0 0 0 5px;
	width: 36px;
	height: 36px;
}
.user-bar .avatar img {
	border-radius: 50%;
	box-shadow: 0 1px 0 rgb(255 255 255 / 10%);
	display: block;
	width: 100%;
}
.user-bar .name {
	font-size: 13px;
	font-weight: 600;
	text-overflow: ellipsis;
	letter-spacing: 0.3px;
	text-align: left;
	margin: 0px 0px 0 7px;
	overflow: hidden;
	white-space: nowrap;
	width: 80px;
}
.user-bar .status {
	display: block;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0;
}
.user-bar .actions{
	float: right;
	margin: 0;
}
.user-bar .actions .more{
	margin: 0 12px 0 32px;
}
.user-bar .actions .attachment {
	margin: 0px -10px 0px 20px;
}
ion-button.templateAddButton {
	margin-top: 4px;
}
.user-bar ::after{
	content: "";
	display: table;
	clear: both;
}
.iphone-screen{
	background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
	background-color: #cccccc;
	height: 463px;
	width: 260px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.chat {
	height:calc(100% - 69px);
	position: relative;
	top: -6px;
}
.chat-container{
	height: 100%;
}
.conversation{
	height: calc(100% - 12px);
	position: relative;
	background: #efe7dd url(https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg) repeat;
	z-index: 0;
}
.conversation .conversation-container {
	height: calc(100% - 48px);
	overflow-x: hidden;
	padding: 0 16px;
	margin-bottom: 5px;
}
.conversation .message--cont{
	color: #000;
	clear: both;
	line-height: 14px;
	font-size: 13px;
	padding: 8px;
	width: 100%;
	border: none;
	padding: 0;
	text-align: left;
	position: relative;
	margin: 8px 0;
	max-width: 85%;
	word-wrap: break-word;
	z-index: -1;
}
.conversation .received{
	background: #fff;
	border-radius: 0px 5px 5px 5px;
	float: left;
}
.conversation-container .message--cont{
	color: #000;
	clear: both;
	line-height: 14px;
	font-size: 13px;
	padding: 8px;
	width: 100%;
	border: none;
	text-align: left;
	position: relative;
	margin: 8px 0;
	word-wrap: break-word;
	z-index: -1;
}
.conversation-container .message--cont:first-child{
	margin: 16px 0 8px;
}
.metadata{
	display: inline-block;
	padding: 0 0 0 7px;
	position: relative;
	bottom: -4px;
}
.message--cont.received .metadata{
	padding: 0 0 0 16px;
  margin-top: 10px;
}
.metadata .time {
	color: rgba(0, 0, 0, .45);
	font-size: 11px;
	display: inline-block;
}
.conversation-container .btn--cont{
	width: 100%;
	float: left;
	margin-top: 10px;
	border-top: 1px solid #eee;
	text-align: center;
}
.conversation-container .btn--cont .btn--forWhatsapp{
	background: #fff;
	font-size: 13px;
	padding: 6px 0;
	color: #049CE5;
}
.message--cont.received:after{
	border-width: 0px 10px 10px 0;
	border-color: transparent #fff transparent transparent;
	top: 0;
	left: -10px;
}
.message--cont:after{
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
}
.message--cont .img--cont{
	text-align: center;
	background: #cccfd4;
	padding: 20px 10px;
}
.conversation-compose{
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	overflow: hidden;
	height: 40px;
	width: 100%;
	z-index: 2;
}
.conversation-compose .emoji{
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	border-radius: 5px 0 0 5px;
	flex: 0 0 auto;
	margin-left: 8px;
	width: 40px;
}
.conversation-compose div, .conversation-compose input{
	background: #fff;
  height: 100%;
}
.conversation-compose .input-msg{
	border: 0;
	flex: 1 1 auto;
	font-size: 15px;
	margin: 0;
	outline: none;
	min-width: 50px;
}
.conversation-compose .photo{
	flex: 0 0 auto;
	border-radius: 0 0 5px 0;
	text-align: center;
	position: relative;
	width: 40px;
	padding-top: 10px;
}
.conversation-compose .send{
	background: transparent;
	border: 0;
	cursor: pointer;
	flex: 0 0 auto;
	margin-left: 8px;
	margin-right: 8px;
	padding: 0;
	position: relative;
	outline: none;
}
.conversation-compose .send .circle{
	background: #008a7c;
	border-radius: 50%;
	color: #fff;
	position: relative;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media only screen and (max-width: 760px) {
  .prevMobile {
		display: block;
	}
  .prevDesktop {
    display: none;
  }
}
</style>