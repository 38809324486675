<template>
  <div class="segments-module-wrappers ion-no-padding">
    <ion-grid class="ion-no-padding" style="margin: 20px 0px 20px 0px">
      <ion-row>
        <ion-col size-xl="4" size-lg="4" size-sm="12" size-xs="12" class="ion-no-padding ion-align-self-center">
          <ion-label class="orderSubtitle">Send message to people who match</ion-label>
        </ion-col>
        <ion-col size-xl="8" size-lg="8" size-sm="12" size-xs="12" class="ion-no-padding">
          <ion-radio-group v-model="filterConditions">
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-justify-content-between">
                <ion-col class="ion-no-padding ion-align-self-end">
                  <ion-item lines="none">
                    <h2 class="ion-no-padding orderDesc ion-no-margin">all the following Conditions</h2>
                    <ion-radio slot="start" value="and" class="radio-bttns"></ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col class="ion-no-padding">
                  <ion-item lines="none">
                    <h2 class="ion-no-padding orderDesc ion-no-margin">any of the following Conditions</h2>
                    <ion-radio slot="start" value="or" class="radio-bttns"></ion-radio>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-radio-group>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div v-if="conditions && conditions.length">
      <div class="segment-list-wrapper">
        <div v-for="(thisCondition, cIndex) in conditions" :key="cIndex" class="segment-row">
          <div size="2" class="segment-name">
            <span>{{ thisCondition.condition_type }}</span>
          </div>

          <template
            v-if="thisCondition.condition_type == CONDITION_TYPE.INTERACTION_DATE || thisCondition.condition_type == CONDITION_TYPE.INTERACTION_TIME">
            <div class="segment-condition">
              <span> {{ thisCondition.data_filter }} </span>
            </div>
          </template>

          <template
            v-else-if="[CONDITION_TYPE.INTERACTION_CHANNEL, CONDITION_TYPE.INTERACTION_CHANNEL_PAGE_ID , CONDITION_TYPE.INTERACTED_WITH_BOT, CONDITION_TYPE.GENDER].includes(thisCondition.condition_type)">
            <div class="segment-condition">
              <span> {{ thisCondition.static }} </span>
            </div>
          </template>

          <template
            v-else-if="[CONDITION_TYPE.NAME, CONDITION_TYPE.EMAIL, CONDITION_TYPE.NUMBER, CONDITION_TYPE.CUSTOM_FIELD].includes(thisCondition.condition_type)">
            <div class="segment-condition" :class="{ 'no-condition-value': !thisCondition.value }">
              <span> {{ thisCondition.data_filter }} </span>
            </div>
          </template>

          <div v-else-if="showOperator(thisCondition)">
            <span v-if="thisCondition.operator == '='"> is </span>
            <span v-if="thisCondition.operator == '!='"> is't </span>
            <span v-if="thisCondition.operator == 'is null' || thisCondition.operator == 'is unknown'"> is unknown</span>
            <span v-if="thisCondition.operator == '<='">is before </span>
            <span v-if="thisCondition.operator == '>='"> is after</span>
          </div>
          <div class="segment-title" v-if="thisCondition.value">
            <span>{{ thisCondition.value || thisCondition.value && thisCondition.value.name || "" }} </span>
          </div>
          <ion-icon v-if="(cIndex != 0 && cIndex != 1) || !!editSegment" @click="removeCondition($event, cIndex)" class="btm-remove-segment" slot="icon-only" :icon="close"></ion-icon>
        </div>
      </div>
    </div>
    <ion-row>
      <ion-col size="12" class="col-btn-add-condition">
        <ion-button @click="addCondtionMode = true" color="light" expand="full" class="uploadBttn">
          <ion-icon :icon="addOutline" style="margin-right: 5px; color: black"></ion-icon>
          Condition
        </ion-button>
      </ion-col>
    </ion-row>
  </div>


  <ion-action-sheet v-if="addCondtionMode" :is-open="addCondtionMode" header="Add Condition"
    :buttons="allConditionOptions" @didDismiss="() => (addCondtionMode = false)" />
  <ion-action-sheet v-if="tagFilter" :is-open="tagFilter" header="Filter By Tag" :buttons="tagFilterOptions"
    @didDismiss="() => (tagFilter = false)" />
  <ion-action-sheet v-if="interactionFilter" :is-open="interactionFilter" header="Filter By Interactions"
    :buttons="interactionFilterOptions" @didDismiss="() => (interactionFilter = false)" />

  <!-- Interaction Date filter modal -->
  <ion-modal v-if="interactionDateFilter" :is-open="interactionDateFilter"
    @didDismiss="() => (interactionDateFilter = false)">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Interaction Date Filter</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => (interactionDateFilter = false)">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Condition Option</ion-label>
          <ion-select v-model="interactionDate.operator">
            <ion-select-option :value="undefined">Select Condition</ion-select-option>
            <ion-select-option v-for="thisOperator in availableFilterOperators" :key="thisOperator.name"
              :value="thisOperator.operator">{{ thisOperator.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label for="interaction-date-input" @click="$refs.openInteractionDateInput.click()">Select Date</ion-label>
          <ion-input @focus="$refs.openInteractionDateInput.click()"
            :value="interactionDate.date ? moment(interactionDate.date).format('DD-MMM-YY') : undefined"
            id="interaction-date-input"></ion-input>
          <ion-button id="openInteractionDateInput" ref="openInteractionDateInput" fill="clear" color="dark">
            <ion-icon slot="start" :icon="calendar"></ion-icon>
          </ion-button>
          <ion-popover side="top" alignment="center" arrow="true" trigger="openInteractionDateInput">
            <ion-datetime display-format="DD/MM/YY" :value="interactionDate.date" ref="interactionDate"
              name="interactionDate"
              @ionChange="(e) => { interactionDate.date = moment( new Date(e.detail.value) ).toISOString() }"
              presentation="date" id="start-date"></ion-datetime>
          </ion-popover>
        </ion-item>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="addInteractionDate" fill="solid" size="small" color="dark">Add Condition</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- Interaction Date time filter modal -->
  <ion-modal v-if="interactionTimeFilter" :is-open="interactionTimeFilter"
    @didDismiss="() => (interactionTimeFilter = false)">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Interaction Time Filter</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => (interactionTimeFilter = false)">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Condition Option</ion-label>
          <ion-select v-model="interactionTime.operator">
            <ion-select-option :value="undefined">Select Condition</ion-select-option>
            <ion-select-option v-for="thisOperator in availableFilterOperators" :key="thisOperator.name"
              :value="thisOperator.operator">{{ thisOperator.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label for="interaction-date-input2" @click="$refs.openInteractionDateInput2.click()">Select Date &amp; Time
          </ion-label>
          <ion-input @focus="$refs.openInteractionDateInput2.click()"
            :value="interactionTime.time ? moment(interactionTime.time).format('YYYY-MM-DD HH:mm:ss') : undefined"
            id="interaction-date-input2"></ion-input>
          <ion-button id="openInteractionDateInput2" ref="openInteractionDateInput2" slot="end" fill="clear">
            <ion-icon slot="start" :icon="calendar"></ion-icon>
          </ion-button>
          <ion-popover slot="end" side="top" trigger="openInteractionDateInput2">
            <ion-datetime display-format="DD/MM/YY hh:mm a" :value="interactionTime.time" ref="interactionDate2"
              name="interactionDate2" @ionChange="(e) => interactionTime.time = e.detail.value" presentation="date-time"
              id="popover-datetime-3"></ion-datetime>
          </ion-popover>
        </ion-item>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="addInteractionTime" fill="solid" size="small" color="dark">Add Condition</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-content>
    </ion-page>
  </ion-modal>
  <ion-action-sheet v-if="agentFilter" :is-open="agentFilter" header="Interacted with Agent" :buttons="agentFilterOptions"
    @didDismiss="() => (agentFilter = false)" />
  <ion-action-sheet v-if="productFilter" :is-open="productFilter" :header="CONDITION_TYPE.INTERACTED_WITH_PRODUCT"
    :buttons="productFilterOptions" @didDismiss="() => (productFilter = false)" />
  <ion-action-sheet v-if="collectionFilter" :is-open="collectionFilter" :header="CONDITION_TYPE.INTERACTED_WITH_CHANNEL"
    :buttons="collectionFilterOptions" @didDismiss="() => (collectionFilter = false)" />
  <ion-action-sheet v-if="botFilter" :is-open="botFilter" :header="CONDITION_TYPE.INTERACTED_WITH_BOT"
    :buttons="botFilterOptions" @didDismiss="() => (botFilter = false)" />
  <ion-action-sheet v-if="channelFilter" :is-open="channelFilter" :header="CONDITION_TYPE.INTERACTION_CHANNEL"
    :buttons="channelFilterOptions" @didDismiss="() => (channelFilter = false)" />
  <ion-action-sheet v-if="personalFilter" :is-open="personalFilter" header="Filter By Interactions"
    :buttons="personalFilterOptions" @didDismiss="() => (personalFilter = false)" />

  <ion-modal v-if="nameFilter || emailFilter || mobileFilter" :is-open="nameFilter || emailFilter || mobileFilter"
    @didDismiss="
          () => {
            nameFilter = false;
            emailFilter = false;
            mobileFilter = false;
          }
        ">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Personal Details</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => { nameFilter = false; emailFilter = false; mobileFilter = false; }">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Condition Option</ion-label>
          <ion-select v-model="personal.operator" @change="onPersonalConditionChange">
            <ion-select-option :value="undefined">Select Condition</ion-select-option>
            <ion-select-option v-for="thisOperator in availableFilterOperations" :key="thisOperator.name"
              :value="thisOperator.operator">
              {{ thisOperator.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div>
          <ion-item v-if="nameFilter">
            <ion-label>Name {{ filterOperatorsMappingReverse[personal.operator] }} </ion-label>
            <ion-input :disabled="isPersonalInputDisabled" v-model="personal.name" placeholder="Enter Full Name">
            </ion-input>
          </ion-item>
          <ion-item v-if="mobileFilter">
            <ion-label>Mobile {{ filterOperatorsMappingReverse[personal.operator] }}
            </ion-label>
            <ion-input :disabled="isPersonalInputDisabled" v-model="personal.mobile" placeholder="Enter Mobile Number">
            </ion-input>
          </ion-item>
          <ion-item v-if="emailFilter">
            <ion-label>Email {{ filterOperatorsMappingReverse[personal.operator] }}</ion-label>
            <ion-input :disabled="isPersonalInputDisabled" v-model="personal.email" placeholder="Enter Email Address">
            </ion-input>
          </ion-item>
        </div>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button :disabled="isPersonalBtnAddConditionDisabled" @click="addPersonal" fill="solid" size="small" color="dark">Add Condition</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-content>
    </ion-page>
  </ion-modal>

  <ion-action-sheet v-if="genderFilter" :is-open="genderFilter" :header="CONDITION_TYPE.INTERACTION_CHANNEL"
    :buttons="genderFilterOptions" @didDismiss="() => (genderFilter = false)" />

  <ion-modal v-if="customFilter" :is-open="customFilter" @didDismiss="
          () => {
            customFilter = false;
          }
        ">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Custom Field</h2>
          <ion-buttons slot="end">
            <ion-button @click="
                    () => {
                      customFilter = false;
                    }
                  ">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Condition Option</ion-label>
          <ion-select v-model="custom.operator">
            <ion-select-option :value="undefined">Select Condition</ion-select-option>
            <ion-select-option v-for="thisOperator in availableFilterOperations" :key="thisOperator.name"
              :value="thisOperator.operator">{{ thisOperator.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <div v-if="
                availableFilterOperations.find(
                  (o) => o.operator == custom.operator
                )
              ">
          <ion-item>
            <ion-label>Custom Filed
              {{
              availableFilterOperations.find(
              (o) => o.operator == custom.operator
              ).name
              }}</ion-label>
            <ion-input v-if="custom.operator !== 'is null'" v-model="custom.field" placeholder="PROPERTY:VALUE">
            </ion-input>
          </ion-item>
        </div>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="addCustom" fill="solid" size="small" color="dark">Add Condition</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-content>
    </ion-page>
  </ion-modal>
</template>

<script>
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, pencilOutline, informationCircleOutline, calendar, arrowBackOutline, documentOutline, addOutline, videocamOutline, searchOutline, trashOutline, close, refreshOutline } from "ionicons/icons";
import { IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonPopover } from "@ionic/vue";
import { mapGetters } from "vuex";

const FIELD_TYPE = {
  NONE: "none",
  DD: "dropdown",
  DWF: "date_with_filter",
  DTWF: "datetime_with_filter",
  DDWF: "dropdown_with_filter",
  DDWS: "dropdown_with_static",
  TBWF: "textbox_with_filter"
};

const CONDITION_TYPE = {
  TAGS: 'Tags',
  HAS_ITEM_IN_CART_AND_NOT_PAID: 'Has Item in Cart and not paid',
  TALKED_WITH_AGENT: 'Talked with Agent',
  INTERACTED_WITH_PRODUCT: 'Interacted with Product',
  INTERACTED_WITH_CHANNEL: 'Interacted with Channel',
  INTERACTION_DATE: 'Interaction Date',
  INTERACTION_TIME: 'Interaction Time',
  INTERACTED_WITH_BOT: 'Interacted with Bot',
  INTERACTION_CHANNEL: 'Interaction Channel',
  NAME: 'Name',
  EMAIL: 'Email',
  NUMBER: 'Number',
  GENDER: 'Gender',
  CUSTOM_FIELD: 'Custom field',
  INTERACTION_CHANNEL_PAGE_ID: 'Interaction Channel Page ID'
}

const channels = [
  {
    name: "Messenger",
    id: "facebook",
    img: "/landing/resources/images/messenger.png",
    selected: true,
    supported_templates: "all",
    active: true
  },
  {
    name: "WhatsApp",
    id: "whatsapp",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/whatsapp.png",
    selected: false,
    supported_templates: "all",
    active: true
  },
  {
    name: "iMessage",
    id: "imessage",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/iMessage.png",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: false
  },
  {
    name: "LINE",
    id: "line",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/LINE.png",
    selected: false,
    supported_templates: "all",
    active: true
  },
  {
    name: "Google Business Messages",
    id: "googlebusinessmsg",
    img: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA0Irc-7ALDA",
    selected: false,
    supported_templates: "all",
    active: true
  },
  {
    name: "Telegram",
    id: "telegram",
    img: "https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: true
  },
  {
    name: "SMS",
    id: "sms",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg",
    selected: false,
    supported_templates: "buttonreply",
    active: true
  },
  {
    name: "Viber",
    id: "viber",
    img: "/landing/resources/images/viber.png",
    selected: false,
    supported_templates: "all",
    active: true
  },
  {
    name: "Twitter",
    id: "twitter",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/Twitter.png",
    selected: false,
    supported_templates: "buttonreply, cancelled, videoreply, quickreplies",
    active: false
  },
  {
    name: "Web",
    id: "jumper",
    img: "https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/ecommerce-website.png",
    selected: false,
    supported_templates: "all",
    active: false
  }
];

const filterOperatorsMapping = {
  on: "=",
  before: "<=",
  after: ">=",
  "is unknown": "is null"
};

const filterOperatorsMappingReverse = {
  "=": "is",
  "<=": "before",
  ">=": "after",
  "!=": "isn't",
  "is null": "is unknown"
};

export default {
  components: {
    IonSelectOption,
    IonSelect,
    IonRadio,
    IonRadioGroup,
    IonPopover
  },
  data() {
    return {
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      arrowBackOutline,
      documentOutline,
      videocamOutline,
      pencilOutline,
      addOutline,
      searchOutline,
      trashOutline,
      informationCircleOutline,
      calendar,
      close,
      loading: false,
      allTags: [],
      allAgents: [],
      allProducts: [],
      allCollections: [],
      allBots: [],
      filterConditions: "and", //or,
      addCondtionMode: false,
      tagFilter: false,
      interactionFilter: false,
      interactionDateFilter: false,
      interactionDate: { operator: undefined, date: undefined },
      interactionTimeFilter: false,
      interactionTime: { operator: undefined, time: undefined },
      agentFilter: false,
      productFilter: false,
      collectionFilter: false,
      botFilter: false,
      channelFilter: false,
      personalFilter: false,
      nameFilter: false,
      emailFilter: false,
      mobileFilter: false,
      personal: {
        name: undefined,
        email: undefined,
        mobile: undefined,
        operator: undefined,
      },
      genderFilter: false,
      customFilter: false,
      custom: {
        field: undefined,
        operator: undefined,
      },
      availableFilterOperators: [
        {
          id: "on",
          name: "on",
          operator: "=",
        },
        {
          id: "before",
          name: "before",
          operator: "<=",
        },
        {
          id: "after",
          name: "after",
          operator: ">=",
        },
      ],
      availableFilterOperations: [
        {
          name: "is",
          operator: "=",
        },
        {
          name: "isn't",
          operator: "!=",
        },
        {
          name: "is unknown",
          operator: "is null",
        },
      ],
      refreshOutline,
      targetedCustomerCount: 0,
      loadingCount: false,

      conditions: [],
      tags: [],
      agents: [],
      CONDITION_TYPE,
      channels,
      socialChannels: [],
      gender: [
        { id: "male", value: "Male" },
        { id: "female", value: "Female" },
        { id: "unknown", value: "Unknown" }
      ],
      filterOperatorsMappingReverse,
      mode: "segment"
    };
  },
  props: {
    editBroadcast: {
      type: Object
    },
    editSegment: {
      type: Object
    },
    selectedSocialPageId: null
  },
  computed: {
    ...mapGetters(
      {
        connectedChannels: 'social/connectedChannels'
      }
    ),
    isPersonalBtnAddConditionDisabled() {
      if (this.personal.operator != 'is null') {
        if ( this.personal?.name?.trim() || this.personal?.mobile?.trim() || this.personal?.email?.trim()) {
          return false;
        }
        return true;
      }
      return false;
    },
    isPersonalInputDisabled() {
      return !this.personal?.operator || this.personal?.operator == 'Select Condition' || this.personal.operator === 'is null';
    },
    allConditionOptions() {
      let options =  [
        {
          text: CONDITION_TYPE.TAGS,
          handler: () => {
            this.tagFilter = true;
            this.addCondtionMode = false;
          },
        },
        {
          text: "Last Interaction",
          handler: () => {
            this.interactionFilter = true;
            this.addCondtionMode = false;
          },
        },
        {
          text: "Personal Details",
          handler: () => {
            this.personalFilter = true;
            this.addCondtionMode = false;
          },
        },
        {
          text: "Custom Field",
          handler: () => {
            this.customFilter = true;
            this.custom.operator = undefined;
            this.custom.field = undefined;
            this.addCondtionMode = false;
          },
        },
      ];
      // remove tags options if we have empty tags
      if (this.tagFilterOptions?.length == 0) {
        const index = options.findIndex(
          (option) => option.text === CONDITION_TYPE.TAGS
        );
        options.splice(index, 1);
        return options;
      } else { // showing all conditions
        return options;
      }
    },
    tagFilterOptions() {
      return this.allTags.map((e) => {
        return {
          text: e,
          handler: () => {
            const object = {
              field_type: "dropdown",
              key: "tags",
              type: CONDITION_TYPE.TAGS,
              data: { id: e, value: e },
              value: e,
              operator: this.filterConditions,
              query: encodeURIComponent('"' + e + '" in unnest(tags)')
            }
            this.addNewCondition(object);
            this.tagFilter = false;
          },
        };
      });
    },
    interactionFilterOptions() {
      const options = [
        {
          text: CONDITION_TYPE.INTERACTION_DATE,
          handler: () => {
            this.interactionDate.date = undefined;
            this.interactionDate.operator = undefined;
            this.interactionDateFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: CONDITION_TYPE.INTERACTION_TIME,
          handler: () => {
            this.interactionTime.time = undefined;
            this.interactionTime.operator = undefined;
            this.interactionTimeFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: CONDITION_TYPE.TALKED_WITH_AGENT,
          handler: () => {
            this.agentFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: "Engaged with Product Flow",
          hideForAsia: true,
          handler: () => {
            this.productFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: "Engaged with Collection Flow",
          hideForAsia: true,
          handler: () => {
            this.collectionFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: "Engaged with Bot Flow",
          hideForAsia: true,
          handler: () => {
            this.botFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: CONDITION_TYPE.INTERACTION_CHANNEL,
          handler: () => {
            this.channelFilter = true;
            this.interactionFilter = false;
          },
        },
        {
          text: "Has Item in Cart and not Paid",
          hideForAsia: true,
          handler: () => {
            const object = {
              field_type: "none",
              key: "ItemInCartValue_L24H",
              type: CONDITION_TYPE.HAS_ITEM_IN_CART_AND_NOT_PAID,
              data: { value: 0 },
              value: 0,
              operator: this.filterConditions,
              query: encodeURIComponent("ItemInCartValue_L24H>0")
            }
            this.addNewCondition(object);
            this.interactionFilter = false;
          },
        },
      ];
      if (this.IS_ASIA) {
        return options.filter((elm) => elm.hideForAsia != true);
      } else {
        return options;
      }
    },
    personalFilterOptions() {
      return [
        {
          text: "Name",
          handler: () => {
            this.resetPersonalDetails();
            this.personal.name = undefined;
            this.personal.operator = undefined;
            this.nameFilter = true;
            this.personalFilter = false;
          },
        },
        {
          text: "Email Address",
          handler: () => {
            this.resetPersonalDetails();
            this.personal.email = undefined;
            this.personal.operator = undefined;
            this.emailFilter = true;
            this.personalFilter = false;
          },
        },
        {
          text: "Mobile Number",
          handler: () => {
            this.resetPersonalDetails();
            this.personal.mobile = undefined;
            this.personal.operator = undefined;
            this.mobileFilter = true;
            this.personalFilter = false;
          },
        },
        {
          text: "Gender",
          handler: () => {
            this.resetPersonalDetails();
            this.genderFilter = true;
            this.personalFilter = false;
          },
        },
      ];
    },
    agentFilterOptions() {
      return this.allAgents.map((e) => {
        return {
          text: e.name,
          handler: () => {
            const object = {
              field_type: "dropdown",
              key: "agent_id",
              type: CONDITION_TYPE.TALKED_WITH_AGENT,
              data: { id: e.id, value: e.name },
              value: e.name,
              operator: this.filterConditions,
              query: encodeURIComponent('"' + e.id + '" in unnest(agent_id)')
            }
            this.addNewCondition(object);
            this.agentFilter = false;
          },
        };
      });
    },
    productFilterOptions() {
      return this.allProducts.map((e) => {
        return {
          text: e.name,
          handler: () => {

            const object = {
              field_type: "dropdown",
              key: "Products",
              type: CONDITION_TYPE.INTERACTED_WITH_PRODUCT,
              data: { id: e.id, value: e.name },
              value: e.name,
              operator: this.filterConditions,
              query: encodeURIComponent('"' + e.id + '" in unnest(Products)')
            }
            this.addNewCondition(object);
            this.productFilter = false;
          },
        };
      });
    },
    collectionFilterOptions() {
      return this.allCollections.map((e) => {
        return {
          text: e.name,
          handler: () => {

            const object = {
              field_type: "dropdown",
              key: "Products",
              type: CONDITION_TYPE.INTERACTED_WITH_CHANNEL,
              data: { id: e.id, value: e.name },
              value: e.name,
              operator: this.filterConditions,
              query: encodeURIComponent('"' + e.id + '" in unnest(Products)')
            }
            this.addNewCondition(object);

            this.collectionFilter = false;
          },
        };
      });
    },
    botFilterOptions() {
      return this.allBots.map((e) => {
        return {
          text: e.displayname,
          handler: () => {
            const object = {
              field_type: "dropdown_with_static",
              key: "Products",
              type: CONDITION_TYPE.INTERACTED_WITH_BOT,
              data: { id: e.id, value: e.displayname },
              value: e.displayname,
              operator: this.filterConditions,
              static: "for",
              query: encodeURIComponent('"' + e.id + '" in unnest(Products)')
            }
            this.addNewCondition(object);
            this.botFilter = false;
          },
        };
      });
    },
    channelFilterOptions() {
      const channels = [
        {
          text: "Messenger",
          handler: (e) => {
            this.addNewChannelConditionObject({ id: 'facebook', value: 'Messenger' });
            this.channelFilter = false;
          },
        },
        {
          text: "WhatsApp",
          handler: () => {
            this.addNewChannelConditionObject({ id: 'whatsapp', value: 'WhatsApp' });
            this.channelFilter = false;
          },
        },
        {
          text: "LINE",
          forAsia: this.connectedChannels.includes('line'),
          handler: () => {
            this.addNewChannelConditionObject({ id: 'line', value: 'LINE' });
            this.channelFilter = false;
          },
        },
        {
          text: "SMS",
          forAsia: this.connectedChannels.includes('sms'),
          handler: () => {
            this.addNewChannelConditionObject({ id: 'sms', value: 'SMS' });
            this.channelFilter = false;
          },
        },
        {
          text: "Viber",
          handler: () => {
            this.addNewChannelConditionObject({ id: 'viber', value: 'Viber' });
            this.channelFilter = false;
          },
        },
        {
          text: "Google Business Messages",
          handler: () => {
            this.addNewChannelConditionObject({ id: 'googlebusinessmsg', value: 'Google Business Messages' });
            this.channelFilter = false;
          },
        },
        {
          text: "Telegram",
          forAsia: this.connectedChannels.includes('telegram'),
          handler: () => {
            this.addNewChannelConditionObject({ id: 'telegram', value: 'Telegram' });
            this.channelFilter = false;
          },
        }
      ];
      if (this.IS_ASIA) {
        return channels.filter((elm) => elm.forAsia == true);
      } else {
        return channels;
      }
    },
    genderFilterOptions() {
      return [
        {
          text: "Male",
          handler: () => {
            this.addGenderDetailsToCondition(
              {
                id: 'male',
                value: 'Male',
                query: encodeURIComponent('user_gender = "male"')
              });
            this.genderFilter = false;
          },
        },
        {
          text: "Female",
          handler: () => {
            this.addGenderDetailsToCondition(
              {
                id: 'female',
                value: 'Female',
                query: encodeURIComponent('user_gender = "female"')
              });
            this.genderFilter = false;
          },
        },
        {
          text: "Is Unknown",
          handler: () => {
            this.addGenderDetailsToCondition(
              {
                id: 'unknown',
                value: 'Unknown',
                query: encodeURIComponent('user_gender = "unknown"')
              });
            this.genderFilter = false;
          },
        },
      ];
    }
  },
  watch: {
  },
  mounted() {
    this.mode = this.editBroadcast ? 'broadcast' : 'segment';
    this.onComponentLoad();
    if (this.mode == 'broadcast') {
      if (this.editBroadcast?.id) {
        this.renderConditions();
      }
      this.setDefaultConditions();
    }

    if (this.mode == 'segment') {
      if (this.editSegment?.id) {
        this.renderConditions();
      }
    }
  },
  methods: {
    onComponentLoad() {
      this.fetchConditionOptions();
      this.socialChannels = this.channels.filter(item => {
        return this.socialPlatformConnectedArray.includes(item.id);
      }).map(obj => {
        return {
          id: obj.id,
          value: obj.name
        };
      });
    },
    onPersonalConditionChange() {
      if (this.personal.operator === 'is null') {
        this.personal.name = "";
        this.personal.email = "";
        this.personal.mobile = "";
      }
    },
    resetPersonalDetails() {
      this.personal.name = undefined;
      this.personal.operator = undefined;
      this.personal.email = undefined;
      this.personal.operator = undefined;
      this.personal.mobile = undefined;
      this.personal.operator = undefined;
      this.nameFilter = false;
      this.emailFilter = false;
      this.mobileFilter = false;
      this.genderFilter = false;
      this.personalFilter = false;
    },
    setDefaultConditions() {
      if (this.editBroadcast.id || !this.socialChannels?.length) {
        return;
      }
      if (this.editBroadcast.channel == 'facebook' && this.conditions.length == 2) {
        return;
      }
      if (this.editBroadcast.channel != 'facebook' && this.conditions.length == 1) {
        return;
      }

      this.conditions = [];
      switch (this.editBroadcast.channel) {
        case 'facebook':
          this.addNewChannelConditionObject({ id: 'facebook', value: 'Messenger' });
          this.interactionTime.time = this.moment().format();
          this.interactionTime.operator = ">=";
          this.addInteractionTime();
        break;
        case 'sms':
          this.addNewChannelConditionObject({ id: 'sms', value: 'SMS' });
        break;
        case 'viber':
          this.addNewChannelConditionObject({ id: 'viber', value: 'Viber' });
          break;
        case 'telegram':
          this.addNewChannelConditionObject({ id: 'telegram', value: 'Telegram' });
          break;
        case 'googlebusinessmsg':
          this.addNewChannelConditionObject({ id: 'googlebusinessmsg', value: 'Google Business Messages' });
          break;
        case 'line':
          this.addNewChannelConditionObject({ id: 'line', value: 'LINE' });
          break;
        case 'whatsapp':
          this.addNewChannelConditionObject({ id: 'whatsapp', value: 'WhatsApp' });
          break;
      }
      if(this.selectedSocialPageId) {
        this.addInteractionChannelPageId(this.selectedSocialPageId);
      }
    },
    renderConditions() {
      let targetingConditions = [];
      if (this.mode == 'broadcast') {
        targetingConditions = this.editBroadcast?.targeting_conditions || [];
      } else {
        targetingConditions = this.editSegment?.targeting_conditions || [];
      }

      this.filterConditions = targetingConditions[0]?.operator || 'and';
      this.conditions = targetingConditions.map((data) => {
        const objCondition = JSON.parse(data.raw_data);
        const dd_data = objCondition.dd_data.map((elm) => { return { 'id': elm.id, 'value': elm.value } });
        objCondition.dd_data = dd_data;
        switch (objCondition.condition_type) {
          case CONDITION_TYPE.TAGS:
            objCondition.value = objCondition.data.value;
            objCondition.operator = this.filterConditions;
            objCondition.query = encodeURIComponent('"' + objCondition.data.value + '" in unnest(tags)');
            break;
          case CONDITION_TYPE.HAS_ITEM_IN_CART_AND_NOT_PAID:
            objCondition.operator = this.filterConditions;
            objCondition.query = encodeURIComponent("ItemInCartValue_L24H>0");
            break
          case CONDITION_TYPE.TALKED_WITH_AGENT:
            objCondition.value = objCondition.data.value;
            objCondition.operator = this.filterConditions;
            objCondition.query = encodeURIComponent('"' + objCondition.data.id + '" in unnest(agent_id)');
            break;
          case CONDITION_TYPE.INTERACTED_WITH_PRODUCT:
            // CODE IS TO DO
            objCondition.value = objCondition.data.value;
            objCondition.operator = this.filterConditions;
            objCondition.query = encodeURIComponent('"' + objCondition.data.id + '" in unnest(Products)');
            break;
          case CONDITION_TYPE.INTERACTION_CHANNEL:
            objCondition.value = objCondition.data.value;
            objCondition.operator = this.filterConditions;
            objCondition.query = encodeURIComponent(`platform = "${objCondition.data.id}"`);
            break;
          case CONDITION_TYPE.INTERACTION_DATE: {
            objCondition.value = objCondition.data.date;
            objCondition.operator = this.filterConditions;
            const operator = filterOperatorsMapping[objCondition.data_filter];
            objCondition.query = encodeURIComponent("LastActive " + operator + ' "' + objCondition.data.date + '"')
            break;
          }
          case CONDITION_TYPE.INTERACTION_TIME: {
            let [date, time] = objCondition.data.dateTime.split(" ");
            const selectedDateTime = this.moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss');
            objCondition.value = selectedDateTime;
            objCondition.operator = this.filterConditions;
            const operator = filterOperatorsMapping[objCondition.data_filter];
            objCondition.query = encodeURIComponent("LastActiveTimestamp " + operator + ' "' + selectedDateTime + '"')
            break;
          }
          case CONDITION_TYPE.CUSTOM_FIELD: {
            objCondition.value = objCondition.data.value;
            const query = this.getQueryForCustomField(objCondition);
            objCondition.operator = this.filterConditions;
            objCondition.query = query;
            break;
          }
          default:
            break;
        }
        return objCondition;
      });
    },
    getQueryForCustomField(condition) {
      let strQuery = '';
      switch (condition.data_filter) {
        case 'is':
          strQuery = '"' + condition.value + '" in unnest(custom_fields)';
          break;

        case "isn't":
          strQuery = '"' + condition.value + '" not in unnest(custom_fields)';
          break;

        case "is unknown":
          strQuery = "custom_fields is null";
          break;
      }
      return strQuery;
    },
    addNewChannelConditionObject({ id, value }) {
      const object = {
        field_type: "dropdown_with_static",
        key: "platform",
        type: CONDITION_TYPE.INTERACTION_CHANNEL,
        data: { id, value },
        value: value,
        operator: this.filterConditions,
        static: "was",
        query: encodeURIComponent(`platform = "${id}"`)
      }
      this.addNewCondition(object);
    },
    addInteractionChannelPageId(value) {
      const object = {
        field_type: "dropdown_with_static",
        key: "pageid",
        type: CONDITION_TYPE.INTERACTION_CHANNEL_PAGE_ID,
        data: { id: 'pageid', value },
        value: value,
        operator: this.filterConditions,
        static: "was",
        query: encodeURIComponent(`pageid = pageid`)
      }
      this.addNewCondition(object);
    },
    showOperator(condition) {
      if (condition.operator) {
        if ([CONDITION_TYPE.TAGS, CONDITION_TYPE.HAS_ITEM_IN_CART_AND_NOT_PAID, CONDITION_TYPE.TALKED_WITH_AGENT].includes(condition.condition_type)) {
          return false;
        }
        return true;
      }
      return false;
    },
    removeCondition(e, index) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      this.conditions.splice(index, 1);
      return false;
    },
    async fetchConditionOptions() {
      try {
        this.loading = true;
        let shopListResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-shop-list"
        );
        let fetchActionResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fetch-action"
        );
        let agentsResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "role-list"
        );
        let tagsResponse = jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "fbrt/fetchtag"
        );

        let allPromises = await Promise.all([
          shopListResponse,
          fetchActionResponse,
          agentsResponse,
          tagsResponse,
        ]);
        shopListResponse = allPromises[0];
        fetchActionResponse = allPromises[1];
        agentsResponse = allPromises[2];
        tagsResponse = allPromises[3];

        if (
          (shopListResponse.status == 200 || shopListResponse.status == 201) &&
          shopListResponse.data.success
        ) {
          if (
            shopListResponse.data.products &&
            shopListResponse.data.products.length
          ) {
            this.allProducts = shopListResponse.data.products.filter(
              (e) => !e.bundle && !e.catalog
            );
            this.allCollections = shopListResponse.data.products.filter(
              (e) => !e.bundle && e.catalog
            );
          }
        } else {
          if (
            shopListResponse.data &&
            !shopListResponse.data.success &&
            shopListResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: shopListResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
        if (
          (fetchActionResponse.status == 200 ||
            fetchActionResponse.status == 201) &&
          fetchActionResponse.data.success
        ) {
          if (
            fetchActionResponse.data.actions &&
            fetchActionResponse.data.actions.length
          )
            this.allBots = fetchActionResponse.data.actions.filter(
              (e) => e.active
            );
        } else {
          if (
            fetchActionResponse.data &&
            !fetchActionResponse.data.success &&
            fetchActionResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: fetchActionResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
        if (
          (agentsResponse.status == 200 || agentsResponse.status == 201) &&
          agentsResponse.data.success
        ) {
          if (agentsResponse.data.roles && agentsResponse.data.roles.length) {
            this.allAgents = agentsResponse.data.roles;
            this.agents = this.allAgents.map(obj => {
              return {
                id: obj.id,
                value: obj.name
              };
            });
          }
        } else {
          if (
            agentsResponse.data &&
            !agentsResponse.data.success &&
            agentsResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: agentsResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
        if (
          (tagsResponse.status == 200 || tagsResponse.status == 201) &&
          tagsResponse.data.success
        ) {
          if (tagsResponse.data.data && tagsResponse.data.data.length) {
            this.allTags = tagsResponse.data.data;
            this.tags = tagsResponse.data.data.map(obj => {
              return {
                id: obj,
                value: obj
              };
            });
          }
        } else {
          if (
            tagsResponse.data &&
            !tagsResponse.data.success &&
            tagsResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: tagsResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    addInteractionDate() {
      this.interactionDateFilter = false;
      if (this.interactionDate.date && this.interactionDate.operator) {
        // this.editSegment.targeting_conditions.push({
        //   operator: this.filterConditions,
        //   query: encodeURIComponent( "LastActive " + this.interactionDate.operator + ' "' + this.moment(this.interactionDate.date).format('YYYY-MM-DD') + '"' )
        // });
        const selectedDate = this.moment(this.interactionDate.date).format('YYYY-MM-DD');
        const object = {
          field_type: "date_with_filter",
          key: "LastActive",
          type: CONDITION_TYPE.INTERACTION_DATE,
          data: { date: selectedDate },
          value: selectedDate,
          operator: this.filterConditions,
          filter: ['on', 'before', 'after'],
          operators: ['=', '<', '>'],
          data_filter: this.interactionDate.operator,
          query: encodeURIComponent("LastActive " + this.interactionDate.operator + ' "' + selectedDate + '"')
        }
        this.addNewCondition(object);

        this.interactionDate.operator = undefined;
        this.interactionDate.date = undefined;
      } else {
        this.updateToast({
          open: true,
          message: "Please select valid condition and date",
          type: "danger",
        });
      }
    },
    addInteractionTime() {
      if (this.interactionTime.time && this.interactionTime.operator) {
        // this.editSegment.targeting_conditions.push({
        //   operator: this.filterConditions,
        //   query: encodeURIComponent("LastActiveTimestamp " + this.interactionTime.operator + ' "' + (this.moment(this.interactionTime.time).format('YYYY-MM-DD HH:mm:ss')) + '"' ),
        // });
        const selectedDateTime = this.moment(this.interactionTime.time).format('YYYY-MM-DD HH:mm:ss');
        const selectedDate = this.moment(this.interactionTime.time).startOf('day');
        const selectedTime = this.moment(this.interactionTime.time).format('HH.mm:ss');
        const object = {
          field_type: "datetime_with_filter",
          key: "LastActiveTimestamp",
          type: CONDITION_TYPE.INTERACTION_TIME,
          data: { date: selectedDate, time: selectedTime, dateTime: selectedDateTime },
          value: selectedDateTime,
          operator: this.filterConditions,
          filter: ['on', 'before', 'after'],
          operators: ['=', '<=', '>='],
          data_filter: this.interactionTime.operator,
          query: encodeURIComponent("LastActiveTimestamp " + this.interactionTime.operator + ' "' + selectedDateTime + '"')
        }
        this.addNewCondition(object);

        this.interactionTime.operator = undefined;
        this.interactionTime.time = undefined;
        this.interactionTimeFilter = false;
      } else {
        this.updateToast({ open: true, message: "Please select valid condition and time", type: "danger", });
      }
    },
    addGenderDetailsToCondition({ id, value, query }) {
      const object = {
        field_type: "dropdown_with_static",
        key: 'user_gender',
        type: CONDITION_TYPE.GENDER,
        data: { id, value },
        value: value,
        operator: this.filterConditions,
        data_filter: '',
        query: query,
        static: 'is'
      }
      this.addNewCondition(object);
    },
    addPersonalDetailsToCondition({ key, type, id, value, query, data_filter }) {
      const object = {
        field_type: "textbox_with_filter",
        key: key,
        type: type,
        data: { id, value },
        value: value,
        operator: this.filterConditions,
        filter: ['is', "isn't", 'is unknown'],
        operators: ['=', '!=', 'is null'],
        data_filter: data_filter,
        query: query
      }
      this.addNewCondition(object);
    },
    resetNameFilter() {
      this.nameFilter = false;
      this.personal.name = undefined;
    },
    resetMobileFilter() {
      this.mobileFilter = false;
      this.personal.mobile = undefined;
    },
    resetEmailFilter() {
      this.emailFilter = false;
      this.personal.email = undefined;
    },
    addPersonal() {
      if (this.personal.operator) {
        // Name Details
        if (this.nameFilter) {
          if (this.personal.operator === "is null") {
            // this.editSegment.targeting_conditions.push({
            //   operator: this.filterConditions,
            //   query: encodeURIComponent("user_name is null"),
            // });
            this.addPersonalDetailsToCondition(
              {
                key: 'user_name',
                type: CONDITION_TYPE.NAME,
                id: null,
                value: null,
                query: encodeURIComponent("user_name is null"),
                data_filter: 'is null'
              });
            this.resetNameFilter();
          } else if (this.personal.name) {
            this.addPersonalDetailsToCondition(
              {
                key: 'user_name',
                type: CONDITION_TYPE.NAME,
                id: null,
                value: this.personal.name,
                query: encodeURIComponent("user_name " + this.personal.operator + ' "' + this.personal.name + '"'),
                data_filter: this.personal.operator
              });
            this.resetNameFilter();
          } else {
            this.updateToast({ open: true, message: "Please enter name", type: "danger", });
          }
        }

        // Mobile Details
        if (this.mobileFilter) {
          if (this.personal.operator === "is null") {
            // this.editSegment.targeting_conditions.push({
            //   operator: this.filterConditions,
            //   query: encodeURIComponent("user_mobilenumber is null"),
            // });
            this.addPersonalDetailsToCondition(
              {
                key: 'user_mobilenumber',
                type: CONDITION_TYPE.NUMBER,
                id: null,
                value: null,
                query: encodeURIComponent("user_mobilenumber is null"),
                data_filter: 'is null'
              });
            this.resetMobileFilter();
          } else if (this.personal.mobile) {
            this.addPersonalDetailsToCondition(
              {
                key: 'user_mobilenumber',
                type: CONDITION_TYPE.NUMBER,
                id: null,
                value: this.personal.mobile,
                query: encodeURIComponent("user_mobilenumber " + this.personal.operator + ' "' + this.personal.mobile + '"'),
                data_filter: this.personal.operator
              });
            this.resetMobileFilter();
          } else {
            this.updateToast({ open: true, message: "Please enter mobile", type: "danger", });
          }
        }

        // Email Details
        if (this.emailFilter) {
          if (this.personal.operator === "is null") {
            this.addPersonalDetailsToCondition(
              {
                key: 'user_email',
                type: CONDITION_TYPE.EMAIL,
                id: null,
                value: null,
                query: encodeURIComponent("user_email is null"),
                data_filter: 'is null'
              });
            this.resetEmailFilter();
          } else if (this.personal.email) {
            this.addPersonalDetailsToCondition(
              {
                key: 'user_email',
                type: CONDITION_TYPE.EMAIL,
                id: null,
                value: this.personal.email,
                query: encodeURIComponent("user_email " + this.personal.operator + ' "' + this.personal.email + '"'),
                data_filter: this.personal.operator
              });
            this.resetEmailFilter();
          } else {
            this.updateToast({ open: true, message: "Please enter email", type: "danger", });
          }
        }
      } else {
        this.updateToast({ open: true, message: "Please select valid condition", type: "danger", });
      }
    },
    addNewCustomCondition({ id, value, data_filter }) {
      const object = {
        field_type: "textbox_with_filter",
        key: "custom_fields",
        type: CONDITION_TYPE.CUSTOM_FIELD,
        data: { id, value },
        value: value,
        operator: this.filterConditions,
        filter: ['is', "isn't", 'is unknown'],
        operators: ['=', '!=', 'is null'],
        data_filter,
        use_unnest: true
      }
      const query = this.getQueryForCustomField(object);
      object.query = query;
      this.addNewCondition(object);
    },
    addCustom() {
      let data_filter = '';
      let errorMessage = '';
      switch (this.custom.operator) {
        case "=":
          data_filter = 'is';
          break;
        case "!=":
          data_filter = "isn't";
          break;
        case "is null":
          data_filter = 'is unknown';
          break;
        default:
          errorMessage = "Please select valid condition";
          break;
      }

      if (!this.custom.field) {
        errorMessage = "Please enter PROP:VALUE"
      }

      if (errorMessage) {
        this.updateToast({ open: true, message: errorMessage, type: "danger", });
        return;
      } else {
        this.addNewCustomCondition({ id: null, value: this.custom.field, data_filter });
      }

      this.customFilter = false;
      this.custom.field = undefined;
      this.custom.operator = undefined;
    },
    getFormattedJsonToSave() {
      var target_cond = [];
      for (let condition of this.conditions) {
        //   if (!condition.is_set) {
        //     continue;
        //   }
        var obj = {
          key: condition.condition_key
        };

        //   switch (condition.condition_type) {
        //     case CONDITION_TYPE.TAGS: {
        //       obj.val = condition.data.value;
        //       break;
        //     }
        //   }
        var queryString = "";
        var inv_comma = "";
        var selected_op = "";
        if (obj.key != "Products" && obj.key != "agent_id" && obj.key != "ItemInCartValue_L24H") {
          inv_comma = "\"";
        }

        if (condition.use_unnest) {
          if (condition.operator) {
            selected_op = condition.operator;
          }
          else if (condition.operators) {
            selected_op = condition.operators[condition.filter.indexOf(condition.data_filter)];
          }
          if (selected_op) {
            if (selected_op == "is null") {
              queryString = obj.key + ' is null';
            }
            else if (selected_op == "!=") {
              queryString = inv_comma + obj.val + inv_comma + ' not in unnest(' + obj.key + ')';
            }
            else {
              queryString = inv_comma + obj.val + inv_comma + ' in unnest(' + obj.key + ')';
            }
          }
        }
        else {
          if (condition.operator) {
            queryString = obj.key + ' ' + condition.operator + ' ' + inv_comma + obj.val + inv_comma;
            selected_op = condition.operator;

          }
          else if (condition.operators) {
            selected_op = condition.operators[condition.filter.indexOf(condition.data_filter)];
            queryString = obj.key + ' ' + selected_op + ' ' + inv_comma + obj.val + inv_comma;
          }
          else {
            queryString = inv_comma + obj.val + inv_comma + ' in unnest(' + obj.key + ')';
          }
        }
        target_cond.push({
          query: condition.query,
          operator: this.filterConditions, // condition.operator,
          // key: condition.condition_key,
          // selected_op: selected_op,
          raw_data: JSON.stringify(condition)
        });
      }
      return target_cond;
    },
    getEmptyConditionObject(data) {
      let obj = {
        condition_type: data.type,
        condition_key: data.key,
        field_type: data.field_type,
        is_set: true,
        data: data.data || {},
        data_filter: "",
        placeholder: data.placeholder || "",
        name: data.name || "",
        value: data.value || "",
        query: data.query || "",
        operator: data.operator || ""
      };
      if (data.field_type == FIELD_TYPE.NONE) {
        obj.is_set = true;
      }
      if (Object.prototype.hasOwnProperty.call(data, 'value')) {
        obj.data.value = data.value;
      }
      const isPersonalDetails = ['user_name', 'user_mobilenumber', 'user_email' ].includes(data.key);
      if (data.filter) {
        obj.filter = data.filter;
        if (isPersonalDetails && data?.operators) {
          let keyIndex = data.operators.indexOf(data.data_filter);
          obj.data_filter = data.filter[keyIndex] || data.filter[0];
        } else {
          const availableOperator = this.availableFilterOperators.find((elm) => elm.operator == data.data_filter);
          if (obj.filter.includes(data.data_filter)) {
            obj.data_filter = data.data_filter;
          } else if (availableOperator) {
            obj.data_filter = availableOperator.name;
          } else {
          obj.data_filter = obj.filter[0];
          }
        }
      }
      if (data.static) {
        obj.static = data.static;
      }
      if (data.operator) {
        obj.operator = data.operator;
      }
      if (data.operators) {
        obj.operators = data.operators;
      }
      if (Object.prototype.hasOwnProperty.call(data, "use_unnest")) {
        obj.use_unnest = data.use_unnest;
      }
      if (Object.prototype.hasOwnProperty.call(data, "lable_style")) {
        obj.lable_style = data.lable_style;
      }
      if (data.use_unnest !== undefined) {
        obj.use_unnest = data.use_unnest;
      }

      return obj;
    },
    addNewCondition(condData, isDefault) {
      var obj = this.getEmptyConditionObject(condData);
      var errMsg = this.fillNewConditionDropdownData(obj);
      if (!errMsg) {
        if (isDefault) {
          obj.cond_default = true;
        }
        this.conditions.push(obj);
        // this.removeAddNewConditionDD();
        return obj;
      } else {
        console.error(errMsg);
        this.updateToast({ open: true, message: errMsg, type: "danger", });
      }
    },
    fillNewConditionDropdownData(condition) {
      var retVal = '';
      switch (condition.condition_type) {
        case CONDITION_TYPE.TAGS: {
          condition.dd_data = [...this.tags];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "Tags are being fetched, please try again in a bit!";
          }
          break;
        }
        case CONDITION_TYPE.TALKED_WITH_AGENT: {
          condition.dd_data = [...this.agents];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No agents available";
          }
          break;
        }
        case "Engaged with product flow": {
          condition.dd_data = [...this.productFlows];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No product flow available";
          }
          break;
        }
        case "Engaged with collection flow": {
          condition.dd_data = [...this.collectionFlows];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No collection flow available";
          }
          break;
        }
        case "Engaged with bot flow": {
          condition.dd_data = [...this.botFlows];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No bot flow available";
          }
          break;
        }
        case CONDITION_TYPE.INTERACTION_CHANNEL: {
          condition.dd_data = [...this.socialChannels];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No channel available";
          }
          break;
        }
        case "Timezone": {
          condition.dd_data = [...this.timezones];
          if (!condition.dd_data || condition.dd_data.length == 0) {
            retVal = "No timezone available";
          }
          break;
        }
        case "Gender": {
          condition.dd_data = [...this.gender];
          break;
        }
        case "Order in last 24 hours":
        case "Order in last 3 days":
        case "Order in last 7 days": {
          condition.dd_data = [...this.fnbOrderTypes];
          break;
        }
        default: {
          condition.dd_data = [];
          break;
        }
      }
      return retVal;
    }
  },
};
</script>

<style scoped>
.col-btn-add-condition {
  margin: 0;
  padding: 5px 0 0 0;
}
.segment-list-wrapper {
  grid-row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.segment-row {
  display: flex;
  column-gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.segment-name, .segment-condition, .segment-title {
  flex: 1;
  white-space: nowrap;
  background: var(--ion-color-primary);
  border-radius: 5px;
  color: var(--ion-color-primary-contrast);
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.segment-name span, .segment-condition span,  .segment-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.segment-condition {
  max-width: fit-content;
}
.no-condition-value {
  padding-right: 25px;
}

.btm-remove-segment {
  position: absolute;
  right: 25px;
  color: var(--ion-color-primary-contrast);
  cursor: pointer;
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}
.table-row:last-child {
  border-bottom: 0px;
}

.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.radio-bttns {
  margin-right: 10px;
}
.uploadBttn {
  border: 1px solid var(--ion-color-black);
  --ion-color-base: var(--ion-color-white) !important;
  height: 35px;
  --box-shadow: none;
}

ion-textarea {
  min-height: 80px;
}
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  box-shadow: none;
}
.panel-body{
  padding: 15px;
}
.panel-text{
  font-size: 16px;
  color: #262626;
  padding-bottom: 10px;
}
ion-button.btn-refresh{
  --box-shadow: none;
  color: #262626;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}
.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 20px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.tooltip_container .tooltip_text.left {
  top: 5%;
}
@media only screen and (max-width: 768px) {
  .conditionsEdit {
    margin-bottom: 20px;
  }
}
.element-container{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  padding-left: 15px;
  padding-bottom: 5px;
}

.upload-img {
  max-width: 453px;
}

.model-sections {
  background: var(--ion-color-white);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  width: fit-content;
}
.inpt-container {
  width: 360px;
  margin: 0px 30px 10px 46px;
}
.addRow{
  margin-top: 0;
  margin-bottom: 0;
}
.addRow:hover {
   border: 2px #881fff dashed;
}

.addNew {
  max-width: 450px;
  width: 100%;
}
.addNew.complete {
  border-bottom: 2px #eee dashed;
}
.textarea-box {
  width: 450px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
}
.img-block{
   border-radius: 5px;
    border: 2px #eee dashed;
    width: 450px;
    display: inline-block;
}
.jinput {
  background-color: #ffffff;
}
@media only screen and (max-width: 760px){
  .model-sections, .element-container {
    width: calc( 100% - 40px );
  }

  .addNew {
    max-width: 100%;
    margin-left: 0px;
  }
  .upload-img {
    max-width: 100%;
    margin-left: 0px;
  }
  .textarea-box{
    width: 100%;
  }
  .img-block{
    width: 100%;
  }
  .inpt-container{
    margin: 0px 10px 10px 10px;
    width: 95%;
  }
  .jinput{
    width: 98%;
  }

}

.tooltip_container .tooltip_text.left {
  top: 5%;
  z-index: 999;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>