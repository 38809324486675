<template>
  <master-layout pageTitle="Invite customers to LINE">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-icon class="btn-back" @click="() => { $router.go(-1) }" size="large" :icon="arrowBackOutline"></ion-icon>
          <h2 class="prdt-title ion-no-margin">LINE</h2>
        </ion-buttons>
      </ion-toolbar>
      <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..." ></ion-loading>
      <div v-else>
				<div v-if="lineConnected" class="line-content-wrapper">
					<h3 class="container-title custom_mrg_left">Make it easier for your customers to add you as a friend on LINE</h3>
					<ion-grid class="container">
						<ion-row>
							<ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="ion-no-padding"> 
								<div class="model-sections">   
									<div class="header">
										<ion-label>
											<h3 class="title">Direct link</h3>
											<p class="sub-title">Copy this link to share it via social media, email, and more to direct users to your account.</p>
										</ion-label>
									</div>
									<ion-item lines="none">
										<div type="url" class="url-input"> {{ 'https://line.me/R/ti/p/@' + channelName}}</div>
										<ion-button class="url-bttn" @click="copyURL('https://line.me/R/ti/p/@' + channelName)" color="primary">Copy</ion-button>
									</ion-item>
								</div>

								<div class="model-sections cust-margin">
									<div class="header">
										<ion-label>
											<h3 class="title">Add a button to your website</h3>
											<p class="sub-title">Users can click or tap this button to friend your account. Copy the HTML tag to add it to websites, blogs, and more.</p>
										</ion-label>
									</div>
									<div>
										<img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" class="img-bttn"/>
									</div>
									<div class="embedCodeAreaContainer">
										<figure class="highlight">
											<pre>{{ buttonHTML }}</pre>
											<a @click="copyURL(buttonHTML)">Copy to Clipboard</a>
										</figure>
									</div>
								</div>
							</ion-col>

							<!-- QR CODE -->
							<ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12" class="colMargin ion-no-padding">
								<div class="model-sections">
									<div class="header">
										<ion-label>
											<h3 class="title">QR code</h3>
											<p class="sub-title">Users can scan this image with a QR code reader to friend your account. Download the image or copy the HTML tag to share it via social media, email, and more.</p>
										</ion-label>
									</div>
									<div class="qrcode">
										<img :src="'https://qr-official.line.me/sid/M/' + channelName + '.png'" width="200" height="200" class="qrcode-img" />
									</div>
									<div class="embedCodeAreaContainer">
										<figure class="highlight">
											<pre>{{ QRHTML }}</pre>
											<a @click="copyURL(QRHTML)">Copy to Clipboard</a>
										</figure>
									</div>
								</div>
							</ion-col>
						</ion-row>
				</ion-grid>
       </div>
			</div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, arrowBackOutline } from 'ionicons/icons';


export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      arrowBackOutline,
      channelName: undefined,
      line: {
        url: 'https://line.me/R/ti/p/@' + this.channelName,
      },
    }
  },
  computed: {
    QRHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"> <img src="https://qr-official.line.me/sid/M/' + this.channelName + '.png" alt="LINE Add friend" height="180" width="180" border="0"> </a>';
    },
    buttonHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"> <img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" alt="LINE Add friend" height="36" border="0"></a>';
    }
  },
	mounted() {
    this.init();
  },
  methods: {
    async doRefresh(event) {
       this.init();
       event.target.complete();
    },
		async init() {
      try {
        this.loading = true;
        if(this.lineConnected) {
          this.active = this.lineSocialData.active;
          this.channelAccessToken = this.lineSocialData.channelaccesstoken;
          this.channelName = this.lineSocialData.channelname;
          this.webhookurl = this.lineSocialData.webhookurl;
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    copyURL(item) {
      try {
        navigator.clipboard.writeText(item);
        this.updateToast({
          open: true,
          message: "Copied to the clipboard!",
          type: "success"
        })
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>

<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0px;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
	margin-right: 10px;
}
.header {
  margin: 0px 0px 10px 10px;
  padding: 10px 0px 0px 5px;
}

.heading {
  font-size: 28px;
  color: var(--ion-color-black);
  font-weight: 400;
  line-height: 1.4;
	margin-bottom: 0px;
}
.colMargin {
  margin-left: 20px;
}
.cust-margin {
	margin-top: 20px;
}
.qrcode {
  margin-right: auto;
  display: block;
  margin-left: auto;
	width: 200px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.qrcode-img{
 border: 1px var(--ion-color-black) solid;
 border-radius: 5px;
}
.embedCodeAreaContainer {
  margin: 15px;
}

figure {
  margin: 0;
}

figure.highlight {
  margin-top: 5px;
}

figure.highlight pre {
  white-space: normal;
  text-align: left;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.custom_mrg_left {
  margin-left: 20px
}
.container{
  margin: 0px 0px 0px 10px;
}
.url-bttn {
	height: 37px;
}
ion-button.url-bttn {
	--border-radius: 0 4px 4px 0px;
}
.url-input {
	border: 1px solid #e3e3e3;
	background-color: #f5f5f5;
	color: #888;
	cursor: not-allowed;
	border-right: 0 none;
	border-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	height: 37px;
	max-width: 350px;
	min-width: 270px;
	padding: 7px;
}
.img-bttn {
	height: 36px;
	margin-left: 15px;
	margin-top: 5px;
}
.line-content-wrapper {
  padding-top: 10px;
}


@media only screen and (max-width: 760px) {
  .container{
    margin: 5px;
  }
	.colMargin {
		margin-left: 0px;
	}
}

</style>