<template>
	<div class="fb-layout-wrapper">

		<div class="btn-row">
			<div class="btn-element btn-text" @click="onClickAction('AddText')" v-if="activeActions.includes('text')">
					<Image image="https://jumper.ai/web-s/resources/images/bot-builder/templates/normal-text.svg" height="31px" width="auto"></Image>
					<p class="ion-no-margin">Text</p>
			</div>
			<div class="btn-element btn-image" @click="onClickAction('AddImage')" v-if="activeActions.includes('image')">
				<Image image="https://jumper.ai/web-s/resources/images/bot-builder/templates/image-only.svg" height="31px" width="auto"></Image>
				<p class="ion-no-margin">Image</p>
			</div>
			<div class="btn-element btn-video" @click="onClickAction('AddVideo')" v-if="activeActions.includes('video')">
				<Image image="https://jumper.ai/web-s/resources/images/bot-builder/templates/video-only.svg" height="31px" width="auto"></Image>
				<p class="ion-no-margin">Video</p>
			</div>
		</div>

		<div class="btn-row" v-if="activeActions.includes('carousel')">
			<div class="btn-element btn-carousel" @click="() => isCarouselOptionsOpen = true">
				<Image image="https://jumper.ai/web-s/resources/images/bot-builder/templates/cards-carousel.svg" height="31px" width="auto"></Image>
				<p class="ion-no-margin">Carousel</p>
			</div>
		</div>

		<div class="btn-row" v-if="activeActions.includes('quickReply')">
			<div class="btn-element btn-quick-replies" @click="onClickAction('AddQuickReplies')">
				<p class="ion-no-margin">Quick Replies</p>
			</div>
		</div>

	</div>

	<ion-action-sheet
		v-if="isCarouselOptionsOpen"
		:is-open="isCarouselOptionsOpen"
		header="Choose any one"
		:buttons="carouselOptions"
		@didDismiss="() => (isCarouselOptionsOpen = false)" />
</template>

<script>
import { IonSelect, IonSelectOption, IonRadioGroup, IonRadio } from "@ionic/vue";
import { addCircleOutline, trashBinOutline, eyeOutline } from 'ionicons/icons';
import myInput from '/src/components/myInput';
import popper from '/src/components/popper';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import Image from '../../../components/Image.vue'

export default {
	components: {
		Image
	},
	emits: ['action'],
	props: {
		editBroadcast: {
			type: Object
		}
	},
	computed: {
		activeActions() {
			let activeActions = [];
			if (this.editBroadcast.channel == 'sms') {
				activeActions = ["text"];
			}
			else if (this.editBroadcast.channel == 'telegram') {
				activeActions = ["text", "image", "video", "quickReply"];
			}
			else {
				activeActions = ["text", "image", "video", "carousel", "quickReply"];
			}
			// hide video action for Asia
			if (this.IS_ASIA) {
				return activeActions.filter(elm => elm != 'video');
			}
			return activeActions;
		},
		carouselOptions() {
			const options = [
				{
					text: "Select Product",
					handler: (e) => {
						this.onClickAction('AddProduct');
					},
				},
				{
					text: "Select Collection",
					handler: (e) => {
						this.onClickAction('AddCollection');
					},
				},
				{
					text: "Custom Card",
					handler: (e) => {
						this.onClickAction('AddCustomCard');
					},
				}
			]
			if (this.IS_ASIA) {
				return [options[2]];
			} else {
				return options;
			}
		}
	},
  data() {
		return {
			isCarouselOptionsOpen: false
		}
	},
	ionViewWillEnter() {
	},
	methods: {
		onClickAction(actionName) {
			this.$emit('action', { actionName });
		}
	}
}
</script>

<style scoped>
.fb-layout-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	max-width: 450px;
}

.fb-layout-wrapper .btn-row {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.btn-element {
	border: 2px dashed #d3d3d3;
	border-radius: 15px;
	font-size: 13px !important;
	color: #757575;
	font-weight: 500 !important;
	text-align: center;
	margin-top: 5px;
	cursor: pointer;
	height: 90px;
	width: 90px;
	margin: 5px;
	padding: 8px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.btn-element p{
	font-size: 14px;
	color: var(--ion-color-black);
	line-height: 16px;
}

.btn-carousel {
	width: 31%;
	max-width: 90px;
	max-height: 90px;
}

.btn-quick-replies{
	height: auto;
	width: 130px;
	display: inline-block;
	margin: 5px;
	padding: 8px 0px;
}

@media only screen and (max-width: 760px) {
	.fb-layout-wrapper {
		max-width: calc( 100% - 40px ) !important;
	}
}
</style>