<template>
  <master-layout pageTitle="LINE Rich Menu">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="allMenus.length == 0" style="width: 100%; padding: 15px;" class="text-center">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No Rich Menu added yet! 😟</h5>
          <ion-text color="medium">Seems like there are no rich menu added yet, click the plus button to add one</ion-text>
        </div>
        <div v-if="allMenus.length > 0">
         <ion-grid class="table-header ion-no-padding">
            <ion-row class="mrg_table-left ion-justify-content-between ion-align-items-center">
              <ion-col >
                Name
              </ion-col>
              <ion-col>
                Status
              </ion-col>
              <ion-col>
                Make Default
              </ion-col>
              <ion-col class="text-center">
                Actions
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="table-container">
            <ion-grid class="ion-no-padding">
              <ion-row v-for="thisMenu in allMenus" :key="thisMenu.id" class="table-row ion-no-padding ion-justify-content-between ion-align-items-center">
                <ion-col class="orderDesc">
                  {{ thisMenu.name }}
                </ion-col>
                <ion-col class="orderDesc" style="text-transform: capitalize;">
                  {{ thisMenu.status }}
                </ion-col>
                <ion-col>
                  <ion-toggle @ionChange="setDefault(thisMenu.id)" v-model="thisMenu.selected"></ion-toggle>
                </ion-col>
                <ion-col class="text-center">
                  <span class="tooltip_container">
                    <ion-button @click="() => $router.push('/add-rich-menu?richMenuid=' + thisMenu.id)" fill="clear" size="default" color="dark">
                      <ion-icon :icon="createOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Edits</span>
                  </span>
                  <span class="tooltip_container">
                    <ion-button @click="deleteMenu(thisMenu.id)" fill="clear" size="default" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text">Delete</span>
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => $router.push('/add-rich-menu')">
          <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
        </button>
      </div>
    </ion-content>
    <popper :note="activeNote" image v-if="editIndex > -1" type="detailed" :config="popperConfig" @close="() => editIndex = -1" @submit="popperSubmit" />
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashOutline, close, createOutline, calendar } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonToggle } from "@ionic/vue";
import popper from '/src/components/popper';

var imageObj = {
  image: undefined,
  action: undefined,
  payload: undefined,
  x: 0, 
  y: 0,
  width: 0,
  height: 0,
  initialize: function(x, y, w, h, action, payload) {
    this.x = x; this.y = y; this.width = w; this.height = h;
    this.action = action; this.payload = payload;
  },
}
export default {
  components: { IonToggle, popper },
  data() {
    return {
      loading: false,
      close,
      chevronDownCircleOutline,
      addCircleOutline,
      trashOutline,
      createOutline,
      calendar,
      allMenus: [],
      next: undefined,
      addEditMode: false,
      dummyMenu: {
        status: undefined,
        name: undefined,
        rich_menu_id: undefined,
        start_date: undefined,
        end_date: undefined,
        image: undefined,
        selected: false,
        id: undefined,
        components: {
          size: { width: undefined, height: undefined },
          selected: false,
          chatBarText: undefined,
          name: undefined,
          areas: []
        },
        availableImages: undefined
      },
      newMenu: {
        status: undefined,
        name: undefined,
        rich_menu_id: undefined,
        start_date: undefined,
        end_date: undefined,
        image: undefined,
        selected: false,
        id: undefined,
        components: {
          size: { width: undefined, height: undefined },
          selected: false,
          chatBarText: undefined,
          name: undefined,
          areas: []
        },
        availableImages: undefined
      },
      finalImage: undefined,
      previewImages: [],
      selectedLayout: undefined,
      finalCanvas: document.createElement("canvas"),
      editIndex: -1
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    popperConfig() {
      return this.editIndex > -1 && this.editIndex < this.previewImages.length ? {
        text: this.previewImages[this.editIndex].image,
        action: this.previewImages[this.editIndex].action,
        payload: this.previewImages[this.editIndex].payload,
      } : { text: undefined, action: undefined, payload: undefined }
    },
    activeNote() {
      return this.editIndex > -1 && this.editIndex < this.previewImages.length ? "Make sure your image is in  JPG, JPEG, or PNG file format, doesn't exceed 1 MB file size and the image size is<span>&nbsp;" + this.previewImages[this.editIndex].width + " x " + this.previewImages[this.editIndex].height + "&nbsp;</span><a target='_blank' href='https://lineforbusiness.com/richmenumaker/th/'>Design your background</a>" : undefined;
    }
  },
  methods: {
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchMenus();
    },
    async fetchMenus() {
      try {
        this.loading = true;
        const allMenusResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/fetch", { limit: 100 });
        if ((allMenusResponse.status == 200 || allMenusResponse.status == 201) && allMenusResponse.data.success) {
          this.allMenus = allMenusResponse.data.data;
          this.next = allMenusResponse.data.next;
        } else {
          if(allMenusResponse.data.errorMessage && allMenusResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: allMenusResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchMenu(id) {
      if(id) {
        try {
          this.loading = true;
          const thisMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/get", { id: id });
          if ((thisMenuResponse.status == 200 || thisMenuResponse.status == 201) && thisMenuResponse.data.success) {
            this.dummyMenu = thisMenuResponse.data.data;
            this.dummyMenu.start_date = this.moment(this.dummyMenu.start_date, "DD/MM/YYYY HH:mm:ss").toISOString();
            this.dummyMenu.end_date = this.moment(this.dummyMenu.end_date, "DD/MM/YYYY HH:mm:ss").toISOString();
            if(this.dummyMenu && this.dummyMenu.components) {
              if(this.dummyMenu.components.areas && this.dummyMenu.components.areas.length) this.dummyMenu.availableImages = this.dummyMenu.components.areas.length;
              if(this.dummyMenu.availableImages && this.dummyMenu.components.size && this.dummyMenu.components.size.width && this.dummyMenu.components.size.height) {
                let width = this.dummyMenu.components.size.width;
                let height = this.dummyMenu.components.size.height;
                let areas = this.dummyMenu.components.areas;

                this.finalCanvas.width = width;
                this.finalCanvas.height = height;

                this.previewImages = [];

                switch(this.dummyMenu.availableImages) {
                  case 1: if(width == 2500 && height == 843) this.selectedLayout = 'c01'; else if(width == 2500 && height == 1686) this.selectedLayout = 'l01';
                  break;
                  case 2: if(width == 2500 && height == 843) {
                    if(areas[0].bounds.width == 833 && areas[0].bounds.height == 843) this.selectedLayout = 'c02a';
                    else if(areas[0].bounds.width == 1667 && areas[0].bounds.height == 843) this.selectedLayout = 'c02b';
                    else if(areas[0].bounds.width == 1250 && areas[0].bounds.height == 843) this.selectedLayout = 'c02c';
                  } else if(width == 2500 && height == 1686) {
                    if(areas[0].bounds.width == 1250 && areas[0].bounds.height == 1686) this.selectedLayout = 'l02a';
                    else if(areas[0].bounds.width == 2500 && areas[0].bounds.height == 843) this.selectedLayout = 'l02b';
                  }
                  break;
                  case 3: if(width == 2500 && height == 843) this.selectedLayout = 'c03'; else if(width == 2500 && height == 1686) this.selectedLayout = 'l03';
                  break;
                  case 4: if(areas[0].bounds.width == 2500) this.selectedLayout = 'l04a'; else this.selectedLayout = 'l04b';
                  break;
                  case 6: this.selectedLayout = 'l06';
                  break;
                  default: console.log("Something is wrong internally");
                }
                areas.forEach(thisArea => {
                  let newImageObj = Object.create(imageObj);
                  let action = thisArea.action.type === 'uri' ? 'website' : undefined;
                  let payload = thisArea.action.type === 'uri' ? thisArea.action.uri : thisArea.action.data;
                  thisArea.action.type === 'postback' && !payload.includes('#') && payload !== '7@1k704um@9' ? payload = 'bot' + payload : '';
                  newImageObj.initialize(thisArea.bounds.x, thisArea.bounds.y, thisArea.bounds.width, thisArea.bounds.height, action, payload);
                  this.previewImages.push(newImageObj);
                })
              }
              if(this.dummyMenu.image && this.dummyMenu.image.length) this.finalImage = this.dummyMenu.image;
            }
          } else {
            if(thisMenuResponse.data.errorMessage && thisMenuResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: thisMenuResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        } finally {
          this.loading = false;
          this.addEditMode = true;
        }
      }
    },
    popperSubmit(data){
      try {
        if(data.action) this.previewImages[this.editIndex].action = data.action;
        if(data.payload) this.previewImages[this.editIndex].payload = data.payload;
        if(typeof data.text === 'object') {
          let file = data.text;
          var reader = new FileReader();
          reader.onload = readerEvent => {
            var image = new Image();
            image.onload = () => {
              var canvas = document.createElement('canvas');
              canvas.width = this.previewImages[this.editIndex].width;
              canvas.height = this.previewImages[this.editIndex].height;
              canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
              this.finalCanvas.getContext('2d').drawImage(image, this.previewImages[this.editIndex].x, this.previewImages[this.editIndex].y, canvas.width, canvas.height);
              var dataUrl = canvas.toDataURL('image/jpeg');
              this.previewImages[this.editIndex].image = dataUrl;
            }
            image.src = readerEvent.target.result;
          }
          reader.readAsDataURL(file);
        } else {
          var image = new Image();
          image.onload = () => {
            var canvas = document.createElement('canvas');
            canvas.width = this.previewImages[this.editIndex].width;
            canvas.height = this.previewImages[this.editIndex].height;
            canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
            this.finalCanvas.getContext('2d').drawImage(image, this.previewImages[this.editIndex].x, this.previewImages[this.editIndex].y, canvas.width, canvas.height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            this.previewImages[this.editIndex].image = dataUrl;
          }
          image.crossOrigin = "anonymous";
          image.src = data.text;
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async deleteMenu(id) {
      if(id) {
        try {
          this.loading = true;
          const deleteMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/delete", {id: id});
          if ((deleteMenuResponse.status == 200 || deleteMenuResponse.status == 201) && deleteMenuResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Menu deleted successfully",
              type: "success"
            })
            await this.fetchMenus();
          } else {
            if(deleteMenuResponse.data.errorMessage && deleteMenuResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: deleteMenuResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        } finally {
          this.dummyMenu = JSON.parse(JSON.stringify(this.newMenu));
          this.addEditMode = false;
          this.loading = false;
        }
      }
    },
    async setDefault(id) {
      if(id) {
        try {
          const setDefaultResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/set-default", {id: id});
          if ((setDefaultResponse.status == 200 || setDefaultResponse.status == 201) && setDefaultResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Done",
              type: "success"
            })
          } else {
            if(setDefaultResponse.data.errorMessage && setDefaultResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: setDefaultResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, #fff);
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}

.table-row:first-child {
  margin-top: 35px;
}

.table-row:last-child {
  border-bottom: 0px;
}

.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip_container .tooltip_text {
  visibility: hidden;
  width: 80px;
  font-size: 13px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-white);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  padding: 5px 5px;
  line-height: 1.5;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -40px; 
  
}
.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
}

.tooltip_container .tooltip_text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ion-color-black) transparent transparent transparent;
}

</style>