<template>
  <ion-item class="shopify-wrapper">
      <ion-grid class="shopify-wrapper-grid">
        <ion-row class="first-row">
          <ion-col size-md="12" size-sm="12" class="left-panel">
            <stepProgress :steps="steps" :propActiveStep="propActiveStep" @onSelectStep="onSelectStep"></stepProgress>
            <ion-item class="step-item" v-if="propActiveStep == 0" lines="none">
              <div class="slide-content" >
                <div class="slide-header margin-next">Go to your shopify dashboard. Click on "Apps >" from the side menu</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsKncrsIIDA" height="auto" width="100%"></Image> </div>
                <div class="sub-text">Click on the "App and sales channel settings"</div>
                <div><Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsKnO3NUKDA" height="auto" width="100%"></Image></div>
              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 1" lines="none">
              <div class="slide-content">
                <div class="slide-header margin-next">Click on "Develop apps for your store" button</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsKmDiYILDA" height="auto" width="100%"></Image></div>

                <div class="slide-header margin-next">To start developing apps on your store, please click on "Allow custom app development" button</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsMmdvd0JDA" height="auto" width="100%"></Image></div>

                <div class="slide-header margin-next">Again click on "Allow custom app development" button to confirm!</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsPnUkeEKDA" height="auto" width="100%"></Image> </div>

                <div class="slide-header margin-next">Now click on "Create an app" button</div>
                <div> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsPHXmYsJDA" height="auto" width="100%"></Image> </div>

              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 2" lines="none">
              <div class="slide-content">
                <div class="slide-header margin-next">Fill in the details as instructed below and hit the "Create app" button</div>
                <div> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsIn0lqIIDA" height="auto" width="100%"></Image> </div>
              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 3" lines="none">
              <div class="slide-content">
                <div class="sub-text margin-next">Now under App development > Overview tab, you will have to configure scopes for both Admin API and Storefront API one-by-one</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsPnFj88IDA" height="auto" width="100%"></Image> </div>
                <div class="sub-text margin-next">Under "Admin API access scopes", ensure you enable Read & Write access permission for all of the options listed below and hit "Save":</div>
                <div class="unordered-list margin-next sub-text">
                  <ul type="disc">
                    <li>Store content like articles, blogs, comments, pages, and redirects</li>
                    <li>Customer details and customer groups</li>
                    <li>Orders, transactions and fulfillments</li>
                    <li>Products, variants and collections</li>
                    <li>Shipping rates, countries and provinces</li>
                    <li>Product information</li>
                    <li>Discounts</li>
                    <li>Inventory</li>
                    <li>Fulfillment services</li>
                  </ul>
                </div>
                <div> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsLmghJcLDA" height="auto" width="100%"></Image> </div>
              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 4" lines="none">
              <div class="slide-content">
                <div class="sub-text margin-next">Now click on "configure" tab to start granting scopes for "Storefront API integration" by clicking the "configure" button </div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsMnE1JgIDA" height="auto" width="100%"></Image> </div>
                <div class="sub-text margin-next">Under "Storefront API access scopes", ensure you enable access permission for all of the options listed below and hit "Save": </div>
                <div class="unordered-list margin-next margin-next">
                  <ul class="sub-text" type="disc">
                    <li>Read products, variants and collections</li>
                    <li>Read and modify customer details</li>
                    <li>Read and modify checkouts</li>
                    <li>Read content like articles, blogs and comments</li>
                  </ul>
                </div>
                <div> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsOmMzrMIDA" height="auto" width="100%"></Image> </div>
              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 5" lines="none">
              <div class="slide-content">
                <div class="sub-text margin-next">Go to "API credentials" tab and click on "Install app" in order to see the app credentials</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsKncroILDA" height="auto" width="100%"></Image> </div>

                <div class="sub-text margin-next">Enter your Shopify private app credentials as shown in the image below:</div>
                <div class="margin-next"> <Image image="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsJm2h8EIDA" height="auto" width="100%"></Image> </div>
                
                <ion-item class="margin-next ion-no-padding" :class="{error: form.isInvalidPrivatePassword}">
                  <InputValidation v-slot="slotProps" :inputVal="form.privatePassword" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="privatePassword" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidPrivatePassword'})">
                    <ion-label> Admin API access token </ion-label> <HelpTooltip title="Private password">Click on show and copy the private API password.</HelpTooltip>
                    <ion-input v-model="form.privatePassword" @change="slotProps.onChange"></ion-input>
                  </InputValidation>
                </ion-item>

                <ion-item class="margin-next ion-no-padding" :class="{error: form.isInvalidStorefrontAccessToken}">
                  <InputValidation v-slot="slotProps" :inputVal="form.storefrontAccessToken" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="privateStoreFrontAccessToken" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidStorefrontAccessToken'})">
                    <ion-label> Storefront API access token </ion-label>
                    <HelpTooltip title="Storefront access token">Scroll to the bottom of the page. In the storefront api section you can find the store front access token, copy and paste the same here.</HelpTooltip>
                    <ion-input v-model="form.storefrontAccessToken" @change="slotProps.onChange"></ion-input>
                  </InputValidation>
                </ion-item>

                <ion-item class="margin-next ion-no-padding" :class="{error: form.isInvalidPrivateKey}">
                  <InputValidation v-slot="slotProps" :inputVal="form.privateKey"
                    :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="privateKey"
                    @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidPrivateKey'})">
                    <ion-label> API key </ion-label>
                    <HelpTooltip title="Private API Key">You can find the private api key under the Admin api section.</HelpTooltip>
                    <ion-input v-model="form.privateKey" @change="slotProps.onChange"></ion-input>
                  </InputValidation>
                </ion-item>

                <ion-item class="margin-next ion-no-padding" :class="{error: form.isInvalidStoreUrl}">
                  <InputValidation v-slot="slotProps" :inputVal="form.storeUrl" :validateConf="{required: true, maxLength: 500, showErrorMessage: false, errorInputBorder: false}" ref="storeUrl" @onValidate="({isInvalid, onChange}) => validationHandler({isInvalid, onChange, inputName: 'isInvalidStoreUrl'})">
                    <ion-label> Shopify store URL</ion-label>
                    <HelpTooltip title="Shopify store url">You can find it at in your browser url.</HelpTooltip>
                    <ion-input v-model="form.storeUrl" @change="slotProps.onChange"></ion-input>
                  </InputValidation>
                </ion-item>
              </div>
            </ion-item>
            <ion-item class="step-item" v-if="propActiveStep == 6" lines="none">
              <div class="slide-content done">
                <div class="slide-header margin-next ion-text-center">Congratulations! You have successfully connected your Shopify Private App</div>
                <div class="sub-text ion-text-center margin-next">Now you can import your Shopify products and increase your sales. Please click the button below to import products.</div>
                <ion-item class="margin-next" lines="none">
                  <ion-button v-if="!isStepAtFirst" class="btn-import-products ion-no-padding" @click="actionClick(0)" fill="solid" color="primary" size="default">
                    Import Products
                  </ion-button>
                </ion-item>
              </div>
            </ion-item>
            <ion-item class="action" v-if="!isStepAtLast" lines="none">
              <ion-buttons slot="end">
                <ion-button v-if="!isStepAtFirst" class="btn-previous ion-no-padding" @click="actionClick(0)" fill="outline">
                  Previous Step
                  <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
                </ion-button>
                <ion-button v-if="!isStepAtLast" class="btn-next ion-no-padding" @click="actionClick(1)" fill="solid" :disabled="isNextButtonDisabled">
                  Next Step
                  <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
          </ion-col>
        </ion-row>
      </ion-grid>
  </ion-item>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapActions } from "vuex";
import stepProgress from "./step-progress.vue"
import HelpTooltip from "../HelpTooltip.vue";
import InputValidation from "../InputValidation.vue";
import Image from "../Image.vue";


const steps = [
  {
    title: 'Go to shopify dashboard',
    code: 'dashboard'
  },
  {
    title: 'Create new private app',
    code: 'new_app'
  },
  {
    title: 'Fill the details',
    code: 'fill_details'
  },
  {
    title: 'Set Admin API',
    code: 'admin_api'
  },
  {
    title: 'Allow access to Storefront',
    code: 'access_to_storefront'
  },
  {
    title: 'Enter keys',
    code: 'enter_keys'
  },
  {
    title: 'Done',
    code: 'done',
    disabled: true
  }
];

export default {
  components: { stepProgress, HelpTooltip, InputValidation, Image },
  data() {
    return {
      steps,
      propActiveStep: 0,
      selectedStep: steps[0],
      chevronForwardOutline,
      chevronBackOutline,
      helpCircleOutline,
      form: {
        privateKey: "",
        privatePassword: "",
        storefrontAccessToken: "",
        storeUrl: "",
        isInvalidPrivateKey: "",
        isInvalidPrivatePassword: "",
        isInvalidStorefrontAccessToken: "",
        isInvalidStoreUrl: "",
        invalidInputs: []
      },
      loading: false
    }
  },
  computed: {
    isStepAtFirst() {
      return this.propActiveStep === 0;
    },
    isStepAtLast() {
      return this.steps.length === this.propActiveStep + 1;
    },
    isNextButtonDisabled() {
      return this.propActiveStep == 5 && this.isFormInvalid;
    },
    isFormInvalid() { // used this compute variable to keep next button enabled/disabled
      return this.form.invalidInputs.length;
    }
  },
  watch: {
    propActiveStep(value) {
      if (value === 6) {
        this.steps[6].disabled = false;
      } else {
        this.steps[6].disabled = true;
      }
    }
  },
  methods: {
    ...mapActions({
      updateIntegration: "integration/updateIntegration"
    }),
    validationHandler({ isInvalid, onChange, inputName }) {
      if (isInvalid) {
        if (!this.form.invalidInputs.includes(inputName)) {
          this.form.invalidInputs.push(inputName);
        }
      } else {
        const index = this.form.invalidInputs.indexOf(inputName);
        if (index > -1) {
          this.form.invalidInputs.splice(index, 1);
        }
      }
      if (onChange) {
        this.form[inputName] = isInvalid;
      }
    },
    onSelectStep({ step, index }) {
      this.propActiveStep = index;
      this.selectedStep = step;
    },
    async actionClick(forward) {
      if (this.propActiveStep == 5 && forward) {
        const result = await this.connect();
        if (!result) {
          return;
        }
      }
      if (forward && this.propActiveStep < this.steps.length) {
        this.propActiveStep++;
      } else if (this.propActiveStep > 0) {
        this.propActiveStep--;
      }
      this.selectedStep = this.steps[this.propActiveStep];
    },
    async updateLocalState() {
      const easyParcelDataToUpdate = {
        integrationName: 'shopify',
        dataToUpdate: { is_active: true },
        storageName: 'jmpr_getCommerceData'
      };
      await this.updateIntegration(easyParcelDataToUpdate);
    },
    async connect() {
      const reqBody = {
        privateusername: this.form.privateKey,
        privatepassword: this.form.privatePassword,
        storefrontaccess: this.form.storefrontAccessToken,
        shop: this.form.storeUrl.replaceAll('https://', '').replaceAll('http://', '').trim()
      }
      this.loading = true;
      const message = {
        success: () => this.updateToast({ open: true, message: "Connected to shopify successfully.", type: "success" }),
        error: (message) => this.updateToast({ open: true, message: message || "Shopify connection failed.", type: "danger" })
      }
      try {
        const response = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "connect-shopify", reqBody);
        if (response.data.success) {
          await this.updateLocalState();
          message.success();
          this.loading = false;
          return true;
        }
        message.error(response.data.errorMessage);
      } catch (error) {
        message.error();
      }
      this.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
.shopify-wrapper-grid{
  height: 100%;
}
.shopify-wrapper-grid .first-row {
  height: 100%;
}
.btn-next{
  width: 160px;
  padding-right: 0;
}
.btn-next button { padding-right: 0; }
.btn-previous{
  width: 160px;
}
.slide-content{ padding-top: 10px; }
.margin-next{ margin-bottom: 10px; }
.unordered-list ul{ padding-left: 15px; }
.action ion-buttons {
  padding: 20px 0;
}
.btn-import-products {
  width: 160px;
}
.slide-content.done {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
}
.slide-content.done .sub-text{
  font-size: 14px;
  color: var(--ion-color-medium);
}

ion-item.error {
  --ion-item-border-color: var(--ion-color-danger);
  --border-color: var(--ion-color-danger);
}
:deep .input-validator-wrapper {
  width: 100%;
}
.step-item {
  min-height: 200px;
}
</style>