import axios from "axios";
import store from '../store/index';
 
const jwtInterceptor = axios.create({});

import { Preferences as Storage } from "@capacitor/preferences";
import { environment } from '/src/shared/env';

jwtInterceptor.interceptors.request.use((config) => {
  const authData = store.getters["auth/getAuthData"];
  if (authData == null) {
    return config;
  }
  config.headers.common["Authorization"] = `JWT ${authData.token}`;
  config.headers.common["userid"] = `${authData.authkey}`; //for CSV exports
  return config;
});
 
jwtInterceptor.interceptors.response.use(
  async (response) => {
    if(response.data.success === false && (response.data.session_expired === true || response.data.authorized === false)) {
      const authData = store.getters["auth/getAuthData"];
      if(!authData || !authData.refreshToken) {
        console.log("No refresh token present", authData);
        await Storage.clear();
        location.reload();
      } else {
        const payload = {
          refresh: authData.refreshToken,
        };
        var refreshResponse = null;
        try {
          refreshResponse = await axios.post(environment('VUE_APP_AUTH_API') + "token/refresh/", payload);
          if(refreshResponse && refreshResponse.data && refreshResponse.data.access) {
            await store.dispatch("auth/saveTokensToStorage", refreshResponse.data);
            response.config.headers["Authorization"] = `JWT ${refreshResponse.data.access}`;
            return axios(response.config);
          } else {
            console.log("Error in getting new token from refresh token", refreshResponse);
            await Storage.clear();
            location.reload();
          }
        } catch (error) {
          console.log("Error in refreshing token", error);
          await Storage.clear();
          location.reload();
        }
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default jwtInterceptor;