<template>
  <master-layout pageTitle="Customers">
    <ion-content role="feed">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)" >
        <ion-refresher-content :pulling-icon="chevronDownCircleOutline" pulling-text="Pull to refresh" refreshing-spinner="circles" refreshing-text="Refreshing..." >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="customersResultsLoading" :is-open="customersResultsLoading" cssClass="my-custom-class" message="Loading..." ></ion-loading>

      <ion-toolbar class="chat-toolbar" color="light" >
        <ion-badge slot="start" v-if="!searchedByText" color="secondary" style="margin-left: 15px;">{{ totalCustomerText }}</ion-badge>
        
        <template v-if="searchedByText">
          <h2 class="hide-xs searched-text ion-no-margin" slot="end" size="small" color="primary" @click="searchModalHandler" >Search By: {{ searchedByText }}</h2>
          <ion-buttons slot="end">
            <ion-button size="small" color="dark" @click="clearFilter" style="padding-left: 10px;" > Clear </ion-button>
          </ion-buttons>
        </template>
        <template v-else>
          <ion-buttons slot="end">
            <ion-button @click="searchModalHandler">
              <ion-icon slot="icon-only" :icon="searchOutline" ></ion-icon>
            </ion-button>
          </ion-buttons>
        </template>
      </ion-toolbar>

      <template v-if="searchedByText">
        <template v-if="customersResults.length > 0">
          <ion-list class="ion-no-padding">
            <ion-item v-for="customer in customersResults" :key="customer.id" @click="customer.unread = 0;" role="article" >
              <singleChatListCard @user_info_updated="(body) => customer[body.prop] = body.value" :customer="customer" crm />
            </ion-item>
          </ion-list>
          <ion-infinite-scroll @ionInfinite="loadMoreUsers($event)" :disabled="loadMore.isDisabled" >
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </template>
        <template v-else>
          <div class="text-center" v-if="customersResults.length == 0" style="width: 100%; padding: 15px;" >
            <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
            <h5>No customer found! 😟</h5>
            <ion-text color="medium">Seems like the one you're looking for, hasn't interacted with you yet</ion-text>
          </div>
        </template>
      </template>
      <div v-else-if="!customersResultsLoading">
        <div class="text-center" v-if="customersResults.length == 0" style="width: 100%; padding: 15px;" >
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No customers yet! 😟</h5>
          <ion-text color="medium">Seems like the one you're looking for, hasn't interacted with you yet</ion-text>
        </div>
        <div v-if="customersResults.length > 0">
          <ion-list class="ion-no-padding">
            <ion-item
              v-for="customer in customersResults"
              :key="customer.id"
              @click="customer.unread = 0;"
            >
              <singleChatListCard
                @user_info_updated="(body) => customer[body.prop] = body.value"
                :customer="customer"
                crm
              />
            </ion-item>
          </ion-list>

          <pagination
            class="pagination-wrapper"
            v-if="!searchedByText"
            :totalCount="totalCustomers"
            @navigate="customersListHandler"
            :currentPage="pagination.pageNumber"
          ></pagination>
        </div>
      </div>

    </ion-content>

    <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => showCrmButtonOptions = true">
      <ion-icon class="addIcon" slot="icon-only" :icon="swapVerticalOutline"></ion-icon>
    </button>

    <ion-action-sheet :is-open="showCrmButtonOptions" header="Select" :buttons="filteredAddOptions" @didDismiss="() => showCrmButtonOptions = false" > </ion-action-sheet>

    <ion-modal v-if="searchModal" :is-open="searchModal" @didDismiss="searchModalHandler()" >
      <ion-page>
        <ion-header>
          <ion-toolbar v-if="searchVal" color="light" >
            <ion-buttons slot="start">
              <ion-button @click="() => searchVal = undefined"> <ion-icon slot="icon-only" :icon="arrowBack" ></ion-icon> </ion-button>
            </ion-buttons>
            <h2 class="ion-no-margin">Search by {{searchVal}}</h2>
            <ion-buttons slot="end">
              <ion-button @click="searchModalHandler">
                <ion-icon slot="icon-only" :icon="close" ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-toolbar v-else>
            <h2 class="ion-no-margin">Search</h2>
            <ion-buttons slot="end">
              <ion-button @click="searchModalHandler">
                <ion-icon slot="icon-only" :icon="close" ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list v-if="searchVal" class="ion-no-padding" >
            <ion-item lines="none" v-if="searchVal === searchTypes.NameEmail" >
              <ion-label position="stacked">Name / email</ion-label>
              <ion-input :maxlength="100" type="text" class="fullBordered" v-model="search.searchByNameOrEmailText" @keyup.enter="customersListHandler" ></ion-input>
            </ion-item>
            <ion-item lines="none" v-if="searchVal === searchTypes.OrderId" >
              <ion-label position="stacked">Order ID</ion-label>
              <ion-input :maxlength="100" type="text" class="fullBordered" v-model="search.searchByOrderIdText" @keyup.enter="customersListHandler" ></ion-input>
            </ion-item>
            <ion-item lines="none" v-if="searchVal === searchTypes.Tag" >
              <ion-label position="stacked">Tag</ion-label>
              <ion-input :maxlength="100" type="text" class="fullBordered" v-model="search.searchByTagText" @keyup.enter="customersListHandler" ></ion-input>
            </ion-item>
            <ion-item lines="none" v-if="searchVal === searchTypes.Custom" >
              <ion-label position="stacked">Custom Field</ion-label>
              <ion-input :maxlength="100" type="text" class="fullBordered" v-model="search.searchByCustomFieldKey" @keyup.enter="customersListHandler" ></ion-input>
              <ion-label position="stacked">Custom Field Value</ion-label>
              <ion-input :maxlength="100" type="text" class="fullBordered" v-model="search.searchByCustomFieldText" @keyup.enter="customersListHandler" ></ion-input>
            </ion-item>
            <ion-item class="segment-selection" lines="none" v-if="searchVal === searchTypes.Segment" >
              <ion-list>
                <ion-item>
                  <ion-text v-if="segment.loading" color="medium" >Loading Segments...</ion-text>
                  <ion-select v-else-if="segment.list && segment.list.length" placeholder="Select Segment" :interface-options="segment.selectInterfaceOptions" v-model="search.segment" >
                    <ion-select-option v-for="segmentItem in segment.list" :key="segmentItem.id" :value="segmentItem.id" >{{ segmentItem.name }}</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-list>
            </ion-item>

            <section style="padding: 15px;">
              <ion-button expand="block" size="default" :disabled="!isSearchFormValid" @click="customersListHandler" >Search</ion-button>
            </section>
          </ion-list>
          <ion-list v-else class="ion-no-padding" >
            <ion-item @click="searchVal = searchTypes.NameEmail">
              <ion-label>Name / email</ion-label>
            </ion-item>
            <ion-item v-if="!IS_ASIA" @click="searchVal = searchTypes.OrderId">
              <ion-label>Order ID</ion-label>
            </ion-item>
            <ion-item @click="searchVal = searchTypes.Tag">
              <ion-label>Tag</ion-label>
            </ion-item>
            <ion-item @click="searchVal = searchTypes.Custom">
              <ion-label>Custom field</ion-label>
            </ion-item>
            <ion-item @click="searchVal = searchTypes.Segment">
              <ion-label>Segment</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>

    <ion-modal class="import-modal-wrapper" v-if="isOpenImportModal" :is-open="isOpenImportModal" @didDismiss="isOpenImportModal = false">
      <ion-page>
        <ion-header class="">
          <ion-toolbar>
            <h2>Import WhatsApp contacts via CSV</h2>
            <ion-buttons slot="end">
              <ion-button @click="isOpenImportModal = false">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content>
          <h3>Instructions & notes:</h3>
          <ul>
            <li><a href="https://jumper.ai/landing/resources/csv_files/jumperai-sample-csv-tsv-files/csv/jumperai-crm-import-whatsapp-contacts.csv" target="_blank" rel="noreferer noopener" ng-bind-html="selectedLangPackData.customers.click_here">Click here</a> to download the sample CSV file and add the details of contacts as per the sample file</li>
            <li><span ng-bind-html="selectedLangPackData.customers.instruction_2" class="ng-binding">These are the mandatory columns
                in the CSV file</span>:
              <ol type="1">
                <li><b>countrycode</b>: <span>Mobile number country code (eg.: "65" for Singapore)</span></li>
                <li><b>mobileNumber</b>: <span>Mobile number</span></li>
                <li><b>firstName</b>: <span>First name</span></li>
                <li><b>lastName</b>: <span>Last name</span></li>
                <li><b>email</b>: <span>Email ID</span></li>
              </ol>
            </li>
            <li>You can add a column named "TAG" using which you can add custom tags to your contact (each tag needs to be separated by "@" sign, for eg.: tag1@tag2@tag3)</li>
            <li>You can also add columns with any custom name in order to add "custom data fields" to your contact (for example we have added 2 columns in the sample CSV file named "custom_field_1_name" and "custom_field_2_name"). You can add as many custom fields as you want.</li>
            <li>Finally, once you are ready with your contacts CSV file, you can click on the button below to upload a file and import the contacts.</li>
            <li>Once all contacts are imported, you can simply reload the page to see your newly imported contacts!</li>
          </ul>
        </ion-content>

        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <template v-if="selectedFile">
              <div class="start-import-wrapper">
                <div class="selected-file-name-wrapper">
                  <div class="remove-file-button-wrapper">
                    <ion-buttons slot="start">
                      <ion-button @click="resetImport" fill="clear" color="danger">
                        <ion-icon size="small" :icon="trashOutline"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </div>
                  <div class="selected-text">Selected File:&nbsp;</div>
                  <div class="selected-file-name"><span>{{selectedFile.name}}</span></div>
                </div>
                <div class="import-button-wrapper">
                  <ion-buttons slot="start">
                    <ion-button :disabled="loadingImport" @click="handleUpload" fill="solid" color="primary">
                      {{ loadingImport ? 'Loading...' : 'Import'}}
                    </ion-button>
                  </ion-buttons>
                </div>
              </div>
            </template>
            <template v-else>
              <input type="file" style="display: none;" id="fileToImport" ref="fileToImport" @input="(event) => selectedFile = event.target.files[0]" accept=".csv" />
              <ion-button @click="() => $refs.fileToImport.click()" color="primary">Upload CSV & import</ion-button>
            </template>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <template v-if="csvExportWithDateRangeModal">
      <exportsModal :type="csvExportWithDateRangeModal_type" :isOpen="csvExportWithDateRangeModal" :title="csvExportWithDateRangeModal_title" @close="() => csvExportWithDateRangeModal = false"></exportsModal>
    </template>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, close, arrowBack, searchOutline, addCircleOutline, trashOutline, swapVerticalOutline } from "ionicons/icons";

import jwtInterceptor from "/src/shared/jwtInterceptor";
import chatMixin from "/src/mixins/chat.js";
/* import components */
import { IonItem, IonList, IonSelect, IonSelectOption, IonInfiniteScroll, IonInfiniteScrollContent, } from "@ionic/vue";
import singleChatListCard from "/src/components/singleChatListCard.vue";
import pagination from "/src/components/pagination.vue";
import exportsModal from '/src/components/exportsModal.vue';


const searchTypes = {
  NameEmail: "Name / email",
  OrderId: "Order ID",
  Tag: "Tag",
  Custom: "Custom field",
  Segment: "Segment",
};

export default {
  mixins: [chatMixin],
  components: { singleChatListCard, IonItem, IonList, IonSelect, IonSelectOption, pagination, IonInfiniteScroll, IonInfiniteScrollContent, exportsModal },
  data() {
    return {
      loading: true,
      chevronDownCircleOutline,
      close,
      arrowBack,
      totalCustomers_loadMore: false,
      activeCustomer: {},
      activeUserConversationDataResult: [],
      customersResults: [],
      selectedChannel: "all",
      customersResultsLoading: true,
      currentPaginationNumber: 1,
      searchByNameOrEmailText: "",
      searchByTagText: "",
      searchByCustomFieldKey: "",
      searchByAssignedToMeText: false,
      searchByAssignedToBotText: false,
      searchByAssignedToAgentText: "",
      searchByAssignedToAgentID: "",
      searchByUnassignedConvoText: false,
      searchByFollowupText: false,
      searchByArchivedUsersText: false,
      anySearchOrFilterIsApplied: false,
      searchModal: false,
      filterVal: undefined,
      searchVal: undefined,
      convListOffset: 10,
      agentFocusedMode: false,
      show_liveChatConversationsListAreaTabs: false,
      liveChatConversationsView_type: "1tabView",
      active_conversationsListTab: "activeChatsTab",
      searchOutline,
      addCircleOutline,
      swapVerticalOutline,
      searchTypes,
      totalCustomers: 0,
      segment: {
        list: [],
        loading: false,
        selectInterfaceOptions: {
          header: "Select Segment",
          translucent: true,
          cssClass: "select-wrapper",
        },
      },
      pagination: {
        offset: 0,
        size: 10,
        pageNumber: 1,
        total_customers: 0,
        total_pages: 0,
      },
      loadMore: {
        nextCursor: "",
        isDisabled: true,
      },
      search: {
        searchByNameOrEmailText: "",
        searchByOrderIdText: "",
        searchByTagText: "",
        searchByCustomFieldKey: "",
        searchByCustomFieldText: "",
        segment: "",
      },
      showCrmButtonOptions: false,
      addOptions: [
        {
          text: 'Import WhatsApp contacts via CSV',
          handler: () => {
            this.isOpenImportModal = true;
          }
        },
        {
          text: 'Export customers',
          handler: () => {
            this.totalCustomerText?.length > 0 ? this.csvExportWithDateRangeModal_open('customers', 'Export customers') : this.updateToast({ open: true, message: "No customers yet!", type: "danger" }) ;

          }
        }
      ],
      isOpenImportModal: false,
      isOpenExportModal: false,
      selectedFile: null,
      csvToUpload: null,
      trashOutline,
      loadingImport: false
    };
  },
  async ionViewWillEnter() {
    this.customersListHandler(null, 0, true);
    this.fetchSegments();
  },
  computed: {
    isSearchFormValid() {
      let isValid = true;
      const {
        searchByNameOrEmailText,
        searchByOrderIdText,
        searchByTagText,
        searchByCustomFieldKey,
        searchByCustomFieldText,
        segment,
      } = this.search;
      switch (this.searchVal) {
        case this.searchTypes.NameEmail:
          isValid = !!searchByNameOrEmailText?.trim();
          break;
        case this.searchTypes.OrderId:
          isValid = !!searchByOrderIdText?.trim();
          break;
        case this.searchTypes.Tag:
          isValid = !!searchByTagText?.trim();
          break;
        case this.searchTypes.Custom:
          isValid =
            !!searchByCustomFieldKey?.trim() &&
            !!searchByCustomFieldText?.trim();
          break;
        case this.searchTypes.Segment:
          isValid = !!segment;
          break;
        case !this.searchVal?.trim():
          isValid = false;
          break;
      }
      return isValid;
    },
    searchedByText() {
      let searchedBy = "";
      for (const property in this.search) {
        if (this.search[property]) {
          searchedBy = this.search[property];
          if (property == "segment") {
            const selectedSegmentId = searchedBy;
            const selectedSegmentObject = this.segment.list.find(
              (segment) => segment.id == selectedSegmentId
            );
            searchedBy = `${selectedSegmentObject.name}`;
          }
        }
      }
      return searchedBy;
    },
    totalCustomerText() {
      let totalCustomerText = "";
      if (this.customersResults) {
        const suffix = this.totalCustomers == 1 ? `Customer` : `Customers`;
        const numberFormatted = this.totalCustomers.toLocaleString();
        totalCustomerText = `${numberFormatted} ${suffix}`;
      }
      return totalCustomerText;
    },
    filteredAddOptions() {
      const options = [...this.addOptions]; 

      if (!this.whatsappConnected) {
        // remove "Import customers via CSV" option
        const index = options.findIndex(
          (option) => option.text === "Import WhatsApp contacts via CSV"
        );
        options.splice(index, 1);
      }

      return options;
    }
  },
  methods: {
    async doRefresh(event) {
      this.customersListHandler(null, 0, true);
      event.target.complete();
    },
    async fetchCustomersList(searchParams) {
      let reqBody = {
        channel: "all",
        retarget: "both",
      };
      if (this.pagination.offset) {
        reqBody.offset = this.pagination.offset;
        reqBody.size = this.pagination.size;
      }
      if (searchParams) {
        reqBody = { ...reqBody, ...searchParams };
      }
      const response = await jwtInterceptor.post(
        this.environment("VUE_APP_ROOT_API") + "fbrt/minimal-user-list",
        reqBody
      );
      return response;
    },
    async searchCustomersList(searchParams) {
      let reqBody = {
        channel: "all",
        size: 10,
        retarget: "both",
      };
      if (this.pagination.offset) {
        reqBody.offset = this.pagination.offset;
      }
      if (this.loadMore.nextCursor) {
        reqBody.cursor = this.loadMore.nextCursor;
      }
      if (searchParams) {
        reqBody = { ...reqBody, ...searchParams };
      }
      const response = await jwtInterceptor.post(
        this.environment("VUE_APP_ROOT_API") + "fbrt/user-list",
        reqBody
      );
      return response;
    },
    buildSearchParams() {
      const {
        searchByNameOrEmailText: search_string,
        searchByOrderIdText: orderid,
        searchByTagText: tags,
        segment: segmentid,
        searchByCustomFieldKey: name,
        searchByCustomFieldText: value,
      } = this.search;
      let params;
      if (search_string) {
        params = { search_string };
      }
      if (orderid) {
        params = { orderid };
      }
      if (tags) {
        params = { tags };
      }
      if (name) {
        params = { custom_tags: JSON.stringify([{ name, value }]) };
      }
      if (segmentid) {
        params = { segmentid };
      }
      return params;
    },
    buildCustomerList(response, loadMore) {
      if (response.status == 200 || response.status == 201) {
        if (!loadMore) {
          this.customersResults = [];
        }
        for (var user of response.data.data) {
          if (typeof user.conversationid == "undefined") {
            user.conversationid = user.id;
          }

          if (user.name == "" || user.name == null || user.name == "None") {
            user.name = "unrecorded";
          }

          if (user.email == "" || user.email == null || user.email == "None") {
            user.email = "unrecorded";
          }

          user["unread"] = 0;

          var conversationidFoundBool = false;

          // for (var i = 0; i < this.customersResults.length; i++) {
          //   if (
          //     user.conversationid == this.customersResults[i].conversationid
          //   ) {
          //     conversationidFoundBool = true;
          //   }
          // }

          if (conversationidFoundBool == false) {
            if (typeof user.active != "undefined") {
              if (user.active == null) {
                user.active = true;
              }
            }

            var obj = user;
            obj.id = user.conversationid;
            obj.currentlyOpendChat = false;
            obj.channel_id = obj.channel;
            obj.channel = this.getChannelFromPageActive(obj.channel);

            if (
              this.calculate24Plus1RuleForCustomer(user.userReplyTimestamp) >=
              24
            ) {
              if (
                obj.channel == "facebook" ||
                obj.channel == "whatsapp" ||
                obj.channel == "instagram_off"
              ) {
                //obj.active = false;
                obj.page_msg_subscription_restriction = true;

                if (obj.channel == "whatsapp") {
                  //this.isWhatsappChat_old24H = true;
                }
              } else {
                obj.page_msg_subscription_restriction = false;

                if (obj.channel == "whatsapp") {
                  //this.isWhatsappChat_old24H = false;
                  //this.isWhatsappChat_sendTemplate = false;
                }
              }
            } else {
              obj.page_msg_subscription_restriction = false;

              if (obj.channel == "whatsapp") {
                //this.isWhatsappChat_old24H = false;
                //this.isWhatsappChat_sendTemplate = false;
              }
            }
            this.customersResults.push(obj);
          }
        }
      } else {
        this.totalCustomers_loadMore = false;
      }
    },
    resetPagination() {
      const pagination = {
        offset: 0,
        size: 10,
        pageNumber: 1,
        total_customers: 0,
        total_pages: 0,
      };
      this.pagination = { ...this.pagination, ...pagination };
    },
    resetLoadMore() {
      this.loadMore.nextCursor = "";
      this.loadMore.isDisabled = true;
    },
    loadMoreHandler(response) {
      if (!response?.data?.next) {
        this.loadMore.isDisabled = true;
      } else {
        this.loadMore.nextCursor = response.data.next;
        this.loadMore.isDisabled = false;
      }
    },
    async customersListHandler(data) {
      if (data) {
        const { offset, pageNumber } = data;
        this.pagination.offset = offset;
        this.pagination.pageNumber = pageNumber;
      }
      try {
        this.searchModal = false;
        this.customersResultsLoading = true;
        this.loadMore.nextCursor = "";
        const searchParams = this.buildSearchParams();
        const isRequestForSearch = searchParams && !this.search.segment;
        let response;
        if (isRequestForSearch) {
          response = await this.searchCustomersList(searchParams);
          this.resetPagination();
          this.loadMoreHandler(response);
        } else {
          response = await this.fetchCustomersList(searchParams);
          this.totalCustomers = response.data.total_customers;
        }
        this.buildCustomerList(response);
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.customersResultsLoading = false;
      }
    },
    async loadMoreUsers(ev) {
      const searchParams = this.buildSearchParams();
      const response = await this.searchCustomersList(searchParams);
      this.loadMore.nextCursor = response.data.next;
      this.buildCustomerList(response, true);
      this.loadMoreHandler(response);
      ev.target.complete();
    },
    searchModalHandler() {
      this.searchModal = !this.searchModal;
    },
    clearFilter() {
      for (const property in this.search) {
        this.search[property] = "";
      }
      this.searchVal = "";
      this.customersListHandler();
      this.resetLoadMore();
    },
    async fetchSegments() {
      try {
        this.segment.loading = true;
        const params = {
          shopname: this.user.referallshopName,
        };
        const response = await jwtInterceptor.get(
          this.environment("VUE_APP_ROOT_API") + "fbrt/customer-segment",
          { params }
        );
        if (response.data) {
          this.segment.list = response.data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.segment.loading = false;
      }
    },
    resetImport() {
      this.selectedFile = null,
      this.csvToUpload = null
    },
    async handleUpload() {
      try {
        this.loadingImport = true;
        if (this.selectedFile && this.selectedFile.name.lastIndexOf('.csv') !== -1) {
          let newCsv = new FileReader();
          newCsv.readAsText(this.selectedFile);
          newCsv.onload = async (event) => {
            this.loadingImport = true;
            let csv = event.target.result;
            let lines = csv.split("\n");
            let headers = lines[0].split(",");
            if (headers.length < 4) {
              this.loadingImport = false;
              this.updateToast({ open: true, message: "The uploaded CSV file doesn't have expected columns!", type: "danger" })
              return;
            }
            var reqBody = new FormData();
            reqBody.append('file0', this.selectedFile);
            const responseUpload = await this.uploadImage(this.selectedFile, '/whatsapp/upload-user/csv');
            if (responseUpload?.success) {
              this.updateToast({ open: true, message: "All contacts in the CSV have been imported successfully!", type: "success" });
              this.resetImport();
            } else {
              this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
            }
            this.loadingImport = false;
          }
          newCsv.onerror = () => {
            this.updateToast({ open: true, message: "Unable to read " + this.selectedFile.name, type: "danger" })
            this.loadingImport = false;
          };
        } else {
          this.updateToast({ open: true, message: "Please add a valid CSV", type: "danger" })
        }
      } catch (error) {
        console.error("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger" })
      } finally {
        this.loadingImport = false;
      }
    },
  },
};
</script>
<style scoped>
.searched-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* flex: 1; */
  text-align: left;
  max-width: 100%;
  width: 100%;
}
.import-modal-wrapper ion-header, .import-modal-wrapper ion-content {
  padding-left: 10px;
  --padding-start: 10px;
}
.import-modal-wrapper ion-content ul {
  line-height: 24px;
}
.start-import-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected-file-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
}
.selected-file-name-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
}
.selected-text{
  flex: 1;
  max-width: 103px;
}
.remove-file-button-wrapper {
  flex: 1;
  max-width: 38px;
}
.import-button-wrapper {
  flex: 1;
  max-width: 70px;
}
</style>