<template>
  <div class="text-center" style="margin-top: 5vh;">
    <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIHJ85sKDA" style="max-width: 95%; max-height: 300px; height: auto; width: auto;" />

    <ion-text>
      <h4 class="title">The page you are looking for doesn't exist!</h4>
    </ion-text>
    <ion-text color="medium">
      <p class="description">You may have mistyped the address or the page may have moved.</p>
    </ion-text>
  </div>
</template>