<template>
	<master-layout pageTitle="Viber">
		<ion-loading
		v-if="loading"
		:is-open="loading"
		cssClass="my-custom-class"
		message="Loading..."
		></ion-loading>
		<div v-else>
			<h2 class="heading ion-no-margin" style="padding-left: 10px">Viber</h2>
			<div class="model-sections" v-if="!viberConnected">
				<ion-item lines="none" style="padding: 0px 15px;">
					<ion-avatar slot="start" style="height: auto; width: 38px;">
						<img src="https://jumperdevnew.appspot.com/landing/resources/images/viber.png" />
					</ion-avatar>
					<ion-label>
						<h3 class="title">Connect Viber</h3>
						<p class="sub-title">Run promotions, take orders, support customers, and send updates on Viber</p>
					</ion-label>
				</ion-item>
				<div class="prdct-section">
					<ion-input type="text" v-model="authtoken" placeholder="Enter your Viber Auth token" class="input_custom_style domainInput"></ion-input>
					<ion-button @click="connectViber" color="dark" fill="solid" class="ion-no-margin connect-bttn">
						Connect
					</ion-button>
					</div>
			</div>
			<div v-else>
				<div class="model-sections">
          <ion-item lines="none" style="padding: 15px;">
            <ion-avatar slot="start" style="height: auto; width: 38px;">
              <img src="https://jumperdevnew.appspot.com/landing/resources/images/viber.png"/>
            </ion-avatar>
            <ion-label class="ion-no-margin">
              <h3 class="title">Connected Viver Account</h3>
              <p class="sub-title">Following Viber account is connected to Jumper.ai</p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-col class="ion-no-padding" size="12">
              <ion-item lines="none" class="viber-container">
                <ion-label class="ion-no-margin">
                  <h3 class="channel-title">{{ viberSocialData.channelname }}</h3>
                </ion-label>
                <ion-button @click="() => disconnectPageMode = true" fill="outline" color="danger">Disconnect Page</ion-button>
              </ion-item>
            </ion-col>
          </ion-item>
         </div>
			</div>
		</div>
		<ion-alert v-if="disconnectPageMode" :is-open="disconnectPageMode" header="Are you sure?" :message="disconnectMsg"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disconnectPageMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnectViber()
        },
      }]"
      @didDismiss="() => disconnectPageMode = false"
    />
	</master-layout>
</template>
    

<script>
import { helpOutline } from "ionicons/icons";
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
	data() {
		return {
			loading: false,
			helpOutline,
			authtoken: undefined,
			disconnectPageMode: false
		};
	},
	computed: {
		disconnectMsg() {
			return `${this.dashboardName} will not be able to converse and help your customers check-out with their order if they comment on your channel with your #hashtag. Are you sure you want to disconnect your Viber account?`;
		}
	},
	methods: {
		async connectViber() {
			try {
				this.loading = true;
				let reqBody = {
					authtoken: this.authtoken
				}
				let connectViberResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "viber-page", reqBody);
				if ((connectViberResponse.status == 200 || connectViberResponse.status == 201) && connectViberResponse.data.success) {
					this.updateToast({
						open: true,
						message: "Your Viber Account is now connected!",
						type: "success"
					})
					await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
					window.location.href = window.location.pathname;
				}
				else {
					if(connectViberResponse.data.errorMessage && connectViberResponse.data.errorMessage.length) {
						this.updateToast({
							open: true,
							message: connectViberResponse.data.errorMessage,
							type: "danger"
						})
					} else {
						this.updateToast({
							open: true,
							message: "Something went wrong",
							type: "danger"
						})
					}
				}
			} catch (error) {
				console.log("Exception", error);
				this.updateToast({
					open: true,
					message: "Something went wrong",
					type: "danger"
				})
			} finally {
				this.loading = false;
			}
		},
		async confirmDisconnectViber() {
			this.loading = true;
      try {
        let disconnectViberResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "delete-social", { platform: "viber" } );
        if ((disconnectViberResponse.status == 200 || disconnectViberResponse.status == 201) && disconnectViberResponse.data.success) {
          if (disconnectViberResponse.data.delete)
            this.updateToast({
              open: true,
              message: "Viber Account disconnected successfully",
              type: "success",
            });
					await this.Storage.remove({ key: "jmpr_fetchSocialData" });
					window.location.href = window.location.pathname;
        } else {
          if(disconnectViberResponse.data.errorMessage && disconnectViberResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: disconnectViberResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
		}
	}
};
</script>

<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
	margin: 20px;
}
.prdct-section {
	padding: 0px 25px;
	margin-top: 20px;
}
.domainInput {
  display: inline-block;
  width: 60%;
	height: 36px;
	border: 1px solid;
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px 0px 0px 5px;
}

ion-button.connect-bttn{
	--border-radius: 0px
}

.connect-bttn {
	margin-bottom: 12px;
}

.channel-title {
	font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.viber-container {
	border: 1px solid #eee;
	border-radius: 5px;
	padding: 10px;
}

.heading{
	font-size: 28px;
	font-weight: 400;
	color: var(--ion-color-black);
	margin: 15px;
}

.title {
	font-size: 28px;
	font-weight: 400;
	color: var(--ion-color-black);
	margin-top: 15px;
}
</style>