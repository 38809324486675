<template>
  <master-layout pageTitle="Data signals">
    <ion-content>
      <ion-loading
    v-if="loading"
    :is-open="loading"
    cssClass="my-custom-class"
    message="Loading..."
    ></ion-loading>
      <section class="table-responsive-container" v-else>
        <table>
          <thead>
            <tr>
              <th class="text-left">Report name</th>
              <th class="text-right">Download</th>
            </tr>
          </thead>
          <tbody v-for="result in reportsDataResult" :key="result.id">
            <tr>
              <td class="text-left">
                <ion-text class="displayBlock">
                  {{result.name}}
                </ion-text>
              </td>
              <td class="text-right">
                <ion-button fill="outline" size="small" color="dark" @click="csvExportWithDateRangeModal_open(result.id,result.name)">
                  Download
                </ion-button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </ion-content>

    <exports-modal :type="csvExportWithDateRangeModal_type" :isOpen="csvExportWithDateRangeModal" :title="csvExportWithDateRangeModal_title" @close="() => csvExportWithDateRangeModal = false"></exports-modal>
  </master-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { close, timeOutline, calendar } from 'ionicons/icons';
import exportsModal from '/src/components/exportsModal.vue';
export default {
  components: {
    exportsModal
  },
  data() {
    return {
      loading: false,
      close,
      timeOutline,
      calendar,
      reportsDataResult: [
        {
          "id": "greenwichbcg_order",
          "name": "Order - Paid and Unpaid"
        },
        {
          "id": "greenwichbcg_firstorder",
          "name": "First Orders"
        },
        {
          "id": "greenwichbcg_adevent",
          "name": "Ad Events"
        },
        {
          "id": "greenwichbcg_b5button",
          "name": "B5 Button"
        },
        {
          "id": "greenwichbcg_b5typed",
          "name": "B5 Typed"
        },
        {
          "id": "greenwichbcg_b14",
          "name": "B14"
        },
        {
          "id": "greenwichbcg_b14dropoff",
          "name": "B14 Dropoff"
        },
        {
          "id": "greenwichbcg_b19",
          "name": "B19"
        },
        {
          "id": "greenwichbcg_b19selectiondropoff",
          "name": "B19 Selection Dropoff"
        },
        {
          "id": "greenwichbcg_b23",
          "name": "B23"
        },
        {
          "id": "greenwichbcg_b24",
          "name": "B24"
        },
        {
          "id": "greenwichbcg_b25",
          "name": "B25"
        },
        {
          "id": "greenwichbcg_b2728",
          "name": "B27/28"
        },
        {
          "id": "greenwichbcg_b29",
          "name": "B29"
        },
        {
          "id": "greenwichbcg_b34",
          "name": "B34"
        },
        {
          "id": "greenwichbcg_b35",
          "name": "B35"
        },
        {
          "id": "greenwichbcg_b4245",
          "name": "B42-45"
        },
        {
          "id": "greenwichbcg_b48",
          "name": "B48"
        },
        {
          "id": "greenwichbcg_b52",
          "name": "B52"
        },
        {
          "id": "greenwichbcg_b54",
          "name": "B54"
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    })
  },
  methods: {
    
  }
}
</script>