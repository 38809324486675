export function jwtDecrypt(token) {
  if(!token) return null;
  try {
    var base64Url = token.split(".")[1];
    if(!base64Url) return null;
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
    );
  
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.log("Exception", error);
    return null;
  }
}