<template>
  <master-layout pageTitle="Instagram Icebreakers">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="ion-hide-md-up toolbar">
          <ion-segment scrollable color="dark" v-model="activeTab">
            <ion-segment-button value="menu">
              <ion-label class="common-label">Icebreakers</ion-label>
            </ion-segment-button>
            <ion-segment-button value="preview">
              <ion-label class="common-label">Preview</ion-label>
            </ion-segment-button>
          </ion-segment>
         </ion-toolbar>
        <div v-if="changed">
          <ion-item lines="none">
            <p class="connectNote" style="color: #aaa; font-size: 13px; padding-top: 2px;">
              <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
              You have pending changes to be saved, please click on "Save all changes" button!
            </p>
            <ion-button slot="end" @click="saveChanges" color="dark">Save</ion-button>
          </ion-item>
        </div>
        <div class="text-center" v-if="allIceBreakers.length == 0" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No ice-breakers yet! 😟</h5>
          <ion-text color="medium">Seems like there are no ice-breakers added yet</ion-text>
        </div>
        <ion-grid>
          <ion-row class="row-margin">
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="ion-no-padding">
              <div v-if="activeTab === 'menu'">
                <div v-if="allIceBreakers.length > 0" class="model-sections">
                  <ion-list class="ion-no-padding">
                    <div v-for="(thisBreaker, bIndex) in allIceBreakers" :key="bIndex" class="container">
                      <ion-row>
                        <ion-col size="8">
                          <div class="container_title">
                            <p class="container_subtitle"> {{ thisBreaker.question }}</p>
                          </div>
                        </ion-col>
                        <ion-col size="4">
                          <ion-button @click="() => { addEditModal = 'detailed'; editIndex = bIndex; }" fill="clear" size="default" color="dark">
                          <ion-icon :icon="pencilOutline"></ion-icon>
                          </ion-button>
                          <ion-button  @click="() => allIceBreakers.splice(bIndex, 1)" size="default" fill="clear" color="danger">
                            <ion-icon :icon="trashOutline"></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </div>
                  </ion-list>
                </div>
              </div>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="ion-no-padding">
               <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
                <i-phone-preview  v-if="allIceBreakers.length > 0">
                    <div v-for="(thisBreaker, bIndex) in allIceBreakers" :key="bIndex" class="preview-container">
                      <p class="sub-title ion-no-margin"> {{ thisBreaker.question}}</p>
                    </div>
                </i-phone-preview>
               </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-button v-if="allIceBreakersClone.length > 0" slot="end" @click="() => deleteAllMode = true" color="dark">
          <ion-icon :icon="trashBinOutline"></ion-icon>
          &nbsp;Delete All
        </ion-button>
        <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => { addEditModal = 'detailed'; editIndex = -1 }">
          <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
        </button>
      </div>
    </ion-content>
    <popper v-if="isPopperOpen" is_agent_or_group_req :type="addEditModal" :config="popperConfig" @close="() => {editIndex = -1; addEditModal = undefined;}" @submit="popperSubmit" />
    <ion-alert
      v-if="deleteAllMode"
      :is-open="deleteAllMode"
      header="Are you sure?"
      :message="'You want to delete all the ice-breakers?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => deleteAllMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          deleteAll();
          deleteAllMode = false;
        },
      }]"
      @didDismiss="() => deleteAllMode = false"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashOutline, pencilOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import popper from '/src/components/popper';
import iPhonePreview from '/src/components/iPhonePreview';

export default {
  components: {popper, iPhonePreview},
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashOutline,
      pencilOutline,
      allIceBreakers: [],
      allIceBreakersClone: [],
      editIndex: -1,
      addEditModal: undefined,
      deleteAllMode: false,
      activeTab: 'menu'
    }
  },
  async ionViewWillEnter(){
    if(this.instagramConnected && this.instagramSocialData && this.instagramSocialData.length && this.instagramSocialData[0].pageid) this.init();
    else this.$router.go({ path: '/sales-channel/instagram' });
  },
  computed: {
    isPopperOpen() {
      return this.addEditModal === 'simple' || this.addEditModal === 'detailed';
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined;
      if(this.editIndex > -1) {
        text = this.allIceBreakers[this.editIndex].question;
        payload = this.allIceBreakers[this.editIndex].payload;
      }
      return { text: text, action: action, payload: payload }
    },
    changed() {
      return JSON.stringify(this.allIceBreakers) !== JSON.stringify(this.allIceBreakersClone)
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchIceBreakers();
    },
    async fetchIceBreakers() {
      try {
        this.loading = true;
        const allIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-instagram-icebreakers", { pageid: this.instagramSocialData[0].pageid });
        if ((allIceBreakersResponse.status == 200 || allIceBreakersResponse.status == 201) && allIceBreakersResponse.data.success) {
          this.allIceBreakers = allIceBreakersResponse.data.ice_breakers;
          this.allIceBreakersClone = JSON.parse(JSON.stringify(this.allIceBreakers));
        } else {
          if(allIceBreakersResponse.data.errorMessage && allIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: allIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    popperSubmit(data) {
      let question = data.text; let payload = undefined;
      switch(data.action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
      }
      if(this.editIndex > -1) {
        this.allIceBreakers[this.editIndex].question = question;
        this.allIceBreakers[this.editIndex].payload = payload;
        this.editIndex = -1;
      } else this.allIceBreakers.push({ question: question, payload: payload });
      this.addEditModal = undefined;
    },
    async saveChanges() {
      try {
        this.loading = true;
        let reqBody = {
          pageid: this.instagramSocialData[0].pageid,
          breakers: JSON.stringify(this.allIceBreakers)
        }
        let saveIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'instagram-icebreakers', reqBody);
        if ((saveIceBreakersResponse.status == 200 || saveIceBreakersResponse.status == 201) && saveIceBreakersResponse.data.success) {
          this.init();
        } else {
          if(saveIceBreakersResponse.data.errorMessage && saveIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async deleteAll() {
      try {
        this.loading = true;
        let reqBody = {
          pageid: this.instagramSocialData[0].pageid
        }
        let saveIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'delete-instagram-icebreakers', reqBody);
        if ((saveIceBreakersResponse.status == 200 || saveIceBreakersResponse.status == 201) && saveIceBreakersResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Successfully deleted all the Ice-Breakers for this Instagram Page",
            type: "succcess"
          })
          this.allIceBreakers = [];
          this.allIceBreakersClone = [];
        } else {
          if(saveIceBreakersResponse.data.errorMessage && saveIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteAllMode = false;
      }
    }
  }
}
</script>

<style scoped>
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding: 20px 0px;
  border-radius: 10px;
}
.row-margin {
  margin-left: 60px;
  margin-top: 20px;
}
.container_title {
  border: 1px solid #eee;
  margin-left: 20px;
}
.container_subtitle {
  font-size: 16px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
  margin-left: 10px;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.jinput {
  width: 98%;
  background: transparent;
}
ion-toolbar {
  --background: var(--ion-color-white);
}
.preview-container{
  background-color: var(--ion-color-white);
  box-shadow: 0 0 6px #b2b2b2;
  padding: 5px 8px;
  max-width: 150px;
  margin: 10px;
  overflow: hidden;
  word-wrap: break-word;
  border-radius: 8px;
}
.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}

.container{
  padding-bottom: 10px;
}

@media only screen and (max-width: 760px) {
  .row-margin {
    margin-left: 0px;
  } 
  .prevMobile {
    display: block;
  }
 .prevDesktop {
    display: none;
  }
}
</style>