<template>
  <master-layout pageTitle="Instagram Business Settings">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
         <div class="model-sections">
          <ion-item lines="none" style="padding: 15px;">
            <ion-avatar slot="start" style="height: auto; width: 38px;">
              <img src="https://jumperdevnew.appspot.com/landing/resources/images/instagram.png"/>
            </ion-avatar>
            <ion-label>
              <h3 class="title">Connected Instagram Page</h3>
              <p class="sub-title">Following Instagram account is connected to Jumper.ai</p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-col class="ion-no-padding"  size="12">
              <ion-item lines="none" class="insta-container">
                <ion-label v-for="thisPage in instagramSocialData" :key="thisPage.id">
                  <h3 class="title">{{ thisPage.name }}</h3>
                </ion-label>
                <ion-button @click="() => disconnectPageMode = true" fill="outline" color="danger">Disconnect Page</ion-button>
              </ion-item>
            </ion-col>
          </ion-item>
         </div>
      </div>
    </ion-content>
    <ion-alert
      v-if="disconnectPageMode"
      :is-open="disconnectPageMode"
      header="Are you sure?"
      message="Jumper will not be able to respond to your customers if they comment on your photos with your product #hashtags. Are you sure you want to disconnect your Instagram account?"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disconnectPageMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnectPage()
        },
      }]"
      @didDismiss="() => disconnectPageMode = false"
    />
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  mounted() {
    if(!this.instagramConnected) this.$router.replace({ path: '/sales-channel/instagram' });
  },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      disconnectPageMode: false,
       welcomeMessage: {
        theme_color: undefined,
        text_color: undefined,
        intent_message: undefined
      },
    }
  },
  methods: {
    async doRefresh(event) {
      event.target.complete();
    },
    async confirmDisconnectPage() {
      this.loading = true;
      try {
        let disconnectInstaResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "delete-social",
          { platform: "instagram" }
        );
        if (
          (disconnectInstaResponse.status == 200 ||
            disconnectInstaResponse.status == 201) &&
          disconnectInstaResponse.data.success
        ) {
          if (disconnectInstaResponse.data.delete)
            this.updateToast({
              open: true,
              message: "Instagram disconnected successfully",
              type: "success",
            });
          await this.Storage.remove({ key: "jmpr_fetchSocialData" });
          this.$router.replace({ path: '/sales-channel/instagram' });
        } else {
          if (
            disconnectInstaResponse.data.errorMessage &&
            disconnectInstaResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: disconnectInstaResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

ion-button {
  margin-left: 5px;
  margin-bottom: 10px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}
.insta-container {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
}

</style>