<template>
  <ion-item
    class="input-validator-wrapper item ion-no-padding ion-no-margin"
    lines="none"
    :class="{'vonage-input-error': !isValid, 'no-error-border': !errorInputBorder}"
  >
    <slot
      ref="inputValidation"
      :onChange="errorHandler"
    ></slot>

  </ion-item>
  <ion-item
    class="ion-no-padding ion-no-margin"
    lines="none"
    v-if="showErrorMessage"
  >
    <div
      v-if="!isValid"
      class="vonage-input-error-message"
    >{{errorMessage}}</div>
  </ion-item>
</template>

<script>
import { ellipsisVerticalOutline } from "ionicons/icons";

export default {
  data: () => {
    return {
      popoverOpen: false,
      event: null,
      ellipsisVerticalOutline,
      isValid: true, // flag used to show actual error message. this is done to avoid showing error message immediately on form init.
      invalidInput: false, // flag to determine whether input is valid or not.
      errorInputBorder: true,
      errorMessage: "Please enter valid input.",
      showErrorMessage: true
    };
  },
  emits: ["onValidate"],
  inheritAttrs: false,
  props: {
    inputVal: {
      type: String,
      default: "",
    },
    validateConf: {
      type: Object,
      default: () => {},
    },
    validateOnMount: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.validateOnMount) {
      this.errorHandler();
    } else {
      this.validate();
    }
    if (this.validateConf.errorInputBorder !== undefined) {
      this.errorInputBorder = this.validateConf.errorInputBorder;
    }
    if (this.validateConf.errorMessage !== undefined) {
      this.errorMessage = this.validateConf.errorMessage;
    }
    if (this.validateConf.showErrorMessage !== undefined) {
      this.showErrorMessage = this.validateConf.showErrorMessage;
    }
  },
  watch: {
    inputVal() {
      this.errorHandler();
    },
  },
  methods: {
    errorHandler() {
      this.validate(true);
      if (this.invalidInput) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
    validate(onChange =false) {
      const elmValue = this.inputVal;
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const { required, minVal, maxVal, minLength, maxLength, email, regEx } =
        this.validateConf;
      this.invalidInput = false;
      if (required && (elmValue?.trim() == "" || !elmValue)) {
        this.invalidInput = true;
      }

      if (minVal && elmValue < minVal) {
        this.invalidInput = true;
      }

      if (maxVal && elmValue < maxVal) {
        this.invalidInput = true;
      }

      if (minLength && elmValue?.length < minLength) {
        this.invalidInput = true;
      }

      if (maxLength && elmValue?.length > maxLength) {
        this.invalidInput = true;
      }

      if (email && !validateEmail(elmValue)) {
        this.invalidInput = true;
      }

      if (regEx && elmValue) {
        const regEx = new RegExp(regEx, "gi");
        this.invalidInput = regEx.match(elmValue);
      }
      this.$emit("onValidate", { isInvalid: this.invalidInput, onChange });
    },
  },
};
</script>

<style>
ion-item.vonage-input-error.item input,
ion-item.vonage-input-error.item textarea {
  background: none;
  outline: none;
  -webkit-appearance: none !important;
  box-shadow: none !important;
  border: none !important;
}
</style>
<style scoped>
ion-item {
  --min-height: auto;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}
.vonage-input-error {
  border: 1px solid red;
  border-radius: 5px;
}
.vonage-input-error.no-error-border {
  border: none;
}
.vonage-input-error .native-input,
.vonage-input-error .native-textarea {
  border: none !important;
}
.vonage-input-error-message {
  font-size: 10px;
  color: red;
  padding: 5px;
}
</style>