<template>
  <master-layout pageTitle="Web">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <div>
          <h2 class="title ion-no-margin" style="margin-top: 20px">Web</h2>
        </div>
        <ion-grid>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer widget">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/attentive-widget')">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-web-sell-via-chatbox.svg"/>
                  </ion-thumbnail>
                  <ion-label style="margin-top: 26px">
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Attentive widget</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Grab your customers attention and start a conversation in the right context. Embed a conversation anywhere - on your website, product pages, blog articles, FAQ pages and more.</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" @click="() => inviteMode = true">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-web-open-web-store.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Open web store</h2>
                    <p>http://lorealbrands.jumperdevnew.appspot.com</p>
                    <a class="btn textButton whiteBtnWithIconOnLeft fullWidthSquareDiv" :href="'http://' + user.shopName + '.jumperdevnew.appspot.com'" target="_blank" rel="noreferer noopener" style="width: auto; margin-right: 10px;">
                      <img width="20" :src="getShopLogoImage" style="vertical-align: middle; margin-right: 10px;" />
                      <span style="vertical-align: middle;">Conversational</span>
                      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-long-arrow-right fa-w-14 fa-2x" style="height: 18px; vertical-align: middle; margin-left: 5px;"><path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                    </a>
                    <a class="btn textButton whiteBtnWithIconOnLeft fullWidthSquareDiv" :href="'http://' + user.shopName + '.jumperdevnew.appspot.com/store'" target="_blank" rel="noreferer noopener" v-if="accountServiceType !== 'restaurant'" style="width: auto;">
                      <img width="20" :src="getShopLogoImage" style="vertical-align: middle; margin-right: 10px;" />
                      <span style="vertical-align: middle;">Traditional</span>
                      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-long-arrow-right fa-w-14 fa-2x" style="height: 18px; vertical-align: middle; margin-left: 5px;"><path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                    </a>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/facebook/messenger-chat-plugin')" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-fb-messenge-chat-plugin.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Messenger Chat Plugin</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Add the Facebook Messenger chat plugin to your website</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/imessage')" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-web-apple-business-chat-button.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Apple Business Chat Button</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Add the Apple Business Chat button on your website</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/re-direct-links')" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sharing-links-icon.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Re-direct links</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Send customers directly to your sales channel to interact with your bots, purchase your products or browse collections</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardContainer whatsapp-widget">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/whatsapp/widget')" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-web-whatsapp-widget.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">WhatsApp widget</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Add the WhatsApp chat widget on your website</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div v-if="isAdmin || oldPermissions.custom_domain">
          <ion-title class="sub-title">Settings</ion-title>
        </div>
        <ion-grid v-if="isAdmin || oldPermissions.custom_domain">
          <ion-row>
            <ion-col>
              <ion-card class="cardContainer">
                <ion-item lines="none" @click="() => $router.push('/sales-channel/web/custom-domain')" style="padding-top: 10px">
                  <ion-thumbnail slot="start">
                    <img src="https://jumper.ai/web-s/resources/images/sc-web-custom-domain.svg" />
                  </ion-thumbnail>
                  <ion-label>
                    <h2 class="cardTitle ion-no-padding ion-no-margin">Setup your domain</h2>
                    <p class="cardSubtitle ion-no-padding ion-no-margin">Host your jumper store on your own domain. Your customers can then browse and purchase your products on your custom link</p>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    })
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      try {
        this.loading = true;
        console.log("Initializing");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
}

.infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}

.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}

.infoBanner.success .panel .panel-body{
  border-left: 3px #099e6e solid;
}

.infoBanner.danger .panel.panel-default{
  background-color: #ffd4d4;
}

.infoBanner.danger .panel .panel-body{
  border-left: 3px #ff0000 solid;
}

.panel-body {
  padding: 20px;
}

.chatBubbleContainerForPreview p{
  padding: 20px;
  text-align: left;
  font-size: 12px;
}
.chatBubbleContainerForPreview{
  background: #f6f7f9;
  border: 1px solid #eee;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  margin: 0 auto;
  width: 80%;
  position: relative;
  margin-left: 40px;
}
.chatBubbleContainerForPreview::before{
  content: '';
  height: 25px;
  width: 25px;
  background-color: #f97873;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -33px;
  top: 10px;
}



.btn.textButton.whiteBtnWithIconOnLeft.fullWidthSquareDiv {
  margin-bottom: 0px;
  margin-right: 0px;
  margin-top: 15px;
  text-decoration: none !important;
  color: #000;
  border: 1px #eee solid;
  width: 100%;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
}
.btn.textButton.whiteBtnWithIconOnLeft {
  padding-right: 12px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1;
  background-color: #fff;
  color: var(--primary-theme-color, #3d39b9);
  -webkit-border-radius: 21px;
  border-radius: 21px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  border: 1px #ddd solid;
  white-space: normal;
}
.btn.textButton {
  background-color: var(--primary-theme-color, #3d39b9);
  color: var(--button-text-color, #fff);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* text-transform: capitalize; */
  opacity: 1;
  /* white-space: pre-wrap; */
  cursor: pointer;
  outline: none !important;
}
.btn {
  border-width: 1px;
  border-color: #000;
  color: #000;
}
body, p, span, li, div, i, .btn, a, td, th {
  font-family: var(--theme-font-family);
}
.btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  opacity: .8;
  padding: 8px 16px;
  border-color: #888;
  color: #888;
}
.btn {
  -webkit-transition: all .1s ease-in;
  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -ms-transition: all .1s ease-in;
  transition: all .1s ease-in;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
a, a:hover, a:focus, a.blackText, td.orderInfoTableTd a, td.orderInfoTableTd a i {
  cursor: pointer;
  color: var(--secondary-theme-color, #1a5ff8);
  outline: none !important;
}
a {
  color: var(--secondary-theme-color, #1a5ff8);
}
a {
  color: #1dc7ea;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a {
  background-color: transparent;
}

.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.sub-title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  letter-spacing: 1px;
  line-height: 1.3;
  color: var(--ion-color-black);
  padding-left: 20px;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.cardSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 4px;
}

.cardContainer {
  min-height: 98px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}
.widget {
  min-height: 125px;
}
.whatsapp-widget {
  min-height: 108px;
}
.cardSubtitle{
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-black);
  margin-top: 4px;
}
</style>