<template>
<div class="ion-padding create-message-wrapper" slot="content">
  <div v-if="loading" class="loading-wrapper">
    <span>Loading...</span>
  </div>
  <ion-list class="ion-no-padding" style="border-top: 1px solid #EDEDED">
    <ion-grid class="ion-no-padding gridMargin">
      <ion-row class="rowMargin ion-justify-content-between">
        <ion-col size-xl="5" size-lg="5" size-sm="12" size-xs="12" class="ion-no-padding conditionsEdit">
          <h2 class="orderSubtitle ion-no-padding ion-no-margin" style="margin: 0px 0px 20px 0px">
            Select template
          </h2>
          <ion-item lines="none" class="selctBox">
            <ion-select :disabled="!allTemplates.length" v-model="waTemplateId" placeholder="select Template" @ionChange="onChangeTemplateHandler">
              <ion-select-option :value="undefined">Select Template</ion-select-option>
              <ion-select-option v-for="thisTemplate in allTemplates" :key="thisTemplate.templates[0].template_id" :value="thisTemplate.templates[0].template_id">
                {{ thisTemplate.template_name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size-xl="5" size-lg="5" size-sm="12" size-xs="12" class="ion-no-padding conditionsEdit">
          <h2 class="orderSubtitle ion-no-padding ion-no-margin" style="margin: 0px 0px 20px 0px">
            Select language
          </h2>
          <ion-item lines="none" class="selctBox">
            <ion-select :disabled="!waTemplateId" v-model="waLanguage" placeholder="select Language">
              <ion-select-option :value="undefined">Select Language</ion-select-option>
              <ion-select-option v-for="thisLang in allLanguages" :key="thisLang.id" :value="thisLang.id">
                {{ thisLang.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>

      <div class="rendering-section">
        <div class="input-files" v-if="header.format == 'DOCUMENT'">
          <div class="document-selection-wrapper">
            <div class="left">
              <ion-button @click="() => $refs.documentInput.click()" fill="clear">
                <ion-icon :icon="icons.documentOutline"></ion-icon>
              </ion-button>
              <input type="file" id="documentInput" ref="documentInput"  @change="onSelectUploadFile" />
            </div>
            <div class="right">
              <ion-input type="text" v-model="documentUrl" placeholder="or paste url to upload an image"></ion-input>
            </div>
          </div>
        </div>

        <div v-if="headerText || textToRender || footerText" class="text-message-wrapper">
          <div v-if="headerText" class="header">
            <b v-html="headerText"></b>
          </div>
          <div v-if="textToRender" class="content">
            <p v-html="textToRender"></p>
          </div>
          <div v-if="footerText" class="footer">
            <p v-html="footerText"></p>
          </div>
          <div class="action-btn-wrapper">
            <ion-button v-for="(button, index) in allButtons" :key="index" fill="outline" shape="round" @click="buttonClickHandler(button)" >{{ button.text }}</ion-button>
          </div>
        </div>

      </div>

      <ion-list class="custom-variable-input-wrapper" v-if="customInputs.length">
        <ion-item v-for="(input, index) in customInputs" :key="index" class="ion-no-padding">
          <ion-label position="stacked">{{input.label}}</ion-label>
          <ion-input :clear-input="true" :id="input.id" v-model="input.value"></ion-input>
        </ion-item>
      </ion-list>
    </ion-grid>
  </ion-list>

  <ion-modal v-if="openModal" :is-open="openModal" @didDismiss="() => (openModal = false)">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">{{ modalTitle }}</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => (openModal = false)">
              <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <DynamicUrlButtonConf v-if="openDynamicUrlModal" :clickedButton="clickedButton" ref="DynamicUrlButtonConf"></DynamicUrlButtonConf>
        <StaticButtonConf v-if="openStaticButtonModal" :clickedButton="clickedButton" ref="StaticButtonConf"></StaticButtonConf>
      </ion-content>

      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button @click="updateButtonConfiguration" color="dark">Save</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>

</div>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline, close, load, documentOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSelectOption, IonSelect } from '@ionic/vue';
import DynamicUrlButtonConf from "./dynamic-url-button-conf.vue";
import StaticButtonConf from "./static-button-conf.vue";

export default {
  components: { IonSelectOption, IonSelect, DynamicUrlButtonConf, StaticButtonConf },
  data() {
    return {
      loading: false,
      allTemplates: [],
      waLanguage: "",
      waTemplateId: "",
      icons: { documentOutline, close },
      documentUrl: "",
      textToRender: "",
      headerText: "",
      footerText: "",
      header: {},
      customInputs: [],
      openModal: false,
      openDynamicUrlModal: false,
      openStaticButtonModal: false,
      clickedButton: "",
      modalTitle: "",
      selectedTemplate: {},
      allButtons: []
    }
  },
  props: {
    editBroadcast: {
      type: Object,
      default: () => { return {}; }
    }
  },
  async mounted() {
    await this.fetchTemplates();
    if(this.editBroadcast?.id) {
      this.preLoadValues();
    }
  },
  emits: ['onUpdatePreview'],
  computed: {
    allLanguages() {
      let allLanguageCodes = [];
      this.allTemplates.forEach((template) => {
        template?.templates?.forEach((innerTemplate) => {
          if (innerTemplate.language && this.waTemplateId == innerTemplate.id) {
            allLanguageCodes.push(innerTemplate.language);
          }
        });
      });
      return this.localeFullFormData_whatsapp.localeFullFormData_whatsapp.filter((e) => allLanguageCodes.indexOf(e.id) > -1);
    },
    noValuesEnteredForButtons() {
      let missedValue = false;
      this.allButtons.forEach((button) => {
        if (button.static != true) {
          if (!button.parameters[0].text) {
            missedValue = true;
          }
        }
      });
      return missedValue;
    },
    noValuesEnteredForCustomVariables() {
      let missedValue = false;
      this.customInputs.forEach((input) => {
        if (!input.value) {
            missedValue = true;
        }
      });
      return missedValue;
    },
    previewBody() {
      let html = this.textToRender;
      if (this.customInputs?.length) {
        this.customInputs.forEach((input, index) => {
          let pIndex = index + 1;
          if (input.value) {
            html = html.replace(`<span class="dynamic-variable-span">{custom_field_var${pIndex}}</span>`, input.value);
          }
        })
      }
      let objBody = {
        header: this.selectedTemplate?.message?.HEADER,
        body: {
          html: html,
        },
        footer: this.footerText,
        allButtons: this.allButtons
      };
      return objBody;
    }
  },
  watch: {
    previewBody() {
      this.$emit('onUpdatePreview', this.previewBody);
    }
  },
  methods: {
    preLoadValues() {
      this.waTemplateId = this.editBroadcast.wa_templateid;
      this.waLanguage = this.editBroadcast.wa_language;
      this.fetchTemplate();
    },
    generateTextToRender(text) {
      this.textToRender = text;
      const thisTemplate = this.selectedTemplate;
      if (thisTemplate.message_params.BODY && thisTemplate.message_params.BODY.length) {
        let varIndex = 0;
        thisTemplate.message_params.BODY.forEach(
          (thisParam, paramIndex) => {
            let param = Object.keys(thisParam)[0];
            let value = Object.values(thisParam)[0];
            let pIndex = paramIndex + 1;
            let strToReplace = "";
            switch (param) {
              case "<username>":
                {
                  strToReplace = "{User's First Name}";
                  break;
                }
              case "<shopname>":
                {
                  strToReplace = "{Store Name}";
                  break;
                }
              case "<agentname>":
                {
                  strToReplace = "{Agent Name}";
                  break;
                }
              case "<sellername>":
                {
                  strToReplace = "{Account Owner Name}";
                  break;
                }
              case "<selleremail>":
                {
                  strToReplace = "{Support E-mail}";
                  break;
                }
              case "<sellerphone>":
                {
                  strToReplace = "{Support Phone}";
                  break;
                }
              case "<menuaddress>":
                {
                  strToReplace = "{Outlet Address}";
                  break;
                }
              default: {
                if (param.includes("<custom_field_")) {
                  let customFieldText = param.replace("<custom_field_", "");
                  customFieldText = customFieldText.replace(">", "");
                  strToReplace = "{custom_field_" + customFieldText + "}";
                  this.customInputs.push({ label: `Enter custom message for var${++varIndex}`, id: `${pIndex}_${value}`, value: '' });
                }
              }
            }
            this.textToRender = this.textToRender.replace("{{" + pIndex + "}}", `<span class="dynamic-variable-span">${strToReplace}</span>`);
            this.textToRender = this.textToRender.replaceAll('\n', '<br>');
          }
        );
      }
    },
    resetTemplateValues() {
      this.documentUrl = "";
      this.textToRender = "";
      this.headerText = "";
      this.footerText = "";
      this.header = { };
      this.customInputs = [];
    },
    onChangeTemplateHandler() {
      this.resetTemplateValues();
      this.fetchTemplate();
    },
    buttonsListHandler(messageButtons) {
      let buttons = [];
      if (messageButtons) {
        messageButtons.forEach((messageButton, index) => {
          if (messageButton.type == 'URL') {
            let button = { ...messageButton, ...{ sub_type: 'url', type: 'button', index, parameters: [{ "type": "text", "text": "" }] } };
            button.text = button.text || 'Web Url';
            buttons.push(button);
          } else if (messageButton.type == 'QUICK_REPLY') {
            let button = { ...messageButton, ...{ sub_type: 'quick_reply', type: 'button', index, static: true } };
            button.text = button.text || 'Quick Reply';
            buttons.push(button);
          } else if (messageButton.type == 'PHONE_NUMBER') {
            let button = { ...messageButton, ...{ sub_type: 'phone_number', type: 'button', index, static: true } };
            button.text = button.text || 'Phone Number';
            buttons.push(button);
          } else {
            buttons.push(messageButton);
          }
        });
      }
      this.allButtons = buttons;
    },
    closeModal() {
      this.openModal = false;
      this.openDynamicUrlModal = false;
      this.openStaticButtonModal = false;
      this.clickedButton = "";
      this.modalTitle = "";
    },
    buttonClickHandler(button) {
      this.openModal = true;
      this.clickedButton = button;
      if (button.sub_type == 'url') {
        this.openDynamicUrlModal = true;
        this.modalTitle = "Web Page URL Configuration";
      } else if (button.sub_type == 'quick_reply') {
        this.openStaticButtonModal = true;
        this.modalTitle = "Quick Reply Configuration";
      } else if (button.sub_type == 'phone_number') {
        this.openStaticButtonModal = true;
        this.modalTitle = "Phone Number Configuration";
      }
    },
    updateButtonConfiguration() {
      if (this.clickedButton.sub_type == 'url') {
        if (this.$refs.DynamicUrlButtonConf.isDynamicUrl) {
          const updateBtn = this.$refs.DynamicUrlButtonConf.updatedButtonObject;
          this.allButtons[updateBtn.index] = { ...updateBtn };
        }
      }
      this.closeModal();
    },
    async fetchTemplates() {
      this.loading = true;
      try {
        let allTemplatesResponse = await jwtInterceptor.post( this.environment('VUE_APP_ROOT') + "whatsapp/message-template/fetch", { limit: "all" } );
        if (
          (allTemplatesResponse.status == 200 ||
            allTemplatesResponse.status == 201) &&
          allTemplatesResponse.data.success
        ) {
          this.allTemplates = allTemplatesResponse.data.data;
        } else {
          if (
            allTemplatesResponse.data &&
            !allTemplatesResponse.data.success &&
            allTemplatesResponse.data.errorMessage
          ) {
            this.updateToast({ open: true, message: allTemplatesResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async fetchTemplate() {
      if (!this.waTemplateId) {
        return;
      }
      const toFetchId = this.waTemplateId; // selected template
      try {
        this.loading = true;
        let id = this.allTemplates.find( (e) => e.templates[0].template_id === toFetchId ).templates[0].id;
        let getTemplateResponse = await jwtInterceptor.post( this.environment('VUE_APP_ROOT') + "whatsapp/message-template/get", { id: id } );
        if ( (getTemplateResponse.status == 200 || getTemplateResponse.status == 201) && getTemplateResponse.data.success ) {
          this.selectedTemplate = getTemplateResponse.data.data;
          let thisTemplate = getTemplateResponse.data.data;
          this.generateTextToRender(thisTemplate.message.BODY.text);
          this.header = this.selectedTemplate?.message?.HEADER || {};

          if (this.selectedTemplate?.message?.FOOTER?.text) {
            this.footerText = this.selectedTemplate?.message?.FOOTER?.text;
          }

          if (this.selectedTemplate?.message?.HEADER?.text && this.selectedTemplate?.message?.HEADER?.format == "TEXT") {
            this.headerText = this.selectedTemplate?.message?.HEADER?.text;
          }

          if (this.selectedTemplate.message.BUTTONS) {
            this.buttonsListHandler(this.selectedTemplate.message.BUTTONS);
          }

        } else {
          if ( getTemplateResponse.data && !getTemplateResponse.data.success && getTemplateResponse.data.errorMessage ) {
            this.updateToast({ open: true, message: getTemplateResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async onSelectUploadFile(event) {
      try {
        const fileToSend = event.target.files[0];
        let $this = this;
        if (fileToSend && fileToSend.name.lastIndexOf(".csv") !== -1) {
          let newCsv = new FileReader();
          newCsv.readAsText(fileToSend);
          newCsv.onload = function (event) {
            let csv = event.target.result;
            let lines = csv.split("\n");
            let headers = lines[0].split(",");
            if (headers.length < 4) {
              this.updateToast({ open: true, message: "There should be atleast 4 columns in CSV", type: "danger", });
              return;
            }
            $this.csvToUpload = fileToSend;
          };
          newCsv.onerror = function () {
            this.csvToUpload = undefined;
            this.updateToast({ open: true, message: "Unable to read " + fileToSend.name, type: "danger", });
          };
        } else {
          this.csvToUpload = undefined;
          this.updateToast({ open: true, message: "Please add a valid CSV", type: "danger", });
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      }
    },
    isValidToSubmit() {
      if (!this.selectedTemplate.id || !this.waTemplateId) {
        this.updateToast({ open: true, message: "Please select a whatsapp Template", type: "danger", });
        return false;
      }
      if (!this.waLanguage) {
        this.updateToast({ open: true, message: "Please select the whatsapp Template Language", type: "danger", });
        return false;
      }
      if (this.noValuesEnteredForButtons) {
        this.updateToast({ open: true, message: "Please click on the button and enter dynamic value.", type: "danger", });
        return false;
      }
      if (this.noValuesEnteredForCustomVariables) {
        this.updateToast({ open: true, message: "Please fill all the custom messages fields.", type: "danger", });
        return false;
      }
      return true;
    },
    async saveWhatsAppBroadcast({ sendNow, targetingConditions, pageId, segmentId }) {
      try {
        let reqBody = {
          name: this.editBroadcast.name,
          mode: sendNow ? "send" : "save",
          channel: this.editBroadcast.channel,
          id: this.editBroadcast.id ? this.editBroadcast.id : undefined,
          pageid: pageId,
          template_id: this.waTemplateId,
          targeting_conditions: targetingConditions,
          segmentid: segmentId
        };

        // set params for send later
        if (!sendNow) {
          reqBody.timezone = this.editBroadcast.timezone;
          reqBody.send_date_time = this.moment(this.editBroadcast.send_date_time).format('DD/MM/YYYY HH:mm:ss');
        }

        // set button params
        if (this.allButtons?.length) {
          let messageParams = this.selectedTemplate?.message_params || {};
          let buttons = [];
          this.allButtons.forEach((button) => {
            if (button.static != true) {
              buttons.push(
                {
                  "type": "button",
                  "sub_type": button.sub_type,
                  "index": button.index,
                  "parameters": button.parameters
                }
              )
            }
          });
          messageParams.BUTTONS = buttons;
          reqBody.message_params = JSON.stringify(messageParams);
        }

        // set custom variable values
        if (this.customInputs?.length) {
          let customVarValues = this.customInputs.map((e) => { return { key: e.id, value: e.value }; });
          reqBody.custom_messages = JSON.stringify(customVarValues);
        }

        console.log(reqBody);

        let saveBroadcastResponse = await jwtInterceptor.post(this.environment("VUE_APP_ROOT_API") + "add-whatsapp-broadcast-preset", reqBody);

        if ((saveBroadcastResponse.status == 200 || saveBroadcastResponse.status == 201) && saveBroadcastResponse.data.success) {
          return saveBroadcastResponse;
        } else {
          if (saveBroadcastResponse.data && !saveBroadcastResponse.data.success && saveBroadcastResponse.data.errorMessage) {
            this.updateToast({ open: true, message: saveBroadcastResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.error("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong please try again", type: "danger", });
      }
    },
  }
}
</script>

<style>
.dynamic-variable-span {
  background: #1c2ac3;
  color: white !important;
  user-select: none;
  border-radius: 10px;
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
}

.text-message-wrapper {
  margin-top: 20px;
  display: block;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.text-message-wrapper .footer {
  color: #9a9a9a;
}

.action-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>>

<style scoped>
.create-message-wrapper {
  position: relative;
}
.loading-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  background: #F0EFEF;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-files input {
  display: none;
}
.document-selection-wrapper {
  display: flex;
  border: 1px solid;
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, .13)))) !important;
  border-radius: 5px;
}
.document-selection-wrapper .left {
  border-right: 1px solid;
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, .13)))) !important;
}
.document-selection-wrapper .right {
  width: 100%;
}
.custom-variable-input-wrapper {
  margin-top: 10px;
}
</style>