import {createStore} from 'vuex';
import AuthModule from './modules/auth';
import SocialModule from './modules/social';
//import CommerceModule from './modules/commerce';
import UserModule from './modules/user';
import PaymentModule from './modules/payment';
import IntegrationModule from './modules/integration';
 
const store = createStore({
  state: {
    /* https://ionicframework.com/docs/api/toast */
    toast: {
      open: false,
      message: "Toast Message",
      type: "primary",
      duration: 2000
    },
    PUSHER: null,
    darkMode: false
  },
  getters: {
    getToast(state) {
      return state.toast;
    },
    getPUSHER(state) {
      return state.PUSHER;
    },
    isDarkMode(state) {
      return state.darkMode;
    }
  },
  actions: {
    updateToast({ commit }, payload) {
      commit('UPDATE_TOAST', payload);
    },
    updatePUSHER({ commit }, payload) {
      commit('UPDATE_PUSHER', payload);
    },
    updateDarkMode({ commit }, payload) {
      commit('UPDATE_DARK_MODE', payload);
    }
  },
  mutations: {
    UPDATE_TOAST(state, payload) {
      state.toast.open = typeof payload.open != undefined ? payload.open : false;
      state.toast.message = payload.message ? payload.message : "Toast Message";
      state.toast.type = payload.type ? payload.type : "primary";
      state.toast.duration = payload.duration ? payload.duration : 2000;
      if(state.toast.open && state.toast.duration) {
        setTimeout(() => {
          state.toast.open = false;
        }, state.toast.duration);
      }
    },
    UPDATE_PUSHER(state, payload) {
      state.PUSHER = payload;
    },
    UPDATE_DARK_MODE(state, payload) {
      typeof payload === 'boolean' ? state.darkMode = payload : state.darkMode = !state.darkMode;
    }
  },
  modules:{
    auth: AuthModule,
    social: SocialModule,
    /* commerce: CommerceModule, */
    user: UserModule,
    payment: PaymentModule,
    integration: IntegrationModule
  }
});
 
export default store;