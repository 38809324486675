<template>
  <master-layout pageTitle="Whatsapp Business Settings">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar color="light">
          <ion-segment scrollable color="dark" v-model="activeTab" class="ion-justify-content-center">
            <ion-segment-button value="account">
              <ion-label class="common-label">Account</ion-label>
            </ion-segment-button>
            <ion-segment-button value="business">
              <ion-label class="common-label">Business info</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
        <div class="cust-margin">
          <h2 class="header ion-no-margin">Business settings</h2>
        </div>
        <div v-if="activeTab === 'account'" class="gridMargin">
          <div class="model-sections">
            <ion-row>
              <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
                <ion-item lines="none">
                  <ion-avatar slot="start" style="height: auto; width: 38px;">
                    <img src="https://jumperdevnew.appspot.com/landing/resources/images/whatsapp.svg"/>
                  </ion-avatar>
                  <ion-label>
                    <h3 class="title">Connected WhatsApp Account</h3>
                    <p class="sub-title">Following WhatsApp account is connected to Jumper.ai</p>
                  </ion-label>
                </ion-item>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
                <ion-item lines=none class="status-container">
                  <span class="status-title">Status</span>
                  <span class="status">All Good</span>
                </ion-item>
              </ion-col>
            </ion-row>
            
            <ion-item lines="none">
              <ion-col class="ion-no-padding" size="12">
                <div class="whatsapp-container">
                  <h4 class="text"><b>Business Name:</b> {{ whatsAppSocialData.name }}</h4>
                  <p class="sub-title"><b>Business Number: </b>{{ whatsAppSocialData.country_code }} {{ whatsAppSocialData.mobile_number }}</p>
                  <p class="sub-title"><b>Business ID: </b>{{ whatsAppSocialData.business_id }}</p>
                </div>
              </ion-col>
            </ion-item>
          </div>
        </div>
        <div v-if="activeTab === 'business'" class="gridMargin">
        <div class="model-sections">
          <ion-card-content class="container">
            <div class="title prdct-section">Business account settings</div>
            <div class="prdct-section">
                <ion-card-title class="orderDesc">About</ion-card-title>
                <ion-input type="text" placeholder="Enter name" v-model="account.about" class="fullBordered input_custom_style sctn-input"></ion-input>
            </div>
            <div class="prdct-section">
                <ion-card-title class="orderDesc">Profile picture</ion-card-title>
                <!-- TODO add img after API Integration lovish -->
                <img :src="account.image" alt="" class="img-container" />
            </div>
            <div>
                <!-- TODO add img after API Integration lovish -->
              <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
              <ion-button @click="() => $refs.productImage.click()" fill="outline">Upload images</ion-button>
            </div>
          </ion-card-content>
          <ion-card-content>
            <div class="title prdct-section">Business profile</div>
            <div class="prdct-section">
                <ion-card-title class="orderDesc">Address</ion-card-title>
                <ion-input type="text" placeholder="Enter your Address" v-model="account.address" class="fullBordered input_custom_style sctn-input"></ion-input>
            </div>
            <div class="prdct-section">
                <ion-card-title class="orderDesc">Business description</ion-card-title>
                <ion-input type="text" placeholder="Description about your Business" v-model="account.description" class="fullBordered input_custom_style sctn-input"></ion-input>
            </div>
            <ion-row>
              <ion-col size="6">
                <div class="prdct-section">
                  <ion-card-title class="orderDesc">Contact email</ion-card-title>
                  <ion-input type="email" placeholder="contact Email" v-model="account.email" class="fullBordered input_custom_style sctn-input"></ion-input>
                </div>
              </ion-col>
              <ion-col size="6">
                <div class="prdct-section">
                  <ion-card-title class="orderDesc">Business category (e.g. service type)</ion-card-title>
                  <ion-select v-model="selectedCategory" placeholder="Select group" class="slctContainer">
                    <ion-select-option v-for="thisCategory in availableCategories" :key="thisCategory.value" :value="thisCategory.value">{{ thisCategory.name }}</ion-select-option>
                  </ion-select>								
                </div>
              </ion-col>
            </ion-row>
            <div class="prdct-section">
              <ion-card-title class="orderDesc">Website(s)</ion-card-title>
              <ion-input type="url" placeholder="Your website URL(required)" v-model="account.website" class="fullBordered input_custom_style sctn-input"></ion-input>
            </div>
            <ion-input type="url" placeholder="Your other website URL(optional)" v-model="account.otherWebsite" class="fullBordered input_custom_style sctn-input"></ion-input>
            <ion-toolbar>
              <ion-buttons  slot="end">
                <ion-button @click="saveChanges" color="dark" fill="solid" class="bttn-box">Save</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-content>
        </div>
        </div>
      </div>
    </ion-content>
    <ion-alert
      v-if="disconnectPageMode"
      :is-open="disconnectPageMode"
      header="Are you sure?"
      message="Jumper will not be able to respond to your customers if they comment on your photos with your product #hashtags. Are you sure you want to disconnect your Instagram account?"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => disconnectPageMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          confirmDisconnectPage()
        },
      }]"
      @didDismiss="() => disconnectPageMode = false"
    />
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons';
// import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      disconnectPageMode: false,
			activeTab: 'account',
      account: {
        name: undefined,
				about: undefined,
				address: undefined,
				description: undefined,
				email: undefined,
				category: undefined,
				website: undefined,
				otherWebsite: undefined,
        image: undefined,
      },
			selectedCategory: undefined,
			availableCategories: [
        {
					name: "Automotive",
					value: "Automotive"
        },
        {
					name: "Beauty, Spa and Salon",
					value: "Beauty, Spa and Salon"
        },
        {
					name: "Clothing and Apparel",
					value: "Clothing and Apparel"
        },
        {
					name: "Education",
					value: "Education"
        },
        {
					name: "Entertainment",
					value: "Entertainment"
        },
        {
					name: "Event Planning and Service",
					value: "Event Planning and Service"
        },
        {
					name: "Finance and Banking",
					value: "Finance and Banking"
        },
        {
					name: "Food and Grocery",
					value: "Food and Grocery"
        },
        {
					name: "Public Service",
					value: "Public Service"
        },
				  {
					name: "Hotel and Lodging",
					value: "Hotel and Lodging"
        },
        {
					name: "Medical and Health",
					value: "Medical and Health"
        },
        {
					name: "Non-profit",
					value: "Non-profit"
        },
        {
					name: "Professional Services",
					value: "Professional Services"
        },
        {
					name: "Shopping and Retail",
					value: "Shopping and Retail"
        },
        {
					name: "Travel and Transportation",
					value: "Travel and Transportation"
        },
        {
					name: "Restaurant",
					value: "Restaurant"
        },
				{
					name: "Other",
					value: "Other"
        }
			],
    }
  },
  methods: {
    async doRefresh(event) {
    event.target.complete();
    },
    // whatsapp API Integration lovish
    async handleImageUpload() {
      this.loading = true;
    },
  }
}
</script>

<style scoped>

ion-button {
  margin-left: 5px;
  margin-bottom: 10px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.text {
	font-size: 16px;
  color: var(--ion-color-black);
  font-weight: 500;
  line-height: 1.4;
}
.title {
  font-size: 16px;
  color: var(--ion-color-black);
  font-weight: 600;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}

.img-container {
	width: 200px;
	height: 200px;
	border-radius: 5px;
	display: inline-block;
	vertical-align: top;
}

.bttn-box{
	margin-top: 25px;
}

ion-button.bttn-box{
	--border-radius: 5px;
}
.cust-margin{
	margin: 10px 20px;
}
.container{
	border-bottom: 1px solid #eee;
}
.status {
  background-color: #eafdf2;
  color: #099e6e;
  margin-left: 10px;
  padding: 4px 11px;
  border-radius: 3px;
}
.status-title{
  font-size: 14px;
  color: var(--ion-color-black);
  font-weight: 500;
}
.slctContainer {
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}
.orderDesc{
	padding-bottom: 10px;
}
.whatsapp-container {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
}
.status-container{
  float: right;
}

@media only screen and (max-width: 760px){
.status-container{
  float: left;
}
}
</style>