<template>
  <ion-modal
    v-if="isOpen"
    :is-open="isOpen"
    @didDismiss="dismissModal" backdropDismiss="false"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ title }}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="dismissModal">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div v-if="type == 'customers' && clarins_my_MerchantBool != true" class="info-box infoMessage">
          <ion-icon :icon="informationCircleOutline" size="large" class="infoIcon"></ion-icon>
          <p class="info-text ion-no-margin">Customer data export is supported up to a maximum of latest 100k records.</p>
        </div> 
        <ion-list class="ion-no-padding">
          <div class="optionsContainer" v-if="type != 'BAReport' && type != 'customers' && type != 'UserRoles'">
            <!-- <p class="sub-title">Date range</p>
            <ion-input type="text" class="sctn-input" v-model="csvExportDateRange"></ion-input> -->

            <p class="sub-title" for="open_csvExportDateRange_start_DateInput" @click="$refs.open_csvExportDateRange_start_DateInput.click()">Start Date</p>
            <ion-input class="sctn-input" @focus="$refs.open_csvExportDateRange_start_DateInput.click()" id="open_csvExportDateRange_start_DateInput" readonly ref="open_csvExportDateRange_start_DateInput" :value="moment(csvExportDateRange_start).format('DD-MM-YYYY')"></ion-input>
            <ion-popover trigger="open_csvExportDateRange_start_DateInput">
              <ion-datetime display-format="DD-MM-YYYY" :value="csvExportDateRange_start" ref="csvExportDateRange_start" name="csvExportDateRange_start" @ionChange="e => csvExportDateRange_start = moment(new Date(e.detail.value)).toISOString()" presentation="date" :max="moment(new Date()).toISOString()" id="start-date" showDefaultButtons></ion-datetime>
            </ion-popover>

            <p class="sub-title" for="open_csvExportDateRange_end_DateInput" @click="$refs.open_csvExportDateRange_end_DateInput.click()">End Date</p>
            <ion-input class="sctn-input" @focus="$refs.open_csvExportDateRange_end_DateInput.click()" id="open_csvExportDateRange_end_DateInput" readonly ref="open_csvExportDateRange_end_DateInput" :value="moment(csvExportDateRange_end).format('DD-MM-YYYY')"></ion-input>
            <ion-popover trigger="open_csvExportDateRange_end_DateInput">
              <ion-datetime display-format="DD-MM-YYYY" :value="csvExportDateRange_end" ref="csvExportDateRange_end" name="csvExportDateRange_end" @ionChange="e => csvExportDateRange_end = moment(new Date(e.detail.value)).toISOString()" presentation="date" id="csvExportDateRange_end" :max="moment(new Date()).toISOString()" showDefaultButtons></ion-datetime>
            </ion-popover>
          </div>
          <div class="optionsContainer">
            <p class="sub-title">Export format</p>
            <ion-select placeholder="Select a file format" v-model="csvExportFormatDdl" ok-text="Okay" cancel-text="Dismiss" class="slctoptions">
              <ion-select-option value="csv" selected>CSV</ion-select-option>
              <ion-select-option value="excel">Excel</ion-select-option>
            </ion-select>
          </div>
          <div class="optionsContainer">
            <p class="sub-title">Delimiter</p>
            <ion-select placeholder="Select a delimiter" v-model="csvExportDelimiterDdl" ok-text="Okay" cancel-text="Dismiss" class="slctoptions">
              <ion-select-option value="," selected>Comma (,)</ion-select-option>
              <ion-select-option value="|">Pipe (|)</ion-select-option>
            </ion-select>
          </div>
          <div class="optionsContainer" v-if="type == 'customers' && clarins_my_MerchantBool != true">
            <p class="sub-title">Filter type</p>
            <ion-select placeholder="Select Filter Type" v-model="csvExportFilterTypeDdl" @ionChange="updatePlaceholder" ok-text="Okay" cancel-text="Dismiss" class="slctoptions">
              <ion-select-option value="noFilter" selected>-- No filter --</ion-select-option>
              <ion-select-option value="user_name">By Name</ion-select-option>
              <ion-select-option value="user_email">By Email</ion-select-option>
              <ion-select-option value="user_mobilenumber_wo_plus">By Mobile Number</ion-select-option>
              <ion-select-option v-if="!IS_ASIA" value="PreviousOrders">By Order ID</ion-select-option>
              <ion-select-option value="CustomTags">By Tag</ion-select-option>
              <ion-select-option value="CustomFields">By Custom Data (Field)</ion-select-option>
            </ion-select>
          </div>
          <div class="optionsContainer" v-if="csvExportFilterTypeDdl !=='noFilter'">
            <p class="sub-title">Filter value</p>
            <ion-input class="sctn-input ion-no-border" maxLength="200" :placeholder="placeholder" v-model="csvExportFilterValueText"></ion-input>
          </div>
          
          <div class="optionsContainer" v-if="lbc_soshop_MerchantBool == true">
            <ion-label position="stacked" class="sub-title">Export type</ion-label>
            <ion-select placeholder="Select export type" v-model="csvExportOrderGenerateTypeDdl" ok-text="Okay" cancel-text="Dismiss" class="slctoptions">
              <ion-select-option value="order" selected>Order</ion-select-option>
              <ion-select-option value="cart">Cart</ion-select-option>
            </ion-select>
          </div>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button size="default" fill="solid" @keyup.enter="csvExportWithDateRangeModal_submit" :disabled="disable" @click="csvExportWithDateRangeModal_submit" class="bttnPrimary">{{ buttonText }}</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { IonSelect, IonSelectOption, IonPopover } from '@ionic/vue';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { close, informationCircleOutline } from 'ionicons/icons';

export default {
  components: { IonSelect, IonSelectOption, IonPopover },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    title: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData"
    }),
  },
  data() {
    return {
      close,
      informationCircleOutline,
      disable: false,
      buttonText: 'Export',
      csvExportDateRange_start: this.moment(new Date()).toISOString(),
      csvExportDateRange_end: this.moment(new Date()).toISOString(),
      csvExportFormatDdl: "csv",
      csvExportDelimiterDdl: ",",
      csvExportOrderGenerateTypeDdl: "order",
      csvExportFilterTypeDdl: "noFilter",
      csvExportFilterValueText: undefined,
      placeholder: 'Enter a value',
      whereclause_bool: false,
      finalValueForCsvExportFilter: '',
    }
  },
  methods: {
    dismissModal() {
      this.resetToDownloadState();
      this.$emit('close');
    },
    resetToDownloadState() { 
      this.disable = false;
      this.buttonText = 'Export';
      this.csvExportFilterTypeDdl = 'noFilter';
      this.csvExportFilterValueText = undefined;
      this.whereclause_bool = false,
      this.finalValueForCsvExportFilter = '';
    },
    csvExportWithDateRangeModal_submit(){
      if(this.type != 'BAReport' && this.type != 'customers' && this.type != 'UserRoles'){
        if(!this.csvExportDateRange_start || !this.csvExportDateRange_end){
          this.updateToast({ open: true, message: "Please select the date range!", type: "danger" })
        }
        else{
          this.downloadCSVForAllOrdersv2(this.type);
        }
      }
      else{
        this.downloadCSVForAllOrdersv2(this.type);
      }
    },
    async downloadCSVForAllOrdersv2(type){
      this.disable = true;
      this.buttonText = "Exporting...";
      var host = "jumper.ai";
      if(this.IS_LIVE == false){
        host = "jumperdevnew.appspot.com";
      }

      var chartQueryName = type;

      if(this.csvExportFilterTypeDdl != 'noFilter' && this.csvExportFilterTypeDdl != null && this.csvExportFilterTypeDdl != '' && this.csvExportFilterValueText != 'undefined' && this.csvExportFilterValueText != null && this.csvExportFilterValueText != ''){
        this.whereclause_bool = true;

        if(this.csvExportFilterTypeDdl == 'user_mobilenumber_wo_plus'){
          if(this.csvExportFilterValueText.indexOf('+') > -1){
            this.finalValueForCsvExportFilter = this.csvExportFilterValueText.replace('+','');
          }
          else{
            this.finalValueForCsvExportFilter = this.csvExportFilterValueText;
          }
        }
        else if(this.csvExportFilterTypeDdl == 'CustomTags'){
          console.log(this.csvExportFilterValueText);
          this.finalValueForCsvExportFilter = "'"+ this.csvExportFilterValueText +"' in unnest(tags)";
        }
        else if(this.csvExportFilterTypeDdl == 'CustomFields'){
          this.finalValueForCsvExportFilter = "'"+ this.csvExportFilterValueText +"' in unnest(custom_fields)";
        }
        else if(this.csvExportFilterTypeDdl == 'PreviousOrders'){
          this.finalValueForCsvExportFilter = 'CONTAINS_SUBSTR(PreviousOrders,"' + this.csvExportFilterValueText + '")';
        }
        else{
          this.finalValueForCsvExportFilter = this.csvExportFilterValueText;
        }
      }

      if(type == 'orders'){
        if(this.user.enterprise_Name == 'clarins_my'){
          chartQueryName = "ClarinsMalaysiaOrder";
        }
        else if(this.user.enterprise_Name == 'abbott_my'){
          chartQueryName = "AbbottOrder";
        }
        else if(this.user.enterprise_Name == 'beautyscentsth' || this.user.enterprise_Name == 'luxasia_my'){
          chartQueryName = "Luxasia";
        }
        else if(this.user.enterprise_Name == 'samsungid'){
          chartQueryName = "SamsungOrderwithDate";
        }
        else if(this.user.enterprise_Name == 'smretail_ph'){
          chartQueryName = "SMRetailOrder";
        }
        else if(this.acommerce_family_user == true){
          chartQueryName = "ACommerceOrderExport";
        }
        else if(this.lbc_soshop_MerchantBool == true){
          if(this.csvExportOrderGenerateTypeDdl == 'cart'){
            chartQueryName = "soshopcart";
          }
          else{
            chartQueryName = "soshoporder";
          }
        }
        else if(this.user.enterprise_Name == 'eazylink' || this.user.enterprise_Name == 'ezlink'){
          chartQueryName = "ezlinkdiscountall";
        }
        else if(this.user.enterprise_Name == 'redRibbon_merchant'){
          chartQueryName = "ezlinkdiscountall";
        }
        else if(this.intrepid_family_user == true){
          chartQueryName = "LorealOrderWithDate";
        }
        else if(this.loreal_my_family_user == true){
          chartQueryName = "LorealOrderWithDate";
        }
        else if(this.redRibbon_ph_manila == true){
          chartQueryName = "-JFCorderWithDate";
        }
        else if(this.user.enterprise_Name == 'walls_my'){
          chartQueryName = "-wallsmalaysiaorder";
        }
        else{
          chartQueryName = "OrderExport";
        }
      }
      else if(type == 'customers'){
        if(this.user.enterprise_Name == 'clarins_my'){
          chartQueryName = "ClarinsMalaysiaCRM";
        }
        else{
          if(this.whereclause_bool == true){
            chartQueryName = "crmdatafilter";
          }
          else{
            chartQueryName = "crmdata_v2";
          }
        }
      }
      var apiUrl = this.environment('VUE_APP_ANALYTICS_URL') + "" + this.user.referallshopName;
      apiUrl = apiUrl +"-"+ chartQueryName;
      apiUrl = apiUrl +"="+ this.csvExportFormatDdl +"?delimiter="+ this.csvExportDelimiterDdl +"&v="+ Math.floor((Math.random() * 1000000) + 1);

      if(this.type != 'BAReport' && this.type != 'customers' && this.type != 'UserRoles'){
        apiUrl = apiUrl +"&fromdate="+ this.moment(this.csvExportDateRange_start).format("YYYY-MM-DD") +"&todate="+ this.moment(this.csvExportDateRange_end).format("YYYY-MM-DD");
      }

      if(this.lbc_soshop_MerchantBool == true){
        apiUrl = apiUrl +"&generate="+ this.csvExportOrderGenerateTypeDdl;
      }

      if(this.whereclause_bool == true){
        console.log(this.csvExportFilterTypeDdl);
        if(this.csvExportFilterTypeDdl === 'PreviousOrders' || this.csvExportFilterTypeDdl === 'CustomTags') {
          apiUrl = apiUrl +"&offset=0&rows=100000&whereclause="+ this.finalValueForCsvExportFilter;
        } else {
          apiUrl = apiUrl +"&offset=0&rows=100000&whereclause="+ this.csvExportFilterTypeDdl +"=\""+ this.finalValueForCsvExportFilter +"\"";
        }
      }
      var filename = this.user.referallshopName +'-'+ type;

      if(this.csvExportFormatDdl == 'csv'){
        filename = filename +".csv";

        fetch(apiUrl, {
          method: "GET",
          headers: {
            "userid": this.authData.authkey,
            //"Authorization": "JWT "+ this.authData.token,
            "host": host
          }
        })
        .then(response => response.blob())
        .then(blob => {
          this.dismissModal();

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }).catch(error => {
          this.updateToast({ open: true, message: "Something went wrong, please try again.", type: "danger" });
          this.resetToDownloadState();
        });

        /* try {
          let downloadReportApiResponse = await jwtInterceptor.get(apiUrl);
          if ((downloadReportApiResponse.status == 200 || downloadReportApiResponse.status == 201) && !downloadReportApiResponse.data.error){
            var data, link;
            if(typeof downloadReportApiResponse.data != 'undefined' && downloadReportApiResponse.data != ''){
              if(downloadReportApiResponse.data.indexOf('#') > -1){
                downloadReportApiResponse.data = downloadReportApiResponse.data.replace(/#/g ,''); 
              }
            }
            var csv = downloadReportApiResponse.data;
            //console.log(csv);
            if(csv == null) return;
            if(!csv.match(/^data:text\/csv/i)){
              csv = 'data:text/csv;charset=utf-8,' + csv;
            }
            data = encodeURI(csv);
            //console.log(data);
            
            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.appendChild(link);
          } else {
            if(downloadReportApiResponse.data.errorMessage && downloadReportApiResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: downloadReportApiResponse.data.errorMessage,
                type: "danger"
              })
            }
            else if(downloadReportApiResponse.data.error && downloadReportApiResponse.data.error.length) {
              this.updateToast({
                open: true,
                message: downloadReportApiResponse.data.error,
                type: "danger"
              })
            }
            else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception: ", error);
          this.updateToast({
            open: true,
            message: "Something went wrong!",
            type: "danger"
          })
        } finally {
          this.dismissModal();
        } */
      }
      else if(this.csvExportFormatDdl == 'excel'){
        filename = filename +".xlsx";

        fetch(apiUrl, {
          method: "GET",
          headers: {
            "userid": this.authData.authkey,
            //"Authorization": "JWT "+ this.authData.token,
            "host": host
          }
        })
        .then(response => response.blob())
        .then(blob => {
          this.dismissModal();

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch(error => {
          this.updateToast({ open: true, message: "Something went wrong, please try again.", type: "danger" });
          this.resetToDownloadState();
        });
      }
    },
    updatePlaceholder() {
      switch (this.csvExportFilterTypeDdl) {
        case 'user_name':
          this.placeholder = 'Eg.: John Doe';
          break;
        case 'user_email':
          this.placeholder = 'Eg.: johndoe@myemail.com';
          break;
        case 'user_mobilenumber_wo_plus':
          this.placeholder = 'Eg.: +65 90909090';
          break;
        case 'PreviousOrders':
          this.placeholder = 'Eg.: FB0101';
          break;
        case 'CustomTags':
          this.placeholder = 'Eg.: Subscriber';
          break;
        case 'CustomFields':
          this.placeholder = 'Eg.: age:30 (key:value)';
          break;
        default:
          this.placeholder = 'Enter a value';
      }
    },
  }
}
</script>

<style scoped>
.infoMessage{
  margin: 20px;
}
.info-box{
  display: flex;
  align-items: center;
  flex-direction: row;
  background: var(--purple-opac);
  padding: 15px 10px;
  border-radius: 5px;
}
.info-text{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  margin-left: 8px;
}
.infoIcon {
  color: var(--purple);
}
ion-input.sctn-input{
  --background: var(--background-primary);
  color: var(--black);
  border-radius: 5px;
}
.sub-title{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--gray-04);
}
.slctoptions {
  width: 100%;
  border-radius: 5px;
	padding: 10px;
  background: var(--background-primary);
}
ion-button.bttnPrimary{
  --background: var(--purple);
  color: var(--white);
  --border-radius: 5px;
  --border-width: 0px;
}
.optionsContainer{
  margin: 0px 20px 20px 20px;
}
</style>