<template>
  <div class="ion-padding dynamic-url-wrapper">
    <ion-item class="ion-no-padding">
      <ion-label position="stacked">Button title</ion-label>
      <ion-input :value="title" :disabled="true"></ion-input>
    </ion-item>

    <ion-item v-if="isDynamicUrl" lines="none" class="ion-no-padding">
      <ion-label position="stacked">Webpage URL</ion-label>
      <div class="url-input-wrapper">
        <div class="pre-url-label">{{ urlLabelToShow }}</div> <ion-input v-model="urlParam" @ionChange="buttonObjectHandler"></ion-input>
      </div>
    </ion-item>

    <ion-item v-else class="ion-no-padding">
      <ion-label position="stacked">Webpage URL</ion-label>
      <ion-input :value="title" :disabled="true"></ion-input>
    </ion-item>

  </div>
</template>

<script>

export default {
  components: { },
  data() {
    return {
      urlParam: "",
      updatedButtonObject: {}
    }
  },
  props: {
    clickedButton: {
      type: Object,
      default: () => { return {}; }
    }
  },
  mounted() {
  },
  computed: {
    urlLabelToShow() {
      return this.clickedButton?.url?.replace("{{1}}", "") || "";
    },
    isDynamicUrl() {
      return this.clickedButton?.url?.includes("{{1}}");
    },
    title() {
      return this.clickedButton.text;
    }
  },
  watch: {
  },
  methods: {
    buttonObjectHandler() {
      this.updatedButtonObject = { ...this.clickedButton };
      this.updatedButtonObject.parameters = [
        {
          "type": "text",
          "text": this.urlParam
        }
      ]
    }
  }
}
</script>

<style>
.url-input-wrapper {
  display: flex;
  border-bottom: 1px solid;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.pre-url-label {
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--ion-color-light-shade);
  border-radius: 5px 5px 0 0px;
}
</style>

<style scoped>
.url-input-wrapper {
  display: flex;
  border-bottom: 1px solid;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  column-gap: 5px;
}

.pre-url-label {
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--ion-color-light-shade);
  border-radius: 5px 5px 0 0px;
  padding: 0 5px;
  max-width: 80%;
  overflow-x: scroll;
  box-sizing: content-box;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.pre-url-label::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
</style>