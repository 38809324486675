<template>
  <master-layout pageTitle="Google Business Messages">
    <ion-loading
    v-if="loading"
    :is-open="loading"
    cssClass="my-custom-class"
    message="Loading..."
    ></ion-loading>
    <div v-else>
      <div v-if="!gBusinessMessagesConnected">
        <ion-card class="container">
          <ion-card-header>
            <ion-item lines="none">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA" />
              </ion-thumbnail>
              <ion-label>
                <ion-card-title style="margin-bottom: 5px">Google Business Messages</ion-card-title>
                <ion-card-subtitle>Register a Google Business Account</ion-card-subtitle>
              </ion-label>
            </ion-item>
          </ion-card-header>
          <div class="cust-margin">
            <div class="prdct-section">
              <ion-card-title class="sctn-labels text-left">Agent ID</ion-card-title>
              <ion-input type="text" placeholder="Enter agent Id" v-model="agentid" class="fullBordered input_custom_style sctn-input text-left"></ion-input>
            </div>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels text-left">Bot Name</ion-card-title>
              <ion-input type="text" placeholder="Enter bot name" v-model="bot_name" class="fullBordered input_custom_style sctn-input text-left"></ion-input>
            </div>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels text-left">Bot Image</ion-card-title>
              <div class="input-wrapper">
                <input type="file" style="display: none;" id="botImage" ref="botImage" @change="handleImageUpload" accept="image/png, image/gif, image/jpeg"/>
                <ion-button v-if="!bot_image || bot_image.length === 0" @click="() => $refs.botImage.click()" expand="block" fill="outline" class="btn-upload">Upload image</ion-button>
                <img v-else :src="bot_image" height="200" width="200" @click="() => $refs.botImage.click()" />
              </div>
            </div>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels text-left">Or Paste Image URL</ion-card-title>
              <ion-input v-model="bot_image" type="text" placeholder="Image URL" class="fullBordered input_custom_style input-wrapper"></ion-input>
            </div>
            <ion-toolbar>
              <ion-buttons slot="end" class="paddingBox">
                <ion-button :disabled="disabled" @click="connectGBM" class="bttn-bgnd" fill="solid" size="small" color="dark">Submit</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </div>
        </ion-card>
      </div>
      <div v-else>
        <div class="cust-margin">
          <h2 class="header ion-no-margin">Google Business Messages</h2>
        </div>
        <div class="model-sections cust-margin">
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
              <ion-item lines="none">
                <ion-avatar slot="start" style="height: auto; width: 38px;">
                  <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA"/>
                </ion-avatar>
                <ion-label>
                  <h3 class="title">Connected Google Business Messages account</h3>
                  <p class="sub-title">Following Google Business Messages account is connected <span v-if="!IS_ASIA">to Jumper.ai</span></p>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" style="padding: 15px;">
              <ion-item lines=none class="status-container">
                <span class="status-title">Status</span>
                <span class="status">All Good</span>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item lines="none">
            <ion-col class="ion-no-padding" size="12">
              <div class="google-message-container">
                <h4 class="text"><b>Bot Name:</b> {{ gBusinessMessagesSocialData.bot_name }}</h4>
              </div>
            </ion-col>
          </ion-item>
        </div>
      </div>
    </div>
  </master-layout>
</template>
  
<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
export default {
  data() {
    return {
      loading: false,
      agentid: undefined,
      bot_name: undefined,
      bot_image: undefined
    }
  },
  computed: {
    disabled() {
      return !this.agentid || this.agentid.length == 0 || !this.bot_name || this.bot_name.length == 0 || !this.bot_image || this.bot_image.length == 0;
    }
  },
  methods: {
    async handleImageUpload (event) {
      try {
        this.loading = true;
        if(event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          console.log(newImage);
          if(await this.validateImage(newImage, 4)) {
            let uploadSendResponseData = await this.uploadImage(newImage, '/add-image');
            this.bot_image = uploadSendResponseData.url;
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async connectGBM() {
      try {
        let reqBody = {
          agentid: this.agentid,
          bot_name: this.bot_name,
          bot_image: this.bot_image,
          jsonkeylocation: "https://jumperdevnew.appspot.com/landing/resources/images/jumper-logo-256x256.png"
        }
        let connectSMSResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "googlebusinessmsg/connect", reqBody);
        if ((connectSMSResponse.status == 200 || connectSMSResponse.status == 201) && connectSMSResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Your Google Business Messages Account is now connected!",
            type: "success"
          })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(connectSMSResponse.data.errorMessage && connectSMSResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: connectSMSResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    }
  }
}
</script>
  
<style scoped>
.container {
  border-radius: 10px;
  border-radius: 10px;
  max-width: 700px;
  margin: 50px auto 20px auto;
  text-align: center;
}
.cust-margin{
  margin: 20px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.status-container{
  float: right;
}
.status {
  background-color: #eafdf2;
  color: #099e6e;
  margin-left: 10px;
  padding: 4px 11px;
  border-radius: 3px;
}
.status-title{
  font-size: 14px;
  color: var(--ion-color-black);
  font-weight: 500;
}
.google-message-container {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
}
.text{
  font-size: 18px;
}
.title {
  font-size: 16px;
  color: var(--ion-color-black);
  font-weight: 600;
  line-height: 1.4;
}
.sub-title {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 500;
  line-height: 1.4;
}

@media only screen and (max-width: 760px){
.status-container{
  float: left;
}
}
</style>