<template>
  <ion-item class="shopify-wrapper" lines="none">
    <ion-card>
      <ion-card-header>
        <ion-card-title>Connect Dialogflow</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <img src="https://jumperdevnew.appspot.com/web-s/resources/images/dialogflow-logo-square.png" style="height: 30px; width: auto;">
        <div>Dialogflow is a Google service that provides machine learning to understand what users are saying accordingly send automated responses.</div>
        <ion-buttons>
          <ion-button v-if="isErrorWhileLoading" class="btn-previous ion-no-padding" @click="fetchDatFlowConnectionUrl" fill="outline" color="primary"> Reload </ion-button>
          <ion-button v-else class="btn-previous ion-no-padding" @click="checkConnection" fill="outline" color="primary"> Connect </ion-button>
        </ion-buttons>
      </ion-card-content>
    </ion-card>
    <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
  </ion-item>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';

export default {
  data() {
    return {
      loading: false,
      dataFlowConnectionUrl: "",
      isErrorWhileLoading: false
    }
  },
  computed: {
  },
  mounted() {
    this.fetchDatFlowConnectionUrl();
  },
  methods: {
    showError(message) {
      this.isErrorWhileLoading = true;
      this.updateToast({ open: true, message: message || "Easyship connection failed, please reload or try after some time.", type: "danger" });
    },
    checkConnection() {
      if (this.dataFlowConnectionUrl) {
        location.href = this.dataFlowConnectionUrl;
      } else {
        this.showError();
      }
    },
    async fetchDatFlowConnectionUrl() {
      this.loading = true;
      try {
        const response = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "start-dialogflow-connect");
        this.loading = false;
        if (response.data.success) {
          this.dataFlowConnectionUrl = response.data.url;
          return true;
        }
        this.showError(response.data.errorMessage);
      } catch (error) {
        this.showError();
      }
      this.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
ion-card {
  width: 100%;
}

ion-card-title {
  text-align: center;
}

ion-card-content {
  text-align: center;
  padding-top: 10px;
}

ion-card-content div {
  text-align: center;
}

ion-buttons {
  padding-top: 10px;
  justify-content: center;
}
</style>