<template>
  <master-layout :pageTitle="'Integration'">
    <ion-content>
      <ion-item lines="none" class="ion-no-padding">
        <ion-toolbar class="chat-toolbar">
          <ion-buttons slot="start">
            <ion-icon @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
            <h2 class="ion-no-margin">{{connectionTitle}}</h2>
          </ion-buttons>
        </ion-toolbar>
      </ion-item>
      <component v-if="componentToLoad" v-bind:is="componentToLoad"></component>
    </ion-content>
  </master-layout>
</template>

<script>
import shopify from "../../components/integration/shopify.vue"
import magento from "../../components/integration/magento.vue"
import bigCommerce from "../../components/integration/bigCommerce.vue"
import easyparcel from "../../components/integration/easyparcel.vue"
import dialogflow from "../../components/integration/dialogflow.vue"
import easyship from "../../components/integration/easyship.vue"
import { arrowBackOutline } from 'ionicons/icons';

export default {
  components: { shopify, magento, bigCommerce, easyparcel, dialogflow, easyship },
  data() {
    return {
      componentToLoad: "",
      arrowBackOutline
    }
  },
  props: {
    numberOfSteps: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    this.componentToLoad = this.$route.params.component;
  },
  computed: {
    connectionTitle() {
      return `Connect ${this.componentToLoad.charAt(0).toUpperCase() + this.componentToLoad.slice(1)}`;
    }
  },
  methods: {}
}
</script>

<style scoped>
</style>