<template>
<master-layout pageTitle="My Orders">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulliv-icon="chevronDownCircleOutline"
        pulliv-text="Pull to refresh"
        refreshiv-spinner="circles"
        refreshiv-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
    ></ion-loading>

    <div class="text-right" style="padding: 15px 15px 0px;">
      <span class="tooltip_container">
        <ion-button fill="outline" size="default" color="dark" @click="() => optInNotificationsModal = true" v-if="user.enterprise_Name != 'redRibbon_merchant' && TYMEBANK_SERVER != true" style="margin-right: 7px;">
          <ion-icon :icon="mailOutline"></ion-icon>
        </ion-button>
        <span class="tooltip_text left">New order email notifications</span>
      </span>

      <!-- <ion-button fill="outline" size="default" color="dark" router-link="/bulk-update/product" v-if="(isAdmin || oldPermissions.bulk_update_order_shipping_link) && ordersPendingResult.length > 0 && (accountType_master == true || userRolesPermission.orders.bulk_update_order_shipping_link != false) && bulkUpdateOfShippingLink_permissionEnabled == true && user.enterprise_Name != 'redRibbon_merchant'" style="margin-right: 7px;">
        Tracking link (Bulk update)
      </ion-button> -->

      <ion-button fill="outline" size="default" color="dark" class="downloadCSVForAllOrdersv2Btn" @click="csvExportWithDateRangeModal_open('orders','Export orders')" v-if="(isAdmin || oldPermissions.orders.orders_export) && ordersPendingResult.length > 0 && (accountType_master == true || user.enterprise_Name == 'soshop' || userRolesPermission.orders.orders_export != false)" style="margin-right: 7px;">
        Export orders
      </ion-button>

      <ion-button fill="outline" size="default" color="dark" class="downloadCSVForAllOrdersv2Btn_loreal_my_ba" @click="csvExportWithDateRangeModal_open('BAReport','Export BA sales report')" v-if="(isAdmin || oldPermissions.orders.orders_export) && ordersPendingResult.length > 0 && accountType_master == true && loreal_my_family_user == true">
        Export BA sales report
      </ion-button>
    </div>
    
    <ion-toolbar class="chat-toolbar" color="light">
      <ion-item class="searchBar" lines="none">
        <ion-input v-model="searchOrderId" placeholder="Search by Ref. Order ID"></ion-input>
        <ion-buttons slot="end">
          <ion-button @click="searchOrder()"> 
            <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!searchMode" @click="() => filterModal = true">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="searchMode" slot="end">
          <ion-button @click="initView(true)"> 
            <ion-icon slot="icon-only" :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-toolbar>

    <section class="table-responsive-container" v-if="!loading">
      <table>
        <thead>
          <tr>
            <th>Order</th>
            <th>Customer</th>
            <th>Total</th>
            <th>Paid via</th>
            <th v-if="user.enterprise_Name != 'eazylink' && user.enterprise_Name != 'ezlink' && user.enterprise_Name != 'redribbon_manila'">
              <span v-if="clickAndCollectUser == true">Status</span>
              <span v-else>Shipment</span>
            </th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody v-for="result in ordersPendingResult" :key="result.id">
          <tr v-if="result.restaurant">
            <td>
              <ion-text class="displayBlock">
                {{moment(result.created).format("DD-MMM-YYYY")}}
              </ion-text>
              <ion-text class="displayBlock">
                <b>ID: </b>{{ result.ordernumber }}
              </ion-text>
              <ion-text class="displayBlock">
                <b>Ref. Order ID: </b>{{ result.orderkeyid }}
              </ion-text>
            </td>
            <td colspan="5">
              <ion-text class="displayBlock" color="medium">
                <span>This order is for your F&B store. </span>
                <a class="hoverUnderline" href="/restaurant-orders">Click here</a>
                <span> to view order details.</span>
              </ion-text>
            </td>
          </tr>
          <tr else>
            <td>
              <div v-if="getKindOfOrder(result) == 'askPayment'">
                <ion-badge color="secondary">Payment request</ion-badge>
              </div>
              <div v-if="result.status == 'stockover'">
                <ion-badge color="warning">1 or more item(s) is out of stock</ion-badge>
              </div>
              <div v-if="result.status == 'discountover'">
                <ion-badge color="warning">1 or more discounted item(s) is over</ion-badge>
              </div>
              <div v-if="result.status != 'stockover' && result.status != 'discountover'">
                <ion-badge color="success" v-if="getOrderStatus_formattedName(result.status,result.payment,result.gateway) == 'Paid'">
                  <b>Status:</b>
                  {{ getOrderStatus_formattedName(result.status,result.payment,result.gateway) }}
                </ion-badge>
                <ion-badge color="secondary" v-else>
                  <b>Status:</b>
                  {{ getOrderStatus_formattedName(result.status,result.payment,result.gateway) }}
                </ion-badge>
              </div>

              <ion-text class="displayBlock">
                {{moment(result.created).format("DD-MMM-YYYY")}}
              </ion-text>
              <ion-text class="displayBlock">
                <b>ID: </b>{{ result.ordernumber }}
              </ion-text>
              <ion-text class="displayBlock">
                <b>Ref. Order ID: </b>{{ result.orderkeyid }}
              </ion-text>

              <ion-text class="displayBlock">
                <span v-if="user.enterprise_Name == 'samsungid'">
                  <b>Ordered via:</b>
                  {{ platformNameWhereOrderPlacedOn(result.type) }}
                </span>

                <div v-if="getKindOfOrder(result) == 'askPayment'" style="margin-top: 10px;">
                  <b>Payment note:</b>
                  {{ result.notes }}
                </div>

                <!-- <div v-if="result.variables && Object.keys(result.variables).length > 0 && (user.enterprise_Name == 'razer' || TYMEBANK_SERVER == true || amorepacific_family_user == true || IS_LIVE == false)" style="margin-top: 10px;">
                  <a @click="viewAllOrderVariables(result)">View extra data</a>
                </div> -->
                
                <!-- <span v-if="(user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink' || user.enterprise_Name == 'redribbon_manila') && result.voucher_codes && result.voucher_codes.length > 0">
                  <br />
                  <a class="hoverUnderline" @click="ezlink_singleOrder_eVouchers_modalOpen(result)">View e-voucher(s)</a>
                </span> -->

                <div v-if="checkIfOrderSoldByAgent(result)" style="margin-top: 10px;">
                  <b>Created by:</b>
                  <br />
                  <ion-chip color="dark" outline="true" style="margin-left: 0px;">
                    <ion-avatar>
                      <img :src="orderSoldByAgent_image(result)">
                    </ion-avatar>
                    <ion-label v-if="orderSoldByAgent_name(result)">{{ orderSoldByAgent_name(result) }}</ion-label>
                  </ion-chip>
                </div>
              </ion-text>
            </td>
            
            <td>
              <ion-text class="displayBlock">
                {{ result.buyer.name }}

                <a @click="$router.push('/page-inbox?conversation='+ result.conversation)" title="Go to conversation" v-if="result.conversation && result.conversation != '' && result.conversation != null && result.conversation != 'None' && user.enterprise_Name != 'redribbon_manila'">
                  <ion-icon :icon="chatbubblesOutline"></ion-icon>
                </a>
              </ion-text>

              <span v-if="accountType_master == true || loreal_my_org != true">
                <ion-text class="displayBlock">
                  <b>Email: </b>
                  <span v-if="result.buyer.email != '' && result.buyer.email != 'None' && result.buyer.email != null">{{ result.buyer.email }}</span>
                  <span v-if="result.buyer.email == '' || result.buyer.email == 'None' || result.buyer.email == null">Not provided</span>
                </ion-text>

                <ion-text class="displayBlock" v-if="result.buyer.mobileNumber != '' && result.buyer.mobileNumber != 'None' && result.buyer.mobileNumber != null">
                  <b>Mobile no.: </b>
                  {{result.buyer.mobilecountrycode}} {{ result.buyer.mobileNumber }}
                </ion-text>
              </span>

              <!-- extra info -->
              <span v-if="user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink'">
                <ion-text class="displayBlock" v-if="result.variables['Real Name']">
                  <b style="text-transform: capitalize;">Real name:</b>
                  {{ result.variables['Real Name'] }}
                </ion-text>
              </span>
              <!-- extra info -->
              
              <!-- product -->
              <span v-if="clickAndCollectUser != true || checkIfClickAndCollectDeliveryTrue(result.address) == true">
                <span v-if="accountType_master == true || loreal_my_org != true">
                  <!-- <ion-text class="displayBlock" v-if="result.address.addressLine1">
                    Shipping address:
                    <br />
                    <span v-html="getBillingAddressForThisOrder_html(result.address)"></span>
                  </ion-text>

                  <ion-text class="displayBlock" v-if="!result.address.addressLine1">
                    <b>Address:</b>
                    Not provided
                  </ion-text> -->
                </span>
              </span>

              <!-- billing address -->
              <span v-if="result.address.addressLine1 && result.billing_address && result.billing_address.addressLine1 && elc_family_user == true">
                <span v-if="accountType_master == true || loreal_my_org != true">
                  <!-- <ion-text class="displayBlock" v-if="result.billing_address.addressLine1 != ''">
                    Billing address:
                    <br />
                    <span v-html="getBillingAddressForThisOrder_html(result.billing_address)"></span>
                  </ion-text> -->
                </span>
              </span>
              <!-- billing address -->

              <ion-text class="displayBlock" v-if="getKindOfOrder(result) != 'askPayment' && result.notes != '' && result.notes != null && result.notes != 'None'">
                <b>Notes:</b>
                <br />
                {{ result.notes }}
              </ion-text>

              <ion-text class="displayBlock" v-if="result.variables.wrap_gift && result.variables.wrap_gift == true && user.enterprise_Name != 'elc_family_sg_lelabo' && user.enterprise_Name != 'elc_family_my_lelaboconcierge'">
                <ion-badge color="success" style="margin-top: 5px;">Gift wrapping required</ion-badge>
              </ion-text>

              <ion-text class="displayBlock" v-if="result.variables.gift_message && result.variables.gift_message != ''">
                <b>
                  <span v-if="user.enterprise_Name == 'elc_family_sg_lelabo' || user.enterprise_Name == 'elc_family_my_lelaboconcierge'">Personalised message:</span>
                  <span v-else>Personalized gift message:</span>
                </b>
                <br />
                <i v-html="renderNormalChat(result.variables.gift_message)"></i>
              </ion-text>
            </td>

            <td>
              <ion-text class="displayBlock">
                <b>Total: </b>
                <span class="uppercase" v-if="result.cart.length > 0">{{result.cart[0].currency}}</span>
                <span class="uppercase" v-if="result.cart.length == 0">{{result.product.currency}}</span>
                {{formatAmountInLocale(result.total,result.product.currency)}}
              </ion-text>

              <ion-text class="displayBlock" color="medium" v-if="result.cart.length == 0">
                <b>Unit price: </b>
                {{formatAmountInLocale(result.unitprice,result.product.currency)}}
              </ion-text>
              
              <ion-text class="displayBlock" color="medium">
                <b>Subtotal: </b>
                {{formatAmountInLocale(result.subtotal,result.product.currency)}}
              </ion-text>
              
              <ion-text class="displayBlock" color="medium" v-if="TYMEBANK_SERVER != true">
                <b>Discount: </b>
                {{formatAmountInLocale(result.discount,result.product.currency)}}
              </ion-text>

              <ion-text class="displayBlock" color="medium" v-if="result['reward discount'] && result['reward discount'] != '' && result['reward discount'] != 'None' && result['reward discount'] != null && result['reward discount'] != '0.00' && result['reward discount'] != '0' && result['reward discount'] != 0.00 && result['reward discount'] != 0">
                <b>Reward: </b>
                - <span v-html="result['reward discount']"></span>
              </ion-text>

              <span v-if="result['discount'] != result['total discount']">
                <ion-text class="displayBlock" color="medium" v-if="result['additional discount'] && result['additional discount'] != '' && result['additional discount'] != 'None' && result['additional discount'] != null && result['additional discount'] != '0.00' && result['additional discount'] != '0' && result['additional discount'] != 0.00 && result['additional discount'] != 0">
                  <b>Additional discount: </b>
                  - <span v-html="result['additional discount']"></span>
                </ion-text>

                <ion-text class="displayBlock" color="medium" v-if="result['total discount'] && result['total discount'] != '' && result['total discount'] != 'None' && result['total discount'] != null && result['total discount'] != '0.00' && result['total discount'] != '0' && result['total discount'] != 0.00 && result['total discount'] != 0">
                  <b>Total discount: </b>
                  - <span v-html="result['total discount']"></span>
                </ion-text>
              </span>
              
              <ion-text class="displayBlock" color="medium">
                <b>Shipping: </b>
                {{formatAmountInLocale(result.shippingcost,result.product.currency)}}
              </ion-text>
              
              <ion-text class="displayBlock" color="medium" v-if="elc_family_sg_user != true && elc_family_nz_user != true && elc_family_au_user != true">
                <b>Tax: </b>
                {{formatAmountInLocale(result.tax,result.product.currency)}}
              </ion-text>
            </td>

            <td>
              <ion-text class="displayBlock" v-if="checkIfPaymentModeIsManualOrNotForSingleOrder(result.gateway) == false">
                <ion-icon :icon="checkmarkOutline" class="iconFS24 vertAlignMid" color="success"></ion-icon>
                {{ renderPaymentModeName(result.gateway) }}
              </ion-text>
              
              <ion-text class="displayBlock" v-if="checkIfPaymentModeIsManualOrNotForSingleOrder(result.gateway) == true">
                <span v-if="user.enterprise_Name == 'samsungid'">
                  <ion-icon :icon="closeOutline" class="iconFS24 vertAlignMid" color="danger" v-if="renderPaymentDoneOrNot(result.status) == 'Pending' && checkIfMidtransBankPaymentTimeExpired(result.created) == true"></ion-icon>

                  <ion-icon :icon="timeOutline" class="iconFS24 vertAlignMid" color="warning" v-if="renderPaymentDoneOrNot(result.status) == 'Pending' && checkIfMidtransBankPaymentTimeExpired(result.created) == false"></ion-icon>
                  
                  <ion-icon :icon="checkmarkOutline" class="iconFS24 vertAlignMid" color="success" v-if="renderPaymentDoneOrNot(result.status) == 'Paid'"></ion-icon>
                </span>
                <span v-if="user.enterprise_Name != 'samsungid'">
                  <span v-if="result.gateway == 'midtrans-bank'">
                    <!-- <ion-icon :icon="closeOutline" class="iconFS24 vertAlignMid" color="danger" v-if="renderPaymentDoneOrNot(result.status) == 'Pending'"></ion-icon> -->

                    <ion-icon :icon="timeOutline" class="iconFS24 vertAlignMid" color="warning" v-if="renderPaymentDoneOrNot(result.status) == 'Pending'"></ion-icon>
                    
                    <ion-icon :icon="checkmarkOutline" class="iconFS24 vertAlignMid" color="success" v-if="renderPaymentDoneOrNot(result.status) == 'Paid'"></ion-icon>
                  </span>
                  <span v-if="result.gateway != 'midtrans-bank'">
                    <ion-icon :icon="closeOutline" class="iconFS24 vertAlignMid" color="danger" v-if="result.payment != true"></ion-icon>
                    <ion-icon :icon="checkmarkOutline" class="iconFS24 vertAlignMid" color="success" v-if="result.payment == true"></ion-icon>
                  </span>
                </span>
                {{ renderPaymentModeName(result.gateway) }}
                
                <span v-if="magentoConnected != true && user.enterprise_Name != 'samsungid' && acommerceConnected != true">
                  <span v-if="clickAndCollectUser != true || checkIfClickAndCollectDeliveryTrue(result.address) == true">
                    <div v-if="userRolesPermission.orders.manage != false">
                      <ion-button fill="solid" size="small" color="dark" class="markAsPaidBtn" v-if="result.payment != true && result.gateway != 'bblqr' && amorepacific_family_user != true" @click="markAsPaid(result.id)">
                        Mark as paid
                      </ion-button>
                    </div>
                  </span>
                </span>
              </ion-text>
            </td>

            <td v-if="user.enterprise_Name != 'eazylink' && user.enterprise_Name != 'ezlink' && user.enterprise_Name != 'redribbon_manila'">
              <span v-if="getKindOfOrder(result) == 'generic'">
                <span v-if="acommerceConnected == true">
                  <ion-text class="displayBlock" v-if="result.shipping.name" title="{{result.shipping.description}}">
                    <b>Type:</b> {{result.shipping.name}}
                  </ion-text>

                  <ion-text class="displayBlock" v-if="result.shippedlink != 'None' && result.shippedlink != null && result.shippedlink != ''">
                    <a :href="result.shippedlink" target="_blank" rel="noreferer noopener">Tracking link</a>
                  </ion-text>

                  <span v-if="user.enterprise_Name == 'samsungid'">
                    <ion-text class="displayBlock" v-if="result.variables['Status']">
                      <b style="text-transform: capitalize;">Status:</b> {{result.variables['Status']}}
                    </ion-text>
                    <ion-text class="displayBlock" v-if="result.variables['Status date']">
                      <b style="text-transform: capitalize;">Status date:</b> {{result.variables['Status date']}}
                    </ion-text>
                    <ion-text class="displayBlock" v-if="result.variables['Tracking link']">
                      <a :href="result.variables['Tracking link']" target="_blank" rel="noreferer noopener" style="text-transform: capitalize;">Tracking link</a>
                    </ion-text>
                  </span>
                  <span v-if="user.enterprise_Name != 'samsungid'">
                    <ion-text class="displayBlock" v-for="(key, value) in result.variables" :key="key">
                      <span v-if="key != 'quickreplies' && key != 'calculated_cost' && key != 'paymaya' && key != '2c2p'">
                        <b style="text-transform: capitalize;">{{key}}:</b> {{value}}
                      </span>
                    </ion-text>
                  </span>
                </span>
                <span v-else>
                  <span v-if="user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink'">
                    <ion-text class="displayBlock" v-if="result.variables['Status']">
                      <b style="text-transform: capitalize;">Status:</b> {{result.variables['Status']}}
                    </ion-text>
                    <ion-text class="displayBlock" v-if="result.variables['Status date']">
                      <b style="text-transform: capitalize;">Status date:</b> {{result.variables['Status date']}}
                    </ion-text>
                    <ion-text class="displayBlock" v-if="result.variables['Tracking link']">
                      <a :href="result.variables['Tracking link']" target="_blank" rel="noreferer noopener" style="text-transform: capitalize;">Tracking link</a>
                    </ion-text>
                  </span>

                  <!-- grab shipping start -->
                  <span v-if="result.variables.Grab_Express && result.variables.Grab_Express == true">
                    <ion-text class="displayBlock" style="display: inline-block; vertical-align: middle;">
                      <img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAkOn0m84KDA" style="height: 18px; width: auto;" />
                    </ion-text>
                    <ion-text class="displayBlock grabShippingStatusDotsContainer" style="display: inline-block; vertical-align: middle;">
                      <span class="singleDot green" v-if="result.variables.status == 'COMPLETED'" title="Completed"></span>
                      
                      <span class="singleDot yellow" v-if="result.variables.status != 'COMPLETED' && result.variables.status != 'FAILED'" title="In progress"></span>

                      <span class="singleDot red" v-if="result.variables.status == 'FAILED'" title="Failed"></span>
                    </ion-text>
                    
                    <!-- <ion-text class="displayBlock">
                      <a title="Shipping details" data-content="[[getGrabShippingDetailsForThisOrder_html(result)]]" ng-bind-html="selectedLangPackData.orders_history.view_shipping_details"></a>
                    </ion-text> -->
                  </span>
                  <!-- grab shipping end -->

                  <span v-if="loreal_my_family_user == true">
                    <ion-text class="displayBlock" v-if="result.variables['marketing_consent']">
                      <b style="text-transform: capitalize;">Marketing consent:</b> {{result.variables['marketing_consent']}}
                    </ion-text>
                    <ion-text class="displayBlock" v-if="!result.variables.marketing_consent && elc_family_user == true">
                      <b style="text-transform: capitalize;">Marketing consent:</b> No
                    </ion-text>

                    <ion-text class="displayBlock" v-if="result.variables['customer_dob']">
                      <b style="text-transform: capitalize;">Customer's DOB:</b> {{result.variables['customer_dob']}}
                    </ion-text>

                    <ion-text class="displayBlock" v-if="result.variables['customer_id']">
                      <b style="text-transform: capitalize;" v-if="loreal_my_org != true || user.referallshopName == 'retaildemo'">Customer ID: </b>
                      <b style="text-transform: capitalize;" v-if="loreal_my_org == true && user.referallshopName != 'retaildemo'">Mars ID: </b>
                      {{result.variables['customer_id']}}
                    </ion-text>

                    <ion-text class="displayBlock" v-if="result.variables['eba_id']">
                      <b style="text-transform: capitalize;" v-if="loreal_my_org != true || user.referallshopName == 'retaildemo'">Agent ID: </b>
                      <b style="text-transform: capitalize;" v-if="loreal_my_org == true && user.referallshopName != 'retaildemo'">EBA ID: </b>
                      {{result.variables['eba_id']}}
                    </ion-text>
                  </span>

                  <span>
                    <ion-text class="displayBlock" v-if="result.variables['merchant_notes']">
                      <b style="text-transform: capitalize;">Remarks:</b> {{result.variables['merchant_notes']}}
                      <a class="hoverUnderline" data-toggle="tooltip" data-placement="top" title="Update remarks" @click="() => { orderToAddRemarks = result.orderkeyid; remarks = result.variables && result.variables['merchant_notes'] ? result.variables['merchant_notes'] : undefined }">
                        <ion-icon :icon="pencilOutline"></ion-icon>
                      </a>
                    </ion-text>
                    <ion-text class="displayBlock" v-else>
                      <a class="hoverUnderline" @click="() => { orderToAddRemarks = result.orderkeyid; remarks = undefined; }">
                        Add remarks
                        <ion-icon :icon="pencilOutline"></ion-icon>
                      </a>
                    </ion-text>
                  </span>

                  <span v-if="user.enterprise_Name == 'smretail_ph'">
                    <ion-text class="displayBlock" v-if="result.variables['state']">
                      <b>Order mode:</b> {{getFormattedOrderModeName(value)}}
                    </ion-text>
                  </span>

                  <span v-if="clickAndCollectUser == true && checkIfClickAndCollectDeliveryTrue(result.address) != true">
                    <ion-text class="displayBlock">
                      <b v-if="result.delivered == true">Collected from: </b>

                      <b v-if="result.delivered == false">Collection from: </b>

                      <span v-if="result.collected_from_address.outletid">{{result.collected_from_address.name}}</span>

                      <span v-if="!result.collected_from_address.outletid">{{result.address.name}}</span>
                    </ion-text>

                    <div v-if="result.delivered == true">
                      <ion-badge color="success" style="margin-top: 10px;">Collected</ion-badge>
                    </div>

                    <div v-if="result.delivered == false">
                      <ion-badge color="secondary" style="margin-top: 10px;">Not collected yet</ion-badge>
                    </div>

                    <div v-if="result.delivered == false">
                      <ion-button fill="solid" size="small" color="dark" class="markAsDeliveredBtn" @click="markAsDeliveredTrue(result.id)">
                        Mark as collected
                      </ion-button>
                    </div>
                  </span>

                  <span v-if="clickAndCollectUser != true || checkIfClickAndCollectDeliveryTrue(result.address) == true">
                    <span v-if="user.enterprise_Name == 'smretail_ph'">
                      <ion-text class="displayBlock" v-if="result.variables['storeoutlet']">
                        <b>To be delivered from:</b> {{getCacOutletNameFromId(result.variables['storeoutlet'])}}
                      </ion-text>
                    </span>

                    <span v-if="user.enterprise_Name != 'eazylink'">
                      <ion-text class="displayBlock" v-if="result.shipping.name" title="{{result.shipping.description}}">
                        <b>Type:</b> {{result.shipping.name}}
                      </ion-text>
                      
                      <ion-text class="displayBlock" v-if="result.variables.shippedid && result.variables.shippedid != 'None' && result.variables.shippedid != null && result.variables.shippedid != ''">
                        <b>Tracking ID:</b> {{result.variables.shippedid}}
                      </ion-text>

                      <ion-text class="displayBlock" v-if="result.shippedlink != 'None' && result.shippedlink != null && result.shippedlink != ''">
                        <a :href="result.shippedlink" target="_blank" rel="noreferer noopener">Tracking link</a>
                      </ion-text>
                    </span>
                    
                    <span class="orderShipTabel">
                      <ion-text class="displayBlock" v-if="user.enterprise_Name == 'razer'">
                        <b>Status:</b> 
                        <span v-if="result.variables.shippedstatus && result.variables.shippedstatus != '' && result.variables.shippedstatus != null && result.variables.shippedstatus != 'None'">{{result.variables.shippedstatus}}</span>
                        <span v-if="!result.variables.shippedstatus || result.variables.shippedstatus == '' || result.variables.shippedstatus == null || result.variables.shippedstatus == 'None'">Received</span>
                      </ion-text>

                      <ion-text class="displayBlock" v-if="result.variables.shipping_event && result.variables.shipping_event != '' && result.variables.shipping_event != 'None' && result.variables.shipping_event != null && TYMEBANK_SERVER == true">
                        <b>Shipping status:</b> 
                        {{result.variables.shipping_event}}
                      </ion-text>

                      <div v-if="result.issent == true && user.enterprise_Name != 'razer' && TYMEBANK_SERVER != true && amorepacific_family_user != true">
                        <ion-badge color="success" style="margin-top: 10px;">Shipped</ion-badge>
                      </div>
                      
                      <span v-if="magentoConnected != true && user.enterprise_Name != 'redribbon_manila' && amorepacific_family_user != true">
                        <div v-if="checkIfToShowShipNowBtn(result) == true">
                          <ion-button fill="solid" size="small" color="dark" @click="() => productToShip = result" v-if="userRolesPermission.orders.manage != false && TYMEBANK_SERVER != true">
                            Ship now!
                          </ion-button>

                          <ion-button fill="solid" size="small" color="dark" @click="() => productToShip = result" v-if="userRolesPermission.orders.manage != false && TYMEBANK_SERVER == true && result.status == 'AUTHORIZED'">
                            Confirm order
                          </ion-button>
                        </div>
                      </span>
                    </span>
                  </span>

                  <span class="orderCancelTabel" v-if="result.cancel == false && (result.gateway == 'mollie' || user.enterprise_Name == 'eazylink' || user.enterprise_Name == 'ezlink')" style="display: block; margin-top: 10px;">
                    <div v-if="userRolesPermission.orders.manage != false && checkCancelForEzLink(result.variables) == true">
                      <ion-button fill="solid" size="small" color="danger" @click="cancelOrder(result)">
                        Cancel
                      </ion-button>
                    </div>
                  </span>
                </span>
              </span>
            </td>
            
            <td class="text-center">
              <div>
                 <span v-if="isAdmin || oldPermissions.orders.view" class="tooltip_container">
                    <ion-button fill="clear" size="default" color="dark" @click="openSingleOrderExpandedView(result)">
                      <ion-icon :icon="eyeOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left">View</span>
                </span>
                
                <span v-if="(isAdmin || oldPermissions.orders.manage) && (accountType_master == true || (accountType_master == false && loreal_my_org != true && user.enterprise_Name != 'redribbon_manila'))">
                  <span class="tooltip_container" v-if="user.enterprise_Name != 'razer' && TYMEBANK_SERVER != true">
                    <ion-button fill="clear" size="default" color="dark" @click="() => { orderToMail = result.orderkeyid; emailId = result.buyer?.email }">
                      <ion-icon :icon="mailOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left">Resend order confirmation</span>
                  </span>

                  <span class="tooltip_container" v-if="(isAdmin || oldPermissions.orders.manage) && (getKindOfOrder(result) == 'generic' && user.enterprise_Name != 'redRibbon_merchant' && elc_family_user != true && user.enterprise_Name != 'razer' && TYMEBANK_SERVER != true)">
                    <ion-button fill="clear" size="default" color="dark" @click="printSingleOrderInvoice(result)">
                      <ion-icon :icon="printOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left">Print order</span>
                  </span>

                  <span class="tooltip_container">
                    <ion-button fill="clear" size="default" color="danger" @click="() => orderToDelete = result.id" v-if="userRolesPermission.orders.manage != false && user.enterprise_Name != 'samsungid' && user.enterprise_Name != 'redribbon_manila' && elc_family_user != true && TYMEBANK_SERVER != true">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left">Delete order</span>
                  </span>

                  <span class="tooltip_container">
                    <ion-button fill="clear" size="default" color="danger" @click="() => orderToDelete = result.id" v-if="(isAdmin || oldPermissions.orders.manage) && (userRolesPermission.orders.manage != false && TYMEBANK_SERVER == true && result.status == 'AUTHORIZED' && checkIfToShowShipNowBtn(result) == true)">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <span class="tooltip_text left">Cancel order</span>
                  </span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination text-right" v-if="totalOrdersCount > ordersPendingResult.length">
        <ul>
          <li>
            <a class="page-link page-next-prev" @click="prevPagesGenericPagination(true)" v-bind:class="{'disabled': paginationOffsetGenericPagination == 0}">
              First
            </a>
          </li>
          <li>
            <a class="page-link page-next-prev" @click="prevPagesGenericPagination(false)" v-bind:class="{'disabled': paginationOffsetGenericPagination == 0}">
              <ion-icon :icon="chevronBackOutline"></ion-icon>
            </a>
          </li>
        </ul>

        <ul v-for="page in ordersPegenationList" :key="page">
          <li v-if="page >= paginationOffsetGenericPagination && page < paginationSizeGenericPagination + paginationOffsetGenericPagination" v-bind:class="{ 'active' : currentPaginationNumber == page+1 }">
            <a class="page-link" @click="loadMoreOrders(page+1)">{{ page+1 }}</a>
          </li>
        </ul>

        <ul>
          <li>
            <a class="page-link page-next-prev" @click="nextPagesGenericPagination(false,ordersPegenationList.length)" v-bind:class="{'disabled': paginationOffsetGenericPagination + paginationSizeGenericPagination == ordersPegenationList.length}">
              <ion-icon :icon="chevronForwardOutline"></ion-icon>
            </a>
          </li>
          <li>
            <a class="page-link page-next-prev" @click="nextPagesGenericPagination(true,ordersPegenationList.length)" v-bind:class="{'disabled': paginationOffsetGenericPagination + paginationSizeGenericPagination == ordersPegenationList.length}">
              Last
            </a>
          </li>
        </ul>
      </div>
    </section>
  </ion-content>

  <order-details v-if="singleOrderExpandedView_opened" :open="singleOrderExpandedView_opened" @close="() => { singleOrderExpandedView_opened = false; currentSingleOrderData = null; }" :order="currentSingleOrderData" :clickAndCollectUser="clickAndCollectUser" :orderKind="getKindOfOrder(currentSingleOrderData)" :soldByAgent="checkIfOrderSoldByAgent(currentSingleOrderData)" :agentName="orderSoldByAgent_name(currentSingleOrderData)" :agentImage="orderSoldByAgent_image(currentSingleOrderData)" :billAddressHTML="getBillingAddressForThisOrder_html(currentSingleOrderData.address)" :grabShippingDetailsHTML="getGrabShippingDetailsForThisOrder_html(currentSingleOrderData)" />

    <!-- New order notifications modal -->
  <ion-modal
    v-if="optInNotificationsModal"
    :is-open="optInNotificationsModal"
    @didDismiss="() => optInNotificationsModal = false"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">New order email notifications</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => optInNotificationsModal = false">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-radio-group v-model="loginCredential">
            <ion-list-header>
              <ion-label>Would you like to receive new order notifications on your email?</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Yes</ion-label>
              <ion-radio slot="start" :value="true"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>No</ion-label>
              <ion-radio slot="start" :value="false"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button fill="solid" size="small" color="dark" @click="saveOptInNotification">Save</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>
  
    <!-- Ship Modal -->
  <ion-modal
    v-if="productToShip !== null"
    :is-open="productToShip !== null"
    @didDismiss="() => productToShip = null"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Shipping Details</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => productToShip = null">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Add shipment tracking ID:</ion-label>
          <ion-input slot="end" v-model="shipmentID" placeholder="Enter shipment tracking ID"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Select provider name & auto-populate shipment tracking link:</ion-label>
          <ion-select slot="end" v-model="shipmentWay" placeholder="Provider">
            <ion-select-option value="ninja">Ninja Van</ion-select-option>
            <ion-select-option value="lala">Lalamove</ion-select-option>
            <ion-select-option value="custom">Enter custom link</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label>Add shipment tracking link:</ion-label>
          <ion-input :disabled="shipmentWay !== 'custom'" slot="end" v-model="shipmentLink" placeholder="Enter shipment tracking link"></ion-input>
        </ion-item>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button fill="solid" size="small" color="dark" @click="shipProduct">Ship Now</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>

  <!-- Resend order confirmation email Modal -->
  <ion-modal
    v-if="orderToMail"
    :is-open="orderToMail"
    @didDismiss="() => { orderToMail = undefined; emailId = undefined; }"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Resend order confirmation email</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => { orderToMail = undefined; emailId = undefined; }">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Customer email</ion-label>
          <ion-input slot="end" v-model="emailId" type="email" placeholder="Enter Customer email"></ion-input>
        </ion-item>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button fill="solid" size="small" color="dark" @click="sendOrderConfirmEmail()">Send Email</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>

  <!-- Add remarks Modal -->
  <ion-modal
    v-if="orderToAddRemarks"
    :is-open="orderToAddRemarks"
    @didDismiss="() => { orderToAddRemarks = undefined; remarks = undefined; }"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <h2 class="ion-no-margin">Order remarks</h2>
          <ion-buttons slot="end">
            <ion-button @click="() => { orderToAddRemarks = undefined; remarks = undefined; }">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item>
          <ion-label>Internal notes & remarks</ion-label>
          <ion-input slot="end" v-model="remarks" placeholder="Enter remarks"></ion-input>
        </ion-item>
      </ion-content>
      <ion-footer>
        <ion-toolbar class="fxd_btm_block">
          <ion-button fill="solid" size="small" color="dark" @click="addRemarks()">Save</ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>

  <!-- Filter Modal  -->
   <ion-modal
      v-if="filterModal"
      :is-open="filterModal"
      @didDismiss="dismissFilter()"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="ion-no-margin" style="padding-left: 12px">Filter by</h2>
            <ion-buttons slot="end">
              <ion-button @click="dismissFilter()">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <!-- pending: filter by outlet -->
          <ion-item-group>
            <ion-item>
              <ion-label for="startDate" @click="$refs.openStartDateInput.click()">Start Date</ion-label>
              <ion-input @focus="$refs.openStartDateInput.click()" :value="startDate ? moment(startDate).format('DD-MMM-YY') : undefined" id="startDate"></ion-input>
              <ion-button color="dark" id="openStartDateInput" ref="openStartDateInput" fill="clear">
                <ion-icon slot="start" :icon="calendar"></ion-icon>
              </ion-button>
              <ion-popover slot="end" side="top" trigger="openStartDateInput">
                <ion-datetime :max="moment().endOf('day').toISOString()" display-format="DD/MM/YY" :value="startDate" ref="startDate" name="startDate" @ionChange="e => {startDate = moment(new Date(e.detail.value)).toISOString(); endDate = undefined;}" presentation="date" id="startDate"></ion-datetime>
              </ion-popover>
            </ion-item>

            <ion-item>
              <ion-label for="endDate" @click="$refs.openEndDateInput.click()">End Date</ion-label>
              <ion-input @focus="$refs.openEndDateInput.click()" :value="endDate ? moment(endDate).format('DD-MMM-YY') : undefined" id="endDate"></ion-input>
              <ion-button color="dark" id="openEndDateInput" ref="openEndDateInput" slot="end" fill="clear" style="margin-right: 10px">
                <ion-icon slot="end" :icon="calendar"></ion-icon>
              </ion-button>
              <ion-popover slot="end" side="top" trigger="openEndDateInput">
                <ion-datetime :min="moment(startDate).endOf('day').toISOString()" :max="moment().endOf('day').toISOString()" display-format="DD/MM/YY" :value="endDate" ref="endDate" name="endDate" @ionChange="e => endDate = moment(new Date(e.detail.value)).toISOString()" presentation="date" id="endDate"></ion-datetime>
              </ion-popover>
            </ion-item>
            <ion-item style="float:right" lines="none">
              <ion-button @click="filterOrders"  fill="solid" size="small" color="dark">
                <ion-icon slot="icon-only" :icon="search" size="small"></ion-icon>
                Filter
              </ion-button>
            </ion-item>
          </ion-item-group>
        </ion-content>
      </ion-page>
   </ion-modal>
   <ion-alert
    v-if="orderToDelete"
    :is-open="orderToDelete"
    header="Are you sure?"
    message="You want to delete this order?"
    :buttons="[{
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        orderToDelete = undefined;
      },
    },
    {
      text: 'Yes',
      handler: () => {
        deleteOrder()
      },
    }]"
    @didDismiss="() => permissionRejected = false"
  >
  </ion-alert>

  <exports-modal :type="csvExportWithDateRangeModal_type" :isOpen="csvExportWithDateRangeModal" :title="csvExportWithDateRangeModal_title" @close="() => csvExportWithDateRangeModal = false"></exports-modal>
</master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonPopover, IonSelect, IonSelectOption, IonRadio, IonRadioGroup } from "@ionic/vue";
import orderDetails from '@/components/orderDetails.vue';
import exportsModal from '/src/components/exportsModal.vue';

import {
  refresh,
  close,
  chevronDownCircleOutline,
  chevronBackOutline,
  chevronForwardOutline,
  search,
  searchOutline,
  trashOutline,
  chatbubblesOutline,
  mailOutline,
  printOutline,
  eyeOutline,
  pencilOutline,
  closeOutline,
  checkmarkOutline,
  timeOutline,
  calendar,
  filterOutline,

} from 'ionicons/icons';
import { mapGetters } from "vuex";

export default {
  components: {
    IonPopover, orderDetails, IonSelect, IonSelectOption, exportsModal, IonRadio, IonRadioGroup
  },
  data() {
    return {
      refresh,
      close,
      calendar,
      chevronDownCircleOutline,
      chevronBackOutline,
      chevronForwardOutline,
      searchOutline,
      filterOutline,
      search,
      trashOutline,
      chatbubblesOutline,
      mailOutline,
      printOutline,
      eyeOutline,
      pencilOutline,
      closeOutline,
      checkmarkOutline,
      timeOutline,
      loading: true,
      loadingTransactions: false,
      allOutlets: [],
      startDate: undefined,
      endDate: undefined,
      totalOrdersCount: 0,
      ordersPendingResult: [],
      ordersPegenationList: [],
      currentPaginationNumber: 1,
      searchOrderId: undefined,
      clickAndCollectUser: false,
      currentSingleOrderData: null,
      currentlyOpen_orderId: undefined,
      currentlyOpen_notes: undefined,
      singleOrderExpandedView_opened: false,
      filterModal: false,
      productToShip: null,
      shipmentID: undefined,
      shipmentWay: undefined,
      shipmentLink: undefined,
      searchMode: false,
      orderToDelete: undefined,
      orderToMail: undefined,
      emailId: undefined,
      orderToAddRemarks: undefined,
      remarks: undefined,
      optInNotificationsModal: false,
      loginCredential: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "user/isAdmin",
      oldPermissions: "user/getPermissions"
    }),
  },
  async ionViewWillEnter(){
    await this.initView();
    this.loginCredential = this.user.loginCredential;
  },
  watch: {
    shipmentWay() {
      switch(this.shipmentWay) {
        case 'ninja': this.shipmentID ? this.shipmentLink = 'https://www.ninjavan.co/en-sg/tracking?id=' + this.shipmentID : this.shipmentLink = 'https://www.ninjavan.co/en-sg/tracking?id=';
        break;
        case 'lala': this.shipmentID ? this.shipmentLink = 'https://share.lalamove.com/' + this.shipmentID : this.shipmentLink = 'https://share.lalamove.com/';
        break;
        default: this.shipmentLink = undefined;
      }
    },
    shipmentID() {
      switch(this.shipmentWay) {
        case 'ninja': this.shipmentID ? this.shipmentLink = 'https://www.ninjavan.co/en-sg/tracking?id=' + this.shipmentID : this.shipmentLink = 'https://www.ninjavan.co/en-sg/tracking?id=';
        break;
        case 'lala': this.shipmentID ? this.shipmentLink = 'https://share.lalamove.com/' + this.shipmentID : this.shipmentLink = 'https://share.lalamove.com/';
        break;
        default: this.shipmentLink = undefined;
      }
    }
  },
  methods: {
    async initView(clearFilter) {
      try {
        if(clearFilter) {this.searchMode = false; this.searchOrderId = undefined; this.startDate = undefined; this.endDate = undefined;}
        let reqBody = {
          seller: "true",
          size: 10
        }
        await this.fetchOrders(reqBody);
        if(!clearFilter) {
          let outletsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-outlets");
          if((outletsResponse.status === 200 || outletsResponse.status === 201) && outletsResponse.data) {
            if(outletsResponse.data.success) {
              this.allOutlets = outletsResponse.data.outlets;
              if(this.allOutlets.length) this.clickAndCollectUser = true;
            } else {
              this.updateToast({
                open: true,
                message: outletsResponse.data.errorMessage,
                type: "danger"
              })
            }
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      }
    },
    async fetchOrders(reqBody,pageOffset) {
      try {
        this.loading = true;
        this.loadingTransactions = true;
        let transactionsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "transaction", reqBody);
        if((transactionsResponse.status === 200 || transactionsResponse.status === 201) && transactionsResponse.data) {
          if(transactionsResponse.data.success) {
            //this.ordersPendingResult && this.ordersPendingResult.length ? this.ordersPendingResult.push.apply(this.ordersPendingResult, transactionsResponse.data.transactions) : this.ordersPendingResult = transactionsResponse.data.transactions;
            this.ordersPendingResult = transactionsResponse.data.transactions;
            this.totalOrdersCount = transactionsResponse.data.total;

            this.settingUpPaginations(transactionsResponse.data,pageOffset);
          } else {
            this.updateToast({
              open: true,
              message: transactionsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.loadingTransactions = false;
      }
    },
    settingUpPaginations(data,pageOffset){
      if(typeof pageOffset != 'undefined'){
        if(pageOffset == 1){
          this.currentPaginationNumber = 1;
        }
        else if(pageOffset != 0){
          this.currentPaginationNumber = pageOffset;
        }
      }

      if(data.transactions.length > 0){
        if(data.total > data.transactions.length){
          var total = data.total;
          var totalPagesCount = total / 10;
          var totalPagesCountString = totalPagesCount.toString();

          if(totalPagesCountString.indexOf('.') > -1){
            totalPagesCountString = totalPagesCountString.split('.');
            
            totalPagesCount = parseInt(totalPagesCountString[0]);

            if(parseInt(totalPagesCountString[1]) > 0){
              totalPagesCount++;
            }
          }

          this.ordersPegenationList = [];
          
          for(var i=0; i < totalPagesCount; i++){
            this.ordersPegenationList.push(i);
          }

          //console.log(this.ordersPegenationList);
        }
      }
    },
    async searchOrder() {
      try {
        if(this.searchOrderId) {
          let reqBody = {
            ordernumber: this.searchOrderId,
            seller: "true",
            size: 10
          }
          await this.fetchOrders(reqBody);
          this.searchMode = true;
        } else {
          this.updateToast({
            open: true,
            message: "Please enter an order ID",
            type: "warning"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async filterOrders() {
      try {
        if(this.startDate && this.endDate) {
          let reqBody = {
            size: 10,
            seller: "true",
            startdate: this.moment(this.startDate).format("DD/MM/YYYY"),
            enddate: this.moment(this.endDate).format("DD/MM/YYYY"),
          }
          await this.fetchOrders(reqBody);
          this.filterModal = false;
          this.searchMode = true;
        } else {
          this.updateToast({
            open: true,
            message: "Please enter a valid start date and end date",
            type: "warning"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async loadMoreOrders(pageOffset) {
      try {
        let reqBody = {
          seller: "true",
          size: 10,
        }

        if(typeof pageOffset != 'undefined'){
          if(pageOffset != 0 && pageOffset != 1){
            reqBody['offset'] = 10 * (pageOffset - 1);
          }
        }

        await this.fetchOrders(reqBody,pageOffset);
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async doRefresh(event) {
      this.initView();
      event.target.complete();
    },
    async markAsPaid(id) {
      try {
        this.loading = true;
        let reqBody = {
          transactionid: id
        }
        let processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "mark-transaction", reqBody);

        if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
          if(processOrderResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Order marked as paid successfully!",
              type: "success"
            })
            let thisOrder = this.ordersPendingResult.find(e => e.id === id);
            if(thisOrder){
              thisOrder.payment = true;
              thisOrder.status = "Paid";
            }
          } else {
            this.updateToast({
              open: true,
              message: processOrderResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", processOrderResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async markAsDeliveredTrue(id) {
      try {
        let reqBody = {
          transactionid: id
        }
        let processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "validate-buzz", reqBody);

        if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
          console.log(processOrderResponse.data);
          if(processOrderResponse.data.success) {
            processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "mark-transaction", reqBody);
            if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
              console.log(processOrderResponse.data);
              if(processOrderResponse.data.success) {
                this.updateToast({
                  open: true,
                  message: "Order marked as collected successfully!",
                  type: "success"
                })
                let thisOrder = this.ordersPendingResult.find(e => e.id === id);
                if(thisOrder){
                  thisOrder.delivered = true;
                  thisOrder.payment = true;
                  thisOrder.status = "Paid";
                }
              } else {
                this.updateToast({
                  open: true,
                  message: processOrderResponse.data.errorMessage,
                  type: "danger"
                })
              }
            } else {
              console.log("Response:", processOrderResponse);
              this.updateToast({
                open: true,
                message: "Oops! Something went wrong",
                type: "danger"
              })
            }
          } else {
            this.updateToast({
              open: true,
              message: processOrderResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", processOrderResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    getKindOfOrder(orderData) {
      if(typeof orderData != 'undefined') {
        if(typeof orderData.cart != 'undefined') {
          if(orderData.cart.length > 0) return "generic";
          else if(typeof orderData.product.hashtag != 'undefined') {
            if(typeof orderData.product.itemtype != 'undefined') return "botBuilderFlow";
            else return "generic";
          }
          else if(orderData.notes != null) return "askPayment";
          else return "botBuilderFlow";
        }
        else return "generic";
      } else return undefined;
    },
    renderPaymentDoneOrNot(input){
      if(typeof input != 'undefined'){
        if(input == "pending" || input == "Pending Payment" || input == "Payment Pending"){
          input = "Pending";
        }
        else if(input == "paid" || input == "Paid" || input == "Captured"){
          input = "Paid";
        }
        
        return input;
      }
    },
    getOrderStatus_formattedName(status,payment,gateway,cancelBool){
      if(typeof status != 'undefined'){
        if(this.amorepacific_family_user == true || this.TYMEBANK_SERVER == true){
          return status;
        }

        if(cancelBool == true){
          return "Cancelled";
        }

        if(this.checkIfPaymentModeIsManualOrNotForSingleOrder(gateway) == false && (this.renderPaymentDoneOrNot(status) == 'Pending' || this.renderPaymentDoneOrNot(status) == 'Paid')){
          return "Paid";
        }

        if(this.renderPaymentDoneOrNot(status) == 'Paid'){
          if(payment === true){
            return "Paid";
          }
          else if(payment === false){
            return "Payment pending";
          }
        }
        else if(this.checkIfPaymentModeIsManualOrNotForSingleOrder(gateway) == true && this.renderPaymentDoneOrNot(status) == 'Pending' && payment === true){
          return "Paid";
        }
        else{
          return status;
        }
      }
    },
    checkCancelForEzLink(data){
      if(typeof data != 'undefined'){
        var statusValue = "";

        for(var key in data){
          if(key == 'Status'){
            statusValue = data[key];
          }
        }

        if(statusValue == '' || statusValue == 'Confirmed' || statusValue == 'Arrived at Hub'){
          return true;
        }
        else{
          return false;
        }
      }
    },
    getBillingAddressForThisOrder_html(address){
      if(typeof address != 'undefined'){
        var html_text = "";

        if(address.customer_name && address.customer_name != ''){
          html_text += "<u>Name:</u> "+ address.customer_name +"<br />";
        }
        if(address.customer_email && address.customer_email != ''){
          html_text += "<u>Email:</u> "+ address.customer_email +"<br />";
        }
        if(address.customer_mobile && address.customer_mobile != ''){
          html_text += "<u>Mobile no.:</u> "+ address.customer_countrycode +" "+ address.customer_mobile +"<br />";
        }

        html_text += address.addressLine1 +", ";

        if(address.addressLine2 && address.addressLine2 != '' && address.addressLine2 != null && address.addressLine2 != 'None'){
          html_text += address.addressLine2 +", ";
        }

        html_text += address.pincode +", "+ address.city +", "+ address.state +", "+ address.country;

        return html_text;
      }
    },
    getGrabShippingDetailsForThisOrder_html(result){
      if(typeof result != 'undefined'){
        var html_text = "";

        if(result.variables.status && result.variables.status != ''){
          html_text += "<u>Status:</u> "+ result.variables.status +"<br />";
        }

        //result.variables.deliveryID
        //result.variables.grab_status

        if(result.variables.driver_name && result.variables.driver_name != ''){
          html_text += "<u>Driver name:</u> "+ result.variables.driver_name +"<br />";
        }

        if(result.variables.driver_phone && result.variables.driver_phone != ''){
          html_text += "<u>Driver phone:</u> "+ result.variables.driver_phone +"<br />";
        }

        if(result.variables.driver_license && result.variables.driver_license != ''){
          html_text += "<u>Driver license:</u> "+ result.variables.driver_license +"<br />";
        }

        html_text += "<u>Collection point:</u> "+ this.getCollectLocationName(result.variables.outletid) +"<br />";

        if(result.variables.estimatedTimeline){
          if(result.variables.estimatedTimeline.pickup && result.variables.estimatedTimeline.pickup != ''){
            html_text += "<u>Pick-up by:</u> "+ this.renderDateTime_forGrabPickupAndDropOff(result.variables.estimatedTimeline.pickup,true,true) +"<br />";
          }
          
          if(result.variables.estimatedTimeline.dropoff && result.variables.estimatedTimeline.dropoff != ''){
            html_text += "<u>Drop-off by:</u> "+ this.renderDateTime_forGrabPickupAndDropOff(result.variables.estimatedTimeline.dropoff,true,true) +"<br />";
          }
        }

        return html_text;
      }
    },
    getCollectLocationName(outletid){
      if(typeof outletid != 'undefined'){
        if(typeof this.outletsResults != 'undefined'){
          for(var i=0; i < this.outletsResults.length; i++){
            if(this.outletsResults[i].outletid == outletid){
              return this.outletsResults[i].name;
            }
          }
        }
      }
    },
    renderDateTime_forGrabPickupAndDropOff(input,convert,timeBool){
      if(typeof input != 'undefined'){
        //2021-08-19T17:38:02Z
        if(convert != false){
          input = input.replace('Z', '+00:00');
          input = this.moment(input).format();
          
          input = input.split('T');

          let date = input[0].split('-');
          date = date[2] +'-'+ date[1] +'-'+ date[0];

          let time = input[1].split(':');
          var h = time[0];
          var m = time[1];
          var s = "";
          if(time[2].indexOf('+') > -1){
            s = time[2].split('+');
          }
          else{
            s = time[2].split('-');
          }
          time = h +':'+ m +':'+ s[0];

          if(timeBool == false){
            return date;
          }
          else{
            return date +' '+ time;
          }
        }
        else{
          input = input.split(' ');
          let date = input[0].split('-');
          date = date[2] +'-'+ date[1] +'-'+ date[0];

          let time = input[1].split('.');
          
          if(timeBool == false){
            return date;
          }
          else{
            return date +' '+ time[0];
          }
        }
      }
    },
    checkIfOrderSoldByAgent(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          return true;
        }
        else{
          if(eba_id != ''){
            return true;
          }
          else{
            return false;
          }
        }
      }
    },
    orderSoldByAgent_name(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          return agentData.name;
        }
        else{
          if(eba_id != ''){
            return eba_id;
          }
        }
      }
    },
    orderSoldByAgent_image(order) {
      if(typeof order != 'undefined'){
        var agentData = order.agent;
        var eba_id = "";

        if(typeof order.variables.eba_id != 'undefined' && order.variables.eba_id != '' && order.variables.eba_id != 'None' && order.variables.eba_id != null){
          eba_id = order.variables.eba_id;
        }

        if(typeof agentData != 'undefined' && Object.keys(agentData).length !== 0){
          if(agentData.picture == '' || agentData.picture == 'None' || agentData.picture == null){
            return "https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg";
          }
          else{
            return agentData.picture;
          }
        }
        else{
          if(eba_id != ''){
            return "https://jumper.ai/web-s/resources/images/page-inbox-human-support.svg";
          }
        }
      }
    },
    checkIfClickAndCollectDeliveryTrue(address){
      if(typeof address != 'undefined'){
        if(this.clickAndCollectUser == true){
          if(typeof address.outletid == 'undefined'){
            return true;
          }
        }
      }
    },
    getFormattedOrderModeName(state){
      if(typeof state != 'undefined'){
        if(state == 'pickup' || state == 'collect'){
          return "Pick-up";
        }
        else if(state == 'delivery'){
          return "Delivery";
        }
      }
    },
    platformNameWhereOrderPlacedOn(type){
      if(typeof type != 'undefined'){
        type = type.toLowerCase();

        if(type == "facebook"){
          return "Facebook";
        }
        else if(type == "whatsapp"){
          return "WhatsApp";
        }
        else if(type == "instagram"){
          return "Instagram";
        }
        else if(type == "line"){
          return "LINE";
        }
        else if(type == "viber"){
          return "Viber";
        }
        else if(type == "telegram"){
          return "Telegram";
        }
        else if(type == "sms"){
          return "SMS";
        }
        else if(type == "googlebusinessmsg"){
          return "Google Business Messages";
        }
        else if(type == "imessage"){
          return "iMessage";
        }
        else if(type == "twitter"){
          return "Twitter";
        }
        else if(type == "adlingo"){
          return "AdLingo";
        }
        else{
          return "Checkout direct link";
        }
      }
    },
    ezlink_singleOrder_eVouchers_modalOpen(transaction) {
      this.currentSingleOrderData = transaction;
    },
    updateOrderMerchantNotesModalOpen(orderid, notes) {
      this.currentlyOpen_orderId = orderid;
      this.currentlyOpen_notes = notes;
    },
    getCacOutletNameFromId(id) {
      if(id) {
        let thisOutlet = this.allOutlets.find(e => e.outletid === id);
        if(thisOutlet) return thisOutlet.name;
      } return undefined;
    },
    checkIfMidtransBankPaymentTimeExpired(input) {
      if(input) {
        input = input.replace(' ', 'T').split('.');
        input = input[0];
        input = input.split(':');
        input = input[0] +":"+ input[1];

        let inputDate = new Date(input);
        
        if(this.myTimeZone == 'Asia/Singapore') inputDate.setMinutes(inputDate.getMinutes() + 480);
        else if(this.myTimeZone == 'Asia/Jakarta') inputDate.setMinutes(inputDate.getMinutes() + 420);
        inputDate = inputDate.getTime();

        let expiryDate = inputDate + (1 * 12 * 60 * 60 * 1000);
        let currentDate = new Date().getTime();

        if(expiryDate > currentDate) return false;
        else if(expiryDate <= currentDate) return true;
      }
      return undefined;
    },
    checkIfToShowShipNowBtn(result) {
      if(typeof result != 'undefined'){
        if(typeof result.variables.Grab_Express != 'undefined'){
          if(result.variables.Grab_Express == true){
            return false;
          }
        }

        if((result.issent == false && (result.shippedlink == '' || result.shippedlink == 'None' || result.shippedlink == null)) && result.cancel == false && this.userRolesPermission.orders && this.userRolesPermission.orders.manage) {
          if(typeof result.variables != 'undefined') {
            let trackingLink_variable_found = false;
            for(var key in result.variables) {
              if(key == 'Tracking link') {
                trackingLink_variable_found = true;
                if(key == '' || key == null || key == 'None') return true;
              }
            }
            if(trackingLink_variable_found == false) return true;
          }
          else return true;
        }
      }
      return undefined;
    },
    openSingleOrderExpandedView(data){
      this.currentSingleOrderData = data;
      this.singleOrderExpandedView_opened = true;
    },
    dismissFilter() {
      this.filterModal = false;
    },
    async shipProduct() {
      try {
        this.loading = true;

        if(!this.productToShip.id) {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
          return;
        }
        if(!this.shipmentLink) {
          this.updateToast({
            open: true,
            message: "Please add relevant data!",
            type: "danger"
          })
          return;
        }
        if(!this.shipmentID) {
          this.updateToast({
            open: true,
            message: "Please add relevant data!",
            type: "danger"
          })
          return;
        }
        let reqBody = {
          transactionid: this.productToShip.id,
          shippedlink: this.shipmentLink,
          shippedid: this.shipmentID,
        }

        let shipProductResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "order-prepared", reqBody);

        if ((shipProductResponse.status == 200 || shipProductResponse.status == 201) && shipProductResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Product Shipped",
            type: "success"
          })
          this.productToShip.variables.shippedid = this.shipmentID;
          this.productToShip.shippedlink = this.shipmentLink;
        } else if((shipProductResponse.status == 200 || shipProductResponse.status == 201) && shipProductResponse.data.errorMessage && shipProductResponse.data.errorMessage.length){
          console.log("Response:", shipProductResponse);
          this.updateToast({
            open: true,
            message: shipProductResponse.data.errorMessage,
            type: "danger"
          })
        } else {
          console.log("Response:", shipProductResponse);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.productToShip = null;
        this.shipmentID = undefined;
        this.shipmentWay = undefined;
        this.shipmentLink = undefined;
      }
    },
    async deleteOrder() {
      if(this.orderToDelete) {
        try {
          this.loading = true;
          let reqBody = {
            transactionid: this.orderToDelete
          }
          let deleteOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "transaction-delete", reqBody);
          if ((deleteOrderResponse.status == 200 || deleteOrderResponse.status == 201) && deleteOrderResponse.data.success) {
            if(deleteOrderResponse.data.delete) this.updateToast({
              open: true,
              message: "Order deleted successfully",
              type: "success"
            })
            await this.initView();
          } else if((deleteOrderResponse.status == 200 || deleteOrderResponse.status == 201) && deleteOrderResponse.data.errorMessage && deleteOrderResponse.data.errorMessage.length){
            console.log("Response:", deleteOrderResponse);
            this.updateToast({
              open: true,
              message: deleteOrderResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            console.log("Response:", deleteOrderResponse);
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong.",
            type: "danger"
          })
        } finally {
          this.loading = false;
          this.orderToDelete = undefined;
        }
      }
    },
    async sendOrderConfirmEmail() {
      if(this.orderToMail) {
        if(this.emailId && this.emailId.length) {
          try {
            this.loading = true;
            let reqBody = {
              orderid: this.orderToMail,
              email: this.emailId
            }
            let sendEmailResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "resend-order-mail", reqBody);
            if ((sendEmailResponse.status == 200 || sendEmailResponse.status == 201) && sendEmailResponse.data.success) {
              this.updateToast({
                open: true,
                message: "Sent order confirmation email successfully!",
                type: "success"
              })
            } else if((sendEmailResponse.status == 200 || sendEmailResponse.status == 201) && sendEmailResponse.data.errorMessage && sendEmailResponse.data.errorMessage.length){
              console.log("Response:", sendEmailResponse);
              this.updateToast({
                open: true,
                message: sendEmailResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              console.log("Response:", sendEmailResponse);
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          } catch (error) {
            console.log("Exception", error);
            this.updateToast({
              open: true,
              message: "Oops! Something went wrong.",
              type: "danger"
            })
          } finally {
            this.loading = false;
            this.orderToMail = undefined;
            this.emailId = undefined;
          }
        } else {
          this.updateToast({
            open: true,
            message: "Please enter a valid email address",
            type: "danger"
          })
        }
      }
    },
    async addRemarks() {
      if(this.orderToAddRemarks) {
        try {
          this.loading = true;
          let reqBody = {
            orderid: this.orderToAddRemarks,
            merchant_notes: this.remarks
          }
          let addRemarksResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT') + "checkout/put-order-info", reqBody);
          if ((addRemarksResponse.status == 200 || addRemarksResponse.status == 201) && addRemarksResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Added remark successfully!",
              type: "success"
            })
            await this.initView();
          } else if((addRemarksResponse.status == 200 || addRemarksResponse.status == 201) && addRemarksResponse.data.errorMessage && addRemarksResponse.data.errorMessage.length){
            console.log("Response:", addRemarksResponse);
            this.updateToast({
              open: true,
              message: addRemarksResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            console.log("Response:", addRemarksResponse);
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong.",
            type: "danger"
          })
        } finally {
          this.loading = false;
          this.orderToAddRemarks = undefined;
          this.remarks = undefined;
        }
      }
    },
    async saveOptInNotification() {
      try {
        this.loading = true;
        let reqBody = {
          loginCredential: this.loginCredential
        }
        let saveOptInNotificationResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-user", reqBody);
        if ((saveOptInNotificationResponse.status == 200 || saveOptInNotificationResponse.status == 201) && saveOptInNotificationResponse.data.success) {
          this.loginCredential = saveOptInNotificationResponse.data.loginCredential;
          await this.Storage.remove({ key: 'jmpr_fetchAddUserData' });
          await this.Storage.set({
            key: "jmpr_fetchAddUserData",
            value: JSON.stringify(saveOptInNotificationResponse.data),
          });
          this.updateToast({
            open: true,
            message: "Setting saved successfully",
            type: "success"
          })
        } else if((saveOptInNotificationResponse.status == 200 || saveOptInNotificationResponse.status == 201) && saveOptInNotificationResponse.data.errorMessage && saveOptInNotificationResponse.data.errorMessage.length){
          console.log("Response:", saveOptInNotificationResponse);
          this.updateToast({
            open: true,
            message: saveOptInNotificationResponse.data.errorMessage,
            type: "danger"
          })
        } else {
          console.log("Response:", saveOptInNotificationResponse);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong.",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.optInNotificationsModal = false;
      }
    },
    renderCartVarInfo(input) {
      if(input == null || typeof input === undefined) return ''
      if(input.indexOf(",") > -1) return input.replace(/,/g, ', ')
      return input
    },
    printSingleOrderInvoice(singleOrderData){
      var print_html = '<div id="printOrderReceiptArea">\
          <div bgcolor="#ffffff" style="width: 100% !important; max-width: 600px; margin-left: 0px; margin-right: 0px; display: block; padding-top: 10px; padding-bottom: 10px;">\
              <table bgcolor="#ffffff" style="width: 100% !important; max-width: 600px; margin-left: auto; margin-right: 0px; display: block; padding-top: 10px; padding-bottom: 10px;">\
                  <tbody style="display: block; padding-left: 0px; padding-right: 0px; margin-left: auto; margin-right: 0px; text-align: right;">\
                      <tr style="display: block;" align="right">\
                          <td style="text-align: right; display: block; margin-left: auto; margin-right: 10px;">\
                              <img src="'+ this.getShopLogoImage +'" style="display:block; height: auto; max-width: 180px !important; width: auto !important; margin-left: auto; margin-right: 0px; text-align: right; max-height: 65px !important;" />\
                          </td>\
                      </tr>\
                  </tbody>\
              </table>\
              <table bgcolor="#ffffff" style="width: 100%; max-width: 600px; margin-left: 0px; display: block;">\
                  <tbody style="display: block; padding-top: 15px; padding-bottom: 15px; padding-left: 10px; padding-right: 10px;">\
                      <tr style="display: block;">\
                          <td  style="text-align: left; display: block;">\
                              <h3 style="color: #221e38; margin-top: 15px; margin-bottom: 5px; font-size: 16px; font-weight: bold; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important;">Order confirmation</h3>\
                          </td>\
                      </tr>\
                  </tbody>\
              </table>\
              <table bgcolor="#ffffff" style="width: 100%; max-width: 600px; display: block;">\
                  <tbody style="display: block; padding-top: 5px; padding-left: 10px; padding-right: 10px; padding-bottom: 10px;">\
                      <tr style="display: block;">\
                          <td style="display: block;">\
                              <p style="color: #221e38; margin-top: 5px; margin-bottom: 0px; font-size: 14px; font-weight: bold; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left;">Hello '+ singleOrderData.buyer.name +',</p>\
                              <p style="color: #221e38; margin-top: 15px; margin-bottom: 20px; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left;">Thank you for shopping with '+ this.user.shopName +'. Your order has been received and the shopping details are as below. We will be in touch with you shortly with details of shipping. If you have any questions regarding this order, please reply to this email.</p>\
                          </td>\
                      </tr>\
                  </tbody>\
              </table>\
              <table bgcolor="#ffffff" style="width: 100%; max-width: 600px;padding-bottom: 30px;border-collapse: collapse;">\
                  <tbody>\
                      <tr>\
                          <td style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;">\
                              Order ID\
                          </td>\
                          <td style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;">\
                              Order date\
                          </td>\
                      </tr>\
                      <tr>\
                          <td style="color: #777; font-size: 13px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;">\
                              '+ singleOrderData.ordernumber +'\
                          </td>\
                          <td style="color: #777; font-size: 13px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;">\
                              '+ this.moment(singleOrderData.created).format("DD-MMM-YYYY") +'\
                          </td>\
                      </tr>\
                      <tr style="height:30px;">\
                          <td bgcolor="#ffffff" colspan="3">&nbsp;</td>\
                      </tr>\
                      <tr style="padding-top: 30px;" ng-if="eventOrdersPage != true && singleOrderData.address.addressLine1">\
                          <td style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;" colspan="2">\
                              Shipping address\
                          </td>\
                      </tr>\
                      <tr ng-if="eventOrdersPage != true && singleOrderData.address.addressLine1">\
                          <td style="color: #777; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;" colspan="2">\
                              '+ singleOrderData.address.addressLine1 +', '+ singleOrderData.address.pincode +', '+ singleOrderData.address.city +', '+ singleOrderData.address.state +', '+ singleOrderData.address.country +'\
                          </td>\
                      </tr>\
                      <tr style="height:30px;">\
                          <td bgcolor="#ffffff" colspan="3">&nbsp;</td>\
                      </tr>\
                      <tr>\
                          <td style="color: #221e38; font-size: 14px; font-weight: bold; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; padding-left: 10px;">\
                              Here is what you ordered:\
                          </td>\
                      </tr>\
                  </tbody>\
              </table>\
              <table bgcolor="#ffffff" style="width: 100%; max-width: 600px; padding-bottom: 30px; border-collapse: collapse;">\
                  <tbody>\
                      <tr style="height:10px;">\
                          <td bgcolor="#ffffff" colspan="3">&nbsp;</td>\
                      </tr>\
                      <tr style="border-bottom: 1px solid #eee;border-collapse: collapse;">\
                          <td colspan="2" style="padding: 5px 10px; width: 70%; color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left;">\
                              Item\
                          </td>\
                          <td style="padding: 5px 15px; color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;">\
                              Quantity\
                          </td>\
                          <td style="padding: 5px 15px; color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;">\
                              Price\
                          </td>\
                      </tr>';
                      
                      if(singleOrderData.cart.length == 0){
                        print_html += '<tr style="border-bottom: 1px solid #eee; border-collapse: collapse;">\
                            <td style="padding: 10px 10px; font-size: 14px;">\
                                <img src="'+ singleOrderData.product.images +'" style="width: auto; height: auto; max-height: 50px; max-width: 80px;">\
                            </td>\
                            <td>\
                                <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; margin-bottom: 0px;">\
                                    '+ singleOrderData.product.name +'\
                                </p>\
                                <p style="color: #777; font-size: 12px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; margin-top: 5px;" ng-if="singleOrderData.variation != \'\'">\
                                    '+ singleOrderData.variation +'\
                                </p>\
                            </td>\
                            <td style="padding: 0 10px;">\
                                <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; padding: 5px 5px; text-align: right;">'+ singleOrderData.quantity +'</p>\
                            </td>\
                            <td style="padding: 0 10px;">\
                                <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; padding: 5px 5px; text-align: right;">'+ this.user.currency +' '+ singleOrderData.unitprice +'</p>\
                            </td>\
                        </tr>';
                      }
                      else{
                        for(var i_cart=0; i_cart < singleOrderData.cart.length; i_cart++){
                          var cart = singleOrderData.cart[i_cart];
                          print_html += '<tr style="border-bottom: 1px solid #eee; border-collapse: collapse;">\
                              <td style="padding: 10px 10px; font-size: 14px;">\
                                  <img src="'+ cart.image +'" style="width: auto; height: auto; max-height: 50px; max-width: 80px;">\
                              </td>\
                              <td>\
                                  <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; margin-bottom: 0px;">\
                                      '+ cart.name +'\
                                  </p>\
                                  <p style="color: #777; font-size: 12px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: left; margin-top: 5px;" ng-if="renderCartVarInfo(cart.subtitle) != \'\'">\
                                      '+ this.renderCartVarInfo(cart.subtitle) +'\
                                  </p>\
                              </td>\
                              <td style="padding: 0 10px;">\
                                  <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; padding: 5px 5px; text-align: right;">'+ cart.quantity +'</p>\
                              </td>\
                              <td style="padding: 0 10px;">\
                                  <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; padding: 5px 5px; text-align: right;">'+ this.user.currency +' '+ cart.cost +'</p>\
                              </td>\
                          </tr>';
                        }
                      }

                  print_html += '</tbody>\
              </table>\
              <table bgcolor="#ffffff" style="width: 100%; max-width: 600px; padding-bottom: 30px;border-collapse: collapse; margin-top: 20px; text-align: right; margin-left: auto; margin-right: 0px; display: block;">\
                  <tbody style="text-align: right; margin-left: auto; margin-right: 0px; display: block; width: 100%;">\
                      <tr style="text-align: right; margin-left: auto; margin-right: 0px; display: block; width: 100%;">\
                          <td style="text-align: right; padding: 0 15px; margin-left: auto; margin-right: 0px; display: block; width: 100%;">\
                              <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;">\
                                  Subtotal: '+ this.user.currency +' '+ singleOrderData.subtotal +'\
                              </p>\
                              <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;">\
                                  Discount: '+ this.user.currency +' '+ singleOrderData.discount +'\
                              </p>\
                              <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;" ng-if="singleOrderData[\'reward discount\'] && singleOrderData[\'reward discount\'] != \'\' && singleOrderData[\'reward discount\'] != \'None\' && singleOrderData[\'reward discount\'] != null && singleOrderData[\'reward discount\'] != \'0.00\' && singleOrderData[\'reward discount\'] != \'0\' && singleOrderData[\'reward discount\'] != 0.00 && singleOrderData[\'reward discount\'] != 0">\
                                  Reward discount: '+ this.user.currency +' '+ singleOrderData["reward discount"] +'\
                              </p>\
                              <span ng-if="singleOrderData[\'discount\'] != singleOrderData[\'total discount\']">\
                                  <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;" ng-if="singleOrderData[\'additional discount\'] && singleOrderData[\'additional discount\'] != \'\' && singleOrderData[\'additional discount\'] != \'None\' && singleOrderData[\'additional discount\'] != null && singleOrderData[\'additional discount\'] != \'0.00\' && singleOrderData[\'additional discount\'] != \'0\' && singleOrderData[\'additional discount\'] != 0.00 && singleOrderData[\'additional discount\'] != 0">\
                                      Additional discount: '+ this.user.currency +' '+ singleOrderData["additional discount"] +'"></span>\
                                  </p>\
                                  <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;" ng-if="singleOrderData[\'total discount\'] && singleOrderData[\'total discount\'] != \'\' && singleOrderData[\'total discount\'] != \'None\' && singleOrderData[\'total discount\'] != null && singleOrderData[\'total discount\'] != \'0.00\' && singleOrderData[\'total discount\'] != \'0\' && singleOrderData[\'total discount\'] != 0.00 && singleOrderData[\'total discount\'] != 0">\
                                      Total discount: '+ this.user.currency +' '+ singleOrderData["total discount"] +'\
                                  </p>\
                              </span>\
                              <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;" ng-if="eventOrdersPage != true">\
                                  Shipping: '+ this.user.currency +' '+ singleOrderData.shippingcost +'\
                              </p>\
                              <p style="color: #221e38; font-size: 14px; font-weight: normal; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right;">\
                                  Tax: '+ this.user.currency +' '+ singleOrderData.tax +'\
                              </p>\
                          </td>\
                      </tr>\
                      <tr style="height:10px;border-top: 1px solid #eee;border-collapse: collapse; text-align: right; display: block; width: 100%;">\
                          <td bgcolor="#ffffff">&nbsp;</td>\
                      </tr>\
                      <tr style="text-align: right; margin-left: auto; margin-right: 0px; display: block; width: 100%;">\
                          <td style="color: #221e38; font-size: 14px; font-weight: bold; font-family: Arial,Helvetica Neue,Helvetica,sans-serif !important; line-height: 1.4; text-align: right; padding: 0 15px; margin-left: auto; margin-right: 0px; display: block; width: 100%;">\
                              Total: '+ this.user.currency +' '+ singleOrderData.total +'\
                          </td>\
                      </tr>\
                  </tbody>\
              </table>\
          </div>\
      </div>';

      var mywindow = window.open('', 'Order');

      mywindow.document.write('<html><head><title>' + singleOrderData.ordernumber  + '</title>');
      mywindow.document.write('</head><body>');
      mywindow.document.write(print_html);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      //mywindow.close();

      return true;
    }
  }
}
</script>

<style scoped>

.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}

.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 19px;
}

@media only screen and (max-width: 760px){
.table-responsive {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 0px 20px 20px;
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}
}

.table-section {
  padding: 25px 20px 0px;
}
.table-title {
  font-size: 14px;
  font-family: Helvetica,Arial, sans-serif;
}

.orderDetails {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.title-header {
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  margin-left: 30px
}
.model-container {
  margin: 0px 20px 20px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 20px;
}

.order-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
  font-family: Helvetica,Arial, sans-serif;
}

.cust-name {
  font-size: 18px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color:  var(--ion-color-black);
  padding-right: 6px;
}

.orderSummarySku {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 5px;
}

.chat-toolbar {
  margin-top: 15px;
}

.imgContainer {
  --border-radius: 5px;
  margin-right: 16px;
}

.textWrap {
  white-space: normal;
}

.orderSubtitle {
   font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}

</style>