<template>
  <master-layout pageTitle="edit rich menu">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
      v-if="loading"
      :is-open="loading"
      cssClass="my-custom-class"
      message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="chat-toolbar">
          <ion-buttons slot="start">
            <ion-icon  @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer"></ion-icon>
            <h2 class="prdt-title ion-no-margin">{{ dummyMenu.id ? 'Edit ' : 'Add '}} Rich Menu</h2>
          </ion-buttons>
          <ion-buttons slot="end" class="paddingBox">
            <ion-button @click="updateMenu" color="white" class="bttn-bgnd">Save</ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar class="ion-hide-md-up toolbar">
          <ion-segment scrollable color="dark" v-model="activeTab">
            <ion-segment-button value="menu">
              <ion-label class="common-label">Rich menu</ion-label>
            </ion-segment-button>
            <ion-segment-button value="preview">
              <ion-label class="common-label">Preview</ion-label>
            </ion-segment-button>
          </ion-segment>
         </ion-toolbar>

      <ion-grid class="cust_mrg">
        <ion-row>
          <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12" class="ion-no-padding">
             <div v-if="activeTab === 'menu'">
              <div class="model-sections">
                <div class="prdct-section">
                <ion-card-title class="sctn-labels">Name</ion-card-title>
                <ion-input v-model="dummyMenu.components.name" type="text" placeholder="Enter Menu Name" class="fullBordered input_custom_style sctn-input"></ion-input>
                </div>
                <div class="prdct-section">
                  <ion-card-title class="sctn-labels">Menu bar label</ion-card-title>
                  <ion-input  v-model="dummyMenu.components.chatBarText" type="text" placeholder="Enter Menu Bar Label" class="fullBordered input_custom_style sctn-input"></ion-input>
                </div>
              </div>

              <div class="model-sections">
                <h2 class="title ion-no-margin">Design menu</h2>
                <ion-row class="rowMargin">
                  <ion-col size-xl="5" size-lg="5" size-md="5" size-sm="12" size-xs="12" class="ion-no-padding">
                    <ion-item lines="none">
                      <ion-label class="sctn-labels">No. of Menu Items</ion-label>
                      <ion-select v-model="dummyMenu.availableImages" placeholder="Menu Items" interface="popover" class="slctContainer">
                        <ion-select-option :value="1">1</ion-select-option>
                        <ion-select-option :value="2">2</ion-select-option>
                        <ion-select-option :value="3">3</ion-select-option>
                        <ion-select-option :value="4">4</ion-select-option>
                        <ion-select-option :value="6">6</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size-xl="7" size-lg="7" size-md="7" size-sm="12" size-xs="12" class="ion-no-padding">
                    <ion-item v-if="dummyMenu.availableImages" lines="none">
                      <p class="sctn-labels sub-label">Select Layout</p>
                      <div>
                        <ion-row v-if="dummyMenu.availableImages === 1">
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'c01' ? 'active' : ''" @click="changeLayout('c01')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/c01.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l01' ? 'active' : ''" @click="changeLayout('l01')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l01.png">
                          </span>
                        </ion-row>
                        <ion-row v-if="dummyMenu.availableImages === 2">
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'c02a' ? 'active' : ''" @click="changeLayout('c02a')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/c02a.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'c02b' ? 'active' : ''" @click="changeLayout('c02b')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/c02b.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'c02c' ? 'active' : ''" @click="changeLayout('c02c')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/c02c.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l02a' ? 'active' : ''" @click="changeLayout('l02a')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l02a.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l02b' ? 'active' : ''" @click="changeLayout('l02b')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l02b.png">
                          </span>
                        </ion-row>
                        <ion-row v-if="dummyMenu.availableImages === 3">
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'c03' ? 'active' : ''" @click="changeLayout('c03')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/c03.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l03' ? 'active' : ''" @click="changeLayout('l03')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l03.png">
                          </span>
                        </ion-row>
                        <ion-row v-if="dummyMenu.availableImages === 4">
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l04a' ? 'active' : ''" @click="changeLayout('l04a')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l04a.png">
                          </span>
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l04b' ? 'active' : ''" @click="changeLayout('l04b')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l04b.png">
                          </span>
                        </ion-row>
                        <ion-row v-if="dummyMenu.availableImages === 6">
                          <span class="rich--menu--icon-cont" :class="selectedLayout === 'l06' ? 'active' : ''" @click="changeLayout('l06')">
                            <img class="menu--icon-img" src="https://jumperdevnew.appspot.com/web-s/resources/images/line-rich-menu/templates/l06.png">
                          </span>
                        </ion-row>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <div v-if="selectedLayout" class="designMenu richMenuItemsImagesContainer" :style="{ background: finalImage ? 'url('+ finalImage +') no-repeat' : '#fff' }">
                  <div v-if="selectedLayout === 'l03'" id="preview" class="layout" :class="selectedLayout">
                    <div class="child LRM_singleImageBox" id="LRM_singleImageBox_0">
                      <input style="display: none;" id="uploadImage-0" type="file" accept="image/*" @input="uploadImage" />
                      <img v-if="previewImages[0].image" class="uploadedPreviewImage_placeholder" :class="selectedLayout.indexOf('l') > -1 ? 'large' : ''" :src="previewImages[0].image" @click="openPopper(0)" />
                      <div v-else @click="openPopper(0)" class="placeholder" :class="selectedLayout.indexOf('l') > -1 ? 'large' : ''">
                        <div>+</div>
                      </div>
                    </div>
                    <div class="child" style="height: 300px;">
                      <div class="LRM_singleImageBox" id="LRM_singleImageBox_1" style="height: 150px;">
                        <input style="display: none;" id="uploadImage-1" type="file" accept="image/*" @input="uploadImage" />
                        <img v-if="previewImages[1].image" class="uploadedPreviewImage_placeholder" :src="previewImages[1].image" @click="openPopper(1)" />
                        <div v-else @click="openPopper(1)" style="height: 100%;" class="placeholder">
                          <div>+</div>
                        </div>
                      </div>
                      <div class="LRM_singleImageBox" id="LRM_singleImageBox_2" style="height: 150px;">
                        <input style="display: none;" id="uploadImage-2" type="file" accept="image/*" @input="uploadImage" />
                        <img v-if="previewImages[2].image" class="uploadedPreviewImage_placeholder" :src="previewImages[2].image" @click="openPopper(2)" />
                        <div v-else @click="openPopper(2)" style="height: 100%;" class="placeholder">
                          <div>+</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else id="preview" class="layout" :class="selectedLayout">
                    <div v-for="(thisObj, index) in previewImages" :key="index" class="child LRM_singleImageBox" :id="'LRM_singleImageBox_' + index">
                      <input style="display: none;" :id="'uploadImage-' + index" type="file" accept="image/*" @input="uploadImage" />
                      <img v-if="thisObj.image" class="uploadedPreviewImage_placeholder" :class="selectedLayout.indexOf('l') > -1 ? 'large' : ''" :src="thisObj.image" @click="openPopper(index)" />
                      <div v-else @click="openPopper(index)" class="placeholder" :class="selectedLayout.indexOf('l') > -1 ? 'large' : ''">
                        <div>+</div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
             <div class="model-sections">
              <ion-row class="rowMargin">
                <ion-col size="6" class="ion-no-padding">            
                  <ion-item lines="none" class="prdct-section">
                    <ion-label position="stacked" for="openStartDateInput" @click="$refs.openStartDateInput.click()">Start Date</ion-label>
                    <ion-input class="fullBordered" @focus="$refs.openStartDateInput.click()" id="openStartDateInput" ref="openStartDateInput" v-model="dummyMenu.start_date"></ion-input>
                    <ion-button id="openStartDateInput" ref="openStartDateInput" fill="clear">
                       <ion-icon slot="end" :icon="calendar"></ion-icon>
                    </ion-button>
                    <ion-popover trigger="openStartDateInput">
                      <ion-datetime display-format="DD-MM-YYYY" :value="dummyMenu.start_date" ref="startDate" name="startDate" 
                      @ionChange="e => dummyMenu.start_date = moment(new Date(e.detail.value)).toISOString()" :show-default-title="true" :max="moment(new Date()).toISOString()" id="start-date" showDefaultButtons></ion-datetime>
                    </ion-popover>
                  </ion-item>
                </ion-col>
                <ion-col size="6" class="ion-no-padding">
                  <ion-item lines="none" class="prdct-section">
                    <ion-label position="stacked" for="endDate" @click="$refs.openEndDateInput.click()">End Date</ion-label>
                    <ion-input class="fullBordered" @focus="$refs.openEndDateInput.click()" id="endDate" ref="endDate" v-model="dummyMenu.end_date"></ion-input>
                    <ion-button id="openEndDateInput" ref="openEndDateInput" slot="end" fill="clear">
                       <ion-icon slot="end" :icon="calendar"></ion-icon>
                    </ion-button>
                    <ion-popover slot="end" side="top" trigger="openEndDateInput">
                      <ion-datetime display-format="DD-MM-YYYY" :value="dummyMenu.end_date" ref="endDate" name="endDate" 
                      @ionChange="e => dummyMenu.end_date = moment(new Date(e.detail.value)).toISOString()" :show-default-title="true" :max="moment(new Date()).toISOString()" id="end-date" showDefaultButtons></ion-datetime>
                    </ion-popover>
                  </ion-item>
                </ion-col>
              </ion-row>
             </div>
			    	</div>
          </ion-col>

          <!-- PREVIEW -->
          <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="ion-no-padding">
            <div :class="activeTab === 'preview' ? 'prevMobile' : 'prevDesktop' ">
              <img class="img-container" src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA4PiKke8LDA" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
			</div>
    </ion-content>
    <popper :note="activeNote" image v-if="editIndex > -1" type="detailed" :config="popperConfig" @close="() => editIndex = -1" @submit="popperSubmit" />
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, arrowBackOutline, calendar  } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { IonSelect, IonSelectOption, IonPopover} from "@ionic/vue";
import popper from '/src/components/popper';

// import popper from '/src/components/popper';

var imageObj = {
  image: undefined,
  action: undefined,
  payload: undefined,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  initialize: function(x, y, w, h, action, payload) {
    this.x = x; this.y = y; this.width = w; this.height = h;
    this.action = action; this.payload = payload;
  },
}
export default {
  components: { IonSelect, IonSelectOption, popper,IonPopover },
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      calendar,
      arrowBackOutline,
      channelName: undefined,
      allMenus: [],
      next: undefined,
      addEditMode: false,
      activeTab: 'menu',
      dummyMenu: {
        status: undefined,
        name: undefined,
        rich_menu_id: undefined,
        start_date: undefined,
        end_date: undefined,
        start_time: undefined,
        end_time: undefined,
        image: undefined,
        selected: false,
        id: undefined,
        components: {
          size: { width: undefined, height: undefined },
          selected: false,
          chatBarText: undefined,
          name: undefined,
          areas: []
        },
        availableImages: undefined
      },
      newMenu: {
        status: undefined,
        name: undefined,
        rich_menu_id: undefined,
        start_date: undefined,
        end_date: undefined,
        start_time: undefined,
        end_time: undefined,
        image: undefined,
        selected: false,
        id: undefined,
        components: {
          size: { width: undefined, height: undefined },
          selected: false,
          chatBarText: undefined,
          name: undefined,
          areas: []
        },
        availableImages: undefined
      },
      finalImage: undefined,
      previewImages: [],
      selectedLayout: undefined,
      finalCanvas: document.createElement("canvas"),
      editIndex: -1
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    QRHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"> <img src="https://qr-official.line.me/sid/M/' + this.channelName + '.png" alt="LINE Add friend" height="180" width="180" border="0"> </a>';
    },
    buttonHTML() {
      return '<a href="https://line.me/R/ti/p/@' + this.channelName + '"> <img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" alt="LINE Add friend" height="36" border="0"></a>';
    },
    popperConfig() {
      return this.editIndex > -1 && this.editIndex < this.previewImages.length ? {
        text: this.previewImages[this.editIndex].image,
        action: this.previewImages[this.editIndex].action,
        payload: this.previewImages[this.editIndex].payload,
      } : { text: undefined, action: undefined, payload: undefined }
    },
    activeNote() {
      return this.editIndex > -1 && this.editIndex < this.previewImages.length ? "Make sure your image is in  JPG, JPEG, or PNG file format, doesn't exceed 1 MB file size and the image size is<span>&nbsp;" + this.previewImages[this.editIndex].width + " x " + this.previewImages[this.editIndex].height + "&nbsp;</span><a target='_blank' href='https://lineforbusiness.com/richmenumaker/th/'>Design your background</a>" : undefined;
    }
  },

	mounted() {
    let id = this.$route.query.richMenuid;
    this.fetchMenu(id);
  },
  methods: {
    async doRefresh(event) {
       await this.init();
       event.target.complete();
    },
    async init() {
      // await this.fetchMenus();
      let id = this.$route.query.richMenuid;
      await this.fetchMenu(id);
    },
     async fetchMenus() {
      try {
        this.loading = true;
        const allMenusResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/fetch", { limit: 100 });
        if ((allMenusResponse.status == 200 || allMenusResponse.status == 201) && allMenusResponse.data.success) {
          this.allMenus = allMenusResponse.data.data;
          this.next = allMenusResponse.data.next;
        } else {
          if(allMenusResponse.data.errorMessage && allMenusResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: allMenusResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchMenu(id) {
      if(id) {
        try {
          this.loading = true;
          console.log(id);
          const thisMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/get", { id: id });
          if ((thisMenuResponse.status == 200 || thisMenuResponse.status == 201) && thisMenuResponse.data.success) {
            this.dummyMenu = thisMenuResponse.data.data;
            this.dummyMenu.start_date =  this.moment(this.moment(this.dummyMenu.start_date, "DD-MM-YYYY HH:mm:ss").toISOString()).format("DD-MM-YYYY HH:mm");
            this.dummyMenu.end_date =  this.moment(this.moment(this.dummyMenu.end_date, "DD-MM-YYYY HH:mm:ss").toISOString()).format("DD-MM-YYYY HH:mm");
            if(this.dummyMenu && this.dummyMenu.components) {
              if(this.dummyMenu.components.areas && this.dummyMenu.components.areas.length) this.dummyMenu.availableImages = this.dummyMenu.components.areas.length;
              if(this.dummyMenu.availableImages && this.dummyMenu.components.size && this.dummyMenu.components.size.width && this.dummyMenu.components.size.height) {
                let width = this.dummyMenu.components.size.width;
                let height = this.dummyMenu.components.size.height;
                let areas = this.dummyMenu.components.areas;

                this.finalCanvas.width = width;
                this.finalCanvas.height = height;
                this.previewImages = [];

                switch(this.dummyMenu.availableImages) {
                  case 1: if(width == 2500 && height == 843) this.selectedLayout = 'c01'; else if(width == 2500 && height == 1686) this.selectedLayout = 'l01';
                  break;
                  case 2: if(width == 2500 && height == 843) {
                    if(areas[0].bounds.width == 833 && areas[0].bounds.height == 843) this.selectedLayout = 'c02a';
                    else if(areas[0].bounds.width == 1667 && areas[0].bounds.height == 843) this.selectedLayout = 'c02b';
                    else if(areas[0].bounds.width == 1250 && areas[0].bounds.height == 843) this.selectedLayout = 'c02c';
                  } else if(width == 2500 && height == 1686) {
                    if(areas[0].bounds.width == 1250 && areas[0].bounds.height == 1686) this.selectedLayout = 'l02a';
                    else if(areas[0].bounds.width == 2500 && areas[0].bounds.height == 843) this.selectedLayout = 'l02b';
                  }
                  break;
                  case 3: if(width == 2500 && height == 843) this.selectedLayout = 'c03'; else if(width == 2500 && height == 1686) this.selectedLayout = 'l03';
                  break;
                  case 4: if(areas[0].bounds.width == 2500) this.selectedLayout = 'l04a'; else this.selectedLayout = 'l04b';
                  break;
                  case 6: this.selectedLayout = 'l06';
                  break;
                  default: console.log("Something is wrong internally");
                }
                areas.forEach(thisArea => {
                  let newImageObj = Object.create(imageObj);
                  let action = thisArea.action.type === 'uri' ? 'website' : undefined;
                  let payload = thisArea.action.type === 'uri' ? thisArea.action.uri : thisArea.action.data;
                  thisArea.action.type === 'postback' && !payload.includes('#') && payload !== '7@1k704um@9' ? payload = 'bot' + payload : '';
                  newImageObj.initialize(thisArea.bounds.x, thisArea.bounds.y, thisArea.bounds.width, thisArea.bounds.height, action, payload);
                  this.previewImages.push(newImageObj);
                })
              }
              if(this.dummyMenu.image && this.dummyMenu.image.length) this.finalImage = this.dummyMenu.image;
            }
          } else {
            if(thisMenuResponse.data.errorMessage && thisMenuResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: thisMenuResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        } finally {
          this.loading = false;
          this.addEditMode = true;
        }
      }
    },
    popperSubmit(data){
      try {
        if(data.action) this.previewImages[this.editIndex].action = data.action;
        if(data.payload) this.previewImages[this.editIndex].payload = data.payload;
        if(typeof data.text === 'object') {
          let file = data.text;
          var reader = new FileReader();
          reader.onload = readerEvent => {
            var image = new Image();
            image.onload = () => {
              var canvas = document.createElement('canvas');
              canvas.width = this.previewImages[this.editIndex].width;
              canvas.height = this.previewImages[this.editIndex].height;
              canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
              this.finalCanvas.getContext('2d').drawImage(image, this.previewImages[this.editIndex].x, this.previewImages[this.editIndex].y, canvas.width, canvas.height);
              var dataUrl = canvas.toDataURL('image/jpeg');
              this.previewImages[this.editIndex].image = dataUrl;
            }
            image.src = readerEvent.target.result;
          }
          reader.readAsDataURL(file);
        } else {
          var image = new Image();
          image.onload = () => {
            var canvas = document.createElement('canvas');
            canvas.width = this.previewImages[this.editIndex].width;
            canvas.height = this.previewImages[this.editIndex].height;
            canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
            this.finalCanvas.getContext('2d').drawImage(image, this.previewImages[this.editIndex].x, this.previewImages[this.editIndex].y, canvas.width, canvas.height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            this.previewImages[this.editIndex].image = dataUrl;
          }
          image.crossOrigin = "anonymous";
          image.src = data.text;
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    openPopper(index, reset) {
      if(index > -1 && index < this.previewImages.length) {
        try {
          this.editIndex = index;
          if(reset) {
            this.previewImages[this.editIndex].action = undefined;
            this.previewImages[this.editIndex].payload = undefined;
          }
        } catch (error) {
          console.log("Exception: ", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      }
    },
    changeLayout(layout) {
      this.selectedLayout = layout;
      this.previewImages = [];
      this.finalCanvas.width = undefined;
      this.finalCanvas.height = undefined;
      var newImageObj = Object.create(imageObj);
      switch(layout) {
        case 'c01': {
          newImageObj.initialize(0, 0, 2500, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 843;
        } break;
        case 'l01': {
          newImageObj.initialize(0, 0, 2500, 1686);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'c02a': {
          newImageObj.initialize(0, 0, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(833, 0, 1667, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 843;
        } break;
        case 'c02b': {
          newImageObj.initialize(0, 0, 1667, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1667, 0, 833, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 843;
        } break;
        case 'c02c': {
          newImageObj.initialize(0, 0, 1250, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1250, 0, 1250, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 843;
        } break;
        case 'l02a': {
          newImageObj.initialize(0, 0, 1250, 1686);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1250, 0, 1250, 1686);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'l02b': {
          newImageObj.initialize(0, 0, 2500, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(0, 843, 1250, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'c03': {
          newImageObj.initialize(0, 0, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(833, 0, 834, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1667, 0, 833, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 843;
        } break;
        case 'l03': {
          newImageObj.initialize(0, 0, 1666, 1686);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1666, 0, 834, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1666, 843, 833, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'l04a': {
          newImageObj.initialize(0, 0, 2500, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(0, 843, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(833, 843, 834, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1667, 843, 833, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'l04b': {
          newImageObj.initialize(0, 0, 1250, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1250, 0, 1250, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(0, 843, 1250, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1250, 843, 1250, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
        case 'l06': {
          newImageObj.initialize(0, 0, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(833, 0, 834, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1667, 0, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(0, 843, 833, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(833, 843, 834, 843);
          this.previewImages.push(newImageObj);
          newImageObj = Object.create(imageObj);
          newImageObj.initialize(1667, 843, 833, 843);
          this.previewImages.push(newImageObj);
          this.finalCanvas.width = 2500;
          this.finalCanvas.height = 1686;
        } break;
      }
    },
    async uploadFinalImage(id) {
      if(id) {
        try {
          let dataURL = this.finalCanvas.toDataURL();
          let blobBin = atob(dataURL.split(',')[1]);
          var array = [];
          for(var i = 0; i < blobBin.length; i++) array.push(blobBin.charCodeAt(i));
          let file0 = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});

          let fd = new FormData();
          fd.append('image', file0);
          fd.append('id', id);

          const uploadImageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/upload-image", fd);
          if ((uploadImageResponse.status == 200 || uploadImageResponse.status == 201) && uploadImageResponse.data.success) {
            this.updateToast({
              open: true,
              message: "Rich Menu Photo uploaded successfully",
              type: "success"
            })
          } else {
            if(uploadImageResponse.data.errorMessage && uploadImageResponse.data.errorMessage.length) {
              this.updateToast({
                open: true,
                message: uploadImageResponse.data.errorMessage,
                type: "danger"
              })
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger"
              })
            }
          }
        } catch (error) {
          console.log("Exception", error);
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      }
    },
    async updateMenu() {
      try {
        this.loading = true;
        var areas = [];
        this.previewImages.forEach(thisImage => {
          let singleObj = {
            bounds: {
              x: thisImage.x,
              y: thisImage.y,
              width: thisImage.width,
              height: thisImage.height
            },
            action: {}
          };
          if(thisImage.action === "website") {
            singleObj.action['type'] = "uri";
            singleObj.action['uri'] = thisImage.payload;
          } else {
            singleObj.action['type'] = "postback";
            if(thisImage.action === 'productFlow' || thisImage.action === 'collectionFlow') thisImage.payload = '#' + thisImage.payload;
            singleObj.action['data'] = thisImage.payload;
            singleObj.action['displayText'] = thisImage.payload;
            singleObj.action['label'] = thisImage.payload;
            if(thisImage.payload == '7@1k704um@9'){
              singleObj.action['displayText'] = "Talk to agent";
              singleObj.action['label'] = "Talk to agent";
            }
          }
          areas.push(singleObj);
        })
        let components = {
          size: {
            width: this.finalCanvas.width,
            height: this.finalCanvas.height
          },
          selected: false,
          name: this.dummyMenu.components.name,
          chatBarText: this.dummyMenu.components.chatBarText,
          areas: areas
        }
        if(!components.name || components.name == '') components.name = "Menu "+ Math.floor((Math.random() * 1000) + 1);
        if(components.chatBarText == '') components.chatBarText = "Menu";

        this.finalImage = this.finalCanvas.toDataURL('image/jpeg');

        let reqBody = {
          components: JSON.stringify(components),
          status: "scheduled",
          start_date: this.moment(this.dummyMenu.start_date).format("DD-MM-YYYY HH:mm"),
          end_date: this.moment(this.dummyMenu.end_date).format("DD-MM-YYYY HH:mm"),
        }

        this.dummyMenu.id ? reqBody["id"] = this.dummyMenu.id : '';

        const saveMenuResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "line-rich-menu/create", reqBody);
        if ((saveMenuResponse.status == 200 || saveMenuResponse.status == 201) && saveMenuResponse.data.success) {
          for(var prop in this.dummyMenu) this.dummyMenu[prop] = saveMenuResponse.data.data[prop];
          await this.uploadFinalImage(saveMenuResponse.data.data.id);
          await this.fetchMenus();
          this.updateToast({
            open: true,
            message: "Rich Menu " + (reqBody.id ? 'updated' : 'added') + " successfully",
            type: "success"
          })
        } else {
          if(saveMenuResponse.data.errorMessage && saveMenuResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveMenuResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.dummyMenu = JSON.parse(JSON.stringify(this.newMenu));
        this.addEditMode = false;
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.rich--menu--icon-cont{
	margin-left: 10px;
	cursor: pointer;
}

.menu--icon-img{
	width: 45px;
	border: 1px #ddd solid;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.menu--icon-img:hover,
.menu--icon-img:active,
.menu--icon-img:focus{
	border: 1px #ccc solid;
}

.rich--menu--icon-cont.active .menu--icon-img {
	border: 1px var(--primary-theme-color, #3d39b9) solid;
}

.layout.largeImage{
	width: 2500px;
	height: 1686px
}

.layout.compactImage{
	width: 1667px;
	height: 843px
}

.layout{
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
	margin: 20px 0
}
.child{
	position: relative
}

.layout.c01>.child{
	width: 100%;
}

.layout.l01>.child{
	width: 100%;
}

.layout.c02a>.child:first-child{
	width: 34%;
}

.layout.c02a>.child:last-child{
	width: 66%;
}

.layout.c02b>.child:last-child{
	width: 34%;
}

.layout.c02b>.child:first-child{
	width: 66%;
}

.layout.c02c>.child{
	width: 50%;
}

.layout.l02a>.child{
	width: 50%;
}

.layout.l02b>.child{
	width: 100%;
}

.layout.c03>.child{
	width: 33%;
}

.layout.c03>.child:nth-child(2){
	width: 34%;
}

.layout.l03>.child{
	width: 50%;
}

.layout.l04a>.child:first-child{
	width: 100%;
}

.layout.l04a>.child:nth-child(3){
	width: 34%;
}

.layout.l04a>.child{
	width: 33%;
}

.layout.l04b>.child{
	width: 50%;
}

.layout.l06>.child{
	width: 33%;
}

.layout.l06>.child:nth-child(2),
.layout.l06>.child:nth-child(5){
	width: 34%!important;
}

.layout.large04>.child{
	width: 50%;
}

.layout.compact01>.child{
	width: 33%;
}
.placeholder{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 100%;
	height: 200px;
	border: 2px dotted #dcdcdc;
	color: #dcdcdc;
	font-size: 2rem;
}
.placeholder.large{
	height: 300px;
}

.LRM_singleImageBox .uploadedPreviewImage_placeholder{
	height: 200px;
	max-height: 100%;
  width: 100%;
}

.LRM_singleImageBox .uploadedPreviewImage_placeholder.large{
	height: 300px;
}

.LRM_singleImageBox{
	cursor: pointer;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
}

.LRM_singleImageBox:hover{
	background-color: rgba(0, 0, 0, .03);
	opacity: 0.7;
}

.child>.placeholder,
.child>img{
	width: 100%;
}
.img-container {
  margin-right: auto;
  display: block;
  margin-left: auto;
}
.richMenuItemsImagesContainer {
	background-position: center center !important;
	-webkit-background-size: 100% 100% !important;
	background-size: 100% 100% !important;
}
.cust_mrg {
  margin: 20px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
}
.title {
  font-size: 20px;
  color: var(--ion-color-light-contrast);
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 10px;
}

.slctContainer {
  width: 100%;
  border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-radius: 5px;
}

.sub-label {
  width: 160px;
}

.prevMobile {
  display: none;
}
.prevDesktop {
  display: block;
}

.dateRow {
  padding-left: 15px;
}


@media only screen and (max-width: 760px) {
  .sub-label {
   max-width: 100%;
   width: 160px;
   margin-top: 30px;
  }
   .prevMobile {
  display: block;
 }
  .prevDesktop {
    display: none;
  }
}

</style>