<template>
<master-layout pageTitle="Orders">
  <ion-content>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing...">
      </ion-refresher-content>
    </ion-refresher>
    <ion-loading
    v-if="loading"
    :is-open="loading"
    cssClass="my-custom-class"
    message="Loading..."
    ></ion-loading>
    <ion-toolbar class="chat-toolbar" color="light">
      <ion-item>
        <ion-label>Select Location</ion-label>
        <ion-select interface="popover" v-model="locationToExplore" placeholder="Select location">
          <ion-select-option value="All">All Locations</ion-select-option>
          <ion-select-option v-for="thisLocation in allLocations" :key="thisLocation.id" :value="thisLocation.urlid">{{ thisLocation.name }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label for="startDate" @click="$refs.openStartDateInput.click()">Start Date</ion-label>
        <ion-input @focus="() => $refs.openStartDateInput.click()" v-model="startDate" id="startDate"></ion-input>
        <ion-button id="openStartDateInput" ref="openStartDateInput" fill="clear">
          <ion-icon slot="start" :icon="calendar"></ion-icon>
        </ion-button>
        <ion-popover slot="end" side="top" trigger="openStartDateInput">
          <ion-datetime display-format="DD/MM/YY" :value="startDate" ref="startDate" name="startDate" @ionChange="e => startDate = moment(new Date(e.detail.value)).toISOString()" presentation="date" id="start-date"></ion-datetime>
        </ion-popover>

        <ion-label for="endDate" @click="$refs.openEndDateInput.click()">End Date</ion-label>
        <ion-input @focus="$refs.openEndDateInput.click()" v-model="endDate" id="endDate"></ion-input>
        <ion-button id="openEndDateInput" ref="openEndDateInput" slot="end" fill="clear">
          <ion-icon slot="end" :icon="calendar"></ion-icon>
        </ion-button>
        <ion-popover slot="end" side="top" trigger="openEndDateInput">
          <ion-datetime display-format="DD/MM/YY" :value="endDate" ref="endDate" name="endDate" @ionChange="e => endDate = moment(new Date(e.detail.value)).toISOString()" presentation="date" id="endDate"></ion-datetime>
        </ion-popover>
        
        <ion-buttons slot="end">
          <ion-button @click="filterOrders">
            <ion-icon slot="icon-only" :icon="refresh"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-toolbar>
    <ion-toolbar style="position: sticky; top: 96px;" color="light">
      <ion-segment color="dark" v-model="activeOrdersTab" scrollable>
        <ion-segment-button value="new">
          <ion-label>
            New orders
            <ion-badge color="primary" style="vertical-align: top;">{{ allNewTransactions.length }}</ion-badge>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="preparing">
          <ion-label>
            Being prepared
            <ion-badge color="warning" style="vertical-align: top;">{{ allPreparingTransactions.length }}</ion-badge>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="ready">
          <ion-label>
            Order ready
            <ion-badge color="success" style="vertical-align: top;">{{ allReadyTransactions.length }}</ion-badge>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="completed">
          <ion-label>
            Completed orders
            <ion-badge color="medium" style="vertical-align: top;">{{ allCompletedTransactions.length }}</ion-badge>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
    <ion-list>
      <ion-item lines="full" v-for="transaction in allSegmentTransactions" :key="transaction.id">
        <restaurant-order-card @click="() => expandOrder = transaction" :order="transaction"></restaurant-order-card>
        <div v-if="activeOrdersTab === 'new'">
          <span class="actionButtonContainer">
            <button @click="processOrder(transaction.id)">
              <span v-if="user.enterprise_Name === 'walls_my'">Start packing</span>
              <span v-else>Start preparing</span>
            </button>
          </span>

          <div class="newOrderTagImg" v-if="transaction.newlyAdded">
            <img src="/web-s/resources/images/new-tag.svg" />
          </div>
        </div>

        <span v-if="activeOrdersTab === 'preparing'" class="actionButtonContainer">
          <button @click="orderReady(transaction.id)">Order ready</button>
        </span>

        <span v-if="activeOrdersTab === 'ready' && !transaction.orderready" class="actionButtonContainer">
          <button @click="markAsPickedUp(transaction.id, transaction.gateway)">
            <span v-if="!checkIfPaymentModeIsManualOrNotForSingleOrder(transaction.gateway)">Mark as complete</span>
            <span v-else>Mark as paid & complete</span>
          </button>
        </span>
      </ion-item>
    </ion-list>
    <ion-modal
      v-if="expandOrder"
      :is-open="expandOrder"
      @didDismiss="() => expandOrder = null"
    >
      <ion-page>
        <ion-content :fullscreen="true">
          <ion-toolbar class="chat-toolbar" color="light">
            <h2 class="ion-no-margin">Order Details</h2>

            <ion-buttons slot="end">
              <ion-button @click="() => expandOrder = null">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <restaurant-order-detail-card v-if="expandOrder" :order="expandOrder"></restaurant-order-detail-card>
        </ion-content>
      </ion-page>
    </ion-modal>
  </ion-content>
</master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import {
  refresh,
  chevronDownCircleOutline,
  searchOutline,
  close,
  calendar
} from 'ionicons/icons';
import { IonSelect, IonSelectOption, IonPopover } from "@ionic/vue";
import restaurantOrderCard from '/src/components/restaurantOrderCard';
import restaurantOrderDetailCard from '/src/components/restaurantOrderDetailCard';

export default {
  data() {
    return {
      refresh,
      chevronDownCircleOutline,
      searchOutline,
      close,
      calendar,
      loading: true,
      allLocations: [],
      startDate: this.moment().subtract(3, 'months').startOf('day').toISOString(),
      endDate: this.moment().endOf('day').toISOString(),
      allTransactions: [],
      searchOrderId: undefined,
      activeOrdersTab: 'new',
      locationToExplore: 'All',
      expandOrder: null
    }
  },
  components: {
    restaurantOrderCard,
    restaurantOrderDetailCard,
    IonSelect,
    IonSelectOption,
    IonPopover
  },
  ionViewWillEnter(){
    this.initView();
  },
  watch: {
    locationToExplore(nVal) {
      if(nVal !== 'All') {
        let reqBody = {
          seller: "true",
          fnb: "true",
          version: "true",
          startdate: this.moment(this.startDate).format("DD/MM/YYYY"),
          enddate: this.moment(this.endDate).format("DD/MM/YYYY"),
          locations: nVal
        }
        this.fetchOrders(reqBody);
      } else {
        let reqBody = {
          seller: "true",
          fnb: "true",
          version: "true",
          startdate: this.moment(this.startDate).format("DD/MM/YYYY"),
          enddate: this.moment(this.endDate).format("DD/MM/YYYY")
        }
        this.fetchOrders(reqBody);
      }
    }
  },
  computed: {
    allNewTransactions() {
      return this.allTransactions.filter(elem => !elem.issent && !elem.released && !elem.cancel && elem.status !== 'refunded')
    },
    allPreparingTransactions() {
      return this.allTransactions.filter(elem => !elem.released && elem.issent && !elem.cancel && elem.status != 'refunded')
    },
    allReadyTransactions() {
      return this.allTransactions.filter(elem => elem.released && !elem.orderready && !elem.cancel && elem.status !== 'refunded')
    },
    allCompletedTransactions() {
      return this.allTransactions.filter(elem => elem.released && elem.orderready && !elem.cancel && elem.status !== 'refunded')
    },
    allSegmentTransactions() {
      switch(this.activeOrdersTab) {
        case 'new': return this.allNewTransactions;
        case 'preparing': return this.allPreparingTransactions;
        case 'ready': return this.allReadyTransactions;
        case 'completed': return this.allCompletedTransactions;
        default: return []
      }
    }
  },
  methods: {
    async initView() {
      try {
        let locationsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "fetch-all-locations");
        if((locationsResponse.status === 200 || locationsResponse.status === 201) && locationsResponse.data) {
          if(locationsResponse.data.success) {
            this.allLocations = locationsResponse.data.locations;
          } else {
            this.updateToast({
              open: true,
              message: locationsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
        let reqBody = {
          seller: "true",
          fnb: "true",
          version: "true",
          startdate: this.moment(this.startDate).format("DD/MM/YYYY"),
          enddate: this.moment(this.endDate).format("DD/MM/YYYY")
        }
        this.fetchOrders(reqBody);
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async fetchOrders(reqBody) {
      try {
        this.loading = true;
        let transactionsResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "transaction", reqBody);
        if((transactionsResponse.status === 200 || transactionsResponse.status === 201) && transactionsResponse.data) {
          if(transactionsResponse.data.success) {
            this.allTransactions = transactionsResponse.data.transactions;
          } else {
            this.updateToast({
              open: true,
              message: transactionsResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          this.updateToast({
            open: true,
            message: "Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Oops! Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async searchOrder() {
      let reqBody = {
        ordernumber: this.searchOrderId,
        seller: "true",
        size: 10
      }
      this.fetchOrders(reqBody);
    },
    async filterOrders() {
      console.log(this.moment(this.startDate).format("DD/MM/YYYY"));
      console.log(this.moment(this.endDate).format("DD/MM/YYYY"));
      let reqBody = {
        seller: "true",
        fnb: "true",
        version: "true",
        startdate: this.moment(this.startDate).format("DD/MM/YYYY"),
        enddate: this.moment(this.endDate).format("DD/MM/YYYY"),
      }
      this.fetchOrders(reqBody);
    },
    async doRefresh(event) {
      this.initView();
      event.target.complete();
    },
    async processOrder(id) {
      try {
        let reqBody = {
          transactionid: id,
          issent: 'true'
        }
        let processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "order-prepared", reqBody);

        if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
          console.log(processOrderResponse.data);
          if(processOrderResponse.data.success) {
            let thisTransactionIndex = this.allTransactions.findIndex(e => e.id === id);
            this.allTransactions[thisTransactionIndex].issent = true;
          } else {
            this.updateToast({
              open: true,
              message: processOrderResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", processOrderResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async orderReady(id) {
      try {
        let reqBody = {
          transactionid: id,
          released: 'true'
        }
        let processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "order-prepared", reqBody);

        if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
          console.log(processOrderResponse.data);
          if(processOrderResponse.data.success) {
            let thisTransactionIndex = this.allTransactions.findIndex(e => e.id === id);
            this.allTransactions[thisTransactionIndex].released = true;
          } else {
            this.updateToast({
              open: true,
              message: processOrderResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", processOrderResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
    async markAsPickedUp(id, gateway) {
      try {
        let reqBody = {
          transactionid: id
        }
        let processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "ready-order", reqBody);

        if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
          console.log(processOrderResponse.data);
          if(processOrderResponse.data.success) {
            if(this.checkIfPaymentModeIsManualOrNotForSingleOrder(gateway)) {
              processOrderResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "ready-order", reqBody);

              if ((processOrderResponse.status == 200 || processOrderResponse.status == 201) && processOrderResponse.data) {
                console.log(processOrderResponse.data);
                if(processOrderResponse.data.success) {
                  let thisTransactionIndex = this.allTransactions.findIndex(e => e.id === id);
                  this.allTransactions[thisTransactionIndex].orderready = true;
                  this.allTransactions[thisTransactionIndex].payment = true;
                } else {
                  this.updateToast({
                    open: true,
                    message: processOrderResponse.data.errorMessage,
                    type: "danger"
                  })
                }
              } else {
                console.log("Response:", processOrderResponse);
                this.updateToast({
                  open: true,
                  message: "Oops! Something went wrong",
                  type: "danger"
                })
              }
            } else {
              let thisTransactionIndex = this.allTransactions.findIndex(e => e.id === id);
              this.allTransactions[thisTransactionIndex].orderready = true;
              this.allTransactions[thisTransactionIndex].payment = true;
            }
          } else {
            this.updateToast({
              open: true,
              message: processOrderResponse.data.errorMessage,
              type: "danger"
            })
          }
        } else {
          console.log("Response:", processOrderResponse);
          this.updateToast({
            open: true,
            message: "Oops! Something went wrong",
            type: "danger"
          })
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      }
    },
  }
}
</script>