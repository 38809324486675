<template>
  <master-layout pageTitle="Store">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)" >
        <ion-refresher-content :pulling-icon="chevronDownCircleOutline" pulling-text="Pull to refresh" refreshing-spinner="circles" refreshing-text="Refreshing..." > </ion-refresher-content>
      </ion-refresher>
      <ion-loading v-if="loading || imageUploadLoading" :is-open="loading || imageUploadLoading" cssClass="my-custom-class" message="Loading..." ></ion-loading>
      <div v-if="!loading">
        <ion-toolbar class="chat-toolbar">
          <ion-buttons slot="start">
            <ion-icon @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline" style="cursor: pointer" ></ion-icon>
            <h2 class="prdt-title ion-no-margin">{{ (dummyCollection.id ? 'Edit' : 'Add') + ' Collection' }}</h2>
          </ion-buttons>
          <ion-buttons slot="end" class="paddingBox" >
            <ion-button @click="updateProduct" class="bttn-bgnd" color="white" >Save Collection</ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-grid>
          <ion-row>
            <!-- left panel -->
            <ion-col size-xl="4" size-lg="5" size-md="4" size-sm="12" size-xs="12" >
              <ion-accordion-group :multiple="true" :value="['collection', 'collectionDetails']" class="set-collections" >
                <ion-accordion class="model-sections" value="collection" >
                  <ion-item slot="header" lines="none" >
                    <ion-text class="order-title"> Collections </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding" >
                    <ion-card-header>
                      <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Web store banner image</ion-card-title>
                        <div v-if="!dummyCollection.images || dummyCollection.images.length === 0" class="custom_ThumbnailBox custom_mrg_bottom" >
                          <img src="https://jumper.ai/web-s/resources/images/upload-image-placeholder.png" alt="" class="img-container" >
                        </div>
                        <div v-else class="custom_mrg_bottom" >
                          <img v-for="(thisImage, iIndex) in dummyCollection.images" :key="iIndex" :src="thisImage" alt="" class="img-container" />
                        </div>
                        <div>
                          <input type="file" style="display: none;" id="productImage" ref="productImage" @change="handleImageUpload" accept="image/*" />
                          <ion-button @click="() => $refs.productImage.click()" expand="block" fill="outline" >Upload image</ion-button>
                        </div>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
                <ion-accordion class="model-sections" value="collectionDetails" >
                  <ion-item slot="header" lines="none" >
                    <ion-text class="order-title">
                      Collection Details
                    </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding" >
                    <ion-grid class="card_container_grid ion-no-padding">
                      <ion-row class="ion-no-padding" style="margin-bottom: 5px" >
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label">
                            Visible on store
                          </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right">
                          <ion-checkbox v-model="dummyCollection.shopActive" class="cust_toggle" ></ion-checkbox>
                        </ion-col>
                      </ion-row>
                      <!-- TODO which variable should this be binded with -->
                      <ion-row row class="ion-no-padding" style="margin-bottom: 5px" >
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label">
                            Enable click & collect
                          </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right">
                          <ion-checkbox class="cust_toggle"></ion-checkbox>
                        </ion-col>
                      </ion-row>
                      <ion-row row class="ion-no-padding" style="margin-bottom: 5px" >
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label"> Ask customer for their address </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right">
                          <ion-checkbox v-model="dummyCollection.skipaddress" class="cust_toggle" ></ion-checkbox>
                        </ion-col>
                      </ion-row>
                      <ion-row row class="ion-no-padding" style="margin-bottom: 5px" >
                        <ion-col style="padding-top: 20px">
                          <ion-text class="common-label"> Ask customer for their email </ion-text>
                        </ion-col>
                        <ion-col class="ion-no-padding text-right">
                          <ion-checkbox v-model="dummyCollection.skipemail" class="cust_toggle" ></ion-checkbox>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>
            <!-- right panel -->
            <ion-col size-xl="8" size-lg="7" size-md="8" size-sm="12" size-xs="12" >
              <ion-accordion-group class="set-collections" :multiple="true" :value="['products', 'others']">
                <ion-accordion class="model-sections" value="others" >
                  <ion-item slot="header" lines="none" >
                    <ion-text class="order-title"> Other settings </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding" >
                    <ion-card-header>
                      <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Collection name</ion-card-title>
                        <ion-input v-model="dummyCollection.name" type="text" placeholder="Enter product name" class="fullBordered input_custom_style sctn-input" ></ion-input>
                      </div>
                      <div v-if="dummyCollection.id === undefined" class="prdct-section" >
                        <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                        <ion-input v-model="dummyCollection.hashtag" @blur="checkHashtag" type="text" placeholder="Enter your collection's checkout trigger" class="fullBordered input_custom_style" style="border: 1px solid #666666" ></ion-input>
                      </div>
                      <ion-grid v-else class="ion-no-padding" >
                        <ion-row class="ion-no-padding">
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" >
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                              <div class="pre_post_text_cont">
                                <span class="pre_text"> &#35; </span>
                                <ion-input disabled v-model="dummyCollection.hashtag" type="text" placeholder="Enter your collection's checkout trigger" class="fullBordered input_custom_style" style="border: 1px solid #666666" ></ion-input>
                                <span class="post_text"> &#33; </span>
                              </div>
                            </div>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12" class="paddingLeft" >
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">Collection URL</ion-card-title>
                              <a :href="shopLinkConsideringCustomDomain + '/' + dummyCollection.hashtag" class="hyperlink_cust v_1" > {{ shopLinkConsideringCustomDomain + '/' + dummyCollection.hashtag }} </a>
                            </div>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                      <!-- <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Collection external URL</ion-card-title>
                        <ion-input type="text" v-model="dummyCollection.online_event_url" placeholder="Enter / Paste URL" class="fullBordered input_custom_style sctn-input" ></ion-input>
                      </div> -->
                      <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Short Description</ion-card-title>
                        <ion-textarea v-model="dummyCollection.description" rows="4" placeholder="Enter short description" class="fullBordered input_custom_style sctn-input" ></ion-textarea>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
                <ion-accordion class="model-sections" value="products" >
                  <ion-item slot="header" lines="none" >
                    <ion-text class="order-title"> Products </ion-text>
                  </ion-item>
                  <ion-list slot="content" class="ion-no-padding" >
                    <ion-card-header class="ion-no-padding">
                      <div class="has-category-question-row">
                        <div>
                          <ion-text class="sctn-labels"> Does your collection have categories? </ion-text>
                        </div>
                        <div>
                          <ion-toggle v-model="hasCategories" ></ion-toggle>
                        </div>
                      </div>
                      <div v-if="hasCategories">
                        <div class="category-section-header">
                          <span class="sub-Hdng"> Categories &#124; <a @click="() => reorderCategories = true" class="hyperlink_cust" > Reorder categories</a> </span>
                        </div>
                        <div v-if="!dummyCollection.variations.variations || dummyCollection.variations.variations.length === 0" class="text-center" >
                          <h2 style="margin-bottom: 20px" class="sctn-labels ion-no-margin" > No categories found! </h2>
                        </div>
                        <ion-grid v-else class="ion-no-padding" >
                          <ion-col v-for="(thisCategory, cIndex) in dummyCollection.variations.variations" :key="thisCategory.id" class="ion-no-padding rowMargin" >
                            <ion-row class="rowMargin">
                              <ion-col size="10" class="ion-align-items-start" >
                                <div class="category-row-header">
                                  <Image :image="thisCategory.image" height="40px" width="40px"></Image>
                                  <div>
                                    <h2 class="catgory-title ion-no-padding ion-no-margin">{{ thisCategory.name }}</h2>
                                    <h2 v-if="thisCategory.variations[0] && thisCategory.variations[0].variations" class="common-label ion-no-padding ion-no-margin">{{ thisCategory.variations[0].variations.length }} Product(s) added</h2>
                                    <h2 v-else class="common-label ion-no-padding ion-no-margin">No Product added</h2>
                                  </div>
                                </div>
                              </ion-col>
                              <ion-col size="2" class="ion-align-items-end" >
                                <ion-item lines="none" class="ion-text-right" >
                                  <div class="action-wrapper category-action">
                                    <ActionPopOver :style="{'--width': '170px'}">
                                      <ion-list>
                                        <ion-item class="ion-no-padding">
                                          <ion-button @click="() => {addProductsToCat = true; editCategoryIndex = cIndex;}" expand="block" fill="none" color="light" >
                                            <ion-icon :icon="addOutline" style="margin-right: 5px; color: black" ></ion-icon>
                                            Add Products
                                          </ion-button>
                                        </ion-item>
                                        <ion-item class="ion-no-padding">
                                          <ion-button @click="editCategoryClick(thisCategory, cIndex)" color="light" expand="block" fill="none" >
                                            <ion-icon :icon="pencilOutline" style="margin-right: 5px; color: black" ></ion-icon>
                                            Edit Category
                                          </ion-button>
                                        </ion-item>
                                        <ion-item class="ion-no-padding" lines="none" >
                                          <ion-button @click="() => deleteCategoryIndex = cIndex" color="light" expand="block" fill="none" >
                                            <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger" ></ion-icon>
                                            Delete Category
                                          </ion-button>
                                        </ion-item>
                                      </ion-list>
                                    </ActionPopOver>
                                  </div>
                                </ion-item>
                              </ion-col>
                            </ion-row>
                            <ion-col v-if="thisCategory.variations[0] && thisCategory.variations[0].variations" class="ion-no-padding" >
                              <ion-reorder-group @ionItemReorder="doReorder($event, thisCategory.variations[0].variations)" :disabled="false" >
                                <ion-row v-for="(thisProduct, pIndex) in thisCategory.variations[0].variations" :key="thisProduct.id" class="table-reorder ion-no-padding ion-justify-content-between" >
                                  <ion-col size="8">
                                    <div class="product-details-wrapper">
                                      <ion-reorder></ion-reorder>
                                      <Image :image="thisProduct.images[0]" height="40px" width="40px"></Image>
                                      <div style="display: inline-block">
                                        <h2 class="common-label ion-no-padding ion-no-margin" style="display: inline-block" >{{ thisProduct.name }}</h2>
                                        <h2 class="ion-no-padding ion-no-margin">
                                          <a :href="shopLinkConsideringCustomDomain + '/' + thisProduct.hashtag" class="hyperlink_cust ion-no-padding" style="font-size: 13px" > {{ '#' + thisProduct.hashtag }} </a>
                                        </h2>
                                      </div>
                                    </div>
                                  </ion-col>
                                  <ion-col size="2" class="ion-align-items-end" >
                                    <ion-item class="ion-text-right ion-no-padding" lines="none" >
                                      <div class="action-wrapper">
                                        <ion-button @click="() => removeProductFromCategory(thisCategory, pIndex)" fill="clear" size="default" color="danger" >
                                          <ion-icon :icon="trashOutline"></ion-icon>
                                        </ion-button>
                                      </div>
                                    </ion-item>
                                  </ion-col>
                                </ion-row>
                              </ion-reorder-group>
                            </ion-col>
                          </ion-col>
                        </ion-grid>
                        <ion-button @click="addCategoryClick" color="light" expand="full" style="border: 1px solid #666666" >
                          <ion-icon :icon="addOutline" style="margin-right: 5px; color: black" ></ion-icon>
                          Click here to Add categories
                        </ion-button>
                      </div>
                      <div v-else>
                        <div v-if="!dummyCollection.variations.variations || dummyCollection.variations.variations.length === 0 || (dummyCollection.variations.variations?.length && dummyCollection.variations.variations[0].attribute)" class="text-center" >
                          <h2 style="margin-bottom: 20px" class="sctn-labels ion-no-margin" >
                            No products added!
                          </h2>
                        </div>
                        <ion-item v-else lines="none" >
                          <ion-grid class="ion-no-padding">
                            <ion-row>
                              <ion-col>
                                <ion-reorder-group @ionItemReorder="doReorder($event, dummyCollection.variations.variations)" :disabled="false" >
                                  <ion-row
                                    v-for="thisProduct in dummyCollection.variations.variations"
                                    :key="thisProduct.id"
                                    class="table-reorder ion-no-padding product-row"
                                  >
                                    <div class="btn-remove-product ion-hide-lg-up">
                                      <ActionPopOver :style="{'--width': '170px'}">
                                        <ion-list>
                                          <ion-item class="ion-no-padding" lines="none" >
                                            <ion-button @click="() => removeProduct(dummyCollection, thisProduct)" color="light" expand="block" fill="none" >
                                              <ion-icon :icon="trashOutline" style="margin-right: 5px; color: black" color="danger" ></ion-icon>
                                              Delete Category
                                            </ion-button>
                                          </ion-item>
                                        </ion-list>
                                      </ActionPopOver>
                                    </div>

                                    <ion-col size-lg="5" size="6" >
                                      <div class="product-details-wrapper">
                                        <ion-reorder></ion-reorder>
                                        <Image :image="thisProduct.images[0]" height="40px" width="40px"></Image>
                                        <div style="display: inline-block; margin-right: 30px; max-width:100%;">
                                          <h2 class="common-label ion-no-padding ion-no-margin product-name" style="display: inline-block" >{{ thisProduct.name }}</h2>
                                          <h2 class="ion-no-padding ion-no-margin">
                                            <a :href="shopLinkConsideringCustomDomain + '/' + thisProduct.hashtag" class="hyperlink_cust ion-no-padding" style="font-size: 13px" > {{ '#' + thisProduct.hashtag }} </a>
                                          </h2>
                                        </div>
                                      </div>
                                    </ion-col>
                                    <ion-col size-lg="5" size="6" class="ion-align-items-center" >
                                      <ion-item lines="none">
                                        <ion-input v-model="thisProduct.quantity" type="text" placeholder="Enter Quantity" class="fullBordered input_custom_style sctn-input" ></ion-input>
                                      </ion-item>
                                    </ion-col>
                                    <ion-col size="2" class="ion-align-items-end ion-hide-lg-down" >
                                      <ion-item lines="none" class="ion-text-right" >
                                        <div class="action-wrapper">
                                          <ion-button
                                            @click="() => removeProduct(dummyCollection, thisProduct)"
                                            fill="clear"
                                            size="default"
                                            color="danger"
                                          >
                                            <ion-icon :icon="trashOutline"></ion-icon>
                                          </ion-button>
                                        </div>
                                      </ion-item>
                                    </ion-col>
                                  </ion-row>
                                </ion-reorder-group>
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </ion-item>
                        <ion-item lines="none">
                          <ion-button
                            @click="() => addProductsModal = true"
                            color="light"
                            expand="full"
                            class="btn-add-product"
                          >
                            <ion-icon :icon="addOutline" style="margin-right: 5px; color: black" ></ion-icon>
                            Click here to Add product
                          </ion-button>
                        </ion-item>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>

    <!-- Add products Modal -->
    <ion-modal
      v-if="addProductsModal"
      :is-open="addProductsModal"
      @didDismiss="() => {addProductsModal = false; productFilter = undefined;}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="prdt-title ion-no-margin">Add Products</h2>
            <ion-buttons slot="end">
              <ion-button
                color="dark"
                @click="() => {addProductsModal = false; productFilter = undefined;}"
              >
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="add-product-content">
          <div class="cust_pad_v1">
            <ion-input
              v-model="productFilter"
              type="text"
              placeholder="Enter product name"
              class="fullBordered cust_input_hght input-width"
            ></ion-input>
          </div>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row
              v-for="thisProduct in productsToShow"
              :key="thisProduct.id"
              class="rowMargin"
            >
              <ion-col size="2">
                <img
                  width="100"
                  v-if="thisProduct.images && thisProduct.images.length"
                  :src="thisProduct.images[0]"
                  style="border-radius: 5px"
                />
              </ion-col>
              <ion-col
                :size="thisProduct.variation ? 10 : 9"
                class="ion-align-self-center"
              >
                <h2
                  class="common-label ion-no-margin"
                  style="margin-left: 30px"
                > {{ thisProduct.name }} </h2>
                <h2
                  class="common-label ion-no-margin"
                  style="margin: 10px 0px 0px 30px; color: #eb445a"
                  v-if="thisProduct.variation"
                > Sorry! You can't add this product as it contains variations </h2>
              </ion-col>
              <ion-col
                :size="thisProduct.variation ? 0 : 1"
                v-if="!thisProduct.variation"
              >
                <div class="action-button-wrapper">
                  <ion-icon
                    slot="icon-only"
                    v-if="dummyCollection.variations.variations.findIndex(e => e.id === thisProduct.id) > -1"
                    @click="() => dummyCollection.variations.variations.splice(dummyCollection.variations.variations.findIndex(e => e.id === thisProduct.id), 1)"
                    :icon="removeCircleOutline"
                    color="danger"
                  ></ion-icon>
                  <ion-icon
                    slot="icon-only"
                    v-else
                    @click="() => dummyCollection.variations.variations.push(thisProduct)"
                    :icon="addCircleOutline"
                  ></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="() => {addProductsModal = false; productFilter = undefined;}"
              color="dark"
            >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <!-- Add categories Modal -->
    <ion-modal
      v-if="addEditCategoryMode"
      :is-open="addEditCategoryMode"
      @didDismiss="() => {addEditCategoryMode = false; editCategoryIndex = -1; dummyCategory = { name: undefined, description: undefined, image: undefined, variations: [{ name: 'product', variations: [] }] };}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="prdt-title ion-no-margin">{{editCategoryIndex > -1 ? 'Edit ' : 'Add '}}Category</h2>
            <ion-buttons slot="end">
              <ion-button
                color="dark"
                @click="() => {addEditCategoryMode = false; editCategoryIndex = -1; dummyCategory = { name: undefined, description: undefined, image: undefined, variations: [{ name: 'product', variations: [] }] }}"
              >
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-card-header>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels">Category Name</ion-card-title>
              <InputValidation
                v-slot="slotProps"
                :inputVal="dummyCategory.name"
                :validateConf="{required: true, maxLength: 500, errorMessage: `Please enter valid Category Name`}"
                ref="categoryNameValidate"
                @onValidate="({isInvalid}) => categoryFormValidation.isInvalidCategoryName = isInvalid"
              >
                <ion-input
                  v-model="dummyCategory.name"
                  type="text"
                  placeholder="Category name"
                  class="fullBordered input_custom_style sctn-input"
                  @change="slotProps.onChange"
                ></ion-input>
              </InputValidation>
            </div>
            <div class="prdct-section">
              <ion-card-title class="sctn-labels">Category Description</ion-card-title>
              <InputValidation
                v-slot="slotProps"
                :inputVal="dummyCategory.description"
                :validateConf="{required: true, maxLength: 500, errorMessage: `Please enter valid Category Description`}"
                ref="categoryDescriptionValidate"
                @onValidate="({isInvalid}) => categoryFormValidation.isInvalidCategoryDescription = isInvalid"
              >
                <ion-textarea
                  ref="categoryDescription"
                  v-model="dummyCategory.description"
                  rows="4"
                  placeholder="Category description"
                  class="fullBordered input_custom_style"
                  @change="slotProps.onChange"
                ></ion-textarea>
              </InputValidation>
            </div>
            <InputValidation
              v-slot="slotProps"
              :inputVal="dummyCategory.image"
              :validateConf="{required: true, errorMessage: `Please select valid image.`, errorInputBorder: false}"
              ref="categoryImageValidate"
              @onValidate="({isInvalid}) => categoryFormValidation.isInvalidImage = isInvalid"
            >
              <div v-if="dummyCategory.image && dummyCategory.image.length" @click="() => $refs.categoryImageInput.click()">
                <Image :image="dummyCategory.image" height="120px" width="120px" ></Image>
              </div>
              <div
                v-else
                style="margin-top: 10px;"
              >
                <ion-button
                  fill="solid"
                  size="default"
                  color="dark"
                  @click="() => $refs.categoryImageInput.click()"
                >
                  Upload Image
                </ion-button>
              </div>
              <input
                type="file"
                style="display: none;"
                id="categoryImageInput"
                ref="categoryImageInput"
                @change="(e) => {slotProps.onChange(); handleImageUpload(e, true)}"
                accept="image/*"
              />
            </InputValidation>
          </ion-card-header>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="updateCategory"
              color="dark"
            >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <!-- Reorder Categories Modal -->
    <ion-modal
      v-if="reorderCategories"
      :is-open="reorderCategories"
      @didDismiss="() => reorderCategories = false"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="prdt-title ion-no-margin">Reorder Categories</h2>
            <ion-buttons slot="end">
              <ion-button
                color="dark"
                @click="() => reorderCategories = false"
              >
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-reorder-group
            @ionItemReorder="doReorder($event, dummyCollection.variations.variations)"
            :disabled="false"
          >
            <ion-row
              v-for="thisCategory in dummyCollection.variations.variations"
              :key="thisCategory.id"
              class="table-reorder ion-no-padding ion-justify-content-between"
            >
              <ion-col>
                <ion-reorder style="display: inline-block"></ion-reorder>
                <img
                  v-if="thisCategory.image && thisCategory.image.length"
                  :src="thisCategory.image"
                  class="catgory-img"
                />
                <img
                  v-else
                  src="https://jumper.ai/web-s/resources/images/add-product.png"
                  class="catgory-img"
                />
                <div style="display: inline-block">
                  <h2
                    class="common-label ion-no-padding ion-no-margin"
                    style="display: inline-block"
                  >{{ thisCategory.name }}</h2>
                  <h2
                    v-if="thisCategory.variations[0] && thisCategory.variations[0].variations"
                    class="common-label ion-no-padding"
                  >{{ thisCategory.variations[0].variations.length }} Product(s) added</h2>
                  <h2
                    v-else
                    class="common-label ion-no-padding ion-no-margin"
                  >No Product added</h2>
                </div>
              </ion-col>
            </ion-row>
          </ion-reorder-group>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="() => reorderCategories = false"
              color="dark"
            >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <!-- Add products to category Modal -->
    <ion-modal
      v-if="dummyCollection.variations && dummyCollection.variations.variations && dummyCollection.variations.variations.length && editCategoryIndex > -1 && dummyCollection.variations.variations[editCategoryIndex] && addProductsToCat"
      :is-open="addProductsToCat"
      @didDismiss="() => {addProductsToCat = false; editCategoryIndex = -1; productFilter = undefined;}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="prdt-title ion-no-margin">Add Products to {{ dummyCollection.variations.variations[editCategoryIndex].name }}</h2>
            <ion-buttons slot="end">
              <ion-button
                color="dark"
                @click="() => {addProductsToCat = false; editCategoryIndex = -1; productFilter = undefined;}"
              >
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>

              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="add-product-to-category-content">
          <div class="cust_pad_v1">
            <ion-input
              v-model="productFilter"
              type="text"
              placeholder="Enter product name"
              class="fullBordered cust_input_hght input-width"
            ></ion-input>
          </div>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row
              v-for="thisProduct in productsToShow"
              :key="thisProduct.id"
              class="rowMargin"
            >
              <ion-col size="2">
                <img
                  width="100"
                  v-if="thisProduct.images && thisProduct.images.length"
                  :src="thisProduct.images[0]"
                />
              </ion-col>
              <ion-col
                :size="thisProduct.variation ? 10 : 9"
                class="ion-align-self-center ion-padding-horizontal"
              >
                <h2 class="common-label ion-no-margin"> {{ thisProduct.name }} </h2>
                <h2
                  class="common-label ion-no-margin"
                  style="margin: 10px 0px 0px 00px; color: #eb445a"
                  v-if="thisProduct.variation"
                > Sorry! You can't add this product as it contains variations </h2>
              </ion-col>
              <ion-col
                :size="thisProduct.variation ? 0 : 1"
                v-if="!thisProduct.variation"
              >

                <div class="action-button-wrapper">
                  <ion-icon
                    slot="icon-only"
                    v-if="dummyCollection.variations.variations[editCategoryIndex].variations[0].variations.findIndex(e => e.id === thisProduct.id) > -1"
                    @click="() => dummyCollection.variations.variations[editCategoryIndex].variations[0].variations.splice(dummyCollection.variations.variations[editCategoryIndex].variations[0].variations.findIndex(e => e.id === thisProduct.id), 1)"
                    :icon="removeCircleOutline"
                    color="danger"
                  ></ion-icon>
                  <ion-icon
                    slot="icon-only"
                    v-else
                    @click="() => dummyCollection.variations.variations[editCategoryIndex].variations[0].variations.push(thisProduct)"
                    :icon="addCircleOutline"
                  ></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="() => {addProductsToCat = false; editCategoryIndex = -1; productFilter = undefined;}"
              color="dark"
            >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>

    <!-- Delete Categories Confirmation Modal -->
    <ion-alert
      v-if="deleteCategoryIndex > -1"
      :is-open="deleteCategoryIndex > -1"
      header="Are you sure?"
      :message="'You want to delete selected category?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => deleteCategoryIndex = -1
      },
      {
        text: 'Yes',
        handler: () => dummyCollection.variations && dummyCollection.variations.variations && dummyCollection.variations.variations.length ? dummyCollection.variations.variations.splice(deleteCategoryIndex, 1) : ''
      }]"
      @didDismiss="() => deleteCategoryIndex = -1"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import {
  chevronDownCircleOutline,
  trashOutline,
  arrowBackOutline,
  addOutline,
  close,
  pencilOutline,
  addCircleOutline,
  removeCircleOutline,
} from "ionicons/icons";
import {
  IonTextarea,
  IonCheckbox,
  IonToggle,
  IonAccordion,
  IonAccordionGroup,
  IonReorderGroup,
  IonReorder,
  alertController,
} from "@ionic/vue";
import jwtInterceptor from "/src/shared/jwtInterceptor";
import ActionPopOver from "../../components/ActionPopOver";
import InputValidation from "../../components/InputValidation.vue";
import Image from "../../components/Image.vue";


export default {
  components: {
    IonTextarea,
    IonCheckbox,
    IonToggle,
    IonAccordion,
    IonAccordionGroup,
    IonReorderGroup,
    IonReorder,
    ActionPopOver,
    InputValidation,
    Image
  },
  data() {
    return {
      chevronDownCircleOutline,
      close,
      trashOutline,
      arrowBackOutline,
      addOutline,
      pencilOutline,
      loading: false,
      imageUploadLoading: false,
      productFilter: undefined,
      chooseProducts: false,
      checkingHashtag: false,
      validHashtag: true,
      dummyCollection: {
        weight_si_unit: "kg",
        woocommerce: false,
        hashtags: undefined,
        magento: false,
        length_si_unit: "cm",
        images: [],
        lowername: false,
        id: undefined,
        category: undefined,
        isbundle: false,
        shopify: false,
        lead: false,
        inventory_policy: "jumper",
        online_event_url: false,
        event: undefined,
        live: false,
        version: null,
        status: undefined,
        selloffline: false,
        mockuprequired: undefined,
        description: undefined,
        tags: false,
        skipaddress: false,
        amazon: false,
        hashtag: undefined,
        active: false,
        name: undefined,
        created: undefined,
        notes: false,
        variations: {
          totalcost: null,
          sku: null,
          sellergetcost: undefined,
          name: undefined,
          tags: [],
          splitpayamount: undefined,
          attribute: undefined,
          image: undefined,
          description: undefined,
          variations: [],
          shippingCost: undefined,
          height: undefined,
          width: undefined,
          length: undefined,
          cost: undefined,
          relation: [],
          length_si_unit: "cm",
          tax: undefined,
          grams: undefined,
          id: undefined,
          stock: undefined,
        },
        skipemailverification: false,
        shopActive: false,
        printful: false,
        bigcommerce: false,
        printfulmockup: false,
        skipemail: false,
      },
      allProducts: [],
      addProductsModal: false,
      collectionImageChanged: false,
      hasCategories: undefined,
      reorderCategories: false,
      addProductsToCat: false,
      editCategoryIndex: -1,
      addEditCategoryMode: false,
      dummyCategory: {
        name: undefined,
        description: undefined,
        image: undefined,
        variations: [{ name: "product", variations: [] }],
      },
      deleteCategoryIndex: -1,
      backUpProdData: null,
      backUpCatData: null,
      categoryFormValidation: {
        isInvalidCategoryName: false,
        isInvalidCategoryDescription: false,
        isInvalidImage: false,
      },
      addCircleOutline,
      removeCircleOutline,
    };
  },
  async ionViewWillEnter() {
    await this.fetchProducts();
    if (this.$route.query && this.$route.query.collectionid) {
      await this.init();
    }
    this.dummyCollection.currency = this.user.currency;
  },
  computed: {
    productsToShow() {
      return this.productFilter && this.productFilter.length
        ? this.allProducts.filter((e) =>
            e.name.match(new RegExp(this.productFilter, "gi"))
          )
        : this.allProducts;
    },
    isInvalidCategoryForm() {
      return (
        this.categoryFormValidation.isInvalidCategoryName ||
        this.categoryFormValidation.isInvalidCategoryDescription ||
        this.categoryFormValidation.isInvalidImage
      );
    },
  },
  watch: {
    hasCategories(n, o) {
      if (o !== undefined) {
        if (n) {
          this.backUpProdData = this.dummyCollection.variations;
          if (this.backUpCatData) {
            this.dummyCollection.variations = this.backUpCatData;
            this.backUpCatData = null;
          } else {
            this.dummyCollection.variations = {
              name: "category",
              variations: [],
            };
          }
        } else {
          this.backUpCatData = this.dummyCollection.variations;
          if (this.backUpProdData) {
            this.dummyCollection.variations = this.backUpProdData;
            this.backUpProdData = null;
          } else {
            this.dummyCollection.variations = {
              variations: [],
            };
          }
        }
      }
    },
  },
  methods: {
    // eslint-disable-next-line
    doReorder(e, arrayToOrder) {
      arrayToOrder = e.detail.complete(arrayToOrder);
    },
    async confirmAlertHandler(onOkay, productToRemoveName) {
      const alert = await alertController.create({
        header: `Do you want to remove product ${productToRemoveName}?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              onOkay();
            },
          },
        ],
      });

      await alert.present();
    },
    async removeProductFromCategory(thisCategory, pIndex) {
      const onOkay = () => {
        thisCategory.variations[0]?.variations?.splice(pIndex, 1);
      };

      const productToRemove = thisCategory.variations[0]?.variations[pIndex];
      this.confirmAlertHandler(onOkay, productToRemove?.name);
    },
    async removeProduct(dummyCollection, thisProduct) {
      const onOkay = () => {
        dummyCollection.variations.variations.splice(
          dummyCollection.variations.variations.findIndex(
            (e) => e.id === thisProduct.id
          ),
          1
        );
      };

      this.confirmAlertHandler(onOkay, thisProduct?.name);
    },
    async init() {
      try {
        this.loading = true;
        let reqBody = { catalog: { id: this.$route.query.collectionid } };
        let editCollectionResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "get-catalogue", reqBody );

        if ( (editCollectionResponse.status == 200 || editCollectionResponse.status == 201) && editCollectionResponse.data.success ) {
          this.dummyCollection = editCollectionResponse.data;
          this.dummyCollection.skipaddress = !this.dummyCollection.skipaddress;
          this.dummyCollection.skipemail = !this.dummyCollection.skipemail;

          if (
            this.dummyCollection.variations &&
            this.dummyCollection.variations.variations &&
            this.dummyCollection.variations.variations.length &&
            this.dummyCollection.variations.variations[0].attribute
          ) {
            this.hasCategories = true;
          } else {
            this.hasCategories = false;
          }
          let variationQuantityLimits = [];
          if ( this.dummyCollection.online_event_url && this.dummyCollection.online_event_url.length ) {
            variationQuantityLimits = this.dummyCollection.online_event_url.split(",");
          }

          if ( variationQuantityLimits.length && this.dummyCollection.variations?.variations?.length ) {
            this.dummyCollection.variations.variations.forEach((e) => {
              let temp = variationQuantityLimits .find((variation) => variation.indexOf(e.id) > -1) ?.split("=");
              e.quantity = temp && temp.length === 2 ? temp[1] : undefined;
            });
          }
        } else {
          if ( editCollectionResponse.data && !editCollectionResponse.data.success && editCollectionResponse.data.errorMessage ) {
            this.updateToast({ open: true, message: editCollectionResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    async checkHashtag() {
      try {
        if ( !this.dummyCollection.hashtag || this.dummyCollection.hashtag.length === 0 ) {
          this.updateToast({ open: true, message: "Please enter a valid input for hashtag!", type: "warning", });
          return;
        }
        this.checkingHashtag = true;
        this.validHashtag = false;

        let checkHashtagResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "check-hashtag", { hashtag: this.dummyCollection.hashtag } );

        if ( (checkHashtagResponse.status == 200 || checkHashtagResponse.status == 201) && checkHashtagResponse.data.success ) {
          this.checkingHashtag = false;
          if (checkHashtagResponse.data.valid) {
            this.validHashtag = true;
          } else {
            this.validHashtag = false;
          }
        } else {
          if ( checkHashtagResponse.data.errorMessage && checkHashtagResponse.data.errorMessage.length ) {
            this.updateToast({ open: true, message: checkHashtagResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong!", type: "danger", });
      }
    },
    async handleImageUpload(event, isCategory) {
      try {
        this.loading = true;
        if (event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          if (await this.validateImage(newImage, 4)) {
            let uploadSendResponseData = await this.uploadImage( newImage, "/add-image" );
            if ( uploadSendResponseData.image && uploadSendResponseData.image.length ) {
              if (isCategory) {
                this.dummyCategory.image = uploadSendResponseData.url;
              } else {
                this.dummyCollection.images[0] = uploadSendResponseData.url;
                this.collectionImageChanged = true;
              }
            } else {
              this.updateToast({ open: true, message: "Something went wrong uploading the picture", type: "danger", });
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({ open: true, message: "Invalid picture", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async updateProduct() {
      try {
        this.loading = true;
        let online_event_url = [];

        let reqBody = {
          catalog: {
            id: this.dummyCollection.id,
            name: this.dummyCollection.name,
            hashtag: this.dummyCollection.hashtag,
          },
        };
        if (!reqBody.catalog.name || reqBody.catalog.name.length === "0") {
          this.updateToast({ open: true, message: "Please enter a valid name", type: "warning", });
          return;
        }
        if ( !this.dummyCollection.description || this.dummyCollection.description.length === "0" ) {
          this.updateToast({ open: true, message: "Please enter a valid description", type: "warning", });
          return;
        }
        if ( !reqBody.catalog.hashtag || reqBody.catalog.hashtag.length === "0" ) {
          this.updateToast({ open: true, message: "Please enter a valid hashtag", type: "warning", });
          return;
        }
        if ( !this.dummyCollection.images || this.dummyCollection.images.length == "0" ) {
          this.updateToast({ open: true, message: "Please add an image", type: "warning", });
          return;
        }
        if (this.hasCategories) {
          reqBody.catalog["categories"] =
            this.dummyCollection.variations.variations.map((e) => {
              let variation = {
                name: e.name,
                description: e.description,
                image:
                  e.image &&
                    e.image.length &&
                    e.image.includes(
                      this.environment('VUE_APP_ROOT') + "jump-image/"
                    )
                    ? e.image.substring(e.image.lastIndexOf("/") + 1)
                    : undefined,
                products: e.variations[0].variations.map((e) => e.id).join(","),
              };
              if (!e.newAddedCategory) {
                variation.id = e.id;
              }
              return variation;
            });
          if ( !reqBody.catalog.categories || reqBody.catalog.categories.length === 0 ) {
            this.updateToast({ open: true, message: "Atleast one category is required", type: "warning", });
            return;
          }
          if (
            !reqBody.catalog.categories.every(
              (thisCat) => thisCat.products.length > 0
            )
          ) {
            this.updateToast({ open: true, message: "Atleast one product should be added in all the categories", type: "warning", });
            return;
          }
        } else {
          reqBody.catalog["products"] =
            this.dummyCollection.variations.variations
              .map((e) => e.id)
              .join(",");
          if (this.dummyCollection.variations?.variations?.length) {
            var invalid = false;
            for ( var i = 0; i < this.dummyCollection.variations?.variations.length; i++ ) {
              let thisVariation = this.dummyCollection.variations?.variations[i];
              if (!thisVariation.quantity) {
                invalid = true;
                break;
              } else
                online_event_url.push( thisVariation.id + "=" + thisVariation.quantity );
            }
            if (invalid) {
              this.updateToast({ open: true, message: "Add quantities for all the products added!", type: "warning", });
              return;
            }
          } else {
            this.updateToast({ open: true, message: "Add at least one product", type: "warning", });
            return;
          }
        }
        let addCatalogueResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "add-catalogue", reqBody );

        if ( (addCatalogueResponse.status == 200 || addCatalogueResponse.status == 201) && addCatalogueResponse.data.success ) {
          reqBody = {
            productid: addCatalogueResponse.data.id,
            description: this.dummyCollection.description,
            skipaddress: (!this.dummyCollection.skipaddress).toString(),
            selloffline: this.dummyCollection.selloffline.toString(),
            skipemail: (!this.dummyCollection.skipemail).toString(),
            shopActive: this.dummyCollection.shopActive.toString(),
            currency: this.dummyCollection.currency,
            tax: "0",
            splitpayamount: "0",
            sellergetcost: "0",
            cash_on_delivery: "false",
            passfees: "false",
            calendarinvite: "false",
          };
          const collectionImage = this.dummyCollection?.images[0];
          if (this.collectionImageChanged && collectionImage ) {
            reqBody.image_url = collectionImage.includes(this.environment('VUE_APP_ROOT') + "jump-image/") ? collectionImage.substring(collectionImage.lastIndexOf("/") + 1) : collectionImage;
          }
          if (!this.hasCategories) {
            if (online_event_url && online_event_url.length)
              reqBody.online_event_url = online_event_url.join(",");

            if (
              this.dummyCollection.hashtags &&
              this.dummyCollection.hashtags.length
            )
              reqBody.hashtags = this.dummyCollection.hashtags.join(",");
            else reqBody.hashtags = undefined;
          }

          let productSaveResponse = await jwtInterceptor.post( this.environment("VUE_APP_ROOT_API") + "add-product", reqBody );
          if ((productSaveResponse.status == 200 || productSaveResponse.status == 201) && productSaveResponse.data.success) {
            this.updateToast({ open: true, message: "Product updated successfully!", type: "success", });
            // for (var prop in this.dummyCollection)
            //   this.dummyCollection[prop] = productSaveResponse.data[prop];
            // this.dummyCollection.skipaddress =
            //   !this.dummyCollection.skipaddress;
            // this.dummyCollection.skipemail = !this.dummyCollection.skipemail;
            this.$router.replace({ path: '/store', query: { tab: 'collections' }});
          } else {
            if ( productSaveResponse.data.errorMessage && productSaveResponse.data.errorMessage.length ) {
              this.updateToast({ open: true, message: productSaveResponse.data.errorMessage, type: "danger", });
            } else {
              this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
            }
          }
        } else {
          if ( addCatalogueResponse.data.errorMessage && addCatalogueResponse.data.errorMessage.length ) {
            this.updateToast({ open: true, message: addCatalogueResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({ open: true, message: "Something went wrong!", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      try {
        this.loading = true;
        let fetchProductsResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-shop-list"
        );
        console.log(fetchProductsResponse);
        /* bundle: false, catalogue: false */

        if ( (fetchProductsResponse.status == 200 || fetchProductsResponse.status == 201) && fetchProductsResponse.data.success ) {
          this.allProducts = fetchProductsResponse.data.products;
          this.allProducts = this.allProducts.filter(
            (e) => !e.bundle && !e.catalog
          );
        } else {
          if ( fetchProductsResponse.data && !fetchProductsResponse.data.success && fetchProductsResponse.data.errorMessage ) {
            this.updateToast({ open: true, message: fetchProductsResponse.data.errorMessage, type: "danger", });
          } else {
            this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.loading = false;
      }
    },
    updateCategory() {
      if (this.isInvalidCategoryForm) {
        this.validateCreateCategoryForm();
        return;
      }
      try {
        this.loading = true;
        if (
          this.dummyCollection.variations &&
          this.dummyCollection.variations.variations &&
          this.dummyCollection.variations.variations.length &&
          this.editCategoryIndex > -1 &&
          this.dummyCollection.variations.variations[this.editCategoryIndex]
        ) {
          this.dummyCollection.variations.variations[ this.editCategoryIndex ].name = this.dummyCategory.name;
          this.dummyCollection.variations.variations[ this.editCategoryIndex ].description = this.dummyCategory.description;
          this.dummyCollection.variations.variations[ this.editCategoryIndex ].image = this.dummyCategory.image;
        } else {
          if (this.editCategoryIndex === -1) {
            if (
              this.dummyCollection.variations &&
              this.dummyCollection.variations.variations &&
              this.dummyCollection.variations.variations.length
            ) {
              this.dummyCategory["id"] = Math.floor(
                100000 + Math.random() * 900000
              );
              this.dummyCategory["newAddedCategory"] = true;
              this.dummyCollection.variations.variations.push( this.dummyCategory );
            } else {
              this.dummyCollection.variations = { name: "category", variations: [this.dummyCategory], };
            }
          } else {
            this.updateToast({ open: true, message: "Something went wrong, please report this error!", type: "danger", });
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({ open: true, message: "Something went wrong", type: "danger", });
      } finally {
        this.loading = false;
        this.addEditCategoryMode = false;
        this.editCategoryIndex = -1;
        this.dummyCategory = {
          name: undefined,
          description: undefined,
          image: undefined,
          variations: [{ name: "product", variations: [] }],
        };
      }
    },
    validateCreateCategoryForm() {
      this.$refs.categoryNameValidate.errorHandler();
      this.$refs.categoryDescriptionValidate.errorHandler();
      this.$refs.categoryImageValidate.errorHandler();
    },
    editCategoryClick(thisCategory, cIndex) {
      this.addEditCategoryMode = true;
      this.editCategoryIndex = cIndex;
      this.dummyCategory = {
        name: thisCategory.name,
        description: thisCategory.description,
        image: thisCategory.image,
        variations: [{ name: "product", variations: [] }],
      };
    },
    addCategoryClick() {
      this.addEditCategoryMode = true;
      this.editCategoryIndex = -1;
      this.dummyCategory = {
        name: undefined,
        description: undefined,
        image: undefined,
        variations: [{ name: "product", variations: [] }],
      };
    },
  },
};
</script>

<style scoped>
.card_container_grid {
  margin: 20px;
}

.btn-add-product {
  border: 1px solid #666666;
  width: 100%;
}

.add-product-content .action-button-wrapper,
.add-product-to-category-content .action-button-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sctn-labels {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
}
.sctn-input {
  border-radius: 5px;
}

.sub-Hdng {
  font-size: 18px;
  color: var(--ion-color-black);
  font-weight: 700;
}

.table-reorder {
  border: 1px solid #ededed;
  padding: 10px 0 10px 10px;
  margin-bottom: 21px;
}

.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 10px;
}

.catgory-img {
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
}

.catgory-title {
  font-size: 18px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-bottom: 7px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
  border-radius: 5px;
}

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.btn-remove-product {
  position: absolute;
  right: 0px;
  z-index: 9;
  top: 2px;
}

.product-row {
  position: relative;
}

.action-wrapper {
  width: 100%;
}
.category-action {
  padding-right: 20px;
}

ion-toolbar.uploadbttn {
  --background: var(--ion-color-white);
}

@media only screen and (max-width: 760px) {
  ion-modal {
    --max-width: auto;
    --border-radius: 0px;
  }
  input-width {
    max-width: 100%;
  }
}
@media only screen and (min-width: 780px) {
  ion-modal {
    --border-radius: 10px;
    --max-width: 540px;
  }
}

@media only screen and (min-width: 760px) {
  .set-collections {
    margin: 5px 20px 5px 5px;
  }
}

@media only screen and (min-width: 760px) {
  .paddingLeft {
    padding-left: 20px;
  }
}
input-width {
  max-width: 480px;
  margin-left: 26px;
}
.action-button-wrapper ion-icon {
  cursor: pointer;
}
.category-row-header {
  display: flex;
  column-gap: 10px;
  margin-left: 16px;
}
.product-details-wrapper {
  display: flex;
  column-gap: 10px;
}
.product-details-wrapper ion-reorder {
  align-self: center;
}
.has-category-question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 5px;
}
.category-section-header {
  margin-left: 16px;
  margin-bottom: 20px;
}
</style>