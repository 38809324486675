<template>
  <div>
    <div class="optionsContainer custBorder">
      <p class="notification-subtitle paddingBottom ion-no-margin">Card title</p>
      <ion-input type="text" placeholder="Enter a title" v-model="addnotification.title" class="ion-no-border sctn-input"></ion-input>
    </div>
    <div class="optionsContainer">
      <p class="notification-subtitle paddingBottom ion-no-margin">Card image</p>
      <ion-item v-if="addnotification.imageurl || addnotification.imageurl != undefined" lines="none" class="cardImage">
        <ion-label>{{ formatUrl(addnotification.imageurl) }}</ion-label>
        <ion-icon slot="end" :icon="trashBinOutline" color="danger" style="cursor: pointer"></ion-icon>
      </ion-item>
      <div v-else>
        <input type="file" style="display: none;" id="productImage" ref="productImage" @change="(event) => $emit('handleImageUpload', event)" accept="image/*" class="fullBordered input_custom_style sctn-input" />
        <ion-button v-if="!addnotification.imageurl || addnotification.imageurl === undefined"  @click="() => $refs.productImage.click()" fill="outline" class="uploadbttn">
          <ion-icon v-if="!uploadingImage" :icon="imageOutline" class="paddingRight"></ion-icon>
          <ion-label v-if="!uploadingImage" :icon="refreshOutline">Upload Image</ion-label>
          <ion-spinner v-else name="lines-small"/>
        </ion-button>
      </div>
    </div>

    <div class="optionsContainer">
      <p class="notification-subtitle paddingBottom ion-no-margin">Card image ratio:</p>
      <ion-radio-group v-model="addnotification.image_aspect_ratio" class="radio-bttn-container">
        <ion-item lines="none" class="radioItems">
          <ion-label>Horizontal</ion-label>
          <ion-radio slot="start" :value="'HORIZONTAL'" class="radioBttn"></ion-radio>
        </ion-item>
        <ion-item lines="none" class="radioItems">
          <ion-label>Square</ion-label>
          <ion-radio slot="start" :value="'SQUARE'" class="radioBttn"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>

    <div class="optionsContainer">
      <p class="notification-subtitle paddingBottom ion-no-margin">Button type:</p>
      <ion-select slot="end" v-model="addnotification.button_title" interface="popover" placeholder="Button Type" class="ion-no-border slctContainer">
        <ion-select-option value="Get Messages">Get messages</ion-select-option>
        <ion-select-option value="Allow messages">Allow messages</ion-select-option>
        <ion-select-option value="Opt in messages">Opt in messages</ion-select-option>
        <ion-select-option value="Sign up for messages">Sign up for messages</ion-select-option>
      </ion-select>
    </div>

    <div class="infoBanner" v-if="addnotification.subscribers !== undefined">
      <ion-item lines="none" class="targetCustomers">
        <ion-icon size="large" :icon="peopleOutline" class="iconPrimary"></ion-icon>
        <ion-label class="description">
          <h2 class="ion-no-margin">{{ addnotification.subscribers }}</h2>
          <p class="ion-no-margin notification-subtitle">users have subscribed to this notification.</p>
        </ion-label>
      </ion-item>
    </div>
    <!-- save/Add Notifications -->
    <div v-if="addNewNotification" class="flexContainer">
      <ion-button fill="outline" size="default" class="bttnPrimary bttnWidth" expand="block"  @click="(event) => $emit('addEditNotification', event)"> 
        <ion-label v-if="commonLoader">Save</ion-label>
        <ion-spinner v-else name="lines-small" class="loader"/>
      </ion-button>
      <ion-button  fill="outline" size="default" class="bttnOutline" expand="block" @click="cancelBttn"> 
        <ion-label>Cancel</ion-label>
      </ion-button>
    </div>
    <div v-else>
      <ion-button fill="outline" size="default" class="bttnPrimary" expand="block"  @click="(event) => $emit('addEditNotification', event)"> 
        <ion-label v-if="commonLoader">Save</ion-label>
        <ion-spinner v-else name="lines-small" class="loader"/>
      </ion-button>
    </div>
  </div>
</template>

<script>
import { refreshOutline, trashBinOutline, createOutline, imageOutline, peopleOutline } from 'ionicons/icons';
import { IonSelect, IonSelectOption, IonSpinner, IonRadio, IonRadioGroup } from "@ionic/vue";

export default {
  components: { IonSelect, IonSelectOption, IonSpinner, IonRadio, IonRadioGroup },
  emits: [ "addEditNotification", "handleImageUpload","update:dummyNotification","update:addEditMode" ],
  props: {
    uploadingImage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    commonLoader: {
      type: Boolean,
      default: false
    },
    allNotifications: {
      type: Array,
      default: () => [],
    },
    addEditMode: {
      type: Boolean,
      default: false
    },
    addNewNotification: {
      type: Boolean,
      default: false
    },
    dummyNotification: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      trashBinOutline,
      createOutline,
      imageOutline,
      peopleOutline,
      refreshOutline,
      addnotification: {},
    }
  },
  mounted() {
    this.addnotification = this.dummyNotification;
    this.cancelButton = this.addEditMode;
  },
  watch: {
    addnotification: {
      handler(newVal) {
        this.$emit('update:dummyNotification', newVal)
      },
      deep: true
    }
  },
  methods: {
    formatUrl(url){
      const maxLength = 50; // adjust as needed
      if (url.length <= maxLength) {
        return url;
      }
      return url.slice(0, maxLength) + '...';
    },
    cancelBttn() {
      this.$emit('update:addEditMode', false)
    }
  }
}
</script>

<style scoped>
.notification-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-04);
}
ion-button.bttnPrimary{
  --background: var(--purple);
  color: var(--white);
  --border-radius: 5px;
  --border-width: 0px;
}
ion-button.bttnOutline{
  --background: var(--white);
  color: var(--purple);
  --border-radius: 5px;
  --border-width: 1px;
  --border-color: var(--purple);
  width: 50%;
}
.bttnWidth {
  width: 50%;
}
ion-button.uploadbttn{
  color: var(--purple);
  --border-radius: 5px;
  --border-width: 1px;
  --border-color: var(--purple);
}
ion-item.cardImage {
  --background: var(--background-primary);
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px;
  border-radius: 5px;
}
.optionsContainer{
  padding-bottom: 20px;
  margin-bottom: 20px;
}
ion-input.sctn-input{
  background: var(--background-primary);
  border-radius: 5px;
  --padding-start: 10px;
}
.custBorder{
  border-bottom: 1px solid var(--background-primary);
}
.paddingRight {
  padding-right: 10px;
}
.paddingBottom{
  padding-bottom: 10px;
}
.slctContainer {
  border-radius: 5px;
  background: var(--background-primary);
  --padding-end: 10px;
}
.infoBanner {
  border-radius: 5px;
  border: 1px solid var(--background-primary);
  margin-top: 15px;
  padding: 15px;
  background: var(--white);
  margin-bottom: 20px;
}
ion-item.targetCustomers{
  --background: var(--white);
  border-radius: 5px;
}
.infoBanner .description {
  padding-left: 15px;
}
.infoBanner .description h2{
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  color: var(--black);
}
ion-icon.iconPrimary{
  color: var(--purple);
}
.loader{
  color: var(--white);
}
ion-radio.radioBttn {
  margin-right: 10px;
  --color-checked: var(--purple);
}
.radio-bttn-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
ion-item.radioItems {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexContainer{
  display: flex;
  align-items: center;
}
</style>