<template>
  <master-layout :pageTitle="pageTitle">
    <ion-content>
      <ion-item lines="none" class="ion-no-padding">
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-icon @click="() => $router.go(-1)" size="large" :icon="arrowBackOutline">
            </ion-icon>
            <h2 class="ion-no-margin">{{pageHeader}}</h2>
          </ion-buttons>
        </ion-toolbar>
      </ion-item>

      <ion-item class="ion-no-padding ion-no-margin search-item" lines="none">
        <ion-searchbar enterkeyhint="search" :debounce="1000" @ionChange="searchProduct($event)" placeholder="Enter product name to search"></ion-searchbar>
      </ion-item>
      <ion-item >
          <ion-list role="feed">
            <ion-list-header>
              <ion-item class="ion-no-padding ion-no-margin">
                <ion-grid class="ion-no-padding ion-no-margin">
                  <ion-row class="table-row ion-no-padding">
                    <ion-col size="3">
                      <ion-label>Image</ion-label>
                    </ion-col>
                    <ion-col size="4">
                      <ion-label>PRODUCT NAME</ion-label>
                    </ion-col>
                    <ion-col size="3">
                      <ion-label>CHECKOUT TRIGGER</ion-label>
                    </ion-col>
                    <ion-col size="2">
                      <ion-label>ACTION</ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ion-list-header>
            <ion-item v-for="product in productList" :key="product.id" role="article">
              <ion-grid>
                <ion-row class="ion-no-padding content-row">
                  <ion-col size="3" class="prod-image-col">
                    <div class="image-wrapper">
                      <img v-if="product.image" :src="product.image" />
                    </div>
                  </ion-col>
                  <ion-col size="4" class="prod-name-col">
                    <ion-label>{{ product.name }}</ion-label>
                  </ion-col>
                  <ion-col size="3" class="prod-trigger-col">
                    <ion-label v-if="product.added" color="danger">{{ product.reason }}</ion-label>
                  </ion-col>
                  <ion-col size="2" class="prod-action-col"></ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
            <ion-infinite-scroll @ionInfinite="loadMore($event)">
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-list>

      </ion-item>

    </ion-content>

  </master-layout>
</template>

<script>
import { arrowBackOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
// import { IonSelect, IonSelectOption, IonCheckbox } from "@ionic/vue";
// import { mapGetters, mapActions } from "vuex";
import { IonInfiniteScroll, IonInfiniteScrollContent, IonSearchbar } from "@ionic/vue";

export default {
  components: { IonInfiniteScroll, IonInfiniteScrollContent, IonSearchbar },
  data() {
    return {
      arrowBackOutline,
      searchText: "",
      productList: []
    }
  },
  computed: {
    pageTitle() {
      let title = '';
      if (this?.$route?.params?.importSource ) {
        title = `${this.$route.params.importSource} import`;
        title = title.charAt(0).toUpperCase() + title.slice(1);
      }
      return title;
    },
    pageHeader() {
      return `Import product from ${this.$route.params.importSource}`;
    }
  },
  mounted() {
    // this.fetchProductsList();
    this.productList = [
      {
        "added": true,
        "name": "test 1",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/avengers-endgame-chris-hemsworth-fat-thor-1200.jpg?v=1666018488",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTAyMTY4NDE=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkwMjE2ODQxLCJsYXN0X3ZhbHVlIjo3MDU5OTkwMjE2ODQxfQ==",
        "reason": "Product is already added.",
        "id": 7059990216841
      },
      {
        "added": true,
        "name": "test 2",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/avengers-pay-to-win.jpg?v=1666018514",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTAzMTUxNDU=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkwMzE1MTQ1LCJsYXN0X3ZhbHVlIjo3MDU5OTkwMzE1MTQ1fQ==",
        "reason": "Product is already added.",
        "id": 7059990315145
      },
      {
        "added": true,
        "name": "test 2",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/download_1.jpg?v=1666018547",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTA0Nzg5ODU=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkwNDc4OTg1LCJsYXN0X3ZhbHVlIjo3MDU5OTkwNDc4OTg1fQ==",
        "reason": "Product is already added.",
        "id": 7059990478985
      },
      {
        "added": true,
        "name": "test 4",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/download_2.jpg?v=1666018612",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTEwNjg4MDk=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxMDY4ODA5LCJsYXN0X3ZhbHVlIjo3MDU5OTkxMDY4ODA5fQ==",
        "reason": "Product is already added.",
        "id": 7059991068809
      },
      {
        "added": true,
        "name": "test 5",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/FYIAFTeUIAEGt4f.jpg?v=1666018630",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTExNjcxMTM=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxMTY3MTEzLCJsYXN0X3ZhbHVlIjo3MDU5OTkxMTY3MTEzfQ==",
        "reason": "Product is already added.",
        "id": 7059991167113
      },
      {
        "added": true,
        "name": "test 6",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/images.jpg?v=1666018648",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTEyNjU0MTc=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxMjY1NDE3LCJsYXN0X3ZhbHVlIjo3MDU5OTkxMjY1NDE3fQ==",
        "reason": "Product is already added.",
        "id": 7059991265417
      },
      {
        "added": true,
        "name": "test 7",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/marveldisney.jpg?v=1666018663",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTEzNjM3MjE=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxMzYzNzIxLCJsYXN0X3ZhbHVlIjo3MDU5OTkxMzYzNzIxfQ==",
        "reason": "Product is already added.",
        "id": 7059991363721
      },
      {
        "added": true,
        "name": "test 8",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/marvel-cinematic-universe-order-1601976266.jpg?v=1666018681",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTE1Mjc1NjE=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxNTI3NTYxLCJsYXN0X3ZhbHVlIjo3MDU5OTkxNTI3NTYxfQ==",
        "reason": "Product is already added.",
        "id": 7059991527561
      },
      {
        "added": true,
        "name": "test 9",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/OpenGraph-TW-1200x630.jpg?v=1666018697",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTE2NTg2MzM=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxNjU4NjMzLCJsYXN0X3ZhbHVlIjo3MDU5OTkxNjU4NjMzfQ==",
        "reason": "Product is already added.",
        "id": 7059991658633
      },
      {
        "added": true,
        "name": "test 10",
        "image": "https://cdn.shopify.com/s/files/1/0603/8362/5353/products/photo-1596727147705-61a532a659bd.jpg?v=1666018714",
        "nodeid": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcwNTk5OTE3MjQxNjk=",
        "cursor": "eyJsYXN0X2lkIjo3MDU5OTkxNzI0MTY5LCJsYXN0X3ZhbHVlIjo3MDU5OTkxNzI0MTY5fQ==",
        "reason": "Product is already added.",
        "id": 7059991724169
      }
    ];
  },
  methods: {
    async searchProduct() {
      console.log(">>>>> ");
      // await this.fetchProductsList();
    },
    async loadMore($ev) {
      await this.fetchProductsList();
      $ev.target.complete();
    },
    async fetchProductsList() {
      try {
        let reqBody = {};
        if (this.searchText) {
          reqBody = { search: this.searchText };
        }

        const response = await jwtInterceptor.post( this.environment("VUE_APP_ROOT") + "shopifylist", reqBody );
        if(response.success) {
          this.generateProductList(response);
        } else {
          throw "error";
        }
        return response;
      } catch (error) {
        this.updateToast({ open: true, message: "Something went wrong while fetching products.", type: "danger" });
      }
    },
    generateProductList(response) {
      this.productList = response.data.products;
    }
  }
}
</script>

<style scoped>
.header-toolbar ion-icon{
  cursor: pointer;
}
ion-grid, ion-list{ width: 100%; }
.image-wrapper {
  width: 50px;
  overflow: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.image-wrapper img {
  border-radius: 5px;
  object-fit: cover;
  height: 50px;
  width: 100%;
}
.content-row ion-col {
  align-items: center;
  display: flex;
}
ion-list-header ion-item { width: 100%; --width: 100%; --min-width: 100%; --max-width: 100%; }
</style>