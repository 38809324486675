<template>
  <ion-item class="shopify-wrapper">

    <ion-grid class="shopify-wrapper-grid">
      <ion-row class="first-row">
        <ion-col size-md="12" size-sm="12" class="left-panel">
          <stepProgress :steps="steps" :propActiveStep="propActiveStep" @onSelectStep="onSelectStep"></stepProgress>
        
          <ion-item v-if="propActiveStep == 0" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">To get started, login to your Magento admin account.</div>
              <div class="margin-next"><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect1.png"></div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 1" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Now go to 'System => Integrations'</div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect2.png"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 2" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Click on 'Add New Integration'</div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect3.png">
              </div>
            </div>
          </ion-item>

          <ion-item v-if="propActiveStep == 3" lines="none">
            <div class="slide-content">
              <div class="slide-header">Enter details to add a new integration and hit the 'Save' button </div>

                <div class="copy-url-container">
                  <div class="copy-url-label">Copy the 'Callback URL' from below and paste it in the form:</div>
                  <ion-item class="copy-url-wrapper" lines="none">
                    <div value="url-text">{{callBackUrl}}</div>
                    <ion-buttons slot="end">
                      <ion-button class="btn-copy-url" @click="copyCallBackUrl" fill="clear">
                        <ion-icon class="ion-no-margin" slot="start" :icon="linkOutline"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>
                </div>

                <div class="copy-url-container">
                  <div class="copy-url-label">Copy the 'Identity link URL' from below and paste it in the form:</div>
                  <ion-item class="copy-url-wrapper" lines="none">
                    <div value="url-text">{{identityUrl}}</div>
                    <ion-buttons slot="end">
                      <ion-button class="btn-copy-url" @click="copyIdentityUrl" fill="clear">
                        <ion-icon class="ion-no-margin" slot="start" :icon="linkOutline"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>
                </div>

              <div class="margin-next">Note: Please do not forget to enter your Magento Admin password before you hit save!</div>
              <div class="margin-next"><img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAwIjVp_sKDA"> </div>
            </div>
          </ion-item>

          <ion-item v-if="propActiveStep == 4" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Your integration has been created successfully! Now click on 'Activate' button to connect Magento with your Jumper account</div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect5.png"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 5" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Please give all API permissions (if any) and click the 'Allow' button </div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect6.png"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 6" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Click the 'Connect' button to integrate Jumper with Magento.</div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect7.png"> </div>
            </div>
          </ion-item>
          <ion-item v-if="propActiveStep == 7" lines="none">
            <div class="slide-content">
              <div class="slide-header margin-next">Your integration has been activated and connection is established with Jumper.</div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect8.png"> </div>
              <div class="slide-header margin-next"> <span ng-bind-html="selectedLangPackData.connect_magento.instruction_line12" class="ng-binding">Once you do the above step, you can now go to your Jumper dashboard</span> =&gt; <a href="/s/connect" class="hoverUnderline ng-binding" ng-bind-html="selectedLangPackData.connect_magento.integrations_page">integrations page</a> <span ng-bind-html="selectedLangPackData.connect_magento.instruction_line13" class="ng-binding">to check the Magento connection.</span> </div>
              <div><img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/web-s-cdn/resources/images/magento-connect/magento-connect9.png"> </div>
            </div>
          </ion-item>

          <ion-item class="action" v-if="propActiveStep < 7" lines="none">
            <ion-buttons slot="end">
              <ion-button v-if="!isStepAtFirst" class="btn-previous ion-no-padding" @click="actionClick(0)"
                fill="outline">
                Previous Step
                <ion-icon slot="start" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="!isStepAtLast" class="btn-next ion-no-padding" @click="actionClick(1)" fill="solid"
                :disabled="isNextButtonDisabled">
                Next Step
                <ion-icon slot="end" :icon="chevronForwardOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

          <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, helpCircleOutline, linkOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { mapGetters } from "vuex";
import stepProgress from "./step-progress.vue"


const steps = [
  {
    title: 'Log into Magento Admin',
    code: 'magento_admin'
  },
  {
    title: 'Go to integrations',
    code: 'integrations'
  },
  {
    title: 'Add new integration',
    code: 'new_integration'
  },
  {
    title: 'Enter general details',
    code: 'general_details'
  },
  {
    title: 'Activate integration',
    code: 'activate_integration'
  },
  {
    title: 'Allow all permissions',
    code: 'permissions'
  },
  {
    title: 'Connect it to Jumper',
    code: 'connect_to_jumper'
  },
  {
    title: 'Done',
    code: 'done'
  }
];

export default {
  components: { stepProgress },
  data() {
    return {
      steps,
      propActiveStep: 0,
      selectedStep: steps[0],
      chevronForwardOutline,
      chevronBackOutline,
      helpCircleOutline,
      linkOutline,
      callBackUrl: "https://jumper.ai/magento/connect/oauth",
      identityUrl: "https://jumper.ai/magento-login",
      form: {
        privateKey: "",
        privatePassword: "",
        storefrontAccessToken: "",
        storeUrl: "",
        isInvalidPrivateKey: "",
        isInvalidPrivatePassword: "",
        isInvalidStorefrontAccessToken: "",
        isInvalidStoreUrl: "",
        invalidInputs: []
      },
      loading: false
    }
  },
  computed: {
    isStepAtFirst() {
      return this.propActiveStep === 0;
    },
    isStepAtLast() {
      return this.steps.length === this.propActiveStep + 1;
    },
    isNextButtonDisabled() {
      return this.propActiveStep == 5 && this.isFormInvalid;
    },
    isFormInvalid() { // used this compute variable to keep next button enabled/disabled
      return this.form.invalidInputs.length;
    }
  },
  methods: {
    copyCallBackUrl() {
      navigator.clipboard.writeText(this.callBackUrl).then(() => {
        this.updateToast({ open: true, message: "Callback url copied successfully.", type: "success" })
      }, function (err) {
        this.updateToast({ open: true, message: "Copy callback url failed.", type: "danger" })
      });
    },
    copyIdentityUrl() {
      navigator.clipboard.writeText(this.callBackUrl).then(() => {
        this.updateToast({ open: true, message: "Identity url copied successfully.", type: "success" })
      }, function (err) {
        this.updateToast({ open: true, message: "Copy identity url failed.", type: "danger" })
      });
    },
    validationHandler({ isInvalid, onChange, inputName }) {
      if (isInvalid) {
        if (!this.form.invalidInputs.includes(inputName)) {
          this.form.invalidInputs.push(inputName);
        }
      } else {
        const index = this.form.invalidInputs.indexOf(inputName);
        if (index > -1) {
          this.form.invalidInputs.splice(index, 1);
        }
      }
      if (onChange) {
        this.form[inputName] = isInvalid;
      }
    },
    onSelectStep({ step, index }) {
      this.propActiveStep = index;
      this.selectedStep = step;
    },
    async actionClick(forward) {

      if (forward && this.propActiveStep < this.steps.length) {
        this.propActiveStep++;
      } else if (this.propActiveStep > 0) {
        this.propActiveStep--;
      }
      this.selectedStep = this.steps[this.propActiveStep];
    }
  }
}
</script>

<style scoped>
.shopify-wrapper-grid {
  height: 100%;
}

.shopify-wrapper-grid .first-row {
  height: 100%;
}

.btn-next {
  width: 160px;
  padding-right: 0;
}

.btn-next button {
  padding-right: 0;
}

.btn-previous {
  width: 160px;
}

.slide-content {
  padding-top: 10px;
}

.margin-next {
  margin-bottom: 10px;
}

.unordered-list ul {
  padding-left: 15px;
}

.action ion-buttons {
  padding: 20px 0;
}

.btn-import-products {
  width: 160px;
}

.slide-content.done {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
}

.slide-content.done .sub-text {
  font-size: 14px;
  color: var(--ion-color-medium);
}

ion-item.error {
  --ion-item-border-color: var(--ion-color-danger);
  --border-color: var(--ion-color-danger);
}

:deep .input-validator-wrapper {
  width: 100%;
}

.copy-url-wrapper {
  color: var(--ion-color-medium);
  background-color: #f5f5f5;
  cursor: not-allowed;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.copy-url-container {
  margin: 20px 0;
}
.copy-url-container .copy-url-label {
  margin-bottom: 5px;
  color: #777;
  font-size: 13px;
}
</style>