<template>
  <div class="segments-module-wrappers ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div class="infoBanner">
          <div class="panel panel-default" style="margin-bottom: 0px; margin-top: 15px;">
            <div class="panel-body">
              <div class="panel-text">Approximate count of target customers according to the conditions you have set!
              </div>
              <ion-button :disabled="loading" @click="initFetchCount" class="btn-refresh" fill="outline">
                <ion-icon :icon="refreshOutline"></ion-icon>
                {{ loading ? 'Calculating...' : 'Refresh Count' }}
              </ion-button>
              <div class="customer-count"><b>The count is:</b> {{ targetedCustomerCount }}</div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>

</template>

<script>
import jwtInterceptor from "/src/shared/jwtInterceptor";
import { refreshOutline } from "ionicons/icons";

export default {
  components: {},
  emits: ["callFetchTargetedCustomerCount"],
  data() {
    return {
      loading: 0,
      refreshOutline,
      targetedCustomerCount: 0
    };
  },
  props: {
    targetingConditionJson: {
      type: Object
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    initFetchCount() {
      this.$emit('callFetchTargetedCustomerCount');
    },
    async fetchTargetedCustomerCount(targetingConditionJson) {
      let errorMessage = '';
      this.loading = true;
      try {
        var reqBody = new FormData();
        // const targetingConditions = this.conditions.map(data => {
        //   return { query: data.query.trim(), operator: data.operator };
        // });
        const targetingConditions = targetingConditionJson;
        reqBody.append('targeting_conditions', JSON.stringify(targetingConditions));
        reqBody.append('sendtag', 'NON_PROMOTIONAL_SUBSCRIPTION');

        let targetedCustomerCountResponse = await jwtInterceptor.post(
          this.environment('VUE_APP_ROOT_API') + "count-target-broadcast-preset",
          reqBody,
          { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" } }
        );

        if ([200, 201].includes(targetedCustomerCountResponse.status) && targetedCustomerCountResponse.data.success) {
          this.targetedCustomerCount = targetedCustomerCountResponse.data.data.total;
        } else {
          errorMessage = targetedCustomerCountResponse?.data?.errorMessage || "Something went wrong please try again";
        }
      } catch (error) {
        console.error("Exception", error);
        errorMessage = "Something went wrong please try again";
      } finally {
        this.loading = false;
        if (errorMessage) {
          this.updateToast({ open: true, message: errorMessage, type: "danger", });
        }
      }
    }
  },
};
</script>

<style scoped>
.target-customer-count-wrapper{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.segment-list-wrapper {
  grid-row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.segment-row {
  display: flex;
  column-gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.segment-name, .segment-condition, .segment-title {
  flex: 1;
  white-space: nowrap;
  background: var(--ion-color-primary);
  border-radius: 5px;
  color: var(--ion-color-primary-contrast);
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.segment-name span, .segment-condition span,  .segment-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.segment-condition {
  max-width: fit-content;
}

.btm-remove-segment {
  position: absolute;
  right: 25px;
  color: var(--ion-color-primary-contrast);
  cursor: pointer;
}
.table-container {
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border-width: 0px;
    font-size: 13px;
    margin: 15px 16px;
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    background: var(--ion-background-color, var(--ion-color-white));
    -webkit-border-radius: 10px;
    overflow: hidden;
}
.table-row {
  border-bottom: 1px solid #EDEDED;
  margin: 21px 0px 21px 17px;
}
.table-row:last-child {
  border-bottom: 0px;
}

.rowMargin {
  margin-bottom: 25px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica,Arial, sans-serif;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.sctn-labels {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color:  var(--ion-color-grey);
}
.radio-bttns {
  margin-right: 10px;
}
.conditionsTitle {
  font-size: 14px;
  color: var(--ion-color-white);
  font-weight: 400;
  background-color: #881fff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.conditionsOperator {
  font-size: 14px;
  color: var(--ion-color-grey);
  font-weight: 400;
  background-color: var(--ion-color-white);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  border: 1px solid var(--ion-color-grey);
}
.uploadBttn {
  border: 1px solid var(--ion-color-black);
  --ion-color-base: var(--ion-color-white) !important;
  height: 35px;
  --box-shadow: none;
}

ion-textarea {
  min-height: 80px;
}
.infoBanner .panel.panel-default{
  width: 100%;
  display: block;
  position: relative;
  background-color: #E1ECFF;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  box-shadow: none;
}
.panel-body{
  padding: 15px;
}
.customer-count{
  padding-top: 10px;
  font-size: 14px;
  color: #262626;
}
.panel-text{
  font-size: 16px;
  color: #262626;
  padding-bottom: 10px;
}
ion-button.btn-refresh{
  --box-shadow: none;
  color: #262626;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.infoBanner .panel .panel-body{
  border-left: 3px var(--primary-theme-color, #3d39b9) solid;
}
.infoBanner.success .panel.panel-default{
  background-color: #eafdf2;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 20px;
}
.chat-toolbar {
  margin-top: 15px;
}
.segment-Info {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin: 15px 0px 10px 20px;
}
.orderSubtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
  margin-top: 25px;
}
.tooltip_container .tooltip_text.left {
  top: 5%;
}
@media only screen and (max-width: 768px) {
  .conditionsEdit {
    margin-bottom: 20px;
  }
}
.element-container{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-template-dynamic-action {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px #eee dashed;
  border-bottom: none;
  height: 40px;
  width: 100% !important;
  color: var(--ion-color-primary);
  cursor: pointer;
}
.img-block .btn-template-dynamic-action {
  border-left: none;
  border-right: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--ion-color-black);
  padding-left: 15px;
  padding-bottom: 5px;
}
.sub-title {
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-grey);
  padding-left: 15px;
}

.sub-text{
  font-size: 16px;
  font-weight: 400;
  color: var(--ion-color-black);
  padding-left: 38px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
}
.upload-img {
  max-width: 453px;
}
.filterImage {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.model-sections {
  background: var(--ion-color-white);
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  width: fit-content;
}
.inpt-container {
  width: 360px;
  margin: 0px 30px 10px 46px;
}
.addRow{
  margin-top: 0;
  margin-bottom: 0;
}
.addRow:hover {
   border: 2px #881fff dashed;
}

.addNew {
  max-width: 450px;
  width: 100%;
}
.addNew.complete {
  border-bottom: 2px #eee dashed;
}
.textarea-box {
  width: 450px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0px 0px;
  background: #eaecf3;
  display: inline-block;
}
.img-block{
   border-radius: 5px;
    border: 2px #eee dashed;
    width: 450px;
    display: inline-block;
}
.jinput {
  background-color: #ffffff;
}
.delete-field{
  position: absolute;
  top: 0;
  right: -40px;
}
@media only screen and (max-width: 760px){
  .model-sections, .element-container {
    width: calc( 100% - 40px );
  }

  .addNew {
    max-width: 100%;
    margin-left: 0px;
  }
  .upload-img {
    max-width: 100%;
    margin-left: 0px;
  }
  .textarea-box{
    width: 100%;
  }
  .img-block{
    width: 100%;
  }
  .inpt-container{
    margin: 0px 10px 10px 10px;
    width: 95%;
  }
  .jinput{
    width: 98%;
  }

}

.tooltip_container .tooltip_text.left {
  top: 5%;
  z-index: 999;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>