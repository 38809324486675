<template>
  <div class="json-builder-action-wrapper">
    <div v-if="loading" class="loading">Loading...</div>
    <div>
      <JsonBuilder :editBroadcast="editBroadcast" @loading="updateLoadingState" @finalJsonElements="onFinalJsonElementsUpdate" ref="JsonBuilderReference"></JsonBuilder>
    </div>
    <div class="actions-container" :class="{ disabled: finalJsonElements.length }">
      <JsonBuildActions :editBroadcast="editBroadcast" @action="actionHandler"></JsonBuildActions>
    </div>
  </div>
</template>

<script>
import JsonBuilder from "./json-builder.vue";
import JsonBuildActions from "./json-builder-actions.vue";
export default {
  components: { JsonBuilder, JsonBuildActions },
  emits: ["finalJson", "finalJsonElements"],
  data() {
    return {
      loading: false,
      finalJsonElements: []
    }
  },
  props: {
    editBroadcast: {
      type: Object
    }
  },
  async ionViewWillEnter(){},
  computed: {},
  methods: {
    actionHandler({actionName}) {
      const {JsonBuilderReference} = this.$refs;
      switch (actionName) {
        case 'AddText':
          JsonBuilderReference.actions().addText();
          break;
        case 'AddImage':
          JsonBuilderReference.actions().addImage();
          break;
        case 'AddVideo':
          JsonBuilderReference.actions().addVideo();
          break;
        case 'AddProduct':
          JsonBuilderReference.actions().addProduct();
          break;
        case 'AddCollection':
          JsonBuilderReference.actions().addCollection();
          break;
        case 'AddQuickReplies':
          JsonBuilderReference.actions().addQuickReplies();
          break;
        case 'AddCustomCard':
          JsonBuilderReference.actions().addCustomCard();
          break;
      }
    },
    onFinalJsonUpdate({ finalJson }) {
      this.$emit('finalJson', { finalJson })
    },
    onFinalJsonElementsUpdate({finalJsonElements}) {
      this.finalJsonElements = finalJsonElements;
      this.$emit('finalJsonElements', { finalJsonElements })
    },
    updateLoadingState({ loading }) {
      this.loading = loading;
    },
    clearElements() {
      this.$refs.JsonBuilderReference.allElements = [];
    }
  }
}
</script>

<style scoped>
.json-builder-action-wrapper {
  position: relative;
}
.actions-container.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>