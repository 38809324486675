<template>
  <master-layout pageTitle="Store">
    <ion-content>
      <ion-refresher
        slot="fixed"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <ion-toolbar class="chat-toolbar">
          <ion-buttons slot="start">
            <ion-icon
              @click="() => $router.go(-1)"
              size="large"
              :icon="arrowBackOutline"
              style="cursor: pointer"
            ></ion-icon>
            <h2 class="prdt-title ion-no-margin">{{ (dummySet.id ? 'Edit' : 'Add') + ' Set' }}</h2>
          </ion-buttons>
          <ion-buttons
            slot="end"
            class="paddingBox"
          >
            <ion-button
              :disabled="checkingHashtag && !validHashtag"
              @click="updateProduct"
              color="white"
              class="bttn-bgnd"
            >Save Set</ion-button>
          </ion-buttons>
        </ion-toolbar>

        <ion-grid>
          <ion-row>
            <!-- left panel -->
            <ion-col
              size-xl="4"
              size-lg="4"
              size-md="4"
              size-sm="12"
              size-xs="12"
              class="ion-no-padding"
            >
              <ion-accordion-group
                value="addset"
                class="set-container"
              >
                <ion-accordion
                  class="model-sections"
                  value="addset"
                >
                  <ion-item
                    slot="header"
                    lines="none"
                  >
                    <ion-text class="order-title">
                      Products
                    </ion-text>
                  </ion-item>
                  <ion-list
                    slot="content"
                    class="ion-no-padding"
                  >
                    <ion-card-header>
                      <div>
                        <div
                          v-if="!dummySet.images || dummySet.images.length === 0"
                          class="custom_ThumbnailBox"
                        >
                          <img
                            src="https://jumper.ai/web-s/resources/images/upload-image-placeholder.png"
                            alt=""
                            class="img-container"
                          >
                        </div>
                        <div v-else>
                          <ion-grid>
                            <ion-row>
                              <ion-col
                                v-for="(thisImage, iIndex) in dummySet.images"
                                :key="iIndex"
                              >
                                <img
                                  :src="thisImage"
                                  alt=""
                                  class="img-container"
                                />
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </div>
                        <div>
                          <input
                            type="file"
                            style="display: none;"
                            id="productImage"
                            ref="productImage"
                            @change="handleImageUpload"
                            accept="image/*"
                          />
                          <ion-button
                            @click="() => $refs.productImage.click()"
                            expand="block"
                            fill="outline"
                          >Upload image</ion-button>
                        </div>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
                <ion-accordion
                  class="model-sections"
                  value="addset"
                >
                  <ion-item
                    slot="header"
                    lines="none"
                  >
                    <ion-text class="order-title">
                      Other Settings
                    </ion-text>
                  </ion-item>
                  <ion-list
                    slot="content"
                    class="ion-no-padding"
                  >
                    <ion-card-header class="ion-no-padding">
                      <ion-grid
                        class="card_container_grid ion-no-padding"
                        style="margin-top: 0px"
                      >
                        <ion-row
                          class="ion-no-padding"
                          style="margin-bottom: 5px"
                        >
                          <ion-col style="padding-top: 20px">
                            <ion-text class="common-label">
                              Visible on store
                            </ion-text>
                          </ion-col>
                          <ion-col class="ion-no-padding text-right">
                            <ion-toggle
                              v-model="dummySet.shopActive"
                              class="cust_toggle"
                            ></ion-toggle>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          row
                          class="ion-no-padding"
                          style="margin-bottom: 5px"
                        >
                          <ion-col style="padding-top: 20px">
                            <ion-text class="common-label">
                              Enable click & collect
                            </ion-text>
                          </ion-col>
                          <ion-col class="ion-no-padding text-right">
                            <ion-toggle class="cust_toggle"></ion-toggle>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>

            <!-- right panel -->
            <ion-col
              size-xl="8"
              size-lg="8"
              size-md="8"
              size-sm="12"
              size-xs="12"
              class="ion-no-padding"
            >
              <ion-accordion-group
                value="addset"
                class="set-container"
              >
                <ion-accordion
                  class="model-sections"
                  value="addset"
                >
                  <ion-item
                    slot="header"
                    lines="none"
                  >
                    <ion-text class="order-title">
                      Set details
                    </ion-text>
                  </ion-item>
                  <ion-list
                    slot="content"
                    class="ion-no-padding"
                  >
                    <ion-card-header>
                      <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Name</ion-card-title>
                        <ion-input
                          type="text"
                          placeholder="Enter product name"
                          v-model="dummySet.name"
                          class="fullBordered input_custom_style sctn-input"
                        ></ion-input>
                      </div>
                      <div
                        v-if="dummySet.id === undefined"
                        class="prdct-section"
                      >
                        <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                        <ion-input
                          type="text"
                          v-model="dummySet.hashtag"
                          @blur="checkHashtag"
                          placeholder="Enter your set's checkout trigger"
                          class="fullBordered input_custom_style"
                          style="border: 1px solid #666666"
                        ></ion-input>
                      </div>
                      <ion-grid
                        v-else
                        class="pad_zero"
                      >
                        <ion-row class="pad_zero">
                          <ion-col
                            size-xl="6"
                            size-lg="6"
                            size-md="6"
                            size-sm="12"
                            size-xs="12"
                          >
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">Checkout trigger</ion-card-title>
                              <div class="pre_post_text_cont">
                                <span class="pre_text">
                                  &#35;
                                </span>
                                <ion-input
                                  type="text"
                                  disabled
                                  v-model="dummySet.hashtag"
                                  placeholder="Enter your set's checkout trigger"
                                  class="fullBordered input_custom_style"
                                  style="border: 1px solid #666666"
                                ></ion-input>
                                <span class="post_text">
                                  &#33;
                                </span>
                              </div>
                            </div>
                          </ion-col>

                          <ion-col
                            size-xl="6"
                            size-lg="6"
                            size-md="6"
                            size-sm="12"
                            size-xs="12"
                          >
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">Set URL</ion-card-title>
                              <a
                                :href="shopLinkConsideringCustomDomain + '/' + dummySet.hashtag"
                                class="hyperlink_cust v_1"
                              >
                                {{ shopLinkConsideringCustomDomain + '/' + dummySet.hashtag }}
                              </a>
                            </div>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                      <div class="prdct-section">
                        <ion-card-title class="sctn-labels">Short Description</ion-card-title>
                        <div>
                          <ion-textarea
                            v-model="dummySet.description"
                            rows="4"
                            placeholder="Enter short description"
                            class="fullBordered input_custom_style sctn-input"
                          ></ion-textarea>
                        </div>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>

                <ion-accordion
                  class="model-sections"
                  value="addset"
                >
                  <ion-item
                    slot="header"
                    lines="none"
                  >
                    <ion-text class="order-title">
                      Stock settings
                    </ion-text>
                  </ion-item>
                  <ion-list
                    slot="content"
                    class="ion-no-padding"
                  >
                    <ion-card-header>
                      <div class="prdct-section">
                        <ion-card-title class="orderDesc">Products</ion-card-title>
                      </div>
                      <div class="text-center">
                        <p v-if="!dummySet.variations.variations || dummySet.variations.variations.length === 0">
                          No products added!
                        </p>
                        <ion-grid
                          v-else
                          class="ion-no-padding"
                        >
                          <ion-row class="ion-justify-content-between">
                            <ion-col>Image</ion-col>
                            <ion-col>Name</ion-col>
                            <ion-col>Stock</ion-col>
                            <ion-col>Quantity</ion-col>
                            <ion-col>Actions</ion-col>
                          </ion-row>
                          <ion-row
                            v-for="thisProduct in dummySet.variations.variations"
                            :key="thisProduct.id"
                            class="table-row ion-no-padding ion-justify-content-between"
                          >
                            <ion-col class="custom_mrg_bottom">
                              <img
                                width="50"
                                v-if="thisProduct.images && thisProduct.images.length"
                                :src="thisProduct.images[0]"
                              />
                            </ion-col>
                            <ion-col class="custom_mrg_bottom">
                              <div>
                                {{ thisProduct.name }}
                              </div>
                              <a
                                :href="shopLinkConsideringCustomDomain + '/' + thisProduct.hashtag"
                                class="hyperlink_cust v_1"
                              >
                                {{ '#' + thisProduct.hashtag }}
                              </a>
                            </ion-col>
                            <ion-col class="custom_mrg_bottom">
                              {{ thisProduct.stock }}
                            </ion-col>
                            <ion-col class="custom_mrg_bottom">
                              <ion-input
                                @change="updateStock"
                                v-model="thisProduct.quantity"
                                type="text"
                                placeholder="Quantity"
                                class="fullBordered input_custom_style"
                              ></ion-input>
                            </ion-col>
                            <ion-col class="custom_mrg_bottom">
                              <ion-button
                                @click="removeProductFromCategory(thisProduct)"
                                fill="clear"
                                size="default"
                                color="danger"
                              >
                                <ion-icon :icon="trashOutline"></ion-icon>
                              </ion-button>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                        <ion-button
                          @click="() => addProductsModal = true"
                          expand="block"
                          fill="outline"
                        >Add Products</ion-button>
                      </div>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>

                <ion-accordion
                  class="model-sections"
                  value="addset"
                >
                  <ion-item
                    slot="header"
                    lines="none"
                  >
                    <ion-text class="order-title">
                      Other settings
                    </ion-text>
                  </ion-item>
                  <ion-list
                    slot="content"
                    class="ion-no-padding"
                  >
                    <ion-card-header>
                      <ion-grid>
                        <ion-row>
                          <ion-col size="6">
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">Stock</ion-card-title>
                              <ion-input
                                disabled
                                type="text"
                                v-model="dummySet.stock"
                                placeholder="500"
                                class="fullBordered input_custom_style sctn-input"
                              ></ion-input>
                            </div>
                          </ion-col>
                          <ion-col size="6">
                            <div class="prdct-section">
                              <ion-card-title class="sctn-labels">SKU</ion-card-title>
                              <ion-input
                                type="text"
                                placeholder="SKU"
                                v-model="dummySet.sku"
                                class="fullBordered input_custom_style sctn-input"
                              ></ion-input>
                            </div>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col class="sctn-labels">Allow customer to buy more than one quantity of this set per order</ion-col>
                          <ion-col class="text-right">
                            <ion-checkbox v-model="dummySet.askquantity"></ion-checkbox>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          v-if="dummySet.askquantity"
                          class="prdct-section"
                        >
                          <ion-col class="sctn-labels">Maximum quantity per order</ion-col>
                          <ion-col class="text-right">
                            <ion-input
                              type="text"
                              v-model="dummySet.taxpercent"
                              placeholder="Enter Maximum quantity per order"
                              class="fullBordered input_custom_style"
                              style="width: 125px"
                            ></ion-input>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          v-if="dummySet.askquantity"
                          class="prdct-section"
                        >
                          <ion-col class="sctn-labels">Maximum quantity per customer</ion-col>
                          <ion-col class="text-right">
                            <ion-input
                              type="text"
                              v-model="dummySet.customer_quantity_limit"
                              placeholder="Enter Maximum quantity per customer"
                              class="fullBordered input_custom_style"
                              style="width: 125px"
                            ></ion-input>
                          </ion-col>
                        </ion-row>
                        <ion-row class="prdct-section">
                          <ion-col class="sctn-labels">Allow customer to purchase items seperately from set</ion-col>
                          <ion-col class="text-right">
                            <ion-checkbox v-model="chooseProducts"></ion-checkbox>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          v-if="chooseProducts"
                          class="prdct-section"
                        >
                          <ion-col class="sctn-labels">Minimum number of items to purchase</ion-col>
                          <ion-col class="text-right">
                            <ion-input
                              type="text"
                              v-model="dummySet.quantity_limit"
                              placeholder="Enter number"
                              class="fullBordered input_custom_style"
                              style="width: 125px"
                            ></ion-input>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-card-header>
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-modal
      v-if="addProductsModal"
      :is-open="addProductsModal"
      @didDismiss="() => {addProductsModal = false; productFilter = undefined;}"
    >
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <h2 class="custom_mrg_left">Select Products</h2>
            <ion-buttons slot="end">
              <ion-button @click="() => {addProductsModal = false; productFilter = undefined;}">
                <ion-icon
                  slot="icon-only"
                  :icon="close"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <div class="cust_pad_v1">
            <ion-input
              v-model="productFilter"
              type="text"
              placeholder="Enter product name"
              class="fullBordered cust_input_hght input-width"
            ></ion-input>
          </div>
          <ion-grid class="card_container_grid ion-no-padding">
            <ion-row
              v-for="thisProduct in productsToShow"
              :key="thisProduct.id"
              class="rowMargin"
            >
              <ion-col size="2">
                <img
                  width="50"
                  v-if="thisProduct.images && thisProduct.images.length"
                  :src="thisProduct.images[0]"
                />
              </ion-col>
              <ion-col
                size="6"
                class="ion-align-self-center"
              >
                <h2 class="common-label ion-no-margin custom_mrg_left"> {{ thisProduct.name }} </h2>
                <h2 v-if="thisProduct.variation" class="danger-label ion-no-margin" >Sorry! You can't add this product as it contains variations</h2>
              </ion-col>
              <div
                v-if="!thisProduct.variation"
                class="addbttn"
              >
                <ion-button
                  v-if="dummySet.variations.variations.findIndex(e => e.id === thisProduct.id) > -1"
                  @click="() => dummySet.variations.variations.splice(dummySet.variations.variations.findIndex(e => e.id === thisProduct.id), 1)"
                  color="danger"
                >Remove</ion-button>
                <ion-button
                  v-else
                  @click="() => dummySet.variations.variations.push(thisProduct)"
                  color="dark"
                >Add</ion-button>
              </div>
            </ion-row>
          </ion-grid>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="fxd_btm_block">
            <ion-button
              @click="() => {addProductsModal = false; productFilter = undefined;}"
              color="dark"
            >Done</ion-button>
          </ion-toolbar>
        </ion-footer>
      </ion-page>
    </ion-modal>
  </master-layout>
</template>

<script>
import {
  chevronDownCircleOutline,
  close,
  trashOutline,
  arrowBackOutline,
} from "ionicons/icons";
import {
  IonTextarea,
  IonToggle,
  IonAccordion,
  IonAccordionGroup,
  IonCheckbox,
  alertController,
} from "@ionic/vue";
import jwtInterceptor from "/src/shared/jwtInterceptor";

export default {
  components: {
    IonTextarea,
    IonToggle,
    IonAccordion,
    IonAccordionGroup,
    IonCheckbox,
  },
  data() {
    return {
      chevronDownCircleOutline,
      close,
      trashOutline,
      arrowBackOutline,
      loading: false,
      productFilter: undefined,
      chooseProducts: false,
      checkingHashtag: false,
      validHashtag: true,
      dummySet: {
        weight_si_unit: "kg",
        woocommerce: false,
        hashtags: undefined,
        tax: false,
        currency: undefined,
        cost: undefined,
        totalcost: undefined,
        magento: false,
        length_si_unit: "cm",
        images: [],
        lowername: false,
        taxpercent: undefined,
        id: undefined,
        category: "",
        isbundle: false,
        shopify: false,
        lead: false,
        inventory_policy: "jumper",
        online_event_url: undefined,
        event: "False",
        live: false,
        version: null,
        stock: undefined,
        status: "published",
        sku: null,
        selloffline: false,
        mockuprequired: false,
        description: undefined,
        tags: false,
        skipaddress: false,
        amazon: false,
        hashtag: undefined,
        active: false,
        customer_quantity_limit: undefined,
        sellergetcost: false,
        quantity_limit: undefined,
        name: undefined,
        created: undefined,
        notes: false,
        askquantity: false,
        variations: {
          totalcost: null,
          sku: null,
          sellergetcost: undefined,
          name: undefined,
          tags: [],
          splitpayamount: undefined,
          attribute: undefined,
          image: undefined,
          description: undefined,
          variations: [],
          shippingCost: undefined,
          grams: undefined,
          height: undefined,
          width: undefined,
          length: undefined,
          cost: undefined,
          relation: [],
          length_si_unit: "cm",
          tax: undefined,
          id: undefined,
          stock: undefined,
        },
        skipemailverification: false,
        shopActive: false,
        cash_on_delivery: false,
        printful: false,
        bigcommerce: false,
        grams: 0,
        height: 0,
        width: 0,
        length: 0,
        printfulmockup: false,
        skipemail: false,
      },
      allProducts: [],
      addProductsModal: false,
      imageChanged: false,
    };
  },
  async ionViewWillEnter() {
    await this.fetchProducts();
    if (this.$route.query && this.$route.query.setid) await this.init();
    this.dummySet.currency = this.user.currency;
  },
  computed: {
    productsToShow() {
      return this.productFilter && this.productFilter.length
        ? this.allProducts.filter((e) =>
            e.name.match(new RegExp(this.productFilter, "gi"))
          )
        : this.allProducts;
    },
  },
  watch: {
    chooseProducts() {
      if (this.chooseProducts === false)
        this.dummySet.quantity_limit =
          this.dummySet.variations.variations.length;
    },
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        let reqBody = {
          catalog: { id: this.$route.query.setid },
        };
        let editSetResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-catalogue",
          reqBody
        );

        if (
          (editSetResponse.status == 200 || editSetResponse.status == 201) &&
          editSetResponse.data.success
        ) {
          this.dummySet = editSetResponse.data;
          if (this.dummySet.quantity_limit) this.chooseProducts = true;
          if (
            this.dummySet.variations?.variations?.length ===
            this.dummySet.quantity_limit
          )
            this.chooseProducts = false;
          let variationQuantityLimits = [];
          if (
            this.dummySet.online_event_url &&
            this.dummySet.online_event_url.length
          )
            variationQuantityLimits = this.dummySet.online_event_url.split(",");
          if (
            variationQuantityLimits.length &&
            this.dummySet.variations?.variations?.length
          )
            this.dummySet.variations.variations.forEach((e) => {
              let temp = variationQuantityLimits
                .find((variation) => variation.indexOf(e.id) > -1)
                ?.split("=");
              e.quantity = temp && temp.length === 2 ? temp[1] : undefined;
            });
        } else {
          if (
            editSetResponse.data &&
            !editSetResponse.data.success &&
            editSetResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: editSetResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
            //console.log("Response:", editSetResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    updateStock() {
      let stocksArr = this.dummySet.variations?.variations.map(
        (e) => parseInt(e.stock) / parseInt(e.quantity)
      );
      console.log(stocksArr);
      this.dummySet.stock = parseInt(Math.min(...stocksArr));
    },
    async checkHashtag() {
      try {
        if (!this.dummySet.hashtag || this.dummySet.hashtag.length === 0) {
          this.updateToast({
            open: true,
            message: "Please enter a valid input for hashtag!",
            type: "warning",
          });
          return;
        }
        this.checkingHashtag = true;
        this.validHashtag = false;

        let checkHashtagResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "check-hashtag",
          { hashtag: this.dummySet.hashtag }
        );

        if (
          (checkHashtagResponse.status == 200 ||
            checkHashtagResponse.status == 201) &&
          checkHashtagResponse.data.success
        ) {
          this.checkingHashtag = false;
          if (checkHashtagResponse.data.valid) this.validHashtag = true;
          else this.validHashtag = false;
        } else {
          if (
            checkHashtagResponse.data.errorMessage &&
            checkHashtagResponse.data.errorMessage.length
          ) {
            this.updateToast({
              open: true,
              message: checkHashtagResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger",
        });
      }
    },
    async handleImageUpload(event) {
      try {
        this.loading = true;
        if (event.target.files && event.target.files.length) {
          let newImage = event.target.files[0];
          if (await this.validateImage(newImage, 4)) {
            let uploadSendResponseData = await this.uploadImage(
              newImage,
              "/add-image"
            );
            if (
              uploadSendResponseData.image &&
              uploadSendResponseData.image.length
            ) {
              this.dummySet.images[0] = uploadSendResponseData.url;
              this.imageChanged = true;
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong uploading the picture",
                type: "danger",
              });
            }
          } else {
            console.log("Invalid picture");
            this.updateToast({
              open: true,
              message: "Invalid picture",
              type: "danger",
            });
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
      } finally {
        this.loading = false;
      }
    },
    async updateProduct() {
      try {
        this.loading = true;
        let formError = false;
        if (!this.dummySet.name || !this.dummySet.name.length) {
          this.updateToast({
            open: true,
            message: "Please add a valid name",
            type: "warning",
          });
          formError = true;
          return;
        }
        if (!this.dummySet.hashtag || !this.dummySet.hashtag.length) {
          this.updateToast({
            open: true,
            message: "Please add a valid hashtag",
            type: "warning",
          });
          formError = true;
          return;
        }
        if (!this.dummySet.images || !this.dummySet.images.length) {
          this.updateToast({
            open: true,
            message: "Please add an image to the set",
            type: "warning",
          });
          formError = true;
          return;
        }
        let cost = 0;
        if (this.dummySet.variations?.variations?.length) {
          this.dummySet.variations?.variations.forEach((thisVariation) => {
            if (!thisVariation.quantity) {
              this.updateToast({
                open: true,
                message: "Add quantities for all the products added!",
                type: "warning",
              });
              formError = true;
              return;
            } else {
              cost += parseFloat(thisVariation.totalcost);
            }
          });
        } else {
          this.updateToast({
            open: true,
            message: "Add at least one product",
            type: "warning",
          });
          formError = true;
          return;
        }
        let reqBody = {
          catalog: {
            id: this.dummySet.id,
            name: this.dummySet.name,
            hashtag: this.dummySet.hashtag,
            bundle: "true",
          },
        };
        reqBody.catalog["products"] = this.dummySet.variations.variations
          .map((e) => e.id)
          .join(",");
        if (!formError) {
          let addCatalogueResponse = await jwtInterceptor.post(
            this.environment("VUE_APP_ROOT_API") + "add-catalogue",
            reqBody
          );
          if (
            (addCatalogueResponse.status == 200 ||
              addCatalogueResponse.status == 201) &&
            addCatalogueResponse.data.success
          ) {
            reqBody = {
              productid: addCatalogueResponse.data.id,
              description: this.dummySet.description,
              skipaddress: this.dummySet.skipaddress.toString(),
              selloffline: this.dummySet.selloffline.toString(),
              skipemail: this.dummySet.skipemail.toString(),
              shopActive: this.dummySet.shopActive.toString(),
              quantity_limit: this.dummySet.quantity_limit?.toString(),
              stock: this.dummySet.stock?.toString(),
              sku: this.dummySet.sku?.toString(),
              grams: this.dummySet.grams?.toString(),
              height: this.dummySet.height?.toString(),
              width: this.dummySet.width?.toString(),
              length: this.dummySet.length?.toString(),
              askquantity: this.dummySet.askquantity.toString(),
              taxpercent: this.dummySet.taxpercent?.toString(),
              customer_quantity_limit:
                this.dummySet.customer_quantity_limit?.toString(),
              cost: cost.toString(),
              totalcost: cost.toString(),
              image_url:
                this.imageChanged &&
                this.dummySet.images &&
                this.dummySet.images.length &&
                this.dummySet.images[0] &&
                this.dummySet.images[0].includes(
                  this.environment('VUE_APP_ROOT') + "jump-image/"
                )
                  ? this.dummySet.images[0].substring(
                      this.dummySet.images[0].lastIndexOf("/") + 1
                    )
                  : undefined,
              currency: this.dummySet.currency,
              tax: "0",
              splitpayamount: "0",
              sellergetcost: "0",
              cash_on_delivery: "false",
              passfees: "false",
              calendarinvite: "false",
            };

            let online_event_url = [];
            if (this.dummySet.variations?.variations?.length) {
              this.dummySet.variations?.variations.forEach((thisVariation) => {
                online_event_url.push(
                  thisVariation.id + "=" + thisVariation.quantity
                );
              });
            }
            if (online_event_url && online_event_url.length)
              reqBody.online_event_url = online_event_url.join(",");

            if (this.dummySet.hashtags && this.dummySet.hashtags.length)
              reqBody.hashtags = this.dummySet.hashtags.join(",");
            else reqBody.hashtags = undefined;

            let productSaveResponse = await jwtInterceptor.post(
              this.environment("VUE_APP_ROOT_API") + "add-product",
              reqBody
            );
            if (
              (productSaveResponse.status == 200 ||
                productSaveResponse.status == 201) &&
              productSaveResponse.data.success
            ) {
              this.updateToast({
                open: true,
                message: "Product updated successfully!",
                type: "success",
              });
              /* for (var prop in this.dummySet)
                this.dummySet[prop] = productSaveResponse.data[prop]; */
              this.$router.replace({ path: '/store', query: { tab: 'sets' }});
            } else {
              if (
                productSaveResponse.data.errorMessage &&
                productSaveResponse.data.errorMessage.length
              ) {
                this.updateToast({
                  open: true,
                  message: productSaveResponse.data.errorMessage,
                  type: "danger",
                });
              } else {
                this.updateToast({
                  open: true,
                  message: "Something went wrong",
                  type: "danger",
                });
              }
            }
          } else {
            if (
              addCatalogueResponse.data.errorMessage &&
              addCatalogueResponse.data.errorMessage.length
            ) {
              this.updateToast({
                open: true,
                message: addCatalogueResponse.data.errorMessage,
                type: "danger",
              });
            } else {
              this.updateToast({
                open: true,
                message: "Something went wrong",
                type: "danger",
              });
            }
          }
        }
      } catch (error) {
        console.log("Exception: ", error);
        this.updateToast({
          open: true,
          message: "Something went wrong!",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      try {
        this.loading = true;
        let fetchProductsResponse = await jwtInterceptor.post(
          this.environment("VUE_APP_ROOT_API") + "get-shop-list"
        );
        console.log(fetchProductsResponse);
        /* bundle: false, catalogue: false */

        if (
          (fetchProductsResponse.status == 200 ||
            fetchProductsResponse.status == 201) &&
          fetchProductsResponse.data.success
        ) {
          this.allProducts = fetchProductsResponse.data.products;
          this.allProducts = this.allProducts.filter(
            (e) => !e.bundle && !e.catalog
          );
        } else {
          if (
            fetchProductsResponse.data &&
            !fetchProductsResponse.data.success &&
            fetchProductsResponse.data.errorMessage
          ) {
            this.updateToast({
              open: true,
              message: fetchProductsResponse.data.errorMessage,
              type: "danger",
            });
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger",
            });
            //console.log("Response:", fetchProductsResponse);
          }
        }
      } catch (error) {
        console.log("Exception", error);
      } finally {
        this.loading = false;
      }
    },
    async confirmAlertHandler(onOkay, productToRemoveName) {
      const alert = await alertController.create({
        header: `Do you want to remove product ${productToRemoveName}?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: () => {
              onOkay();
            },
          },
        ],
      });
      await alert.present();
    },
    async removeProductFromCategory(thisProduct) {
      const onOkay = () => {
        this.dummySet.variations.variations.splice(
          this.dummySet.variations.variations.findIndex(
            (e) => e.id === thisProduct.id
          ),
          1
        );
      };
      this.confirmAlertHandler(onOkay, thisProduct?.name);
    },
  },
};
</script>

<style scoped>
.card_container_grid {
  margin: 20px;
}

.sctn-labels {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-grey);
}
.sctn-input {
  border-radius: 5px;
}

.table-row {
  border-bottom: 1px solid #ededed;
  margin: 21px 19px;
}
.img-container {
  width: 50%;
  margin-right: auto;
  display: block;
  margin-left: auto;
  border-radius: 5px;
}
.model-sections {
  background: var(--ion-color-white);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 10px;
}

.addbttn {
  margin: 10px 0px 0px 10px;
}
.descContainer {
  border-top: 1px solid #ededed;
  padding: 20px;
}

.danger-label{
  font-size: 12px;
  color: red;
  margin: 5px 0px 0px 10px;
}

@media only screen and (min-width: 760px) {
  .set-container {
    margin: 5px 20px 5px 5px;
  }
}

@media only screen and (max-width: 760px) {
  .table-row {
    border-bottom: 1px solid #ededed;
    margin: 21px 0px;
  }
}
</style>