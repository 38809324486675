<template>
<master-layout pageTitle="Instagram">
	<ion-loading
	v-if="loading"
	:is-open="loading"
	cssClass="my-custom-class"
	message="Loading..."
	></ion-loading>
	<div v-else>
		<ion-grid v-if="instagramConnected">
      <h2 class="title ion-no-margin" style="padding-left: 25px">Instagram</h2>
      <ion-row>
        <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
          <ion-card class="cardContainer">
            <ion-item lines="none" router-link="/sales-channel/instagram/business-settings">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/landing/resources/images/instagram.png" />
              </ion-thumbnail>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin">Business settings</h2>
                <p class="cardSubtitle ion-no-padding">Manage your Instagram account settings</p>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
        <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
          <ion-card class="cardContainer">
            <ion-item lines="none" router-link="/sales-channel/instagram/ice-breakers">
              <ion-thumbnail slot="start">
                <img src="https://jumper.ai/web-s/resources/images/sc-ice-breakers.svg" />
              </ion-thumbnail>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin"> Ice Breakers</h2>
                <p class="cardSubtitle ion-no-padding">Ice Breakers provide a way for users to start a conversation with a list of frequently asked questions</p>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
        <ion-col size-xl="6" size-lg="6" size-sm="12" size-xs="12">
          <ion-card class="cardContainer">
            <ion-item lines="none" router-link="/sales-channel/instagram/notification-optin">
              <ion-icon :icon="notificationsOutline" size="large" class="customIcon"></ion-icon>
              <ion-label>
                <h2 class="cardTitle ion-no-padding ion-no-margin"> Opt-in notifications</h2>
                <p class="cardSubtitle ion-no-padding">Create and manage opt-in notifications to messages to your customers at any time within their subscription period</p>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
		<div v-else>
      <ion-card class="container">
        <ion-card-header>
          <ion-item lines="none">
						<ion-thumbnail slot="start">
							<img src="https://jumper.ai/landing/resources/images/instagram.png" />
						</ion-thumbnail>
						<ion-label>
              <ion-card-title style="margin-bottom: 5px">Instagram</ion-card-title>
							<ion-card-subtitle>Manage Instagram Direct Messages (DM) within {{ dashboardName }} to support your customers, identify leads and drive commerce all within Instagram DM.</ion-card-subtitle>
						</ion-label>
					</ion-item>
        </ion-card-header>

        <ion-card-content>
          <ion-button @click="connectInstagram" fill="outline" expand="block" class="bttn">Connect</ion-button>
          <p class="smallHintText">
            <span>By connecting your account, you agree to Facebook's </span>
            
            <a class="hoverUnderline" href="https://www.facebook.com/legal/commerce_product_merchant_agreement" target="_blank" rel="noreferer noopener">terms and conditions</a>. 
            <span>You need to follow Facebook's </span>
            
            <a class="hoverUnderline" href="https://www.facebook.com/legal/customer_support_policy" target="_blank" rel="noreferer noopener">customer support</a> 
            <span> and </span>
            
            <a class="hoverUnderline" href="https://www.facebook.com/payments_terms/commerce_salespolicy" target="_blank" rel="noreferer noopener">delivery &amp; return</a> 
            <span> policies when selling on Facebook.</span>
          </p>
        </ion-card-content>
      </ion-card>
		</div>
	</div>
</master-layout>
</template>

<script>
import jwtInterceptor from '/src/shared/jwtInterceptor';
import { notificationsOutline } from 'ionicons/icons';

export default {
  data() {
    return {
      notificationsOutline,
      loading: false,
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.callback && this.$route.query.callback === 'connect') {
      this.loading = true;
      this.connectPage();
    }
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    async connectPage() {
      try {
        this.loading = true;
        let addPageResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "add-instagram-page");
				if ((addPageResponse.status == 200 || addPageResponse.status == 201) && addPageResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Your Instagram is now connected!",
            type: "success"
          })
          await this.Storage.remove({ key: 'jmpr_fetchSocialData' });
          window.location.href = window.location.pathname;
        }
        else {
          if(addPageResponse.data.errorMessage && addPageResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: addPageResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    connectInstagram() {
      console.log("Connecting Instagram");
      let fbScopePermission = this.fbScopePermission;
      let fbScopePermissionWithIg = fbScopePermission + ",instagram_basic,instagram_manage_messages,instagram_manage_comments";
      window.location.href = "https://www.facebook.com/dialog/oauth?client_id=" + this.environment('VUE_APP_FB_APP_ID') + "&auth_type=rerequest&display=popup&response_type=token&granted_scopes&scope=" + fbScopePermissionWithIg + "&redirect_uri=" + this.environment('VUE_APP_FE') + "sales-channel/instagram?callback=connect";
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  color: var(--ion-color-black);
  margin-top: 15px;
}
.cardContainer {
  min-height: 98px;
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
}
.cardTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-black);
}

.container {
  border-radius: 10px;
  border-radius: 10px;
  max-width: 700px;
  margin: 50px auto 20px auto;
  text-align: center;
}

.bttn {
  max-width: 300px;
  margin: auto;
  margin-bottom: 20px;
}
.cardSubtitle{
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-black);
  margin-top: 4px;
}
</style>