<template>
  <div v-if="image" class="image-wrapper" :style="style" >
    <img :src="image" @error="replaceByDefault" />
  </div>
</template>

<script>

export default {
  props:{
    image: {
      type: String,
      default: "https://jumper.ai/web-s/resources/images/add-product.png"
    },
    width: {
      type: String,
      default: "50px"
    },
    height: {
      type: String,
      default: "50px"
    }
  },
  data: () => { 
    return {
      style: {},
      imageToLoad: ""
    }
  },
  mounted() {
    this.style = { width: this.width, height: this.height};
    this.imageToLoad = this.image;
  },
  methods: {
    replaceByDefault() {
      this.imageToLoad = "https://jumper.ai/web-s/resources/images/add-product.png";
    }
  }
}
</script>

<style>
.image-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image-wrapper img {
  border-radius: 5px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
