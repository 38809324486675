<template>
  <master-layout pageTitle="Facebook Ice Breakers">
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          :pulling-icon="chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>
      <ion-loading
        v-if="loading"
        :is-open="loading"
        cssClass="my-custom-class"
        message="Loading..."
      ></ion-loading>
      <div v-else>
        <div v-if="changed">
          <ion-item>
            <p class="connectNote" style="color: #aaa; font-size: 13px; padding-top: 2px;">
              <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
              You have pending changes to be saved, please click on "Save all changes" button!
            </p>
            <ion-button slot="end" @click="saveChanges" color="dark">Save</ion-button>
          </ion-item>
        </div>
        <div class="text-center" v-if="allIceBreakers.length == 0" style="width: 100%; padding: 15px;">
          <img src="https://jumper.ai/web-s/resources/images/no-customer-image.svg" style="max-width: 200px; width: 100%; height: auto; display: block; margin: 0 auto;" />
          <h5>No ice-breakers yet! 😟</h5>
          <ion-text color="medium">Seems like there are no ice-breakers added yet</ion-text>
        </div>
        <div v-if="allIceBreakers.length > 0">
          <ion-list class="ion-no-padding">
            <ion-item v-for="(thisBreaker, bIndex) in allIceBreakers" :key="bIndex">
              <div @click="() => { addEditModal = 'detailed'; editIndex = bIndex; }">{{ thisBreaker.question }}</div>
              <ion-icon @click="() => allIceBreakers.splice(bIndex, 1)" slot="end" :icon="trashBinOutline"></ion-icon>
            </ion-item>
          </ion-list>
        </div>
        <ion-button v-if="allIceBreakersClone.length > 0" slot="end" @click="() => deleteAllMode = true" color="dark">
          <ion-icon :icon="trashBinOutline"></ion-icon>
          &nbsp;Delete All
        </ion-button>
        <button type="button" class="submitBtn addNewBttn" id="addBtn" @click="() => { addEditModal = 'detailed'; editIndex = -1 }" style="position: fixed; bottom: 30px; right: 30px; z-index: 2; width: 50px; height: 50px; border-radius: 50%; background-color: black;">
          <ion-icon class="addIcon" slot="icon-only" :icon="addCircleOutline"></ion-icon>
        </button>
      </div>
    </ion-content>
    <popper v-if="isPopperOpen" is_agent_or_group_req :type="addEditModal" :config="popperConfig" @close="() => {editIndex = -1; addEditModal = undefined;}" @submit="popperSubmit" />
    <ion-alert
      v-if="deleteAllMode"
      :is-open="deleteAllMode"
      header="Are you sure?"
      :message="'You want to delete all the ice-breakers?'"
      :buttons="[{
        text: 'Cancel',
        role: 'cancel',
        handler: () => deleteAllMode = false,
      },
      {
        text: 'Yes',
        handler: () => {
          deleteAll();
          deleteAllMode = false;
        },
      }]"
      @didDismiss="() => deleteAllMode = false"
    >
    </ion-alert>
  </master-layout>
</template>

<script>
import { chevronDownCircleOutline, addCircleOutline, trashBinOutline } from 'ionicons/icons';
import jwtInterceptor from '/src/shared/jwtInterceptor';
import popper from '/src/components/popper';

export default {
  components: {popper},
  data() {
    return {
      loading: false,
      chevronDownCircleOutline,
      addCircleOutline,
      trashBinOutline,
      allIceBreakers: [],
      allIceBreakersClone: [],
      editIndex: -1,
      addEditModal: undefined,
      deleteAllMode: false
    }
  },
  async ionViewWillEnter(){
    if(this.facebookConnected && this.facebookSocialData.pages && this.facebookSocialData.pages.length && this.facebookSocialData.pages[0].id) this.init();
    else this.$router.push({ path: '/sales-channel/facebook' });
  },
  computed: {
    isPopperOpen() {
      return this.addEditModal === 'simple' || this.addEditModal === 'detailed';
    },
    popperConfig() {
      let text = undefined; let action = undefined; let payload = undefined;
      if(this.editIndex > -1) {
        text = this.allIceBreakers[this.editIndex].question;
        payload = this.allIceBreakers[this.editIndex].payload;
      }
      return { text: text, action: action, payload: payload }
    },
    changed() {
      return JSON.stringify(this.allIceBreakers) !== JSON.stringify(this.allIceBreakersClone)
    }
  },
  methods: {
    async doRefresh(event) {
      this.init();
      event.target.complete();
    },
    async init() {
      await this.fetchIceBreakers();
    },
    async fetchIceBreakers() {
      try {
        this.loading = true;
        const allIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + "get-facebook-icebreakers", { pageid: this.facebookSocialData.pages[0].messenger });
        if ((allIceBreakersResponse.status == 200 || allIceBreakersResponse.status == 201) && allIceBreakersResponse.data.success) {
          this.allIceBreakers = allIceBreakersResponse.data.ice_breakers;
          this.allIceBreakersClone = JSON.parse(JSON.stringify(this.allIceBreakers));
        } else {
          if(allIceBreakersResponse.data.errorMessage && allIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: allIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    popperSubmit(data) {
      let question = data.text; let payload = undefined;
      switch(data.action) {
        case 'productFlow': payload = '#' + data.payload;
        break;
        case 'collectionFlow': payload = '#' + data.payload;
        break;
        case 'botFlow': payload = data.payload;
        break;
        case 'agent_or_group': payload = '7@1k704um@9 ___' + data.payload;
        break;
        case 'livechat': payload = '7@1k704um@9';
        break;
      }
      if(this.editIndex > -1) {
        this.allIceBreakers[this.editIndex].question = question;
        this.allIceBreakers[this.editIndex].payload = payload;
        this.editIndex = -1;
      } else this.allIceBreakers.push({ question: question, payload: payload });
      this.addEditModal = undefined;
    },
    async saveChanges() {
      try {
        this.loading = true;
        let reqBody = {
          pageid: this.facebookSocialData.pages[0].messenger,
          breakers: JSON.stringify(this.allIceBreakers)
        }
        let saveIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'facebook-icebreakers', reqBody);
        if ((saveIceBreakersResponse.status == 200 || saveIceBreakersResponse.status == 201) && saveIceBreakersResponse.data.success) {
          this.init();
        } else {
          if(saveIceBreakersResponse.data.errorMessage && saveIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
      }
    },
    async deleteAll() {
      try {
        this.loading = true;
        let reqBody = {
          pageid: this.facebookSocialData.pages[0].messenger
        }
        let saveIceBreakersResponse = await jwtInterceptor.post(this.environment('VUE_APP_ROOT_API') + 'delete-facebook-icebreakers', reqBody);
        if ((saveIceBreakersResponse.status == 200 || saveIceBreakersResponse.status == 201) && saveIceBreakersResponse.data.success) {
          this.updateToast({
            open: true,
            message: "Successfully deleted all the Ice-Breakers for this Facebook Page",
            type: "succcess"
          })
          this.allIceBreakers = [];
          this.allIceBreakersClone = [];
        } else {
          if(saveIceBreakersResponse.data.errorMessage && saveIceBreakersResponse.data.errorMessage.length) {
            this.updateToast({
              open: true,
              message: saveIceBreakersResponse.data.errorMessage,
              type: "danger"
            })
          } else {
            this.updateToast({
              open: true,
              message: "Something went wrong",
              type: "danger"
            })
          }
        }
      } catch (error) {
        console.log("Exception", error);
        this.updateToast({
          open: true,
          message: "Something went wrong",
          type: "danger"
        })
      } finally {
        this.loading = false;
        this.deleteAllMode = false;
      }
    }
  }
}
</script>