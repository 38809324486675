<template>
  <master-layout v-if="!isEnterprise" pageTitle="Pricing">
    <ion-loading v-if="loading" :is-open="loading" cssClass="my-custom-class" message="Loading..."></ion-loading>
    <div v-else>
      <div class="plans-container">
        <div class="plans-header">
          <h1 class="ion-no-margin title">Manage your plan</h1>
          <p class="ion-no-margin sub-title">Upgrade, add-on or downgrade your plan.</p>
        </div>
        <div class="plans-cards">
          <ion-grid>
            <ion-row class="rowMargin">
              <ion-col>
                <div class="plans-cards-container">
                  <div class="plans-cards-header">
                    <div class="plans-card-desc">
                      <div class="sms-img">
                        <img src="../../resources/images/SMS-Notify.svg" alt="sms-img" class="smsImg" />
                      </div>
                      <div class="help-info">
                        <h2 class="help-title ion-no-margin">Notify</h2>
                        <p class="help-subtitle ion-no-margin">Reach customers at the right touchpoint.</p>
                      </div>
                    </div>
                  </div>
                  <div class="plans-cards-desc">
                    <div class="plans-cards-features">
                      <ion-item lines="none" v-for="item in notifyFeatures" :key="item">
                        <ion-icon :src="checkmarkCircle" class="checkmarkIcon"></ion-icon>
                        <ion-label class="checkbox-text">{{ item }}</ion-label>
                      </ion-item>
                    </div>
                    <ion-footer>
                      <ion-button color="primary" fill="outline" expand="block">Current plan</ion-button>
                    </ion-footer>
                  </div>
                </div>
              </ion-col>
              <ion-col>
                <div class="plans-cards-container">
                  <div class="plans-cards-header plans-cards-header-engage">
                    <div class="plans-card-desc">
                      <div class="sms-img">
                        <img src="../../resources/images/Groups-Served.svg" alt="sms-img" class="smsImg" />
                      </div>
                      <div class="help-info">
                        <h2 class="help-title ion-no-margin">Engage</h2>
                        <p class="help-subtitle ion-no-margin">Go beyond notifications and engage users.</p>
                      </div>
                    </div>
                  </div>
                  <div class="plans-cards-desc">
                    <div class="plans-cards-engage-features">
                      <ion-item lines="none" v-for="item in engageFeatures" :key="item">
                        <ion-icon :src="checkmarkCircle" class="checkmarkIcon"></ion-icon>
                        <ion-label class="checkbox-text">{{ item }}</ion-label>
                      </ion-item>
                    </div>
                    <ion-button color="primary" fill="solid" expand="block">Upgrade plan</ion-button>
                  </div>
                </div>
              </ion-col>
              <ion-col>
                <div class="plans-cards-container">
                  <div class="plans-cards-header plans-cards-header-convert">
                    <div class="plans-card-desc">
                      <div class="sms-img">
                        <img src="../../resources/images/Conversational-Commerce.svg" alt="sms-img" class="smsImg" />
                      </div>
                      <div class="help-info">
                        <h2 class="help-title ion-no-margin">Convert</h2>
                        <p class="ion-no-margin convert-subtitle">Turn your engagement into sales.</p>
                      </div>
                    </div>
                  </div>
                  <div class="plans-cards-desc">
                    <div class="plans-cards-convert-features">
                      <ion-item lines="none" v-for="item in convertFeatures" :key="item">
                        <ion-icon :src="checkmarkCircle" class="checkmarkIcon"></ion-icon>
                        <ion-label class="checkbox-text">{{ item }}</ion-label>
                      </ion-item>
                    </div>
                    <ion-button color="primary" fill="solid" expand="block">Upgrade plan</ion-button>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="plans-addons">
          <div class="plans-header">
            <h1 class="ion-no-margin addon-title">Add-ons</h1>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/Facebook.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">Facebook </div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-button color="primary" fill="solid" class="buyBttn">Buy</ion-button>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/whatsapp.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">WhatsApp </div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-button color="primary" fill="solid" class="buyBttn">Buy</ion-button>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/Instagram.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">Instagram</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-icon :src="checkmarkCircleOutline" size="large" class="checkmarkIcon"></ion-icon>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://jumper.ai/landing/resources/images/viber.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">Viber</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-icon :src="checkmarkCircleOutline" size="large" class="checkmarkIcon"></ion-icon>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICA8LqFpIMKDA" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">Telegram</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-icon :src="checkmarkCircleOutline" size="large" class="checkmarkIcon"></ion-icon>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/LINE.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">LINE</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-button color="primary" fill="solid" class="buyBttn">Buy</ion-button>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://insights.jumper.ai/wp-content/jumper-resources/landing-cdn/resources/new-logos/iMessage.png" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">iMessage</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-button color="primary" fill="solid" class="buyBttn">Buy</ion-button>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://storage.googleapis.com/polygon-prod-123/wp-content/jumper-resources/landing-cdn/resources/new-logos/SMS-2.svg" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">SMS</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-icon :src="checkmarkCircleOutline" size="large" class="checkmarkIcon"></ion-icon>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
              <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" class="rowMargin">
                <div class="sales-channel-container">
                  <div class="sales-box">
                    <div class="image">
                      <ion-img src="https://jumper.ai/jump-image/ag9zfmp1bXBlci0xNDc4MDZyGAsSC0p1bXBlcmltYWdlGICAsNPyx6wJDA" />
                    </div>
                    <div class="sales-channel-text">
                      <div class="name">Sales Channel</div>
                      <div style="padding-top: 5px">Google Business Messages</div>
                    </div>
                  </div>
                  <div class="bttn-container">
                    <ion-buttons slot="end">
                      <ion-icon :src="checkmarkCircleOutline" size="large" class="checkmarkIcon"></ion-icon>
                    </ion-buttons>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { IonItem, IonLabel, IonButton, IonImg } from '@ionic/vue';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import { mapState } from "vuex";

export default {
  components: {
    IonItem, IonLabel, IonButton, IonImg
  },
  data() {
    return {
      loading: false,
      checkBox: true,
      checkmarkCircle,
      checkmarkCircleOutline,
      notifyFeatures: ['Broadcast', 'Live Chat', 'Analytics'],
      engageFeatures: ['Broadcast', 'Live Chat', 'Analytics', 'Bot Builder'],
      convertFeatures: ['Broadcast', 'Live Chat', 'Analytics', 'Bot Builder', 'Commerce Capabilities']
    }
  },
  computed: {
    ...mapState({
      isEnterprise: (state) => state.user.enterprise
    })
  },
  mounted() {
    if (this.isEnterprise) {
      this.$router.push({ path: 'dashboard' });
      setTimeout(() => {
        this.$router.push({ path: 'dashboard' });
      }, 1000)
    }
  }
}
</script>

<style>
.plans-container {
  margin: 20px;
}

.plans-header {
  margin: 0 auto;
  width: 50%;
}

.plans-header .title {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: var(--ion-color-black);
}

.plans-header .sub-title {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--ion-color-black);
  padding-top: 10px;
}

.plans-header .addon-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: var(--ion-color-black);
  text-align: center;
  margin: 20px 0px 40px;

}

.plans-cards {
  margin-top: 40px;
}

.plans-cards-container {
  background: #FFFFFF;
  border-radius: 5px;
  min-height: 650px;
  max-height: 660px;
}

.plans-cards-container .plans-cards-header {
  background: #FDF5FA;
  border-radius: 5px 5px 0px 0px;
  padding: 40px;
}

.plans-cards-header .sms-img {
  display: flex;
  justify-content: center;
}

.plans-cards-header .help-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: var(--ion-color-black);
  text-align: center;
  margin: 10px;
}

.plans-cards-header .help-subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #54575E;
  text-align: center;
}

.plans-cards-container .plans-cards-desc {
  background: #FFFFFF;
  padding: 23px;
}

.checkmarkIcon {
  color: var(--ion-color-primary);
}

.checkbox-text {
  padding-left: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #54575E;
}

.plans-cards-features {
  margin: 0px 0px 140px 60px;
}

.plans-cards-engage-features {
  margin: 0px 0px 92px 60px;
}

.plans-cards-convert-features {
  margin: 0px 0px 44px 60px;
}

.plans-cards-container .plans-cards-header-engage {
  background: #F3F9FE;
}

.plans-cards-container .plans-cards-header-convert {
  background: #F9F4FF;
}

.convert-subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #54575E;
  text-align: center;
}

.sales-channel-container {
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  padding: 22px;
  margin-right: 10px;
}

.sales-box {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.sales-channel-container .image {
  width: 50px;
  height: 50px;
}

.sales-channel-text {
  margin-left: 10px;
}

.sales-channel-text .name {
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #262626;
}

.sales-channel-text .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #54575E;
  text-decoration: none !important;
}

.buyBttn {
  border-radius: 5px;
  border: 1px solid #881FFF;
}

.sales-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bttn-container {
  bottom: 36px;
  position: relative;
  float: right;
}
</style>